<div id="caliMedStyle">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <div class="row">
                <div class="col-md-12">
                    <img src="./assets/images/Califorinia.png"  alt="Credential my Doc" class="imStyle">
                    <h2 class="hStyle">California Participating Midlevel Application</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p style="text-align: justify;">
                        <b>This form should be typed or legibly printed in black or blue ink.</b>If more space is needed, send additional sheets to <u style="color: blue;">operations@bassmedicalgroup.com</u> and reference the question being answered. Please do not use abbreviations when completing the application.
                    </p>
                </div>
            </div>
        </div>
        <div class="section">
            <form [formGroup]="caliMed">
                <table class="table tabwidth" border="1">
                    <thead class="tabhead">
                        <tr>
                            <th class="tabTh">I. IDENTIFYING INFORMATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">LastName</label>
                                        <input class="form-control onboard"  formControlName="9"
                                            [class.is-invalid]="(caliMed.controls['9'].touched && caliMed.controls['9'].invalid) || (caliMed.controls['9'].invalid && isSubmit)"
                                            id="9" name="9" type="text" placeholder= "Last Name" data-id="managing_contact_name" (change)="onChangeLastName($event)">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['9'].touched && caliMed.controls['9'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['9'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">First Name</label>
                                        <input class="form-control onboard"  formControlName="8"
                                            [class.is-invalid]="(caliMed.controls['8'].touched && caliMed.controls['8'].invalid) || (caliMed.controls['8'].invalid && isSubmit)"
                                            id="8" name="8" type="text" placeholder= "First Name" data-id="managing_contact_name" (change)="onChangeFirstName($event)">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['8'].touched && caliMed.controls['8'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['8'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Middle Name or Initial</label>
                                        <input class="form-control onboard" formControlName="809"
                                            [class.is-invalid]="(caliMed.controls['809'].touched && caliMed.controls['809'].invalid) || (caliMed.controls['809'].invalid && isSubmit)"
                                            id="809" name="809" type="text" placeholder= "Middle Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['809'].touched && caliMed.controls['809'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['809'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div> -->
                                    <div class="col-3">
                                        <label style="font-weight: normal;font-size: 14px">Middle Name or Initial<span *ngIf="caliMed.controls['809'].invalid" class="mandatory">*</span> </label>
                                    
            
                                        <input class="form-control onboard" formControlName="809" (blur)="handleNAchange('cmd_NA_01')" [class.is-invalid]="caliMed.controls['809'].invalid" id="809" name="809" class="form-control"
                                        placeholder= "Middle Name" formControlName="809" type="text" data-id="managing_contact_name" />
                                        <div class="mandatory" *ngIf="caliMed.controls['809'].invalid">
                                          <small>required</small>
                                        
                                        </div>
                                    </div>

                                        
                                    <div >
                                      <input formControlName="cmd_NA_01" (ngModelChange)="handleNAchange('cmd_NA_01')"
                                       type="checkbox" value="1" id="cmd_NA_01">
                                      <label class="form-check-label" for="cmd_NA_01">
                                        N/A
                                      </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home Mailing Street Address</label>
                                        <input class="form-control onboard" formControlName="16"
                                            [class.is-invalid]="(caliMed.controls['16'].touched && caliMed.controls['16'].invalid) || (caliMed.controls['16'].invalid && isSubmit)"
                                            id="16" name="16" type="text" data-id="managing_contact_name" placeholder="Home Address1">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['16'].touched && caliMed.controls['16'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['16'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label style="font-weight: normal;font-size: 14px;padding-top:15px;"></label>
                                        <input class="form-control onboard" formControlName="17"
                                            [class.is-invalid]="(caliMed.controls['17'].touched && caliMed.controls['17'].invalid) || (caliMed.controls['17'].invalid && isSubmit)"
                                            id="17" name="17" type="text" data-id="managing_contact_name" placeholder="Home Address2">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['17'].touched && caliMed.controls['17'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['17'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number</label>
                                        <input class="form-control onboard" formControlName="708"
                                            [class.is-invalid]="(caliMed.controls['708'].touched && caliMed.controls['708'].invalid) || (caliMed.controls['708'].invalid && isSubmit)"
                                            id="708" name="708" type="text" placeholder= "Phone Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['708'].touched && caliMed.controls['708'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['708'].errors?.required">
                                                Home phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['708'].errors?.pattern">
                                                Required Pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email</label>
                                        <input class="form-control onboard" formControlName="759"
                                            [class.is-invalid]="(caliMed.controls['759'].touched && caliMed.controls['759'].invalid) || (caliMed.controls['759'].invalid && isSubmit)"
                                            id="759" name="759" type="text" placeholder= "Email" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['759'].touched && caliMed.controls['759'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['759'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home City</label>
                                        <input class="form-control onboard" formControlName="18"
                                            [class.is-invalid]="(caliMed.controls['18'].touched && caliMed.controls['18'].invalid) || (caliMed.controls['18'].invalid && isSubmit)"
                                            id="18" name="18" type="text" data-id="managing_contact_name" placeholder="Home Address1">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['18'].touched && caliMed.controls['18'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['18'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home US county</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="county" bindLabel="name" placeholder="Select County" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['19'].touched && caliMed.controls['19'].invalid) || (caliMed.controls['19'].invalid && isSubmit)"
                                                formControlName="19">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['19'].touched && caliMed.controls['19'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['19'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Home State or Country</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['20'].touched && caliMed.controls['20'].invalid) || (caliMed.controls['20'].invalid && isSubmit)"
                                                formControlName="20">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['20'].touched && caliMed.controls['20'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['20'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Home Zip</label>
                                        <input class="form-control onboard" formControlName="21"
                                            [class.is-invalid]="(caliMed.controls['21'].touched && caliMed.controls['21'].invalid) || (caliMed.controls['21'].invalid && isSubmit)"
                                            id="21" name="21" type="text" placeholder= "Home Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['21'].touched && caliMed.controls['21'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['21'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Birthdate</label>
                                        <div class="input-group">
                                            <input class="form-control text" ngbDatepicker #d1="ngbDatepicker" formControlName="31"   (blur)="clearInputIfInvalidMandi('31')"
                                                [class.is-invalid]="(caliMed.controls['31'].touched && caliMed.controls['31'].invalid) || (caliMed.controls['31'].invalid && isSubmit)"
                                                id="31" name="31" type="text" placeholder= "Birth Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['31'].invalid&&caliMed.controls['31'].touched) && (caliMed.controls['31'].value==''|| caliMed.controls['31'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('31').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('31').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('31').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('31').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">BirthPlace(City)</label>
                                        <input class="form-control onboard" formControlName="810"
                                            [class.is-invalid]="(caliMed.controls['810'].touched && caliMed.controls['810'].invalid) || (caliMed.controls['810'].invalid && isSubmit)"
                                            id="810" name="810" type="text" placeholder= "BirthPlace City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['810'].touched && caliMed.controls['810'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['810'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">BirthPlace(State/Country)</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['289'].touched && caliMed.controls['289'].invalid) || (caliMed.controls['289'].invalid && isSubmit)"
                                                formControlName="289">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['289'].touched && caliMed.controls['289'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['289'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Citizenship (if not a United States citizen, please include copy of Alien Registration Card )</label>
                                    <input class="form-control onboard" formControlName="292" placeholder="Citizenship"
                                    [class.is-invalid]="(caliMed.controls['292'].touched && caliMed.controls['292'].invalid) || (caliMed.controls['292'].invalid && isSubmit)"
                                    id="292" name="292" type="text" data-id="managing_contact_name">
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliMed.controls['292'].touched && caliMed.controls['292'].invalid">
                                        <small class="text-danger" *ngIf="caliMed.controls['292'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Social Security Number</label>
                                    <input class="form-control onboard" formControlName="32" placeholder="SSN"
                                        [class.is-invalid]="(caliMed.controls['32'].touched && caliMed.controls['32'].invalid) || (caliMed.controls['32'].invalid && isSubmit)"
                                        id="32" name="32" type="text" data-id="managing_contact_name">
                                    <div class="formLabel col-md-11"
                                        *ngIf="caliMed.controls['32'].touched && caliMed.controls['32'].invalid">
                                        <small class="text-danger" *ngIf="caliMed.controls['32'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">Gender</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="gender" bindLabel="name" placeholder="Select Gender" bindValue="id"
                                                    [class.is-invalid]="(caliMed.controls['30'].touched && caliMed.controls['30'].invalid) || (caliMed.controls['30'].invalid && isSubmit)"
                                                    formControlName="30">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['30'].touched && caliMed.controls['30'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['30'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Race/Ethnicity (required)</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="race" bindLabel="name" placeholder="Select Race" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['741'].touched && caliMed.controls['741'].invalid) || (caliMed.controls['741'].invalid && isSubmit)"
                                                formControlName="741">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['741'].touched && caliMed.controls['741'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['741'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-5">
                                    <label style="font-weight: normal;font-size: 14px">Please list any foreign languages you speak fluently:</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="mb-3 selectDrop dropDrownSet">
                                        <ng-select [items]="languages" bindLabel="name" placeholder="Select langauge" bindValue="id"
                                        [class.is-invalid]="(caliMed.controls['277'].touched && caliMed.controls['277'].invalid) || (caliMed.controls['277'].invalid && isSubmit)"
                                        formControlName="277">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['277'].touched && caliMed.controls['277'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['277'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="mb-3 selectDrop dropDrownSet">
                                        <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select langauge" bindValue="id"
                                        [class.is-invalid]="(caliMed.controls['1535'].touched && caliMed.controls['1535'].invalid) || (caliMed.controls['1535'].invalid && isSubmit)"
                                        formControlName="1535">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1535'].touched && caliMed.controls['1535'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1535'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="mb-3 selectDrop dropDrownSet">
                                        <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select langauge" bindValue="id"
                                        [class.is-invalid]="(caliMed.controls['1536'].touched && caliMed.controls['1536'].invalid) || (caliMed.controls['1536'].invalid && isSubmit)"
                                        formControlName="1536">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1536'].touched && caliMed.controls['1536'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1536'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" style="padding-top:10px">
                                    <div class="mb-3 selectDrop dropDrownSet">
                                        <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select langauge" bindValue="id"
                                        [class.is-invalid]="(caliMed.controls['2207'].touched && caliMed.controls['2207'].invalid) || (caliMed.controls['2207'].invalid && isSubmit)"
                                        formControlName="2207">
                                        </ng-select>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['2207'].touched && caliMed.controls['2207'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['2207'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    You will receive an email to have Paula Hewitt act as a surrogate in order to apply for a BASS Medicare number in the I+A system. When the
                                    email is sent from Noridian, please accept the request.
                                </p>
                                <div class="row">
                                    <div class="col-md-4">
                                        <input class="form-control onboard" formControlName="cmd_292"
                                        [class.is-invalid]="(caliMed.controls['cmd_292'].touched && caliMed.controls['cmd_292'].invalid) || (caliMed.controls['cmd_292'].invalid && isSubmit)"
                                        id="cmd_292" name="cmd_292" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_292'].touched && caliMed.controls['cmd_292'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_292'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="tabSideHeading">CAQH PROVIEW</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>Please list the username and password for your CAQH Proview account. CAQH is a universal database which is accessed by health plans for credentialing information. You will need to create a complete profile at https://proview.caqh.org. Please make sure to save your work on every page. Our credentialing team will add the billing and contact info and will maintain the profile for you; we just need you to do the initial setup. If you need assistance with CAQH, please reach out to the CAQH help desk.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <b>CAQH Provider Help Desk:</b>
                                    </div>
                                    <div class="col-md-12">
                                        Phone: 1-888-599-1771
                                    </div>
                                    <div class="col-md-12">
                                        Phone Hours:
                                    </div>
                                    <div class="col-md-12">
                                        Monday – Thursday: 7 AM – 9 PM (EST)
                                    </div>
                                    <div class="col-md-12">
                                        Friday: 7 AM – 7 PM (EST)
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Provider Number</label>
                                        <input class="form-control onboard" formControlName="333"
                                            [class.is-invalid]="(caliMed.controls['333'].touched && caliMed.controls['333'].invalid) || (caliMed.controls['333'].invalid && isSubmit)"
                                            id="333" name="333" type="text"  data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['333'].touched && caliMed.controls['333'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['333'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">User Name</label>
                                        <input class="form-control onboard" formControlName="334"
                                            [class.is-invalid]="(caliMed.controls['334'].touched && caliMed.controls['334'].invalid) || (caliMed.controls['334'].invalid && isSubmit)"
                                            id="334" name="334" type="text"  data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['334'].touched && caliMed.controls['334'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['334'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Password</label>
                                        <input class="form-control onboard" formControlName="335"
                                            [class.is-invalid]="(caliMed.controls['335'].touched && caliMed.controls['335'].invalid) || (caliMed.controls['335'].invalid && isSubmit)"
                                            id="335" name="335" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['335'].touched && caliMed.controls['335'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['335'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">MEDICAL LICENSURE/REGISTRATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">California Medical License</label>
                                        <input class="form-control onboard" formControlName="293"
                                            [class.is-invalid]="(caliMed.controls['293'].touched && caliMed.controls['293'].invalid) || (caliMed.controls['293'].invalid && isSubmit)"
                                            id="293" name="293" type="text" placeholder= "License Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['293'].touched && caliMed.controls['293'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['293'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Issue Date</label>
                                        <div class="input-group">
                                            <input class="form-control" formControlName="294"   (blur)="clearInputIfInvalidMandi('294')" ngbDatepicker #d2="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['294'].touched && caliMed.controls['294'].invalid) || (caliMed.controls['294'].invalid && isSubmit)"
                                            id="294" name="294" type="text" placeholder= "License IssueDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['294'].invalid&&caliMed.controls['294'].touched) && (caliMed.controls['294'].value==''|| caliMed.controls['294'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('294').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('294').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('294').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('294').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                        <!-- <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['294'].touched && caliMed.controls['294'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['294'].errors?.required">
                                            required
                                            </small>
                                        </div> -->
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="295"   (blur)="clearInputIfInvalidMandi('295')" ngbDatepicker #d3="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['295'].touched && caliMed.controls['295'].invalid) || (caliMed.controls['295'].invalid && isSubmit)"
                                            id="295" name="295" type="text" placeholder= "License ExpDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['295'].invalid&&caliMed.controls['295'].touched) && (caliMed.controls['295'].value==''|| caliMed.controls['295'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('295').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('295').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('295').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('295').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Drug Enforcement Administration (DEA)</label>
                                        <input class="form-control onboard" formControlName="309"
                                            [class.is-invalid]="(caliMed.controls['309'].touched && caliMed.controls['309'].invalid) || (caliMed.controls['309'].invalid && isSubmit)"
                                            id="309" name="309" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['309'].touched && caliMed.controls['309'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['309'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="311"   (blur)="clearInputIfInvalidMandi('311')" ngbDatepicker #d4="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['311'].touched && caliMed.controls['311'].invalid) || (caliMed.controls['311'].invalid && isSubmit)"
                                            id="311" name="311" type="text" placeholder= "DEA ExpDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['311'].invalid&&caliMed.controls['311'].touched) && (caliMed.controls['311'].value==''|| caliMed.controls['311'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('311').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('311').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('311').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('311').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">National Physician Identifier (NPI)</label>
                                        <input class="form-control onboard" formControlName="1634"
                                            [class.is-invalid]="(caliMed.controls['1634'].touched && caliMed.controls['1634'].invalid) || (caliMed.controls['1634'].invalid && isSubmit)"
                                            id="1634" name="1634" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1634'].touched && caliMed.controls['1634'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1634'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Educational Commission for Foreign Medical Graduates (ECFMG) Number
            (applicable to foreign medical graduates):</label>
                                        <input class="form-control onboard" formControlName="514"
                                        [class.is-invalid]="(caliMed.controls['514'].touched && caliMed.controls['514'].invalid) || (caliMed.controls['514'].invalid && isSubmit)"
                                        id="514" name="514" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['514'].touched && caliMed.controls['514'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['514'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Issue Date:</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="515"   (blur)="clearInputIfInvalidMandi('515')" ngbDatepicker #d5="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['515'].touched && caliMed.controls['515'].invalid) || (caliMed.controls['515'].invalid && isSubmit)"
                                            id="515" name="515" type="text" placeholder= "ECFMG Issue Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['515'].invalid&&caliMed.controls['515'].touched) && (caliMed.controls['515'].value==''|| caliMed.controls['515'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('515').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('515').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('515').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('515').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Valid Through Date:</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="516"   (blur)="clearInputIfInvalidMandi('516')"
                                            [class.is-invalid]="(caliMed.controls['516'].touched && caliMed.controls['516'].invalid) || (caliMed.controls['516'].invalid && isSubmit)"
                                            ngbDatepicker #d6="ngbDatepicker" id="516" name="516" type="text" placeholder= "ECFMG Exp Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['516'].invalid&&caliMed.controls['516'].touched) && (caliMed.controls['516'].value==''|| caliMed.controls['516'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('516').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('516').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('516').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('516').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medicare Number (Mandatory)</label>
                                        <input class="form-control onboard" formControlName="325"
                                        [class.is-invalid]="(caliMed.controls['325'].touched && caliMed.controls['325'].invalid) || (caliMed.controls['325'].invalid && isSubmit)"
                                        id="325" name="325" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['325'].touched && caliMed.controls['325'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['325'].errors?.required">
                                                required
                                                </small>
                                            </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medi-Cal Number (If Applicable)</label>
                                        <input class="form-control onboard" formControlName="812"
                                        [class.is-invalid]="(caliMed.controls['812'].touched && caliMed.controls['812'].invalid) || (caliMed.controls['812'].invalid && isSubmit)"
                                        id="812" name="812" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['812'].touched && caliMed.controls['812'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['812'].errors?.required">
                                                required
                                                </small>
                                            </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">PEER REFERENCES</th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                List three professional references, preferably from your specialty area, not including relatives, current partners or associates in practice. If
                possible, include at least one member from the Medical Staff of each facility at which you have privileges.
                                </p>
                                <p>
                                    <b>Note:</b>References must be from individuals who are directly familiar with your work, either via direct clinical observation or through close
                working relations.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and Address:</b></label>
                                        <input class="form-control onboard" formControlName="359"
                                        [class.is-invalid]="(caliMed.controls['359'].touched && caliMed.controls['359'].invalid) || (caliMed.controls['359'].invalid && isSubmit)"
                                        id="359" name="359" type="text" placeholder= "Reference1 Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['359'].touched && caliMed.controls['359'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['359'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="360"
                                        [class.is-invalid]="(caliMed.controls['360'].touched && caliMed.controls['360'].invalid) || (caliMed.controls['360'].invalid && isSubmit)"
                                        id="360" name="360" type="text" placeholder= "Reference1 Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['360'].touched && caliMed.controls['360'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['360'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="361"
                                        [class.is-invalid]="(caliMed.controls['361'].touched && caliMed.controls['361'].invalid) || (caliMed.controls['361'].invalid && isSubmit)"
                                        id="361" name="361" type="text" placeholder= "Reference1 Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['361'].touched && caliMed.controls['361'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['361'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="362"
                                        [class.is-invalid]="(caliMed.controls['362'].touched && caliMed.controls['362'].invalid) || (caliMed.controls['362'].invalid && isSubmit)"
                                        id="362" name="362" type="text" placeholder= "Reference1 City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['362'].touched && caliMed.controls['362'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['362'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="365"
                                        [class.is-invalid]="(caliMed.controls['365'].touched && caliMed.controls['365'].invalid) || (caliMed.controls['365'].invalid && isSubmit)"
                                        id="365" name="365" type="text" placeholder= "Reference1 Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['365'].touched && caliMed.controls['365'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['365'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="states" bindLabel="name" placeholder="Select State" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['364'].touched && caliMed.controls['364'].invalid) || (caliMed.controls['364'].invalid && isSubmit)"
                                                formControlName="364">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['364'].touched && caliMed.controls['364'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['364'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialities" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['368'].touched && caliMed.controls['368'].invalid) || (caliMed.controls['368'].invalid && isSubmit)"
                                                formControlName="368">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['368'].touched && caliMed.controls['368'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['368'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="369"
                                        [class.is-invalid]="(caliMed.controls['369'].touched && caliMed.controls['369'].invalid) || (caliMed.controls['369'].invalid && isSubmit)"
                                        id="369" name="369" type="text" placeholder= "Reference1 Phone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['369'].touched && caliMed.controls['369'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['369'].errors?.required">
                                                Home phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['369'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="371"
                                        [class.is-invalid]="(caliMed.controls['371'].touched && caliMed.controls['371'].invalid) || (caliMed.controls['371'].invalid && isSubmit)"
                                        id="371" name="371" type="text" placeholder= "Reference1 Email" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['371'].touched && caliMed.controls['371'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['371'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and Address:</b></label>
                                        <input class="form-control onboard" formControlName="372"
                                        [class.is-invalid]="(caliMed.controls['372'].touched && caliMed.controls['372'].invalid) || (caliMed.controls['372'].invalid && isSubmit)"
                                        id="372" name="372" type="text" placeholder= "Reference2 Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['372'].touched && caliMed.controls['372'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['372'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="373"
                                        [class.is-invalid]="(caliMed.controls['373'].touched && caliMed.controls['373'].invalid) || (caliMed.controls['373'].invalid && isSubmit)"
                                        id="373" name="373" type="text" placeholder= "Reference2 Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['373'].touched && caliMed.controls['373'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['373'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="374"
                                        [class.is-invalid]="(caliMed.controls['374'].touched && caliMed.controls['374'].invalid) || (caliMed.controls['374'].invalid && isSubmit)"
                                        id="374" name="374" type="text" placeholder= "Reference2 Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['374'].touched && caliMed.controls['374'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['374'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="375"
                                        [class.is-invalid]="(caliMed.controls['375'].touched && caliMed.controls['375'].invalid) || (caliMed.controls['375'].invalid && isSubmit)"
                                        id="375" name="375" type="text" placeholder= "Reference2 City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['375'].touched && caliMed.controls['375'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['375'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="378"
                                        [class.is-invalid]="(caliMed.controls['378'].touched && caliMed.controls['378'].invalid) || (caliMed.controls['378'].invalid && isSubmit)"
                                        id="378" name="378" type="text" placeholder= "Reference2 Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['378'].touched && caliMed.controls['378'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['378'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['377'].touched && caliMed.controls['377'].invalid) || (caliMed.controls['377'].invalid && isSubmit)"
                                                formControlName="377">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['377'].touched && caliMed.controls['377'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['377'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                        -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialities" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['380'].touched && caliMed.controls['380'].invalid) || (caliMed.controls['380'].invalid && isSubmit)"
                                                formControlName="380">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['380'].touched && caliMed.controls['380'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['380'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="381"
                                        [class.is-invalid]="(caliMed.controls['381'].touched && caliMed.controls['381'].invalid) || (caliMed.controls['381'].invalid && isSubmit)"
                                        id="381" name="381" type="text" placeholder= "Reference2 Phone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['381'].touched && caliMed.controls['381'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['381'].errors?.required">
                                                Home phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['381'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="383"
                                        [class.is-invalid]="(caliMed.controls['383'].touched && caliMed.controls['383'].invalid) || (caliMed.controls['383'].invalid && isSubmit)"
                                        id="383" name="383" type="text" placeholder= "Reference2 Email" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['383'].touched && caliMed.controls['383'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['383'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px"><b>Name of Reference and Address:</b></label>
                                        <input class="form-control onboard" formControlName="384"
                                        [class.is-invalid]="(caliMed.controls['384'].touched && caliMed.controls['384'].invalid) || (caliMed.controls['384'].invalid && isSubmit)"
                                        id="384" name="384" type="text" placeholder= "Reference3 Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['384'].touched && caliMed.controls['384'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['384'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="385"
                                        [class.is-invalid]="(caliMed.controls['385'].touched && caliMed.controls['385'].invalid) || (caliMed.controls['385'].invalid && isSubmit)"
                                        id="385" name="385" type="text" placeholder= "Reference3 Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['385'].touched && caliMed.controls['385'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['385'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="386"
                                        [class.is-invalid]="(caliMed.controls['386'].touched && caliMed.controls['386'].invalid) || (caliMed.controls['386'].invalid && isSubmit)"
                                        id="386" name="386" type="text" placeholder= "Reference3 Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['386'].touched && caliMed.controls['386'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['386'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="387"
                                        [class.is-invalid]="(caliMed.controls['387'].touched && caliMed.controls['387'].invalid) || (caliMed.controls['387'].invalid && isSubmit)"
                                        id="387" name="387" type="text" placeholder= "Reference3 City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['387'].touched && caliMed.controls['387'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['387'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="390"
                                        [class.is-invalid]="(caliMed.controls['390'].touched && caliMed.controls['390'].invalid) || (caliMed.controls['390'].invalid && isSubmit)"
                                        id="390" name="390" type="text" placeholder= "Reference3 Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['390'].touched && caliMed.controls['390'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['390'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['389'].touched && caliMed.controls['389'].invalid) || (caliMed.controls['389'].invalid && isSubmit)"
                                                formControlName="389">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['389'].touched && caliMed.controls['389'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['389'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Specialty</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                         -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialities" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['393'].touched && caliMed.controls['393'].invalid) || (caliMed.controls['393'].invalid && isSubmit)"
                                                formControlName="393">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['393'].touched && caliMed.controls['393'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['393'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Phone Number:</label>
                                        <input class="form-control onboard" formControlName="394"
                                        [class.is-invalid]="(caliMed.controls['394'].touched && caliMed.controls['394'].invalid) || (caliMed.controls['394'].invalid && isSubmit)"
                                        id="394" name="394" type="text" placeholder= "Reference3 Phone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['394'].touched && caliMed.controls['394'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['394'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['394'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Email:</label>
                                        <input class="form-control onboard" formControlName="396"
                                        [class.is-invalid]="(caliMed.controls['396'].touched && caliMed.controls['396'].invalid) || (caliMed.controls['396'].invalid && isSubmit)"
                                        id="396" name="396" type="text" placeholder= "Reference3 Email" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['396'].touched && caliMed.controls['396'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['396'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">II.EDUCATION (Send additional sheets to <u style="color:blue;">operations@bassmedicalgroup.com</u> if necessary. Please reference this section number and title.)
                            </th>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">PREMEDICAL EDUCATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">College or University Name</label>
                                        <input class="form-control onboard" formControlName="503"
                                        [class.is-invalid]="(caliMed.controls['503'].touched && caliMed.controls['503'].invalid) || (caliMed.controls['503'].invalid && isSubmit)"
                                        id="503" name="503" type="text" placeholder= "UG School" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['503'].touched && caliMed.controls['503'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['503'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="504"
                                        [class.is-invalid]="(caliMed.controls['504'].touched && caliMed.controls['504'].invalid) || (caliMed.controls['504'].invalid && isSubmit)"
                                        id="504" name="504" type="text" placeholder= "UG School Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['504'].touched && caliMed.controls['504'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['504'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="505"
                                        [class.is-invalid]="(caliMed.controls['505'].touched && caliMed.controls['505'].invalid) || (caliMed.controls['505'].invalid && isSubmit)"
                                        id="505" name="505" type="text" placeholder= "UG School Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['505'].touched && caliMed.controls['505'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['505'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="506"
                                        [class.is-invalid]="(caliMed.controls['506'].touched && caliMed.controls['506'].invalid) || (caliMed.controls['506'].invalid && isSubmit)"
                                        id="506" name="506" type="text" placeholder= "UG School City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['506'].touched && caliMed.controls['506'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['506'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="509"
                                        [class.is-invalid]="(caliMed.controls['509'].touched && caliMed.controls['509'].invalid) || (caliMed.controls['509'].invalid && isSubmit)"
                                        id="509" name="509" type="text" placeholder= "UG School Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['509'].touched && caliMed.controls['509'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['509'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                                            [class.is-invalid]="(caliMed.controls['512'].touched && caliMed.controls['512'].invalid) || (caliMed.controls['512'].invalid && isSubmit)"
                                             formControlName="512">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['512'].touched && caliMed.controls['512'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['512'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                      <label style="font-weight: normal;font-size: 14px">Start Date (mm/dd/yyyy)</label>
                                      <div class="input-group">
                                          <input class="form-control onboard datepicker" formControlName="510"   (blur)="clearInputIfInvalidMandi('510')"
                                          [class.is-invalid]="(caliMed.controls['510'].touched && caliMed.controls['510'].invalid) || (caliMed.controls['510'].invalid && isSubmit)"
                                          ngbDatepicker #d100="ngbDatepicker" id="510" name="510" type="text" placeholder= "Enter Start Date" data-id="510">
                                          <div class="input-group-append">
                                              <button class="btn btn-outline-secondary calendar" (click)="d100.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                          </div>
                                          
                                      </div>
                                      <div class="text-danger" *ngIf="(caliMed.controls['510'].invalid&&caliMed.controls['510'].touched) && (caliMed.controls['510'].value==''|| caliMed.controls['510'].value==null) " >
                                        <small >
                                        required
                                        </small>
                                      
                                      </div>
                                      <small class="text-danger" *ngIf="caliMed.get('510').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                                      <small class="text-danger" *ngIf="caliMed.get('510').errors?.invalidMonth">
                                      Month is out of range
                                      </small>
                                      <small class="text-danger" *ngIf="caliMed.get('510').errors?.invalidDateyear">
                                        year is out of range(1900-2099)                  </small>
                                        <small class="text-danger" *ngIf="caliMed.get('510').errors?.invalidDateRange">
                                          date is out of range
                                          </small>
                                  </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Date of Graduation (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="511"   (blur)="clearInputIfInvalidMandi('511')"
                                            [class.is-invalid]="(caliMed.controls['511'].touched && caliMed.controls['511'].invalid) || (caliMed.controls['511'].invalid && isSubmit)"
                                            ngbDatepicker #d7="ngbDatepicker" id="511" name="511" type="text" placeholder= "UG Date of Graduation" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['511'].invalid&&caliMed.controls['511'].touched) && (caliMed.controls['511'].value==''|| caliMed.controls['511'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('511').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('511').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('511').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('511').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">MEDICAL EDUCATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medical/Professional School</label>
                                        <input class="form-control onboard" formControlName="479"
                                        [class.is-invalid]="(caliMed.controls['479'].touched && caliMed.controls['479'].invalid) || (caliMed.controls['479'].invalid && isSubmit)"
                                        id="479" name="479" type="text" placeholder= "Grad School" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['479'].touched && caliMed.controls['479'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['479'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailing Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="483"
                                        [class.is-invalid]="(caliMed.controls['483'].touched && caliMed.controls['483'].invalid) || (caliMed.controls['483'].invalid && isSubmit)"
                                        id="483" name="483" type="text" placeholder= "Grad School Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['483'].touched && caliMed.controls['483'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['483'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="484"
                                        [class.is-invalid]="(caliMed.controls['484'].touched && caliMed.controls['484'].invalid) || (caliMed.controls['484'].invalid && isSubmit)"
                                        id="484" name="484" type="text" placeholder= "Grad School Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['484'].touched && caliMed.controls['484'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['484'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="485"
                                        [class.is-invalid]="(caliMed.controls['485'].touched && caliMed.controls['485'].invalid) || (caliMed.controls['485'].invalid && isSubmit)"
                                        id="485" name="485" type="text" placeholder= "Grad School City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['485'].touched && caliMed.controls['485'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['485'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="488"
                                        [class.is-invalid]="(caliMed.controls['488'].touched && caliMed.controls['488'].invalid) || (caliMed.controls['488'].invalid && isSubmit)"
                                        id="488" name="488" type="text" placeholder= "Grad School Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['488'].touched && caliMed.controls['488'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['488'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree"
                                            [class.is-invalid]="(caliMed.controls['480'].touched && caliMed.controls['480'].invalid) || (caliMed.controls['480'].invalid && isSubmit)"
                                            bindValue="id" formControlName="480">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['480'].touched && caliMed.controls['480'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['480'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                      <label style="font-weight: normal;font-size: 14px">Start Date (mm/dd/yyyy)</label>
                                          <div class="input-group">
                                              <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('481')" formControlName="481" ngbDatepicker #d101="ngbDatepicker"
                                              [class.is-invalid]="(caliMed.controls['481'].touched && caliMed.controls['481'].invalid) || (caliMed.controls['481'].invalid && isSubmit)"
                                              id="481" name="481" type="text" placeholder= "Enter Start Date" data-id="managing_contact_name">
                                              <div class="input-group-append">
                                                  <button class="btn btn-outline-secondary calendar" (click)="d101.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                              </div>
                                              <div class="formLabel col-md-11"
                                                  *ngIf="caliMed.controls['481'].touched && caliMed.controls['481'].invalid">
                                                  <small class="text-danger" *ngIf="caliMed.controls['481'].errors?.required">
                                                      required
                                                  </small>
                                              </div>
                                          </div>
                                          <div  *ngIf="caliMed.get('481').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('481').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('481').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('481').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('481').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                      </div>
                                    <div class="col-md-4">
                                    <label style="font-weight: normal;font-size: 14px">Date of Graduation (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="482" (blur)="clearInputIfInvalid('482')" ngbDatepicker #d8="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['482'].touched && caliMed.controls['482'].invalid) || (caliMed.controls['482'].invalid && isSubmit)"
                                            id="482" name="482" type="text" placeholder= "Grad Date of Graduation" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['482'].touched && caliMed.controls['482'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['482'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('482').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('482').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('482').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('482').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('482').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                   
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Medical/Professional School</label>
                                        <input class="form-control onboard" formControlName="1288"
                                            [class.is-invalid]="(caliMed.controls['1288'].touched && caliMed.controls['1288'].invalid) || (caliMed.controls['1288'].invalid && isSubmit)"
                                            id="1288" name="1288" type="text" placeholder= "Additional Med School" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1288'].touched && caliMed.controls['1288'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1288'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1289"
                                            [class.is-invalid]="(caliMed.controls['1289'].touched && caliMed.controls['1289'].invalid) || (caliMed.controls['1289'].invalid && isSubmit)"
                                            id="1289" name="1289" type="text" placeholder= "Additional Med School Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1289'].touched && caliMed.controls['1289'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1289'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1290"
                                            [class.is-invalid]="(caliMed.controls['1290'].touched && caliMed.controls['1290'].invalid) || (caliMed.controls['1290'].invalid && isSubmit)"
                                            id="1290" name="1290" type="text" placeholder= "Additional Med School Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1290'].touched && caliMed.controls['1290'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1290'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1291"
                                            [class.is-invalid]="(caliMed.controls['1291'].touched && caliMed.controls['1291'].invalid) || (caliMed.controls['1291'].invalid && isSubmit)"
                                            id="1291" name="1291" type="text" placeholder= "Additional Med School City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1291'].touched && caliMed.controls['1291'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1291'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1294"
                                            [class.is-invalid]="(caliMed.controls['1294'].touched && caliMed.controls['1294'].invalid) || (caliMed.controls['1294'].invalid && isSubmit)"
                                            id="1294" name="1294" type="text" placeholder= "Additional Med School Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1294'].touched && caliMed.controls['1294'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1294'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Degree Received</label>
                                        <div class="mb-3 selectDrop dropDrownSet">
                                            <ng-select [items]="degree" bindLabel="name" placeholder="Additional Select Degree" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['1298'].touched && caliMed.controls['1298'].invalid) || (caliMed.controls['1298'].invalid && isSubmit)"
                                                formControlName="1298">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['1298'].touched && caliMed.controls['1298'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['1298'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                      <label style="font-weight: normal;font-size: 14px">Start Date (mm/dd/yyyy)</label>
                                      <div class="input-group">
                                          <input class="form-control onboard datepicker" formControlName="1295" (blur)="clearInputIfInvalid('1295')" ngbDatepicker #d102="ngbDatepicker"
                                          [class.is-invalid]="(caliMed.controls['1295'].touched && caliMed.controls['1295'].invalid) || (caliMed.controls['1295'].invalid && isSubmit)"
                                          id="1295" name="1295" type="text" placeholder= "Select Start Date" data-id="managing_contact_name">
                                          <div class="input-group-append">
                                              <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                          </div>
                                      </div>
                                      <div  *ngIf="caliMed.get('1295').invalid ">
                                        <small class="text-danger" *ngIf="caliMed.get('1295').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="caliMed.get('1295').errors?.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="caliMed.get('1295').errors?.invalidDateyear">
                                          year is out of range(1900-2099)                      </small>
                                          <small class="text-danger" *ngIf="caliMed.get('1295').errors?.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>
                                      <div class="formLabel col-md-11"
                                          *ngIf="caliMed.controls['1295'].touched && caliMed.controls['1295'].invalid">
                                          <small class="text-danger" *ngIf="caliMed.controls['1295'].errors?.required">
                                              required
                                          </small>
                                      </div>
                                  </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Date of Graduation (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1297" (blur)="clearInputIfInvalid('1297')" ngbDatepicker #d9="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['1297'].touched && caliMed.controls['1297'].invalid) || (caliMed.controls['1297'].invalid && isSubmit)"
                                            id="1297" name="1297" type="text" placeholder= "Additional Med Date of Graduation" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('1297').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('1297').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1297').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1297').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1297').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1297'].touched && caliMed.controls['1297'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1297'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">III. WORK HISTORY (Send additional sheets to <u style="color: blue;">operations@bassmedicalgroup.com</u> if necessary. Please reference this section number and title.)
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                Chronologically list all work activities within the last five years. Curriculum vitae is sufficient, provided it is current and contains all information requested below. Please explain any gaps in professional work history.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Current Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="445"
                                        [class.is-invalid]="(caliMed.controls['445'].touched && caliMed.controls['445'].invalid) || (caliMed.controls['445'].invalid && isSubmit)"
                                        id="445" name="445" type="text" placeholder= "WorkHistory1 Location" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['445'].touched && caliMed.controls['445'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['445'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="454"   (blur)="clearInputIfInvalidMandi('454')" ngbDatepicker #d16="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['454'].touched && caliMed.controls['454'].invalid) || (caliMed.controls['454'].invalid && isSubmit)"
                                            id="454" name="454" type="text" placeholder= "WorkHistory1 Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['454'].invalid&&caliMed.controls['454'].touched) && (caliMed.controls['454'].value==''|| caliMed.controls['454'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('454').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('454').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('454').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('454').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="455"   (blur)="clearInputIfInvalidMandi('455')"
                                            [class.is-invalid]="(caliMed.controls['455'].touched && caliMed.controls['455'].invalid) || (caliMed.controls['455'].invalid && isSubmit)"
                                            ngbDatepicker #d17="ngbDatepicker" id="455" name="455" type="text" placeholder= "WorkHistory1 End Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['455'].invalid&&caliMed.controls['455'].touched) && (caliMed.controls['455'].value==''|| caliMed.controls['455'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('455').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('455').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('455').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('455').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="446"
                                        [class.is-invalid]="(caliMed.controls['446'].touched && caliMed.controls['446'].invalid) || (caliMed.controls['446'].invalid && isSubmit)"
                                        id="446" name="446" type="text" placeholder= "WorkHistory1 Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['446'].touched && caliMed.controls['446'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['446'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="447"
                                        [class.is-invalid]="(caliMed.controls['447'].touched && caliMed.controls['447'].invalid) || (caliMed.controls['447'].invalid && isSubmit)"
                                        id="447" name="447" type="text" placeholder= "WorkHistory1 Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['447'].touched && caliMed.controls['447'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['447'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="448"
                                        [class.is-invalid]="(caliMed.controls['448'].touched && caliMed.controls['448'].invalid) || (caliMed.controls['448'].invalid && isSubmit)"
                                        id="448" name="448" type="text" placeholder= "WorkHistory1 City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['448'].touched && caliMed.controls['448'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['448'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="451"
                                        [class.is-invalid]="(caliMed.controls['451'].touched && caliMed.controls['451'].invalid) || (caliMed.controls['451'].invalid && isSubmit)"
                                        id="451" name="451" type="text" placeholder= "WorkHistory1 Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['451'].touched && caliMed.controls['451'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['451'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory1 State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                             -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['450'].touched && caliMed.controls['450'].invalid) || (caliMed.controls['450'].invalid && isSubmit)"
                                                formControlName="450">
                                                </ng-select>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['450'].touched && caliMed.controls['450'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['450'].errors?.required">
                                                        required
                                                    </small>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="452"
                                        [class.is-invalid]="(caliMed.controls['452'].touched && caliMed.controls['452'].invalid) || (caliMed.controls['452'].invalid && isSubmit)"
                                        id="452" name="452" type="text" placeholder= "WorkHistory1 Contact" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['452'].touched && caliMed.controls['452'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['452'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="453"
                                        [class.is-invalid]="(caliMed.controls['453'].touched && caliMed.controls['453'].invalid) || (caliMed.controls['453'].invalid && isSubmit)"
                                        id="453" name="453" type="text" placeholder= "WorkHistory1 Telephone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['453'].touched && caliMed.controls['453'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['453'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['453'].errors?.required">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="889"
                                        [class.is-invalid]="(caliMed.controls['889'].touched && caliMed.controls['889'].invalid) || (caliMed.controls['889'].invalid && isSubmit)"
                                        id="889" name="889" type="text" placeholder= "WorkHistory1 Email" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['889'].touched && caliMed.controls['889'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['889'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1138"
                                        [class.is-invalid]="(caliMed.controls['1138'].touched && caliMed.controls['1138'].invalid) || (caliMed.controls['1138'].invalid && isSubmit)"
                                        id="1138" name="1138" type="text" placeholder= "Explanation for Gap in WorkHistory1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1138'].touched && caliMed.controls['1138'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1138'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Previous Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="456" id="456" name="456" type="text" placeholder= "WorkHistory2 Location " data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                            <div class="input-group">
                                                <input class="form-control onboard datepicker" formControlName="465" (blur)="clearInputIfInvalid('465')" ngbDatepicker #d18="ngbDatepicker"
                                                [class.is-invalid]="(caliMed.controls['465'].touched && caliMed.controls['465'].invalid) || (caliMed.controls['465'].invalid && isSubmit)"
                                                id="465" name="465" type="text" placeholder= "WorkHistory2 Start Date" data-id="managing_contact_name">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                                </div>
                                                <div class="formLabel col-md-11"
                                                    *ngIf="caliMed.controls['465'].touched && caliMed.controls['465'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['465'].errors?.required">
                                                    </small>
                                                </div>
                                            </div>
                                            <div  *ngIf="caliMed.get('465').invalid ">
                                                <small class="text-danger" *ngIf="caliMed.get('465').errors?.invalidDateFormat">
                                                  Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="caliMed.get('465').errors?.invalidMonth">
                                                Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="caliMed.get('465').errors?.invalidDateyear">
                                                  year is out of range(1900-2099)                      </small>
                                                  <small class="text-danger" *ngIf="caliMed.get('465').errors?.invalidDateRange">
                                                    date is out of range
                                                    </small>
                                                </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                            <div class="input-group">
                                                <input class="form-control onboard datepicker" formControlName="466" (blur)="clearInputIfInvalid('466')"
                                                [class.is-invalid]="(caliMed.controls['466'].touched && caliMed.controls['466'].invalid) || (caliMed.controls['466'].invalid && isSubmit)"
                                                ngbDatepicker #d19="ngbDatepicker" id="466" name="466" type="text" placeholder= "WorkHistory2 End Date" data-id="managing_contact_name">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                                </div>
                                                <div class="formLabel col-md-11"
                                                    *ngIf=" caliMed.controls['466'].touched && caliMed.controls['466'].invalid">
                                                    <small class="text-danger" *ngIf="caliMed.controls['466'].errors?.required">
                                                        
                                                    </small>
                                                </div>
                                            </div>
                                            <div  *ngIf="caliMed.get('466').invalid ">
                                                <small class="text-danger" *ngIf="caliMed.get('466').errors?.invalidDateFormat">
                                                  Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="caliMed.get('466').errors?.invalidMonth">
                                                Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="caliMed.get('466').errors?.invalidDateyear">
                                                  year is out of range(1900-2099)                      </small>
                                                  <small class="text-danger" *ngIf="caliMed.get('466').errors?.invalidDateRange">
                                                    date is out of range
                                                    </small>
                                                </div>
                                        </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="457" id="457" name="457" type="text" placeholder= "WorkHistory2 Address1" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="458" id="458" name="458" type="text" placeholder= "WorkHistory2 Address2" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="459" id="459" name="459" type="text" placeholder= "WorkHistory2 City" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="462" id="462" name="462" type="text" placeholder= "WorkHistory2 Zip" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory2 State</label>
                                        <!-- <div class="col-md-3" style="padding-top:30px">                                             -->
                                            <div class="mb-3 selectDrop dropDrownSet">
                                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                    formControlName="461">
                                                </ng-select>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="463" id="463" name="463" type="text" placeholder= "WorkHistory2 Contact" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="464"
                                        [class.is-invalid]="(caliMed.controls['464'].touched && caliMed.controls['464'].invalid) || (caliMed.controls['464'].invalid && isSubmit)"
                                        id="464" name="464" type="text" placeholder= "WorkHistory2 Telephone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['464'].touched && caliMed.controls['464'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['464'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['464'].errors?.pattern">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="891" id="891" name="891" type="text" placeholder= "WorkHistory2 Email" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1831" id="1831" name="1831" type="text" placeholder= "Explanation for Gap in WorkHistory2" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Previous Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="467" id="467" name="467" type="text" placeholder= "WorkHistory3 Location" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="476" (blur)="clearInputIfInvalid('476')" ngbDatepicker #d109="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['476'].touched && caliMed.controls['476'].invalid) || (caliMed.controls['476'].invalid && isSubmit)"
                                            id="476" name="476" type="text" placeholder= "WorkHistory3 Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d109.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['476'].touched && caliMed.controls['476'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['476'].errors?.required">
                                                </small>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('476').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('476').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('476').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('476').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('476').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="477" (blur)="clearInputIfInvalid('477')"
                                            [class.is-invalid]="(caliMed.controls['477'].touched && caliMed.controls['477'].invalid) || (caliMed.controls['477'].invalid && isSubmit)"
                                            ngbDatepicker #d209="ngbDatepicker" id="477" name="477" type="text" placeholder= "WorkHistory3 End Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d209.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            <div class="formLabel col-md-11"
                                                *ngIf=" caliMed.controls['477'].touched && caliMed.controls['477'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['477'].errors?.required">
                                                    
                                                </small>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('477').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('477').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('477').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('477').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('477').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="468" id="468" name="468" type="text" placeholder= "WorkHistory3 Address1" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="469" id="469" name="469" type="text" placeholder= "WorkHistory3 Address2" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="470" id="470" name="470" type="text" placeholder= "WorkHistory3 City" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="473" id="473" name="473" type="text" placeholder= "WorkHistory3 Zip" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory3 State</label>
                                        <div  style="padding-top:30px">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="472">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="474" id="474" name="474" type="text" placeholder= "WorkHistory3 Contact" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="475"
                                        [class.is-invalid]="(caliMed.controls['475'].touched && caliMed.controls['475'].invalid) || (caliMed.controls['475'].invalid && isSubmit)"
                                        id="475" name="475" type="text" placeholder= "WorkHistory3 Telephone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['475'].touched && caliMed.controls['475'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['475'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['475'].errors?.pattern">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="893" id="893" name="893" type="text" placeholder= "WorkHistory3 Email" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1832" id="1832" name="1832" type="text" placeholder= "Explanation for Gap in WorkHistory3" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Previous Practice/Employer:</label>
                                        <input class="form-control onboard" formControlName="1935" id="1935" name="1935" type="text" placeholder= "WorkHistory4 Location" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1947" (blur)="clearInputIfInvalid('1947')" ngbDatepicker #d89="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['1947'].touched && caliMed.controls['1947'].invalid) || (caliMed.controls['1947'].invalid && isSubmit)"
                                            id="1947" name="1947" type="text" placeholder= "WorkHistory4 Start Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d89.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['1947'].touched && caliMed.controls['1947'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['1947'].errors?.required">
                                                </small>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('1947').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('1947').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1947').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1947').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1947').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1948" (blur)="clearInputIfInvalid('1948')"
                                            [class.is-invalid]="(caliMed.controls['1948'].touched && caliMed.controls['1948'].invalid) || (caliMed.controls['1948'].invalid && isSubmit)"
                                            ngbDatepicker #d90="ngbDatepicker" id="1948" name="1948" type="text" placeholder= "WorkHistory4 End Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d90.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                            <div class="formLabel col-md-11"
                                                *ngIf=" caliMed.controls['1948'].touched && caliMed.controls['1948'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['1948'].errors?.required">
                                                    
                                                </small>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('1948').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('1948').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1948').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1948').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1948').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailling Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1936" id="1936" name="1936" type="text" placeholder= "WorkHistory4 Address1" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1937" id="1937" name="1937" type="text" placeholder= "WorkHistory4 Address2" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1938" id="1938" name="1938" type="text" placeholder= "WorkHistory4 City" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1941" id="1941" name="1941" type="text" placeholder= "WorkHistory4 Zip" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-right:10px;">WorkHistory4 State</label>
                                        <div>
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="1940">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                    <label style="font-weight: normal;font-size: 14px">Contact Name</label>
                                        <input class="form-control onboard" formControlName="1943" id="1943" name="1943" type="text" placeholder= "WorkHistory4 Contact" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Telephone Number</label>
                                        <input class="form-control onboard" formControlName="1944"
                                        [class.is-invalid]="(caliMed.controls['1944'].touched && caliMed.controls['1944'].invalid) || (caliMed.controls['1944'].invalid && isSubmit)"
                                        id="1944" name="1944" type="text" placeholder= "WorkHistory4 Telephone" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1944'].touched && caliMed.controls['1944'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1944'].errors?.required">
                                                phone is required and format is xxx-xxx-xxxx
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.controls['1944'].errors?.pattern">
                                                pattern xxx-xxx-xxxx
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px">Email Address</label>
                                        <input class="form-control onboard" formControlName="1946" id="1946" name="1946" type="text" placeholder= "WorkHistory4 Email" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label style="font-weight: normal;font-size: 14px">Please explain any gaps between this and previous employment</label>
                                        <input class="form-control onboard" formControlName="1953" id="1953" name="1953" type="text" placeholder= "Explanation for Gap in WorkHistory4" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">IV. PRACTICE INFORMATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Current Name Affiliated with Tax ID Number</label>
                                        <input class="form-control onboard" formControlName="cmd_100046"
                                        [class.is-invalid]="(caliMed.controls['cmd_100046'].touched && caliMed.controls['cmd_100046'].invalid) || (caliMed.controls['cmd_100046'].invalid && isSubmit)"
                                        id="cmd_100046" name="cmd_100046" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100046'].touched && caliMed.controls['cmd_100046'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100046'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Federal Tax ID Number</label>
                                        <input class="form-control onboard" formControlName="cmd_100047"
                                        [class.is-invalid]="(caliMed.controls['cmd_100047'].touched && caliMed.controls['cmd_100047'].invalid) || (caliMed.controls['cmd_100047'].invalid && isSubmit)"
                                        id="cmd_100047" name="cmd_100047" type="text" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100047'].touched && caliMed.controls['cmd_100047'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100047'].errors?.required">
                                            required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you intend to serve as a primary care provider?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_100015" id="cmd_100015" name="cmd_100015"
                                        [class.is-invalid]="(caliMed.controls['cmd_100015'].touched && caliMed.controls['cmd_100015'].invalid) || (caliMed.controls['cmd_100015'].invalid && isSubmit)"
                                        value="1">
                                        <label>Yes &nbsp;</label>
                                        <input type="radio" formControlName="cmd_100015" id="cmd_100015" name="cmd_100015"
                                        [class.is-invalid]="(caliMed.controls['cmd_100015'].touched && caliMed.controls['cmd_100015'].invalid) || (caliMed.controls['cmd_100015'].invalid && isSubmit)"
                                        value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100015'].touched && caliMed.controls['cmd_100015'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100015'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100015'].touched && caliMed.controls['cmd_100015'].invalid) || (caliMed.controls['cmd_100015'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you intend to serve as a specialist?</label>
                                    </div>
                                    
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_100016"
                                        (ngModelChange)="handleYesNoChanges('cmd_100016')"
                                            [class.is-invalid]="(caliMed.controls['cmd_100016'].touched && caliMed.controls['cmd_100016'].invalid) || (caliMed.controls['cmd_100016'].invalid && isSubmit)"
                                            id="cmd_100016" name="cmd_100016" value="1" >
                                        <label>Yes &nbsp;</label>
                                        <input type="radio" formControlName="cmd_100016"
                                        (ngModelChange)="handleYesNoChanges('cmd_100016')"
                                        [class.is-invalid]="(caliMed.controls['cmd_100016'].touched && caliMed.controls['cmd_100016'].invalid) || (caliMed.controls['cmd_100016'].invalid && isSubmit)"
                                            id="cmd_100016" name="cmd_100016" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100016'].touched && caliMed.controls['cmd_100016'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100016'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                            <span *ngIf="(caliMed.controls['cmd_100016'].touched && caliMed.controls['cmd_100016'].invalid) || (caliMed.controls['cmd_100016'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                            </span>
                                 </div>

                                 <div class="col-md-3">
                                    <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes, list specialty(ies)</label>
                                    <input class="form-control onboard" formControlName="cmd_100017"
                                        [class.is-invalid]="(caliMed.controls['cmd_100017'].invalid) || (caliMed.controls['cmd_100017'].invalid && isSubmit)"
                                        id="cmd_100017" name="cmd_100017" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_100016')" >
                                    <!-- <div class="formLabel col-md-11"
                                        *ngIf=" caliMed.controls['cmd_100019'].invalid">
                                        <small class="text-danger" *ngIf="caliMed.controls['cmd_100019'].errors?.required">
                                            required
                                        </small>
                                    </div> -->
                                    <span *ngIf="(  caliMed.controls['cmd_100017'].invalid) || (caliMed.controls['cmd_100017'].invalid && isSubmit)">
                                        <i class="fas fa-times ms-2 text-danger">required</i>
                                    </span>
                                </div>
                                         </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Is your practice limited to certain ages?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_100018"
                                        (ngModelChange)="handleYesNoChanges('cmd_100018')"
                                            [class.is-invalid]="(caliMed.controls['cmd_100018'].touched && caliMed.controls['cmd_100018'].invalid) || (caliMed.controls['cmd_100018'].invalid && isSubmit)"
                                            id="cmd_100018" name="cmd_100018" value="1" >
                                        <label>Yes &nbsp;</label>
                                        <input type="radio" formControlName="cmd_100018"
                                        (ngModelChange)="handleYesNoChanges('cmd_100018')"
                                        [class.is-invalid]="(caliMed.controls['cmd_100018'].touched && caliMed.controls['cmd_100018'].invalid) || (caliMed.controls['cmd_100018'].invalid && isSubmit)"
                                            id="cmd_100018" name="cmd_100018" value="0" >
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100018'].touched && caliMed.controls['cmd_100018'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100018'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                            <span *ngIf="(caliMed.controls['cmd_100018'].touched && caliMed.controls['cmd_100018'].invalid) || (caliMed.controls['cmd_100018'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                            </span>
                                 </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes, specify limitations</label>
                                        <input class="form-control onboard" formControlName="cmd_100019"
                                        [class.is-invalid]="(caliMed.controls['cmd_100019'].invalid) || (caliMed.controls['cmd_100019'].invalid && isSubmit)"
                                        id="cmd_100019" name="cmd_100019" type="text" data-id="managing_contact_name" (blur)="handleYesNoChanges('cmd_100018')" >
                                        <!-- <div class="formLabel col-md-11"
                                            *ngIf=" caliMed.controls['cmd_100019'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100019'].errors?.required">
                                                required
                                            </small>
                                        </div> -->
                                        <span *ngIf="(  caliMed.controls['cmd_100019'].invalid) || (caliMed.controls['cmd_100019'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger">required</i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you participate in EDI (electronic data interchange)?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_100020"

                                            id="cmd_100020" name="cmd_100020" value="1">
                                        <label>Yes &nbsp;</label>
                                        <input type="radio" formControlName="cmd_100020"

                                            id="cmd_100020" name="cmd_100020" value="0">
                                        <label>No</label>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes, which Network?</label>
                                        <input class="form-control onboard" formControlName="cmd_100021"
                                            id="cmd_100021" name="cmd_100021" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Do you use a practice management system/software?</label>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="radio" formControlName="cmd_100023"

                                            id="cmd_100023" name="cmd_100023" value="1">
                                        <label>Yes &nbsp;</label>
                                        <input type="radio" formControlName="cmd_100023"

                                            id="cmd_100023" name="cmd_100023" value="0">
                                        <label>No</label>

                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">If yes, which one?</label>
                                        <input class="form-control onboard" formControlName="cmd_100022"

                                            id="cmd_100022" name="cmd_100022" type="text" data-id="managing_contact_name">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">V. CURRENT HOSPITAL AND OTHER INSTITUTIONAL AFFILIATIONS </th>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                Please list in reverse chronological order (with the current affiliation[s] first) all institutions where you have current affiliations (A) and have
            had previous hospital privileges (B) during the past ten years. This includes hospitals, surgery centers, institutions, corporations, military
            assignments, or government agencies. If you do not have hospital privileges, please explain in the last question of this section
                                </p>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">CURRENT AFFILIATIONS (Attach additional sheets if necessary. Please reference this section number and title.)</th>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Primary Admitting Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="418"
                                            [class.is-invalid]="(caliMed.controls['418'].touched && caliMed.controls['418'].invalid) || (caliMed.controls['418'].invalid && isSubmit)"
                                            id="418" name="418" type="text" placeholder= "Hospital Affiliation Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['418'].touched && caliMed.controls['418'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['418'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="419"
                                            [class.is-invalid]="(caliMed.controls['419'].touched && caliMed.controls['419'].invalid) || (caliMed.controls['419'].invalid && isSubmit)"
                                            id="419" name="419" type="text" placeholder= "Hospital Affiliation Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['419'].touched && caliMed.controls['419'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['419'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="420"
                                            [class.is-invalid]="(caliMed.controls['420'].touched && caliMed.controls['420'].invalid) || (caliMed.controls['420'].invalid && isSubmit)"
                                            id="420" name="420" type="text" placeholder= "Hospital Affiliation Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['420'].touched && caliMed.controls['420'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['420'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="421"
                                            [class.is-invalid]="(caliMed.controls['421'].touched && caliMed.controls['421'].invalid) || (caliMed.controls['421'].invalid && isSubmit)"
                                            id="421" name="421" type="text" placeholder= "Hospital Affiliation City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['421'].touched && caliMed.controls['421'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['421'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation State</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['423'].touched && caliMed.controls['423'].invalid) || (caliMed.controls['423'].invalid && isSubmit)"
                                                formControlName="423">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['423'].touched && caliMed.controls['423'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['423'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation Zip</label>
                                        <input class="form-control onboard" formControlName="424"
                                            [class.is-invalid]="(caliMed.controls['424'].touched && caliMed.controls['424'].invalid) || (caliMed.controls['424'].invalid && isSubmit)"
                                            id="424" name="424" type="text" placeholder= "Hospital Affiliation Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['424'].touched && caliMed.controls['424'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['424'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Department</label>
                                        <input class="form-control onboard" formControlName="425"
                                            [class.is-invalid]="(caliMed.controls['425'].touched && caliMed.controls['425'].invalid) || (caliMed.controls['425'].invalid && isSubmit)"
                                            id="425" name="425" type="text" placeholder= "Hospital Affiliation Dep" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['425'].touched && caliMed.controls['425'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['425'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>                             -->
                                        <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="staffStatus" bindLabel="name" placeholder="Select StaffStatus" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['894'].touched && caliMed.controls['894'].invalid) || (caliMed.controls['894'].invalid && isSubmit)"
                                                formControlName="894">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['894'].touched && caliMed.controls['894'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['894'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Appointment Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="426"   (blur)="clearInputIfInvalidMandi('426')" ngbDatepicker #d21="ngbDatepicker"
                                                [class.is-invalid]="(caliMed.controls['426'].touched && caliMed.controls['426'].invalid) || (caliMed.controls['426'].invalid && isSubmit)"
                                                id="426" name="426" type="text" placeholder= "Hospital Affiliation InitialAppointDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['426'].invalid&&caliMed.controls['426'].touched) && (caliMed.controls['426'].value==''|| caliMed.controls['426'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('426').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('426').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('426').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('426').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                       
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name and Address of Other Admitting Hospital</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="427"
                                            [class.is-invalid]="(caliMed.controls['427'].touched && caliMed.controls['427'].invalid) || (caliMed.controls['427'].invalid && isSubmit)"
                                            id="427" name="427" type="text" placeholder= "Hospital2 Affiliation Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['427'].touched && caliMed.controls['427'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['427'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="428"
                                            [class.is-invalid]="(caliMed.controls['428'].touched && caliMed.controls['428'].invalid) || (caliMed.controls['428'].invalid && isSubmit)"
                                            id="428" name="428" type="text" placeholder= "Hospital2 Affiliation Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['428'].touched && caliMed.controls['428'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['428'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="429"
                                            [class.is-invalid]="(caliMed.controls['429'].touched && caliMed.controls['429'].invalid) || (caliMed.controls['429'].invalid && isSubmit)"
                                            id="429" name="429" type="text" placeholder= "Hospital2 Affiliation Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['429'].touched && caliMed.controls['429'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['429'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="430"
                                            [class.is-invalid]="(caliMed.controls['430'].touched && caliMed.controls['430'].invalid) || (caliMed.controls['430'].invalid && isSubmit)"
                                            id="430" name="430" type="text" placeholder= "Hospital2 Affiliation City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['430'].touched && caliMed.controls['430'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['430'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation State</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['432'].touched && caliMed.controls['432'].invalid) || (caliMed.controls['432'].invalid && isSubmit)"
                                                formControlName="432">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['432'].touched && caliMed.controls['432'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['432'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Hospital Affiliation Zip</label>
                                        <input class="form-control onboard" formControlName="433"
                                            [class.is-invalid]="(caliMed.controls['433'].touched && caliMed.controls['433'].invalid) || (caliMed.controls['433'].invalid && isSubmit)"
                                            id="433" name="433" type="text" placeholder= "Hospital2 Affiliation Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['433'].touched && caliMed.controls['433'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['433'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Department</label>
                                        <input class="form-control onboard" formControlName="434"
                                            [class.is-invalid]="(caliMed.controls['434'].touched && caliMed.controls['434'].invalid) || (caliMed.controls['434'].invalid && isSubmit)"
                                            id="434" name="434" type="text" placeholder= "Hospital2 Affiliation Dep" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['434'].touched && caliMed.controls['434'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['434'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>                             -->
                                        <label style="font-weight: normal;font-size: 14px">Status (active, provisional, courtesy)</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="staffStatus" bindLabel="name" placeholder="Select StaffStatus" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['895'].touched && caliMed.controls['895'].invalid) || (caliMed.controls['895'].invalid && isSubmit)"
                                                formControlName="895">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['895'].touched && caliMed.controls['895'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['895'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Appointment Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="435" (blur)="clearInputIfInvalid('435')" ngbDatepicker #d22="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['435'].touched && caliMed.controls['435'].invalid) || (caliMed.controls['435'].invalid && isSubmit)"
                                            id="435" name="435" type="text" placeholder= "Hospital2 Affiliation InitialAppointDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('435').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('435').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('435').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('435').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('435').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['435'].touched && caliMed.controls['435'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['435'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr style="background-color:#000000;">
                            <th style="color: white;text-align:left;">VI. PROFESSIONAL LIABILITY. Please send copies of professional liability policy or certification face sheet to <u style="color: blue;">operations@bassmedicalgroup.com</u></th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Current Insurance Carrier</label>
                                        <input class="form-control onboard" formControlName="345"
                                        [class.is-invalid]="(caliMed.controls['345'].touched && caliMed.controls['345'].invalid) || (caliMed.controls['345'].invalid && isSubmit)"
                                        id="345" name="345" type="text" placeholder= "MLP Carrier Name" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['345'].touched && caliMed.controls['345'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['345'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Policy Number</label>
                                        <input class="form-control onboard" formControlName="346"
                                        [class.is-invalid]="(caliMed.controls['346'].touched && caliMed.controls['346'].invalid) || (caliMed.controls['346'].invalid && isSubmit)"
                                        id="346" name="346" type="text" placeholder= "MLP Policy Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['346'].touched && caliMed.controls['346'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['346'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Original Effective Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="348"    (blur)="clearInputIfInvalidMandi('348')" ngbDatepicker #d87="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['348'].touched && caliMed.controls['348'].invalid) || (caliMed.controls['348'].invalid && isSubmit)"
                                            id="348" name="348" type="text" placeholder= "MLP issue date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d87.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['348'].invalid&&caliMed.controls['348'].touched) && (caliMed.controls['348'].value==''|| caliMed.controls['348'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('348').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('348').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('348').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('348').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Mailing Address</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="901"
                                        [class.is-invalid]="(caliMed.controls['901'].touched && caliMed.controls['901'].invalid) || (caliMed.controls['901'].invalid && isSubmit)"
                                        id="901" name="901" type="text" placeholder= "MLP Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['901'].touched && caliMed.controls['901'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['901'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="902"
                                        [class.is-invalid]="(caliMed.controls['902'].touched && caliMed.controls['902'].invalid) || (caliMed.controls['902'].invalid && isSubmit)"
                                        id="902" name="902" type="text" placeholder= "MLP Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['902'].touched && caliMed.controls['902'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['902'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="906"
                                        [class.is-invalid]="(caliMed.controls['906'].touched && caliMed.controls['906'].invalid) || (caliMed.controls['906'].invalid && isSubmit)"
                                        id="906" name="906" type="text" placeholder= "MLP ZIP" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['906'].touched && caliMed.controls['906'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['906'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="903"
                                        [class.is-invalid]="(caliMed.controls['903'].touched && caliMed.controls['903'].invalid) || (caliMed.controls['903'].invalid && isSubmit)"
                                        id="903" name="903" type="text" placeholder= "MLP City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['903'].touched && caliMed.controls['903'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['903'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP State</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                            [class.is-invalid]="(caliMed.controls['905'].touched && caliMed.controls['905'].invalid) || (caliMed.controls['905'].invalid && isSubmit)"
                                            formControlName="905">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['905'].touched && caliMed.controls['905'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['905'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP PerCliam </label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="preClaim" bindLabel="name" placeholder="Select PreClaim" bindValue="id"
                                            [class.is-invalid]="(caliMed.controls['350'].touched && caliMed.controls['350'].invalid) || (caliMed.controls['350'].invalid && isSubmit)"
                                            formControlName="350">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['350'].touched && caliMed.controls['350'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['350'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">MLP Aggregate</label>
                                        <div class="mb-3 selectDropTwo">
                                            <ng-select [items]="aggregates" bindLabel="name" placeholder="Select Aggregate" bindValue="id"
                                            [class.is-invalid]="(caliMed.controls['351'].touched && caliMed.controls['351'].invalid) || (caliMed.controls['351'].invalid && isSubmit)"
                                            formControlName="351">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['351'].touched && caliMed.controls['351'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['351'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Expiration Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="349"   (blur)="clearInputIfInvalidMandi('349')"
                                            [class.is-invalid]="(caliMed.controls['349'].touched && caliMed.controls['349'].invalid) || (caliMed.controls['349'].invalid && isSubmit)"
                                            ngbDatepicker #d31="ngbDatepicker" id="349" name="349" type="text" placeholder= "MLP ExiprationDate" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                          
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['349'].invalid&&caliMed.controls['349'].touched) && (caliMed.controls['349'].value==''|| caliMed.controls['349'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('349').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('349').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('349').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('349').errors?.invalidDateRange">
                                              date is out of range
                                              </small>

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabSideHeading">
                            <th class="tabMainHeading">Please list all your professional liability carriers within the past 7 years, other than the one listed above</th>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px">Name of Carrier and Address of Carrier</label>
                                <div class="row">
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1242"
                                            [class.is-invalid]="(caliMed.controls['1242'].touched && caliMed.controls['1242'].invalid) || (caliMed.controls['1242'].invalid && isSubmit)"
                                            id="1242" name="1242" type="text" placeholder= "Previous MLP Carriers" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1242'].touched && caliMed.controls['1242'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1242'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1244"
                                            [class.is-invalid]="(caliMed.controls['1244'].touched && caliMed.controls['1244'].invalid) || (caliMed.controls['1244'].invalid && isSubmit)"
                                            id="1244" name="1244" type="text" placeholder= "Previous MLP Address1" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1244'].touched && caliMed.controls['1244'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1244'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1245"
                                            [class.is-invalid]="(caliMed.controls['1245'].touched && caliMed.controls['1245'].invalid) || (caliMed.controls['1245'].invalid && isSubmit)"
                                            id="1245" name="1245" type="text" placeholder= "Previous MLP Address2" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1245'].touched && caliMed.controls['1245'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1245'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <input class="form-control onboard" formControlName="1246"
                                            [class.is-invalid]="(caliMed.controls['1246'].touched && caliMed.controls['1246'].invalid) || (caliMed.controls['1246'].invalid && isSubmit)"
                                            id="1246" name="1246" type="text" placeholder= "Previous MLP City" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1246'].touched && caliMed.controls['1246'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1246'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Previous MLP State</label>
                                        <div class="mb-3 selectDrop">
                                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                                [class.is-invalid]="(caliMed.controls['1248'].touched && caliMed.controls['1248'].invalid) || (caliMed.controls['1248'].invalid && isSubmit)"
                                                formControlName="1248">
                                            </ng-select>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['1248'].touched && caliMed.controls['1248'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['1248'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label style="font-weight: normal;font-size: 14px;padding-top:15px"></label>
                                        <input class="form-control onboard" formControlName="1249"
                                            [class.is-invalid]="(caliMed.controls['1249'].touched && caliMed.controls['1249'].invalid) || (caliMed.controls['1249'].invalid && isSubmit)"
                                            id="1249" name="1249" type="text" placeholder= "Previous MLP Zip" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1249'].touched && caliMed.controls['1249'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1249'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">Policy No</label>
                                        <input class="form-control onboard" formControlName="1256"
                                            [class.is-invalid]="(caliMed.controls['1256'].touched && caliMed.controls['1256'].invalid) || (caliMed.controls['1256'].invalid && isSubmit)"
                                            id="1256" name="1256" type="text" placeholder= "Previous MLP Policy Number" data-id="managing_contact_name">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1256'].touched && caliMed.controls['1256'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1256'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">From (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1252" (blur)="clearInputIfInvalid('1252')" ngbDatepicker #d35="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['1252'].touched && caliMed.controls['1252'].invalid) || (caliMed.controls['1252'].invalid && isSubmit)"
                                            id="1252" name="1252" type="text" placeholder= "Previous MLP Effective Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('1252').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('1252').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1252').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1252').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1252').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1252'].touched && caliMed.controls['1252'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1252'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label style="font-weight: normal;font-size: 14px">To (mm/yyyy)</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="1253" (blur)="clearInputIfInvalid('1253')" ngbDatepicker #d36="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['1253'].touched && caliMed.controls['1253'].invalid) || (caliMed.controls['1253'].invalid && isSubmit)"
                                            id="1253" name="1253" type="text" placeholder= "Previous MLP Exipration Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div  *ngIf="caliMed.get('1253').invalid ">
                                            <small class="text-danger" *ngIf="caliMed.get('1253').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1253').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1253').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1253').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['1253'].touched && caliMed.controls['1253'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['1253'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">VII. ATTESTATION QUESTIONS</th>
                        </tr>
                        <tr>
                            <td>
                                <p><b>
                                    Please answer the following questions "yes" or "no". If your answer to questions A through L is "yes" or if your answer to M & N is "no", please provide full details on a separate document and email to <u style="color: blue;">operations@bassmedicalgroup.com.</u>
                                </b>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Has your license to practice medicine in any jurisdiction, your Drug Enforcement Administration (DEA) registration
                    or any applicable narcotic registration in any jurisdiction ever been denied, limited, restricted, suspended, revoked,
                    not renewed, or subject to probationary conditions, or have you voluntarily or involuntarily relinquished any such
                    license or registration or voluntarily or involuntarily accepted any such actions or conditions, or have you been fined
                    or received a letter of reprimand or is such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100024"
                                        [class.is-invalid]="(caliMed.controls['cmd_100024'].touched && caliMed.controls['cmd_100024'].invalid) || (caliMed.controls['cmd_100024'].invalid && isSubmit)"
                                        id="cmd_100024" name="cmd_100024" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100024'].touched && caliMed.controls['cmd_100024'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100024'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100024"
                                        [class.is-invalid]="(caliMed.controls['cmd_100024'].touched && caliMed.controls['cmd_100024'].invalid) || (caliMed.controls['cmd_100024'].invalid && isSubmit)"id="cmd_100024" name="cmd_100024" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100024'].touched && caliMed.controls['cmd_100024'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100024'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100024'].touched && caliMed.controls['cmd_100024'].invalid) || (caliMed.controls['cmd_100024'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            B. Have you ever been charged, suspended, fined, disciplined, or otherwise sanctioned, subjected to probationary
            conditions, restricted or excluded, or have you voluntarily or involuntarily relinquished eligibility to provide
            services or accepted conditions on your eligibility to provide services, for reasons relating to possible incompetence
            or improper professional conduct, or breach of contract or program conditions, by Medicare, Medicaid, or any
            public program, or is any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100025"
                                        [class.is-invalid]="(caliMed.controls['cmd_100025'].touched && caliMed.controls['cmd_100025'].invalid) || (caliMed.controls['cmd_100025'].invalid && isSubmit)"
                                        id="cmd_100025" name="cmd_100025" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100025'].touched && caliMed.controls['cmd_100025'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100025'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100025"
                                        [class.is-invalid]="(caliMed.controls['cmd_100025'].touched && caliMed.controls['cmd_100025'].invalid) || (caliMed.controls['cmd_100025'].invalid && isSubmit)"
                                        id="cmd_100025" name="cmd_100025" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100025'].touched && caliMed.controls['cmd_100025'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100025'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100025'].touched && caliMed.controls['cmd_100025'].invalid) || (caliMed.controls['cmd_100025'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            C. Have your clinical privileges, membership, contractual participation or employment by any medical organization
            (e.g. hospital medical staff, medical group, independent practice association (IPA), health plan, health maintenance
            organization (HMO), preferred provider organization (PPO), private payer (including those that contract with public
            programs), medical society, professional association, medical school faculty position or other health delivery entity
            or system), ever been denied, suspended, restricted, reduced, subject to probationary conditions, revoked or not
            renewed for possible incompetence, improper professional conduct or breach of contract, or is any such action
            pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100026"
                                        [class.is-invalid]="(caliMed.controls['cmd_100026'].touched && caliMed.controls['cmd_100026'].invalid) || (caliMed.controls['cmd_100026'].invalid && isSubmit)"
                                        id="cmd_100026" name="cmd_100026" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100026'].touched && caliMed.controls['cmd_100026'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100026'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100026"
                                        [class.is-invalid]="(caliMed.controls['cmd_100026'].touched && caliMed.controls['cmd_100026'].invalid) || (caliMed.controls['cmd_100026'].invalid && isSubmit)"
                                        id="cmd_100026" name="cmd_100026" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100026'].touched && caliMed.controls['cmd_100026'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100026'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100026'].touched && caliMed.controls['cmd_100026'].invalid) || (caliMed.controls['cmd_100026'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            D. Have you ever surrendered, allowed to expire, voluntarily or involuntarily withdrawn a request for membership or
            clinical privileges, terminated contractual participation or employment, or resigned from any medical organization
            (e.g. hospital medical staff, medical group, independent practice association (IPA), health plan, health maintenance
            organization (HMO), preferred provider organization (PPO), medical society, professional association, medical
            school faculty position or other health delivery entity or system) while under investigation for possible
            incompetence or improper professional conduct, or breach of contract, or in return for such an investigation not
            being conducted, or is any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100027"
                                        [class.is-invalid]="(caliMed.controls['cmd_100027'].touched && caliMed.controls['cmd_100027'].invalid) || (caliMed.controls['cmd_100027'].invalid && isSubmit)"
                                        id="cmd_100027" name="cmd_100027" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100027'].touched && caliMed.controls['cmd_100027'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100027'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100027"
                                        [class.is-invalid]="(caliMed.controls['cmd_100027'].touched && caliMed.controls['cmd_100027'].invalid) || (caliMed.controls['cmd_100027'].invalid && isSubmit)"
                                        id="cmd_100027" name="cmd_100027" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100027'].touched && caliMed.controls['cmd_100027'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100027'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100027'].touched && caliMed.controls['cmd_100027'].invalid) || (caliMed.controls['cmd_100027'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            E. Have you ever surrendered, voluntarily withdrawn, or been requested or compelled to relinquish your status as a
            student in good standing in any internship, residency, fellowship, preceptorship, or other clinical education
            program?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100028"
                                        [class.is-invalid]="(caliMed.controls['cmd_100028'].touched && caliMed.controls['cmd_100028'].invalid) || (caliMed.controls['cmd_100028'].invalid && isSubmit)"
                                        id="cmd_100028" name="cmd_100028" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100028'].touched && caliMed.controls['cmd_100028'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100028'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100028"
                                        [class.is-invalid]="(caliMed.controls['cmd_100028'].touched && caliMed.controls['cmd_100028'].invalid) || (caliMed.controls['cmd_100028'].invalid && isSubmit)"
                                        id="cmd_100028" name="cmd_100028" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100028'].touched && caliMed.controls['cmd_100028'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100028'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100028'].touched && caliMed.controls['cmd_100028'].invalid) || (caliMed.controls['cmd_100028'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            F. Has your membership or fellowship in any local, county, state, regional, national, or international professional
            organization ever been revoked, denied, reduced, limited, subjected to probationary conditions, or not renewed, or is
            any such action pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100029"
                                        [class.is-invalid]="(caliMed.controls['cmd_100029'].touched && caliMed.controls['cmd_100029'].invalid) || (caliMed.controls['cmd_100029'].invalid && isSubmit)"
                                        id="cmd_100029" name="cmd_100029" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100029'].touched && caliMed.controls['cmd_100029'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100029'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100029"
                                        [class.is-invalid]="(caliMed.controls['cmd_100029'].touched && caliMed.controls['cmd_100029'].invalid) || (caliMed.controls['cmd_100029'].invalid && isSubmit)"
                                        id="cmd_100029" name="cmd_100029" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100029'].touched && caliMed.controls['cmd_100029'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100029'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100029'].touched && caliMed.controls['cmd_100029'].invalid) || (caliMed.controls['cmd_100029'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            G. Have you been denied certification/recertification by a specialty board, or has your eligibility, certification or
            recertification status changed (other than changing from eligible to certified)?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100030"
                                        [class.is-invalid]="(caliMed.controls['cmd_100030'].touched && caliMed.controls['cmd_100030'].invalid) || (caliMed.controls['cmd_100030'].invalid && isSubmit)"
                                        id="cmd_100030" name="cmd_100030" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100030'].touched && caliMed.controls['cmd_100030'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100030'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100030"
                                        [class.is-invalid]="(caliMed.controls['cmd_100030'].touched && caliMed.controls['cmd_100030'].invalid) || (caliMed.controls['cmd_100030'].invalid && isSubmit)"
                                        id="cmd_100030" name="cmd_100030" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100030'].touched && caliMed.controls['cmd_100030'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100030'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100030'].touched && caliMed.controls['cmd_100030'].invalid) || (caliMed.controls['cmd_100030'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            H. Have you ever been convicted of any crime (other than a minor traffic violation)?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100031"
                                        [class.is-invalid]="(caliMed.controls['cmd_100031'].touched && caliMed.controls['cmd_100031'].invalid) || (caliMed.controls['cmd_100031'].invalid && isSubmit)"
                                        id="cmd_100031" name="cmd_100031" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100031'].touched && caliMed.controls['cmd_100031'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100031'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100031"
                                        [class.is-invalid]="(caliMed.controls['cmd_100031'].touched && caliMed.controls['cmd_100031'].invalid) || (caliMed.controls['cmd_100031'].invalid && isSubmit)"
                                        id="cmd_100031" name="cmd_100031" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100031'].touched && caliMed.controls['cmd_100031'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100031'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100031'].touched && caliMed.controls['cmd_100031'].invalid) || (caliMed.controls['cmd_100031'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            I. Do you presently use any drugs illegally?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100032"
                                        [class.is-invalid]="(caliMed.controls['cmd_100032'].touched && caliMed.controls['cmd_100032'].invalid) || (caliMed.controls['cmd_100032'].invalid && isSubmit)"
                                        id="cmd_100032" name="cmd_100032" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100032'].touched && caliMed.controls['cmd_100032'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100032'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100032"
                                        [class.is-invalid]="(caliMed.controls['cmd_100032'].touched && caliMed.controls['cmd_100032'].invalid) || (caliMed.controls['cmd_100032'].invalid && isSubmit)"
                                        id="cmd_100032" name="cmd_100032" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100032'].touched && caliMed.controls['cmd_100032'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100032'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100032'].touched && caliMed.controls['cmd_100032'].invalid) || (caliMed.controls['cmd_100032'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                                J. Do you have any ongoing physical or mental impairment or condition which would make you unable, with or
            without reasonable accommodation, to perform the essential functions of a practitioner in your area of practice or
            unable to perform those essential functions without a direct threat to the health and safety of others? Please describe
            any accommodations that could reasonably be made to facilitate your performance of such functions without risk of
            compromise
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100033"
                                        [class.is-invalid]="(caliMed.controls['cmd_100033'].touched && caliMed.controls['cmd_100033'].invalid) || (caliMed.controls['cmd_100033'].invalid && isSubmit)"
                                        id="cmd_100033" name="cmd_100033" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100033'].touched && caliMed.controls['cmd_100033'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100033'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100033"
                                        [class.is-invalid]="(caliMed.controls['cmd_100033'].touched && caliMed.controls['cmd_100033'].invalid) || (caliMed.controls['cmd_100033'].invalid && isSubmit)"
                                        id="cmd_100033" name="cmd_100033" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100033'].touched && caliMed.controls['cmd_100033'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100033'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100033'].touched && caliMed.controls['cmd_100033'].invalid) || (caliMed.controls['cmd_100033'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            K. Have any judgments been entered against you or settlements been agreed to by you within the last seven (7) years,
            in professional liability cases, or are there any filed and served professional liability lawsuits/arbitrations against
            you pending?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100034"
                                        [class.is-invalid]="(caliMed.controls['cmd_100034'].touched && caliMed.controls['cmd_100034'].invalid) || (caliMed.controls['cmd_100034'].invalid && isSubmit)"
                                        id="cmd_100034" name="cmd_100034" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100034'].touched && caliMed.controls['cmd_100034'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100034'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100034"
                                        [class.is-invalid]="(caliMed.controls['cmd_100034'].touched && caliMed.controls['cmd_100034'].invalid) || (caliMed.controls['cmd_100034'].invalid && isSubmit)"
                                        id="cmd_100034" name="cmd_100034" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100034'].touched && caliMed.controls['cmd_100034'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100034'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100034'].touched && caliMed.controls['cmd_100034'].invalid) || (caliMed.controls['cmd_100034'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                        L. Has your professional liability insurance ever been terminated, not renewed, restricted, or modified (e.g. reduced
            limits, restricted coverage, surcharged) or have you ever been denied professional liability insurance, or has any
            professional liability carrier provided you with written notice of any intent to deny, cancel, not renew, or limit your
            professional liability insurance or its coverage of any procedures?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100035"
                                        [class.is-invalid]="(caliMed.controls['cmd_100035'].touched && caliMed.controls['cmd_100035'].invalid) || (caliMed.controls['cmd_100035'].invalid && isSubmit)"
                                        id="cmd_100035" name="cmd_100035" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100035'].touched && caliMed.controls['cmd_100035'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100035'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100035"
                                        [class.is-invalid]="(caliMed.controls['cmd_100035'].touched && caliMed.controls['cmd_100035'].invalid) || (caliMed.controls['cmd_100035'].invalid && isSubmit)"
                                        id="cmd_100035" name="cmd_100035" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100035'].touched && caliMed.controls['cmd_100035'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100035'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100035'].touched && caliMed.controls['cmd_100035'].invalid) || (caliMed.controls['cmd_100035'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            M. Is your professional liability insurance valid and current?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100036"
                                        [class.is-invalid]="(caliMed.controls['cmd_100036'].touched && caliMed.controls['cmd_100036'].invalid) || (caliMed.controls['cmd_100036'].invalid && isSubmit)"
                                        id="cmd_100036" name="cmd_100036" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100036'].touched && caliMed.controls['cmd_100036'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100036'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100036"
                                        [class.is-invalid]="(caliMed.controls['cmd_100036'].touched && caliMed.controls['cmd_100036'].invalid) || (caliMed.controls['cmd_100036'].invalid && isSubmit)"
                                        id="cmd_100036" name="cmd_100036" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100036'].touched && caliMed.controls['cmd_100036'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100036'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100036'].touched && caliMed.controls['cmd_100036'].invalid) || (caliMed.controls['cmd_100036'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                        N. Are you able to perform all the services required by your agreement with, or the professional staff bylaws of, the
            Healthcare Organization to which you are applying, with or without reasonable accommodation, according to
            accepted standards of professional performance and without posing a direct threat to the safety of patients?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100037"
                                        [class.is-invalid]="(caliMed.controls['cmd_100037'].touched && caliMed.controls['cmd_100037'].invalid) || (caliMed.controls['cmd_100037'].invalid && isSubmit)"
                                        id="cmd_100037" name="cmd_100037" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100037'].touched && caliMed.controls['cmd_100037'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100037'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100037"
                                        [class.is-invalid]="(caliMed.controls['cmd_100037'].touched && caliMed.controls['cmd_100037'].invalid) || (caliMed.controls['cmd_100037'].invalid && isSubmit)"
                                        id="cmd_100037" name="cmd_100037" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100037'].touched && caliMed.controls['cmd_100037'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100037'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100037'].touched && caliMed.controls['cmd_100037'].invalid) || (caliMed.controls['cmd_100037'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">VIII. PROFESSIONAL LIABILITY ACTION EXPLANATION</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Do you have a pending/settled claim to report in the past five years?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100038"
                                        [class.is-invalid]="(caliMed.controls['cmd_100038'].touched && caliMed.controls['cmd_100038'].invalid) || (caliMed.controls['cmd_100038'].invalid && isSubmit)"
                                        id="cmd_100038" name="cmd_100038" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100038'].touched && caliMed.controls['cmd_100038'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100038'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100038"
                                        [class.is-invalid]="(caliMed.controls['cmd_100038'].touched && caliMed.controls['cmd_100038'].invalid) || (caliMed.controls['cmd_100038'].invalid && isSubmit)"
                                         id="cmd_100038" name="cmd_100038" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100038'].touched && caliMed.controls['cmd_100038'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100038'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100038'].touched && caliMed.controls['cmd_100038'].invalid) || (caliMed.controls['cmd_100038'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="tabhead">
                            <th class="tabTh">IX. ADDITIONAL QUESTIONS</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            A. Do you have board certifications? If so, please attach.
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100039"
                                        [class.is-invalid]="(caliMed.controls['cmd_100039'].touched && caliMed.controls['cmd_100039'].invalid) || (caliMed.controls['cmd_100039'].invalid && isSubmit)"
                                        id="cmd_100039" name="cmd_100039" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100039'].touched && caliMed.controls['cmd_100039'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100039'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100039"
                                        [class.is-invalid]="(caliMed.controls['cmd_100039'].touched && caliMed.controls['cmd_100039'].invalid) || (caliMed.controls['cmd_100039'].invalid && isSubmit)"
                                        id="cmd_100039" name="cmd_100039" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100039'].touched && caliMed.controls['cmd_100039'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100039'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100039'].touched && caliMed.controls['cmd_100039'].invalid) || (caliMed.controls['cmd_100039'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            B. Do you have any other certifications? If so, please attach
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100040"
                                        [class.is-invalid]="(caliMed.controls['cmd_100040'].touched && caliMed.controls['cmd_100040'].invalid) || (caliMed.controls['cmd_100040'].invalid && isSubmit)"
                                        id="cmd_100040" name="cmd_100040" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100040'].touched && caliMed.controls['cmd_100040'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100040'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100040"
                                        [class.is-invalid]="(caliMed.controls['cmd_100040'].touched && caliMed.controls['cmd_100040'].invalid) || (caliMed.controls['cmd_100040'].invalid && isSubmit)"
                                        id="cmd_100040" name="cmd_100040" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100040'].touched && caliMed.controls['cmd_100040'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100040'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100040'].touched && caliMed.controls['cmd_100040'].invalid) || (caliMed.controls['cmd_100040'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            C. Do you have any other state licenses? If so, please attach
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100041"
                                        [class.is-invalid]="(caliMed.controls['cmd_100041'].touched && caliMed.controls['cmd_100041'].invalid) || (caliMed.controls['cmd_100041'].invalid && isSubmit)"
                                        id="cmd_100041" name="cmd_100041" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100041'].touched && caliMed.controls['cmd_100041'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100041'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100041"
                                        [class.is-invalid]="(caliMed.controls['cmd_100041'].touched && caliMed.controls['cmd_100041'].invalid) || (caliMed.controls['cmd_100041'].invalid && isSubmit)"
                                        id="cmd_100041" name="cmd_100041" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100041'].touched && caliMed.controls['cmd_100041'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100041'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100041'].touched && caliMed.controls['cmd_100041'].invalid) || (caliMed.controls['cmd_100041'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            D. Are you a certified Worker’s Compensation provider?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100042"
                                        [class.is-invalid]="(caliMed.controls['cmd_100042'].touched && caliMed.controls['cmd_100042'].invalid) || (caliMed.controls['cmd_100042'].invalid && isSubmit)"
                                        id="cmd_100042" name="cmd_100042" value="1">
                                        <label>Yes&nbsp;</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100042'].touched && caliMed.controls['cmd_100042'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100042'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <input type="radio" formControlName="cmd_100042"
                                        [class.is-invalid]="(caliMed.controls['cmd_100042'].touched && caliMed.controls['cmd_100042'].invalid) || (caliMed.controls['cmd_100042'].invalid && isSubmit)"
                                        id="cmd_100042" name="cmd_100042" value="0">
                                        <label>No</label>
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_100042'].touched && caliMed.controls['cmd_100042'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_100042'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_100042'].touched && caliMed.controls['cmd_100042'].invalid) || (caliMed.controls['cmd_100042'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-9">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            E. Are you a reservist?
                                        </label>
                                    </div>
                                    <div class="col-md-2">
                                        <input type="radio" formControlName="cmd_100043"
                                            [class.is-invalid]="(caliMed.controls['cmd_100043'].touched && caliMed.controls['cmd_100043'].invalid) || (caliMed.controls['cmd_100043'].invalid && isSubmit)"
                                            id="cmd_100043" name="cmd_100043" value="1" (ngModelChange)="handleYesNoChanges('cmd_100043')">
                                        <label>Yes&nbsp;</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['cmd_100043'].touched && caliMed.controls['cmd_100043'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['cmd_100043'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                        <input type="radio" formControlName="cmd_100043"
                                            [class.is-invalid]="(caliMed.controls['cmd_100043'].touched && caliMed.controls['cmd_100043'].invalid) || (caliMed.controls['cmd_100043'].invalid && isSubmit)"
                                            id="cmd_100043" name="cmd_100043" value="0" (ngModelChange)="handleYesNoChanges('cmd_100043')">
                                        <label>No</label>
                                            <div class="formLabel col-md-11"
                                                *ngIf="caliMed.controls['cmd_100043'].touched && caliMed.controls['cmd_100043'].invalid">
                                                <small class="text-danger" *ngIf="caliMed.controls['cmd_100043'].errors?.required">
                                                    required
                                                </small>
                                            </div>
                                            <span *ngIf="(caliMed.controls['cmd_100043'].touched && caliMed.controls['cmd_100043'].invalid) || (caliMed.controls['cmd_100043'].invalid && isSubmit)">
                                                <i class="fas fa-times ms-2 text-danger"></i>
                                            </span>
                                    </div>
                                    <div class="col-md-5">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                            If yes, which branch?
                                        </label>
                                        <input class="form-control onboard" formControlName="1141"  (blur)="handleYesNoChanges('cmd_100043')"
                                        [class.is-invalid]="caliMed.controls['1141'].invalid"
                                            id="1141" name="1141" type="text" data-id="1141">
                                            <div class="mandatory" *ngIf="caliMed.controls['1141'].invalid">
                                                <small >
                                                required
                                                </small>
                                              </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px">Anticipated date of separation from reserve duty?</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" (blur)="clearInputIfInvalid('1145','cmd_100043')" formControlName="1145" ngbDatepicker #d103="ngbDatepicker" 
                                            [class.is-invalid]="(caliMed.controls['1145'].invalid) || (caliMed.controls['1145'].invalid && isSubmit)"
                                                id="1145" name="1145" type="text" placeholder= "Active Duty Discharge Date " data-id="1145">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="mandatory" *ngIf="caliMed.controls['1145'].invalid && (caliMed.controls['1145'].value==''|| caliMed.controls['1145'].value==null) " >
                                            <small >
                                            required
                                            </small>
                          
                                        </div>
                                          <div  *ngIf="caliMed.get('1145').invalid">
                                            <small class="text-danger" *ngIf="caliMed.get('1145').errors.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1145').errors.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="caliMed.get('1145').errors.invalidDateyear">
                                              year is out of range(1900-2099)                    </small>
                                              <small class="text-danger" *ngIf="caliMed.get('1145').errors.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">
                                    <b> Current copies of the following documents must be sent to <u style="color: blue;">operations@bassmedicalgroup.com.</u></b>
                                </label>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- State Medical License(s)</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Driver’s License</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Face Sheet of Professional Liability Certification</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Board Certification (if applicable)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- DEA Certificate</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Life Support Documents</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Curriculum Vitae in Month/Year (MM/YYYY) format for all Education & Work History</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- ECFMG Certificate (if applicable)</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- Master’s Diploma in Nursing if applicable
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px;padding-left:20px;text-align:block;">- National Certification if applicable</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class ="row">
                                    <div class="col-md-12">
                                        <p>I hereby affirm that the information submitted in this Section VII, Attestation Questions, and any addenda thereto is true, current, correct, and
                                        complete to the best of my knowledge and belief an is furnished in good faith. I understand that material, omissions or misrepresentations may result
                                        in denial of my application or termination of my privileges, employment or physician participation agreement.</p>
                                    </div>
                                </div>
                                <div class ="row">
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Provider Name</label>
                                        <input class="form-control onboard" formControlName="cmd_100044" id="cmd_100044" name="cmd_100044" type="text" placeholder= "Provider Name" data-id="managing_contact_name">
                                    </div>
                                    <div class="col-md-6">
                                        <label style="font-weight: normal;font-size: 14px">Date</label>
                                        <div class="input-group">
                                            <input class="form-control onboard datepicker" formControlName="cmd_100045"   (blur)="clearInputIfInvalidMandi('cmd_100045')" ngbDatepicker #d41="ngbDatepicker"
                                            [class.is-invalid]="(caliMed.controls['cmd_100045'].touched && caliMed.controls['cmd_100045'].invalid) || (caliMed.controls['cmd_100045'].invalid && isSubmit)"
                                            id="cmd_100045" name="cmd_100045" type="text" placeholder= "Date" data-id="managing_contact_name">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div class="text-danger" *ngIf="(caliMed.controls['cmd_100045'].invalid&&caliMed.controls['cmd_100045'].touched) && (caliMed.controls['cmd_100045'].value==''|| caliMed.controls['cmd_100045'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_100045').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_100045').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_100045').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('cmd_100045').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                                    </div>
                                </div>
                                <div class="row" style="padding-left:20px">
                                    <div class="col-md-3" >
                                        <label style="font-weight: normal;font-size: 15px">Signature:</label>
                                    </div>
                                    <div class="col-md-4" >
                                        <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">
                                        <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">
                                        <input type="hidden" name="cmd_00050"
                                        [class.is-invalid]="(caliMed.controls['cmd_00050'].touched && caliMed.controls['cmd_00050'].invalid) || (caliMed.controls['cmd_00050'].invalid && isSubmit)"
                                        formControlName="cmd_00050" id="cmd_00050">
                                        <div class="formLabel col-md-11"
                                            *ngIf="caliMed.controls['cmd_00050'].touched && caliMed.controls['cmd_00050'].invalid">
                                            <small class="text-danger" *ngIf="caliMed.controls['cmd_00050'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <span *ngIf="(caliMed.controls['cmd_00050'].touched && caliMed.controls['cmd_00050'].invalid) || (caliMed.controls['cmd_00050'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr style="border:2px solid black;text-align:center">
                            <th>INFORMATION RELEASE/ACKNOWLEDGMENTS </th>
                        </tr>
                        <tr>
                            <td>
                        
                                <label for="">
                                    I hereby consent to the disclosure, inspection and copying of information and documents relating to my
                                    credentials, qualifications and I hereby consent to
                                    the disclosure, inspection and copying of information and documents relating to my credentials,
                                    qualifications and performance ("credentialing
                                    information") by and between "this Healthcare Organization" and other Healthcare Organizations (e.g.,
                                    hospital medical staffs, medical groups,
                                    independent practice associations&#123;IPAs&#125;, -health maintenance organizations &#123;HMOs&#125;,
                                    preferred
                                    provider
                                    organizations &#123;PPOs&#125;, other health delivery
                                    systems or entities, medical societies, professional associations, medical school faculty positions,
                                    training programs, professional liability insurance
                                    companies &#123;with respect to certification of coverage and claim history&#125;, licensing authorities,
                                    and
                                    businesses and individuals acting as their agents
                                    collectively "Healthcare Organizations,") for the purpose of evaluating this application and any
                                    recredentialing application regarding my professional
                                    training, experience, character, conduct and judgment, ethics and ability to work with others. In this
                                    regard, the utmost care shall be taken to safeguard the
                                    privacy of patients and the confidentiality of patient records, and to protect credentialing information
                                    from being further disclosed.
                                </label>
                        
                        
                        
                                <label for="">
                                    I am informed and acknowledge that federal and state laws provide immunity protections to certain
                                    individuals and entities for their acts and/or
                                    communications in connection with evaluating the qualifications of healthcare providers. I hereby release
                                    all persons and entities, including this Healthcare
                                    Organization, engaged in quality assessment, peer review and credentialing on behalf of this Healthcare
                                    Organization, and all persons and entities providing
                                    credentialing information to such representatives of this Healthcare Organization, from any liability they
                                    might incur for their acts and/or communications
                                    in connection with evaluation of my qualifications for participation in this Healthcare Organization, to the
                                    extent that those acts and/or communications are
                                    protected by state or federal law.
                                </label>
                                <label for="">
                                    I understand that I shall be afforded such fair procedures with respect to my participation in this
                                    Healthcare Organization as may be required by state and
                                    federal law and regulation, including but not limited to, California Business and Professional Code Section
                                    809 <u>et seq</u>, if applicable.
                                </label>
                                <label>
                                    I the undersigned and agree that I, as an applicant, have the burden of producing adequate information for
                                    proper evaluation of my professional
                                    competence, character, ethics and other qualifications and for resolving any doubt about such
                                    qualifications.
                                </label>
                                <label for="">
                                    During such time as this application is being processed, I agree to update the application should there be any change in the information provided
                                </label>
                                <br />
                                <label for="">
                                    In addition to any notice required by any contract with a Healthcare Organization, I agree to notify this Healthcare
                                    Organization immediately in writing of
                                    the occurrence of any of the following: (i) the unstayed suspension, revocation or nonrenewal of my license to
                                    practice medicine in California; (ii) any
                                    suspension, revocation or nonrenewal of my DEA or other controlled substances registration; or (iii) any
                                    cancellation or nonrenewal of my professional
                                    liability insurance coverage.
                                </label>
                                <label for="">
                                    I further agree to notify this Healthcare Organization in writing, promptly and no later than fourteen (14) calendar
                                    days from the occurrence of any of the
                                    following: (i) receipt of written notice of any adverse action against me by the Medical Board of California taken
                                    or pending, including but not limited to,
                                    any accusation filed, temporary restraining order, or imposition of any interim suspension, probation or limitations
                                    affecting my license to practice
                                    medicine; or (ii) any adverse action against me by any Healthcare Organization which has resulted in the filing of a
                                    Section 805 report with the Medical
                                    Board of California, or a report with the National Practitioner Data Bank; or (iii) the denial, revocation,
                                    suspension reduction, limitation, nonrenewal or
                                    voluntary relinquishment by registration of my medical staff membership or clinical privileges at any Healthcare
                                    Organization; or (iv) any material
                                    reduction in my professional liability insurance coverage; or (v) my receipt of written notice of any legal action
                                    against me, including, without limitation,
                                    ant filed and served malpractice suite or arbitration action; or (vi) my conviction of any criminal law (excluding
                                    minor traffic violations); or (vii) my receipt
                                    of written notice of any adverse action against me under the Medicare or Medicaid programs, including, but not
                                    limited to, fraud and abuse proceedings or
                                    convictions.
                                </label>
                                <label for="">
                                    I hereby affirm that the information submitted in this application and any addenda thereto (including my curriculum
                                    vitae if attached) is true, current,
                                    correct, and complete to the best of my knowledge and belief and is furnished in good faith. I understand that
                                    material omissions or misrepresentations
                                    may result in denial of my application or termination of my privileges, employment or physician participation
                                    agreement. A photocopy of this document
                                    shall be as effective as the original, however, original signatures and current dates are required on pages 7 and 8.
                                </label>
                            </td>
                        </tr>
                        <tr>
                            
                            <td colspan="12">
                        
                                <div class="row">
                        
                                    <div class="col-2 pe-0"> <label ><b>Print Name here:</b></label></div>
                        
                                    <div class="col-4 ps-0">
                        
                                        <input type="text" placeholder=" Printed Name" id="cmd_43554" [class.is-invalid]="(caliMed.controls['cmd_43554'].touched &&
                                                        
                                                        caliMed.controls['cmd_43554'].invalid) ||
                                                        
                                                        (caliMed.controls['cmd_43554'].invalid && isSubmit)" formControlName="cmd_43554"
                                            class="form-control">
                        
                                        <div class="invalid-feedback"
                                            *ngIf="(caliMed.controls['cmd_43554'].touched && caliMed.controls['cmd_43554'].invalid) || (caliMed.controls['cmd_43554'].invalid && isSubmit)">
                                            Required </div>
                        
                                    </div>
                                </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                <div class="row" >
                        
                                    <div class="col-1 pe-0"> <label > <b>Signature:</b></label></div>
                        
                                    <div class="col-5 ps-0">
                        
                                        <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                            (click)="esignOpenTwo()" />
                        
                                        <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                                            (click)="esignOpenTwo()" /><br><span> <b>(Stamped Signature Is Not Acceptable)</b>
                        
                                        </span>
                        
                                        <input type="hidden" placeholder=" Provider Signature" name="cmd_355" value="{{finalImgPathTwo}}"
                                            id="cmd_355" class="form-control" formControlName="cmd_355"
                                            [class.is-invalid]="(caliMed.controls['cmd_355'].touched && caliMed.controls['cmd_355'].invalid ) || (caliMed.controls['cmd_355'].invalid && isSubmit)">
                                            <span
                                            *ngIf="(caliMed.controls['cmd_355'].touched && caliMed.controls['cmd_355'].invalid) || (caliMed.controls['cmd_355'].invalid && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                        <div class="invalid-feedback"
                                            *ngIf="(caliMed.controls['cmd_355'].touched && caliMed.controls['cmd_355'].invalid) || (caliMed.controls['cmd_355'].invalid && isSubmit)">
                        
                                            Provider Signature is required and cannot be empty
                        
                                        </div>
                        
                                    </div>
                        
                        
                                    <div class="col-1 pe-0"> <label ><b>Date: </b></label></div>
                        
                                    <div class="col-5 ps-0 ">
                                        <div class="input-group">
                                        
                                        <input type="text" placeholder=" date picker" id="cmd_10354" (blur)="clearInputIfInvalidMandi('cmd_10354')" [class.is-invalid]="(caliMed.controls['cmd_10354'].touched &&
                                                        
                                                        caliMed.controls['cmd_10354'].invalid) ||
                                                        
                                                        (caliMed.controls['cmd_10354'].invalid && isSubmit)" formControlName="cmd_10354"
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #idh101="ngbDatepicker">
                        
                                        <div class="input-group-append">
                        
                                            <button class="btn btn-outline-secondary calendar" (click)="idh101.toggle()" type="button">
                        
                                                <i class="fas fa-calendar-alt"></i>
                        
                                            </button>
                        
                                        </div>
                                        </div>
                                        
                                        <div class="text-danger" *ngIf="(caliMed.controls['cmd_10354'].invalid&&caliMed.controls['cmd_10354'].touched) && (caliMed.controls['cmd_10354'].value==''|| caliMed.controls['cmd_10354'].value==null) " >
                                            <small >
                                            required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_10354').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_10354').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="caliMed.get('cmd_10354').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="caliMed.get('cmd_10354').errors?.invalidDateRange">
                                              date is out of range
                                              </small>
                        
                                    </div>
                        
                                  
                        
                                    
                        
                                </div>
                                <br>
                                <label for="" style="font-size: 10px;">
                                    The intent of this release is to apply at a minimum, protection comparable to those available in California to any action, regardless of where such action is brought. 
                                </label>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <label for=""><b> Addenda Submitting (Please check the following):</b></label>
                                        <div class="row">
                                            <div class="col-1"><input type="checkbox" id="cmd_35512" name="cmd_35512" formControlName="cmd_35512"
                                                value="1" /></div>
                                            <div class="col-11">
                                                <label for=""> <b >  Addendum A - Health Plan and IPA/Medical Group</b></label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1"><input type="checkbox" id="cmd_35513" name="cmd_35513" formControlName="cmd_35513"
                                                value="1" /></div>
                                            <div class="col-11">
                                                <label for=""><b> Addendum B - Professional Liability Action Explanation </b></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label for="">This Application and Addenda A and B were created and are endorsed by:</label>
                                        <div class="row">
                                            <ul>
                                                <li>American Medical Group Association - (310/430-1191x223)</li>
                                                <li>California Association of Health Plans - (916/552-2910)</li>
                                                <li>California Healthcare Association - (916/552-7574)</li>
                                                <li>California Medical Association - (415/882-3368)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <label for="">
                                    Individual healthcare organizations may request additional information or attach supplements to this form. They are
                                    not part of the
                                    California Participation Physician Application nor have they been endorsed by the above organizations. Any questions
                                    about
                                    supplements should be addressed to the healthcare organization from which it was provided.
                                </label>
                        
                            </td>
                        
                        </tr>
                        <tr style="border:2px solid black;">
                            <th style="text-align:center">HOSPITAL SERVICES CORPORATION  
                            <br>
                            <label for="">CREDENTIALS VERIFICATION SERVICE  </label>
                            <br>
                            <label for="" style="text-align: left;">DESIGNATION AND AUTHORIZATION FOR RELEASE AND REDISCLOSURE OF INFORMATION </label>
                            <br>
                            <label for="">(“Release”)  </label>
                        </th>
                        
                        </tr>
                        <tr>
                            <td>
                                <label for=""><b>Authority to Release: </b></label> I have applied to participate as a provider for <input
                                    type="text" id="cmd_35514" name="cmd_35514"  formControlName="cmd_35514" class="form-control">
                                <br>
                                <label for="" style="display: block; text-align: center;">

                                   <b> Print the names of all organizations to which you are applying.</b>
                                  </label>
                                <label for="">and its authorized representatives (hereafter “Health Care Entity”) which has designated Hospital
                                    Services
                                    Corporation’s Credentials Verification Service (“HSC”) as their agent. I consent to complete disclosure by the
                                    recipient of this release to HSC of all relevant information pertaining to my professional qualifications, moral
                                    character, physical and mental health (hereinafter “qualifications”). I authorize the recipient to make
                                    available
                                    and/or disclose to HSC all such information in its files from any university, professional school, licensing
                                    authority, accreditation board, hospital, physician, dentist, professional society, insurance carrier, law
                                    enforcement agency, military service, or any other person or entity deemed necessary or appropriate in the
                                    investigation and processing of my application. </label>
                                <label for="">
                                    I request and authorize the recipient to release the requested information and I expressly waive any claim of
                                    privilege or privacy with respect to the released information bearing on my admission to, retention or
                                    termination
                                    of medical staff appointment or clinical privileges. I release and discharge HSC, the Health Care Entity and the
                                    medical, dental, podiatry and ancillary staffs or panels, credentials committees, administrators, review and
                                    approval boards or committees, governing boards, whether or not designated by these titles, and their agents,
                                    servants or employees authorized by representatives and all other persons or entities supplying information to
                                    them from liability or claims of any kind or character in any way arising out of inquiries concerning me or
                                    disclosures made in good faith in connection with my application for appointment to the Health Care Entity’s
                                    Medical Staff or Provider Panel.
                                </label>
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap ">
                                    <label class="formLabel" for=""><b>Authority to Redisclose: Unless I have denied authority by initialing here</b></label>
                                    <input type="text" class="form-control" id="cmd_35515" name="cmd_35515"  formControlName="cmd_35515"  style="display: inline-block; width: 880px;">
                                    <span style="display: inline-block;">, I authorize the Health
                                    Care Entity, the Health Care Entity’s Authorized Representatives, and HSC to redisclose information concerning
                                    my qualifications, or credentials and privileges to third parties who have a need to know the information (1)
                                    based upon state or federal laws or regulations, or (2) pursuant to any health care provider agreement to which
                                    I am or will be a party and in which I have an interest as an individual health care provider, or (3) to participate
                                    in the common recredentials program, if applicable.</span>
                                </div>
                                
                                <label for="">
                                    This Release does not authorize HSC to disclose information about my qualifications to any claimant. If a
                                    claimant requests information from HSC about me or if a subpoena duces tecum is served upon HSC seeking
                                    information about me, which is in HSC’s possession, I understand I will be notified immediately. If I direct HSC
                                    to resist the subpoena, I hereby agree to indemnify and hold harmless HSC, its officers, directors, employees
                                    and agents for all attorney fees, costs, fines, and expenses incurred in resisting the subpoena at my request.
                                </label>
                                <label for="">
                                    This authorization is limited to the acquisition and disclosure of information required by state or federal law, and
                                    information which is acquired or disclosed pursuant to activities protected by the state’s Review Organizational
                                    Immunity Act and the Health Care Quality Improvement Act of 1986. A photocopy of this Designation and
                                    Authorization for release and redisclosure of information shall be considered by the recipient to be a signed
                                    original, as long as it is transmitted to the recipient by HSC and is received within five years of its date.
                                </label>

                                <label for="">
                                    <b>The certain definitions used in this Release and set forth on the following page of this application are
                                        incorporated by reference. I understand that I may withdraw or modify this authorization at any time in
                                        writing by submitting a written request to HSC. PHOTOCOPY BOTH PAGES OF THIS FORM.</b>
                                </label>
                                <label for="" style="display: block; text-align: center;">

                                    <b> Signature stamps and date stamps are not acceptable. </b>
                                   </label>
                                   <br>
<div class="row">

    <!-- <div class="col-2 pe-0"> <label>Print Name here:</label></div>

    <div class="col-4 ps-0">

        <input type="text" placeholder=" Printed Name" id="cmd_435541"
            [class.is-invalid]="(caliMed.controls['cmd_435541'].touched &&
                                                                                        
                                                                                        caliMed.controls['cmd_435541'].invalid) ||
                                                                                        
                                                                                        (caliMed.controls['cmd_435541'].invalid && isSubmit)" formControlName="cmd_435541"
            class="form-control">

        <div class="invalid-feedback"
            *ngIf="(caliMed.controls['cmd_435541'].touched && caliMed.controls['cmd_435541'].invalid) || (caliMed.controls['cmd_435541'].invalid && isSubmit)">
            Required </div>

    </div> -->
    <div class="col-2 pe-0"> <label> <b> Applicant Signature :</b></label></div>

    <div class="col-4 ps-0">

        <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
            (click)="esignOpenThree()" />

        <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
            (click)="esignOpenThree()" /><br><span> (Stamped Signature Is Not Acceptable)

        </span>

        <input type="hidden" placeholder=" Provider Signature" name="cmd_3551" value="{{finalImgPathThree}}"
            id="cmd_3551" class="form-control" formControlName="cmd_3551"
            [class.is-invalid]="(caliMed.controls['cmd_3551'].touched && caliMed.controls['cmd_3551'].invalid ) || (caliMed.controls['cmd_3551'].invalid && isSubmit)">
        <span
            *ngIf="(caliMed.controls['cmd_3551'].touched && caliMed.controls['cmd_3551'].invalid) || (caliMed.controls['cmd_3551'].invalid && isSubmit)">
            <i class="fas fa-times ms-2 text-danger"></i>
        </span>
        <div class="invalid-feedback"
            *ngIf="(caliMed.controls['cmd_3551'].touched && caliMed.controls['cmd_3551'].invalid) || (caliMed.controls['cmd_3551'].invalid && isSubmit)">

            Provider Signature is required and cannot be empty

        </div>

    </div>
</div>
<br>
<br>
<br>
<br>
<div class="row">

    <div class="col-1 pe-0"> <label> <b > Print Name:</b></label></div>

    <div class="col-5 ps-0">
        <input type="text" placeholder=" Printed Name" id="cmd_435541"
        [class.is-invalid]="(caliMed.controls['cmd_435541'].touched &&
                                                                                    
                                                                                    caliMed.controls['cmd_435541'].invalid) ||
                                                                                    
                                                                                    (caliMed.controls['cmd_435541'].invalid && isSubmit)" formControlName="cmd_435541"
        class="form-control">

    <div class="invalid-feedback"
        *ngIf="(caliMed.controls['cmd_435541'].touched && caliMed.controls['cmd_435541'].invalid) || (caliMed.controls['cmd_435541'].invalid && isSubmit)">
        Required </div>
       

    </div>


    <div class="col-1 pe-0"> <label><b>  Date:</b></label></div>

    <div class="col-5 ps-0 ">
        <div class="input-group">

            <input type="text" placeholder=" date picker" id="cmd_103541" (blur)="clearInputIfInvalidMandi('cmd_103541')"
                [class.is-invalid]="(caliMed.controls['cmd_103541'].touched &&
                                                                                        
                                                                                        caliMed.controls['cmd_103541'].invalid) ||
                                                                                        
                                                                                        (caliMed.controls['cmd_103541'].invalid && isSubmit)" formControlName="cmd_103541"
                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh1012="ngbDatepicker">

            <div class="input-group-append">

                <button class="btn btn-outline-secondary calendar" (click)="idh1012.toggle()" type="button">

                    <i class="fas fa-calendar-alt"></i>

                </button>

            </div>
        </div>

        <div class="text-danger" *ngIf="(caliMed.controls['cmd_103541'].invalid&&caliMed.controls['cmd_103541'].touched) && (caliMed.controls['cmd_103541'].value==''|| caliMed.controls['cmd_103541'].value==null) " >
            <small >
            required
            </small>
          
          </div>
          <small class="text-danger" *ngIf="caliMed.get('cmd_103541').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)                </small>
          <small class="text-danger" *ngIf="caliMed.get('cmd_103541').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="caliMed.get('cmd_103541').errors?.invalidDateyear">
            year is out of range(1900-2099)                  </small>
            <small class="text-danger" *ngIf="caliMed.get('cmd_103541').errors?.invalidDateRange">
              date is out of range
              </small>

    </div>





</div>
<br>
<label for="" style="display: block; text-align: center;">

    <b>Please fax or e-mail this completed form to:  </b>

   </label>
   <label for="" style="display: block; text-align: center;">

    Hospital Services Corporation  
   </label>
   <label for="" style="display: block; text-align: center;">

    Credentials Verification Services 
   </label>
   <label for="" style="display: block; text-align: center;">

    Toll Free: (866) 908-0070 
   </label>
   <label for="" style="display: block; text-align: center;">
    Facsimile: (505) 346-0288
   </label>
   <label for="" style="display: block; text-align: center;">
    Email:<span style="color: rgb(18, 18, 248);"><u> Credentialing@nmhsc.com</u></span>
   </label>
                            
                            </td>
                         
                              
                        </tr>
                    </tbody>
                </table>
                
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-end"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
    </div>
</div>
