import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-med-spring',
    templateUrl: './med-spring.component.html',
    styleUrls: ['./med-spring.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class MedSpringComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private notiService: ToasterNotiService,
    private onboardService: OnboardingformsServiceService
  ) {}

  public medSpring: UntypedFormGroup;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Input() fieldsData: any[];
  @Output() onClose = new EventEmitter();
  @Input() formData;
  isSubmit: boolean = false;
  credentials: Array<object> = [];
  states: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  boardCertification: Array<object> = [];
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  ngOnInit(): void {
    this.buildForm();
    this.getCredentialsData();
    this.getStatesData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getBoardCertificationData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
    }
    this.medSpring.patchValue(fieldsCtrls);
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 14,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  buildForm() {
    this.medSpring = this.fb.group({
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      822: [null, [Validators.required]],
      30: ['1'],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null],
      291: ['', [Validators.required]],
      32: ['', [Validators.required]],
      709: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      16: [''],
      17: [''],
      18: [''],
      20: [null],
      21: ['', [Validators.minLength(5), Validators.maxLength(10)]],
      1634: ['', [Validators.required]],
      293: ['', [Validators.required]],
      cmd_0400: [''],
      309: [''],
      337: [''],
      cmd_0401: [''],
      554: [null],
      1169: [''],
      560:[null,[this.dateFormatValidator]],
      2108: [''],
      2109: [''],
      333: ['', [Validators.required]],
      334: ['', [Validators.required]],
      335: ['', [Validators.required]],
      503: [''],
      511:[null,[this.dateFormatValidator]],
      479: [''],
      482: [null,[this.dateFormatValidator]],
      490: [''],
      499: [null,[this.dateFormatValidator]],
      cmd_0424: [''],
      529: [''],
      537: [null,[this.dateFormatValidator]],
      538: [null,[this.dateFormatValidator]],
      445: [''],
      454: [null,[this.dateFormatValidator]],
      455: [null,[this.dateFormatValidator]],
      456: [''],
      465: [null,[this.dateFormatValidator]],
      466: [null,[this.dateFormatValidator]],
      467: [''],
      476: [null,[this.dateFormatValidator]],
      477:[null,[this.dateFormatValidator]],
      1138: [''],
      1831: [''],
      1832: [''],
      1953: [''],
      345: [''],
      346: [''],
      901: [''],
      902: [''],
      903: [''],
      905: [null],
      906: [''],
      1266: [null, [Validators.pattern('^[0-9]*$')]],
      1273: [null, [Validators.pattern('^[0-9]*$')]],
      348:  [null,[this.dateFormatValidator]],
      349:  [null,[this.dateFormatValidator]],
      cmd_0426: [''],
      350: [null],
      351: [null],
      1242: [''],
      1256: [''],
      1244: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: [''],
      1254: [null, [Validators.pattern('^[0-9]*$')]],
      1255: [null, [Validators.pattern('^[0-9]*$')]],
      1252: [null,[this.dateFormatValidator]],
      1253: [null,[this.dateFormatValidator]],
      cmd_0425: [''],
      1264: [null],
      1265: [null]
    });
    // console.log(this.medSpring);
  }

  saveForm(type) {
    // console.log(this.medSpring);
    this.isSubmit = true;
    if (type == 'submit') {
      if (this.medSpring.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.medSpring.value
        };
        // console.log(data);
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.medSpring.value,
        formId: 14
      };
      // console.log(data);
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  public getCredentialsData() {
    let params: {};
    ////this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  close() {
    this.onClose.emit('close modal');
  }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    
    if (dateString && !dateString.match(dateFormat)) {
    return { 'invalidDateFormat': true };
    }
   
   
   
    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
     const year = parseInt(dateParts[2]);
   
    // Check if any of the date components are zero or month is not between 1 and 12
  
    const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  
   
    if (month < 1 || month > 12 || day === 0 || year === 0) {
    return { 'invalidMonth': true };
  }
   if (month === 1 || month > 2) {
    if (day > ListofDays[month - 1]) {
   return { 'invalidDateRange': true };
    }
    } else if (month === 2) {
    const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    if (!leapYear && day >= 29) {
   return { 'invalidDateRange': true };
    }
   else if (leapYear && day > 29) {
    return { 'invalidDateRange': true };
   }
   }
   if (year < 1900 || year > 2099) {
   return { 'invalidDateyear': true };
  }
   
 
   }
   
    return null;
   }
   clearInputIfInvalidMandi(controlName: string) {
    const control = this.medSpring.get(controlName);
    const dateString = control.value;
     
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
     
     if (validationErrors) {
     // If any of the validation conditions are met, clear the input field and set errors to null
     control.setValue('');
     
   }}
   clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.medSpring.get(controlName);
    const dateString = control.value;
   
    // Use the dateFormatValidator to check if the date is valid
   const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    if (validationErrors) {
    // If any of the validation conditions are met, clear the input field and set errors to null
   control.setValue('');
     control.setErrors(null);
     }
    //     if(handleYesNoValue ){
    //       if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //         this.handleYesNoChanges(handleYesNoValue);
    //       }
    //       else{
    //           this.handleNAchange(handleYesNoValue);
    //       }
    //       
    //       
    //     }
     
     }
}
