import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { CommonService } from '../../services/common/common.service';
import { MaskInputDirective } from '../../SharedComponents/MaskInputDirective.directive';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'ctg-status-update-modal',
    templateUrl: './status-update-modal.component.html',
    styleUrls: ['./status-update-modal.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, NgSelectModule, NgIf, NgbDatepickerModule, MaskInputDirective]
})
export class StatusUpdateModalComponent implements OnInit {
  modalResponse: any = {
    type: "close"
  }
  @Input() popupType;
  @Input() subStatusId;
  @Input() FlagForSubstatus;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public statusDropdown: Array<object> = [];
  public substatusDropdown:any;
  public status = null;
  public subStatus = null;
  public date = null;
  public effDate = null;
  public providerNumber;
  public readOnlyUser:boolean=false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {
    // console.log(this.popupType);
    // console.log(this.subStatusId);
    // console.log(this.FlagForSubstatus,"flagstatus");
    this.statusDropdown = this.popupType.dropdown;
    this.substatusDropdown = this.subStatusId;
    this.status = this.popupType.selected;
    this.subStatus=this.popupType.row?.substatus_id;
    this.effDate=this.popupType.row.eff_date;
    this.providerNumber=this.popupType.row.provider_number;
    if(this.popupType.date){
      // console.log(this.popupType.row);
      this.date=this.popupType.row.date
    }
    this.commonService.currentUserPermission.subscribe(data => {
      if (data) {
        if(data && data.permissions==1){
          this.readOnlyUser = true
        }
      }
    }
    )
  }

  onSave() {
    this.modalResponse = {
      type: "save",
      status: this.status,
      subStatus:this.subStatus
    }
    if(this.popupType.date)
    {
      this.modalResponse.date=this.date;
    }
    if(this.status == 4){
      this.modalResponse.date = this.effDate;
      this.modalResponse.provider_number = this.providerNumber;
    }

    this.passEntry.emit(this.modalResponse)
    // this.activeModal.close(this.modalResponse)
  }
}
