import { Component, OnInit,Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { CreateTaskService } from '../../services/create-task/create-task.service';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';
import { CommonService, File_Error } from '../../services/common/common.service';
import { AsyncSubject, Observable } from 'rxjs';
import { MaskInputDirective } from '../../SharedComponents/MaskInputDirective.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgClass, NgIf } from '@angular/common';
export interface SelectedFiles {
  fileName: string;
  fileSize : Number;
  base64?: string;
}

@Component({
    selector: 'ctg-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, ReactiveFormsModule, NgClass, NgIf, NgSelectModule, NgbDatepickerModule, MaskInputDirective]
})
export class CreateTaskComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('FileToUpload') FileToUpload:ElementRef;

  public userId:any;
  public customerId:any;
  public submitted = false;
  public TaskForm:UntypedFormGroup;
  public modalResponse:object={
    type:"close"
  };
  public showSubject:boolean=false;
  public Sub=null
  public  subjectDropdownData:Array<object>=[];
  public payerDropdownData:Array<object>=[];
  public tinDropdownData:Array<object>=[];
  public showCat:boolean=false;
  public CategoryDropdownData:Array<object>=[];
  public priorityDropdownData:Array<object>=[
    {
      "id":1,
      "name":"High"
    },
    {
      "id":2,
      "name":"Medium"
    },
    {
      "id":3,
      "name":"Low"
    }
  ];
  public selectLabel:string=''
  public drop_category:Array<any>=[];
  public subStatusDropdownData:Array<object>=[];
  public statusDropdownData:Array<object>=[];
  public assignToDropdown:Array<object>=[];
  public dueDate: NgbDateStruct;
  public formInfo;
  public selectedFile:any=[];
  public selectedFiles : SelectedFiles[] = [];
  public date;
  public catValidator: boolean = false;
  public readOnlyUser:boolean=false;
  public handleproviderId:boolean=false;
  public AffliationsDropdownData:Array<object>=[];
  public handpnull:boolean=false;
  public handpnull2:boolean=false;
  public addpassport:boolean=false;
  public selectedAffliationValue:Array<object>=[];
  public UserPermission:any
  public applyPayerToProvider:any;
  public multipleSelection:boolean =true;
  public disableUploadFile:boolean = false;
  public disableTin:boolean = false;

  @Input() popupType
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private CreateTaskService:CreateTaskService,
    private notiService: ToasterNotiService,
    private commonService: CommonService,
    private calendar: NgbCalendar,
    private storageService:StorageService
    ) {}

  ngOnInit(): void {
    this.userId= Number(this.storageService.getItem('userId'))
    this.customerId=Number(this.storageService.getItem('customerId'))
    this.UserPermission = this.storageService.getItem('userPermission') 
    this.applyPayerToProvider = JSON.parse(this.UserPermission);
    this.buildTaskForm();
    this.loadData(this.popupType);
    // console.log(this.popupType);
    if (this.popupType.place != 'workflow') {
      this.handleCategoy(this.popupType.data);
       //i changed here
    }
    this.commonService.currentUserPermission.subscribe(data => {
      if (data) {
        if(data && data.permissions==1){
          this.readOnlyUser = true
        }
      }
    }
    )
  }
  ValuesChange(event){
    // this.selectedAffliationValue=event;
    this.selectedAffliationValue = event.map(s=>s.aff_order_id);
  }
  handleCategoy(data) {
 
    this.catValidator = true;
    this.handleproviderId=true; 
    this.addpassport=true;
    this.TaskForm.controls['tin_id'].setValue(data.values.tin_id);
    this.TaskForm.controls['category_id'].setValue(data.values.category_id);
    this.TaskForm.controls['category_item_id'].setValue([data.values.category_item_id]);
    // this.TaskForm.controls['category_item_id'].setValue(data.values.category_item_id);
    //i changed here   
    // console.log(data.values.category_item_id);
        if(data.values.category_id == 3){
            if(data.hospital_affiliationList.length>0){
              this.AffliationsDropdownData=data.hospital_affiliationList ;
              console.log(data.hospital_affiliationList.length);
            }
            else{
              this.AffliationsDropdownData=[];
              this.handleproviderId=false;
              this.notiService.showError("No Affliations found", '', 4000);
            }
        }
    
    if (this.popupType.place == 'provider') {
      this.drop_category = this.popupType.data.providers_list;
      this.selectLabel = 'Provider';
    }
    else if (this.popupType.place == 'tin') {
      this.drop_category = this.popupType.data.tins;
      this.selectLabel = 'Tin';
    }
    else if (this.popupType.place == 'location') {
      this.drop_category = this.popupType.data.location_list;
      this.selectLabel = 'Location'
    }
  }
  getToday(){
    this.date=this.calendar.getToday()
    return this.date ?   this.date.month + '/' + this.date.day + '/' + this.date.year : '';
  }

  buildTaskForm(){
    this.TaskForm= this.fb.group({
      subject: ['', Validators.required],
      selectSub:[null],
      description:['', Validators.required],
      payer_id:[[], Validators.required],
      tin_id:[null, Validators.required],
      category_id:[null, Validators.required],
      category_item_id:[[], Validators.required],
      affliations:[[]],   
      priority:[1, Validators.required],
      location_code:[null],
      payer_code:[null],
      // is_applicable:[true],
      status_id:[2, Validators.required],
      sub_status_id:[null],
      user_assign_to:[null, Validators.required],
      tracking_email:[null],
      tracking_no:[null],
      contact_name:[null],
      email_id:[''],
      phone_number:[''],
      phone_extension:[''],
      fax:[null],
      specific_date:[this.getToday(), Validators.required],
      is_mail_request:[true],
      is_apply_payer_to_provider:this.applyPayerToProvider.apply_payer_to_provider=='1'?[true]:[false],
      is_notifying_user:[false]
    })
  }
  save(){
    this.modalResponse={
      type:"save",
      data:this.TaskForm.value
    }
    let x={...this.TaskForm.value}
    // console.log(x);
    let formdata={
      subject:x.subject,
      description:x.description,
      tin_id:x.tin_id,
      category_id:x.category_id,
      priority:x.priority,
      location_code:x.location_code,
      payer_code:x.payer_code,
      status_id:x.status_id,
      // parent_status_id:x.status_id,
      sub_status_id:x.sub_status_id,
    //  user_assign_to: this.TaskForm.value.payer_id.length ==1 ? this.getString(x.user_assign_to)  : x.user_assign_to,
      user_assign_to: this.multipleSelection ? this.getString(x.user_assign_to) : x.user_assign_to,
      tracking_email:x.tracking_email,
      tracking_no:x.tracking_no,
      contact_name:x.contact_name,
      email_id: x.email_id,
      phone_number: x.phone_number,
      phone_extension: x.phone_extension,
      fax: x.fax,
      specific_date: x.specific_date,
      child_specific_date: x.specific_date,
      is_mail_req: x.is_mail_request?'1':'0',
      hospital_affiliation_id:null,
      hospital_affiliation_order:null,
  
    }

     
     if(x.category_id ==1 || x.category_id==2 ){
      formdata={
        ...formdata,
        subject: x.subject,
        // parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        hospital_affiliation_id:null,
        hospital_affiliation_order:null,

      }

    }
    else if(this.addpassport){
      // console.log(x.affliations?.id);
      // console.log(x.affliations.aff_order_id);
      if(x.affliations){
        formdata = {
          ...formdata,
          subject: x.subject,
          // parent_status_id: x.status_id,
          child_specific_date: x.specific_date,
          sub_status_id: x.sub_status_id,
          status_id: x.status_id,
          // affliations:x.affliations,
          hospital_affiliation_id:this.getString(x?.affliations),
          hospital_affiliation_order:this.getString(this.selectedAffliationValue),

      }
    }
    else{
      formdata = {
        ...formdata,
        subject: x.subject,
        // parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        // affliations:x.affliations,
        hospital_affiliation_id:null,
        hospital_affiliation_order:null,


    }

    }

  }

  else {
    // console.log(x.affliations?.id);
    // console.log(x.affliations.aff_order_id);
    if(x.affliations){
      formdata = {
        ...formdata,
        subject: x.subject,
        // parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        // affliations:x.affliations,
        hospital_affiliation_id:this.getString(x?.affliations),
        hospital_affiliation_order:this.getString(this.selectedAffliationValue),

    }
  }
  else{
    formdata = {
      ...formdata,
      subject: x.subject,
      // parent_status_id: x.status_id,
      child_specific_date: x.specific_date,
      sub_status_id: x.sub_status_id,
      status_id: x.status_id,
      // affliations:x.affliations,
      hospital_affiliation_id:null,
      hospital_affiliation_order:null,


  }

  }

}
    // console.log(formdata);
    // console.log(x.affliations);

    let params={
      customerId:this.customerId,
      userId: this.userId,
      data:JSON.stringify(formdata) ,
      payer_id:x.payer_id,
      category_item_id:x.category_item_id,
      is_apply_payer_to_provider:x.is_apply_payer_to_provider?1:0,
      is_notifying_user:x.is_notifying_user?1:0,
     
    }


    if(this.selectedFiles.length != 0){
      for (let x in this.selectedFiles) {
      let size =  this.selectedFiles[x].fileSize.toString();
      let uploadFileSizeLimit = 60;
      console.log("File Size in MB : ",((+size/1024)/1000));
          if(((+size/1024)/1000) > uploadFileSizeLimit){
            this.notiService.showError("File size can not be greater than 60 MB.","",3000);
            this.selectedFiles = [];
            return
          }
      }
   }

    let postUploadParams = new FormData();
    // if (this.selectedFile.length > 0) {
      for (let i in params) {
        postUploadParams.append(i, params[i]);
      }
      for (var i = 0; i < this.selectedFiles.length; i++) {
          postUploadParams.append("upload_file[]", JSON.stringify(this.selectedFiles[i]));
      }
      // for (var i = 0; i < this.selectedFile.length; i++) {
      //   postUploadParams.append("upload_file[]", this.selectedFile[i]);
      // }
    // }
    // console.log(params);
    // if (this.selectedFile.length > 0) {
      this.spinner.show()
    this.CreateTaskService.saveTaskFile(postUploadParams).subscribe(
      data=>{
        this.spinner.hide();
        if(data['success']){
          this.activeModal.close('success')
        //  this.notiService.showSuccess(data['status']['message'],'',3000);
        this.notiService.showSuccess(data['message'],'',3000);
          this.selectedFiles = [];
          if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
            this.FileToUpload.nativeElement.value = null;
          }
        }
        else{
          this.selectedFiles = [];
          if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
            this.FileToUpload.nativeElement.value = null;
          }
          let  x=data['error']

            for (const key in x) {
              if (x.hasOwnProperty(key)) {
                const element = x[key];
                this.notiService.showError(element[0],'',3000)
              }
            }
          
         
        }
      },
      error=>{
        // console.log(error,"test");
        this.selectedFiles = [];
        this.notiService.showError(JSON.parse(error))
        this.spinner.hide();
      }
    )
    // }
    // else{
    //   this.CreateTaskService.saveTask(params).subscribe(
    //     data=>{
    //       if(data['success']){
    //         this.activeModal.close('success')
    //         this.notiService.showSuccess(data['status']['message'],'',3000)
    //       }
    //       else{
    //         this.notiService.showError(data['error'],'',3000)
    //       }
    //       this.spinner.hide();
    //     },
    //     error=>{
    //       // console.log(error);
    //       this.notiService.showError(Error_Message,'',3000)
    //       this.spinner.hide();
    //     }
    //   )
    // }
  }

  getString(array: Array<any>) {
    let t = ''
    for (let i = 0; i < array.length; i++) {
      if (i == 0) {
        t = array[i].toString()
      }
      else {
        t = t + ',' + array[i]
      }
    }
    return t
  }
  get f() { return this.TaskForm.controls; }

  handleSubject(data){
    this.TaskForm.controls['subject'].setValue(data.name) ;
    this.TaskForm.controls['description'].setValue(data.description);
   }

   handleTin(data){
    this.selectLabel = '';
    this.drop_category = []
    this.TaskForm.controls['category_id'].setValue(null);
    this.TaskForm.controls['affliations'].setValue([]);
    // handle category
    this.catValidator = false
      if (data == undefined || data == null) {
        this.showCat = false
      }
      else{
        this.showCat = true;
      }
    }

    handleTinLoc(e){
      this.TaskForm.controls['affliations'].setValue([]);
      let params={
        customerId:this.customerId,
        userId: this.userId,
        tinId:this.TaskForm.controls['tin_id'].value,
        category_id:e.id
      }
      this.spinner.show();
      this.CreateTaskService.tinMappedRecords(params).subscribe(
        data=>{
          if(data['success']){
            this.drop_category=data.location_list || data.tins_list || data.providers_list;
            this.selectLabel=e.name;
            this.TaskForm.controls['category_item_id'].setValue([]);
            if(e.id==1){
              this.TaskForm.controls['category_item_id'].setValue([this.TaskForm.value.tin_id]);
              this.handlePayer();
            }
            else if (e.id == 3) {
              this.disableTin = false;
              if (data.providers_list.length == 0) {
                this.notiService.showError('No Providers Available', '', 4000)
              }
            }
            else {
              this.disableTin = false;
              if (data.location_list.length == 0) {
                this.notiService.showError('No Locations Available', '', 4000)
              }
            }
          }
          else{
            this.notiService.showError(data['error'],'',3000)
            this.selectLabel='';
            this.drop_category=[];
          }
          this.spinner.hide();
        },
        error=>{
          // console.log(error)
          this.selectLabel=''
          this.spinner.hide();
          this.drop_category=[];
        }
      )

    }
    handleAffliations(f,catId){
      
      this.TaskForm.controls['affliations'].setValue([]);
      if(this.selectLabel=="Provider"){
        if(f[0] && !f[1] || f.id){
         this.handleproviderId=true;
         let params={
              customerId:this.customerId,
              userId:this.userId,
              providerId:f[0]? f[0].id : f.id,
              is_task:1
          }
  
         this.spinner.show();
         this.CreateTaskService.Affliation(params).subscribe(data =>{
           if(data['success']){
            //  console.log(data);
                    if( data.affiliations_list.message =="No Affiliations found."){
                        this.handpnull=true;
                        this.AffliationsDropdownData = [];
                        this.handleproviderId = false;
                        this.notiService.showError("No Affliations found", '', 4000);
                    }
                    else{         
                            let nullNameCount = 0; 
                              for (let item of data.affiliations_list) {
                                // console.log(item);
                                if (item.name === null) {
                                  nullNameCount++;
                                }
                              }
                          
                            if (nullNameCount === data.affiliations_list.length) {
                              // console.log("All names are null");
                              this.AffliationsDropdownData = [];
                              this.handpnull2=true;
                              this.handleproviderId = false;
                              this.notiService.showError("No Affliations found", '', 4000);
                            } else {
                              this.AffliationsDropdownData = data.affiliations_list;
                            }
                      }                   
           }
            this.spinner.hide();
       })
      }
      else{
        this.handleproviderId=false;
       
        this.AffliationsDropdownData=[]
      }
  
      }

      this.handlePayer()

   }
   
  loadData(info){
    this.formInfo=info.data
    // console.log(this.formInfo);
    this.subjectDropdownData = this.formInfo.predefined_Subject;
    this.payerDropdownData = this.formInfo.payers;
    this.tinDropdownData = this.formInfo.tins;
    this.statusDropdownData = this.formInfo.status;
    this.subStatusDropdownData = this.formInfo.sub_status;
    this.CategoryDropdownData = this.formInfo.types;
    this.assignToDropdown = this.formInfo.assign_to
  }


  chooseFile(e) {
    this.selectedFile = e.target.files;
    // this.FileToUpload.nativeElement.nextSibling.innerHTML = 'Choose file'
    // if (this.selectedFile.length > 1) {
    //   this.FileToUpload.nativeElement.nextSibling.innerHTML = this.selectedFile.length + ' Selected';
    // }
    // else if (this.selectedFile.length == 1) {
    //   this.FileToUpload.nativeElement.nextSibling.innerHTML = this.selectedFile[0].name;
    // }
  }

  public toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(f => f?.fileName != files[i]?.name)
          selectedFiles.push({ fileName: files[i]?.name, fileSize : files[i]?.size, base64:reader?.result as string })
          result.next(selectedFiles);
         //const base64Img = (fileReader.result as string).substring((fileReader.result as string).indexOf( ',' ) + 1 );
          this.selectedFiles = selectedFiles;
          if (files?.length === (i + 1)) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  public onFileSelected(event) {
    const file = event.target.files;
     this.selectedFiles = []; // clear
    console.log(file)
    this.toFilesBase64(file, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
      this.selectedFiles = res;
    });
  }

  onClear(){
      this.submitted = false;
      // this.TaskForm.reset();
      this.buildTaskForm()
      if (this.popupType.place != 'workflow') {
        this.handleCategoy(this.popupType.data.values);
      }
  }

  handlePayer(){
   
        if(this.TaskForm.value.payer_id.length == 1 && this.TaskForm.value.category_item_id.length == 1){
            this.multipleSelection = true
            this.disableUploadFile = false
        } else {
            this.multipleSelection = false
            this.disableUploadFile = true
        }
    
    // if(this.TaskForm.value.tin_id != null && this.TaskForm.value.category_id == 1){
    //   this.TaskForm.controls['category_item_id'].setValue(this.TaskForm.value.tin_id);
    // }else{
    //   this.TaskForm.controls['category_item_id'].setValue([]);
    // }
      this.TaskForm.controls['user_assign_to'].setValue([]);
      this.selectedFiles = [];
      if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
        this.FileToUpload.nativeElement.value = null;
      }
  }
}
