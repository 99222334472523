<div class="modal-header">
    <h4 class="modal-title">Esign Upload</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="p-3"> 
<form [formGroup]="esignuploadForm">
        <div class="from-group pb-4">
            <label>Upload Signed File</label>
            <div class="custom-file">
              <input
                type="file"
                #uploadfile
                class="custom-file-input"
                id="custLogo"
                formControlName="uploadfile"
                (change)="onFileSelected($event)"
                placeholder="Browse"
                accept=".png, .jpg, .jpeg"
              />
              <label class="custom-file-label" for="custLogo"
                >Choose file</label
              >
            </div>
            <div *ngIf=" (submitted ||f.uploadfile.touched) && f.uploadfile.errors" class="text-danger">
                <div *ngIf="f.uploadfile.errors.required">File is required.</div>
          </div>
        </div>

          <div class="from-group text-end">
           <button type="submit" class="btn customOrange" (click)="onUploadClick()"> Upload</button>
          </div>
          
        </form>

    </div>
    </div>
    <div class="modal-footer">

    </div>