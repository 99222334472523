<div id="lifeWaysDirectStyle">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <div class="">
                <img  class="img-logo" src="./assets/images/cmd_universal_credentialing.png" alt="Credential my Doc">
                <h3 class="ResidensialSideHeading">Residential Direct Care Worker (DCW) / Aide Credentialing Application </h3>
            </div>
        </div>
        <div class="section">
            <form [formGroup]="lifeWaysDirect">
                <div class="centered centerAlignment">
                    <label>Checklist of Materials to Submit</label>
                </div>
                <div class="centered">
                    <label><b>Ensure the documents listed below have been submitted to your Agency Credentialing Coordinator.</b>
                    </label>
                </div>
                <div class="checkStyle">
                    <input type="checkbox" id="cmd_3000" name="cmd_3000" formControlName="cmd_3000" value="1">A completed application<br><br>
                    <input type="checkbox" id="cmd_3001" name="cmd_3001" formControlName="cmd_3001" value="1">A copy of Driver’s License (initial credentialing only)<br><br>
                    <input type="checkbox" id="cmd_3002" name="cmd_3002" formControlName="cmd_3002" value="1">Copies of applicable certifications<br><br>
                    <input type="checkbox" id="cmd_3003" name="cmd_3003" formControlName="cmd_3003" value="1">A current Curriculum Vitae/Resume (initial credentialing only)<br><br>
                    <input type="checkbox" id="cmd_3004" name="cmd_3004" formControlName="cmd_3004" value="1">Copy of College Transcripts (initial credentialing only)<br><br>
                    <input type="checkbox" id="cmd_3005" name="cmd_3005" formControlName="cmd_3005" value="1">Signed attestation form (page 04)<br><br>
                </div><br><br><br><br><br><br>
                <div class="centered"><label><b>The following documents are included in this application.</b>
                </label>
                </div>
                <div class="checkStyle">
                    <input type="checkbox" id="cmd_3007" name="cmd_3007" formControlName="cmd_3007" value="1">Completed Disciplinary Actions Form (Page 3)<br><br>
                    <input type="checkbox" id="cmd_3008" name="cmd_3008" formControlName="cmd_3008" value="1">Signed attestation form (page 4)<br><br>
                    <input type="checkbox" id="cmd_3009" name="cmd_3009" formControlName="cmd_3009" value="1">Signed Criminal Background Check Authorization (Attachment 1 – Page 5)
                </div><br><br><br><br><br><br>
                <div class="centered centerAlignment">
                    <h3>APPLICATION FOR DIRECT CARE / AIDE STAFF CREDENTIALS</h3>
              </div><br><br>
              <div class="centered"><label class="headingSet"><b>General Instructions:</b></label>
                <ul>
                      <li>Please type or check all responses.</li>
                      <li>Attach additional sheets if necessary (submit to HR / Credentialing Coordinator)</li>
                      <li>Submission of evidence of appropriate education/degree completion is required for initial application.</li>
                      <li>Attach additional sheets if necessary (submit to HR / Credentialing Coordinator)</li>
                     <li>Application must be complete, including submission of all requested supporting documentation.</li>
                </ul>
                </div><br>
                <div class="centered centerAlignment">
                    <h3><u>PERSONAL INFORMATION</u></h3>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-1">
                        <label class="formLabel">Last Name:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="9" name="9" formControlName="9" [class.is-invalid]="(lifeWaysDirect.controls['9'].touched && lifeWaysDirect.controls['9'].invalid) || (lifeWaysDirect.controls['9'].invalid && isSubmit)" type="text" data-id="last_name" (change)="onChangeLastName($event)">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['9'].touched && lifeWaysDirect.controls['9'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['9'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">First Name:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="8" name="8" type="text" formControlName="8" [class.is-invalid]="(lifeWaysDirect.controls['8'].touched && lifeWaysDirect.controls['8'].invalid) || (lifeWaysDirect.controls['8'].invalid && isSubmit)" data-id="first_name" (change)="onChangeFirstName($event)">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['8'].touched && lifeWaysDirect.controls['8'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['8'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="formLabel">Middle Intial:</label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" id="809" name="809"
                        placeholder="Middle Intial" type="text" formControlName="809"
                        [class.is-invalid]="(lifeWaysDirect.controls['809'].touched && lifeWaysDirect.controls['809'].invalid) || (lifeWaysDirect.controls['809'].invalid && isSubmit)" data-id="middlename" (change)="onChangeMiddleName($event)">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['809'].touched && lifeWaysDirect.controls['809'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['809'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-1">
                        <label class="formLabel">Email:</label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" id="759" name="759" formControlName="759"
                        [class.is-invalid]="(lifeWaysDirect.controls['759'].touched && lifeWaysDirect.controls['759'].invalid) || (lifeWaysDirect.controls['759'].invalid && isSubmit)" type="text"  data-id="pemail">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['759'].touched && lifeWaysDirect.controls['759'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['759'].errors?.required">
                                required
                            </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['759'].errors?.pattern">
                                Email address is not valid
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-5">
                        <label class="formLabel">Signature Credentials (Ex: John Doe, M.A., LMSW):</label>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 selectDropTwo selectDrop dropDrownSet divTag suffix">
                            <ng-select [items]="credentials" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="822" [class.is-invalid]="(lifeWaysDirect.controls['822'].touched && lifeWaysDirect.controls['822'].invalid) || (lifeWaysDirect.controls['822'].invalid && isSubmit)">
                            </ng-select>
                            <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['822'].touched && lifeWaysDirect.controls['822'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['822'].errors?.required">
                                required
                            </small>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-5">
                        <label class="formLabel">National Provider Identifier (NPI) Number :</label>
                    </div>
                    <div class="col-md-5">
                        <input class="form-control" id="1634" name="1634" formControlName="1634" type="text" [class.is-invalid]="(lifeWaysDirect.controls['1634'].touched && lifeWaysDirect.controls['1634'].invalid) || (lifeWaysDirect.controls['1634'].invalid && isSubmit)" data-id="npi_no" >
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['1634'].touched && lifeWaysDirect.controls['1634'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['1634'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-2">
                        <label>Date of Birth:</label>
                    </div>
                        <div class="col-md-2">
                        <input class="form-control datepicker" id="31"
                            name="31" ngbDatepicker #d1="ngbDatepicker"
                            formControlName="31" type="text"
                            [class.is-invalid]="(lifeWaysDirect.controls['31'].touched && lifeWaysDirect.controls['31'].invalid) || (lifeWaysDirect.controls['31'].invalid && isSubmit)"
                            placeholder= "Date" data-id="pdob_id"
                            (ngModelChange)="onChangeDobName($event)"
                            (blur)="clearInputIfInvalidMandi('31')"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar calenderWidthOne" (click)="d1.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div class="mandatory" *ngIf="(lifeWaysDirect.controls['31'].invalid&&lifeWaysDirect.controls['31'].touched) && (lifeWaysDirect.controls['31'].value==''|| lifeWaysDirect.controls['31'].value==null) " >
                            <small >
                            required
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('31').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('31').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('31').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('31').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                </div>
                <div class="row marginPadding">
                    <div class="col-md-2">
                            <label class="formLabel">Social Security Number:</label>
                    </div>
                    <div class="col-md-4">
                        <input class="form-control"
                        [class.is-invalid]="(lifeWaysDirect.controls['32'].touched && lifeWaysDirect.controls['32'].invalid) || (lifeWaysDirect.controls['32'].invalid && isSubmit)"
                        id="32" name="32" formControlName="32" type="text"  data-id="ssn_no" >
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['32'].touched && lifeWaysDirect.controls['32'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['32'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="centered centerAlignment">
                    <h3><u>CREDENTIALS</u></h3>
                </div>
                <div class="centered">
                    <label><b>Education -</b> Please indicate all degrees held, name of educational institution and year degree granted.</label>
                </div>
                <div class="centered">
                    <label><b>Attach copies of degree(s) if initial credentialing.</b></label>
                </div>
                <table border="1" class="firstTableWidth">
                    <tr>
                        <th colspan="3" bgcolor="#a6a6a6">Degree
                        </th>
                        <th colspan="3" bgcolor="#a6a6a6">Education Institution
                        </th>
                        <th colspan="3" bgcolor="#a6a6a6">Year Degree Granted
                        </th>
                    </tr>
                    <tr>
                        <td class="" colspan="3">
                            <div class="mb-3 selectDropTwo suffix">
                                <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="512">
                                </ng-select>
                            </div>
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control" id="503" name="503" formControlName="503" type="text"  data-id="ugname" placeholder="UG SCHOOL">
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control onboard datepicker col-md-5" id="511" name="511" ngbDatepicker #d2="ngbDatepicker" 
                            (blur)="clearInputIfInvalid('511')" formControlName="511" type="text" data-id="ugcompelete" placeholder="UG SCHOOL COMPLETED DATE">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary col-md-2 calendar calenderWidthThree" (click)="d2.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="lifeWaysDirect.get('511').invalid ">
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('511').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('511').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('511').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="lifeWaysDirect.get('511').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="" colspan="3">
                            <div class="mb-3 selectDropTwo suffix">
                                <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="480">
                                </ng-select>
                            </div>
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control" id="479" name="479" formControlName="479" type="text"  data-id="gname" placeholder="GRAD SCHOOL">
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control onboard datepicker col-md-5" ngbDatepicker #d3="ngbDatepicker" id="482" name="482" (blur)="clearInputIfInvalid('482')" formControlName="482" type="text" data-id="gcompelete" placeholder="GRAD SCHOOL COMPLETED DATE">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary col-md-2 calendar calenderWidthThree" (click)="d3.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="lifeWaysDirect.get('482').invalid ">
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('482').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('482').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('482').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="lifeWaysDirect.get('482').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </td>
                    </tr>
                    <tr class = "mb-3">
                        <td class="" colspan="3">
                            <div class="mb-3 selectDropTwo suffix">
                                <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="500">
                                </ng-select>
                            </div>
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control" id="490" name="490" formControlName="490" type="text"  data-id="medname" placeholder="MED SCHOOL" >
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control onboard datepicker col-md-5" ngbDatepicker #d4="ngbDatepicker" id="499" name="499" (blur)="clearInputIfInvalid('499')" formControlName="499" type="text" data-id="medcomplete" placeholder="MED SCHOOL COMPLETED DATE">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary col-md-2 calendar calenderWidthThree" (click)="d4.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="lifeWaysDirect.get('499').invalid ">
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('499').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('499').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('499').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="lifeWaysDirect.get('499').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="" colspan="3">
                            <div class="mb-3 selectDropTwo suffix">
                                <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="530">
                                </ng-select>
                            </div>
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control" id="529" name="529" formControlName="529" type="text"  data-id="gname2" placeholder="RES SCHOOL">
                        </td>
                        <td class="" colspan="3">
                            <input class="form-control onboard datepicker col-md-5" ngbDatepicker #d5="ngbDatepicker" id="539" name="539" (blur)="clearInputIfInvalid('539')" formControlName="539" type="text" data-id="gcompelete2" placeholder="RES SCHOOL COMPLETED DATE">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary col-md-2 calendar calenderWidthThree" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <div  *ngIf="lifeWaysDirect.get('539').invalid ">
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('539').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('539').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="lifeWaysDirect.get('539').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="lifeWaysDirect.get('539').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </td>
                    </tr>
                </table>
                <div class="centered centerAlignment">
                    <h3><u>SERVICE CONTINUUM</u></h3>
                </div><br><br>
                <div class="centered">
                    <label>Please indicate populations you have previous working experience with:
                    </label>
                </div><br><br>
                <div class="checkStyle">
                    <input type="checkbox" id="cmd_3010" name="cmd_3010" formControlName="cmd_3010" value="1">Adults w/Mental Illness<br>
                    <input type="checkbox" id="cmd_3011" name="cmd_3011" formControlName="cmd_3011" value="1">Adults w/Developmental Disabilities <br>
                    <input type="checkbox" id="cmd_3012" name="cmd_3012" formControlName="cmd_3012" value="1">Children w/Serious Emotional Disturbance <br>
                    <input type="checkbox" id="cmd_3013" name="cmd_3013" formControlName="cmd_3013" value="1">Children w/Developmental Disabilities <br>
                    <input type="checkbox" id="cmd_3014" name="cmd_3014" formControlName="cmd_3014" value="1">Co-Occurring Mental Illness and Substance Use Disorders<br>
                    <input type="checkbox" id="cmd_3015" name="cmd_3015" formControlName="cmd_3015" value="1">None<br>
                </div>
                <div class="centered centerAlignment">
                    <h3><u>DISCIPLINARY ACTIONS</u></h3>
                </div>
                <div class="centered">
                    <label>
                        Have you ever had any of the following denied, revoked, suspended, reduced, limited, or placed on probation, or have you voluntarily relinquished any of the following in anticipation of any of these actions,or are any of these actions now pending?<b> IF YES, PROVIDE FULL EXPLANATION ON A SEPARATE SHEET.</b>
                    </label>
                </div>
                <table border="1" class="firstTableWidth">
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">1</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel"> License, in any State </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10200" name="cmd_10200" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10200'].touched && lifeWaysDirect.controls['cmd_10200'].invalid) || (lifeWaysDirect.controls['cmd_10200'].invalid && isSubmit)" formControlName="cmd_10200" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10200" name="cmd_10200" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10200'].touched && lifeWaysDirect.controls['cmd_10200'].invalid) || (lifeWaysDirect.controls['cmd_10200'].invalid && isSubmit)" formControlName="cmd_10200" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10200'].touched && lifeWaysDirect.controls['cmd_10200'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10200'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10200'].touched && lifeWaysDirect.controls['cmd_10200'].invalid) || (lifeWaysDirect.controls['cmd_10200'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                            <label  class="formLabel">2</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                            <label  class="formLabel">DEA Registration
                            </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10201" name="cmd_10201" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10201'].touched && lifeWaysDirect.controls['cmd_10201'].invalid) || (lifeWaysDirect.controls['cmd_10201'].invalid && isSubmit)" formControlName="cmd_10201" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10201" name="cmd_10201" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10201'].touched && lifeWaysDirect.controls['cmd_10201'].invalid) || (lifeWaysDirect.controls['cmd_10201'].invalid && isSubmit)" formControlName="cmd_10201" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10201'].touched && lifeWaysDirect.controls['cmd_10201'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10201'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10201'].touched && lifeWaysDirect.controls['cmd_10201'].invalid) || (lifeWaysDirect.controls['cmd_10201'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">3</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Other professional Registration/License
                                </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10202" name="cmd_10202" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10202'].touched && lifeWaysDirect.controls['cmd_10202'].invalid) || (lifeWaysDirect.controls['cmd_10202'].invalid && isSubmit)" formControlName="cmd_10202" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10202" name="cmd_10202" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10202'].touched && lifeWaysDirect.controls['cmd_10202'].invalid) || (lifeWaysDirect.controls['cmd_10202'].invalid && isSubmit)" formControlName="cmd_10202" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10202'].touched && lifeWaysDirect.controls['cmd_10202'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10202'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10202'].touched && lifeWaysDirect.controls['cmd_10202'].invalid) || (lifeWaysDirect.controls['cmd_10202'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">4</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Medical/Hospital Staff Membership</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10203" name="cmd_10203" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10203'].touched && lifeWaysDirect.controls['cmd_10203'].invalid) || (lifeWaysDirect.controls['cmd_10203'].invalid && isSubmit)" formControlName="cmd_10203" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10203" name="cmd_10203" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10203'].touched && lifeWaysDirect.controls['cmd_10203'].invalid) || (lifeWaysDirect.controls['cmd_10203'].invalid && isSubmit)" formControlName="cmd_10203" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10203'].touched && lifeWaysDirect.controls['cmd_10203'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10203'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10203'].touched && lifeWaysDirect.controls['cmd_10203'].invalid) || (lifeWaysDirect.controls['cmd_10203'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">5</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Clinical Privileges</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10204" name="cmd_10204" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10204'].touched && lifeWaysDirect.controls['cmd_10204'].invalid) || (lifeWaysDirect.controls['cmd_10204'].invalid && isSubmit)" formControlName="cmd_10204" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10204" name="cmd_10204" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10204'].touched && lifeWaysDirect.controls['cmd_10204'].invalid) || (lifeWaysDirect.controls['cmd_10204'].invalid && isSubmit)" formControlName="cmd_10204" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10204'].touched && lifeWaysDirect.controls['cmd_10204'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10204'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10204'].touched && lifeWaysDirect.controls['cmd_10204'].invalid) || (lifeWaysDirect.controls['cmd_10204'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">6</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Professional Liability Insurance</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10205" name="cmd_10205" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10205'].touched && lifeWaysDirect.controls['cmd_10205'].invalid) || (lifeWaysDirect.controls['cmd_10205'].invalid && isSubmit)" formControlName="cmd_10205" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10205" name="cmd_10205" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10205'].touched && lifeWaysDirect.controls['cmd_10205'].invalid) || (lifeWaysDirect.controls['cmd_10205'].invalid && isSubmit)" formControlName="cmd_10205" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10205'].touched && lifeWaysDirect.controls['cmd_10205'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10205'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10205'].touched && lifeWaysDirect.controls['cmd_10205'].invalid) || (lifeWaysDirect.controls['cmd_10205'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">7</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Have you had malpractice suits settled against you in the past five (5) years, or currently pending?
                                </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10206" name="cmd_10206" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10206'].touched && lifeWaysDirect.controls['cmd_10206'].invalid) || (lifeWaysDirect.controls['cmd_10206'].invalid && isSubmit)" formControlName="cmd_10206" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10206" name="cmd_10206" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10206'].touched && lifeWaysDirect.controls['cmd_10206'].invalid) || (lifeWaysDirect.controls['cmd_10206'].invalid && isSubmit)" formControlName="cmd_10206" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10206'].touched && lifeWaysDirect.controls['cmd_10206'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10206'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10206'].touched && lifeWaysDirect.controls['cmd_10206'].invalid) || (lifeWaysDirect.controls['cmd_10206'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">8</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Have you had any other professional sanction?
                                </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10207" name="cmd_10207" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10207'].touched && lifeWaysDirect.controls['cmd_10207'].invalid) || (lifeWaysDirect.controls['cmd_10207'].invalid && isSubmit)" formControlName="cmd_10207" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10207" name="cmd_10207" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10207'].touched && lifeWaysDirect.controls['cmd_10207'].invalid) || (lifeWaysDirect.controls['cmd_10207'].invalid && isSubmit)" formControlName="cmd_10207" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10207'].touched && lifeWaysDirect.controls['cmd_10207'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10207'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10207'].touched && lifeWaysDirect.controls['cmd_10207'].invalid) || (lifeWaysDirect.controls['cmd_10207'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">9</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Have you had any professional action against you which was resolved by monetary settlement? </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10208" name="cmd_10208" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10208'].touched && lifeWaysDirect.controls['cmd_10208'].invalid) || (lifeWaysDirect.controls['cmd_10208'].invalid && isSubmit)" formControlName="cmd_10208" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10208" name="cmd_10208" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10208'].touched && lifeWaysDirect.controls['cmd_10208'].invalid) || (lifeWaysDirect.controls['cmd_10208'].invalid && isSubmit)" formControlName="cmd_10208" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10208'].touched && lifeWaysDirect.controls['cmd_10208'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10208'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10208'].touched && lifeWaysDirect.controls['cmd_10208'].invalid) || (lifeWaysDirect.controls['cmd_10208'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">10</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel"> Are there any medical incidents for which you have been contacted by an attorney regarding potential malpractice liability (settlement request, writ of summons, etc.)?</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10209" name="cmd_10209" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10209'].touched && lifeWaysDirect.controls['cmd_10209'].invalid) || (lifeWaysDirect.controls['cmd_10209'].invalid && isSubmit)" formControlName="cmd_10209" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10209" name="cmd_10209" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10209'].touched && lifeWaysDirect.controls['cmd_10209'].invalid) || (lifeWaysDirect.controls['cmd_10209'].invalid && isSubmit)" formControlName="cmd_10209" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10209'].touched && lifeWaysDirect.controls['cmd_10209'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10209'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10209'].touched && lifeWaysDirect.controls['cmd_10209'].invalid) || (lifeWaysDirect.controls['cmd_10209'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">11</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">with in the past ten (10) years, have you ever been convicted of, or pleaded guilty to,a criminal offense, including a verdict of guilty following a plea of nolo contendere?  </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10210" name="cmd_10210" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10210'].touched && lifeWaysDirect.controls['cmd_10210'].invalid) || (lifeWaysDirect.controls['cmd_10210'].invalid && isSubmit)" formControlName="cmd_10210" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10210" name="cmd_10210" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10210'].touched && lifeWaysDirect.controls['cmd_10210'].invalid) || (lifeWaysDirect.controls['cmd_10210'].invalid && isSubmit)" formControlName="cmd_10210" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10210'].touched && lifeWaysDirect.controls['cmd_10210'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10210'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10210'].touched && lifeWaysDirect.controls['cmd_10210'].invalid) || (lifeWaysDirect.controls['cmd_10210'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                            <label  class="formLabel">12</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                            <label  class="formLabel">Have you had any Medicaid, Medicare, or other governmental or third-party payer sanctions?</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10211" name="cmd_10211" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10211'].touched && lifeWaysDirect.controls['cmd_10211'].invalid) || (lifeWaysDirect.controls['cmd_10211'].invalid && isSubmit)" formControlName="cmd_10211" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10211" name="cmd_10211" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10211'].touched && lifeWaysDirect.controls['cmd_10211'].invalid) || (lifeWaysDirect.controls['cmd_10211'].invalid && isSubmit)" formControlName="cmd_10211" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10211'].touched && lifeWaysDirect.controls['cmd_10211'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10211'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10211'].touched && lifeWaysDirect.controls['cmd_10211'].invalid) || (lifeWaysDirect.controls['cmd_10211'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                            <label  class="formLabel">13</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel"> Have you ever been excluded from the Medicare program? </label>
                            </div>
                            <br>
                            <div *ngIf="changeCmdOne">
                                <div class="col-md-4">
                                    <label>If yes, specify date:</label>
                                </div>
                                <div class="col-md-3">
                                    <input class="form-control onboard datepicker" ngbDatepicker #d6="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_112352" name="cmd_112352" (blur)="clearInputIfInvalidForYesorNo('cmd_112352')"
                                    [class.is-invalid]="lifeWaysDirect.controls['cmd_112352'].value==null" formControlName="cmd_112352" type="text" data-id="Mil Active from">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar calenderWidthSet" (click)="d6.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                    <div class="formLabel col-md-11"
                                        *ngIf="lifeWaysDirect.controls['cmd_112352'].touched && lifeWaysDirect.controls['cmd_112352'].invalid">
                                        <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_112352'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <div  *ngIf="lifeWaysDirect.get('cmd_112352').invalid && lifeWaysDirect.get('cmd_112352').touched">
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112352').errors.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112352').errors.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112352').errors.invalidDateyear">
                                          year is out of range(1900-2099)                    </small>
                                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112352').errors.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>
                                </div>
                                <br>
                                <br>
                                <br>
                                <div class="col-md-3">
                                    <label>Date of Reinstatement:</label>
                                </div>
                                <div class="col-md-3">
                                    <input class="form-control onboard datepicker" ngbDatepicker #d7="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_112352" name="cmd_112353" (blur)="clearInputIfInvalidForYesorNo('cmd_112353')"
                                    [class.is-invalid]="lifeWaysDirect.controls['cmd_112353'].value==null" formControlName="cmd_112353" type="text" data-id="Mil Active from">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar calenderWidthSet" (click)="d7.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                    <div class="formLabel col-md-11"
                                        *ngIf="lifeWaysDirect.controls['cmd_112353'].touched && lifeWaysDirect.controls['cmd_112353'].invalid">
                                        <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_112353'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                    <div  *ngIf="lifeWaysDirect.get('cmd_112353').invalid && lifeWaysDirect.get('cmd_112353').touched">
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112353').errors.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112353').errors.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112353').errors.invalidDateyear">
                                          year is out of range(1900-2099)                    </small>
                                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112353').errors.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>
                                </div>
                                <br>
                                <br>
                                <br>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10212" name="cmd_10212" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10212'].touched && lifeWaysDirect.controls['cmd_10212'].invalid) || (lifeWaysDirect.controls['cmd_10212'].invalid && isSubmit)" formControlName="cmd_10212" value="1"  (change)="onChangeCmdOne($event)">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10212" name="cmd_10212" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10212'].touched && lifeWaysDirect.controls['cmd_10212'].invalid) || (lifeWaysDirect.controls['cmd_10212'].invalid && isSubmit)" formControlName="cmd_10212"  value="0" (change)="onChangeCmdOne($event)">No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10212'].touched && lifeWaysDirect.controls['cmd_10212'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10212'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10212'].touched && lifeWaysDirect.controls['cmd_10212'].invalid) || (lifeWaysDirect.controls['cmd_10212'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">14</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label  class="formLabel">Have civil and monetary penalties been levied against you by Medicare or Medicaid programs?</label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10213" name="cmd_10213" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10213'].touched && lifeWaysDirect.controls['cmd_10213'].invalid) || (lifeWaysDirect.controls['cmd_10213'].invalid && isSubmit)" formControlName="cmd_10213" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10213" name="cmd_10213" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10213'].touched && lifeWaysDirect.controls['cmd_10213'].invalid) || (lifeWaysDirect.controls['cmd_10213'].invalid && isSubmit)" formControlName="cmd_10213" value="0">No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10213'].touched && lifeWaysDirect.controls['cmd_10213'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10213'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10213'].touched && lifeWaysDirect.controls['cmd_10213'].invalid) || (lifeWaysDirect.controls['cmd_10213'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </table><br><br>
                <div class="centered">
                    <label><b>Statement of Ability to Perform</b></label>
                </div>
                <table border="1" class="firstTableWidth">
                    <tr>
                        <td colspan="2" class="secondTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="formLabel">1</label>
                            </div>
                        </td>
                        <td colspan="12" class="thirdTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="formLabel">Do you now, or have you had any physical condition, mental condition, or substance abuse condition (alcohol, illegal or prescription drugs) that has interfered with your ability to practice or perform clinical duties, or led to suspension, termination, or any other action(s) toward privileges or employment?
                                </label>
                            </div>
                        </td>
                        <td colspan="4" class="fourTableWidth">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin tableInputboxPadding">
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10215" name="cmd_10215" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10215'].touched && lifeWaysDirect.controls['cmd_10215'].invalid) || (lifeWaysDirect.controls['cmd_10215'].invalid && isSubmit)" formControlName="cmd_10215" value="1" >Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_10215" name="cmd_10215" [class.is-invalid]="(lifeWaysDirect.controls['cmd_10215'].touched && lifeWaysDirect.controls['cmd_10215'].invalid) || (lifeWaysDirect.controls['cmd_10215'].invalid && isSubmit)" formControlName="cmd_10215" value="0" >No</label>
                                <div class="formLabel col-md-11"
                                    *ngIf="lifeWaysDirect.controls['cmd_10215'].touched && lifeWaysDirect.controls['cmd_10215'].invalid">
                                    <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_10215'].errors?.required">
                                        required
                                    </small>
                                </div>
                                <span *ngIf="(lifeWaysDirect.controls['cmd_10215'].touched && lifeWaysDirect.controls['cmd_10215'].invalid) || (lifeWaysDirect.controls['cmd_10215'].invalid && isSubmit)">
                                    <i class="fas fa-times ms-2 text-danger"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </table><br><br>
                <div class="row paddingSet">
                    <div class="">
                        <h4 class="hThreeStylePadding"><b>Please specify all fluent communicable languages (including sign language) in order of
                        competency: </b></h4>
                    </div>
                </div><br><br>
                <table class = "southform_table languageTable">
                    <tr>
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">1st </label>
                            </div>
                        </td>
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">2nd</label>
                            </div>
                        </td>
                        <td colspan="3" bgcolor="#a6a6a6">
                            <div class="col-md-3 inputBoxPadding">
                                <label class="checkBoxHeading">3rd</label>
                            </div>
                        </td>
                        <td colspan="2" bgcolor="#a6a6a6">
                            <div class="col-md-2 inputBoxPadding">
                                <label class="checkBoxHeading">4th</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan=3>
                            <div class="col-md-3 inputBoxPadding">
                                <div class="mb-3 selectDrop suffix">
                                    <ng-select [items]="languages" bindLabel="name" placeholder="Select Language" bindValue="id" formControlName="277">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                        <td colspan="3">
                            <div class="col-md-3 inputBoxPadding">
                                <div class="mb-3 selectDrop suffix">
                                    <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select Language" bindValue="id" formControlName="1535">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                        <td colspan="3">
                            <div class="col-md-3 inputBoxPadding">
                                <div class="mb-3 selectDrop suffix">
                                    <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select Language" bindValue="id" formControlName="1536">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2 inputBoxPadding">
                                <div class="mb-3 selectDrop suffix">
                                    <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select Language" bindValue="id" formControlName="2207">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <br><br><br><br><br><br>
                <div class="">
                    <div class="">
                        <h4 class="centerNormalParagraph"><b>ATTESTATION FORM</b></h4>
                        <h4 class="centerNormalParagraph"><b>PRIVILEGED STAFF ORGANIZATION</b></h4>
                        <h4 class="centerNormalParagraph"><b>STATEMENT OF PRIVATE PRACTITIONER APPLICANT</b></h4>
                    </div>
                    <div class="row sideHeadingAlignment">
                        <div class="col-md-12">
                            <label class = "universal-label formLabel">
                                I fully understand that any significant misstatement in or omission from this application constitutes cause
                                for denial of appointment or cause for summary dismissal from the organization of privileged staff, loss
                                of clinical privileges, and termination of employment or contract. All information submitted by me in this
                                application is true to my best knowledge and belief.
                            </label><br>
                            <label class = "universal-label formLabel">
                                In making this application for appointment or reappointment to the LifeWays provider network and
                                privileged staff, I acknowledge that I have received and read the standards and rules and regulations
                                of the organized, credentialed and privileged staff of this organization, and that I am familiar with the
                                standards and ethics of the national, state, and local associations that apply to and govern my specialty
                                and/or profession, and I agree to be bound by the terms thereof if I am granted clinical privileges, and I
                                further agree to be bound by the terms thereof without regard to whether or not I am granted clinical
                                privileges in all matters relating to the consideration of my application for appointment to the organized
                                privileged staff or clinical privileges, and I further agree to abide by the organization and staff rules and
                                regulations as may be from time to time enacted.
                            </label><br>
                            <label class = "universal-label formLabel">
                                By applying for appointment or reappointment of membership and applicable clinical privileges, I hereby
                                signify my willingness to appear for interviews in connection with my application. I hereby authorize the
                                Agency, its staff, and their representatives to contact administrators and members or staffs of other
                                facilities or institutions with which I have been associated, and with any person, organization, or others,
                                including past and present malpractice liability insurance carriers, who may have information bearing
                                on my professional competence, character, and ethical qualifications. I hereby further consent to the
                                inspection, by the Agency, its Privileged Staff Organization, and its representatives, of all documents,
                                including consumer records and other facilities, which may be material to an evaluation of my
                                qualifications and competence to continue as a member of the Privileged Staff Organization and to
                                maintain applicable privileges.
                            </label><br>
                            <label class = "universal-label formLabel">
                                I hereby release from liability all representatives of the Agency and its staff, including, without limitation,
                                its officers, directors, agents, and employees, for all their acts performed in good faith and without
                                malicious intent in connection with evaluating my application, my credentials, and qualifications. I also
                                hereby release from liability any and all individuals and organizations who provide information to the
                                Agency or its staff in good faith and without malicious intent concerning my competence, ethics,
                                character, and other qualifications for membership in the Privileged Staff Organization or applicable
                                privileges, and I hereby consent to the release of such information.
                            </label><br>
                            <label class = "universal-label formLabel">
                                I understand and agree that I, as an applicant for organized privileged staff membership or clinical
                                privileges, have the burden of producing adequate information for proper evaluation of my professional
                                competence, character, ethics, and other qualifications and for resolving any doubts about such
                                qualifications.
                            </label><br>
                            <label class = "universal-label formLabel">
                                I have not requested privileges for any procedures for which I am not qualified. Furthermore, I realize
                                that certification or licensure does not necessarily qualify me to perform certain privileges. However, I
                                believe that I am qualified to perform all clinical activities for which I have requested privileges.
                            </label><br>
                        </div>
                    </div><br><br><br><br><br><br>
                    <div class="col-md-3" >
                        <label class="normalParagraph"><b>Signature of Applicant: </b></label>
                    </div>
                    <div class="col-md-3 signaturePadding">
                        <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">
                        <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">
                        <input type="hidden" name="cmd_1000120" id="cmd_1000120" [class.is-invalid]="(lifeWaysDirect.controls['cmd_1000120'].touched && lifeWaysDirect.controls['cmd_1000120'].invalid) || (lifeWaysDirect.controls['cmd_1000120'].invalid && isSubmit)" formControlName="cmd_1000120">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['cmd_1000120'].touched && lifeWaysDirect.controls['cmd_1000120'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_1000120'].errors?.required">
                                required
                            </small>
                        </div>
                        <span *ngIf="(lifeWaysDirect.controls['cmd_1000120'].touched && lifeWaysDirect.controls['cmd_1000120'].invalid) || (lifeWaysDirect.controls['cmd_1000120'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </div>
                    <br><br><br><br><br><br>
                    <div class="col-md-2">
                            <label class="normalParagraph"><b>Date: </b></label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard datepicker" id="cmd_112350" ngbDatepicker #d8="ngbDatepicker" name="cmd_112350" [class.is-invalid]="(lifeWaysDirect.controls['cmd_112350'].touched && lifeWaysDirect.controls['cmd_112350'].invalid) || (lifeWaysDirect.controls['cmd_112350'].invalid && isSubmit)" formControlName="cmd_112350" type="text"  (blur)="clearInputIfInvalidMandi('cmd_112350')" placeholder= "Date" data-id="managing_contact_name">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar calenderWidthFour" (click)="d8.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div class="mandatory" *ngIf="(lifeWaysDirect.controls['cmd_112350'].invalid&&lifeWaysDirect.controls['cmd_112350'].touched) && (lifeWaysDirect.controls['cmd_112350'].value==''|| lifeWaysDirect.controls['cmd_112350'].value==null) " >
                            <small >
                            required
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112350').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112350').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112350').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112350').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                    <br><br><br><br><br><br>
                </div>
                <div class="">
                    <h4 class="centerNormalParagraph">ATTACHMENT 1</h4><br>
                    <h4 class="centerNormalParagraph"><b>CONFIDENTIAL</b></h4><br>
                    <h4 class="centerNormalParagraph"><b><u>CRIMINAL BACKGROUND CHECK AUTHORIZATION</u></b></h4><br>
                </div><br><br>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-12">
                        <label class = "universal-label normalParagraph">
                            In connection with my Employee Practitioner Credentialing Application, I hereby authorize LifeWays
                            and my employing agency to investigate and obtain a record of my criminal convictions and any pending
                            felony charges from official law enforcement agencies.
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                        <h4 class="hThreeStyle"><b>*Required Fields</b></h4>
                    </div>
                </div>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-1">
                        <label class="normalParagraph">LastName:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard " id="cmd_11235" name="cmd_11235" formControlName="cmd_11235" type="text" placeholder= "Last Name" data-id="lname">
                    </div>
                    <div class="col-md-1">
                        <label class="normalParagraph">FirstName:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard " id="cmd_11234" name="cmd_11234" formControlName="cmd_11234" type="text" placeholder= "First Name" data-id="fname">
                    </div>
                    <div class="col-md-1">
                        <label class="normalParagraph">MI:</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard " id="cmd_11236" name="cmd_11236" formControlName="cmd_11236" type="text" placeholder= "MI" data-id="fname">
                    </div>
                </div>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-3">
                        <label class="normalParagraph">Alias/Previous Name(s): </label>
                    </div>
                    <div class="col-md-8">
                        <input class="form-control" id="278" name="278" [class.is-invalid]="(lifeWaysDirect.controls['278'].touched && lifeWaysDirect.controls['278'].invalid) || (lifeWaysDirect.controls['278'].invalid && isSubmit)" formControlName="278" type="text" data-id="prior_name">
                        <div class="formLabel col-md-11"
                        *ngIf="lifeWaysDirect.controls['278'].touched && lifeWaysDirect.controls['278'].invalid">
                        <small class="text-danger" *ngIf="lifeWaysDirect.controls['278'].errors?.required">
                            required
                        </small>
                    </div>
                    </div>
                </div>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-2">
                        <label class="normalParagraph">Date of Birth</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control onboard datepicker col-md-7" id="cmd_11237" (blur)="clearInputIfInvalid('cmd_11237')"  ngbDatepicker #d9="ngbDatepicker" name="cmd_11237" [value]=dob [class.is-invalid]="(lifeWaysDirect.controls['cmd_11237'].touched && lifeWaysDirect.controls['cmd_11237'].invalid) || (lifeWaysDirect.controls['cmd_11237'].invalid && isSubmit)" formControlName="cmd_11237" type="text" placeholder= "Date" data-id="dob">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar calenderWidthee" (click)="d9.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div  *ngIf="lifeWaysDirect.get('cmd_11237').invalid ">
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_11237').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_11237').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_11237').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_11237').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>
                    </div>
                    <div class="col-md-1">
                        <label class="normalParagraph">Sex:</label>
                    </div>
                    <div class="row">
                        <div class="col-md-3 inputBoxPadding">
                            <label class="checkbox-inline radioStyle">
                            <input type="radio" id="30" name="30" [class.is-invalid]="(lifeWaysDirect.controls['30'].touched && lifeWaysDirect.controls['30'].invalid) || (lifeWaysDirect.controls['30'].invalid && isSubmit)" formControlName="30" value="2">Male</label>
                        </div>
                        <div class="col-md-3 inputBoxPadding">
                            <label class="checkbox-inline radioStyle">
                            <input type="radio" id="30" name="30" [class.is-invalid]="(lifeWaysDirect.controls['30'].touched && lifeWaysDirect.controls['30'].invalid) || (lifeWaysDirect.controls['30'].invalid && isSubmit)" formControlName="30" value="1">Female</label>
                            <div class="formLabel col-md-11"
                                *ngIf="lifeWaysDirect.controls['30'].touched && lifeWaysDirect.controls['30'].invalid">
                                <small class="text-danger" *ngIf="lifeWaysDirect.controls['30'].errors?.required">
                                    required
                                </small>
                            </div>
                            <span *ngIf="(lifeWaysDirect.controls['30'].touched && lifeWaysDirect.controls['30'].invalid) || (lifeWaysDirect.controls['30'].invalid && isSubmit)">
                                <i class="fas fa-times ms-2 text-danger"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-3">
                        <label class="normalParagraph">Race</label>
                    </div>
                    <div class="col-md-8">
                        <div class="mb-3 selectDrop dropDrownSet">
                            <ng-select [items]="race" bindLabel="name" placeholder="Select Race" [class.is-invalid]="(lifeWaysDirect.controls['741'].touched && lifeWaysDirect.controls['741'].invalid) || (lifeWaysDirect.controls['741'].invalid && isSubmit)" bindValue="id" formControlName="741">
                            </ng-select>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['741'].touched && lifeWaysDirect.controls['741'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['741'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <input type="checkbox" id="cmd_12405" name="cmd_12405" formControlName="cmd_12405"  value="1">
                    <label class = "universal-label" for="cmd_2500001"><b>Clinical Staff Only –</b>I acknowledge that LifeWays may be required to submit my information to the
                        Jackson County Jail and Hillsdale County Jail, who will perform their own background check, in
                        order for me to gain access to their facility. </label><br>
                </div><br><br><br><br><br><br>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-12">
                        <label>My signature below indicates that the information that I have furnished is true and correct to the best of
                            my knowledge.</label><br>
                    </div>
                </div>
                <br><br><br><br><br><br>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-3">
                        <label class="normalParagraph"><b>Applicant's Signature </b></label>
                    </div>
                    <div class="col-md-3 signaturePaddingSig">
                        <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenTwo()">
                        <img  *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo"  alt="Credential my Doc" (click)="esignOpenTwo()">
                        <input type="hidden" name="cmd_1000130" id="cmd_1000130" [class.is-invalid]="(lifeWaysDirect.controls['cmd_1000130'].touched && lifeWaysDirect.controls['cmd_1000130'].invalid) || (lifeWaysDirect.controls['cmd_1000130'].invalid && isSubmit)" formControlName="cmd_1000130">
                        <div class="formLabel col-md-11"
                            *ngIf="lifeWaysDirect.controls['cmd_1000130'].touched && lifeWaysDirect.controls['cmd_1000130'].invalid">
                            <small class="text-danger" *ngIf="lifeWaysDirect.controls['cmd_1000130'].errors?.required">
                                required
                            </small>
                        </div>
                        <span *ngIf="(lifeWaysDirect.controls['cmd_1000130'].touched && lifeWaysDirect.controls['cmd_1000130'].invalid) || (lifeWaysDirect.controls['cmd_1000130'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </div>
                </div>
                <br><br><br><br><br><br>
                <div class="row sideHeadingAlignment">
                    <div class="col-md-2">
                        <label class="normalParagraph"><b>Date: </b></label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard datepicker" ngbDatepicker #d10="ngbDatepicker" id="cmd_112351" name="cmd_112351" [class.is-invalid]="(lifeWaysDirect.controls['cmd_112351'].touched && lifeWaysDirect.controls['cmd_112351'].invalid) || (lifeWaysDirect.controls['cmd_112351'].invalid && isSubmit)" formControlName="cmd_112351" type="text" 
                        (blur)="clearInputIfInvalidMandi('cmd_112351')" placeholder= "DATE" data-id="managing_contact_name">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar calenderWidthFour" (click)="d10.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                        </div>
                        <div class="mandatory" *ngIf="(lifeWaysDirect.controls['cmd_112351'].invalid&&lifeWaysDirect.controls['cmd_112351'].touched) && (lifeWaysDirect.controls['cmd_112351'].value==''|| lifeWaysDirect.controls['cmd_112351'].value==null) " >
                            <small >
                            required
                            </small>
          
                        </div>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112351').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112351').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112351').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="lifeWaysDirect.get('cmd_112351').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
    </div>
</div>
