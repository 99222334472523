<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
<h4 class="modal-title">Create Esign</h4>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
<!-- [(activeId)]="active" -->
    <ul ngbNav #nav="ngbNav"  class="nav-tabs">
        <li [ngbNavItem]="1">
            <button ngbNavLink>Sign Now</button>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-md-5">
                        <div id="signature-pad" class="m-signature-pad">
                            <div class="m-signature-pad-body">
                                <!-- <signature-pad [options]="signaturePadOptions"></signature-pad> -->
                                <!-- <signature-pad [options]="signaturePadOptions" ></signature-pad> -->
                                <canvas
                                    #canvas
                                    (touchstart)="startDrawing($event)"
                                    (touchmove)="moved($event)"
                                    width="500"
                                    height="200"
                                    ></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-left">
                    <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="createEsign()">Create</button>
                    <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="reset()">Reset</button>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <button ngbNavLink> Upload Sign</button>
            <ng-template ngbNavContent>
                <div class="row">
                    <div class="text-left marginPad paddingPad">
                        <input type="file"  
                        (change)="onFileSelected($event)" 
                        accept=".jpeg,.jpg,.png">
                    </div>
                </div>
                <div class="text-left row">
                    <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="uploadEsign()">Create</button>
                </div>
            </ng-template>
        </li>
    </ul>
    
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <!-- <ngb-tabset #t="ngbTabset">
        <ngb-tab id="tab-selectbyid1" title="Sign Now">
        <ng-template ngbTabContent>
            <div class="row">
                <div class="col-md-5">
                    <div id="signature-pad" class="m-signature-pad">
                        <div class="m-signature-pad-body">
                            <signature-pad [options]="signaturePadOptions"></signature-pad>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-left">
                <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="createEsign()">Create</button>
                <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="reset()">Reset</button>
            </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="tab-selectbyid2" title="Upload Sign">
            <ng-template ngbTabContent>
            <div class="row">
                <div class="text-left marginPad paddingPad">
                    <input type="file"  
                    (change)="onFileSelected($event)" 
                    accept=".jpeg,.jpg,.png">
                </div>
            </div>
            <div class="text-left row">
                <button type="button" class="btn btn-outline-dark customOrange marginPad" (click)="uploadEsign()">Create</button>
            </div>
        </ng-template>
        </ngb-tab>
    </ngb-tabset> -->
                    <!-- (change)="selectedFileForUpload($event)" formControlName="uploadfile" -->

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark customOrange" (click)="activeModal.close()">Close</button>
</div>


