<div id="dicksonmedmd">
    <div class="cotainer-fluid mx-1">
      <div class="header" style="height: 50px">
        <div class="logoImg" style="float: left;width:20%;">
          <img class="logoImg"src="../../../../assets/images/MicrosoftTeams-image.png"
            alt="Credential my Doc" />
        </div>
        <div class="" style="float: right;width:80%;margin-top: 20px;">
          <div class="text-center">
            <h2>CredentialMyDoc Credentialing Application</h2>
          </div>
        </div>
      </div>
      <br><br>
      <div class="section">
        <form [formGroup]="DicksonMDFroms">
          <div class="centered texasFirstTable">
            <b style="font-size:16px;">Section I-Individual Information</b>
          </div>
          <table border="1" width="100%">
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-4">
                    TYPE OF PROFESSIONAL
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="credentials"
                        [class.is-invalid]="
                          (DicksonMDFroms.controls['822'].touched &&
                            DicksonMDFroms.controls['822'].invalid) ||
                          (DicksonMDFroms.controls['822'].invalid && isSubmit)
                        "
                        bindLabel="name"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="822"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['822'].touched &&
                          DicksonMDFroms.controls['822'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['822'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">LAST NAME</label>
                    <input
                      class="form-control"
                      id="9"
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['9'].touched &&
                          DicksonMDFroms.controls['9'].invalid) ||
                        (DicksonMDFroms.controls['9'].invalid && isSubmit)
                      "
                      name="9"
                      formControlName="9"
                      (change)="onChangeLastName($event)"
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['9'].touched &&
                        DicksonMDFroms.controls['9'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['9'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">FIRST NAME</label>
                    <input
                      class="form-control"
                      id="8"
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['8'].touched &&
                          DicksonMDFroms.controls['8'].invalid) ||
                        (DicksonMDFroms.controls['8'].invalid && isSubmit)
                      "
                      name="8"
                      (change)="onChangeFirstName($event)"
                      formControlName="8"
                      type="text"
                      data-id="fname"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['8'].touched &&
                        DicksonMDFroms.controls['8'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['8'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <label class="formLabel">MIDDLE NAME</label>
                    <input
                      class="form-control"
                      id="809"
                      name="809"
                      formControlName="809"
                      type="text"
                      data-id="mname"
                    />
                  </div>
                  <div class="col-md-3">
                    (JR., SR., ETC.)
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="suffix"
                        bindLabel="name"
                        placeholder="Suffix"
                        bindValue="id"
                        formControlName="29"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="row">
                  <div class="no-margin col-md-6">
                    <label class="formLabel">MAIDEN NAME </label>
                    <input
                      class="form-control"
                      id="278"
                      name="278"
                      formControlName="278"
                      type="text"
                      data-id="priorname"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="formLabel">YEARS ASSOCIATED (YYYY-YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1="ngbDatepicker"
                        id="280"
                        name="280"
                        formControlName="280"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('280')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('280').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('280').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('280').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('280').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('280').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
  
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d2="ngbDatepicker"
                        id="281"
                        name="281"
                        formControlName="281"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('281')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d2.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('281').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('281').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('281').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('281').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('281').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="row">
                  <div class="col-md-6">
                    <label class="formLabel">OTHER NAME </label>
                    <input
                      class="form-control"
                      id="2337"
                      name="2337"
                      formControlName="2337"
                      type="text"
                      data-id="othername"
                    />
                  </div>
                  <div class="mb-3 col-md-6">
                    <label class="formLabel">YEARS ASSOCIATED(YYYY-YYYY)</label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d3="ngbDatepicker"
                        id="cmd_01"
                        name="cmd_01"
                        formControlName="cmd_01"
                        type="text"
                        data-id="othernameeffdate_date"
                        (blur)="clearInputIfInvalid('cmd_01')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d3.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('cmd_01').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_01').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_01').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_01').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_01').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d4="ngbDatepicker"
                        id="cmd_02"
                        name="cmd_02"
                        formControlName="cmd_02"
                        type="text"
                        data-id="othernametermdate_date"
                        (blur)="clearInputIfInvalid('cmd_02')"
                        
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d4.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('cmd_02').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_02').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_02').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_02').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('cmd_02').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">HOME MAILING ADDRESS</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (DicksonMDFroms.controls['16'].touched &&
                        DicksonMDFroms.controls['16'].errors) ||
                      (DicksonMDFroms.controls['16'].errors && isSubmit)
                    "
                    id="16"
                    name="16"
                    formControlName="16"
                    type="text"
                    data-id="homeaddress1"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['16'].touched &&
                      DicksonMDFroms.controls['16'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['16'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                    
                    id="17"
                    name="17"
                    formControlName="17"
                    type="text"
                    data-id="homeaddress2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['18'].touched &&
                          DicksonMDFroms.controls['18'].invalid) ||
                        (DicksonMDFroms.controls['18'].invalid && isSubmit)
                      "
                      id="18"
                      name="18"
                      formControlName="18"
                      type="text"
                      data-id="homecity"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['18'].touched &&
                        DicksonMDFroms.controls['18'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['18'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="mb-3 col-md-3 sideLabelAlignment">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="mb-3 col-md-12 dropDrownSet">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="
                          (DicksonMDFroms.controls['20'].touched &&
                            DicksonMDFroms.controls['20'].invalid) ||
                          (DicksonMDFroms.controls['20'].invalid && isSubmit)
                        "
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="20"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['20'].touched &&
                          DicksonMDFroms.controls['20'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['20'].errors?.required"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 col-md-3 sideLabelAlignment">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control col-md-8"
               
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['21'].touched &&
                          DicksonMDFroms.controls['21'].invalid) ||
                        (DicksonMDFroms.controls['21'].invalid && isSubmit)
                      "
                      id="21"
                      name="21"
                      formControlName="21"
                      type="text"
                      data-id="homezip"
                      
  
  
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        
                        DicksonMDFroms.controls['21'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['21'].errors?.required"
                      >
                        
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          DicksonMDFroms.controls['21'].errors?.invalidZipCode
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">HOME PHONE NUMBER </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="
                      (DicksonMDFroms.controls['708'].touched &&
                        DicksonMDFroms.controls['708'].invalid) ||
                      (DicksonMDFroms.controls['708'].invalid && isSubmit)
                    "
                    id="708"
                    name="708"
                    formControlName="708"
                    type="text"
                    data-id="homephone"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['708'].touched &&
                      DicksonMDFroms.controls['708'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['708'].errors?.required"
                    >
                      required 
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SOCIAL SECURITY NUMBER </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (DicksonMDFroms.controls['32'].touched &&
                        DicksonMDFroms.controls['32'].invalid) ||
                      (DicksonMDFroms.controls['32'].invalid && isSubmit)
                    "
                    id="32"
                    name="32"
                    formControlName="32"
                    type="text"
                    data-id="ssn"
                    (ngModelChange)="getSsn($event)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['32'].touched &&
                      DicksonMDFroms.controls['32'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['32'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">GENDER</label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="gender"
                      bindLabel="name"
                      placeholder="Select Gender"
                      bindValue="id"
                      formControlName="30"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                        <div class="col-md-3">
                            <b style="font-size:13px;padding:10px;">CORRESPONDENCE ADDRESS </b>
                        </div>    
                        <div class="col-md-5">
                            <input
                            class="form-control"
                            id="cmd_837"
                            name="cmd_837"
                            formControlName="cmd_837"
                            type="text"
                            data-id="prac1_MailingAddress1"
                            />
                        </div>
                        <div class="col-md-4">
                            <input
                            class="form-control"
                            id="cmd_838"
                            name="cmd_838"
                            formControlName="cmd_838"
                            type="text"
                            data-id="prac1_MailingAddress2"
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">CITY</label>
                    <input
                      class="form-control"
                      id="cmd_839"
                      name="cmd_839"
                      formControlName="cmd_839"
                      type="text"
                      data-id="prac1_MailingCity"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="formLabel ">STATE/COUNTRY</label>
                    <div class="mb-3 dropDrownSet">
                      <div class="col-md-12">
                        <ng-select
                          [items]="states"
                          bindLabel="name"
                          placeholder="Select States"
                          bindValue="id"
                          formControlName="cmd_841"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="formLabel">POSTAL CODE</label>
                    <input
                      class="form-control col-md-8"
                      id="cmd_842"
                      name="cmd_842"
                      formControlName="cmd_842"
                      type="text"
                      data-id="prac1_MailingZIP"
                    />
                    <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['cmd_842'].errors?.pattern"
                        > Zip code must be atleast 5 digits and less than 10 digits.</small
                      >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel">PHONE NUMBER</label>
                  <input
                    class="form-control"
                    id="cmd_870"
                    name="cmd_870"
                    (keypress)="numberOnly($event)"
                    formControlName="cmd_870"
                    type="text"
                    data-id="prac1_mailingphone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel">FAX NUMBER </label>
                  <input
                    class="form-control"
                    id="cmd_871"
                    name="cmd_871"
                    formControlName="cmd_871"
                    type="text"
                    data-id="prac1_mailingfax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="col-md-12">
                  <label class="formLabel">E-MAIL </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (DicksonMDFroms.controls['759'].touched &&
                        DicksonMDFroms.controls['759'].invalid) ||
                      (DicksonMDFroms.controls['759'].invalid && isSubmit)
                    "
                    id="759"
                    name="759"
                    formControlName="759"
                    type="text"
                    data-id="hemail"
                  />
                  <div
                    class="formLabel col-md-12"
                    *ngIf="
                      DicksonMDFroms.controls['759'].touched &&
                      DicksonMDFroms.controls['759'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['759'].errors?.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['759'].errors.pattern"
                      >Please enter a valid email.</small
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div class="col-md-6">
                  <label class="formLabel">DATE OF BIRTH (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard"
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['31'].touched &&
                          DicksonMDFroms.controls['31'].invalid) ||
                        (DicksonMDFroms.controls['31'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d5="ngbDatepicker"
                      id="31"
                      name="31"
                      formControlName="31"
                      type="text"
                      data-id="dob_date"
                      (blur)="clearInputIfInvalidMandi('31')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d5.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(DicksonMDFroms.controls['31'].invalid&&DicksonMDFroms.controls['31'].touched) && (DicksonMDFroms.controls['31'].value==''|| DicksonMDFroms.controls['31'].value==null) " >
                    <small >
                    required
                    </small>
  
                  </div>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('31').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('31').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('31').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  
                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('31').errors?.invalidDateRange">
                      date is out of range
                    </small>
                </div>
              </td>
              <!-- <td colspan="7">
                              <div class="col-md-8">
                                  <div class="row">
                                      <label for="31" class="col-sm-4 pt-2 formLabel">DATE OF BIRTH (MM/DD/YYYY)<span
                                              class="mandatory">*</span></label>
                                      <div class="mb-3 col-md-8 ">
                                          <div class="input-group">
                                              <input class="form-control" formControlName="31" [class.is-invalid]="(DicksonMDFroms.controls['31'].touched && DicksonMDFroms.controls['31'].invalid) || (DicksonMDFroms.controls['31'].invalid && isSubmit)" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker #d5="ngbDatepicker" placement="bottom-left">
                                              <div class="input-group-append">
                                                  <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                              </div>
                                          </div>
                                          <div class="formLabel col-md-4"
                                              *ngIf="DicksonMDFroms.controls['31'].touched && DicksonMDFroms.controls['31'].invalid">
                                              <small class="text-danger" *ngIf="DicksonMDFroms.controls['31'].errors?.required">The
                                                  DOB is required and cannot be empty
                                              </small>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </td> -->
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PLACE OF BIRTH </label>
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control"
                    id="810"
                    [class.is-invalid]="
                      (DicksonMDFroms.controls['810'].touched &&
                        DicksonMDFroms.controls['810'].invalid) ||
                      (DicksonMDFroms.controls['810'].invalid && isSubmit)
                    "
                    name="810"
                    formControlName="810"
                    type="text"
                    data-id="bcity"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['810'].touched &&
                      DicksonMDFroms.controls['810'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['810'].errors?.required"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (DicksonMDFroms.controls['289'].touched &&
                          DicksonMDFroms.controls['289'].invalid) ||
                        (DicksonMDFroms.controls['289'].invalid && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="289"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['289'].touched &&
                        DicksonMDFroms.controls['289'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['289'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">CITIZENSHIP </label>
                  <input
                    class="form-control"
                    id="292"
                    name="292"
                    formControlName="292"
                    type="text"
                    data-id="citizenship"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS
                  </label>
                  <input
                    class="form-control"
                    id="1584"
                    name="1584"
                    formControlName="1584"
                    type="text"
                    data-id="Visa #"
                  />
                  <input
                    class="form-control"
                    id="1282"
                    name="1282"
                    formControlName="1282"
                    type="text"
                    data-id="Visa Status"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?
                  </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">CAQH ID</label>
                  <input
                    class="form-control"
                    id="333"
                    name="333"
                    formControlName="333"
                    type="text"
                    data-id="caqh"
                  />
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">USERNAME</label>
                  <input
                    class="form-control"
                    id="334"
                    name="334"
                    formControlName="334"
                    type="text"
                    data-id="caqh_username"
                  />
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <label class="formLabel">PASSWORD</label>
                  <input
                    class="form-control"
                    id="335"
                    name="335"
                    formControlName="335"
                    type="text"
                    data-id="caqh_pswd"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >U.S.MILITARY SERVICE/PUBLIC HEALTH
                  </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      (ngModelChange)="handleYesNoChanges('cmd_05')" [class.is-invalid]="(DicksonMDFroms.controls['cmd_05'].touched && DicksonMDFroms.controls['cmd_05'].invalid) || (DicksonMDFroms.controls['cmd_05'].invalid && isSubmit)"
                      
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_05"
                      name="cmd_05"
                      formControlName="cmd_05"
                      (ngModelChange)="handleYesNoChanges('cmd_05')" [class.is-invalid]="(DicksonMDFroms.controls['cmd_05'].touched && DicksonMDFroms.controls['cmd_05'].invalid) || (DicksonMDFroms.controls['cmd_05'].invalid && isSubmit)"
                      
                      value="0"
                    />No</label
                  >
                  <div class="formLabel col-md-11"
                    *ngIf="DicksonMDFroms.controls['cmd_05'].touched && DicksonMDFroms.controls['cmd_05'].invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.controls['cmd_05'].errors?.required">
                        required
                    </small>
                  </div>
                  <span *ngIf="(DicksonMDFroms.controls['cmd_05'].touched && DicksonMDFroms.controls['cmd_05'].errors) || (DicksonMDFroms.controls['cmd_05'].errors && isSubmit)">
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >DATES OF SERVICE (MM/DD/YYYY) TO (MM/DD/YYYY)
                  </label>
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['1144'].invalid"
                      (blur)="handleYesNoChanges('cmd_05')"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d6="ngbDatepicker"
                      id="1144"
                      name="1144"
                      formControlName="1144"
                      type="text"
                      data-id="Mil Active from"
                      (blur)="clearInputIfInvalid('1144','cmd_05')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d6.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1144'].invalid && (DicksonMDFroms.controls['1144'].value==''|| DicksonMDFroms.controls['1144'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                <div  *ngIf="DicksonMDFroms.get('1144').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('1144').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('1144').errors.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('1144').errors.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1144').errors.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
             
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      (blur)="handleYesNoChanges('cmd_05')"
                      [class.is-invalid]="(DicksonMDFroms.controls['1145'].invalid) || (DicksonMDFroms.controls['1145'].invalid && isSubmit)"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d7="ngbDatepicker"
                      id="1145"
                      name="1145"
                      formControlName="1145"
                      type="text"
                      data-id="Mil Active to"
                      (blur)="clearInputIfInvalid('1145','cmd_05')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d7.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1145'].invalid && (DicksonMDFroms.controls['1145'].value==''|| DicksonMDFroms.controls['1145'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('1145').invalid && DicksonMDFroms.get('1145').touched">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1145').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1145').errors.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1145').errors.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1145').errors.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">LAST LOCATION </label>
                  <input
                    class="form-control"
                    id="1139"
                    (blur)="handleYesNoChanges('cmd_05')"
                    [class.is-invalid]="DicksonMDFroms.controls['1139'].invalid"
                    name="1139"
                    formControlName="1139"
                    type="text"
                    data-id="Military Locaiton"
                  />
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1139'].invalid">
                    <small >
                    required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">BRANCH OF SERVICE </label>
                  <input
                    class="form-control"
                    (blur)="handleYesNoChanges('cmd_05')"
                      [class.is-invalid]="DicksonMDFroms.controls['1141'].invalid"
                    id="1141"
                    name="1141"
                    formControlName="1141"
                    type="text"
                    data-id="Mil branch"
                  />
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1141'].invalid">
                    <small >
                    required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?
                  </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_06"
                      name="cmd_06"
                      formControlName="cmd_06"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
          </table>  
          <table border="1" width="100%">
            <tr>
              <td colspan="14" style="width:50%;">
                <div class="col-md-12">
                  <b style="font-size:16px;">Education</b>
                </div>
              </td>
              <td colspan="6">
                <div >
                  <input formControlName="cmd_0722" (ngModelChange)="handleNAchange('cmd_0722')"  type="checkbox" value="1" id="cmd_0722">
                     <label class="form-check-label" for="cmd_0722">
                      <b style="font-size:12px;">Does Not Apply</b>
                     </label>
                </div>
              </td>              
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                <label class="formLabel">
                  <b style="font: size 13px;;">PROFESSIONAL DEGREE</b> (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)
                  </label>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Issuing Institution: </label>
                </div>
                <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['490'].invalid" 
                    id="490"
                    name="490"
                    formControlName="490"
                    type="text"
                    data-id="meds"
                    (blur)="handleNAchange('cmd_0722', 490)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['490'].touched &&
                      DicksonMDFroms.controls['490'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['490'].errors"
                    >
                      required
                    </small>
                  </div>
  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['491'].invalid" 
                    id="491"
                    name="491"
                    formControlName="491"
                    type="text"
                    data-id="medsadd1"
                    (blur)="handleNAchange('cmd_0722', 491)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['491'].touched &&
                      DicksonMDFroms.controls['491'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['491'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                    
                    id="492"
                    name="492"
                    formControlName="492"
                    type="text"
                    data-id="medsadd2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:1%;">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>
                  <div class="col-md-3">
                    <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['493'].invalid" 
                    id="493"
                    name="493"
                    formControlName="493"
                    type="text"
                    data-id="medsci"
                    (blur)="handleNAchange('cmd_0722', 493)"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['493'].touched &&
                        DicksonMDFroms.controls['493'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['493'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>  
                
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    
                      
                        <ng-select
                          [items]="states"
                          [class.is-invalid]="DicksonMDFroms.controls['495'].invalid" 
                          bindLabel="name"
                          placeholder="Select States"
                          bindValue="id"
                          formControlName="495"
                          (blur)="handleNAchange('cmd_0722', 495)"
                        >
                        </ng-select>
                      
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['495'].touched &&
                          DicksonMDFroms.controls['495'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['495'].errors"
                        >
                          required
                        </small>
                      </div>
                  </div>
                  
                  <div class="col-md-2">
                    <label class="formLabel">POSTAL CODE</label>
                  </div>
                  <div class="col-md-2">  
                    <input
                      class="form-control"
                      
                      [class.is-invalid]="DicksonMDFroms.controls['496'].invalid" 
                      id="496"
                      name="496"
                      formControlName="496"
                      type="text"
                      data-id="medsz"
                      (blur)="handleNAchange('cmd_0722', 496)"
                      
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['496'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['496'].errors"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          DicksonMDFroms.controls['496'].errors?.invalidZipCode
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">DEGREE </label>
                  <div class="col-md-10">
                    <ng-select
                      [items]="degree"
                      (blur)="handleNAchange('cmd_0722',500)"
                      [class.is-invalid]="DicksonMDFroms.controls['500'].invalid"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="500"
                    >
                    </ng-select>
                    <div class="mandatory"
                                       *ngIf=" DicksonMDFroms.controls['500'].invalid">
                                       <small>
                                        required
                                      </small>
                  </div>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div class="row">
                  <div
                    class="col-md-9"
                  >
                    <label class="formLabel"
                      >ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)</label
                    >
                    
                  </div>
                
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        (blur)="clearInputIfInvalidMandi('497')"
                         [class.is-invalid]="DicksonMDFroms.controls['497'].invalid"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d8="ngbDatepicker"
                        id="497"
                        name="497"
                        formControlName="497"
                        type="text"
                        (blur)="handleNAchange('cmd_0722',497)"
                        data-id="medsstart_date"
                        
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d8.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="mandatory" *ngIf="(DicksonMDFroms.controls['497'].invalid&&DicksonMDFroms.controls['497'].touched) && (DicksonMDFroms.controls['497'].value==''|| DicksonMDFroms.controls['497'].value==null) " >
                      <small >
                      required
                      </small>
                    </div>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('497').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('497').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('497').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  
                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('497').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker" (blur)="clearInputIfInvalidMandi('498')"
                         [class.is-invalid]="DicksonMDFroms.controls['498'].invalid"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d9="ngbDatepicker"
                        id="498"
                        name="498"
                        formControlName="498"
                        type="text"
                        (blur)="handleNAchange('cmd_0722',498)"
                        data-id="medsend_date"
                        
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d9.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    
                    <div class="mandatory" *ngIf="(DicksonMDFroms.controls['498'].invalid&&DicksonMDFroms.controls['498'].touched) && (DicksonMDFroms.controls['498'].value==''|| DicksonMDFroms.controls['498'].value==null) " >
                      <small >
                      required
                      </small>
                    </div>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('498').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('498').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('498').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  
                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('498').errors?.invalidDateRange">
                          date is out of range
                        </small>
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
                <td colspan="18" style="padding:10px;">
                  <div >
                    <input formControlName="cmd_001"   type="checkbox" value="1" id="cmd_001">
                       <label class="form-check-label" for="cmd_001">
                                <i class="formLabel">Please check this box and complete and submit Attachment A if you received other professional degrees.</i>
                       </label>
                           
                  </div>  
                </td>
              </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:13px;">POST-GRADUATE EDUCATION </b>
                </div>
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  formControlName="cmd_08"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  formControlName="cmd_08"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  formControlName="cmd_08"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_08"
                  name="cmd_08"
                  formControlName="cmd_08"
                  value="3"
                />Teaching Appointment
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SPECIALTY</label>
                </div>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="527"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="517"
                    name="517"
                    formControlName="517"
                    type="text"
                    data-id="interns"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="518"
                    name="518"
                    placeholder="Address"
                    formControlName="518"
                    type="text"
                    data-id="internadd1"
                  />
                  <input
                    class="form-control"
                    id="519"
                    name="519"
                    placeholder="Address"
                    formControlName="519"
                    type="text"
                    data-id="internadd2"
                  />
                </div>
              </td>
            </tr>            
            <tr>
              <td colspan="18" style="padding:1%;">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-3">
                    <input                     
                      class="form-control"
                      id="520"
                      name="520"
                      formControlName="520"
                      type="text"
                      data-id="internci"
                    />
                  </div>  
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="522"
                      >
                      </ng-select>
                  </div>                  
                  <div class="col-md-2">
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2"> 
                    <input
                      
                      class="form-control"
                      id="523"
                      name="523"
                      formControlName="523"
                      type="text"
                      data-id="internz"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['523'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                ></div>
                <input
                  type="checkbox"
                  id="cmd_09"
                  name="cmd_09"
                  formControlName="cmd_09"
                  value="1"
                /><label class="formLabel">Program successfully completed</label>
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d10="ngbDatepicker"
                      id="524"
                      name="524"
                      formControlName="524"
                      type="text"
                      data-id="internstart_date"
                      (blur)="clearInputIfInvalid('524')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d10.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('524').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('524').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('524').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('524').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('524').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                  
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d11="ngbDatepicker"
                      id="525"
                      name="525"
                      formControlName="525"
                      type="text"
                      data-id="internend_date"
                      (blur)="clearInputIfInvalid('525')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d11.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('525').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('525').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('525').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('525').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('525').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="528"
                    name="528"
                    formControlName="528"
                    type="text"
                    data-id="interndir"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                  </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="818"
                    name="818"
                    formControlName="818"
                    type="text"
                    data-id="interndir2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                <b style="font-size:13px;">POST-GRADUATE EDUCATION
                </b>
                </div>
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_010"
                  name="cmd_010"
                  formControlName="cmd_010"
                  value="3"
                />Teaching Appointment
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SPECIALTY</label>
                </div>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="530"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="529"
                    name="529"
                    formControlName="529"
                    type="text"
                    data-id="res"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="531"
                    name="531"
                    formControlName="531"
                    type="text"
                    data-id="resadd1"
                  />
                  <input
                    class="form-control"
                    id="1498"
                    name="1498"
                    formControlName="1498"
                    type="text"
                    data-id="resadd2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="533"
                      name="533"
                      formControlName="533"
                      type="text"
                      data-id="resci"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-3">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="535"
                      >
                      </ng-select>
                  </div>
                  <div class="col-md-2">
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2"> 
                    <input
                      
                      class="form-control"
                      id="536"
                      name="536"
                      formControlName="536"
                      type="text"
                      data-id="resz"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['536'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>      
                </div>
              </td>
            </tr>
          </table>  
          <hr class="tablePersonal" />
          <table border="1" width="100%">
            <td colspan="14">
              <div class="centered texasFirstTable">
                <b style="font-size:16px;">Education -continued</b>
              </div>
            </td>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                <b style="font-size:13px;">POST-GRADUATE EDUCATION
                </b>
                </div>
                <input
                  type="checkbox"
                  id="cmd_011"
                  name="cmd_011"
                  formControlName="cmd_011"
                  value="1"
                />Program successfully completed
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                </div>
                <div class="row">
                  <div class="input-group col-md-3">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d12="ngbDatepicker"
                      id="537"
                      name="537"
                      formControlName="537"
                      type="text"
                      data-id="resstart_date"
                      (blur)="clearInputIfInvalid('537')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d12.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('537').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('537').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('537').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('537').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('537').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                  
                  <div class="input-group col-md-3">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d13="ngbDatepicker"
                      id="538"
                      name="538"
                      formControlName="538"
                      type="text"
                      data-id="resend_date"
                      (blur)="clearInputIfInvalid('538')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d13.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('538').invalid && DicksonMDFroms.get('538').touched">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('538').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('538').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('538').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('538').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="540"
                    name="540"
                    formControlName="540"
                    type="text"
                    data-id="resdir1"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)</label
                  >
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="541"
                    name="541"
                    formControlName="541"
                    type="text"
                    data-id="resdir2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                <div >
                  <input formControlName="cmd_002"   type="checkbox" value="1" id="cmd_002">
                     <label class="form-check-label" for="cmd_002">
                              <i class="formLabel">Please check this box and complete and submit Attachment B if you received additional postgraduate training. </i>
                     </label>
                         
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:13px;">OTHER GRADUATE-LEVEL EDUCATION</b>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <label class="formLabel">Issuing Institution: </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="479"
                      name="479"
                      formControlName="479"
                      type="text"
                      data-id="grad_school1"
                    />
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="483"
                    name="483"
                    formControlName="483"
                    type="text"
                    data-id="Training Pro 1 address1"
                  />
                  <input
                    class="form-control"
                    id="484"
                    name="484"
                    formControlName="484"
                    type="text"
                    data-id="Training Pro 1 address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:1%;">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="485"
                      name="485"
                      formControlName="485"
                      type="text"
                      data-id="Training Pro 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-3">
                        <ng-select
                          [items]="states"
                          bindLabel="name"
                          placeholder="Select States"
                          bindValue="id"
                          formControlName="487"
                        >
                        </ng-select>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="488"
                      name="488"
                      formControlName="488"
                      type="text"
                      data-id="Training Pro 1 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['488'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>               
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">DEGREE</label>
                  
                    <div class="col-md-12">
                      <ng-select
                        [items]="degree"
                        bindLabel="name"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="480"
                      >
                      </ng-select>
                    </div>  
                  
                </div>
              </td>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                </div>
                <div class="row">
                  <div class="input-group col-md-3">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d14="ngbDatepicker"
                      id="481"
                      name="481"
                      formControlName="481"
                      type="text"
                      style="width:25%;"
                      data-id="Training Pro 1 from"
                      (blur)="clearInputIfInvalid('481')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d14.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('481').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('481').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('481').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('481').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('481').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>                
                  <div class="input-group col-md-3">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d15="ngbDatepicker"
                      id="1300"
                      name="1300"
                      style="width:25%;"
                      formControlName="1300"
                      type="text"
                      data-id="Training Pro 1 to"
                      (blur)="clearInputIfInvalid('1300')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d15.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('1300').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1300').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                  </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1300').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1300').errors?.invalidDateyear">
                        year is out of range(1900-2099)                    </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('1300').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>                  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:14px;"
                    >Licenses and Certificates</b
                  >
                  <label style="font-size:14px;">
                  - Please include all license(s) and certifications in all States
                  where you are currently or have previously been licensed.</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE TYPE </label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="296"
                    name="296"
                    formControlName="296"
                    type="text"
                    data-id="licensetype"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE NUMBER </label>
                  <input
                    style="width:70%;"
                    [class.is-invalid]="DicksonMDFroms.controls['293'].invalid" 
                    class="form-control"
                    id="293"
                    name="293"
                    formControlName="293"
                    type="text"
                    data-id="license"
                    (blur)="handleNAchange('cmd_07259', 293)"
                  />
                  <div
                  class="formLabel col-md-2"
                  *ngIf="
                    DicksonMDFroms.controls['293'].touched &&
                    DicksonMDFroms.controls['293'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['293'].errors"
                  >
                    required
                  </small>
                </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12"
                  style="padding: 2px;"
                >
                  <label class="formLabel">STATE OF REGISTRATION </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="DicksonMDFroms.controls['299'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="299"
                        (blur)="handleNAchange('cmd_07259', 299)"
                      >
                      </ng-select>
                    </div>
                    <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['299'].touched &&
                      DicksonMDFroms.controls['299'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['299'].errors"
                    >
                      required
                    </small>
                  </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:50%;"
                      [class.is-invalid]="DicksonMDFroms.controls['294'].invalid" 
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d16="ngbDatepicker"
                      id="294"
                      name="294"
                      formControlName="294"
                      type="text"
                      data-id="licenseissuedate"
                      (blur)="handleNAchange('cmd_07259', 294)"
                      (blur)="clearInputIfInvalid('294','cmd_07259')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d16.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['294'].invalid && (DicksonMDFroms.controls['294'].value==''|| DicksonMDFroms.controls['294'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('294').invalid && DicksonMDFroms.get('294').touched">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('294').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('294').errors.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('294').errors.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('294').errors.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width:50%;"
                      [class.is-invalid]="DicksonMDFroms.controls['295'].invalid" 
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d17="ngbDatepicker"
                      id="295"
                      name="295"
                      formControlName="295"
                      type="text"
                      data-id="licenseexp_date"
                      (blur)="handleNAchange('cmd_07259', 295)"
                      (blur)="clearInputIfInvalid('295','cmd_07259')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d17.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['295'].invalid && (DicksonMDFroms.controls['295'].value==''|| DicksonMDFroms.controls['295'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('295').invalid && DicksonMDFroms.get('295').touched">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('295').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('295').errors.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('295').errors.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('295').errors.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="mb-3 no-margin col-md-10 sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_013"
                      name="cmd_013"
                      formControlName="cmd_013"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_013"
                      name="cmd_013"
                      formControlName="cmd_013"
                      value="0"
                    />No</label
                  >
                </div>
                
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE TYPE </label>
                  <input
                    
                    class="form-control"
                    id="1274"
                    name="1274"
                    formControlName="1274"
                    type="text"
                    data-id="Alt License type"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE NUMBER </label>
                  <input
                    style="width: 60%"
                    class="form-control"
                    id="300"
                    name="300"
                    formControlName="300"
                    type="text"
                    data-id="alt license"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">STATE OF REGISTRATION </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="altLicenseState"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="907"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d18="ngbDatepicker"
                      id="307"
                      name="307"
                      formControlName="307"
                      type="text"
                      data-id="alt licesne issue"
                      (blur)="clearInputIfInvalid('307')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d18.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('307').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('307').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('307').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('307').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('307').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width: 48%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d19="ngbDatepicker"
                      id="308"
                      name="308"
                      formControlName="308"
                      type="text"
                      data-id="alt licesne exp"
                      (blur)="clearInputIfInvalid('308')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d19.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('308').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('308').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('308').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('308').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('308').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_014"
                      name="cmd_014"
                      formControlName="cmd_014"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_014"
                      name="cmd_014"
                      formControlName="cmd_014"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE TYPE </label>
                  <input
                    
                    class="form-control"
                    id="1432"
                    name="1432"
                    formControlName="1432"
                    type="text"
                    data-id="add_license_type"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LICENSE NUMBER </label>
                  <input
                    style="width: 60%"
                    class="form-control"
                    id="999"
                    name="999"
                    formControlName="999"
                    type="text"
                    data-id="add_license"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 marginStyle"
                >
                  <label class="formLabel">STATE OF REGISTRATION </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="additionalLicenseState"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1010"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d20="ngbDatepicker"
                      id="1003"
                      name="1003"
                      formControlName="1003"
                      type="text"
                      data-id="add license issue"
                      (blur)="clearInputIfInvalid('1003')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d20.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('1003').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1003').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1003').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1003').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1003').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width: 48%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d21="ngbDatepicker"
                      id="1004"
                      name="1004"
                      formControlName="1004"
                      type="text"
                      data-id="add license exp"
                      (blur)="clearInputIfInvalid('1004')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d21.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('1004').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1004').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1004').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1004').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('1004').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  DO YOU CURRENTLY PRACTICE IN THIS STATE?
                </div>
                <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_015"
                      name="cmd_015"
                      formControlName="cmd_015"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_015"
                      name="cmd_015"
                      formControlName="cmd_015"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                ></div>
                <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                  <label class="radio-inline radioStyle"></label>
                  <input
                    type="radio"
                    id="cmd_016"
                    name="cmd_016"
                    formControlName="cmd_016"
                    value="1"
                  />DEA Number:
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="309"
                    name="309"
                    formControlName="309"
                    type="text"
                    data-id="dea"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d22="ngbDatepicker"
                      id="310"
                      name="310"
                      formControlName="310"
                      type="text"
                      data-id="deaissue"
                      (blur)="clearInputIfInvalid('310')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d22.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('310').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('310').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('310').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('310').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('310').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width:52%;"
                      class="form-control onboard datepicker"
                      ngbDatepicker
                      #d23="ngbDatepicker"
                      placeholder="MM/DD/YYYY"
                      id="311"
                      name="311"
                      formControlName="311"
                      type="text"
                      data-id="dea_exp_date"
                      (blur)="clearInputIfInvalid('311')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d23.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('311').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('311').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('311').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('311').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('311').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                ></div>
                <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                  <label class="radio-inline radioStyle"></label>
                  <input
                    type="radio"
                    id="cmd_017"
                    name="cmd_017"
                    formControlName="cmd_017"
                    value="1"
                  />
                  CDS Number:
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="337"
                    name="337"
                    formControlName="337"
                    type="text"
                    data-id="driverslic"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:48%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d24="ngbDatepicker"
                      id="338"
                      name="338"
                      formControlName="338"
                      type="text"
                      data-id="drivers issue"
                      (blur)="clearInputIfInvalid('338')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d24.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('338').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('338').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('338').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('338').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('338').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width:52%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d25="ngbDatepicker"
                      id="339"
                      name="339"
                      formControlName="339"
                      type="text"
                      data-id="drivers exp"
                      (blur)="clearInputIfInvalid('339')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d25.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('339').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('339').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('339').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('339').errors?.invalidDateyear">
                      year is out of range(1900-2099)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('339').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"><b style="font-size:13px;">OTHER CDS</b> (PLEASE SPECIFY) </label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="cmd_019"
                    name="cmd_019"
                    formControlName="cmd_019"
                    type="text"
                    data-id="CDs other"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">NUMBER</label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="340"
                    name="340"
                    formControlName="340"
                    type="text"
                    data-id="CDs number"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">STATE OF REGISTRATION </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="AltCdsStates"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="997"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:50%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d26="ngbDatepicker"
                      id="341"
                      name="341"
                      formControlName="341"
                      type="text"
                      data-id="alt CDs issue date"
                      (blur)="clearInputIfInvalid('341')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d26.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
  
                </div>
                <div  *ngIf="DicksonMDFroms.get('341').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('341').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('341').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('341').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('341').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      style="width:48%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d27="ngbDatepicker"
                      id="342"
                      name="342"
                      formControlName="342"
                      type="text"
                      data-id="alt CDs Exp date"
                      (blur)="clearInputIfInvalid('342')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d27.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
  
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('342').invalid">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('342').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('342').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('342').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('342').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >DO YOU CURRENTLY PRACTICE IN THIS STATE?
                    <input
                      type="radio"
                      id="cmd_020"
                      name="cmd_020"
                      formControlName="cmd_020"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_020"
                      name="cmd_020"
                      formControlName="cmd_020"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">UPIN</label>
                  <input
                    class="form-control"
                    id="819"
                    name="819"
                    formControlName="819"
                    type="text"
                    data-id="upin"
                  />
                </div>
              </td>
              <td colspan="10">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >NATIONAL PROVIDER IDENTIFIER (WHEN AVAILABLE)
                  </label>
                  <input
                    class="form-control"
                    id="1634"
                    name="1634"
                    formControlName="1634"
                    type="text"
                    data-id="npi"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU A PARTICIPATING MEDICARE PROVIDER?
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="0"
                    />No</label
                  >
                </div>  
                  <div class="row">
                    <div class="col-md-5">
                      <label class="formLabel">Medicare Provider Number:</label>
                    </div>  
                    <div class="col-md-3">
                      <input
                        class="form-control"
                        id="325"
                        name="325"
                        formControlName="325"
                        type="text"
                        data-id="medicare"
                      />
                    </div>  
                  </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                    <input
                      type="radio"
                      id="cmd_022"
                      name="cmd_022"
                      formControlName="cmd_022"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_022"
                      name="cmd_022"
                      formControlName="cmd_022"
                      value="0"
                    />No</label
                  >
                </div>  
                <div class="row">
                  <div class="col-md-5">  
                    <label class="formLabel">Medicaid Provider Number:</label>
                  </div>  
                  <div class="col-md-5">  
                    <input
                      class="form-control"
                      id="812"
                      name="812"
                      formControlName="812"
                      type="text"
                      data-id="medicaid"
                    />
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="boldParagraph"
                    >EDUCATIONAL COUNCIL FOR FOREIGN MEDICAL GRADUATES (ECFMG)
                    <input
                      type="radio"
                      id="cmd_023"
                      name="cmd_023"
                      (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(DicksonMDFroms.controls['cmd_023'].touched && DicksonMDFroms.controls['cmd_023'].invalid) || (DicksonMDFroms.controls['cmd_023'].invalid && isSubmit)"
                      formControlName="cmd_023"
                      value="0"
                    />N/A</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_023"
                      name="cmd_023"
                      (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(DicksonMDFroms.controls['cmd_023'].touched && DicksonMDFroms.controls['cmd_023'].invalid) || (DicksonMDFroms.controls['cmd_023'].invalid && isSubmit)"
                      formControlName="cmd_023"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_023"
                      name="cmd_023"
                      (ngModelChange)="handleYesNoChanges('cmd_023')" [class.is-invalid]="(DicksonMDFroms.controls['cmd_023'].touched && DicksonMDFroms.controls['cmd_023'].invalid) || (DicksonMDFroms.controls['cmd_023'].invalid && isSubmit)"
                      formControlName="cmd_023"
                      value="2"
                    />No</label
                  >
                  <div class="mandatory" *ngIf="(DicksonMDFroms.controls['cmd_023'].invalid && isSubmit) ">
                    <small >
                    required
                    </small>
                  </div>
                  <div class="formLabel col-md-11"></div>
                  <label class="formLabel"> ECFMG Number:</label>
                  <input
                  [class.is-invalid]="(DicksonMDFroms.controls['514'].invalid) || (DicksonMDFroms.controls['cmd_023'].value ==1 && DicksonMDFroms.controls['514'].value=='') "   
                    class="text form-control col-md-3"
                    id="514"
                    name="514"
                    formControlName="514"
                    type="text"
                    data-id="ecfmg"
                  />
                
            <div class="mandatory" *ngIf="(DicksonMDFroms.controls['514'].invalid) || (DicksonMDFroms.controls['cmd_023'].value ==1 && DicksonMDFroms.controls['514'].value=='')">
              <small >
              required
              </small>
            </div>
                </div>
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ECFMG ISSUE DATE (MM/DD/YYYY) </label>
                </div>
                <div class="input-group">
                  <input
                    style="width:38%;"
                    class="form-control onboard datepicker"
                    placeholder="MM/DD/YYYY"
                    ngbDatepicker
                    #d28="ngbDatepicker"
                    id="515"
                    name="515"
                    formControlName="515"
                    type="text"
                    data-id="ecfmgissue_date"
                    (blur)="clearInputIfInvalid('515')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d28.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div  *ngIf="DicksonMDFroms.get('515').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('515').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('515').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('515').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('515').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
          </table>
          
          <table border="1" width="100%">
            <tr>
              <td colspan="14" style="width:60%;">
                <div class="col-md-12">
                  <b style="font-size:18px;">Professional/Specialty Information</b>
                </div>
              </td>
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_0726"
                  id="cmd_0726"
                  (ngModelChange)="handleNAchange('cmd_0726')"
                  formControlName="cmd_0726"
                  value="1"
                />
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div
                  class="col-md-12 "
                >
                  <b style="font-size:13px;">PRIMARY SPECIALTY </b>
                </div>  
                  
                    
                      <ng-select
                      class="col-md-12"
                        [items]="specialty"
                        [class.is-invalid]="DicksonMDFroms.controls['284'].invalid" 
                        bindLabel="name"
                        placeholder="Select Specialty"
                        bindValue="id"
                        formControlName="284"
                        (blur)="handleNAchange('cmd_0726', 284)"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['284'].touched &&
                          DicksonMDFroms.controls['284'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['284'].errors"
                        >
                          required
                        </small>
                      </div>
                     
                    
                
              </td>
              <td colspan="14">
                  
                <label class="boldParagraph">BOARD CERTIFIED?</label>
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    formControlName="cmd_024"
                    (blur)="handleNAchange('cmd_0726')"
                    (ngModelChange)="handleYesNoChanges('cmd_024')"
                    [class.is-invalid]="(DicksonMDFroms.controls['cmd_024'].touched &&
                    DicksonMDFroms.controls['cmd_024'].invalid) ||
                (DicksonMDFroms.controls['cmd_024'].invalid && isSubmit)"
                    
                    value="1"
                    
                  />Yes</label
                >
                <label class="radio-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_024"
                    name="cmd_024"
                    formControlName="cmd_024"
                    (blur)="handleNAchange('cmd_0726')"
                    (ngModelChange)="handleYesNoChanges('cmd_024')"
                    [class.is-invalid]="(DicksonMDFroms.controls['cmd_024'].touched &&
                    DicksonMDFroms.controls['cmd_024'].invalid) ||
                (DicksonMDFroms.controls['cmd_024'].invalid && isSubmit)"
                    
                    value="0"
                    
                  />No</label
                >
                <div class="mandatory"
                *ngIf="DicksonMDFroms.controls['cmd_024'].invalid">
          <small >
              required
          </small>
          </div>
                <span *ngIf="(DicksonMDFroms.controls['cmd_024'].touched && DicksonMDFroms.controls['cmd_024'].errors) || (DicksonMDFroms.controls['cmd_024'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
                <br />
            
                <div >
                  
                  
                    <label class="formLabel">Name of Certifying Board:</label>
                    
                      
                        <ng-select class="col-md-6"
                        [items]="boardCertification"
                        (blur)="handleYesNoChanges('cmd_024')" 
                        [class.is-invalid]="DicksonMDFroms.controls['554'].invalid"                              
                        placeholder="Primary Board Certification "
                        bindValue="id"
                        bindLabel="name"
                        formControlName="554"
                        
                        >
                        </ng-select>
                        <div class="mandatory"
                        *ngIf="DicksonMDFroms.controls['554'].invalid">
                  <small >
                      required
                  </small>
                           </div>
                      
                    
  
                </div>
              
              
            </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:80%;"
                      [class.is-invalid]="DicksonMDFroms.controls['557'].invalid" 
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d29="ngbDatepicker"
                      id="557"
                      name="557"
                      formControlName="557"
                      type="text"
                      data-id="primarybcert"
                      (blur)="handleNAchange('cmd_0726', 557)"
                      (blur)="clearInputIfInvalid('557','cmd_0726')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d29.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['557'].invalid && (DicksonMDFroms.controls['557'].value==''|| DicksonMDFroms.controls['557'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                </div>
                <div  *ngIf="DicksonMDFroms.get('557').invalid && DicksonMDFroms.get('557').touched">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('557').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('557').errors.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('557').errors.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('557').errors.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:48%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d30="ngbDatepicker"
                      id="559"
                      name="559"
                      formControlName="559"
                      type="text"
                      data-id="primarybrcert2"
                      (blur)="clearInputIfInvalid('559')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d30.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('559').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('559').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('559').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('559').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('559').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:51%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d31="ngbDatepicker"
                      id="560"
                      name="560"
                      formControlName="560"
                      type="text"
                      data-id="primarybexp_date"
                      (blur)="clearInputIfInvalid('560')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d31.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                
                </div>
                <div  *ngIf="DicksonMDFroms.get('560').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('560').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('560').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('560').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('560').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="col-md-12">
                  <label style="font-weight: normal;font-size: 14px">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                    APPLY.
                  </label>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="checkbox-inline" style="font-size:14px;font-weight:normal;">
                      <input type="checkbox" id="cmd_025" name="cmd_025" value="1" formControlName="cmd_025"> I have taken exam, results pending for Board
                    </label>
                  </div>  
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="cmd_1377"
                      name="cmd_1377"
                      formControlName="cmd_1377"
                      type="text"
                      data-id="test2"
                    />
                  </div> 
                </div>  
                <div class="row">
                  <div class="col-md-5">
                    <label class="checkbox-inline" style="font-size:14px;font-weight:normal;">
                      <input type="checkbox" id="cmd_026" name="cmd_026" value="1" formControlName="cmd_026"> I have taken Part I and am eligible for Part II of the Exam.
                    </label>
                  </div>  
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="cmd_030"
                      name="cmd_030"
                      formControlName="cmd_030"
                      type="text"
                      data-id="test2"
                    />
                  </div>    
                </div>  
                <div class="row">
                  <div class="col-md-4">
                    <label class="checkbox-inline" style="font-size:14px;font-weight:normal;">
                      <input type="checkbox" id="cmd_027" name="cmd_027" value="1" formControlName="cmd_027"> I am intending to sit for the Boards on (date)
                    </label>
                  </div>  
                  <div class="col-md-4">
                    <input
                      class="form-control"
                      id="cmd_031"
                      name="cmd_031"
                      formControlName="cmd_031"
                      type="text"
                      data-id="test2"
                    />
                  </div>    
                </div>  
                <div class="row">
                  <div class="col-md-4">
                    <label class="checkbox-inline" style="font-size:14px;font-weight:normal;">
                      <input type="checkbox" id="cmd_028" name="cmd_028" value="1" formControlName="cmd_028"> I am not planning to take Boards
                    </label>
                  </div>  
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <div class="row extraBoxPaddingLeft">
                    <div class="col-md-12">
                      <label class="formLabel"
                        >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                        SPECIALTY?
                      </label>
                    </div>
                  </div>
                  <br />
                  <label class="formLabel"><b>HMO:</b></label>
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      formControlName="cmd_032"
                      value="1"
                    />
                    Yes</label
                  >
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      formControlName="cmd_032"
                      value="0"
                    />
                    No
                  </label>
                  <br />
                  <label class="formLabel"><b>PPO:</b></label>
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      formControlName="cmd_033"
                      value="1"
                    />
                    Yes</label
                  >
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      formControlName="cmd_033"
                      value="0"
                    />
                    No
                  </label>
                  <br />
                  <label class="formLabel"><b>POS:</b></label>
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      formControlName="cmd_034"
                      value="1"
                    />Yes</label
                  >
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      formControlName="cmd_034"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div
                  class="col-md-12 "
                >
                  <label class="formLabel">SECONDARY SPECIALTY </label>
                </div>  
                <div
                  class="col-md-12 "
                >  
                    <ng-select class="col-md-12"
                      [items]="specialtySecondary"
                      bindLabel="name"
                      placeholder="Select Secondary Specialty"
                      bindValue="id"
                      formControlName="962"
                    >
                    </ng-select>
                </div>    
                  
                
              </td>
              <td colspan="14">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="boldParagraph">BOARD CERTIFIED?</label>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      formControlName="cmd_035"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      formControlName="cmd_035"
                      value="0"
                    />No</label
                  >
                  <br />
                  <div class="row extraBoxPaddingLeft">
                    <label class="formLabel">Name of Certifying Board:</label>
                    <div class="mb-3 selectDrop dropDrownSet ">
                      <div class="col-md-12">
                        <ng-select
                          [items]="boardCertification"
                          bindLabel="name"
                          placeholder="Select Secondary Board Certification"
                          bindValue="id"
                          formControlName="561"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:53%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d32="ngbDatepicker"
                      id="564"
                      name="564"
                      formControlName="564"
                      type="text"
                      data-id="secbcert"
                      (blur)="clearInputIfInvalid('564')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d32.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('564').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('564').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('564').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('564').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('564').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:47%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d33="ngbDatepicker"
                      id="565"
                      name="565"
                      formControlName="565"
                      type="text"
                      data-id="secbrcert2"
                      (blur)="clearInputIfInvalid('565')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d33.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
            
                </div>
                <div  *ngIf="DicksonMDFroms.get('565').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('565').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('565').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('565').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('565').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:50%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d34="ngbDatepicker"
                      id="567"
                      name="567"
                      formControlName="567"
                      type="text"
                      data-id="secbexp_date"
                      (blur)="clearInputIfInvalid('567')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d34.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                
                </div>
                <div  *ngIf="DicksonMDFroms.get('567').invalid">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('567').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('567').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('567').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('567').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
          </table>
  
          <hr class="tablePersonal" />
          <table border="1" width="100%">
              <td colspan="18">
              
                <div class="centered texasFirstTable">
                  <b style="font-size:16px;">Professional/Specialty Information</b><i style="font-size:13px;">-continued</i>
                </div>
              </td>
           <tr>
              <td colspan="18">
                
                  <div class="col-md-8">
                    <label class="formLabel"
                      >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                      APPLY.</label
                    >
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <label>
                    
                      <input
                        type="checkbox"
                        id="cmd_036"
                        name="cmd_036"
                        formControlName="cmd_036"
                        value="1"/>
                      I have taken exam, results pending for Board</label>
                    </div>
                    <div class="col-md-6">
                      <input
                        class="form-control"
                        id="cmd_1378"
                        name="cmd_1378"
                        formControlName="cmd_1378"
                        type="text"
                        data-id="test2"
                      />
                    </div> 
                  </div>  
                  <div class="row">
                    <div class="col-md-4">
                      <label>
                      <input
                        type="checkbox"
                        id="cmd_037"
                        name="cmd_037"
                        formControlName="cmd_037"
                        value="1"/>
                      I have taken Part I and am eligible for Part II of the Exam.</label>
                    </div>
                    <div class="col-md-4">   
                      <input
                        class="form-control"
                        id="cmd_041"
                        name="cmd_041"
                        formControlName="cmd_041"
                        type="text"
                        data-id="test5"
                      />
                    </div>
                  </div>  
                  <div class="row">             
                    <div class="col-md-4">
                      <label>
                    
                      <input
                        type="checkbox"
                        id="cmd_038"
                        name="cmd_038"
                        formControlName="cmd_038"
                        value="1"/>I am intending to sit for the Boards on (date)</label>
                    </div>
                    <div class="col-md-6">   
                      <input
                        style="width: 50%"
                        class="form-control"
                        id="cmd_042"
                        name="cmd_042"
                        formControlName="cmd_042"
                        type="text"
                        data-id="test6"
                      />
                    </div>
                  </div>  
                  <div class="row">
            
                    <div class="col-md-12">
                      <label>
                        <input
                          type="checkbox"
                          id="cmd_039"
                          name="cmd_039"
                          formControlName="cmd_039"
                          value="1"
                        />
                      I am not planning to take Boards</label>
                   
                    </div>
                  </div>  
                
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                    SPECIALTY?
                  </label>&nbsp;&nbsp;
                  
                  <label class="formLabel">HMO:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_043"
                      name="cmd_043"
                      formControlName="cmd_043"
                      value="1"
                    />
                    Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_043"
                      name="cmd_043"
                      formControlName="cmd_043"
                      value="0"
                    />
                    No
                  </label>&nbsp;&nbsp;
                  
                  <label class="formLabel">PPO:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_044"
                      name="cmd_044"
                      formControlName="cmd_044"
                      value="1"
                    />
                    Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_044"
                      name="cmd_044"
                      formControlName="cmd_044"
                      value="0"
                    />
                    No
                  </label>&nbsp;&nbsp;
                  
                  <label class="formLabel">POS:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_045"
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_045"
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="col-md-12">
                  <label class="formLabel">ADDITIONAL SPECIALTY </label>
                </div>  
                    <div class="col-md-12">
                      <ng-select class="col-md-12"
                        [items]="specialtyTer"
                        bindLabel="name"
                        placeholder="Select Specialty"
                        bindValue="id"
                        formControlName="965"
                      >
                      </ng-select>
                    </div>
                
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="boldParagraph">BOARD CERTIFIED?</label>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="0"
                    />No</label
                  >
                  <br />
                  <div class="row extraBoxPaddingLeft">
                    <label class="formLabel">Name of Certifying Board:</label>
                    <div class="mb-3 selectDrop dropDrownSet">
                      <div class="col-md-10">
                        <ng-select
                          [items]="boardCertification"
                          bindLabel="name"
                          placeholder="Select ADDTIONAL BOARD CERTIFICATON"
                          bindValue="id"
                          formControlName="946"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >INITIAL CERTIFICATION DATE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:69%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d35="ngbDatepicker"
                      id="953"
                      name="953"
                      formControlName="953"
                      type="text"
                      data-id="thrid board original issue date"
                      (blur)="clearInputIfInvalid('953')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d35.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('953').invalid">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('953').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('953').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('953').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('953').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >RECERTIFICATION DATE(S), IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:43%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d36="ngbDatepicker"
                      id="950"
                      name="950"
                      formControlName="950"
                      type="text"
                      data-id="Tertiary Board recert 2"
                      (blur)="clearInputIfInvalid('950')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d36.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('950').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('950').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('950').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('950').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('950').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >EXPIRATION DATE, IF APPLICABLE (MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:45%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d37="ngbDatepicker"
                      id="954"
                      name="954"
                      formControlName="954"
                      type="text"
                      data-id="Tertiary Board exp"
                      (blur)="clearInputIfInvalid('954')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d37.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  
                </div>
                <div  *ngIf="DicksonMDFroms.get('954').invalid ">
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('954').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('954').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="DicksonMDFroms.get('954').errors?.invalidDateyear">
                    year is out of range(1900-2099)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('954').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                
                  <div class="col-md-8">
                    <label class="formLabel"
                      >IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                      APPLY.</label
                    >
                  </div>  
                  <div class="row">
                    <div class="col-md-4">
                      <label class="formLabel"
                       
                      >
                        <input
                          type="checkbox"
                          id="cmd_047"
                          name="cmd_047"
                          formControlName="cmd_047"
                          value="1"/>
                        I have taken exam, results pending for Board</label>
                    </div>
                    <div class="col-md-6">
                      <input
                        class="form-control"
                        id="cmd_1379"
                        name="cmd_1379"
                        formControlName="cmd_1379"
                        type="text"
                        data-id="test2"
                      />
                    </div> 
                  </div>  
                  <div class="row">
                      <div class="col-md-4">
                        <label>
                          <input
                            type="checkbox"
                            id="cmd_048"
                            name="cmd_048"
                            formControlName="cmd_048"
                            value="1"/>
                          I have taken Part I and am eligible for Part II of the Exam.</label>
                      </div>
                      <div class="col-md-6">    
                          <input
                            style="width: 50%"
                            class="form-control"
                            id="cmd_052"
                            name="cmd_052"
                            formControlName="cmd_052"
                            type="text"
                            data-id="test2"
                        />
                      </div>
                  </div>  
                  <div class="row">             
                      <div class="col-md-4">
                        <label
                         
                        >
                          <input
                            type="checkbox"
                            id="cmd_049"
                            name="cmd_049"
                            formControlName="cmd_049"
                            value="1"/>I am intending to sit for the Boards on (date)</label>
                      </div>
                      <div class="col-md-6">
                          <input
                            style="width: 50%"
                            class="form-control"
                            id="cmd_053"
                            name="cmd_053"
                            formControlName="cmd_053"
                            type="text"
                            data-id="test3"
                        />
                      </div>
                  </div>  
                  <div class="row">
                      
                        <div class="col-md-12">
                          <label
                            
                          >
                            <input
                              type="checkbox"
                              id="cmd_050"
                              name="cmd_050"
                              formControlName="cmd_050"
                              value="1"
                            />
                            I am not planning to take Boards</label
                          >
                        </div>
                  </div>
               
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                    SPECIALTY?
                  </label>&nbsp;&nbsp;
                  
                  <label class="formLabel">HMO:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_054"
                      name="cmd_054"
                      formControlName="cmd_054"
                      value="1"
                    />
                    Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_054"
                      name="cmd_054"
                      formControlName="cmd_054"
                      value="0"
                    />
                    No
                  </label>&nbsp;&nbsp;
                  
                  <label class="formLabel">PPO:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_055"
                      name="cmd_055"
                      formControlName="cmd_055"
                      value="1"
                    />
                    Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_055"
                      name="cmd_055"
                      formControlName="cmd_055"
                      value="0"
                    />
                    No
                  </label>&nbsp;&nbsp;
                  <br />
                  <label class="formLabel">POS:</label>&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_056"
                      name="cmd_056"
                      formControlName="cmd_056"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="boldParagraph" class="formLabel">
                    <input
                      type="radio"
                      id="cmd_056"
                      name="cmd_056"
                      formControlName="cmd_056"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE INTEREST OR
                    FOCUS (HIV/AIDS, ETC.)
                  </label>
                  <input
                    class="form-control"
                    id="cmd_057"
                    name="cmd_057"
                    formControlName="cmd_057"
                    type="text"
                    data-id="other interest"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:16px;">Work History</b><i style="font-size:13px;">- Please provide a chronological work history. You may submit a
                    Curriculum Vitae as a supplement. Please explain all gaps in
                    employment that lasted more than six months.</i>
                </div>
              </td>
             
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  name="cmd_0727"
                  id="cmd_0727"
                  formControlName="cmd_0727"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_0727')"
                  class="texasFloat"
                />
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >CURRENT PRACTICE/EMPLOYER NAME
                    <input
                      class="form-control"
                      id="445"
                      name="445"
                      formControlName="445"
                      [class.is-invalid]="DicksonMDFroms.controls['445'].invalid" 
                      type="text"
                      data-id="wkhist1"
                      (blur)="handleNAchange('cmd_0727', 445)"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['445'].touched &&
                        DicksonMDFroms.controls['445'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['445'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['454'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d38="ngbDatepicker"
                      id="454"
                      name="454"
                      formControlName="454"
                      type="text"
                      data-id="wk1histstartdt"
                      (blur)="handleNAchange('cmd_0727', 454)"
                      (blur)="clearInputIfInvalid('454','cmd_0727')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d38.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['454'].invalid && (DicksonMDFroms.controls['454'].value==''|| DicksonMDFroms.controls['454'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('454').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('454').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('454').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('454').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('454').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['455'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d39="ngbDatepicker"
                      id="455"
                      name="455"
                      formControlName="455"
                      type="text"
                      data-id="wk1histenddt"
                      (blur)="handleNAchange('cmd_0727', 455)"
                      (blur)="clearInputIfInvalid('455','cmd_0727')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d39.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['455'].invalid && (DicksonMDFroms.controls['455'].value==''|| DicksonMDFroms.controls['455'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('455').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('455').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('455').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('455').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('455').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    [class.is-invalid]="DicksonMDFroms.controls['446'].invalid" 
                    class="form-control"
                    placeholder="Address 1"
                    id="446"
                    name="446"
                    formControlName="446"
                    type="text"
                    data-id="wkhist1add"
                    (blur)="handleNAchange('cmd_0727', 446)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['446'].touched &&
                      DicksonMDFroms.controls['446'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['446'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    
                    class="form-control"
                    placeholder="Address 2"
                    id="447"
                    name="447"
                    formControlName="447"
                    type="text"
                    data-id="wkhist1add2"
                  />
                 
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:1%;">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>
                  <div class="col-md-2">  
                    <input
                      placeholder="City"
                      [class.is-invalid]="DicksonMDFroms.controls['448'].invalid" 
                      class="form-control"
                      id="448"
                      name="448"
                      formControlName="448"
                      type="text"
                      data-id="Work hist 1 city"
                      (blur)="handleNAchange('cmd_0727', 448)"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['448'].touched &&
                        DicksonMDFroms.controls['448'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['448'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="DicksonMDFroms.controls['450'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="450"
                      (blur)="handleNAchange('cmd_0727', 450)"
                    >
                    </ng-select>
                  
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['450'].touched &&
                        DicksonMDFroms.controls['450'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['450'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input                     
                      [class.is-invalid]="DicksonMDFroms.controls['451'].invalid" 
                      class="form-control"
                      placeholder="Zip"
                      id="451"
                      name="451"
                      formControlName="451"
                      type="text"
                      data-id="Work hist 1 zip"
                      (blur)="handleNAchange('cmd_0727', 451)"
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['451'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['451'].errors"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          DicksonMDFroms.controls['451'].errors?.invalidZipCode
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>
                </div> 
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="456"
                      name="456"
                      formControlName="456"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d40="ngbDatepicker"
                      id="465"
                      name="465"
                      formControlName="465"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('465')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d40.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('465').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('465').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('465').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('465').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('465').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d41="ngbDatepicker"
                      id="466"
                      name="466"
                      formControlName="466"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('466')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d41.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('466').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('466').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('466').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('466').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('466').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="457"
                    name="457"
                    formControlName="457"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="458"
                    name="458"
                    formControlName="458"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="459"
                      name="459"
                      formControlName="459"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="DicksonMDFroms.controls['461'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="461"
                      (blur)="handleNAchange('cmd_0727', 461)"
                    >
                    </ng-select>
                  
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['461'].touched &&
                        DicksonMDFroms.controls['461'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['461'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="462"
                      name="462"
                      formControlName="462"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['462'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="1338"
                    name="1338"
                    formControlName="1338"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="467"
                      name="467"
                      formControlName="467"
                      type="text"
                      data-id="wkhist3"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d42="ngbDatepicker"
                      id="476"
                      name="476"
                      formControlName="476"
                      type="text"
                      data-id="wkhist3startdt"
                      (blur)="clearInputIfInvalid('476')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d42.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('476').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('476').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('476').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('476').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('476').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d43="ngbDatepicker"
                      id="477"
                      name="477"
                      formControlName="477"
                      type="text"
                      data-id="wkhist3enddt"
                      (blur)="clearInputIfInvalid('477')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d43.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('477').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('477').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('477').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('477').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('477').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="468"
                    name="468"
                    formControlName="468"
                    type="text"
                    data-id="wkhist3add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="469"
                    name="469"
                    formControlName="469"
                    type="text"
                    data-id="wkhist3address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="470"
                      name="470"
                      formControlName="470"
                      type="text"
                      data-id="Work hist 3 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div> 
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="DicksonMDFroms.controls['472'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="472"
                        (blur)="handleNAchange('cmd_0727', 472)"
                      >
                      </ng-select>
                    
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['472'].touched &&
                        DicksonMDFroms.controls['472'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['472'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="473"
                      name="473"
                      formControlName="473"
                      type="text"
                      data-id="Work hist 3 zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['473'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="1342"
                    name="1342"
                    formControlName="1342"
                    type="text"
                    data-id="wkhist3reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="1935"
                      name="1935"
                      formControlName="1935"
                      type="text"
                      data-id="wkhist4"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d44="ngbDatepicker"
                      id="1947"
                      name="1947"
                      formControlName="1947"
                      type="text"
                      data-id="wkhist4startdt"
                      (blur)="clearInputIfInvalid('1947')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d44.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('1947').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1947').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1947').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1947').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1947').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d45="ngbDatepicker"
                      id="1948"
                      name="1948"
                      formControlName="1948"
                      type="text"
                      data-id="wkhist4enddt"
                      (blur)="clearInputIfInvalid('1948')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d45.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('1948').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1948').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1948').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1948').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1948').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="1936"
                    name="1936"
                    formControlName="1936"
                    type="text"
                    data-id="wkhist4add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="1937"
                    name="1937"
                    formControlName="1937"
                    type="text"
                    data-id="wkhist4address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:1%">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="1938"
                      name="1938"
                      formControlName="1938"
                      type="text"
                      data-id="Work hist 4 city"
                    />
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1940"
                      >
                      </ng-select>
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="1941"
                      name="1941"
                      formControlName="1941"
                      type="text"
                      data-id="Work hist 4 zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['1941'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="1952"
                    name="1952"
                    formControlName="1952"
                    type="text"
                    data-id="wkhist4reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="60%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">
                    PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX
                    MONTHS (MM/DD/YYYY TO MM/DD/YYYY) IN WORK HISTORY.
                  </label>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Gap Dates: </label>
                  <div class="input-group">
                    <input
                      
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d46="ngbDatepicker"
                      id="3395"
                      name="3395"
                      formControlName="3395"
                      type="text"
                      data-id="Gap from 1"
                      (blur)="clearInputIfInvalid('3395')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d46.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3395').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3395').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3395').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3395').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3395').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      style="width: 53%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d47="ngbDatepicker"
                      id="3396"
                      name="3396"
                      formControlName="3396"
                      type="text"
                      data-id="Gap to 1"
                      (blur)="clearInputIfInvalid('3396')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d47.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3396').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3396').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3396').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3396').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3396').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Explanation: </label>
                  <input
                    style="width: 50%"
                    class="form-control"
                    id="1138"
                    name="1138"
                    formControlName="1138"
                    type="text"
                    data-id="Gap 1"
                  />
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Gap Dates:</label>
                  <div class="input-group">
                    <input
                      
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d48="ngbDatepicker"
                      id="3397"
                      name="3397"
                      formControlName="3397"
                      type="text"
                      data-id="Gap from 2"
                      (blur)="clearInputIfInvalid('3397')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d48.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3397').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3397').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3397').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3397').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3397').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      style="width: 53%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d49="ngbDatepicker"
                      id="3398"
                      name="3398"
                      formControlName="3398"
                      type="text"
                      data-id="Gap to 2"
                      (blur)="clearInputIfInvalid('3398')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d49.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3398').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3398').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3398').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3398').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3398').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel"> Explanation: </label>
                  <input
                    style="width: 50%"
                    class="form-control"
                    id="1831"
                    name="1831"
                    formControlName="1831"
                    type="text"
                    data-id="Gap 2"
                  />
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Gap Dates:</label>
                  <div class="input-group">
                    <input
                      
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d50="ngbDatepicker"
                      id="3399"
                      name="3399"
                      formControlName="3399"
                      type="text"
                      data-id="Gap from 3.0"
                      (blur)="clearInputIfInvalid('3399')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d50.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3399').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3399').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3399').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3399').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3399').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      style="width: 53%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d51="ngbDatepicker"
                      id="3400"
                      name="3400"
                      formControlName="3400"
                      type="text"
                      data-id="Gap to 3.0"
                      (blur)="clearInputIfInvalid('3400')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d51.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3400').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3400').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3400').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3400').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3400').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel"> Explanation: </label>
                  <input
                    style="width: 50%"
                    class="form-control"
                    id="1832"
                    name="1832"
                    formControlName="1832"
                    type="text"
                    data-id="Gap 3.0"
                  />
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">Gap Dates:</label>
                  <div class="input-group">
                    <input
                      
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d52="ngbDatepicker"
                      id="3401"
                      name="3401"
                      formControlName="3401"
                      type="text"
                      data-id="Gap from 3.1"
                      (blur)="clearInputIfInvalid('3401')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d52.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3401').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3401').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3401').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3401').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3401').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      style="width: 53%"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d53="ngbDatepicker"
                      id="3402"
                      name="3402"
                      formControlName="3402"
                      type="text"
                      data-id="Gap to 3.1"
                      (blur)="clearInputIfInvalid('3402')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d53.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('3402').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3402').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3402').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('3402').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('3402').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel"> Explanation: </label>
                  <input
                    style="width: 50%"
                    class="form-control"
                    id="1953"
                    name="1953"
                    formControlName="1953"
                    type="text"
                    data-id="Gap 3.1"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                <div >
                  <input formControlName="cmd_003"   type="checkbox" value="1" id="cmd_003">
                     <label class="form-check-label" for="cmd_003">
                              <i class="formLabel">Please check this box and complete and submit Attachment C if you have additional work history</i>
                     </label>
                         
              </div>  
              </td>
            </tr>
          </table>
  
          <hr class="tablePersonal" />
          <table border="1" width="100%">
            <tr>
              <td colspan="14">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:16px;">Hospital Affiliations</b><i style="font-size:13px;">-Please include all hospitals where you currently have or have
                  previously had privileges.</i>
                </div>
              </td>
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  name="cmd_0723"
                  id="cmd_0723"
                  formControlName="cmd_0723"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_0723')"
                  class="texasFloat"
                />
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >DO YOU HAVE HOSPITAL PRIVILEGES?
                  </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_059"
                      name="cmd_059"
                      formControlName="cmd_059"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_059"
                      name="cmd_059"
                      formControlName="cmd_059"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >IF YOU DO NOT HAVE ADMITTING PRIVILEGES, WHAT ADMITTING
                    ARRANGEMENTS DO YOU HAVE?
                  </label>
                  <input
                    class="form-control"
                    id="cmd_060"
                    name="cmd_060"
                    formControlName="cmd_060"
                    type="text"
                    data-id="hospaff1arrangements"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >PRIMARY HOSPITAL WHERE YOU HAVE ADMITTING PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['418'].invalid" 
                    id="418"
                    name="418"
                    formControlName="418"
                    type="text"
                    data-id="hospaff1nm"
                    (blur)="handleNAchange('cmd_0723', 418)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['418'].touched &&
                      DicksonMDFroms.controls['418'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['418'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['426'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d54="ngbDatepicker"
                      id="426"
                      name="426"
                      formControlName="426"
                      type="text"
                      data-id="hospaff1app"
                      (blur)="handleNAchange('cmd_0723', 426)"
                      (blur)="clearInputIfInvalid('426','cmd_0723')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d54.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['426'].invalid && (DicksonMDFroms.controls['426'].value==''|| DicksonMDFroms.controls['426'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('426').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('426').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('426').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('426').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('426').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['419'].invalid" 
                    id="419"
                    name="419"
                    formControlName="419"
                    type="text"
                    data-id="hospaff1add1"
                    (blur)="handleNAchange('cmd_0723', 419)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['419'].touched &&
                      DicksonMDFroms.controls['419'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['419'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                    
                    id="420"
                    name="420"
                    formControlName="420"
                    type="text"
                    data-id="hospaff1add2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
               <div
                class="row"
                >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                    <div class="col-md-2">
                      <input                    
                        [class.is-invalid]="DicksonMDFroms.controls['421'].invalid" 
                        class="form-control"
                        placeholder="City"
                        id="421"
                        name="421"
                        formControlName="421"
                        type="text"
                        data-id="hospaff1city"
                        (blur)="handleNAchange('cmd_0723', 421)"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['421'].touched &&
                          DicksonMDFroms.controls['421'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['421'].errors"
                        >
                          required
                        </small>
                      </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  
                    <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="DicksonMDFroms.controls['423'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="423"
                        (blur)="handleNAchange('cmd_0723', 423)"
                      >
                      </ng-select>
                    </div>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['423'].touched &&
                        DicksonMDFroms.controls['423'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['423'].errors"
                      >
                        required
                      </small>
                    </div>               
                    <div
                      class="col-md-2"
                    >
                      <label class="formLabel">POSTAL CODE</label>
                    </div>  
                    <div class="col-md-2">
                      <input                   
                        [class.is-invalid]="DicksonMDFroms.controls['424'].invalid" 
                        class="form-control"
                        placeholder="Zip"
                        id="424"
                        name="424"
                        formControlName="424"
                        type="text"
                        data-id="hospaff1zip"
                        (blur)="handleNAchange('cmd_0723', 424)"
                      />
                      <div
                        class="formLabel col-md-12"
                        *ngIf="
                          DicksonMDFroms.controls['424'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['424'].errors"
                        >
                          required
                        </small>
                        <small
                          class="text-danger"
                          *ngIf="
                            DicksonMDFroms.controls['424'].errors?.invalidZipCode
                          "
                        >
                          Zip code must be atleast 5 digits and less than 10 digits
                        </small>
                      </div>
                    </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">PHONE NUMBER </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="DicksonMDFroms.controls['1305'].invalid" 
                    id="1305"
                    name="1305"
                    formControlName="1305"
                    type="text"
                    data-id="hospaff1phone"
                    (blur)="handleNAchange('cmd_0723', 1305)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['1305'].touched &&
                      DicksonMDFroms.controls['1305'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1305'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">FAX</label>
                  <input
                    class="form-control"
                    id="2136"
                    name="2136"
                    formControlName="2136"
                    type="text"
                    data-id="hospaff1fax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL </label>
                  <input
                    class="form-control"
                    id="2142"
                    name="2142"
                    formControlName="2142"
                    type="text"
                    data-id="hospaff1email"
                  />
                  <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['2142'].errors?.pattern"
                        >Please enter a valid email.</small
                      >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_061"
                      name="cmd_061"
                      formControlName="cmd_061"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_061"
                      name="cmd_061"
                      formControlName="cmd_061"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <div class="col-md-12">
                      <ng-select
                        [items]="staffStatus"
                        bindLabel="name"
                        placeholder="Select Status"
                        bindValue="id"
                        formControlName="894"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_062"
                      name="cmd_062"
                      formControlName="cmd_062"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_062"
                      name="cmd_062"
                      formControlName="cmd_062"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                    PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                  </label>
                  <input
                    class="form-control"
                    id="cmd_063"
                    name="cmd_063"
                    formControlName="cmd_063"
                    type="text"
                    data-id="hospaff1adimissons"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                  </label>
                  <input
                    class="form-control"
                    id="427"
                    name="427"
                    formControlName="427"
                    type="text"
                    data-id="hospaff2nm"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-5 sideLabelAlignment"
                >
                  <label class="formLabel">START DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d55="ngbDatepicker"
                      id="435"
                      name="435"
                      formControlName="435"
                      type="text"
                      data-id="hospaff2app"
                      (blur)="clearInputIfInvalid('435')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d55.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('435').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('435').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('435').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('435').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('435').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">ADDRESS</label></div>&nbsp;&nbsp;
                  <div class="col-md-2">  
                    <input
                      class="form-control"
                      id="428"
                      name="428"
                      formControlName="428"
                      type="text"
                      data-id="hospaff2add.0"
                    />
                  </div>
                  <div class="col-md-2">    
                    <input
                      class="form-control"
                      id="429"
                      name="429"
                      formControlName="429"
                      type="text"
                      data-id="hospaff2add.1"
                    />
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="430"
                      name="430"
                      formControlName="430"
                      type="text"
                      placeholder="City"
                      data-id="hospaff2city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  
                    <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="DicksonMDFroms.controls['432'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="432"
                        (blur)="handleNAchange('cmd_0723', 432)"
                      >
                      </ng-select>
                    </div>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['432'].touched &&
                        DicksonMDFroms.controls['432'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['432'].errors"
                      >
                        required
                      </small>
                    </div>
                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      id="433"
                      name="433"
                      formControlName="433"
                      type="text"
                      placeholder="Zip"
                      data-id="hospaff2zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['433'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small>
                  </div>  

                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PHONE NUMBER </label>&nbsp;
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="1307"
                    name="1307"
                    formControlName="1307"
                    type="text"
                    data-id="hospaff2phone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8 sideLabelAlignment"
                >
                  <label class="formLabel">FAX</label>&nbsp;&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="2137"
                    name="2137"
                    formControlName="2137"
                    type="text"
                    data-id="hospaff2fax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL</label>&nbsp;
                  <input
                    class="form-control"
                    id="2143"
                    name="2143"
                    formControlName="2143"
                    type="text"
                    data-id="hospaff2email"
                  />
                  <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['2143'].errors?.pattern"
                        >Please enter a valid email.</small
                      >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_064"
                      name="cmd_064"
                      formControlName="cmd_064"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_064"
                      name="cmd_064"
                      formControlName="cmd_064"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                  <div class="col-md-8">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="895"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_065"
                      name="cmd_065"
                      formControlName="cmd_065"
                      value="1"
                    />Yes</label
                  >&nbsp;
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_065"
                      name="cmd_065"
                      formControlName="cmd_065"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE
                    PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                  </label>
                  <input
                    class="form-control"
                    id="cmd_066"
                    name="cmd_066"
                    formControlName="cmd_066"
                    type="text"
                    data-id="hospaff2admissons"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                  <div >
                    <input formControlName="cmd_0005"   type="checkbox" value="1" id="cmd_0005">
                      <label class="form-check-label" for="cmd_0005">
                                <i class="formLabel">Please check this box and complete and submit Attachment D if you have additional <u>current</u> hospital.</i>
                      </label>
                          
                  </div>  
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label
                  >
                  <input
                    class="form-control"
                    id="1183"
                    name="1183"
                    formControlName="1183"
                    type="text"
                    data-id="Pre aff 1"
                  />
                </div>
              </td>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width: 30%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d56="ngbDatepicker"
                      id="1191"
                      name="1191"
                      formControlName="1191"
                      type="text"
                      data-id="Pre aff 1 from"
                      (blur)="clearInputIfInvalid('1191')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d56.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('1191').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d57="ngbDatepicker"
                      id="2381"
                      name="2381"
                      formControlName="2381"
                      type="text"
                      data-id="Pre aff 1 to"
                      (blur)="clearInputIfInvalid('2381')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d57.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('2381').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('2381').errors?.invalidDateRange">
                        date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="1184"
                    name="1184"
                    formControlName="1184"
                    type="text"
                    data-id="Pre aff 1 add1"
                  />
                  <input
                    class="form-control"
                    id="1185"
                    name="1185"
                    formControlName="1185"
                    type="text"
                    data-id="Pre aff 1 add2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                  >
                  <div class="col-md-1">
                      <label class="formLabel">CITY</label>
                  </div>    
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="1186"
                      name="1186"
                      formControlName="1186"
                      type="text"
                      placeholder="City"
                      data-id="Pre aff 1 city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="DicksonMDFroms.controls['1188'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1188"
                      (blur)="handleNAchange('cmd_0723', 1188)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['1188'].touched &&
                        DicksonMDFroms.controls['1188'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['1188'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2">
                      <input
                        
                        class="form-control"
                        id="1189"
                        name="1189"
                        formControlName="1189"
                        type="text"
                        placeholder="Zip"
                        data-id="Pre aff 1 zip"
                      />
                      <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1189'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>

              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_068"
                      name="cmd_068"
                      formControlName="cmd_068"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_068"
                      name="cmd_068"
                      formControlName="cmd_068"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel"
                    >TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                  </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="staffStatus"
                      bindLabel="name"
                      placeholder="Select Status"
                      bindValue="id"
                      formControlName="1192"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_069"
                      name="cmd_069"
                      formControlName="cmd_069"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_069"
                      name="cmd_069"
                      formControlName="cmd_069"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                  <input
                    class="form-control"
                    id="1303"
                    name="1303"
                    formControlName="1303"
                    type="text"
                    data-id="Pre aff 1 leaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" style="padding:10px;">
                  <div >
                    <input formControlName="cmd_005"   type="checkbox" value="1" id="cmd_005">
                      <label class="form-check-label" for="cmd_005">
                                <i class="formLabel">Please check this box and complete and submit Attachment E if you have additional <u>previous</u>  hospital affiliations.</i>
                      </label>
                          
                  </div>  
              </td>
            </tr>
          </table>
  
          <hr class="tablePersonal" />
          <table border="1" width="100%">
            <tr>
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:16px;">References</b><i style="font-size:13px;">-Please provide three peer references from the same field
                    and/or specialty who are not partners in your own group practice
                    and are not relatives. All peer references should have firsthand
                    knowledge of your abilities.</i>
                </div>
              </td>
             
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  class="texasFloat"
                  type="checkbox"
                  name="cmd_0724"
                  formControlName="cmd_0724"
                  id="cmd_0724"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_0724')"
                  class="texasFloat"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6">
                
                  <label class="formLabel">1. NAME/TITLE</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['359'].invalid"
                    (blur)="handleNAchange('cmd_0724', 359)"
                    id="359"
                    name="359"
                    formControlName="359"
                    type="text"
                    data-id="reference1name"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['359'].touched &&
                      DicksonMDFroms.controls['359'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['359'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="formLabel">E-MAIL</label>
                  <input
                  
                    [class.is-invalid]="DicksonMDFroms.controls['371'].invalid"
                    (blur)="handleNAchange('cmd_0724', 371)"
                    class="form-control"
                    id="371"
                    name="371"
                    formControlName="371"
                    type="text"
                    data-id="reference1email"
                  />
                  <div
                    class="formLabel col-md-12"
                    *ngIf="
                      DicksonMDFroms.controls['371'].touched &&
                      DicksonMDFroms.controls['371'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['371'].errors"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['371'].errors.invalidEmail"
                      >Please enter a valid email.</small
                    >
                  </div>
     
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >PHONE NUMBER
                    <input
                      class="form-control"
                      (keypress)="numberOnly($event)"
                      [class.is-invalid]="DicksonMDFroms.controls['369'].invalid"
                      (blur)="handleNAchange('cmd_0724', 369)"
                      id="369"
                      name="369"
                      formControlName="369"
                      type="text"
                      data-id="reference1telephone"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['369'].touched &&
                        DicksonMDFroms.controls['369'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['369'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >FAX NUMBER
                    <input
                      class="form-control"
                      id="370"
                      name="370"
                      formControlName="370"
                      type="text"
                      data-id="reference1fax"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    style="width: 40%;"
                    [class.is-invalid]="DicksonMDFroms.controls['360'].invalid"
                    (blur)="handleNAchange('cmd_0724', 360)"
                    class="form-control"
                    id="360"
                    name="360"
                    formControlName="360"
                    type="text"
                    data-id="reference1address"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['360'].touched &&
                      DicksonMDFroms.controls['360'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['360'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    style="width: 40%;"
                    
                    class="form-control"
                    id="361"
                    name="361"
                    formControlName="361"
                    type="text"
                    data-id="reference1address2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      [class.is-invalid]="DicksonMDFroms.controls['362'].invalid"
                      (blur)="handleNAchange('cmd_0724', 362)"
                      class="form-control"
                      id="362"
                      name="362"
                      formControlName="362"
                      type="text"
                      data-id="reference1city"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['362'].touched &&
                        DicksonMDFroms.controls['362'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['362'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="DicksonMDFroms.controls['364'].invalid"
                      (blur)="handleNAchange('cmd_0724', 364)"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="364"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['364'].touched &&
                        DicksonMDFroms.controls['364'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['364'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>               
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div class="col-md-2">
                    <input    
                      [class.is-invalid]="DicksonMDFroms.controls['365'].invalid"
                      (blur)="handleNAchange('cmd_0724', 365)"
                      class="form-control"
                      id="365"
                      name="365"
                      formControlName="365"
                      type="text"
                      data-id="reference1zip"
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['365'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['365'].errors"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          DicksonMDFroms.controls['365'].errors?.invalidZipCode
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                
                  <label class="formLabel">2. NAME/TITLE</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['372'].invalid"
                    (blur)="handleNAchange('cmd_0724', 372)"
                    id="372"
                    name="372"
                    formControlName="372"
                    type="text"
                    data-id="reference2name"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['372'].touched &&
                      DicksonMDFroms.controls['372'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['372'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="formLabel"
                    >E-MAIL</label>
                    <input
                      class="form-control"
                      [class.is-invalid]="DicksonMDFroms.controls['383'].invalid"
                      (blur)="handleNAchange('cmd_0724', 383)"
                      id="383"
                      name="383"
                      formControlName="383"
                      type="text"
                      data-id="Text8.1.0"
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['383'].touched &&
                        DicksonMDFroms.controls['383'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['383'].errors"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['383'].errors.invalidEmail"
                        >Please enter a valid email.</small
                      >
                    </div>
            
            
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >PHONE NUMBER
                    <input
                      class="form-control"
                      (keypress)="numberOnly($event)"
                      [class.is-invalid]="DicksonMDFroms.controls['381'].invalid"
                      (blur)="handleNAchange('cmd_0724', 381)"
                      id="381"
                      name="381"
                      formControlName="381"
                      type="text"
                      data-id="reference2telephone"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['381'].touched &&
                        DicksonMDFroms.controls['381'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['381'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >FAX NUMBER
                    <input
                      style="margin-left: 65px"
                      class="form-control"
                      id="382"
                      name="382"
                      formControlName="382"
                      type="text"
                      data-id="Text8.1.1"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="373"
                    name="373"
                    formControlName="373"
                    type="text"
                    data-id="reference2address"
                  />
                  <input
                    class="form-control"
                    id="374"
                    name="374"
                    formControlName="374"
                    type="text"
                    data-id="reference2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="375"
                      name="375"
                      formControlName="375"
                      type="text"
                      data-id="reference2city"
                    />
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="377"
                      >
                      </ng-select>
                   
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div
                    class="col-md-2"
                  > 
                    <input
                      class="form-control"
                      id="378"
                      name="378"
                      formControlName="378"
                      type="text"
                      data-id="reference2zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['378'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small
                  >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="6">
                
                  <label class="formLabel">3. NAME/TITLE</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['384'].invalid"
                    (blur)="handleNAchange('cmd_0724', 384)"
                    id="384"
                    name="384"
                    formControlName="384"
                    type="text"
                    data-id="reference3name"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['384'].touched &&
                      DicksonMDFroms.controls['384'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['384'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="formLabel"
                    >E-MAIL </label>
                    <input
                      class="form-control"
                      [class.is-invalid]="DicksonMDFroms.controls['396'].invalid"
                      (blur)="handleNAchange('cmd_0724', 396)"
                      id="396"
                      name="396"
                      formControlName="396"
                      type="text"
                      data-id="Text8.2.0"
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['396'].touched &&
                        DicksonMDFroms.controls['396'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['396'].errors"
                      >
                        required
                      </small>
  
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['396'].errors.invalidEmail"
                        >Please enter a valid email.</small
                      >
                    </div>
                 
              
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >PHONE NUMBER
                    <input
                      class="form-control"
                      (keypress)="numberOnly($event)"
                      [class.is-invalid]="DicksonMDFroms.controls['394'].invalid"
                      (blur)="handleNAchange('cmd_0724', 394)"
                      id="394"
                      name="394"
                      formControlName="394"
                      type="text"
                      data-id="reference3telephone"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['394'].touched &&
                        DicksonMDFroms.controls['394'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['394'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 textInPro">
                  <label class="formLabel"
                    >FAX NUMBER
                    <input
                      style="margin-left : 65px"
                      class="form-control"
                      id="395"
                      name="395"
                      formControlName="395"
                      type="text"
                      data-id="Text8.2.1"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="385"
                    name="385"
                    formControlName="385"
                    type="text"
                    data-id="reference3add"
                  />
                  <input
                    class="form-control"
                    id="386"
                    name="386"
                    formControlName="386"
                    type="text"
                    data-id="reference3spec"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      
                      class="form-control"
                      id="387"
                      name="387"
                      formControlName="387"
                      type="text"
                      data-id="reference3city"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="389"
                    >
                    </ng-select>
                  </div>
                
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="390"
                      name="390"
                      formControlName="390"
                      type="text"
                      data-id="reference3zip"
                    />
                    <small
                    class="text-danger"
                    *ngIf="DicksonMDFroms.controls['390'].errors?.pattern"
                    > Zip code must be atleast 5 digits and less than 10 digits.</small
                    >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:16px;">Professional Liability Insurance Coverage</b>
                </div>
              </td>
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  name="cmd_0725"
                  id="cmd_0725"
                  formControlName="cmd_0725"
                  (ngModelChange)="handleNAchange('cmd_0725')"
                  value="1"
                  class="texasFloat"
                />
              </td>
            </tr>
            <tr>
              <td colspan="6" width="21%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  SELF-INSURED?<label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_071"
                      name="cmd_071"
                      formControlName="cmd_071"
                      value="1"
                    />Yes</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_071"
                      name="cmd_071"
                      formControlName="cmd_071"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
              <td colspan="12" width="79%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED
                    ENTITY
                  </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['345'].invalid" 
                    id="345"
                    name="345"
                    formControlName="345"
                    type="text"
                    data-id="mlp1carrier"
                    (blur)="handleNAchange('cmd_0725', 345)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['345'].touched &&
                      DicksonMDFroms.controls['345'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['345'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="79%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['901'].invalid" 
                    id="901"
                    name="901"
                    formControlName="901"
                    type="text"
                    data-id="MLP Mailing Add1"
                    (blur)="handleNAchange('cmd_0725', 901)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['901'].touched &&
                      DicksonMDFroms.controls['901'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['901'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                     
                    id="902"
                    name="902"
                    formControlName="902"
                    type="text"
                    data-id="MLP Mailing Add2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                > 
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      [class.is-invalid]="DicksonMDFroms.controls['903'].invalid" 
                      class="form-control"
                      id="903"
                      name="903"
                      formControlName="903"
                      type="text"
                      data-id="MLP City"
                      (blur)="handleNAchange('cmd_0725', 903)"
                    />
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['903'].touched &&
                        DicksonMDFroms.controls['903'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['903'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>  
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="DicksonMDFroms.controls['905'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="905"
                      (blur)="handleNAchange('cmd_0725', 905)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['905'].touched &&
                        DicksonMDFroms.controls['905'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['905'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      [class.is-invalid]="DicksonMDFroms.controls['906'].invalid" 
                      class="form-control"
                      id="906"
                      name="906"
                      formControlName="906"
                      type="text"
                      data-id="MLP Zip"
                      (blur)="handleNAchange('cmd_0725', 906)"
                    />
                    <div
                      class="formLabel col-md-12"
                      *ngIf="
                        DicksonMDFroms.controls['906'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['906'].errors"
                      >
                        required
                      </small>
                      <small
                        class="text-danger"
                        *ngIf="
                          DicksonMDFroms.controls['906'].errors?.invalidZipCode
                        "
                      >
                        Zip code must be atleast 5 digits and less than 10 digits
                      </small>
                    </div>
                  </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="col-md-12"
                >
                  <label class="formLabel">PHONE NUMBER </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    [class.is-invalid]="DicksonMDFroms.controls['1266'].invalid" 
                    id="1266"
                    name="1266"
                    formControlName="1266"
                    type="text"
                    data-id="MLP Phone"
                    (blur)="handleNAchange('cmd_0725', 1266)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['1266'].touched &&
                      DicksonMDFroms.controls['1266'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1266'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-5 sideLabelAlignment"
                >
                  <label class="formLabel">POLICY NUMBER</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['346'].invalid" 
                    id="346"
                    name="346"
                    formControlName="346"
                    type="text"
                    data-id="mlp1pol"
                    (blur)="handleNAchange('cmd_0725', 346)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['346'].touched &&
                      DicksonMDFroms.controls['346'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['346'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d58="ngbDatepicker"
                      id="348"
                      name="348"
                      formControlName="348"
                      type="text"
                      data-id="mlp1dateeff_date"
                      [class.is-invalid]="DicksonMDFroms.controls['348'].invalid" 
                      (blur)="handleNAchange('cmd_0725', 348)"
                      (blur)="clearInputIfInvalid('348','cmd_0725')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar "
                        (click)="d58.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['348'].invalid && (DicksonMDFroms.controls['348'].value==''|| DicksonMDFroms.controls['348'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('348').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('348').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('348').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('348').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('348').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['349'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d59="ngbDatepicker"
                      id="349"
                      name="349"
                      formControlName="349"
                      type="text"
                      data-id="mlp1dateexp_date"
                      (blur)="handleNAchange('cmd_0725', 349)"
                      (blur)="clearInputIfInvalid('349','cmd_0725')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d59.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['349'].invalid && (DicksonMDFroms.controls['349'].value==''|| DicksonMDFroms.controls['349'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('349').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('349').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('349').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('349').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('349').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="col-md-12"
                >
                  <label class="formLabel"
                    >AMOUNT OF COVERAGE PER OCCURRENCE
                  </label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="preClaim"
                      [class.is-invalid]="DicksonMDFroms.controls['350'].invalid" 
                      bindLabel="name"
                      placeholder="Select Per Occurrence"
                      bindValue="id"
                      formControlName="350"
                      (blur)="handleNAchange('cmd_0725', 350)"
                    >
                    </ng-select>
                    <div
                      class="formLabel "
                      *ngIf="
                        DicksonMDFroms.controls['350'].touched &&
                        DicksonMDFroms.controls['350'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['350'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="aggregates"
                      [class.is-invalid]="DicksonMDFroms.controls['351'].invalid" 
                      bindLabel="name"
                      placeholder="Select Aggregate"
                      bindValue="id"
                      formControlName="351"
                      (blur)="handleNAchange('cmd_0725', 351)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['351'].touched &&
                        DicksonMDFroms.controls['351'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['351'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">
                    TYPE OF COVERAGE
                    <br />
                    <input
                      type="radio"
                      id="cmd_072"
                      name="cmd_072"
                      formControlName="cmd_072"
                      value="1"
                    />Individual</label
                  >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_072"
                      name="cmd_072"
                      formControlName="cmd_072"
                      value="0"
                    />Shared</label
                  >
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
                  <input
                    class="form-control"
                    id="cmd_073"
                    name="cmd_073"
                    formControlName="cmd_073"
                    type="text"
                    data-id="MLP length of time"
                  />
                </div>
              </td>
            </tr>
            <tr>
              
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:16px;">PREVIOUS MALPRACTICE INSURANCE</b>
                </div>
              </td>
              <td colspan="4">
                <b style="font-size:12px;">Does Not Apply</b>
                <input
                  type="checkbox"
                  name="cmd_07251"
                  id="cmd_07251"
                  formControlName="cmd_07251"
                  (ngModelChange)="handleNAchange('cmd_07251')"
                  value="1"
                  class="texasFloat"
                />
              </td>
            </tr>
            <tr>
              <td colspan="18" width="79%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH
                    CURRENT CARRIER LESS THAN 5 YEARS
                  </label>
                  <input
                    class="form-control"
                    id="1242"
                    [class.is-invalid]="DicksonMDFroms.controls['1242'].invalid" 
                    name="1242"
                    formControlName="1242"
                    type="text"
                    data-id="prevmlp1carrier"
                    (blur)="handleNAchange('cmd_07251', 1242)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['1242'].touched &&
                      DicksonMDFroms.controls['1242'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1242'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="79%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">ADDRESS </label>
                  <input
                    class="form-control"
                    id="1244"
                    [class.is-invalid]="DicksonMDFroms.controls['1244'].invalid" 
                    name="1244"
                    formControlName="1244"
                    type="text"
                    data-id="prevMLP Mailing Add1"
                    (blur)="handleNAchange('cmd_07251', 1244)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['1244'].touched &&
                      DicksonMDFroms.controls['1244'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1244'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control"
                    
                    id="1245"
                    name="1245"
                    formControlName="1245"
                    type="text"
                    data-id="prevMLP Mailing Add2"
                  />
                  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
               <div
                class="row"
                >
                    <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                    </div>    
                    <div class="col-md-2">
                      <input                        
                        [class.is-invalid]="DicksonMDFroms.controls['1246'].invalid" 
                        class="form-control"
                        id="1246"
                        name="1246"
                        formControlName="1246"
                        type="text"
                        data-id="prevMLP City"
                        (blur)="handleNAchange('cmd_07251', 1246)"
                      />
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['1246'].touched &&
                          DicksonMDFroms.controls['1246'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['1246'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                    <div
                      class="col-md-2">
                    
                      <label class="formLabel">STATE/COUNTRY</label>
                    </div>  
                 
                    <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        [class.is-invalid]="DicksonMDFroms.controls['1248'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1248"
                        (blur)="handleNAchange('cmd_07251', 1248)"
                      >
                      </ng-select>
                    
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          DicksonMDFroms.controls['1248'].touched &&
                          DicksonMDFroms.controls['1248'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['1248'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                    <div
                      class="col-md-2"
                    >
                      <label class="formLabel">POSTAL CODE</label>
                    </div>  
                    <div class="col-md-2">
                      <input
                        [class.is-invalid]="DicksonMDFroms.controls['1249'].invalid" 
                        class="form-control"
                        id="1249"
                        name="1249"
                        formControlName="1249"
                        type="text"
                        data-id="prevMLP Zip"
                        (blur)="handleNAchange('cmd_07251', 1249)"
                      />
                      <div
                        class="formLabel col-md-12"
                        *ngIf="
                          DicksonMDFroms.controls['1249'].invalid"
                      >
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['1249'].errors"
                        >
                          required
                        </small>
                        <small
                          class="text-danger"
                          *ngIf="
                            DicksonMDFroms.controls['1249'].errors?.invalidZipCode
                          "
                        >
                          Zip code must be atleast 5 digits and less than 10 digits
                        </small>
                      </div>
                    </div>  
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="col-md-8"
                >
                  <label class="formLabel">PHONE NUMBER </label>
                </div>
                <div
                  class="col-md-8"
                >  
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="1254"
                    name="1254"
                    formControlName="1254"
                    type="text"
                    data-id="prevMLP Phone"
                  />
                </div>
              </td>
              <td colspan="4">
                <div
                  class="col-md-8"
                >
                  <label class="formLabel">POLICY NUMBER</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="DicksonMDFroms.controls['1256'].invalid" 
                    id="1256"
                    name="1256"
                    formControlName="1256"
                    type="text"
                    data-id="prevmlp1pol"
                    (blur)="handleNAchange('cmd_07251', 1256)"
                  />
                  <div
                    class="formLabel col-md-2"
                    *ngIf="
                      DicksonMDFroms.controls['1256'].touched &&
                      DicksonMDFroms.controls['1256'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['1256'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['1252'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d60="ngbDatepicker"
                      id="1252"
                      name="1252"
                      formControlName="1252"
                      type="text"
                      data-id="prevmlp1dateeff_date"
                      (blur)="handleNAchange('cmd_07251', 1252)"
                      (blur)="clearInputIfInvalid('1252','cmd_07251')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d60.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1252'].invalid && (DicksonMDFroms.controls['1252'].value==''|| DicksonMDFroms.controls['1252'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('1252').invalid && DicksonMDFroms.get('1252').touched">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1252').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1252').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1252').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1252').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment"
                >
                  <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="DicksonMDFroms.controls['1253'].invalid" 
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d61="ngbDatepicker"
                      id="1253"
                      name="1253"
                      formControlName="1253"
                      type="text"
                      data-id="prevmlp1dateexp_date"
                      (blur)="handleNAchange('cmd_07251', 1253)"
                      (blur)="clearInputIfInvalid('1253','cmd_07251')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d61.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="DicksonMDFroms.controls['1253'].invalid && (DicksonMDFroms.controls['1253'].value==''|| DicksonMDFroms.controls['1253'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <div  *ngIf="DicksonMDFroms.get('1253').invalid ">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1253').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1253').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('1253').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1253').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="col-md-12"
                >
                  <label class="formLabel"
                    >AMOUNT OF COVERAGE PER OCCURRENCE
                  </label>
                </div>  
                  <div class="col-md-12">
                    <ng-select
                      [items]="preClaim"
                      [class.is-invalid]="DicksonMDFroms.controls['1264'].invalid" 
                      bindLabel="name"
                      placeholder="Select Per Occurrence"
                      bindValue="id"
                      formControlName="1264"
                      (blur)="handleNAchange('cmd_07251', 1264)"
                    >
                    </ng-select>
                    <div
                      class="formLabel "
                      *ngIf="
                        DicksonMDFroms.controls['1264'].touched &&
                        DicksonMDFroms.controls['1264'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['1264'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
                  <div class="mb-3 selectDrop col-md-6 dropDrownSet">
                    <ng-select
                      [items]="aggregates"
                      [class.is-invalid]="DicksonMDFroms.controls['1265'].invalid" 
                      bindLabel="name"
                      placeholder="Select Aggregate"
                      bindValue="id"
                      formControlName="1265"
                      (blur)="handleNAchange('cmd_07251', 1265)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        DicksonMDFroms.controls['1265'].touched &&
                        DicksonMDFroms.controls['1265'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['1265'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">
                    TYPE OF COVERAGE
                  </label>    
                </div>  
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">
                    <input
                      type="radio"
                      id="cmd_074"
                      name="cmd_074"
                      formControlName="cmd_074"
                      value="1"
                    />Individual</label
                    >&nbsp;
                    <label class="formLabel">
                      <input
                        type="radio"
                        id="cmd_074"
                        name="cmd_074"
                        formControlName="cmd_074"
                        value="0"
                      />Shared</label
                    >
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
                  <input
                    class="form-control"
                    id="cmd_075"
                    name="cmd_075"
                    formControlName="cmd_075"
                    type="text"
                    data-id="prevMLP length of time"
                  />
                </div>
              </td>
            </tr>
          </table>
          <hr class="tablePersonal" />
          
          <table border="1" width="100%">
            <tr>
              <td colspan="14">
                <div class="col-md-12">
                  <b style="font-size:16px;">Practice Location Information </b><i style="font-size:13px;">- Please answer the following questions for each practice
                    location. Use Attachment F or make copies of pages 6-7 as
                    necessary.</i>
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PRACTICE LOCATION
                  </label>
                  <input
                    class="form-control"
                    id="cmd_088"
                    name="cmd_088"
                    formControlName="cmd_088"
                    type="text"
                    data-id="Text3.0"
                  />of
                  <input
                    class="form-control"
                    id="cmd_089"
                    name="cmd_089"
                    formControlName="cmd_089"
                    type="text"
                    data-id="Text3.1"
                  />
                </div>
              </td>
            </tr>   
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel">TYPE OF SERVICE PROVIDED</label>&nbsp;&nbsp;
                  <label class="formLabel">
                    <input
                      type="checkbox"
                      id="cmd_090"
                      name="cmd_090"
                      formControlName="cmd_090"
                      value="1"
                    />&nbsp;Solo Primary Care
                  </label>&nbsp;&nbsp;
                  <label class="formLabel">
                    <input
                      type="checkbox"
                      id="cmd_091"
                      name="cmd_091"
                      formControlName="cmd_091"
                      value="1"
                    />Solo Specialty Care
                  </label>&nbsp;&nbsp;
                  <label class="formLabel">
                    <input
                      type="checkbox"
                      id="cmd_092"
                      name="cmd_092"
                      formControlName="cmd_092"
                      value="1"
                    />Group Primary Care
                  </label>&nbsp;&nbsp;
                  <label class="formLabel">
                    <input
                      type="checkbox"
                      id="cmd_093"
                      name="cmd_093"
                      formControlName="cmd_093"
                      value="1"
                    />Group Single Specialty
                  </label>&nbsp;&nbsp;
                  <label class="formLabel">
                    <input
                      type="checkbox"
                      id="cmd_094"
                      name="cmd_094"
                      formControlName="cmd_094"
                      value="1"
                    />Group Multi-Specialty
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >GROUP NAME/PRACTICE NAME TO APPEAR IN THE DIRECTORY
                  </label>
                  <input
                    class="form-control"
                    id="cmd_0728"
                    name="cmd_0728"
                    formControlName="cmd_0728"
                    type="text"
                    data-id="prac1_PracticeName"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                  <label class="formLabel"
                    >GROUP/CORPORATE NAME AS IT APPEARS ON IRS W-9
                  </label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="cmd_0729"
                    name="cmd_0729"
                    formControlName="cmd_0729"
                    type="text"
                    data-id="prac1_TINName"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >PRACTICE LOCATION ADDRESS:&nbsp;&nbsp; &nbsp;<input
                      type="checkbox"
                      id="cmd_095"
                      name="cmd_095"
                      formControlName="cmd_095"
                      value="1"
                    />&nbsp; Primary
                  </label>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                  <input
                    class="form-control"
                    id="cmd_0730"
                    name="cmd_0730"
                    formControlName="cmd_0730"
                    type="text"
                    data-id="prac1_Address1"
                  />
                  <input
                    class="form-control"
                    id="cmd_0731"
                    name="cmd_0731"
                    formControlName="cmd_0731"
                    type="text"
                    data-id="prac1_Address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="row"
                >
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      class="form-control"
                      id="cmd_0732"
                      name="cmd_0732"
                      formControlName="cmd_0732"
                      type="text"
                      data-id="prac1_City"
                    />
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="cmd_0733"
                    >
                    </ng-select>
                  </div>               
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      id="cmd_0734"
                      name="cmd_0734"
                      formControlName="cmd_0734"
                      type="text"
                      data-id="prac1_ZIP"
                    />
                    <small
                      class="text-danger"
                      *ngIf="DicksonMDFroms.controls['cmd_0734'].errors?.pattern"
                      > Zip code must be atleast 5 digits and less than 10 digits.
                    </small>
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">PHONE NUMBER</label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="cmd_0735"
                    name="cmd_0735"
                    formControlName="cmd_0735"
                    type="text"
                    data-id="prac1_Phone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">FAX NUMBER </label>
                  <input
                    class="form-control"
                    id="cmd_0736"
                    name="cmd_0736"
                    formControlName="cmd_0736"
                    type="text"
                    data-id="prac1_Fax"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">E-MAIL </label>
                  <input
                    class="form-control"
                    id="cmd_0737"
                    name="cmd_0737"
                    formControlName="cmd_0737"
                    type="text"
                    data-id="Office email"
                  />
                  <small
                        class="text-danger"
                        *ngIf="DicksonMDFroms.controls['cmd_0737'].errors?.pattern"
                        >Please enter a valid email.</small
                      >
                
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                  <label class="formLabel">BACK OFFICE PHONE NUMBER </label>
                  <input
                    class="form-control"
                    (keypress)="numberOnly($event)"
                    id="cmd_0738"
                    name="cmd_0738"
                    formControlName="cmd_0738"
                    type="text"
                    data-id="Answering Service"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">TAX ID NUMBER</label>
                  <input
                    class="form-control"
                    id="cmd_0740"
                    name="cmd_0740"
                    formControlName="cmd_0740"
                    type="text"
                    data-id="prac1_TIN"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >GROUP NAME CORRESPONDING TO TAX ID NUMBER
                  </label>
                  <input
                    class="form-control"
                    id="cmd_0742"
                    name="cmd_0742"
                    formControlName="cmd_0742"
                    type="text"
                    data-id="prac1_TINName"
                  />
                </div>
              </td>
            </tr>
          </table>  
  
          <hr class="tablePersonal" />
          
          <table border="1" width="100%">
            <tr style="border-style: none;">
              <td colspan="18" width="100%" style="border-style: none;text-align: center;">
                
                  <b style="font-size:16px;text-align: center;" >Other Professional Degrees</b>
                
              </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                      <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                    </div>
                    <div class="row">  
                      <div class="col-md-3">
                        <label class="formLabel"
                        > Issuing Institution: </label>
                      </div>  
                      <div class="col-md-3">
                      <input  class="form-control onboard " id="add_1_1288" name="add_1_1288" formControlName="add_1_1288" type="text" data-id="ugs">
                    
                      </div>
                    </div>  
                </td>

            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_1_1289" name="add_1_1289" formControlName="add_1_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_1_1290" name="add_1_1290" formControlName="add_1_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_1_1291" name="add_1_1291" formControlName="add_1_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="add_1_1293">
                        </ng-select>
                      </div> 
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                          <input class="form-control onboard " id="add_1_1294" name="add_1_1294" formControlName="add_1_1294" type="text" data-id="ugszip">
                          <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_1_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                          </small>
                      
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                        <ng-select [items]="degree" bindLabel="name" placeholder="Select  Degree" bindValue="id"
                           formControlName="add_1_1298">
                       </ng-select>
                     </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1295="ngbDatepicker"
                        id="add_1_1295"
                        name="add_1_1295"
                        formControlName="add_1_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_1_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_1_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d1296="ngbDatepicker"
                        id="add_1_1296"
                        name="add_1_1296"
                        formControlName="add_1_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_1_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d1296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_1_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_2_1288" name="add_2_1288" formControlName="add_2_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_2_1289" name="add_2_1289" formControlName="add_2_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_2_1290" name="add_2_1290" formControlName="add_2_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_2_1291" name="add_2_1291" formControlName="add_2_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                        <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="add_2_1293">
                        </ng-select>
                      </div> 
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_2_1294" name="add_2_1294" formControlName="add_2_1294" type="text" data-id="ugszip">
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_2_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                        </small>
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                        <ng-select [items]="degree" bindLabel="name" placeholder="Select  Degree" bindValue="id"
                           formControlName="add_2_1298">
                       </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d01295="ngbDatepicker"
                        id="add_2_1295"
                        name="add_2_1295"
                        formControlName="add_2_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_2_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d01295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_2_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d01296="ngbDatepicker"
                        id="add_2_1296"
                        name="add_2_1296"
                        formControlName="add_2_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_2_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d01296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_2_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_3_1288" name="add_3_1288" formControlName="add_3_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_3_1289" name="add_3_1289" formControlName="add_3_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_3_1290" name="add_3_1290" formControlName="add_3_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_3_1291" name="add_3_1291" formControlName="add_3_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Select States" bindValue="id" formControlName="add_3_1293">
                            </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_3_1294" name="add_3_1294" formControlName="add_3_1294" type="text" data-id="ugszip">
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_3_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                        </small>
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                          <ng-select [items]="degree"  placeholder="Select Degree" bindLabel="name" bindValue="id" formControlName="add_3_1298">
                          </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d11295="ngbDatepicker"
                        id="add_3_1295"
                        name="add_3_1295"
                        formControlName="add_3_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_3_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d11295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_3_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d11296="ngbDatepicker"
                        id="add_3_1296"
                        name="add_3_1296"
                        formControlName="add_3_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_3_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d11296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_3_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_4_1288" name="add_4_1288" formControlName="add_4_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_4_1289" name="add_4_1289" formControlName="add_4_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_4_1290" name="add_4_1290" formControlName="add_4_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_4_1291" name="add_4_1291" formControlName="add_4_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                            <ng-select [items]="states" bindLabel="name"  placeholder="Select States" bindValue="id" formControlName="add_4_1293">
                            </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_4_1294" name="add_4_1294" formControlName="add_4_1294" type="text" data-id="ugszip">
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_4_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                        </small>  
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                          <ng-select [items]="degree" bindLabel="name"  placeholder="Select Degree" bindValue="id" formControlName="add_4_1298">
                          </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d21295="ngbDatepicker"
                        id="add_4_1295"
                        name="add_4_1295"
                        formControlName="add_4_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_4_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d21295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_4_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d21296="ngbDatepicker"
                        id="add_4_1296"
                        name="add_4_1296"
                        formControlName="add_4_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_4_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d21296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_4_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_5_1288" name="add_5_1288" formControlName="add_5_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_5_1289" name="add_5_1289" formControlName="add_5_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_5_1290" name="add_5_1290" formControlName="add_5_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_5_1291" name="add_5_1291" formControlName="add_5_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                            <ng-select [items]="states" bindLabel="name"  placeholder="Select States" bindValue="id" formControlName="add_5_1293">
                            </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_5_1294" name="add_5_1294" formControlName="add_5_1294" type="text" data-id="ugszip">
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_5_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                        </small>
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                          <ng-select [items]="degree" bindLabel="name"  placeholder="Select Degree" bindValue="id" formControlName="add_5_1298">
                          </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d31295="ngbDatepicker"
                        id="add_5_1295"
                        name="add_5_1295"
                        formControlName="add_5_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_5_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d31295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_5_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d31296="ngbDatepicker"
                        id="add_5_1296"
                        name="add_5_1296"
                        formControlName="add_5_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_5_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d31296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_5_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_6_1288" name="add_6_1288" formControlName="add_6_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_6_1289" name="add_6_1289" formControlName="add_6_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_6_1290" name="add_6_1290" formControlName="add_6_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_6_1291" name="add_6_1291" formControlName="add_6_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                            <ng-select [items]="states" bindLabel="name"  placeholder="Select States" bindValue="id" formControlName="add_6_1293">
                            </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_6_1294" name="add_6_1294" formControlName="add_6_1294" type="text" data-id="ugszip">
                        <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_6_1294'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.
                        </small> 
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                          <ng-select [items]="degree" bindLabel="name"  placeholder="Select Degree" bindValue="id" formControlName="add_6_1298">
                          </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d41295="ngbDatepicker"
                        id="add_6_1295"
                        name="add_6_1295"
                        formControlName="add_6_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_6_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d41295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_6_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d41296="ngbDatepicker"
                        id="add_6_1296"
                        name="add_6_1296"
                        formControlName="add_6_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_6_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d41296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_6_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
            <tr>
              <td colspan="18">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                    <b style="font-size:13px;">OTHER PROFESSIONAL DEGREE</b> 
                  </div>
                  <div class="row">  
                    <div class="col-md-3">
                      <label class="formLabel"
                      > Issuing Institution: </label>
                    </div>  
                    <div class="col-md-3">
                    <input  class="form-control onboard " id="add_7_1288" name="add_7_1288" formControlName="add_7_1288" type="text" data-id="ugs">
                  
                    </div>
                  </div>  
              </td>
          
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-2">
                        <label class="formLabel">ADDRESS</label>
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_7_1289" name="add_7_1289" formControlName="add_7_1289" type="text" data-id="ugsaddress1">
                      </div>  
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_7_1290" name="add_7_1290" formControlName="add_7_1290" type="text" data-id="ugsaddress2">
                      </div>  
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="18">
                    <div class="row">
                      <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                      </div>
                      <div class="col-md-3">
                        <input  class="form-control onboard " id="add_7_1291" name="add_7_1291" formControlName="add_7_1291" type="text" data-id="ugsci">
                      </div>  
                      <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                            <ng-select [items]="states" bindLabel="name"  placeholder="Select States" bindValue="id" formControlName="add_7_1293">
                            </ng-select>
                      </div>
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div>
                      <div class="col-md-2">
                        <input class="form-control onboard " id="add_7_1294" name="add_7_1294" formControlName="add_7_1294" type="text" data-id="ugszip">
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['add_7_1294'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.
                        </small>
                      </div>
                    </div>  
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="row">
                      <div class="col-md-12">
                          <label class="formLabel">DEGREE</label>
                      </div>    
                      <div class="col-md-8">
                          <ng-select [items]="degree" bindLabel="name"  placeholder="Select Degree" bindValue="id" formControlName="add_7_1298">
                          </ng-select>
                      </div>
                    </div>  
                </td>
                <td colspan="12">
                  <div class="col-md-8">
                    <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY) </label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d51295="ngbDatepicker"
                        id="add_7_1295"
                        name="add_7_1295"
                        formControlName="add_7_1295"
                        type="text"
                        data-id="priornameeffdate_date"
                        (blur)="clearInputIfInvalid('add_7_1295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d51295.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_7_1295').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker"
                        placeholder="MM/DD/YYYY"
                        ngbDatepicker
                        #d51296="ngbDatepicker"
                        id="add_7_1296"
                        name="add_7_1296"
                        formControlName="add_7_1296"
                        type="text"
                        data-id="priornametermdate_date"
                        (blur)="clearInputIfInvalid('add_7_1296')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d51296.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_7_1296').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1296').errors.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1296').errors.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1296').errors.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1296').errors.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div> 
                  
                </td>
            </tr>
         
          </table>  
          <hr class="tablePersonal" />
          
          <table border="1" width="100%">
            <tr style="border-style: none;">
              <td colspan="18" width="100%" style="border-style: none;text-align: center;">
                
                  <b style="font-size:16px;text-align: center;" >Other Post Graduate Education</b>
                
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:13px;">OTHER POST-GRADUATE EDUCATION </b>
                </div>
                <input
                  type="radio"
                  id="cmd_008"
                  name="cmd_008"
                  formControlName="cmd_008"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_008"
                  name="cmd_008"
                  formControlName="cmd_008"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_008"
                  name="cmd_008"
                  formControlName="cmd_008"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_008"
                  name="cmd_008"
                  formControlName="cmd_008"
                  value="3"
                />Teaching Appointment
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SPECIALTY</label>
                </div>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="1171"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="1170"
                    name="1170"
                    formControlName="1170"
                    type="text"
                    data-id="interns"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="1172"
                    name="1172"
                    placeholder="Address"
                    formControlName="1172"
                    type="text"
                    data-id="internadd1"
                  />
                  <input
                    class="form-control"
                    id="1173"
                    name="1173"
                    placeholder="Address"
                    formControlName="1173"
                    type="text"
                    data-id="internadd2"
                  />
                </div>
              </td>
            </tr>            
            <tr>
              <td colspan="18" style="padding:1%;">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-3">
                    <input                     
                      class="form-control"
                      id="1174"
                      name="1174"
                      formControlName="1174"
                      type="text"
                      data-id="internci"
                    />
                  </div>  
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1176"
                      >
                      </ng-select>
                  </div>                  
                  <div class="col-md-2">
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2"> 
                    <input
                      
                      class="form-control"
                      id="1177"
                      name="1177"
                      formControlName="1177"
                      type="text"
                      data-id="internz"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['1177'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                ></div>
                <input
                  type="checkbox"
                  id="cmd_009"
                  name="cmd_009"
                  formControlName="cmd_009"
                  value="1"
                /><label class="formLabel">Program successfully completed</label>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d010="ngbDatepicker"
                      id="1178"
                      name="1178"
                      formControlName="1178"
                      type="text"
                      data-id="internstart_date"
                      (blur)="clearInputIfInvalid('1178')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d010.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('1178').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1178').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1178').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1178').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('1178').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                  
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d011="ngbDatepicker"
                      id="1179"
                      name="1179"
                      formControlName="1179"
                      type="text"
                      data-id="internend_date"
                      (blur)="clearInputIfInvalid('1179')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d011.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('1179').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1179').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1179').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('1179').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('1179').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    class="form-control"
                    id="1181"
                    name="1181"
                    formControlName="1181"
                    type="text"
                    data-id="interndir"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                  </label>
                  <input
                    class="form-control"
                    id="1182"
                    name="1182"
                    formControlName="1182"
                    type="text"
                    data-id="interndir2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <b style="font-size:13px;">OTHER POST-GRADUATE EDUCATION </b>
                </div>
                <input
                  type="radio"
                  id="cmd_0008"
                  name="cmd_0008"
                  formControlName="cmd_0008"
                  value="0"
                />Internship
                <input
                  type="radio"
                  id="cmd_0008"
                  name="cmd_0008"
                  formControlName="cmd_0008"
                  value="1"
                />Residency
                <input
                  type="radio"
                  id="cmd_0008"
                  name="cmd_0008"
                  formControlName="cmd_0008"
                  value="2"
                />Fellowship
                <input
                  type="radio"
                  id="cmd_0008"
                  name="cmd_0008"
                  formControlName="cmd_0008"
                  value="3"
                />Teaching Appointment
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">SPECIALTY</label>
                </div>
                <div class="col-md-10">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="add_1_1171"
                  >
                  </ng-select>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">INSTITUTION</label>
                  <input
                    class="form-control"
                    id="add_1_1170"
                    name="add_1_1170"
                    formControlName="add_1_1170"
                    type="text"
                    data-id="interns"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>
                  <input
                    class="form-control"
                    id="add_1_1172"
                    name="add_1_1172"
                    placeholder="Address"
                    formControlName="add_1_1172"
                    type="text"
                    data-id="internadd1"
                  />
                  <input
                    class="form-control"
                    id="add_1_1173"
                    name="add_1_1173"
                    placeholder="Address"
                    formControlName="add_1_1173"
                    type="text"
                    data-id="internadd2"
                  />
                </div>
              </td>
            </tr>            
            <tr>
              <td colspan="18" style="padding:1%;">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-3">
                    <input                     
                      class="form-control"
                      id="add_1_1174"
                      name="add_1_1174"
                      formControlName="add_1_1174"
                      type="text"
                      data-id="internci"
                    />
                  </div>  
                  <div class="col-md-2">
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                  <div class="col-md-2">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="add_1_1176"
                      >
                      </ng-select>
                  </div>                  
                  <div class="col-md-2">
                    <label class="formLabel">POSTAL CODE</label>
                  </div> 
                  <div class="col-md-2"> 
                    <input
                      
                      class="form-control"
                      id="add_1_1177"
                      name="add_1_1177"
                      formControlName="add_1_1177"
                      type="text"
                      data-id="internz"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_1_1177'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                ></div>
                <input
                  type="checkbox"
                  id="cmd_0009"
                  name="cmd_0009"
                  formControlName="cmd_0009"
                  value="1"
                /><label class="formLabel">Program successfully completed</label>
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00010="ngbDatepicker"
                      id="add_1_1178"
                      name="add_1_1178"
                      formControlName="add_1_1178"
                      type="text"
                      data-id="internstart_date"
                      (blur)="clearInputIfInvalid('add_1_1178')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00010.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_1_1178').invalid">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1178').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1178').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1178').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1178').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                  
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0011="ngbDatepicker"
                      id="add_1_1179"
                      name="add_1_1179"
                      formControlName="add_1_1179"
                      type="text"
                      data-id="internend_date"
                      (blur)="clearInputIfInvalid('add_1_1179')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0011.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div  *ngIf="DicksonMDFroms.get('add_1_1179').invalid ">
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1179').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                    </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1179').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1179').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1179').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">PROGRAM DIRECTOR </label>
                  <input
                    class="form-control"
                    id="add_1_1181"
                    name="add_1_1181"
                    formControlName="add_1_1181"
                    type="text"
                    data-id="interndir"
                  />
                </div>
              </td>
              <td colspan="9">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                  </label>
                  <input
                    class="form-control"
                    id="add_1_1182"
                    name="add_1_1182"
                    formControlName="add_1_1182"
                    type="text"
                    data-id="interndir2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                  <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                  >
                  <b style="font-size:13px;">OTHER POST-GRADUATE EDUCATION </b>
                  </div>
                  <input
                  type="radio"
                  id="cmd_00008"
                  name="cmd_00008"
                  formControlName="cmd_00008"
                  value="0"
                  />Internship
                  <input
                  type="radio"
                  id="cmd_00008"
                  name="cmd_00008"
                  formControlName="cmd_00008"
                  value="1"
                  />Residency
                  <input
                  type="radio"
                  id="cmd_00008"
                  name="cmd_00008"
                  formControlName="cmd_00008"
                  value="2"
                  />Fellowship
                  <input
                  type="radio"
                  id="cmd_00008"
                  name="cmd_00008"
                  formControlName="cmd_00008"
                  value="3"
                  />Teaching Appointment
              </td>
              <td colspan="6">
                  <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                  >
                  <label class="formLabel">SPECIALTY</label>
                  </div>
                  <div class="mb-3 selectDrop dropDrownSet">
                  <ng-select
                      [items]="specialty"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="967"
                  >
                  </ng-select>
                  </div>
              </td>
              </tr>
              <tr>
                  <td colspan="18">
                  <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                  >
                      <label class="formLabel">INSTITUTION</label>
                      <input
                      class="form-control"
                      id="966"
                      name="966"
                      formControlName="966"
                      type="text"
                      data-id="interns"
                      />
                  </div>
                  </td>
              </tr>
              <tr>
                  <td colspan="18">
                  <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                  >
                      <label class="formLabel">ADDRESS</label>
                      <input
                      class="form-control"
                      id="968"
                      name="968"
                      placeholder="Address"
                      formControlName="968"
                      type="text"
                      data-id="internadd1"
                      />
                      <input
                      class="form-control"
                      id="969"
                      name="969"
                      placeholder="Address"
                      formControlName="969"
                      type="text"
                      data-id="internadd2"
                      />
                  </div>
                  </td>
              </tr>  
              <tr>
                <td colspan="18" style="padding:1%;">
                    <div class="row">
                    <div class="col-md-1">
                        <label class="formLabel">CITY</label>
                    </div>  
                    <div class="col-md-2">
                        <input                     
                        class="form-control"
                        id="970"
                        name="970"
                        formControlName="970"
                        type="text"
                        data-id="internci"
                        />
                    </div>  
                    <div class="col-md-2">
                        <label class="formLabel">STATE/COUNTRY</label>
                    </div>  
                    <div class="col-md-2">
                        <ng-select
                            [items]="states"
                            bindLabel="name"
                            placeholder="Select States"
                            bindValue="id"
                            formControlName="972"
                        >
                        </ng-select>
                    </div>                  
                    <div class="col-md-2">
                        <label class="formLabel">POSTAL CODE</label>
                    </div> 
                    <div class="col-md-2"> 
                        <input
                        
                        class="form-control"
                        id="1301"
                        name="1301"
                        formControlName="1301"
                        type="text"
                        data-id="internz"
                        />
                        <small
                            class="text-danger"
                            *ngIf="DicksonMDFroms.controls['1301'].errors?.pattern"
                            > Zip code must be atleast 5 digits and less than 10 digits.</small
                            >
                    </div>
                    </div>  
                </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      ></div>
                      <input
                      type="checkbox"
                      id="cmd_00009"
                      name="cmd_00009"
                      formControlName="cmd_00009"
                      value="1"
                      /><label class="formLabel">Program successfully completed</label>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                      </label>
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d0010="ngbDatepicker"
                          id="974"
                          name="974"
                          formControlName="974"
                          type="text"
                          data-id="internstart_date"
                          (blur)="clearInputIfInvalid('974')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d0010.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('974').invalid">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('974').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('974').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('974').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('974').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d00110="ngbDatepicker"
                          id="975"
                          name="975"
                          formControlName="975"
                          type="text"
                          data-id="internend_date"
                          (blur)="clearInputIfInvalid('975')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d00110.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('975').invalid ">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('975').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('975').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('975').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('975').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel">PROGRAM DIRECTOR </label>
                      <input
                          class="form-control"
                          id="983"
                          name="983"
                          formControlName="983"
                          type="text"
                          data-id="interndir"
                      />
                      </div>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                      </label>
                      <input
                          class="form-control"
                          id="984"
                          name="984"
                          formControlName="984"
                          type="text"
                          data-id="interndir2"
                      />
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <b style="font-size:13px;">OTHER POST-GRADUATE EDUCATION </b>
                      </div>
                      <input
                      type="radio"
                      id="cmd_000008"
                      name="cmd_000008"
                      formControlName="cmd_000008"
                      value="0"
                      />Internship
                      <input
                      type="radio"
                      id="cmd_000008"
                      name="cmd_000008"
                      formControlName="cmd_000008"
                      value="1"
                      />Residency
                      <input
                      type="radio"
                      id="cmd_000008"
                      name="cmd_000008"
                      formControlName="cmd_000008"
                      value="2"
                      />Fellowship
                      <input
                      type="radio"
                      id="cmd_000008"
                      name="cmd_000008"
                      formControlName="cmd_000008"
                      value="3"
                      />Teaching Appointment
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel">SPECIALTY</label>
                      </div>
                      <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                          [items]="specialty"
                          bindLabel="name"
                          placeholder="Select Specialty"
                          bindValue="id"
                          formControlName="add_1_967"
                      >
                      </ng-select>
                      </div>
                  </td>
                </tr>
                <tr>
                    <td colspan="18">
                    <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                    >
                        <label class="formLabel">INSTITUTION</label>
                        <input
                        class="form-control"
                        id="add_1_966"
                        name="add_1_966"
                        formControlName="add_1_966"
                        type="text"
                        data-id="interns"
                        />
                    </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="18">
                    <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                    >
                        <label class="formLabel">ADDRESS</label>
                        <input
                        class="form-control"
                        id="add_1_968"
                        name="add_1_968"
                        placeholder="Address"
                        formControlName="add_1_968"
                        type="text"
                        data-id="internadd1"
                        />
                        <input
                        class="form-control"
                        id="add_1_969"
                        name="add_1_969"
                        placeholder="Address"
                        formControlName="add_1_969"
                        type="text"
                        data-id="internadd2"
                        />
                    </div>
                    </td>
                </tr>  
                <tr>
                  <td colspan="18" style="padding:1%;">
                      <div class="row">
                      <div class="col-md-1">
                          <label class="formLabel">CITY</label>
                      </div>  
                      <div class="col-md-2">
                          <input                     
                          class="form-control"
                          id="add_1_970"
                          name="add_1_970"
                          formControlName="add_1_970"
                          type="text"
                          data-id="internci"
                          />
                      </div>  
                      <div class="col-md-2">
                          <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                          <ng-select
                              [items]="states"
                              bindLabel="name"
                              placeholder="Select States"
                              bindValue="id"
                              formControlName="add_1_972"
                          >
                          </ng-select>
                      </div>                  
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div> 
                      <div class="col-md-2"> 
                          <input
                          
                          class="form-control"
                          id="add_1_1301"
                          name="add_1_1301"
                          formControlName="add_1_1301"
                          type="text"
                          data-id="internz"
                          />
                          <small
                              class="text-danger"
                              *ngIf="DicksonMDFroms.controls['add_1_1301'].errors?.pattern"
                              > Zip code must be atleast 5 digits and less than 10 digits.</small
                              >
                      </div>
                      </div>  
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      ></div>
                      <input
                      type="checkbox"
                      id="cmd_000009"
                      name="cmd_000009"
                      formControlName="cmd_000009"
                      value="1"
                      /><label class="formLabel">Program successfully completed</label>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                      </label>
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d000010="ngbDatepicker"
                          id="add_1_974"
                          name="add_1_974"
                          formControlName="add_1_974"
                          type="text"
                          data-id="internstart_date"
                          (blur)="clearInputIfInvalid('add_1_974')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d000010.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('add_1_974').invalid">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_974').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_974').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_974').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_974').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d000011="ngbDatepicker"
                          id="add_1_975"
                          name="add_1_975"
                          formControlName="add_1_975"
                          type="text"
                          data-id="internend_date"
                          (blur)="clearInputIfInvalid('add_1_975')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d000011.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('add_1_975').invalid ">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_975').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_975').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_975').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_975').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel">PROGRAM DIRECTOR </label>
                      <input
                          class="form-control"
                          id="add_1_983"
                          name="add_1_983"
                          formControlName="add_1_983"
                          type="text"
                          data-id="interndir"
                      />
                      </div>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                      </label>
                      <input
                          class="form-control"
                          id="add_1_984"
                          name="add_1_984"
                          formControlName="add_1_984"
                          type="text"
                          data-id="interndir2"
                      />
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <b style="font-size:13px;">OTHER POST-GRADUATE EDUCATION </b>
                      </div>
                      <input
                      type="radio"
                      id="cmd_0000008"
                      name="cmd_0000008"
                      formControlName="cmd_0000008"
                      value="0"
                      />Internship
                      <input
                      type="radio"
                      id="cmd_0000008"
                      name="cmd_0000008"
                      formControlName="cmd_0000008"
                      value="1"
                      />Residency
                      <input
                      type="radio"
                      id="cmd_0000008"
                      name="cmd_0000008"
                      formControlName="cmd_0000008"
                      value="2"
                      />Fellowship
                      <input
                      type="radio"
                      id="cmd_0000008"
                      name="cmd_0000008"
                      formControlName="cmd_0000008"
                      value="3"
                      />Teaching Appointment
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel">SPECIALTY</label>
                      </div>
                      <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                          [items]="specialty"
                          bindLabel="name"
                          placeholder="Select Specialty"
                          bindValue="id"
                          formControlName="1147"
                      >
                      </ng-select>
                      </div>
                  </td>
                </tr>
                <tr>
                    <td colspan="18">
                    <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                    >
                        <label class="formLabel">INSTITUTION</label>
                        <input
                        class="form-control"
                        id="1146"
                        name="1146"
                        formControlName="1146"
                        type="text"
                        data-id="interns"
                        />
                    </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="18">
                    <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                    >
                        <label class="formLabel">ADDRESS</label>
                        <input
                        class="form-control"
                        id="1148"
                        name="1148"
                        placeholder="Address"
                        formControlName="1148"
                        type="text"
                        data-id="internadd1"
                        />
                        <input
                        class="form-control"
                        id="1149"
                        name="1149"
                        placeholder="Address"
                        formControlName="1149"
                        type="text"
                        data-id="internadd2"
                        />
                    </div>
                    </td>
                </tr>  
                <tr>
                  <td colspan="18" style="padding:1%;">
                      <div class="row">
                      <div class="col-md-1">
                          <label class="formLabel">CITY</label>
                      </div>  
                      <div class="col-md-2">
                          <input                     
                          class="form-control"
                          id="1150"
                          name="1150"
                          formControlName="1150"
                          type="text"
                          data-id="internci"
                          />
                      </div>  
                      <div class="col-md-2">
                          <label class="formLabel">STATE/COUNTRY</label>
                      </div>  
                      <div class="col-md-2">
                          <ng-select
                              [items]="states"
                              bindLabel="name"
                              placeholder="Select States"
                              bindValue="id"
                              formControlName="1152"
                          >
                          </ng-select>
                      </div>                  
                      <div class="col-md-2">
                          <label class="formLabel">POSTAL CODE</label>
                      </div> 
                      <div class="col-md-2"> 
                          <input
                          
                          class="form-control"
                          id="1153"
                          name="1153"
                          formControlName="1153"
                          type="text"
                          data-id="internz"
                          />
                          <small
                              class="text-danger"
                              *ngIf="DicksonMDFroms.controls['1153'].errors?.pattern"
                              > Zip code must be atleast 5 digits and less than 10 digits.</small
                              >
                      </div>
                      </div>  
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      ></div>
                      <input
                      type="checkbox"
                      id="cmd_0000009"
                      name="cmd_0000009"
                      formControlName="cmd_0000009"
                      value="1"
                      /><label class="formLabel">Program successfully completed</label>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY)
                      </label>
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d01155="ngbDatepicker"
                          id="1155"
                          name="1155"
                          formControlName="1155"
                          type="text"
                          data-id="internstart_date"
                          (blur)="clearInputIfInvalid('1155')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d01155.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('1155').invalid">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1155').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1155').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1155').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('1155').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      
                      <div class="input-group">
                          <input
                          class="form-control onboard datepicker"
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #d01156="ngbDatepicker"
                          id="1156"
                          name="1156"
                          formControlName="1156"
                          type="text"
                          data-id="internend_date"
                          (blur)="clearInputIfInvalid('1156')"
                          />
                          <div class="input-group-append">
                          <button
                              class="btn btn-outline-secondary calendar"
                              (click)="d01156.toggle()"
                              type="button"
                          >
                              <i class="fas fa-calendar-alt"></i>
                          </button>
                          </div>
                          <div  *ngIf="DicksonMDFroms.get('1156').invalid ">
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1156').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)                    </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1156').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="DicksonMDFroms.get('1156').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="DicksonMDFroms.get('1156').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                      </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel">PROGRAM DIRECTOR </label>
                      <input
                          class="form-control"
                          id="1382"
                          name="1382"
                          formControlName="1382"
                          type="text"
                          data-id="interndir"
                      />
                      </div>
                  </td>
                  <td colspan="6">
                      <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                      >
                      <label class="formLabel"
                          >CURRENT PROGRAM DIRECTOR (IF KNOWN)
                      </label>
                      <input
                          class="form-control"
                          id="cmd_00122"
                          name="cmd_00122"
                          formControlName="cmd_00122"
                          type="text"
                          data-id="interndir2"
                      />
                      </div>
                  </td>
                </tr>
                            
            
          </table>  
          <hr class="tablePersonal" />`
          <table border="1" width="100%">
            <tr style="border-style: none;">
              <td colspan="18" width="100%" style="border-style: none;text-align: center;">
                
                  <b style="font-size:16px;text-align: center;" >Other Work History</b>
                
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_1_1954"
                      name="add_1_1954"
                      formControlName="add_1_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d040="ngbDatepicker"
                      id="add_1_1966"
                      name="add_1_1966"
                      formControlName="add_1_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_1_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_1_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d041="ngbDatepicker"
                      id="add_1_1967"
                      name="add_1_1967"
                      formControlName="add_1_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_1_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_1_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_1_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_1_1955"
                    name="add_1_1955"
                    formControlName="add_1_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_1_1956"
                    name="add_1_1956"
                    formControlName="add_1_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_1_1957"
                      name="add_1_1957"
                      formControlName="add_1_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_1_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_1_1960"
                      name="add_1_1960"
                      formControlName="add_1_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_1_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_1_1971"
                    name="add_1_1971"
                    formControlName="add_1_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_2_1954"
                      name="add_2_1954"
                      formControlName="add_2_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0040="ngbDatepicker"
                      id="add_2_1966"
                      name="add_2_1966"
                      formControlName="add_2_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_2_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_2_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0041="ngbDatepicker"
                      id="add_2_1967"
                      name="add_2_1967"
                      formControlName="add_2_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_2_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_2_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_2_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_2_1955"
                    name="add_2_1955"
                    formControlName="add_2_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_2_1956"
                    name="add_2_1956"
                    formControlName="add_2_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_2_1957"
                      name="add_2_1957"
                      formControlName="add_2_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_2_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_2_1960"
                      name="add_2_1960"
                      formControlName="add_2_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_2_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_2_1971"
                    name="add_2_1971"
                    formControlName="add_2_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_3_1954"
                      name="add_3_1954"
                      formControlName="add_3_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00040="ngbDatepicker"
                      id="add_3_1966"
                      name="add_3_1966"
                      formControlName="add_3_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_3_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_3_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00041="ngbDatepicker"
                      id="add_3_1967"
                      name="add_3_1967"
                      formControlName="add_3_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_3_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_3_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_3_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_3_1955"
                    name="add_3_1955"
                    formControlName="add_3_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_3_1956"
                    name="add_3_1956"
                    formControlName="add_3_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_3_1957"
                      name="add_3_1957"
                      formControlName="add_3_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_3_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_3_1960"
                      name="add_3_1960"
                      formControlName="add_3_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_3_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_3_1971"
                    name="add_3_1971"
                    formControlName="add_3_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_4_1954"
                      name="add_4_1954"
                      formControlName="add_4_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000040="ngbDatepicker"
                      id="add_4_1966"
                      name="add_4_1966"
                      formControlName="add_4_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_4_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_4_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000041="ngbDatepicker"
                      id="add_4_1967"
                      name="add_4_1967"
                      formControlName="add_4_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_4_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_4_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_4_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_4_1955"
                    name="add_4_1955"
                    formControlName="add_4_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_4_1956"
                    name="add_4_1956"
                    formControlName="add_4_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_4_1957"
                      name="add_4_1957"
                      formControlName="add_4_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_4_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_4_1960"
                      name="add_4_1960"
                      formControlName="add_4_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_4_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_4_1971"
                    name="add_4_1971"
                    formControlName="add_4_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_5_1954"
                      name="add_5_1954"
                      formControlName="add_5_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0000040="ngbDatepicker"
                      id="add_5_1966"
                      name="add_5_1966"
                      formControlName="add_5_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_5_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0000040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_5_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d0000041="ngbDatepicker"
                      id="add_5_1967"
                      name="add_5_1967"
                      formControlName="add_5_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_5_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d0000041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_5_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_5_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_5_1955"
                    name="add_5_1955"
                    formControlName="add_5_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_5_1956"
                    name="add_5_1956"
                    formControlName="add_5_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_5_1957"
                      name="add_5_1957"
                      formControlName="add_5_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_5_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_5_1960"
                      name="add_5_1960"
                      formControlName="add_5_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_5_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_5_1971"
                    name="add_5_1971"
                    formControlName="add_5_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_6_1954"
                      name="add_6_1954"
                      formControlName="add_6_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00000040="ngbDatepicker"
                      id="add_6_1966"
                      name="add_6_1966"
                      formControlName="add_6_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_6_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00000040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_6_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d00000041="ngbDatepicker"
                      id="add_6_1967"
                      name="add_6_1967"
                      formControlName="add_6_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_6_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d00000041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_6_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_6_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_6_1955"
                    name="add_6_1955"
                    formControlName="add_6_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_6_1956"
                    name="add_6_1956"
                    formControlName="add_6_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_6_1957"
                      name="add_6_1957"
                      formControlName="add_6_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_6_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_6_1960"
                      name="add_6_1960"
                      formControlName="add_6_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_6_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_6_1971"
                    name="add_6_1971"
                    formControlName="add_6_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    ><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                    <input
                      class="form-control"
                      id="add_7_1954"
                      name="add_7_1954"
                      formControlName="add_7_1954"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel"
                    >START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                  </label>
                  <div class="input-group">
                    <input
                      style="width:25%;"
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000000040="ngbDatepicker"
                      id="add_7_1966"
                      name="add_7_1966"
                      formControlName="add_7_1966"
                      type="text"
                      data-id="wkhist2startdt"
                      (blur)="clearInputIfInvalid('add_7_1966')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000000040.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_7_1966').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1966').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1966').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1966').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1966').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d000000041="ngbDatepicker"
                      id="add_7_1967"
                      name="add_7_1967"
                      formControlName="add_7_1967"
                      type="text"
                      data-id="wkhist2enddt"
                      (blur)="clearInputIfInvalid('add_7_1967')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d000000041.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="DicksonMDFroms.get('add_7_1967').invalid">
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1967').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1967').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1967').errors?.invalidDateyear">
                      year is out of range(1900-2099)
                      </small>
                      <small class="text-danger" *ngIf="DicksonMDFroms.get('add_7_1967').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">ADDRESS</label>&nbsp;&nbsp;
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 1"
                    id="add_7_1955"
                    name="add_7_1955"
                    formControlName="add_7_1955"
                    type="text"
                    data-id="wkhist2add"
                  />
                  <input
                    style="width: 40%;margin-bottom: 2px;"
                    class="form-control"
                    placeholder="Address 2"
                    id="add_7_1956"
                    name="add_7_1956"
                    formControlName="add_7_1956"
                    type="text"
                    data-id="wkhist2address2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="row">
                  <div class="col-md-1">
                    <label class="formLabel">CITY</label>
                  </div>  
                  <div class="col-md-2">
                    <input
                      placeholder="City"
                      class="form-control"
                      id="add_7_1957"
                      name="add_7_1957"
                      formControlName="add_7_1957"
                      type="text"
                      data-id="Work hist 2 city"
                    />
                  </div>                  
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">STATE/COUNTRY</label>
                  </div>  
                    
                  <div class="col-md-2">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="add_7_1959"
                    >
                    </ng-select>
                  
                    
                  </div>
                  <div
                    class="col-md-2"
                  >
                    <label class="formLabel">POSTAL CODE</label>
                  </div>  
                  <div
                    class="col-md-2"
                  >
                    <input
                      class="form-control"
                      placeholder="Zip"
                      id="add_7_1960"
                      name="add_7_1960"
                      formControlName="add_7_1960"
                      type="text"
                      data-id="Work hist 2 zip"
                    />
                    <small
                          class="text-danger"
                          *ngIf="DicksonMDFroms.controls['add_7_1960'].errors?.pattern"
                          > Zip code must be atleast 5 digits and less than 10 digits.</small
                        >
                  </div>
                </div>  
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"
                >
                  <label class="formLabel">REASON FOR DISCONTINUANCE </label>&nbsp;&nbsp;
                  <input
                    class="form-control"
                    id="add_7_1971"
                    name="add_7_1971"
                    formControlName="add_7_1971"
                    type="text"
                    data-id="wkhist2reasonforleaving"
                  />
                </div>
              </td>
            </tr>
          </table>  
          <dicksonmd
            [DicksonMDFroms1]="DicksonMDFroms"
            [isSubmit]="isSubmit"
            (change)="validationsDataAdd($event)"
            (isclearInputIfInvalid)="clearInputIfInvalid($event)"
            (isclearInputIfInvalidMandi)="clearInputIfInvalidMandi($event)"
            (isHandleNAChanges)="handleNAchange($event)"
            (numberOnly)="numberOnly($event)"
            [showOne]="showOne" [finalImgPath]="finalImgPath" [esignOne]="esignOne"
            >
          
          </dicksonmd>
          <div class="modal-footer mt-4">
            <button
              *ngIf="formData.receivedForm"
              type="button"
              class="btn customOrange float-end"
              (click)="saveForm('approve')"
            >
              Approve
            </button>
            <button
              *ngIf="formData.submitForm"
              type="button"
              class="btn btn customOrange float-end"
              (click)="saveForm('submit')"
            >
              Submit
            </button>
            <button
              *ngIf="formData.submitForm"
              type="button"
              class="btn btn customOrange float-end"
              (click)="saveForm('save')"
            >
              Save
            </button>
            <button
              *ngIf="formData.previewForm"
              type="button"
              class="btn customOrange float-end"
              (click)="close()"
            >
              Close
            </button>
          </div>
        </form>  
      </div>
    </div>
</div>  
  