<div id="sphAll">
    <div class="cotainer-fluid mx-1">
        <div class="header row">
            <span class="col-md-2">
                <img  class="imgAlign" src="./assets/images/southform.png" alt="Credential my Doc">
            </span>
            <span class="col-md-9">
                <u>
                    <h2>SOUTH GEORGIA PHYSICIANS ASSOCIATION L.L.C.</h2>
                </u>
                <p>413 West Montgomery Cross Rd Suite 602 • Savannah, Georgia 31406</p>
                <p>(912) 691-5711• Fax (678)559-069</p>
                <p>• www.sgpallc.com</p>
            </span>
        </div>
        <div class="section">
            <form [formGroup]="southGeorgiaFormthree">
                <div class="row dateStyle">
                    <!-- <div class="allPadding col-md-12">April 03, 2020</div><br><br> -->
                    {{ datepicker | date}}
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label class="pt-2 formLabel">Dear</label>
                    </div>
                    <div class="mb-3 col-md-2">
                        <input type="text" placeholder="Office Manager First Name" class="form-control" formControlName="cmd_110016">
                    </div>
                    <div class="mb-3 col-md-2">
                        <input type="text" placeholder="Office Manager Last Name" class="form-control" formControlName="cmd_110017">
                    </div>
                    <span>
                        <div>,</div>
                    </span>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="pt-2 formLabel">I am pleased to announce that</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" placeholder="First Name" class="form-control" formControlName="8" [class.is-invalid]="(southGeorgiaFormthree.controls['8'].touched && southGeorgiaFormthree.controls['8'].invalid) || (southGeorgiaFormthree.controls['8'].invalid && isSubmit)" (change)="onChangeFirstName($event)">
                        <div class="formLabel col-md-11"
                            *ngIf="southGeorgiaFormthree.controls['8'].touched && southGeorgiaFormthree.controls['8'].invalid">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['8'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="text" placeholder="Last Name" class="form-control" formControlName="9" [class.is-invalid]="(southGeorgiaFormthree.controls['9'].touched && southGeorgiaFormthree.controls['9'].invalid) || (southGeorgiaFormthree.controls['9'].invalid && isSubmit)" (change)="onChangeLastName($event)">
                        <div class="formLabel col-md-11"
                            *ngIf="southGeorgiaFormthree.controls['9'].touched && southGeorgiaFormthree.controls['9'].invalid">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['9'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                    's credentialing application for the South
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label class="pt-2 formLabel">Georgia Physicians Association, LLC was approved on</label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input style="width:50%;" type="text" ngbDatepicker #d1="ngbDatepicker" placeholder="MM/DD/YYYY" class="form-control" (blur)="clearInputIfInvalidMandi('1994')" formControlName="1994" [class.is-invalid]="(southGeorgiaFormthree.controls['1994'].touched && southGeorgiaFormthree.controls['1994'].invalid) || (southGeorgiaFormthree.controls['1994'].invalid && isSubmit)">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1994').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1994').errors?.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1994').errors?.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1994').errors?.invalidDateRange">
                                  date is out of range
                                  </small>

                        </div>
                    </div>
                    <div class="formLabel col-md-11"
                            *ngIf="southGeorgiaFormthree.controls['1449'].touched && southGeorgiaFormthree.controls['1449'].invalid">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['1449'].errors?.required">
                                required
                            </small>
                        </div>
                    <div class="col-md-1">
                        <label class="pt-2 formLabel">&middot;</label>
                    </div>
                    <div class="mb-3 selectDropTwo col-md-2">
                        <ng-select [items]="greetings" bindLabel="name" placeholder="Select Greeting" bindValue="id" formControlName="811" [class.is-invalid]="(southGeorgiaFormthree.controls['811'].touched && southGeorgiaFormthree.controls['811'].invalid) || (southGeorgiaFormthree.controls['811'].invalid && isSubmit)" (change)="onChangeGreetings($event)">
                        </ng-select>
                        <div class="formLabel col-md-11"
                            *ngIf="southGeorgiaFormthree.controls['811'].touched && southGeorgiaFormthree.controls['811'].invalid">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['811'].errors?.required">
                                required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <input class="form-control" type="text" placeholder= "Last Name" formControlName="cmd_110014">
                    </div>
                    <div class="col-md-6">
                        <label class="formLabel">’s membership was granted for a period of <I>three years</I> in the specialty of</label>
                    </div>
                    <div class="mb-3 selectDropTwo col-md-2">
                        <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id" formControlName="284" (change)="onChangeSpecialty($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label class="pt-2 formLabel">&middot;</label>
                    </div>
                     <div class="mb-3 selectDropTwo col-md-2">
                        <ng-select class="divheadingMargin" [items]="greetings" bindLabel="name" placeholder="Select Greeting" bindValue="id" formControlName="cmd_110214">
                        </ng-select>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control onboard " type="text" class="form-control" formControlName="cmd_110114" placeholder="Last Name">
                    </div>
                    <div class="col-md-3">
                        <label class="formLabel">will be due for recredentialing on</label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input style="width:50%;" class="form-control" ngbDatepicker #d2="ngbDatepicker" (blur)="clearInputIfInvalidMandi('1449')" placeholder="MM/DD/YYYY" type="text" [class.is-invalid]="(southGeorgiaFormthree.controls['1449'].touched && southGeorgiaFormthree.controls['1449'].invalid) || (southGeorgiaFormthree.controls['1449'].invalid && isSubmit)" formControlName="1449" data-id="nextcredential">
                            <div class="formLabel col-md-11"
                                *ngIf="southGeorgiaFormthree.controls['1449'].touched && southGeorgiaFormthree.controls['1449'].invalid">
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['1449'].errors?.required">
                                    required
                                </small>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1449').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1449').errors?.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1449').errors?.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.get('1449').errors?.invalidDateRange">
                                  date is out of range
                                  </small>

                        </div>
                    </div>
                    <div class="col-md-1">
                        <label class="pt-2 formLabel">&middot;</label>
                    </div>
                </div>
                <div class="col-md-12">
                    <label class="pt-2 formLabel">
                        <br>If you have any questions, please let me know at 912-691-5711
                    </label>
                </div>
                <div class="col-md-12">
                    <label class="pt-2 formLabel">Sincerely,</label>
                </div>
                <span class="col-md-2">
                    <img  class="imgAlign" src="./assets/images/southAsset.png" alt="Credential my Doc">
                </span>
                <div class="col-md-12">
                    <label class="pt-2 formLabel">Kelly Mooney</label>
                </div>
                <div class="col-md-12">
                    <label class="pt-2 formLabel"> South Georgia Physicians Association, L.L.C.</label>
                </div><br><br>
                <div class="mb-3 col-md-12">
                    <label class="pt-2 formLabel"><h4>The enclosed SGPA Provider Packet contains the following documents that are
                    required for participation in the SGPA managed care contracts:</h4>
                    </label>
                    <ol class="ol" type="1">
                        <li>
                            Individual Data Sheet – Listing of your demographic profile which the networks
                            will use for your provider listing. Any discrepancies between this form and your
                            actual demographic profile can result in billing issues.<br><br>

                            **Please review this sheet and correct any erroneous information. Complete any
                            sections that are highlighted and attach any requested documents. Please return
                            the reviewed/completed data sheet with your packet in order to ensure that the
                            networks receive your correct information.<br><br>

                            <font  class="fontStyle">Accurate Medicare and Medicaid #s must be included for each location in order
                            to participate in the Medicare Advantage or Medicaid CMO networks.</font>
                        </li><br>
                        <li>
                            Network Opt-In Report - Lists each network available through the SGPA. Please
                            check a participation option for each network.
                        </li><br>
                        <li>
                            Sample Fee Schedule – A schedule of the top codes for your specialty. The last
                            page of the schedule lists networks that reimburse based on a percentage of
                            billed charges. If additional CPT codes are needed, please contact the SGPA.
                        </li><br>
                        <li>
                            SGPA Physician Provider Agreement - This is the same Agreement that all 550+
                            physicians associated with the SGPA have signed.
                        </li><br>
                    </ol>
                    <label class="pt-2 formLabel">All completed, signed forms must be returned to the SGPA at 413 West Montgomery
                        Cross Rd, Suite 602 Savannah, GA 31406 or at
                         <font class="fontHyperLink"><u>sgpa@sgpallc.com.</u></font>
                    </label>
                </div>
                <table class = "southform_table tableOne">
                    <tr>
                        <td colspan="2">
                            <div class="mb-3 colspan colspanAlign">
                                <img  class="img-fluid" src="./assets/images/southform.png" alt="Credential my Doc">
                            </div>
                        </td>
                        <td colspan="14" class="colspanAlign">
                            <div class="mb-3 col-md-13 colspan colspanAlign">
                                <div class="text-center">
                                    <h3 class="hthreeStyle">SOUTH GEORGIA PHYSICIANS ASSOCIATION L.L.C. </h3>
                                    <h5 class="colspanAlign">413 West Montgomery Cross Rd Suite 602 • Savannah, Georgia 31406 <br>
                                        (912) 691-5711• Fax (678)559-0699 <br>• www.sgpallc.com</h5>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="mb-3 colspan colspanAlign">
                                <img  class="img-fluid" src="./assets/images/southform.png" alt="Credential my Doc">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-2" class="tablePadding">
                                    <label class="formLabel">Physician: </label>
                                </div>
                                <div class="col-md-5" class="tablePadding">
                                    <input class="form-control" id="cmd_110001" name="cmd_110001" formControlName="cmd_110001" type="text" placeholder= "First Name">
                                </div>
                                <div class="col-md-5" class="tablePadding">
                                    <input class="form-control" id="cmd_110002" name="cmd_110002" formControlName="cmd_110002" type="text" placeholder= "Last Name">
                                </div>
                                <div class="col-md-2" class="tablePadding">
                                    <label class="formLabel">DEGREE </label>
                                </div>
                                <div class="mb-3 selectDrop col-md-2 pt-2">
                                    <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" [class.is-invalid]="(southGeorgiaFormthree.controls['500'].touched && southGeorgiaFormthree.controls['500'].invalid) || (southGeorgiaFormthree.controls['500'].invalid && isSubmit)" formControlName="500" (change)="onChangedegree($event)">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['500'].touched && southGeorgiaFormthree.controls['500'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['500'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Role of Provider: </label>
                                </div>
                                <div class="col-md-6" class="tablePadding">
                                    <input class="form-control" id="1218" name="1218" [class.is-invalid]="(southGeorgiaFormthree.controls['1218'].touched && southGeorgiaFormthree.controls['1218'].invalid) || (southGeorgiaFormthree.controls['1218'].invalid && isSubmit)" formControlName="1218" type="text" data-id="what_was_the_providers_role_in_the_event">
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['1218'].touched && southGeorgiaFormthree.controls['1218'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['1218'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">NPI:  </label>
                                </div>
                                <div class="col-md-6" class="tablePadding">
                                    <input class="form-control" id="1634" name="1634" formControlName="1634" type="text" data-id="npi">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Medicare:  </label>
                                </div>
                                <div class="col-md-6" class="tablePadding">
                                    <input class="form-control" id="325" name="325" [class.is-invalid]="(southGeorgiaFormthree.controls['325'].touched && southGeorgiaFormthree.controls['325'].invalid) || (southGeorgiaFormthree.controls['325'].invalid && isSubmit)" formControlName="325" type="text" data-id="medicare_number">
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['325'].touched && southGeorgiaFormthree.controls['325'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['325'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Primary Languages</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="languages" bindLabel="name" placeholder="Select Language1" bindValue="id" [class.is-invalid]="(southGeorgiaFormthree.controls['277'].touched && southGeorgiaFormthree.controls['277'].invalid) || (southGeorgiaFormthree.controls['277'].invalid && isSubmit)" formControlName="277">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['277'].touched && southGeorgiaFormthree.controls['277'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['277'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Secondary Languages</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="secondaryLanguages" bindLabel="name" placeholder="Select SecondaryLanguages" bindValue="id" formControlName="1535">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Tertiary Languages</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="tertiaryLanguages" bindLabel="name" placeholder="Select TertiaryLanguages" bindValue="id" formControlName="1536">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Quaternary Languages</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="quaternaryLanguages" bindLabel="name" placeholder="Select QuaternaryLanguages" bindValue="id" formControlName="2207">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Primary Specialty:</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id" formControlName="cmd_110215">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5" class="tablePadding">
                                    <label class="formLabel">Secondary Specialty:</label>
                                </div>
                                <div class="mb-3 selectDrop col-md-5">
                                    <ng-select [items]="specialtySecondary" bindLabel="name" [class.is-invalid]="(southGeorgiaFormthree.controls['962'].touched && southGeorgiaFormthree.controls['962'].invalid) || (southGeorgiaFormthree.controls['962'].invalid && isSubmit)" placeholder="Select Specialty" bindValue="id" formControlName="962">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['962'].touched && southGeorgiaFormthree.controls['962'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['962'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-4" class="tablePadding tableHeaddingSettingOne"><b>Certifying Board</b></div>
                                <div class="col-md-4" class="tablePadding tableHeaddingSettingTwo"><b>Certificate #:</b></div>
                                <div class="col-md-4" class="tablePadding tableHeaddingSettingThree"><b>Initial Cert.:</b></div>
                                <div class="col-md-4" class="tablePadding tableHeaddingSettingFour"><b>Cert. Exp:</b></div>
                            </div>
                            <div class="row">
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select BoardCertification" [class.is-invalid]="(southGeorgiaFormthree.controls['554'].touched && southGeorgiaFormthree.controls['554'].invalid) || (southGeorgiaFormthree.controls['554'].invalid && isSubmit)" bindValue="id" formControlName="554">
                                    </ng-select>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['554'].touched && southGeorgiaFormthree.controls['554'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['554'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <input class="form-control onboard " id="1169" name="1169"  type="text" formControlName="1169" [class.is-invalid]="(southGeorgiaFormthree.controls['1169'].touched && southGeorgiaFormthree.controls['1169'].invalid) || (southGeorgiaFormthree.controls['1169'].invalid && isSubmit)" data-id="primary_board_certification_number">
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['1169'].touched && southGeorgiaFormthree.controls['1169'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['1169'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input style="width:50%;" class="form-control" (blur)="clearInputIfInvalidMandi('557')" ngbDatepicker #d3="ngbDatepicker" [class.is-invalid]="(southGeorgiaFormthree.controls['557'].touched && southGeorgiaFormthree.controls['557'].invalid) || (southGeorgiaFormthree.controls['557'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="557" name="557" formControlName="557" type="text" data-id="primary_board_original_issue_date">
                                        <div class="formLabel col-md-11"
                                            *ngIf="southGeorgiaFormthree.controls['557'].touched && southGeorgiaFormthree.controls['557'].invalid">
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['557'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('557').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('557').errors?.invalidMonth">
                                          Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('557').errors?.invalidDateyear">
                                            year is out of range(1900-2099)                  </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('557').errors?.invalidDateRange">
                                              date is out of range
                                              </small>

                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input style="width:50%;" class="form-control" ngbDatepicker #d4="ngbDatepicker" (blur)="clearInputIfInvalidMandi('560')" [class.is-invalid]="(southGeorgiaFormthree.controls['560'].touched && southGeorgiaFormthree.controls['560'].invalid) || (southGeorgiaFormthree.controls['560'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="560" name="560" formControlName="560" type="text" data-id="primary_board_expiration_date">
                                        <div class="formLabel col-md-11"
                                            *ngIf="southGeorgiaFormthree.controls['560'].touched && southGeorgiaFormthree.controls['560'].invalid">
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['560'].errors?.required">
                                                required
                                            </small>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                    <small class="text-danger" *ngIf="southGeorgiaFormthree.get('560').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                                      <small class="text-danger" *ngIf="southGeorgiaFormthree.get('560').errors?.invalidMonth">
                                      Month is out of range
                                      </small>
                                      <small class="text-danger" *ngIf="southGeorgiaFormthree.get('560').errors?.invalidDateyear">
                                        year is out of range(1900-2099)                  </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('560').errors?.invalidDateRange">
                                          date is out of range
                                          </small>

                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select BoardCertification" bindValue="id" formControlName="561">
                                    </ng-select>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <input class="form-control" id="562" name="562"  type="text" formControlName="562" data-id="certificate_number">
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input class="form-control" ngbDatepicker #d5="ngbDatepicker" placeholder="MM/DD/YYYY" id="564" name="564" (blur)="clearInputIfInvalid('564')"  formControlName="564" type="text" data-id="secondary_board_original_issue_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div  *ngIf="southGeorgiaFormthree.get('564').invalid ">
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('564').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('564').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('564').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('564').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
    
                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input style="width:50%;" class="form-control onboard datepicker" ngbDatepicker #d6="ngbDatepicker" (blur)="clearInputIfInvalid('567')"  placeholder="MM/DD/YYYY" id="567" name="567" formControlName="567" type="text" data-id="secondary_board_expiration_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                    <div  *ngIf="southGeorgiaFormthree.get('567').invalid ">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('567').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('567').errors?.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('567').errors?.invalidDateyear">
                                          year is out of range(1900-2099)                      </small>
                                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('567').errors?.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select BoardCertification" bindValue="id" formControlName="946">
                                    </ng-select>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <input class="form-control onboard " id="951" name="951"  type="text" formControlName="951" data-id="third_board_certificate_number">
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input style="width:50%;" class="form-control onboard datepicker" ngbDatepicker #d7="ngbDatepicker" (blur)="clearInputIfInvalid('953')" placeholder="MM/DD/YYYY" id="953" name="953" formControlName="953" type="text" data-id="third_board_original_issue_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div  *ngIf="southGeorgiaFormthree.get('953').invalid ">
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('953').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('953').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('953').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('953').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
    
                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input class="form-control onboard datepicker" ngbDatepicker #d8="ngbDatepicker" (blur)="clearInputIfInvalid('954')" placeholder="MM/DD/YYYY" id="954" name="954" formControlName="954" type="text" data-id="thrid_board_expiration_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                    <div  *ngIf="southGeorgiaFormthree.get('954').invalid ">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('954').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('954').errors?.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('954').errors?.invalidDateyear">
                                          year is out of range(1900-2099)                      </small>
                                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('954').errors?.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select BoardCertification" bindValue="id" formControlName="955">
                                    </ng-select>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <input class="form-control" id="956" name="956"  formControlName="956" type="text" data-id="fourth_board_certificate_number">
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input class="form-control" ngbDatepicker #d9="ngbDatepicker" placeholder="MM/DD/YYYY" (blur)="clearInputIfInvalid('957')" id="957" name="957" formControlName="957" type="text" data-id="fourth_board_original_issue_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar " (click)="d9.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                        <div  *ngIf="southGeorgiaFormthree.get('957').invalid ">
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('957').errors?.invalidDateFormat">
                                              Only Date Format is allowed(MM/DD/YYYY)
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('957').errors?.invalidMonth">
                                            Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('957').errors?.invalidDateyear">
                                              year is out of range(1900-2099)                      </small>
                                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('957').errors?.invalidDateRange">
                                                date is out of range
                                                </small>
                                            </div>
    
                                    </div>
                                </div>
                                <div class="col-md-3" class="tablePadding">
                                    <div class="input-group">
                                        <input class="form-control" ngbDatepicker #d10="ngbDatepicker" (blur)="clearInputIfInvalid('961')"  placeholder="MM/DD/YYYY" id="961" name="961" formControlName="961" type="text" data-id="fourth_board_expiration_date">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar " (click)="d10.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                    <div  *ngIf="southGeorgiaFormthree.get('961').invalid ">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('961').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('961').errors?.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('961').errors?.invalidDateyear">
                                          year is out of range(1900-2099)                      </small>
                                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('961').errors?.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>

                                </div>

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-11" class="tablePadding">
                                    <label class="formLabel">Email addresses are for SGPA use only. They will not be shared with networks or other entities.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label class="formLabel">Physician Email:</label>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input class="form-control" id="759" name="759" [class.is-invalid]="(southGeorgiaFormthree.controls['759'].touched && southGeorgiaFormthree.controls['759'].invalid) || (southGeorgiaFormthree.controls['759'].invalid && isSubmit)" formControlName="759" type="text" data-id="email">
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['759'].touched && southGeorgiaFormthree.controls['759'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['759'].errors?.required">
                                            required
                                        </small>
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['759'].errors?.pattern">
                                            Email address is not valid
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label class="formLabel">Office Manager Email:</label>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <input class="form-control" id="cmd_110015" name="cmd_110015" formControlName="cmd_110015" type="text" data-id="managing_contact_email">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="row">
                                <div class="col-md-6 ">
                                    <label class="formLabel"><b>Mailing Address:</b></label>
                                </div>
                                <div class="col-md-6">
                                    <label class="formLabel"><b>Other:</b></label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <input class="form-control" id="cmd_110009" name="cmd_110009"  formControlName="cmd_110009" type="text" placeholder="Mailing Address 1" data-id="practice_mailing_address_add1">
                                </div>
                                <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100013" name="cmd_100013"  formControlName= "cmd_100013" type="text" placeholder="Other" data-id="other">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <input class="form-control" id="cmd_110010" name="cmd_110010"  formControlName= "cmd_110010" type="text" placeholder="Mailing Address 2" data-id="practice_mailing_address_add2">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <input class="form-control" id="cmd_110011" name="cmd_110011" formControlName= "cmd_110011" type="text" placeholder="Mailing Address City" data-id="practice_mailing_address_city">
                                </div>
                            </div>
                            <div class="row">
                              <div class="mb-3 col-md-6">
                                <label>STATE/COUNTRY</label>
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_110012">
                                    </ng-select>
                                </div>
                                <div class="col-md-6 mb-3">
                                  <label></label>
                                    <input class="form-control" id="cmd_110013" name="cmd_110013" formControlName="cmd_110013"  type="text" placeholder="Mailing Address Zip" data-id="practice_mailing_address_zip">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="colspanAlign">
                        <label class="rotate">Primary Address:</label>
                      </td>
                      <td colspan="16">
                        <div class="row mt-2">
                          <div class="col-6">
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Practice Name:</b></label>
                              </div>
                              <div class="mb-3 col-md-6">
                                <ng-select bindLabel="label" placeholder="Select Location" bindValue="id"
                                  formControlName="cmd_100040"
                                  [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100040'].touched && southGeorgiaFormthree.controls['cmd_100040'].invalid) || (southGeorgiaFormthree.controls['cmd_100040'].invalid && isSubmit)"
                                  (change)="toGetLocationDetailsOne($event)">
                                  <ng-option *ngFor="let c of locDropdown" [value]="c.id">{{c.label}}</ng-option>
                                </ng-select>
                              </div>
                              <div class="formLabel col-md-11"
                                *ngIf="southGeorgiaFormthree.controls['cmd_100040'].touched && southGeorgiaFormthree.controls['cmd_100040'].invalid">
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100040'].errors?.required">
                                  required
                                </small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Tax ID:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100044" name="cmd_100044" formControlName="cmd_100044" type="text"
                                  data-id="tax_id">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Group NPI:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100046" name="cmd_100046" formControlName="cmd_100046" type="text"
                                  data-id="group_npi">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Medicaid #:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100048" name="cmd_100048" formControlName="cmd_100048" type="text"
                                  data-id="medicaid">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Office Manager:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100050" name="cmd_100050" formControlName="cmd_100050"
                                  placeholder="First Name" type="text" data-id="office_manager_first">
                              </div>
                              <div class="col-md-6 mb-3">

                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100051" name="cmd_100051" formControlName="cmd_100051"
                                  placeholder="Last Name" type="text" data-id="office_manager_last">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Phone:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100053" name="cmd_100053" formControlName="cmd_100053" type="text"
                                  data-id="phone">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Fax:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100056" name="cmd_100056" formControlName="cmd_100056" type="text"
                                  data-id="fax">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Physical Location:</b></label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100045" name="cmd_100045" formControlName="cmd_100045" type="text"
                                  placeholder="practice_address1" data-id="practice__add1">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100047" name="cmd_100047" formControlName="cmd_100047" type="text"
                                  placeholder="practice_address2" data-id="practice__add2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100049" name="cmd_100049" formControlName="cmd_100049" type="text"
                                  placeholder="Practice City" data-id="practice__city">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="mb-3 col-md-6 ">
                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                  formControlName="cmd_100093">
                                </ng-select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100052" name="cmd_100052" formControlName="cmd_100052" type="text"
                                  placeholder="Practice ZIP" data-id="practice__zip">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Billing Address:</b></label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_110006" name="cmd_110006" formControlName="cmd_110006" type="text"
                                  placeholder="Billing Address" data-id="billing_add">
                              </div>
                            </div>
                            <div class="row">
                              <div class="mb-3 selectDrop col-md-3 allMargin">
                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                  formControlName="cmd_100055">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100054" name="cmd_100054" formControlName="cmd_100054" type="text"
                              placeholder="BillingCity" data-id="billing_city">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100057" name="cmd_100057" formControlName="cmd_100057" type="text"
                              placeholder="Billing ZIP" data-id="billing_zip">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100058" name="cmd_100058" formControlName="cmd_100058" type="text"
                              placeholder="Billing Phone" data-id="billing_phone">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100059" name="cmd_100059" formControlName="cmd_100059" type="text"
                              placeholder="Billing Fax" data-id="billing_fax">
                          </div>
                        </div>

                      </td>
                      </tr>
                    <tr>
                      <td colspan="2" class="colspanAlign">
                        <label class="rotate">Additional Address:</label>
                      </td>
                      <td colspan="16">
                        <div class="row mt-2">
                          <div class="col-6">
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Practice Name:</label>
                          </div>
                          <div class="mb-3 col-md-6">
                            <ng-select bindLabel="label"
                              [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100041'].touched && southGeorgiaFormthree.controls['cmd_100041'].invalid) || (southGeorgiaFormthree.controls['cmd_100041'].invalid && isSubmit)"
                              placeholder="Select Location" bindValue="id" formControlName="cmd_100041"
                              (change)="toGetLocationDetailsTwo($event)">
                              <ng-option *ngFor="let c of locDropdown" [value]="c.id">{{c.label}}</ng-option>
                            </ng-select>
                            <div class="formLabel col-md-11"
                              *ngIf="southGeorgiaFormthree.controls['cmd_100041'].touched && southGeorgiaFormthree.controls['cmd_100041'].invalid">
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100041'].errors?.required">
                                required
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Tax ID:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100060" name="cmd_100060" formControlName="cmd_100060" type="text"
                              placeholder="Practice Name" data-id="tax_id1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Group NPI:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100062" name="cmd_100062" formControlName="cmd_100062" type="text"
                              data-id="group_npi1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Medicaid #:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100064" name="cmd_100064" formControlName="cmd_100064" type="text"
                              data-id="medicaid">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Office Manager:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100066" name="cmd_100066" formControlName="cmd_100066" type="text"
                              data-id="office_manager_first1">
                          </div>
                          <div class="col-md-6 mb-3">

                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100067" name="cmd_100067" formControlName="cmd_100067" type="text"
                              data-id="office_manager_last1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Phone:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100069" name="cmd_100069" formControlName="cmd_100069" type="text"
                              data-id="phone1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel">Fax:</label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_1000721" name="cmd_1000721" formControlName="cmd_1000721" type="text"
                              data-id="fax1">
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel"><b>Physical Location:</b></label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100061" name="cmd_100061" formControlName="cmd_100061" type="text"
                              placeholder="Practice Address1" data-id="practice__add11">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100063" name="cmd_100063" formControlName="cmd_100063" type="text"
                              placeholder="Practice Address2" data-id="practice__add21">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_100065" name="cmd_100065" formControlName="cmd_100065" type="text"
                              placeholder="Practice City" data-id="practice__city1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="mb-3 col-md-6">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                              formControlName="cmd_100094">
                            </ng-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_1000681" name="cmd_1000681" formControlName="cmd_1000681" type="text"
                              placeholder="Practice ZIP" data-id="practice__zip1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="formLabel"><b>Billing Address:</b></label>
                          </div>
                          <div class="col-md-6 mb-3">
                            <input class="form-control" id="cmd_110007" name="cmd_110007" formControlName="cmd_110007" type="text"
                              placeholder="Billing Address" data-id="billing_add1">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6"></div>
                          <div class="mb-3 col-md-6">
                            <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                              formControlName="cmd_100071">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                        <div class="row mb-2">
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_1000701" name="cmd_1000701" formControlName="cmd_1000701" type="text"
                              placeholder="BillingCity" data-id="billing_city1">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100073" name="cmd_100073" formControlName="cmd_100073" type="text"
                              placeholder="Billing ZIP" data-id="billing_zip1">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100074" name="cmd_100074" formControlName="cmd_100074" type="text"
                              placeholder="Billing Phone" data-id="billing_phone1">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100075" name="cmd_100075" formControlName="cmd_100075" type="text"
                              placeholder="Billing Fax" data-id="billing_fax1">
                          </div>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="colspanAlign">
                        <label class="rotate">Additional Address:</label>
                      </td>
                      <td colspan="16">
                        <div class="row mt-2">
                          <div class="col-6">
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Practice Name:</b></label>
                              </div>
                              <div class="mb-3 col-md-6">
                                <ng-select bindLabel="label"
                                  [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100042'].touched && southGeorgiaFormthree.controls['cmd_100042'].invalid) || (southGeorgiaFormthree.controls['cmd_100042'].invalid && isSubmit)"
                                  placeholder="Select Location" bindValue="id" formControlName="cmd_100042"
                                  (change)="toGetLocationDetailsThree($event)">
                                  <ng-option *ngFor="let c of locDropdown" [value]="c.id">{{c.label}}</ng-option>
                                </ng-select>
                                <div class="formLabel col-md-11"
                                  *ngIf="southGeorgiaFormthree.controls['cmd_100042'].touched && southGeorgiaFormthree.controls['cmd_100042'].invalid">
                                  <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100042'].errors?.required">
                                    required
                                  </small>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Tax ID:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100076" name="cmd_100076" formControlName="cmd_100076" type="text"
                                  placeholder="Practice Name" data-id="tax_id2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Group NPI:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100092" name="cmd_100092" formControlName="cmd_100092" type="text"
                                  data-id="group_npi2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Medicaid #:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100079" name="cmd_100079" formControlName="cmd_100079" type="text"
                                  data-id="medicaid2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Office Manager:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100081" name="cmd_100081" formControlName="cmd_100081"
                                  placeholder="First Name" type="text" data-id="office_manager_first2">
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100082" name="cmd_100082" formControlName="cmd_100082"
                                  placeholder="Last Name" type="text" data-id="office_manager_last2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Phone:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100084" name="cmd_100084" formControlName="cmd_100084" type="text"
                                  data-id="phone2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel">Fax:</label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100087" name="cmd_100087" formControlName="cmd_100087" type="text"
                                  data-id="fax2">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Physical Location:</b></label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100077" name="cmd_100077" formControlName="cmd_100077" type="text"
                                  placeholder="Practice Address1" data-id="practice__add12">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100078" name="cmd_100078" formControlName="cmd_100078" type="text"
                                  placeholder="Practice Address2" data-id="practice__add21">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100080" name="cmd_100080" formControlName="cmd_100080" type="text"
                                  placeholder="Practice City" data-id="practice__city2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="mb-3 col-md-6">
                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                  formControlName="cmd_100095">
                                </ng-select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_100083" name="cmd_100083" formControlName="cmd_100083" type="text"
                                  placeholder="Practice ZIP" data-id="practice__zip2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label class="formLabel"><b>Billing Address:</b></label>
                              </div>
                              <div class="col-md-6 mb-3">
                                <input class="form-control" id="cmd_110008" name="cmd_110008" formControlName="cmd_110008" type="text"
                                  placeholder="Billing Address" data-id="billing_add2">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"></div>
                              <div class="mb-3 col-md-6">
                                <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                  formControlName="cmd_100086">
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100085" name="cmd_100085" formControlName="cmd_100085" type="text"
                              placeholder="BillingCity" data-id="billing_city2">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100088" name="cmd_100088" formControlName="cmd_100088" type="text"
                              placeholder="Billing ZIP" data-id="billing_zip2">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100089" name="cmd_100089" formControlName="cmd_100089" type="text"
                              placeholder="Billing Phone" data-id="billing_phone2">
                          </div>
                          <div class="col-md-3 mb-3">
                            <input class="form-control" id="cmd_100090" name="cmd_100090" formControlName="cmd_100090" type="text"
                              placeholder="Billing Fax" data-id="billing_fax2">
                          </div>
                        </div>

                      </td>
                    </tr>
                </table>
                <tr>
                    <br>
                    <div class="row">
                        <h3 class="text-center divheadingPaddingOne">SGPA NETWORK OPT-IN REPORT</h3>
                        <p class="text-center divheadingPadding">South Georgia Physicians Association, L.L.C. (SGPA),<br>
                        413 West Montgomery Cross Rd Suite 602 ~ Savannah, GA 31406
                        Phone 912-691-5711 ~ Fax 678-559-0699<br>
                        sgpa@sgpallc.com</p>
                    </div>
                </tr>
                <div class="row">
                    <div class="col-md-18">
                        <ul class="ultype">
                            <li>
                                Re: SGPA (The "Network")
                            </li>
                            <li>
                                Please accept as notice for the undersigned's agreement, as a member of the Network, to provide Covered
                            </li>
                            <li>
                                Services to the following Healthcare plan (the "Plans"):
                            </li>
                            <li>
                                The undersigned has reviewed the information with respect to reimbursement for Covered Services under the
                            </li>
                            <li>
                                Plan and other information supplied by the Network prior to giving notice.
                            </li>
                        </ul>
                    </div>
                </div>
                <table class = "tableOne">
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8">
                                <label>PPO Networks:</label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label>Participate </label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label>Do Not Wish to Participate</label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Beech Street Corporation </label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100014" name="cmd_100014" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100014'].touched && southGeorgiaFormthree.controls['cmd_100014'].invalid) || (southGeorgiaFormthree.controls['cmd_100014'].invalid && isSubmit)" formControlName="cmd_100014" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100014" name="cmd_100014" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100014'].touched && southGeorgiaFormthree.controls['cmd_100014'].invalid) || (southGeorgiaFormthree.controls['cmd_100014'].invalid && isSubmit)" formControlName="cmd_100014" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100014'].touched && southGeorgiaFormthree.controls['cmd_100014'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100014'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100014'].touched && southGeorgiaFormthree.controls['cmd_100014'].invalid) || (southGeorgiaFormthree.controls['cmd_100014'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">CorVel</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100015" name="cmd_100015" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100015'].touched && southGeorgiaFormthree.controls['cmd_100015'].invalid) || (southGeorgiaFormthree.controls['cmd_100015'].invalid && isSubmit)" formControlName="cmd_100015" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100015" name="cmd_100015" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100015'].touched && southGeorgiaFormthree.controls['cmd_100015'].invalid) || (southGeorgiaFormthree.controls['cmd_100015'].invalid && isSubmit)" formControlName="cmd_100015" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100015'].touched && southGeorgiaFormthree.controls['cmd_100015'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100015'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100015'].touched && southGeorgiaFormthree.controls['cmd_100015'].invalid) || (southGeorgiaFormthree.controls['cmd_100015'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Evolutions Health Care Systems</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100016" name="cmd_100016" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100016'].touched && southGeorgiaFormthree.controls['cmd_100016'].invalid) || (southGeorgiaFormthree.controls['cmd_100016'].invalid && isSubmit)" formControlName="cmd_100016" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100016" name="cmd_100016" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100016'].touched && southGeorgiaFormthree.controls['cmd_100016'].invalid) || (southGeorgiaFormthree.controls['cmd_100016'].invalid && isSubmit)" formControlName="cmd_100016" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100016'].touched && southGeorgiaFormthree.controls['cmd_100016'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100016'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100016'].touched && southGeorgiaFormthree.controls['cmd_100016'].invalid) || (southGeorgiaFormthree.controls['cmd_100016'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">GHN – Georgia Health Network</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100017" name="cmd_100017" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100017'].touched && southGeorgiaFormthree.controls['cmd_100017'].invalid) || (southGeorgiaFormthree.controls['cmd_100017'].invalid && isSubmit)" formControlName="cmd_100017" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100017" name="cmd_100017" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100017'].touched && southGeorgiaFormthree.controls['cmd_100017'].invalid) || (southGeorgiaFormthree.controls['cmd_100017'].invalid && isSubmit)" formControlName="cmd_100017" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100017'].touched && southGeorgiaFormthree.controls['cmd_100017'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100017'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100017'].touched && southGeorgiaFormthree.controls['cmd_100017'].invalid) || (southGeorgiaFormthree.controls['cmd_100017'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">IBG - Industry Buying Group</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_101018" name="cmd_101018" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_101018'].touched && southGeorgiaFormthree.controls['cmd_101018'].invalid) || (southGeorgiaFormthree.controls['cmd_101018'].invalid && isSubmit)" formControlName="cmd_101018" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_101018" name="cmd_101018" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_101018'].touched && southGeorgiaFormthree.controls['cmd_101018'].invalid) || (southGeorgiaFormthree.controls['cmd_101018'].invalid && isSubmit)" formControlName="cmd_101018" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_101018'].touched && southGeorgiaFormthree.controls['cmd_101018'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_101018'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_101018'].touched && southGeorgiaFormthree.controls['cmd_101018'].invalid) || (southGeorgiaFormthree.controls['cmd_101018'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">NovaNet, Inc.</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100018" name="cmd_100018" formControlName="cmd_100018" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100018'].touched && southGeorgiaFormthree.controls['cmd_101018'].invalid) || (southGeorgiaFormthree.controls['cmd_101018'].invalid && isSubmit)" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100018" name="cmd_100018" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100018'].touched && southGeorgiaFormthree.controls['cmd_100018'].invalid) || (southGeorgiaFormthree.controls['cmd_101018'].invalid && isSubmit)" formControlName="cmd_100018" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100018'].touched && southGeorgiaFormthree.controls['cmd_100018'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100018'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100018'].touched && southGeorgiaFormthree.controls['cmd_100018'].invalid) || (southGeorgiaFormthree.controls['cmd_100018'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Prime Health Services</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100019" name="cmd_100019" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100019'].touched && southGeorgiaFormthree.controls['cmd_100019'].invalid) || (southGeorgiaFormthree.controls['cmd_100019'].invalid && isSubmit)" formControlName="cmd_100019" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100019" name="cmd_100019" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100019'].touched && southGeorgiaFormthree.controls['cmd_100019'].invalid) || (southGeorgiaFormthree.controls['cmd_100019'].invalid && isSubmit)" formControlName="cmd_100019" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100019'].touched && southGeorgiaFormthree.controls['cmd_100019'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100019'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100019'].touched && southGeorgiaFormthree.controls['cmd_100019'].invalid) || (southGeorgiaFormthree.controls['cmd_100019'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">PHCS - Private Health Care Systems</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100020" name="cmd_100020" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100020'].touched && southGeorgiaFormthree.controls['cmd_100020'].invalid) || (southGeorgiaFormthree.controls['cmd_100020'].invalid && isSubmit)" formControlName="cmd_100020" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100020" name="cmd_100020" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100020'].touched && southGeorgiaFormthree.controls['cmd_100020'].invalid) || (southGeorgiaFormthree.controls['cmd_100020'].invalid && isSubmit)" formControlName="cmd_100020" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100020'].touched && southGeorgiaFormthree.controls['cmd_100020'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100020'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100020'].touched && southGeorgiaFormthree.controls['cmd_100020'].invalid) || (southGeorgiaFormthree.controls['cmd_100020'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">TRPN - Three Rivers Provider Network</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100021" name="cmd_100021" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100021'].touched && southGeorgiaFormthree.controls['cmd_100021'].invalid) || (southGeorgiaFormthree.controls['cmd_100021'].invalid && isSubmit)" formControlName="cmd_100021" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100021" name="cmd_100021" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100021'].touched && southGeorgiaFormthree.controls['cmd_100021'].invalid) || (southGeorgiaFormthree.controls['cmd_100021'].invalid && isSubmit)" formControlName="cmd_100021" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100021'].touched && southGeorgiaFormthree.controls['cmd_100021'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100021'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100021'].touched && southGeorgiaFormthree.controls['cmd_100021'].invalid) || (southGeorgiaFormthree.controls['cmd_100021'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Multiple Products Contracts</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Participate</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Do Not Wish to Participate</b></label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label class="formLabel">Aetna</label>
                                <ol class="olTypeSet">
                                    <li>PPO
                                    </li>
                                    <li>HMO
                                    </li>
                                </ol>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100022" name="cmd_100022" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100022'].touched && southGeorgiaFormthree.controls['cmd_100022'].invalid) || (southGeorgiaFormthree.controls['cmd_100022'].invalid && isSubmit)" formControlName="cmd_100022" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100022" name="cmd_100022" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100022'].touched && southGeorgiaFormthree.controls['cmd_100022'].invalid) || (southGeorgiaFormthree.controls['cmd_100022'].invalid && isSubmit)" formControlName="cmd_100022" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100022'].touched && southGeorgiaFormthree.controls['cmd_100022'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100022'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100022'].touched && southGeorgiaFormthree.controls['cmd_100022'].invalid) || (southGeorgiaFormthree.controls['cmd_100022'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Coventry</label>
                                <ol style="font-weight: normal;font-size: 14px;list-style-type:1;" >
                                    <li>Coventry HealthCare of Georgia (HMO/Open Access/POS)
                                    </li>
                                    <li>Coventry HealthCare National Network
                                    </li>
                                    <li>First Health (PPO)
                                    </li>
                                </ol>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100023" name="cmd_100023" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100023'].touched && southGeorgiaFormthree.controls['cmd_100023'].invalid) || (southGeorgiaFormthree.controls['cmd_100023'].invalid && isSubmit)" formControlName="cmd_100023"  value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100023" name="cmd_100023" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100023'].touched && southGeorgiaFormthree.controls['cmd_100023'].invalid) || (southGeorgiaFormthree.controls['cmd_100023'].invalid && isSubmit)" formControlName="cmd_100023" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100023'].touched && southGeorgiaFormthree.controls['cmd_100023'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100023'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100023'].touched && southGeorgiaFormthree.controls['cmd_100023'].invalid) || (southGeorgiaFormthree.controls['cmd_100023'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Humana PPO and HMO</label>
                                <ol style="font-weight: normal;font-size: 14px;list-style-type:1;" >
                                    <li>ChoiceCare Network PPO
                                    </li>
                                    <li>Humana National POS - Open Access (including Humana NPOS Exchange product)
                                    </li>
                                    <li>Humana<I>One</I>
                                    </li>
                                </ol>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100024" name="cmd_100024" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100024'].touched && southGeorgiaFormthree.controls['cmd_100024'].invalid) || (southGeorgiaFormthree.controls['cmd_100024'].invalid && isSubmit)" formControlName="cmd_100024" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100024" name="cmd_100024" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100024'].touched && southGeorgiaFormthree.controls['cmd_100024'].invalid) || (southGeorgiaFormthree.controls['cmd_100024'].invalid && isSubmit)" formControlName="cmd_100024"  value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100024'].touched && southGeorgiaFormthree.controls['cmd_100024'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100024'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100024'].touched && southGeorgiaFormthree.controls['cmd_100024'].invalid) || (southGeorgiaFormthree.controls['cmd_100024'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label class="formLabel"><b>Exchange Product</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label class="formLabel"><b>Participate</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label class="formLabel"><b>Do Not Wish to Participate</b></label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Ambetter</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100025" name="cmd_100025" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100025'].touched && southGeorgiaFormthree.controls['cmd_100025'].invalid) || (southGeorgiaFormthree.controls['cmd_100025'].invalid && isSubmit)" formControlName="cmd_100025" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100025" name="cmd_100025" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100025'].touched && southGeorgiaFormthree.controls['cmd_100025'].invalid) || (southGeorgiaFormthree.controls['cmd_100025'].invalid && isSubmit)" formControlName="cmd_100025" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100025'].touched && southGeorgiaFormthree.controls['cmd_100025'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100025'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100025'].touched && southGeorgiaFormthree.controls['cmd_100025'].invalid) || (southGeorgiaFormthree.controls['cmd_100025'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Caresource Exchange</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100026" name="cmd_100026" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100026'].touched && southGeorgiaFormthree.controls['cmd_100026'].invalid) || (southGeorgiaFormthree.controls['cmd_100026'].invalid && isSubmit)" formControlName="cmd_100026"  value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100026" name="cmd_100026" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100026'].touched && southGeorgiaFormthree.controls['cmd_100026'].invalid) || (southGeorgiaFormthree.controls['cmd_100026'].invalid && isSubmit)" formControlName="cmd_100026" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100026'].touched && southGeorgiaFormthree.controls['cmd_100026'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100026'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100026'].touched && southGeorgiaFormthree.controls['cmd_100026'].invalid) || (southGeorgiaFormthree.controls['cmd_100026'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label class="formLabel"><b>Workers' Compensation Programs</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label class="formLabel"><b>Participate</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label class="formLabel"><b>Do Not Wish to Participate</b></label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label class="formLabel">Corvel Workers' Compensation Program</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100027" name="cmd_100027" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100027'].touched && southGeorgiaFormthree.controls['cmd_100027'].invalid) || (southGeorgiaFormthree.controls['cmd_100027'].invalid && isSubmit)" formControlName="cmd_100027" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100027" name="cmd_100027" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100027'].touched && southGeorgiaFormthree.controls['cmd_100027'].invalid) || (southGeorgiaFormthree.controls['cmd_100027'].invalid && isSubmit)" formControlName="cmd_100027" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100027'].touched && southGeorgiaFormthree.controls['cmd_100027'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100027'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100027'].touched && southGeorgiaFormthree.controls['cmd_100027'].invalid) || (southGeorgiaFormthree.controls['cmd_100027'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Coventry Workers' Compensation Program</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100028" name="cmd_100028" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100028'].touched && southGeorgiaFormthree.controls['cmd_100028'].invalid) || (southGeorgiaFormthree.controls['cmd_100028'].invalid && isSubmit)" formControlName="cmd_100028" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100028" name="cmd_100028" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100028'].touched && southGeorgiaFormthree.controls['cmd_100028'].invalid) || (southGeorgiaFormthree.controls['cmd_100028'].invalid && isSubmit)" formControlName="cmd_100028" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100028'].touched && southGeorgiaFormthree.controls['cmd_100028'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100028'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100028'].touched && southGeorgiaFormthree.controls['cmd_100028'].invalid) || (southGeorgiaFormthree.controls['cmd_100028'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">NovaNet Workers' Compensation Program</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100029" name="cmd_100029" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100029'].touched && southGeorgiaFormthree.controls['cmd_100029'].invalid) || (southGeorgiaFormthree.controls['cmd_100029'].invalid && isSubmit)" formControlName="cmd_100029" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100029" name="cmd_100029" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100029'].touched && southGeorgiaFormthree.controls['cmd_100029'].invalid) || (southGeorgiaFormthree.controls['cmd_100029'].invalid && isSubmit)" formControlName="cmd_100029" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100029'].touched && southGeorgiaFormthree.controls['cmd_100029'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100029'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100029'].touched && southGeorgiaFormthree.controls['cmd_100029'].invalid) || (southGeorgiaFormthree.controls['cmd_100029'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Prime Health Services Workers' Compensation Program</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100030" name="cmd_100030" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100030'].touched && southGeorgiaFormthree.controls['cmd_100030'].invalid) || (southGeorgiaFormthree.controls['cmd_100030'].invalid && isSubmit)" formControlName="cmd_100030" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100030" name="cmd_100030" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100030'].touched && southGeorgiaFormthree.controls['cmd_100030'].invalid) || (southGeorgiaFormthree.controls['cmd_100030'].invalid && isSubmit)" formControlName="cmd_100030" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100030'].touched && southGeorgiaFormthree.controls['cmd_100030'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100030'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100030'].touched && southGeorgiaFormthree.controls['cmd_100030'].invalid) || (southGeorgiaFormthree.controls['cmd_100030'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Medicare Programs</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Participate</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Do Not Wish to Participate</b></label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Advantra</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100031" name="cmd_100031" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100031'].touched && southGeorgiaFormthree.controls['cmd_100031'].invalid) || (southGeorgiaFormthree.controls['cmd_100031'].invalid && isSubmit)" formControlName="cmd_100031" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100031" name="cmd_100031" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100031'].touched && southGeorgiaFormthree.controls['cmd_100031'].invalid) || (southGeorgiaFormthree.controls['cmd_100031'].invalid && isSubmit)" formControlName="cmd_100031" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100031'].touched && southGeorgiaFormthree.controls['cmd_100031'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100031'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100031'].touched && southGeorgiaFormthree.controls['cmd_100031'].invalid) || (southGeorgiaFormthree.controls['cmd_100031'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Aetna Medicare</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100032" name="cmd_100032" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100032'].touched && southGeorgiaFormthree.controls['cmd_100032'].invalid) || (southGeorgiaFormthree.controls['cmd_100032'].invalid && isSubmit)" formControlName="cmd_100032" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100032" name="cmd_100032" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100032'].touched && southGeorgiaFormthree.controls['cmd_100032'].invalid) || (southGeorgiaFormthree.controls['cmd_100032'].invalid && isSubmit)" formControlName="cmd_100032" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100032'].touched && southGeorgiaFormthree.controls['cmd_100032'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100032'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100032'].touched && southGeorgiaFormthree.controls['cmd_100032'].invalid) || (southGeorgiaFormthree.controls['cmd_100032'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label class="formLabel">Humana Medicare</label>
                                <ol class="olTypeSet" >
                                    <li>HumanaChoice PPO
                                    </li>
                                    <li>Humana Gold Choice PFFS
                                    </li>
                                    <li>Humana Group Medicare PPO Plan
                                    </li>
                                </ol>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100033" name="cmd_100033" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100033'].touched && southGeorgiaFormthree.controls['cmd_100033'].invalid) || (southGeorgiaFormthree.controls['cmd_100033'].invalid && isSubmit)" formControlName="cmd_100033" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100033" name="cmd_100033" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100033'].touched && southGeorgiaFormthree.controls['cmd_100033'].invalid) || (southGeorgiaFormthree.controls['cmd_100033'].invalid && isSubmit)" formControlName="cmd_100033" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100033'].touched && southGeorgiaFormthree.controls['cmd_100033'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100033'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100033'].touched && southGeorgiaFormthree.controls['cmd_100033'].invalid) || (southGeorgiaFormthree.controls['cmd_100033'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Prime Health Services Medicare</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100034" name="cmd_100034" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100034'].touched && southGeorgiaFormthree.controls['cmd_100034'].invalid) || (southGeorgiaFormthree.controls['cmd_100034'].invalid && isSubmit)" formControlName="cmd_100034" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100034" name="cmd_100034" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100034'].touched && southGeorgiaFormthree.controls['cmd_100034'].invalid) || (southGeorgiaFormthree.controls['cmd_100034'].invalid && isSubmit)" formControlName="cmd_100034" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100034'].touched && southGeorgiaFormthree.controls['cmd_100034'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100034'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100034'].touched && southGeorgiaFormthree.controls['cmd_100034'].invalid) || (southGeorgiaFormthree.controls['cmd_100034'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">WellCare Medicare</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100035" name="cmd_100035" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100035'].touched && southGeorgiaFormthree.controls['cmd_100035'].invalid) || (southGeorgiaFormthree.controls['cmd_100035'].invalid && isSubmit)" formControlName="cmd_100035" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100035" name="cmd_100035" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100035'].touched && southGeorgiaFormthree.controls['cmd_100035'].invalid) || (southGeorgiaFormthree.controls['cmd_100035'].invalid && isSubmit)" formControlName="cmd_100035" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100035'].touched && southGeorgiaFormthree.controls['cmd_100035'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100035'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100035'].touched && southGeorgiaFormthree.controls['cmd_100035'].invalid) || (southGeorgiaFormthree.controls['cmd_100035'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <th colspan="10" class="tableHeadingColour">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Medicaid CMO Programs</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Participate</b></label>
                            </div>
                        </th>
                        <th colspan="2" class="tableHeadingColour">
                            <div class="col-md-2" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px"><b>Do Not Wish to Participate</b></label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">CareSource Medicaid</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100036" name="cmd_100036" formControlName="cmd_100036" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100036" name="cmd_100036" formControlName="cmd_100036" value="0"></label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">Peach State Health Plan</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100037" name="cmd_100037" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100037'].touched && southGeorgiaFormthree.controls['cmd_100037'].invalid) || (southGeorgiaFormthree.controls['cmd_100037'].invalid && isSubmit)" formControlName="cmd_100037"  value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100037" name="cmd_100037" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100037'].touched && southGeorgiaFormthree.controls['cmd_100037'].invalid) || (southGeorgiaFormthree.controls['cmd_100037'].invalid && isSubmit)" formControlName="cmd_100037"  value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100037'].touched && southGeorgiaFormthree.controls['cmd_100037'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100037'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100037'].touched && southGeorgiaFormthree.controls['cmd_100037'].invalid) || (southGeorgiaFormthree.controls['cmd_100037'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <div class="col-md-8" class="tableheadingPadding">
                                <label style="font-weight: normal;font-size: 14px">WellCare Medicaid</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100038" name="cmd_100038" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100038'].touched && southGeorgiaFormthree.controls['cmd_100038'].invalid) || (southGeorgiaFormthree.controls['cmd_100038'].invalid && isSubmit)" formControlName="cmd_100038" value="1"></label>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="col-md-2" class="tableheadingPadding">
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px">
                                    <label class="checkbox-inline" style="padding-top: 0px">
                                    <input type="radio" id="cmd_100038" name="cmd_100038" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100038'].touched && southGeorgiaFormthree.controls['cmd_100038'].invalid) || (southGeorgiaFormthree.controls['cmd_100038'].invalid && isSubmit)" formControlName="cmd_100038" value="0"></label>
                                    <div class="formLabel col-md-11"
                                        *ngIf="southGeorgiaFormthree.controls['cmd_100038'].touched && southGeorgiaFormthree.controls['cmd_100038'].invalid">
                                        <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100038'].errors?.required">
                                            required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <span *ngIf="(southGeorgiaFormthree.controls['cmd_100038'].touched && southGeorgiaFormthree.controls['cmd_100038'].invalid) || (southGeorgiaFormthree.controls['cmd_100038'].invalid && isSubmit)">
                            <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                    </tr>
                </table>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Date:</b></label>
                    </div>
                    <div class="col-md-2 set">
                        <div class="input-group">
                            <input style="width:50%;" class="text" ngbDatepicker #d11="ngbDatepicker" class="form-control" (blur)="clearInputIfInvalidMandi('cmd_100008')" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100008'].touched && southGeorgiaFormthree.controls['cmd_100008'].invalid) || (southGeorgiaFormthree.controls['cmd_100008'].invalid && isSubmit)" placeholder="MM/DD/YYYY" id="cmd_100008" name="cmd_100008" formControlName ="cmd_100008" type="text" data-id="tabledate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>
                        <div class="formLabel col-md-11"
                            *ngIf="southGeorgiaFormthree.controls['cmd_100008'].touched && southGeorgiaFormthree.controls['cmd_100008'].invalid">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100008'].errors?.required">
                                required
                            </small>
                        </div>
                        <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100008').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)                </small>
                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100008').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100008').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100008').errors?.invalidDateRange">
                              date is out of range
                              </small>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Physician Signature:</b></label>
                    </div>
                    <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">

                    <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">

                    <input type="hidden" name="cmd_103009" id="cmd_103009" formControlName="cmd_103009" value={{finalImgPath}}>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Physician Name:</b></label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" id="cmd_110003" name="cmd_110003" formControlName="cmd_110003" type="text" placeholder= "First Name" data-id="fname">
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" id="cmd_110004" name="cmd_110004" formControlName="cmd_110004" type="text" placeholder= "Last Name" data-id="lname">
                    </div>
                    <div class="mb-3 selectDropTwo col-md-2">
                        <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="cmd_110216">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Physician Tax I.D. Number:</b></label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="cmd_100011" name="cmd_100011" formControlName="cmd_100011" type="text" data-id="taxid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Practice Name:</b></label>
                    </div>
                    <!-- <div class="mb-3 selectDrop col-md-2">
                        <ng-select [items]="location" bindLabel="name" placeholder="Select Location" bindValue="id" formControlName="cmd_100097">
                        </ng-select>
                    </div> -->
                    <div class="mb-3 selectDrop col-md-2">
                        <ng-select [items]="locDropdown" bindLabel="label" placeholder="Select Location" bindValue="id" formControlName="cmd_100097">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Specialty:</b></label>
                    </div>
                    <div class="mb-3 selectDrop col-md-2">
                        <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id" formControlName="cmd_100099">
                        </ng-select>
                    </div>
                </div>

                <div class="row">
                    <h3 class="text-center divheadingPadding">SOUTH GEORGIA PHYSICIANS ASSOCIATION, L.L.C. </h3>
                    <h5 class="text-center divheadingPaddingTwo">HEALTHCARE PROVIDER AGREEMENT WITH PHYSICIAN </h5>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-md-4">
                        <label class="formLabel"> THIS AGREEMENT, entered into as of the</label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="cmd_100001" name="cmd_100001" formControlName="cmd_100001" type="text" data-id="agreementday">
                    </div>
                    <div class="col-md-4">
                        <input class="form-control" id="cmd_100002" name="cmd_100002" formControlName="cmd_100002" type="text" data-id="agreementdate">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label style="font-weight: normal;font-size: 14px"> , 2019 by and between South Georgia Physicians Association, L.L.C., a Georgia corporation (the "IPA"), and the
                            undersigned physician (the "Physician").
                        </label>
                    </div>
                </div>
                <h5 class="title">WITNESSETH: </h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            WHEREAS, the IPA is organized for the purpose of functioning as an independent practice
                            association and arranging for the provision of professional health care services, by and through its
                            contracted providers by their entering into contractual or other arrangements with health maintenance
                            organizations, preferred provider organizations, self insured employers, managed care organizations,
                            hospitals, and others; and
                        </li><br>
                        <li>
                            WHEREAS, the Physician desires, by entering into this Agreement, to qualify for and become
                            a participating contracted provider for the IPA and to render professional health care services as
                            hereinafter described; and
                        </li><br>
                        <li>
                            Sample Fee Schedule – A schedule of the top codes for your specialty. The last
                            page of the schedule lists networks that reimburse based on a percentage of
                            billed charges. If additional CPT codes are needed, please contact the SGPA.
                        </li><br>
                        <li>
                            SGPA Physician Provider Agreement - This is the same Agreement that all 550+
                            physicians associated with the SGPA have signed.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE l.</h5>
                <h5 class="title">DEFINITIONS</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 1. 1. <u>Approved Providers.</u> "Approved Providers" shall mean those duly licensed health
                            care practitioners (e.g., radiologists) and duly licensed health care providers (e.g., laboratories)
                            approved by a particular Plan to render specified health care services to Plan Members of that Plan.
                        </li><br>
                        <li>
                            Section 1.2. <u> Services.</u> "Covered Services" shall mean those health care services for
                            which Plan Members are entitled to receive benefits from a Plan under a Plan 1 Member Contract.
                        </li><br>
                        <li>
                            Section 1.3. <u>Designated Hospitals.</u> "Designated Hospitals" shall be those hospitals included on
                            the list of Designated Hospitals adopted by the Board of Directors of the IPA from time to time.
                        </li><br>
                        <li>
                            Section 1.4. <u>Medical Services Agreement.</u> "Medical Services Agreement" shall mean an
                            agreement entered into by and between the IPA and a Plan.
                        </li><br>
                        <li>
                            Section 1.5. <u>Physician.</u> "Physician" shall mean the undersigned physician.
                        </li><br>
                        <li>
                            Section 1.6. <u>Non-Covered Services.</u> "Non-Covered Services" shall mean all professional health
                            care services rendered by the Physician to Plan Members other than Covered Services.
                        </li><br>
                        <li>
                            Section 1.7. <u>IPA.</u> "IPA" shall mean South Georgia Physicians Association, L.L.C., a
                            Georgia for Profit Corporation.
                        </li><br>
                        <li>
                            Section 1.8. <u>Payor.</u> "Payor" shall mean any employer, insurance company, health benefit trust
                            or other similar entity that has entered into a Medical Services Agreement with the IPA.
                        </li><br>
                        <li>
                            Section 1.9. <u>Physician.</u> "Physician" shall mean the Physician who executed this Agreement.
                        </li><br>
                        <li>
                            Section 1.10. <u>Plan.</u> "Plan" shall mean a health maintenance organization, preferred provider
                            organization, self insured employer, managed care organization or other person or entity offering
                            comprehensive health care services to its members through one or more health care plans.
                        </li><br>
                        <li>
                            Section 1.11. <u>Plan Member.</u> "Plan Member" shall mean a person who has entered into a
                            Plan/Member Contract for the provision of Covered Services, or an employee whose employer has
                            entered into a group contract with a Plan for the provision of Covered Services to its employees, and
                            his or her eligible dependents.
                        </li><br>
                        <li>
                            Section 1.12. <u>Plan/Member Contract.</u> "Plan/Member Contract" shall mean a contract entered
                            into by a Plan, which entitles Plan Members to receive benefits for Covered Services in accordance
                            with the terms and conditions thereof
                        </li><br>
                        <li>
                            Section 1.13. <u>Utilization Review.</u> "Utilization Review" shall mean the function performed by the
                            IPA or its designee, or by any Payor or its designee, to determine whether the services provided
                            pursuant to the Plan/Member Contract, or to be provided thereunder, are Covered Services and meet
                            quality assurance standards. The Utilization Review program may include mechanisms for
                            pre-admission, concurrent, and retrospective review of medical and hospital services.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 2.</h5>
                <h5 class="title">RELATIONSHIP BETWEEN THE IPA AND THE PHYSICIAN</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 2.1. <u>Contractual Relationship.</u> The Physician must enter into this Agreement in
                            order to become a contracting party with the IPA.
                        </li><br>
                        <li>
                            Section 2.2. <u>Independent Contractor Relationship.</u> The IPA and the Physician are
                            independent entities. In the performance of all duties and obligations under this Agreement, the
                            Physician shall at all times act and perform solely as an independent contractor. Nothing in this
                            Agreement shall be construed or be deemed to create a relationship between the IPA and the
                            Physician, of employer and employee, co-venturer, partner, or principal and agent, or any relationship
                            whatsoever other than that of independent parties contracting with each other solely for the purposes
                            of carrying out the provisions of this Agreement, with the exception of the limited agency created by
                            the appointment of the IPA as the Physician's attorney-in-fact for the purpose of negotiating the
                            Medical Services Agreement. The Physician shall have no authority to bind the IPA in any contract or
                            other obligation.
                        </li><br>
                        <li>
                            Section 2.3. <u>Physician/Patient Relation hip.</u> This Agreement is not intended to alter nor shall it
                            be construed as altering, the physician/patient relationship between the Physician and each Plan
                            Member. The Physician shall be solely responsible, as between the Physician and the IPA, for all
                            professional health care services rendered by the Physician to Plan Members.
                        </li><br>
                        <li>
                            Section 2.4. <u>Payment Obligations.</u> Except as otherwise expressly set forth in this Agreement,
                            the IPA's payment obligation hereunder shall be limited to the payment of the fees described in Article
                            5 of this Agreement to the Physician for the provision for Covered Services to Plan Members. Except
                            as may be expressly authorized from time to time under the IPA's policies and procedures, the
                            Physician hereby waives his or her right to bill and collect payment for the provision of Covered
                            Services to Plan Members from either the IPA, Plan Member, or any other person or entity.
                        </li><br>
                        <li>
                            Section 2.5. <u>Plan Member Benefits.</u> The benefits to which a Plan Member shall be entitled are
                            set out in the applicable Plan/Member Contract in effect at the time the Covered Services are
                            rendered, and the IPA shall not be responsible for any misrepresentation of such benefits made to the
                            Physician by the Plan Member or by any person other than the IPA.
                        </li><br>
                        <li>
                            Section 2.6. <u>Other Affiliations.</u> Nothing contained in this Agreement is intended to prevent or
                            otherwise restrict the Physician from joining or affiliating with any other health care delivery
                            organization, managed care entity or from contracting with any Payor to provide health care services.
                            The Physician may not serve as an officer or director of any independent practice association, which
                            competes, with the IPA.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 3. </h5>
                <h5 class="title">PHYSICIAN SERVICES AND RESPONSIBILITIES</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 3.1. Current and Effective License to Practice Membership and Privileges at
                            <u>Designated Hospitals.</u><br>
                            During the term of this Agreement, the Physician shall be in good standing to practice
                            medicine in the State of Georgia, and shall also remain in compliance with the IPA's criteria for its
                            contracted physicians, a copy of which shall be provided to Physician upon request. Upon request by
                            the IPA, the Physician shall submit evidence of all such current and effective licenses and certification
                            to the IPA.<br>
                            Subject to the requirements of this Subsection 3.1, the Physician shall also have and
                            maintain current, unrestricted medical staff membership and clinical privileges sufficient to admit
                            patients at one or more Designated Hospitals.
                            Subject to the requirements of this Subsection 3.1, the credential verification process
                            described in Section 3.6 hereof must be completed prior to the Physician providing health care
                            services to any Plan Members referred by or through the IPA.
                        </li><br>
                        <li>
                            Section 3.2. <u>Provision of Covered Services.</u> The Physician shall provide the professional
                            health care services required by all Plan Members who are referred by or through the IPA to the
                            Physician for medical treatment; provided, however, that the Physician acknowledges and
                            understands that the IPA shall have no obligation to refer any Plan Member to the Physician,
                            except as required under the IPA's then-current policies and procedures.
                            All such professional health care services will be provided only when medically necessary,
                            and only in such setting as is appropriate in the context of good patient care. The Physician will
                            render services in a cost effective manner and in a cost effective setting, consistent with the
                            appropriate treatment of the condition for which treatment is required in the provision of safe,
                            quality care. The Physician is not authorized to subcontract its obligation to render Covered
                            Services to Plan Members hereunder, except for such call and other arrangements as may be
                            authorized from time to time under the IPA's then current policies and procedures.
                        </li><br>
                        <li>
                            Section 3.3. <u>Provider Referrals.</u> When referral of a Plan Member to a health care provider
                            (radiologist, laboratory, etc.) is appropriate in the Physician's judgment, the Physician will (if
                            required) refer such Plan Member only to an Approved Provider, in accordance with such policies
                            as may be adopted from time to time by the IPA and the particular Plan involved.
                        </li><br>
                        <li>
                            Section 3.4. <u>Cooperation with Utilization Review.</u> The Physician shall cooperate with and
                            participate in the Utilization Review program implemented by the IPA, as described in Article 6
                            hereof, and shall accept the Utilization Review determinations as conclusive as to the IPA's
                            obligation to make payments to the Physician for Covered Service hereunder, subject to any rights
                            of appeal as provided in Article 6.
                        </li><br>
                        <li>
                            Section 3.5. <u>Compliance with IPA Policies. Etc.</u> The Physician hereby agrees to abide by
                            the policies, rules and regulations of the IPA, which are applicable to the rendering of professional
                            health care services hereunder, and as may be published from time to time by the IPA or
                            otherwise made known to the Physician.
                        </li><br>
                        <li>
                            Section 3.6. <u>Peer Review and Credentialing.</u> In addition to the requirements of Section 3.1
                            hereof, the Physician hereby acknowledges that the IPA will individually credential the Physician,
                            and agrees to cooperate with the credentialing and review process. The IPA will adopt a
                            credentialing and review process for determining the eligibility of each Physician to participate in
                            the IPA. The credentialing and review process established by the IPA will be consistent with the
                            requirements of the Health Care Quality Improvement Act of 1986, as amended.
                        </li><br>
                        <li>
                            Section 3.7. <u>Quality Assessment.</u> The IPA shall implement a quality assessment program to
                            monitor the care provided to Plan Members and to verify that the Physician is rendering Covered
                            Services in compliance with recognized standards of care, including proper coding of services and
                            the provision of medically necessary care.<br>
                            The Physician acknowledges that the IPA's quality assessment program may identify
                            Approved Providers and physicians who do not appear to be providing acceptable medical care.
                            The Physician shall cooperate fully with the IPA in resolving such situations. If a satisfactory
                            resolution does not occur within a reasonable time as determined by the IPA in its sole discretion,
                            the Physician agrees that this Agreement may be subject to termination by the IPA in accordance
                            with Article 9 of this Agreement. The Physician shall assist the IPA with compliance with this
                            Section 3.7 by responding to requests for information regarding Plan Member's treatments and
                            admissions.
                        </li><br>
                        <li>
                            Section 3.8. <u>Outcome and Performance Data.</u> The Physician covenants that he or she shall
                            assist the IPA in the collection of outcomes and performance data for each Physician and
                            Approved Provider. The Physician acknowledges and agrees that the IPA may provide such data
                            to Payors, prospective Payors and Designated Hospitals. The Physician shall have the right to
                            inspect his or her individual data and shall also have the right to correct any errors or omissions.
                            Nothing herein shall authorize the IPA to require or obtain financial data relating to the business
                            operations of the Physician.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 4.</h5>
                <h5 class="title">IPA RESPONSIBILITIES</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 4.1. <u>Payment.</u> The IPA will compensate the Physician by direct payment, pursuant to
                            the provisions of and in accordance with the IPA's obligations under Article 5 hereof.
                        </li><br>
                        <li>
                            Section 4.2. <u>Information and Material.</u> The IPA agrees to provide the Physician with such
                            informational, marketing and promotional materials as is provided to the IPA by each Plan.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 5.</h5>
                <h5 class="title">COMPENSATION AND BILLING </h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 5. 1. <u>Submission of Claims.</u> The Physician agrees to submit, on standard HCFA 1500
                            forms or on other forms supplied or approved in advance by the IPA, a statement of Covered Services
                            rendered by the Physician to each Plan Member, including such specific information as the IPA
                            reasonably may require, such submission to be made to the person or persons designated by the IPA.
                            Such claims should be submitted within ninety (90) business days after the end of the month during
                            which the services are rendered, or within such shorter period of time as may be required under a
                            particular Medical Services Agreement. Claims not made within such periods are not required to be
                            paid, but the IPA will consider the advisability of payment if late claims are accompanied by
                            documentation stating why the claims were filed late.
                        </li><br>
                        <li>
                            Section 5.2. <u>Limitations on Charges.</u> Except as expressly provided herein, the Physician shall
                            seek payment only from the IPA for the provision of Covered Services to Plan Members, and
                            specifically shall not seek payment from the Plan Member or the Plan for such services; provided,
                            however, that nothing in this Agreement shall be construed as limiting the Physician's right to seek
                            payment from Plan Members for charges relating to (i) the provisions of Non-Covered Services, (ii)
                            any applicable co-payments or deductibles, and (iii) the provisions of those Covered Services for which
                            separate payment to the Physician is authorized under the applicable Medical Service Agreement.
                        </li><br>
                        <li>
                            Section 5.3. <u>Payment of Charges.</u> The IPA shall pay to the Physician, as payment in full
                            (except as otherwise expressly provided in this Agreement) for the provision of Covered Services to
                            Plan Members hereunder, that amount described in and calculated in accordance with the
                            then-current compensation policy of the IPA, as approved by the Board of Directors of the IPA; a
                            copy of such compensation policy shall be attached hereto as <u>Exhibit A</u> and made a pars hereof.
                            Payment of such amounts shall be made in accordance with the terms and provisions set out in <u>Exhibit
                            A</u> hereto.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 6.</h5>
                <h5 class="title">UTILIZATION REVIEW</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 6. 1. <u>Utilization Review.</u> With respect to all Covered Services, the IPA shall establish,
                            and the Physician hereby agrees to cooperate with and be bound by the determinations of, the
                            Utilization Review Program in effect from time to time during the term of this Agreement.
                        </li><br>
                        <li>
                            Section 6.2. <u>Appeals.</u> The Physician may appeal a Utilization Review decision by requesting
                            reconsideration by the IPA following an initial adverse recommendation, within thirty (30) days
                            following communication of such initial adverse recommendation to the Physician. Such
                            reconsideration shall be conducted and the reconsideration decision shall be communicated to the
                            Physician in accordance with procedures adopted by the Board of Directors of the IPA from time to
                            time. If the Physician is not satisfied with the result of such reconsideration, the Physician may initiate
                            the dispute resolution process set forth in Section 8.3 hereof, if and as provided in the applicable
                            Plan/Member Contract.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 7.</h5>
                <h5 class="title">MEDICAL RECORDS</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 7.1. <u>Preparation and Maintenance of record.</u> The Physician shall prepare and maintain
                            all appropriate records on Plan Members receiving Covered Services. The records shall be maintained
                            in accordance with prudent record-keeping procedures and as required by law.
                        </li><br>
                        <li>
                            Section 7.2. <u>Ownership of Records.</u> Records of Plan Members shall be owned by the
                            Physician treating such Plan Members, except as otherwise provided under applicable state law.
                            Subject to all applicable privacy and confidentiality requirements, medical records of a Plan
                            Member shall be made available by the Physician upon request to the IPA and its designees for
                            Utilization Review purposes.
                        </li><br>
                        <li>
                            Section 7.3. <u>Confidentiality.</u> The IPA and the Physician each agree to keep confidential,
                            and to take all reasonable precautions to prevent unauthorized disclosure of, any and all records
                            required to be prepared and/or maintained by this Agreement. Neither the IPA nor the Physician
                            shall disclose any such records or their contents, except upon written request for the other party
                            hereto or their authorized representatives, to a properly identified government authority or as
                            otherwise provided in this Agreement or required by law.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 8. </h5>
                <h5 class="title">INSURANCE, INDEMNIFICATION AND DISPUTE RESOLUTION</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 8.1. <u>Insurance.</u> During the term of this Agreement and for the period thereafter that
                            the Plan Members remain patients of the Physician, the Physician shall, at his or her sole cost and
                            expense, obtain and maintain such policies of comprehensive general liability, professional liability and
                            other insurance as shall be necessary to ensure that the Physician, and his or her agents, servants, and
                            employees, are covered and protected against any claim for damages arising by reason of personal
                            injuries or death occasioned directly or indirectly in connection with the performance or
                            non-performance of any services provided by him or her, or by his or her agents, servants and
                            employees acting within the scope of their duties. The IPA shall be named as an additional insured in
                            such policies.
                            Policy limits for such insurance shall equal or exceed one million dollars ($1,000,000) for
                            comprehensive general liability insurance and one million dollars ($1,000,000) per occurrence and
                            one million dollars ($ 1,000,000) aggregate for professional liability insurance or the minimum levels
                            that may from time to time be other-wise established by the IPA. The Physician shall provide the IPA
                            with a certificate of insurance verifying placement and maintenance of all required insurance. The
                            Physician shall also ensure that the insurer provides thirty (30) days' written notice to the IPA regarding
                            cancellation of insurance, intent not to renew, or any material change in coverage.
                        </li><br>
                        <li>
                            Section 8.2. <u>Indemnification by Physician.</u> To the extent not covered by the Physician' s
                            insurance as described in Section 8. 1 herein, the Physician hereby agrees to indemnity and hold
                            harmless the IPA, its officers, directors and employees, from and against any claim or liability arising
                            from or as a result of the Physician's provision of services or failure to provide necessary services to
                            Plan Members under this Agreement.
                        </li><br>
                        <li>
                            Section 8.3. <u>Dispute Resolution.</u>
                            <ol class="olTypeAStyle">
                                <li>
                                    <u>Resolution and Mediation of Disputes.</u> In the event of a dispute between the parties to this
                                    Agreement, the following procedure will be used in a good faith attempt to resolve the dispute prior to
                                    the pursuit by either party of other available remedies set forth herein:
                                    <ol class="olTypeIStyle">
                                        <li>
                                            A meeting (the "initial Meeting") shall promptly be held at which all parties are present or
                                            are represented by individuals with full decision-making authority regarding the matters
                                            in dispute.
                                        </li><br>
                                        <li>
                                            If, within thirty (30) days following the Initial Meeting, the parties have not resolved the
                                            dispute, the dispute shall be submitted to mediation administered by the American
                                            Arbitration Association under its Commercial Mediation Rules. Each party shall bear
                                            its proportionate share of the costs of the mediation.
                                        </li><br>
                                        <li>
                                            The parties agree to negotiate in good faith during the Initial Meeting and during
                                            mediation conferences.
                                        </li><br>
                                    </ol>
                                </li>
                                <li>
                                    <u>Failure of Mediation.</u> If, after a period of sixty (60) days following the Initial Meeting, the
                                    parties are unable to resolve the dispute, either party may initiate binding arbitration pursuant to
                                    Subsection 8.3(c) upon ten (10) days' prior written notice to the other party. The initiation of
                                    arbitration, however, shall not eliminate the obligation of the parties to continue to negotiate in good
                                    faith and attempt to resolve the dispute.
                                </li>
                                <li>
                                    <u>Binding Arbitration.</u> Any and all disputes over the interpretation or application of any of the
                                    terms of this Agreement, including whether a matter is arbitrational, which are not resolved through
                                    the mediation process described herein, shall be submitted to final and binding arbitration pursuant to
                                    the provisions of the Georgia Arbitration Code (O.C.G.A. § 9-9-l, et sseq.), as amended from time to
                                    time.
                                </li>
                            </ol>
                            terms of this Agreement, including whether a matter is arbitrational, which are not resolved through
                            the mediation process described herein, shall be submitted to final and binding arbitration pursuant to
                            the provisions of the Georgia Arbitration Code (O.C.G.A. § 9-9-l, et sseq.), as amended from time to
                            time. <br>
                            The arbitration shall be administered by the American Arbitration Association in accordance
                            with its Commercial Arbitration rules. The arbitration decision shall not be appealable, shall be in lieu
                            of any right to seek litigation or other arbitration in any court or tribunal, and judgment on the award
                            may be entered in any court having jurisdiction thereof. Such arbitration shall be held in the State of
                            Georgia. Each party shall be responsible for its own costs; provided, however, that the losing party shall
                            pay all common costs incurred in connection with such arbitration proceedings.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 9.</h5>
                <h5 class="title">TERM AND TERMINATION</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 9.1. <u>Term.</u> This Agreement shall become effective as of the date and year first above
                            written, and shall continue for an initial term ending on December 31, 2017. Thereafter, this
                            Agreement shall be automatically renewed for additional one-year terms unless and until terminated
                            pursuant to the terms of this Article 9.
                        </li><br>
                        <li>
                            Section 9.2. <u>Termination by the Physician.</u> The Physician may terminate this Agreement, with
                            or without cause, upon providing written notice to the IPA at least ninety (90) days prior to the
                            effective termination date which date shall be stated in such notice.
                        </li><br>
                        <li>
                            Section 9.3. <u>Termination by the IPA.</u>
                            <ol class="olTypeAStyle">
                                <li>
                                    This Agreement may be terminated by the IPA in the event that the Physician's license
                                    to practice is suspended, nullified or revoked by the Composite State Board of
                                    Medical Examiners; provided, however, that the Physician shall have ten (10) days
                                    from such termination event to cure the default (e.g.; the license is reinstated).
                                    <br>
                                    Notwithstanding the foregoing, if the Physician fails to maintain the credentials required
                                    hereunder, he or she is immediately and automatically prohibited from treating any Plan
                                    Members referred by or through the IPA to the Physician.
                                </li>
                                <li>
                                    This Agreement may also be terminated by the IPA on thirty (30) days prior written
                                    notice to the Physician if (i) the Physician shall breach any of its obligations under this
                                    Agreement; or (u) the Physician provides written notice to terminate this Agreement
                                    pursuant to Section 9.2 above
                                </li>
                                <li>
                                    IPA may terminate this Agreement in the event that a pattern of overutilization by said
                                    Physician is determined by the IPA, in its sole discretion, and in accordance with
                                    Section 6. 1 hereof.
                                </li>
                            </ol>
                        </li><br>
                        <li>
                            Section 9.4. <u>Termination by Either Party.</u> This Agreement may also be terminated by
                            either party without cause on ninety (90) days prior written notice to the other party.
                            <br>
                            Either party may terminate this Agreement if the other party files, or has filed against it, in
                            any court of competent jurisdiction a petition in bankruptcy, which petition is not discharged within
                            sixty (60) days.
                        </li><br>
                        <li>
                            Section 9.5. <u>Effect of Termination.</u> This Agreement shall remain in full force and effect during
                            the period between the date that notice of termination is provided and the effective date of such
                            termination, unless the termination is effective immediately as herein provided. Upon termination of
                            this Agreement, the IPA shall or shall require the Physician to notify each Plan Member seeking
                            services from the Physician, prior to providing services, that this Agreement has been terminated. In
                            such an event, the Physician acknowledges that the IPA may notify the Plan Members of such
                            termination.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 10.</h5>
                <h5 class="title">CMS COMPLIANCE</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 10.1 <u>Record Retention</u> HHS, the Comptroller General or their designees have
                            the right to audit, evaluate and inspect any pertinent information including books, contracts,
                            records, including medical records, and documentation related to the provision of services to any
                            beneficiary of CMS for a period of 10 years from the final date of the contract period or the
                            completion of any audit, whichever is later.
                        </li><br>
                        <li>
                             Section 10.2 <u>Privacy and Accuracy of Records</u> Provider and their suppliers agree to
                            safeguard beneficiary privacy and confidentiality and ensure the accuracy of beneficiary health
                            records.
                        </li><br>
                        <li>
                            Section 10.3 <u>Hold Harmless</u> Providers may not hold beneficiaries liable for payment of fees
                            that are the legal obligation of the network.
                        </li><br>
                        <li>
                            Section 10.4 <u>Compliance with CMS Contractual Obligations.</u> As a downstream provider
                            for CMS beneficiaries, any services or other activity performed are consistent and comply with
                            CMS’ contractual obligations.
                        </li><br>
                        <li>
                            Section 10.5 <u>Delegated Activities – Credentialing.</u> The credentials of medical professionals
                            affiliated with the IPA will comply with CMS requirements and will be audited to ensure ongoing
                            adherence to required standards.
                        </li><br>
                    </ul>
                </div>
                <h5 class="title">ARTICLE 11.</h5>
                <h5 class="title">GENERAL PROVISIONS</h5>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                    <ul class="ultype">
                        <li>
                            Section 11.1. <u>Assignment.</u> Except as provided for in Section 3.2 herein, no assignment of the
                            rights, duties or obligations of this Agreement shall be made by the Physician. Any attempted
                            assignment in violation of this Provision shall be void and of no force and effect.
                        </li><br>
                        <li>
                            Section 11.2. <u>Waiver of Breach.</u> Waiver of a breach of any provision of this Agreement
                            shall not be deemed a waiver of any other breach of the same or of any different provision.
                        </li><br>
                        <li>
                            Section 11.3. <u>Notices.</u> Any notice required to be given pursuant to the terms and
                            provisions of this Agreement shall be in writing and shall be sent by certified mail, return receipt
                            requested, postage prepaid, to the IPA at and to the Physician at the address shown on the books
                            and records of the IPA, or at such other address as may be provided by the Physician in writing to
                            the IPA.
                        </li><br>
                        <li>
                            Section 11.4. <u>Entire Agreement.</u> This Agreement, including the exhibits attached hereto,
                            constitutes the entire understanding between the parties hereto with respect to the subject matter
                            hereof, and, unless otherwise authorized in this Agreement, no changes, amendments, or alterations
                            shall be effective unless agreed to in writing by the parties hereto. Notice to or consent of Plan
                            Members shall not be required to effect this Agreement or any medication hereto.
                        </li><br>
                        <li>
                            Section 11.5. <u>Severability.</u> In the event any provision of this Agreement is rendered invalid or
                            unenforceable by any federal or state law or by any regulation duly promulgated by officers of agencies
                            of the United States or the State of Georgia, the remainder of the provisions of this Agreement shall
                            remain in full force and effect
                        </li><br>
                        <li>
                            Section 11.6. <u>Governing Law.</u> This Agreement shall be governed by and construed in
                            accordance with the laws of the State of Georgia.
                        </li><br>
                        <li>
                            Section 11.7. <u>Headings.</u> The headings contained in this Agreement are for reference
                            purposes only and shall not affect in any way the meaning or interpretations of this Agreement.
                        </li><br>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="formLabel"><b>IPA:</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="formLabel"><b>SOUTH GEORGIA PHYSICIANS ASSOCIATION, L.L.C.</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>By:</b></label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="cmd_100003" name="cmd_100003" formControlName="cmd_100003" type="text" data-id="by">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Date:</b></label>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group">
                            <input class="form-control" ngbDatepicker #d12="ngbDatepicker" (blur)="clearInputIfInvalid('cmd_100004')" placeholder="MM/DD/YYYY" id="cmd_100004" name="cmd_100004" formControlName="cmd_100004" type="text" data-id="bydate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar " (click)="d12.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                        </div>
                        <div  *ngIf="southGeorgiaFormthree.get('cmd_100004').invalid ">
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100004').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                            </small>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100004').errors?.invalidMonth">
                            Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100004').errors?.invalidDateyear">
                              year is out of range(1900-2099)                      </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100004').errors?.invalidDateRange">
                                date is out of range
                                </small>
                            </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="formLabel centerAlign"><b>It's duly authorized officer</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="formLabel"><b>PHYSICIAN NAME:</b></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Physician's Name:</b></label>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" id="cmd_110005" name="cmd_110005" formControlName="cmd_110005" type="text" placeholder= "First Name" data-id="fname">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Signature:</b></label>
                    </div>
                    <img  *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenTwo()">
                    <img  *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo"  alt="Credential my Doc" (click)="esignOpenTwo()">
                    <input type="hidden" name="cmd_103096" id="cmd_103096" formControlName="cmd_103096" value={{finalImgPathTwo}}>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="formLabel"><b>Date:</b></label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input class="form-control" ngbDatepicker #d13="ngbDatepicker" placeholder="MM/DD/YYYY" type="text" (blur)="clearInputIfInvalidMandi('cmd_100007')" [class.is-invalid]="(southGeorgiaFormthree.controls['cmd_100007'].touched && southGeorgiaFormthree.controls['cmd_100007'].invalid) || (southGeorgiaFormthree.controls['cmd_100007'].invalid && isSubmit)" formControlName="cmd_100007" data-id="nextcredential">
                            <div class="formLabel col-md-11"
                                *ngIf="southGeorgiaFormthree.controls['cmd_100007'].touched && southGeorgiaFormthree.controls['cmd_100007'].invalid">
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.controls['cmd_100007'].errors?.required">
                                    required
                                </small>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                            </div>
                            <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100007').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY)                </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100007').errors?.invalidMonth">
                              Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100007').errors?.invalidDateyear">
                                year is out of range(1900-2099)                  </small>
                                <small class="text-danger" *ngIf="southGeorgiaFormthree.get('cmd_100007').errors?.invalidDateRange">
                                  date is out of range
                                  </small>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4 ">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
    </div>
</div>
