import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-lifeways-universal',
    templateUrl: './lifeways-universal.component.html',
    styleUrls: ['./lifeways-universal.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class LifewaysUniversalComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer
  ) {}

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public lifeWaysUniversal: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  credentials: Array<object> = [];
  greetings: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  degree: Array<object> = [];
  location: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  boardCertification: Array<object> = [];
  states: Array<object> = [];
  mediCertification: Array<object> = [];
  county: Array<object> = [];
  race: Array<object> = [];
  finalName: any;
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  finalImgPathThree: any = [];
  finalImgThree: any = '';
  esignOne: any;
  esignTwo: any;
  esignThree: any;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  changeCmdOne: any;
  isSubmit: boolean = false;
  firstName: any;
  lastName: any;
  middleName: any;
  db: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  ngOnInit(): void {
    this.buildForm();
    this.getCredentialsData();
    this.getMediCertifications();
    this.getDegreeData();
    this.getStatesData();
    this.getCountyData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getRaceData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
    if (this.formData.previewForm) {
      this.changeCmdOne = true;
    }
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_2500017') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_2500021') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_2500023') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_2500036') {
        if (array[i].field_value == 1) {
          this.changeCmdOne = 1;
        } else {
          this.changeCmdOne = 0;
        }
      }
    }
    this.lifeWaysUniversal.patchValue(fieldsCtrls);
  }

  buildForm() {
    this.lifeWaysUniversal = this.fb.group({
      cmd_2500001: [''],
      cmd_2500002: [''],
      cmd_2500003: [''],
      cmd_2500004: [''],
      cmd_2500005: [''],
      cmd_2500006: [''],
      cmd_2500007: [''],
      cmd_2500008: [''],
      cmd_2500009: [''],
      cmd_2500010: [''],
      cmd_2500011: [''],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      809: ['', [Validators.required]],
      822: [null, [Validators.required]],
      1634: ['', [Validators.required]],
      819: [''],
      325: [''],
      cmd_2500042: [''],
      293: [''],
      295: [null, [this.dateFormatValidator]],
      cmd_2500043: [''],
      300: [''],
      308: [null, [this.dateFormatValidator]],
      cmd_2500045: [''],
      999: [''],
      1004: [null, [this.dateFormatValidator]],
      cmd_2500047: [''],
      1196: [''],
      1199: [null, [this.dateFormatValidator]],
      cmd_900001: [null],
      cmd_900002: [''],
      cmd_900003: [null, [this.dateFormatValidator]],
      cmd_900004: [null],
      cmd_900005: [''],
      1216: [''],
      1217: [''],
      359: [''],
      cmd_25000137: [''],
      360: [''],
      361: [''],
      362: [''],
      365: [''],
      2722: [''],
      369: [''],
      371: [''],
      372: [''],
      460: [null],
      461: [null],
      cmd_25000138: [''],
      373: [''],
      374: [''],
      375: [''],
      378: [''],
      2723: [''],
      381: [''],
      383: [''],
      384: [''],
      cmd_25000139: [''],
      385: [''],
      386: [''],
      387: [''],
      390: [''],
      2725: [''],
      396: [''],
      cmd_25000149: [''],
      cmd_900006: [null, [this.dateFormatValidator]],
      cmd_900007: [null],
      cmd_900008: [''],
      cmd_900009: [null, [this.dateFormatValidator]],
      cmd_9000010: [null],
      cmd_9000011: [''],
      cmd_9000012: [null, [this.dateFormatValidator]],
      cmd_9000013: [null],
      cmd_9000014: [''],
      cmd_9000015: [null, [this.dateFormatValidator]],
      cmd_9000016: [null],
      cmd_9000017: [''],
      cmd_9000018: [null, [this.dateFormatValidator]],
      512: [null],
      503: [''],
      511: [null, [this.dateFormatValidator]],
      480: [null],
      479: [''],
      482: [null, [this.dateFormatValidator]],
      500: [null],
      490: [''],
      499: [null, [this.dateFormatValidator]],
      cmd_2500050: [null],
      cmd_2500051: [''],
      cmd_2500052: [null, [this.dateFormatValidator]],
      517: [''],
      1318: [''],
      518: [''],
      520: [''],
      522: [null],
      519: [''],
      528: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      529: [''],
      1316: [''],
      531: [''],
      533: [''],
      535: [null],
      1498: [''],
      540: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      542: [''],
      1322: [''],
      544: [''],
      546: [''],
      548: [null],
      545: [''],
      553: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      cmd_25000012: [''],
      cmd_25000013: [''],
      cmd_25000014: [''],
      cmd_2500015: [''],
      cmd_2500016: [''],
      cmd_25000018: [''],
      cmd_25000075: [''],
      cmd_25000076: [''],
      cmd_25000077: [''],
      cmd_25000078: [''],
      cmd_25000079: [''],
      cmd_25000080: [''],
      cmd_25000081: [''],
      cmd_25000082: [''],
      cmd_25000083: [''],
      cmd_25000084: [''],
      cmd_25000085: [''],
      cmd_25000086: [''],
      cmd_25000087: [''],
      cmd_25000088: [''],
      cmd_25000089: [''],
      cmd_25000090: [''],
      cmd_25000091: [''],
      cmd_25000092: [''],
      cmd_25000093: [''],
      cmd_25000094: [''],
      cmd_25000095: [''],
      cmd_25000096: [''],
      cmd_25000097: [''],
      cmd_25000098: [''],
      cmd_25000099: [''],
      cmd_25000100: [''],
      cmd_25000101: [''],
      cmd_25000102: [''],
      cmd_25000103: [''],
      cmd_25000104: [''],
      cmd_25000105: [''],
      cmd_25000106: [''],
      cmd_25000107: [''],
      cmd_25000108: [''],
      cmd_25000109: [''],
      cmd_25000110: [''],
      cmd_25000111: [''],
      cmd_25000112: [''],
      cmd_25000113: [''],
      cmd_25000114: [''],
      cmd_25000115: [''],
      cmd_25000116: [''],
      cmd_250001117: [''],
      cmd_25000118: [''],
      cmd_25000119: [''],
      cmd_25000120: [''],
      cmd_25000121: [''],
      cmd_25000122: [''],
      cmd_25000123: [''],
      cmd_25000124: [''],
      cmd_25000125: [''],
      cmd_25000126: [null, [this.dateFormatValidator]],
      cmd_25000127: [''],
      cmd_25000128: [null, [this.dateFormatValidator]],
      cmd_25000129: [''],
      cmd_25000130: [null, [this.dateFormatValidator]],
      cmd_25000131: [''],
      cmd_25000132: [null, [this.dateFormatValidator]],
      cmd_25000133: [''],
      445: [''],
      453: [''],
      446: [''],
      449: [null],
      450: [null],
      888: [''],
      448: [''],
      451: [''],
      452: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      456: [''],
      464: [''],
      457: [''],
      3104: [null],
      890: [''],
      459: [''],
      462: [''],
      463: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      467: [''],
      475: [''],
      471: [null],
      472: [null],
      892: [''],
      470: [''],
      473: [''],
      1339: [''],
      474: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      1935: [''],
      1936: [''],
      1939: [null],
      1940: [null],
      1958: [null],
      1959: [null],
      1945: [''],
      1938: [''],
      1948: [null, [this.dateFormatValidator]],
      1949: [''],
      1943: [''],
      1947: [null, [this.dateFormatValidator]],
      1954: [''],
      1963: [''],
      1955: [''],
      1964: [''],
      1957: [''],
      1960: [''],
      1968: [''],
      1962: [''],
      1966: [null, [this.dateFormatValidator]],
      1967: [null, [this.dateFormatValidator]],
      1326: [''],
      468: [''],
      1944: [''],
      1343: [''],
      cmd_2500024: ['', [Validators.required]],
      cmd_2500025: ['', [Validators.required]],
      cmd_2500026: ['', [Validators.required]],
      cmd_2500027: ['', [Validators.required]],
      cmd_2500028: ['', [Validators.required]],
      cmd_2500029: ['', [Validators.required]],
      cmd_2500030: ['', [Validators.required]],
      cmd_2500032: ['', [Validators.required]],
      cmd_2500031: ['', [Validators.required]],
      cmd_2500033: ['', [Validators.required]],
      cmd_2500034: ['', [Validators.required]],
      cmd_2500035: ['', [Validators.required]],
      cmd_25000136: [null, [this.dateFormatValidator]],
      cmd_25000135: [null, [this.dateFormatValidator]],
      cmd_2500036: ['', [Validators.required]],
      cmd_2500037: ['', [Validators.required]],
      cmd_25000134: ['', [Validators.required]],
      cmd_2500017: ['', [Validators.required]],
      //cmd_1000120 : ['', [Validators.required]],
      277: [null],
      1535: [null],
      394: [''],
      1536: [null],
      2207: [null],
      cmd_2500040: [null, [Validators.required,this.dateFormatValidator]],
      cmd_25000069: [''],
      cmd_25000070: [''],
      cmd_25000071: [''],
      278: ['', [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      cmd_2500018: ['', [Validators.required]],
      741: [null],
      cmd_2500020: [''],
      cmd_2500021: ['', [Validators.required]],
      cmd_2500038: [null, [Validators.required,this.dateFormatValidator]],
      1206: [''],
      1853: [''],
      1208: [null, [this.dateFormatValidator]],
      cmd_25000074: [''],
      1209: [null, [this.dateFormatValidator]],
      1228: [''],
      1210: [''],
      cmd_25000073: [''],
      cmd_2500039: [null, [this.dateFormatValidator]],
      cmd_25000072: [''],
      cmd_2500041: [null, [this.dateFormatValidator]],
      cmd_110215: [null],
      cmd_2500023: ['']
    });
    // console.log(this.lifeWaysUniversal);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_2500017') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_2500021') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_2500023') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 25,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getMediCertifications() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getMediCertifications(params).subscribe(
      data => {
        if (data['success']) {
          this.mediCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    console.log(this.lifeWaysUniversal);
    console.log(this.lifeWaysUniversal.value);
    this.isSubmit = true;
    if (type == 'submit') {
      if (
        this.lifeWaysUniversal.invalid ||
        (this.changeCmdOne &&
        (  this.lifeWaysUniversal.controls['cmd_25000135'].value == null ||
          this.lifeWaysUniversal.controls['cmd_25000136'].value == null))
      ) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.lifeWaysUniversal.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.lifeWaysUniversal.value,
        formId: 25
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
      this.lifeWaysUniversal.get("cmd_25000135").setErrors(null);
      this.lifeWaysUniversal.get("cmd_25000136").setErrors(null);

    }
  }

  onChangeFirstName(event) {
    //cmd_25000070
    //cmd_25000072
    //cmd_25000073
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_25000070: this.firstName,
      cmd_25000072: this.finalName,
      cmd_25000073: this.finalName
    };
    this.lifeWaysUniversal.patchValue(temp);
  }

  onChangeLastName(event) {
    //cmd_25000069
    //cmd_25000072
    //cmd_25000073
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_25000069: this.lastName,
      cmd_25000072: this.finalName,
      cmd_25000073: this.finalName
    };
    this.lifeWaysUniversal.patchValue(temp);
  }

  onChangeMiddleName(event) {
    //cmd_25000071
    this.middleName = event.target.value;
    let temp = {
      cmd_25000071: this.middleName
    };
    this.lifeWaysUniversal.patchValue(temp);
  }

  onChangeDropDownName(event) {
    //cmd_110215
    this.lifeWaysUniversal.controls['cmd_110215'].setValue(event.id);
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_2500017: this.finalImgPath
      };
      this.lifeWaysUniversal.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_2500021: this.finalImgPathTwo
      };
      this.lifeWaysUniversal.patchValue(temp);
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_2500023: this.finalImgPathThree
      };
      this.lifeWaysUniversal.patchValue(temp);
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }
  clearInputIfInvalidForYesorNo(controlName: string,handleYesNoValue?:string) {
    const control = this.lifeWaysUniversal.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue(null);
      control.setErrors({ errors: true });
    }
    // if(handleYesNoValue ){
    //   // if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //   //   this.handleYesNoChanges(handleYesNoValue);
    //   // }
    //   // else{
    //   //     this.handleNAchange(handleYesNoValue);
    //   // }
      
      
    // }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.lifeWaysUniversal.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.lifeWaysUniversal.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
     
      
    }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

}
