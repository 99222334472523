import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-cherokee-health-system',
    templateUrl: './cherokee-health-system.component.html',
    styleUrls: ['./cherokee-health-system.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class CherokeeHealthSystemComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  public userId: any;
  public customerId: any;
  public cherokeeHealthForm: UntypedFormGroup;
  public credentialDropDownList: any = [];
  public stateDropDownDate: any = [];
  public languages: Array<object> = [];
  public secondaryLanguages: Array<object> = [];
  public tertiaryLanguages: Array<object> = [];
  public quaternaryLanguages: Array<object> = [];
  public specialtySecondary: any = [];
  public specialty: any = [];
  public country: any = [];
  public preClaim: any = [];
  public aggregates: any = [];
  public credentials: any = [];
  public staffStatus: any = [];
  public additionalLicenseStateData: any = [];
  public alternateLicenseStateData: any = [];
  public degree: any = [];
  public showOne: boolean = false;
  public showTwo: boolean = false;
  public showThree: boolean = false;
  public showFour: boolean = false;
  public esignOne: any;
  public esignTwo: any;
  public esignThree: any;
  public esignFour: any;
  public finalImgPath: any = [];
  public finalImg: any;
  public finalImgPathOne: any;
  public finalImgOne: any;
  public finalImgPathTwo: any;
  public finalImgTwo: any;
  public finalImgPathThree: any;
  public finalImgThree: any;
  public finalImgPathFour: any;
  public finalImgFour: any;
  isSubmit: boolean = false;
  firstName: any;
  middleName: any;
  lastName: any;
  finalName: any;
  totalName: any;
  changeCmdOne: any;
  changeCmdTwo: any;
  changeCmdThree: any;
  changeCmdFour: any;
  changeCmdFive: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };

  ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildcherokeeHealthForm();
    this.getCountyData();
    this.getStatesData();
    this.getSpecialtyData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getSpecialtySecondaryData();
    this.getAggregatesData();
    this.getPreClaimData();
    this.getCredentialsData();
    this.getDegreeData();
    this.getStaffStatusData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_1662') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_1660') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_1658') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_1621') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_1618') {
        if (array[i].field_value == 1) {
          this.changeCmdOne = 1;
        } else {
          this.changeCmdOne = 0;
        }
      }
      if (array[i].field_name == 'cmd_1616') {
        if (array[i].field_value == 1) {
          this.changeCmdTwo = 1;
        } else {
          this.changeCmdTwo = 0;
        }
      }
      if (array[i].field_name == 'cmd_1605') {
        if (array[i].field_value == 1) {
          this.changeCmdThree = 1;
        } else {
          this.changeCmdThree = 0;
        }
      }
      if (array[i].field_name == 'cmd_1604') {
        if (array[i].field_value == 1) {
          this.changeCmdFour = 1;
        } else {
          this.changeCmdFour = 0;
        }
      }
      if (array[i].field_name == 'cmd_1603') {
        if (array[i].field_value == 1) {
          this.changeCmdFive = 1;
        } else {
          this.changeCmdFive = 0;
        }
      }
    }
    this.cherokeeHealthForm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_1662') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_1660') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_1658') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
    if (fieldId == 'cmd_1621') {
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showFour = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 27,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        // this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  buildcherokeeHealthForm() {
    this.cherokeeHealthForm = this.fb.group({
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],
      9: ['', [Validators.required]],
      822: [null, [Validators.required]],
      278: ['', [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      32: ['', [Validators.required]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      291: ['', [Validators.required]],
      277: [null, [Validators.required]],
      cmd_1521: [''],
      cmd_1522: [''],
      cmd_1523: [''],
      cmd_1524: [''],
      cmd_1525: [''],
      cmd_1526: [''],
      1535: [null],
      cmd_1527: [''],
      cmd_1528: [''],
      cmd_1529: [''],
      1536: [null],
      cmd_1530: [''],
      cmd_1531: [''],
      cmd_1532: [''],
      cmd_1360: [''],
      cmd_1533: [''],
      299: [null],
      293: [''],
      296: [''],
      294: [null, [this.dateFormatValidator]],
      295: [null, [this.dateFormatValidator]],
      907: [null],
      300: [''],
      1274: [''],
      307: [null, [this.dateFormatValidator]],
      308: [null, [this.dateFormatValidator]],
      1010: [null],
      999: [''],
      1432: [''],
      1003: [null, [this.dateFormatValidator]],
      1004: [null, [this.dateFormatValidator]],
      503: [''],
      512: [null],
      508: [null],
      506: [''],
      1238: [null, [this.dateFormatValidator]],
      510: [null, [this.dateFormatValidator]],
      cmd_1514: [''],
      513: [''],
      479: [''],
      cmd_1361: [''],
      485: [''],
      487: [null],
      481: [null, [this.dateFormatValidator]],
      1300: [null, [this.dateFormatValidator]],
      480: [null],
      cmd_1513: [''],
      489: [''],
      490: [''],
      cmd_1511: [''],
      493: [''],
      495: [null],
      497: [null, [this.dateFormatValidator]],
      498: [null, [this.dateFormatValidator]],
      500: [null],
      cmd_1512: [''],
      1376: [''],
      517: [''],
      cmd_1362: [''],
      520: [''],
      522: [null],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      527: [null],
      cmd_1515: [''],
      cmd_1364: [''],
      cmd_1363: [''],
      529: [''],
      533: [''],
      535: [null],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      cmd_1519: [''],
      cmd_1664: [''],
      530: [null],
      542: [''],
      cmd_1365: [''],
      546: [''],
      548: [null],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      cmd_1518: [''],
      cmd_1665: [''],
      543: [null],
      cmd_1516: [''],
      cmd_1366: [''],
      cmd_1517: [''],
      cmd_1520: [''],
      cmd_1534: [null, [this.dateFormatValidator]],
      cmd_1535: [null, [this.dateFormatValidator]],
      cmd_1536: [''],
      cmd_1537: [''],
      cmd_1538: [''],
      cmd_1367: [''],
      cmd_1368: [''],
      cmd_1369: [''],
      cmd_1539: [''],
      cmd_1540: [null, [this.dateFormatValidator]],
      cmd_1541: [''],
      cmd_1542: [null, [this.dateFormatValidator]],
      cmd_1370: [''],
      cmd_1543: [''],
      cmd_1544: [null, [this.dateFormatValidator]],
      cmd_1545: [''],
      cmd_1371: [''],
      556: [null],
      557: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      564: [null, [this.dateFormatValidator]],
      567: [null, [this.dateFormatValidator]],
      cmd_1372: [''],
      cmd_1546: [''],
      cmd_1547: [null, [this.dateFormatValidator]],
      418: [''],
      419: [''],
      420: [''],
      421: [''],
      423: [null],
      424: [''],
      422: [null],
      426: [null, [this.dateFormatValidator]],
      3083: [null],
      894: [null],
      cmd_1373: [''],
      cmd_1374: [''],
      cmd_1375: [''],
      cmd_1376: [''],
      cmd_1377: [''],
      cmd_1378: [''],
      cmd_1379: [''],
      cmd_1380: [''],
      427: [''],
      428: [''],
      429: [''],
      430: [''],
      433: [''],
      431: [null],
      432: [null],
      435: [null, [this.dateFormatValidator]],
      3085: [null],
      895: [null],
      cmd_1381: [''],
      cmd_1382: [''],
      cmd_1383: [''],
      cmd_1384: [''],
      cmd_1385: [''],
      cmd_1386: [''],
      cmd_1387: [''],
      cmd_1388: [''],
      cmd_1389: [''],
      cmd_1390: [''],
      cmd_1391: [''],
      cmd_1392: [''],
      cmd_1393: [''],
      cmd_1394: [''],
      cmd_1395: [''],
      cmd_1396: [''],
      cmd_1397: [''],
      cmd_1398: [''],
      cmd_1399: [''],
      cmd_1400: [''],
      cmd_1401: [''],
      cmd_1402: [''],
      cmd_1403: [''],
      cmd_1404: [''],
      cmd_1405: [''],
      cmd_1406: [''],
      cmd_1407: [''],
      cmd_1408: [''],
      cmd_1409: [''],
      cmd_1410: [''],
      cmd_1411: [''],
      cmd_1412: [''],
      cmd_1413: [''],
      cmd_1414: [''],
      cmd_1415: [''],
      cmd_1416: [''],
      cmd_1417: [''],
      cmd_1418: [''],
      cmd_1419: [''],
      cmd_1420: [''],
      cmd_1421: [''],
      cmd_1422: [''],
      cmd_1423: [''],
      cmd_1424: [''],
      cmd_1425: [''],
      cmd_1426: [''],
      cmd_1427: [''],
      cmd_1428: [''],
      cmd_1429: [''],
      cmd_1430: [''],
      cmd_1431: [''],
      cmd_1432: [''],
      cmd_1433: [''],
      cmd_1434: [''],
      cmd_1435: [''],
      cmd_1436: [''],
      cmd_1437: [''],
      cmd_1438: [''],
      cmd_1439: [''],
      cmd_1440: [''],
      cmd_1441: [''],
      cmd_1442: [''],
      cmd_1443: [''],
      cmd_1444: [''],
      cmd_1445: ['', [Validators.required]],
      cmd_1446: ['', [Validators.required]],
      cmd_1447: ['', [Validators.required]],
      cmd_1448: ['', [Validators.required]],
      cmd_1449: ['', [Validators.required]],
      cmd_1450: ['', [Validators.required]],
      cmd_1451: ['', [Validators.required]],
      cmd_1452: ['', [Validators.required]],
      cmd_1453: ['', [Validators.required]],
      cmd_1454: ['', [Validators.required]],
      cmd_1455: [''],
      cmd_1456: [''],
      cmd_1457: [''],
      cmd_1458: [''],
      cmd_1459: [''],
      cmd_1460: [''],
      cmd_1461: [''],
      cmd_1462: [''],
      cmd_1463: [''],
      cmd_1464: [''],
      cmd_1465: [''],
      cmd_1466: [''],
      cmd_1467: [''],
      cmd_1468: [''],
      cmd_1469: [''],
      cmd_1470: [''],
      cmd_1471: [''],
      cmd_1472: [''],
      cmd_1473: [''],
      cmd_1474: [''],
      cmd_1475: [''],
      cmd_1476: [''],
      cmd_1477: [''],
      cmd_1478: [''],
      cmd_1479: [''],
      cmd_01480: [''],
      345: [''],
      901: [''],
      902: [''],
      903: [''],
      905: [null],
      906: [''],
      350: [null],
      351: [null],
      348: [null, [this.dateFormatValidator]],
      349: [null, [this.dateFormatValidator]],
      346: [''],
      cmd_01481: [''],
      1242: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: [''],
      1264: [null],
      1265: [null],
      1252: [null, [this.dateFormatValidator]],
      1253: [null, [this.dateFormatValidator]],
      1256: [''],
      cmd_01483: [''],
      cmd_01485: ['', [Validators.required]],
      cmd_01486: ['', [Validators.required]],
      cmd_01488: ['', [Validators.required]],
      cmd_01489: ['', [Validators.required]],
      cmd_1490: ['', [Validators.required]],
      cmd_1491: ['', [Validators.required]],
      cmd_1492: ['', [Validators.required]],
      cmd_1493: ['', [Validators.required]],
      cmd_1494: ['', [Validators.required]],
      cmd_1495: ['', [Validators.required]],
      cmd_1496: ['', [Validators.required]],
      cmd_1497: ['', [Validators.required]],
      cmd_1498: [''],
      cmd_1499: [''],
      cmd_1500: [''],
      cmd_1501: [''],
      cmd_1502: [''],
      cmd_1503: [''],
      cmd_1504: [''],
      445: [''],
      1326: [''],
      446: [''],
      447: [''],
      448: [''],
      450: [null],
      451: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      456: [''],
      1343: [''],
      457: [''],
      458: [''],
      459: [''],
      461: [null],
      462: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      469: [''],
      1339: [''],
      468: [''],
      470: [''],
      472: [null],
      473: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      1935: [''],
      1949: [''],
      1936: [''],
      1938: [''],
      1937: [''],
      1940: [null],
      1941: [''],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      cmd_1622: [''],
      cmd_1623: [''],
      cmd_1624: [''],
      cmd_1625: [''],
      cmd_1626: [''],
      cmd_1627: [null],
      cmd_1628: [''],
      cmd_1629: [null, [this.dateFormatValidator]],
      cmd_1630: [null, [this.dateFormatValidator]],
      cmd_1631: [''],
      cmd_1632: [''],
      cmd_1633: [''],
      cmd_1634: [''],
      cmd_1635: [''],
      cmd_1636: [null],
      cmd_1637: [''],
      cmd_1638: [null, [this.dateFormatValidator]],
      cmd_1639: [null, [this.dateFormatValidator]],
      cmd_1640: [''],
      cmd_1641: [''],
      cmd_1642: [''],
      cmd_1643: [''],
      cmd_1644: [''],
      cmd_1645: [null],
      cmd_1646: [''],
      cmd_1647: [null, [this.dateFormatValidator]],
      cmd_1648: [null, [this.dateFormatValidator]],
      cmd_1649: [''],
      cmd_1650: [''],
      cmd_1651: [''],
      cmd_1652: [''],
      cmd_1653: [''],
      cmd_1654: [null],
      cmd_1655: [''],
      cmd_1656: [null, [this.dateFormatValidator]],
      cmd_1657: [null, [this.dateFormatValidator]],
      cmd_1662: [''],
      cmd_1663: [null, [this.dateFormatValidator]],
      1634: ['', [Validators.required]],
      333: ['', [Validators.required]],
      1635: [''],
      334: ['', [Validators.required]],
      1636: [''],
      335: ['', [Validators.required]],
      cmd_1505: ['', [Validators.required]],
      cmd_1506: ['', [Validators.required]],
      cmd_1507: ['', [Validators.required]],
      cmd_1508: ['', [Validators.required]],
      cmd_1509: ['', [Validators.required]],
      cmd_1510: ['', [Validators.required]],
      //
      359: ['', [Validators.required]],
      2722: ['', [Validators.required]],
      360: ['', [Validators.required]],
      361: ['', [Validators.required]],
      362: ['', [Validators.required]],
      364: [null, [Validators.required]],
      365: ['', [Validators.required]],
      369: ['', [Validators.required]],
      372: ['', [Validators.required]],
      2723: ['', [Validators.required]],
      373: ['', [Validators.required]],
      374: ['', [Validators.required]],
      375: ['', [Validators.required]],
      377: [null, [Validators.required]],
      378: ['', [Validators.required]],
      381: ['', [Validators.required]],
      384: ['', [Validators.required]],
      2725: ['', [Validators.required]],
      385: ['', [Validators.required]],
      386: ['', [Validators.required]],
      387: ['', [Validators.required]],
      389: [null, [Validators.required]],
      390: ['', [Validators.required]],
      394: ['', [Validators.required]],
      1244: [''],
      1627: [''],
      1645: [''],
      cmd_1613: [''],
      1451: [''],
      283: [null, [this.dateFormatValidator]],
      cmd_1548: [null, [this.dateFormatValidator]],
      cmd_1549: [''],
      cmd_1550: [''],
      cmd_1551: [null, [this.dateFormatValidator]],
      cmd_1552: [''],
      cmd_1553: [''],
      cmd_1554: [null, [this.dateFormatValidator]],
      cmd_1555: [''],
      cmd_1556: [''],
      cmd_1557: [null, [this.dateFormatValidator]],
      cmd_1558: [''],
      cmd_1559: [''],
      cmd_1560: [null, [this.dateFormatValidator]],
      cmd_1561: [''],
      cmd_1562: [''],
      cmd_1563: [null, [this.dateFormatValidator]],
      563: [null],

      cmd_1564: [''],
      cmd_1565: [''],
      cmd_1566: [null, [this.dateFormatValidator]],
      cmd_1567: [''],
      cmd_1568: [''],
      cmd_1569: [null, [this.dateFormatValidator]],
      cmd_1570: [''],
      cmd_1571: [''],
      cmd_1572: [null, [this.dateFormatValidator]],
      cmd_1573: [''],
      cmd_1574: [''],
      cmd_1575: [null, [this.dateFormatValidator]],
      cmd_1576: [''],
      cmd_1577: [''],
      cmd_1578: [null, [this.dateFormatValidator]],
      cmd_1579: [''],
      cmd_1580: [''],
      cmd_1581: [null, [this.dateFormatValidator]],
      cmd_1582: [''],
      cmd_1583: [''],
      cmd_1584: [null, [this.dateFormatValidator]],
      cmd_1585: [''],
      cmd_1586: [''],
      cmd_1587: [null, [this.dateFormatValidator]],
      cmd_1588: [''],
      cmd_1589: [''],
      cmd_1590: [null, [this.dateFormatValidator]],
      cmd_1591: [''],
      cmd_1592: [''],
      cmd_1593: [null, [this.dateFormatValidator]],
      cmd_1594: [''],
      cmd_1595: [''],
      cmd_1596: [null, [this.dateFormatValidator]],
      cmd_1597: [''],
      cmd_1598: [''],
      cmd_1599: [null, [this.dateFormatValidator]],
      cmd_1600: [''],
      cmd_1601: [''],
      //
      cmd_1602: [''],
      cmd_1661: [null, [this.dateFormatValidator]],
      cmd_1660: [''],
      cmd_1603: ['', [Validators.required]],
      cmd_1604: ['', [Validators.required]],
      cmd_1605: ['', [Validators.required]],
      cmd_1606: [''],
      cmd_1607: [''],
      cmd_1608: [''],
      cmd_1609: [''],
      cmd_1610: [''],
      cmd_1611: [''],
      cmd_1658: [''],
      cmd_1659: [null, [this.dateFormatValidator]],
      cmd_1615: [''],
      cmd_1621: [''],
      cmd_1616: ['', [Validators.required]],
      cmd_1617: [''],
      cmd_1670: [''],
      cmd_1671: [''],
      cmd_1672: [''],
      cmd_1674: [''],
      cmd_1675: [''],
      819: [''],
      cmd_1618: ['', [Validators.required]],
      cmd_1676: [''],
      cmd_1677: [''],
      cmd_1678: [''],
      cmd_1679: [''],
      cmd_1680: [''],
      cmd_1681: [''],
      cmd_1620: [null, [this.dateFormatValidator]]
    });
  }

  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName =
      this.firstName + ' ' + this.middleName + ' ' + this.lastName;
    this.totalName =
      this.firstName + ' ' + this.middleName + ' ' + this.lastName;
    let temp = {
      cmd_1613: this.finalName
    };
    let temp1 = {
      cmd_1602: this.finalName
    };
    let temp2 = {
      cmd_1615: this.finalName
    };
    this.cherokeeHealthForm.patchValue(temp);
    this.cherokeeHealthForm.patchValue(temp1);
    this.cherokeeHealthForm.patchValue(temp2);
  }
  onChangeMiddleName(event) {
    this.middleName = event.target.value;
    this.finalName =
      this.firstName + ' ' + this.middleName + ' ' + this.lastName;
    let temp = {
      cmd_1613: this.finalName
    };
    let temp1 = {
      cmd_1602: this.finalName
    };
    let temp2 = {
      cmd_1615: this.finalName
    };
    this.cherokeeHealthForm.patchValue(temp);
    this.cherokeeHealthForm.patchValue(temp1);
    this.cherokeeHealthForm.patchValue(temp2);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName =
      this.firstName + ' ' + this.middleName + ' ' + this.lastName;
    let temp = {
      cmd_1613: this.finalName
    };
    let temp1 = {
      cmd_1602: this.finalName
    };
    let temp2 = {
      cmd_1615: this.finalName
    };
    this.cherokeeHealthForm.patchValue(temp);
    this.cherokeeHealthForm.patchValue(temp1);
    this.cherokeeHealthForm.patchValue(temp2);
  }
  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
    }
  }
  onChangeCmdTwo(event) {
    if (event.target.value == 1) {
      this.changeCmdTwo = true;
    } else {
      this.changeCmdTwo = false;
    }
  }
  onChangeCmdThree(event) {
    if (event.target.value == 1) {
      this.changeCmdThree = true;
    } else {
      this.changeCmdThree = false;
    }
  }
  onChangeCmdFour(event) {
    if (event.target.value == 1) {
      this.changeCmdFour = true;
    } else {
      this.changeCmdFour = false;
    }
  }
  onChangeCmdFive(event) {
    if (event.target.value == 1) {
      this.changeCmdFive = true;
    } else {
      this.changeCmdFive = false;
    }
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseStateData = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.alternateLicenseStateData = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.stateDropDownDate = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          // console.log(data.data)
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    // this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    // this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    // this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    // this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          // console.log(data.data)
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
    // console.log(this.cherokeeHealthForm);
    if (type == 'submit') {
      if (
        this.cherokeeHealthForm.invalid ||
        (this.changeCmdOne &&
          this.cherokeeHealthForm.controls['cmd_1676'].value == '') ||
        (this.changeCmdTwo &&
          this.cherokeeHealthForm.controls['cmd_1617'].value == '') ||
        (this.changeCmdThree &&
          this.cherokeeHealthForm.controls['cmd_1608'].value == '') ||
        (this.changeCmdFour &&
          this.cherokeeHealthForm.controls['cmd_1607'].value == '') ||
        (this.changeCmdFive &&
          this.cherokeeHealthForm.controls['cmd_1606'].value == '')
      ) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.cherokeeHealthForm.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.cherokeeHealthForm.value,
        formId: 27
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      this.finalImgPath = data.finalFilePath;
      modalRef.close();
      let temp = {
        cmd_1662: this.finalImgPath
      };
      this.cherokeeHealthForm.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_1660: this.finalImgPathTwo
      };
      this.cherokeeHealthForm.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_1658: this.finalImgPathThree
      };
      this.cherokeeHealthForm.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  public esignOpenFour() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathFour = data.finalFilePath;
      let temp = {
        cmd_1621: this.finalImgPathFour
      };
      this.cherokeeHealthForm.patchValue(temp);
      this.finalImgPathFour = data.finalFilePath;
      this.finalImgFour = data.base64Img;
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgFour
      );
      this.showFour = true;
    });
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.cherokeeHealthForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.cherokeeHealthForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
