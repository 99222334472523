<div id="texas">
    <div class="cotainer-fluid mx-1">
        <div class="header">
            <div class="text-center">
                <h2>Texas Standardized Credentialing Application</h2>
            </div>
        </div>
        <div class="section">
            <form [formGroup]="texas">
                <div class="centered">
                    <h3 class="texasHThree">Section I-Individual Information
                    </h3>
                </div>
                <ctg-texas-section-one [texas]="texas" (educationapp)="education($event)"  (workhistoryapp)="workHistApp($event)"
                 (hospitalaffapp)="hospAffApp($event)" (referapp)="referencesApp($event)"  (professionalapp)="proInsApp($event)"  (profspecialtyapp)="proSpecApp($event)"
                 (usmilitary)="onChangeCmdTwo($event)" (ecfmgnumber)="onChangeCmdOne($event)" (certified)="onChangeCmdThree($event)"
                [isSubmit] ="isSubmit"  [ecfmgchange]="changeCmdOne"  (change)="validationsDataAdd($event)"  [usmilchange]="changeCmdTwo"  [certifiedchange]="changeCmdThree"

                ></ctg-texas-section-one>
                <table border="1" width="100%">
                    <tr>
                        <td colspan="186">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;font-size: 14px;">Please use the space below to explain yes answers to any question except 16.
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;font-size: 14px;">QUESTION NUMBER</label>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;font-size: 14px;">PLEASE EXPLAIN </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 0 && texas.controls['cmd_0255'].invalid"
                                class="form-control"
                                id="cmd_0255"
                                formControlName="cmd_0255"
                                name="cmd_0255"
                              ></textarea>
                              <div class="formLabel col-sm-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 0 && texas.controls['cmd_0255'].invalid"
                                >
                                  required
                                </small>
                                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0256" name="cmd_0256" formControlName="cmd_0256" data-id="Text5.2.0.0"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 1 && texas.controls['cmd_0257'].invalid
                  "
                  class="form-control"
                  id="cmd_0257"
                  formControlName="cmd_0257"
                  name="cmd_0257"
                  data-id="Text5.2.1.1"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 1 && texas.controls['cmd_0257'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0258" name="cmd_0258" formControlName="cmd_0258" data-id="Text5.2.0.1"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 2 && texas.controls['cmd_0259'].invalid
                                "
                                class="form-control"
                                id="cmd_0259"
                                formControlName="cmd_0259"
                                name="cmd_0259"
                                data-id="Text5.2.1.2"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 2 && texas.controls['cmd_0259'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0260" name="cmd_0260" formControlName="cmd_0260" data-id="Text5.2.0.2"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 3 && texas.controls['cmd_0261'].invalid
                                "
                                class="form-control"
                                id="cmd_0261"
                                formControlName="cmd_0261"
                                name="cmd_0261"
                                data-id="Text5.2.1.3"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 3 && texas.controls['cmd_0261'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0262" name="cmd_0262" formControlName="cmd_0262" data-id="Text5.2.0.3"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 4 && texas.controls['cmd_0263'].invalid
                                "
                                class="form-control"
                                id="cmd_0263"
                                formControlName="cmd_0263"
                                name="cmd_0263"
                                data-id="Text5.2.1.4"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 4 && texas.controls['cmd_0263'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0264" name="cmd_0264" formControlName="cmd_0264" data-id="Text5.2.0.4"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 5 && texas.controls['cmd_0265'].invalid
                                "
                                class="form-control"
                                id="cmd_0265"
                                formControlName="cmd_0265"
                                name="cmd_0265"
                                data-id="Text5.2.1.5"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 5 && texas.controls['cmd_0265'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0266" name="cmd_0266" formControlName="cmd_0266" data-id="Text5.2.0.5"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 6 && texas.controls['cmd_0267'].invalid
                                "
                                class="form-control"
                                id="cmd_0267"
                                formControlName="cmd_0267"
                                name="cmd_0267"
                                data-id="Text5.2.1.6"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 6 && texas.controls['cmd_0267'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0268" name="cmd_0268" formControlName="cmd_0268" data-id="Text5.2.0.6"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 7 && texas.controls['cmd_0269'].invalid
                                "
                                class="form-control"
                                id="cmd_0269"
                                formControlName="cmd_0269"
                                name="cmd_0269"
                                data-id="Text5.2.1.7"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 7 && texas.controls['cmd_0269'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0270" name="cmd_0270" formControlName="cmd_0270" data-id="Text5.2.0.7"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 8 && texas.controls['cmd_0271'].invalid
                                "
                                class="form-control"
                                id="cmd_0271"
                                formControlName="cmd_0271"
                                name="cmd_0271"
                                data-id="Text5.2.1.8.0"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 8 && texas.controls['cmd_0271'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0272" name="cmd_0272" formControlName="cmd_0272" data-id="Text5.2.0.8.0"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 9 && texas.controls['cmd_0273'].invalid
                  "
                  class="form-control"
                  id="cmd_0273"
                  formControlName="cmd_0273"
                  name="cmd_0273"
                  data-id="Text5.2.1.9.0"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 9 && texas.controls['cmd_0273'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0274" name="cmd_0274" formControlName="cmd_0274" data-id="Text5.2.0.9.0"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 10 && texas.controls['cmd_0275'].invalid
                  "
                  class="form-control"
                  id="cmd_0275"
                  formControlName="cmd_0275"
                  name="cmd_0275"
                  data-id="Text5.2.1.8.1"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 10 &&
                      texas.controls['cmd_0275'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0276" name="cmd_0276" formControlName="cmd_0276" data-id="Text5.2.0.8.1"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 11 && texas.controls['cmd_0277'].invalid
                  "
                  class="form-control"
                  id="cmd_0277"
                  formControlName="cmd_0277"
                  name="cmd_0277"
                  data-id="Text5.2.1.9.1"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 11 &&
                      texas.controls['cmd_0277'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0278" name="cmd_0278" formControlName="cmd_0278" data-id="Text5.2.0.9.1"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 12 && texas.controls['cmd_0279'].invalid
                  "
                  class="form-control"
                  id="cmd_0279"
                  formControlName="cmd_0279"
                  name="cmd_0279"
                  data-id="Text5.2.1.8.2"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 12 &&
                      texas.controls['cmd_0279'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0280" name="cmd_0280" formControlName="cmd_0280" data-id="Text5.2.0.8.2"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 13 && texas.controls['cmd_0281'].invalid
                                "
                                class="form-control"
                                id="cmd_0281"
                                formControlName="cmd_0281"
                                name="cmd_0281"
                                data-id="Text5.2.1.9.2"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 13 &&
                                    texas.controls['cmd_0281'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0282" name="cmd_0282" formControlName="cmd_0282" data-id="Text5.2.0.9.2"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 14 && texas.controls['cmd_0283'].invalid
                                "
                                class="form-control"
                                id="cmd_0283"
                                formControlName="cmd_0283"
                                name="cmd_0283"
                                data-id="Text5.2.1.8.3"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 14 &&
                                    texas.controls['cmd_0283'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0284" name="cmd_0284" formControlName="cmd_0284" data-id="Text5.2.0.8.3"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 15 && texas.controls['cmd_0285'].invalid
                  "
                  class="form-control"
                  id="cmd_0285"
                  formControlName="cmd_0285"
                  name="cmd_0285"
                  data-id="Explain 10"
                ></textarea>
                <div
                  class="formLabel col-md-2"
                  *ngIf="
                    texas.controls['cmd_0285'].touched &&
                    texas.controls['cmd_0285'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 15 &&
                      texas.controls['cmd_0285'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0286" name="cmd_0286" formControlName="cmd_0286" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 16 && texas.controls['cmd_0287'].invalid
                  "
                  class="form-control"
                  id="cmd_0287"
                  formControlName="cmd_0287"
                  name="cmd_0287"
                  data-id="Text5.2.1.9.3"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 16 &&
                      texas.controls['cmd_0287'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0288" name="cmd_0288" formControlName="cmd_0288" data-id="Text5.2.0.9.3"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 17 && texas.controls['cmd_0289'].invalid
                                "
                                class="form-control"
                                id="cmd_0289"
                                formControlName="cmd_0289"
                                name="cmd_0289"
                                data-id="Text5.2.1.8.4"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 17 &&
                                    texas.controls['cmd_0289'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0290" name="cmd_0290" formControlName="cmd_0290" data-id="Text5.2.0.8.4"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 18 && texas.controls['cmd_0291'].invalid
                  "
                  class="form-control"
                  id="cmd_0291"
                  formControlName="cmd_0291"
                  name="cmd_0291"
                  data-id="Text5.2.1.9.4"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 18 &&
                      texas.controls['cmd_0291'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0292" name="cmd_0292" formControlName="cmd_0292" data-id="Text5.2.0.9.4"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 19 && texas.controls['cmd_0293'].invalid
                                "
                                class="form-control"
                                id="cmd_0293"
                                formControlName="cmd_0293"
                                name="cmd_0293"
                                data-id="Text5.2.0.9.4"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 19 &&
                                    texas.controls['cmd_0293'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0294" name="cmd_0294" formControlName="cmd_0294" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 20 && texas.controls['cmd_0295'].invalid
                  "
                  class="form-control"
                  id="cmd_0295"
                  formControlName="cmd_0295"
                  name="cmd_0295"
                  data-id="Explain 10"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 20 &&
                      texas.controls['cmd_0295'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0296" name="cmd_0296" formControlName="cmd_0296" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                                style="width: 70%"
                                [class.is-invalid]="
                                  questionCounter > 21 && texas.controls['cmd_0297'].invalid
                                "
                                class="form-control"
                                id="cmd_0297"
                                formControlName="cmd_0297"
                                name="cmd_0297"
                                data-id="Explain 10"
                              ></textarea>
                              <div class="formLabel col-md-2">
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    questionCounter > 21 &&
                                    texas.controls['cmd_0297'].invalid
                                  "
                                >
                                  required
                                </small>
                              </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0298" name="cmd_0298" formControlName="cmd_0298"  data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea
                  style="width: 70%"
                  [class.is-invalid]="
                    questionCounter > 22 && texas.controls['cmd_0299'].invalid
                  "
                  class="form-control"
                  id="cmd_0299"
                  formControlName="cmd_0299"
                  name="cmd_0299"
                  data-id="Explain 10"
                ></textarea>
                <div class="formLabel col-md-2">
                  <small
                    class="text-danger"
                    *ngIf="
                      questionCounter > 22 &&
                      texas.controls['cmd_0299'].invalid
                    "
                  >
                    required
                  </small>
                </div>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0300" name="cmd_0300" formControlName="cmd_0300" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0301" name="cmd_0301" formControlName="cmd_0301" data-id="Explain 10"></textarea>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0302" name="cmd_0302" formControlName="cmd_0302" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0303" name="cmd_0303" formControlName="cmd_0303" data-id="Explain 10"></textarea>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0304" name="cmd_0304" formControlName="cmd_0304" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0305" name="cmd_0305" formControlName="cmd_0305" data-id="Explain 10"></textarea>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <textarea style="width: 70%" class="form-control onboard " id="cmd_0306" name="cmd_0306" formControlName="cmd_0306" data-id="Page 10"></textarea>
                            </div>
                        </td>
                    </tr>
                </table>
                <table border="1">
                    <tr>
                        <td colspan="12" width="85%">
                            <h3 style="padding-left:10px;margin: 0;  display: inline; "><b>Section III – Standard Authorization, Attestation and Release</b></h3>
                            <label>(Not for Use for Employment Purposes)
                            I understand and agree that, as part of the credentialing application process for participation and⁄or clinical privileges
                            (hereinafter, referred to as “Participation”) at or with </label>
                        </td>
                        <td colspan="6" width="15%">
                            <span class="texasFloat">
                            <label class="checkbox-inline radioStyle">
                            <input type="checkbox" id="cmd_048" name="cmd_048" formControlName="cmd_048" value="1"> Dont Not Apply</label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">
                                I understand and agree that, as part of the credentialing application process for participation and⁄or clinical privileges (hereinafter, referred to as “Participation”) at or with
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">
                                (PLEASE INDICATE MANAGED CARE COMPANY(S) OR HOSPITAL(S) TO WHICH YOU ARE APPLYING) (HEREINAFTER, INDIVIDUALLY REFERRED TO AS THE “ENTITY”)
                                </label>
                                <input style="width: 70%" class="form-control onboard " id="cmd_0307" name="cmd_0307" formControlName="cmd_0307" type="text" data-id="Text5.1.4">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">and any of the Entity’s affiliated entities, I am required to provide sufficient and accurate information for a proper evaluation
                                of my current licensure, relevant training and⁄or experience, clinical competence, health status, character, ethics, and any
                                other criteria used by the Entity for determining initial and ongoing eligibility for Participation. Each Entity and its
                                representatives, employees, and agent(s) acknowledge that the information obtained relating to the application process will
                                be held confidential to the extent permitted by law. </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">I acknowledge that each Entity has its own criteria for acceptance, and I may be accepted or rejected by each
                                independently. I further acknowledge and understand that my cooperation in obtaining information and my consent to the
                                release of information do not guarantee that any Entity will grant me clinical privileges or contract with me as a provider of
                                services. I understand that my application for Participation with the Entity is not an application for employment with the Entity
                                and that acceptance of my application by the Entity will not result in my employment by the Entity.  </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">For Hospital Credentialing. I consent to appear for an interview with the credentials committee, medical staff executive
                                committee, or other representatives of the medical staff, hospital administration or the governing board, if required or
                                requested. As a medical staff member, I pledge to provide continuous care for my patients. I have been informed of existing
                                hospital bylaws, rules and regulations, and policies regarding the application process, and I agree that as a medical staff
                                member, I will be bound by them.  </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">Authorization of Investigation Concerning Application for Participation. I authorize the following individuals including, without
                                limitation, the Entity, its representatives, employees, and/or designated agent(s); the Entity’s affiliated entities and their
                                representatives, employees, and/or designated agents; and the Entity’s designated professional credentials verification
                                organization (collectively referred to as “Agents”), to investigate information, which includes both oral and written statements,
                                records, and documents, concerning my application for Participation. I agree to allow the Entity and/or its Agent(s) to inspect
                                all records and documents relating to such an investigation.  </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">Authorization of Third-Party Sources to Release Information Concerning Application for Participation. I authorize any third party,
                                including, but not limited to, individuals, agencies, medical groups responsible for credentials verification, corporations,
                                companies, employers, former employers, hospitals, health plans, health maintenance organizations, managed care
                                organizations, law enforcement or licensing agencies, insurance companies, educational and other institutions, military
                                services, medical credentialing and accreditation agencies, professional medical societies, the Federation of State Medical
                                Boards, the National Practitioner Data Bank, and the Health Care Integrity and Protection Data Bank, to release to the Entity
                                and/or its Agent(s), information, including otherwise privileged or confidential information, concerning my professional
                                qualifications, credentials, clinical competence, quality assurance and utilization data, character, mental condition, physical
                                condition, alcohol or chemical dependency diagnosis and treatment, ethics, behavior, or any other matter reasonably having
                                a bearing on my qualifications for Participation in, or with, the Entity. I authorize my current and past professional liability
                                carrier(s) to release my history of claims that have been made and/or are currently pending against me. I specifically waive
                                written notice from any entities and individuals who provide information based upon this Authorization, Attestation and
                                Release.  </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">Authorization of Release and Exchange of Disciplinary Information. I hereby further authorize any third party at which I currently
                                have Participation or had Participation and/or each third party’s agents to release “Disciplinary Information,” as defined
                                below, to the Entity and/or its Agent(s). I hereby further authorize the Agent(s) to release Disciplinary Information about any
                                disciplinary action taken against me to its participating Entities at which I have Participation, and as may be otherwise
                                required by law. As used herein, “Disciplinary Information” means information concerning: (I) any action taken by such health
                                care organizations, their administrators, or their medical or other committees to revoke, deny, suspend, restrict, or condition my
                                Participation or impose a corrective action plan; (ii) any other disciplinary action involving me, including, but not limited to,
                                discipline in the employment context; or (iii) my resignation prior to the conclusion of any disciplinary proceedings or prior to
                                the commencement of formal charges, but after I have knowledge that such formal charges were being (or are being)
                                contemplated and/or were (or are) in preparation.
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-9 sideLabelAlignment">
                                <label style="font-weight: bold;">Release from Liability. I release from all liability and hold harmless any Entity, its Agent(s), and any other third party for their acts
                                performed in good faith and without malice unless such acts are due to the gross negligence or willful misconduct of the
                                Entity, its Agent(s), or other third party in connection with the gathering, release and exchange of, and reliance upon,
                                information used in accordance with this Authorization, Attestation and Release. I further agree not to sue any Entity, any
                                Agent(s), or any other third party for their acts, defamation or any other claims based on statements made in good faith and without malice or
                                misconduct of such Entity, Agent(s) or third party in connection with the credentialing process. This release shall be in addition
                                </label>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3" style="padding: 2px;float: right;">
                                <label style="font-weight: bold;">
                                to, and in no way shall limit, any other applicable immunities provided by law for peer review and credentialing activities. </label>
                                <input style="width: 30%" class="form-control onboard " id="cmd_0308" name="cmd_0308" formControlName="cmd_0308" type="text" data-id="Text5.1.3">
                                <input style="width: 30%" class="form-control onboard " id="cmd_0309" name="cmd_0309" formControlName="cmd_0307" type="text" data-id="Text5.1.2">
                                <label style="font-weight: bold;">APPLICANT’S INITIALS AND DATE (MM ⁄DD⁄ YYYY)</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">In this Authorization, Attestation and Release, all references to the Entity, its Agent(s), and⁄or other third party include their
                                respective employees, directors, officers, advisors, counsel, and agents. The Entity or any of its affiliates or agents retains the
                                right to allow access to the application information for purposes of a credentialing audit to customers and⁄or their auditors to
                                the extent required in connection with an audit of the credentialing processes and provided that the customer and⁄or their
                                auditor executes an appropriate confidentiality agreement. I understand and agree that this Authorization, Attestation and
                                Release is irrevocable for any period during which I am an applicant for Participation at an Entity, a member of an Entity’s
                                medical or health care staff, or a participating provider of an Entity. I agree to execute another form of consent if law or
                                regulation limits the application of this irrevocable authorization. I understand that my failure to promptly provide another
                                consent may be grounds for termination or discipline by the Entity in accordance with the applicable bylaws, rules, and
                                regulations, and requirements of the Entity, or grounds for my termination of Participation at or with the Entity. I agree that
                                information obtained in accordance with the provisions of this Authorization, Attestation and Release is not and will not be a
                                violation of my privacy. </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">I certify that all information provided by me in my application is true, correct, and complete to the best of my knowledge and
                                belief, and that I will notify the Entity and⁄or its Agent(s) within 10 days of any material changes to the information I have
                                provided in my application or authorized to be released pursuant to the credentialing process. I understand that corrections to
                                the application are permitted at any time prior to a determination of Participation by the Entity, and must be submitted on-line
                                or in writing, and must be dated and signed by me (may be a written or an electronic signature). I understand and agree that
                                any material misstatement or omission in the application may constitute grounds for withdrawal of the application from
                                consideration; denial or revocation of Participation; and⁄or immediate suspension or termination of Participation. This action
                                may be disclosed to the Entity and⁄or its Agent(s).  </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="100%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;">I further acknowledge that I have read and understand the foregoing Authorization, Attestation and Release. I understand
                                and agree that a facsimile or photocopy of this Authorization, Attestation and Release shall be as effective as the original.  </label>
                            </div>
                            <div class="vcenter">
                                <label style="font-weight: bold;width: 100%">SIGNATURE  </label>
                                <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">

                                <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">

                                <input type="hidden" name="cmd_0778" id="cmd_0778" formControlName="cmd_0778" value={{finalImgPath}}>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin" style="padding: 2px;float: left;">
                                <label style="font-weight: bold;width: 50%">NAME (PLEASE PRINT OR TYPE)   </label>
                                <input style="width: 50%" class="form-control onboard " id="cmd_0779" name="cmd_0779" formControlName="cmd_0779" type="text" data-id="NPI">
                                <label style="font-weight: bold;width: 50%">Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE) </label>
                                <input style="width: 40%" class="form-control onboard  datepicker" (blur)="clearInputIfInvalid('cmd_0310')" ngbDatepicker #d63="ngbDatepicker" id="cmd_0310" name="cmd_0310" formControlName="cmd_0310" type="text" data-id="Text5.1.2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                               
                                <label style="font-weight: bold;width: 50%">DATE (MM⁄DD⁄ YYYY)  </label>
                                <div  *ngIf="texas.get('cmd_0310').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0310').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0310').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0310').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0310').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;font-size: 14px">Required Attachments or Supplemental Information – Please attach hard copy or scanned documents of the following: </label>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0311" name="cmd_0311" formControlName="cmd_0311" value="1"> Copy of DEA or state DPS Controlled Substances Registration Certificate </label>
                                </div>
                                <br>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0312" name="cmd_0312" formControlName="cmd_0312" value="1"> Copy of other Controlled Dangerous Substances Registration Certificate(s)</label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0313" name="cmd_0313" formControlName="cmd_0313" value="1">Copy of current professional liability insurance policy face sheet, showing expiration dates, limits and applicant’s name </label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0314" name="cmd_0314" formControlName="cmd_0314" value="1"> Copies of IRS W-9s for verification of each tax identification number used </label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0315" name="cmd_0315" formControlName="cmd_0315" value="1"> Copy of workers compensation certificate of coverage, if applicable </label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0316" name="cmd_0316" formControlName="cmd_0316" value="1"> Copy of CLIA certifications, if applicable  </label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox"  id="cmd_0317" name="cmd_0317" formControlName="cmd_0317" value="1"> Copies of radiology certifications, if applicable </label>
                                </div>
                                <br/>
                                <div class="col-md-12">
                                    <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                    <input type="checkbox" id="cmd_0318" name="cmd_0318" formControlName="cmd_0318" value="1">Copy of DD214, record of military service, if applicable </label>
                                </div>
                            </div>
                                <label>Reproduction of this form without any changes is allowed. </label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18" width="80%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <b>Notice About Certain Information Laws and Practices Pertaining to State Governmental Bodies (i.e. State Hospitals)</b>
                                <label style="font-weight: bold;">With few exceptions, you are entitled to be informed about the information that a state governmental body collects about
                                you (i.e. a state hospital). Under sections 552.021 and 552.023 of the Texas Government Code, you have a right to review or
                                receive copies of information about yourself, including private information. However the state governmental body may
                                withhold information for reasons other than to protect your right to privacy. Under section 559.004 of the Texas Government
                                Code, you are entitled to request that the state governmental body correct information that it has about you that is incorrect.
                                For information about the procedure and costs for obtaining information, please contact the appropriate state governmental
                                body to which you have submitted this application. </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE  Issuing Institution: </label>
                               <input style="width: 40%" class="form-control onboard " id="cmd_0780" name="cmd_0780" formControlName="cmd_0780" type="text" data-id="ugs">
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0781" name="cmd_0781" formControlName="cmd_0781" type="text" data-id="ugsaddress1">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0782" name="cmd_0782" formControlName="cmd_0782" type="text" data-id="ugsaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0783" name="cmd_0783" formControlName="cmd_0783" type="text" data-id="ugsci">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0784">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0785" name="cmd_0785" formControlName="cmd_0785" type="text" data-id="ugszip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0786" name="cmd_0786" formControlName="cmd_0786" type="text" data-id="ugsdeg">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0787')" placeholder="MM/DD/YYYY" ngbDatepicker #d64="ngbDatepicker" id="cmd_0787" name="cmd_0787" formControlName="cmd_0787" type="text" data-id="ugstart">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d64.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0787').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0787').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0787').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0787').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0787').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0788')" placeholder="MM/DD/YYYY" ngbDatepicker #d65="ngbDatepicker" id="cmd_0788" name="cmd_0788" formControlName="cmd_0788" type="text" data-id="ugcomp">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d65.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0788').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0788').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0788').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0788').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0788').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE</label>
                                Issuing Institution: <input style="width: 40%" class="form-control onboard " id="cmd_0789" name="cmd_0789" formControlName="cmd_0789" type="text" data-id="grs">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0790" name="cmd_0790" formControlName="cmd_0790" type="text" data-id="grsaddress1">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0791" name="cmd_0791" formControlName="cmd_0791" type="text" data-id="grsaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0792" name="cmd_0792" formControlName="cmd_0792" type="text" data-id="grsci">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0793">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0794" name="cmd_0794" formControlName="cmd_0794" type="text" data-id="grszip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0795" name="cmd_0795" formControlName="cmd_0795" type="text" data-id="grsdeg">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width:30%;" class="form-control onboard datepicker"  (blur)="clearInputIfInvalid('cmd_0796')" placeholder="MM/DD/YYYY" ngbDatepicker #d66="ngbDatepicker" id="cmd_0796" name="cmd_0796" formControlName="cmd_0796" type="text" data-id="grsstart">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d66.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0796').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0796').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0796').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0796').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0796').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0797')" placeholder="MM/DD/YYYY" ngbDatepicker #d67="ngbDatepicker" id="cmd_0797" name="cmd_0797" formControlName="cmd_0797" type="text" data-id="grscomp">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d67.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0797').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0797').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0797').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0797').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0797').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE</label>
                                Issuing Institution: <input style="width: 40%" class="form-control onboard " id="cmd_0798" name="cmd_0798" formControlName="cmd_0798" type="text" data-id="Training Pro 1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0799" name="cmd_0799" formControlName="cmd_0799" type="text" data-id="Training Pro 1address1a">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0800" name="cmd_0800" formControlName="cmd_0800" type="text" data-id="Training Pro 1address2a">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0319" name="cmd_0319" formControlName="cmd_0319" type="text" data-id="Training Pro 1 citya">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0320">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0321" name="cmd_0321" formControlName="cmd_0321" type="text" data-id="Training Pro 1 zipa">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0322" name="cmd_0322" formControlName="cmd_0322" type="text" data-id="Training Pro 1 certa">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0323')" placeholder="MM/DD/YYYY" ngbDatepicker #d68="ngbDatepicker" id="cmd_0323" name="cmd_0323" formControlName="cmd_0323" type="text" data-id="Training Pro 1 froma">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d68.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0323').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0323').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0323').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0323').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0323').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0324')" placeholder="MM/DD/YYYY" ngbDatepicker #d69="ngbDatepicker" id="cmd_0324" name="cmd_0324" formControlName="cmd_0324" type="text" data-id="Training Pro 1 toa">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d69.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0324').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0324').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0324').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0324').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0324').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE</label>
                                Issuing Institution: <input style="width: 40%" class="form-control onboard " id="cmd_0325" name="cmd_0325" formControlName="cmd_0325" type="text" data-id="Text7.6.6">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0326" name="cmd_0326" formControlName="cmd_0326" type="text" data-id="Text7.6.5">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0327" name="cmd_0327" formControlName="cmd_0327" type="text" data-id="Text7.6.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0328" name="cmd_0328" formControlName="cmd_0328" type="text" data-id="Text7.6.4">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0329">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0330" name="cmd_0330" formControlName="cmd_0330" type="text" data-id="Text7.6.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0331" name="cmd_0331" formControlName="cmd_0331" type="text" data-id="Text7.5.6">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0332')" placeholder="MM/DD/YYYY" ngbDatepicker #d70="ngbDatepicker" id="cmd_0332" name="cmd_0332" formControlName="cmd_0332" type="text" data-id="Text7.6.0.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d70.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0332').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0332').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0332').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0332').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0332').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0333')" placeholder="MM/DD/YYYY" ngbDatepicker #d71="ngbDatepicker" id="cmd_0333" name="cmd_0333" formControlName="cmd_0333" type="text" data-id="Text7.6.0.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d71.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0333').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0333').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0333').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0333').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0333').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE</label>
                                Issuing Institution: <input style="width: 40%" class="form-control onboard " id="cmd_0334" name="cmd_0334" formControlName="cmd_0334" type="text" data-id="Text7.5.5">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0335" name="cmd_0335" formControlName="cmd_0335" type="text" data-id="Text7.5.4">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0336" name="cmd_0336" formControlName="cmd_0336" type="text" data-id="Text7.5.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0337" name="cmd_0337" formControlName="cmd_0337" type="text" data-id="Text7.5.0">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0338">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0339" name="cmd_0339" formControlName="cmd_0339" type="text" data-id="Text7.5.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0340" name="cmd_0340" formControlName="cmd_0340" type="text" data-id="Text7.4.6">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0341')" placeholder="MM/DD/YYYY" ngbDatepicker #d72="ngbDatepicker" id="cmd_0341" name="cmd_0341" formControlName="cmd_0341" type="text" data-id="Text7.4.5.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d72.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0341').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0341').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0341').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0341').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0341').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0342')" placeholder="MM/DD/YYYY" ngbDatepicker #d73="ngbDatepicker" id="cmd_0342" name="cmd_0342" formControlName="cmd_0342" type="text" data-id="Text7.4.5.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d73.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0342').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0342').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0342').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0342').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0342').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE</label>
                                Issuing Institution: <input style="width: 40%" class="form-control onboard " id="cmd_0343" name="cmd_0343" formControlName="cmd_0343" type="text" data-id="Text7.4.4">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0344" name="cmd_0344" formControlName="cmd_0344" type="text" data-id="Text7.4.3">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0345" name="cmd_0345" formControlName="cmd_0345" type="text" data-id="Text7.4.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0346" name="cmd_0346" formControlName="cmd_0346" type="text" data-id="Text7.4.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0347">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0348" name="cmd_0348" formControlName="cmd_0348" type="text" data-id="Text7.3.6">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DEGREE</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0349" name="cmd_0349" formControlName="cmd_0349" type="text" data-id="Text7.3.5">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0350')" placeholder="MM/DD/YYYY" ngbDatepicker #d74="ngbDatepicker" id="cmd_0350" name="cmd_0350" formControlName="cmd_0350" type="text" data-id="Text7.3.4.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d74.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0350').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0350').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0350').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0350').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0350').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0351')" placeholder="MM/DD/YYYY" ngbDatepicker #d75="ngbDatepicker" id="cmd_0351" name="cmd_0351" formControlName="cmd_0351" type="text" data-id="Text7.3.4.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d75.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0351').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0351').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0351').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0351').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0351').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER PROFESSIONAL DEGREE Issuing Institution:</label>
                                 <input style="width: 40%" class="form-control onboard " id="cmd_0352" name="cmd_0352" formControlName="cmd_0352" type="text" data-id="Text7.3.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0353" name="cmd_0353" formControlName="cmd_0353" type="text" data-id="Text7.3.2">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0354" name="cmd_0354" formControlName="cmd_0354" type="text" data-id="Text7.3.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0355" name="cmd_0355" formControlName="cmd_0355" type="text" data-id="Text7.3.0">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0356">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0357" name="cmd_0357" formControlName="cmd_0357" type="text" data-id="Text7.2.4">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DEGREE
                                <input style="width:40%;" class="form-control onboard " id="cmd_0358" name="cmd_0358" formControlName="cmd_0358" type="text" data-id="ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY) ">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ATTENDANCE DATES(MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0359')" placeholder="MM/DD/YYYY" ngbDatepicker #d76="ngbDatepicker" id="cmd_0359" name="cmd_0359" formControlName="cmd_0359" type="text" data-id="Text7.2.3.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d76.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0359').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0359').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0359').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0359').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0359').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0360')" placeholder="MM/DD/YYYY" ngbDatepicker #d77="ngbDatepicker" id="cmd_0360" name="cmd_0360" formControlName="cmd_0360" type="text" data-id="Text7.2.3.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d77.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0360').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0360').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0360').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0360').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0360').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                   <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER POST-GRADUATE EDUCATION </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0361" name="cmd_0361" formControlName="cmd_0361" value="1"> Internship</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0362" name="cmd_0362"  formControlName="cmd_0362" value="1">Residency</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0363" name="cmd_0363" formControlName="cmd_0363" value="1">Fellowship </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0364" name="cmd_0364"  formControlName="cmd_0364" value="1"> Teaching Appointment</label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SPECIALTY</label>
                                <input style="width: 100%;margin-bottom: 2px;" class="form-control onboard "  id="cmd_0801" name="cmd_0801" formControlName="cmd_0801" type="text" data-id="addresspec">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">INSTITUTION</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard" id="cmd_0802" name="cmd_0802" formControlName="cmd_0802" type="text" data-id="addresschool">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0803" name="cmd_0803" formControlName="cmd_0803" type="text" data-id="addresaddress1">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0804" name="cmd_0804" formControlName="cmd_0804" type="text" data-id="addresaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0805" name="cmd_0805" formControlName="cmd_0805" type="text" data-id="addrescity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0806">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0807" name="cmd_0807" formControlName="cmd_0807" type="text" data-id="addreszip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <input type="checkbox" id="cmd_0365" name="cmd_0365" formControlName="cmd_0365" value="1">Program successfully completed
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                            </div>
                            <div class="input-group">
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0808')" placeholder="MM/DD/YYYY" ngbDatepicker #d78="ngbDatepicker" id="cmd_0808" name="cmd_0808" formControlName="cmd_0808" type="text" data-id="addresstart">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d78.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0808').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0808').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0808').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0808').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0808').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="input-group">
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0809')" placeholder="MM/DD/YYYY" ngbDatepicker #d79="ngbDatepicker" id="cmd_0809" name="cmd_0809" formControlName="cmd_0809" type="text" data-id="addresend">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d79.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0809').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0809').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0809').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0809').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0809').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROGRAM DIRECTOR </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0810" name="cmd_0810" formControlName="cmd_0810" type="text" data-id="addresdirector">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0811" name="cmd_0811" formControlName="cmd_0811" type="text" data-id="addresdirector1">
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER POST-GRADUATE EDUCATION </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0366" name="cmd_0366" formControlName="cmd_0366" value="1"> Internship</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0367" name="cmd_0367"  formControlName="cmd_0367" value="1">Residency</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0368" name="cmd_0368" formControlName="cmd_0368" value="1">Fellowship </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0369" name="cmd_0369" formControlName="cmd_0369" value="1"> Teaching Appointment</label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SPECIALTY</label>
                                <input style="width: 100%;margin-bottom: 2px;" class="form-control onboard "  id="cmd_0812" name="cmd_0812" formControlName="cmd_0812" type="text" data-id="fellowspec">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">INSTITUTION</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard" id="cmd_0813" name="cmd_0813" formControlName="cmd_0813" type="text" data-id="fellowschool">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0814" name="cmd_0814" formControlName="cmd_0814" type="text" data-id="fellowadd1">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0815" name="cmd_0815" formControlName="cmd_0815" type="text" data-id="fellowadd2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0816" name="cmd_0816" formControlName="cmd_0816" type="text" data-id="fellowci">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0817">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0818" name="cmd_0818" formControlName="cmd_0818" type="text" data-id="fellowz">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <input type="checkbox" id="cmd_0370" name="cmd_0370" value="1">Program successfully completed
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                            </div>
                            <div class="input-group">
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0819')" placeholder="MM/DD/YYYY" ngbDatepicker #d80="ngbDatepicker" id="cmd_0819" name="cmd_0819" formControlName="cmd_0819" type="text" data-id="fellowstart_date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d80.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0819').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0819').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0819').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0819').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0819').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="input-group">
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0820')" placeholder="MM/DD/YYYY" ngbDatepicker #d81="ngbDatepicker" id="cmd_0820" name="cmd_0820" formControlName="cmd_0820" type="text" data-id="fellowcompdate_date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d81.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0820').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0820').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0820').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0820').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0820').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROGRAM DIRECTOR </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0821" name="cmd_0821" formControlName="cmd_0821" type="text" data-id="fellowdirector1">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0822" name="cmd_0822" formControlName="cmd_0822" type="text" data-id="fellowdirector2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER POST-GRADUATE EDUCATION </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0371" name="cmd_0371" formControlName="cmd_0371" value="1"> Internship</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0372" name="cmd_0372"  formControlName="cmd_0372" value="1">Residency</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0373" name="cmd_0373" formControlName="cmd_0373" value="1">Fellowship </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0374" name="cmd_0374"  formControlName="cmd_0374" value="1"> Teaching Appointment</label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SPECIALTY</label>
                                <input style="width: 100%;margin-bottom: 2px;" class="form-control onboard "  id="cmd_0822" name="cmd_0822" formControlName="cmd_0822" type="text" data-id="addfellowspec">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">INSTITUTION</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard" id="cmd_0823" name="cmd_0823" formControlName="cmd_0823" type="text" data-id="addfellowschool">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0824" name="cmd_0824" formControlName="cmd_0824" type="text" data-id="addfellowaddress1">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0825" name="cmd_0825" formControlName="cmd_0825" type="text" data-id="addfellowaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0826" name="cmd_0826" formControlName="cmd_0825" type="text" data-id="addfellowcity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0827">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0828" name="cmd_0828" formControlName="cmd_0828" type="text" data-id="addfellowfax">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <input type="checkbox" id="cmd_0375" name="cmd_0375" formControlName="cmd_0375" value="1">Program successfully completed
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                            </div>
                            <div class="input-group">
                                <input style="width:30;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0829')" placeholder="MM/DD/YYYY" ngbDatepicker #d82="ngbDatepicker"  id="cmd_0829" name="cmd_0829" formControlName="cmd_0829" type="text" data-id="addfellowstart">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d82.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0829').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0829').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0829').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0829').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0829').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="input-group">
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0830')" placeholder="MM/DD/YYYY" ngbDatepicker #d83="ngbDatepicker" id="cmd_0830" name="cmd_0830" formControlName="cmd_0830" type="text" data-id="addfellowend">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d83.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0830').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0830').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0830').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0830').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0830').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROGRAM DIRECTOR </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0831" name="cmd_0831" formControlName="cmd_0831" type="text" data-id="addfellowdirector">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0832" name="cmd_0832" formControlName="cmd_0832" type="text" data-id="addfellowdirector1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER POST-GRADUATE EDUCATION </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0376" name="cmd_0376" formControlName="cmd_0376" value="1"> Internship</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0377" name="cmd_0377"  formControlName="cmd_0377" value="1">Residency</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0378" name="cmd_0378" formControlName="cmd_0378" value="1">Fellowship </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0379" name="cmd_0379" formControlName="cmd_0379"  value="1"> Teaching Appointment</label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SPECIALTY</label>
                                <input style="width: 100%;margin-bottom: 2px;" class="form-control onboard "  id="cmd_0380" name="cmd_0380" formControlName="cmd_0380" type="text" data-id="Text7.2.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">INSTITUTION</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard" id="cmd_0381" name="cmd_0381" formControlName="cmd_0381" type="text" data-id="Text7.9.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0382" name="cmd_0382" formControlName="cmd_0382" type="text" data-id="Text7.9.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0383" name="cmd_0383" formControlName="cmd_0383" type="text" data-id="Text7.8.6">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0384" name="cmd_0384" formControlName="cmd_0384" type="text" data-id="Text7.8.5">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0385">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0386" name="cmd_0386" formControlName="cmd_0386" type="text" data-id="Text7.8.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <input type="checkbox" id="cmd_0387" name="cmd_0387" formControlName="cmd_0387" value="1">Program successfully completed
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                            </div>
                            <div class="input-group">
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0388')" placeholder="MM/DD/YYYY" ngbDatepicker #d84="ngbDatepicker" id="cmd_0388" name="cmd_0388" formControlName="cmd_0388" type="text" data-id="Text7.8.1.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d84.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0388').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0388').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0388').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0388').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0388').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="input-group">
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0389')" placeholder="MM/DD/YYYY" ngbDatepicker #d85="ngbDatepicker" id="cmd_0389" name="cmd_0389" formControlName="cmd_0389" type="text" data-id="Text7.8.1.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d85.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0389').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0389').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0389').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0389').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0389').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROGRAM DIRECTOR </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0390" name="cmd_0390" formControlName="cmd_0390" type="text" data-id="Text7.8.4.0">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0391" name="cmd_0391" formControlName="cmd_0391" type="text" data-id="Text7.8.4.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER POST-GRADUATE EDUCATION </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0392" name="cmd_0392" formControlName="cmd_0392" value="1"> Internship</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0393" name="cmd_0393"  formControlName="cmd_0393" value="1">Residency</label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0394" name="cmd_0394" formControlName="cmd_0394" value="1">Fellowship </label>
                                <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0395" name="cmd_0395" formControlName="cmd_0395" value="1"> Teaching Appointment</label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SPECIALTY</label>
                                <input style="width: 100%;margin-bottom: 2px;" class="form-control onboard "  id="cmd_0396" name="cmd_0396" formControlName="cmd_0396" type="text" data-id="Text7.2.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">INSTITUTION</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard" id="cmd_0397" name="cmd_0397" formControlName="cmd_0397" type="text" data-id="Text7.8.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0398" name="cmd_0398" formControlName="cmd_0398" type="text" data-id="Text7.7.6">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0399" name="cmd_0399" formControlName="cmd_0399" type="text" data-id="Text7.7.5">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0400" name="cmd_0400" formControlName="cmd_0400" type="text" data-id="Text7.7.2">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0401">
                                    </ng-select>
                                </div>
                            </div>
                                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0402" name="cmd_0402" formControlName="cmd_0402" type="text" data-id="Text7.7.4">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <input type="checkbox" id="cmd_0403" name="cmd_0403" formControlName="cmd_0403" value="1">Program successfully completed
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/DD/YYYY TO MM/DD/YYYY) </label>
                            </div>
                            <div class="input-group">
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0404')" placeholder="MM/DD/YYYY" ngbDatepicker #d86="ngbDatepicker" id="cmd_0404" name="cmd_0404" formControlName="cmd_0404" type="text" data-id="Text7.7.0.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d86.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0404').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0404').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0404').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0404').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0404').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="input-group">
                                <input style="width:25%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0405')" placeholder="MM/DD/YYYY" ngbDatepicker #d87="ngbDatepicker" id="cmd_0405" name="cmd_0405" formControlName="cmd_0405" type="text" data-id="Text7.7.0.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d87.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0405').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0405').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0405').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0405').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0405').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROGRAM DIRECTOR </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0406" name="cmd_0406" formControlName="cmd_0406" type="text" data-id="Text7.7.1.0">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                                <input style="width:45%;" class="form-control onboard " id="cmd_0407" name="cmd_0407" formControlName="cmd_0407" type="text" data-id="Text7.7.1.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                <input class="form-control onboard " id="cmd_0408" name="cmd_0408" formControlName="cmd_0408" type="text" data-id="Text7.0.2">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0409')" placeholder="MM/DD/YYYY" ngbDatepicker #d88="ngbDatepicker" id="cmd_0409" name="cmd_0409" formControlName="cmd_0409" type="text" data-id="Text1.3.5">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d88.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0409').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0409').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0409').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0409').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0409').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0410')" placeholder="MM/DD/YYYY" ngbDatepicker #d89="ngbDatepicker" id="cmd_0410" name="cmd_0410" formControlName="cmd_0410" type="text" data-id="Text1.3.4">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d89.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0410').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0410').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0410').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0410').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0410').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0411" name="cmd_0411" formControlName="cmd_0411" type="text" data-id="Text1.9.5.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0412" name="cmd_0412" formControlName="cmd_0412" type="text" data-id="Text1.9.0.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0413" name="cmd_0413" formControlName="cmd_0413" type="text" data-id="Work hist 1 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0414">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0415" name="cmd_0415" formControlName="cmd_0415" type="text" data-id="Work hist 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0416" name="cmd_0416" formControlName="cmd_0416" type="text" data-id="Text1.9.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                <input class="form-control onboard " id="cmd_0417" name="cmd_0417" formControlName="cmd_0417" type="text" data-id="Text7.0.1">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0418')" placeholder="MM/DD/YYYY" ngbDatepicker #d90="ngbDatepicker" id="cmd_0418" name="cmd_0418" formControlName="cmd_0418" type="text" data-id="Text1.4.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d90.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0418').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0418').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0418').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0418').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0418').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0419')" placeholder="MM/DD/YYYY" ngbDatepicker #d91="ngbDatepicker" id="cmd_0419" name="cmd_0419" formControlName="cmd_0419" type="text" data-id="Text1.4.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d91.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0419').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0419').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0419').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0419').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0419').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0420" name="cmd_0420" formControlName="cmd_0420" type="text" data-id="Text1.9.5.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0421" name="cmd_0421" formControlName="cmd_0421" type="text" data-id="Text1.9.5.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0422" name="cmd_0422" formControlName="cmd_0422" type="text" data-id="Text1.4.6">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0423">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0424" name="cmd_0424" formControlName="cmd_0424" type="text" data-id="Work hist 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0425" name="cmd_0425" formControlName="cmd_0425" type="text" data-id="Text1.9.6">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                <input class="form-control onboard " id="cmd_0426" name="cmd_0426" formControlName="cmd_0426" type="text" data-id="Text1.9.4">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0427')" placeholder="MM/DD/YYYY" ngbDatepicker #d92="ngbDatepicker" id="cmd_0427" name="cmd_0427" formControlName="cmd_0427" type="text" data-id="Text1.4.4">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d92.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0427').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0427').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0427').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0427').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0427').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0428')" placeholder="MM/DD/YYYY" ngbDatepicker #d93="ngbDatepicker" id="cmd_0428" name="cmd_0428" formControlName="cmd_0428" type="text" data-id="Text1.4.3">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d93.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0428').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0428').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0428').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0428').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0428').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0429" name="cmd_0429" formControlName="cmd_0429" type="text" data-id="Text1.4.7.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0430" name="cmd_0430" formControlName="cmd_0430" type="text" data-id="Text1.4.7.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0431" name="cmd_0431" formControlName="cmd_0431" type="text" data-id="Text1.5.2">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0432">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0433" name="cmd_0433" formControlName="cmd_0433" type="text" data-id="Work hist 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0434" name="cmd_0434" formControlName="cmd_0434" type="text" data-id="Text1.9.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                <input class="form-control onboard " id="cmd_0435" name="cmd_0435" formControlName="cmd_0435" type="text" data-id="Text1.9.2">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0436')" placeholder="MM/DD/YYYY" ngbDatepicker #d94="ngbDatepicker" id="cmd_0436" name="cmd_0436" formControlName="cmd_0436" type="text" data-id="wkhist2startdt">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d94.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0436').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0436').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0436').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0436').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0436').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0437')" placeholder="MM/DD/YYYY" ngbDatepicker #d95="ngbDatepicker" id="cmd_0437" name="cmd_0437" formControlName="cmd_0437" type="text" data-id="wkhist2enddt">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d95.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0437').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0437').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0437').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0437').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0437').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0438" name="cmd_0438" formControlName="cmd_0438" type="text" data-id="wkhist2add">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0439" name="cmd_0439" formControlName="cmd_0439" type="text" data-id="wkhist2add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0440" name="cmd_0440" formControlName="cmd_0440" type="text" data-id="Text1.5.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0441">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0442" name="cmd_0442" formControlName="cmd_0442" type="text" data-id="Text1.5.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0443" name="cmd_0443" formControlName="cmd_0443" type="text" data-id="Text1.9.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                <input class="form-control onboard " id="cmd_0444" name="cmd_0444" formControlName="cmd_0444" type="text" data-id="Text1.8.7">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0445')" placeholder="MM/DD/YYYY" ngbDatepicker #d96="ngbDatepicker" id="cmd_0445" name="cmd_0445" formControlName="cmd_0445" type="text" data-id="Text1.6.2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d96.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0445').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0445').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0445').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0445').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0445').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0446')" placeholder="MM/DD/YYYY" ngbDatepicker #d97="ngbDatepicker" id="cmd_0446" name="cmd_0446" formControlName="cmd_0446" type="text" data-id="Text1.6.1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d97.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0446').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0446').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0446').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0446').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0446').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0447" name="cmd_0447" formControlName="cmd_0447" type="text" data-id="Text1.8.6.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0448" name="cmd_0448" formControlName="cmd_0448" type="text" data-id="Text1.8.6.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0449" name="cmd_0449" formControlName="cmd_0449" type="text" data-id="Text1.5.0">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0450">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0451" name="cmd_0451" formControlName="cmd_0451" type="text" data-id="Text1.6.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0452" name="cmd_0452" formControlName="cmd_0452" type="text" data-id="Text1.8.5">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS PRACTICE/EMPLOYER NAME
                                    <input class="form-control onboard " id="cmd_0453" name="cmd_0453" formControlName="cmd_0453" type="text" data-id="Text1.8.3">
                                </label>
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE/END DATE (MM/DD/YYYY TO MM/DD/YYYY)
                                </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0454')" placeholder="MM/DD/YYYY" ngbDatepicker #d98="ngbDatepicker" id="cmd_0454" name="cmd_0454" formControlName="cmd_0454" type="text" data-id="Text1.7.0">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d98.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0454').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0454').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0454').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0454').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0454').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0455')" placeholder="MM/DD/YYYY" ngbDatepicker #d99="ngbDatepicker" id="cmd_0455" name="cmd_0455" formControlName="cmd_0455" type="text" data-id="Text1.6.7">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d99.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0455').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0455').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0455').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0455').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0455').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 1" id="cmd_0456" name="cmd_0456" formControlName="cmd_0456" type="text" data-id="Text1.8.4.0">
                                <input style="width: 40%;margin-bottom: 2px;" class="form-control onboard " placeholder="Address 2" id="cmd_0457" name="cmd_0457" formControlName="cmd_0457" type="text" data-id="Text1.8.4.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" placeholder="City" class="form-control onboard " id="cmd_0458" name="cmd_0458" formControlName="cmd_0458" type="text" data-id="Work hist 1 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0459">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="Zip" id="cmd_0460" name="cmd_0460" formControlName="cmd_0460" type="text" data-id="Work hist 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0461" name="cmd_0461" formControlName="cmd_0461" type="text" data-id="Text1.7.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="cmd_0833" name="cmd_0833" formControlName="cmd_0833" type="text" data-id="hospaff3nm">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <input style="width:35%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0834')" placeholder="MM/DD/YYYY" ngbDatepicker #d100="ngbDatepicker" id="cmd_0834" name="cmd_0834" formControlName="cmd_0834" type="text" data-id="hospaff3app">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d100.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0834').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0834').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0834').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0834').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0834').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " style="width: 40%" id="cmd_0835" name="cmd_0835" formControlName="cmd_0835" type="text" data-id="hospaff3add1">
                                <input class="form-control onboard " style="width: 40%" id="cmd_0836" name="cmd_0836" formControlName="cmd_0836" type="text" data-id="hospaff3add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="City" id="cmd_0837" name="cmd_0837" formControlName="cmd_0837" type="text" data-id="hospaff3city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0838">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0839" name="cmd_0839" formControlName="cmd_0839" type="text" placeholder="Zip" data-id="hospaff3zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0462" name="cmd_0462" formControlName="cmd_0462" type="text" data-id="Text1.3.2">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_0463" name="cmd_0463" formControlName="cmd_0463" type="text" data-id="reference2fax">
                            </div>
                        </td>
                        <td colspan="8">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-82 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_0464" name="cmd_0464" formControlName="cmd_0464" type="text" data-id="Text1.3.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0465" name="cmd_0465" formControlName="cmd_0465" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0465" name="cmd_0465" formControlName="cmd_0465" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0840" name="cmd_0840" formControlName="cmd_0840" type="text" data-id="hospaff3dep">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0466" name="cmd_0466" formControlName="cmd_0466" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0466" name="cmd_0466" formControlName="cmd_0466" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0467" name="cmd_0467" formControlName="cmd_0467" type="text" data-id="hospaff1adimissons">
                            </div>
                        </td>
                    </tr>
                   <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="cmd_0841" name="cmd_0841" formControlName="cmd_0841" type="text" data-id="Hosp aff 4 name">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <input style="width:35%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0842')" placeholder="MM/DD/YYYY" ngbDatepicker #d101="ngbDatepicker" id="cmd_0842" name="cmd_0842" formControlName="cmd_0842" type="text" data-id="Hosp aff 4 app">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d101.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0842').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0842').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0842').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0842').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0842').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0843" name="cmd_0843" formControlName="cmd_0843" type="text" data-id="Hosp aff 4 add1">
                                <input class="form-control onboard " id="cmd_0844" name="cmd_0844" formControlName="cmd_0844" type="text" data-id="Hosp aff 4 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0845" name="cmd_0845" formControlName="cmd_0845" type="text" placeholder="City" data-id="Hosp aff 4 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0846">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0847" name="cmd_0847" formControlName="cmd_0847" type="text" placeholder="Zip" data-id="Hosp aff 4 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_04681" name="cmd_04681" formControlName="cmd_04681" type="text" data-id="hospaff2phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_04691" name="cmd_04691" formControlName="cmd_04691" type="text" data-id="hospaff2fax">
                            </div>
                        </td>
                        <td colspan="8">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_04701" name="cmd_04701" formControlName="cmd_04701" type="text" data-id="hospaff2email">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0471" name="cmd_04711" formControlName="cmd_04711" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0471" name="cmd_04711" formControlName="cmd_04711" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0848" name="cmd_0848" formControlName="cmd_0848" type="text" data-id="Hosp aff 4 status">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_04721" name="cmd_04721" formControlName="cmd_04721" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_04721" name="cmd_04721" formControlName="cmd_04721" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0473" name="cmd_0473" formControlName="cmd_0473" type="text" data-id="Text1.2.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="cmd_0849" name="cmd_0849" formControlName="cmd_0849" type="text" data-id="Pending app 1 Name">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0850')" placeholder="MM/DD/YYYY" ngbDatepicker #d102="ngbDatepicker" id="cmd_0850" name="cmd_0850" formControlName="cmd_0850" type="text" data-id="Pending app 1">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0850').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0850').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0850').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0850').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0850').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0851" name="cmd_0851" formControlName="cmd_0851" type="text" data-id="Pending app 1 add1">
                                <input class="form-control onboard " id="cmd_0852" name="cmd_0852" formControlName="cmd_0852" type="text" data-id="Pending app 1 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0853" name="cmd_0853" formControlName="cmd_0853" type="text" placeholder="City" data-id="Pending app 1 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0854">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0855" name="cmd_0855" formControlName="cmd_0855" type="text" placeholder="Zip" data-id="Pending app 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0474" name="cmd_0474" formControlName="cmd_0474" type="text" data-id="hospaff2phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_0475" name="cmd_0475" formControlName="cmd_0475" type="text" data-id="hospaff2fax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_0476" name="cmd_0476" formControlName="cmd_0476" type="text" data-id="hospaff2email">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0477" name="cmd_0477" formControlName="cmd_0477" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0477" name="cmd_0477" formControlName="cmd_0477" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0856" name="cmd_0856" formControlName="cmd_0856" type="text" data-id="Pending app 1 status">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0478" name="cmd_0478" formControlName="cmd_0478" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0478" name="cmd_0478" formControlName="cmd_0478" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0467" name="cmd_0467" formControlName="cmd_0467" type="text" data-id="hospaff1adimissons">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="986" name="986" formControlName="986" type="text" data-id="Hosp aff 4 name">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('994')" placeholder="MM/DD/YYYY" ngbDatepicker #d103="ngbDatepicker" id="994" name="994" formControlName="994" type="text" data-id="Hosp aff 4 app">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('994').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('994').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('994').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('994').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('994').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="987" name="987" formControlName="987" type="text" data-id="Hosp aff 4 add1">
                                <input class="form-control onboard " id="988" name="988" formControlName="988" type="text" data-id="Hosp aff 4 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="989" name="989" formControlName="989" type="text" placeholder="City" data-id="Hosp aff 4 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="991">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard "  id="992" name="992" formControlName="992" type="text" placeholder="Zip" data-id="Hosp aff 4 zip">

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0468" name="cmd_0468" formControlName="cmd_0468" type="text" data-id="hospaff2phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_0469" name="cmd_0469" formControlName="cmd_0469" type="text" data-id="hospaff2fax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_0470" name="cmd_0470" formControlName="cmd_0470" type="text" data-id="hospaff2email">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0471" name="cmd_0471" formControlName="cmd_0471" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0471" name="cmd_0471" formControlName="cmd_0471" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="995" name="995" formControlName="995" type="text" data-id="Hosp aff 4 status">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0472" name="cmd_0472" formControlName="cmd_0472" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0472" name="cmd_0472" formControlName="cmd_0472" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0479" name="cmd_0479" formControlName="cmd_0479" type="text" data-id="Text1.1.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="cmd_0857" name="cmd_0857" formControlName="cmd_0857" type="text" data-id="Pending app 2 Name">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0858')" placeholder="MM/DD/YYYY" ngbDatepicker #d104="ngbDatepicker" id="cmd_0858" name="cmd_0858" formControlName="cmd_0858" type="text" data-id="Pending app 2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d104.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0858').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0858').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0858').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0858').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0858').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0859" name="cmd_0859" formControlName="cmd_0859" type="text" data-id="Pending app 2 add1">
                                <input class="form-control onboard " id="cmd_0860" name="cmd_0860" formControlName="cmd_0860" type="text" data-id="Pending app 2 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0861" name="cmd_0861" formControlName="cmd_0861" type="text" placeholder="City" data-id="Pending app 2 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0862">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0863" name="cmd_0863" formControlName="cmd_0863" type="text" placeholder="Zip" data-id="Pending app 2 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0480" name="cmd_0480" formControlName="cmd_0480" type="text" data-id="hospaff2phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_0481" name="cmd_0481" formControlName="cmd_0481" type="text" data-id="hospaff2fax">
                            </div>
                        </td>
                        <td colspan="8">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_0482" name="cmd_0482" formControlName="cmd_0482" type="text" data-id="hospaff2email">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0483" name="cmd_0483" formControlName="cmd_0483"  value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0483" name="cmd_0483" formControlName="cmd_0483" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0864" name="cmd_0864"  formControlName="cmd_0864" type="text" data-id="Pending app 2 status">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0484" name="cmd_0484" formControlName="cmd_0484" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0484" name="cmd_0484" formControlName="cmd_0484" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0485" name="cmd_0485" formControlName="cmd_0485" type="text" data-id="Text1.1.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES </label>
                                <input class="form-control onboard " id="cmd_0865" name="cmd_0865" formControlName="cmd_0865" type="text" data-id="Pending app 3">
                            </div>
                        </td>
                        <td colspan="12">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">START DATE (MM/DD/YYYY)  </label>
                                <div class="input-group">
                                    <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0866')" placeholder="MM/DD/YYYY" ngbDatepicker #d105="ngbDatepicker" id="cmd_0866" name="cmd_0866" formControlName="cmd_0866" type="text" data-id="Pending app 3 sub">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d105.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                    <div  *ngIf="texas.get('cmd_0866').invalid ">
                                        <small class="text-danger" *ngIf="texas.get('cmd_0866').errors?.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY)
                                        </small>
                                        <small class="text-danger" *ngIf="texas.get('cmd_0866').errors?.invalidMonth">
                                        Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="texas.get('cmd_0866').errors?.invalidDateyear">
                                          year is out of range(1900-2099)                      </small>
                                          <small class="text-danger" *ngIf="texas.get('cmd_0866').errors?.invalidDateRange">
                                            date is out of range
                                            </small>
                                        </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0867" name="cmd_0867" formControlName="cmd_0867" type="text" data-id="Pending app 3 add1">
                                <input class="form-control onboard " id="cmd_0868" name="cmd_0868" formControlName="cmd_0868" type="text" data-id="Pending app 3 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0869" name="cmd_0869" formControlName="cmd_0869" type="text" placeholder="City" data-id="Pending app 3 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0870">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0871" name="cmd_0871" formControlName="cmd_0871" type="text" placeholder="Zip" data-id="Pending app 3 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0485" name="cmd_0485" formControlName="cmd_0485" type="text" data-id="hospaff2phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FAX</label>
                                <input class="form-control onboard " id="cmd_0486" name="cmd_0486" formControlName="cmd_0486" type="text" data-id="hospaff2fax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL</label>
                                <input style="width: 90%;" class="form-control onboard " id="cmd_0487" formControlName="cmd_0487" name="cmd_0487" type="text" data-id="hospaff2email">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0488" name="cmd_0488" formControlName="cmd_0488" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0488" name="cmd_0488" formControlName="cmd_0488" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0872" name="cmd_0872" formControlName="cmd_0872" type="text" data-id="Pending app 3 status">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0489" name="cmd_0489" formControlName="cmd_0489" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0489" name="cmd_0489" formControlName="cmd_0489"  value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN THE PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL? </label>
                                <input class="form-control onboard " id="cmd_0490" name="cmd_0490" formControlName="cmd_0490" type="text" data-id="hospaff1adimissons">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                                <input class="form-control onboard " id="cmd_0491" name="cmd_0491" formControlName="cmd_0491" type="text" data-id="Other aff 1">
                            </div>
                        </td>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0492')" placeholder="MM/DD/YYYY" ngbDatepicker #d106="ngbDatepicker" id="cmd_0492" name="cmd_0492" formControlName="cmd_0492" type="text" data-id="Other aff 1 from">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d106.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0492').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0492').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0492').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0492').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0492').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0493')" placeholder="MM/DD/YYYY" ngbDatepicker #d107="ngbDatepicker" id="cmd_0493" name="cmd_0493" formControlName="cmd_0493" type="text" data-id="Other aff 1 to">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d107.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0493').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0493').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0493').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0493').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0493').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " style="width:40%" id="cmd_0494" name="cmd_0494" formControlName="cmd_0494" type="text" data-id="Other aff 1 add1">
                                <input class="form-control onboard " style="width:40%"  id="cmd_0495" name="cmd_0495" formControlName="cmd_0495" type="text" data-id="Other aff 1 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="city" id="cmd_0496" name="cmd_0496" formControlName="cmd_0496" type="text" data-id="Other aff 1 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0497">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " placeholder="zip" id="cmd_0498" name="cmd_0498" formControlName="cmd_0498" type="text" data-id="Other aff 1 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0499" name="cmd_0499" formControlName="cmd_0499" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0499" name="cmd_0499" formControlName="cmd_0499" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0500" name="cmd_0500" formControlName="cmd_0500" type="text" data-id="Text1.0.6">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0501" name="cmd_0501" formControlName="cmd_0501" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0501" name="cmd_0501" formControlName="cmd_0501" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0502" name="cmd_0502" formControlName="cmd_0502" type="text" data-id="Other aff 1 leaving">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                                <input class="form-control onboard " id="cmd_0503" name="cmd_0503" formControlName="cmd_0503" type="text" data-id="Other aff 2">
                            </div>
                        </td>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0504')" placeholder="MM/DD/YYYY" ngbDatepicker #d108="ngbDatepicker" id="cmd_0504" name="cmd_0504" formControlName="cmd_0504" type="text" data-id="Other aff 2 from">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d108.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0504').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0504').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0504').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0504').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0504').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0505')" placeholder="MM/DD/YYYY" ngbDatepicker #d109="ngbDatepicker" id="cmd_0505" name="cmd_0505" formControlName="cmd_0505" type="text" data-id="Other aff 2 to">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d109.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0505').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0505').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0505').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0505').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0505').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " style="width: 40%" id="cmd_0506" name="cmd_0506" formControlName="cmd_0506" type="text" data-id="Other aff 2 add1">
                                <input class="form-control onboard " style="width: 40%" id="cmd_0507" name="cmd_0507" formControlName="cmd_0507" type="text" data-id="Other aff 2 add1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0508" name="cmd_0508" formControlName="cmd_0508" type="text" data-id="Other aff 2 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0509">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0510" name="cmd_0510" formControlName="cmd_0510" type="text" data-id="Other aff 2  zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0511" name="cmd_0511" formControlName="cmd_0511" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0511" name="cmd_0511" formControlName="cmd_0511" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0512" name="cmd_0512" formControlName="cmd_0512" type="text" data-id="Military Locaiton">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0513" name="cmd_0513" formControlName="cmd_0513" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0513" name="cmd_0513" formControlName="cmd_0513" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0514" name="cmd_0514" formControlName="cmd_0514" type="text" data-id="Other aff 2 leaving">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                                <input class="form-control onboard " id="cmd_0515" name="cmd_0515" formControlName="cmd_0515" type="text" data-id="Other aff 3">
                            </div>
                        </td>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0516')" placeholder="MM/DD/YYYY" ngbDatepicker #d110="ngbDatepicker" id="cmd_0516" name="cmd_0516" formControlName="cmd_0516" type="text" data-id="Other aff 3 from">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d110.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0516').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0516').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0516').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0516').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0516').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0517')" placeholder="MM/DD/YYYY" ngbDatepicker #d111="ngbDatepicker" id="cmd_0517" name="cmd_0517" formControlName="cmd_0517" type="text" data-id="Other aff 3  to">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d111.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0517').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0517').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0517').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0517').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0517').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " style="width: 40%" id="cmd_0518" name="cmd_0518" formControlName="cmd_0518" type="text" data-id="Other aff 3 add1">
                                <input class="form-control onboard " style="width: 40%" id="cmd_0519" name="cmd_0519" formControlName="cmd_0519" type="text" data-id="Other aff 3 add2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0520" name="cmd_0520" formControlName="cmd_0520" type="text" data-id="Other aff 3 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0521">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0522" name="cmd_0522" formControlName="cmd_0522" type="text" data-id="Other aff 3 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0523" name="cmd_0523" formControlName="cmd_0523" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0523" name="cmd_0523" formControlName="cmd_0523" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0524" name="cmd_0524" formControlName="cmd_0524" type="text" data-id="Military Locaiton">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0525" name="cmd_0525" formControlName="cmd_0525" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0525" name="cmd_0525" formControlName="cmd_0525" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0526" name="cmd_0526" formControlName="cmd_0526" type="text" data-id="Other aff 3 leaving">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                                <input class="form-control onboard " id="cmd_0527" name="cmd_0527" formControlName="cmd_0527" type="text" data-id="Other aff 4">
                            </div>
                        </td>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)  </label>
                                <input style="width: 30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0528')" placeholder="MM/DD/YYYY" ngbDatepicker #d112="ngbDatepicker" id="cmd_0528" name="cmd_0528" formControlName="cmd_0528" type="text" data-id="Other aff 4 from">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d112.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0528').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0528').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0528').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0528').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0528').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0529')" placeholder="MM/DD/YYYY" ngbDatepicker #d113="ngbDatepicker" id="cmd_0529" name="cmd_0529" formControlName="cmd_0529" type="text" data-id="Other aff 4 to">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d113.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0529').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0529').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0529').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0529').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0529').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0530" name="cmd_0530" formControlName="cmd_0530" style="width: 40%" type="text" data-id="Other aff 4 add1">
                                <input class="form-control onboard " id="cmd_0531" name="cmd_0531" formControlName="cmd_0531" type="text"  style="width: 40%" data-id="Other aff 4 add1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0532" name="cmd_0532" formControlName="cmd_0532" type="text" data-id="Other aff 4 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0533">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0534" name="cmd_0534" formControlName="cmd_0534" type="text" data-id="Other aff 4 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0535" name="cmd_0535" formControlName="cmd_0535"  value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0535" name="cmd_0535" formControlName="cmd_0535" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0536" name="cmd_0536" formControlName="cmd_0536" type="text" data-id="Military Locaiton">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0537" name="cmd_0537" formControlName="cmd_0537" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0537" name="cmd_0537" formControlName="cmd_0537" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0538" name="cmd_0538" formControlName="cmd_0538" type="text" data-id="Other aff 4 leaving">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                                <input class="form-control onboard " id="cmd_0539" name="cmd_0539" formControlName="cmd_0872" type="text" data-id="Other aff 4">
                            </div>
                        </td>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">AFFILIATION DATES (MM/DD/YYYY TO MM/DD/YYYY)  </label>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0540')" placeholder="MM/DD/YYYY" ngbDatepicker #d114="ngbDatepicker" id="cmd_0540" name="cmd_0540" formControlName="cmd_0540" type="text" data-id="Other aff 4 from">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d114.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0540').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0540').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0540').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0540').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0540').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                                <input style="width:30%;" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0541')" placeholder="MM/DD/YYYY" ngbDatepicker #d115="ngbDatepicker" id="cmd_0541" name="cmd_0541" formControlName="cmd_0541" type="text" data-id="Other aff 4 to">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d115.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0541').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0541').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0541').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0541').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0541').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">ADDRESS</label>
                                <input class="form-control onboard " id="cmd_0542" name="cmd_0542" formControlName="cmd_0542" type="text" data-id="Other aff 4 add1">
                                <input class="form-control onboard " id="cmd_0543" name="cmd_0543" formControlName="cmd_0543" type="text" data-id="Other aff 4 add1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0544" name="cmd_0544" formControlName="cmd_0544" type="text" data-id="Other aff 4 city">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" name="cmd_0545" formControlName="cmd_0545">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0546" name="cmd_0546" formControlName="cmd_0546" type="text" data-id="Other aff 4 zip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FULL UNRESTRICTED PRIVILEGES?  </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0547" name="cmd_0547" formControlName="cmd_0547" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0547" name="cmd_0547" formControlName="cmd_0547" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.) </label>
                                <input class="form-control onboard " id="cmd_0548" name="cmd_0548" formControlName="cmd_0548" type="text" data-id="Military Locaiton">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WERE PRIVILEGES TEMPORARY? </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0549" name="cmd_0549" formControlName="cmd_0549" value="1">Yes</label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0549" name="cmd_0549" formControlName="cmd_0549" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">REASON FOR DISCONTINUANCE </label>
                                <input class="form-control onboard " id="cmd_0550" name="cmd_0550" formControlName="cmd_0550" type="text" data-id="Text2.5.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="14">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle"><b>Practice Location Information </b></label> - Please answer the following questions for each practice location. Use Attachment F or make copies of pages 6-7 as necessary.
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">PRACTICE LOCATION </label>of
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                TYPE OF SERVICE PROVIDED
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0551" name="cmd_0551" formControlName="cmd_0551" value="1">Solo Primary Care
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0552" name="cmd_0552" formControlName="cmd_0552" value="1">Solo Specialty Care
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0553" name="cmd_0553" formControlName="cmd_0553" value="1">Group Primary Care
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0554" name="cmd_0554" formControlName="cmd_0554" value="1">Group Single Specialty
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0555" name="cmd_0555" formControlName="cmd_0555" value="1">Group Multi-Specialty
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">GROUP NAME/PRACTICE NAME TO APPEAR IN THE DIRECTORY </label>
                                <input class="form-control onboard " id="cmd_0873" name="cmd_0873" formControlName="cmd_0873" type="text" data-id="prac2_PracticeName">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">GROUP/CORPORATE NAME AS IT APPEARS ON IRS W-9  </label>
                                <input class="form-control onboard " id="cmd_0874" name="cmd_0874" formControlName="cmd_0874" type="text" data-id="prac2_TINName">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PRACTICE LOCATION ADDRESS:<input type="checkbox" id="cmd_0556" name="cmd_0556" formControlName="cmd_0556" value="1">Primary
                                </label>
                                <input class="form-control onboard " style="width: 40%" id="cmd_0875" name="cmd_0875" formControlName="cmd_0875" type="text" data-id="prac2_Address1">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0876" name="cmd_0876" formControlName="cmd_0876" type="text" data-id="prac2_Address2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0877" name="cmd_0877" formControlName="cmd_0877" type="text" data-id="prac2_City">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop col-md-3 allMargin">
                                    <ng-select [items]="country" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0878">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0879" name="cmd_0879" formControlName="cmd_0879" type="text" data-id="prac2_ZIP">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER</label>
                                <input class="form-control onboard " id="cmd_0880" name="cmd_0880" formControlName="cmd_0880" type="text" data-id="prac2_Phone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FAX NUMBER </label>
                                <input class="form-control onboard " id="cmd_0881" name="cmd_0881" formControlName="cmd_0881"  type="text" data-id="prac2_fax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL </label>
                                <input class="form-control onboard " id="cmd_0882" name="cmd_0882" formControlName="cmd_0882" type="text" data-id="prac2_OfficeManemail">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">BACK OFFICE PHONE NUMBER </label>
                                <input class="form-control onboard " id="cmd_0883" name="cmd_0883" formControlName="cmd_0883" type="text" data-id="prac2_answering service">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">SITE-SPECIFIC MEDICAID NUMBER</label>
                                <input class="form-control onboard " id="cmd_0884" name="cmd_0884" formControlName="cmd_0884" type="text" data-id="prac2_medicaid">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">TAX ID NUMBER</label>
                                <input class="form-control onboard " id="cmd_0885" name="cmd_0885" formControlName="cmd_0885" type="text" data-id="prac2_TIN">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">GROUP NUMBER CORRESPONDING TO TAX ID NUMBER</label>
                                <input class="form-control onboard " id="cmd_0557" name="cmd_0557" formControlName="cmd_0557" type="text" data-id="prac2_">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">GROUP NAME CORRESPONDING TO TAX ID NUMBER </label>
                                <input class="form-control onboard " id="cmd_0886" name="cmd_0886" formControlName="cmd_0886" type="text" data-id="prac2_TINName">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                ARE YOU CURRENTLY PRACTICING AT THIS LOCATION?
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0558" name="cmd_0558" formControlName="cmd_0558"  value="1">Yes
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0558" name="cmd_0558" formControlName="cmd_0558"  value="1">No
                                </label>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph"> IF NO, EXPECTED START DATE? (MM/DD/YYYY)  </label>
                                <input class="form-control onboard datepicker" id="cmd_0887" (blur)="clearInputIfInvalid('cmd_0887')" name="cmd_0887" ngbDatepicker #d116="ngbDatepicker" placeholder="MM/DD/YYYY" formControlName="cmd_0887" type="text" data-id="Phys Start Date 2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d116.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0887').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0887').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0887').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0887').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0887').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                DO YOU WANT THIS LOCATION LISTED IN THE DIRECTORY?
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0559" name="cmd_0559" formControlName="cmd_0559"  value="1">Yes
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0559" name="cmd_0559" formControlName="cmd_0559"  value="0">No
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">OFFICE MANAGER OR STAFF CONTACT  </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0888" name="cmd_0888" formControlName="cmd_0888" type="text" data-id="prac2_OMFname">
                                <input style="width:40%;" class="form-control onboard " id="cmd_0889" name="cmd_0889" formControlName="cmd_0889" type="text" data-id="prac2_OMLname">
                                <div class="mb-3 selectDrop suffix dropDrownSet col-md-3">
                                    <ng-select [items]="suffix" bindLabel="name" placeholder="Select Suffix" bindValue="id" formControlName="cmd_0890">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0891" name="cmd_0891" formControlName="cmd_0891" type="text" data-id="prac2_OMPhone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FAX NUMBER </label>
                                <input style="width:30%;" class="form-control onboard " id="cmd_0892" name="cmd_0892" formControlName="cmd_0892" type="text" data-id="prac2_OMFax">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">CREDENTIALING CONTACT</label>
                                <input style="width: 20%" class="form-control onboard " placeholder="lastname" id="cmd_0893" name="cmd_0893" formControlName="cmd_0893" type="text" data-id="cplname">
                                <input style="width: 20%" class="form-control onboard " placeholder="firstname" id="cmd_0894" name="cmd_0894" formControlName="cmd_0894" type="text" data-id="cpfname">
                                <div class="mb-3 selectDrop suffix dropDrownSet col-md-3">
                                    <ng-select [items]="suffix" bindLabel="name" placeholder="Select Suffix" bindValue="id" formControlName="cmd_0895">
                                    </ng-select>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input class="form-control onboard " style="width: 40%" id="cmd_0896" name="cmd_0896" placeholder="Address" formControlName="cmd_0896" type="text" data-id="cpadd1">
                                <input class="form-control onboard " id="cmd_0897" name="cmd_0897" style="width: 40%" placeholder="Address" formControlName="cmd_0897" type="text" data-id="cpadd2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input class="form-control onboard " placeholder="City" id="cmd_0898" name="cmd_0898" formControlName="cmd_0898" type="text" data-id="cpcity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0899">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0900" name="cmd_0900" placeholder="Zip" formControlName="cmd_0900" type="text" data-id="cpzip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0901" name="cmd_0901" formControlName="cmd_0901" type="text" data-id="cptele">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FAX NUMBER </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0902" name="cmd_0902" formControlName="cmd_0902" type="text" data-id="cpfax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL  </label>
                                <input style="width:40%;" class="form-control onboard " id="cmd_0903" name="cmd_0903" formControlName="cmd_0903" type="text" data-id="cpemail">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">BILLING COMPANY'S NAME (IF APPLICABLE)</label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0904" name="cmd_0904" formControlName="cmd_0904" type="text" data-id="prac2_paytolocation">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">BILLING REPRESENTATIVE</label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0905" name="cmd_0905" formControlName="cmd_0905" type="text" data-id="prac2_paytocontact">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS </label>
                                <input class="form-control onboard " id="cmd_0906" name="cmd_0906" formControlName="cmd_0906" type="text" data-id="prac2_paytoaddress1">
                                <input class="form-control onboard " id="cmd_0907" name="cmd_0907" formControlName="cmd_0907" type="text" data-id="prac2_paytoaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input class="form-control onboard " id="cmd_0908" name="cmd_0908" formControlName="cmd_0908" type="text" data-id="prac2_paytocity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet degree col-md-6">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0909">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0910" name="cmd_0910" formControlName="cmd_0910" type="text" data-id="prac2_paytozip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0911" name="cmd_0911" formControlName="cmd_0911" type="text" data-id="prac2_paytophone">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">FAX NUMBER </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0912" name="cmd_0912" formControlName="cmd_0912" type="text" data-id="prac2_paytofax">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">E-MAIL  </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0913" name="cmd_0913" formControlName="cmd_0913" type="text" data-id="prac2_paytoemail">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DEPARTMENT NAME IF HOSPITAL-BASED </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0560" name="cmd_0560" formControlName="cmd_0560" type="text" data-id="prac2_paytodepart">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">CHECK PAYABLE TO  </label>
                                <input style="width:70%;" class="form-control onboard " id="cmd_0914" name="cmd_0914" formControlName="cmd_0914" type="text" data-id="prac2_paytoTinName">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">CAN YOU BILL ELECTRONICALLY?   </label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0561" name="cmd_0561" formControlName="cmd_0561" value="1">Yes</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0561" name="cmd_0561" formControlName="cmd_0561" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">HOURS PATIENTS ARE SEEN </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Monday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0562" name="cmd_0562" formControlName="cmd_0562" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0915" name="cmd_0915" formControlName="cmd_0915" type="text" data-id="prac2_Monday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0563" name="cmd_0563" formControlName="cmd_0563" type="text" data-id="Text2.3.6.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0564" name="cmd_0564" formControlName="cmd_0564" type="text" data-id="Text2.3.6.2">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Tuesday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0565" name="cmd_0565" formControlName="cmd_0565" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0916" name="cmd_0916" formControlName="cmd_0916" type="text" data-id="prac2_Tuesday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0566" name="cmd_0566" formControlName="cmd_0566" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0567" name="cmd_0567" formControlName="cmd_0567" type="text" data-id="Text2.3.5.2">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Wednesday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0568" name="cmd_0568" formControlName="cmd_0568" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0917" name="cmd_0917" formControlName="cmd_0917" type="text" data-id="prac2_Wednesday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0569" name="cmd_0569" formControlName="cmd_0569" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0570" name="cmd_0570" formControlName="cmd_0570" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Thursday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0571" name="cmd_0571" formControlName="cmd_0571" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0918" name="cmd_0918" formControlName="cmd_0918" type="text" data-id="prac2_thrusday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0572" name="cmd_0572" formControlName="cmd_0572" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0573" name="cmd_0573" formControlName="cmd_0573" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Friday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0574" name="cmd_0574" formControlName="cmd_0574" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0919" name="cmd_0919" formControlName="cmd_0919" type="text" data-id="prac2_Friday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0575" name="cmd_0575" formControlName="cmd_0575" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0576" name="cmd_0576" formControlName="cmd_0576" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Saturday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0577" name="cmd_0577" formControlName="cmd_0577" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0920" name="cmd_0920" formControlName="cmd_0920" type="text" data-id="prac2_Saturday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0578" name="cmd_0578" formControlName="cmd_0578" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0579" name="cmd_0579" formControlName="cmd_0579" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Sunday
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0580" name="cmd_0580" formControlName="cmd_0580" value="1">No Office Hours
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Morning<input style="width:70%;" class="form-control onboard " id="cmd_0921" name="cmd_0921" formControlName="cmd_0921" type="text" data-id="prac2_Sunday">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                Afternoon:<input style="width:70%;" class="form-control onboard " id="cmd_0581" name="cmd_0581"  formControlName="cmd_0581" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Evening:<input style="width:70%;" class="form-control onboard " id="cmd_0582" name="cmd_0582" formControlName="cmd_0582" type="text" data-id="cptele">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">DOES THIS LOCATION PROVIDE 24 HOUR/7 DAY A WEEK PHONE COVERAGE? </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0583" name="cmd_0583" formControlName="cmd_0583" value="1">Answering Service
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <input type="checkbox" id="cmd_0584" name="cmd_0584" formControlName="cmd_0584" value="1">Voice mail with instructions to call answering service
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0585" name="cmd_0585" formControlName="cmd_0585" value="1">Voice mail with other instructions
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0586" name="cmd_0586" formControlName="cmd_0586" value="1">None
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">THIS PRACTICE LOCATION ACCEPTS</label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0587" name="cmd_0587" formControlName="cmd_0587" value="1">all new patients
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0588" name="cmd_0588" formControlName="cmd_0588"  value="1">existing patients with change of payor
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0589" name="cmd_0589" formControlName="cmd_0589" value="1">new patients with referral
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0590" name="cmd_0590" formControlName="cmd_0590" value="1"> new Medicare patients
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0591" name="cmd_0591" formControlName="cmd_0591" value="1"> new Medicaid patients
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">IF NEW PATIENT ACCEPTANCE VARIES BY HEALTH PLAN, PLEASE PROVIDE EXPLANATION. </label>
                                <input class="form-control onboard " id="cmd_0592" name="cmd_0592" formControlName="cmd_0592" type="text" data-id="Text2.4.8">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">PRACTICE LIMITATIONS </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="radio" id="30" name="30" formControlName="30"  value="1">Male only
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="radio" id="30" name="30" formControlName="30" value="0">Female only
                            </div>
                            Age:
                            <input style="width:70%;" class="form-control onboard " id="cmd_0594" name="cmd_0594" formControlName="cmd_0594" type="text" data-id="Text2.5.1">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="radio" id="cmd_0593" name="cmd_0593" formControlName="cmd_0593" value="2">Other
                                <input style="width:70%;" class="form-control onboard " id="cmd_0595" name="cmd_0595" formControlName="cmd_0595" type="text" data-id="Text2.5.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">DO NURSE PRACTITIONERS, PHYSICIAN ASSISTANTS, MIDWIVES, SOCIAL WORKERS OR OTHER NON-PHYSICIAN PROVIDERS CARE FOR PATIENTS AT THIS PRACTICE
                                LOCATION? </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0596" name="cmd_0596" formControlName="cmd_0596" value="1">Yes
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3">
                                <input type="checkbox" id="cmd_0596" name="cmd_0596" formControlName="cmd_0596" value="1">No
                            </div>
                            If yes, provide the following information for each staff member:
                            <input style="width:70%;" class="form-control onboard " id="cmd_0597" name="cmd_0597" formControlName="cmd_0597" type="text" data-id="Text2.4.1">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0598" name="cmd_0598" formControlName="cmd_0598" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0599" name="cmd_0599" formControlName="cmd_0599" Wtype="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0600" name="cmd_0600" formControlName="cmd_0600" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0601" name="cmd_0601" formControlName="cmd_0601" type="text" data-id="Text2.4.9.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0602" name="cmd_0602" formControlName="cmd_0602" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0603" name="cmd_0603" formControlName="cmd_0603" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0604" name="cmd_0604" formControlName="cmd_0604" type="text" data-id="Text2.4.9.2">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0605" name="cmd_0605" formControlName="cmd_0605" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0606" name="cmd_0606" formControlName="cmd_0606" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0607" name="cmd_0607" formControlName="cmd_0607" type="text" data-id="Text2.4.9.3">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0608" name="cmd_0608" formControlName="cmd_0608" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0609" name="cmd_0609" formControlName="cmd_0609" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0610" name="cmd_0610" formControlName="cmd_0610" type="text" data-id="Text2.4.9.4.0">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0611" name="cmd_0611" formControlName="cmd_0611" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0612" name="cmd_0612" formControlName="cmd_0612" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">NAME</label>
                                <input class="form-control onboard " id="cmd_0613" name="cmd_0613" formControlName="cmd_0613" type="text" data-id="Text2.4.9.4.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL DESIGNATION </label>
                                <input class="form-control onboard " id="cmd_0614" name="cmd_0614" formControlName="cmd_0614" type="text" data-id="homecity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 sideLabelAlignment">
                                <label class="normalParagraph">STATE & LICENSE NO.</label>
                                <input class="form-control onboard " id="cmd_0615" name="cmd_0615" formControlName="cmd_0615" type="text" data-id="lname">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">NON-ENGLISH LANGUAGES SPOKEN BY HEALTH CARE PROVIDERS</label>
                                <input class="form-control onboard " style="width: 30%" id="cmd_0922" name="cmd_0922" formControlName="cmd_0922" type="text" data-id="prac2_language1">
                                <input class="form-control onboard " style="width: 30%" id="cmd_0616" name="cmd_0616" formControlName="cmd_0616" type="text" data-id="Text2.1.4">
                                <input class="form-control onboard " style="width: 30%" id="cmd_0923" name="cmd_0923" formControlName="cmd_0923" type="text" data-id="prac2_language3">
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">NON-ENGLISH LANGUAGES SPOKEN BY OFFICE PERSONNEL </label>
                                <input class="form-control onboard " id="cmd_0617" name="cmd_0617" formControlName="cmd_0617" type="text" data-id="Text2.1.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">ARE INTERPRETERS AVAILABLE? </label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0618" name="cmd_0618" formControlName="cmd_0618" value="1">Yes</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0618" name="cmd_0618" formControlName="cmd_0618" value="0">No</label>
                                No If yes, please specify languages:
                                <input class="form-control onboard " id="cmd_0619" name="cmd_0619" formControlName="cmd_0619" type="text" data-id="Text2.1.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DOES THIS PRACTICE LOCATION MEET ADA ACCESSIBILITY STANDARDS?</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0620" name="cmd_0620" formControlName="cmd_0620" value="1">Yes</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0620" name="cmd_0620" formControlName="cmd_0620" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">WHICH OF THE FOLLOWING FACILITIES ARE HANDICAPPED ACCESSIBLE? </label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0621" name="cmd_0621" formControlName="cmd_0621" value="1">Building</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0622" name="cmd_0622" formControlName="cmd_0622" value="1">Parking</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0623" name="cmd_0623" formControlName="cmd_0623" value="1">Restroom</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0624" name="cmd_0624" formControlName="cmd_0624" value="1">Other:</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DOES THIS LOCATION HAVE OTHER SERVICES FOR THE DISABLED?  </label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0625" name="cmd_0625" formControlName="cmd_0625" value="1">Text Telephony-TTY</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0626" name="cmd_0626" formControlName="cmd_0626" value="1">American Sign Language-ASL</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0627" name="cmd_0627" formControlName="cmd_0627" value="1">Mental/Physical Impairment Services </label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0628" name="cmd_0628" formControlName="cmd_0628" value="1">0ther </label>
                                <input class="form-control onboard " id="cmd_0629" name="cmd_0629" formControlName="cmd_0629" type="text" data-id="Text2.1.7">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">IS THIS LOCATION ACCESSIBLE BY PUBLIC TRANSPORTATION?  </label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0630" name="cmd_0630" formControlName="cmd_0630" value="1">Bus</label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0631" name="cmd_0631" formControlName="cmd_0631" value="1">Regional Train </label>
                                <label class="radio-inline radioStyle">
                                <input type="checkbox" id="cmd_0632" name="cmd_0632" formControlName="cmd_0632"  value="1">0ther </label>
                                <input class="form-control onboard " id="cmd_0633" name="cmd_0633" formControlName="cmd_0633" type="text" data-id="Text2.1.6">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DOES THIS LOCATION PROVIDE CHILDCARE SERVICES? </label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0634" name="cmd_0634" formControlName="cmd_0634" value="1">Yes</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0634" name="cmd_0634" formControlName="cmd_0634" value="0">No</label>
                            </div>
                        </td>
                        <td colspan="9">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="normalParagraph">DOES THIS LOCATION QUALIFY AS A MINORITY BUSINESS ENTERPRISE? </label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0635" name="cmd_0635" formControlName="cmd_0635" value="1">Yes</label>
                                <label class="radio-inline radioStyle">
                                <input type="radio" id="cmd_0635" name="cmd_0635" formControlName="cmd_0635" value="0">No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">WHO AT THIS LOCATION HAVE THE FOLLOWING CURRENT CERTIFICATIONS? (PLEASE LIST ONLY THE APPLICANT'S CERTIFICATION EXPIRATION DATES.) </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Basic Life Support
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0636" name="cmd_0636" formControlName="cmd_0636" value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0637" name="cmd_0637" formControlName="cmd_0637" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0638" name="cmd_0638" formControlName="cmd_0638" type="text" data-id="Text2.0.8.0">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Advanced Life Support in OB
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0639" name="cmd_0639" formControlName="cmd_0639" value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0640" name="cmd_0640" formControlName="cmd_0640" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0641" name="cmd_0641" formControlName="cmd_0641" type="text" data-id="Text2.0.8.1">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Advanced Trauma Life Support
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0642" name="cmd_0642" formControlName="cmd_0642" value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0643" name="cmd_0643" formControlName="cmd_0643" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0644" name="cmd_0644" formControlName="cmd_0644" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Cardio-Pulmonary Resuscitation
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0645" name="cmd_0645" formControlName="cmd_0645"  value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0646" name="cmd_0646" formControlName="cmd_0646" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0647" name="cmd_0647" formControlName="cmd_0647" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Advanced Cardiac Life Support
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0648" name="cmd_0648" formControlName="cmd_0648" value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0649" name="cmd_0649" formControlName="cmd_0649"  value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0650" name="cmd_0650" formControlName="cmd_0650" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Pediatric Advanced Life Support
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0651" name="cmd_0651" formControlName="cmd_0651"  value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0652" name="cmd_0651" formControlName="cmd_0651"  value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0653" name="cmd_0653" formControlName="cmd_0653" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Neonatal Advanced Life Support
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0654" name="cmd_0654" formControlName="cmd_0654"  value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0655" name="cmd_0655" formControlName="cmd_0655" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0656" name="cmd_0656" formControlName="cmd_0656" type="text" data-id="cptele">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                Other (please specify)
                            <input style="width:70%;" class="form-control onboard " id="cmd_0657" name="cmd_0657" formControlName="cmd_0657" type="text" data-id="Text2.0.4">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0658" name="cmd_0658" formControlName="cmd_0658" value="1">Staff
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0659" name="cmd_0659" formControlName="cmd_0659" value="1">Provider Exp:<input style="width:70%;" class="form-control onboard " id="cmd_0660" name="cmd_0660" formControlName="cmd_0660" type="text" data-id="Text2.0.5.1">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                <label class="normalParagraph">DOES THIS LOCATION PROVIDE ANY OF THE FOLLOWING SERVICES ON SITE? </label>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="radio" id="cmd_0661" name="cmd_0661" formControlName="cmd_0661" value="1">Yes
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="radio" id="cmd_0661" name="cmd_0661" formControlName="cmd_0661" value="0">No
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <input type="checkbox" id="cmd_0662" name="cmd_0161" formControlName="cmd_0161" value="1"> Laboratory Services; please list all Certificates of Participation (CLIA, AAFP, COLA, CAP, MLE): <input style="width:70%;" class="form-control onboard " id="cmd_0663" name="cmd_0663" type="text" data-id="Text2.1.0">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-8">
                                <label class="normalParagraph">DOES THIS LOCATION PROVIDE ANY OF THE FOLLOWING SERVICES ON SITE?</label>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="radio" id="cmd_0664" name="cmd_0664" formControlName="cmd_0664" value="1">Yes
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="radio" id="cmd_0664" name="cmd_0664" formControlName="cmd_0664" value="0">No
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <input type="checkbox" id="cmd_0665" name="cmd_0665" formControlName="cmd_0665" value="1"> X-ray; please list all certifications: <input style="width:70%;" class="form-control onboard " id="cmd_0721" name="cmd_0721" type="text" data-id="Text2.0.9">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" width="33.33%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">OTHER SERVICES </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0666" name="cmd_0666"  formControlName="cmd_0666" value="1"> Radiology Services</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0667" name="cmd_0667" formControlName="cmd_0667" value="1">Allergy Injections</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox"  id="cmd_0668" name="cmd_0668" formControlName="cmd_0668" value="1">Age Appropriate Immunizations </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0669" name="cmd_0669" formControlName="cmd_0669" value="1"> Osteopathic Manipulations </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0670" name="cmd_0670" formControlName="cmd_0670" value="1">  Other: </label>
                            </div>
                        </td>
                        <td colspan="4" width="33.33%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0671" name="cmd_0671" formControlName="cmd_0671" value="1">EKG</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0672" name="cmd_0672" formControlName="cmd_0672" value="1">Allergy Skin Tests </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0673" name="cmd_0673" formControlName="cmd_0673" value="1">Flexible Sigmoidoscopy </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0674" name="cmd_0674"  formControlName="cmd_0674" value="1"> IV Hydration /Treatments </label>
                            </div>
                        </td>
                        <td colspan="4" width="33.33%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0675" name="cmd_0675" formControlName="cmd_0675" value="1"> Care of Minor Lacerations </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0676" name="cmd_0676" formControlName="cmd_0676" value="1">Routine Office Gynecology</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0677" name="cmd_0677" formControlName="cmd_0677" value="1">Tympanometry/Audiometry Tests </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0678" name="cmd_0678" formControlName="cmd_0678" value="1">Cardiac Stress Tests </label>
                            </div>
                        </td>
                        <td colspan="6" width="33.33%">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0679" name="cmd_0679" formControlName="cmd_0679" value="1"> Pulmonary Function Tests</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0680" name="cmd_0680" formControlName="cmd_0680" value="1">Drawing Blood</label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0681" name="cmd_0681" formControlName="cmd_0681" value="1">Asthma Treatments  </label>
                                    <br/>
                                <label style="font-weight: bold;" class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0682" name="cmd_0682" formControlName="cmd_0682" value="1">Physical Therapies </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">PLEASE LIST ANY ADDITIONAL OFFICE PROCEDURES PROVIDED (INCLUDING SURGICAL PROCEDURES) </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <input style="width:70%;" class="form-control onboard " id="cmd_0683" name="cmd_0683" formControlName="cmd_0683" type="text" data-id="Text2.0.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="16">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">IS ANESTHESIA ADMINISTERED AT THIS PRACTICE LOCATION?</label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0684" name="cmd_0684" formControlName="cmd_0684"  value="1">Yes
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2">
                                <input type="checkbox" id="cmd_0684" name="cmd_0684" formControlName="cmd_0684"  value="0">No
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                Please specify the classes or categories:
                                <input style="width:70%;" class="form-control onboard " id="cmd_0685" name="cmd_0685" formControlName="cmd_0685" type="text" data-id="cptele">
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <label class="normalParagraph">WHO ADMINISTERS IT? </label>
                            </div>
                            <br/>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12">
                                <input style="width:70%;" class="form-control onboard " id="cmd_0686" name="cmd_0686" formControlName="cmd_0686" type="text" data-id="cptele">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0687" name="cmd_0687" formControlName="cmd_0687" value="1">Please check this box and complete and submit Attachment F if you have other practice locations. </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">INCIDENT DATE (MM/DD/YYYY)</label>
                                <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0924')" ngbDatepicker #d120="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_0924" name="cmd_0924" formControlName="cmd_0924" type="text" data-id="pending date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d120.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0924').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0924').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0924').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0924').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0924').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                                <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0925')" ngbDatepicker #d121="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_0925" name="cmd_0925" formControlName="cmd_0925" type="text" data-id="pending date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d121.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0925').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0925').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0925').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0925').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0925').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CLAIM/CASE STATUS </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0926" name="cmd_0926" formControlName="cmd_0926" type="text" data-id="Claim/case status">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL LIABILITY CARRIER INVOLVED</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0927" name="cmd_0927" formControlName="cmd_0927" type="text" data-id="Insur carrier at time of claim">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS</label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0928" name="cmd_0928" formControlName="cmd_0928" type="text" data-id="claimaddress1">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0929" name="cmd_0929" formControlName="cmd_0929" type="text" data-id="claimaddress2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input class="form-control onboard " id="cmd_0930" name="cmd_0930" formControlName="cmd_0930" type="text" data-id="claimcity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0931">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0932" name="cmd_0932" formControlName="cmd_0932" type="text" data-id="claimzip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER</label>
                                <input class="form-control onboard " id="cmd_0933" name="cmd_0933" formControlName="cmd_0933" type="text">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">POLICY NUMBER  </label>
                                <input class="form-control onboard " id="cmd_0934" name="cmd_0934" formControlName="cmd_0934" type="text">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">AMOUNT OF AWARD OR SETTLEMENT & AMOUNT PAID </label>
                                <input style="width: 30%" class="form-control onboard " id="cmd_0935" name="cmd_0935" formControlName="cmd_0935" type="text" data-id="amount settled">
                                <input style="width: 30%" class="form-control onboard " id="cmd_0936" name="cmd_0936" formControlName="cmd_0936" type="text" data-id="settle amount paid by prov">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                METHOD OF RESOLUTION <br/>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0688" name="cmd_0688" formControlName="cmd_0688" value="1">Dismissed
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0689" name="cmd_0689" formControlName="cmd_0689" value="1">Settled (with prejudice)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0690" name="cmd_0690" formControlName="cmd_0690" value="1">Settled (without prejudice)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0691" name="cmd_0691" formControlName="cmd_0691" value="1">Judgment for Defendant(s)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0692" name="cmd_0692" formControlName="cmd_0692" value="1">Judgment for Plaintiff(s)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0693" name="cmd_0693" formControlName="cmd_0693" value="1">Mediation or Arbitration
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DESCRIPTION OF ALLEGATIONS</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0937" name="cmd_0937" formControlName="cmd_0937" type="text" data-id="Specifics on the claim">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0938" name="cmd_0938" formControlName="cmd_0938" type="text" data-id="Specifics on the claim">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph"> NUMBER OF OTHER CO-DEFENDANTS </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0939" name="cmd_0939" formControlName="cmd_0939" type="text" data-id="List other defendants">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0940" name="cmd_0940" formControlName="cmd_0940" type="text" data-id="Role in event">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0941" name="cmd_0941" formControlName="cmd_0941" type="text" data-id="Cause of harm">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? <br/>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0694" name="cmd_0694" formControlName="cmd_0694" value="1">Yes
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0694" name="cmd_0694" formControlName="cmd_0694" value="0">No
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">INCIDENT DATE (MM/DD/YYYY) </label>
                                <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0695')" ngbDatepicker #d117="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_0695" name="cmd_0695" formControlName="cmd_0695" type="text" data-id="pending date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d117.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0695').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0695').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0695').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0695').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0695').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph"> DATE CLAIM WAS FILED (MM/DD/YYYY) </label>
                                <input style="width: 30%" class="form-control onboard datepicker" (blur)="clearInputIfInvalid('cmd_0696')" ngbDatepicker #d118="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_0696" name="cmd_0696" formControlName="cmd_0696" type="text" data-id="pending date">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d118.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                </div>
                                <div  *ngIf="texas.get('cmd_0696').invalid ">
                                    <small class="text-danger" *ngIf="texas.get('cmd_0696').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0696').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="texas.get('cmd_0696').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="texas.get('cmd_0696').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">CLAIM/CASE STATUS  </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0697" name="cmd_0697" formControlName="cmd_0697" type="text" data-id="Role in event">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PROFESSIONAL LIABILITY CARRIER INVOLVED </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0698" name="cmd_0698" formControlName="cmd_0698" type="text" data-id="Cause of harm">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">ADDRESS </label>
                                <input style="width: 40%" class="form-control onboard " id="cmd_0699" name="cmd_0699" formControlName="cmd_0699" type="text" data-id="Text6.4.1">
                                <input style="width: 40%" class="form-control onboard " id="cmd_0700" name="cmd_0700" formControlName="cmd_0700" type="text" data-id="Text6.4.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">CITY</label>
                                <input class="form-control onboard " id="cmd_0701" name="cmd_0701" formControlName="cmd_0701" type="text" data-id="claimcity">
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">STATE/COUNTRY</label>
                                <div class="mb-3 selectDrop dropDrownSet col-md-3">
                                    <ng-select [items]="states" bindLabel="name" placeholder="Select States" bindValue="id" formControlName="cmd_0702">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 sideLabelAlignment">
                                <label class="normalParagraph">POSTAL CODE</label>
                                <input class="form-control onboard " id="cmd_0703" name="cmd_0703" formControlName="cmd_0703" type="text" data-id="claimzip">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">PHONE NUMBER</label>
                                <input class="form-control onboard " id="cmd_0704" name="cmd_0704" formControlName="cmd_0704" type="text">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">POLICY NUMBER  </label>
                                <input class="form-control onboard " id="cmd_0705" name="cmd_0705" formControlName="cmd_0705"  type="text">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">AMOUNT OF AWARD OR SETTLEMENT & AMOUNT PAID </label>
                                <input style="width: 30%" class="form-control onboard " id="cmd_0706" name="cmd_0706" formControlName="cmd_0706" type="text" data-id="Text6.3.0">
                                <input style="width: 30%" class="form-control onboard " id="cmd_0707" name="cmd_0707" formControlName="cmd_0707" type="text" data-id="Text6.2.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                METHOD OF RESOLUTION <br/>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0708" name="cmd_0708" formControlName="cmd_0708" value="1">Dismissed
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0709" name="cmd_0709" formControlName="cmd_0709" value="1">Settled (with prejudice)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0710" name="cmd_0710" formControlName="cmd_0710" value="1">Settled (without prejudice)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0711" name="cmd_0711" formControlName="cmd_0711" value="1">Judgment for Defendant(s)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0712" name="cmd_0712" formControlName="cmd_0712" value="1">Judgment for Plaintiff(s)
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="checkbox" id="cmd_0713" name="cmd_0713" formControlName="cmd_0713" value="1">Mediation or Arbitration
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DESCRIPTION OF ALLEGATIONS</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0714" name="cmd_0714" formControlName="cmd_0714" type="text" data-id="Text6.1.3">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">WERE YOU PRIMARY DEFENDANT OR CO-DEFENDANT?</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0715" name="cmd_0715" formControlName="cmd_0715" type="text" data-id="Specifics on the claim">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph"> NUMBER OF OTHER CO-DEFENDANTS </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0716" name="cmd_0716" formControlName="cmd_0716" type="text" data-id="List other defendants">
                            </div>
                        </td>
                        <td colspan="6">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">YOUR INVOLVEMENT (ATTENDING, CONSULTING, ETC.) </label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0717" name="cmd_0717" formControlName="cmd_0717" type="text" data-id="Role in event">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                <label class="normalParagraph">DESCRIPTION OF ALLEGED INJURY TO THE PATIENT</label>
                                <input style="width: 100%" class="form-control onboard " id="cmd_0718" name="cmd_0718" formControlName="cmd_0718" type="text" data-id="Text6.1.2">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="18">
                            <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                                TO THE BEST OF YOUR KNOWLEDGE, IS THIS CASE INCLUDED IN THE NATIONAL PRACTITIONER DATA BANK (NPDB)? <br/>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719" value="1">Yes
                                </label>
                                <label class="checkbox-inline radioStyle">
                                <input type="radio" id="cmd_0719" name="cmd_0719" formControlName="cmd_0719"  value="0">No
                                </label>
                            </div>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
      </div>
</div>
