import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'newman-alliedhealth',
    templateUrl: './newman-alliedhealth.component.html',
    styleUrls: ['./newman-alliedhealth.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
  export class NewManAlliedComponent implements OnInit {
    @Input() formData;
    @Input() fieldsData;
    @Input() formId;
    @Output() onSubmit = new EventEmitter();
    @Output() onClose = new EventEmitter();
    private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(
      private fb: UntypedFormBuilder,
      private onboardService: OnboardingformsServiceService,
      private spinner: NgxSpinnerService,
      private notiService: ToasterNotiService,
      private modalService: NgbModal,
      private _sanitizer: DomSanitizer,
      private storageService: StorageService
    ) {}
    public userId: any;
    public customerId: any;
    public NewManAlliedForms: UntypedFormGroup;
    isSubmit: boolean = false;
    private phonePattern = '^[0-9]*$';
    modalOption: NgbModalOptions = {
      size: 'md',
      keyboard: false,
      backdrop: 'static'
    };

    ngOnInit(): void {
      console.log(this.formData);
      this.userId = Number(this.storageService.getItem('userId'));
      this.customerId = Number(this.storageService.getItem('customerId'));
      this.buildnewmanalliedForm();      
      this.getStatesData();
      this.getGenderData();
      this.getDegreeData();
      this.getSpecialtyData();
      this.getAlternateLicenseStateData();
      this.fieldsData && this.bindFormValue(this.fieldsData);
      this.afterSaveDataDisplay();
    } 
    //drop down arrays
    states: Array<object> = [];
    gender: Array<object> = [];
    degree: Array<object> = [];
    specialty: Array<object> = [];
    altLicenseState: Array<object> = [];
    public doits: any;
    finalImgPath: any = [];
    finalImg: any;
    esignOne: any;
    showOne: any = false;
    
    public getSpecialtyData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getDegreeData() {
      let params: {};
      this.onboardService.getDegreeData(params).subscribe(
        data => {
          if (data['success']) {
            this.degree = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
        },
        error => {
        }
      );
    }
  
    
    public getStatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.states = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    } 
    public getGenderData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getGenderData(params).subscribe(
        data => {
          if (data['success']) {
            this.gender = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public afterSaveDataDisplay() {
      let params = {
        type: 37,
        formId: this.formId
      };
      //this.spinner.show();
      this.onboardService.toGetSaveData(params).subscribe(
        data => {
          if (data['success']) {
            this.bindFormValue(data.data);
          } else {
            // this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          // this.notiService.showError(Error_Message, '', 3000)
        }
      );
    }

    public getAlternateLicenseStateData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAlternateLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.altLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public setEsign(filePath, fieldId) {
      // console.log(filePath);
      if (fieldId == 'cmd_0019') {
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showOne = true;
      }
    }

    public previewEsign(filePath, fieldId) {
      //this.spinner.show();
      let params = {
        filePath: filePath
      };
      this.onboardService.getPreviewEsign(params).subscribe(
        (data: any) => {
          if (data['success']) {
            this.setEsign(data.base64, fieldId);
          } else {
            //this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public bindFormValue(array) {
      let fieldsCtrls = {};
      for (let i = 0; i < array.length; i++) {
        fieldsCtrls[array[i].field_name] = array[i].field_value;
        if (array[i].field_name == 'cmd_0019') {
          if (array[i].field_value != '') {
            this.previewEsign(array[i].field_value, array[i].field_name);
          }
        }
   
      }
      
      this.NewManAlliedForms.patchValue(fieldsCtrls);
    }

    buildnewmanalliedForm() {
      this.NewManAlliedForms = this.fb.group({
        8: ['', [Validators.required]],
        809: [''],
        9: ['', [Validators.required]],
        cmd_001: [''],
        cmd_002: [''],
        cmd_003: [''],
        cmd_004: [null],
        cmd_005: [''],
        cmd_006: ['', [ Validators.pattern('^[0-9]*$')]],
        16: [''],
        17: [''],
        18: [''],
        20: [null],
        21: [''],
        708: ['', [ Validators.pattern('^[0-9]*$')]],
        759: ['', [ Validators.pattern(this.emailPattern)]],
        30: [null],
        31: [null, [this.dateFormatValidator]],
        32: ['', [Validators.required]],
        1634: [''],
        cmd_007: [''],
        //UG  Id's
        503: [''],
        504: [''],
        505: [''],
        506: [''],
        508: [null],
        509: [''],
        2345:['', [Validators.pattern(this.phonePattern)]],
        512: [null],
        1238:[null, [this.dateFormatValidator]],
        //Grad Id's
        479: [''],
        483: [''],
        484: [''],
        485: [''],
        487: [null],
        488: [''],
        2348:['',[Validators.pattern(this.phonePattern)]],
        480:[null],
        1300:[null, [this.dateFormatValidator]],
        //Add sch id's
        1288:[''],
        1289:[''],
        1290:[''],
        1291:[''],
        1293:[null],
        1294:[''],
        2354:['',[Validators.pattern(this.phonePattern)]],
        1628:[null],
        1296:[null, [this.dateFormatValidator]],
        //intern id's
        518: [''],
        519: [''],
        520: [''],
        522: [null],
        523: [''],
        528: [''],
        524: [null, [this.dateFormatValidator]],
        525: [null, [this.dateFormatValidator]],
        //add intern id
        2859: [''],
        2860: [''],
        2861: [''],
        2863: [null],
        2864: [''],
        2869: [''],
        2866: [null, [this.dateFormatValidator]],
        2867: [null, [this.dateFormatValidator]],
        //Residency address
        531: [''],
        1498: [''],
        533: [''],
        535: [null],
        536: [''],
        540: [''],
        537: [null, [this.dateFormatValidator]],
        538: [null, [this.dateFormatValidator]],
        //work history1
        1326: [''],
        454:  [null, [this.dateFormatValidator]],
        455:  [null, [this.dateFormatValidator]],
        445: [''],
        446: [''],
        447: [''],
        448: [''],
        450:  [null],
        451: [''],
        888: [''],
        //work history2
        1343: [''],
        465: [null, [this.dateFormatValidator]],
        466: [null, [this.dateFormatValidator]],
        456: [''],
        890: [''],
        457: [''],
        458: [''],
        459: [''],
        461: [null],
        462: [''],
        //work history3
        1339: [''],
        476: [null, [this.dateFormatValidator]],
        477: [null, [this.dateFormatValidator]],
        467: [''],
        892: [''],
        468: [''],
        469: [''],
        470: [''],
        472: [null],
        473: [''],
        //work history3
        1949: [''],
        1947: [null, [this.dateFormatValidator]],
        1948: [null, [this.dateFormatValidator]],
        1935: [''],
        1945: [''],
        1936: [''],
        1937: [''],
        1938: [''],
        1940: [null],
        1941: [''],
        cmd_008: [''],
        //Lic
        293: [''],
        299: [null],
        //Dea
        309: [''],
        312: [null],
        //certification 
        300: [''],
        907: [null],
        cmd_009: [''],
        cmd_0010: [''],
        cmd_0011: [''],
        //Ref1
        359: [''],
        368: [null],
        360: [''],
        361: [''],
        362: [''],
        364: [null],
        365: [''],
        369: ['', [ Validators.pattern('^[0-9]*$')]],
        370: [''],
        4242:[''],
        371: ['', [ Validators.pattern(this.emailPattern)]],
        //Ref2
        372: [''],
        380: [null],
        373: [''],
        374: [''],
        375: [''],
        377: [null],
        378: [''],
        381: ['', [ Validators.pattern('^[0-9]*$')]],
        382: [''],
        4243:[''],
        383: ['', [ Validators.pattern(this.emailPattern)]],
        //Ref3
        384: [''],
        393: [null],
        385: [''],
        386: [''],
        387: [''],
        389: [null],
        390: [''],
        394: ['', [ Validators.pattern('^[0-9]*$')]],
        395: [''],
        4244:[''],
        396: ['', [ Validators.pattern(this.emailPattern)]],
         //Ref3
         1681: [''],
         1691: [null],
         1682: [''],
         1683: [''],
         1684: [''],
         1686: [null],
         1687: [''],
         1692: ['', [ Validators.pattern('^[0-9]*$')]],
         1693: [''],
         4245:[''],
         1694: ['', [ Validators.pattern(this.emailPattern)]],
         //MLP1
         345: [''],
         901: [''],
         902: [''],
         903: [''],
         905: [null], 
         906: [''],
         //prev mlp
         1242:[''],
         1244:[''],
         1245:[''],
         1246:[''],
         1248:[null],
         1249:[''],
         1256:[''],
         1252:[null, [this.dateFormatValidator]],
         1253:[null, [this.dateFormatValidator]],
         //prev1
         add_1_1242:[''],
         add_1_1256:[''],
         add_1_1244:[''],
         add_1_1245:[''],
         add_1_1246:[''],
         add_1_1248:[null],
         add_1_1249:[''],
         add_1_1252:[null, [this.dateFormatValidator]],
         add_1_1253:[null, [this.dateFormatValidator]],
         ///prev2
         add_2_1242:[''],
         add_2_1256:[''],
         add_2_1244:[''],
         add_2_1245:[''],
         add_2_1246:[''],
         add_2_1248:[null],
         add_2_1249:[''],
         add_2_1252:[null, [this.dateFormatValidator]],
         add_2_1253:[null, [this.dateFormatValidator]],
         cmd_0012: [''],
         cmd_0013: [''],
         cmd_0014: [''],
         cmd_0015: [''],
         cmd_0016: [''],
         cmd_0017: [''],
         cmd_0018: [''],
         cmd_0019:  ['', [Validators.required]],
         cmd_0020:[null, [Validators.required,this.dateFormatValidator]],
        
      });
      console.log(this.NewManAlliedForms);

    } 

    setErrorControl(name){
      this.NewManAlliedForms.controls[name].setErrors({ errors: true });
    }
    removeErrorControl(name){
      this.NewManAlliedForms.controls[name].setErrors(null)
    }
    
    public esignOpenOne() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_0019: this.finalImgPath
        };
        this.NewManAlliedForms.controls['cmd_0019'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showOne = true;
      });
    }
    doit(event) {
      this.doits = event.target.value;
      if (this.doits == 0) {
        this.NewManAlliedForms.controls['cmd_0018'].setErrors(null);
      }
      else if (this.doits == 1) {
        this.NewManAlliedForms.controls['cmd_0018'].setErrors({ errors: true });
      }
    }
    

    saveForm(type) {
      this.isSubmit = true;      
      
      console.log(this.NewManAlliedForms);
      if (type == 'submit') {
        if (this.NewManAlliedForms.invalid) {
          this.notiService.showError(
            'Please Fill the all mandatory fields to continue',
            '',
            3000
          );
          for (const key in this.NewManAlliedForms.controls) {
            if (this.NewManAlliedForms.controls.hasOwnProperty(key)) {
              const element = this.NewManAlliedForms.controls[key];
              if(element.status=="INVALID"){
              console.log(key + " " + element.status)
              }
            }
          }
        } else {
          if (this.NewManAlliedForms.controls['cmd_0019'].value == null || this.NewManAlliedForms.controls['cmd_0019'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          let data = {
            formType: type,
            formValues: this.NewManAlliedForms.value
          };
          console.log("Form has been submitted successfully");
          this.onSubmit.emit(data);
          console.log(this.NewManAlliedForms);
  
        }
      } else {
        let data = {
          formType: type,
          formValues: this.NewManAlliedForms.value,
          formId: 37
        };
        this.onSubmit.emit(data);
        this.onClose.emit('close modal');
      }
    }
    close() {
      this.onClose.emit('close modal');
    }

    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.NewManAlliedForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
      
      
    }
    clearInputIfInvalidMandi(controlName: string) {
      const control = this.NewManAlliedForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
  
      }}
  
    dateFormatValidator(control: FormControl): ValidationErrors | null {
      const dateString = control.value;
      const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    
      if (dateString && !dateString.match(dateFormat)) {
        return { 'invalidDateFormat': true };
      }
    
  
  
      if (dateString) {
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0]);
        const day = parseInt(dateParts[1]);
        const year = parseInt(dateParts[2]);
    
        // Check if any of the date components are zero or month is not between 1 and 12
        
    
        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
        
        if (month < 1 || month > 12 || day === 0 || year === 0) {
          return { 'invalidMonth': true };
        }
        if (month === 1 || month > 2) {
          if (day > ListofDays[month - 1]) {
            return { 'invalidDateRange': true };
          }
        } else if (month === 2) {
          const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
          if (!leapYear && day >= 29) {
            return { 'invalidDateRange': true };
          }
           else if (leapYear && day > 29) {
            return { 'invalidDateRange': true };
          }
        }
        if (year < 1900 || year > 2099) {
          return { 'invalidDateyear': true };
        }
        
       
      }
    
      return null;
    }
















      
      
     
     
         
  }