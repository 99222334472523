<div id="anneStyle">
  <div class="cotainer-fluid mx-1">
    <div class="header">
      <div class="imgStyle">
        <img
          class="imgStyletwo"
          src="./assets/images/anne_img.png"
          alt="Credential my Doc"
        />
      </div>
    </div>
    <div class="section">
      <form [formGroup]="annederm">
        <table border="1" width="100%">
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle ">Legal First Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_134  "
                  id="8"
                  name="8"
                  formControlName="8"
                  [class.is-invalid]="
                    (annederm.controls['8'].touched &&
                      annederm.controls['8'].invalid) ||
                    (annederm.controls['8'].invalid && isSubmit)
                  "
                  type="text"
                  placeholder="Provider First Name"
                  data-id="managing_contact_name"
                  (change)="onChangeFirstName($event)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['8'].touched &&
                    annederm.controls['8'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['8'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Legal Last Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="9"
                  name="9"
                  formControlName="9"
                  [class.is-invalid]="
                    (annederm.controls['9'].touched &&
                      annederm.controls['9'].invalid) ||
                    (annederm.controls['9'].invalid && isSubmit)
                  "
                  type="text"
                  placeholder="Provider Last Name"
                  data-id="managing_contact_name123"
                  (change)="onChangeLastName($event)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['9'].touched &&
                    annederm.controls['9'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['9'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Preferred Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="cmd_100001"
                  name="cmd_100001"
                  [class.is-invalid]="
                    (annederm.controls['cmd_100001'].touched &&
                      annederm.controls['cmd_100001'].invalid) ||
                    (annederm.controls['cmd_100001'].invalid && isSubmit)
                  "
                  formControlName="cmd_100001"
                  type="text"
                  placeholder="Preferred Name"
                  data-id="managing_contact_last_name"
                  
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100001'].touched &&
                    annederm.controls['cmd_100001'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100001'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Cell Phone #:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="709"
                  name="709"
                  [class.is-invalid]="
                    (annederm.controls['709'].touched &&
                      annederm.controls['709'].invalid) ||
                    (annederm.controls['709'].invalid && isSubmit)
                  "
                  formControlName="709"
                  type="text"
                  placeholder="Cell Phone"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['709'].touched &&
                    annederm.controls['709'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['709'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Personal Email Address:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard"
                  id="759"
                  name="759"
                  formControlName="759"
                  [class.is-invalid]="
                    (annederm.controls['759'].touched &&
                      annederm.controls['759'].invalid) ||
                    (annederm.controls['759'].invalid && isSubmit)
                  "
                  type="text"
                  placeholder="Personal Email Address"
                  data-id="email"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['759'].touched &&
                    annederm.controls['759'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['759'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Preferred method of being contacted for credentialing
                  purposes:
                  <label class="checkbox-inline radioStyle" >
                    <input
                      type="checkbox"
                      id="cmd_100000010"
                      name="cmd_100000010"
                      formControlName="cmd_100000010"
                      value="1"
                    />
                    Email
                  </label>
                  <label class="checkbox-inline radioStyle ms-2">
                    <input
                      type="checkbox"
                      id="cmd_100000011"
                      name="cmd_100000011"
                      formControlName="cmd_100000011"
                      value="1"
                    />
                    Text Message
                  </label>
                  <label class="checkbox-inline radioStyle ms-2">
                    <input
                      type="checkbox"
                      id="cmd_100000012"
                      name="cmd_100000012"
                      formControlName="cmd_100000012"
                      value="1"
                    />
                    Phone Call
                  </label></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <p><b>PERSONAL DATA</b></p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="16"
                  name="16"
                  [class.is-invalid]="
                    (annederm.controls['16'].touched &&
                      annederm.controls['16'].invalid) ||
                    (annederm.controls['16'].invalid && isSubmit)
                  "
                  formControlName="16"
                  type="text"
                  placeholder="Home add 1"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['16'].touched &&
                    annederm.controls['16'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['16'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="17"
                  name="17"
                  formControlName="17"
                  type="text"
                  placeholder="Home add 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="18"
                  name="18"
                  [class.is-invalid]="
                    (annederm.controls['18'].touched &&
                      annederm.controls['18'].invalid) ||
                    (annederm.controls['18'].invalid && isSubmit)
                  "
                  formControlName="18"
                  type="text"
                  placeholder="Home City"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['18'].touched &&
                    annederm.controls['18'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['18'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select Home State"
                    bindValue="id"
                    formControlName="20"
                  >
                  </ng-select>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date of Birth:</label>
              </div>
              <div class="col-md-4">
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker"
                    id="31"
                    name="31"
                    [class.is-invalid]="
                      (annederm.controls['31'].touched &&
                        annederm.controls['31'].invalid) ||
                      (annederm.controls['31'].invalid && isSubmit)
                    "
                    formControlName="31"
                    type="text"
                    placeholder="Date of Birth"
                    data-id="managing_contact_name"
                    ngbDatepicker
                    #ad01="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('31')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar calenderWidth"
                      (click)="ad01.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>

                <div class="mandatory" *ngIf="(annederm.controls['31'].invalid&&annederm.controls['31'].touched) && (annederm.controls['31'].value==''|| annederm.controls['31'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="annederm.get('31').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="annederm.get('31').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('31').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="annederm.get('31').errors?.invalidDateRange">
                    date is out of range
                    </small>
              </div>

              <div class="col-md-2">
                <label class="normalParagraphStyle">Social Security #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="32"
                  name="32"
                  [class.is-invalid]="
                    (annederm.controls['32'].touched &&
                      annederm.controls['32'].invalid) ||
                    (annederm.controls['32'].invalid && isSubmit)
                  "
                  formControlName="32"
                  type="text"
                  placeholder="Social Security"
                  data-id="managing_contact_last_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['32'].touched &&
                    annederm.controls['32'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['32'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Place of Birth:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="810"
                  name="810"
                  [class.is-invalid]="
                    (annederm.controls['810'].touched &&
                      annederm.controls['810'].invalid) ||
                    (annederm.controls['810'].invalid && isSubmit)
                  "
                  formControlName="810"
                  type="text"
                  placeholder="City of Birth"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['810'].touched &&
                    annederm.controls['810'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['810'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
             
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="289"
                  >
                  </ng-select>
               
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Marital Status:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1680"
                  name="1680"
                  formControlName="1680"
                  type="text"
                  placeholder="Marital Status"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Maiden Name (If Applicable):</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="278"
                  name="278"
                  formControlName="278"
                  type="text"
                  placeholder="PRIOR NAME"
                  data-id="managing_contact_last_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <p><b>PROFESSIONAL DATA</b></p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">NPI #:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1 "
                  id="1634"
                  name="1634"
                  [class.is-invalid]="
                    (annederm.controls['1634'].touched &&
                      annederm.controls['1634'].invalid) ||
                    (annederm.controls['1634'].invalid && isSubmit)
                  "
                  formControlName="1634"
                  type="text"
                  placeholder="NPI"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['1634'].touched &&
                    annederm.controls['1634'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['1634'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Username:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="1635"
                  name="1635"
                  formControlName="1635"
                  type="text"
                  placeholder="NPI Username"
                  data-id="managing_contact_last_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Password:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="1636"
                  name="1636"
                  formControlName="1636"
                  type="text"
                  placeholder="NPI Password"
                  data-id="managing_contact_last_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">CAQH #:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="333"
                  name="333"
                  [class.is-invalid]="
                    (annederm.controls['333'].touched &&
                      annederm.controls['333'].invalid) ||
                    (annederm.controls['333'].invalid && isSubmit)
                  "
                  formControlName="333"
                  type="text"
                  placeholder="CAQH"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['333'].touched &&
                    annederm.controls['333'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['333'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Username:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="334"
                  name="334"
                  formControlName="334"
                  type="text"
                  placeholder="CAQH Username"
                  data-id="managing_contact_last_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Password:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="335"
                  name="335"
                  formControlName="335"
                  type="text"
                  placeholder="CAQH Password"
                  data-id="managing_contact_last_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Medicaid #:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="812"
                  name="812"
                  formControlName="812"
                  type="text"
                  placeholder="Medicaid"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Username:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="cmd_100002"
                  name="cmd_100002"
                  formControlName="cmd_100002"
                  type="text"
                  placeholder="Medicaid Username"
                  data-id="managing_contact_last_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Password:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_last_name_1"
                  id="2111"
                  name="2111"
                  formControlName="2111"
                  type="text"
                  placeholder="Medicaid Password"
                  data-id="managing_contact_last_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <p>
                  If you do not know your login for NPI or CAQH please follow
                  the applicable links below to recover that information:
                </p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <p>
                  NPI -
                  <a href="https://nppes.cms.hhs.gov/IAWeb/login.do"
                    >https://nppes.cms.hhs.gov/IAWeb/login.d</a
                  >
                </p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <p>
                  CAQH -
                  <a href="https://proview.caqh.org/Login"
                    >https://proview.caqh.org/Login</a
                  >
                </p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <p>
                  <b>LICENSURE</b>
                  <span style="color: red;"
                    >(Please email copies of all licenses and certifications
                    listed below)</span
                  >
                </p>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle"><b>Specialty:</b></label>
              </div>
              <div class="mb-3 selectDropSpec suffix dropDrownSet col-md-4">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Speciality"
                    bindValue="id"
                    formControlName="284"
                  >
                  </ng-select>
                
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Board Certified?:</label>
              </div>
              <div class="col-md-8">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100003"
                    name="cmd_100003"
                    formControlName="cmd_100003"
                    value="1"
                  />Yes
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100003"
                    name="cmd_100003"
                    formControlName="cmd_100003"
                    value="0"
                  />No</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Date Certified (mm/dd/yyyy):</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="557"
                  name="557"
                  formControlName="557"
                  type="text"
                  placeholder="PRIM  BOARD ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad02="ngbDatepicker"
                  (blur)="clearInputIfInvalid('557')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad02.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              
              
              </div>
              <div  *ngIf="annederm.get('557').invalid ">
                <small class="text-danger" *ngIf="annederm.get('557').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('557').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('557').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('557').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
             
          
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Certificate Number:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1169"
                  name="1169"
                  formControlName="1169"
                  type="text"
                  placeholder="PRIM BOARD CERT NO"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Does your certification have an expiration date?:</label
                >
              </div>
              <div class="col-md-4">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100004"
                    name="cmd_100004"
                    formControlName="cmd_100004"
                    value="1"
                  />Yes
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100004"
                    name="cmd_100004"
                    formControlName="cmd_100004"
                    value="0"
                  />No</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Expiration date (mm/dd/yyyy):</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="560"
                  name="560"
                  formControlName="560"
                  type="text"
                  placeholder="PRIM  BOARD  EXPDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad03="ngbDatepicker"
                  (blur)="clearInputIfInvalid('560')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad03.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"> </i>
                  </button>
                </div>
              </div>
              <div  *ngIf="annederm.get('560').invalid ">
                <small class="text-danger" *ngIf="annederm.get('560').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('560').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('560').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('560').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  ><b>Subspecialties:</b></label
                >
              </div>
             
                <div class="mb-3 selectDropSpec suffix dropDrownSet col-md-4">
                  <ng-select
                    [items]="specialtySecondary"
                    bindLabel="name"
                    placeholder="Select Sec Speciality"
                    bindValue="id"
                    formControlName="962"
                  >
                  </ng-select>
               
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Board Certified?:</label>
              </div>
              <div class="col-md-8">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100005"
                    name="cmd_100005"
                    formControlName="cmd_100005"
                    value="1"
                  />Yes
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100005"
                    name="cmd_100005"
                    formControlName="cmd_100005"
                    value="0"
                  />No</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Certificate:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="562"
                  name="562"
                  formControlName="562"
                  type="text"
                  placeholder="SECBOARD CERT NO"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Date Certified (mm/dd/yyyy):</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="564"
                  name="564"
                  formControlName="564"
                  type="text"
                  placeholder="SEC BOARD  ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad04="ngbDatepicker"
                  (blur)="clearInputIfInvalid('564')"
                  
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="ad04.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="annederm.get('564').invalid ">
                <small class="text-danger" *ngIf="annederm.get('564').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('564').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('564').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('564').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  ><b>Professional License(s):</b></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1">
                <label class="normalParagraphStyle">#:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="293"
                  name="293"
                  formControlName="293"
                  type="text"
                  placeholder="LIC NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">State:</label>
              </div>
              
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="299"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">Initial Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  placement="bottom"
                  class="form-control onboard datepicker"
                  id="294"
                  name="294"
                  formControlName="294"
                  type="text"
                  placeholder="LIC  ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad06="ngbDatepicker"
                  (blur)="clearInputIfInvalid('294')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="ad06.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('294').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('294').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('294').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('294').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('294').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>

              <div class="col-md-1">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-2 input-group ">
                <input
                  placement="bottom"
                  class="form-control onboard datepicker"
                  id="295"
                  name="295"
                  formControlName="295"
                  type="text"
                  placeholder="LIC  EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad05="ngbDatepicker"
                  (blur)="clearInputIfInvalid('295')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad05.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('295').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('295').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('295').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('295').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('295').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1">
                <label class="normalParagraphStyle">#:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="300"
                  name="300"
                  formControlName="300"
                  type="text"
                  placeholder="ALT LIC  NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">State:</label>
              </div>
            
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="altLicenseState"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="907"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">Initial Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="307"
                  name="307"
                  formControlName="307"
                  type="text"
                  placeholder="ALT LIC  ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad07="ngbDatepicker"
                  (blur)="clearInputIfInvalid('307')"
                  
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="ad07.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('307').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('307').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('307').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('307').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('307').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>

              <div class="col-md-1">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="308"
                  name="308"
                  formControlName="308"
                  type="text"
                  placeholder="ALT LIC  EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad08="ngbDatepicker"
                  (blur)="clearInputIfInvalid('308')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar "
                    (click)="ad08.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('308').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('308').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('308').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('308').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('308').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
           
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1">
                <label class="normalParagraphStyle">#:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="999"
                  name="999"
                  formControlName="999"
                  type="text"
                  placeholder="ADD LIC  NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">State:</label>
              </div>
             
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1010"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">Initial Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="1003"
                  name="1003"
                  formControlName="1003"
                  type="text"
                  placeholder="ADD LIC  ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad09="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1003')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad09.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('1003').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1003').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1003').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1003').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1003').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>

              </div>

              <div class="col-md-1">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="1004"
                  name="1004"
                  formControlName="1004"
                  type="text"
                  placeholder="ADD LIC  EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad10="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1004')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad10.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('1004').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1004').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1004').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1004').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1004').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1">
                <label class="normalParagraphStyle">#:</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1196"
                  name="1196"
                  formControlName="1196"
                  type="text"
                  placeholder="PREV LIC NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">State:</label>
              </div>
            
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1197"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">Initial Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard "
                  id="1198"
                  name="1198"
                  formControlName="1198"
                  type="text"
                  placeholder="PREV LIC  ISSUE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad11="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1198')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad11.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('1198').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1198').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1198').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1198').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1198').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>

              <div class="col-md-1">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard "
                  id="1199"
                  name="1199"
                  formControlName="1199"
                  type="text"
                  placeholder="PREV LIC  EXPDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad12="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1199')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad12.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                <div  *ngIf="annederm.get('1199').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1199').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1199').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1199').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1199').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Other(s):</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100006"
                  name="cmd_100006"
                  formControlName="cmd_100006"
                  type="text"
                  placeholder="Other(s)"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b>Federal DEA License#:</b></label
                >
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="309"
                  name="309"
                  formControlName="309"
                  type="text"
                  placeholder="DEA NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b>Expiration Date (mm/dd/yyyy):</b></label
                >
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard "
                  id="311"
                  name="311"
                  formControlName="311"
                  type="text"
                  placeholder="DEA EXP"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad13="ngbDatepicker"
                  (blur)="clearInputIfInvalid('311')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad13.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
             
              </div>
              <div class="col-md-9"> </div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('311').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('311').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('311').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('311').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('311').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>  
              </div>
              
            </div>
            
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b>State CDS License #:</b></label
                >
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="337"
                  name="337"
                  formControlName="337"
                  type="text"
                  placeholder="CDS NO"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b>Expiration Date (mm/dd/yyyy):</b></label
                >
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="339"
                  name="339"
                  formControlName="339"
                  type="text"
                  placeholder="CDS EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad14="ngbDatepicker"
                  (blur)="clearInputIfInvalid('339')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad14.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
               
              </div>
              <div class="col-md-9"> </div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('339').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('339').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('339').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('339').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('339').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle me-2"
                  ><b
                    >Have you registered with your primary practice state’s
                    Prescription Drug Monitoring Program?
                  </b></label
                >
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100007"
                    name="cmd_100007"
                    formControlName="cmd_100007"
                    value="1"
                  />Yes
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100007"
                    name="cmd_100007"
                    formControlName="cmd_100007"
                    value="0"
                  />No</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-5">
                <label class="normalParagraphStyle"
                  ><b
                    >If a Maryland provider, please provide your confirmation
                    code:</b
                  ></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100008"
                  name="cmd_100008"
                  formControlName="cmd_100008"
                  type="text"
                  placeholder=""
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  ><b>EDUCATION: </b
                  ><u style="color: red"
                    >(Please email copies of all certificates and diplomas)</u
                  ></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Undergraduate School:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="503"
                  name="503"
                  formControlName="503"
                  type="text"
                  placeholder="UG SCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="504"
                  name="504"
                  formControlName="504"
                  type="text"
                  placeholder="UG SCHOOADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="505"
                  name="505"
                  formControlName="505"
                  type="text"
                  placeholder="UG SCHOOADD2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="506"
                  name="506"
                  formControlName="506"
                  type="text"
                  placeholder="UG SCHOOL CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="508"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="509"
                  name="509"
                  formControlName="509"
                  type="text"
                  placeholder="UG SCHOOL  ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2345"
                  name="2345"
                  formControlName="2345"
                  type="text"
                  placeholder="UG SCHOOL PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year):</label
                >
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="510"
                  name="510"
                  formControlName="510"
                  type="text"
                  placeholder="UG SCHOOL  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad15="ngbDatepicker"
                  (blur)="clearInputIfInvalid('510')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad15.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="1238"
                  name="1238"
                  formControlName="1238"
                  type="text"
                  placeholder="UG SCHOOL  ENDDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad16="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1238')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad16.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
               
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('510').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('510').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('510').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('510').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('510').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('1238').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1238').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1238').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1238').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1238').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Degree Earned:</label>
              </div>
              <div class="mb-3 selectDropDegree suffix dropDrownSet col-md-3">
                
                <ng-select
                  [items]="degree"
                  formControlName="512"
                  bindLabel="name"
                  placeholder="Select Degree"
                  bindValue="id"
                  formControlName="512"
                >
                </ng-select>
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Other Graduate Training:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="479"
                  name="479"
                  formControlName="479"
                  type="text"
                  placeholder="GRADSCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="483"
                  name="483"
                  formControlName="483"
                  type="text"
                  placeholder="GRADSCHOOL ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="484"
                  name="484"
                  formControlName="484"
                  type="text"
                  placeholder="GRADSCHOOL ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="485"
                  name="485"
                  formControlName="485"
                  type="text"
                  placeholder="GRADSCHOOL  CITY"
                  data-id="managing_contact_name"
                />
              </div>
                
                <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="487"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="488"
                  name="488"
                  type="text"
                  placeholder="GRADSCHOOLZIP"
                  formControlName="488"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2348"
                  name="2348"
                  formControlName="2348"
                  type="text"
                  placeholder="GRADSCHOOL PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year):</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="481"
                  name="481"
                  formControlName="481"
                  type="text"
                  placeholder="GRADSCHOOL  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad17="ngbDatepicker"
                  (blur)="clearInputIfInvalid('481')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad17.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
               
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="1300"
                  name="1300"
                  formControlName="1300"
                  type="text"
                  placeholder="GRADSCHOOL  END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad18="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1300')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad18.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
           <div class="col-md-3"></div>
           <div class="col-md-4">
            <div  *ngIf="annederm.get('481').invalid ">
              <small class="text-danger" *ngIf="annederm.get('481').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="annederm.get('481').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="annederm.get('481').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="annederm.get('481').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
           </div>
           <div class="col-md-4">
            <div  *ngIf="annederm.get('1300').invalid ">
              <small class="text-danger" *ngIf="annederm.get('1300').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="annederm.get('1300').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="annederm.get('1300').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="annederm.get('1300').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
           </div>
  
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Degree Earned:</label>
              </div>
              <div class="mb-3 selectDropDegree suffix dropDrownSet col-md-3">
                <ng-select
                  [items]="degree"
                  bindLabel="name"
                  placeholder="Select Degree"
                  bindValue="id"
                  formControlName="480"
                >
                </ng-select>
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Medical School:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="490"
                  name="490"
                  formControlName="490"
                  type="text"
                  placeholder="MED SCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="491"
                  name="491"
                  formControlName="491"
                  type="text"
                  placeholder="MED SCHOOL ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="492"
                  name="492"
                  formControlName="492"
                  type="text"
                  placeholder="MED SCHOOL ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="493"
                  name="493"
                  formControlName="493"
                  type="text"
                  placeholder="MED SCHOOL CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="495"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="496"
                  name="496"
                  formControlName="496"
                  type="text"
                  placeholder="MED SCHOOL  ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2351"
                  name="2351"
                  formControlName="2351"
                  type="text"
                  placeholder="MED SCHOOL   PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year):</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="497"
                  name="497"
                  formControlName="497"
                  type="text"
                  placeholder="MED SCHOOL   START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad19="ngbDatepicker"
                  (blur)="clearInputIfInvalid('497')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad19.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="498"
                  name="498"
                  formControlName="498"
                  type="text"
                  placeholder="MED SCHOOL   ENDDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad20="ngbDatepicker"
                  (blur)="clearInputIfInvalid('498')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad20.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('497').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('497').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('497').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('497').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('497').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('498').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('498').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('498').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('498').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('498').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Degree Earned:</label>
              </div>
              <div class="mb-3 selectDropDegree suffix dropDrownSet col-md-3">
                <ng-select
                  [items]="degree"
                  bindLabel="name"
                  placeholder="Select Degree"
                  bindValue="id"
                  formControlName="500"
                >
                </ng-select>
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Internship:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="517"
                  name="517"
                  formControlName="517"
                  type="text"
                  placeholder="INTERN SCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="518"
                  name="518"
                  formControlName="518"
                  type="text"
                  placeholder="INTERN ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="519"
                  name="519"
                  formControlName="519"
                  type="text"
                  placeholder="INTERN ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="520"
                  name="520"
                  formControlName="520"
                  type="text"
                  placeholder="INTERN SCHOOLCITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="522"
                  >
                  </ng-select>
              
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="523"
                  name="523"
                  formControlName="523"
                  type="text"
                  placeholder="INTERN ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1318"
                  name="1318"
                  formControlName="1318"
                  type="text"
                  placeholder="INTERN PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Chief of Staff:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="528"
                  name="528"
                  formControlName="528"
                  type="text"
                  placeholder="INTERN CHIEF"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Type of Internship:</label>
              </div>
              
                <div class="mb-3 selectDropSpec suffix dropDrownSet col-md-4">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Speciality"
                    bindValue="id"
                    formControlName="527"
                  >
                  </ng-select>
              
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year):</label
                >
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="524"
                  name="524"
                  formControlName="524"
                  type="text"
                  placeholder="INTERN SCHOOL START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad21="ngbDatepicker"
                  (blur)="clearInputIfInvalid('524')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad21.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="525"
                  name="525"
                  formControlName="525"
                  type="text"
                  placeholder="INTERN SCHOOL ENDDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad22="ngbDatepicker"
                  (blur)="clearInputIfInvalid('525')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad22.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
               
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('524').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('524').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('524').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('524').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('524').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('525').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('525').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('525').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('525').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('525').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Residency:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="529"
                  name="529"
                  formControlName="529"
                  type="text"
                  placeholder="RES SCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="531"
                  name="531"
                  formControlName="531"
                  type="text"
                  placeholder="RES SCHOOL ADD1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1498"
                  name="1498"
                  formControlName="1498"
                  type="text"
                  placeholder="RES SCHOOL ADD2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="533"
                  name="533"
                  formControlName="533"
                  type="text"
                  placeholder="RES SCHOOLCITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="535"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="536"
                  name="536"
                  formControlName="536"
                  type="text"
                  placeholder="RES SCHOOL ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1316"
                  name="1316"
                  formControlName="1316"
                  type="text"
                  placeholder="RES SCHOOL   PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Chief of Service:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="540"
                  name="540"
                  formControlName="540"
                  type="text"
                  placeholder="RES SCHOOL    CHIEF"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Type of Residency:</label>
              </div>
              
                <div class="mb-3 selectDropSpec suffix dropDrownSet col-md-4">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Speciality"
                    bindValue="id"
                    formControlName="530"
                  >
                  </ng-select>
                
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year)</label
                >
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="537"
                  name="537"
                  formControlName="537"
                  type="text"
                  placeholder="RES SCHOOL START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad23="ngbDatepicker"
                  (blur)="clearInputIfInvalid('537')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad23.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
                
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="538"
                  name="538"
                  formControlName="538"
                  type="text"
                  placeholder="RES SCHOOL ENDDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad24="ngbDatepicker"
                  (blur)="clearInputIfInvalid('538')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad24.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
               
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('537').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('537').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('537').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('537').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('537').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('538').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('538').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('538').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('538').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('538').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div> 
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  ><b><u>Fellowship:</u></b></label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="542"
                  name="542"
                  formControlName="542"
                  type="text"
                  placeholder="FELLOWSHIP SCHOOL NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="544"
                  name="544"
                  formControlName="544"
                  type="text"
                  placeholder="FELLOWSHIP ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="545"
                  name="545"
                  formControlName="545"
                  type="text"
                  placeholder="FELLOWSHIP ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="546"
                  name="546"
                  formControlName="546"
                  type="text"
                  placeholder="FELLOWSHIP SCHOOL CITY"
                  data-id="managing_contact_name"
                />
              </div>
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="548"
                  >
                  </ng-select>
                </div>
              
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="549"
                  name="549"
                  formControlName="549"
                  type="text"
                  placeholder="FELLOWSHIP ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone No:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1322"
                  name="1322"
                  formControlName="1322"
                  type="text"
                  placeholder="FELLOWSHIP PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Chief of Service:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="553"
                  name="553"
                  formControlName="553"
                  type="text"
                  placeholder="FELLOWSHIP CHIEF"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Type of Fellowship:</label>
              </div>
             
                <div class="mb-3 selectDropSpec suffix dropDrownSet col-md-4">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Speciality"
                    bindValue="id"
                    formControlName="543"
                  >
                  </ng-select>
               
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Dates Attended (Month/Year):</label
                >
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="550"
                  name="550"
                  formControlName="550"
                  type="text"
                  placeholder="FELLOWSHIP SCHOOL  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad25="ngbDatepicker"
                  (blur)="clearInputIfInvalid('550')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad25.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="551"
                  name="551"
                  formControlName="551"
                  type="text"
                  placeholder="FELLOWSHIP SCHOOL   ENDDATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad26="ngbDatepicker"
                  (blur)="clearInputIfInvalid('551')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad26.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('550').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('550').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('550').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('550').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('550').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('551').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('551').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('551').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('551').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('551').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <br /><br />

          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >List any foreign languages you are fluent in:</label
                >
              </div>
              <div class="col-md-3">
                <div class="mb-3 selectDrop  dropDrownSet">
                  <ng-select
                    [items]="languages"
                    bindLabel="name"
                    placeholder="Select language1"
                    bindValue="id"
                    formControlName="277"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3 selectDrop  dropDrownSet">
                  <ng-select
                    [items]="secondaryLanguages"
                    bindLabel="name"
                    placeholder="Select language2"
                    bindValue="id"
                    formControlName="1535"
                  >
                  </ng-select>
                </div>
               </div>
              <div class="col-md-3">
                <div class="mb-3 selectDrop  dropDrownSet">
                  <ng-select
                    [items]="tertiaryLanguages"
                    bindLabel="name"
                    placeholder="Select language3"
                    bindValue="id"
                    formControlName="1536"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </tr>
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  ><b>PROFESSIONAL REFERENCES: </b
                  ><u style="color: red;"
                    >(Of the same professional level or higher, preferably in
                    the same field)</u
                  ></label
                >
              </div>
            </div>
          </tr>
          <!-- 3rd Page -->
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="359"
                  name="359"
                  [class.is-invalid]="
                    (annederm.controls['359'].touched &&
                      annederm.controls['359'].invalid) ||
                    (annederm.controls['359'].invalid && isSubmit)
                  "
                  formControlName="359"
                  type="text"
                  placeholder="REF 1 NAME"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['359'].touched &&
                    annederm.controls['359'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['359'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Title/Position:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2722"
                  name="2722"
                  [class.is-invalid]="
                    (annederm.controls['2722'].touched &&
                      annederm.controls['2722'].invalid) ||
                    (annederm.controls['2722'].invalid && isSubmit)
                  "
                  formControlName="2722"
                  type="text"
                  placeholder="REF 1 TITLE"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['2722'].touched &&
                    annederm.controls['2722'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['2722'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Email:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="371"
                  name="371"
                  [class.is-invalid]="
                    (annederm.controls['371'].touched &&
                      annederm.controls['371'].invalid) ||
                    (annederm.controls['371'].invalid && isSubmit)
                  "
                  formControlName="371"
                  type="text"
                  placeholder="REF 1  EMAL"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['371'].touched &&
                    annederm.controls['371'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['371'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="360"
                  name="360"
                  formControlName="360"
                  type="text"
                  placeholder="REF 1    ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="361"
                  name="361"
                  formControlName="361"
                  type="text"
                  placeholder="REF 1  ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="362"
                  name="362"
                  formControlName="362"
                  type="text"
                  placeholder="REF 1  CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="364"
                  >
                  </ng-select>
                </div>
              
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="365"
                  name="365"
                  formControlName="365"
                  type="text"
                  placeholder="REF 1   ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Phone:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="369"
                  name="369"
                  formControlName="369"
                  type="text"
                  placeholder="REF 1 PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="372"
                  name="372"
                  [class.is-invalid]="
                    (annederm.controls['372'].touched &&
                      annederm.controls['372'].invalid) ||
                    (annederm.controls['372'].invalid && isSubmit)
                  "
                  formControlName="372"
                  type="text"
                  placeholder="REF 2 NAME"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['372'].touched &&
                    annederm.controls['372'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['372'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Title/Position:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2723"
                  name="2723"
                  [class.is-invalid]="
                    (annederm.controls['2723'].touched &&
                      annederm.controls['2723'].invalid) ||
                    (annederm.controls['2723'].invalid && isSubmit)
                  "
                  formControlName="2723"
                  type="text"
                  placeholder="REF 2  TITLE"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['2723'].touched &&
                    annederm.controls['2723'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['2723'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Email:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="383"
                  name="383"
                  [class.is-invalid]="
                    (annederm.controls['383'].touched &&
                      annederm.controls['383'].invalid) ||
                    (annederm.controls['383'].invalid && isSubmit)
                  "
                  formControlName="383"
                  type="text"
                  placeholder="REF 2   EMAIL"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    (annederm.controls['383'].touched &&
                      annederm.controls['383'].invalid) ||
                    (annederm.controls['383'].invalid && isSubmit)
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['383'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="373"
                  name="373"
                  formControlName="373"
                  type="text"
                  placeholder="REF 2    ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="374"
                  name="374"
                  formControlName="374"
                  type="text"
                  placeholder="REF 2    ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="375"
                  name="375"
                  formControlName="375"
                  type="text"
                  placeholder="REF 2   CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="377"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="578"
                  name="578"
                  formControlName="378"
                  type="text"
                  placeholder="REF 2   ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Phone:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="381"
                  name="381"
                  formControlName="381"
                  type="text"
                  placeholder="REF 2      PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Name:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="384"
                  name="384"
                  [class.is-invalid]="
                    (annederm.controls['384'].touched &&
                      annederm.controls['384'].invalid) ||
                    (annederm.controls['384'].invalid && isSubmit)
                  "
                  formControlName="384"
                  type="text"
                  placeholder="REF 3 NAME"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['384'].touched &&
                    annederm.controls['384'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['384'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Title/Position:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="2725"
                  name="2725"
                  [class.is-invalid]="
                    (annederm.controls['2725'].touched &&
                      annederm.controls['2725'].invalid) ||
                    (annederm.controls['2725'].invalid && isSubmit)
                  "
                  formControlName="2725"
                  type="text"
                  placeholder="REF 3 TITLE"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['2725'].touched &&
                    annederm.controls['2725'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['2725'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Email:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="396"
                  name="396"
                  [class.is-invalid]="
                    (annederm.controls['396'].touched &&
                      annederm.controls['396'].invalid) ||
                    (annederm.controls['396'].invalid && isSubmit)
                  "
                  formControlName="396"
                  type="text"
                  placeholder="REF 3 EMAIL"
                  data-id="managing_contact_name"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['396'].touched &&
                    annederm.controls['396'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['396'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="385"
                  name="385"
                  formControlName="385"
                  type="text"
                  placeholder="REF 3  ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="386"
                  name="386"
                  formControlName="386"
                  type="text"
                  placeholder="REF 3  ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="387"
                  name="387"
                  formControlName="387"
                  type="text"
                  placeholder="REF 3   CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="389"
                  >
                  </ng-select>
                </div>
             
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="390"
                  name="390"
                  formControlName="390"
                  type="text"
                  placeholder="REF 3 ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Phone:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="394"
                  name="394"
                  formControlName="394"
                  type="text"
                  placeholder="REF 3  PHONE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle"
                  >Active Duty Military:</label
                >
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100009"
                    name="cmd_100009"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100009'].touched &&
                        annederm.controls['cmd_100009'].invalid) ||
                      (annederm.controls['cmd_100009'].invalid && isSubmit)
                    "
                    formControlName="cmd_100009"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100009'].touched &&
                    annederm.controls['cmd_100009'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100009'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100009"
                    name="cmd_100009"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100009'].touched &&
                        annederm.controls['cmd_100009'].invalid) ||
                      (annederm.controls['cmd_100009'].invalid && isSubmit)
                    "
                    formControlName="cmd_100009"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100009'].touched &&
                    annederm.controls['cmd_100009'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100009'].errors?.required"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (annederm.controls['cmd_100009'].touched &&
                      annederm.controls['cmd_100009'].invalid) ||
                    (annederm.controls['cmd_100009'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">If yes: Branch</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1141"
                  name="1141"
                  formControlName="1141"
                  type="text"
                  placeholder="MIL BRANCH"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  ><b>HOSPITAL AFFILIATIONS:</b
                  ><u style="color: red;"
                    >(Please email proof of privileges)</u
                  ></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Primary Hospital:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="418"
                  name="418"
                  formControlName="418"
                  type="text"
                  placeholder="HOSP AFF 1 NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="419"
                  name="419"
                  formControlName="419"
                  type="text"
                  placeholder="HOSP AFF 1 ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="420"
                  name="420"
                  formControlName="420"
                  type="text"
                  placeholder="HOSP AFF 1 ADD  2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="421"
                  name="421"
                  formControlName="421"
                  type="text"
                  placeholder="HOSP AFF 1 CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="423"
                  >
                  </ng-select>
               
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="424"
                  name="424"
                  formControlName="424"
                  type="text"
                  placeholder="HOSP AFF 1 ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Type of Privilege:</label>
              </div>
              <div class="mb-3 selectDropStatus  dropDrownSet col-md-3">
                <ng-select
                  [items]="staffStatus"
                  bindLabel="name"
                  placeholder="Select Status"
                  bindValue="id"
                  formControlName="894"
                >
                </ng-select>
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Other Affiliations:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="427"
                  name="427"
                  formControlName="427"
                  type="text"
                  placeholder="HOSP AFF 2 NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="428"
                  name="428"
                  formControlName="428"
                  type="text"
                  placeholder="HOSP AFF 2  ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="429"
                  name="429"
                  formControlName="429"
                  type="text"
                  placeholder="HOSP AFF 2  ADD  2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="430"
                  name="430"
                  formControlName="430"
                  type="text"
                  placeholder="HOSP AFF 2  CITY"
                  data-id="managing_contact_name"
                />
              </div>
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="432"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="433"
                  name="433"
                  formControlName="433"
                  type="text"
                  placeholder="HOSP AFF 2  ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2">
                <label class="normalParagraphStyle">Type of Privilege:</label>
              </div>
                <div class="mb-3 selectDropStatus  dropDrownSet col-md-3">
                <ng-select
                  [items]="staffStatus"
                  bindLabel="name"
                  placeholder="Select Status"
                  bindValue="id"
                  formControlName="895"
                >
                </ng-select>
                </div>
              </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"><b>WORK HISTORY</b></label>
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Current Employer Name:</label
                >
              </div>
              <div class="col-md-5">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="445"
                  name="445"
                  formControlName="445"
                  type="text"
                  placeholder="WORK HIST 1 NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="446"
                  name="446"
                  formControlName="446"
                  type="text"
                  placeholder="WORK HIST 1  ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="447"
                  name="447"
                  formControlName="447"
                  type="text"
                  placeholder="WORK HIST 1  ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="448"
                  name="448"
                  formControlName="448"
                  type="text"
                  placeholder="WORK HIST 1 CITY"
                  data-id="managing_contact_name"
                />
              </div>
           
           
             
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="450"
                  >
                  </ng-select>
                </div>
              
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="451"
                  name="451"
                  type="text"
                  formControlName="451"
                  placeholder="WORK HIST 1  ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="453"
                  name="453"
                  formControlName="453"
                  type="text"
                  placeholder="WORK HIST 1    PHONE"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="888"
                  name="888"
                  formControlName="888"
                  type="text"
                  placeholder="WORK HIST 1 FAX "
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy):</label
                >
              </div>
              <div class="col-md-3  input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="454"
                  name="454"
                  formControlName="454"
                  type="text"
                  placeholder="WORK HIST 1 START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad27="ngbDatepicker"
                  (blur)="clearInputIfInvalid('454')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad27.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="455"
                  name="455"
                  formControlName="455"
                  type="text"
                  placeholder="WORK HIST 1 END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad28="ngbDatepicker"
                  (blur)="clearInputIfInvalid('455')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad28.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('454').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('454').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('454').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('454').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('454').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('455').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('455').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('455').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('455').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('455').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Enter additional work history for the past 10 years</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Practice/Employer Name:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="456"
                  name="456"
                  formControlName="456"
                  type="text"
                  placeholder="WORK HIST 2 NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="457"
                  name="457"
                  formControlName="457"
                  type="text"
                  placeholder="WORK HIST 2  ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="458"
                  name="458"
                  formControlName="458"
                  type="text"
                  placeholder="WORK HIST 2  ADD  2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="459"
                  name="459"
                  formControlName="459"
                  type="text"
                  placeholder="WORK HIST 2  CITY"
                  data-id="managing_contact_name"
                />
              </div>
            
          
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="461"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="462"
                  name="462"
                  formControlName="462"
                  type="text"
                  placeholder="WORK HIST 2  ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>

          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone#:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="464"
                  name="464"
                  formControlName="464"
                  type="text"
                  placeholder="WORK HIST 2    PHONE"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax#:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="890"
                  name="890"
                  formControlName="890"
                  type="text"
                  placeholder="WORK HIST 2    FAX"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy) :</label
                >
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="465"
                  name="465"
                  formControlName="465"
                  type="text"
                  placeholder="WORK HIST 2  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad29="ngbDatepicker"
                  (blur)="clearInputIfInvalid('465')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad29.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="466"
                  name="466"
                  formControlName="466"
                  type="text"
                  placeholder="WORK HIST 2  END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad30="ngbDatepicker"
                  (blur)="clearInputIfInvalid('466')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad30.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('465').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('465').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('465').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('465').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('465').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('466').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('466').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('466').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('466').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('466').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Reason for departure:</label
                >
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100010"
                  name="cmd_100010"
                  formControlName="cmd_100010"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                  rows="3"></textarea>
                
              </div>
            </div>
          </tr>
         <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-9"></div>
            </div>
          </tr>
          <!-- 4th Page -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Practice/Employer Name:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="467"
                  name="467"
                  formControlName="467"
                  type="text"
                  placeholder="Work history 3 Location"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="468"
                  name="468"
                  formControlName="468"
                  type="text"
                  placeholder="Work History 3 Address1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="469"
                  name="469"
                  formControlName="469"
                  type="text"
                  placeholder="Work History 3 Address2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="470"
                  name="470"
                  formControlName="470"
                  type="text"
                  placeholder="Work History 3 City"
                  data-id="managing_contact_name"
                />
              </div>
          
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="472"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="473"
                  name="473"
                  formControlName="473"
                  type="text"
                  placeholder="Work History 3 Zip"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="475"
                  name="475"
                  formControlName="475"
                  type="text"
                  placeholder="Work History 3 Telephone "
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="892"
                  name="892"
                  formControlName="892"
                  type="text"
                  placeholder="Work History 3 Fax"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy):</label
                >
              </div>
              <div class="col-md-3 input-group  ">
                <input
                  class="form-control onboard datepickers"
                  id="476"
                  name="476"
                  formControlName="476"
                  type="text"
                  placeholder="Work History 3 Start Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad31="ngbDatepicker"
                  (blur)="clearInputIfInvalid('476')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad31.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="477"
                  name="477"
                  formControlName="477"
                  type="text"
                  placeholder="Work History 3 End Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad32="ngbDatepicker"
                  (blur)="clearInputIfInvalid('477')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad32.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('476').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('476').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('476').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('476').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('476').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('477').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('477').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('477').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('477').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('477').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Reason for departure:</label
                >
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100013"
                  name="cmd_100013"
                  formControlName="cmd_100013"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </tr>

          <!-- <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100014"
                  name="cmd_100014"
                  formControlName="cmd_100014"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100015"
                  name="cmd_100015"
                  formControlName="cmd_100015"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>-->
          <tr>
            
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Practice/Employer Name:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1935"
                  name="1935"
                  formControlName="1935"
                  type="text"
                  placeholder="Work History 4 Location"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr> 
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1936"
                  name="1936"
                  formControlName="1936"
                  type="text"
                  placeholder="Work History 4  Address1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1937"
                  name="1937"
                  formControlName="1937"
                  type="text"
                  placeholder="Work History 4  Address2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1938"
                  name="1938"
                  formControlName="1938"
                  type="text"
                  placeholder="Work History 4  City"
                  data-id="managing_contact_name"
                />
              </div>
            
              
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select State"
                    bindValue="id"
                    formControlName="1940"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1941"
                  name="1941"
                  formControlName="1941"
                  type="text"
                  placeholder="Work History 4  Zip"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1944"
                  name="1944"
                  formControlName="1944"
                  type="text"
                  placeholder="Work History 4 Telephone"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1945"
                  name="1945"
                  formControlName="1945"
                  type="text"
                  placeholder="Work History 4 Fax"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy):</label
                >
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="1947"
                  name="1947"
                  formControlName="1947"
                  type="text"
                  placeholder="Work History 4 Start Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad33="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1947')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad33.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3  input-group">
                <input
                  class="form-control onboard datepickers"
                  id="1948"
                  name="1948"
                  formControlName="1948"
                  type="text"
                  placeholder="Work History 4 End Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad34="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1948')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad34.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1947').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1947').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1947').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1947').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1947').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1948').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1948').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1948').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1948').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1948').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
             
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Reason for departure:</label
                >
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100016"
                  name="cmd_100016"
                  formControlName="cmd_100016"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </tr>
          <!-- <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100017"
                  name="cmd_100017"
                  formControlName="cmd_100017"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100018"
                  name="cmd_100018"
                  formControlName="cmd_100018"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr> -->
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Practice/Employer Name:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1954"
                  name="1954"
                  formControlName="1954"
                  type="text"
                  placeholder="WORK HIST 5 NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1955"
                  name="1955"
                  formControlName="1955"
                  type="text"
                  placeholder="WORK HIST  5ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1956"
                  name="1956"
                  formControlName="1956"
                  type="text"
                  placeholder="WORK HIST  5ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1957"
                  name="1957"
                  formControlName="1957"
                  type="text"
                  placeholder="WORK HIST  5 CITY"
                  data-id="managing_contact_name"
                />
              </div>
            
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select State"
                    bindValue="id"
                    formControlName="1959"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1960"
                  name="1960"
                  formControlName="1960"
                  type="text"
                  placeholder="WORK HIST  5 ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1963"
                  name="1963"
                  formControlName="1963"
                  type="text"
                  placeholder="WORK HIST  5  PHONE"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1964"
                  name="1964"
                  formControlName="1964"
                  type="text"
                  placeholder="WORK HIST  5  FAX"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy):</label
                >
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="1966"
                  name="1966"
                  formControlName="1966"
                  type="text"
                  placeholder="WORK HIST  5   START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad60="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1966')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad60.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepickers"
                  id="1967"
                  name="1967"
                  formControlName="1967"
                  type="text"
                  placeholder="WORK HIST  5   END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad35="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1967')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad35.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1966').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1966').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1966').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1966').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1966').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1967').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1967').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1967').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1967').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1967').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Reason for departure:</label
                >
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100019"
                  name="cmd_100019"
                  formControlName="cmd_100019"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </tr>
          <!-- <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100020"
                  name="cmd_100020"
                  formControlName="cmd_100020"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100021"
                  name="cmd_100021"
                  formControlName="cmd_100021"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr> -->
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Practice/Employer Name:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100161"
                  name="cmd_1100161"
                  formControlName="cmd_1100161"
                  type="text"
                  placeholder="Practice/Employer Name"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100162"
                  name="cmd_1100162"
                  formControlName="cmd_1100162"
                  type="text"
                  placeholder="Address1"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100163"
                  name="cmd_1100163"
                  formControlName="cmd_1100163"
                  type="text"
                  placeholder="Address2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100164"
                  name="cmd_1100164"
                  formControlName="cmd_1100164"
                  type="text"
                  placeholder="CITY"
                  data-id="managing_contact_name"
                />
              </div>
            
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select State"
                    bindValue="id"
                    formControlName="cmd_01100164"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_001100164"
                  name="cmd_001100164"
                  formControlName="cmd_001100164"
                  type="text"
                  placeholder="ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>

          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Phone #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100165"
                  name="cmd_1100165"
                  formControlName="cmd_1100165"
                  type="text"
                  placeholder="Phone"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Fax #:</label>
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_110016123"
                  name="cmd_110016123"
                  formControlName="cmd_110016123"
                  type="text"
                  placeholder="Fax"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Start Date: (mm/yyyy):</label
                >
              </div>
              <div class="col-md-3 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="cmd_1100167"
                  name="cmd_1100167"
                  formControlName="cmd_1100167"
                  type="text"
                  placeholder="Start Date: (mm/yyyy) "
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad36="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_1100167')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad36.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">End Date: (mm/yyyy):</label>
              </div>
              <div class="col-md-3 input-group">
                <input
                  class="form-control onboard datepickers"
                  id="cmd_1100168"
                  name="cmd_1100168"
                  formControlName="cmd_1100168"
                  type="text"
                  placeholder="End Date: (mm/yyyy)"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad37="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_1100168')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad37.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('cmd_1100167').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100167').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100167').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100167').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('cmd_1100167').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('cmd_1100168').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100168').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100168').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_1100168').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('cmd_1100168').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Reason for departure:</label
                >
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_1100169"
                  name="cmd_1100169"
                  formControlName="cmd_1100169"
                  type="text"
                  placeholder="Reason for departure"
                  data-id="managing_contact_name"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </tr>
          

          <tr>
            <div class="row">
              <div class="col-md-12 textBoxFourStyle">
                <label class="textBoxFiveStyle"
                  ><u
                    >If you have any gaps in work history please provide further
                    information below including dates and reasoning.</u
                  ></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1138"
                  name="1138"
                  formControlName="1138"
                  type="text"
                  placeholder="WORK HIST 1 GAP EXPL"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="3395"
                  name="3395"
                  formControlName="3395"
                  type="text"
                  placeholder="WORK HIST 1 GAP  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad38="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3395')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad38.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="3396"
                  name="3396"
                  formControlName="3396"
                  type="text"
                  placeholder="WORK HIST 1 GAP  END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad39="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3396')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad39.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3395').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3395').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3395').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3395').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3395').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3396').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3396').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3396').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3396').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3396').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1831"
                  name="1831"
                  formControlName="1831"
                  type="text"
                  placeholder="WORK HIST 2 GAP  EXPL"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="3397"
                  name="3397"
                  formControlName="3397"
                  type="text"
                  placeholder="WORK HIST 2 GAP START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad40="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3397')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad40.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="3398"
                  name="3398"
                  formControlName="3398"
                  type="text"
                  placeholder="WORK HIST 2 GAP END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad41="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3398')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad41.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3397').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3397').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3397').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3397').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3397').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3398').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3398').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3398').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3398').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3398').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1832"
                  name="1832"
                  formControlName="1832"
                  type="text"
                  placeholder="WORK HIST 3 GAP  EXPL"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4  input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="3399"
                  name="3399"
                  formControlName="3399"
                  type="text"
                  placeholder="WORK HIST 3 GAP  START DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad42="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3399')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad42.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="3400"
                  name="3400"
                  formControlName="3400"
                  type="text"
                  placeholder="WORK HIST 3 GAP  END DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad44="ngbDatepicker"
                  (blur)="clearInputIfInvalid('3400')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad44.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3399').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3399').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3399').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3399').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3399').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('3400').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('3400').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3400').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('3400').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('3400').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <!-- 5th Page    -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />

          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class=" normalParagraphStyle"
                  ><b>INSURANCE:</b
                  ><u style="color: red;"
                    >(Please email copy of malpractice insurance certificate)</u
                  ></label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Malpractice Carrier:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="345"
                  name="345"
                  formControlName="345"
                  type="text"
                  placeholder="MLP CARRIER NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Address:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="901"
                  name="901"
                  formControlName="901"
                  type="text"
                  placeholder="MLP CARRIER  ADD 1"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="902"
                  name="902"
                  formControlName="902"
                  type="text"
                  placeholder="MLP CARRIER  ADD 2"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="903"
                  name="903"
                  formControlName="903"
                  type="text"
                  placeholder="MLP CARRIER   CITY"
                  data-id="managing_contact_name"
                />
              </div>
             
              <div class="mb-3 selectDrop suffix dropDrownSet col-md-2">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select State"
                    bindValue="id"
                    formControlName="905"
                  >
                  </ng-select>
                
              </div>
              <div class="col-md-3">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="906"
                  name="906"
                  formControlName="906"
                  type="text"
                  placeholder="MLP CARRIER   ZIP"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Amount of Coverage:</label>
              </div>
              <div class="col-md-4">
                <!-- <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="350"
                  name="350"
                  formControlName="350"
                  type="text"
                  placeholder="MLP CARRIER  PER CLAIM"
                  data-id="managing_contact_name"
                /> -->
                <div class="mb-3 selectDrop col-md-8">
                  <ng-select
                    [items]="preClaim"
                    bindLabel="name"
                    placeholder="PerClaim Amount"
                    bindValue="id"
                    formControlName="350"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-5">
                <!-- <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="351"
                  name="351"
                  formControlName="351"
                  type="text"
                  placeholder="MLP CARRIER  AGGREGATE"
                  data-id="managing_contact_name"
                /> -->
                <div class="mb-3 selectDrop col-md-8">
                  <ng-select
                    [items]="aggregates"
                    bindLabel="name"
                    placeholder="PerAggregate Amount"
                    bindValue="id"
                    formControlName="351"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Policy Number:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="346"
                  name="346"
                  formControlName="346"
                  type="text"
                  placeholder="PREV MLP CARRIER  POLICY"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-4 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="349"
                  name="349"
                  formControlName="349"
                  type="text"
                  placeholder="MLP CARRIER EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad45="ngbDatepicker"
                  (blur)="clearInputIfInvalid('349')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad45.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="annederm.get('349').invalid ">
                <small class="text-danger" *ngIf="annederm.get('349').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('349').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('349').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('349').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Length of Time with Current Carrier:</label
                >
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_000022"
                  name="cmd_000022"
                  formControlName="cmd_000022"
                  type="text"
                  placeholder="Length of Time with Current Carrier"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >(If less than 5 years please provide)</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Previous Carrier:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1242"
                  name="1242"
                  formControlName="1242"
                  type="text"
                  placeholder="PREV MLP CARRIER NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Amount of Coverage:</label>
              </div>
              <div class="col-md-4">
                <!-- <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1264"
                  name="1264"
                  formControlName="1264"
                  type="text"
                  placeholder="PREV MLP CARRIER  PER CLAIM"
                  data-id="managing_contact_name"
                /> -->
                <div class="mb-3 selectDrop col-md-8">
                  <ng-select
                    [items]="preClaim"
                    bindLabel="name"
                    placeholder="PerClaim Amount"
                    bindValue="id"
                    formControlName="1264"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-5">
                <!-- <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1265"
                  name="1265"
                  formControlName="1265"
                  type="text"
                  placeholder="PREV MLP CARRIER  AGGREGATE"
                  data-id="managing_contact_name"
                /> -->
                <div class="mb-3 selectDrop col-md-8">
                  <ng-select
                    [items]="aggregates"
                    bindLabel="name"
                    placeholder="PerAggregate Amount"
                    bindValue="id"
                    formControlName="1265"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Policy Number:</label>
              </div>
              <div class="col-md-4">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1256"
                  name="1256"
                  formControlName="1256"
                  type="text"
                  placeholder="PREV MLP CARRIER  POLICY"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-3">
                <label class="normalParagraphStyle">Expiration Date:</label>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="1253"
                  name="1253"
                  formControlName="1253"
                  type="text"
                  placeholder="PREV MLP CARRIER EXP DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad46="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1253')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad46.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div  *ngIf="annederm.get('1253').invalid ">
                <small class="text-danger" *ngIf="annederm.get('1253').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('1253').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('1253').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('1253').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Below are the CAQH disclosure questions that will be attested
                  to when completing your credentialing. Circle the appropriate
                  answer for each question and explain any “yes” answers on a
                  separate sheet of paper. If at any time during your employment
                  with us the answers to these questions change, I should be
                  notified immediately.</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">1.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Has your license, registration or certification to practice
                  in your profession ever been voluntarily or involuntarily
                  relinquished, denied, suspended, revoked, restricted, or have
                  you ever been subject to a fine, reprimand, consent order,
                  probation or any conditions or limitations by any state or
                  professional licensing, registration or certification
                  board?</label
                >
              </div>
            <!-- </div> -->
          <!-- </tr> -->
          <!-- <tr> -->
            <!-- <div class="row"> -->
              <!-- <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100023"
                    name="cmd_100023"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100023'].touched &&
                        annederm.controls['cmd_100023'].invalid) ||
                      (annederm.controls['cmd_100023'].invalid && isSubmit)
                    "
                    formControlName="cmd_100023"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100023'].touched &&
                    annederm.controls['cmd_100023'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100023'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100023"
                    name="cmd_100023"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100023'].touched &&
                        annederm.controls['cmd_100023'].invalid) ||
                      (annederm.controls['cmd_100023'].invalid && isSubmit)
                    "
                    formControlName="cmd_100023"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100023'].touched &&
                    annederm.controls['cmd_100023'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100023'].errors?.required"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (annederm.controls['cmd_100023'].touched &&
                      annederm.controls['cmd_100023'].invalid) ||
                    (annederm.controls['cmd_100023'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">2.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Has there been any challenge to your licensure, registration
                  or certification?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100024"
                    name="cmd_100024"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100024'].touched &&
                        annederm.controls['cmd_100024'].invalid) ||
                      (annederm.controls['cmd_100024'].invalid && isSubmit)
                    "
                    formControlName="cmd_100024"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100024'].touched &&
                    annederm.controls['cmd_100024'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100024'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100024"
                    name="cmd_100024"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100024'].touched &&
                        annederm.controls['cmd_100024'].invalid) ||
                      (annederm.controls['cmd_100024'].invalid && isSubmit)
                    "
                    formControlName="cmd_100024"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100024'].touched &&
                    annederm.controls['cmd_100024'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100024'].errors?.required"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (annederm.controls['cmd_100024'].touched &&
                      annederm.controls['cmd_100024'].invalid) ||
                    (annederm.controls['cmd_100024'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">3.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have your clinical privileges or medical staff membership at
                  any hospital or healthcare institution, voluntarily or
                  involuntarily, ever been denied, suspended, revoked,
                  restricted, denied renewal or subject to probationary or to
                  other disciplinary conditions (for reasons other than
                  non-completion of medical record when quality of care was not
                  adversely affected) or have proceedings toward any of those
                  ends been instituted or recommended by any hospital or
                  healthcare institution, medical staff or committee, or
                  governing board?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100025"
                    name="cmd_100025"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100025'].touched &&
                        annederm.controls['cmd_100025'].invalid) ||
                      (annederm.controls['cmd_100025'].invalid && isSubmit)
                    "
                    formControlName="cmd_100025"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100025'].touched &&
                    annederm.controls['cmd_100025'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100025'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100025"
                    name="cmd_100025"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100025'].touched &&
                        annederm.controls['cmd_100025'].invalid) ||
                      (annederm.controls['cmd_100025'].invalid && isSubmit)
                    "
                    formControlName="cmd_100025"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100025'].touched &&
                    annederm.controls['cmd_100025'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100025'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100025'].touched &&
                      annederm.controls['cmd_100025'].invalid) ||
                    (annederm.controls['cmd_100025'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">4.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you voluntarily or involuntarily surrendered, limited
                  your privileges or not reapplied for privileges while under
                  investigation?</label
                >
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100026"
                    name="cmd_100026"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100026'].touched &&
                        annederm.controls['cmd_100026'].invalid) ||
                      (annederm.controls['cmd_100026'].invalid && isSubmit)
                    "
                    formControlName="cmd_100026"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100026'].touched &&
                    annederm.controls['cmd_100026'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100026'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100026"
                    name="cmd_100026"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100026'].touched &&
                        annederm.controls['cmd_100026'].invalid) ||
                      (annederm.controls['cmd_100026'].invalid && isSubmit)
                    "
                    formControlName="cmd_100026"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100026'].touched &&
                    annederm.controls['cmd_100026'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100026'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100026'].touched &&
                      annederm.controls['cmd_100026'].invalid) ||
                    (annederm.controls['cmd_100026'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">5.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been terminated for cause or not renewed for
                  cause from participation, or been subject to any disciplinary
                  action, by any managed care organizations (including HMOs,
                  PPOs, or provider organizations such as IPAs, PHOs)?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100027"
                    name="cmd_100027"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100027'].touched &&
                        annederm.controls['cmd_100027'].invalid) ||
                      (annederm.controls['cmd_100027'].invalid && isSubmit)
                    "
                    formControlName="cmd_100027"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100027'].touched &&
                    annederm.controls['cmd_100027'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100027'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100027"
                    name="cmd_100027"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100027'].touched &&
                        annederm.controls['cmd_100027'].invalid) ||
                      (annederm.controls['cmd_100027'].invalid && isSubmit)
                    "
                    formControlName="cmd_100027"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100027'].touched &&
                    annederm.controls['cmd_100027'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100027'].errors?.required"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (annederm.controls['cmd_100027'].touched &&
                      annederm.controls['cmd_100027'].invalid) ||
                    (annederm.controls['cmd_100027'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">6.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Were you ever placed on probation, disciplined, formally
                  reprimanded, suspended or asked to resign during an
                  internship, residency, fellowship, preceptorship or other
                  clinical education program? If you are currently in a training
                  program, have you been placed on probation, disciplined,
                  formally reprimanded, suspended or asked to resign?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100028"
                    name="cmd_100028"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100028'].touched &&
                        annederm.controls['cmd_100028'].invalid) ||
                      (annederm.controls['cmd_100028'].invalid && isSubmit)
                    "
                    formControlName="cmd_100028"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100028'].touched &&
                    annederm.controls['cmd_100028'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100028'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100028"
                    name="cmd_100028"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100028'].touched &&
                        annederm.controls['cmd_100028'].invalid) ||
                      (annederm.controls['cmd_100028'].invalid && isSubmit)
                    "
                    formControlName="cmd_100028"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100028'].touched &&
                    annederm.controls['cmd_100028'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100028'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100028'].touched &&
                      annederm.controls['cmd_100028'].invalid) ||
                    (annederm.controls['cmd_100028'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">7.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever, while under investigation or to avoid an
                  investigation, voluntarily withdrawn or prematurely terminated
                  your status as a student or employee in any internship,
                  residency, fellowship, preceptorship, or other clinical
                  education program?</label
                >
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100029"
                    name="cmd_100029"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100029'].touched &&
                        annederm.controls['cmd_100029'].invalid) ||
                      (annederm.controls['cmd_100029'].invalid && isSubmit)
                    "
                    formControlName="cmd_100029"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100029'].touched &&
                    annederm.controls['cmd_100029'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100029'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100029"
                    name="cmd_100029"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100029'].touched &&
                        annederm.controls['cmd_100029'].invalid) ||
                      (annederm.controls['cmd_100029'].invalid && isSubmit)
                    "
                    formControlName="cmd_100029"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100029'].touched &&
                    annederm.controls['cmd_100029'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100029'].errors?.required"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (annederm.controls['cmd_100029'].touched &&
                      annederm.controls['cmd_100029'].invalid) ||
                    (annederm.controls['cmd_100029'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">8.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have any of your board certifications or eligibility ever
                  been revoked?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100030"
                    name="cmd_100030"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100030'].touched &&
                        annederm.controls['cmd_100030'].invalid) ||
                      (annederm.controls['cmd_100030'].invalid && isSubmit)
                    "
                    formControlName="cmd_100030"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100030'].touched &&
                    annederm.controls['cmd_100030'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100030'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100030"
                    name="cmd_100030"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100030'].touched &&
                        annederm.controls['cmd_100030'].invalid) ||
                      (annederm.controls['cmd_100030'].invalid && isSubmit)
                    "
                    formControlName="cmd_100030"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100030'].touched &&
                    annederm.controls['cmd_100030'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100030'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100030'].touched &&
                      annederm.controls['cmd_100030'].invalid) ||
                    (annederm.controls['cmd_100030'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">9.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever chosen not to re-certify or voluntarily
                  surrendered your board certification(s) while under
                  investigation?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100031"
                    name="cmd_100031"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100031'].touched &&
                        annederm.controls['cmd_100031'].invalid) ||
                      (annederm.controls['cmd_100031'].invalid && isSubmit)
                    "
                    formControlName="cmd_100031"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100031'].touched &&
                    annederm.controls['cmd_100031'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100031'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100031"
                    name="cmd_100031"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100031'].touched &&
                        annederm.controls['cmd_100031'].invalid) ||
                      (annederm.controls['cmd_100031'].invalid && isSubmit)
                    "
                    formControlName="cmd_100031"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100031'].touched &&
                    annederm.controls['cmd_100031'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100031'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100031'].touched &&
                      annederm.controls['cmd_100031'].invalid) ||
                    (annederm.controls['cmd_100031'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <!-- 6th Page -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">10.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have your Federal DEA and/or State Controlled Dangerous
                  Substances (CDS) certificate(s) or authorization(s) ever been
                  challenged, denied, suspended, revoked, restricted, denied
                  renewal, or voluntarily or involuntarily relinquished?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100032"
                    name="cmd_100032"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100032'].touched &&
                        annederm.controls['cmd_100032'].invalid) ||
                      (annederm.controls['cmd_100032'].invalid && isSubmit)
                    "
                    formControlName="cmd_100032"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100032'].touched &&
                    annederm.controls['cmd_100032'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100032'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100032"
                    name="cmd_100032"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100032'].touched &&
                        annederm.controls['cmd_100032'].invalid) ||
                      (annederm.controls['cmd_100032'].invalid && isSubmit)
                    "
                    formControlName="cmd_100032"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100032'].touched &&
                    annederm.controls['cmd_100032'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100032'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100032'].touched &&
                      annederm.controls['cmd_100032'].invalid) ||
                    (annederm.controls['cmd_100032'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">11.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been disciplined, excluded from, debarred,
                  suspended, reprimanded, sanctioned, censured, disqualified or
                  otherwise restricted in regard to participation in the
                  Medicare or Medicaid program, or in regard to other federal or
                  state governmental health care plans or programs?</label
                >
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100033"
                    name="cmd_100033"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100033'].touched &&
                        annederm.controls['cmd_100033'].invalid) ||
                      (annederm.controls['cmd_100033'].invalid && isSubmit)
                    "
                    formControlName="cmd_100033"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100033'].touched &&
                    annederm.controls['cmd_100033'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100033'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100033"
                    name="cmd_100033"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100033'].touched &&
                        annederm.controls['cmd_100033'].invalid) ||
                      (annederm.controls['cmd_100033'].invalid && isSubmit)
                    "
                    formControlName="cmd_100033"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100033'].touched &&
                    annederm.controls['cmd_100033'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100033'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100033'].touched &&
                      annederm.controls['cmd_100033'].invalid) ||
                    (annederm.controls['cmd_100033'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">12.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Are you currently the subject of an investigation by any
                  hospital, licensing authority, DEA or CDS authorizing
                  entities, education or training program, Medicare or Medicaid
                  program, or any other private, federal or state health program
                  or a defendant in any civil action that is reasonably related
                  to your qualifications, competence, functions, or duties as a
                  medical professional for alleged fraud, an act of violence,
                  child abuse or a sexual offence or sexual misconduct?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100034"
                    name="cmd_100034"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100034'].touched &&
                        annederm.controls['cmd_100034'].invalid) ||
                      (annederm.controls['cmd_100034'].invalid && isSubmit)
                    "
                    formControlName="cmd_100034"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100034'].touched &&
                    annederm.controls['cmd_100034'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100034'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100034"
                    name="cmd_100034"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100034'].touched &&
                        annederm.controls['cmd_100034'].invalid) ||
                      (annederm.controls['cmd_100034'].invalid && isSubmit)
                    "
                    formControlName="cmd_100034"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100034'].touched &&
                    annederm.controls['cmd_100034'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100034'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100034'].touched &&
                      annederm.controls['cmd_100034'].invalid) ||
                    (annederm.controls['cmd_100034'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">13.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >To your knowledge, has information pertaining to you ever
                  been reported to the National Practitioner Data Bank or
                  Healthcare Integrity and Protection Data Bank?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100035"
                    name="cmd_100035"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100035'].touched &&
                        annederm.controls['cmd_100035'].invalid) ||
                      (annederm.controls['cmd_100035'].invalid && isSubmit)
                    "
                    formControlName="cmd_100035"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100035'].touched &&
                    annederm.controls['cmd_100035'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100035'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100035"
                    name="cmd_100035"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100035'].touched &&
                        annederm.controls['cmd_100035'].invalid) ||
                      (annederm.controls['cmd_100035'].invalid && isSubmit)
                    "
                    formControlName="cmd_100035"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100035'].touched &&
                    annederm.controls['cmd_100035'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100035'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100035'].touched &&
                      annederm.controls['cmd_100035'].invalid) ||
                    (annederm.controls['cmd_100035'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">14.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever received sanctions from or are you currently
                  the subject of investigation by any regulatory agencies (e.g.,
                  CLIA, OSHA, etc.)?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100036"
                    name="cmd_100036"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100036'].touched &&
                        annederm.controls['cmd_100036'].invalid) ||
                      (annederm.controls['cmd_100036'].invalid && isSubmit)
                    "
                    formControlName="cmd_100036"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100036'].touched &&
                    annederm.controls['cmd_100036'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100036'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100036"
                    name="cmd_100036"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100036'].touched &&
                        annederm.controls['cmd_100036'].invalid) ||
                      (annederm.controls['cmd_100036'].invalid && isSubmit)
                    "
                    formControlName="cmd_100036"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100036'].touched &&
                    annederm.controls['cmd_100036'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100036'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100036'].touched &&
                      annederm.controls['cmd_100036'].invalid) ||
                    (annederm.controls['cmd_100036'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">15.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been convicted of, pled guilty to, pled nolo
                  contendere to, sanctioned, reprimanded, restricted,
                  disciplined or resigned in exchange for no investigation or
                  adverse action within the last ten years for sexual harassment
                  or other illegal misconduct?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100037"
                    name="cmd_100037"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100037'].touched &&
                        annederm.controls['cmd_100037'].invalid) ||
                      (annederm.controls['cmd_100037'].invalid && isSubmit)
                    "
                    formControlName="cmd_100037"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100037'].touched &&
                    annederm.controls['cmd_100037'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100037'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100037"
                    name="cmd_100037"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100037'].touched &&
                        annederm.controls['cmd_100037'].invalid) ||
                      (annederm.controls['cmd_100037'].invalid && isSubmit)
                    "
                    formControlName="cmd_100037"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100037'].touched &&
                    annederm.controls['cmd_100037'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100037'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100037'].touched &&
                      annederm.controls['cmd_100037'].invalid) ||
                    (annederm.controls['cmd_100037'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">16.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Are you currently being investigated or have you ever been
                  sanctioned, reprimanded, or cautioned by a military hospital,
                  facility, or agency, or voluntarily terminated or resigned
                  while under investigation or in exchange for no investigation
                  by a hospital or healthcare facility of any military agency?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100038"
                    name="cmd_100038"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100038'].touched &&
                        annederm.controls['cmd_100038'].invalid) ||
                      (annederm.controls['cmd_100038'].invalid && isSubmit)
                    "
                    formControlName="cmd_100038"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100038'].touched &&
                    annederm.controls['cmd_100038'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100038'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100038"
                    name="cmd_100038"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100038'].touched &&
                        annederm.controls['cmd_100038'].invalid) ||
                      (annederm.controls['cmd_100038'].invalid && isSubmit)
                    "
                    formControlName="cmd_100038"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100038'].touched &&
                    annederm.controls['cmd_100038'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100038'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100038'].touched &&
                      annederm.controls['cmd_100038'].invalid) ||
                    (annederm.controls['cmd_100038'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">17.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Has your professional liability coverage ever been cancelled,
                  restricted, declined or not renewed by the carrier based on
                  your individual liability history?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100039"
                    name="cmd_100039"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100039'].touched &&
                        annederm.controls['cmd_100039'].invalid) ||
                      (annederm.controls['cmd_100039'].invalid && isSubmit)
                    "
                    formControlName="cmd_100039"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100039'].touched &&
                    annederm.controls['cmd_100039'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100039'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100039"
                    name="cmd_100039"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100039'].touched &&
                        annederm.controls['cmd_100039'].invalid) ||
                      (annederm.controls['cmd_100039'].invalid && isSubmit)
                    "
                    formControlName="cmd_100039"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100039'].touched &&
                    annederm.controls['cmd_100039'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100039'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100039'].touched &&
                      annederm.controls['cmd_100039'].invalid) ||
                    (annederm.controls['cmd_100039'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">18.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been assessed a surcharge, or rated in a
                  high-risk class for your specialty, by your professional
                  liability insurance carrier, based on your individual
                  liability history?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100040"
                    name="cmd_100040"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100040'].touched &&
                        annederm.controls['cmd_100040'].invalid) ||
                      (annederm.controls['cmd_100040'].invalid && isSubmit)
                    "
                    formControlName="cmd_100040"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100040'].touched &&
                    annederm.controls['cmd_100040'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100040'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100040"
                    name="cmd_100040"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100040'].touched &&
                        annederm.controls['cmd_100040'].invalid) ||
                      (annederm.controls['cmd_100040'].invalid && isSubmit)
                    "
                    formControlName="cmd_100040"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100040'].touched &&
                    annederm.controls['cmd_100040'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100040'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100040'].touched &&
                      annederm.controls['cmd_100040'].invalid) ||
                    (annederm.controls['cmd_100040'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">19.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever had any professional liability actions
                  (pending, settled, arbitrated, mediated or litigated) within
                  the past 10 years? If yes, provide information for each case.
                  (**See form after question #26)
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100041"
                    name="cmd_100041"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100041'].touched &&
                        annederm.controls['cmd_100041'].invalid) ||
                      (annederm.controls['cmd_100041'].invalid && isSubmit)
                    "
                    formControlName="cmd_100041"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100041'].touched &&
                    annederm.controls['cmd_100041'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100041'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100041"
                    name="cmd_100041"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100041'].touched &&
                        annederm.controls['cmd_100041'].invalid) ||
                      (annederm.controls['cmd_100041'].invalid && isSubmit)
                    "
                    formControlName="cmd_100041"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100041'].touched &&
                    annederm.controls['cmd_100041'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100041'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100041'].touched &&
                      annederm.controls['cmd_100041'].invalid) ||
                    (annederm.controls['cmd_100041'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">20.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been convicted of, pled guilty to, or pled nolo
                  contendere to any felony?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100042"
                    name="cmd_100042"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100042'].touched &&
                        annederm.controls['cmd_100042'].invalid) ||
                      (annederm.controls['cmd_100042'].invalid && isSubmit)
                    "
                    formControlName="cmd_100042"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100042'].touched &&
                    annederm.controls['cmd_100042'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100042'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100042"
                    name="cmd_100042"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100042'].touched &&
                        annederm.controls['cmd_100042'].invalid) ||
                      (annederm.controls['cmd_100042'].invalid && isSubmit)
                    "
                    formControlName="cmd_100042"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100042'].touched &&
                    annederm.controls['cmd_100042'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100042'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100042'].touched &&
                      annederm.controls['cmd_100042'].invalid) ||
                    (annederm.controls['cmd_100042'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">21.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >In the past ten years have you been convicted of, pled guilty
                  to, or pled nolo contendere to any misdemeanor (excluding
                  minor traffic violations) or been found liable or responsible
                  for any civil offense that is reasonably related to your
                  qualifications, competence, functions, or duties as a medical
                  professional, or for fraud, an act of violence, child abuse or
                  a sexual offence or sexual misconduct?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100043"
                    name="cmd_100043"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100043'].touched &&
                        annederm.controls['cmd_100043'].invalid) ||
                      (annederm.controls['cmd_100043'].invalid && isSubmit)
                    "
                    formControlName="cmd_100043"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100043'].touched &&
                    annederm.controls['cmd_100043'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100043'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100043"
                    name="cmd_100043"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100043'].touched &&
                        annederm.controls['cmd_100043'].invalid) ||
                      (annederm.controls['cmd_100043'].invalid && isSubmit)
                    "
                    formControlName="cmd_100043"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100043'].touched &&
                    annederm.controls['cmd_100043'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100043'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100043'].touched &&
                      annederm.controls['cmd_100043'].invalid) ||
                    (annederm.controls['cmd_100043'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">22.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Have you ever been court-martialed for actions related to
                  your duties as a medical professional?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100044"
                    name="cmd_100044"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100044'].touched &&
                        annederm.controls['cmd_100044'].invalid) ||
                      (annederm.controls['cmd_100044'].invalid && isSubmit)
                    "
                    formControlName="cmd_100044"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100044'].touched &&
                    annederm.controls['cmd_100044'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100044'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100044"
                    name="cmd_100044"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100044'].touched &&
                        annederm.controls['cmd_100044'].invalid) ||
                      (annederm.controls['cmd_100044'].invalid && isSubmit)
                    "
                    formControlName="cmd_100044"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100044'].touched &&
                    annederm.controls['cmd_100044'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100044'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100044'].touched &&
                      annederm.controls['cmd_100044'].invalid) ||
                    (annederm.controls['cmd_100044'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">23.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Are you currently engaged in the illegal use of drugs?
                  (Currently means sufficiently recent to justify a reasonable
                  belief that the use of drug may have an ongoing impact on
                  one's ability to practice medicine. It is not limited to the
                  day of, or within a matter of days or weeks before the date of
                  application, rather that it has occurred recently enough to
                  indicate the individual is actively engaged in such conduct.
                  Illegal use of drugs refers to drugs whose possession or
                  distribution is unlawful under the Controlled Substances Act,
                  21 U.S.C. 812.22. It does not include the use of a drug taken
                  under supervision by a licensed health care professional, or
                  other uses authorized by the controlled Substances Act or
                  other provision of Federal law. The term does include,
                  however, the unlawful use of prescription-controlled
                  substances.)
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100045"
                    name="cmd_100045"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100045'].touched &&
                        annederm.controls['cmd_100045'].invalid) ||
                      (annederm.controls['cmd_100045'].invalid && isSubmit)
                    "
                    formControlName="cmd_100045"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100045'].touched &&
                    annederm.controls['cmd_100045'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100045'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100045"
                    name="cmd_100045"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100045'].touched &&
                        annederm.controls['cmd_100045'].invalid) ||
                      (annederm.controls['cmd_100045'].invalid && isSubmit)
                    "
                    formControlName="cmd_100045"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100045'].touched &&
                    annederm.controls['cmd_100045'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100045'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100045'].touched &&
                      annederm.controls['cmd_100045'].invalid) ||
                    (annederm.controls['cmd_100045'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <!-- 7th Page -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">24.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Do you use any chemical substances that would in any way
                  impair or limit your ability to practice medicine and perform
                  the functions of your job with reasonable skill and safety?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100046"
                    name="cmd_100046"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100046'].touched &&
                        annederm.controls['cmd_100046'].invalid) ||
                      (annederm.controls['cmd_100046'].invalid && isSubmit)
                    "
                    formControlName="cmd_100046"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100046'].touched &&
                    annederm.controls['cmd_100046'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100046'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100046"
                    name="cmd_100046"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100046'].touched &&
                        annederm.controls['cmd_100046'].invalid) ||
                      (annederm.controls['cmd_100046'].invalid && isSubmit)
                    "
                    formControlName="cmd_100046"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100046'].touched &&
                    annederm.controls['cmd_100046'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100046'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100046'].touched &&
                      annederm.controls['cmd_100046'].invalid) ||
                    (annederm.controls['cmd_100046'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">25.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Do you have any reason to believe that you would pose a risk
                  to the safety or well-being of your patients?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100047"
                    name="cmd_100047"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100047'].touched &&
                        annederm.controls['cmd_100047'].invalid) ||
                      (annederm.controls['cmd_100047'].invalid && isSubmit)
                    "
                    formControlName="cmd_100047"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100047'].touched &&
                    annederm.controls['cmd_100047'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100047'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100047"
                    name="cmd_100047"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100047'].touched &&
                        annederm.controls['cmd_100047'].invalid) ||
                      (annederm.controls['cmd_100047'].invalid && isSubmit)
                    "
                    formControlName="cmd_100047"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100047'].touched &&
                    annederm.controls['cmd_100047'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100047'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100047'].touched &&
                      annederm.controls['cmd_100047'].invalid) ||
                    (annederm.controls['cmd_100047'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1">
                <label class="normalParagraphStyle">26.</label>
              </div>
              <div class="col-md-8">
                <label class="normalParagraphStyle"
                  >Are you unable to perform the essential functions of a
                  practitioner in your area of practice even with reasonable
                  accommodation?
                </label>
              </div>
            <!-- </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div> -->
              <div class="col-md-2">
                <label class="checkbox-inline radioStyle me-2">
                  <input
                    type="radio"
                    id="cmd_100048"
                    name="cmd_100048"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100048'].touched &&
                        annederm.controls['cmd_100048'].invalid) ||
                      (annederm.controls['cmd_100048'].invalid && isSubmit)
                    "
                    formControlName="cmd_100048"
                    value="1"
                  />Yes
                </label>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100048'].touched &&
                    annederm.controls['cmd_100048'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100048'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_100048"
                    name="cmd_100048"
                    [class.is-invalid]="
                      (annederm.controls['cmd_100048'].touched &&
                        annederm.controls['cmd_100048'].invalid) ||
                      (annederm.controls['cmd_100048'].invalid && isSubmit)
                    "
                    formControlName="cmd_100048"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    annederm.controls['cmd_100048'].touched &&
                    annederm.controls['cmd_100048'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="annederm.controls['cmd_100048'].errors?.required"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (annederm.controls['cmd_100048'].touched &&
                      annederm.controls['cmd_100048'].invalid) ||
                    (annederm.controls['cmd_100048'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label
                  >**If you answered YES to question #19, please complete the
                  following: (If more than one case, please photocopy these
                  questions and submit information for each case.)</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle">Date Claim Filed:</label>
              </div>
              <div class="col-md-3 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="1209"
                  name="1209"
                  formControlName="1209"
                  type="text"
                  placeholder="CLAIM FILE DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad47="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1209')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad47.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date of Incident:</label>
              </div>
              <div class="col-md-3 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="1208"
                  name="1208"
                  formControlName="1208"
                  type="text"
                  placeholder="DATE OF OCCURNECE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad48="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1208')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad48.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1209').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1209').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1209').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1209').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1209').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-3">
                <div  *ngIf="annederm.get('1208').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1208').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1208').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1208').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1208').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Location of Incident:</label
                >
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1909"
                  name="1909"
                  formControlName="1909"
                  type="text"
                  placeholder="LOCATION OF INCIDENT"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle">Nature of Incident:</label>
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100049"
                  name="cmd_100049"
                  formControlName="cmd_100049"
                  type="text"
                  placeholder="Nature of Incident"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Who is (was) the involved carrier:</label
                >
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1210"
                  name="1210"
                  formControlName="1210"
                  type="text"
                  placeholder="MLP CLAIMS CARRIER NAME"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >What is (was) your role:</label
                >
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1218"
                  name="1218"
                  formControlName="1218"
                  type="text"
                  placeholder="PROV ROLE"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >What is (was) your status:</label
                >
              </div>
              <div class="col-md-8">
                <label class="checkbox-inline radioStyle">
                  Primary Defendant<input
                    type="radio"
                    id="cmd_100050"
                    name="cmd_100050"
                    formControlName="cmd_100050"
                    value="Primary Defendan"
                  />
                </label>
                <label class="checkbox-inline radioStyle ms-2">
                  Co-defendant<input
                    type="radio"
                    id="cmd_100050"
                    name="cmd_100050"
                    formControlName="cmd_100050"
                    value="Co-defendant"
                  />
                </label>
                <label class="checkbox-inline radioStyle ms-2">
                  Other<input
                    type="radio"
                    id="cmd_100050"
                    name="cmd_100050"
                    formControlName="cmd_100050"
                    value="Other"
                  />
                </label>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <label class="normalParagraphStyle"
                  >Current status of suit:</label
                >
              </div>
              <div class="col-md-8">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="1858"
                  name="1858"
                  formControlName="1858"
                  type="text"
                  placeholder="CLAIM STATUS"
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Outcome of Incident:</label>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Settled out of Court</label>
              </div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="radio"
                  id="cmd_100050"
                  name="cmd_100051"
                  formControlName="cmd_100051"
                  value="1"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date Settled</label>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="1226"
                  name="1226"
                  formControlName="1226"
                  type="text"
                  placeholder="SETTLEMTN DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad49="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1226')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad49.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('1226').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1226').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1226').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1226').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1226').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Dismissed</label>
              </div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="radio"
                  id="cmd_100050"
                  name="cmd_100052"
                  formControlName="cmd_100052"
                  value="1"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date Dismissed</label>
              </div>
              <div class="col-md-4  input-group">
                <input
                  class="form-control onboard datepicker"
                  id="1222"
                  name="1222"
                  formControlName="1222"
                  type="text"
                  placeholder="DISMISSAL DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad50="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1222')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad50.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('1222').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1222').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1222').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1222').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1222').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Brought to Trial</label>
              </div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="radio"
                  id="cmd_100050"
                  name="cmd_100053"
                  formControlName="cmd_100053"
                  value="1"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date</label>
              </div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="1220"
                  name="1220"
                  formControlName="1220"
                  type="text"
                  placeholder="TRIAL DATE"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad51="ngbDatepicker"
                  (blur)="clearInputIfInvalid('1220')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad51.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('1220').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('1220').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1220').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('1220').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('1220').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-2"></div>
              <div class="col-md-2"></div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Dismissed</label>
              </div>
              <div class="col-md-4">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="radio"
                  id="cmd_100054_new"
                  name="cmd_100054_new"
                  formControlName="cmd_100054_new"
                  value="1"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Defendant Found:</label>
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Liable</label>
              </div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100055"
                  id="cmd_100055"
                  formControlName="cmd_100055"
                  value="1"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Not Liable</label>
              </div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100055"
                  id="cmd_100055"
                  formControlName="cmd_100055"
                  value="1"
                />
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >By signing below, I attest that all information provided in
                  this application is timely, accurate, and complete to the best
                  of my knowledge. I understand the information provided will be
                  verified and that I will be contacted for clarification if
                  need be.</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-12 textBoxFourStyle">
                <label class="normalParagraphStyle"
                  >Please sign in the box below.</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12 textBoxFourStyle"></div>
              <div
                style="border: 1px solid black;width: 50%; height: 137px; margin-left: 25%;"
              >
                <!-- <img
                  *ngIf="!showOne"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <img
                  *ngIf="showOne"
                  class="img eSignImageShow"
                  [src]="esignOne"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <input
                  type="hidden"
                  name="cmd_10006801"
                  id="cmd_10006801"
                  formControlName="cmd_10006801"
                /> -->
                <img
                  *ngIf="!showOne"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <img
                  *ngIf="showOne"
                  class="img eSignImageShow"
                  [src]="esignOne"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <input
                  type="hidden"
                  name="cmd_10006801"
                  id="cmd_10006801"
                  formControlName="cmd_10006801"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Name (Printed):</label>
              </div>
              <div class="col-md-2">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100000102"
                  name="cmd_100000102"
                  formControlName="cmd_100000102"
                  type="text"
                  placeholder="PRINT NAME-M"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-2">
                <label class="normalParagraphStyle">Date:</label>
              </div>
              <div class="col-md-2 input-group">
                <input
                  class="form-control onboard datepicker"
                  id="cmd_100057"
                  name="cmd_100057"
                  formControlName="cmd_100057"
                  type="text"
                  placeholder="Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad61="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_100057')"
                />
                <span class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad61.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </span>
                <!-- <input class="form-control onboard datepicker" ngbDatepicker #ad16="ngbDatepicker" placeholder="MM/DD/YYYY" id="cmd_100057" name="cmd_100057" [class.is-invalid]="kidsDental.controls['cmd_100057'].value==null" formControlName="cmd_100057" type="text" data-id="Mil Active from">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar " (click)="ad16.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                </div> -->
              </div>
              <div class="col-md-7"></div>
              <div class="col-md-2">
                <div  *ngIf="annederm.get('cmd_100057').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('cmd_100057').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100057').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100057').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('cmd_100057').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <!-- 8th Page   -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-4"></div>
              <div
                class="col-md-4"
                style="text-align: center;margin-top: -14px;"
              >
                <img
                  src="./assets/images/ann_img_2.png"
                  alt="Credential my Doc"
                />
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-9">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100000103"
                  name="cmd_100000103"
                  formControlName="cmd_100000103"
                  type="text"
                  placeholder="PRINT NAME-N"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-3"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Provider’s Name (please print)</label
                >
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Please indicate which of the following procedures are
                  performed by you in the routine scope of your practice.</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100058"
                  id="cmd_100058"
                  formControlName="cmd_100058"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Biopsy of skin lesions</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100059123"
                  id="cmd_100059123"
                  formControlName="cmd_100059123"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Surgical excision of skin lesions</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100060"
                  id="cmd_100060"
                  formControlName="cmd_100060"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Repair of simple lacerations</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100061"
                  id="cmd_100061"
                  formControlName="cmd_100061"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Electrosurgical or cryosurgical destruction of benign
                  lesions</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100062"
                  id="cmd_100062"
                  formControlName="cmd_100062"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Incisions and drainage of abscesses</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100063"
                  id="cmd_100063"
                  formControlName="cmd_100063"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle">Mohs surgery</label>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100064"
                  id="cmd_100064"
                  formControlName="cmd_100064"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle"
                  >Other minor surgical procedures</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-1">
                <label class="checkbox-inline radioStyle"> </label>
                <input
                  type="checkbox"
                  name="cmd_100065"
                  id="cmd_100065"
                  formControlName="cmd_100065"
                  value="1"
                />
              </div>
              <div class="col-md-9">
                <label class="normalParagraphStyle">Dermatopathology</label>
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-9">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100066"
                  name="cmd_100066"
                  formControlName="cmd_100066"
                  type="text"
                  placeholder=""
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-9">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100067"
                  name="cmd_100067"
                  formControlName="cmd_100067"
                  type="text"
                  placeholder=""
                  data-id="managing_contact_name"
                />
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >I hereby certify that I have had training and experience in
                  the indicated procedures.</label
                >
              </div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-4" style=" text-align: center;">
                <!-- <img
                  *ngIf="!showTwo"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <img
                  *ngIf="showTwo"
                  class="img eSignImageShow"
                  [src]="esignTwo"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <input
                  type="hidden"
                  name="cmd_100068"
                  id="cmd_100068"
                  formControlName="cmd_100068"
                  value="{{ finalImgPathTwo }}"
                /> -->
                <img
                  *ngIf="!showTwo"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <img
                  *ngIf="showTwo"
                  class="img eSignImageShow"
                  [src]="esignTwo"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <input
                  type="hidden"
                  name="cmd_100068"
                  id="cmd_100068"
                  formControlName="cmd_100068"
                />
              </div>
              <div class="col-md-4 input-group ">
                
                <input
                  class="form-control onboard datepicker "
                  id="cmd_100069"
                  name="cmd_100069"
                  formControlName="cmd_100069"
                  type="text"
                  placeholder="Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad54="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_100069')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad54.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('cmd_100069').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('cmd_100069').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100069').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100069').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('cmd_100069').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4 textBoxFourStyle">
                <label
                  style="font-weight: normal;font-size: 14px;text-align: center;"
                  >⁭Signature:</label
                >
              </div>
              <div class="col-md-4 textBoxFourStyle">
                <label
                  style="font-weight: normal;font-size: 14px;text-align: center;"
                  >Date:</label
                >
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <!-- 9th Page   -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-2"></div>
              <div
                class="col-md-8"
                style="text-align: center;margin-top: -14px;"
              >
                <img src="./assets/images/anne_4.png" alt="Credential my Doc" />
              </div>
              <div class="col-md-2"></div>
            </div>
          </tr>
          <br /><br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle">
                  Applicant agrees to participate in the
                  credentialing/re-credentialing program as established by Anne
                  Arundel Dermatology. Applicant consents to the release of
                  information for the purpose of proper evaluation of his/her
                  professional competence, character, ethics and other
                  qualifications and for resolving any doubts about such
                  qualifications. Applicant further agrees to release from any
                  and all liability any physician, hospital or other person or
                  entity providing information which, but for such waiver, would
                  be privileged and confidential. Furthermore, a copy of these
                  statements shall be as binding as the original. Applicant also
                  understands that all information submitted on or with this
                  form and/or the CAQH Universal Provider Datasource is subject
                  to investigation and review by Anne Arundel Dermatology.
                  Applicant understands and agrees that he/she has the burden of
                  producing information for proper evaluation of professional
                  competence, character, ethics and other qualifications and for
                  resolving any doubts about such qualifications. Applicant
                  further understands that it is his/her responsibility to
                  notify Anne Arundel Dermatology in writing within 30 days of
                  any changes or additions to the information submitted on or
                  with this form and/or the CAQH Universal Provider Datasource.
                </label>
              </div>
            </div>
          </tr>
          ⁭<br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Applicant authorizes the credentialing team at Anne Arundel
                  Dermatology to:</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span> Review, edit and attest their CAQH
                  profile</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span>Review, edit and electronically sign their
                  Medicare profile via PECOS</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span>Submit personal and professional data to
                  health insurance companies for the purpose of
                  credentialing/recredentialing.</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >Applicant understands that he/she has the right to:</label
                >
              </div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span> Review, edit and attest their CAQH
                  profile</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span> Review information obtained through primary
                  source verification for credentialing purposes. This includes
                  information from malpractice insurance carriers and state
                  licensing boards. This does not include information. collected
                  from references, recommendations and other peer-review
                  protected information.</label
                >
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span> Be notified if any credentialing information
                  is received that varies substantially from application
                  information submitted by the practitioner. The practitioner
                  will be notified of any of the following types of variances:
                  e.g., actions on license, malpractice claim history,
                  suspension or termination of hospital privileges or
                  board-certification decisions with the exception of
                  references, recommendations or other peer-review protected
                  information.
                </label>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-11">
                <label class="normalParagraphStyle"
                  ><span>◆ </span> The practitioner will have the right to
                  correct erroneous information if the credentialing information
                  received varies substantially from the information that was
                  submitted on his/her application.
                </label>
              </div>
            </div>
          </tr>
          ⁭<br />
          <tr>
            <div class="row">
              <div class="col-md-12">
                <label class="normalParagraphStyle"
                  >I certify that all information included in my application and
                  the accompanying documents are correct and complete to the
                  best of my knowledge.
                </label>
              </div>
            </div>
          </tr>
          ⁭<br />
          <tr>
            <div class="row">
              <div class="col-md-4">
                <label class="normalParagraphStyle"
                  >Provider’s Name (Printed):</label
                >
              </div>
              <div class="col-md-6">
                <input
                  class="form-control onboard append_office_manager_first_name_1"
                  id="cmd_100000104"
                  name="cmd_100000104"
                  formControlName="cmd_100000104"
                  type="text"
                  placeholder="PRINT NAME-O"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-4 textBoxFourStyle">
                <!-- <img
                  *ngIf="!showThree"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenThree()"
                />
                <img
                  *ngIf="showThree"
                  class="img eSignImageShow"
                  [src]="esignThree"
                  alt="Credential my Doc"
                  (click)="esignOpenThree()"
                />
                <input
                  type="hidden"
                  name="cmd_100070"
                  id="cmd_100070"
                  formControlName="cmd_100070"
                  value="{{ finalImgPathThree }}"
                /> -->
                <img
                  *ngIf="!showThree"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenThree()"
                />
                <img
                  *ngIf="showThree"
                  class="img eSignImageShow"
                  [src]="esignThree"
                  alt="Credential my Doc"
                  (click)="esignOpenThree()"
                />
                <input
                  type="hidden"
                  name="cmd_100070"
                  id="cmd_100070"
                  formControlName="cmd_100070"
                />
              </div>
              <div class="col-md-4 input-group  ">
                <input
                  class="form-control onboard datepicker"
                  id="cmd_100071"
                  name="cmd_100071"
                  formControlName="cmd_100071"
                  type="text"
                  placeholder="Date"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad53="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_100071')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad53.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                
              </div>
              <div class="col-md-4">
                <div  *ngIf="annederm.get('cmd_100071').invalid ">
                  <small class="text-danger" *ngIf="annederm.get('cmd_100071').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100071').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100071').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="annederm.get('cmd_100071').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4 textBoxFourStyle">
                <label
                  style="font-weight: normal;font-size: 14px;text-align: center;"
                  >⁭Signature:</label
                >
              </div>
              <div class="col-md-4 textBoxFourStyle">
                <label
                  style="font-weight: normal;font-size: 14px;text-align: center;"
                  >Date:</label
                >
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <!-- 10th Page   -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-12 textBoxFourStyle">
                <h3 style="text-align: center;">
                  <b>Standard Authorization, Attestation and Release</b>
                </h3>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  I understand and agree that, as part of the credentialing
                  application process for participation, membership and/or
                  clinical privileges (hereinafter, referred to as
                  "Participation") at or with each healthcare organization
                  indicated on the "List of Authorized Organizations" that
                  accompanies this Provider Application (hereinafter, each
                  healthcare organization on the "List of Authorized
                  Organizations" is individually referred to as the "Entity"),
                  and any of the Entity's affiliated entities, I am required to
                  provide sufficient and accurate information for a proper
                  evaluation of my current licensure, relevant training and/or
                  experience, clinical competence, health status, character,
                  ethics, and any other criteria used by the Entity for
                  determining initial and ongoing eligibility for Participation.
                  Each Entity and its representatives, employ - ees, and
                  agent(s) acknowledge that the information obtained relating to
                  the application process will be held confidential to the
                  extent permitted by law.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  I acknowledge that each Entity has its own criteria for
                  acceptance, and I may be accepted or rejected by each
                  independently. I further acknowledge and understand that my
                  cooperation in obtaining information and my consent to the
                  release of information do not guarantee that any Entity will
                  grant me clinical privileges or contract with me as a provider
                  of services. I understand that my application for
                  Participation with the Entity is not an application for
                  employment with the Entity and that acceptance of my
                  application by the Entity will not result in my employment by
                  the Entity.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  <b
                    >Authorization of Investigation Concerning Application for
                    Participation.</b
                  >
                  I authorize the following individuals including, without
                  limitation, the Entity, its representatives, employees, and/or
                  designated agent(s); the Entity's affiliated entities and
                  their representatives, employees, and/or designated agents;
                  and the Entity's designated professional credentials
                  verification organization (collectively referred to as
                  "Agents"), to investigate information, which includes both
                  oral and written statements, records, and documents,
                  concerning my application for Participation. I agree to allow
                  the Entity and/or its Agent(s) to inspect and copy all records
                  and documents relating to such an investigation.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  <b
                    >Authorization of Third-Party Sources to Release Information
                    Concerning Application for Participation.</b
                  >
                  I authorize any third party, including, but not limited to,
                  individuals, agencies, medical groups responsible for
                  credentials verification, corporations, companies, employers,
                  former employers, hospitals, health plans, health maintenance
                  organizations, managed care organizations, law enforcement or
                  licensing agencies, insurance companies, educational and other
                  institutions, military services, medical credentialing and
                  accreditation agencies, professional medical societies, the
                  Federation of State Medical Boards, the National Practitioner
                  Data Bank, and the Health Care Integrity and Protection Data
                  Bank, to release to the Entity and/or its Agent(s),
                  information, including otherwise privileged or confidential
                  information, concerning my professional qualifications,
                  credentials, clinical competence, quality assurance and
                  utilization data, character, mental condition, physical
                  condition, alcohol or chemical dependency diagnosis and
                  treatment, ethics, behavior, or any other matter reasonably
                  having a bearing on my qualifications for Participation in, or
                  with, the Entity. I authorize my current and past professional
                  liability carrier(s) to release my history of claims that have
                  been made and/or are currently pending against me. I
                  specifically waive written notice from any entities and
                  individuals who provide information based upon this
                  Authorization, Attestation and Release.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  <b
                    >Authorization of Release and Exchange of Disciplinary
                    Information.</b
                  >
                  I hereby further authorize any third party at which I
                  currently have Participation or had Participation and/or each
                  third party's agents to release "Disciplinary Information," as
                  defined below, to the Entity and/or its Agent(s). I hereby
                  further authorize the Agent(s) to release Disciplinary
                  Information about any disciplinary action taken against me to
                  its participating Entities at which I have Participation, and
                  as may be otherwise required by law. As used herein,
                  "Disciplinary Information" means information concerning (i)
                  any action taken by such health care organizations, their
                  administrators, or their medical or other committees to
                  revoke, deny, suspend, restrict, or condition my Participation
                  or impose a corrective action plan; (ii) any other
                  disciplinary action involving me, including, but not limited
                  to, discipline in the employment context; or (iii) my
                  resignation prior to the conclusion of any disciplinary
                  proceedings or prior to the commencement of formal charges,
                  but after I have knowledge that such formal charges were being
                  (or are being) contemplated and/or were (or are) in
                  preparation.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  <b>Release from Liability.</b> I release from all liability
                  and hold harmless any Entity, its Agent(s), and any other
                  third party for their acts performed in good faith and without
                  malice unless such acts are due to the gross negligence or
                  willful misconduct of the Entity, its Agent(s), or other third
                  party in connection with the gathering, release and exchange
                  of, and reliance upon, information used in accordance with
                  this Authorization, Attestation and Release. I further agree
                  not to sue any Entity, any Agent(s), or any other third party
                  for their acts, defamation or any other claims based on
                  statements made in good faith and without malice or misconduct
                  of such Entity, Agent(s) or third party in connection with the
                  credentialing process. This release shall be in addition to,
                  and in no way shall limit, any other applicable immunities
                  provided by law for peer review and credentialing activities.
                  In this Authorization, Attestation and Release, all references
                  to the Entity, its Agent(s), and/or other third party include
                  their respective employees, directors, officers, advisors,
                  counsel, and agents. The Entity or any of its affiliates or
                  agents retains the right to allow access to the application
                  information for purposes of a credentialing audit to customers
                  and/or their auditors to the extent required in connection
                  with an audit of the credentialing processes and provided that
                  the customer and/or their auditor executes an appropriate
                  confidentiality agreement. I understand and agree that this
                  Authorization, Attestation and Release is irrevocable for any
                  period during which I am an applicant for Participation at an
                  Entity, a member of an Entity's medical or health care staff,
                  or a participating provider of an Entity. I agree to execute
                  another form of consent if law or regulation limits the
                  application of this irrevocable authorization. I understand
                  that my failure to promptly provide another consent may be
                  grounds for termination or discipline by the Entity in
                  accordance with the applicable bylaws, rules, and regulations,
                  and requirements of the Entity, or grounds for my termination
                  of Participation at or with the Entity. I agree that
                  information obtained in accordance with the provisions of this
                  Authorization, Attestation and Release is not and will not be
                  a violation of my privacy
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <p style="font-size: 11px;">
                  I certify that all information provided by me in my application
                  is current, true, correct, accurate and complete to the best
                  of my knowledge and belief, and is furnished in good faith. I
                  will notify the Entity and/or its Agent(s) within 10 days of
                  any material changes to the information (including any
                  changes/challenges to licenses, DEA, insurance, malpractice
                  claims, NPDB/HIPDB reports, discipline, criminal convictions,
                  etc.) I have provided in my application or authorized to be
                  released pursuant to the credentialing process. I understand
                  that corrections to the application are permitted at any time
                  prior to a determination of Participation by the Entity, and
                  must be submitted online or in writing, and must be dated and
                  signed by me (may be a written or an electronic signature). I
                  acknowledge that the Entity will not process an application
                  until they deem it to be a complete application and that I am
                  responsible to provide a complete application and to produce
                  adequate and timely information for resolving questions that
                  arise in the application process. I understand and agree that
                  any material misstatement or omission in the application may
                  constitute grounds for withdrawal of the application from
                  consideration; denial or revocation of Participation; and/or
                  immediate suspension or termination of Participation. This
                  action may be disclosed to the Entity and/or its Agent(s). I
                  further acknowledge that I have read and understand the
                  foregoing Authorization, Attestation and Release and that I
                  have access to the bylaws of applicable medical staff
                  organizations and agree to abide by these bylaws, rules and
                  regulations. I understand and agree that a facsimile or
                  photocopy of this Authorization, Attestation and Release shall
                  be as effective as the original.
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-4 textBoxFourStyle">
                <!-- <img
                  *ngIf="!showFour"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <img
                  *ngIf="showFour"
                  class="img eSignImageShow"
                  [src]="esignFour"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <input
                  type="hidden"
                  name="cmd_100072"
                  id="cmd_100072"
                  formControlName="cmd_100072"
                  value="{{ finalImgPathFour }}"
                /> -->
                <img
                  *ngIf="!showFour"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <img
                  *ngIf="showFour"
                  class="img eSignImageShow"
                  [src]="esignFour"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <input
                  type="hidden"
                  name="cmd_100072"
                  id="cmd_100072"
                  formControlName="cmd_100072"
                />
              </div>
              <div class="col-md-4">
                <input
                  class="form-control  onboard append_office_manager_first_name_1"
                  id="cmd_100000105"
                  name="897"
                  formControlName="cmd_100000105"
                  type="text"
                  placeholder="PRINT NAME-P"
                  data-id="managing_contact_name"
                />
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-4 textBoxFourStyle">
                <label
                class=" normalParagraphStyle"
                  >⁭Signature*</label
                >
              </div>
              <div class="col-md-4">
                <label class=" normalParagraphStyle">Name (print)*</label>
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <br />
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4 input-group ">
                <input
                  class="form-control onboard datepicker"
                  id="cmd_100073"
                  name="cmd_100073"
                  formControlName="cmd_100073"
                  type="text"
                  placeholder="DATE SIGNED"
                  data-id="managing_contact_name"
                  ngbDatepicker
                  #ad55="ngbDatepicker"
                  (blur)="clearInputIfInvalid('cmd_100073')"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="ad55.toggle()"
                    type="button"
                  >
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-7"></div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div  *ngIf="annederm.get('cmd_100073').invalid ">
                <small class="text-danger" *ngIf="annederm.get('cmd_100073').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="annederm.get('cmd_100073').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="annederm.get('cmd_100073').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="annederm.get('cmd_100073').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              
            </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label
                  style="font-weight: normal;font-size: 14px;text-align: center;"
                  >DATE SIGNED*</label
                >
              </div>
              <div class="col-md-7"></div>
            </div>
          </tr>
          <!-- 11th Page   -->
          <hr
            style="border: 17px solid #818181; margin-left: -87px;margin-right: -87px; margin-top: 50px; margin-bottom: 50px;"
          />
          <tr>
            <div class="row">
              <div class="col-md-4"></div>
              <div
                class="col-md-4"
                style="text-align: center;margin-top: -14px;"
              >
                <img src="./assets/images/anne_5.png" alt="Credential my Doc" />
              </div>
              <div class="col-md-4"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;">
                  The application and list of required documents shown below
                  should be returned to the Credentialing Manager via email
                  and/or mail. If you have any questions or concerns please
                  don’t hesitate to contact Faith Deavers directly.
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-12 textBoxFourStyle">
                <h3>
                  <b><u>List of Required Documents</u></b>
                </h3>
              </div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>State Professional License(s)
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>DEA License(s) (if applicable)
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>CDS License(s) (if applicable)
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>Board Certificate(s)
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>Malpractice Certificate
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>Diploma(s)
                </label>
              </div>
              <div class="col-md-1"></div>
            </div>
          </tr>
          <tr>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <label style="font-weight: normal;font-size: 17px;"
                  ><span>▪ </span>CV (In Word Format)
                </label>
              </div>

              <div class="col-md-1"></div>
            </div>
          </tr>
        </table>

        <br /><br /><br/>
        <div class="row text-center">
          <div class="col-md-12 ">
            <h3>
              <b><u>AAD Credentialing Team</u></b>
            </h3>
          </div>
        </div>

        <!-- <div class="row text-center">
          <div class="col-md-12" style="text-align: center; ">
            <h4><b>Lisa Albea, CPCS </b></h4>
          </div>
        </div> -->

        <!-- <br /><br /> -->
        

        <div class="row text-center">
          <div class="col-md-12 ">
            <h4>
              <a href="credentialing@aadermatology.com"
                ><u>credentialing@aadermatology.com </u></a
              >
            </h4>
          </div>
        </div>
       
        
        <div class="row text-center">
          <div class="col-md-12 ">
            <h3>
              <b style="font-size:16px;">If you have any questions or concerns, please do not hesitate to contact the AAD Credentialing Team directly.</b>
            </h3>
          </div>
        </div>        
        <div class="row text-center">
          <div class="col-md-12 textBoxTwoStyle">
            <label class=" normalParagraphStyle"><b><h4>Direct: 727-434-3118</h4></b></label>
          </div>
        </div>
        
      </form>
    </div>
  </div>
  <div class="modal-footer mt-4">
    <button
      *ngIf="formData.receivedForm"
      type="button"
      class="btn customOrange float-end"
      (click)="saveForm('approve')"
    >
      Approve
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('submit')"
    >
      Submit
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('save')"
    >
      Save
    </button>
    <button
      *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-end"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
