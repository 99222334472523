import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { EsignComponent } from '../esign/esign.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-cali-med',
    templateUrl: './cali-med.component.html',
    styleUrls: ['./cali-med.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class CaliMedComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public caliMed: UntypedFormGroup;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  cName:any;
  eName:any;
  ImgOne: any;
  imgOneSet: any;
  imgOneSetting: any;
  esignOne: any;
  esignTwo:any;
  esignThree:any
  finalImgPath: any = [];
  finalImgPathTwo: any = [];
  finalImgPathThree: any = [];
  public customerId: any;
  finalImg: any;
  finalImgTwo: any ;
  finalImgThree: any ;
  firstName: any;
  lastName: any;
  finalName: any;
  states: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  race: Array<object> = [];
  county: Array<object> = [];
  specialty: Array<object> = [];
  degree: Array<object> = [];
  isSubmit: boolean = false;
  public preClaim: any = [];
  public aggregates: any = [];
  gender: Array<object> = [];
  staffStatus: Array<object> = [];
  public preAffiliation: boolean = false;
  public proLiabilities: boolean = false;
  public ynchange: any = false;
  public doits: any;
  public iypls: any;
  public ayars: any;

  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getGenderData();
    this.getCountyData();
    this.getStatesData();
    this.getPreClaimData();
    this.getStaffStatusData();
    this.getAggregatesData();
    this.getRaceData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  buildForm() {
    this.caliMed = this.fb.group({
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      809: [''],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      708: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      759: ['', [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      292: ['', [Validators.required]],
      32: ['', [Validators.required]],
      30: [null, [Validators.required]],
      741: [null, [Validators.required]],
      277: [null, [Validators.required]],
      1535: [null, [Validators.required]],
      1536: [null, [Validators.required]],
      2207: [null, [Validators.required]],
      333: ['', [Validators.required]],
      334: ['', [Validators.required]],
      335: ['', [Validators.required]],
      293: ['', [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      309: ['', [Validators.required]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      1634: ['', [Validators.required]],
      514: ['', [Validators.required]],
      515: [null, [Validators.required,this.dateFormatValidator]],
      516: [null, [Validators.required,this.dateFormatValidator]],
      325: ['', [Validators.required]],
      510: [null,[Validators.required,this.dateFormatValidator]],
      511: [null, [Validators.required,this.dateFormatValidator]],
      812: ['', [Validators.required]],
      359: ['', [Validators.required]],
      360: ['', [Validators.required]],
      361: ['', [Validators.required]],
      362: ['', [Validators.required]],
      365: ['', [Validators.required]],
      364: [null, [Validators.required]],
      368: [null, [Validators.required]],
      369: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      371: ['', [Validators.required]],
      372: ['', [Validators.required]],
      373: ['', [Validators.required]],
      374: ['', [Validators.required]],
      375: ['', [Validators.required]],
      378: ['', [Validators.required]],
      377: [null, [Validators.required]],
      380: [null, [Validators.required]],
      381: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      383: ['', [Validators.required]],
      384: ['', [Validators.required]],
      385: ['', [Validators.required]],
      386: ['', [Validators.required]],
      387: ['', [Validators.required]],
      390: ['', [Validators.required]],
      389: [null, [Validators.required]],
      393: [null, [Validators.required]],
      394: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      396: ['', [Validators.required]],
      503: ['', [Validators.required]],
      504: ['', [Validators.required]],
      505: ['', [Validators.required]],
      506: ['', [Validators.required]],
      509: ['', [Validators.required]],
      512: [null, [Validators.required]],
      479: [null],
      483: [null],
      484: [null],
      485: [null],
      488: [null],
      480: [null],
      481:[null, [this.dateFormatValidator]],
      482: [null, [this.dateFormatValidator]],
      490: [''],
      1289: [''],
      1290: [''],
      1291: [''],
      1294: [''],
      1298: [null],
      1297: [null, [this.dateFormatValidator]],
      1295: [null, [this.dateFormatValidator]],
      445: ['', [Validators.required]],
      454: [null, [Validators.required,this.dateFormatValidator]],
      455: [null, [Validators.required,this.dateFormatValidator]],
      446: ['', [Validators.required]],
      447: ['', [Validators.required]],
      448: ['', [Validators.required]],
      451: ['', [Validators.required]],
      450: [null, [Validators.required]],
      452: ['', [Validators.required]],
      453: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      889: ['', [Validators.required]],
      1138: ['', [Validators.required]],
      457: [''],
      456: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      458: [''],
      459: [''],
      462: [''],
      461: [null],
      463: [''],
      464: [ '',Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')],
      891: [''],
      1831: [''],
      18 : [''],
      19 : [null],
      20 : [null],
      21 : [''],
      467: [null],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      468: [''],
      469: [''],
      470: [''],
      473: [null],
      472: [null],
      474: [''],
      475: ['',Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')],
      893: [''],
      1832: [''],
      1935: [''],
      1940: [null],
      1288: [null],
      cmd_100018: ['', [Validators.required]],
      cmd_100046: ['', [Validators.required]],
      cmd_100035: ['', [Validators.required]],
      cmd_100047: ['', [Validators.required]],
      cmd_100015: ['', [Validators.required]],
      cmd_100016: ['', [Validators.required]],
      cmd_100017: [''],
      cmd_100019: [''],
      cmd_100020: [''],
      cmd_100022: [''],
      cmd_100021: [''],
      cmd_100023: [''],
      418: ['', [Validators.required]],
      419: ['', [Validators.required]],
      420: ['', [Validators.required]],
      421: ['', [Validators.required]],
      423: [null, [Validators.required]],
      424: ['', [Validators.required]],
      425: ['', [Validators.required]],
      894: [null, [Validators.required]],
      426: [null, [Validators.required,this.dateFormatValidator]],
      427: [''],
      428: [''],
      429: [''],
      430: [''],
      432: [null],
      433: [''],
      434: [''],
      895: [null],
      435: [null, [this.dateFormatValidator]],
      345: ['', [Validators.required]],
      346: ['', [Validators.required]],
      348: ['', [Validators.required,this.dateFormatValidator]],
      901: ['', [Validators.required]],
      902: ['', [Validators.required]],
      906: ['', [Validators.required]],
      903: ['', [Validators.required]],
      905: [null, [Validators.required]],
      350: [null, [Validators.required]],
      351: [null, [Validators.required]],
      349: [null, [Validators.required,this.dateFormatValidator]],
      1242: [''],
      1244: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: [''],
      1256: [''],
      1252: [null, [this.dateFormatValidator]],
      1253: [null, [this.dateFormatValidator]],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      1936: [''],
      1937: [''],
      1938: [''],
      1941: [''],
      1943: [''],
      1944: ['',Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')],
      1946: [''],
      1953: [''],
      
      cmd_NA_01:[null],
      cmd_100024: ['', [Validators.required]],
      cmd_100025: ['', [Validators.required]],
      cmd_100026: ['', [Validators.required]],
      cmd_100027: ['', [Validators.required]],
      cmd_100028: ['', [Validators.required]],
      cmd_100029: ['', [Validators.required]],
      cmd_100030: ['', [Validators.required]],
      cmd_100031: ['', [Validators.required]],
      cmd_100032: ['', [Validators.required]],
      cmd_100033: ['', [Validators.required]],
      cmd_100034: ['', [Validators.required]],
      cmd_100036: ['', [Validators.required]],
      cmd_292: ['', [Validators.required]],
      cmd_100037: ['', [Validators.required]],
      cmd_100038: ['', [Validators.required]],
      cmd_100039: ['', [Validators.required]],
      cmd_100040: ['', [Validators.required]],
      cmd_100041: ['', [Validators.required]],
      cmd_100042: ['', [Validators.required]],
      cmd_100043: ['', [Validators.required]],
      1141: [''],
      1145: [null,this.dateFormatValidator],
      cmd_100044: [''],
      cmd_100045: [null, [Validators.required,this.dateFormatValidator]],
      cmd_00050: ['', [Validators.required]],
      cmd_43554: ['', [Validators.required]],
      cmd_10354:[null,[Validators.required,this.dateFormatValidator]],
      cmd_355:['',Validators.required],
      cmd_435541: ['', [Validators.required]],
      cmd_103541:[null,[Validators.required,this.dateFormatValidator]],
      cmd_3551:['',Validators.required],
      cmd_35512:[""], 
      cmd_35513:[""],
      cmd_35514:[""],
      cmd_35515:[""],
    });
    console.log(this.caliMed);
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_00050') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_355') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_3551') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.caliMed.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    console.log(filePath);
    if (fieldId == 'cmd_00050') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_355') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
         'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
   }
   if (fieldId == 'cmd_3551') {
    this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
       'data:image/jpg;base64,' + filePath
    );
    this.showThree = true;
 }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 30,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  // setErrorControl(name){
  //   this.caliMed.controls[name].setErrors({ errors: true });
  // }
  // removeErrorControl(name){
  //   this.caliMed.controls[name].setErrors(null)
  // }

  handleNAchange(cName,eName?){
    // console.log(cName);
    // console.log(this.onPointForm.controls[cName].value);
    let result:boolean=false;
    if (cName == "cmd_NA_01") {
    
    
        if((this.caliMed.controls['809'].value=='' || this.caliMed.controls['809'].value==null) && !this.caliMed.controls[cName].value){
          this.setErrorControl('809');
          result = true
        }
        else{
          this.removeErrorControl('809');
        }
    
      }
  }

  saveForm(type) {
    console.log(this.caliMed);
    this.isSubmit = true;
    this.handleYesNoChanges('cmd_100043');
    this.handleYesNoChanges(this.cName);
      this.handleNAchange('cmd_NA_01');
    if (type == 'submit') { 
      if (this.caliMed.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.caliMed.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.caliMed.value,
        formId: 30
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_00050: this.finalImgPath
      };
      this.caliMed.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  public esignOpenTwo()
  {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPathTwo = data.finalFilePath;
           let temp = {
           cmd_355: this.finalImgPathTwo
           };
        this.caliMed.patchValue(temp);
        this.finalImgPathTwo = data.finalFilePath;
        this.finalImgTwo = data.base64Img;
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImgTwo
           );
        this.showTwo = true;
    });
   }
   public esignOpenThree()
   {
     const modalRef = this.modalService.open(EsignComponent, this.modalOption);
     modalRef.result.then(result => { });
     modalRef.componentInstance.base64Img.subscribe(data => {
         modalRef.close();
         this.finalImgPathThree = data.finalFilePath;
            let temp = {
            cmd_3551: this.finalImgPathThree
            };
         this.caliMed.patchValue(temp);
         this.finalImgPathThree = data.finalFilePath;
         this.finalImgThree = data.base64Img;
         this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
           'data:image/jpg;base64,' + this.finalImgThree
            );
         this.showThree = true;
     });
    }
  setErrorControl(name){
    this.caliMed.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.caliMed.controls[name].setErrors(null)
  }

  handleYesNoChanges(cName,eName?){
    if (cName == "cmd_100016") {
      let x=['cmd_100017']
      x.forEach(element => {
        if((this.caliMed.controls[element].value=='' || this.caliMed.controls[element].value==null) && (this.caliMed.controls[cName].value==1 || this.caliMed.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }

    if (cName == "cmd_100018") {
      let x=['cmd_100019']
      x.forEach(element => {
        if((this.caliMed.controls[element].value=='' || this.caliMed.controls[element].value==null) && (this.caliMed.controls[cName].value==1 || this.caliMed.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_100043") {
      let x=['1141','1145']

      
  
      x.forEach(element => {
        const ctrl = this.caliMed.controls[element];
        if((this.caliMed.controls[element].value=='' || this.caliMed.controls[element].value==null) && (this.caliMed.controls[cName].value==1 || this.caliMed.controls[cName].value==null)){
          this.setErrorControl(element);
        }

        else{
          this.removeErrorControl(element);
        }
      });
    }
  }
  doit(event) {
    this.doits = event.target.value;
    if (this.doits == 0) {
      this.caliMed.controls['cmd_100017'].setErrors(null);
    }
    else if (this.doits == 1) {
      this.caliMed.controls['cmd_100017'].setErrors({ errors: true });
    }
  }
  
  iypl(event) {
    this.iypls = event.target.value;
    if (this.iypls == 0) {
      this.caliMed.controls['cmd_100019'].setErrors(null);
    }
  }

  // ayar(event) {
  //   this.ayars = event.target.value;
  //   if (this.ayars == 0) {
  //     this.caliMed.controls['1141'].setErrors(null);
  //     this.caliMed.controls['1145'].setErrors(null);
  //   }
  // }

  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_100044: this.finalName,
      cmd_43554:this.finalName,
      cmd_435541:this.finalName
    };
    this.caliMed.patchValue(temp);
  }

  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_100044: this.finalName,
      cmd_43554:this.finalName,
      cmd_435541:this.finalName
    };
    this.caliMed.patchValue(temp);
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.caliMed.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      if(handleYesNoValue == ("cmd_100043")){
        this.handleYesNoChanges(handleYesNoValue);
      }
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.caliMed.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
