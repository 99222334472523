import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlSegment, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { CommonService } from '../../../shared/services/common/common.service'
import { UserFieldConstant } from '../../../shared/constants/user-field-constants';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard  {
  public authToken;
  private isAuthenticated

  constructor(private router: Router, public auth: AuthService,
    private commonService: CommonService,
    private storageService: StorageService) { }
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

   
    this.isAuthenticated = this.auth.isLoggedIn() ? true : false;
    let UserPermission = this.storageService.getItem('userPermission') 

    let currentUserPermission = JSON.parse(UserPermission)
    
    if (!this.isAuthenticated) {
      this.router.navigate(['']);
      return false;
    }
     
     if (route.data && route.data.role && currentUserPermission) {
    
      if (currentUserPermission && currentUserPermission.type_id == 2 && (currentUserPermission && currentUserPermission.role_id == 1 || currentUserPermission && currentUserPermission.isShowAll)) {
        return true
      }
      else if (currentUserPermission && currentUserPermission.type_id == 2
         && !currentUserPermission.isShowAll
         &&(currentUserPermission.role_id == 2 || currentUserPermission.role_id == 3 ||
           currentUserPermission.role_id == 4 || currentUserPermission.role_id == 5 || currentUserPermission.role_id ==6 )
        && !(currentUserPermission.chk_ids && [...currentUserPermission.chk_ids.split(",")].some(item => route.data.role.includes(item))))  {

   // add addtional route here 
        if ((currentUserPermission.onboarding_forms_access == '1' && route.data.role.includes(UserFieldConstant.onBoardingFormAccess))
          || (currentUserPermission.dop_access == '1' && route.data.role.includes(UserFieldConstant.dopAccess))
          || (currentUserPermission.caqh_sync_access == '1' && route.data.role.includes(UserFieldConstant.caqhsyncAccess))
              || (currentUserPermission.canned_report_scheduler == '1' && route.data.role.includes(UserFieldConstant.cannedReportScheduleAccess))
                || (currentUserPermission.onboarding_forms_access == '1' && route.data.role.includes(UserFieldConstant.onBoardingFormAccess))
                  || (currentUserPermission.docusign_access == '1' && route.data.role.includes(UserFieldConstant.docsignAccess))
          || (currentUserPermission.api_access == '1' && route.data.role.includes(UserFieldConstant.apiAccess))) {
          return true
        }

       this.router.navigate(['']);
        return false
      } 
       // route realted to provider login 
      else if (currentUserPermission && (currentUserPermission.type_id == 3 || currentUserPermission.type_id == 4 || currentUserPermission.type_id == 5) && route.data && route.data.role){
      
       if (route.data.role.includes(UserFieldConstant.providerlogin) || route.data.role.includes(UserFieldConstant.pendingForm) ){
      
        return true        
       }else{
         this.router.navigate(['']);
         return false
       }

       }
      
      }
      return true
  }
  }



