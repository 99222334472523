import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';
import { DicksonMedicalAssociatesNP1Component } from './dicksonnppo/dickson-medical-associates-npo1.component';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';


@Component({
    selector: 'dickson-med-np',
    templateUrl: './dickson-medical-associates-npo.component.html',
    styleUrls: ['./dickson-medical-associates-npo.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgSelectModule, NgIf, NgbDatepickerModule, DicksonMedicalAssociatesNP1Component]
})
export class DicksonMedicalAssociatesNPComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private _sanitizer: DomSanitizer
  ) { }
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  
  public DicksonNPFroms: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  credentials: Array<object> = [];
  states: Array<object> = [];
  degree: Array<object> = [];
  specialty: Array<object> = [];
  gender: Array<object> = [];
  removeFieldVAlue:boolean=false;
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  boardCertification: Array<object> = [];
  specialtySecondary: Array<object> = [];
  specialtyTer: Array<object> = [];
  staffStatus: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  county: Array<object> = [];
  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  showOne: any = false;
  isSubmit: boolean = false;
  // changeCmdOne: any;
  changeCmdTwo: any;
  // changeCmdThree: any;
  doesNotApplyVar: any;
  public eduapp: boolean = false;
  public workapp: boolean = false;
  public hospapp: boolean = false;
  public refapp: boolean = false;
  public proapp: boolean = false;
  public premlpapp: boolean = false;
  public prospeapp: boolean = false;
  public licenseapp: boolean = false;
  public dataRadio: boolean = false;

  firstName: any;
  lastName: any;
  finalName: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  yesNoQuestionIds: any = [];
  yesNoQuestionExplanation: any = [];
  questionCounter = 0;

  // questionCounter = 0;
  // answerCounter = 0;
  // questionNumbers = '';
  indexKey = 0;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  customerId = 0;
  private zippattern=/^.{5,9}$/;
  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    console.log(this.customerId)
    this.buildForm();
    this.getCredentialsData();
    this.getGenderData();
    this.getSpecialtyData();
    this.getSuffixData();
    this.getDegreeData();
    this.getStatesData();
    this.getAltCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getBoardCertificationData();
    this.getSpecialtySecondaryData();
    this.getStaffStatusData();
    this.getTerSpecialtyData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getCountyData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    // if(this.formData.receivedForm == true){
        // console.log(this.formData.receivedForm);
        this.afterSaveDataDisplay();
    // }
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_11122') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      
    }
    console.log(fieldsCtrls);
    this.DicksonNPFroms.patchValue(fieldsCtrls);
    
  }

  // returnImgPath(){
  //   let x="./assets/images/MicrosoftTeams-image.png"
  //   if (this.customerId == 16859) {
  //     x = './assets/images/commitService.jpg'
  //   }
  //   else if (this.customerId == 17047) {
  //     x='./assets/images/STI-logo.png'
  //   }
  //   return x
  // }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.DicksonNPFroms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
        this.handleYesNoChanges(handleYesNoValue);
      }
      else{
          this.handleNAchange(handleYesNoValue);
      }
      
      
    }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.DicksonNPFroms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  
  public zipCodeValidator(control: FormControl): ValidationErrors | null {
    const zipCode = control.value;
    const zipCodePattern = /^.{5,9}$/;
  
    if (zipCode && !zipCode.match(zipCodePattern)) {
      return { 'invalidZipCode': true };
    }
  
    return null;
  }
  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_11122') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 50,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  buildForm() {
    this.DicksonNPFroms = this.fb.group({
      9: ['', [Validators.required]],
      8: ['', [Validators.required]],
      809: [''],
      278: [''],
      280: [null, [this.dateFormatValidator] ],
      281: [null, [this.dateFormatValidator]],
      cmd_01: [null, [this.dateFormatValidator]],
      cmd_02: [null, [this.dateFormatValidator]],
      16: ['', [Validators.required]],
      17: [''],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      29: [null],
      822: [null, [Validators.required]],
      2337: [''],
      21: ['',[Validators.required,this.zipCodeValidator]],
      708: ['', [Validators.required]],
      32: ['', [Validators.required]],
      30: [null],
      cmd_837: [''],
      cmd_838: [''],
      cmd_839: [''],
      cmd_841: [null],
      cmd_842: ['' ,[Validators.pattern(this.zippattern)]],
      cmd_870: [''],
      cmd_871: [''],
      cmd_017: [''],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      483: [''],
      484: [''],
      cmd_06: [''],
      cmd_0768: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0258: [''],
      // cmd_025: [''],
      // cmd_026: [''],
      // cmd_027: [''],
      1138: [''],
      430: [''],
      cmd_0301: [''],
      cmd_11122:  ['', [Validators.required]],
      480: [null],
      308: [null, [this.dateFormatValidator]],
      999: [null],
      1010: [null],
      946: [null],
      292: [''],
      1584: [''],
      1282: [''],
      cmd_04: [''],
      333: [''],
      334: [''],
      335: [''],
      cmd_05: ['',Validators.required],
      1144: [null,this.dateFormatValidator],
      1145: [null,this.dateFormatValidator],
      1139: ['',],
      1141: [''],
      490: [''],
      491: [''],
      492: [''],
      493: [''],
      // cmd_0722: [''],
      // 495: [null],
      // 496: ['', [this.zipCodeValidator]],
      // 500: [null],
      // 497: [null, this.dateFormatValidator],
      // 498: [null, this.dateFormatValidator],
      // cmd_001:[''],
      // cmd_08: [''],
      // cmd_0130: [''],
      // 527: [null],
      // 517: [''],
      // 518: [''],
      // 519: [''],
      // 520: [''],
      // 522: [null],
      // 523: ['',[Validators.pattern(this.zippattern)]],
      // 524: [null, [this.dateFormatValidator] ],
      // 525: [null, [this.dateFormatValidator]],
      // cmd_09: [''],
      // 528: [''],
      // 818: [''],
      // cmd_010: [''],
      // 530: [null],
      // 529: [''],
      // 531: [''],
      // 1498: [''],
      // 533: [''],
      // 535: [null],
      // 536: ['',[Validators.pattern(this.zippattern)]],
      // cmd_011: [''],
      // 537: [null, [this.dateFormatValidator]],
      // 538: [null, [this.dateFormatValidator]],
      // 540: [''],
      // 541: [''],
      // cmd_002:[''],
      479: [''],
      485: [''],
      487: [null],
      488: ['',[Validators.pattern(this.zippattern)]],
      1300: [null, [this.dateFormatValidator]],
      481: [null, [this.dateFormatValidator]],
      296: [''],
      293: [''],
      299: [null],
      294: [null,[this.dateFormatValidator] ],
      295: [null,[this.dateFormatValidator] ],
      cmd_013: [''],
      1274: [''],
      300: [''],
      907: [null],
      307: [null, [this.dateFormatValidator]],
      cmd_014: [''],
      1432: [''],
      1003: [null, [this.dateFormatValidator]],
      1004: [null, [this.dateFormatValidator]],
      cmd_015: [''],
      cmd_016: [''],
      309: [''],
      310: [null, [this.dateFormatValidator]],
      311: [null, [this.dateFormatValidator]],
      337: [''],
      338: [null, [this.dateFormatValidator]],
      339: [null, [this.dateFormatValidator]],
      cmd_019: [''],
      340: [''],
      997: [null],
      341: [null, [this.dateFormatValidator]],
      342: [null, [this.dateFormatValidator]],
      cmd_020: [''],
      819: [''],
      cmd_021: [''],
      325: [''],
      1634: [''],
      cmd_022: [''],
      812: [''],
      cmd_023: ['',[Validators.required]],
      514: [''],
      515: [null, [this.dateFormatValidator]],
      cmd_0726: [''],
      284: [null],
      cmd_024: [''],
      554: [null],
      557: [null,[this.dateFormatValidator] ],
      559: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      // cmd_1377: [''],
      // cmd_030: [''],
      // cmd_031: [''],
      // cmd_028: [''],
      // cmd_032: [''],
      // cmd_033: [''],
      // cmd_034: [''],
      // 962: [null],
      // cmd_035: [''],
      // 561: [null],
      // 564: [null, [this.dateFormatValidator]],
      // 565: [null, [this.dateFormatValidator]],
      // 567: [null, [this.dateFormatValidator]],
      // cmd_036: [''],
      // cmd_040: [''],
      // cmd_037: [''],
      // cmd_041: [''],
      // cmd_042: [''],
      // cmd_039: [''],
      // cmd_043: [''],
      // cmd_044: [''],
      // cmd_045: [''],
      // 965: [null],
      // cmd_046: [''],
      // 953: [null, [this.dateFormatValidator]],
      // 950: [null, [this.dateFormatValidator]],
      // 954: [null, [this.dateFormatValidator]],
      // cmd_047: [''],
      // cmd_051: [''],
      // cmd_048: [''],
      // cmd_049: [''],
      // cmd_052: [''],
      // cmd_050: [''],
      // cmd_053: [''],
      // cmd_054: [''],
      // cmd_055: [''],
      // cmd_056: [''],
      // cmd_057: [''],
      cmd_0727: [''],
      445: [''],
      454: [null,[this.dateFormatValidator] ],
      455: [null,[this.dateFormatValidator] ],
      446: [''],
      447: [''],
      448: [''],
      450: [null],
      451: ['', [this.zipCodeValidator]],
      456: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      457: [''],
      458: [''],
      459: [''],
      461: [null],
      462: ['',[Validators.pattern(this.zippattern)]],
      1338: [''],
      467: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      468: [''],
      469: [''],
      470: [''],
      472: [null],
      473: ['',[Validators.pattern(this.zippattern)]],
      1342: [''],
      1935: [''],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      1936: [''],
      1937: [''],
      1938: [''],
      1940: [null],
      1941: ['',[Validators.pattern(this.zippattern)]],
      1952: [''],
      3395: [null, [this.dateFormatValidator]],
      3396: [null, [this.dateFormatValidator]],
      3397: [null, [this.dateFormatValidator]],
      3398: [null, [this.dateFormatValidator]],
      1831: [''],
      1832: [''],

      3399: [null, [this.dateFormatValidator]],
      3400: [null, [this.dateFormatValidator]],
      3401: [null, [this.dateFormatValidator]],
      3402: [null, [this.dateFormatValidator]],
      1953: [''],
      // cmd_0723: [''],
      // // cmd_059: [''],
      // // cmd_060: [''],
      // // 418: [''],
      // // 426: [null,[this.dateFormatValidator] ],
      // 419: [''],
      // 420: [''],
      // 421: [''],
      // 424: ['', [this.zipCodeValidator]],
      // 423: [null],
      // 1305: [''],
      // 2136: [''],
      // 2142: ['',[Validators.pattern(this.emailPattern)]],
      // cmd_061: [''],
      // 894: [null],
      // cmd_062: [''],
      // cmd_063: [''],
      // 427: [''],
      // 435: [null, [this.dateFormatValidator]],
      // 428: [''],
      // 429: [''],
      // 432: [null],
      // 433: ['',[Validators.pattern(this.zippattern)]],
      // 1307: [''],
      // 2137: [''],
      // // 442 : ['', [Validators.required]],
      // 2143: ['',[Validators.pattern(this.emailPattern)]],
      // cmd_064: [''],
      // 895: [null],
      // cmd_065: [''],
      // cmd_066: [''],
      // 1183: [''],
      // 1184: [''],
      // 1185: [''],
      // 1191: [null, [this.dateFormatValidator]],
      // 2381: [null, [this.dateFormatValidator]],
      // 1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_068: [''],
      // 1192: [null],
      // cmd_069: [''],
      // 1186: [''],
      // 1188: [null],
      // 1303: [''],
      // add_1_1183: [''],
      // add_1_1184: [''],
      // add_1_1185: [''],
      // add_1_1191: [null, [this.dateFormatValidator]],
      // add_1_2381: [null, [this.dateFormatValidator]],
      // add_1_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_0068: [''],
      // add_1_1192: [null],
      // cmd_0069: [''],
      // add_1_1186: [''],
      // add_1_1188: [null],
      // add_1_1303: [''],
      // add_2_1183: [''],
      // add_2_1184: [''],
      // add_2_1185: [''],
      // add_2_1191: [null, [this.dateFormatValidator]],
      // add_2_2381: [null, [this.dateFormatValidator]],
      // add_2_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_00068: [''],
      // add_2_1192: [null],
      // cmd_00069: [''],
      // add_2_1186: [''],
      // add_2_1188: [null],
      // add_2_1303: [''],
      // add_3_1183: [''],
      // add_3_1184: [''],
      // add_3_1185: [''],
      // add_3_1191: [null, [this.dateFormatValidator]],
      // add_3_2381: [null, [this.dateFormatValidator]],
      // add_3_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_000068: [''],
      // add_3_1192: [null],
      // cmd_000069: [''],
      // add_3_1186: [''],
      // add_3_1188: [null],
      // add_3_1303: [''],
      // add_4_1183: [''],
      // add_4_1184: [''],
      // add_4_1185: [''],
      // add_4_1191: [null, [this.dateFormatValidator]],
      // add_4_2381: [null, [this.dateFormatValidator]],
      // add_4_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_0000068: [''],
      // add_4_1192: [null],
      // cmd_0000069: [''],
      // add_4_1186: [''],
      // add_4_1188: [null],
      // add_4_1303: [''],
      // add_5_1183: [''],
      // add_5_1184: [''],
      // add_5_1185: [''],
      // add_5_1191: [null, [this.dateFormatValidator]],
      // add_5_2381: [null, [this.dateFormatValidator]],
      // add_5_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_00000068: [''],
      // add_5_1192: [null],
      // cmd_00000069: [''],
      // add_5_1186: [''],
      // add_5_1188: [null],
      // add_5_1303: [''],
      // add_6_1183: [''],
      // add_6_1184: [''],
      // add_6_1185: [''],
      // add_6_1191: [null, [this.dateFormatValidator]],
      // add_6_2381: [null, [this.dateFormatValidator]],
      // add_6_1189: ['',[Validators.pattern(this.zippattern)]],
      // cmd_000000068: [''],
      // add_6_1192: [null],
      // cmd_000000069: [''],
      // add_6_1186: [''],
      // add_6_1188: [null],
      // add_6_1303: [''],
      cmd_0724: [''],
      359: [''],
      371: ['', [Validators.pattern(this.emailPattern)]],
      369: [''],
      370: [''],
      360: [''],
      361: [''],
      362: [''],
      364: [null],
      365: ['', [this.zipCodeValidator]],
      372: [''],
      383: ['', [Validators.pattern(this.emailPattern)]],
      396: ['', [Validators.pattern(this.emailPattern)]],
      381: [''],
      382: [''],
      373: [''],
      374: [''],
      375: [''],
      377: [null],
      378: ['',[Validators.pattern(this.zippattern)]],
      384: [''],
      394: [''],
      395: [''],
      385: [''],
      386: [''],
      387: [''],
      389: [null],
      390: ['',[Validators.pattern(this.zippattern)]],
      cmd_0725: [''],
      cmd_071: [''],
      345: [''],
      901: [''],
      902: [''],
      903: [''],
      905: [null],
      906: ['', [this.zipCodeValidator]],
      1266: [''],
      346: [''],
      348: [null,[this.dateFormatValidator] ],
      349: [null,[this.dateFormatValidator] ],
      350: [null],
      351: [null],
      1242: [''],
      cmd_072: [''],
      cmd_073: [''],
      cmd_07251: [''],
      1244: [''],
      1245: [''],
      1246: [''],
      1248: [null],
      1249: ['',[Validators.pattern(this.zippattern)]],
      1254: [''],
      1256: [''],
      1252: [null,[this.dateFormatValidator] ],
      1253: [null,[this.dateFormatValidator] ],
      1264: [null],
      1265: [null],
      cmd_074: [''],
      cmd_075: [''],
      cmd_088: [''],
      cmd_089: [''],
      cmd_090: [''],
      cmd_091: [''],
      cmd_092: [''],
      cmd_093: [''],
      cmd_094: [''],
      cmd_0728: [''],
      cmd_0729: [''],
      cmd_095: [''],
      cmd_0730: [''],
      cmd_0731: [''],
      cmd_0732: [''],
      cmd_0733: [null],
      cmd_0734: ['',[Validators.pattern(this.zippattern)]],
      cmd_0735: [''],
      cmd_0736: [''],
      cmd_0737: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0738: [''],
      cmd_0739: [''],
      cmd_0740: [''],
      cmd_0741: [''],
      cmd_0742: [''],
      cmd_0743: [null, [this.dateFormatValidator]],
      cmd_096: [''],
      cmd_0744: [''],
      cmd_0745: [''],
      cmd_0746: [''],
      cmd_0747: [''],
      cmd_0748: [''],
      cmd_0749: [''],
      cmd_0750: [null],
      cmd_0751: [''],
      cmd_0752: [''],
      cmd_0753: [''],
      cmd_0754: [null],
      cmd_0755: ['',[Validators.pattern(this.zippattern)]],
      cmd_0756: [''],
      cmd_0757: [''],
      cmd_0758: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0759: [''],
      cmd_0760: [''],
      cmd_0761: [''],
      cmd_0762: [''],
      cmd_0763: [''],
      cmd_0764: [null],
      cmd_0765: ['',[Validators.pattern(this.zippattern)]],
      cmd_0766: [''],
      cmd_0767: [''],
      cmd_097: [''],
      cmd_0769: [''],
      cmd_098: [''],
      cmd_099: [''],
      cmd_0770: [''],
      cmd_0106: [''],
      cmd_0114: [''],
      cmd_0100: [''],
      cmd_0771: [''],
      cmd_0107: [''],
      cmd_0115: [''],
      cmd_0101: [''],
      cmd_0772: [''],
      cmd_0109: [''],
      cmd_0116: [''],
      cmd_0102: [''],
      cmd_0773: [''],
      cmd_0110: [''],
      cmd_0117: [''],
      cmd_0103: [''],
      cmd_0774: [''],
      cmd_0111: [''],
      cmd_0118: [''],
      cmd_0104: [''],
      cmd_0775: [''],
      cmd_0112: [''],
      cmd_0119: [''],
      cmd_0105: [''],
      cmd_0776: [''],
      cmd_0113: [''],
      cmd_0120: [''],
      cmd_0121: [''],
      cmd_0122: [''],
      cmd_0123: [''],
      cmd_0124: [''],
      cmd_0125: [''],
      cmd_0126: [''],
      cmd_0127: [''],
      cmd_0128: [''],
      cmd_0129: [''],
      cmd_0131: [''],
      cmd_0777: [''],
      cmd_0132: [''],
      cmd_0133: [''],
      cmd_0134: [''],
      cmd_0135: [''],
      cmd_0136: [''],
      cmd_0137: [''],
      cmd_0138: [''],
      cmd_0139: [''],
      cmd_0140: [''],
      cmd_0141: [''],
      cmd_0142: [''],
      cmd_0232: ['', [Validators.required]],
      cmd_0233: ['', [Validators.required]],
      cmd_0234: ['', [Validators.required]],
      cmd_0235: ['', [Validators.required]],
      cmd_0236: ['', [Validators.required]],
      cmd_0237: ['', [Validators.required]],
      cmd_0238: ['', [Validators.required]],
      cmd_0239: ['', [Validators.required]],
      cmd_0240: ['', [Validators.required]],
      cmd_0241: ['', [Validators.required]],
      cmd_0242: ['', [Validators.required]],
      cmd_0243: ['', [Validators.required]],
      cmd_0244: ['', [Validators.required]],
      cmd_0245: ['', [Validators.required]],
      cmd_0246: ['', [Validators.required]],
      cmd_0247: ['', [Validators.required]],
      cmd_0248: ['', [Validators.required]],
      cmd_0249: ['', [Validators.required]],
      cmd_0250: ['', [Validators.required]],
      cmd_0251: ['', [Validators.required]],
      cmd_0252: ['', [Validators.required]],
      cmd_0253: ['', [Validators.required]],
      cmd_0254: ['', [Validators.required]],
      cmd_0255: ['', [Validators.required]],
      cmd_0256: [''],
      cmd_0257: ['', [Validators.required]],
      cmd_0259: ['', [Validators.required]],
      cmd_0260: [''],
      cmd_0261: ['', [Validators.required]],
      cmd_0262: [''],
      cmd_0263: ['', [Validators.required]],
      cmd_0264: [''],
      cmd_0265: ['', [Validators.required]],
      cmd_0266: [''],
      cmd_0267: ['', [Validators.required]],
      cmd_0268: [''],
      cmd_0269: ['', [Validators.required]],
      cmd_0270: [''],
      cmd_0271: ['', [Validators.required]],
      cmd_0272: [''],
      cmd_0273: ['', [Validators.required]],
      cmd_0274: [''],
      cmd_0275: ['', [Validators.required]],
      cmd_0276: [''],
      cmd_0277: ['', [Validators.required]],
      cmd_0278: [''],
      cmd_0279: ['', [Validators.required]],
      cmd_0390: [''],
      cmd_038: [''],
      cmd_0281: ['', [Validators.required]],
      cmd_0282: [''],
      cmd_0283: ['', [Validators.required]],
      cmd_0284: [''],
      cmd_0285: ['', [Validators.required]],
      cmd_0286: [''],
      cmd_0287: ['', [Validators.required]],
      cmd_0288: [''],
      cmd_0289: ['', [Validators.required]],
      cmd_0290: [''],
      cmd_0291: ['', [Validators.required]],
      cmd_0292: [''],
      cmd_0293: ['', [Validators.required]],
      cmd_0294: [''],
      cmd_0295: ['', [Validators.required]],
      cmd_0296: [''],
      cmd_0297: ['', [Validators.required]],
      cmd_0298: [''],
      cmd_0299: ['', [Validators.required]],
      cmd_0300: [''],
      cmd_0302: [''],
      cmd_0303: [''],
      cmd_0304: [''],
      cmd_0305: [''],
      cmd_0306: [''],
      cmd_0307: [''],
      cmd_0897: [''],
      cmd_0779: [''],
      cmd_0310: [null, [this.dateFormatValidator]],
      cmd_0280: [null],
      cmd_003:[''],
      cmd_NA_014:[null],
      1208:[null, [this.dateFormatValidator]],
      1209:[null, [this.dateFormatValidator]],
      1858:[''],
      1210:[''],  
      2012:[''],
      1844:[''],
      1845:[''],
      1847:[null],
      1848:['',[Validators.pattern(this.zippattern)]],
      1849:[''],    
      1859:[''],   
      1229:[''],   
      1228:[''],   
      cmd_0708: [''],
      cmd_0709: [''],
      cmd_0710: [''],
      cmd_0711: [''],
      cmd_0712: [''],
      cmd_0714: [''],
      cmd_0713: [''],
      1216:[''],
      1213:[''],
      cmd_0716:[''],
      1218:[''],
      1217:[''],
      cmd_0719:[''],
      add_1_1208:[null, [this.dateFormatValidator]],
      add_1_1209:[null, [this.dateFormatValidator]],
      add_1_1858:[''],
      add_1_1210:[''],  
      add_1_2012:[''],
      add_1_1844:[''],
      add_1_1845:[''],
      add_1_1847:[null],
      add_1_1848:['',[Validators.pattern(this.zippattern)]],
      add_1_1849:[''],    
      add_1_1859:[''],   
      add_1_1229:[''],   
      add_1_1228:[''],   
      cmd_0715: [''],
      cmd_0720: [''],
      cmd_0721: [''],
      cmd_0718: [''],
      add_1_1216:[''],
      add_1_1213:[''],
      cmd_07211:[''],
      add_1_1218:[''],
      add_1_1217:[''],
      cmd_00722:[''],
      // cmd_005:[''],
      cmd_0005:[''],
      add_1_1288: [''],
      add_1_1289: [''],
      add_1_1290: [''],
      add_1_1291: [''],
      add_1_1293: [null],
      add_1_1294: ['',[Validators.pattern(this.zippattern)]],
      add_1_1298: [null],
      add_1_1296:[null, [this.dateFormatValidator]],
      add_1_1295:[null, [this.dateFormatValidator]],
      add_2_1288: [''],
      add_2_1289: [''],
      add_2_1290: [''],
      add_2_1291: [''],
      add_2_1293: [null],
      add_2_1294: ['',[Validators.pattern(this.zippattern)]],
      add_2_1298: [null],
      add_2_1296:[null, [this.dateFormatValidator]],
      add_2_1295:[null, [this.dateFormatValidator]],
      add_3_1288: [''],
      add_3_1289: [''],
      add_3_1290: [''],
      add_3_1291: [''],
      add_3_1293: [null],
      add_3_1294: ['',[Validators.pattern(this.zippattern)]],
      add_3_1298: [null],
      add_3_1296:[null, [this.dateFormatValidator]],
      add_3_1295:[null, [this.dateFormatValidator]],
      add_4_1288: [''],
      add_4_1289: [''],
      add_4_1290: [''],
      add_4_1291: [''],
      add_4_1293: [null],
      add_4_1294: ['',[Validators.pattern(this.zippattern)]],
      add_4_1298: [null],
      add_4_1296:[null, [this.dateFormatValidator]],
      add_4_1295:[null, [this.dateFormatValidator]],
      add_5_1288: [''],
      add_5_1289: [''],
      add_5_1290: [''],
      add_5_1291: [''],
      add_5_1293: [null],
      add_5_1294: ['',[Validators.pattern(this.zippattern)]],
      add_5_1298: [null],
      add_5_1296:[null, [this.dateFormatValidator]],
      add_5_1295:[null, [this.dateFormatValidator]],
      add_6_1288: [''],
      add_6_1289: [''],
      add_6_1290: [''],
      add_6_1291: [''],
      add_6_1293: [null],
      add_6_1294: ['',[Validators.pattern(this.zippattern)]],
      add_6_1298: [null],
      add_6_1296:[null, [this.dateFormatValidator]],
      add_6_1295:[null, [this.dateFormatValidator]],
      add_7_1288: [''],
      add_7_1289: [''],
      add_7_1290: [''],
      add_7_1291: [''],
      add_7_1293: [null],
      add_7_1294: ['',[Validators.pattern(this.zippattern)]],
      add_7_1298: [null],
      add_7_1296:[null, [this.dateFormatValidator]],
      add_7_1295:[null, [this.dateFormatValidator]],
      cmd_NA_02:[],
      cmd_1378: [''],
      cmd_1379: [''],
      cmd_0793:[null],
      cmd_0329:[null],
      cmd_0338:[null],
      cmd_0347:[null],
      cmd_0356:[null],
      cmd_0320:[null],
      // 1182:[''],
      // 1181:[''],
      // 1179:[null, [this.dateFormatValidator]],
      // 1178:[null, [this.dateFormatValidator]],
      // cmd_009:[''],
      // 1177:['',[Validators.pattern(this.zippattern)]],
      // 1176:[null],
      // 1174:[null],
      // 1173:[''],
      // 1172:[''],
      // 1170:[''],
      // 1171:[null],
      // cmd_008:[''],
      // add_1_1182:[''],
      // add_1_1181:[''],
      // add_1_1179:[null, [this.dateFormatValidator]],
      // add_1_1178:[null, [this.dateFormatValidator]],
      // cmd_0009:[''],
      // add_1_1177:['',[Validators.pattern(this.zippattern)]],
      // add_1_1176:[null],
      // add_1_1174:[null],
      // add_1_1173:[''],
      // add_1_1172:[''],
      // add_1_1170:[''],
      // add_1_1171:[null],
      // cmd_0008:[''],
      // 984:[''],
      // 983:[''],
      // 975:[null, [this.dateFormatValidator]],
      // 974:[null, [this.dateFormatValidator]],
      // cmd_00009:[''],
      // 1301:['',[Validators.pattern(this.zippattern)]],
      // 972:[null],
      // 970:[null],
      // 969:[''],
      // 968:[''],
      // 966:[''],
      // 967:[null],
      // cmd_00008:[''],
      // add_1_984:[''],
      // add_1_983:[''],
      // add_1_975:[null, [this.dateFormatValidator]],
      // add_1_974:[null, [this.dateFormatValidator]],
      // cmd_000009:[''],
      // add_1_1301:['' ,[Validators.pattern(this.zippattern)]],
      // add_1_972:[null],
      // add_1_970:[null],
      // add_1_969:[''],
      // add_1_968:[''],
      // add_1_966:[''],
      // add_1_967:[null],
      // cmd_000008:[''],
      add_1_1954:[''],
      add_1_1966:[null, [this.dateFormatValidator]],
      add_1_1967:[null, [this.dateFormatValidator]],
      add_1_1955:[''],
      add_1_1956:[''],
      add_1_1957:[''],
      add_1_1959:[null],
      add_1_1960:['',[Validators.pattern(this.zippattern)]],
      add_1_1971:[''],
      add_2_1954:[''],
      add_2_1966:[null, [this.dateFormatValidator]],
      add_2_1967:[null, [this.dateFormatValidator]],
      add_2_1955:[''],
      add_2_1956:[''],
      add_2_1957:[''],
      add_2_1959:[null],
      add_2_1960:['',[Validators.pattern(this.zippattern)]],
      add_2_1971:[''],
      add_3_1954:[''],
      add_3_1966:[null, [this.dateFormatValidator]],
      add_3_1967:[null, [this.dateFormatValidator]],
      add_3_1955:[''],
      add_3_1956:[''],
      add_3_1957:[''],
      add_3_1959:[null],
      add_3_1960:['',[Validators.pattern(this.zippattern)]],
      add_3_1971:[''],
      add_4_1954:[''],
      add_4_1966:[null, [this.dateFormatValidator]],
      add_4_1967:[null, [this.dateFormatValidator]],
      add_4_1955:[''],
      add_4_1956:[''],
      add_4_1957:[''],
      add_4_1959:[null],
      add_4_1960:['',[Validators.pattern(this.zippattern)]],
      add_4_1971:[''],
      add_5_1954:[''],
      add_5_1966:[null, [this.dateFormatValidator]],
      add_5_1967:[null, [this.dateFormatValidator]],
      add_5_1955:[''],
      add_5_1956:[''],
      add_5_1957:[''],
      add_5_1959:[null],
      add_5_1960:['',[Validators.pattern(this.zippattern)]],
      add_5_1971:[''],
      add_6_1954:[''],
      add_6_1966:[null, [this.dateFormatValidator]],
      add_6_1967:[null, [this.dateFormatValidator]],
      add_6_1955:[''],
      add_6_1956:[''],
      add_6_1957:[''],
      add_6_1959:[null],
      add_6_1960:['',[Validators.pattern(this.zippattern)]],
      add_6_1971:[''],
      add_7_1954:[''],
      add_7_1966:[null, [this.dateFormatValidator]],
      add_7_1967:[null, [this.dateFormatValidator]],
      add_7_1955:[''],
      add_7_1956:[''],
      add_7_1957:[''],
      add_7_1959:[null],
      add_7_1960:['',[Validators.pattern(this.zippattern)]],
      add_7_1971:[''],
      // 436:[''],
      // 444:[null, [this.dateFormatValidator]],
      // 437:[''],
      // 438:[''],
      // 439:[''],
      // 441:[null],
      // 442:['',[Validators.pattern(this.zippattern)]],
      // 1309:[''],
      // 2138:[''],
      // 2144:['',[Validators.pattern(this.emailPattern)]],
      // cmd_0064:[''],
      // 896:[null],
      // cmd_0065:[''],
      // cmd_0066:[''],
      // add_1_986:[''],
      // add_1_994:[null, [this.dateFormatValidator]],
      // add_1_1373:[null, [this.dateFormatValidator]],
      // add_1_987:[''],
      // add_1_988:[''],
      // add_1_989:[''],
      // add_1_991:[null],
      // add_1_992:['',[Validators.pattern(this.zippattern)]],
      // add_1_1311:[''],
      // add_1_2139:[''],
      // add_1_2145:['',[Validators.pattern(this.emailPattern)]],
      // cmd_00064:[''],
      // add_1_995:[null],
      // cmd_00065:[''],
      // cmd_00066:[''],
      // add_2_986:[''],
      // add_2_994:[null, [this.dateFormatValidator]],
      // add_2_1373:[null, [this.dateFormatValidator]],
      // add_2_987:[''],
      // add_2_988:[''],
      // add_2_989:[''],
      // add_2_991:[null],
      // add_2_992:['',[Validators.pattern(this.zippattern)]],
      // add_2_1311:[''],
      // add_2_2139:[''],
      // add_2_2145:['',[Validators.pattern(this.emailPattern)]],
      // cmd_000064:[''],
      // add_2_995:[null],
      // cmd_000065:[''],
      // cmd_000066:[''],
      // add_3_986:[''],
      // add_3_994:[null, [this.dateFormatValidator]],
      // add_3_1373:[null, [this.dateFormatValidator]],
      // add_3_987:[''],
      // add_3_988:[''],
      // add_3_989:[''],
      // add_3_991:[null],
      // add_3_992:['',[Validators.pattern(this.zippattern)]],
      // add_3_1311:[''],
      // add_3_2139:[''],
      // add_3_2145:['',[Validators.pattern(this.emailPattern)]],
      // cmd_0000064:[''],
      // add_3_995:[null],
      // cmd_0000065:[''],
      // cmd_0000066:[''],
      // add_4_986:[''],
      // add_4_994:[null, [this.dateFormatValidator]],
      // add_4_1373:[null, [this.dateFormatValidator]],
      // add_4_987:[''],
      // add_4_988:[''],
      // add_4_989:[''],
      // add_4_991:[null],
      // add_4_992:['',[Validators.pattern(this.zippattern)]],
      // add_4_1311:[''],
      // add_4_2139:[''],
      // add_4_2145:['',[Validators.pattern(this.emailPattern)]],
      // cmd_00000064:[''],
      // add_4_995:[null],
      // cmd_00000065:[''],
      // cmd_00000066:[''],
      // // cmd_0000008:[''],
      // 1147:[null],
      // 1146:[''],
      // 1148:[''],
      // 1149:[''],
      // 1150:[''],
      // 1152:[null],
      // 1153:['',[Validators.pattern(this.zippattern)]],
      // cmd_0000009:[''],
      // 1155:[null, [this.dateFormatValidator]],
      // 1156:[null, [this.dateFormatValidator]],
      // 1382:[''],
      // cmd_00122:[''],
        
    });
    // console.log(this.DicksonNPFroms);
    this.DicksonNPFroms.get('cmd_11122').value;
    // this.onChangeCmdOne(1)
  }
  getSsn(value?) {
    this.DicksonNPFroms.patchValue({
      // cmd_0779: value
      cmd_0779: value?.substring(value.length-4, value.length)
    });
  }
  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0897: this.finalName
    };
    this.DicksonNPFroms.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0897: this.finalName
    };
    this.DicksonNPFroms.patchValue(temp);
  }

  doesNotApply($event) {
    // console.log(event);
    // if(event.target.value == 1) {
    //   this.doesNotApplyVar=true;
    // } else {
    //   this.doesNotApplyVar=false;
    // }
  }
  setErrorControl(name){
    this.DicksonNPFroms.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.DicksonNPFroms.controls[name].setErrors(null)
  }
  handleNAchange(cName,eName?){
    let result:boolean=false;
    // if(cName=="cmd_0722"){
    //   let x=[490,491,493,495,496,497,498,500]
    //   x.forEach(element => {
    //     const ctrl = this.DicksonNPFroms.controls[element];
    //       if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
    //         this.setErrorControl(element);
    //         result = true;
    //     } 
    //     else if ((element === 496) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
    //       this.setErrorControl(element);
    //       ctrl.setErrors({ 'invalidZipCode': true });
    //       result = true;
    //     } else if ((element === 496  ) && this.DicksonNPFroms.controls[cName].value) {
    //       this.removeErrorControl(element);
    //       ctrl.setErrors(null);
    //     } 
        
    //     else {
    //         this.removeErrorControl(element);
    //         ctrl.setErrors(null);
    //       }
   
      

    //   });
    // } 
    if(cName=='cmd_0726'){
      let x=['cmd_024',557,284]
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_0727'){
      let x=[445,454,455,446,448,450,451,461,472]
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          else if ((element === 451) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 451  ) && this.DicksonNPFroms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    // if(cName=='cmd_0723'){
    //   let x=[418,426,419,421,423,424,1305,432,1188]
    //   x.forEach(element => {
    //     const ctrl = this.DicksonNPFroms.controls[element];
    //       if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
    //         this.setErrorControl(element);
    //         result = true;
    //       } 
    //       else if ((element === 424) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
    //         this.setErrorControl(element);
    //         ctrl.setErrors({ 'invalidZipCode': true });
    //         result = true;
    //       } else if ((element === 424  ) && this.DicksonNPFroms.controls[cName].value) {
    //         this.removeErrorControl(element);
    //         ctrl.setErrors(null);
    //       } 
    //       else {
    //         this.removeErrorControl(element);
    //         ctrl.setErrors(null);
    //       }
    //   });
    // } 
    if(cName=='cmd_0725'){
      let x=[345,901,903,905,906,1266,346,348,349,350,351]
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          else if ((element === 906) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 906  ) && this.DicksonNPFroms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_07251'){
      let x=[1242,1244,1246,1248,1249,1256,1252,1253,1264,1265]
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } 
          else if ((element === 1249) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 1249  ) && this.DicksonNPFroms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }  
    if(cName=='cmd_0724'){
      let x=[359,371,369,362,360,364,365,372,383,381,384,396,394]
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.DicksonNPFroms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          else if ((element === 365) && !/^.{5,9}$/.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidZipCode': true });
            result = true;
          } else if ((element === 365  ) && this.DicksonNPFroms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          else if ((element === 371 || element === 383 || element === 396) && !this.emailPattern.test(ctrl.value) && (!this.DicksonNPFroms.controls[cName].value || (this.DicksonNPFroms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'invalidEmail': true });
            result = true;
          } else if ((element === 371 || element === 383 || element === 396 ) && this.DicksonNPFroms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } 
          
           else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    } 
    if(cName=='cmd_NA_014'){
      let x=[1208,1209,1858,1210]
      x.forEach(element => {
        if((this.DicksonNPFroms.controls[element].value=='' || this.DicksonNPFroms.controls[element].value==null) && !this.DicksonNPFroms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    
  }
  handleYesNoChanges(cName,eName?){
    let result:boolean=false;
    
    if (cName == "cmd_05") {
      let x=['1141','1139','1144','1145']

      
  
      x.forEach(element => {
        const ctrl = this.DicksonNPFroms.controls[element];
        if((this.DicksonNPFroms.controls[element].value=='' || this.DicksonNPFroms.controls[element].value==null) && (this.DicksonNPFroms.controls[cName].value==1 || this.DicksonNPFroms.controls[cName].value==null)){
          this.setErrorControl(element);
        }

        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_023") {

      let x = ['514'];
      x.forEach(element => {
        if((this.DicksonNPFroms.controls[element].value=='' || this.DicksonNPFroms.controls[element].value==null) && (this.DicksonNPFroms.controls["cmd_023"].value==1 )){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_024") {
      let x=['554']
      x.forEach(element => {
        if((this.DicksonNPFroms.controls[element].value=='' || this.DicksonNPFroms.controls[element].value==null) && (this.DicksonNPFroms.controls["cmd_024"].value==1 )){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    
  }
  // public proSpecApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.prospeapp = event;
  //   if (this.prospeapp) {
  //     this.DicksonNPFroms.controls[284].setErrors(null);
  //     this.DicksonNPFroms.controls[557].setErrors(null);
  //     this.DicksonNPFroms.controls['cmd_024'].setErrors(null);
  //   }
  // }

  // public workHistApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.workapp = event;
  //   if (this.workapp) {
  //     this.DicksonNPFroms.controls[445].setErrors(null);
  //     this.DicksonNPFroms.controls[454].setErrors(null);
  //     this.DicksonNPFroms.controls[455].setErrors(null);
  //     this.DicksonNPFroms.controls[446].setErrors(null);
  //     this.DicksonNPFroms.controls[447].setErrors(null);
  //     this.DicksonNPFroms.controls[448].setErrors(null);
  //     this.DicksonNPFroms.controls[450].setErrors(null);
  //     this.DicksonNPFroms.controls[451].setErrors(null);
  //     this.DicksonNPFroms.controls[461].setErrors(null);
  //     this.DicksonNPFroms.controls[472].setErrors(null);
  //   }
  // }
  // public hospAffApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.hospapp = event;
  //   if (this.hospapp) {
  //     this.DicksonNPFroms.controls[418].setErrors(null);
  //     this.DicksonNPFroms.controls[426].setErrors(null);
  //     this.DicksonNPFroms.controls[419].setErrors(null);
  //     this.DicksonNPFroms.controls[420].setErrors(null);
  //     this.DicksonNPFroms.controls[421].setErrors(null);
  //     this.DicksonNPFroms.controls[423].setErrors(null);
  //     this.DicksonNPFroms.controls[424].setErrors(null);
  //     this.DicksonNPFroms.controls[1305].setErrors(null);
  //     this.DicksonNPFroms.controls[432].setErrors(null);
  //     this.DicksonNPFroms.controls[1188].setErrors(null);
  //   }
  // }
  validateInput() {
    Object.values(this.DicksonNPFroms.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }
  
  // public referencesApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.refapp = event;
  //   if (this.refapp) {
  //     this.DicksonNPFroms.controls[359].setErrors(null);
  //     this.DicksonNPFroms.controls[371].setErrors(null);
  //     this.DicksonNPFroms.controls[369].setErrors(null);
  //     this.DicksonNPFroms.controls[360].setErrors(null);
  //     // this.DicksonNPFroms.controls[361].setErrors(null);
  //     this.DicksonNPFroms.controls[362].setErrors(null);
  //     this.DicksonNPFroms.controls[364].setErrors(null);
  //     this.DicksonNPFroms.controls[365].setErrors(null);
  //     this.DicksonNPFroms.controls[372].setErrors(null);
  //     this.DicksonNPFroms.controls[383].setErrors(null);
  //     this.DicksonNPFroms.controls[381].setErrors(null);
  //     this.DicksonNPFroms.controls[384].setErrors(null);
  //     this.DicksonNPFroms.controls[396].setErrors(null);
  //     this.DicksonNPFroms.controls[394].setErrors(null);
  //   }
  // }
  // public proInsApp(event) {
  //   console.log(event);
  //   this.proapp = event;
  //   if (this.proapp) {
  //     this.DicksonNPFroms.controls[345].setErrors(null);
  //     this.DicksonNPFroms.controls[901].setErrors(null);
  //     this.DicksonNPFroms.controls[902].setErrors(null);
  //     this.DicksonNPFroms.controls[903].setErrors(null);
  //     this.DicksonNPFroms.controls[905].setErrors(null);
  //     this.DicksonNPFroms.controls[906].setErrors(null);
  //     this.DicksonNPFroms.controls[1266].setErrors(null);
  //     this.DicksonNPFroms.controls[346].setErrors(null);
  //     this.DicksonNPFroms.controls[348].setErrors(null);
  //     this.DicksonNPFroms.controls[349].setErrors(null);
  //     this.DicksonNPFroms.controls[350].setErrors(null);
  //     this.DicksonNPFroms.controls[351].setErrors(null);
  //   }
  // }
  // public preInsMalApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.premlpapp = event;
  //   if (this.premlpapp) {
  //     this.DicksonNPFroms.controls[1242].setErrors(null);
  //     this.DicksonNPFroms.controls[1244].setErrors(null);
  //     this.DicksonNPFroms.controls[1245].setErrors(null);
  //     this.DicksonNPFroms.controls[1246].setErrors(null);
  //     this.DicksonNPFroms.controls[1248].setErrors(null);
  //     this.DicksonNPFroms.controls[1249].setErrors(null);
  //     this.DicksonNPFroms.controls[1256].setErrors(null);
  //     this.DicksonNPFroms.controls[1252].setErrors(null);
  //     this.DicksonNPFroms.controls[1253].setErrors(null);
  //     this.DicksonNPFroms.controls[1264].setErrors(null);
  //     this.DicksonNPFroms.controls[1265].setErrors(null);
  //   }
  // }

  // public licApp(event) {
  //   // console.log(event.target.value, event.target.checked);
  //   this.licenseapp = event;
  //   if (this.licenseapp) {
  //     this.DicksonNPFroms.controls[293].setErrors(null);
  //     this.DicksonNPFroms.controls[299].setErrors(null);
  //     this.DicksonNPFroms.controls[294].setErrors(null);
  //     this.DicksonNPFroms.controls[295].setErrors(null);
  //   }
  // }

  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.county = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_11122: this.finalImgPath
      };
      this.DicksonNPFroms.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  saveForm(type) {
    this.isSubmit = true;
    // this.handleNAchange('cmd_0722');
    this.handleNAchange('cmd_0726');
    this.handleNAchange('cmd_0727');
    // this.handleNAchange('cmd_0723');
    this.handleNAchange('cmd_0725');
    this.handleNAchange('cmd_07251');
    this.handleNAchange('cmd_0724');
    this.handleYesNoChanges('cmd_05');
    this.handleYesNoChanges('cmd_024');
    this.handleYesNoChanges('cmd_023');
    this.handleNAchange('cmd_NA_014')
    console.log(this.DicksonNPFroms);
    this.DicksonNPFroms.get('cmd_11122').value;
    this.validationsDataAdd()
    console.log(this.DicksonNPFroms.controls);
    if (type == 'submit') {

      if (this.DicksonNPFroms.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.DicksonNPFroms.controls) {
          if (this.DicksonNPFroms.controls.hasOwnProperty(key)) {
            const element = this.DicksonNPFroms.controls[key];
            if(element.status=="INVALID"){
            console.log(key + " " + element.status)
            }
          }
        }
      } else {
        if (this.DicksonNPFroms.controls['cmd_11122'].value == null || this.DicksonNPFroms.controls['cmd_11122'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        let data = {
          formType: type,
          formValues: this.DicksonNPFroms.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.DicksonNPFroms);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.DicksonNPFroms.value,
        formId: 50
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  

  public validationsDataAdd(event?) {
    let yesNoQuestionIds = {
      cmd_0232: 'cmd_0255',
      cmd_0233: 'cmd_0257',
      cmd_0234: 'cmd_0259',
      cmd_0235: 'cmd_0261',
      cmd_0236: 'cmd_0263',
      cmd_0237: 'cmd_0265',
      cmd_0238: 'cmd_0267',
      cmd_0239: 'cmd_0269',
      cmd_0240: 'cmd_0271',
      cmd_0241: 'cmd_0273',
      cmd_0242: 'cmd_0275',
      cmd_0243: 'cmd_0277',
      cmd_0244: 'cmd_0279',
      cmd_0245: 'cmd_0281',
      cmd_0246: 'cmd_0283',
      cmd_0247: 'cmd_0285',
      cmd_0248: 'cmd_0287',
      cmd_0249: 'cmd_0289',
      cmd_0250: 'cmd_0291',
      cmd_0251: 'cmd_0293',
      cmd_0252: 'cmd_0295',
      cmd_0253: 'cmd_0297',
      cmd_0254: 'cmd_0299'
    };
    // ID of respective explanation section
    let yesNoQuestionExplanation = [
      'cmd_0255',
      'cmd_0257',
      'cmd_0259',
      'cmd_0261',
      'cmd_0263',
      'cmd_0265',
      'cmd_0267',
      'cmd_0269',
      'cmd_0271',
      'cmd_0273',
      'cmd_0275',
      'cmd_0277',
      'cmd_0279',
      'cmd_0281',
      'cmd_0283',
      'cmd_0285',
      'cmd_0287',
      'cmd_0289',
      'cmd_0291',
      'cmd_0293',
      'cmd_0295',
      'cmd_0297',
      'cmd_0299'
    ];
    this.questionCounter = 0;
    for (const key in yesNoQuestionIds) {
      if (yesNoQuestionIds.hasOwnProperty(key)) {
        // console.log(yesNoQuestionIds[key]);
        const element = this.DicksonNPFroms.controls[key].value;
        // console.log(element);
        if (element == 1) {
          this.questionCounter++;
        }
      }
    }
    // console.log(this.questionCounter);
    for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
      // console.log(yesNoQuestionExplanation[0]);
      if (this.indexKey < this.questionCounter) {
        // console.log(this.DicksonNPFroms.controls[yesNoQuestionExplanation[this.indexKey]])
        if (this.DicksonNPFroms.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.DicksonNPFroms.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
          // console.log('setting error')
        this.DicksonNPFroms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          {
            errors: true
          }
        );
        }
      } else {
        // console.log('removing error');
        // console.log('else error');
        this.DicksonNPFroms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          null
        );
      }
    }
    // for (const key in this.DicksonNPFroms.controls) {
    //   if (this.DicksonNPFroms.controls.hasOwnProperty(key)) {
    //     const element = this.DicksonNPFroms.controls[key];
    //     if(element.status=="INVALID"){
    //     console.log(key + " " + element.status)
    //     }
    //   }
    // }
  }
}
