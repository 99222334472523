import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDateAdapter, NgbDateParserFormatter, NgbCalendar, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CreateNoteService } from '../../services/create-note/create-note.service';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';
import { CommonService, File_Error, Error_Message } from '../../services/common/common.service';
import { AsyncSubject, Observable } from 'rxjs';
import { MaskInputDirective } from '../../SharedComponents/MaskInputDirective.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgClass } from '@angular/common';
export interface SelectedFiles {
  fileName: string;
  fileSize : Number;
  base64?: string;
}
@Component({
    selector: 'ctg-create-note',
    templateUrl: './create-note.component.html',
    styleUrls: ['./create-note.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [NgxSpinnerModule, NgIf, FormsModule, ReactiveFormsModule, NgClass, NgSelectModule, NgbDatepickerModule, MaskInputDirective]
})
export class CreateNoteComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() popupType;
  @ViewChild('FileToUpload') FileToUpload;
  public submitted = false;
  public NoteForm: UntypedFormGroup;
  public modalResponse: object = {
    type: "close"
  };
  public showSubject: boolean = false;
  public Sub = null
  public subjectDropdownData: Array<object> = [];
  public payerDropdownData: Array<object> = [];
  public tinDropdownData: Array<object> = [];
  public AffliationsDropdownData:Array<object>=[];
  public showCat: boolean = false;
  public CategoryDropdownData: Array<object> = [];
  public priorityDropdownData: Array<object> = [];
  public selectLabel: string = ''
  public drop_category: Array<any> = [];
  public subStatusDropdownData: Array<object> = [];
  public statusDropdownData: Array<object> = [];
  public dueDate: NgbDateStruct;
  public formInfo;
  public selectedFile = [];
  public selectedFiles : SelectedFiles[] = [];
  public date;
  public subject: string = '';
  public childValidator: boolean = false;
  public catValidator: boolean = false;
  public userId:any;
  public customerId:any;
  public readOnlyUser:boolean=false;
  public is_form_note : any;
  public handleproviderId:boolean=false;
  
  public handpnull:boolean=false;
  
  public handpnull2:boolean=false;
  
  public addpassport:boolean=false;
  public selectedAffliationValue:Array<object>=[];

  public UserPermission:any
  public applyPayerToProvider:any;
  public multipleSelection:boolean =true;
  public disableUploadFile:boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private CreateNoteService: CreateNoteService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private calendar: NgbCalendar,
    private commonService: CommonService,
    private storageService:StorageService
  ) { }

  ngOnInit(): void {

   this.userId= Number(this.storageService.getItem('userId'))
   this.customerId=Number(this.storageService.getItem('customerId'))
   this.UserPermission = this.storageService.getItem('userPermission') 
   this.applyPayerToProvider = JSON.parse(this.UserPermission);
    this.buildNoteForm();
    this.loadData(this.popupType);
    // console.log(this.popupType);
  
    
    if (this.popupType.type == 'child') {
     
      let temp =this.popupType.data
      this.is_form_note = temp.parent_data.is_form_note;
 
      if(temp.parent_data.category_id=='1'){
        this.drop_category = this.popupType.data.tins;
        this.selectLabel = 'Tin';
      }
      else if(temp.parent_data.category_id=='2'){
        this.drop_category = this.popupType.data.location_list
        this.selectLabel = 'Location'
      }
      else if(temp.parent_data.category_id=='3'){
        this.drop_category = this.popupType.data.providers_list;
        this.selectLabel = 'Provider';
      }
      // this.drop_category = this.popupType.data.providers_list || this.popupType.data.tins || this.popupType.data.location_list;
      this.handleChildNote(temp.parent_data,temp?.hospital_affiliation_id_name);
      // console.log(temp.hospital_affiliation_id_name.hospital_affiliation_name);
    }
    else if (this.popupType.place != 'workflow') {
      this.handleCategoy(this.popupType.data);
    }
    this.commonService.currentUserPermission.subscribe(data => {
      if (data) {
        if(data && data.permissions==1){
          this.readOnlyUser = true
        }
      }
    }
    )
  }
  handleCategoy(data) {
    this.catValidator = true;
    this.handleproviderId=true; 
    this.addpassport=true;
    this.NoteForm.controls['tin_id'].setValue(data.values.tin_id);
    this.NoteForm.controls['category_id'].setValue(data.values.category_id);
    this.NoteForm.controls['category_item_id'].setValue([data.values.category_item_id]);
    
    this.NoteForm.controls['status_id'].setValue(data.values.status_id);
    this.NoteForm.controls['sub_status_id'].setValue(data.values.sub_status_id);
   // console.log(data);
   
    if(data.values.category_id == 3){
          if(data.hospital_affiliationList.length>0){
            this.AffliationsDropdownData=data.hospital_affiliationList ;
          }
          else{
            this.AffliationsDropdownData=[];
            this.handleproviderId=false;
            this.notiService.showError("No Affliations found", '', 4000);
          }
    }
    if (this.popupType.place == 'provider') {
      this.drop_category = this.popupType.data.providers_list;
      this.selectLabel = 'Provider';
    }
    else if (this.popupType.place == 'tin') {
      this.drop_category = this.popupType.data.tins;
      this.selectLabel = 'Tin';
    }
    else if (this.popupType.place == 'location') {
      this.drop_category = this.popupType.data.location_list;
      this.selectLabel = 'Location'
    }
  }
  ValuesChange(event){
    // this.selectedAffliationValue=event;
    this.selectedAffliationValue = event.map(s=>s.aff_order_id);
    console.log(this.selectedAffliationValue);
  }
  handleChildNote(data,data2) {
    // console.log(data2);
     console.log(data);
    this.is_form_note == null ? this.NoteForm.get('tin_id').setValidators(Validators.required) : this.NoteForm.get('tin_id').setValidators(null);
    this.childValidator = true;
    
    this.subject = `For ${data.subject}`
    this.NoteForm.controls['subject'].setValue(data.subject);
    this.NoteForm.controls['payer_id'].setValue([data.payer_id]);
    this.NoteForm.controls['tin_id'].setValue(data.tin_id);
    this.NoteForm.controls['category_id'].setValue(data.category_id);
    this.NoteForm.controls['category_item_id'].setValue([data.category_item_id]);
    this.NoteForm.controls['affliations'].setValue(data.hospital_affiliation_id);
    this.NoteForm.controls['status_id'].setValue(data.status_id);
    this.NoteForm.controls['sub_status_id'].setValue(data.sub_status_id);
    if(data2?.hospital_affiliation_name){
      this.handleproviderId=true;
      
    }
    else{
      this.handleproviderId=false;
    }
    
  }

  loadData(info) {
    this.formInfo = info.data;
    this.subjectDropdownData = this.formInfo.predefined_Subject;
    this.payerDropdownData = this.formInfo.payers;
    this.tinDropdownData = this.formInfo.tins;
    this.statusDropdownData = this.formInfo.status;
    this.subStatusDropdownData = this.formInfo.sub_status;
    this.CategoryDropdownData = this.formInfo.types;
    this.AffliationsDropdownData=this.formInfo?.affiliation_list;
  }

  getToday() {
    this.date = this.calendar.getToday()
    return this.date ? this.date.month + '/' + this.date.day + '/' + this.date.year : '';
  }

  buildNoteForm() {
    this.NoteForm = this.fb.group({
      subject: ['', Validators.required],
      selectSub: [null],
      description: ['', Validators.required],
      payer_id: [[], Validators.required],
      tin_id: [null, Validators.required],
      category_id: [null, Validators.required],
      category_item_id: [[], Validators.required],
      affliations:[[]], 
      location_code: [''],
      payer_code: [''],
      is_applicable: [true],
      status_id: [2, Validators.required],
      sub_status_id: [null],
      tracking_email: [null],
      tracking_no: [null],
      contact_name: [null],
      email_id: [''],
      phone_number: [''],
      phone_extension: [''],
      fax: [null],
      specific_date: [this.getToday(), Validators.required],
      is_mail_request: [true],
      is_apply_payer_to_provider:this.applyPayerToProvider.apply_payer_to_provider=='1'?[true]:[false],
    })
  }

  get f() { return this.NoteForm.controls; }

  handleSubject(data) {
    this.NoteForm.controls['subject'].setValue(data.name);
    this.NoteForm.controls['description'].setValue(data.description);
  }

  handleTin(data) {
    this.selectLabel = '';
    this.drop_category = []
    this.NoteForm.controls['category_id'].setValue(null);
    this.NoteForm.controls['affliations'].setValue([]);
    // handle category
    this.catValidator = false;
    if (data == undefined || data == null) {
      this.showCat = false
    }
    else {
      this.showCat = true;
    }
  }

  handleTinLoc(e) {
    this.NoteForm.controls['affliations'].setValue([]);
    let params = {
      customerId: this.customerId,
      userId: this.userId,
      tinId: this.NoteForm.controls['tin_id'].value,
      category_id: e.id
    }
    this.spinner.show();
    this.CreateNoteService.tinMappedRecords(params).subscribe(
      data => {
        if (data['success']) {
          this.drop_category = data.location_list || data.tins_list || data.providers_list;
          this.selectLabel = e.name;
          this.NoteForm.controls['category_item_id'].setValue([]);
          if (e.id == 1) {
            this.NoteForm.controls['category_item_id'].setValue([this.NoteForm.value.tin_id]);
            this.handlePayer();
          }
          else if (e.id == 3) {
            
            if (data.providers_list.length == 0) {
              this.notiService.showError('No Providers Available', '', 4000)
            }
          }
          else {
          
            if (data.location_list.length == 0) {
              this.notiService.showError('No Locations Available', '', 4000)
            }
          }
        }
        else {
          this.notiService.showError(data['error'], '', 3000)
          this.selectLabel = '';
          this.drop_category = [];
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error)
        this.selectLabel = ''
        this.spinner.hide();
        this.drop_category = [];
      }
    )
 
  }

 
  handleAffliations(f){
    this.NoteForm.controls['affliations'].setValue([]);
   if(this.selectLabel=="Provider"){
        if(f[0] && !f[1] || f.id){
          this.handleproviderId=true;
          let params={
          customerId:this.customerId,
          userId:this.userId,
          providerId:f[0]? f[0].id : f.id,
          is_task:0

          }
          // console.log(f[0]);
          this.spinner.show();
          this.CreateNoteService.Affliation(params).subscribe(data =>{
            if(data['success']){
              // console.log(data);
              if( data.affiliations_list.message =="No Affiliations found."){
                this.handpnull=true;
                this.AffliationsDropdownData = [];
                  this.handleproviderId = false;
                this.notiService.showError("No Affliations found", '', 4000);
              }
              else{
                    let nullNameCount = 0; 
                    for (let item of data.affiliations_list) {
                      // console.log(item);
                      if (item.name === null) {
                        nullNameCount++;
                      }
                    }
                  // console.log(data.affiliations_list.message);
                  if (nullNameCount === data.affiliations_list.length ) {
                    // console.log("All names are null");
                    this.handpnull2=true;
                    this.AffliationsDropdownData = [];
                    this.handleproviderId = false;
                    this.notiService.showError("No Affliations found", '', 4000);
                  } else {
                      this.AffliationsDropdownData = data.affiliations_list;
                  }
              }
        
            }
            this.spinner.hide(); 
          })
        
        }
        else{
          this.handleproviderId=false;
        
          this.AffliationsDropdownData=[]
        }
    }

    this.handlePayer()
  }

  
  chooseFile(e) {
    this.selectedFile = e.target.files;
    // // console.log(this.selectedFile);
    // this.FileToUpload.nativeElement.nextSibling.innerHTML = 'Choose file'
    // if (this.selectedFile.length > 1) {
    //   this.FileToUpload.nativeElement.nextSibling.innerHTML = this.selectedFile.length + ' Selected';
    // }
    // else if (this.selectedFile.length == 1) {
    //   this.FileToUpload.nativeElement.nextSibling.innerHTML = this.selectedFile[0].name;
    // }

  }

  public toFilesBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
    const result = new AsyncSubject<SelectedFiles[]>();
    if (files?.length) {
      Object.keys(files)?.forEach(async (file, i) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (e) => {
          selectedFiles = selectedFiles?.filter(f => f?.fileName != files[i]?.name)
          selectedFiles.push({ fileName: files[i]?.name, fileSize : files[i]?.size, base64:reader?.result as string })
          result.next(selectedFiles);
         //const base64Img = (fileReader.result as string).substring((fileReader.result as string).indexOf( ',' ) + 1 );
          this.selectedFiles = selectedFiles;
          if (files?.length === (i + 1)) {
            result.complete();
          }
        };
      });
      return result;
    } else {
      result.next([]);
      result.complete();
      return result;
    }
  }

  public onFileSelected(event) {
    const file = event.target.files;
     this.selectedFiles = []; // clear
    console.log(file)
    this.toFilesBase64(file, this.selectedFiles).subscribe((res: SelectedFiles[]) => {
      this.selectedFiles = res;
    });
  }

  save() {
   // console.log(this.NoteForm.value); //I want to delete after checking
    let x = { ...this.NoteForm.value }
    let formdata: any = {
      description: x.description,
      tin_id: x.tin_id,
      category_id: x.category_id, 
      location_code: x.location_code,
      payer_code: x.payer_code,
      tracking_email: x.tracking_email,
      tracking_no: x.tracking_no,
      contact_name: x.contact_name,
      email_id: x.email_id,
      phone_number: x.phone_number,
      phone_extension: x.phone_extension,
      fax: x.fax,
      specific_date: x.specific_date,
      is_mail_req: x.is_mail_request ? '1' : '0',
      // is_applicable:x.is_applicable,
    }
   // console.log(formdata); //I want to delete after checking
  //   console.log(this.popupType);
    
  // console.log(x.category_id);

      //I want to delete after checking
    //for child array
    if (this.popupType.type == 'child') {
     
      formdata = {
        ...formdata,
        parent_id: this.popupType.data.parent_data.parent_id,
        payer_id: x.payer_id,
        category_item_id: x.category_item_id,
        parent_sub_status_id:x.sub_status_id,
      }
    } 
    else if(x.category_id ==1 || x.category_id==2 ){
      
      formdata={
        ...formdata,
        subject: x.subject,
        parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        hospital_affiliation_id:null,
        hospital_affiliation_order:null,

      }

    }
    else if(this.addpassport){
      // console.log(x.affliations?.id);
      // console.log(x.affliations.aff_order_id);
      if(x.affliations){
        formdata = {
          ...formdata,
          subject: x.subject,
          // parent_status_id: x.status_id,
          child_specific_date: x.specific_date,
          sub_status_id: x.sub_status_id,
          status_id: x.status_id,
          // affliations:x.affliations,
          hospital_affiliation_id:this.getStringData(x?.affliations),
          hospital_affiliation_order:this.getStringData(this.selectedAffliationValue),

      }
    }
    else{
      formdata = {
        ...formdata,
        subject: x.subject,
        // parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        // affliations:x.affliations,
        hospital_affiliation_id:null,
        hospital_affiliation_order:null,


    }

    }

  }
  
    
    else {
      // console.log(x.affliations?.id);
      // console.log(x.affliations.aff_order_id);
      if(x.affliations){
        formdata = {
          ...formdata,
          subject: x.subject,
          // parent_status_id: x.status_id,
          child_specific_date: x.specific_date,
          sub_status_id: x.sub_status_id,
          status_id: x.status_id,
          // affliations:x.affliations,
          hospital_affiliation_id:this.getStringData(x?.affliations),
          hospital_affiliation_order:this.getStringData(this.selectedAffliationValue),
  
      }
    }
    else{
      formdata = {
        ...formdata,
        subject: x.subject,
        // parent_status_id: x.status_id,
        child_specific_date: x.specific_date,
        sub_status_id: x.sub_status_id,
        status_id: x.status_id,
        // affliations:x.affliations,
        hospital_affiliation_id:null,
        hospital_affiliation_order:null,
  
  
    }
  
    }
  
  }
    
    
// console.log(x.affliations.id);
// console.log(x.affliations.aff_order_id);
// console.log(formdata);



    var params:any = {
      customerId: this.customerId,
      userId: this.userId,
      data: JSON.stringify(formdata),
      is_apply_payer_to_provider: x.is_apply_payer_to_provider ? 1 : 0,
      is_task: this.popupType.is_task==undefined?0:this.popupType.is_task
    }
    if(this.popupType.type == 'child'){
      params.parent_id =  this.popupType.data.parent_data.parent_id;
      params.status_id = x.status_id;
    }
    if(this.popupType.type !== 'child'){
      params.payer_id= x.payer_id,
      params.category_item_id= x.category_item_id,
      params.parent_id=null
    }

    if(this.selectedFiles.length != 0){
      for (let x in this.selectedFiles) {
      let size =  this.selectedFiles[x].fileSize.toString();
      let uploadFileSizeLimit = 60;
      console.log("File Size in MB : ",((+size/1024)/1000));
          if(((+size/1024)/1000) > uploadFileSizeLimit){
            this.notiService.showError("File size can not be greater than 60 MB.","",3000);
            this.selectedFiles = [];
            if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
              this.FileToUpload.nativeElement.value = null;
            }
            return
          }
      }
   } 

    let postUploadParams = new FormData();

    // if (this.selectedFile.length > 0) {
      for (let i in params) {
          postUploadParams.append(i, params[i]);
      }
      for (var i = 0; i < this.selectedFiles.length; i++) {
        postUploadParams.append("upload_file[]", JSON.stringify(this.selectedFiles[i]));
      }
      // for (var i = 0; i < this.selectedFile.length; i++) {
      //   postUploadParams.append("upload_file[]", this.selectedFile[i]);
      // }
    // }
    // // console.log(params);

    if (this.popupType.type == 'child') {
      this.spinner.show()
        this.CreateNoteService.saveChildnoteFile(postUploadParams).subscribe(
          data => {
            // console.log(data);
            this.spinner.hide();
            if (data['success']) {
              this.activeModal.close('success')
              this.notiService.showSuccess(data['status']['message'], '', 3000)
            }
            else {
              // this.notiService.showError(data['error'], '', 3000)
            // console.log(data['error']);
            // this.notiService.showError(data['error']['upload_file'][0],'',3000)
            let  x=data['error']
            for (const key in x) {
              if (x.hasOwnProperty(key)) {
                const element = x[key];
                this.notiService.showError(element[0],'',3000)
              }
            }
            }
            this.spinner.hide();
          },
          error => {
            // console.log(error);
            this.notiService.showError(File_Error)
            this.spinner.hide();
          }
        )
    }
    else {
      this.spinner.show()
        this.CreateNoteService.saveNoteFile(postUploadParams).subscribe(
          data=>{
           // console.log(data);
            this.spinner.hide();
            if(data['success']){
              this.selectedFiles = [];
              this.activeModal.close('success')
           //   this.notiService.showSuccess(data['status']['message'],'',3000);
              this.notiService.showSuccess(data['message'],'',3000);
              if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
                this.FileToUpload.nativeElement.value = null;
              }
            }
            else {
              // console.log(data['error']);
              this.selectedFiles = [];
              if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
                this.FileToUpload.nativeElement.value = null;
              }
              let x = data['error']
              for (const key in x) {
                if (x.hasOwnProperty(key)) {
                  const element = x[key];
                  this.notiService.showError(element[0], '', 3000)
                }
              }
            }
          },
          error=>{
            // console.log(error);
            this.selectedFiles = [];
            if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
              this.FileToUpload.nativeElement.value = null;
            }
           
            this.notiService.showError(JSON.parse(error),'',3000)
            this.spinner.hide();
          }
        )
    }
  }

  getStringData(array: Array<any>) {
    let t = ''
    for (let i = 0; i < array.length; i++) {
      if (i == 0) {
        t = array[i].toString()
      }
      else {
        t = t + ',' + array[i]
      }
    }
    return t
  }

  onClear() {
    this.submitted = false;
    // this.NoteForm.reset();
    this.FileToUpload = null
    this.buildNoteForm()
    if (this.popupType.type == 'child') {
      this.handleChildNote(this.popupType.data.parent_data,this.popupType.data.hospital_affiliation_id_name);
    }
    else if (this.popupType.place != 'workflow') {
      this.handleCategoy(this.popupType.data.values);
    }
  }

  handlePayer(){
  
        if(this.NoteForm.value.payer_id.length == 1 && this.NoteForm.value.category_item_id.length == 1){
            this.multipleSelection = true
            this.disableUploadFile = false
        } else {
            this.multipleSelection = false
            this.disableUploadFile = true
        }

      this.selectedFiles = [];
      if (this.FileToUpload && this.FileToUpload.nativeElement !== undefined) {
        this.FileToUpload.nativeElement.value = null;
      }
  }

}
