import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OnboardingformsServiceService } from "../../services/onboardingforms-service/onboardingforms-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToasterNotiService } from "../../services/notifications/toaster-noti.service";
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { EsignComponent } from "../esign/esign.component";
import { DomSanitizer } from "@angular/platform-browser";
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from "../../DateFormatter/customDate";
import { StorageService } from "../../services/storage/storage.service";
import { NgIf } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
    selector: "ctg-sage-neuroscience",
    templateUrl: "./sage-neuroscience.component.html",
    styleUrls: ["./sage-neuroscience.component.scss"],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgSelectModule, NgIf, NgbDatepickerModule]
})
export class SageNeuroscienceComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private _sanitizer: DomSanitizer
  ) {}
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public sageneu: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: "md",
    keyboard: false,
    backdrop: "static",
  };
  suffix: Array<object> = [];
  credentials: Array<object> = [];
  states: Array<object> = [];
  degree: Array<object> = [];
  specialty: Array<object> = [];
  gender: Array<object> = [];
  removeFieldVAlue: boolean = false;
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  boardCertification: Array<object> = [];
  specialtySecondary: Array<object> = [];
  specialtyTer: Array<object> = [];
  staffStatus: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  county: Array<object> = [];
  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  showOne: any = false;
  isSubmit: boolean = false;
  // changeCmdOne: any;
  changeCmdTwo: any;
  // changeCmdThree: any;
  doesNotApplyVar: any;
  public eduapp: boolean = false;
  public workapp: boolean = false;
  public hospapp: boolean = false;
  public refapp: boolean = false;
  public proapp: boolean = false;
  public premlpapp: boolean = false;
  public prospeapp: boolean = false;
  public licenseapp: boolean = false;
  public dataRadio: boolean = false;

  firstName: any;
  lastName: any;
  finalName: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  yesNoQuestionIds: any = [];
  yesNoQuestionExplanation: any = [];
  questionCounter = 0;

  // questionCounter = 0;
  // answerCounter = 0;
  // questionNumbers = '';
  indexKey = 0;
  private emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  customerId = 0;
  private zippattern = /^.{5,9}$/;
  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem("customerId"));
    console.log(this.customerId);
    this.buildForm();
    this.getCredentialsData();
    this.getGenderData();
    this.getSpecialtyData();
    this.getSuffixData();
    this.getDegreeData();
    this.getStatesData();
    this.getAltCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getBoardCertificationData();
    this.getSpecialtySecondaryData();
    this.getStaffStatusData();
    this.getTerSpecialtyData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getCountyData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    // if(this.formData.receivedForm == true){
    // console.log(this.formData.receivedForm);
    this.afterSaveDataDisplay();
    // }
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == "cmd_11122") {
        if (array[i].field_value != "") {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      
    }
    console.log(fieldsCtrls);
    this.sageneu.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath,
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data["success"]) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  clearInputIfInvalid(controlName: string, handleYesNoValue?: string) {
    const control = this.sageneu.get(controlName);
    const dateString = control.value;

    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(
      new FormControl(dateString)
    );

    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue("");
      control.setErrors(null);
    }
    if (handleYesNoValue) {
   
        this.handleNAchange(handleYesNoValue);
     
    }
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.sageneu.get(controlName);
    const dateString = control.value;

    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(
      new FormControl(dateString)
    );

    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue("");
    }
  }

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;

    if (dateString && !dateString.match(dateFormat)) {
      return { invalidDateFormat: true };
    }

    if (dateString) {
      const dateParts = dateString.split("/");
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);

      // Check if any of the date components are zero or month is not between 1 and 12

      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { invalidMonth: true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { invalidDateRange: true };
        }
      } else if (month === 2) {
        const leapYear =
          (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        if (!leapYear && day >= 29) {
          return { invalidDateRange: true };
        } else if (leapYear && day > 29) {
          return { invalidDateRange: true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { invalidDateyear: true };
      }
    }

    return null;
  }


  public zipCodeValidator(control: FormControl): ValidationErrors | null {
    const zipCode = control.value;
    const zipCodePattern = /^.{5,9}$/;

    if (zipCode && !zipCode.match(zipCodePattern)) {
      return { invalidZipCode: true };
    }

    return null;
  }
  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == "cmd_11122") {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + filePath
      );
      this.showOne = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 51,
      formId: this.formId,
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  buildForm() {
    this.sageneu = this.fb.group({
      9: ["", [Validators.required]],
      8: ["", [Validators.required]],
      809: [""],
      278: [""],
      280: [null, [this.dateFormatValidator]],
      281: [null, [this.dateFormatValidator]],
      cmd_01: [null, [this.dateFormatValidator]],
      cmd_02: [null, [this.dateFormatValidator]],
      16: ["", [Validators.required]],
      17: [""],
      18: ["", [Validators.required]],
      20: [null, [Validators.required]],
      29: [null],
      822: [null],
      2337: [""],
      21: ["", [Validators.required, this.zipCodeValidator]],
      708: ["", [Validators.required]],
      32: ["", [Validators.required]],
      30: [null],
      cmd_837: [""],
      cmd_838: [""],
      cmd_839: [""],
      cmd_841: [null],
      cmd_842: ["", [Validators.pattern(this.zippattern)]],
      cmd_870: [""],
      cmd_871: [""],
      cmd_017: [""],
      759: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      31: [null, [Validators.required, this.dateFormatValidator]],
      810: [""],
      289: [null],
      504: [""],
      505: [""],
      cmd_06: [""],
   
      cmd_0258: [""],
      cmd_025: [""],
      cmd_026: [""],
      cmd_027: [""],
      1138: [""],
      430: [""],
      cmd_0301: [""],
      cmd_11122: ["", [Validators.required]],
      480: [null],
      308: [null, [this.dateFormatValidator]],
      999: [null],
      1010: [null],
      946: [null],
      292: [""],
      1584: [""],
      1282: [""],
      cmd_04: [""],
      333: ["", Validators.required],
      334: ["", Validators.required],
      335: ["", Validators.required],
      cmd_05: [""],
      1144: [null, this.dateFormatValidator],
      1145: [null, this.dateFormatValidator],
      1139: [""],
      1141: [""],
      490: [""],
      491: [""],
      492: [""],
      493: [""],
      cmd_0722: [""],
      495: [null],
      496: ["", [this.zipCodeValidator]],
      500: [null],
      497: [null, [this.dateFormatValidator]],
      498: [null, [this.dateFormatValidator]],
      cmd_0812: [""],
      cmd_072235: [""],
      cmd_08: [""],
   
      527: [null],
      517: [""],
      518: [""],
      519: [""],
      520: [""],
      522: [null],
      523: ["", [this.zipCodeValidator]],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      cmd_09: [""],
      528: [""],
      818: [""],
      cmd_010: [""],
      530: [null],
      529: [""],
      531: [""],
      1498: [""],
      533: [""],
      535: [null],
      536: ["", [Validators.pattern(this.zippattern)]],
      cmd_011: [""],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      540: [""],
      541: [""],
      cmd_0813:[""],
      503: [""],
      506: [""],
      508: [null],
      509: ["", [Validators.pattern(this.zippattern)]],
      512: [null],
      1238: [null, [this.dateFormatValidator]],
      510: [null, [this.dateFormatValidator]],
      296: ["", [Validators.required]],
      293: ["", [Validators.required]],
      299: [null, [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      cmd_013: [""],
      1274: [""],
      300: [""],
      907: [null],
      307: [null, [this.dateFormatValidator]],
      cmd_014: [""],
      1432: [""],
      1003: [null, [this.dateFormatValidator]],
      1004: [null, [this.dateFormatValidator]],
      cmd_015: [""],
      cmd_016: [""],
      309: ["",Validators.required],
      310: [null, [Validators.required,this.dateFormatValidator]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      337: [""],
      338: [null, [this.dateFormatValidator]],
      339: [null, [this.dateFormatValidator]],
      cmd_019: [""],
      340: [""],
      997: [null],
      341: [null, [this.dateFormatValidator]],
      342: [null, [this.dateFormatValidator]],
      cmd_020: [""],
      819: [""],
      cmd_021: [""],
      325: [""],
      1634: ["", [Validators.required]],
      1635: ["", [Validators.required]],
      1636: ["", [Validators.required]],
      4571: ["", [Validators.required]],
      cmd_022: [""],
      812: [""],
      cmd_023: [""],
      514: [""],
      515: [null, [this.dateFormatValidator]],
      cmd_0726: [""],
      284: [null],
      cmd_024: [""],
      554: [null],
      557: [null, [this.dateFormatValidator]],
      558: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      cmd_029: [""],
      cmd_030: [""],
      cmd_031: [""],
      cmd_028: [""],
      // cmd_032: [""],
      // cmd_033: [""],
      // cmd_034: [""],
      962: [null],
      cmd_035: [""],
      561: [null],
      564: [null, [this.dateFormatValidator]],
      565: [null, [this.dateFormatValidator]],
      567: [null, [this.dateFormatValidator]],
      cmd_036: [""],
      cmd_040: [""],
      cmd_037: [""],
      cmd_041: [""],
      cmd_042: [""],
      cmd_039: [""],
      // cmd_043: [""],
      cmd_044: [""],
      cmd_045: [""],
      965: [null],
      cmd_046: [""],
      953: [null, [this.dateFormatValidator]],
      950: [null, [this.dateFormatValidator]],
      954: [null, [this.dateFormatValidator]],
      cmd_047: [""],
      cmd_051: [""],
      cmd_048: [""],
      cmd_049: [""],
      cmd_052: [""],
      cmd_050: [""],
      cmd_053: [""],
      // cmd_054: [""],
      cmd_055: [""],
      cmd_056: [""],
      3826: [""],
      cmd_0727: [""],
      445: [""],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      446: [""],
      447: [""],
      448: [""],
      450: [null],
      451: ["", [this.zipCodeValidator]],
      456: [""],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      457: [""],
      458: [""],
      459: [""],
      461: [null],
      462: ["", [Validators.pattern(this.zippattern)]],
      1338: [""],
      467: [""],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      468: [""],
      469: [""],
      470: [""],
      472: [null],
      473: ["", [Validators.pattern(this.zippattern)]],
      1342: [""],
      1935: [""],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      1936: [""],
      1937: [""],
      1938: [""],
      1940: [null],
      1941: ["", [Validators.pattern(this.zippattern)]],
      1952: [""],
      3395: [null, [this.dateFormatValidator]],
      3396: [null, [this.dateFormatValidator]],
      3397: [null, [this.dateFormatValidator]],
      3398: [null, [this.dateFormatValidator]],
      1831: [""],
      1832: [""],

      3399: [null, [this.dateFormatValidator]],
      3400: [null, [this.dateFormatValidator]],
      3401: [null, [this.dateFormatValidator]],
      3402: [null, [this.dateFormatValidator]],
      1953: [""],
      cmd_0814:[""],
      cmd_0723: [""],
      cmd_059: [""],
      cmd_060: [""],
      418: [""],
      426: [null, [this.dateFormatValidator]],
      419: [""],
      420: [""],
      421: [""],
      424: ["", [this.zipCodeValidator]],
      423: [null],
      1305: [""],
      2136: [""],
      2142: ["", [Validators.pattern(this.emailPattern)]],
      cmd_061: [""],
      894: [null],
      cmd_062: [""],
      cmd_063: [""],
      427: [""],
      435: [null, [this.dateFormatValidator]],
      428: [""],
      429: [""],
      432: [null],
      433: ["", [Validators.pattern(this.zippattern)]],
      1307: [""],
      2137: [""],
      // 442 : ['', [Validators.required]],
      2143: ["", [Validators.pattern(this.emailPattern)]],
      cmd_064: [""],
      895: [null],
      cmd_065: [""],
      cmd_066: [""],
      cmd_0815:[""],
      1183: [""],
      1184: [""],
      1185: [""],
      1191: [null, [this.dateFormatValidator]],
      2381: [null, [this.dateFormatValidator]],
      1189: ["", [Validators.pattern(this.zippattern)]],
      cmd_068: [""],
      1192: [null],
      cmd_069: [""],
      1186: [""],
      1188: [null],
      1303: [""],
      cmd_0816:[""],
      cmd_0817:[""],
      cmd_0818:[""],
      cmd_0819:[""],
      cmd_0820:[""],
      cmd_0821:[""],
      cmd_0822:[""],
      cmd_0823:[""],
      cmd_0824:[""],
      cmd_0825:[""],
      cmd_0826:[""],
      cmd_0827:[""],
      cmd_0828:[""],
      cmd_0829:[""],
      cmd_0830:[""],
     
      // cmd_07259: [""],
      
     
      cmd_0725: [""],
      cmd_071: [""],
      345: [""],
      901: [""],
      902: [""],
      903: [""],
      905: [null],
      906: ["", [this.zipCodeValidator]],
      1266: [""],
      346: [""],
      348: [null, [this.dateFormatValidator]],
      349: [null, [this.dateFormatValidator]],
      350: [null],
      351: [null],
      1242: [""],
      cmd_072: [""],
      cmd_073: [""],
  
      1244: [""],
      1245: [""],
      1246: [""],
      1248: [null],
      1249: ["", [Validators.pattern(this.zippattern)]],
      1254: [""],
      1256: [""],
      1252: [null, [this.dateFormatValidator]],
      1253: [null, [this.dateFormatValidator]],
      1264: [null],
      1265: [null],
      cmd_074: [""],
      cmd_075: [""],
  
    
    
      
     
      
      cmd_0232: ["", [Validators.required]],
      cmd_0233: ["", [Validators.required]],
      cmd_0234: ["", [Validators.required]],
      cmd_0235: ["", [Validators.required]],
      cmd_0236: ["", [Validators.required]],
      cmd_0237: ["", [Validators.required]],
      cmd_0238: ["", [Validators.required]],
      cmd_0239: ["", [Validators.required]],
      cmd_0240: ["", [Validators.required]],
      cmd_0241: ["", [Validators.required]],
      cmd_0242: ["", [Validators.required]],
      cmd_0243: ["", [Validators.required]],
      cmd_0244: ["", [Validators.required]],
      cmd_0245: ["", [Validators.required]],
      cmd_0246: ["", [Validators.required]],
      cmd_08171:[""],
      cmd_0247: ["", [Validators.required]],
      cmd_0248: ["", [Validators.required]],
      cmd_0249: ["", [Validators.required]],
      cmd_0250: ["", [Validators.required]],
      cmd_0251: ["", [Validators.required]],
      cmd_0252: ["", [Validators.required]],
      cmd_0253: ["", [Validators.required]],
      cmd_0254: ["", [Validators.required]],
      cmd_0255: ["", [Validators.required]],
      cmd_0256: [""],
      cmd_0257: ["", [Validators.required]],
      cmd_0259: ["", [Validators.required]],
      cmd_0260: [""],
      cmd_0261: ["", [Validators.required]],
      cmd_0262: [""],
      cmd_0263: ["", [Validators.required]],
      cmd_0264: [""],
      cmd_0265: ["", [Validators.required]],
      cmd_0266: [""],
      cmd_0267: ["", [Validators.required]],
      cmd_0268: [""],
      cmd_0269: ["", [Validators.required]],
      cmd_0270: [""],
      cmd_0271: ["", [Validators.required]],
      cmd_0272: [""],
      cmd_0273: ["", [Validators.required]],
      cmd_0274: [""],
      cmd_0275: ["", [Validators.required]],
      cmd_0276: [""],
      cmd_0277: ["", [Validators.required]],
      cmd_0278: [""],
      cmd_0279: ["", [Validators.required]],
      cmd_0390: [""],
      cmd_038: [""],
      cmd_0281: ["", [Validators.required]],
      cmd_0282: [""],
      cmd_0283: ["", [Validators.required]],
      cmd_0284: [""],
      cmd_0285: ["", [Validators.required]],
      cmd_0286: [""],
      cmd_0287: ["", [Validators.required]],
      cmd_0288: [""],
      cmd_0289: ["", [Validators.required]],
      cmd_0290: [""],
      cmd_0291: ["", [Validators.required]],
      cmd_0292: [""],
      cmd_0293: ["", [Validators.required]],
      cmd_0294: [""],
      cmd_0295: ["", [Validators.required]],
      cmd_0296: [""],
      cmd_0297: ["", [Validators.required]],
      cmd_0298: [""],
      cmd_0299: ["", [Validators.required]],
      cmd_0300: [""],
      cmd_0302: [""],
      cmd_0303: [""],
      cmd_0304: [""],
      cmd_0305: [""],
      cmd_0306: [""],
      cmd_0307: [""],
      cmd_0897: ["",[Validators.required]],
      cmd_0779: ["",[Validators.required]],
      cmd_0310: [null, [Validators.required,this.dateFormatValidator]],
      cmd_0280: [null],
    });
    // console.log(this.sageneu);
    this.sageneu.get("cmd_11122").value;
    // this.onChangeCmdOne(1)
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.credentials = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }
  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + " " + this.lastName;
    let temp = {
      cmd_0897: this.finalName,
    };
    this.sageneu.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + " " + this.lastName;
    let temp = {
      cmd_0897: this.finalName,
    };
    this.sageneu.patchValue(temp);
  }
  
 
  setErrorControl(name) {
    this.sageneu.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name) {
    this.sageneu.controls[name].setErrors(null);
  }
  handleNAchange(cName, eName?) {
    let result: boolean = false;
    if (cName == "cmd_0722") {
      let x = [490, 491, 493, 495, 496, 497, 498, 500];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else if (
          element === 496 &&
          !/^.{5,9}$/.test(ctrl.value) &&
          (!this.sageneu.controls[cName].value ||
            (this.sageneu.controls[cName].value &&
              ctrl.value !== "" &&
              ctrl.value !== null))
        ) {
          this.setErrorControl(element);
          ctrl.setErrors({ invalidZipCode: true });
          result = true;
        } else if (element === 496 && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if (cName == "cmd_072235") {
      let x = ['cmd_08',527,517,518 ,520,522,523];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else if (
          element === 523 &&
          !/^.{5,9}$/.test(ctrl.value) &&
          (!this.sageneu.controls[cName].value ||
            (this.sageneu.controls[cName].value &&
              ctrl.value !== "" &&
              ctrl.value !== null))
        ) {
          this.setErrorControl(element);
          ctrl.setErrors({ invalidZipCode: true });
          result = true;
        } else if (element === 523 && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if (cName == "cmd_0726") {
      let x = ["cmd_024", 557, 284,558,560];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if (cName == "cmd_0727") {
      let x = [445, 454, 455, 446, 448, 450, 451];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else if (
          element === 451 &&
          !/^.{5,9}$/.test(ctrl.value) &&
          (!this.sageneu.controls[cName].value ||
            (this.sageneu.controls[cName].value &&
              ctrl.value !== "" &&
              ctrl.value !== null))
        ) {
          this.setErrorControl(element);
          ctrl.setErrors({ invalidZipCode: true });
          result = true;
        } else if (element === 451 && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if (cName == "cmd_0723") {
      let x = [418, 426, 419, 421, 423, 424, 1305 ,2136,2142,'cmd_061',894,'cmd_062'];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else if (
          element === 424 &&
          !/^.{5,9}$/.test(ctrl.value) &&
          (!this.sageneu.controls[cName].value ||
            (this.sageneu.controls[cName].value &&
              ctrl.value !== "" &&
              ctrl.value !== null))
        ) {
          this.setErrorControl(element);
          ctrl.setErrors({ invalidZipCode: true });
          result = true;
        } else if (element === 424 && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } 
        else if ((element === 2142) && !this.emailPattern.test(ctrl.value) && (!this.sageneu.controls[cName].value || (this.sageneu.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          this.setErrorControl(element);
          ctrl.setErrors({ 'invalidEmail': true });
          result = true;
        } else if ((element === 2142) && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
        else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if (cName == "cmd_0725") {
      let x = [345, 901, 903, 905, 906, 1266, 346, 348, 349, 350, 351,'cmd_072','cmd_073'];
      x.forEach((element) => {
        const ctrl = this.sageneu.controls[element];
        if (
          (ctrl.value === "" || ctrl.value === null) &&
          !this.sageneu.controls[cName].value
        ) {
          this.setErrorControl(element);
          result = true;
        } else if (
          element === 906 &&
          !/^.{5,9}$/.test(ctrl.value) &&
          (!this.sageneu.controls[cName].value ||
            (this.sageneu.controls[cName].value &&
              ctrl.value !== "" &&
              ctrl.value !== null))
        ) {
          this.setErrorControl(element);
          ctrl.setErrors({ invalidZipCode: true });
          result = true;
        } else if (element === 906 && this.sageneu.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    
   
    
  }
  handleYesNoChanges(cName, eName?) {
    let result: boolean = false;

   
    
    if (cName == "cmd_024") {
      let x = ["554"];
      x.forEach((element) => {
        if (
          (this.sageneu.controls[element].value == "" ||
            this.sageneu.controls[element].value == null) &&
          this.sageneu.controls["cmd_024"].value == 1
        ) {
          this.setErrorControl(element);
        } else {
          this.removeErrorControl(element);
        }
      });
    }
  }

 
  validateInput() {
    Object.values(this.sageneu.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });
  }

  
  

  

  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.specialtyTer = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.county = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.altLicenseState = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.AltCdsStates = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.additionalLicenseState = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.degree = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.specialty = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.states = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.gender = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.suffix = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.specialtySecondary = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.boardCertification = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.staffStatus = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.aggregates = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      (data) => {
        if (data["success"]) {
          this.preClaim = data.data.map((i) => ({
            ...i,
            id: i.id.toString(),
          }));
        } else {
          this.notiService.showError(data.error, "", 3000);
        }
        //this.spinner.hide();
      },
      (error) => {
        //this.spinner.hide();
      }
    );
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then((result) => {});
    modalRef.componentInstance.base64Img.subscribe((data) => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_11122: this.finalImgPath,
      };
      this.sageneu.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + this.finalImg
      );
      this.showOne = true;
    });
  }

  saveForm(type) {
    this.isSubmit = true;
    this.handleNAchange("cmd_0722");
    this.handleNAchange("cmd_072235");
    this.handleNAchange("cmd_0726");
    this.handleNAchange("cmd_0727");
    this.handleNAchange("cmd_0723");
    this.handleNAchange("cmd_0725");

    // this.handleNAchange("cmd_07259");
    
    // this.handleYesNoChanges('cmd_05');
    this.handleYesNoChanges("cmd_024");
    
    console.log(this.sageneu);
    this.sageneu.get("cmd_11122").value;
    this.validationsDataAdd();
    console.log(this.sageneu.controls);
   
    if (type == "submit") {
      if (this.sageneu.invalid) {
        this.notiService.showError(
          "Please Fill the all mandatory fields to continue",
          "",
          3000
        );
        for (const key in this.sageneu.controls) {
          if (this.sageneu.controls.hasOwnProperty(key)) {
            const element = this.sageneu.controls[key];
            if (element.status == "INVALID") {
              console.log(key + " " + element.status);
            }
          }
        }
      } else {
        if (
          this.sageneu.controls["cmd_11122"].value == null ||
          this.sageneu.controls["cmd_11122"].value == ""
        ) {
          this.notiService.showError("Please add your Signature");
          return;
        }
        let data = {
          formType: type,
          formValues: this.sageneu.value,
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.sageneu);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.sageneu.value,
        formId: 51,
      };
      this.onSubmit.emit(data);
      this.onClose.emit("close modal");
    }
  }

  close() {
    this.onClose.emit("close modal");
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getSsn(value) {
    this.sageneu.patchValue({
      cmd_0779: value,
    });
  }

  public validationsDataAdd(event?) {
    let yesNoQuestionIds = {
      cmd_0232: "cmd_0255",
      cmd_0233: "cmd_0257",
      cmd_0234: "cmd_0259",
      cmd_0235: "cmd_0261",
      cmd_0236: "cmd_0263",
      cmd_0237: "cmd_0265",
      cmd_0238: "cmd_0267",
      cmd_0239: "cmd_0269",
      cmd_0240: "cmd_0271",
      cmd_0241: "cmd_0273",
      cmd_0242: "cmd_0275",
      cmd_0243: "cmd_0277",
      cmd_0244: "cmd_0279",
      cmd_0245: "cmd_0281",
      cmd_0246: "cmd_0283",
      cmd_0247: "cmd_0285",
      cmd_0248: "cmd_0287",
      cmd_0249: "cmd_0289",
      cmd_0250: "cmd_0291",
      cmd_0251: "cmd_0293",
      cmd_0252: "cmd_0295",
      cmd_0253: "cmd_0297",
      cmd_0254: "cmd_0299",
    };
    // ID of respective explanation section
    let yesNoQuestionExplanation = [
      "cmd_0255",
      "cmd_0257",
      "cmd_0259",
      "cmd_0261",
      "cmd_0263",
      "cmd_0265",
      "cmd_0267",
      "cmd_0269",
      "cmd_0271",
      "cmd_0273",
      "cmd_0275",
      "cmd_0277",
      "cmd_0279",
      "cmd_0281",
      "cmd_0283",
      "cmd_0285",
      "cmd_0287",
      "cmd_0289",
      "cmd_0291",
      "cmd_0293",
      "cmd_0295",
      "cmd_0297",
      "cmd_0299",
    ];
    this.questionCounter = 0;
    for (const key in yesNoQuestionIds) {
      if (yesNoQuestionIds.hasOwnProperty(key)) {
        // console.log(yesNoQuestionIds[key]);
        const element = this.sageneu.controls[key].value;
        // console.log(element);
        if (element == 1) {
          this.questionCounter++;
        }
      }
    }
    // console.log(this.questionCounter);
    for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
      // console.log(yesNoQuestionExplanation[0]);
      if (this.indexKey < this.questionCounter) {
        // console.log(this.sageneu.controls[yesNoQuestionExplanation[this.indexKey]])
        if (
          this.sageneu.controls[yesNoQuestionExplanation[this.indexKey]]
            .value == "" ||
          this.sageneu.controls[yesNoQuestionExplanation[this.indexKey]]
            .value == null
        ) {
          // console.log('setting error')
          this.sageneu.controls[
            yesNoQuestionExplanation[this.indexKey]
          ].setErrors({
            errors: true,
          });
        }
      } else {
        // console.log('removing error');
        // console.log('else error');
        this.sageneu.controls[
          yesNoQuestionExplanation[this.indexKey]
        ].setErrors(null);
      }
    }
    
  }
}
