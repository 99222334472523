import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { ChangeDetectorRef } from '@angular/core';
import { InterMedLLCSection1Component } from './Intermed-llc-Section1/Intermed-llc-Section1.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'ctg-Intermed-llc',
    templateUrl: './Intermed-llc.component.html',
    styleUrls: ['./Intermed-llc.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule, InterMedLLCSection1Component]
})
export class InterMedLLCComponent implements OnInit 
{

   public intermedimage: FormGroup;
   isSubmit: boolean = false;
   private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   private phonePattern = '^[0-9]*$';

   constructor(private fb: FormBuilder,
   private modalService: NgbModal,
   private _sanitizer: DomSanitizer,
   private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,
   private cdr: ChangeDetectorRef
   ) { }

   @Input() formData;
   @Input() fieldsData;
   @Input() formId;
   @Output() onSubmit = new EventEmitter();
   @Output() onClose = new EventEmitter();

   modalOption: NgbModalOptions = {
   size: 'md',
   keyboard: false,
   backdrop: 'static'
   };

   ngOnInit(): void {

   this.buildForm();
   this.getCredentialsData();
   this.getStatesData();
   this.getDegreeData();
   this.getLanguagesData();
   this.getsecondaryLanguagesData();
   this.getGenderData();
   this.getSpecialtyData();
   this.getSpecialtySecondaryData();
   this.getStaffStatusData();
   this.getPreClaimData();
   this.getAggregatesData();
   this.getSuffixData();
   this.getAlternateLicenseStateData();
   this.getAdditionalLicenseStateData();
   this.getBoardCertificationData();
   this.getMediCertifications();
   this.getTerSpecialtyData();
   this.afterSaveDataDisplay();
  
   


   this.fieldsData && this.bindFormValue(this.fieldsData);

   console.log(this.formData);
   }
    
   bcRadioButtonNo: boolean = false;
   bcRadioButtonYes: boolean = false;
   radioStatus: boolean;
   flagForHandle: boolean =  false;

   public suffix: any = [];
   altLicenseState: Array<object> = [];
   additionalLicenseState: Array<object> = [];
   boardCertification: Array<object> = [];
   mediCertifications: Array<object> = [];
   specialtyTer: Array<object> = [];
   credentials: Array<object> = [];
   states: Array<object> = [];
   degree: Array<object> = [];
   secondaryLanguages: Array<object> = [];
   languages: Array<object> = [];
   gender: Array<object> = [];
   specialty: Array<object> = [];
   specialtySecondary: Array<object> = [];
   staffStatus: Array<object> = [];
   public preClaim: any = [];
   public aggregates: any = [];



   finalImg: any;
   finalImgPath: any = [];
   finalImgPathTwo: any = [];
   finalImgTwo: any = '';
   esignOne: any;
   esignTwo: any;
   showOne: any = false;
   showTwo: any = false;
   firstName: any;
   lastName: any;
   finalName: any;



    onChangeFirstName(event) 
    {
      this.firstName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
      cmd_33553: this.finalName,
      cmd_43554:this.finalName,
      cmd_03550:this.finalName,
      cmd_13551:this.finalName,
      cmd_356:this.finalName,
      };
      this.intermedimage.patchValue(temp);
    }
    onChangeLastName(event) 
    {
      this.lastName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
         cmd_33553: this.finalName,
         cmd_43554:this.finalName,
         cmd_03550:this.finalName,
         cmd_13551:this.finalName,
         cmd_356:this.finalName,
       };
      this.intermedimage.patchValue(temp);
    }
    public afterSaveDataDisplay() {
      let params = {
        type: 39,
        formId: this.formId
      };
      //this.spinner.show();
      this.onboardService.toGetSaveData(params).subscribe(
        data => {
          if (data['success']) {
            this.bindFormValue(data.data);
          } else {
            //this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          //this.notiService.showError(Error_Message, '', 3000)
        }
      );
    }
    public getSuffixData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSuffixData(params).subscribe(
        data => {
          if (data['success']) {
            this.suffix = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getSpecialtySecondaryData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtySecondaryData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtySecondary = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getBoardCertificationData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getBoardCertificationData(params).subscribe(
        data => {
          if (data['success']) {
            this.boardCertification = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getMediCertifications() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getMediCertifications(params).subscribe(
        data => {
          if (data['success']) {
            this.mediCertifications = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getAdditionalLicenseStateData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAdditionalLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.additionalLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getAlternateLicenseStateData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAlternateLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.altLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getAggregatesData()
   {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAggregatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.aggregates = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getPreClaimData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getPreClaimData(params).subscribe(
        data => {
          if (data['success']) {
            this.preClaim = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getStaffStatusData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStaffStatusData(params).subscribe(
        data => {
          if (data['success']) {
            this.staffStatus = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getGenderData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getGenderData(params).subscribe(
        data => {
          if (data['success']) {
            this.gender = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getsecondaryLanguagesData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getsecondaryLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.secondaryLanguages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getLanguagesData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getLanguagesData(params).subscribe(
        data => {
          if (data['success']) {
            this.languages = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getDegreeData()
     {
      let params: {};
      this.onboardService.getDegreeData(params).subscribe(
        data => {
          if (data['success']) {
            this.degree = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
        },
        error => {
        }
      );
    }
    public getCredentialsData()
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getCredentialsData(params).subscribe(
        data => {
          if (data['success']) {
            this.credentials = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  
    public getStatesData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.states = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getTerSpecialtyData() 
    {
      let params: {};
      //this.spinner.show();
      this.onboardService.getTerSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtyTer = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

 public bindFormValue(array) 
 {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) 
     {
       fieldsCtrls[array[i].field_name] = array[i].field_value;
        if (array[i].field_name == 'cmd_353'||array[i].field_name=='cmd_355'|| array[i].field_name=='cmd_370') 
           {
             if (array[i].field_value != '')
               {
               this.previewEsign(array[i].field_value, array[i].field_name);
               }
            }
      }
    this.intermedimage.patchValue(fieldsCtrls);
    console.log(this.intermedimage)
  } 




  public previewEsign(filePath, fieldId)
  {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.setEsign(data.base64, fieldId);
        } else {
        }
      },
      error => {
      }
    );
  }


 public setEsign(filePath, fieldId) 
 {
      // console.log(filePath);
      if (fieldId == 'cmd_353') {
         this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
            'data:image/jpg;base64,' + filePath
         );
         this.showOne = true;
      }
      if (fieldId == 'cmd_355') {
         this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
            'data:image/jpg;base64,' + filePath
         );
         this.showTwo = true;
      }
  }
 public esignOpenOne()
 {
   const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
         modalRef.close();
         this.finalImgPath = data.finalFilePath;
            let temp = {
            cmd_353: this.finalImgPath
            };
         this.intermedimage.patchValue(temp);
         this.finalImg = data.base64Img;
         console.log(this.finalImg);
         this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
           'data:image/jpg;base64,' + this.finalImg
         );
         console.log(this.esignOne);
         this.showOne = true;
    });
 }
 
   public esignOpenTwo()
   {
     const modalRef = this.modalService.open(EsignComponent, this.modalOption);
     modalRef.result.then(result => { });
     modalRef.componentInstance.base64Img.subscribe(data => {
         modalRef.close();
         this.finalImgPathTwo = data.finalFilePath;
            let temp = {
            cmd_355: this.finalImgPathTwo
            };
         this.intermedimage.patchValue(temp);
         this.finalImgPathTwo = data.finalFilePath;
         this.finalImgTwo = data.base64Img;
         this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
           'data:image/jpg;base64,' + this.finalImgTwo
            );
         this.showTwo = true;
     });
    }
  setErrorControl(name) {
    this.intermedimage.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name) {
    this.intermedimage.controls[name].setErrors(null)
  }
  // BCRadioButtonYes(event) {
  //   // alert(event.target.value);
  //   this.bcRadioButtonYes = event.target.value;
  //   this.intermedimage.controls['cmd_0174'].setErrors(null);
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls[554].value == null) {
  //     this.intermedimage.controls[554].setErrors({ errors: true });
  //   }
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls["cmd_175"].value == null) {
  //     this.intermedimage.controls["cmd_175"].setErrors({ errors: true });
  //   }
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls[556].value == null) {
  //     this.intermedimage.controls[556].setErrors({ errors: true });
  //   }
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls[557].value == null) {
  //     this.intermedimage.controls[557].setErrors({ errors: true });
  //   }
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls[558].value == null) {
  //     this.intermedimage.controls[558].setErrors({ errors: true });
  //   }
  //   if (!this.intermedimage.controls['cmd_173'].value && this.bcRadioButtonYes && this.intermedimage.controls[560].value == null) {
  //     this.intermedimage.controls[560].setErrors({ errors: true });
  //   }
  //   this.bcRadioButtonNo = false;
  // }
  BCRadioButtonYes(event) {
    // alert(event.target.value);
    this.bcRadioButtonNo = false;
    this.flagForHandle=false;
    if(this.intermedimage.controls["cmd_173"].value ==true){
      this.intermedimage.controls[554].setErrors(null);
      this.intermedimage.controls["cmd_175"].setErrors(null);
      this.intermedimage.controls[556].setErrors(null);
      this.intermedimage.controls[557].setErrors(null);
      this.intermedimage.controls[558].setErrors(null);
      this.intermedimage.controls[560].setErrors(null);
      return 
  
    }
  
    this.bcRadioButtonYes = event.target.value;
    this.intermedimage.controls["cmd_0174"].setErrors(null);
    if (this.bcRadioButtonYes && this.intermedimage.controls[554].value == null) {
      this.intermedimage.controls[554].setErrors({ errors: true });
    }
    if (this.bcRadioButtonYes && this.intermedimage.controls["cmd_175"].value == null) {
      this.intermedimage.controls["cmd_175"].setErrors({ errors: true });
    }
    if (this.bcRadioButtonYes && this.intermedimage.controls[556].value == null) {
      this.intermedimage.controls[556].setErrors({ errors: true });
    }
    if (this.bcRadioButtonYes && this.intermedimage.controls[557].value == null) {
      this.intermedimage.controls[557].setErrors({ errors: true });
    }
    if (this.bcRadioButtonYes && this.intermedimage.controls[558].value == null) {
      this.intermedimage.controls[558].setErrors({ errors: true });
    }
    if (this.bcRadioButtonYes && this.intermedimage.controls[560].value == null) {
      this.intermedimage.controls[560].setErrors({ errors: true });
    }
    
  
  
  
  }
  
  // BCRadioButtonNo(event) {
  //   if (!this.intermedimage.controls['cmd_173'].value && event.target.value == 0) {
  //     this.intermedimage.controls['cmd_0174'].setErrors({ errors: true });
  //     this.intermedimage.controls[554].setErrors(null);
  //     this.intermedimage.controls["cmd_175"].setErrors(null);
  //     this.intermedimage.controls[556].setErrors(null);
  //     this.intermedimage.controls[557].setErrors(null);
  //     this.intermedimage.controls[558].setErrors(null);
  //     this.intermedimage.controls[560].setErrors(null);
  //   }
  // }
  BCRadioButtonNo(event) {

    this.bcRadioButtonNo = true;
    this.flagForHandle=true;
    this.cdr.detectChanges(); 
    if(this.intermedimage.controls["cmd_173"].value ==true){
     
      this.intermedimage.controls["cmd_0174"].setErrors(null);
      return 
  
    }
    
    if (event.target.value == 0) {
      
      this.intermedimage.controls[554].setErrors(null);
        this.intermedimage.controls["cmd_175"].setErrors(null);
        this.intermedimage.controls[556].setErrors(null);
        this.intermedimage.controls[557].setErrors(null);
        this.intermedimage.controls[558].setErrors(null);
        this.intermedimage.controls[560].setErrors(null);
        if (this.intermedimage.controls["cmd_0174"].value == "") {
          this.intermedimage.controls["cmd_0174"].setErrors({ errors: true });
        }
        // this.intermedimage.controls["cmd_0174"].setErrors({ errors: true });
    } else {
      this.bcRadioButtonNo = false;
    }
  }

  handleNAchange(cName, dName?) {
    let result: boolean = false;
    if (cName == 'cmd_1600') {
      let deanum = [309, 310, 311]
      deanum.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_160') {
      let undergrad = [503, 512, 511, 504, 505, 506, 508, 509]
      undergrad.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_162') {
      let mediprof = [490, 497, 499, 500, 491, 492, 2352, 2351, 493, 495, 496]
      mediprof.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_0160') {
      let grad = [479, 480, 481, 482, 483, 484, 485, 487, 488, 489, 2348, 2349, 4074]
      grad.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_0162') {
      let intern = [517, 1318, 1319, 518, 519, 520, 522, 528, 523, 524, 526, 527, 2539, "cmd_0165"]
      intern.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_163') {
      let residency = [529, 1316, 1317, 540, 531, 1498, 533, 535, 536, 2540, 530, 537, 538, "cmd_164"]
      residency.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_0163') {
      let residencyAdd = [1170, 1320, 1321, 1181, 1172, 1173, 1174, 1176, 1177, 2541, 1171, 1178, 1180, "cmd_165"]
      residencyAdd.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_166') {
      let fellowShip = [542, 1322, 1323, 553, 544, 545, 546, 548, 549, 2542, 550, 552, "cmd_167"]
      fellowShip.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_00163') {
      let fellowAdd = [966, 1324, 1325, 983, 968, 969, 970, 972, 1301, 2543, 974, 982, "cmd_168"]
      fellowAdd.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_169') {
      let preceptor = [2857, 2859, 2871, 2860, 2861, 2863, 2864, 2872, 2869, 2866, 2868, 2873]
      preceptor.forEach(element => {
        this.intermedimage.controls[element].setErrors(null);
      });
    }
    if (cName == 'cmd_170') {
      let faculty = [1146, 1148, 1149, 1150, 1152, 4142, 1153, "cmd_171", 1155, 1382, 1154, 1156]
      faculty.forEach(element => {
        this.intermedimage.controls[element].setErrors(null);
      });
    }
    if (cName == 'cmd_173') {
      // if (!this.intermedimage.controls[cName].value) {
      //   let x = [554, 'cmd_175', 556, 557, 558, 560]
      //   x.forEach(element => {
      //     if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null)) {
      //       this.setErrorControl(element);
      //       result = true
      //     }
      //     else {
      //       this.removeErrorControl(element);
      //     }
      //   });
      // }
      // else {
      //   this.intermedimage.get('cmd_174').reset();
      //   let x = [554, 'cmd_175', 556, 557, 558, 560, "cmd_0174"]
      //   x.forEach(element => {
      //     this.removeErrorControl(element);
      //   });
      // }
      if(this.flagForHandle==true){
        let x=['cmd_0174'];
        x.forEach(element => {
          const ctrl = this.intermedimage.controls[element];
            if ((ctrl.value === '' || ctrl.value === null) && !this.intermedimage.controls[cName].value) {
              this.setErrorControl(element);
              result = true;
            } else {
              this.removeErrorControl(element);
              ctrl.setErrors(null);
            }
        });
      }
      else{
        let x=[554,'cmd_175',556,557,558,560];
        x.forEach(element => {
          const ctrl = this.intermedimage.controls[element];
            if ((ctrl.value === '' || ctrl.value === null) && !this.intermedimage.controls[cName].value) {
              this.setErrorControl(element);
              result = true;
            } else {
              this.removeErrorControl(element);
              ctrl.setErrors(null);
            }
        });
      }
    }
    if (cName == 'cmd_0173') {
      let otherCertificate = [674, 3270, 678, 'add_1_674', 'add_1_3270', 'add_1_678', 'add_2_3270', 'add_2_678', 'add_2_674', 'add_3_674', 'add_3_3270', 'add_3_678']
      otherCertificate.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_187') {
      let hmoia = [418, 425, 419, 420, 1304, 421, 423, 424, 1305, 2136, 894, 426, "cmd_000339"]
      hmoia.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_059') {
      let faculty = [1183, 1190, 1312, 1184, 1185, 1186, 1188, 1189, 1313, 2140, 1192, 1303, 1191, 2381]
      faculty.forEach(element => {
        this.intermedimage.controls[element].setErrors(null);
      });
    }
    if (cName == 'cmd_59') {
      let inpatientCoverage = ['cmd_60', 'cmd_61', 'cmd_62', 'cmd_63', 'cmd_061', 'cmd_063']
      inpatientCoverage.forEach(element => {
        this.intermedimage.controls[element].setErrors(null);
      });
    }
    if (cName == 'cmd_00187') {
      let workHis = [445, 452, 453, 888, 446, 447, 448, 450, 451, 454, 455]
      workHis.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_0059') {
      let gapsBetween = [1138, 3395, 3396, 1831, 3397, 3398, 1832, 3399, 3400]
      gapsBetween.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_0187') {
      let peerRef = [359, 2722, 368, 360, 371, 361, 362, 364, 365, 369, 370, 'cmd_252']
      peerRef.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == 'cmd_000187') {
      let proflia = [345, 346, 901, 902, 903, 905, 906, 1266, 1273, 945, 350, 351, 348, 349]
      proflia.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }

    if (cName == 'cmd_099') {
      let profconfidential = ['cmd_356', 1217, 1208, 1209, 1214, 1218, 1210, 2012, 1844, 1845, 1847, 1848, 1213, 1858, 1226, 1228]
      profconfidential.forEach(element => {
        if ((this.intermedimage.controls[element].value == '' || this.intermedimage.controls[element].value == null) && !this.intermedimage.controls[cName].value) {
          this.setErrorControl(element);
          result = true
        }
        else {
          this.removeErrorControl(element);
        }
      });
    }
  }


   close() {
   this.onClose.emit('close modal');
   }


   saveForm(type)
   {
      this.isSubmit = true;
      this.handleNAchange('cmd_1600');
      this.handleNAchange('cmd_160');
      this.handleNAchange('cmd_162');
      this.handleNAchange('cmd_0160');
      this.handleNAchange('cmd_0162');
      this.handleNAchange('cmd_163');
      this.handleNAchange('cmd_0163');
      this.handleNAchange('cmd_166');
      this.handleNAchange('cmd_00163');
      this.handleNAchange('cmd_0173');
      this.handleNAchange('cmd_173');
      this.handleNAchange('cmd_187');
      this.handleNAchange('cmd_00187');
      this.handleNAchange('cmd_0059');
      this.handleNAchange('cmd_0187');
      this.handleNAchange('cmd_000187');
      this.handleNAchange('cmd_099');
      

      console.log(this.intermedimage);
      if (type == 'submit')
      {
         if (this.intermedimage.invalid )
         {
            this.notiService.showError(
            'Please Fill all the mandatory fields to continue','',3000
            );
               for (const key in this.intermedimage.controls) {
                  if (this.intermedimage.controls.hasOwnProperty(key))
                  {
                     const element = this.intermedimage.controls[key];
                     if(element.status=="INVALID")
                     {
                     console.log(key + " " + element.status)
                     }
                  }
               }
         } 
        else
          {
               let data = {
               formType: type,
               formValues: this.intermedimage.value
               };
                  console.log("Form has been submitted successfully");
                  this.onSubmit.emit(data);
                  console.log(this.intermedimage);

            }
      } 
      else 
      {
         let data = {
         formType: type,
         formValues: this.intermedimage.value,
         formId: 39
         };
         this.onSubmit.emit(data);
         this.onClose.emit('close modal');
      }
   }

  buildForm() {
    this.intermedimage = this.fb.group({
      "cmd_373": [''],
      //PRACTITIONER INFORMATION
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],
      29: [null, [Validators.required]],
      30: [null, [Validators.required]],
      9: ['', [Validators.required]],
      822: [null],
      31: [null, [Validators.required,this.dateFormatValidator]],
      32: ['', [Validators.required]],
      708: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
      1017: [''],
      278: [''],
      709: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      21: ['', [Validators.required]],
      810: ['', [Validators.required]],
      291: ['', [Validators.required]],
      292: ['', [Validators.required]],
      289: [null, [Validators.required]],
      284: [null,],
      962: [null,],
      277: [null,],
      1535: [null],
      1634: ['', [Validators.required]],
      812: [''],
      819: [''],
      325: [''],
      3826: [''],
      "cmd_01": [''],
      "cmd_02": [''],
      "cmd_03": [''],

      //PRACTICE INFORMATION 
      283: [null, [this.dateFormatValidator]],
      "cmd_15": [''],
      "cmd_16": [''],
      "cmd_19": [''],
      "cmd_019": [''],
      "cmd_20": [''],
      "cmd_21": [null],
      "cmd_22": [''],
      "cmd_17": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_18": [''],
      "cmd_35": [''],
      "cmd_36": [''],
      "cmd_24": [''],
      "cmd_374": [''],
      "cmd_375": [''],
      "cmd_376": [null],
      "cmd_377": [''],
      "cmd_25": [''],
      "cmd_378": [''],
      "cmd_379": [''],
      "cmd_380": [null],
      "cmd_381": [''],
      "cmd_27": [''],
      "cmd_382": [''],
      "cmd_383": [''],
      "cmd_28": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_30": [''],
      "cmd_29": ['', [Validators.pattern(this.emailPattern)]],
      "cmd_31": [''],
      "cmd_384": [''],
      "cmd_385": [''],
      "cmd_32": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_34": [''],
      "cmd_33": ['', [Validators.pattern(this.emailPattern)]],
      "cmd_77": [null, [this.dateFormatValidator]],
      "cmd_92": [''],
      "cmd_93": [''],
      "cmd_96": [''],
      "cmd_096": [''],
      "cmd_97": [''],
      "cmd_98": [null],
      "cmd_99": [''],
      "cmd_94": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_95": [''],
      "cmd_112": [''],
      "cmd_113": [''],
      "cmd_101": [''],
      "cmd_0101": [''],
      "cmd_00101": [''],
      "cmd_000101": [null],
      "cmd_0000101": [''],
      "cmd_102": [''],
      "cmd_0102": [''],
      "cmd_00102": [''],
      "cmd_000102": [null],
      "cmd_0000102": [''],
      "cmd_104": [''],
      "cmd_0104": [''],
      // "cmd_00104": [''],
      "cmd_105": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_107": [''],
      "cmd_106": ['', [Validators.pattern(this.emailPattern)]],
      "cmd_108": [''],
      "cmd_0108": [''],
      // "cmd_00108": [''],
      "cmd_109": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_111": [''],
      "cmd_110": ['', [Validators.pattern(this.emailPattern)]],

      //PROFESSIONAL LICENSURE
      293: [null, [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      "cmd_003": [''],
      "cmd_04": [''],
      "cmd_05": [''],
      1745: [''],
      1746: [''],
      1748: [null],
      1749: [null],
      309: ['', ],
      310: [null,[this.dateFormatValidator]],
      311: [null,[this.dateFormatValidator]],
      337: [''],
      338: [null, [this.dateFormatValidator]],
      339: [null, [this.dateFormatValidator]],
      "cmd_1600": [''],
      514: [''],
      515: [null, [this.dateFormatValidator]],

      //ALL OTHER PROFESSIONAL LICENSES
      907: [null],
      300: [''],
      307: [null, [this.dateFormatValidator]],
      308: [null, [this.dateFormatValidator]],
      "cmd_154": [''],
      "cmd_155": [''],
      1010: [null],
      999: [''],
      1003: [null, [this.dateFormatValidator]],
      1004: [null, [this.dateFormatValidator]],
      "cmd_156": [''],
      "cmd_157": [''],
      "add_1_1010": [null],
      "add_1_999": [''],
      "add_1_1003": [null, [this.dateFormatValidator]],
      "add_1_1004": [null, [this.dateFormatValidator]],
      "cmd_0155": [''],
      "cmd_0156": [''],
      //UNDER-GRADUATE EDUCATION
      "cmd_160": [''],
      503: ['', ],
      504: ['', ],
      505: ['', ],
      506: ['', ],
      508: [null, ],
      509: ['',],
      512: [null,],
      511: [null,[this.dateFormatValidator]],
      1288: [''],
      1298: [null],
      1297: [null, [this.dateFormatValidator]],
      1289: [''],
      1290: [''],
      1291: [''],
      1293: [null],
      1294: [''],
      //MEDICAL/PROFESSIONAL EDUCATION
      "cmd_162":[''],
      490:['', ],
      497:[null,[this.dateFormatValidator]],
      499:[null,[this.dateFormatValidator] ],
      500:[null,],
      491:['',],
      492:['',],
      2352:['',],
      493:['',],
      495:[null,],
      496:['',],
      2351:['',[Validators.pattern(this.phonePattern)]],
      "add_1_1288":[''],
      "add_1_1295":[null, [this.dateFormatValidator]],
      "add_1_1297":[null, [this.dateFormatValidator]],
      "add_1_1298":[null],
      "add_1_1289":[''],
      "add_1_1290":[''],
      "add_1_1291":[''],
      "add_1_1293":[null],
      "add_1_1294":[''],
      2354:[''],
      2355:[''],
      //GRADUATE EDUCATION
      "cmd_0160":[''],
      479:['',],
      480:[null,],
      482:[null, [this.dateFormatValidator]],
      481:[null, [this.dateFormatValidator]],
      483:['',],
      484:['',],
      485:['',],
      489:['',],
      2348:['',[Validators.pattern(this.phonePattern)]],
      2349:['',],
      4074:[''],
      487:[null,],
      488:['',],
      // INTERNSHIP/PGYI
      "cmd_0162":[''],
      517:['',],
      1318:['',[Validators.pattern(this.phonePattern)]],
      1319:[''],
      528:['',],
      518:['',],
      519:['',],
      520:['',],
      522:[null,],
      523:['',],
      2539:['',],
      524:[null,[this.dateFormatValidator]],
      526:[null,[this.dateFormatValidator]],
      527:[null],
      "cmd_0165":[''],
      //RESIDENCIES
      cmd_163:[''],
      cmd_0163:[''],
      529:['',],
      1316:['',[Validators.pattern(this.phonePattern)]],
      1317:[''],
      540:[''],
      531:['',],
      1498:['',],
      533:['',],
      535:[null,],
      536:['',],
      2540:['',],
      530:[null,],
      537:[null,[this.dateFormatValidator]],
      538:[null,[this.dateFormatValidator]],
      1170:['',],
      1320:['',[Validators.pattern(this.phonePattern)]],
      1321:['',],
      1181:['',],
      1172:['',],
      1173:['',],
      1174:['',],
      1176:[null,],
      1177:['',],
      2541:['',],
      1171:[null,],
      1178:[null,[this.dateFormatValidator]],
      1180:[null,[this.dateFormatValidator]],
      "cmd_164":['', ],
      "cmd_165":[''],
      //FELLOWSHIPS
      cmd_166:[''],
      542:['',],
      1322:['',[Validators.pattern(this.phonePattern)]],
      1323:['',],
      553:['',],
      544:['',],
      545:['',],
      546:[''],
      548:[null,],
      549:['',],
      2542:['',],
      550:[null,[this.dateFormatValidator]],
      552:[null,[this.dateFormatValidator]],
      966:['',],
      1324:['',[Validators.pattern(this.phonePattern)]],
      1325:['',],
      983:['',],
      968:['',],
      969:['',],
      970:['',],
      972:[null,],
      1301:['',],
      2543:['',],
      974:[null,[this.dateFormatValidator]],
      982:[null,[this.dateFormatValidator]],
      "cmd_167":['',],
      "cmd_168":['',],
      cmd_00163:[''],
      //PRECEPTORSHIP
      cmd_169:[''],
      2857:[''],
      2859:[''],
      2860:[''],
      2871:['',[Validators.pattern(this.phonePattern)]],
      2861:[''],
      2863:[null],
      2864:[''],
      2872:[''],
      2869:[''],
      2866:[null, [this.dateFormatValidator]],
      2868:[null, [this.dateFormatValidator]],
      2873:[''],
      // FACULTY APPOINTMENT
      cmd_170:[''],
      1146:[''],
      1148:[''],
      1149:[''],
      4142:['',[Validators.pattern(this.phonePattern)]],
      1150:[''],
      1152:[null],
      1153:[''],
      cmd_171:[''],
      1382:[''],
      1155:[null, [this.dateFormatValidator]],
      1156:[null, [this.dateFormatValidator]],
      1154:[''],
      // BOARD CERTIFICATION 
      'cmd_173':[''],
      'cmd_174':[''],
      "cmd_0174":[''],
      554:[null],
      'cmd_175':[null],
      556:[null],
      557:[null,[this.dateFormatValidator]],
      558:[null,[this.dateFormatValidator]],
      560:[null,[this.dateFormatValidator]],
      "cmd_176":[null],
      561:[null],
      563:[null],
      564:[null, [this.dateFormatValidator]],
      565:[null, [this.dateFormatValidator]],
      567:[null, [this.dateFormatValidator]],
      cmd_177:[null],
      946:[null],
      948:[null],
      953:[null, [this.dateFormatValidator]],
      950:[null, [this.dateFormatValidator]],
      954:[null, [this.dateFormatValidator]],
      "cmd_179":[''],
      "cmd_000355":[''],
      cmd_180:[null, [this.dateFormatValidator]],
      cmd_182:[''],
       //OTHER CERTIFICATIONS
      cmd_0173:[''],
      674:[null,],
      3270:['',],
      678:[null,[this.dateFormatValidator]],
      "add_1_674":[null,],
      "add_1_3270":['',],
      "add_1_678":[null,[this.dateFormatValidator]],
      "add_2_674":[null,],
      "add_2_3270":['',],
      "add_2_678":[null,[this.dateFormatValidator]],
      "add_3_674":[null,],
      "add_3_3270":['',],
      "add_3_678":[null,[this.dateFormatValidator]],
       //HOSPITAL AND OTHER INSTITUTIONAL AFFILIATIONS 
      cmd_187:[''],
      418:['', ],
      425:['', ],
      419:['', ],
      1304:['',],
      420:['', ],
      421:['', ],
      423:[null, ],
      424:['',],
      1305:['', [Validators.pattern(this.phonePattern)]],
      2136:['',],
      894:[null, ],
      426:[null,[this.dateFormatValidator]],
      "cmd_188":[''],
      "cmd_189":[''],
      "cmd_190":[''],
      "cmd_191":[''],
      "cmd_0339":['',],
      427:[''],
      428:[''],
      429:[''],
      430:[''],
      1306:[''],
      432:[null],
      433:[''],
      1307:['',[Validators.pattern(this.phonePattern)]],
      2137:[''],
      895:[null],
      435:[null, [this.dateFormatValidator]],
      "cmd_192":[''],
      "cmd_193":[''],
      "cmd_194":[''],
      "cmd_195":[''],
      "cmd_00339":['',],
      "cmd_000339":['',[Validators.required]],

      436:[''],
      434:[''],
      437:[''],
      438:[''],
      1308:[''],
      439:[''],
      441:[null],
      442:[''],
      443:[''],
      1309:['',[Validators.pattern(this.phonePattern)]],
      2138:[''],
      896:[null],
      444:[null, [this.dateFormatValidator]],
      "cmd_196":[''],
      "cmd_197":[''],
      "cmd_198":[''],
      "cmd_199":[''],
      "cmd_234":[''],
      "cmd_235":['',[Validators.pattern(this.phonePattern)]],
      "cmd_0235":['',],
      "cmd_236":[null, [this.dateFormatValidator]],
      "cmd_237":[''],
      "cmd_238":[''],
      "cmd_239":[''],
      "cmd_240":[null],
      "cmd_241":[''],
      "cmd_242":[''],
      "cmd_243":['',[Validators.pattern(this.phonePattern)]],
      "cmd_0243":[''],
      "cmd_244":[null, [this.dateFormatValidator]],
      "cmd_245":[''],
      "cmd_246":[''],
      "cmd_247":[''],
      "cmd_248":[null],
      "cmd_249":[''],
      

    //  // PREVIOUS HOSPITAL AFFILIATIONS
      cmd_059:[''],
      1183:[''],
      1190:[''],
      1312:[''],
      1184:[''],
      1185:[''],
      1186:[''],
      1313:[''],
      2140:[''],
      1188:[null],
      1189:[''],
      1192:[null],
      1191:[null, [this.dateFormatValidator]],
      2381:[null, [this.dateFormatValidator]],
      1303:[''],
      "add_1_1183":[''],
      "add_1_1190":[''],
      "add_1_1312":[''],
      "add_1_1184":[''],
      "add_1_1185":[''],
      "add_1_1186":[''],
      "add_1_1188":[null],
      "add_1_1189":[''],
      "add_1_1313":[''],
      "add_1_2140":[''],
      "add_1_1192":[null],
      "add_1_1303":[''],
      "add_1_1191":[null, [this.dateFormatValidator]],
      "add_1_2381":[null, [this.dateFormatValidator]],
      "add_2_1183":[''],
      "add_2_1190":[''],
      "add_2_1184":[''],
      "add_2_1185":[''],
      "add_2_1186":[''],
      "add_2_1312":[''],
      "add_2_2140":[''],
      "add_2_1313":[''],
      "add_2_1188":[null],
      "add_2_1189":[''],
      "add_2_1192":[null],
      "add_2_1191":[null, [this.dateFormatValidator]],
      "add_2_2381":[null, [this.dateFormatValidator]],
      "add_2_1303":[''],
      //INPATIENT COVERAGE PLAN
      cmd_59:[''],
      "cmd_60":[''],
      "cmd_61":[''],
      "cmd_62":[''],
      "cmd_63":[''],
      "cmd_061":[''],
      "cmd_063":[''],
      //WORK HISTORY
      cmd_00187:[''],
      445:['', ],
      452:['', ],
      453:['', [Validators.pattern(this.phonePattern)]],
      888:['', ],
      446:['', ],
      447:['', ],
      448:['',],
      450:[null,],
      451:['', ],
      454:[null,[this.dateFormatValidator] ],
      455:[null, [this.dateFormatValidator]],
      456:['', ],
      463:['',],
      464:['', ],
      1338:['', ],
      891:['', ],
      890:['', ],
      457:['', ],
      458:['', ],
      459:['',],
      461:[null, ],
      462:['',],
      465:[null, [this.dateFormatValidator]],
      466:[null, [this.dateFormatValidator]],
      467:[''],
      474:[''],
      475:['',[Validators.pattern(this.phonePattern)]],
      1342:[''],
      893:['',[Validators.pattern(this.emailPattern)]],
      892:[''],
      468:[''],
      469:[''],
      470:[''],
      472:[null],
      473:[''],
      476:[null, [this.dateFormatValidator]],
      477:[null, [this.dateFormatValidator]],
      cmd_0059:[''],

      //GAPS IN HISTORY
      1138:['',],
      3395:[null,[this.dateFormatValidator]],
      3396:[null,[this.dateFormatValidator]],
      1831:['',],
      3397:[null,[this.dateFormatValidator]],
      3398:[null,[this.dateFormatValidator]],
      1832:[''],
      3399:[null,[this.dateFormatValidator]],
      3400:[null,[this.dateFormatValidator]],
      //PROFESSIONAL AFFILIATIONS
      1695:[''],
      1696:[null, [this.dateFormatValidator]],
      "cmd_253":[''],
      1701:[''],
      1702:[null, [this.dateFormatValidator]],
      "cmd_255":[''],
      1707:[''],
      1708:[null, [this.dateFormatValidator]],
      "cmd_256":[''],
      cmd_1701:[''],
      cmd_1702:[null, [this.dateFormatValidator]],
      "cmd_257":[''],
      cmd_1695:[''],
      cmd_1696:[null, [this.dateFormatValidator]],
      "cmd_258":[''],
      cmd_01701:[''],
      cmd_01702:[null, [this.dateFormatValidator]],
      "cmd_259":[''],
      // PEER REFERENCES
      cmd_0187:[''],
      359:['', ],
      2722:['', ],
      368:[null, ],
      371:['', [Validators.pattern(this.emailPattern)]],
      360:['', ],
      361:['', ],
      362:['', ],
      364:[null, ],
      365:['', ],
      369:['', [Validators.pattern(this.phonePattern)]],
      370:['',],
      "cmd_252":['',[Validators.pattern(this.phonePattern)]],
      372:['', ],
      2723:[null, ],
      380:[null,],
      383:['', Validators.pattern(this.emailPattern)],
      373:['', ],
      374:['', ],
      375:['', ],
      377:[null, ],
      378:['', ],
      381:['', [Validators.pattern(this.phonePattern)]],
      382:[''],
      "cmd_250":['',[Validators.pattern(this.phonePattern)]],
      393:[null, ],
      384:['', ],
      2725:[null, ],
      396:['', Validators.pattern(this.emailPattern)],
      385:['',],
      387:['', ],
      386:['', ],
      389:[null, ],
      390:['', ],
      394:['', [Validators.pattern(this.phonePattern)]],
      395:[''],
      "cmd_251":['',[Validators.pattern(this.phonePattern)]],
      //PROFESSIONAL LIABILITY
      cmd_000187:[''],
      345:['',],
      346:['',],
      901:['',],
      902:['',],
      903:['',],
      905:[null,],
      906:['',],
      1266:['',[Validators.pattern(this.phonePattern)]],
      1273:['',],
      945:[null,[this.dateFormatValidator]],
      350:[null,],
      351:[null],
      348:[null,[this.dateFormatValidator]],
      349:[null,[this.dateFormatValidator]],
      cmd_00059:[''],

      //PREVIOUS PROFESSIONAL LIABILITY
      1242:['', ],
      1256:['', ],
      1244:['',],
      1245:['',],
      1246:['', ],
      1248:[null, ],
      1249:['', ],
      1254:['', ],
      1255:['',],
      1264:[null, ],
      1265:[null, ],
      1252:[null, [this.dateFormatValidator] ],
      1253:[null, [this.dateFormatValidator]],
      "add_1_1242":[''],
      "add_1_1256":[''],
      "add_1_1244":[''],
      "add_1_1245":[''],
      "add_1_1246":[''],
      "add_1_1248":[null],
      "add_1_1249":[''],
      "add_1_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_1_1255":[''],
      "add_1_1264":[null],
      "add_1_1265":[null],
      "add_1_1252":[null, [this.dateFormatValidator]],
      "add_1_1253":[null, [this.dateFormatValidator]],
      "add_2_1242":[''],
      "add_2_1256":[''],
      "add_2_1244":[''],
      "add_2_1245":[''],
      "add_2_1246":[''],
      "add_2_1248":[null],
      "add_2_1249":[''],
      "add_2_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_2_1255":[''],
      "add_2_1264":[null],
      "add_2_1265":[null],
      "add_2_1252":[null, [this.dateFormatValidator]],
      "add_2_1253":[null, [this.dateFormatValidator]],
      "add_3_1242":[''],
      "add_3_1256":[''],
      "add_3_1244":[''],
      "add_3_1245":[''],
      "add_3_1246":[''],
      "add_3_1248":[null],
      "add_3_1249":[''],
      "add_3_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_3_1255":[''],
      "add_3_1264":[null],
      "add_3_1265":[null],
      "add_3_1252":[null, [this.dateFormatValidator]],
      "add_3_1253":[null, [this.dateFormatValidator]],
      "add_4_1242":[''],
      "add_4_1256":[''],
      "add_4_1244":[''],
      "add_4_1245":[''],
      "add_4_1246":[''],
      "add_4_1248":[null],
      "add_4_1249":[''],
      "add_4_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_4_1255":[''],
      "add_4_1264":[null],
      "add_4_1265":[null],
      "add_4_1252":[null],
      "add_4_1253":[null],
      "add_5_1242":[''],
      "add_5_1256":[''],
      "add_5_1244":[''],
      "add_5_1245":[''],
      "add_5_1246":[''],
      "add_5_1248":[null],
      "add_5_1249":[''],
      "add_5_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_5_1255":[''],
      "add_5_1264":[null],
      "add_5_1265":[null],
      "add_5_1252":[null],
      "add_5_1253":[null],
      "add_6_1242":[''],
      "add_6_1256":[''],
      "add_6_1244":[''],
      "add_6_1245":[''],
      "add_6_1246":[''],
      "add_6_1248":[null],
      "add_6_1249":[''],
      "add_6_1254":['',[Validators.pattern(this.phonePattern)]],
      "add_6_1255":[''],
      "add_6_1264":[null],
      "add_6_1265":[null],
      "add_6_1252":[null],
      "add_6_1253":[null],
      //PROFESSIONAL LIABILITY ACTION DETAIL-CONFIDENTIAL
      cmd_099:[''],
      "cmd_356":['', ],
      1208:[null,[this.dateFormatValidator]],
      1209:[null,[this.dateFormatValidator] ],
      1217:['',],
      1218:['', ],
      1214:['', ],
      1210:['', ],
      1844:['', ],
      2012:['', ],
      1845:['', ],
      1847:[null, ],
      1848:['', ],
      1213:['', ],
      1858:['', ],
      1226:[null,[this.dateFormatValidator] ],
      1228:['',],
      //PROFESSIONAL SANCTIONS
      "cmd_329":['',[Validators.required]],
      "cmd_330":['',[Validators.required]],
      "cmd_331":['',[Validators.required]],
      "cmd_332":['',[Validators.required]],
      "cmd_333":['',[Validators.required]],
      "cmd_334":['',[Validators.required]],
      "cmd_335":['',[Validators.required]],
      "cmd_336":['',[Validators.required]],
      "cmd_337":['',[Validators.required]],
      "cmd_338":['',[Validators.required]],
      "cmd_339":['',[Validators.required]],
      "cmd_340":['',[Validators.required]],
      "cmd_341":['',[Validators.required]],
      "cmd_342":['',[Validators.required]],
      "cmd_343":['',[Validators.required]],
      "cmd_344":['',[Validators.required]],
      "cmd_345":['',[Validators.required]],
      "cmd_346":['',[Validators.required]],
      "cmd_347":['',[Validators.required]],
      "cmd_348":['',[Validators.required]],
      "cmd_349":['',[Validators.required]],
      "cmd_350":['',[Validators.required]],
      "cmd_351":['',[Validators.required]],
      "cmd_352":['',[Validators.required]],
      "cmd_353":['',[Validators.required]],
      "cmd_354":[null,[Validators.required,this.dateFormatValidator]],
      "cmd_33553":['',Validators.required],
      "cmd_43554":['',Validators.required],
      "cmd_355":['',Validators.required],
      "cmd_10354":[null,[Validators.required,this.dateFormatValidator]],
      "cmd_0354":[''],


    });
    console.log(this.intermedimage)
  }

  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.intermedimage.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
     
      this.handleNAchange(handleYesNoValue);
    }
  
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.intermedimage.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

}

