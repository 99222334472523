import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule } from '@angular/forms';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgStyle, NgClass } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'ctg-custom-date-modal',
    templateUrl: './custom-date-modal.component.html',
    styleUrls: ['./custom-date-modal.component.scss'],
    standalone: true,
    imports: [FormsModule, NgbPopoverModule, NgSelectModule, NgFor, NgStyle, NgClass]
})
export class CustomDateModalComponent implements OnInit {

  @ViewChild('pop') pop: NgbPopover;
  @Input('initialVal') initialVal;
  @Input('isEdit') isEdit;

  @Output() returnValue: EventEmitter<any> = new EventEmitter();
 // @Input('controler') controler;

 constructor(private fb: UntypedFormBuilder) { }
  public form: UntypedFormGroup;
  public selectedDate: string = '';
  public days: Array<any> = [];
  public selectedMonth: string = "01";
  public months: Array<any> = [
    {
      name: 'Jan',
      value: '01'
    },
    {
      name: 'Feb',
      value: '02'
    },
    {
      name: 'Mar',
      value: '03'
    },
    {
      name: 'Apr',
      value: '04'
    },
    {
      name: 'May',
      value: '05'
    },
    {
      name: 'Jun',
      value: '06'
    },
    {
      name: 'Jul',
      value: '07'
    },
    {
      name: 'Aug',
      value: '08'
    },
    {
      name: 'Sept',
      value: '09'
    },
    {
      name: 'Oct',
      value: '10'
    },
    {
      name: 'Nov',
      value: '11'
    },
    {
      name: 'Dec',
      value: '12'
    },

  ]
  public selectedYear = '2021'
  public years: Array<any> = [];
  public limit = 31
  public offset: Array<any> = []
  public date = new Date();
  public today = ''
  ngOnInit(): void {
    this.loadYears()
    if (this.initialVal) {
      this.selectedMonth = this.returnMonth(this.initialVal)
      this.selectedYear = this.returnYear(this.initialVal)
      this.selectedDate = this.initialVal
    } else {
      this.selectedMonth = this.returnMonth(this.getTodayDate());
      this.selectedYear = this.returnYear(this.getTodayDate());
    }
      // this.handleFormat(this.initialVal)
    this.loadData();
    this.today = this.getTodayDate();
    // console.log(this.controler);
  }
    ngOnChanges() {
     // console.log(this.isEdit);
    if(this.isEdit){
      if (this.initialVal) {
        this.selectedMonth = this.returnMonth(this.initialVal)
        this.selectedYear = this.returnYear(this.initialVal)
        this.selectedDate = this.initialVal
      } else {
        this.selectedDate = '';
      }
      this.loadData();
    }
  }
  getTodayDate() {
    let day = this.date.getDate();
    let month = this.date.getMonth() + 1;
    let year = this.date.getFullYear();
    return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year
  }

  onBlur() {
    this.selectedDate = this.handleFormat(this.selectedDate)
    this.returnValue.emit(this.selectedDate)
  }
  handleFormat(value: string) {
    if (!value || !/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/.test(value)) {
      return '';
    }
    return value;
  }

  loadData(): void {
    this.limit = new Date(Number(this.selectedYear), Number(this.selectedMonth), 0).getDate();
    this.loadDays();
    this.calculateOffsets();
}

  loadYears() {
    for (let index = 1900; index < 2100; index++) {
      let t = {
        name: index.toString(),
        id: index.toString()
      }
      this.years.push(t)
    }
  }

  loadDays() {
    this.days = []
    for (let i = 1; i <= this.limit; i++) {
      let x = {
        name: `${i}`,
        value: `${this.selectedMonth}/${i < 10 ? '0' + i : i}/${this.selectedYear}`
      }
      this.days.push(x)
    }
  }

  calculateOffsets(): void {
    const firstDay = new Date(Number(this.selectedYear), Number(this.selectedMonth) - 1, 1).getDay();
    this.offset = Array.from({ length: firstDay === 0 ? 6 : firstDay - 1 });
  }
handleDaysSelection(val) {
    this.selectedDate = val.value;
    this.returnValue.emit(this.selectedDate)
  }
  //   handlePopover(popover) {
  //   if (popover.isOpen()) {
  //     popover.close();
  //     this.returnValue.emit(this.selectedDate)
  //   } else {
  //     popover.open();
  //     this.loadData()
  //   }
  // }
  // handlePopover(popover) {
  //   if (popover.isOpen()) {
  //     popover.close();
  //     this.returnValue.emit(this.selectedDate);
  //   } else {
  //     if (!this.selectedDate) {
       
  //       this.today = this.getTodayDate();
  //     }
  //     popover.open();
  //     this.loadData();
  //   }
  // }
  handlePopover(popover) {
    if (popover.isOpen()) {
      popover.close();
      this.returnValue.emit(this.selectedDate)
    } else {
      popover.open();
      this.selectedMonth = this.returnMonth(this.initialVal)
      this.selectedYear = this.returnYear(this.initialVal)
      this.loadData()
    }
  }

  

  returnMonth(date: string) {
    return date ? date.substring(0, 2) : String(this.date.getMonth() + 1).padStart(2, '0');
  }

  returnYear(date: string): string {
    return date ? date.substring(6, 10) : String(this.date.getFullYear());
  }

  handleDateChange(event){
    let value = event.target.value.replace(/[^\d/]/g, '');
    if (value.length === 2 || value.length === 5) {
      value += '/';
    }
    this.selectedDate = value;
    this.returnValue.emit(this.selectedDate);
  }

  handleYear(item) {
    this.loadData()
  }

  handleMonthSelect(action: string) {
    if (action === 'back') {
      if (this.selectedMonth === '01') {
        this.selectedMonth = '12';
        this.selectedYear = String(Number(this.selectedYear) - 1);
      } else {
        this.selectedMonth = String(Number(this.selectedMonth) - 1).padStart(2, '0');
      }
    } else if (action === 'move') {
      if (this.selectedMonth === '12') {
        this.selectedMonth = '01';
        this.selectedYear = String(Number(this.selectedYear) + 1)
      } 
      else {
        this.selectedMonth = String(Number(this.selectedMonth) + 1).padStart(2, '0')
      }
    }
    this.loadData()
  }
}
