<div id="flagship">
  <div class="header bg">
    <div class="row">
      <div class="col-md-6 ">
        <h3 class="hd1">
          APPLICATION FOR APPOINTMENT TO THE
        </h3>
        <h3 class="hd2">MEDICAL/PROFESSIONAL STAFF</h3>
        <h5 class="font-match hd3" >
          CAROLINAS CENTER FOR ORAL & FACIAL SURGERY
        </h5>
      </div>
      <div class="col-md-6">
        <img class="logoImg" src="../../../../assets/images/flagship.png" />
      </div>
    </div>
  </div>
  <table border="1" width="100%">
    <tr>
      <td colspan="12" >
        <h6>
          <b class="al "
            >Note: This application cannot be processed unless all requested
            information and the following required  attachments are provided.
           </b
          >
     </h6>
      </td>
    </tr>
  </table>

  <div class="section">
    <form [formGroup]="FlagShipForms">
      <table border="1" width="100%">
        <tr>
          <td colspan="12" class="bg ">
            <h6 class="  ms-3 mt-3 mb-3">I.PERSONAL INFORMATION</h6>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label  for="9">Last Name</label>

            <input
              type="text"
              class="form-control"
              formControlName="9"
              id="9"
              placeholder="Last name"
              [class.is-invalid]="
                (FlagShipForms.controls['9'].touched &&
                  FlagShipForms.controls['9'].invalid) ||
                (FlagShipForms.controls['9'].invalid && isSubmit)
              "
            />

            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['9'].touched &&
                FlagShipForms.controls['9'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['9'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="4">
            <label  for="8">First Name</label>

            <input
              type="text"
              class="form-control"
              formControlName="8"
              id="8"
              placeholder="First Name "
              [class.is-invalid]="
                (FlagShipForms.controls['8'].touched &&
                  FlagShipForms.controls['8'].invalid) ||
                (FlagShipForms.controls['8'].invalid && isSubmit)
              "
            />
            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['8'].touched &&
                FlagShipForms.controls['8'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['8'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label  for="809">M.I.</label>

            <input
              type="text"
              class="form-control"
              formControlName="809"
              id="809"
              placeholder="Middle Name"
            />
          </td>
          <td colspan="2">
            <label  for="822">Credentials</label>

            <ng-select
              [items]="credentials"
              id="822"
              bindLabel="name"
              placeholder="Credentials "
              [class.is-invalid]="
                (FlagShipForms.controls['822'].touched &&
                  FlagShipForms.controls['822'].invalid) ||
                (FlagShipForms.controls['822'].invalid && isSubmit)
              "
              bindValue="id"
              formControlName="822"
            >
            </ng-select>
            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['822'].touched &&
                FlagShipForms.controls['822'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['822'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <label for="31" for="31">Date of Birth </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="DOB"
                ngbDatepicker
                #d31="ngbDatepicker"
                id="31"
                class="form-control"
                formControlName="31"
                (blur)="clearInputIfInvalidMandi('31')"
                [class.is-invalid]="
                  (FlagShipForms.controls['31'].touched &&
                    FlagShipForms.controls['31'].invalid) ||
                  (FlagShipForms.controls['31'].invalid && isSubmit)
                "
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d31.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['31'].touched &&
                FlagShipForms.controls['31'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['31'].errors?.required"
              >
                required
              </small>
            </div> -->
            <div class="text-danger"
              *ngIf="(FlagShipForms.controls['31'].invalid&&FlagShipForms.controls['31'].touched) && (FlagShipForms.controls['31'].value==''|| FlagShipForms.controls['31'].value==null) ">
              <small>
                required
              </small>
            
            </div>
            <small class="text-danger" *ngIf="FlagShipForms.get('31').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('31').errors?.invalidMonth">
              Month is out of range
            </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('31').errors?.invalidDateyear">
              year is out of range(1900-2099) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('31').errors?.invalidDateRange">
              date is out of range
            </small>
          </td>
          <td colspan="4">
            <label for="1680">Marital Status</label>
            <input
              type="text"
              class="form-control"
              formControlName="1680"
              id="1680"
              placeholder="Marital Status"
              [class.is-invalid]="
                (FlagShipForms.controls['1680'].touched &&
                  FlagShipForms.controls['1680'].invalid) ||
                (FlagShipForms.controls['1680'].invalid && isSubmit)
              "
            />
            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['1680'].touched &&
                FlagShipForms.controls['1680'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['1680'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="6">
            <label for="33">Name of Spouse </label>
            <input
              type="text"
              class="form-control"
              formControlName="33"
              id="33"
              placeholder="Spouse "
            />
          </td>
        </tr>

        <tr>
          <td colspan="3">
            <label for="32">Social Security Number</label>
            <input
              type="text"
              class="form-control"
              id="32"
              formControlName="32"
              [class.is-invalid]="
                (FlagShipForms.controls['32'].touched &&
                  FlagShipForms.controls['32'].invalid) ||
                (FlagShipForms.controls['32'].invalid && isSubmit)
              "
              placeholder="SSN"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['32'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['32'].touched &&
                FlagShipForms.controls['32'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['32'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="4">
            <label for="1634">NPI Number</label>
            <input
              type="text"
              class="form-control"
              id="1634"
              formControlName="1634"
              placeholder="NPI"
              [class.is-invalid]="
                (FlagShipForms.controls['1634'].touched &&
                  FlagShipForms.controls['1634'].invalid) ||
                (FlagShipForms.controls['1634'].invalid && isSubmit)
              "
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['1634'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['1634'].touched &&
                FlagShipForms.controls['1634'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['1634'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="4">
            <label>Place of birth</label>
            <input
              class="form-control"
              id="810"
              name="810"
              formControlName="810"
              [class.is-invalid]="
                (FlagShipForms.controls['810'].touched &&
                  FlagShipForms.controls['810'].invalid) ||
                (FlagShipForms.controls['810'].invalid && isSubmit)
              "
              placeholder="City Of Birth"
              type="text"
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['810'].touched &&
                FlagShipForms.controls['810'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['810'].errors?.required"
              >
                required
              </small>
            </div><br>

            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="  Birth State or Country"
              bindValue="id"
              formControlName="289"
              [class.is-invalid]="
                (FlagShipForms.controls['289'].touched &&
                  FlagShipForms.controls['289'].invalid) ||
                (FlagShipForms.controls['289'].invalid && isSubmit)
              "
            >
            </ng-select>
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['289'].touched &&
                FlagShipForms.controls['289'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['289'].errors?.required"
              >
                required
              </small>
            </div><br>

            <input
              class="form-control"
              id="291"
              name="291"
              formControlName="291"
              [class.is-invalid]="
                (FlagShipForms.controls['291'].touched &&
                  FlagShipForms.controls['291'].invalid) ||
                (FlagShipForms.controls['291'].invalid && isSubmit)
              "
              placeholder="Country"
              type="text"
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['291'].touched &&
                FlagShipForms.controls['291'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['291'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="278">Maiden Name </label>
            <input
              type="text"
              class="form-control"
              id="278"
              formControlName="278"
              placeholder=" Prior Name"
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="16">Home Address</label>
            <div class="row">
              <div class="col-md-6"> <input
                type="text"
                class="form-control"
                id="16"
                formControlName="16"
                placeholder="Home Address 1 "
                [class.is-invalid]="
                  (FlagShipForms.controls['16'].touched &&
                    FlagShipForms.controls['16'].invalid) ||
                  (FlagShipForms.controls['16'].invalid && isSubmit)
                "
              />
              <div
                class="formLabel col-md-11"
                *ngIf="
                  FlagShipForms.controls['16'].touched &&
                  FlagShipForms.controls['16'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="FlagShipForms.controls['16'].errors?.required"
                >
                  required
                </small>
              </div></div>
              <div class="col-md-6">
                <input
                type="text"
                class="form-control"
                id="17"
                formControlName="17"
                placeholder="Home Address 2 "
              />
              </div>
            </div>
           
          
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="18">City</label>
            <input
              class="form-control"
              id="18"
              formControlName="18"
              placeholder="Home City "
              [class.is-invalid]="
                (FlagShipForms.controls['18'].touched &&
                  FlagShipForms.controls['18'].invalid) ||
                (FlagShipForms.controls['18'].invalid && isSubmit)
              "
            >
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['18'].touched &&
                FlagShipForms.controls['18'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['18'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="20">State</label>
            <ng-select
              [items]="states"
              id="20"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="20"
              [class.is-invalid]="
                (FlagShipForms.controls['20'].touched &&
                  FlagShipForms.controls['20'].invalid) ||
                (FlagShipForms.controls['20'].invalid && isSubmit)
              "
            >
            </ng-select>
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['20'].touched &&
                FlagShipForms.controls['20'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['20'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="21">Zip Code</label>
            <input
              type="text"
              class="form-control"
              id="21"
              formControlName="21"
              [class.is-invalid]="
                (FlagShipForms.controls['21'].touched &&
                  FlagShipForms.controls['21'].invalid) ||
                (FlagShipForms.controls['21'].invalid && isSubmit)
              "
              placeholder="Home Zip"
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['21'].touched &&
                FlagShipForms.controls['21'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['21'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <label for="759">Email</label>
            <input
              type="text"
              class="form-control"
              id="759"
              formControlName="759"
              [class.is-invalid]="
                (FlagShipForms.controls['759'].touched &&
                  FlagShipForms.controls['759'].invalid) ||
                (FlagShipForms.controls['759'].invalid && isSubmit)
              "
              placeholder="Email"
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['759'].touched &&
                FlagShipForms.controls['759'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['759'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="5">
            <label for="708">Phone</label>
            <input
              type="text"
              class="form-control"
              id="708"
              formControlName="708"
              [class.is-invalid]="
                (FlagShipForms.controls['708'].touched &&
                  FlagShipForms.controls['708'].invalid) ||
                (FlagShipForms.controls['708'].invalid && isSubmit)
              "
              placeholder="Home Phone"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['708'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['708'].touched &&
                FlagShipForms.controls['708'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['708'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <!-- <h6 class="  ms-3 mt-3 mb-3">II.CURRENT EMPLOYER</h6> -->
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">II.CURRENT EMPLOYER</h6>
              </div>
              <div class="col-md-2 ">
                <input class="mt-3"
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_01"
                  id="cmd_NA_01"
                  formControlName="cmd_NA_01"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_01')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="445">Primary Practice Name</label>

            <input
              type="text"
              class="form-control"
              id="445"
              formControlName="445"
              (blur)="handleNAchange('cmd_NA_01', 445)"
              [class.is-invalid]="FlagShipForms.controls['445'].invalid"
              placeholder="Work History 1 Location"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['445'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label for="453">Practice Phone</label>
            <input
              type="text"
              class="form-control"
              id="453"
              formControlName="453"
              (blur)="handleNAchange('cmd_NA_01', 453)"
              [class.is-invalid]="FlagShipForms.controls['453'].invalid"
              placeholder="Work History 1 Telephone"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['453'].errors?.pattern">
              Only 10 digits allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['453'].invalid">
            <small >
              required
            </small>
            </div>
           
          </td>
          <td colspan="3">
            <label for="454">Start Date</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Work History 1 Start Date"
                (blur)="handleNAchange('cmd_NA_01', 454)"
                (blur)="clearInputIfInvalid('454','cmd_NA_01')"
                ngbDatepicker
                #d454="ngbDatepicker"
                id="454"
                class="form-control"
                formControlName="454"
                [class.is-invalid]="FlagShipForms.controls['454'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d454.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="mandatory" *ngIf="FlagShipForms.controls['454'].invalid && (FlagShipForms.controls['454'].value==''|| FlagShipForms.controls['454'].value==null) " >
              <small >
              required
              </small>
            
            </div>
            <div *ngIf="FlagShipForms.get('454').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('454').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('454').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('454').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('454').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="446">Practice Street Address</label>
            <input
              type="text"
              class="form-control"
              id="446"
              formControlName="446"
              (blur)="handleNAchange('cmd_NA_01', 446)"
              [class.is-invalid]="FlagShipForms.controls['446'].invalid"
              placeholder="Work History 1 Address 1"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['446'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <label for="448">City</label>
            <input
              type="text"
              class="form-control"
              id="448"
              formControlName="448"
              (blur)="handleNAchange('cmd_NA_01', 448)"
              [class.is-invalid]="FlagShipForms.controls['448'].invalid"
              placeholder="Work History 1 City"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['448'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label for="450">State</label>
            <ng-select
              [items]="states"
              id="450"
              bindLabel="name"
              placeholder="State or Country"
              (blur)="handleNAchange('cmd_NA_01', 450)"
              bindValue="id"
              formControlName="450"
              [class.is-invalid]="FlagShipForms.controls['450'].invalid"
            >
            </ng-select>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['450'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label for="451">Zip Code</label>
            <input
              type="text"
              placeholder="Work History 1 Zip"
              (blur)="handleNAchange('cmd_NA_01', 451)"
              id="451"
              class="form-control"
              formControlName="451"
              [class.is-invalid]="FlagShipForms.controls['451'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['451'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="456">Secondary Practice Name</label>
            <input
              type="text"
              class="form-control"
              id="456"
              formControlName="456"
              placeholder="Work History 2 Location"
            />
          </td>
          <td colspan="3">
            <label for="464">Practice Phone</label>
            <input
              type="text"
              class="form-control"
              id="464"
              formControlName="464"
              placeholder="Work History 2 Telephone"
            />
            <div *ngIf="FlagShipForms.get('464').invalid && FlagShipForms.get('464').touched">
              <small class="text-danger" *ngIf="FlagShipForms.get('464').errors.invalidPhoneFormat">
                Only 10 digits  Allowed
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="465">Start Date</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Work History 2 Start Date"
                ngbDatepicker
                #d465="ngbDatepicker"
                id="465"
                class="form-control"
                formControlName="465"
                (blur)="clearInputIfInvalid('465')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d465.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
              
            </div>
            <div *ngIf="FlagShipForms.get('465').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('465').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('465').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('465').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('465').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="457">Practice Street Address</label>
            <input
              type="text"
              class="form-control"
              id="457"
              formControlName="457"
              placeholder="Work History 2 Address 1"
            />
          </td>
        </tr>
        <tr>
          <td colspan="7">
            <label for="459">City</label>
            <input
              type="text"
              class="form-control"
              id="459"
              formControlName="459"
              placeholder="Work History 2 City"
            />
          </td>
          <td colspan="4">
            <label for="461">State</label>
            <ng-select
              [items]="states"
              id="461"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="461"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label for="462">Zip Code</label>
            <input
              type="text"
              placeholder="Work History 2 Zip"
              id="462"
              class="form-control"
              formControlName="462"
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div>
              <label for=""
                  >Is your practice limited to a specialty or subspecialty? If
                  so, please indicate:</label
                >
              
            </div>
            <div class="row">
            <div class="col-md-6">
              <label for="284">Specialty:</label>
              <ng-select
                [items]="specialty"
                bindLabel="name"
                placeholder="Primary Specialty "
                id="284"
                bindValue="id"
                formControlName="284"
              >
              </ng-select>
         
            </div>
            <div class="col-md-6">
              <label for="">Subspecialty:</label>
             
                    <ng-select
                      [items]="specialtySecondary"
                      bindLabel="name"
                      id="cmd_016"
                      placeholder=""
                      id="cmd_016"
                      bindValue="id"
                     
                      formControlName="cmd_016"
                    >
                    </ng-select>
            </div>
            </div>
          
                  

            
                    
                
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for=""
              >Corporation Name & Partners or Associates</label
            >
            <input type="text"
             class="form-control"
             id="cmd_042"
             formControlName="cmd_042"
              />
          </td>
        </tr>
   
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">III.PRACTICE HISTORY</h6>
              </div>
              
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <h6>
              <b class="al"
                >Chronological listing of medical/dental practice since
                medical/dental training. If additional space is  
                needed, please upload copy of full history to Credential My Doc </b> </h6>
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <label for="467">Name of Practice</label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_01', 467)"
              class="form-control"
              id="467"
              [class.is-invalid]="FlagShipForms.controls['467'].invalid"
              formControlName="467"
              placeholder="Work History 3 Location"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['467'].invalid">
            <small >
              required
            </small>
        </div> 
          </td>
          <td colspan="6">
            <label for="">Address</label>

            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_01', 468)"
              class="form-control"
              id="468"
              [class.is-invalid]="FlagShipForms.controls['468'].invalid"
              formControlName="468"
              placeholder="Work History 3 Address 1"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['468'].invalid">
            <small >
              required
            </small>
        </div> <br>
            <input
        
            class="form-control"
            id="469"
            formControlName="469"
            placeholder="Work History 3 Address 2"
          /><br>

            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_01', 470)"
              class="form-control"
              id="470"
              [class.is-invalid]="FlagShipForms.controls['470'].invalid"
              
              formControlName="470"
              placeholder="Work History 3 City"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['470'].invalid">
            <small >
              required
            </small>
        </div><br>

            <ng-select
              [items]="states"
              (blur)="handleNAchange('cmd_NA_01', 472)"
              id="472"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="472"
              
              placeholder="State or Country"
            >
            </ng-select>

            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['450'].invalid">
            <small >
              required
            </small>
        </div> -->
        <br>

            <input
              type="text"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_01', 473)"
              formControlName="473"
              id="473"
              placeholder="Work History 3 Zip"
              
            />
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['451'].invalid">
            <small >
              required
            </small>
        </div>  -->
          </td>
          <td colspan="2">
            <label for="476" class="ca">FROM</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Work History 3 Start Date"
                ngbDatepicker
                #d476="ngbDatepicker"
                id="476"
                class="form-control"
                formControlName="476"
                (blur)="clearInputIfInvalid('476')"
                
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d476.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('476').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('476').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('476').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('476').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('476').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['454'].invalid">
            <small >
              required
            </small>
        </div>  -->

            <label for="477" class="ca">TO</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Work History 3 End Date"
                ngbDatepicker
                #d477="ngbDatepicker"
                id="477"
                class="form-control"
                formControlName="477"
                (blur)="clearInputIfInvalid('477')"
                
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d477.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('477').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('477').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('477').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('477').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('477').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['477'].invalid">
            <small >
              required
            </small>
        </div>  -->
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="1935">Name of Practice</label>
            <input
              name=""
             
              class="form-control"
              id=" 1935"
              formControlName="1935"
              placeholder="Work History 4 Location"
            >
          </td>
          <td colspan="6">
            <label for="">Address</label>

                <input
               
                  class="form-control"
                  id="1936"
                  formControlName="1936"
                  placeholder="Work History 4 Address 1"
                ><br>
                <input
        
                class="form-control"
                id="1937"
                formControlName="1937"
                placeholder="Work History 4 Address 2"
              /><br>
             
               
                <input
                  
                  class="form-control"
                  id="1938"
                  formControlName="1938"
                  placeholder="Work History 4 City"
                ><br>
             
            
                <ng-select
                  [items]="states"
                  id="1940"
                  bindLabel="name"
                  placeholder="State or Country"
                  bindValue="id"
                  formControlName="1940"
                >
                </ng-select><br>
           
               
                <input
                  type="text"
                  class="form-control"
                  formControlName="1941"
                  id="1941"
                  placeholder="Work History 4 Zip"
                />
           
          </td>
          <td colspan="2">
           
                <label for="1947"  class="ca">FROM</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Work History 4 Start Date"
                    ngbDatepicker
                    #d1947="ngbDatepicker"
                    id="1947"
                    class="form-control"
                    formControlName="1947"
                    (blur)="clearInputIfInvalid('1947')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1947.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
               
              </div>
              <div *ngIf="FlagShipForms.get('1947').invalid ">
                <small class="text-danger" *ngIf="FlagShipForms.get('1947').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('1947').errors.invalidMonth">
                  Month is out of range
                </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('1947').errors.invalidDateyear">
                  year is out of range(1900-2099) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('1947').errors.invalidDateRange">
                  date is out of range
                </small>
              </div>
             
                <label for="1948"  class="ca">TO</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Work History 4 End Date"
                    ngbDatepicker
                    #d1948="ngbDatepicker"
                    id="1948"
                    class="form-control"
                    formControlName="1948"
                    (blur)="clearInputIfInvalid('1948')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1948.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1948').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1948').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1948').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1948').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1948').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
            
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="add_1_1954">Name of Practice</label>
            <input
           
              class="form-control"
              id=" add_1_1954"
              formControlName="add_1_1954"
              placeholder="Additional Work History Location"
            >
          </td>
          <td colspan="6">
            <label for="">Address</label>

          
            
                <input
                  type="text"
                  class="form-control"
                  id="add_1_1955"
                  formControlName="add_1_1955"
                  placeholder="Additional Work History  Address 1"
                /><br>
                <input
        
                class="form-control"
                id="add_1_1956"
                formControlName="add_1_1956"
                placeholder="Additional Work History  Address 2"
              /><br>
        
                <input
                  type="text"
                  class="form-control"
                  id="add_1_1957"
                  formControlName="add_1_1957"
                  placeholder="Additional Work History  City"
                /><br>
            
      
          
                <ng-select
                  [items]="states"
                  id="add_1_1959"
                  bindLabel="name"
                  placeholder="State or Country"
                  bindValue="id"
                  formControlName="add_1_1959"
                >
                </ng-select><br>
     
               
                <input
                  type="text"
                  class="form-control"
                  formControlName="add_1_1960"
                  id="add_1_1960"
                  placeholder="Additional Work History  Zip"
                />
            
          </td>
          <td colspan="2">
           
                <label for="add_1_1966"  class="ca">FROM</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Work History  Start Date"
                    ngbDatepicker
                    #dadd_1_1966="ngbDatepicker"
                    id="add_1_1966"
                    class="form-control"
                    formControlName="add_1_1966"
                    (blur)="clearInputIfInvalid('add_1_1966')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dadd_1_1966.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('add_1_1966').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1966').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1966').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1966').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1966').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
           
                <label for="add_1_1967"  class="ca">TO</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Work History End Date"
                    ngbDatepicker
                    #dadd_1_1967="ngbDatepicker"
                    id="add_1_1967"
                    class="form-control"
                    formControlName="add_1_1967"
                    (blur)="clearInputIfInvalid('add_1_1967')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dadd_1_1967.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('add_1_1967').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1967').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1967').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1967').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1967').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="add_2_1954">Name of Practice</label>
            <input
           
              class="form-control"
              id=" add_2_1954"
              formControlName="add_2_1954"
              placeholder="Additional Work History Location"
            >
          </td>
          <td colspan="6">
            <label for="">Address</label>

          
            
                <input
                  type="text"
                  class="form-control"
                  id="add_2_1955"
                  formControlName="add_2_1955"
                  placeholder="Additional Work History  Address 1"
                /><br>
                <input
        
                class="form-control"
                id="add_2_1956"
                formControlName="add_2_1956"
                placeholder="Additional Work History  Address 2"
              /><br>
        
                <input
                  type="text"
                  class="form-control"
                  id="add_2_1957"
                  formControlName="add_2_1957"
                  placeholder="Additional Work History  City"
                /><br>
            
      
          
                <ng-select
                  [items]="states"
                  id="add_2_1959"
                  bindLabel="name"
                  placeholder="State or Country"
                  bindValue="id"
                  formControlName="add_2_1959"
                >
                </ng-select><br>
     
               
                <input
                  type="text"
                  class="form-control"
                  formControlName="add_2_1960"
                  id="add_2_1960"
                  placeholder="Additional Work History  Zip"
                />
            
          </td>
          <td colspan="2">
           
                <label for="add_2_1966"  class="ca">FROM</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Work History  Start Date"
                    ngbDatepicker
                    #dadd_2_1966="ngbDatepicker"
                    id="add_2_1966"
                    class="form-control"
                    formControlName="add_2_1966"
                    (blur)="clearInputIfInvalid('add_2_1966')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dadd_2_1966.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('add_2_1966').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1966').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1966').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1966').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1966').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
           
                <label for="add_2_1967"  class="ca">TO</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Work History End Date"
                    ngbDatepicker
                    #dadd_2_1967="ngbDatepicker"
                    id="add_2_1967"
                    class="form-control"
                    formControlName="add_2_1967"
                    (blur)="clearInputIfInvalid('add_2_1967')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dadd_2_1967.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('add_2_1967').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1967').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1967').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1967').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1967').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              
          </td>
        </tr>
        
        <tr>
          <td colspan="12" class="bg">
            <h6 class=" ms-3 mt-3 mb-3">IV.DENTAL/MEDICAL EDUCATION</h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="490">Institution 1</label>
            <input
              type="text"
              id="490"
              class="form-control"
              placeholder="Med School"
              formControlName="490"
              [class.is-invalid]="
                (FlagShipForms.controls['490'].touched &&
                  FlagShipForms.controls['490'].invalid) ||
                (FlagShipForms.controls['490'].invalid && isSubmit)
              "
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['490'].touched &&
                FlagShipForms.controls['490'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['490'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="491">Address </label>
            <div class="row">
              <div class="col-md-6"> <input type="text"
              
                class="form-control"
                id="491"
                formControlName="491"
                placeholder=" Med School Address1 "
                [class.is-invalid]="
                  (FlagShipForms.controls['491'].touched &&
                    FlagShipForms.controls['491'].invalid) ||
                  (FlagShipForms.controls['491'].invalid && isSubmit)
                "
              >
              <div
                class="formLabel col-md-11"
                *ngIf="
                  FlagShipForms.controls['491'].touched &&
                  FlagShipForms.controls['491'].invalid
                "
              >
                <small
                  class="text-danger"
                  *ngIf="FlagShipForms.controls['491'].errors?.required"
                >
                  required
                </small>
              </div></div>
              <div class="col-md-6"><input type="text" placeholder="Med School Address2 " id="492" formControlName="492" class="form-control"/>
              </div>
            </div>
           
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="493">City</label>
            <input
             
              class="form-control"
              id="493"
              formControlName="493"
              placeholder=" Med School City  "
              [class.is-invalid]="
                (FlagShipForms.controls['493'].touched &&
                  FlagShipForms.controls['493'].invalid) ||
                (FlagShipForms.controls['493'].invalid && isSubmit)
              "
            >
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['493'].touched &&
                FlagShipForms.controls['493'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['493'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="4">
            <label>  State </label>
            <ng-select
              [items]="states"
              id="495"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="495"
              [class.is-invalid]="
                (FlagShipForms.controls['495'].touched &&
                  FlagShipForms.controls['495'].invalid) ||
                (FlagShipForms.controls['495'].invalid && isSubmit)
              "
            >
            </ng-select>

            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['495'].touched &&
                FlagShipForms.controls['495'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['495'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="496">Zip Code</label>
            <input
              type="text"
              class="form-control"
              id="496"
              formControlName="496"
              [class.is-invalid]="
                (FlagShipForms.controls['496'].touched &&
                  FlagShipForms.controls['496'].invalid) ||
                (FlagShipForms.controls['496'].invalid && isSubmit)
              "
              placeholder="Med School Zip "
            />
            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['496'].touched &&
                FlagShipForms.controls['496'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['496'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="500">  Degree </label>
            <ng-select
              [items]="degree"
              id="500"
              bindLabel="name"
              placeholder="Degree"
              bindValue="id"
              formControlName="500"
              [class.is-invalid]="
                (FlagShipForms.controls['500'].touched &&
                  FlagShipForms.controls['500'].invalid) ||
                (FlagShipForms.controls['500'].invalid && isSubmit)
              "
            >
            </ng-select>

            <div
              class="formLabel col-md-11"
              *ngIf="
                FlagShipForms.controls['500'].touched &&
                FlagShipForms.controls['500'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['500'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="5">
            <label for=""> Date Attended</label>
            <div class="row">
              <div class="col-md-6">
                <label for="497"  class="ca"> FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Med School Start "
                    ngbDatepicker
                    #d497="ngbDatepicker"
                    id="497"
                    class="form-control"
                    formControlName="497"
                    (blur)="clearInputIfInvalidMandi('497')"
                    [class.is-invalid]="
                      (FlagShipForms.controls['497'].touched &&
                        FlagShipForms.controls['497'].invalid) ||
                      (FlagShipForms.controls['497'].invalid && isSubmit)
                    "
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d497.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  
                </div>
                <div class="text-danger"
                    *ngIf="(FlagShipForms.controls['497'].invalid&&FlagShipForms.controls['497'].touched) && (FlagShipForms.controls['497'].value==''|| FlagShipForms.controls['497'].value==null) ">
                    <small>
                     required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="FlagShipForms.get('497').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('497').errors?.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('497').errors?.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('497').errors?.invalidDateRange">
                    date is out of range
                  </small>
              </div>
              <div class="col-md-6">
                <label for="498"  class="ca">  TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Med School End "
                    ngbDatepicker
                    #d498="ngbDatepicker"
                    id="498"
                    class="form-control"
                    formControlName="498"
                    (blur)="clearInputIfInvalidMandi('498')"
                    [class.is-invalid]="
                      (FlagShipForms.controls['498'].touched &&
                        FlagShipForms.controls['498'].invalid) ||
                      (FlagShipForms.controls['498'].invalid && isSubmit)
                    "
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d498.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                 
                </div>
                <div class="text-danger"
                *ngIf="(FlagShipForms.controls['498'].invalid&&FlagShipForms.controls['498'].touched) && (FlagShipForms.controls['498'].value==''|| FlagShipForms.controls['498'].value==null) ">
                <small>
                required 
                </small>
              
              </div>
              <small class="text-danger" *ngIf="FlagShipForms.get('498').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('498').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('498').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('498').errors?.invalidDateRange">
                date is out of range
              </small>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1288">Dental School</label>
            <input
              type="text"
              id="1288"
              class="form-control"
              placeholder="Additional  School"
              formControlName="1288"
            />
          </td>
        </tr>

        <tr>
          <td colspan="12">
            <label for="1289">Address </label>
            <div class="row">
              <div class="col-md-6">
                <input
                class="form-control"
                id="1289"
                formControlName="1289"
                placeholder=" Additional School Address1"
              />
              </div>
              <div class="col-md-6">
                <input type="text" placeholder="Additional School Address2 " id="1290" formControlName="1290" class="form-control"/>
              </div>
            </div>
       
            
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1291">City </label>
            <input
              type="text"
              class="form-control"
              id="1291"
              formControlName="1291"
              placeholder=" Additional School City  "
            />
          </td>
          <td colspan="4">
            <label>  State </label>
            <ng-select
              [items]="states"
              id="1293"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="1293"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label for="1294">Zip Code</label>
            <input
              type="text"
              class="form-control"
              id="1294"
              formControlName="1294"
              placeholder="Additional School Zip "
            />
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="1298">  Degree </label>
            <ng-select
              [items]="degree"
              id="1298"
              bindLabel="name"
              placeholder="Degree"
              bindValue="id"
              formControlName="1298"
            >
            </ng-select>
          </td>
          <td colspan="5">
            <label for="">  Date Attended </label>
            <div class="row">
              <div class="col-md-6">
                <label for="1295"  class="ca">  FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional School Start Date"
                    ngbDatepicker
                    #d1295="ngbDatepicker"
                    id="1295"
                    class="form-control"
                    formControlName="1295"
                    (blur)="clearInputIfInvalid('1295')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1295.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1295').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1295').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1295').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1295').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1295').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label for="1296"  class="ca">TO</label>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional School End Date"
                    ngbDatepicker
                    #d1296="ngbDatepicker"
                    id="1296"
                    class="form-control"
                    formControlName="1296"
                    (blur)="clearInputIfInvalid('1296')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1296.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1296').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1296').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1296').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1296').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1296').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="10">
            <label for="514"> ECFMG (If Applicable) </label>
            <input
              type="text"
              id="514"
              formControlName="514"
              class="form-control"
              placeholder="ECFMG Number "
            />
          </td>
          <td colspan="2">
            <label for="515"> Issue Date</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="ECFMG Issue Date "
                ngbDatepicker
                #d515="ngbDatepicker"
                id="515"
                class="form-control"
                formControlName="515"
                (blur)="clearInputIfInvalid('515')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d515.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('515').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('515').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('515').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('515').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('515').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">V.INTERNSHIP</h6>
              </div>
              <div class="col-md-2 mt-2">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_02"
                  id="cmd_NA_02"
                  formControlName="cmd_NA_02"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_02')"
                /><b>Does Not Apply </b> 
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="517">Institution </label>
            <input
              type="text"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_02', 517)"
              id="517"
              formControlName="517"
              placeholder=" Intern School "
              [class.is-invalid]="FlagShipForms.controls['517'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['517'].invalid">
            <small >
              required
            </small>
        </div>          
      </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="518">Address </label>
            <div class="row">
              <div class="col-md-6">
                <input
                type="text"
                class="form-control"
                id="518"
                (blur)="handleNAchange('cmd_NA_02', 518)"
                formControlName="518"
                placeholder=" Intern Address1 "
                [class.is-invalid]="FlagShipForms.controls['518'].invalid"
              />
              <div class="mandatory"
              *ngIf="FlagShipForms.controls['518'].invalid">
              <small >
                required
              </small>
          </div>
              </div>
              <div class="col-md-6">
                <input type="text" placeholder=" Intern Address2" id="519" formControlName="519" class="form-control"/>
              </div>
            </div>
           
          
          </td>
        </tr>


    
        <tr>
          <td colspan="6">
            <label for="520">City</label>
            <input
              type="text"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_02', 520)"
              id="520"
              formControlName="520"
              placeholder="Intern City"
              [class.is-invalid]="FlagShipForms.controls['520'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['520'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label for="522"> State </label>
            <!-- <ng-select
              [items]="states"
              id="522"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="522"
              (blur)="handleNAchange('cmd_NA_02', 522)"
              [class.is-invalid]="FlagShipForms.controls['522'].invalid"
              placeholder="State or Country"
            >
            </ng-select> -->
            <ng-select
            [items]="states"
            id="522"
            bindLabel="name"
            placeholder="State or Country"
            (blur)="handleNAchange('cmd_NA_02', 522)"
            bindValue="id"
            formControlName="522"
            [class.is-invalid]="FlagShipForms.controls['522'].invalid"
          >
          </ng-select>
          <div class="mandatory"
          *ngIf="FlagShipForms.controls['522'].invalid">
          <small >
            required
          </small>
          </div>
          </td>
          <td colspan="2">
            <label for="523">Zip Code</label>
            <input
              type="text"
              id="523"
              (blur)="handleNAchange('cmd_NA_02', 523)"
              class="form-control"
              placeholder="Intern Zip"
              formControlName="523"
              [class.is-invalid]="FlagShipForms.controls['523'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['523'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="2539">Type of Internship</label>

            <input
              type="text"
              class="form-control"
              id="2539"
              formControlName="2539"
              (blur)="handleNAchange('cmd_NA_02', 2539)"
              [class.is-invalid]="FlagShipForms.controls['2539'].invalid"
              placeholder="Internship Department"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['2539'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label for="527">Specialty </label>
            <ng-select
              [items]="specialty"
              (blur)="handleNAchange('cmd_NA_02', 527)"
              id="527"
              bindLabel="name"
              placeholder="Primary Specialty"
              bindValue="id"
              formControlName="527"
              [class.is-invalid]="FlagShipForms.controls['527'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['527'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label for="">Date</label>
            <div>
              <div class="row">
                <div class="col-md-6">
                  <label for="524"  class="ca"> FROM </label>
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Intern Start Date  "
                      (blur)="handleNAchange('cmd_NA_02', 524)"
                      (blur)="clearInputIfInvalid('524','cmd_NA_02')"
                      ngbDatepicker
                      #d524="ngbDatepicker"
                      id="524"
                      class="form-control"
                      formControlName="524"
                      [class.is-invalid]="FlagShipForms.controls['524'].invalid"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d524.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <!-- <div class="mandatory"
                    *ngIf="FlagShipForms.controls['524'].invalid">
                    <small >
                      required
                    </small>
                    </div> -->
                  </div>
                  <div class="mandatory" *ngIf="FlagShipForms.controls['524'].invalid && (FlagShipForms.controls['524'].value==''|| FlagShipForms.controls['524'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <div *ngIf="FlagShipForms.get('524').invalid ">
                    <small class="text-danger" *ngIf="FlagShipForms.get('524').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('524').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('524').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('524').errors?.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="525"  class="ca"> TO </label>
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Intern End Date  "
                      (blur)="handleNAchange('cmd_NA_02', 525)"
                      (blur)="clearInputIfInvalid('525','cmd_NA_02')"
                      ngbDatepicker
                      #d525="ngbDatepicker"
                      id="525"
                      class="form-control"
                      formControlName="525"
                      [class.is-invalid]="FlagShipForms.controls['525'].invalid"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="d525.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <!-- <div class="mandatory"
                    *ngIf="FlagShipForms.controls['525'].invalid">
                    <small >
                      required
                    </small>
                    </div> -->
                  </div>
                  <div class="mandatory" *ngIf="FlagShipForms.controls['525'].invalid && (FlagShipForms.controls['525'].value==''|| FlagShipForms.controls['525'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <div *ngIf="FlagShipForms.get('525').invalid ">
                    <small class="text-danger" *ngIf="FlagShipForms.get('525').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('525').errors?.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('525').errors?.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="FlagShipForms.get('525').errors?.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class="  ms-3 mt-3 mb-3">VI.RESIDENCIES</h6>
              </div>
              <div class="col-md-2 mt-2">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_03"
                  id="cmd_NA_03"
                  formControlName="cmd_NA_03"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_03')"
                /><b>Does Not Apply</b>  
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="529">Institution 1</label>

            <input
              type="text"
              class="form-control"
              id="529"
              (blur)="handleNAchange('cmd_NA_03', 529)"
              formControlName="529"
              [class.is-invalid]="FlagShipForms.controls['529'].invalid"
              placeholder="Residency School "
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['529'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="540">Chairperson/Chief of Service</label>

            <input
              type="text"
              class="form-control"
              id="540"
              (blur)="handleNAchange('cmd_NA_03', 540)"
              formControlName="540"
              [class.is-invalid]="FlagShipForms.controls['540'].invalid"
              placeholder=" Residency Director  "
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['540'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="531">Address</label>
            <div class="row">
              <div class="col-md-6">
                <input type="text"
            
              class="form-control"
              (blur)="handleNAchange('cmd_NA_03', 531)"
              id="531"
              formControlName="531"
              placeholder="Residency Address1"
              [class.is-invalid]="FlagShipForms.controls['531'].invalid"
            >
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['531'].invalid">
            <small >
              required
            </small>
            </div>
              </div>
              <div class="col-md-6">
                <input type="text" id="1498" formControlName="1498" class="form-control" placeholder="Residency Address2" />
              </div>
            </div>
            
           
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="533">City</label>
            <input 
            
              class="form-control"
              (blur)="handleNAchange('cmd_NA_03', 533)"
              id="533"
              formControlName="533"
              placeholder="Residency City "
              [class.is-invalid]="FlagShipForms.controls['533'].invalid"
            >
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['533'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label for="535">State</label>
            <ng-select
              [items]="states"
              id="535"
              bindLabel="name"
              placeholder="State or Country"
              (blur)="handleNAchange('cmd_NA_03', 535)"
              bindValue="id"
              formControlName="535"
              [class.is-invalid]="FlagShipForms.controls['535'].invalid"
            >
            </ng-select>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['535'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label for="536">Zip Code</label>
            <input
              type="text"
              id="536"
              class="form-control"
              placeholder="Residency Zip"
              formControlName="536"
              (blur)="handleNAchange('cmd_NA_03', 536)"
              [class.is-invalid]="FlagShipForms.controls['536'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['536'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="530"> Specialty </label>
            <ng-select
              [items]="specialty"
              id="530"
              (blur)="handleNAchange('cmd_NA_03', 530)"
              bindLabel="name"
              placeholder="Primary Specialty"
              bindValue="id"
              formControlName="530"
              [class.is-invalid]="FlagShipForms.controls['530'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['530'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label>Date Attended</label>
            <div class="row">
              <div class="col-md-6">
                <label for="537"  class="ca"> FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Residency Start   "
                    (blur)="handleNAchange('cmd_NA_03', 537)"
                    (blur)="clearInputIfInvalid('537','cmd_NA_03')"
                    ngbDatepicker
                    #d537="ngbDatepicker"
                    id="537"
                    class="form-control"
                    formControlName="537"
                    [class.is-invalid]="FlagShipForms.controls['537'].invalid"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d537.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <!-- <div class="mandatory"
                  *ngIf="FlagShipForms.controls['537'].invalid">
                  <small >
                    required
                  </small>
                  </div> -->
                </div>
                <div class="mandatory" *ngIf="FlagShipForms.controls['537'].invalid && (FlagShipForms.controls['537'].value==''|| FlagShipForms.controls['537'].value==null) " >
                  <small >
                  required
                  </small>
                </div>
                <div *ngIf="FlagShipForms.get('537').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('537').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('537').errors?.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('537').errors?.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('537').errors?.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label for="538"  class="ca"> TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Residency End   "
                    (blur)="handleNAchange('cmd_NA_03', 538)"
                    (blur)="clearInputIfInvalid('538','cmd_NA_03')"
                    ngbDatepicker
                    #d538="ngbDatepicker"
                    id="538"
                    class="form-control"
                    formControlName="538"
                    [class.is-invalid]="FlagShipForms.controls['538'].invalid"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d538.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <!-- <div class="mandatory"
                  *ngIf="FlagShipForms.controls['538'].invalid">
                  <small >
                    required
                  </small>
                  </div> -->
                </div>
                <div class="mandatory" *ngIf="FlagShipForms.controls['538'].invalid && (FlagShipForms.controls['538'].value==''|| FlagShipForms.controls['538'].value==null) " >
                  <small >
                  required
                  </small>
                </div>
                <div *ngIf="FlagShipForms.get('538').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('538').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('538').errors?.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('538').errors?.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('538').errors?.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="529">Institution 2</label>

            <input
              type="text"
              class="form-control"
              id="1170"
              formControlName="1170"
              placeholder="Additional Residency Schools "
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1181">Chairperson/Chief of Service</label>

            <input
              type="text"
              class="form-control"
              id="1181"
              formControlName="1181"
              placeholder="Additional Residency Director   "
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1172">Address</label>
            <div class="row">
              <div class="col-md-6">
                <input type="text"

              class="form-control"
              id="1172"
              formControlName="1172"
              placeholder="Additional Residency Address1"
            >
              </div>
              <div class="col-md-6">
                <input type="text"

                class="form-control"
                id="1173"
                formControlName="1173"
                placeholder="Additional Residency Address2"
              >
              </div>
            </div>
            
         
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1174">City</label>
            <input
            
              class="form-control"
              id="1174"
              formControlName="1174"
              placeholder="Additional Residency City "
            >
          </td>
          <td colspan="4">
            <label for="1176">State</label>
            <ng-select
              [items]="states"
              id="1176"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="1176"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label for="1177">Zip Code</label>
            <input
              type="text"
              id="1177"
              class="form-control"
              placeholder="Additional Residency Zip"
              formControlName="1177"
            />
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="1171">Specialty </label>
            <ng-select
              [items]="specialty"
              id="1171"
              bindLabel="name"
              placeholder="Primary Specialty"
              bindValue="id"
              formControlName="1171"
            >
            </ng-select>
          </td>
          <td colspan="4">
            <label>Date Attended</label>
            <div class="row">
              <div class="col-md-6">
                <label for="1178">FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Residency Start   "
                    ngbDatepicker
                    #d1178="ngbDatepicker"
                    id="1178"
                    class="form-control"
                    formControlName="1178"
                    (blur)="clearInputIfInvalid('1178')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1178.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1178').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1178').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1178').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1178').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1178').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label for="1179">TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Additional Residency End   "
                    ngbDatepicker
                    #d1179="ngbDatepicker"
                    id="1179"
                    class="form-control"
                    formControlName="1179"
                    (blur)="clearInputIfInvalid('1179')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1179.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1179').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1179').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1179').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1179').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1179').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <h6 class=" ms-3 mt-3 mb-3">VII.TEACHING APPOINTMENTS</h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1146">Institution 1 </label>
            <textarea
              name=""
              cols="3"
              rows="1"
              class="form-control"
              id="1146"
              formControlName="1146"
              placeholder=" Primary Institution Name "
            ></textarea>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1148">Address </label>
            <div class="row">
              <div class="col-md-6">
                <input
             
                class="form-control"
                id="1148"
                formControlName="1148"
                placeholder=" Primary Institution Address1 "
              >
              </div>
              <div class="col-md-6">
                <input
             
                class="form-control"
                id="1149"
                formControlName="1149"
                placeholder=" Primary Institution Address2 "
              >
              </div>
            </div>
          
          
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1150">City</label>
            <input
            
              class="form-control"
              id="1150"
              formControlName="1150"
              placeholder="Primary Institution City  "
            >
          </td>
          <td colspan="4">
            <label for="1152">State</label>
            <ng-select
              [items]="states"
              id="1152"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="1152"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label for="1153">Zip Code</label>
            <input
              type="text"
              id="1153"
              class="form-control"
              placeholder="Primary Institution Zip "
              formControlName="1153"
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="1382">Department Chief </label>
            <input
              type="text"
              id="1382"
              class="form-control"
              placeholder="Primary Institution Chairman  "
              formControlName="1382"
            />
          </td>
          <td colspan="4">
            <label for="1154"> Type of Appointment </label>
            <input
              type="text"
              id="1154"
              class="form-control"
              placeholder="Primary Position Rank or Title "
              formControlName="1154"
            />
          </td>
          <td colspan="4">
            <label>Dates</label>
            <div class="row">
              <div class="col-md-6">
                <label for="1155">  FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Primary Position Start Date "
                    ngbDatepicker
                    #d1155="ngbDatepicker"
                    id="1155"
                    class="form-control"
                    formControlName="1155"
                    (blur)="clearInputIfInvalid('1155')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1155.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1155').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1155').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1155').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1155').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1155').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label for="1156"> TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Primary Position Term Date"
                    ngbDatepicker
                    #d1156="ngbDatepicker"
                    id="1156"
                    class="form-control"
                    formControlName="1156"
                    (blur)="clearInputIfInvalid('1156')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1156.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1156').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1156').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1156').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1156').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1156').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1157">Institution 2 </label>
            <input
              type="text"
              class="form-control"
              id="1157"
              formControlName="1157"
              placeholder=" Secondary Institution Name "
            />
          </td>
        </tr>
   
        <tr>
          <td colspan="12">
            <label for="1159">Address </label>
            <div class="row">
              <div class="col-md-6">
                <input
                type="text"
                class="form-control"
                id="1159"
                formControlName="1159"
                placeholder=" Secondary Institution Address1 "
              />
              </div>
              <div class="col-md-6">
                <input
                type="text"
                class="form-control"
                id="1160"
                formControlName="1160"
                placeholder=" Secondary Institution Address2 "
              />
              </div>
            </div>
          
           
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1161">City</label>
            <input
              type="text"
              class="form-control"
              id="1161"
              formControlName="1161"
              placeholder="Secondary Institution City  "
            />
          </td>
          <td colspan="4">
            <label for="1163">State</label>
            <ng-select
              [items]="states"
              id="1163"
              bindLabel="name"
              placeholder="State or Country"
              bindValue="id"
              formControlName="1163"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label for="1164">Zip Code</label>
            <input
              type="text"
              id="1164"
              class="form-control"
              placeholder="Secondary Institution Zip "
              formControlName="1164"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label for="1383">  Department Chief </label>
            <input
              type="text"
              id="1383"
              class="form-control"
              placeholder="Secondary Institution Chairman  "
              formControlName="1383"
            />
          </td>
          <td colspan="4">
            <label for="1165">  Type of Appointment   </label>
            <input
              type="text"
              id="1165"
              class="form-control"
              placeholder="Secondary Position Rank or Title "
              formControlName="1165"
            />
          </td>
          <td colspan="6">
            <label>Dates</label>
            <div class="row">
              <div class="col-md-6">
                <label for="1166">FROM</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Secondary Position Start Date"
                    ngbDatepicker
                    #d1166="ngbDatepicker"
                    id="1166"
                    class="form-control"
                    formControlName="1166"
                    (blur)="clearInputIfInvalid('1166')"
                    
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1166.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1166').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1166').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1166').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1166').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1166').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <label for="1167">  TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Secondary Position Term Date"
                    ngbDatepicker
                    #d1167="ngbDatepicker"
                    id="1167"
                    class="form-control"
                    formControlName="1167"
                    (blur)="clearInputIfInvalid('1167')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1167.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('1167').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('1167').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1167').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1167').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('1167').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <h6 class=" ms-3 mt-3 mb-3">VIII. HOSPITAL STAFF</h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <h6>
              <b class="al"
                >List in chronological order, with dates and addresses, all
                present and past hospital appointments. Identify       
                category of staff membership held (i.e. Active, Courtesy, or
                 Provisional.) </b> </h6>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="418">  Hospital </label>
            <input
              type="text"
              id="418"
              class="form-control"
              placeholder="Hospital Affiliation 1 Name  "
              formControlName="418"
            />
          </td>
          <td colspan="4">
            <label for="425">Department</label>
            <input
              type="text"
              id="425"
              class="form-control"
              placeholder="Hospital Affiliation 1 Dept  "
              formControlName="425"
            />
          </td>
          <td colspan="4">
            <label>Dates</label>
            <br>

            <!-- <div class="row">
              <div class="col-md-6"> -->
                <label for="426" class="cb" >FROM</label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 1 Initial Appointment Date"
                    ngbDatepicker
                    #d426="ngbDatepicker"
                    id="426"
                    class="form-control"
                    formControlName="426"
                    (blur)="clearInputIfInvalid('426')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d426.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('426').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('426').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('426').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('426').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('426').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              <!-- </div> -->
              <!-- <div class="col-md-6"> -->
                <label for="2007" class="cb"> TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 1 Next Appointment Date   "
                    ngbDatepicker
                    #d2007="ngbDatepicker"
                    id="2007"
                    class="form-control"
                    formControlName="2007"
                    (blur)="clearInputIfInvalid('2007')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d2007.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('2007').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('2007').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2007').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2007').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2007').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              <!-- </div>
            </div> -->
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <label for="427">  Hospital   </label>
            <input
              type="text"
              id="427"
              class="form-control"
              placeholder="Hospital Affiliation 2 Name  "
              formControlName="427"
            />
          </td>
          <td colspan="4">
            <label for="434">Department </label>
            <input
              type="text"
              id="434"
              class="form-control"
              placeholder="Hospital Affiliation 2 Dept  "
              formControlName="434"
            />
          </td>
          <td colspan="4">
            <label>Dates</label>
            <br>

            <!-- <div class="row">
              <div class="col-md-6"> -->
                <label for="435"  class="cb">  FROM </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 2 Initial Appointment Date "
                    ngbDatepicker
                    #d435="ngbDatepicker"
                    id="435"
                    class="form-control"
                    formControlName="435"
                    (blur)="clearInputIfInvalid('435')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d435.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('435').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('435').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('435').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('435').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('435').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              <!-- </div> -->
              <!-- <div class="col-md-6"> -->
                <label for="2008" class="cb">  TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 2 Next Appointment Date   "
                    ngbDatepicker
                    #d2008="ngbDatepicker"
                    id="2008"
                    class="form-control"
                    formControlName="2008"
                    (blur)="clearInputIfInvalid('2008')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d2008.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="FlagShipForms.get('2008').invalid ">
                  <small class="text-danger" *ngIf="FlagShipForms.get('2008').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2008').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2008').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('2008').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              <!-- </div>
            </div> -->
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <label for="436"> Hospital </label>
            <input
              type="text"
              id="436"
              class="form-control"
              placeholder="Hospital Affiliation 3 Name  "
              formControlName="436"
            />
          </td>
          <td colspan="4">
            <label for="443">  Department </label>
            <input
              type="text"
              id="443"
              class="form-control"
              placeholder="Hospital Affiliation 3 Dept  "
              formControlName="443"
            />
          </td>
          <td colspan="4">
            <label>Dates</label>
            <br>

            <!-- <div class="row">
              <div class="col-md-6"> -->
                <label for="444" class="cb">  FROM   </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 3 Initial Appointment Date "
                    ngbDatepicker
                    #d444="ngbDatepicker"
                    id="435"
                    class="form-control"
                    formControlName="444"
                    (blur)="clearInputIfInvalid('444')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d444.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                <!-- </div> -->
              </div>
              <div *ngIf="FlagShipForms.get('444').invalid ">
                <small class="text-danger" *ngIf="FlagShipForms.get('444').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('444').errors.invalidMonth">
                  Month is out of range
                </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('444').errors.invalidDateyear">
                  year is out of range(1900-2099) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('444').errors.invalidDateRange">
                  date is out of range
                </small>
              </div>
              <!-- <div class="col-md-6"> -->
                <label for="2011" class="cb">  TO </label>
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Hospital Affiliation 3 Next Appointment Date   "
                    ngbDatepicker
                    #d2011="ngbDatepicker"
                    id="2011"
                    class="form-control"
                    formControlName="2011"
                    (blur)="clearInputIfInvalid('2011')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d2011.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  <!-- </div>
                </div> -->
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('2011').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('2011').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2011').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2011').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2011').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="12" class="bg">
            <h6 class="  ms-3 mt-3 mb-3">IX.LICENSURE</h6>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="states"
              id="299"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="299"
              [class.is-invalid]="
                (FlagShipForms.controls['299'].touched &&
                  FlagShipForms.controls['299'].invalid) ||
                (FlagShipForms.controls['299'].invalid && isSubmit)
              "
            >
            </ng-select>

            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['299'].touched &&
                FlagShipForms.controls['299'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['299'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label>Type</label>
            <input
              id="296"
              class="form-control"
              bindLabel="name"
              placeholder="License Type "
              bindValue="id"
              formControlName="296"
              [class.is-invalid]="
                (FlagShipForms.controls['296'].touched &&
                  FlagShipForms.controls['296'].invalid) ||
                (FlagShipForms.controls['296'].invalid && isSubmit)
              "
            />

            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['296'].touched &&
                FlagShipForms.controls['296'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['296'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label>Number</label>
            <input
              id="293"
              class="form-control"
              bindLabel="name"
              placeholder="License Number "
              bindValue="id"
              formControlName="293"
              [class.is-invalid]="
                (FlagShipForms.controls['293'].touched &&
                  FlagShipForms.controls['293'].invalid) ||
                (FlagShipForms.controls['293'].invalid && isSubmit)
              "
            />

            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['293'].touched &&
                FlagShipForms.controls['293'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['293'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label>Licensing Entity</label>
            <input
              id="1918"
              class="form-control"
              bindLabel="name"
              placeholder="Licensing Entity "
              bindValue="id"
              formControlName="1918"
              [class.is-invalid]="
                (FlagShipForms.controls['1918'].touched &&
                  FlagShipForms.controls['1918'].invalid) ||
                (FlagShipForms.controls['1918'].invalid && isSubmit)
              "
            />

            <div
              class="formLabel col-md-2"
              *ngIf="
                FlagShipForms.controls['1918'].touched &&
                FlagShipForms.controls['1918'].invalid
              "
            >
              <small
                class="text-danger"
                *ngIf="FlagShipForms.controls['1918'].errors?.required"
              >
                required
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="294">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="License Issue Date "
                ngbDatepicker
                #d294="ngbDatepicker"
                id="294"
                class="form-control"
                formControlName="294"
                (blur)="clearInputIfInvalidMandi('294')"
                [class.is-invalid]="
                  (FlagShipForms.controls['294'].touched &&
                    FlagShipForms.controls['294'].invalid) ||
                  (FlagShipForms.controls['294'].invalid && isSubmit)
                "
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d294.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="text-danger"
              *ngIf="(FlagShipForms.controls['294'].invalid&&FlagShipForms.controls['294'].touched) && (FlagShipForms.controls['294'].value==''|| FlagShipForms.controls['294'].value==null) ">
              <small>
               required
              </small>
            
            </div>
            <small class="text-danger" *ngIf="FlagShipForms.get('294').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('294').errors?.invalidMonth">
              Month is out of range
            </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('294').errors?.invalidDateyear">
              year is out of range(1900-2099) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('294').errors?.invalidDateRange">
              date is out of range
            </small>
          </td>
          <td colspan="2">
            <label for="295">Date Expires </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="License Expiration Date"
                ngbDatepicker
                #d295="ngbDatepicker"
                id="295"
                class="form-control"
                formControlName="295"
                (blur)="clearInputIfInvalidMandi('295')"
                [class.is-invalid]="
                  (FlagShipForms.controls['295'].touched &&
                    FlagShipForms.controls['295'].invalid) ||
                  (FlagShipForms.controls['295'].invalid && isSubmit)
                "
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d295.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="text-danger"
              *ngIf="(FlagShipForms.controls['295'].invalid&&FlagShipForms.controls['295'].touched) && (FlagShipForms.controls['295'].value==''|| FlagShipForms.controls['295'].value==null) ">
              <small>
              required 
              </small>
            
            </div>
            <small class="text-danger" *ngIf="FlagShipForms.get('295').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('295').errors?.invalidMonth">
              Month is out of range
            </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('295').errors?.invalidDateyear">
              year is out of range(1900-2099) </small>
            <small class="text-danger" *ngIf="FlagShipForms.get('295').errors?.invalidDateRange">
              date is out of range
            </small>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="altLicenseState"
              id="907"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="907"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label>Type</label>
            <input
              id="1274"
              class="form-control"
              bindLabel="name"
              placeholder="License2 Type "
              bindValue="id"
              formControlName="1274"
            />
          </td>
          <td colspan="2">
            <label>Number</label>
            <input
              id="300"
              class="form-control"
              bindLabel="name"
              placeholder="License 2 Number "
              bindValue="id"
              formControlName="300"
            />
          </td>
          <td colspan="2">
            <label>Licensing Entity</label>
            <input
              id="1919"
              class="form-control"
              bindLabel="name"
              placeholder="License 2 Entity  "
              bindValue="id"
              formControlName="1919"
            />
          </td>
          <td colspan="2">
            <label for="307">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="License 2 Issue Date"
                ngbDatepicker
                #d307="ngbDatepicker"
                id="307"
                class="form-control"
                formControlName="307"
                (blur)="clearInputIfInvalid('307')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d307.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('307').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('307').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('307').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('307').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('307').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="308">Date Expires </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Alt License Exp Date "
                ngbDatepicker
                #d308="ngbDatepicker"
                id="308"
                class="form-control"
                formControlName="308"
                (blur)="clearInputIfInvalid('308')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d308.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('308').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('308').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('308').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('308').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('308').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State  </label>
            <ng-select
              [items]="additionalLicenseStateData"
              id="add_1_1010"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_1_1010"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label>Type</label>
            <input
              id="add_1_1432"
              class="form-control"
              bindLabel="name"
              placeholder=" Additional Lic Type "
              bindValue="id"
              formControlName="add_1_1432"
            />
          </td>
          <td colspan="2">
            <label>Number</label>
            <input
              id="add_1_999"
              class="form-control"
              bindLabel="name"
              placeholder="Additional License Num "
              bindValue="id"
              formControlName="add_1_999"
            />
          </td>
          <td colspan="2">
            <label>Licensing Entity  </label>
            <input
              id="add_1_1920"
              class="form-control"
              bindLabel="name"
              placeholder="Additional Lic Entity "
              bindValue="id"
              formControlName="add_1_1920"
            />
          </td>
          <td colspan="2">
            <label for="add_1_1003">Date Issued</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Issue"
                ngbDatepicker
                #dadd_1_1003="ngbDatepicker"
                id="add_1_1003"
                class="form-control"
                formControlName="add_1_1003"
                (blur)="clearInputIfInvalid('add_1_1003')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_1_1003.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_1_1003').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1003').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1003').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1003').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1003').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_1_1004">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Exp "
                ngbDatepicker
                #dadd_1_1004="ngbDatepicker"
                id="add_1_1004"
                class="form-control"
                formControlName="add_1_1004"
                (blur)="clearInputIfInvalid('add_1_1004')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_1_1004.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_1_1004').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1004').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1004').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1004').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1004').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="additionalLicenseStateData"
              id="add_2_1010"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_2_1010"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label>Type</label>
            <input
              id="add_2_1432"
              class="form-control"
              bindLabel="name"
              placeholder=" Additional Lic Type "
              bindValue="id"
              formControlName="add_2_1432"
            />
          </td>
          <td colspan="2">
            <label>Number</label>
            <input
              id="add_2_999"
              class="form-control"
              bindLabel="name"
              placeholder="Additional License Num"
              bindValue="id"
              formControlName="add_2_999"
            />
          </td>
          <td colspan="2">
            <label>Licensing Entity</label>
            <input
              id="add_2_1920"
              class="form-control"
              bindLabel="name"
              placeholder="Additional Lic Entity "
              bindValue="id"
              formControlName="add_2_1920"
            />
          </td>
          <td colspan="2">
            <label for="add_2_1003">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Issue"
                ngbDatepicker
                #dadd_2_1003="ngbDatepicker"
                id="add_2_1003"
                class="form-control"
                formControlName="add_2_1003"
                (blur)="clearInputIfInvalid('add_2_1003')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_2_1003.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_2_1003').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1003').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1003').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1003').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1003').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_2_1004">Date Expires </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Exp "
                ngbDatepicker
                #dadd_2_1004="ngbDatepicker"
                id="add_2_1004"
                class="form-control"
                formControlName="add_2_1004"
                (blur)="clearInputIfInvalid('add_2_1004')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_2_1004.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_2_1004').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1004').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1004').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1004').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1004').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="additionalLicenseStateData"
              id="add_3_1010"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_3_1010"
            >
            </ng-select>
          </td>
          <td colspan="2">
            <label>Type</label>
            <input
              id="add_3_1432"
              class="form-control"
              bindLabel="name"
              placeholder=" Additional Lic Type "
              bindValue="id"
              formControlName="add_3_1432"
            />
          </td>
          <td colspan="2">
            <label>Number</label>
            <input
              id="add_3_999"
              class="form-control"
              bindLabel="name"
              placeholder="Additional License Num "
              bindValue="id"
              formControlName="add_3_999"
            />
          </td>
          <td colspan="2">
            <label>Licensing Entity</label>
            <input
              id="add_3_1920"
              class="form-control"
              bindLabel="name"
              placeholder="Additional Lic Entity "
              bindValue="id"
              formControlName="add_3_1920"
            />
          </td>
          <td colspan="2">
            <label for="add_3_1003">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Issue"
                ngbDatepicker
                #dadd_3_1003="ngbDatepicker"
                id="add_3_1003"
                class="form-control"
                formControlName="add_3_1003"
                (blur)="clearInputIfInvalid('add_3_1003')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_3_1003.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_3_1003').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1003').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1003').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1003').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1003').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_3_1004">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add Lic Exp "
                ngbDatepicker
                #dadd_3_1004="ngbDatepicker"
                id="add_3_1004"
                class="form-control"
                formControlName="add_3_1004"
                (blur)="clearInputIfInvalid('add_3_1004')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_3_1004.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_3_1004').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1004').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1004').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1004').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1004').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">
                  X. DRUG ENFORCEMENT ADMINISTRATION INFORMATION (DEA)
                </h6>
              </div>
              <div class="col-md-2 mt-2">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_04"
                  id="cmd_NA_04"
                  formControlName="cmd_NA_04"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_04')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>Federal DEA Registration Number</label>
            <input
              id="309"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_04', 309)"
              bindLabel="name"
              placeholder="DEA Number  "
              bindValue="id"
              formControlName="309"
              [class.is-invalid]="FlagShipForms.controls['309'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['309'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="states"
              id="312"
              (blur)="handleNAchange('cmd_NA_04', 312)"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="312"
              [class.is-invalid]="FlagShipForms.controls['312'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['312'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label>DEA Schedules</label>
            <input
              id="2923"
              (blur)="handleNAchange('cmd_NA_04', 2923)"
              class="form-control"
              bindLabel="name"
              placeholder="DEA Schedules  "
              bindValue="id"
              formControlName="2923"
              [class.is-invalid]="FlagShipForms.controls['2923'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['2923'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label for="310">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                (blur)="handleNAchange('cmd_NA_04', 310)"
                (blur)="clearInputIfInvalid('310','cmd_NA_04')"
                placeholder="DEA Issue Date "
                ngbDatepicker
                #d310="ngbDatepicker"
                id="310"
                class="form-control"
                formControlName="310"
                [class.is-invalid]="FlagShipForms.controls['310'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d310.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['310'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['310'].invalid && (FlagShipForms.controls['310'].value==''|| FlagShipForms.controls['310'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('310').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('310').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('310').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('310').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('310').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="311">Date Expires </label>
            <div class="input-group">
              <input
                type="text"
                (blur)="handleNAchange('cmd_NA_04', 311)"
                (blur)="clearInputIfInvalid('311','cmd_NA_04')"
                placeholder="DEA Exp Date "
                ngbDatepicker
                #d311="ngbDatepicker"
                id="311"
                class="form-control"
                formControlName="311"
                [class.is-invalid]="FlagShipForms.controls['311'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d311.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div class="mandatory" *ngIf="FlagShipForms.controls['311'].invalid && (FlagShipForms.controls['311'].value==''|| FlagShipForms.controls['311'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('311').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('311').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('311').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('311').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('311').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['311'].invalid">
            <small >
              required
            </small>
            </div> -->
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>Federal DEA Registration Number</label>
            <input
              id="314"
              class="form-control"
              bindLabel="name"
              placeholder="DEA 2 Number  "
              bindValue="id"
              formControlName="314"
            />
          </td>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="Dea2States"
              id="908"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="908"
            >
            </ng-select>
          </td>
          <td colspan="4">
            <label>DEA Schedules</label>
            <input
              id="2924"
              class="form-control"
              bindLabel="name"
              placeholder="DEA 2 Schedules  "
              bindValue="id"
              formControlName="2924"
            />
          </td>
          <td colspan="2">
            <label for="315">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="DEA  2 Issue  "
                ngbDatepicker
                #d315="ngbDatepicker"
                id="315"
                class="form-control"
                formControlName="315"
                (blur)="clearInputIfInvalid('315')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d315.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('315').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('315').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('315').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('315').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('315').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="316">Date Expires </label>
            <div class="input-group">
              <input
                type="text"
                placeholder=" Alt DEA Exp  "
                ngbDatepicker
                #d316="ngbDatepicker"
                id="316"
                class="form-control"
                formControlName="316"
                (blur)="clearInputIfInvalid('316')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d316.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('316').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('316').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('316').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('316').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('316').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>Federal DEA Registration Number</label>
            <input
              id="add_1_1006"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Number   "
              bindValue="id"
              formControlName="add_1_1006"
            />
          </td>
          <td colspan="2">
            <label>State</label>
            <ng-select
              [items]="AddDeaStates"
              id="add_1_1009"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_1_1009"
            >
            </ng-select>
          </td>
          <td colspan="4">
            <label>DEA Schedules  </label>
            <input
              id="add_1_2925"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Schedules   "
              bindValue="id"
              formControlName="add_1_2925"
            />
          </td>
          <td colspan="2">
            <label for="add_1_1007">Date Issued </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add DEA Issue  "
                ngbDatepicker
                #dadd_1_1007="ngbDatepicker"
                id="add_1_1007"
                class="form-control"
                formControlName="add_1_1007"
                (blur)="clearInputIfInvalid('add_1_1007')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_1_1007.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_1_1007').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1007').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1007').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1007').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1007').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_1_1008">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder=" Add DEA Exp  "
                ngbDatepicker
                #dadd_1_1008="ngbDatepicker"
                id="add_1_1008"
                class="form-control"
                formControlName="add_1_1008"
                (blur)="clearInputIfInvalid('add_1_1008')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_1_1008.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_1_1008').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1008').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1008').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1008').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_1_1008').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>Federal DEA Registration Number  </label>
            <input
              id="add_2_1006"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Number   "
              bindValue="id"
              formControlName="add_2_1006"
            />
          </td>
          <td colspan="2">
            <label>State  </label>
            <ng-select
              [items]="AddDeaStates"
              id="add_2_1009"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_2_1009"
            >
            </ng-select>
          </td>
          <td colspan="4">
            <label>DEA Schedules  </label>
            <input
              id="add_2_2925"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Schedules   "
              bindValue="id"
              formControlName="add_2_2925"
            />
          </td>
          <td colspan="2">
            <label for="add_2_1007">Date Issued   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add DEA Issue  "
                ngbDatepicker
                #dadd_2_1007="ngbDatepicker"
                id="add_2_1007"
                class="form-control"
                formControlName="add_2_1007"
                (blur)="clearInputIfInvalid('add_2_1007')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_2_1007.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_2_1007').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1007').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1007').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1007').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1007').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_2_1008">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder=" Add DEA Exp  "
                ngbDatepicker
                #dadd_2_1008="ngbDatepicker"
                id="add_2_1008"
                class="form-control"
                formControlName="add_2_1008"
                (blur)="clearInputIfInvalid('add_2_1008')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_2_1008.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_2_1008').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1008').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1008').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1008').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_2_1008').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>Federal DEA Registration Number  </label>
            <input
              id="add_3_1006"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Number   "
              bindValue="id"
              formControlName="add_3_1006"
            />
          </td>
          <td colspan="2">
            <label>State  </label>
            <ng-select
              [items]="AddDeaStates"
              id="add_3_1009"
              bindLabel="name"
              placeholder="State"
              bindValue="id"
              formControlName="add_3_1009"
            >
            </ng-select>
          </td>
          <td colspan="4">
            <label>DEA Schedules  </label>
            <input
              id="add_3_2925"
              class="form-control"
              bindLabel="name"
              placeholder="Additional DEA Schedules   "
              bindValue="id"
              formControlName="add_3_2925"
            />
          </td>
          <td colspan="2">
            <label for="add_3_1007">Date Issued   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add DEA Issue  "
                ngbDatepicker
                #dadd_3_1007="ngbDatepicker"
                id="add_3_1007"
                class="form-control"
                formControlName="add_3_1007"
                (blur)="clearInputIfInvalid('add_3_1007')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_3_1007.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_3_1007').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1007').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1007').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1007').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1007').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="add_3_1008">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder=" Add DEA Exp  "
                ngbDatepicker
                #dadd_3_1008="ngbDatepicker"
                id="add_3_1008"
                class="form-control"
                formControlName="add_3_1008"
                (blur)="clearInputIfInvalid('add_3_1008')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="dadd_3_1008.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('add_3_1008').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1008').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1008').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1008').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('add_3_1008').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
 
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">XI.CDS License</h6>
              </div>
              <div class="col-md-2 mt-3">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_05"
                  id="cmd_NA_05"
                  formControlName="cmd_NA_05"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_05')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label for="996">State  </label>
            <ng-select
              [items]="CdsStates"
              id="996"
              bindLabel="name"
              placeholder="CDS State "
              bindValue="id"
              formControlName="996"
              (blur)="handleNAchange('cmd_NA_05', 996)"
              [class.is-invalid]="FlagShipForms.controls['996'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['996'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="6">
            <label>CDS Number  </label>
            <input
              id="337"
              (blur)="handleNAchange('cmd_NA_05', 337)"
              class="form-control"
              bindLabel="name"
              placeholder="State Controlled Substance Number (CDS)"
              bindValue="id"
              formControlName="337"
              [class.is-invalid]="FlagShipForms.controls['337'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['337'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label for="338">Issue Date   </label>
            <div class="input-group">
              <input
                type="text"
                (blur)="handleNAchange('cmd_NA_05', 338)"
                (blur)="clearInputIfInvalid('338','cmd_NA_05')"
                placeholder="CDS Issue Date "
                ngbDatepicker
                #d338="ngbDatepicker"
                id="338"
                class="form-control"
                formControlName="338"
                [class.is-invalid]="FlagShipForms.controls['338'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d338.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['338'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['338'].invalid && (FlagShipForms.controls['338'].value==''|| FlagShipForms.controls['338'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('338').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('338').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('338').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('338').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('338').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="339">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                (blur)="handleNAchange('cmd_NA_05', 339)"
                (blur)="clearInputIfInvalid('339','cmd_NA_05')"
                placeholder="CDS Exp Date  "
                ngbDatepicker
                #d339="ngbDatepicker"
                id="339"
                class="form-control"
                formControlName="339"
                [class.is-invalid]="FlagShipForms.controls['339'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d339.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['339'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['339'].invalid && (FlagShipForms.controls['339'].value==''|| FlagShipForms.controls['339'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('339').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('339').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('339').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('339').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('339').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label for="997">State  </label>
            <ng-select
              [items]="AltCdsStates"
              id="997"
              bindLabel="name"
              placeholder=" Alt CDS State "
              bindValue="id"
              formControlName="997"
            >
            </ng-select>
          </td>
          <td colspan="6">
            <label>CDS Number  </label>
            <input
              id="340"
              class="form-control"
              bindLabel="name"
              placeholder="Alt CDS Number "
              bindValue="id"
              formControlName="340"
            />
          </td>
          <td colspan="2">
            <label for="341">Issue Date   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Alt CDS Issue Date "
                ngbDatepicker
                #d341="ngbDatepicker"
                id="341"
                class="form-control"
                formControlName="341"
                (blur)="clearInputIfInvalid('341')"

              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d341.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('341').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('341').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('341').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('341').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('341').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="342">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Alt CDS Exp Date"
                ngbDatepicker
                #d342="ngbDatepicker"
                id="342"
                class="form-control"
                formControlName="342"
                (blur)="clearInputIfInvalid('342')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d342.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('342').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('342').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('342').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('342').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('342').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State  </label>
            <ng-select
              [items]="AddCdsStates"
              id="2260"
              bindLabel="name"
              placeholder=" Additional 1 CDS State  "
              bindValue="id"
              formControlName="2260"
            >
            </ng-select>
          </td>
          <td colspan="6">
            <label>CDS Number  </label>
            <input
              id="2254"
              class="form-control"
              bindLabel="name"
              placeholder="Additional 1 CDS Number  "
              bindValue="id"
              formControlName="2254"
            />
          </td>
          <td colspan="2">
            <label for="2255">Issue Date   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add1 CDS Issue "
                ngbDatepicker
                #d2255="ngbDatepicker"
                id="2255"
                class="form-control"
                formControlName="2255"
                (blur)="clearInputIfInvalid('2255')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2255.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('2255').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('2255').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2255').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2255').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2255').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="2256">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add1 CDS Exp"
                ngbDatepicker
                #d2256="ngbDatepicker"
                id="2256"
                class="form-control"
                formControlName="2256"
                (blur)="clearInputIfInvalid('2256')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2256.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('2256').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('2256').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2256').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2256').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2256').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label>State  </label>
            <ng-select
              [items]="Add2CdsStates"
              id="2261"
              bindLabel="name"
              placeholder=" Additional 2 CDS State  "
              bindValue="id"
              formControlName="2261"
            >
            </ng-select>
          </td>
          <td colspan="6">
            <label>CDS Number  </label>
            <input
              id="2257"
              class="form-control"
              bindLabel="name"
              placeholder="Additional 2 CDS Number  "
              bindValue="id"
              formControlName="2257"
            />
          </td>
          <td colspan="2">
            <label for="2258">Issue Date   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add2 CDS Issue "
                ngbDatepicker
                #d2258="ngbDatepicker"
                id="2258"
                class="form-control"
                formControlName="2258"
                (blur)="clearInputIfInvalid('2258')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2258.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('2258').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('2258').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2258').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2258').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2258').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="2259">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add2 CDS Exp"
                ngbDatepicker
                #d2259="ngbDatepicker"
                id="2259"
                class="form-control"
                formControlName="2259"
                (blur)="clearInputIfInvalid('2259')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d2259.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('2259').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('2259').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2259').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2259').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('2259').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class="  ms-3 mt-3 mb-3">XII.REFERENCES</h6>
              </div>
              <div class="col-md-2 mt-3">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_06"
                  id="cmd_NA_06"
                  formControlName="cmd_NA_06"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_06')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <label>Name of Reference 1    </label>
            <input
              id="359"
              (blur)="handleNAchange('cmd_NA_06', 359)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 1 Name"
              bindValue="id"
              formControlName="359"
              [class.is-invalid]="FlagShipForms.controls['359'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['359'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label>Phone   </label>
            <input
              id="369"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_06', 369)"
              bindLabel="name"
              placeholder="Reference 1 Phone "
              bindValue="id"
              formControlName="369"
              [class.is-invalid]="FlagShipForms.controls['369'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['369'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['369'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="5">
            <label>Email   </label>
            <input
              id="371"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_06', 371)"
              bindLabel="name"
              placeholder="Reference 1 Email "
              bindValue="id"
              formControlName="371"
              [class.is-invalid]="FlagShipForms.controls['371'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['371'].errors?.pattern">
              Only Email pattern is allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['371'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <label>Name of Reference 2 </label>
            <input
              id="372"
              (blur)="handleNAchange('cmd_NA_06', 372)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 2 Name"
              bindValue="id"
              formControlName="372"
              [class.is-invalid]="FlagShipForms.controls['372'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['372'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label>Phone   </label>
            <input
              id="381"
              (blur)="handleNAchange('cmd_NA_06', 381)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 2 Phone "
              bindValue="id"
              formControlName="381"
              [class.is-invalid]="FlagShipForms.controls['381'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['381'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['381'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="5">
            <label>Email   </label>
            <input
              id="383"
              (blur)="handleNAchange('cmd_NA_06', 383)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 2 Email "
              bindValue="id"
              formControlName="383"
              [class.is-invalid]="FlagShipForms.controls['383'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['383'].errors?.pattern">
              Only Email pattern is allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['383'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <label>  Name of Reference 3   </label>
            <input
              id="384"
              class="form-control"
              (blur)="handleNAchange('cmd_NA_06', 384)"
              bindLabel="name"
              placeholder="Reference 3 Name"
              bindValue="id"
              formControlName="384"
              [class.is-invalid]="FlagShipForms.controls['384'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['384'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="4">
            <label>Phone   </label>
            <input
              id="394"
              (blur)="handleNAchange('cmd_NA_06', 394)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 3 Phone "
              bindValue="id"
              formControlName="394"
              [class.is-invalid]="FlagShipForms.controls['394'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['394'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['394'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="5">
            <label>Email   </label>
            <input
              id="396"
              (blur)="handleNAchange('cmd_NA_06', 396)"
              class="form-control"
              bindLabel="name"
              placeholder="Reference 3 Email "
              bindValue="id"
              formControlName="396"
              [class.is-invalid]="FlagShipForms.controls['396'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['396'].errors?.pattern">
              Only Email pattern is allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['396'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class="  ms-3 mt-3 mb-3">XIII.SPECIALTY BOARD CERTIFICATIONS</h6>
              </div>
              <div class="col-md-2 mt-3">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_07"
                  id="cmd_NA_07"
                  formControlName="cmd_NA_07"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_07')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <h6>
              <b class="al "
                >List full name of medical/dental boards by which you are
                certified.</b>
              
            </h6>
            </td>
        </tr>
        <tr>
          <td colspan="6">
            <label>Board  </label>
            <ng-select
              [items]="boardCertification"
              id="554"
              (blur)="handleNAchange('cmd_NA_07', 554)"
              bindLabel="name"
              placeholder="Primary Board Certification  "
              bindValue="id"
              formControlName="554"
              [class.is-invalid]="FlagShipForms.controls['554'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['554'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label for="557">Date Certified   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Pri Board Issue "
                (blur)="handleNAchange('cmd_NA_07', 557)"
                (blur)="clearInputIfInvalid('557','cmd_NA_07')"
                ngbDatepicker
                #d557="ngbDatepicker"
                id="557"
                class="form-control"
                formControlName="557"
                [class.is-invalid]="FlagShipForms.controls['557'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d557.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['557'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['557'].invalid && (FlagShipForms.controls['557'].value==''|| FlagShipForms.controls['557'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('557').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('557').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('557').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('557').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('557').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="560">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Pri Board Exp "
                (blur)="handleNAchange('cmd_NA_07', 560)"
                (blur)="clearInputIfInvalid('560','cmd_NA_07')"
                ngbDatepicker
                #d560="ngbDatepicker"
                id="560"
                class="form-control"
                formControlName="560"
                [class.is-invalid]="FlagShipForms.controls['560'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d560.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['560'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['560'].invalid && (FlagShipForms.controls['560'].value==''|| FlagShipForms.controls['560'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('560').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('560').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('560').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('560').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('560').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label>Board  </label>
            <ng-select
              [items]="boardCertification"
              id="561"
              bindLabel="name"
              placeholder="Second Board Certification  "
              bindValue="id"
              formControlName="561"
            >
            </ng-select>
          </td>
          <td colspan="3">
            <label for="564">Date Certified   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Sec Board Issue "
                ngbDatepicker
                #d564="ngbDatepicker"
                id="564"
                class="form-control"
                formControlName="564"
                (blur)="clearInputIfInvalid('564')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d564.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('564').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('564').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('564').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('564').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('564').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="567">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Sec Board Exp"
                ngbDatepicker
                #d567="ngbDatepicker"
                id="567"
                class="form-control"
                formControlName="567"
                (blur)="clearInputIfInvalid('567')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d567.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('567').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('567').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('567').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('567').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('567').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label>Board  </label>
            <ng-select
              [items]="boardCertification"
              id="946"
              bindLabel="name"
              placeholder="Third Board Certification "
              bindValue="id"
              formControlName="946"
            >
            </ng-select>
          </td>
          <td colspan="3">
            <label for="953">Date Certified   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Third Board Issue"
                ngbDatepicker
                #d953="ngbDatepicker"
                id="953"
                class="form-control"
                formControlName="953"
                (blur)="clearInputIfInvalid('953')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d953.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('953').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('953').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('953').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('953').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('953').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="3">
            <label for="954">Date Expires   </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Third Board Exp"
                ngbDatepicker
                #d954="ngbDatepicker"
                id="954"
                class="form-control"
                formControlName="954"
                (blur)="clearInputIfInvalid('954')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d954.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('954').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('954').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('954').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('954').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('954').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="12">
            <h6>
              <b   class="al "
                >If you have applied to a specialty board for examination, give the name of the board and the date of the  application. 
                </b
              >
           
              </h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label>Oral  </label>
            <input
              id="cmd_017"
              class="form-control"
              bindLabel="name"
              placeholder="  "
              bindValue="id"
              formControlName="cmd_017"
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label>Written  </label>
            <input
              id="cmd_018"
              class="form-control"
              bindLabel="name"
              placeholder="  "
              bindValue="id"
              formControlName="cmd_018"
            />
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="row">
              <div class="col-md-10">
                <h6>
                  <b class="al boldTextSize"
                    >If status is one of eligibility, year when eligibility status will terminate under rules of specific board:
              </b>  </h6>
          
              </div>
              <div class="col-md-2">
                <input
                  id="cmd_019"
                  class="form-control "
                  bindLabel="name"
                  placeholder="  "
                  bindValue="id"
                  formControlName="cmd_019"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <h6 class=" ms-3 mt-3 mb-3">XIV.HEALTH STATUS</h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="ms-3">
              <h6>
                <b class="al "
                  >If any of the following questions are answered in the
                  affirmative, please upload copy of full history
                  to Credential My Doc </b> </h6>
          
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al boldTextSize">
                    1. Do you presently have a physical or mental health
                    condition, including alcohol
                    or drug dependence, that affects or that may reasonably
                    progress within the
                    next two-year period to the point of affecting your ability
                    to perform
                    professional or medical staff duties appropriately?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_020"
                      name="cmd_020"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_020'].touched &&
                          FlagShipForms.controls['cmd_020'].invalid) ||
                        (FlagShipForms.controls['cmd_020'].invalid && isSubmit)
                      "
                      formControlName="cmd_020"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_020"
                      name="cmd_020"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_020'].touched &&
                          FlagShipForms.controls['cmd_020'].invalid) ||
                        (FlagShipForms.controls['cmd_020'].invalid && isSubmit)
                      "
                      formControlName="cmd_020"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_020'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_020'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    2. Have you ever had any such condition in the past that is
                    now resolved without the
                    need for continuing therapy or medication?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_021'].touched &&
                          FlagShipForms.controls['cmd_021'].invalid) ||
                        (FlagShipForms.controls['cmd_021'].invalid && isSubmit)
                      "
                      formControlName="cmd_021"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_021'].touched &&
                          FlagShipForms.controls['cmd_021'].invalid) ||
                        (FlagShipForms.controls['cmd_021'].invalid && isSubmit)
                      "
                      formControlName="cmd_021"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_021'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_021'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    3. Are you currently taking medication or under therapy for
                    a condition that could
                    affect your ability to perform professional or medical staff
                    duties if the
                    medication/therapy were discontinued today?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_022"
                      name="cmd_022"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_022'].touched &&
                          FlagShipForms.controls['cmd_022'].invalid) ||
                        (FlagShipForms.controls['cmd_022'].invalid && isSubmit)
                      "
                      formControlName="cmd_022"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_022"
                      name="cmd_022"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_022'].touched &&
                          FlagShipForms.controls['cmd_022'].invalid) ||
                        (FlagShipForms.controls['cmd_022'].invalid && isSubmit)
                      "
                      formControlName="cmd_022"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_022'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_022'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    4. Have you at any time during the last ten years been
                    hospitalized for a physical or
                    mental condition, including alcohol or drug dependence, or
                    received any
                    other type of institutional care for a health problem?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_023"
                      name="cmd_023"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_023'].touched &&
                          FlagShipForms.controls['cmd_023'].invalid) ||
                        (FlagShipForms.controls['cmd_023'].invalid && isSubmit)
                      "
                      formControlName="cmd_023"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_023"
                      name="cmd_023"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_023'].touched &&
                          FlagShipForms.controls['cmd_023'].invalid) ||
                        (FlagShipForms.controls['cmd_023'].invalid && isSubmit)
                      "
                      formControlName="cmd_023"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_023'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_023'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    5. Has any hospital, medical society, or professional
                    organization ever requested or
                    required you to be evaluated for an alleged physical or
                    mental condition,
                    alcoholism, drug abuse, or dependency?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_024"
                      name="cmd_024"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_024'].touched &&
                          FlagShipForms.controls['cmd_024'].invalid) ||
                        (FlagShipForms.controls['cmd_024'].invalid && isSubmit)
                      "
                      formControlName="cmd_024"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label   class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_024"
                      name="cmd_024"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_024'].touched &&
                          FlagShipForms.controls['cmd_024'].invalid) ||
                        (FlagShipForms.controls['cmd_024'].invalid && isSubmit)
                      "
                      formControlName="cmd_024"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_024'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_024'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    6. Date of most recent physical examination (Must be within
                    the past two years.)
                  </h6>
                </div>
                <div class="col-md-2">
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder=" "
                      ngbDatepicker
                      #dcmd_025="ngbDatepicker"
                      id="cmd_025"
                      class="form-control"
                      formControlName="cmd_025"
                      (blur)="clearInputIfInvalidMandi('cmd_025')"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_025'].touched &&
                          FlagShipForms.controls['cmd_025'].invalid) ||
                        (FlagShipForms.controls['cmd_025'].invalid && isSubmit)
                      "
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="dcmd_025.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="text-danger"
                    *ngIf="(FlagShipForms.controls['cmd_025'].invalid&&FlagShipForms.controls['cmd_025'].touched) && (FlagShipForms.controls['cmd_025'].value==''|| FlagShipForms.controls['cmd_025'].value==null) ">
                    <small>
                      required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="FlagShipForms.get('cmd_025').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('cmd_025').errors?.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('cmd_025').errors?.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="FlagShipForms.get('cmd_025').errors?.invalidDateRange">
                    date is out of range
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <h6 class=" ms-3 mt-3 mb-3">XV.DISCIPLINARY ACTIONS</h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="ms-3">
              <h6  class="al">
                
                  Have any of the following ever been, or are currently in the
                  process of being denied, revoked, suspended,
                  reduced, limited,placed on probation, not renewed, or voluntarily relinquished,
                  or have you ever withdrawn or failed to proceed with an
                  application for any of the following? If yes, please upload
                  copy of full history to Credential My Doc
                  
              </h6>
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    1. Medical or Dental license in any state
                  </h6>
                </div>
                <div class="col-md-2">
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_026"
                      name="cmd_026"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_026'].touched &&
                          FlagShipForms.controls['cmd_026'].invalid) ||
                        (FlagShipForms.controls['cmd_026'].invalid && isSubmit)
                      "
                      formControlName="cmd_026"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_026"
                      name="cmd_026"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_026'].touched &&
                          FlagShipForms.controls['cmd_026'].invalid) ||
                        (FlagShipForms.controls['cmd_026'].invalid && isSubmit)
                      "
                      formControlName="cmd_026"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_026'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_026'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
      
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    2. Other professional registration/license
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_027"
                      name="cmd_027"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_027'].touched &&
                          FlagShipForms.controls['cmd_027'].invalid) ||
                        (FlagShipForms.controls['cmd_027'].invalid && isSubmit)
                      "
                      formControlName="cmd_027"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_027"
                      name="cmd_027"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_027'].touched &&
                          FlagShipForms.controls['cmd_027'].invalid) ||
                        (FlagShipForms.controls['cmd_027'].invalid && isSubmit)
                      "
                      formControlName="cmd_027"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_027'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_027'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
             
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    3. DEA/Controlled Substances Registration
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_028"
                      name="cmd_028"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_028'].touched &&
                          FlagShipForms.controls['cmd_028'].invalid) ||
                        (FlagShipForms.controls['cmd_028'].invalid && isSubmit)
                      "
                      formControlName="cmd_028"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_028"
                      name="cmd_028"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_028'].touched &&
                          FlagShipForms.controls['cmd_028'].invalid) ||
                        (FlagShipForms.controls['cmd_028'].invalid && isSubmit)
                      "
                      formControlName="cmd_028"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_028'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_028'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
             
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">4. Academic Appointment</h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_029"
                      name="cmd_029"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_029'].touched &&
                          FlagShipForms.controls['cmd_029'].invalid) ||
                        (FlagShipForms.controls['cmd_029'].invalid && isSubmit)
                      "
                      formControlName="cmd_029"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_029"
                      name="cmd_029"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_029'].touched &&
                          FlagShipForms.controls['cmd_029'].invalid) ||
                        (FlagShipForms.controls['cmd_029'].invalid && isSubmit)
                      "
                      formControlName="cmd_029"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_029'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_029'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
             
              <div class="row mt-3 ">
                <div class="col-md-10">
                  <h6 class="al">
                    5.Membership on any hospital medical staff
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_030"
                      name="cmd_030"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_030'].touched &&
                          FlagShipForms.controls['cmd_030'].invalid) ||
                        (FlagShipForms.controls['cmd_030'].invalid && isSubmit)
                      "
                      formControlName="cmd_030"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_030"
                      name="cmd_030"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_030'].touched &&
                          FlagShipForms.controls['cmd_030'].invalid) ||
                        (FlagShipForms.controls['cmd_030'].invalid && isSubmit)
                      "
                      formControlName="cmd_030"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_030'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_030'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
             
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">6. Clinical privileges</h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_031"
                      name="cmd_031"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_031'].touched &&
                          FlagShipForms.controls['cmd_031'].invalid) ||
                        (FlagShipForms.controls['cmd_031'].invalid && isSubmit)
                      "
                      formControlName="cmd_031"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_031"
                      name="cmd_031"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_031'].touched &&
                          FlagShipForms.controls['cmd_031'].invalid) ||
                        (FlagShipForms.controls['cmd_031'].invalid && isSubmit)
                      "
                      formControlName="cmd_031"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_031'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_031'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
          
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    7. Prerogatives/rights on any medical staff
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_032'].touched &&
                          FlagShipForms.controls['cmd_032'].invalid) ||
                        (FlagShipForms.controls['cmd_032'].invalid && isSubmit)
                      "
                      formControlName="cmd_032"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_032"
                      name="cmd_032"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_032'].touched &&
                          FlagShipForms.controls['cmd_032'].invalid) ||
                        (FlagShipForms.controls['cmd_032'].invalid && isSubmit)
                      "
                      formControlName="cmd_032"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_032'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_032'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
           
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    8. Other institutional affiliations or status
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_033'].touched &&
                          FlagShipForms.controls['cmd_033'].invalid) ||
                        (FlagShipForms.controls['cmd_033'].invalid && isSubmit)
                      "
                      formControlName="cmd_033"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_033"
                      name="cmd_033"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_033'].touched &&
                          FlagShipForms.controls['cmd_033'].invalid) ||
                        (FlagShipForms.controls['cmd_033'].invalid && isSubmit)
                      "
                      formControlName="cmd_033"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_033'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_033'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
      
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    9. Professional society membership, fellowship, or specialty
                    boards
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_034'].touched &&
                          FlagShipForms.controls['cmd_034'].invalid) ||
                        (FlagShipForms.controls['cmd_034'].invalid && isSubmit)
                      "
                      formControlName="cmd_034"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_034"
                      name="cmd_034"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_034'].touched &&
                          FlagShipForms.controls['cmd_034'].invalid) ||
                        (FlagShipForms.controls['cmd_034'].invalid && isSubmit)
                      "
                      formControlName="cmd_034"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_034'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_034'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
            
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    10. Any other type of professional sanction
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_035'].touched &&
                          FlagShipForms.controls['cmd_035'].invalid) ||
                        (FlagShipForms.controls['cmd_035'].invalid && isSubmit)
                      "
                      formControlName="cmd_035"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_035"
                      name="cmd_035"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_035'].touched &&
                          FlagShipForms.controls['cmd_035'].invalid) ||
                        (FlagShipForms.controls['cmd_035'].invalid && isSubmit)
                      "
                      formControlName="cmd_035"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_035'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_035'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
           
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    11. Have there ever been any criminal charges (Other than
                    motor vehicle violations) brought against you?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_036"
                      name="cmd_036"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_036'].touched &&
                          FlagShipForms.controls['cmd_036'].invalid) ||
                        (FlagShipForms.controls['cmd_036'].invalid && isSubmit)
                      "
                      formControlName="cmd_036"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_036"
                      name="cmd_036"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_036'].touched &&
                          FlagShipForms.controls['cmd_036'].invalid) ||
                        (FlagShipForms.controls['cmd_036'].invalid && isSubmit)
                      "
                      formControlName="cmd_036"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_036'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_036'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
           
              <div class="row mt-3">
                <div class="col-md-10">
                  <h6 class="al">
                    12. Have there ever been any sanctions by Medicare/Medicaid
                    against you?
                  </h6>
                </div>
                <div class="col-md-2">
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_037"
                      name="cmd_037"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_037'].touched &&
                          FlagShipForms.controls['cmd_037'].invalid) ||
                        (FlagShipForms.controls['cmd_037'].invalid && isSubmit)
                      "
                      formControlName="cmd_037"
                      value="YES"
                    /><b>YES</b></label
                  >
                  <label  class="checkbox-inline al">
                    <input
                      type="radio"
                      id="cmd_037"
                      name="cmd_037"
                      [class.is-invalid]="
                        (FlagShipForms.controls['cmd_037'].touched &&
                          FlagShipForms.controls['cmd_037'].invalid) ||
                        (FlagShipForms.controls['cmd_037'].invalid && isSubmit)
                      "
                      formControlName="cmd_037"
                      value="NO"
                    /><b>NO</b></label
                  >
                  <div
                    *ngIf="
                      FlagShipForms.controls['cmd_037'].invalid && isSubmit
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="FlagShipForms.controls['cmd_037'].errors"
                    >
                      Please select YES or NO
                    </small>
                  </div>
                </div>
              </div>
           
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="bg">
            <div class="row">
              <div class="col-md-10">
                <h6 class=" ms-3 mt-3 mb-3">XVI.PROFESSIONAL LIABILITY INSURANCE</h6>
              </div>
              <div class="col-md-2 mt-3">
                <input
                  type="checkbox"
                  style="height:17px;width:15px;"
                  name="cmd_NA_08"
                  id="cmd_NA_08"
                  formControlName="cmd_NA_08"
                  value="1"
                  (ngModelChange)="handleNAchange('cmd_NA_08')"
                /><b>Does Not Apply</b>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label>Name of Carrier   </label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_08', 345)"
              id="345"
              class="form-control"
              bindLabel="name"
              placeholder="MLP Carrier Name "
              bindValue="id"
              formControlName="345"
              [class.is-invalid]="FlagShipForms.controls['345'].invalid"
            />

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['345'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label>Amount of Coverage Per Claim  </label>
            <ng-select
              [items]="preClaim"
              (blur)="handleNAchange('cmd_NA_08', 350)"
              id="350"
              bindLabel="name"
              placeholder="Per Claim  "
              bindValue="id"
              formControlName="350"
              [class.is-invalid]="FlagShipForms.controls['350'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['350'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label>Amount of Coverage Aggregate  </label>
            <ng-select
              [items]="aggregates"
              (blur)="handleNAchange('cmd_NA_08', 351)"
              id="351"
              bindLabel="name"
              placeholder="Aggregate  "
              bindValue="id"
              formControlName="351"
              [class.is-invalid]="FlagShipForms.controls['351'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['351'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="901">Address  </label>
            <div class="row">
              <div class="col-md-6">
                <input
                type="text"
                (blur)="handleNAchange('cmd_NA_08', 901)"
                class="form-control"
                id="901"
                formControlName="901"
                placeholder="MLP Address 1"
                [class.is-invalid]="FlagShipForms.controls['901'].invalid"
              />
              <div class="mandatory"
              *ngIf="FlagShipForms.controls['901'].invalid">
              <small >
                required
              </small>
              </div>
              </div>
              <div class="col-md-6">
                <input type=text formControlName="902" id="902" placeholder="MLP Address 2" class="form-control" />
              </div>
            </div>
           
            
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="903">City  </label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_08', 903)"
              class="form-control"
              id="903"
              formControlName="903"
              placeholder="MLP City "
              [class.is-invalid]="FlagShipForms.controls['903'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['903'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label>State  </label>
            <ng-select
              [items]="states"
              (blur)="handleNAchange('cmd_NA_08', 905)"
              id="905"
              bindLabel="name"
              placeholder="State or Country "
              bindValue="id"
              formControlName="905"
              [class.is-invalid]="FlagShipForms.controls['905'].invalid"
            >
            </ng-select>

            <div class="mandatory"
            *ngIf="FlagShipForms.controls['905'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="3">
            <label for="906">Zip Code  </label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_08', 906)"
              class="form-control"
              id="906"
              formControlName="906"
              placeholder="MLP Zip "
              [class.is-invalid]="FlagShipForms.controls['906'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['906'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="346">Policy Number  </label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_08', 346)"
              class="form-control"
              id="346"
              formControlName="346"
              placeholder="MLP Policy Number "
              [class.is-invalid]="FlagShipForms.controls['346'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['346'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="2">
            <label for="348">Issue Date  </label>
            <div class="input-group">
              <input
                type="text"
                (blur)="handleNAchange('cmd_NA_08', 348)"
                (blur)="clearInputIfInvalid('348','cmd_NA_08')"
                placeholder="MLP Issue Date "
                ngbDatepicker
                #d348="ngbDatepicker"
                id="348"
                class="form-control"
                formControlName="348"
                [class.is-invalid]="FlagShipForms.controls['348'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d348.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['348'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['348'].invalid && (FlagShipForms.controls['348'].value==''|| FlagShipForms.controls['348'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('348').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('348').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('348').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('348').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('348').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="349">Date Expires  </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="MLP Expiration  "
                (blur)="handleNAchange('cmd_NA_08', 349)"
                (blur)="clearInputIfInvalid('349','cmd_NA_08')"
                ngbDatepicker
                #d349="ngbDatepicker"
                id="349"
                class="form-control"
                formControlName="349"
                [class.is-invalid]="FlagShipForms.controls['349'].invalid"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d349.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- <div class="mandatory"
            *ngIf="FlagShipForms.controls['349'].invalid">
            <small >
              required
            </small>
            </div> -->
            <div class="mandatory" *ngIf="FlagShipForms.controls['349'].invalid && (FlagShipForms.controls['349'].value==''|| FlagShipForms.controls['349'].value==null) " >
              <small >
              required
              </small>
            </div>
            <div *ngIf="FlagShipForms.get('349').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('349').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('349').errors?.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('349').errors?.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('349').errors?.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1240">Agent  </label>
            <input
              type="text"
              class="form-control"
              id="1240"
              formControlName="1240"
              (blur)="handleNAchange('cmd_NA_08', 1240)"
              placeholder="MLP Agents Name  "
              [class.is-invalid]="FlagShipForms.controls['1240'].invalid"
            />
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['1240'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
          <td colspan="6">
            <label for="1266">Phone  </label>
            <input
              type="text"
              (blur)="handleNAchange('cmd_NA_08', 1266)"
              class="form-control"
              id="1266"
              formControlName="1266"
              placeholder="MLP Phone"
              [class.is-invalid]="FlagShipForms.controls['1266'].invalid"
            />
            <small class="text-danger" *ngIf="FlagShipForms.controls['1266'].errors?.pattern">
              Only 10 digits  allowed
            </small>
            <div class="mandatory"
            *ngIf="FlagShipForms.controls['1266'].invalid">
            <small >
              required
            </small>
            </div>
          </td>
      
        </tr>
        <tr>
          <td colspan="6">
            <label
              >Name of Additional Carriers in the Past 5 Years   </label
            >
            <input
              id="1509"
              class="form-control"
              bindLabel="name"
              placeholder="Additional MLP Carrier Name  "
              bindValue="id"
              formControlName="1509"
            />
          </td>
          <td colspan="3">
            <label>Amount of Coverage Per Claim  </label>
            <ng-select
              [items]="preClaim"
              id="1525"
              bindLabel="name"
              placeholder="Per Claim  "
              bindValue="id"
              formControlName="1525"
            >
            </ng-select>
          </td>
          <td colspan="3">
            <label>Amount of Coverage Aggregate  </label>
            <ng-select
              [items]="aggregates"
              id="1526"
              bindLabel="name"
              placeholder="Aggregate  "
              bindValue="id"
              formControlName="1526"
            >
            </ng-select>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label for="1511">Address  </label>
            <div class="row">
              <div class="col-md-6">
                <input
              type="text"
              class="form-control"
              id="1511"
              formControlName="1511"
              placeholder="Additional MLP Address 1"
            />
              </div>
              <div class="col-md-6">
                <input
              type="text"
              class="form-control"
              id="1512"
              formControlName="1512"
              placeholder="Additional MLP Address 2"
            />
              </div>
            </div>
            
            
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1513">City  </label>
            <input
              type="text"
              class="form-control"
              id="1513"
              formControlName="1513"
              placeholder="Additional MLP City "
            />
          </td>
          <td colspan="3">
            <label>State  </label>
            <ng-select
              [items]="states"
              id="1515"
              bindLabel="name"
              placeholder="State or Country "
              bindValue="id"
              formControlName="1515"
            >
            </ng-select>
          </td>
          <td colspan="3">
            <label for="1516">Zip Code  </label>
            <input
              type="text"
              class="form-control"
              id="1516"
              formControlName="1516"
              placeholder="Additional MLP Zip"
            />
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <label for="1520">Policy Number  </label>
            <input
              type="text"
              class="form-control"
              id="1520"
              formControlName="1520"
              placeholder="Additional MLP Policy Number "
            />
          </td>
          <td colspan="2">
            <label for="1523">Issue Date  </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add MLP Issue "
                ngbDatepicker
                #d1523="ngbDatepicker"
                id="1523"
                class="form-control"
                formControlName="1523"
                (blur)="clearInputIfInvalid('1523')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d1523.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('1523').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('1523').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1523').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1523').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1523').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
          <td colspan="2">
            <label for="1524">Date Expires  </label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Add MLP Exp"
                ngbDatepicker
                #d1524="ngbDatepicker"
                id="1524"
                class="form-control"
                formControlName="1524"
                (blur)="clearInputIfInvalid('1524')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="d1524.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div *ngIf="FlagShipForms.get('1524').invalid ">
              <small class="text-danger" *ngIf="FlagShipForms.get('1524').errors.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1524').errors.invalidMonth">
                Month is out of range
              </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1524').errors.invalidDateyear">
                year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="FlagShipForms.get('1524').errors.invalidDateRange">
                date is out of range
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <label for="1510">Agent  </label>
            <input
              type="text"
              class="form-control"
              id="1510"
              formControlName="1510"
              placeholder="Additional MLP Agents Name "
            />
          </td>
          <td colspan="6">
            <label for="1518">Phone  </label>
            <input
              type="text"
              class="form-control"
              id="1518"
              formControlName="1518"
              placeholder="Additional MLP Phone "
            />
            <div *ngIf="FlagShipForms.get('1518').invalid && FlagShipForms.get('1518').touched">
              <small class="text-danger" *ngIf="FlagShipForms.get('1518').errors.invalidPhoneFormat">
                Only 10 digits Allowed
              </small>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="row">
              <div class="col-md-10">
                <h6  class="al">
                  Have there been, or are there currently any pending
                  malpractice claims, suits, settlement, or arbitration
                  proceedings involving your practice of medicine?
                </h6>
              </div>
              <div class="col-md-2  mt-3">
                <label  class="checkbox-inline al">
                  <input
                    type="radio"
                    id="cmd_038"
                    name="cmd_038"
                    [class.is-invalid]="
                      (FlagShipForms.controls['cmd_038'].touched &&
                        FlagShipForms.controls['cmd_038'].invalid) ||
                      (FlagShipForms.controls['cmd_038'].invalid && isSubmit)
                    "
                    formControlName="cmd_038"
                    value="YES"
                  /><b>YES</b></label
                >
                <label  class="checkbox-inline al">
                  <input
                    type="radio"
                    id="cmd_038"
                    name="cmd_038"
                    [class.is-invalid]=" 
                      (FlagShipForms.controls['cmd_038'].touched &&
                        FlagShipForms.controls['cmd_038'].invalid) ||
                      (FlagShipForms.controls['cmd_038'].invalid && isSubmit)
                    "
                    formControlName="cmd_038"
                    value="NO"
                  /><b>NO</b></label
                >
                <div
                  *ngIf="FlagShipForms.controls['cmd_038'].invalid && isSubmit"
                >
                  <small
                    class="text-danger"
                    *ngIf="FlagShipForms.controls['cmd_038'].errors"
                  >
                    Please select YES or NO
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            
              <h6 class="al">
                If answer to above question is “yes,” please provide the
                following information for each situation on a separate document
                and upload to Credential My Doc
              </h6>
              <br /> 
              <h6 class="al">a. Date suit or claim was initiated</h6>
              <h6 class="al">b. Name and location of the court</h6>
              <h6 class="al">c. Names of the parties</h6>
              <h6 class="al">d. Brief description of the nature of the claim</h6>
              <h6 class="al">e. Current status or summary of how concluded</h6>
           
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="row">
              <div class="col-md-10 ">
                <h6 class="al">
                  Have you ever been denied professional liability insurance or
                  has your coverage  ever been canceled? If yes, please upload copy of full history to Credential My
                  Doc
                 
                 
                </h6>
              </div>
              <div class="col-md-2  mt-3">
                <label  class="checkbox-inline al">
                  <input
                    type="radio"
                    id="cmd_039"
                    name="cmd_039"
                    [class.is-invalid]="
                      (FlagShipForms.controls['cmd_039'].touched &&
                        FlagShipForms.controls['cmd_039'].invalid) ||
                      (FlagShipForms.controls['cmd_039'].invalid && isSubmit)
                    "
                    formControlName="cmd_039"
                    value="YES"
                  /><b>YES</b></label
                >
                <label  class="checkbox-inline al">
                  <input
                  type="radio"
                    id="cmd_039"
                    name="cmd_039"
                    [class.is-invalid]="
                      (FlagShipForms.controls['cmd_039'].touched &&
                        FlagShipForms.controls['cmd_039'].invalid) ||
                      (FlagShipForms.controls['cmd_039'].invalid && isSubmit)
                    "
                    formControlName="cmd_039"
                    value="NO"
                  /><b>NO</b></label
                >
                <div
                  *ngIf="FlagShipForms.controls['cmd_039'].invalid && isSubmit"
                >
                  <small
                    class="text-danger"
                    *ngIf="FlagShipForms.controls['cmd_039'].errors"
                  >
                    Please select YES or NO
                  </small>
                </div>
              </div>
            </div>
          </td>
        </tr>


        <tr>
          <td colspan="12" class="cl">
            <div class="text-center mt-3  mb-3 ">
              <h6><b style="font-size: 16px">Flagship Specialty Partners </b> </h6>

              <br />
              <h6><b style="font-size: 16px">Credentialing Contact </b> </h6>
              <h6>
                <b style="font-size: 16px">Jennifer Hock, Director of Contracting & Credentialing  
             </b> </h6>
              <h6><b style="font-size: 16px">Phone: 704-295-4653 </b> </h6>
              <h6><b style="font-size: 16px">Email: Credentialing@Flagshipsp.com </b>  </h6>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="12">
            <h6 class="al">
              NOTE: This page must be signed and dated to complete the
              application.
            </h6>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <label class="mt-3 mb-3 ms-3 " style="font-size: 16px"
              >“I certify that the facts contained in this application are true
              and complete to the best of
              my knowledge and understand that if granted privileges, falsified
              statements on this
              application may be grounds for suspension or revocation of
              privileges.  <br>
              <br>
              By signing this application, I release this organization from any
              liability associated with
              my application process.”  
             </label>

            <br />
            <br />
            <br />
            <div class="row ms-3">
              <div class="col-md-2">
                <label   style="font-size:16px" (click)="esignOpenOne()">Applicant Signature:</label>
              </div>
              <div class="col-md-4">
                <img
                  *ngIf="!showOne"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <img
                  *ngIf="showOne"
                  class="img eSignImageShow"
                  [src]="esignOne"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <input
                  type="hidden"
                  name="cmd_040"
                  id="cmd_040"
                  [class.is-invalid]="
                    (FlagShipForms.controls['cmd_040'].touched &&
                      FlagShipForms.controls['cmd_040'].invalid) ||
                    (FlagShipForms.controls['cmd_040'].invalid && isSubmit)
                  "
                  formControlName="cmd_040"
                  value="{{ finalImgPath }}"
                />
                <div> 
                <small>
                  Apply a manual signature or upload an esignature from PNG or JPEG formats.
                </small>
              </div>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (FlagShipForms.controls['cmd_040'].touched &&
                      FlagShipForms.controls['cmd_040'].invalid) ||
                    (FlagShipForms.controls['cmd_040'].invalid && isSubmit)
                  "
                >
                  Signature is required
                </div>
              </div>
              <div class="col-md-1">
                <label style="font-size:16px">Date:</label>
              </div>
              <div class="col-md-4 me-2">
                <div class="input-group">
                  <input
                    type="text"
                    placeholder=" "
                    ngbDatepicker
                    #dcmd_041="ngbDatepicker"
                    id="cmd_041"
                    class="form-control"
                    formControlName="cmd_041"
                    (blur)="clearInputIfInvalidMandi('cmd_041')"
                    [class.is-invalid]="
                      (FlagShipForms.controls['cmd_041'].touched &&
                        FlagShipForms.controls['cmd_041'].invalid) ||
                      (FlagShipForms.controls['cmd_041'].invalid && isSubmit)
                    "
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dcmd_041.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="text-danger"
                  *ngIf="(FlagShipForms.controls['cmd_041'].invalid&&FlagShipForms.controls['cmd_041'].touched) && (FlagShipForms.controls['cmd_041'].value==''|| FlagShipForms.controls['cmd_041'].value==null) ">
                  <small>
                    required
                  </small>
                
                </div>
                <small class="text-danger" *ngIf="FlagShipForms.get('cmd_041').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('cmd_041').errors?.invalidMonth">
                  Month is out of range
                </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('cmd_041').errors?.invalidDateyear">
                  year is out of range(1900-2099) </small>
                <small class="text-danger" *ngIf="FlagShipForms.get('cmd_041').errors?.invalidDateRange">
                  date is out of range
                </small>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>
  </div>
  <div class="modal-footer mt-4">
    <button
      *ngIf="formData.receivedForm"
      type="button"
      class="btn customOrange float-end"
      (click)="saveForm('approve')"
    >
      Approve
    </button>
    <button
      *ngIf="formData.submitForm"
      type="submit"
      class="btn btn customOrange float-end"
      (click)="saveForm('submit')"
    >
      Submit
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('save')"
    >
      Save
    </button>
    <button
      *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-end"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
