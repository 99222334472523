
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedSpringComponent } from './med-spring/med-spring.component';
import { SPHAllCountiesComponent } from './sph-all-counties/sph-all-counties.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { FormBuilderComponent } from './form-builder/form-builder.component';
// import { FormBuilderTableComponent } from './form-builder-table/form-builder-table.component';
// import { MultipleDataModalComponent } from './multiple-data-modal/multiple-data-modal.component';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { DpDatePickerModule } from 'ng2-date-picker';
// import { SignaturePadModule } from 'angular2-signaturepad';
import { EsignComponent } from './esign/esign.component';
import { SphChathamComponent } from './sph-chatham/sph-chatham.component';
import { SphDoughertyComponent } from './sph-dougherty/sph-dougherty.component';
import { GaFormComponent } from './ga-form/ga-form.component';
import { LifewaysUniversalComponent } from './lifeways-universal/lifeways-universal.component';
import { LifewaysDirectComponent } from './lifeways-direct/lifeways-direct.component';
import { KidsDentalComponent } from './kids-dental/kids-dental.component';
import { TexasComponent } from './texas/texas.component';
import { AnneDermatologyComponent } from './anne-dermatology/anne-dermatology.component';
import { ProviderCredentialingComponent } from './provider-credentialing/provider-credentialing.component';
// import { DelineationFormComponent } from './delineation-form/delineation-form.component';
// import { PeerReferenceFormComponent } from './peer-reference-form/peer-reference-form.component';
// import { LocationsFormComponent } from './locations-form/locations-form.component';
// import { CmeFormComponent } from './cme-form/cme-form.component';
// import { ViewThreadModalComponent } from './view-thread-modal/view-thread-modal.component';
import { CherokeeHealthSystemComponent } from './cherokee-health-system/cherokee-health-system.component';
import { ErieFormComponent } from './erie-form/erie-form.component';
import { SharedComponentsModule } from '../SharedComponents/shared-components.module';
import { ErieFormSection1Component } from './erie-form/erie-form-section1/erie-form-section1.component';
import { TexasSectionOneComponent } from './texas/shared/SharedForms/texas/texas-section-one/texas-section-one.component';
import { RouterModule } from '@angular/router';
import { AffiliatedDermatologyComponent } from './affiliated-dermatology/affiliated-dermatology.component';
 //import { EditPayerComponent } from './edit-payer/edit-payer.component';
import { CaliMedComponent } from './cali-med/cali-med.component';
import { CaliPhyComponent } from './cali-phy/cali-phy.component';
import { IntermountianFormComponent } from './intermountian-form/intermountian-form.component';
import { GaUnifiedComponent } from './ga-unified/ga-unified.component';
import { PcsFormComponent } from './pcs-form/pcs-form.component';
import { OnPointMedicalComponent } from './on-point-medical/on-point-medical.component';
import { WpaComponent } from './wpa/wpa.component';
import { Wpa1Component } from './wpa/wpa1/wpa1.component';
import { NewManAlliedComponent } from './newman-alliedhealth/newman-alliedhealth.component';
import { NewmanMedicalStaffComponent } from './newman-medical-staff/newman-medical-staff.component';
import { AbaBuildingBlocksComponent } from './aba-building-blocks/aba-building-blocks.component';
import { TannerClinicComponent } from './tanner-clinic/tanner-clinic.component';
import { InterMedLLCComponent } from './Intermed-llc/Intermed-llc.component';
import { InterMedLLCSection1Component } from './Intermed-llc/Intermed-llc-Section1/Intermed-llc-Section1.component';
import { WinterHealthcareProviderComponent } from './winter-healthcare-provider-form/winter-healthcare-provider.component';
import { ComprashveCareComponent } from './comprashve_care/comprashve_care.component';
import { ComprashveIntegratedComponent } from './comprashve_integrated/comprashve_integrated.component';
import { CancerCareNorthwestComponent } from './cancer-care-northwest/cancer-care-northwest.component';
import { CancerCareNorthwestComponentSection } from './cancer-care-northwest/cancer-care-northwest-section1/cancer-care-northwest-section1.component';
import { flagshipComponent } from './Flagship/flagship.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { HapCredComponent } from './HAP-CRED-MAPPING/hap-cred.component';
import { EyeHelthComponent } from './eye-health-north/eye-health-north.component';
import { DicksonMedicalAssociatesMDComponent } from './dickson-medical-associates/dickson-medical-associates-md.component';
import { DicksonMedicalAssociatesMD1Component } from './dickson-medical-associates/dicksonmd/dickson-medical-associates-md1.component';
import { DicksonMedicalAssociatesNPComponent } from './dickson-medical-associates-np-po/dickson-medical-associates-npo.component';
import { DicksonMedicalAssociatesNP1Component } from './dickson-medical-associates-np-po/dicksonnppo/dickson-medical-associates-npo1.component';
import { SageNeuroscienceComponent } from './Sage-Neuroscience/sage-neuroscience.component';
import { SignaturePadModule } from 'ngx-signaturepad'
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxDatatableModule,
        NgbModule,
        DpDatePickerModule,
        SignaturePadModule,
        SharedComponentsModule,
        RouterModule,
        NgxSpinnerModule,
        MedSpringComponent,
        SPHAllCountiesComponent,
        // FormBuilderComponent,
        // FormBuilderTableComponent,
        // MultipleDataModalComponent,
        EsignComponent,
        SphChathamComponent,
        SphDoughertyComponent,
        GaFormComponent,
        LifewaysUniversalComponent,
        LifewaysDirectComponent,
        KidsDentalComponent,
        TexasComponent,
        AnneDermatologyComponent,
        ProviderCredentialingComponent,
        // LocationsFormComponent,
        // DelineationFormComponent,
        // PeerReferenceFormComponent,
        // CmeFormComponent,
        // ViewThreadModalComponent,
        // EditPayerComponent,
        CherokeeHealthSystemComponent,
        ErieFormComponent,
        ErieFormSection1Component,
        TexasSectionOneComponent,
        AffiliatedDermatologyComponent,
        CaliMedComponent,
        CaliPhyComponent,
        IntermountianFormComponent,
        GaUnifiedComponent,
        PcsFormComponent,
        OnPointMedicalComponent,
        WpaComponent,
        Wpa1Component,
        NewManAlliedComponent,
        NewmanMedicalStaffComponent,
        AbaBuildingBlocksComponent,
        TannerClinicComponent,
        InterMedLLCComponent,
        InterMedLLCSection1Component,
        WinterHealthcareProviderComponent,
        ComprashveCareComponent,
        ComprashveIntegratedComponent,
        CancerCareNorthwestComponent,
        CancerCareNorthwestComponentSection,
        flagshipComponent,
        HapCredComponent,
        EyeHelthComponent,
        DicksonMedicalAssociatesMDComponent,
        DicksonMedicalAssociatesMD1Component,
        DicksonMedicalAssociatesNPComponent,
        DicksonMedicalAssociatesNP1Component,
        SageNeuroscienceComponent
    ],
    exports: [
        MedSpringComponent,
        SPHAllCountiesComponent,
        SphChathamComponent,
        SphDoughertyComponent,
        EsignComponent,
        GaFormComponent,
        LifewaysUniversalComponent,
        LifewaysDirectComponent,
        KidsDentalComponent,
        TexasComponent,
        AnneDermatologyComponent,
        ProviderCredentialingComponent,
        // FormBuilderComponent,
        // FormBuilderTableComponent,
        // LocationsFormComponent,
        // MultipleDataModalComponent,
         SignaturePadModule,
        // DelineationFormComponent,
        // PeerReferenceFormComponent,
        // CmeFormComponent,
        // ViewThreadModalComponent,
        CherokeeHealthSystemComponent,
        ErieFormComponent,
        ErieFormSection1Component,
        TexasSectionOneComponent,
        RouterModule,
        AffiliatedDermatologyComponent,
        // EditPayerComponent,
        CaliMedComponent,
        CaliPhyComponent,
        IntermountianFormComponent,
        GaUnifiedComponent,
        PcsFormComponent,
        OnPointMedicalComponent,
        WpaComponent,
        Wpa1Component,
        NewManAlliedComponent,
        NewmanMedicalStaffComponent,
        AbaBuildingBlocksComponent,
        TannerClinicComponent,
        InterMedLLCComponent,
        InterMedLLCSection1Component,
        WinterHealthcareProviderComponent,
        ComprashveCareComponent,
        ComprashveIntegratedComponent,
        CancerCareNorthwestComponent,
        CancerCareNorthwestComponentSection,
        flagshipComponent,
        HapCredComponent,
        EyeHelthComponent,
        DicksonMedicalAssociatesMDComponent,
        DicksonMedicalAssociatesMD1Component,
        DicksonMedicalAssociatesNPComponent,
        DicksonMedicalAssociatesNP1Component,
        SageNeuroscienceComponent
    ]
})
export class SharedModule{
  constructor(private datepickerConfig: NgbDatepickerConfig) {
    // Set your global datepicker configuration options here
    this.datepickerConfig.minDate = { year: 1900, month: 1, day: 1 };
    this.datepickerConfig.maxDate = { year: 2099, month: 12, day: 31 };
    // You can set other configuration options as needed
  }
}
