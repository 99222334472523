<div id="gaStyle">
  <div class="cotainer-fluid mx-1">
    <div class="header">
      <div class="text-center">
        <h2>
          GEORGIA UNIFORM HEALTHCARE
          <br />
          <u>PRACTITIONER</u> CREDENTIALING <br />
          APPLICATION FORM
        </h2>
        <br />
        <hr class="borderStyle" />
      </div>
      <p class="p">
        Please contact the Hospital, Health Plan or other Healthcare
        Organization, hereinafter "Healthcare Entity(ies)", to which you are
        applying for instructions on how to proceed. <br />The Healthcare Entity
        may not have adopted this form for use and/or may require a
        pre-application prior to submitting this form.
      </p>
      <div class="paddingParagraph">
        <div class="centered borderBox">
          <p class="p">
            This Application has been designed and organized into two main
            parts: Part One and Part Two.
          </p>
          <p class="p">
            Part One is standardized for Healthcare Entity(ies), and contains
            identical questions that Healthcare Entities need to ask as a part
            of their credentialing processes. Part One is available on the
            Georgia Uniform Healthcare Practitioner Credentialing Application
            Form (UHPCAF) web site at
            <a href="http://www.georgiacredentialing.org/"
              >www.georgiacredentialing.org.</a
            >
          </p>
          <p class="p">
            Part Two for health plans is standardized and contains additional
            identical questions that health plans need to ask as part of their
            credentialing processes and, is also available at
            <a href="http://www.georgiacredentialing.org/"
              >www.georgiacredentialing.org.</a
            >
          </p>
          <p class="p">
            Part Two for hospitals contains additional, customized or more
            specific questions as part of their credentialing and privileging
            processes.
          </p>
        </div>
        <div class="text-center marginStyle">
          <h4>PREPARED AND ENDORSED BYMEMBERS OF:</h4>
          <p class="text-center size">
            GHA/AN ASSOCIATION OF HOSPITALS AND HEALTH SYSTEMS
            <br />
            GEORGIA IN-HOUSE COUNSEL ASSOCIATION <br />
            GEORGIA ASSOCIATION MEDICAL STAFF SERVICES <br />
            GEORGIA ASSOCIATION OF HEALTH PLANS
            <br />
          </p>
        </div>
        <hr class="borderBlackStyle" />
      </div>
    </div>
    <div class="section">
      <form [formGroup]="gaForm">
        <div class="">
          <div class="text-center">
            <h2>
              GEORGIA UNIFORM HEALTHCARE
              <u>PRACTITIONER</u>
              <br />
              CREDENTIALING APPLICATION FORM
            </h2>
            <br />
            <p class="p">
              <u
                >Prior to completing this Application, please read and observe
                the following:</u
              >
            </p>
          </div>
          <div class="generalInstructionPaddingStyle">
            <div class="centered generalInstructionBoxStyle">
              <h3 class="generalInstructionhStyle">GENERAL INSTRUCTIONS</h3>
              <div class="generalInstructionPaddingSetStyle">
                <ul class="list-styled">
                  <li>Please type or print legibly your responses</li>
                  <li>
                    Please note that modification to the wording or format of
                    this Application will invalidate it.
                  </li>
                  <li>
                    All information requested must be FULLY and TRUTHFULLY
                    provided.
                  </li>
                  <li>
                    Any changes to your responses must be lined through and
                    initialed. Use of any form of correctional fluid or tape is
                    not acceptable.
                  </li>
                  <li>
                    If an entire section does not apply to you, then please
                    check the box provided at the top of the section. If a
                    particular question does not apply to you, then write “N/A”
                    in the answer blank. If there are multiple, repetitive
                    answer blanks in a particular section (as, for example, in
                    the section entitled “Residencies and Fellowships”), it is
                    not necessary to mark “N/A” in each unneeded answer blank.
                  </li>
                  <li>
                    Unless specifically permitted by a particular question,
                    please understand that a reference to “See CV” for an answer
                    is not appropriate.
                  </li>
                  <li>
                    <b
                      >If more space than is provided on this Application is
                      needed in order to answer a question completely, use the
                      attached Explanation Form as necessary. Make as many
                      copies of the Explanation Form as needed to fully answer
                      each question. Include the section and page number of the
                      question being answered as well as your name and Social
                      Security Number on each Explanation Form. Attach all
                      Explanation Forms to this Application.</b
                    >
                  </li>
                  <li>
                    After <b>Part One</b> of the Application has been completed
                    in its entirety but <u>before</u> you sign and date it or
                    fill in the information on page <b>ii</b>,
                    <u
                      >make a copy of the Application to retain in your files
                      and/or computer for future use.</u
                    >
                    <br />
                    In so doing, at the time of a submission to another
                    Healthcare Entity, all you will need to do is to check to
                    ensure that all the information remains complete, current
                    and accurate before completing page <b>ii</b> and signing
                    and forwarding the Application as needed.
                  </li>
                  <li>
                    Any gaps of time greater than thirty (30) days from
                    completion of medical school to the present date must be
                    accounted for before your Application will be considered
                    complete.
                  </li>
                  <li>Please sign and date the Application.</li>
                  <li>
                    Please sign and date Schedule A, Schedule B and Schedule C
                    (as appropriate).
                  </li>
                  <li>
                    Identify the Healthcare Entity to which you are submitting
                    this Application and for what practice area(s) you are
                    applying in the spaces provided on page <b>ii</b>.
                  </li>
                  <li>
                    Mail the Application, Schedules, any Explanation Form(s)
                    prepared in order to answer any question(s) completely, as
                    well as a copy of all applicable enclosures listed on page
                    <b>ii</b> to the Healthcare Entity.
                  </li>
                </ul>
                <p class="p">
                  A current copy of the following documents must be submitted
                  with your Application:
                </p>
                <ul class="list-styled">
                  <li>One recent passport size photograph of yourself</li>
                  <li>State Professional License(s)</li>
                  <li>Federal Narcotics License (DEA Registration)</li>
                  <li>
                    Curriculum Vitae with complete professional history in
                    chronological order (month & year)
                  </li>
                  <li>
                    Diplomas and/or certificates of completion (e.g. medical
                    school, internship, residency, fellowship, etc.)
                  </li>
                  <li>
                    Diplomate of National Board of Medical Examiners or
                    Educational Commission for Foreign Medical Graduates (ECFMG)
                    Certificate (if applicable)
                  </li>
                  <li>
                    Specialty/Subspecialty Board Certification or letter from
                    Board(s) stating your status (if applicable)
                  </li>
                  <li>
                    Declaration Page (Face Sheet) of Professional Liability
                    Policy or Certificate of Insurance
                  </li>
                  <li>
                    Permanent Resident Card or Visa Status (if applicable)
                  </li>
                  <li>
                    Military Discharge Record (Form DD-214) (if applicable)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centered" class="inputBorderBox">
            <div class="mb-3">
              <label
                >Name of Healthcare Entity to which you are submitting this
                Application:</label
              >
              <input
                class="form-control"
                type="text"
                id="cmd_0171"
                name="cmd_0171"
                formControlName="cmd_0171"
                data-id="healthcare entity for submission"
              />
            </div>
            <hr class="hrStyle" />
            <div class="mb-3">
              <label
                >For what type of relationship (i.e., staff membership, network
                participation, etc.):</label
              >
              <input
                class="form-control"
                type="text"
                id="cmd_172"
                name="cmd_172"
                formControlName="cmd_172"
                data-id="For what type of relationship"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="">
          <div class="text-center">
            <h3>
              GEORGIA UNIFORM HEALTHCARE
              <u>PRACTITIONER</u>
              <br />
              CREDENTIALING APPLICATION FORM
            </h3>
            <h3 class="hTwoAlign">***************PART ONE***************</h3>
          </div>
          <div>
            <p class="p">
              <u
                >If more space than is provided on this Application is needed in
                order to answer a question completely, please use the attached
                Explanation Form as necessary.</u
              >
            </p>
          </div>
          <div class="centered" class="identifyBox">
            <h3 class="hThreeAlign">
              I. IDENTIFYING INFORMATION
              <span class="normalParagraphStyle">
                <i>Please provide the practitioner’s full legal name.</i>
              </span>
            </h3>
          </div>

          <table border="1" class="tableWidth">
            <tr>
              <td colspan="10">
                <div class="mb-3 no-margin col-md-5 inputPadding">
                  <label class="normalParagraphStyle"
                    >Last Name (include suffix; Jr., Sr., III):</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="9"
                    name="9"
                    [class.is-invalid]="
                      (gaForm.controls['9'].touched &&
                        gaForm.controls['9'].invalid) ||
                      (gaForm.controls['9'].invalid && isSubmit)
                    "
                    formControlName="9"
                    type="text"
                    data-id="lname"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['9'].touched &&
                      gaForm.controls['9'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['9'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 selectDrop suffix dropDrownSet">
                  <ng-select
                    [items]="suffix"
                    bindLabel="name"
                    placeholder="Select Suffix"
                    bindValue="id"
                    formControlName="29"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding"
                >
                  <label class="normalParagraphStyle">First:</label>
                </div>
                <div class="mb-3 col-md-9 inputPadding">
                  <input
                    style="width:60%;"
                    class="form-control inputWidth "
                    id="8"
                    name="8"
                    [class.is-invalid]="
                      (gaForm.controls['8'].touched &&
                        gaForm.controls['8'].invalid) ||
                      (gaForm.controls['8'].invalid && isSubmit)
                    "
                    formControlName="8"
                    type="text"
                    data-id="fname"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['8'].touched &&
                      gaForm.controls['8'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['8'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Middle:</label>
                  <input
                    class="form-control inputWidth "
                    style="width:30%;"
                    id="809"
                    name="809"
                    formControlName="809"
                    type="text"
                    data-id="mname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-2 inputPadding"
                >
                  <label class="normalParagraphStyle">Degree(s):</label>
                </div>
                <div class="mb-3 selectDropTwo degree inputPadding">
                  <ng-select
                    [items]="degree"
                    bindLabel="name"
                    [class.is-invalid]="
                      (gaForm.controls['822'].touched &&
                        gaForm.controls['822'].invalid) ||
                      (gaForm.controls['822'].invalid && isSubmit)
                    "
                    placeholder="Select Degree"
                    bindValue="id"
                    formControlName="822"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['822'].touched &&
                      gaForm.controls['822'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['822'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Is there any other name under which you have been known or
                    have used (e.g. maiden name)?</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_180"
                      name="cmd_180"
                      formControlName="cmd_180"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_180"
                      name="cmd_180"
                      formControlName="cmd_180"
                      value="0"
                    />No</label
                  >
                </div>
                <br />
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Name(s) and Date(s) Used:</label
                  >
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding"
                >
                  <input
                    class="form-control"
                    id="278"
                    name="278"
                    formControlName="278"
                    type="text"
                    data-id="priorname"
                  />
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      placeholder="MM/DD/YYYY"
                      id="280"
                      formControlName="280"
                      name="280"
                      type="text"
                      data-id="priornameeffdate_date"
                      ngbDatepicker
                      #ga01="ngbDatepicker"
                      (blur)="clearInputIfInvalid('280')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga01.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                   
                  </div>
                  <div *ngIf="gaForm.get('280').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('280').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('280').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('280').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('280').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                    

                  -
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="281"
                      formControlName="281"
                      name="281"
                      type="text"
                      data-id="priornametermdate_date"
                      ngbDatepicker
                      #ga02="ngbDatepicker"
                      (blur)="clearInputIfInvalid('281')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga02.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="gaForm.get('281').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('281').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('281').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('281').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('281').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Home Street Address:</label
                  >
                  <input
                    class="form-control"
                    id="16"
                    name="16"
                    [class.is-invalid]="
                      (gaForm.controls['16'].touched &&
                        gaForm.controls['16'].invalid) ||
                      (gaForm.controls['16'].invalid && isSubmit)
                    "
                    formControlName="16"
                    type="text"
                    data-id="homeaddress"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['16'].touched &&
                      gaForm.controls['16'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['16'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    class="form-control"
                    id="18"
                    name="18"
                    [class.is-invalid]="
                      (gaForm.controls['18'].touched &&
                        gaForm.controls['18'].invalid) ||
                      (gaForm.controls['18'].invalid && isSubmit)
                    "
                    formControlName="18"
                    type="text"
                    data-id="homecity"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['18'].touched &&
                      gaForm.controls['18'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['18'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding">
                  <label class="normalParagraphStyle">State:</label>
                </div>
                <div class="mb-3 selectDrop dropDrownSet degree">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    [class.is-invalid]="
                      (gaForm.controls['20'].touched &&
                        gaForm.controls['20'].invalid) ||
                      (gaForm.controls['20'].invalid && isSubmit)
                    "
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="20"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['20'].touched &&
                      gaForm.controls['20'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['20'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-2 inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                </div>
                <div class="mb-3 no-margin col-md-10 inputPadding">
                  <input
                    class="form-control"
                    id="21"
                    name="21"
                    [class.is-invalid]="
                      (gaForm.controls['21'].touched &&
                        gaForm.controls['21'].invalid) ||
                      (gaForm.controls['21'].invalid && isSubmit)
                    "
                    formControlName="21"
                    type="text"
                    data-id="lname"
                    minlength="5"
                    maxlength="10"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['21'].touched &&
                      gaForm.controls['21'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['21'].errors.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaForm.controls['21'].errors.required &&
                        gaForm.controls['21'].value.length < 5
                      "
                    >
                      Should not be less then 5 digits</small
                    >
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaForm.controls['21'].errors.required &&
                        gaForm.controls['21'].value.length > 10
                      "
                    >
                      Should not be more then 10 digits</small
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding">
                  <label class="normalParagraphStyle"
                    >Home Telephone Number:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-8 inputPadding">
                  <input
                    class="form-control"
                    id="708"
                    name="708"
                    formControlName="708"
                    type="text"
                    data-id="homephone"
                  />
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle">E-Mail Address:</label>
                </div>
                <div class="mb-3 no-margin col-md-8 inputPadding">
                  <input
                    class="form-control"
                    id="759"
                    name="759"
                    [class.is-invalid]="
                      (gaForm.controls['759'].touched &&
                        gaForm.controls['759'].invalid) ||
                      (gaForm.controls['759'].invalid && isSubmit)
                    "
                    formControlName="759"
                    type="text"
                    data-id="hemail"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['759'].touched &&
                      gaForm.controls['759'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['759'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-5 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Citizenship (if not USA, provide type and status of visa
                    and enclose a copy):</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-7 inputPadding">
                  <input
                    class="form-control"
                    id="292"
                    name="292"
                    formControlName="292"
                    type="text"
                    data-id="citizenship"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-5 inputPadding"
                >
                  <label class="normalParagraphStyle">Date of Birth:</label>
                </div>
                <div
                  class="mb-3 no-margin col-md-7 inputPadding  input-group"
                >
                  <input
                    class="form-control  datepicker input-group "
                    [class.is-invalid]="
                      (gaForm.controls['31'].touched &&
                        gaForm.controls['31'].invalid) ||
                      (gaForm.controls['31'].invalid && isSubmit)
                    "
                    placeholder="MM/DD/YYYY"
                    formControlName="31"
                    id="31"
                    name="31"
                    type="text"
                    data-id="dob_date"
                    ngbDatepicker
                    #ga03="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('31')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga03.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>

                  <div class="mandatory" *ngIf="(gaForm.controls['31'].invalid&&gaForm.controls['31'].touched) && (gaForm.controls['31'].value==''|| gaForm.controls['31'].value==null) " >
                     <small >
                   required
                   </small>
                    
                  </div>
                    <small class="text-danger" *ngIf="gaForm.get('31').errors?.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                   <small class="text-danger" *ngIf="gaForm.get('31').errors?.invalidMonth">
                    Month is out of range
                   </small>
                   <small class="text-danger" *ngIf="gaForm.get('31').errors?.invalidDateyear">
                     year is out of range(1900-2099)</small>
                    <small class="text-danger" *ngIf="gaForm.get('31').errors?.invalidDateRange">
                    date is out of range
                     </small>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding">
                  <label class="normalParagraphStyle">Place of Birth:</label>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="810"
                    [class.is-invalid]="
                      (gaForm.controls['810'].touched &&
                        gaForm.controls['810'].invalid) ||
                      (gaForm.controls['810'].invalid && isSubmit)
                    "
                    name="810"
                    formControlName="810"
                    type="text"
                    data-id="bcity"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['810'].touched &&
                      gaForm.controls['810'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['810'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 selectDrop dropDrownSet inputPadding ">
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['289'].touched &&
                        gaForm.controls['289'].invalid) ||
                      (gaForm.controls['289'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="289"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['289'].touched &&
                      gaForm.controls['289'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['289'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Gender:</label>
                  <label class="radio-inline" class="radioStyle">
                    <input
                      type="radio"
                      id="cmd_01"
                      name="cmd_01"
                      formControlName="cmd_01"
                      value="1"
                    />Male</label
                  >
                  <label class="radio-inline" class="radioStyle">
                    <input
                      type="radio"
                      id="cmd_01"
                      name="cmd_01"
                      formControlName="cmd_01"
                      value="0"
                    />Female</label
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="6">
                <div class="mb-3 no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Social Security Number:</label
                  >
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    id="32"
                    name="32"
                    [class.is-invalid]="
                      (gaForm.controls['32'].touched &&
                        gaForm.controls['32'].invalid) ||
                      (gaForm.controls['32'].invalid && isSubmit)
                    "
                    formControlName="32"
                    type="text"
                    data-id="ssn"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['32'].touched &&
                      gaForm.controls['32'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['32'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding">
                  <label class="normalParagraphStyle">UPIN:</label>
                </div>
                <div class="mb-3 col-md-8 inputPadding">
                  <input
                    class="form-control "
                    id="819"
                    name="819"
                    formControlName="819"
                    type="text"
                    data-id="upin"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >National Provider Identifier (NPI) (Type 1 Only):
                  </label>
                </div>
                <div class="mb-3 inputPadding">
                  <input
                    class="form-control"
                    id="1634"
                    name="1634"
                    formControlName="1634"
                    type="text"
                    data-id="npi"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Medicare Provider Number:</label
                  >
                  <input
                    class="form-control"
                    id="325"
                    name="325"
                    formControlName="325"
                    type="text"
                    data-id="medicare"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Georgia Medicaid Provider Number(s):</label
                  >
                  <input
                    class="form-control"
                    id="812"
                    name="812"
                    formControlName="812"
                    type="text"
                    data-id="medicaid"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Other State Medicaid Provider Number:
                  </label>
                  <input
                    class="form-control"
                    id="814"
                    name="814"
                    formControlName="814"
                    type="text"
                    data-id="altmedicaid"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Georgia License Number:
                  </label>
                  <input
                    class="form-control inputWidth"
                    id="293"
                    name="293"
                    [class.is-invalid]="
                      (gaForm.controls['293'].touched &&
                        gaForm.controls['293'].invalid) ||
                      (gaForm.controls['293'].invalid && isSubmit)
                    "
                    formControlName="293"
                    type="text"
                    data-id="license"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['293'].touched &&
                      gaForm.controls['293'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['293'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date mm/yy:
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      [class.is-invalid]="
                        (gaForm.controls['295'].touched &&
                          gaForm.controls['295'].invalid) ||
                        (gaForm.controls['295'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="295"
                      name="295"
                      formControlName="295"
                      type="text"
                      data-id="licenseexp_date"
                      ngbDatepicker
                      #ga84="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('295')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga84.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['295'].touched &&
                      gaForm.controls['295'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['295'].errors"
                    >
                      required
                    </small>
                  </div>
                 <div>
                  <small class="text-danger" *ngIf="gaForm.get('295').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('295').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('295').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('295').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Drug Enforcement Administration Registration #:
                  </label>
                  <input
                    class="form-control inputWidth"
                    [class.is-invalid]="
                      (gaForm.controls['309'].touched &&
                        gaForm.controls['309'].errors) ||
                      (gaForm.controls['309'].errors && isSubmit)
                    "
                    id="309"
                    name="309"
                    formControlName="309"
                    type="text"
                    data-id="dea"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['309'].touched &&
                      gaForm.controls['309'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['309'].errors"
                    >
                      required
                    </small>
                  </div>
                  <br />
                  <input
                    type="checkbox"
                    name="cmd_0194"
                    id="cmd_0194"
                    value="1"
                    (change)="drugEnforcement($event)"
                  />
                  <label>Does Not Apply</label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date mm/yy:
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      [class.is-invalid]="
                        (gaForm.controls['311'].touched &&
                          gaForm.controls['311'].errors) ||
                        (gaForm.controls['311'].errors && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="311"
                      name="311"
                      formControlName="311"
                      type="text"
                      data-id="dea_exp_date"
                      ngbDatepicker
                      #ga85="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('311')"
                    />

                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga85.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['311'].touched &&
                      gaForm.controls['311'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['311'].errors"
                    >
                      required
                    </small>
                  </div>
                   <div>
                  <small class="text-danger" *ngIf="gaForm.get('311').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('311').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('311').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('311').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Controlled Substance Registration Number</label
                  >
                  <input
                    class="form-control inputWidth"
                    id="cmd_02"
                    name="cmd_02"
                    formControlName="cmd_02"
                    type="text"
                    data-id="CDs number"
                  />
                  <br />
                  <input
                    type="checkbox"
                    name="cmd_0205"
                    id="cmd_0205"
                    value="1"
                  />
                  <label>Does Not Apply</label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Issued (if applicable):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="cmd_03"
                      name="cmd_03"
                      formControlName="cmd_03"
                      type="text"
                      data-id="CDs Exp date"
                      ngbDatepicker
                      #ga86="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_03')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga86.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_03').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_03').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_03').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_03').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Marital Status (optional):</label
                  >
                  <br />
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="0"
                    />Single</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="1"
                    />Married</label
                  >
                  <br />
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="2"
                    />Divorced</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="3"
                    />Widow</label
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Spouse (if applicable) (optional):</label
                  >
                  <input
                    class="form-control"
                    id="33"
                    name="33"
                    formControlName="33"
                    type="text"
                    data-id="spouse"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Medical Specialty for Which Applying
                  </label>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle">Primary: </label>
                  <div class="mb-3 selectDrop col-md-2 suffix">
                    <ng-select
                      [items]="specialty"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="284"
                    >
                    </ng-select>
                  </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle">Secondary: </label>
                  <div class="mb-3 selectDrop col-md-2 suffix">
                    <ng-select
                      [items]="specialtySecondary"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="962"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">LANGUAGES:</label>
                  <br />
                  <label class="normalParagraphStyle"
                    >1. Please list any language other than English (including
                    sign language) in which you are fluent:
                  </label>
                  <div class="mb-3 selectDrop col-md-2 languageDropBox">
                    <ng-select
                      [items]="languages"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="277"
                    >
                    </ng-select>
                  </div>
                  <div class="mb-3 selectDrop col-md-2 languageDropBox">
                    <ng-select
                      [items]="secondaryLanguages"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="1535"
                    >
                    </ng-select>
                  </div>
                  <div class="mb-3 selectDrop col-md-2 languageDropBox">
                    <ng-select
                      [items]="tertiaryLanguages"
                      bindLabel="name"
                      placeholder="Select TertiaryLanguages"
                      bindValue="id"
                      formControlName="1536"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered" class="inputBorderBox">
            <h4 class="boardCertification">
              III. BOARD CERTIFICATION/RECERTIFICATION
            </h4>
            <label class="floatInputs">Does Not Apply</label>
            <input
              class="floatInputs"
              type="checkbox"
              name="cmd_0193"
              id="cmd_0193"
              (ngModelChange)="handleNAchange('cmd_0193')"
              formControlName="cmd_0193"
              value="1"
            />
            <!-- (change)="boardCerti($event)" -->
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you board certified?</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0190"
                      name="cmd_0190"
                      FormControlName="cmd_0190"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0190"
                      name="cmd_0190"
                      FormControlName="cmd_0190"
                      value="0"
                    />No</label
                  >
                  <span class="spanPadding">
                    <i>List all current and past board certifications.</i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Issuing Board
                  </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Certified (mm/yy):
                  </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Recertified (mm/yy):
                  </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Recertified (mm/yy):
                  </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (if any) (mm/yy):
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="boardCertification"
                    [class.is-invalid]="
                      (gaForm.controls['554'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select Board Certification"
                    bindValue="id"
                    formControlName="554"
                    (blur)="handleNAchange('cmd_0193', 554)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['554'].touched &&
                      gaForm.controls['554'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['554'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    [class.is-invalid]="
                      (gaForm.controls['556'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="556"
                    (blur)="handleNAchange('cmd_0193', 556)"

                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['556'].touched &&
                      gaForm.controls['556'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['556'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      [class.is-invalid]="
                        (gaForm.controls['557'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="557"
                      name="557"
                      formControlName="557"
                      type="text"
                      data-id="primarybcert"
                      ngbDatepicker
                      #ga87="ngbDatepicker"
                      (blur)="clearInputIfInvalid('557')"
                      (blur)="handleNAchange('cmd_0193', 557)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga87.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['557'].touched &&
                      gaForm.controls['557'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['557'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('557').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('557').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('557').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('557').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="558"
                      name="558"
                      formControlName="558"
                      type="text"
                      data-id="primarybrcert1"
                      ngbDatepicker
                      #ga93="ngbDatepicker"
                      (blur)="clearInputIfInvalid('558')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga93.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('558').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('558').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('558').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('558').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="559"
                      name="559"
                      formControlName="559"
                      type="text"
                      data-id="primarybrcert2"
                      ngbDatepicker
                      #ga92="ngbDatepicker"
                      (blur)="clearInputIfInvalid('559')"
                      
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga92.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('559').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('559').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('559').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('559').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      [class.is-invalid]="
                        (gaForm.controls['560'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="560"
                      name="560"
                      formControlName="560"
                      type="text"
                      data-id="primarybexp_date"
                      ngbDatepicker
                      #ga91="ngbDatepicker"
                      (blur)="clearInputIfInvalid('560')"
                      (blur)="handleNAchange('cmd_0193', 560)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga91.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['560'].touched &&
                      gaForm.controls['560'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['560'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('560').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('560').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('560').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('560').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div
                  class="mb-3 selectDrop boardCertiSet inputPadding col-md-5"
                >
                  <ng-select
                    [items]="boardCertification"
                    bindLabel="name"
                    placeholder="Select Board Certification"
                    bindValue="id"
                    formControlName="561"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="563"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="564"
                      name="564"
                      formControlName="564"
                      type="text"
                      data-id="secbcert"
                      ngbDatepicker
                      #ga88="ngbDatepicker"
                      (blur)="clearInputIfInvalid('564')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga88.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('564').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('564').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('564').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('564').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
               
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="565"
                      name="565"
                      formControlName="565"
                      type="text"
                      data-id="secbrcert1"
                      ngbDatepicker
                      #ga89="ngbDatepicker"
                      (blur)="clearInputIfInvalid('565')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga89.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('565').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('565').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('565').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('565').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                   
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="566"
                      name="566"
                      formControlName="566"
                      type="text"
                      data-id="secbrcert2"
                      ngbDatepicker
                      #ga90="ngbDatepicker"
                      (blur)="clearInputIfInvalid('566')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga90.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('566').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('566').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('566').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('566').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                   
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="567"
                      name="567"
                      formControlName="567"
                      type="text"
                      data-id="secbexp_date"
                      ngbDatepicker
                      #ga94="ngbDatepicker"
                      (blur)="clearInputIfInvalid('567')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga94.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('567').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('567').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('567').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('567').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                   
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3" width="30%">
                <div
                  class="mb-3 selectDrop boardCertiSet inputPadding col-md-5"
                >
                  <ng-select
                    [items]="boardCertification"
                    bindLabel="name"
                    placeholder="Select Board Certification"
                    bindValue="id"
                    formControlName="946"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="948"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="953"
                      name="953"
                      formControlName="953"
                      type="text"
                      data-id="Tertiary Board cert"
                      ngbDatepicker
                      #ga95="ngbDatepicker"
                      (blur)="clearInputIfInvalid('953')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga95.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('953').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('953').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('953').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('953').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="950"
                      name="950"
                      formControlName="950"
                      type="text"
                      data-id="Tertiary Board recert 1"
                      ngbDatepicker
                      #ga96="ngbDatepicker"
                      (blur)="clearInputIfInvalid('950')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga96.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('950').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('950').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('950').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('950').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="952"
                      name="952"
                      formControlName="952"
                      type="text"
                      data-id="Tertiary Board recert 2"
                      ngbDatepicker
                      #ga97="ngbDatepicker"
                      (blur)="clearInputIfInvalid('952')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga97.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('952').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('952').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('952').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('952').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="954"
                      name="954"
                      formControlName="954"
                      type="text"
                      data-id="Tertiary Board exp"
                      ngbDatepicker
                      #ga98="ngbDatepicker"
                      (blur)="clearInputIfInvalid('954')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga98.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('954').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('954').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('954').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('954').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Please answer the following questions. Attach Explanation
                    Form(s), if necessary.</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">A.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been examined by any specialty board, but
                    failed to pass? If yes, please provide name of board(s) and
                    date(s):</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_012"
                      formControlName="cmd_012"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_012'].touched &&
                          gaForm.controls['cmd_012'].invalid) ||
                        (gaForm.controls['cmd_012'].invalid && isSubmit)
                      "
                      name="cmd_012"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_012'].touched &&
                      gaForm.controls['cmd_012'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_012'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_012"
                      formControlName="cmd_012"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_012'].touched &&
                          gaForm.controls['cmd_012'].invalid) ||
                        (gaForm.controls['cmd_012'].invalid && isSubmit)
                      "
                      name="cmd_012"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_012'].touched &&
                      gaForm.controls['cmd_012'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_012'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_012'].touched &&
                        gaForm.controls['cmd_012'].invalid) ||
                      (gaForm.controls['cmd_012'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">B.</label>
                </div>
              </td>
              <td colspan="16">
                <table border="1">
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >1. If you are not currently certified, have you
                          applied for the certification examination?</label
                        >
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_013"
                            formControlName="cmd_013"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_013'].touched &&
                                gaForm.controls['cmd_013'].invalid) ||
                              (gaForm.controls['cmd_013'].invalid && isSubmit)
                            "
                            name="cmd_013"
                            value="1"
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_013'].touched &&
                            gaForm.controls['cmd_013'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_013'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_013"
                            formControlName="cmd_013"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_013'].touched &&
                                gaForm.controls['cmd_013'].invalid) ||
                              (gaForm.controls['cmd_013'].invalid && isSubmit)
                            "
                            name="cmd_013"
                            value="0"
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_013'].touched &&
                            gaForm.controls['cmd_013'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_013'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaForm.controls['cmd_013'].touched &&
                              gaForm.controls['cmd_013'].invalid) ||
                            (gaForm.controls['cmd_013'].invalid && isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >2. If you have not applied for the certification
                          examination, do you intend to apply for the
                          certification examination? If yes, when? Date:
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              id="cmd_014"
                              name="cmd_014"
                              formControlName="cmd_014"
                              placeholder="MM/DD/YYYY"
                              type="text"
                              data-id="date of cert applied"
                              ngbDatepicker
                              #ga04="ngbDatepicker"
                              (blur)="clearInputIfInvalid('cmd_014')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga04.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_014').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_014').errors?.invalidMonth">
                             Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_014').errors?.invalidDateyear">
                              year is out of range(1900-2099)</small>
                             <small class="text-danger" *ngIf="gaForm.get('cmd_014').errors?.invalidDateRange">
                             date is out of range
                              </small>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_015"
                            formControlName="cmd_015"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_015'].touched &&
                                gaForm.controls['cmd_015'].invalid) ||
                              (gaForm.controls['cmd_015'].invalid && isSubmit)
                            "
                            name="cmd_015"
                            value="1"
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_015'].touched &&
                            gaForm.controls['cmd_015'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_015'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_015"
                            formControlName="cmd_015"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_015'].touched &&
                                gaForm.controls['cmd_015'].invalid) ||
                              (gaForm.controls['cmd_015'].invalid && isSubmit)
                            "
                            name="cmd_015"
                            value="0"
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_015'].touched &&
                            gaForm.controls['cmd_015'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_015'].errors"
                          >
                            required
                          </small>
                        </div>

                        <span
                          *ngIf="
                            (gaForm.controls['cmd_015'].touched &&
                              gaForm.controls['cmd_015'].invalid) ||
                            (gaForm.controls['cmd_015'].invalid && isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >3. If you have applied for the certification
                          examination, have you been accepted to take the
                          certification examination?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_016"
                            formControlName="cmd_016"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_016'].touched &&
                                gaForm.controls['cmd_016'].invalid) ||
                              (gaForm.controls['cmd_016'].invalid && isSubmit)
                            "
                            name="cmd_016"
                            value="1"
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_016'].touched &&
                            gaForm.controls['cmd_016'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_016'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_016"
                            formControlName="cmd_016"
                            [class.is-invalid]="
                              (gaForm.controls['cmd_016'].touched &&
                                gaForm.controls['cmd_016'].invalid) ||
                              (gaForm.controls['cmd_016'].invalid && isSubmit)
                            "
                            name="cmd_016"
                            value="0"
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['cmd_016'].touched &&
                            gaForm.controls['cmd_016'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['cmd_016'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaForm.controls['cmd_016'].touched &&
                              gaForm.controls['cmd_016'].invalid) ||
                            (gaForm.controls['cmd_016'].invalid && isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >4. If you have been accepted, when do you intend to
                          take the certification examination?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Date:
                          <div class="input-group">
                            <input
                              style="width: 60%"
                              class="form-control  datepicker"
                              placeholder="MM/DD/YYYY"
                              id="1377"
                              name="1377"
                              formControlName="1377"
                              type="text"
                              data-id="date of cert testing"
                              ngbDatepicker
                              #ga05="ngbDatepicker"
                              (blur)="clearInputIfInvalid('1377')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga05.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <small class="text-danger" *ngIf="gaForm.get('1377').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('1377').errors?.invalidMonth">
                             Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="gaForm.get('1377').errors?.invalidDateyear">
                              year is out of range(1900-2099)</small>
                             <small class="text-danger" *ngIf="gaForm.get('1377').errors?.invalidDateRange">
                             date is out of range
                              </small>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="16" width="100%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >5. If you do not intend to apply for the
                          certification examination, please attach reason on
                          Explanation Form(s)
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">C.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >If you are not currently board certified, please provide
                    the expiration date of admissibility</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Date (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control inputWidth"
                      placeholder="MM/DD/YYYY"
                      id="cmd_017"
                      name="cmd_017"
                      formControlName="cmd_017"
                      type="text"
                      data-id="date of admissibility"
                      ngbDatepicker
                      #ga100="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_017')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga100.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_017').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_017').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_017').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_017').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">D.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever had board certification revoked, limited,
                    suspended, involuntarily relinquished, subject to stipulated
                    or probationary conditions, received a letter of reprimand
                    from a specialty board, or is any such action currently
                    pending or under review? If yes, please attach Explanation
                    Form(s).</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_018"
                      formControlName="cmd_018"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_018'].touched &&
                          gaForm.controls['cmd_018'].invalid) ||
                        (gaForm.controls['cmd_018'].invalid && isSubmit)
                      "
                      name="cmd_018"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_018'].touched &&
                      gaForm.controls['cmd_018'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_018'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_018"
                      formControlName="cmd_018"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_018'].touched &&
                          gaForm.controls['cmd_018'].invalid) ||
                        (gaForm.controls['cmd_018'].invalid && isSubmit)
                      "
                      name="cmd_018"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_018'].touched &&
                      gaForm.controls['cmd_018'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_018'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_018'].touched &&
                        gaForm.controls['cmd_018'].invalid) ||
                      (gaForm.controls['cmd_018'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">D.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever voluntarily relinquished a board
                    certification, including any voluntary non-renewal of a time
                    limited board certification? If yes, please attach
                    Explanation Form(s).</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_019"
                      formControlName="cmd_019"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_019'].touched &&
                          gaForm.controls['cmd_019'].invalid) ||
                        (gaForm.controls['cmd_019'].invalid && isSubmit)
                      "
                      name="cmd_019"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_019'].touched &&
                      gaForm.controls['cmd_019'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_019'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_019"
                      formControlName="cmd_019"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_019'].touched &&
                          gaForm.controls['cmd_019'].invalid) ||
                        (gaForm.controls['cmd_019'].invalid && isSubmit)
                      "
                      name="cmd_019"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_019'].touched &&
                      gaForm.controls['cmd_019'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_019'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_019'].touched &&
                        gaForm.controls['cmd_019'].invalid) ||
                      (gaForm.controls['cmd_019'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              IV. EDUCATION, TRAINING AND PROFESSIONAL EXPERIENCE
            </h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">A. UNDERGRADUATE</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Complete School Name:</label
                  >
                  <input
                    style="width: 40%"
                    formControlName="503"
                    [class.is-invalid]="
                      (gaForm.controls['503'].touched &&
                        gaForm.controls['503'].invalid) ||
                      (gaForm.controls['503'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="503"
                    name="503"
                    type="text"
                    data-id="ugs"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['503'].touched &&
                      gaForm.controls['503'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['503'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 selectDropTwo inputPadding">
                  <ng-select
                    [items]="degree"
                    formControlName="512"
                    [class.is-invalid]="
                      (gaForm.controls['512'].touched &&
                        gaForm.controls['512'].invalid) ||
                      (gaForm.controls['512'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select Degree"
                    bindValue="id"
                    formControlName="512"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['512'].touched &&
                      gaForm.controls['512'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['512'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Graduation Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 40%"
                      [class.is-invalid]="
                        (gaForm.controls['511'].touched &&
                          gaForm.controls['511'].invalid) ||
                        (gaForm.controls['511'].invalid && isSubmit)
                      "
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="511"
                      name="511"
                      formControlName="511"
                      type="text"
                      data-id="ugcomp"
                      ngbDatepicker
                      #ga06="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('511')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga06.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['511'].touched &&
                      gaForm.controls['511'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['511'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('511').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('511').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('511').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('511').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    formControlName="506"
                    [class.is-invalid]="
                      (gaForm.controls['506'].touched &&
                        gaForm.controls['506'].invalid) ||
                      (gaForm.controls['506'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="506"
                    name="506"
                    type="text"
                    data-id="ugsci"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['506'].touched &&
                      gaForm.controls['506'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['506'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet suffix inputPadding"
                >
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['508'].touched &&
                        gaForm.controls['508'].invalid) ||
                      (gaForm.controls['508'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="508"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['508'].touched &&
                      gaForm.controls['508'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['508'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Course of Study or Major:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['513'].touched &&
                        gaForm.controls['513'].invalid) ||
                      (gaForm.controls['513'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="513"
                    name="513"
                    formControlName="513"
                    type="text"
                    data-id="ugspr"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['513'].touched &&
                      gaForm.controls['513'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['513'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >B. GRADUATE OR OTHER PROFESSIONAL DEGREES</label
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_020"
                      name="cmd_020"
                      (ngModelChange)="handleNAchange('cmd_020')"
                      formControlName="cmd_020"
                      value="1"
                    />
                    <!-- (change)="graduateProfessional($event)" -->
                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Complete School Name:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['479'].invalid)
                    "
                    class="form-control"
                    id="479"
                    name="479"
                    formControlName="479"
                    type="text"
                    data-id="grs"
                    (blur)="handleNAchange('cmd_020', 479)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['479'].touched &&
                      gaForm.controls['479'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['479'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Degree(s) Received:</label
                  >
                  <div class="mb-3 selectDropTwo suffix inputPadding">
                    <ng-select
                      [items]="degree"
                      [class.is-invalid]="
                        (gaForm.controls['480'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="480"
                      (blur)="handleNAchange('cmd_020', 480)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['480'].touched &&
                        gaForm.controls['480'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['480'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Graduation Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 40%"
                      [class.is-invalid]="
                        (gaForm.controls['482'].invalid)
                      "
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="482"
                      name="482"
                      formControlName="482"
                      type="text"
                      data-id="grscomp"
                      ngbDatepicker
                      #ga07="ngbDatepicker"
                      (blur)="clearInputIfInvalid('482')"
                      (blur)="handleNAchange('cmd_020', 482)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga07.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('482').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('482').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('482').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('482').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['482'].touched &&
                      gaForm.controls['482'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['482'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['485'].invalid)
                    "
                    class="form-control"
                    id="485"
                    name="485"
                    formControlName="485"
                    type="text"
                    data-id="grsci"
                    (blur)="handleNAchange('cmd_020', 485)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['485'].touched &&
                      gaForm.controls['485'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['485'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet suffix inputPadding "
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['487'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="487"
                      (blur)="handleNAchange('cmd_020', 487)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['487'].touched &&
                        gaForm.controls['487'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['487'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Course of Study or Major:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['489'].invalid)
                    "
                    class="form-control"
                    id="489"
                    name="489"
                    formControlName="489"
                    type="text"
                    data-id="grspr"
                    (blur)="handleNAchange('cmd_020', 489)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['489'].touched &&
                      gaForm.controls['489'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['489'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >C. MEDICAL / PROFESSIONAL</label
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0191"
                      name="cmd_0191"
                      formControlName="cmd_0191"
                      (ngModelChange)="handleNAchange('cmd_0191')"
                      value="1"
                    />Does Not Apply</label
                  >
                  <!-- (change)="medicalprofession($event)" -->

                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Medical / Professional School Name and Street
                    Address:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['490'].invalid)
                    "
                    id="490"
                    name="490"
                    formControlName="490"
                    type="text"
                    placeholder="Name"
                    data-id="meds"
                    (blur)="handleNAchange('cmd_0191', 490)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['490'].touched &&
                      gaForm.controls['490'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['490'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['491'].invalid)
                    "
                    id="491"
                    name="491"
                    formControlName="491"
                    type="text"
                    placeholder="Address"
                    data-id="medsadd"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['491'].touched &&
                      gaForm.controls['491'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['491'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['493'].invalid)
                    "
                    class="form-control"
                    id="493"
                    name="493"
                    formControlName="493"
                    type="text"
                    data-id="medsci"
                    (blur)="handleNAchange('cmd_0191', 493)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['493'].touched &&
                      gaForm.controls['493'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['493'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix "
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['495'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="495"
                      (blur)="handleNAchange('cmd_0191', 495)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['495'].touched &&
                        gaForm.controls['495'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['495'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['496'].invalid)
                    "
                    class="form-control"
                    id="496"
                    name="496"
                    formControlName="496"
                    type="text"
                    data-id="medsz"
                    (blur)="handleNAchange('cmd_0191', 496)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['496'].touched &&
                      gaForm.controls['496'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['496'].errors.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaForm.controls['496'].errors.required &&
                        gaForm.controls['496'].value.length < 5
                      "
                    >
                      Should not be less then 5 digits</small
                    >
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaForm.controls['496'].errors.required &&
                        gaForm.controls['496'].value.length > 10
                      "
                    >
                      Should not be more then 10 digits</small
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      [class.is-invalid]="
                        (gaForm.controls['497'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="497"
                      name="497"
                      formControlName="497"
                      type="text"
                      data-id="medsstart_date"
                      ngbDatepicker
                      #ga08="ngbDatepicker"
                      (blur)="clearInputIfInvalid('497')"
                      (blur)="handleNAchange('cmd_0191', 497)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga08.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['497'].touched &&
                      gaForm.controls['497'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['497'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('497').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('497').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('497').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('497').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaForm.controls['498'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="498"
                      name="498"
                      formControlName="498"
                      type="text"
                      data-id="medsend_date"
                      ngbDatepicker
                      #ga09="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('498')"
                      (blur)="handleNAchange('cmd_0191', 498)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga09.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['498'].touched &&
                      gaForm.controls['498'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['498'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('498').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('498').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('498').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('498').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group  "
                      [class.is-invalid]="
                        (gaForm.controls['499'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="499"
                      name="499"
                      formControlName="499"
                      data-id="medscomp"
                      type="medscomp"
                      ngbDatepicker
                      #ga10="ngbDatepicker"
                      (blur)="clearInputIfInvalid('499')"
                      (blur)="handleNAchange('cmd_0191', 499)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga10.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['499'].touched &&
                      gaForm.controls['499'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['499'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                  <small class="text-danger" *ngIf="gaForm.get('499').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('499').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('499').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('499').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Degree(s) Received:</label
                  >

                  <div
                    class="mb-3 selectDropTwo inputPadding suffix col-md-8"
                  >
                    <ng-select
                      [items]="degree"
                      [class.is-invalid]="
                        (gaForm.controls['500'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="500"
                      (blur)="handleNAchange('cmd_0191', 500)"

                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['500'].touched &&
                        gaForm.controls['500'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['500'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="0"
                    />No</label
                  >
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_021'].touched &&
                        gaForm.controls['cmd_021'].invalid) ||
                      (gaForm.controls['cmd_021'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>

                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >D. FOREIGN MEDICAL GRADUATE</label
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_022"
                      name="cmd_022"
                      formControlName="cmd_022"
                      (ngModelChange)="handleNAchange('cmd_022')"
                      value="1"
                    />
                    <!-- (change)="foreignMedical($event)" -->
                    Does Not Apply
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Educational Commission for Foreign Medical Graduates
                    (ECFMG) Number:</label
                  >
                  <input
                    style="width: 20%"
                    [class.is-invalid]="
                      (gaForm.controls['514'].invalid)
                    "
                    class="form-control"
                    id="514"
                    name="514"
                    formControlName="514"
                    type="text"
                    data-id="ecfmg"
                    (blur)="handleNAchange('cmd_022', 514)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['514'].touched &&
                      gaForm.controls['514'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['514'].errors"
                    >
                      required
                    </small>
                  </div>

                  <span class="normalParagraphStyle">
                    <i>Please enclose a copy of your Certificate.</i>
                  </span>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Issued (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 20%"
                      [class.is-invalid]="
                        (gaForm.controls['515'].invalid)"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="515"
                      name="515"
                      formControlName="515"
                      type="text"
                      data-id="ecfmgissue_date"
                      ngbDatepicker
                      #ga11="ngbDatepicker"
                      (blur)="clearInputIfInvalid('515')"
                      (blur)="handleNAchange('cmd_022', 515)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga11.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['515'].touched &&
                      gaForm.controls['515'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['515'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('515').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('515').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('515').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('515').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Other:</label>
                  <br />
                  <label class="normalParagraphStyle">Fifth Pathway</label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_023'].touched &&
                          gaForm.controls['cmd_023'].errors) ||
                        (gaForm.controls['cmd_023'].errors && isSubmit)
                      "
                      id="cmd_023"
                      name="cmd_023"
                      formControlName="cmd_023"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_023'].touched &&
                      gaForm.controls['cmd_023'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_023'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_023'].touched &&
                          gaForm.controls['cmd_023'].errors) ||
                        (gaForm.controls['cmd_023'].errors && isSubmit)
                      "
                      id="cmd_023"
                      name="cmd_023"
                      formControlName="cmd_023"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_023'].touched &&
                      gaForm.controls['cmd_023'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_023'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_023'].touched &&
                        gaForm.controls['cmd_023'].errors) ||
                      (gaForm.controls['cmd_023'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>

                  <br />
                  <span class="normalParagraphStyle">
                    <i
                      >If Yes, please provide name and address of
                      institution.</i
                    >
                  </span>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Dates of Attendance (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 20%"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_024'].invalid)
                      "
                      class="form-control  datepicker"
                      placeholder="MM/DD/YYYY"
                      id="cmd_024"
                      name="cmd_024"
                      formControlName="cmd_024"
                      type="text"
                      data-id="Date of attendance"
                      ngbDatepicker
                      #ga12="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_024')"
                      (blur)="handleNAchange('cmd_022', 'cmd_024')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga12.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_024'].touched &&
                      gaForm.controls['cmd_024'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_024'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_024').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_024').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_024').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_024').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">E. INTERNSHIP</label>
                  <label class="checkbox-inline radioStyle">
                    Include all programs you attended, whether or not completed.
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_026"
                      name="cmd_026"
                      formControlName="cmd_026"
                      (ngModelChange)="handleNAchange('cmd_026')"
                      value="1"
                    />
                    <!-- (change)="internship($event)" -->
                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Institution Name and Street Address:</label
                  >
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['517'].invalid)
                    "
                    id="517"
                    name="517"
                    formControlName="517"
                    placeholder="Name"
                    type="text"
                    data-id="interns"
                    (blur)="handleNAchange('cmd_026', 517)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['517'].touched &&
                      gaForm.controls['517'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['517'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['518'].touched &&
                        gaForm.controls['518'].errors) ||
                      (gaForm.controls['518'].errors && isSubmit)
                    "
                    id="518"
                    name="518"
                    formControlName="518"
                    placeholder="Address"
                    type="text"
                    data-id="internadd"
                    (blur)="handleNAchange('cmd_026', 518)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['518'].touched &&
                      gaForm.controls['518'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['518'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['520'].invalid)
                    "
                    class="form-control"
                    id="520"
                    name="520"
                    formControlName="520"
                    type="text"
                    data-id="internci"
                    (blur)="handleNAchange('cmd_026', 520)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['520'].touched &&
                      gaForm.controls['520'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['520'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['522'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="522"
                      (blur)="handleNAchange('cmd_026', 522)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['522'].touched &&
                        gaForm.controls['522'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['522'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['523'].invalid)
                    "
                    class="form-control"
                    id="523"
                    name="523"
                    formControlName="523"
                    type="text"
                    data-id="internz"
                    (blur)="handleNAchange('cmd_026', 523)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['523'].touched &&
                      gaForm.controls['523'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['523'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaForm.controls['524'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="524"
                      name="524"
                      formControlName="524"
                      type="text"
                      data-id="internstart_date"
                      ngbDatepicker
                      #ga104="ngbDatepicker"
                      (blur)="clearInputIfInvalid('524')"
                      (blur)="handleNAchange('cmd_026', 524)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga104.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['524'].touched &&
                      gaForm.controls['524'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['524'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('524').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('524').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('524').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('524').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaForm.controls['525'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="525"
                      name="525"
                      formControlName="525"
                      type="text"
                      data-id="internend_date"
                      ngbDatepicker
                      #ga106="ngbDatepicker"
                      (blur)="clearInputIfInvalid('525')"
                      (blur)="handleNAchange('cmd_026', 525)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga106.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['525'].touched &&
                      gaForm.controls['525'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['525'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('525').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('525').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('525').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('525').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaForm.controls['526'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="526"
                      name="526"
                      formControlName="526"
                      data-id="interncompdate_date"
                      type="text"
                      ngbDatepicker
                      #ga13="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('526')"
                      (blur)="handleNAchange('cmd_026', 526)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga13.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['526'].touched &&
                      gaForm.controls['526'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['526'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('526').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('526').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('526').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('526').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty: </label>
                </div>

                <div class="mb-3 selectDropTwo inputPadding suffix">
                  <ng-select
                    [items]="specialty"
                    [class.is-invalid]="
                      (gaForm.controls['527'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="527"
                    (blur)="handleNAchange('cmd_026', 527)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['527'].touched &&
                      gaForm.controls['527'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['527'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Program Director:</label
                  >
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['528'].invalid)
                    "
                    class="form-control"
                    id="528"
                    name="528"
                    formControlName="528"
                    type="text"
                    data-id="interndir"
                    (blur)="handleNAchange('cmd_026', 528)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['528'].touched &&
                      gaForm.controls['528'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['528'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_027"
                      name="cmd_027"
                      formControlName="cmd_027"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_027"
                      name="cmd_027"
                      formControlName="cmd_027"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle boldParagraphStyle">
                    RESIDENCY
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_025"
                      name="cmd_025"
                      formControlName="cmd_025"
                      value="1"
                      (ngModelChange)="handleNAchange('cmd_025')"
                    />

                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Institution Name and Street Address:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="529"
                    name="529"
                    [class.is-invalid]="
                      (gaForm.controls['529'].invalid)
                    "
                    formControlName="529"
                    placeholder="Name"
                    type="text"
                    data-id="res"
                    (blur)="handleNAchange('cmd_025', 529)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['529'].touched &&
                      gaForm.controls['529'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['529'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="531"
                    name="531"
                    [class.is-invalid]="
                      (gaForm.controls['531'].invalid)
                    "
                    formControlName="531"
                    placeholder="Address"
                    type="text"
                    data-id="resadd"
                    (blur)="handleNAchange('cmd_025', 531)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['531'].touched &&
                      gaForm.controls['531'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['531'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty</label>
                </div>

                <div class="mb-3 selectDropTwo inputPadding suffix">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    [class.is-invalid]="
                      (gaForm.controls['530'].invalid)
                    "
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="530"
                    (blur)="handleNAchange('cmd_025', 530)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['530'].touched &&
                      gaForm.controls['530'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['530'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['533'].invalid)
                    "
                    id="533"
                    name="533"
                    formControlName="533"
                    type="text"
                    data-id="resci"
                    (blur)="handleNAchange('cmd_025', 533)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['533'].touched &&
                      gaForm.controls['533'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['533'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaForm.controls['535'].invalid)
                      "
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="535"
                      (blur)="handleNAchange('cmd_025', 535)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['535'].touched &&
                        gaForm.controls['535'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['535'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['536'].invalid)
                    "
                    id="536"
                    name="536"
                    formControlName="536"
                    type="text"
                    data-id="resz"
                    (blur)="handleNAchange('cmd_025', 536)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['536'].touched &&
                      gaForm.controls['536'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['536'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaForm.controls['537'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="537"
                      name="537"
                      formControlName="537"
                      type="text"
                      data-id="resstart_date"
                      ngbDatepicker
                      #ga14="ngbDatepicker"
                      (blur)="clearInputIfInvalid('537')"
                      (blur)="handleNAchange('cmd_025', 537)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga14.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['537'].touched &&
                      gaForm.controls['537'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['537'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('537').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('537').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('537').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('537').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaForm.controls['538'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="538"
                      name="538"
                      formControlName="538"
                      type="text"
                      data-id="resend_date"
                      ngbDatepicker
                      #ga103="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('538')"
                      (blur)="handleNAchange('cmd_025', 538)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga103.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['538'].touched &&
                      gaForm.controls['538'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['538'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('538').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('538').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('538').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('538').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaForm.controls['539'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="539"
                      name="539"
                      formControlName="539"
                      data-id="resend_date"
                      type="text"
                      ngbDatepicker
                      #ga107="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('539')"
                      (blur)="handleNAchange('cmd_025', 539)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga107.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['539'].touched &&
                      gaForm.controls['539'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['539'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('539').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('539').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('539').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('539').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Program Director:</label
                  >
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['540'].invalid)
                    "
                    class="form-control "
                    id="540"
                    name="540"
                    formControlName="540"
                    type="text"
                    data-id="resdir"
                    (blur)="handleNAchange('cmd_025', 540)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['540'].touched &&
                      gaForm.controls['540'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['540'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_028"
                      name="cmd_028"
                      formControlName="cmd_028"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_028"
                      name="cmd_028"
                      formControlName="cmd_028"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">F. FELLOWSHIPS </label>
                  <i
                    >If you completed more than one fellowship, please provide
                    the information on an explanation form.
                  </i>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_029"
                      name="cmd_029"
                      formControlName="cmd_029"
                      (ngModelChange)="handleNAchange('cmd_029')"
                      value="1"
                    />
                    <!-- (change)="fellowship($event)" -->

                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Institution Name and Street Address:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="542"
                    [class.is-invalid]="
                      (gaForm.controls['542'].invalid)
                    "
                    name="542"
                    formControlName="542"
                    type="text"
                    placeholder="Name"
                    data-id="fellowschool"
                    (blur)="handleNAchange('cmd_029', 542)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['542'].touched &&
                      gaForm.controls['542'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['542'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control"
                    id="544"
                    [class.is-invalid]="
                    (gaForm.controls['544'].invalid)
                    "
                    name="544"
                    formControlName="544"
                    type="text"
                    placeholder="Address"
                    data-id="fellowadd"
                    (blur)="handleNAchange('cmd_029', 544)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['544'].touched &&
                      gaForm.controls['544'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['544'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty</label>
                </div>

                <div class="mb-3 selectDropTwo inputPadding suffix">
                  <ng-select
                    [items]="specialty"
                    [class.is-invalid]="
                      (gaForm.controls['543'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="543"
                    (blur)="handleNAchange('cmd_029', 543)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['543'].touched &&
                      gaForm.controls['543'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['543'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['546'].invalid)
                    "
                    id="546"
                    name="546"
                    formControlName="546"
                    type="text"
                    data-id="fellowci"
                    (blur)="handleNAchange('cmd_029', 546)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['546'].touched &&
                      gaForm.controls['546'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['546'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['548'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="548"
                      (blur)="handleNAchange('cmd_029', 548)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['548'].touched &&
                        gaForm.controls['548'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['548'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['549'].invalid)
                    "
                    class="form-control"
                    id="549"
                    name="549"
                    formControlName="549"
                    type="text"
                    data-id="fellowz"
                    (blur)="handleNAchange('cmd_029', 549)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['549'].touched &&
                      gaForm.controls['549'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['549'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group  "
                      [class.is-invalid]="
                        (gaForm.controls['550'].invalid)
                         
                      "
                      placeholder="MM/DD/YYYY"
                      id="550"
                      name="550"
                      formControlName="550"
                      type="text"
                      data-id="fellowstart_date"
                      ngbDatepicker
                      #ga15="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('550')"
                      (blur)="handleNAchange('cmd_029', 550)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga15.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['550'].touched &&
                      gaForm.controls['550'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['550'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('550').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('550').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('550').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('550').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaForm.controls['551'].invalid)
                        
                      "
                      placeholder="MM/DD/YYYY"
                      id="551"
                      name="551"
                      formControlName="551"
                      type="text"
                      data-id="fellowend_date"
                      ngbDatepicker
                      #ga16="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('551')"
                      (blur)="handleNAchange('cmd_029', 551)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga16.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['551'].touched &&
                      gaForm.controls['551'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['551'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('551').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('551').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('551').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('551').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker  input-group "
                      [class.is-invalid]="
                        (gaForm.controls['552'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="552"
                      name="552"
                      formControlName="552"
                      data-id="fellowcompdate_date"
                      type="text"
                      ngbDatepicker
                      #ga17="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('552')"
                      (blur)="handleNAchange('cmd_029', 552)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga17.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['552'].touched &&
                      gaForm.controls['552'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['552'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('552').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('552').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('552').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('552').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Program Director:</label
                  >
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['553'].invalid)
                        
                    "
                    class="form-control"
                    id="553"
                    name="553"
                    formControlName="553"
                    type="text"
                    data-id="fellowdirector"
                    (blur)="handleNAchange('cmd_029', 553)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['553'].touched &&
                      gaForm.controls['553'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['553'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_030"
                      name="cmd_030"
                      formControlName="cmd_030"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_030"
                      name="cmd_030"
                      formControlName="cmd_030"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >G. OTHER CLINICAL TRAINING PROGRAMS</label
                  >
                  <i
                    >(For example, preceptorship, procedural certificate course,
                    etc.)</i
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0192"
                      name="cmd_0192"
                      formControlName="cmd_0192"
                      (ngModelChange)="handleNAchange('cmd_0192')"
                      value="1"
                    />
                    <!-- (change)="otherClinical($event)"
 -->
                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name and Street Address:</label
                  >
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['1288'].invalid)
                        
                    "
                    id="1288"
                    name="1288"
                    formControlName="1288"
                    placeholder="Name"
                    type="text"
                    data-id="Training Pro 1"
                    (blur)="handleNAchange('cmd_0192', 1288)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1288'].touched &&
                      gaForm.controls['1288'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1288'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty</label>
                </div>
                <div class="mb-3 selectDropTwo inputPadding ">
                  <ng-select
                    [items]="specialty"
                    [class.is-invalid]="
                      (gaForm.controls['1628'].invalid)
                      
                    "
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="1628"
                    (blur)="handleNAchange('cmd_0192', 1628)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1628'].touched &&
                      gaForm.controls['1628'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1628'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['1291'].invalid)
                       
                    "
                    class="form-control"
                    id="1291"
                    name="1291"
                    formControlName="1291"
                    type="text"
                    data-id="Training Pro 1 city"
                    (blur)="handleNAchange('cmd_0192', 1291)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1291'].touched &&
                      gaForm.controls['1291'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1291'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>

                  <div
                    class="mb-3 selectDrop dropDrownSet suffix inputPadding"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['1293'].invalid)
                         
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1293"
                      (blur)="handleNAchange('cmd_0192', 1293)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1293'].touched &&
                        gaForm.controls['1293'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1293'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaForm.controls['1294'].invalid)
                        
                    "
                    class="form-control"
                    id="1294"
                    name="1294"
                    formControlName="1294"
                    type="text"
                    data-id="Training Pro 1 zip"
                    (blur)="handleNAchange('cmd_0192', 1294)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1294'].touched &&
                      gaForm.controls['1294'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1294'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaForm.controls['1295'].invalid)
                       
                      "
                      placeholder="MM/DD/YYYY"
                      id="1295"
                      name="1295"
                      formControlName="1295"
                      type="text"
                      data-id="Training Pro 1 from"
                      ngbDatepicker
                      #ga18="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1295')"
                      (blur)="handleNAchange('cmd_0192', 1295)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga18.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1295'].touched &&
                      gaForm.controls['1295'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1295'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1295').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1295').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1295').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1295').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="(gaForm.controls['1296'].invalid)"
                      placeholder="MM/DD/YYYY"
                      id="1296"
                      name="1296"
                      formControlName="1296"
                      type="text"
                      data-id="Training Pro 1 to"
                      ngbDatepicker
                      #ga19="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1296')"
                      (blur)="handleNAchange('cmd_0192', 1296)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga19.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1296'].touched &&
                      gaForm.controls['1296'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1296'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1296').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1296').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1296').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1296').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaForm.controls['1297'].invalid)
                      
                      "
                      placeholder="MM/DD/YYYY"
                      id="1297"
                      name="1297"
                      formControlName="1297"
                      data-id="Office email 2"
                      type="Training Pro 1 comp"
                      ngbDatepicker
                      #ga20="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1297')"
                      (blur)="handleNAchange('cmd_0192', 1297)"

                    />

                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga20.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1297'].touched &&
                      gaForm.controls['1297'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1297'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1297').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1297').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1297').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1297').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Program Director:</label
                  >
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['1299'].invalid)
                       
                    "
                    class="form-control"
                    id="1299"
                    name="1299"
                    formControlName="1299"
                    type="text"
                    data-id="Training Pro 1 program"
                    (blur)="handleNAchange('cmd_0192', 1299)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1299'].touched &&
                      gaForm.controls['1299'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1299'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 selectDropTwo inputPadding suffix">
                  <ng-select
                    [items]="degree"
                    [class.is-invalid]="
                      (gaForm.controls['1298'].invalid)
                       
                    "
                    bindLabel="name"
                    placeholder="Select Degree"
                    bindValue="id"
                    formControlName="1298"
                    (blur)="handleNAchange('cmd_0192', 1298)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1298'].touched &&
                      gaForm.controls['1298'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1298'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_031"
                      name="cmd_031"
                      formControlName="cmd_031"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_031"
                      name="cmd_031"
                      formControlName="cmd_031"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name and Street Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_032"
                    name="cmd_032"
                    formControlName="cmd_032"
                    type="text"
                    data-id="Training Pro 2"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty:</label>
                  <input
                    class="form-control"
                    id="cmd_033"
                    name="cmd_033"
                    formControlName="cmd_033"
                    type="text"
                    data-id="Training Pro 2 spec"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="cmd_034"
                    name="cmd_034"
                    formControlName="cmd_034"
                    type="text"
                    data-id="Training Pro 2 city"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="cmd_035"
                    name="cmd_035"
                    formControlName="cmd_035"
                    type="text"
                    data-id="Training Pro 2 st"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    style="width: 40%"
                    class="form-control"
                    id="cmd_036"
                    name="cmd_036"
                    formControlName="cmd_036"
                    type="text"
                    data-id="Training Pro 2 zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      placeholder="MM/DD/YYYY"
                      id="cmd_037"
                      name="cmd_037"
                      formControlName="cmd_037"
                      type="text"
                      data-id="Training Pro 2 from"
                      ngbDatepicker
                      #ga21="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_037')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga21.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('cmd_037').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('cmd_037').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('cmd_037').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_037').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_037').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_038"
                      name="cmd_038"
                      formControlName="cmd_038"
                      type="text"
                      data-id="Training Pro 2 to"
                      ngbDatepicker
                      #ga22="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_038')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga22.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('cmd_038').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('cmd_038').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('cmd_038').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_038').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_038').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):
                  </label>
                  <input
                    class="form-control  datepicker input-group "
                    placeholder="MM/DD/YYYY"
                    id="cmd_039"
                    name="cmd_039"
                    formControlName="cmd_039"
                    data-id="Office email 2"
                    type="Training Pro 2 comp"
                    ngbDatepicker
                    #ga23="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_039')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga23.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_039').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_039').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_039').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_039').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_039').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Program Director:</label
                  >
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="cmd_040"
                    name="cmd_040"
                    formControlName="cmd_040"
                    type="text"
                    data-id="Training Pro 2 program"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Certificate Awarded:</label
                  >
                  <input
                    style="width:40%;"
                    class="form-control"
                    id="cmd_041"
                    name="cmd_041"
                    formControlName="cmd_041"
                    type="text"
                    data-id="Training Pro 2 cert"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_042"
                      name="cmd_042"
                      formControlName="cmd_042"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_042"
                      name="cmd_042"
                      formControlName="cmd_042"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">H. FACULTY POSITIONS</label>
                  <i
                    >List all academic, faculty, research, assistantships or
                    teaching positions you have held and the dates of those
                    appointments.</i
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_043"
                      name="cmd_043"
                      formControlName="cmd_043"
                      value="1"
                      (ngModelChange)="handleNAchange('cmd_043')"
                    />
                    <!-- (change)="facultyPosition($event)" -->

                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Program Specialty & Institution:
                  </label>
                  <div
                    class="mb-3 selectDrop inputPadding suffix col-md-2"
                  >
                    <ng-select
                      [items]="specialty"
                      [class.is-invalid]="
                        (gaForm.controls['1147'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="1147"
                      (blur)="handleNAchange('cmd_043', 1147)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1147'].touched &&
                        gaForm.controls['1147'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1147'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Academic Rank or Title:
                  </label>
                  <input
                    style="width:70%;"
                    [class.is-invalid]="
                      (gaForm.controls['1154'].invalid)
                    "
                    class="form-control"
                    id="1154"
                    name="1154"
                    formControlName="1154"
                    type="text"
                    data-id="faculty Pos 1 title"
                    (blur)="handleNAchange('cmd_043', 1154)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1154'].touched &&
                      gaForm.controls['1154'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1154'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name & Address:
                  </label>
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['1146'].invalid)
                    "
                    class="form-control"
                    id="1146"
                    name="1146"
                    formControlName="1146"
                    type="text"
                    data-id="faculty Pos 1 Inst name"
                    (blur)="handleNAchange('cmd_043', 1146)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1146'].touched &&
                      gaForm.controls['1146'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1146'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City: </label>
                  <input
                    style="width:60%;"
                    [class.is-invalid]="
                      (gaForm.controls['1150'].invalid)
                    "
                    class="form-control"
                    id="1150"
                    name="1150"
                    formControlName="1150"
                    type="text"
                    data-id="faculty Pos 1 city"
                    (blur)="handleNAchange('cmd_043', 1150)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1150'].touched &&
                      gaForm.controls['1150'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1150'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country: </label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix col-md-3"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['1152'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1152"
                      (blur)="handleNAchange('cmd_043', 1152)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1152'].touched &&
                        gaForm.controls['1152'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1152'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip: </label>
                  <input
                    style="width:60%;"
                    [class.is-invalid]="
                      (gaForm.controls['1153'].invalid)
                    "
                    class="form-control"
                    id="1153"
                    name="1153"
                    formControlName="1153"
                    type="text"
                    data-id="faculty Pos 1 zip"
                    (blur)="handleNAchange('cmd_043', 1153)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1153'].touched &&
                      gaForm.controls['1153'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1153'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:40%;"
                      [class.is-invalid]="
                        (gaForm.controls['1155'].invalid)
                      "
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1155"
                      name="1155"
                      formControlName="1155"
                      type="text"
                      data-id="faculty Pos 1 from"
                      ngbDatepicker
                      #ga24="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1155')"
                      (blur)="handleNAchange('cmd_043', 1155)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga24.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1155'].touched &&
                      gaForm.controls['1155'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1155'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1155').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1155').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1155').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1155').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:70%;"
                      [class.is-invalid]="
                        (gaForm.controls['1156'].invalid)
                      "
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1156"
                      name="1156"
                      formControlName="1156"
                      type="text"
                      data-id="faculty Pos 1  to"
                      ngbDatepicker
                      #ga25="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1156')"
                      (blur)="handleNAchange('cmd_043', 1156)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga25.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1156'].touched &&
                      gaForm.controls['1156'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1156'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1156').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1156').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1156').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1156').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Program Specialty & Institution:
                  </label>
                  <div
                    class="mb-3 selectDrop inputPadding suffix col-md-2"
                  >
                    <ng-select
                      [items]="specialty"
                      [class.is-invalid]="
                        (gaForm.controls['1158'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="1158"
                      (blur)="handleNAchange('cmd_043', 1158)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1158'].touched &&
                        gaForm.controls['1158'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1158'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Academic Rank or Title:
                  </label>
                  <input
                    style="width:70%;"
                    [class.is-invalid]="
                      (gaForm.controls['1165'].invalid)
                    "
                    class="form-control"
                    id="1165"
                    name="1165"
                    formControlName="1165"
                    type="text"
                    data-id="faculty Pos 2 title"
                    (blur)="handleNAchange('cmd_043', 1165)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1165'].touched &&
                      gaForm.controls['1165'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1165'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name & Address
                  </label>
                  <input
                    style="width:40%;"
                    [class.is-invalid]="
                      (gaForm.controls['1157'].invalid)
                    "
                    class="form-control"
                    placeholder="Name"
                    id="1157"
                    name="1157"
                    formControlName="1157"
                    type="text"
                    data-id="faculty Pos 2 inst name"
                    (blur)="handleNAchange('cmd_043', 1157)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1157'].touched &&
                      gaForm.controls['1157'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1157'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City: </label>
                  <input
                    style="width:60%;"
                    [class.is-invalid]="
                      (gaForm.controls['1161'].invalid)
                    "
                    class="form-control"
                    id="1161"
                    name="1161"
                    formControlName="1161"
                    type="text"
                    data-id="faculty Pos 2 city"
                    (blur)="handleNAchange('cmd_043', 1161)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1161'].touched &&
                      gaForm.controls['1161'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1161'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country: </label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix "
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['1163'].invalid)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1163"
                      (blur)="handleNAchange('cmd_043', 1163)"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1163'].touched &&
                        gaForm.controls['1163'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1163'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="3" width="17.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip: </label>
                  <input
                    style="width:60%;"
                    [class.is-invalid]="
                      (gaForm.controls['1164'].invalid)
                    "
                    class="form-control"
                    id="1164"
                    name="1164"
                    formControlName="1164"
                    type="text"
                    data-id="faculty Pos 2 zip"
                    (blur)="handleNAchange('cmd_043', 1164)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1164'].touched &&
                      gaForm.controls['1164'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1164'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:40%;"
                      [class.is-invalid]="
                        (gaForm.controls['1166'].invalid)
                      "
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1166"
                      name="1166"
                      formControlName="1166"
                      type="text"
                      data-id="faculty Pos 2 from"
                      ngbDatepicker
                      #ga26="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1166')"
                      (blur)="handleNAchange('cmd_043', 1166)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga26.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1166'].touched &&
                      gaForm.controls['1166'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1166'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1166').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1166').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1166').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1166').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:70%;"
                      [class.is-invalid]="
                        (gaForm.controls['1167'].invalid)
                      "
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1167"
                      name="1167"
                      formControlName="1167"
                      type="text"
                      data-id="faculty Pos 2 to"
                      ngbDatepicker
                      #ga27="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1167')"
                      (blur)="handleNAchange('cmd_043', 1167)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga27.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1167'].touched &&
                      gaForm.controls['1167'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1167'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1167').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1167').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1167').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1167').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >I. MILITARY/PUBLIC HEALTH SERVICE</label
                  >
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_044"
                      name="cmd_044"
                      formControlName="cmd_044"
                      value="1"
                      (ngModelChange)="handleNAchange('cmd_044')"

                    />
                    <!-- (change)="milpublicHealth($event)" -->
                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding col-md-4"
                >
                  <label class="normalParagraphStyle"
                    >Location of Last Duty Station:
                  </label>
                </div>
                <div class="mb-3 no-margin col-md-5 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['1139'].invalid)
                    "
                    id="1139"
                    name="1139"
                    formControlName="1139"
                    type="text"
                    data-id="Military Locaiton"
                    (blur)="handleNAchange('cmd_044', 1139)"

                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1139'].touched &&
                      gaForm.controls['1139'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1139'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Rank at Discharge:
                  </label>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['1140'].invalid)
                    "
                    id="1140"
                    name="1140"
                    formControlName="1140"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_044', 1140)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1140'].touched &&
                      gaForm.controls['1140'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1140'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="4">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle">Branch: </label>
                </div>
                <div class="mb-3 no-margin col-md-8 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['1141'].invalid)
                    "
                    id="1141"
                    name="1141"
                    formControlName="1141"
                    type="text"
                    data-id="Mil branch"
                    (blur)="handleNAchange('cmd_044', 1141)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1141'].touched &&
                      gaForm.controls['1141'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1141'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Active Duty Dates: <br />From (mm/yy)
                  </label>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding ">
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      [class.is-invalid]="
                        (gaForm.controls['1144'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="1144"
                      name="1144"
                      formControlName="1144"
                      type="text"
                      data-id="Mil Active from"
                      ngbDatepicker
                      #ga28="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1144')"
                      (blur)="handleNAchange('cmd_044', 1144)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga28.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1144'].touched &&
                      gaForm.controls['1144'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1144'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div>
                    <small class="text-danger" *ngIf="gaForm.get('1144').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1144').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1144').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1144').errors?.invalidDateRange">
                     date is out of range
                      </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Active Duty Dates: <br />
                    To (mm/yy)
                  </label>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      [class.is-invalid]="
                        (gaForm.controls['1145'].invalid)
                      "
                      placeholder="MM/DD/YYYY"
                      id="1145"
                      name="1145"
                      formControlName="1145"
                      type="text"
                      data-id="Mil Active to"
                      ngbDatepicker
                      #ga29="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('1145')"
                      (blur)="handleNAchange('cmd_044', 1145)"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga29.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div
                      class="formLabel col-md-2"
                      *ngIf="
                        gaForm.controls['1145'].touched &&
                        gaForm.controls['1145'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1145'].errors"
                      >
                        required
                      </small>
                    </div>
                    <div>
                      <small class="text-danger" *ngIf="gaForm.get('1145').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1145').errors?.invalidMonth">
                       Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaForm.get('1145').errors?.invalidDateyear">
                        year is out of range(1900-2099)</small>
                       <small class="text-danger" *ngIf="gaForm.get('1145').errors?.invalidDateRange">
                       date is out of range
                        </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Honorable Discharge:</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_045'].touched &&
                          gaForm.controls['cmd_045'].errors) ||
                        (gaForm.controls['cmd_045'].errors && isSubmit)
                      "
                      id="cmd_045"
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_045'].touched &&
                      gaForm.controls['cmd_045'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_045'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_045"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_045'].touched &&
                          gaForm.controls['cmd_045'].errors) ||
                        (gaForm.controls['cmd_045'].errors && isSubmit)
                      "
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_045'].touched &&
                      gaForm.controls['cmd_045'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_045'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_045'].touched &&
                        gaForm.controls['cmd_045'].errors) ||
                      (gaForm.controls['cmd_045'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>

                  <label class="normalParagraphStyle"
                    >If no, attach Explanation Form(s).</label
                  >
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you currently in the Reserves or National Guard?</label
                  ><br />
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_046'].touched &&
                          gaForm.controls['cmd_046'].errors) ||
                        (gaForm.controls['cmd_046'].errors && isSubmit)
                      "
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_046'].touched &&
                      gaForm.controls['cmd_046'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_046'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_046'].touched &&
                          gaForm.controls['cmd_046'].errors) ||
                        (gaForm.controls['cmd_046'].errors && isSubmit)
                      "
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_046'].touched &&
                      gaForm.controls['cmd_046'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_046'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_046'].touched &&
                        gaForm.controls['cmd_046'].errors) ||
                      (gaForm.controls['cmd_046'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been court-martialed?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_047"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_047'].touched &&
                          gaForm.controls['cmd_047'].invalid) ||
                        (gaForm.controls['cmd_047'].invalid && isSubmit)
                      "
                      name="cmd_047"
                      formControlName="cmd_047"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_047'].touched &&
                      gaForm.controls['cmd_047'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_047'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_047"
                      [class.is-invalid]="
                        (gaForm.controls['cmd_047'].touched &&
                          gaForm.controls['cmd_047'].invalid) ||
                        (gaForm.controls['cmd_047'].invalid && isSubmit)
                      "
                      name="cmd_047"
                      formControlName="cmd_047"
                      value="0"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['cmd_047'].touched &&
                      gaForm.controls['cmd_047'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['cmd_047'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaForm.controls['cmd_047'].touched &&
                        gaForm.controls['cmd_047'].invalid) ||
                      (gaForm.controls['cmd_047'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>

                  <label class="normalParagraphStyle"
                    >If no, attach Explanation Form(s).</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Attach a copy of DD-214 Form.
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >J. CONTINUING MEDICAL EDUCATION</label
                  >
                  <br />
                  <i class="normalParagraphStyle"
                    >If not listed on your Curriculum Vitae, please list on
                    Explanation Form(s) all post graduate activities and
                    scientific meetings that you have attended or for which you
                    have received Category 1 credit in the past twenty-four
                    months, or provide copies of certificates.</i
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >K. PROFESSIONAL MEDICAL ASSOCIATIONS</label
                  >
                  <br />
                  <i class="normalParagraphStyle"
                    >Please list, on the Explanation Form, all professional
                    organizations and societies (local, state and national) in
                    which you have membership</i
                  >
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="12">
                <h4 class="boardCertification">
                  V. OTHER STATE HEALTH CARE LICENSES, REGISTRATIONS &
                  CERTIFICATES
                </h4>
                <br />
                <i
                  >Please include all ever held. If more room is needed please
                  list on an attached Explanation Form.</i
                >
              </td>
              <td colspan="6" width="15%">
                <span class="floatInputs">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_048"
                      name="cmd_048"
                      formControlName="cmd_048"
                      (ngModelChange)="handleNAchange('cmd_048')"
                     
                      value="1"
                    />
                    <!-- (change)="otherStateHealth($event)" -->
                    Dont Not Apply</label
                  >
                </span>
              </td>
            </tr>
          </table>

          <table border="1" class="tableWidth">
            <tr>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Type and Status:
                    <input
                      style="width: 40%"
                      [class.is-invalid]="
                        (gaForm.controls['1274'].invalid)
                      "
                      class="form-control"
                      id="1274"
                      name="1274"
                      formControlName="1274"
                      type="text"
                      data-id="Alt License type"
                      (blur)="handleNAchange('cmd_048', 1274)"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1274'].touched &&
                        gaForm.controls['1274'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1274'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Number:
                    <input
                      style="width: 60%"
                      [class.is-invalid]="
                        (gaForm.controls['300'].invalid)
                      "
                      class="form-control"
                      id="300"
                      name="300"
                      formControlName="300"
                      type="text"
                      data-id="alt license"
                      (blur)="handleNAchange('cmd_048', 300)"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['300'].touched &&
                        gaForm.controls['300'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['300'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >State/Country:
                    <div
                      class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                    >
                      <ng-select
                        [items]="altLicenseState"
                        [class.is-invalid]="
                          (gaForm.controls['907'].invalid)
                        "
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="907"
                        (blur)="handleNAchange('cmd_048', 907)"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['907'].touched &&
                          gaForm.controls['907'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['907'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):
                    <div class="input-group">
                      <input
                        style="width: 30%"
                        [class.is-invalid]="
                          (gaForm.controls['308'].invalid)
                        "
                        class="form-control  datepicker input-group "
                        placeholder="MM/DD/YYYY"
                        id="308"
                        name="308"
                        formControlName="308"
                        type="text"
                        data-id="alt_license_exp_date"
                        ngbDatepicker
                        #ga30="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('308')"
                        (blur)="handleNAchange('cmd_048', 308)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga30.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['308'].touched &&
                        gaForm.controls['308'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['308'].errors"
                      >
                        required
                      </small>
                    </div>
                      <small class="text-danger" *ngIf="gaForm.get('308').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('308').errors?.invalidMonth">
                       Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaForm.get('308').errors?.invalidDateyear">
                        year is out of range(1900-2099)</small>
                       <small class="text-danger" *ngIf="gaForm.get('308').errors?.invalidDateRange">
                       date is out of range
                        </small>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Obtained:
                    <input
                      class="form-control onboard datepicker"
                      [class.is-invalid]="
                        (gaForm.controls['307'].invalid)
                      "
                      id="307"
                      ngbDatepicker
                      #ga111="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('307')"
                      name="307"
                      formControlName="307"
                      type="text"
                      data-id="alt licesne issue"
                      (blur)="handleNAchange('cmd_048', 307)"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga111.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['307'].touched &&
                        gaForm.controls['307'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['307'].errors"
                      >
                        required
                      </small>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('307').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('307').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('307').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('307').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Relinquished:
                    <div class="input-group">
                      <input
                        style="width: 40%"
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="3690"
                        name="3690"
                        [class.is-invalid]="
                          (gaForm.controls['3690'].invalid)
                        "
                        formControlName="3690"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga110="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('3690')"
                        (blur)="handleNAchange('cmd_048', 3690)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga110.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          gaForm.controls['3690'].touched &&
                          gaForm.controls['3690'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['3690'].errors"
                        >
                          required
                        </small>
                      </div>
                      <small class="text-danger" *ngIf="gaForm.get('3690').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('3690').errors?.invalidMonth">
                       Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaForm.get('3690').errors?.invalidDateyear">
                        year is out of range(1900-2099)</small>
                       <small class="text-danger" *ngIf="gaForm.get('3690').errors?.invalidDateRange">
                       date is out of range
                        </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Reason:
                    <input
                      class="form-control"
                      id="cmd_049"
                      name="cmd_049"
                      formControlName="cmd_049"
                      type="text"
                      data-id="alt licesne reason"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Type and Status:
                    <input
                      style="width: 40%"
                      [class.is-invalid]="
                        (gaForm.controls['1432'].invalid)
                      "
                      class="form-control"
                      id="1432"
                      name="1432"
                      formControlName="1432"
                      type="text"
                      data-id="sc_license_type"
                      (blur)="handleNAchange('cmd_048', 1432)"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1432'].touched &&
                        gaForm.controls['1432'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1432'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Number:
                    <input
                      style="width: 60%"
                      [class.is-invalid]="
                        (gaForm.controls['999'].invalid)
                      "
                      class="form-control"
                      id="999"
                      name="999"
                      formControlName="999"
                      type="text"
                      data-id="sc_license"
                      (blur)="handleNAchange('cmd_048', 999)"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['999'].touched &&
                        gaForm.controls['999'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['999'].errors"
                      >
                        required
                      </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >State/Country:
                    <div class="mb-3 selectDrop dropDrownSet ">
                      <ng-select
                        [items]="additionalLicenseState"
                        [class.is-invalid]="
                          (gaForm.controls['1010'].invalid)
                        "
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1010"
                        (blur)="handleNAchange('cmd_048', 1010)"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['1010'].touched &&
                          gaForm.controls['1010'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['1010'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):
                    <div class="input-group">
                      <input
                        style="width: 30%"
                        [class.is-invalid]="
                          (gaForm.controls['1004'].invalid)
                        "
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1004"
                        name="1004"
                        formControlName="1004"
                        type="text"
                        data-id="sc_license_epx_date"
                        ngbDatepicker
                        #ga31="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('1004')"
                        (blur)="handleNAchange('cmd_048', 1004)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga31.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1004'].touched &&
                        gaForm.controls['1004'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1004'].errors"
                      >
                        required
                      </small>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Obtained:
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        [class.is-invalid]="
                          (gaForm.controls['1003'].invalid)
                        "
                        placeholder="MM/DD/YYYY"
                        id="1003"
                        name="1003"
                        formControlName="1003"
                        type="text"
                        data-id="sc license issue"
                        ngbDatepicker
                        #ga32="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('1003')"
                        (blur)="handleNAchange('cmd_048', 1003)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga32.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['1003'].touched &&
                        gaForm.controls['1003'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['1003'].errors"
                      >
                        required
                      </small>
                    </div>
                    <small class="text-danger" *ngIf="gaForm.get('1003').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('1003').errors?.invalidMonth">
                     Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaForm.get('1003').errors?.invalidDateyear">
                      year is out of range(1900-2099)</small>
                     <small class="text-danger" *ngIf="gaForm.get('1003').errors?.invalidDateRange">
                     date is out of range
                      </small>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Relinquished:
                    <div class="input-group">
                      <input
                        style="width: 40%"
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1004"
                        name="1004"
                        [class.is-invalid]="
                          (gaForm.controls['1004'].invalid)
                        "
                        formControlName="1004"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga33="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('1004')"
                        (blur)="handleNAchange('cmd_048', 1004)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga33.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          gaForm.controls['1004'].touched &&
                          gaForm.controls['1004'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['1004'].errors"
                        >
                          required
                        </small>
                      </div>
                      <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidMonth">
                       Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateyear">
                        year is out of range(1900-2099)</small>
                       <small class="text-danger" *ngIf="gaForm.get('1004').errors?.invalidDateRange">
                       date is out of range
                        </small>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Reason:
                    <input
                      class="form-control"
                      id="cmd_051"
                      name="cmd_051"
                      formControlName="cmd_051"
                      type="text"
                      data-id="sc license reason"
                    />
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              VI. CURRENT HOSPITAL AND OTHER FACILITY AFFILIATIONS
            </h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">
                    <b
                      >Please list in reverse chronological order with the
                      current affiliation(s) first:
                    </b>
                    <i
                      >(A) current hospital affiliations, (B) hospital
                      applications in process, (C) previous hospital
                      affiliations and (D) other current facility affiliations
                      (which includes surgery centers, dialysis centers, nursing
                      homes and other health care related facilities).</i
                    >
                    <b
                      >Do not list residencies, internships or fellowships.
                      Please list all employment in Section VII.</b
                    >
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="80%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >A. CURRENT HOSPITAL AFFILIATIONS</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Primary Facility Name:
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaForm.controls['418'].touched &&
                                gaForm.controls['418'].invalid) ||
                              (gaForm.controls['418'].invalid && isSubmit)
                            "
                            id="418"
                            name="418"
                            formControlName="418"
                            type="text"
                            data-id="hospaff1nm"
                          />
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['418'].touched &&
                              gaForm.controls['418'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['418'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaForm.controls['425'].touched &&
                                gaForm.controls['425'].invalid) ||
                              (gaForm.controls['425'].invalid && isSubmit)
                            "
                            id="425"
                            name="425"
                            formControlName="425"
                            type="text"
                            data-id="hospaff1dep"
                          />
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['425'].touched &&
                              gaForm.controls['425'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['425'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              [class.is-invalid]="
                                (gaForm.controls['426'].touched &&
                                  gaForm.controls['426'].invalid) ||
                                (gaForm.controls['426'].invalid && isSubmit)
                              "
                              placeholder="MM/DD/YYYY"
                              id="426"
                              name="426"
                              formControlName="426"
                              type="text"
                              data-id="hospaff1app"
                              ngbDatepicker
                              #ga34="ngbDatepicker"
                              (blur)="clearInputIfInvalidMandi('426')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga34.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                          </div>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['426'].touched &&
                              gaForm.controls['426'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['426'].errors"
                            >
                              required
                            </small>
                          </div>
                          <small class="text-danger" *ngIf="gaForm.get('426').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('426').errors?.invalidMonth">
                           Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaForm.get('426').errors?.invalidDateyear">
                            year is out of range(1900-2099)</small>
                           <small class="text-danger" *ngIf="gaForm.get('426').errors?.invalidDateRange">
                           date is out of range
                            </small>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="
                      (gaForm.controls['419'].touched &&
                        gaForm.controls['419'].invalid) ||
                      (gaForm.controls['419'].invalid && isSubmit)
                    "
                    placeholder="Address"
                    class="form-control"
                    id="419"
                    name="419"
                    formControlName="419"
                    type="text"
                    data-id="hospaff1add"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['419'].touched &&
                      gaForm.controls['419'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['419'].errors"
                    >
                      required
                    </small>
                  </div>
                  <br />
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['421'].touched &&
                        gaForm.controls['421'].invalid) ||
                      (gaForm.controls['421'].invalid && isSubmit)
                    "
                    class="form-control"
                    placeholder="City"
                    id="421"
                    name="421"
                    formControlName="421"
                    type="text"
                    data-id="hospaff1city"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['421'].touched &&
                      gaForm.controls['421'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['421'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4">
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaForm.controls['423'].touched &&
                          gaForm.controls['423'].invalid) ||
                        (gaForm.controls['423'].invalid && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="423"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaForm.controls['423'].touched &&
                        gaForm.controls['423'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaForm.controls['423'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['424'].touched &&
                        gaForm.controls['424'].invalid) ||
                      (gaForm.controls['424'].invalid && isSubmit)
                    "
                    class="form-control"
                    placeholder="Zip"
                    id="424"
                    name="424"
                    formControlName="424"
                    type="text"
                    data-id="hospaff1zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['424'].touched &&
                      gaForm.controls['424'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['424'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control "
                            id="427"
                            name="427"
                            formControlName="427"
                            type="text"
                            data-id="hospaff2nm"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control "
                            id="434"
                            name="434"
                            formControlName="434"
                            type="text"
                            data-id="hospaff2dep"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group"
                              placeholder="MM/DD/YYYY"
                              id="435"
                              name="435"
                              formControlName="435"
                              type="text"
                              data-id="hospaff2app"
                              ngbDatepicker
                              #ga35="ngbDatepicker"
                              (blur)="clearInputIfInvalid('435')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga35.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div *ngIf="gaForm.get('435').invalid ">
                              <small class="text-danger" *ngIf="gaForm.get('435').errors.invalidDateFormat">
                               Only Date Format is allowed(MM/DD/YYYY)</small>
                              <small class="text-danger" *ngIf="gaForm.get('435').errors.invalidMonth">
                              Month is out of range
                              </small>
                             <small class="text-danger" *ngIf="gaForm.get('435').errors.invalidDateyear">
                               year is out of range(1900-2099) </small>
                             <small class="text-danger" *ngIf="gaForm.get('435').errors.invalidDateRange">
                             date is out of range
                             </small>
                             </div>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="428"
                    name="428"
                    formControlName="428"
                    placeholder="Address"
                    type="text"
                    data-id="hospaff2add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="430"
                    name="430"
                    formControlName="430"
                    type="text"
                    placeholder="City"
                    data-id="hospaff2city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop suffix dropDrownSet">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="432"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="433"
                    name="433"
                    formControlName="433"
                    type="text"
                    placeholder="Zip"
                    data-id="hospaff2zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            id="436"
                            name="436"
                            formControlName="436"
                            type="text"
                            data-id="hospaff3nm"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control"
                            id="443"
                            name="443"
                            formControlName="443"
                            type="text"
                            data-id="hospaff3dep"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <input
                            class="form-control  datepicker input-group "
                            formControlName="444"
                            placeholder="MM/DD/YYYY"
                            id="444"
                            name="444"
                            type="text"
                            data-id="hospaff3app"
                            ngbDatepicker
                            #ga36="ngbDatepicker"
                            (blur)="clearInputIfInvalid('444')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga36.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                            <div *ngIf="gaForm.get('444').invalid ">
                              <small class="text-danger" *ngIf="gaForm.get('444').errors.invalidDateFormat">
                               Only Date Format is allowed(MM/DD/YYYY)</small>
                              <small class="text-danger" *ngIf="gaForm.get('444').errors.invalidMonth">
                              Month is out of range
                              </small>
                             <small class="text-danger" *ngIf="gaForm.get('444').errors.invalidDateyear">
                               year is out of range(1900-2099) </small>
                             <small class="text-danger" *ngIf="gaForm.get('444').errors.invalidDateRange">
                             date is out of range
                             </small>
                             </div>
                          </div>
                        
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="437"
                    name="437"
                    formControlName="437"
                    placeholder="Address"
                    type="text"
                    data-id="hospaff3add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    placeholder="City"
                    id="439"
                    name="439"
                    formControlName="439"
                    type="text"
                    data-id="hospaff3city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop suffix dropDrownSet">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="441"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="442"
                    name="442"
                    formControlName="442"
                    type="text"
                    placeholder="Zip"
                    data-id="hospaff3zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            id="986"
                            name="986"
                            formControlName="986"
                            type="text"
                            data-id="Hosp aff 4 name"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control"
                            id="993"
                            name="993"
                            formControlName="993"
                            type="text"
                            data-id="hospaff3dep"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <input
                            class="form-control  datepicker input-group "
                            placeholder="MM/DD/YYYY"
                            id="994"
                            name="994"
                            formControlName="994"
                            type="text"
                            data-id="Hosp aff 4 app"
                            ngbDatepicker
                            #ga37="ngbDatepicker"
                            (blur)="clearInputIfInvalid('994')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga37.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('994').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('994').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('994').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('994').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('994').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="987"
                    name="987"
                    formControlName="987"
                    type="text"
                    data-id="Hosp aff 4 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="989"
                    name="989"
                    formControlName="989"
                    type="text"
                    data-id="Hosp aff 4 city"
                    placeholder="city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix ">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="991"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="992"
                    name="992"
                    formControlName="992"
                    type="text"
                    data-id="Hosp aff 4 zip"
                    placeholder="zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14" width="80%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >B. HOSPITAL APPLICATIONS IN PROCESS</label
                  >
                  <i class="normalParagraphStyle"
                    >Please list all applications currently in process.</i
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_053"
                      name="cmd_053"
                      formControlName="cmd_053"
                      value="1"
                    />Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                        </label>
                        <input
                          class="form-control"
                          id="cmd_054"
                          name="cmd_054"
                          formControlName="cmd_054"
                          type="text"
                          data-id="Pending app 1"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                        </label>
                        <input
                          class="form-control"
                          id="cmd_055"
                          name="cmd_055"
                          formControlName="cmd_055"
                          type="text"
                          data-id="Pending app 1 status"
                        />
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <input
                            class="form-control  datepicker input-group"
                            placeholder="MM/DD/YYYY"
                            id="cmd_056"
                            name="cmd_056"
                            formControlName="cmd_056"
                            type="text"
                            data-id="Pending app 1 submit"
                            ngbDatepicker
                            #ga38="ngbDatepicker"
                            (blur)="clearInputIfInvalid('cmd_056')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga38.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('cmd_056').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('cmd_056').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_056').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_056').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_056').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="cmd_057"
                    name="cmd_057"
                    formControlName="cmd_057"
                    type="text"
                    data-id="Pending app 1 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_058"
                    name="cmd_058"
                    formControlName="cmd_058"
                    type="text"
                    data-id="Pending app 1 city"
                    placeholder="city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="cmd_059"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_060"
                    name="cmd_060"
                    formControlName="cmd_060"
                    type="text"
                    placeholder="zip"
                    data-id="Pending app 1 zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            id="cmd_061"
                            name="cmd_061"
                            formControlName="cmd_061"
                            type="text"
                            data-id="Pending app 2"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control"
                            id="cmd_062"
                            name="cmd_062"
                            formControlName="cmd_062"
                            type="text"
                            data-id="Pending app 2 status"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <input
                            class="form-control  datepicker input-group "
                            placeholder="MM/DD/YYYY"
                            id="cmd_063"
                            name="cmd_063"
                            formControlName="cmd_063"
                            type="text"
                            data-id="Pending app 2 sub"
                            ngbDatepicker
                            #ga39="ngbDatepicker"
                            (blur)="clearInputIfInvalid('cmd_063')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga39.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('cmd_063').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('cmd_063').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_063').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_063').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_063').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="cmd_064"
                    name="cmd_064"
                    formControlName="cmd_064"
                    type="text"
                    data-id="Pending app 2 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_065"
                    name="cmd_065"
                    formControlName="cmd_065"
                    type="text"
                    data-id="Pending app 2 city"
                    placeholder="city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="cmd_066"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    placeholder="zip"
                    id="cmd_067"
                    name="cmd_067"
                    formControlName="cmd_067"
                    type="text"
                    data-id="Pending app 2 zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            id="cmd_068"
                            name="cmd_068"
                            formControlName="cmd_068"
                            type="text"
                            data-id="Pending app 3"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin ">
                        <label class="normalParagraphStyle"
                          >Department/Status (e.g. active, courtesy,
                          provisional, etc.):
                          <input
                            class="form-control"
                            id="cmd_069"
                            name="cmd_069"
                            formControlName="cmd_069"
                            type="text"
                            data-id="Pending app 3 status"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Appointment Date (mm/yy):
                          <input
                            class="form-control  datepicker input-group "
                            placeholder="MM/DD/YYYY"
                            id="cmd_070"
                            name="cmd_070"
                            formControlName="cmd_070"
                            type="text"
                            data-id="Pending app 3 sub"
                            ngbDatepicker
                            #ga40="ngbDatepicker"
                            (blur)="clearInputIfInvalid('cmd_070')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga40.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('cmd_070').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('cmd_070').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('cmd_070').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_070').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('cmd_070').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="cmd_071"
                    name="cmd_071"
                    formControlName="cmd_071"
                    type="text"
                    data-id="Pending app 3 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    placeholder="city"
                    id="cmd_072"
                    name="cmd_072"
                    formControlName="cmd_072"
                    type="text"
                    data-id="Pending app 3 city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="cmd_073"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    placeholder="zip"
                    id="cmd_074"
                    name="cmd_074"
                    formControlName="cmd_074"
                    type="text"
                    data-id="Pending app 3 zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14" width="80%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >C. PREVIOUS HOSPITAL AFFILIATIONS</label
                  >
                  <i class="normalParagraphStyle"
                    >Please list all previous affiliations.</i
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_075"
                      name="cmd_075"
                      formControlName="cmd_075"
                      (ngModelChange)="handleNAchange('cmd_075')"
                      value="1"

                    />
                    <!-- (change)="preHosAff($event)" -->

                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaForm.controls['2025'].invalid)
                            "
                            id="2025"
                            name="2025"
                            formControlName="2025"
                            type="text"
                            data-id="Other aff 1"
                            (blur)="handleNAchange('cmd_075', 2025)"
                          />
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['2025'].touched &&
                              gaForm.controls['2025'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['2025'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >From (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              [class.is-invalid]="
                                (gaForm.controls['2042'].invalid)
                              "
                              placeholder="MM/DD/YYYY"
                              id="2042"
                              name="2042"
                              formControlName="2042"
                              type="text"
                              data-id="Other aff 1 from"
                              ngbDatepicker
                              #ga41="ngbDatepicker"
                              (blur)="clearInputIfInvalid('2042')"
                              (blur)="handleNAchange('cmd_075', 2042)"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga41.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                          </div>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['2042'].touched &&
                              gaForm.controls['2042'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['2042'].errors"
                            >
                              required
                            </small>
                          </div>
                          <small class="text-danger" *ngIf="gaForm.get('2042').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('2042').errors?.invalidMonth">
                           Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaForm.get('2042').errors?.invalidDateyear">
                            year is out of range(1900-2099)</small>
                           <small class="text-danger" *ngIf="gaForm.get('2042').errors?.invalidDateRange">
                           date is out of range
                            </small>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">To (mm/yy): </label>
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            [class.is-invalid]="
                              (gaForm.controls['2044'].invalid)
                            "
                            placeholder="MM/DD/YYYY"
                            id="2044"
                            name="2044"
                            formControlName="2044"
                            type="text"
                            data-id="Other aff 1 to"
                            ngbDatepicker
                            #ga42="ngbDatepicker"
                            (blur)="clearInputIfInvalid('2044')"
                            (blur)="handleNAchange('cmd_075', 2044)"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga42.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['2044'].touched &&
                            gaForm.controls['2044'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['2044'].errors"
                          >
                            required
                          </small>
                        </div>
                        <small class="text-danger" *ngIf="gaForm.get('2044').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)</small>
                        <small class="text-danger" *ngIf="gaForm.get('2044').errors?.invalidMonth">
                         Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaForm.get('2044').errors?.invalidDateyear">
                          year is out of range(1900-2099)</small>
                         <small class="text-danger" *ngIf="gaForm.get('2044').errors?.invalidDateRange">
                         date is out of range
                          </small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Reason for Leaving:
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaForm.controls['1303'].invalid)
                            "
                            id="1303"
                            name="1303"
                            formControlName="1303"
                            type="text"
                            data-id="Other aff 1 leaving"
                            (blur)="handleNAchange('cmd_075', 1303)"
                          />
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['1303'].touched &&
                              gaForm.controls['1303'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['1303'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    [class.is-invalid]="
                      (gaForm.controls['1184'].invalid)
                    "
                    id="1184"
                    name="1184"
                    formControlName="1184"
                    type="text"
                    data-id="Other aff 1 add"
                    (blur)="handleNAchange('cmd_075', 1184)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1184'].touched &&
                      gaForm.controls['1184'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1184'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['1186'].invalid)
                    "
                    class="form-control "
                    placeholder="city"
                    id="1186"
                    name="1186"
                    formControlName="1186"
                    type="text"
                    data-id="Other aff 1 city"
                    (blur)="handleNAchange('cmd_075', 1186)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1186'].touched &&
                      gaForm.controls['1186'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1186'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                >
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="1188"
                  >
                  </ng-select>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['1189'].invalid)
                    "
                    class="form-control"
                    placeholder="zip"
                    id="1189"
                    name="1189"
                    formControlName="1189"
                    type="text"
                    data-id="Other aff 1 zip"
                    (blur)="handleNAchange('cmd_075', 1189)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1189'].touched &&
                      gaForm.controls['1189'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1189'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            id="2046"
                            name="2046"
                            formControlName="2046"
                            type="text"
                            data-id="Other aff 2"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >From (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              placeholder="MM/DD/YYYY"
                              id="2057"
                              name="2057"
                              formControlName="2057"
                              type="text"
                              data-id="Other aff 2 from"
                              ngbDatepicker
                              #ga43="ngbDatepicker"
                              (blur)="clearInputIfInvalid('2057')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga43.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div *ngIf="gaForm.get('2057').invalid ">
                              <small class="text-danger" *ngIf="gaForm.get('2057').errors.invalidDateFormat">
                               Only Date Format is allowed(MM/DD/YYYY)</small>
                              <small class="text-danger" *ngIf="gaForm.get('2057').errors.invalidMonth">
                              Month is out of range
                              </small>
                             <small class="text-danger" *ngIf="gaForm.get('2057').errors.invalidDateyear">
                               year is out of range(1900-2099) </small>
                             <small class="text-danger" *ngIf="gaForm.get('2057').errors.invalidDateRange">
                             date is out of range
                             </small>
                             </div>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >To (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group"
                              placeholder="MM/DD/YYYY"
                              id="2059"
                              name="2059"
                              formControlName="2059"
                              type="text"
                              data-id="Other aff 2 to"
                              ngbDatepicker
                              #ga108="ngbDatepicker"
                              (blur)="clearInputIfInvalid('2059')"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga108.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div *ngIf="gaForm.get('2059').invalid ">
                              <small class="text-danger" *ngIf="gaForm.get('2059').errors.invalidDateFormat">
                               Only Date Format is allowed(MM/DD/YYYY)</small>
                              <small class="text-danger" *ngIf="gaForm.get('2059').errors.invalidMonth">
                              Month is out of range
                              </small>
                             <small class="text-danger" *ngIf="gaForm.get('2059').errors.invalidDateyear">
                               year is out of range(1900-2099) </small>
                             <small class="text-danger" *ngIf="gaForm.get('2059').errors.invalidDateRange">
                             date is out of range
                             </small>
                             </div>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Reason for Leaving:
                          <input
                            class="form-control"
                            id="cmd_076"
                            name="cmd_076"
                            formControlName="cmd_076"
                            type="text"
                            data-id="Other aff 2 leaving"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="2047"
                    formControlName="2047"
                    name="2047"
                    type="text"
                    data-id="Other aff 2 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="2049"
                    name="2049"
                    formControlName="2049"
                    type="text"
                    data-id="Other aff 2 city"
                  />
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                >
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="2051"
                  >
                  </ng-select>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['2052'].touched &&
                        gaForm.controls['2052'].invalid) ||
                      (gaForm.controls['2052'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="2052"
                    name="2052"
                    formControlName="2052"
                    type="text"
                    data-id="Other aff 2  zip"
                    (blur)="handleNAchange('cmd_075', 2052)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['2052'].touched &&
                      gaForm.controls['2052'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['2052'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14" width="80%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >D. OTHER FACILITY AFFILIATIONS</label
                  >
                  <i class="normalParagraphStyle"
                    >Please list all current affiliations with other
                    facilities.</i
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_077"
                      name="cmd_077"
                      (ngModelChange)="handleNAchange('cmd_077')"
                      formControlName="cmd_077"
                      value="1"
                    />
                    <!-- (change)="otherFacilityAff($event)" -->
                    Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Facility Name:
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaForm.controls['2061'].invalid)
                            "
                            id="2061"
                            name="2061"
                            formControlName="2061"
                            type="text"
                            data-id="Other aff 3"
                            (blur)="handleNAchange('cmd_077', 2061)"
                          />
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['2061'].touched &&
                              gaForm.controls['2061'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['2061'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >From (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              [class.is-invalid]="
                                (gaForm.controls['2072'].invalid)
                              "
                              placeholder="MM/DD/YYYY"
                              id="2072"
                              name="2072"
                              formControlName="2072"
                              type="text"
                              data-id="Other aff 3 from"
                              ngbDatepicker
                              #ga44="ngbDatepicker"
                              (blur)="clearInputIfInvalidMandi('2072')"
                              (blur)="handleNAchange('cmd_077', 2072)"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga44.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                          </div>

                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['2072'].touched &&
                              gaForm.controls['2072'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['2072'].errors"
                            >
                              required
                            </small>
                          </div>
                          <div>
                            <small class="text-danger" *ngIf="gaForm.get('2072').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('2072').errors?.invalidMonth">
                             Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="gaForm.get('2072').errors?.invalidDateyear">
                              year is out of range(1900-2099)</small>
                             <small class="text-danger" *ngIf="gaForm.get('2072').errors?.invalidDateRange">
                             date is out of range
                              </small>
                            </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">To (mm/yy): </label>

                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group "
                            [class.is-invalid]="
                              (gaForm.controls['2074'].invalid)
                            "
                            placeholder="MM/DD/YYYY"
                            id="2074"
                            name="2074"
                            formControlName="2074"
                            type="text"
                            data-id="Other aff 3  to"
                            ngbDatepicker
                            #ga46="ngbDatepicker"
                            (blur)="clearInputIfInvalidMandi('2074')"
                            (blur)="handleNAchange('cmd_077', 2074)"
                          />

                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga46.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['2074'].touched &&
                            gaForm.controls['2074'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['2074'].errors"
                          >
                            required
                          </small>
                        </div>
                        <div>
                          <small class="text-danger" *ngIf="gaForm.get('2074').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('2074').errors?.invalidMonth">
                           Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaForm.get('2074').errors?.invalidDateyear">
                            year is out of range(1900-2099)</small>
                           <small class="text-danger" *ngIf="gaForm.get('2074').errors?.invalidDateRange">
                           date is out of range
                            </small>
                          </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Reason for Leaving:
                          <input
                            class="form-control"
                            id="cmd_078"
                            name="cmd_078"
                            formControlName="cmd_078"
                            type="text"
                            data-id="Other aff 3 leaving"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    [class.is-invalid]="
                      (gaForm.controls['2062'].invalid)
                    "
                    class="form-control"
                    id="2062"
                    name="2062"
                    formControlName="2062"
                    type="text"
                    data-id="Other aff 3  add"
                    (blur)="handleNAchange('cmd_077', 2062)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['2062'].touched &&
                      gaForm.controls['2062'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['2062'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['2064'].invalid)
                    "
                    class="form-control"
                    id="2064"
                    name="2064"
                    formControlName="2064"
                    type="text"
                    data-id="Other aff 3 city"
                    (blur)="handleNAchange('cmd_077', 2064)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['2064'].touched &&
                      gaForm.controls['2064'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['2064'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                >
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['2066'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="2066"
                    (blur)="handleNAchange('cmd_077', 2066)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['2066'].touched &&
                      gaForm.controls['2066'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['2066'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaForm.controls['2067'].invalid)
                    "
                    class="form-control"
                    id="2067"
                    name="2067"
                    formControlName="2067"
                    type="text"
                    data-id="Other aff 3 zip"
                    (blur)="handleNAchange('cmd_077', 2067)"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['2067'].touched &&
                      gaForm.controls['2067'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['2067'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class=""
                          >Facility Name:
                          <input
                            class="form-control"
                            id="2076"
                            name="2076"
                            formControlName="2076"
                            type="text"
                            data-id="Other aff 4"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >From (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker input-group "
                              placeholder="MM/DD/YYYY"
                              id="2087"
                              name="2087"
                              formControlName="2087"
                              type="text"
                              data-id="Other aff 4 from"
                              ngbDatepicker
                              #ga47="ngbDatepicker"
                              (blur)="clearInputIfInvalid('2087')"

                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga47.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                            </div>
                            <div *ngIf="gaForm.get('2087').invalid ">
                              <small class="text-danger" *ngIf="gaForm.get('2087').errors.invalidDateFormat">
                               Only Date Format is allowed(MM/DD/YYYY)</small>
                              <small class="text-danger" *ngIf="gaForm.get('2087').errors.invalidMonth">
                              Month is out of range
                              </small>
                             <small class="text-danger" *ngIf="gaForm.get('2087').errors.invalidDateyear">
                               year is out of range(1900-2099) </small>
                             <small class="text-danger" *ngIf="gaForm.get('2087').errors.invalidDateRange">
                             date is out of range
                             </small>
                             </div>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >To (mm/yy):
                          <div class="input-group">
                            <input
                              class="form-control  datepicker"
                              placeholder="MM/DD/YYYY"
                              id="2089"
                              name="2089"
                              formControlName="2089"
                              type="text"
                              data-id="Other aff 4 to"
                              ngbDatepicker
                              #ga48="ngbDatepicker"
                              (blur)="clearInputIfInvalid('2089')"

                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary calendar"
                                (click)="ga48.toggle()"
                                type="button"
                              >
                                <i class="fas fa-calendar-alt"></i>
                              </button>
                              <div *ngIf="gaForm.get('2089').invalid ">
                                <small class="text-danger" *ngIf="gaForm.get('2089').errors.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY)</small>
                                <small class="text-danger" *ngIf="gaForm.get('2089').errors.invalidMonth">
                                Month is out of range
                                </small>
                               <small class="text-danger" *ngIf="gaForm.get('2089').errors.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                               <small class="text-danger" *ngIf="gaForm.get('2089').errors.invalidDateRange">
                               date is out of range
                               </small>
                               </div>
                            </div>

                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Reason for Leaving:
                          <input
                            class="form-control "
                            id="cmd_079"
                            name="cmd_079"
                            formControlName="cmd_079"
                            type="text"
                            data-id="Other aff 2 leaving"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    class="form-control "
                    id="2077"
                    name="2077"
                    formControlName="2077"
                    type="text"
                    data-id="Other aff 4 add"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control "
                    id="2079"
                    name="2079"
                    formControlName="2079"
                    type="text"
                    data-id="Other aff 4 city"
                  />
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                >
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="2081"
                  >
                  </ng-select>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="2082"
                    name="2082"
                    formControlName="2082"
                    type="text"
                    data-id="Other aff 4 zip"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="centered inputBorderBox">
          <h4 class="boardCertification">
            VII. PROFESSIONAL PRACTICE / WORK HISTORY
          </h4>
          <br />
          <i
            >A curriculum vitae is not sufficient for a complete answer to these
            questions.</i
          >
        </div>
        <table border="1">
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Please list in reverse chronological order all work and
                  professional and practice history activities not detailed
                  under Section II, IV or VI. Include any previous office
                  addresses and any military experience. Explain below any gaps
                  greater than thirty (30) days.
                </label>
              </div>
            </td>
          </tr>
        </table>

        <table border="1" class="tableWidth">
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Name of Current Practice / Employer:
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaForm.controls['445'].touched &&
                        gaForm.controls['445'].invalid) ||
                      (gaForm.controls['445'].invalid && isSubmit)
                    "
                    id="445"
                    name="445"
                    formControlName="445"
                    type="text"
                    data-id="wkhist1"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['445'].touched &&
                      gaForm.controls['445'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['445'].errors"
                    >
                      required
                    </small>
                  </div>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Name:
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['452'].touched &&
                              gaForm.controls['452'].invalid) ||
                            (gaForm.controls['452'].invalid && isSubmit)
                          "
                          id="452"
                          name="452"
                          formControlName="452"
                          type="text"
                          data-id="wk1histcontact"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['452'].touched &&
                            gaForm.controls['452'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['452'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          style="width: 50%"
                          [class.is-invalid]="
                            (gaForm.controls['453'].touched &&
                              gaForm.controls['453'].invalid) ||
                            (gaForm.controls['453'].invalid && isSubmit)
                          "
                          class="form-control"
                          id="453"
                          name="453"
                          formControlName="453"
                          type="text"
                          data-id="wk1histtelephone"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['453'].touched &&
                            gaForm.controls['453'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['453'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >From (mm/yy):
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            [class.is-invalid]="
                              (gaForm.controls['454'].touched &&
                                gaForm.controls['454'].invalid) ||
                              (gaForm.controls['454'].invalid && isSubmit)
                            "
                            placeholder="MM/DD/YYYY"
                            id="454"
                            name="454"
                            formControlName="454"
                            type="text"
                            data-id="wk1histstartdt"
                            ngbDatepicker
                            #ga49="ngbDatepicker"
                            (blur)="clearInputIfInvalidMandi('454')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga49.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>

                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['454'].touched &&
                            gaForm.controls['454'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['454'].errors"
                          >
                            required
                          </small>
                        </div>
                        <div>
                          <small class="text-danger" *ngIf="gaForm.get('454').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('454').errors?.invalidMonth">
                           Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaForm.get('454').errors?.invalidDateyear">
                            year is out of range(1900-2099)</small>
                           <small class="text-danger" *ngIf="gaForm.get('454').errors?.invalidDateRange">
                           date is out of range
                            </small>
                          </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">To (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control onboard datepicker"
                          [class.is-invalid]="
                            (gaForm.controls['455'].touched &&
                              gaForm.controls['455'].errors) ||
                            (gaForm.controls['455'].errors && isSubmit)
                          "
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #ga50="ngbDatepicker"
                          (blur)="clearInputIfInvalidMandi('455')"

                          id="455"
                          name="455"
                          formControlName="455"
                          type="text"
                          data-id="wk1histenddt"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga50.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        class="formLabel col-md-2"
                        *ngIf="
                          gaForm.controls['455'].touched &&
                          gaForm.controls['455'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['455'].errors"
                        >
                          required
                        </small>
                      </div>
                      <div>
                        <small class="text-danger" *ngIf="gaForm.get('455').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)</small>
                        <small class="text-danger" *ngIf="gaForm.get('455').errors?.invalidMonth">
                         Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaForm.get('455').errors?.invalidDateyear">
                          year is out of range(1900-2099)</small>
                         <small class="text-danger" *ngIf="gaForm.get('455').errors?.invalidDateRange">
                         date is out of range
                          </small>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <input
                        type="checkbox"
                        name="cmd_0206"
                        id="cmd_0206"
                        value="1"
                        (change)="proWorkHist($event)"
                        formControlName="cmd_0206"
                      />
                      <label class="formLabel">Present</label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <label class="normalParagraphStyle">Complete Address:</label
              ><br />
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  [class.is-invalid]="
                    (gaForm.controls['446'].touched &&
                      gaForm.controls['446'].invalid) ||
                    (gaForm.controls['446'].invalid && isSubmit)
                  "
                  placeholder="Address 1"
                  id="446"
                  name="446"
                  formControlName="446"
                  type="text"
                  data-id="wkhist1add"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['446'].touched &&
                    gaForm.controls['446'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['446'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  [class.is-invalid]="
                    (gaForm.controls['447'].touched &&
                      gaForm.controls['447'].invalid) ||
                    (gaForm.controls['447'].invalid && isSubmit)
                  "
                  placeholder="Address 2"
                  id="447"
                  name="447"
                  formControlName="447"
                  type="text"
                  data-id="wkhist1add2"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['447'].touched &&
                    gaForm.controls['447'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['447'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['448'].touched &&
                      gaForm.controls['448'].invalid) ||
                    (gaForm.controls['448'].invalid && isSubmit)
                  "
                  placeholder="City"
                  class="form-control"
                  id="448"
                  name="448"
                  formControlName="448"
                  type="text"
                  data-id="Work hist 1 city"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['448'].touched &&
                    gaForm.controls['448'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['448'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding suffix">
                <div class="mb-3 selectDrop dropDrownSet">
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['450'].touched &&
                        gaForm.controls['450'].invalid) ||
                      (gaForm.controls['450'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="450"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['450'].touched &&
                      gaForm.controls['450'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['450'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['451'].touched &&
                      gaForm.controls['451'].invalid) ||
                    (gaForm.controls['451'].invalid && isSubmit)
                  "
                  class="form-control"
                  placeholder="Zip"
                  id="451"
                  name="451"
                  formControlName="451"
                  type="text"
                  data-id="Work hist 1 zip"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['451'].touched &&
                    gaForm.controls['451'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['451'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Name of Previous Practice / Employer:
                  <input
                    class="form-control"
                    id="456"
                    name="456"
                    formControlName="456"
                    type="text"
                    data-id="wkhist2"
                  />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Name:
                        <input
                          class="form-control"
                          id="463"
                          name="463"
                          formControlName="463"
                          type="text"
                          data-id="wkhist2contact"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="464"
                          name="464"
                          formControlName="464"
                          type="text"
                          data-id="wkhist2telephone"
                        />
                      </label>
                    </div>
                  </td>
                  <td colspan="9"></td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >From (mm/yy):
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            placeholder="MM/DD/YYYY"
                            id="465"
                            name="465"
                            formControlName="465"
                            type="text"
                            data-id="wkhist2startdt"
                            ngbDatepicker
                            #ga51="ngbDatepicker"
                            (blur)="clearInputIfInvalid('465')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga51.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('465').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('465').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('465').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('465').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('465').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >To (mm/yy):
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group  "
                            placeholder="MM/DD/YYYY"
                            id="466"
                            name="466"
                            formControlName="466"
                            type="text"
                            data-id="wkhist2enddt"
                            ngbDatepicker
                            #ga52="ngbDatepicker"
                            (blur)="clearInputIfInvalid('466')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga52.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('466').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('466').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('466').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('466').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('466').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <label class="normalParagraphStyle">Complete Address:</label
              ><br />
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  placeholder="Address 1"
                  id="457"
                  name="457"
                  formControlName="457"
                  type="text"
                  data-id="wkhist1add"
                />
              </div>
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  placeholder="Address 2"
                  id="458"
                  name="458"
                  formControlName="458"
                  type="text"
                  data-id="wkhist1add2"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="459"
                  name="459"
                  formControlName="459"
                  type="text"
                  data-id="Work hist 1 city"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding suffix">
                <ng-select
                  [items]="states"
                  bindLabel="name"
                  placeholder="Select States"
                  bindValue="id"
                  formControlName="461"
                >
                </ng-select>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['462'].touched &&
                      gaForm.controls['462'].invalid) ||
                    (gaForm.controls['462'].invalid && isSubmit)
                  "
                  class="form-control"
                  placeholder="Zip"
                  id="462"
                  name="462"
                  formControlName="462"
                  type="text"
                  data-id="Work hist 1 zip"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['462'].touched &&
                    gaForm.controls['462'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['462'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Name of Previous Practice / Employer:
                  <input
                    class="form-control"
                    id="467"
                    name="467"
                    formControlName="467"
                    type="text"
                    data-id="wkhist3"
                  />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Name:
                        <input
                          class="form-control"
                          id="474"
                          name="474"
                          formControlName="474"
                          type="text"
                          data-id="wkhist3contact"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          style="width: 50%"
                          [class.is-invalid]="
                            (gaForm.controls['475'].touched &&
                              gaForm.controls['475'].invalid) ||
                            (gaForm.controls['475'].invalid && isSubmit)
                          "
                          class="form-control"
                          id="475"
                          name="475"
                          formControlName="475"
                          type="text"
                          data-id="wkhist3telephone"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['475'].touched &&
                            gaForm.controls['475'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['475'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9"></td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >From (mm/yy):
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            placeholder="MM/DD/YYYY"
                            id="476"
                            name="476"
                            formControlName="476"
                            type="text"
                            data-id="wkhist3startdt"
                            ngbDatepicker
                            #ga53="ngbDatepicker"
                            (blur)="clearInputIfInvalid('476')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga53.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('476').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('476').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('476').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('476').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('476').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >To (mm/yy):
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group "
                            placeholder="MM/DD/YYYY"
                            id="477"
                            name="477"
                            formControlName="477"
                            type="text"
                            data-id="wkhist3enddt"
                            ngbDatepicker
                            #ga101="ngbDatepicker"
                            (blur)="clearInputIfInvalid('477')"

                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga101.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                          <div *ngIf="gaForm.get('477').invalid ">
                            <small class="text-danger" *ngIf="gaForm.get('477').errors.invalidDateFormat">
                             Only Date Format is allowed(MM/DD/YYYY)</small>
                            <small class="text-danger" *ngIf="gaForm.get('477').errors.invalidMonth">
                            Month is out of range
                            </small>
                           <small class="text-danger" *ngIf="gaForm.get('477').errors.invalidDateyear">
                             year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="gaForm.get('477').errors.invalidDateRange">
                           date is out of range
                           </small>
                           </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <label class="normalParagraphStyle">Complete Address:</label
              ><br />
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  placeholder="Address 1"
                  id="468"
                  name="468"
                  formControlName="468"
                  type="text"
                  data-id="wkhist1add"
                />
              </div>
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
              >
                <input
                  class="form-control inputBoxStylesOne"
                  placeholder="Address 2"
                  id="469"
                  name="469"
                  formControlName="469"
                  type="text"
                  data-id="wkhist1add2"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  placeholder="City"
                  class="form-control"
                  id="470"
                  name="470"
                  formControlName="470"
                  type="text"
                  data-id="Work hist 1 city"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding suffix">
                <div class="mb-3 selectDrop dropDrownSet">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="472"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  [class.is-invalid]="
                    (gaForm.controls['475'].touched &&
                      gaForm.controls['475'].invalid) ||
                    (gaForm.controls['475'].invalid && isSubmit)
                  "
                  placeholder="Zip"
                  id="473"
                  name="473"
                  formControlName="473"
                  type="text"
                  data-id="Work hist 1 zip"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['475'].touched &&
                    gaForm.controls['475'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['475'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="16" width="70%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >If your training, practice, military or work experience has
                  been interrupted for more than thirty (30) days by, for
                  example, illness, injury or family medical leave, then please
                  explain below any such gap since completing medical
                  school.</label
                >
              </div>
            </td>
            <td colspan="4" width="30%">
              <label class="checkbox-inline radioStyle">
                <input
                  type="checkbox"
                  id="cmd_081"
                  name="cmd_081"
                  formControlName="cmd_081"
                  (ngModelChange)="handleNAchange('cmd_081')"
                  value="1"
                />
                <!-- (change)="expalanationRequired($event)" -->
                Dont Not Apply</label
              >
            </td>
          </tr>
          <tr>
            <td colspan="6" width="60%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Explanation of Interruption:</label
                >
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle">From (mm/yy):</label>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle">To (mm/yy):</label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="60%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['1138'].invalid)
                  "
                  class="form-control "
                  id="1138"
                  name="1138"
                  formControlName="1138"
                  type="text"
                  data-id="Gap 1"
                  (blur)="handleNAchange('cmd_081', 1138)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1138'].touched &&
                    gaForm.controls['1138'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1138'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div
                class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding input-group"
              >
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker"
                    placeholder="MM/DD/YYYY"
                    id="cmd_082"
                    name="cmd_082"
                    formControlName="cmd_082"
                    type="text"
                    data-id="Gap from 1"
                    ngbDatepicker
                    #ga54="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_082')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga54.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_082').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_082').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_082').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_082').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_082').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_083"
                    name="cmd_083"
                    formControlName="cmd_083"
                    type="text"
                    data-id="Gap to 1"
                    ngbDatepicker
                    #ga55="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_083')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga55.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_083').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_083').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_083').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_083').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_083').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="60%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['1831'].invalid)
                  "
                  class="form-control"
                  id="1831"
                  name="1831"
                  formControlName="1831"
                  type="text"
                  data-id="Gap 2"
                  (blur)="handleNAchange('cmd_081', 1831)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1831'].touched &&
                    gaForm.controls['1831'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1831'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker  input-group "
                    placeholder="MM/DD/YYYY"
                    id="cmd_084"
                    name="cmd_084"
                    formControlName="cmd_084"
                    type="text"
                    data-id="Gap from 2"
                    ngbDatepicker
                    #ga56="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_084')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga56.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_084').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_084').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_084').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_084').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_084').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_085"
                    name="cmd_085"
                    formControlName="cmd_085"
                    type="text"
                    data-id="Gap to 2"
                    ngbDatepicker
                    #ga57="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_085')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga57.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_085').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_085').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_085').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_085').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_085').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="60%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['1832'].invalid)
                  "
                  class="form-control "
                  id="1832"
                  name="1832"
                  formControlName="1832"
                  type="text"
                  data-id="Gap 3"
                  (blur)="handleNAchange('cmd_081', 1832)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1832'].touched &&
                    gaForm.controls['1832'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1832'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_086"
                    name="cmd_086"
                    formControlName="cmd_086"
                    type="text"
                    data-id="Gap from 3"
                    ngbDatepicker
                    #ga58="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_086')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga58.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_086').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_086').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_086').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_086').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_086').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="6" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div class="input-group">
                  <input
                    style="width: 50%"
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_087"
                    name="cmd_087"
                    formControlName="cmd_087"
                    type="text"
                    data-id="Gap to 3"
                    ngbDatepicker
                    #ga59="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_087')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga59.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_087').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_087').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_087').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_087').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_087').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div class="centered inputBorderBox">
          <h4 class="boardCertification">VIII. PEER REFERENCES</h4>
        </div>
        <table border="1">
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Please list three (3) references, from licensed professional
                  peers who through recent observations have personal knowledge
                  of and are directly familiar with your professional
                  competence, conduct and work. Do not include relatives. At
                  least one reference must be a practitioner in your same
                  professional discipline. (Please refer to Part Two of this
                  Application for any additional specific reference
                  requirements.)</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Reference:
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['359'].touched &&
                              gaForm.controls['359'].invalid) ||
                            (gaForm.controls['359'].invalid && isSubmit)
                          "
                          id="359"
                          name="359"
                          formControlName="359"
                          type="text"
                          data-id="reference1name"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['359'].touched &&
                            gaForm.controls['359'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['359'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin">
                      <label class="normalParagraphStyle"
                        >Specialty:
                        <div class="mb-3 selectDrop inputPadding suffix">
                          <ng-select
                            [items]="specialty"
                            [class.is-invalid]="
                              (gaForm.controls['368'].touched &&
                                gaForm.controls['368'].invalid) ||
                              (gaForm.controls['368'].invalid && isSubmit)
                            "
                            bindLabel="name"
                            placeholder="Select Specialty"
                            bindValue="id"
                            formControlName="368"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['368'].touched &&
                              gaForm.controls['368'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['368'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Dates of Association:</label
                      >
                      <div class="input-group">
                        <input
                          class="form-control  datepicker  input-group "
                          [class.is-invalid]="
                            (gaForm.controls['366'].touched &&
                              gaForm.controls['366'].invalid) ||
                            (gaForm.controls['366'].invalid && isSubmit)
                          "
                          placeholder="MM/DD/YYYY"
                          id="366"
                          name="366"
                          formControlName="366"
                          type="text"
                          data-id="reference1date1"
                          ngbDatepicker
                          #ga60="ngbDatepicker"
                          (blur)="clearInputIfInvalidMandi('366')"

                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga60.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      -
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['366'].touched &&
                          gaForm.controls['366'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['366'].errors"
                        >
                          required
                        </small>
                      </div>
                      <div>
                        <small class="text-danger" *ngIf="gaForm.get('366').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)</small>
                        <small class="text-danger" *ngIf="gaForm.get('366').errors?.invalidMonth">
                         Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaForm.get('366').errors?.invalidDateyear">
                          year is out of range(1900-2099)</small>
                         <small class="text-danger" *ngIf="gaForm.get('366').errors?.invalidDateRange">
                         date is out of range
                          </small>
                        </div>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          [class.is-invalid]="
                            (gaForm.controls['367'].touched &&
                              gaForm.controls['367'].invalid) ||
                            (gaForm.controls['367'].invalid && isSubmit)
                          "
                          placeholder="MM/DD/YYYY"
                          id="367"
                          name="367"
                          formControlName="367"
                          type="text"
                          data-id="reference1date2"
                          ngbDatepicker
                          #ga62="ngbDatepicker"
                          (blur)="clearInputIfInvalidMandi('367')"

                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga62.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['367'].touched &&
                          gaForm.controls['367'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['367'].errors"
                        >
                          required
                        </small>
                      </div>
                      <div>
                        <small class="text-danger" *ngIf="gaForm.get('367').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)</small>
                        <small class="text-danger" *ngIf="gaForm.get('367').errors?.invalidMonth">
                         Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaForm.get('367').errors?.invalidDateyear">
                          year is out of range(1900-2099)</small>
                         <small class="text-danger" *ngIf="gaForm.get('367').errors?.invalidDateRange">
                         date is out of range
                          </small>
                        </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          class="form-control "
                          [class.is-invalid]="
                            (gaForm.controls['369'].touched &&
                              gaForm.controls['369'].invalid) ||
                            (gaForm.controls['369'].invalid && isSubmit)
                          "
                          id="369"
                          name="369"
                          formControlName="369"
                          type="text"
                          data-id="reference1telephone"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['369'].touched &&
                            gaForm.controls['369'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['369'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Fax Number:
                        <input
                          class="form-control"
                          id="370"
                          name="370"
                          formControlName="370"
                          type="text"
                          data-id="reference1fax"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Email:</label>
                <input
                  style="width: 90%;"
                  [class.is-invalid]="
                    (gaForm.controls['371'].touched &&
                      gaForm.controls['371'].invalid) ||
                    (gaForm.controls['371'].invalid && isSubmit)
                  "
                  class="form-control"
                  id="371"
                  name="371"
                  formControlName="371"
                  type="text"
                  data-id="reference1address"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['371'].touched &&
                    gaForm.controls['371'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['371'].errors"
                  >
                    required
                  </small>
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['371'].errors.pattern"
                    >Please enter a valid email.</small
                  >
                </div>
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Complete Address:</label>
                <input
                  class="form-control inputBoxStylesOne"
                  [class.is-invalid]="
                    (gaForm.controls['360'].touched &&
                      gaForm.controls['360'].invalid) ||
                    (gaForm.controls['360'].invalid && isSubmit)
                  "
                  id="360"
                  name="360"
                  formControlName="360"
                  type="text"
                  data-id="reference1address"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['360'].touched &&
                    gaForm.controls['360'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['360'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['362'].touched &&
                      gaForm.controls['362'].invalid) ||
                    (gaForm.controls['362'].invalid && isSubmit)
                  "
                  class="form-control"
                  id="362"
                  name="362"
                  formControlName="362"
                  type="text"
                  data-id="reference1city"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['362'].touched &&
                    gaForm.controls['362'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['362'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <div class="mb-3 selectDrop dropDrownSet">
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['364'].touched &&
                        gaForm.controls['364'].invalid) ||
                      (gaForm.controls['364'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="364"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['364'].touched &&
                      gaForm.controls['364'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['364'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['365'].touched &&
                      gaForm.controls['365'].invalid) ||
                    (gaForm.controls['365'].invalid && isSubmit)
                  "
                  class="form-control"
                  id="365"
                  name="365"
                  formControlName="365"
                  type="text"
                  data-id="reference1zip"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['365'].touched &&
                    gaForm.controls['365'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['365'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Reference:
                        <input
                          class="form-control"
                          id="372"
                          name="372"
                          formControlName="372"
                          type="text"
                          data-id="reference2name"
                        />
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Specialty:
                        <div class="mb-3 selectDrop suffix">
                          <ng-select
                            [items]="specialty"
                            bindLabel="name"
                            placeholder="Select Specialty"
                            bindValue="id"
                            formControlName="380"
                          >
                          </ng-select>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Dates of Association:</label
                      >
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group "
                          placeholder="MM/DD/YYYY"
                          id="817"
                          name="817"
                          formControlName="817"
                          type="text"
                          data-id="reference2date1"
                          ngbDatepicker
                          #ga63="ngbDatepicker"
                          (blur)="clearInputIfInvalid('817')"

                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga63.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div *ngIf="gaForm.get('817').invalid ">
                          <small class="text-danger" *ngIf="gaForm.get('817').errors.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('817').errors.invalidMonth">
                          Month is out of range
                          </small>
                         <small class="text-danger" *ngIf="gaForm.get('817').errors.invalidDateyear">
                           year is out of range(1900-2099) </small>
                         <small class="text-danger" *ngIf="gaForm.get('817').errors.invalidDateRange">
                         date is out of range
                         </small>
                         </div>
                      </div>

                      -
                      <div class="input-group">
                        <input
                          class="form-control  datepicker"
                          placeholder="MM/DD/YYYY"
                          id="379"
                          name="379"
                          formControlName="379"
                          type="text"
                          data-id="reference2date2"
                          ngbDatepicker
                          #ga64="ngbDatepicker"
                          (blur)="clearInputIfInvalid('379')"

                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga64.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div *ngIf="gaForm.get('379').invalid ">
                          <small class="text-danger" *ngIf="gaForm.get('379').errors.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('379').errors.invalidMonth">
                          Month is out of range
                          </small>
                         <small class="text-danger" *ngIf="gaForm.get('379').errors.invalidDateyear">
                           year is out of range(1900-2099) </small>
                         <small class="text-danger" *ngIf="gaForm.get('379').errors.invalidDateRange">
                         date is out of range
                         </small>
                         </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          class="form-control"
                          id="381"
                          name="381"
                          formControlName="381"
                          type="text"
                          data-id="reference2telephone"
                        />
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Fax Number:
                        <input
                          id="382"
                          name="382"
                          formControlName="382"
                          type="text"
                          data-id="reference2fax"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Email:</label>
                <input
                  style="width: 90%;"
                  class="form-control"
                  id="383"
                  name="383"
                  formControlName="383"
                  type="text"
                  data-id="reference1address"
                />
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Complete Address:</label>
                <input
                  class="form-control inputBoxStylesOne"
                  id="373"
                  name="373"
                  formControlName="373"
                  type="text"
                  data-id="reference1address"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="375"
                  name="375"
                  formControlName="375"
                  type="text"
                  data-id="reference1city"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <div class="mb-3 selectDrop dropDrownSet suffix">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="377"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="378"
                  name="378"
                  formControlName="378"
                  type="text"
                  data-id="reference1zip"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Reference:
                        <input
                          class="form-control"
                          id="384"
                          name="384"
                          formControlName="384"
                          type="text"
                          data-id="reference3name"
                        />
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Specialty:
                        <div class="mb-3 selectDrop suffix">
                          <ng-select
                            [items]="specialty"
                            bindLabel="name"
                            placeholder="Select Specialty"
                            bindValue="id"
                            formControlName="393"
                          >
                          </ng-select>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Dates of Association:</label
                      >
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          placeholder="MM/DD/YYYY"
                          id="391"
                          name="391"
                          formControlName="391"
                          type="text"
                          data-id="reference3date1"
                          ngbDatepicker
                          #ga102="ngbDatepicker"
                          (blur)="clearInputIfInvalid('391')"

                        />

                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga102.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div *ngIf="gaForm.get('391').invalid ">
                          <small class="text-danger" *ngIf="gaForm.get('391').errors.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('391').errors.invalidMonth">
                          Month is out of range
                          </small>
                         <small class="text-danger" *ngIf="gaForm.get('391').errors.invalidDateyear">
                           year is out of range(1900-2099) </small>
                         <small class="text-danger" *ngIf="gaForm.get('391').errors.invalidDateRange">
                         date is out of range
                         </small>
                         </div>
                      </div>

                      -
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          placeholder="MM/DD/YYYY"
                          id="392"
                          name="392"
                          formControlName="392"
                          type="text"
                          data-id="reference3date2"
                          ngbDatepicker
                          #ga65="ngbDatepicker"
                          (blur)="clearInputIfInvalid('392')"

                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga65.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div *ngIf="gaForm.get('392').invalid ">
                          <small class="text-danger" *ngIf="gaForm.get('392').errors.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="gaForm.get('392').errors.invalidMonth">
                          Month is out of range
                          </small>
                         <small class="text-danger" *ngIf="gaForm.get('392').errors.invalidDateyear">
                           year is out of range(1900-2099) </small>
                         <small class="text-danger" *ngIf="gaForm.get('392').errors.invalidDateRange">
                         date is out of range
                         </small>
                         </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          class="form-control"
                          id="394"
                          name="394"
                          formControlName="394"
                          type="text"
                          data-id="reference3telephone"
                        />
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div
                      class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                    >
                      <label class="normalParagraphStyle"
                        >Fax Number:
                        <input
                          id="395"
                          name="395"
                          formControlName="395"
                          type="text"
                          data-id="reference3fax"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Email:</label>
                <input
                  id="396"
                  name="396"
                  formControlName="396"
                  type="text"
                  data-id="reference1address"
                />
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Complete Address:</label>
                <input
                  class="form-control inputBoxStylesThree"
                  id="385"
                  name="385"
                  formControlName="385"
                  type="text"
                  data-id="reference1address"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="387"
                  name="387"
                  formControlName="387"
                  type="text"
                  data-id="reference1city"
                />
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <div class="mb-3 selectDrop dropDrownSet suffix">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="389"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="390"
                  name="390"
                  formControlName="390"
                  type="text"
                  data-id="reference1zip"
                />
              </div>
            </td>
          </tr>
        </table>

        <div class="centered inputBorderBox">
          <h4 class="boardCertification">
            IX. PROFESSIONAL LIABILITY INSURANCE
          </h4>
          <label class="floatInputs">Does Not Apply</label>
          <input
            type="checkbox"
            name="cmd_0201"
            id="cmd_0201"
            formControlName="cmd_0201"
            (ngModelChange)="handleNAchange('cmd_0201')"
            style="float: right;"
          />
        </div>
        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Current Insurance Carrier / Provider of Professional
                  Liability Coverage:
                </label>
                <input
                  class="form-control "
                  [class.is-invalid]="
                    (gaForm.controls['345'].invalid)
                  "
                  id="345"
                  name="345"
                  formControlName="345"
                  type="text"
                  data-id="mlp1carrier"
                  (blur)="handleNAchange('cmd_0201', 345)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['345'].touched &&
                    gaForm.controls['345'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['345'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Policy Number</label>
                <input
                  class="form-control "
                  [class.is-invalid]="
                    (gaForm.controls['346'].invalid)
                  "
                  id="346"
                  name="346"
                  formControlName="346"
                  type="text"
                  data-id="mlp1pol"
                  (blur)="handleNAchange('cmd_0201', 346)"

                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['346'].touched &&
                    gaForm.controls['346'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['346'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_088"
                    formControlName="cmd_088"
                    name="cmd_088"
                    value="0"
                  />Claims-Made</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_088"
                    formControlName="cmd_088"
                    name="cmd_088"
                    value="1"
                  />Occurrence</label
                >
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="12" width="60%">
              <table border="1">
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Local Contact (e.g. Insurance Agent or Broker):
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['1240'].invalid)
                          "
                          id="1240"
                          name="1240"
                          formControlName="1240"
                          type="text"
                          data-id="MLP agent"
                          (blur)="handleNAchange('cmd_0201', 1240)"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['1240'].touched &&
                            gaForm.controls['1240'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['1240'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Telephone Number:
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['1266'].invalid)
                          "
                          id="1266"
                          name="1266"
                          formControlName="1266"
                          type="text"
                          data-id="MLP Phone"
                          (blur)="handleNAchange('cmd_0201', 1266)"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['1266'].touched &&
                            gaForm.controls['1266'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['1266'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Per claim limit of liability:
                        <div class="mb-3 selectDrop dropDrownSet suffix">
                          <ng-select
                            [items]="preClaim"
                            [class.is-invalid]="
                              (gaForm.controls['350'].invalid)
                            "
                            bindLabel="name"
                            placeholder="Select PreClaim"
                            bindValue="id"
                            formControlName="350"
                            (blur)="handleNAchange('cmd_0201', 350)"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['350'].touched &&
                              gaForm.controls['350'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['350'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Aggregate amount:
                        <div class="mb-3 selectDrop dropDrownSet suffix">
                          <ng-select
                            [items]="aggregates"
                            [class.is-invalid]="
                              (gaForm.controls['351'].invalid)
                            "
                            bindLabel="name"
                            placeholder="Select PreClaim"
                            bindValue="id"
                            formControlName="351"
                            (blur)="handleNAchange('cmd_0201', 351)"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['351'].touched &&
                              gaForm.controls['351'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['351'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" style="width: 40%;">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Mailing Address:</label>
                <input
                  style="width: 100%;margin-bottom: 2px;"
                  [class.is-invalid]="
                    (gaForm.controls['901'].invalid)
                  "
                  class="form-control"
                  id="901"
                  formControlName="901"
                  name="901"
                  type="text"
                  data-id="MLP Mailing Add"
                  (blur)="handleNAchange('cmd_0201', 901)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['901'].touched &&
                    gaForm.controls['901'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['901'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['903'].invalid)
                  "
                  class="form-control "
                  id="903"
                  name="903"
                  formControlName="903"
                  type="text"
                  data-id="MLP City"
                  (blur)="handleNAchange('cmd_0201', 903)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['903'].touched &&
                    gaForm.controls['903'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['903'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <div class="mb-3 selectDrop dropDrownSet suffix">
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['905'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="905"
                    (blur)="handleNAchange('cmd_0201', 905)"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['905'].touched &&
                      gaForm.controls['905'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['905'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </div>
              <div class="mb-3 no-margin col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['906'].invalid)
                  "
                  class="form-control"
                  id="906"
                  name="906"
                  formControlName="906"
                  type="text"
                  data-id="MLP Zip"
                  (blur)="handleNAchange('cmd_0201', 906)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['906'].touched &&
                    gaForm.controls['906'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['906'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Effective Date (mm/yy):
                </label>
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    [class.is-invalid]="
                      (gaForm.controls['348'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="348"
                    name="348"
                    formControlName="348"
                    type="text"
                    data-id="mlp1dateeff_date"
                    ngbDatepicker
                    #ga66="ngbDatepicker"
                    (blur)="clearInputIfInvalid('348')"
                    (blur)="handleNAchange('cmd_0201', 348)"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga66.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['348'].touched &&
                    gaForm.controls['348'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['348'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('348').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('348').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('348').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('348').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Expiration Date (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    [class.is-invalid]="
                      (gaForm.controls['349'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="349"
                    name="349"
                    formControlName="349"
                    type="text"
                    data-id="mlp1dateexp_date"
                    ngbDatepicker
                    #ga67="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('349')"
                    (blur)="handleNAchange('cmd_0201', 349)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga67.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['349'].touched &&
                    gaForm.controls['349'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['349'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('349').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('349').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('349').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('349').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Retroactive Date, if applicable (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    [class.is-invalid]="
                      (gaForm.controls['945'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="945"
                    name="945"
                    formControlName="945"
                    type="text"
                    data-id="MLP Retro date"
                    ngbDatepicker
                    #ga68="ngbDatepicker"
                    (blur)="clearInputIfInvalid('945')"
                    (blur)="handleNAchange('cmd_0201', 945)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga68.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['945'].touched &&
                    gaForm.controls['945'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['945'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('945').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('945').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('945').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('945').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="18" width="100%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >If you have changed your coverage within the last ten years,
                  did you purchase tail and/or nose (prior occurrence/acts)
                  coverage?
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_089"
                    name="cmd_089"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_089'].touched &&
                        gaForm.controls['cmd_089'].errors) ||
                      (gaForm.controls['cmd_089'].errors && isSubmit)
                    "
                    formControlName="cmd_089"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_089'].touched &&
                    gaForm.controls['cmd_089'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_089'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_089"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_089'].touched &&
                        gaForm.controls['cmd_089'].invalid) ||
                      (gaForm.controls['cmd_089'].invalid && isSubmit)
                    "
                    name="cmd_089"
                    formControlName="cmd_089"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_089'].touched &&
                    gaForm.controls['cmd_089'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_089'].errors"
                  >
                    required
                  </small>
                </div>

                <span
                  *ngIf="
                    (gaForm.controls['cmd_089'].touched &&
                      gaForm.controls['cmd_089'].invalid) ||
                    (gaForm.controls['cmd_089'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
              <label class="normalParagraphStyle"
                >If yes, please provide details/supporting data. If no, please
                explain why not on an Explanation Form of the Application
              </label>
              <br />
              <label class="boldParagraphStyle">
                NOTE: IF YOU ARE COVERED BY A MEDICAL PROFESSIONAL LIABILITY
                INSURANCE PROGRAM THAT IS A CLAIMS MADE POLICY, YOU ARE REQUIRED
                TO SHOW EVIDENCE OF PURCHASE OF CURRENT REPORTING ENDORSEMENT
                COVERAGE (TAIL COVERAGE) OR PRIOR OCCURRENCE/ACTS COVERAGE TO
                COVER PREVIOUS YEARS OF PRACTICE.
              </label>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="14" width="80%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Please list all previous professional liability carriers
                  within the past ten (10) years (including any carriers during
                  medical training if within the ten year period).</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="checkbox"
                    id="cmd_090"
                    name="cmd_090"
                    formControlName="cmd_090"
                    (ngModelChange)="handleNAchange('cmd_090')"
                    value="1"
                  />
                  Dont Not Apply</label
                >
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Current Insurance Carrier / Provider of Professional
                  Liability Coverage:
                </label>
                <input
                  class="form-control"
                  [class.is-invalid]="
                    (gaForm.controls['1242'].invalid)
                  "
                  id="1242"
                  name="1242"
                  formControlName="1242"
                  type="text"
                  data-id="MLP Carrier 2"
                  (blur)="handleNAchange('cmd_090', 1242)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1242'].touched &&
                    gaForm.controls['1242'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1242'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Policy Number</label>
                <input
                  class="form-control"
                  [class.is-invalid]="
                    (gaForm.controls['1256'].invalid)
                  "
                  id="1256"
                  name="1256"
                  formControlName="1256"
                  type="text"
                  data-id="MLP 2 pol"
                  (blur)="handleNAchange('cmd_090', 1256)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1256'].touched &&
                    gaForm.controls['1256'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1256'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_092"
                    formControlName="cmd_092"
                    name="cmd_092"
                    value="0"
                  />Claims-Made</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_092"
                    formControlName="cmd_092"
                    name="cmd_092"
                    value="1"
                  />Occurrence</label
                >
              </div>
            </td>
          </tr>
        </table>

        <table border="1" width="100%">
          <tr>
            <td colspan="12" width="60%">
              <table border="1" width="100%">
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Local Contact (e.g. Insurance Agent or Broker):
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['1243'].invalid)
                          "
                          id="1243"
                          name="1243"
                          formControlName="1243"
                          type="text"
                          data-id="MLP contact 2"
                          (blur)="handleNAchange('cmd_090', 1243)"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['1243'].touched &&
                            gaForm.controls['1243'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['1243'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Telephone Number:
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaForm.controls['1254'].invalid)
                          "
                          id="1254"
                          name="1254"
                          formControlName="1254"
                          type="text"
                          data-id="MLP Phone 2"
                          (blur)="handleNAchange('cmd_090', 1254)"

                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaForm.controls['1254'].touched &&
                            gaForm.controls['1254'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaForm.controls['1254'].errors"
                          >
                            required
                          </small>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Per claim limit of liability:
                        <div class="mb-3 selectDrop dropDrownSet suffix">
                          <ng-select
                            [items]="preClaim"
                            [class.is-invalid]="
                              (gaForm.controls['1264'].invalid)
                            "
                            bindLabel="name"
                            placeholder="Select PreClaim"
                            bindValue="id"
                            formControlName="1264"
                            (blur)="handleNAchange('cmd_090', 1264)"

                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['1264'].touched &&
                              gaForm.controls['1264'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['1264'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                        <!-- <div class="mb-3 selectDrop dropDrownSet suffix">
                          <ng-select
                            [items]="aggregates"
                            bindLabel="name"
                            placeholder="Select Aggregate"
                            bindValue="id"
                            formControlName="351"
                          >
                          </ng-select>
                        </div> -->
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Aggregate amount:
                        <div class="mb-3 selectDrop dropDrownSet suffix">
                          <ng-select
                            [items]="aggregates"
                            [class.is-invalid]="
                              (gaForm.controls['1265'].invalid)
                            "
                            bindLabel="name"
                            placeholder="Select Aggregate"
                            bindValue="id"
                            formControlName="1265"
                            (blur)="handleNAchange('cmd_090', 1265)"

                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaForm.controls['1265'].touched &&
                              gaForm.controls['1265'].errors
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaForm.controls['1265'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Mailing Address:</label>
                <input
                  class="form-control inputBoxStylesThree"
                  [class.is-invalid]="
                    (gaForm.controls['1245'].invalid)
                  "
                  id="1245"
                  name="1245"
                  formControlName="1245"
                  type="text"
                  data-id="MLP 2 address"
                  (blur)="handleNAchange('cmd_090', 1245)"

                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1245'].touched &&
                    gaForm.controls['1245'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1245'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['1246'].invalid)
                  "
                  class="form-control "
                  id="1246"
                  name="1246"
                  formControlName="1246"
                  type="text"
                  data-id="mlp 2 city"
                  (blur)="handleNAchange('cmd_090', 1246)"

                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1246'].touched &&
                    gaForm.controls['1246'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1246'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4">
                <div class="mb-3 selectDrop dropDrownSet suffix">
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaForm.controls['1248'].invalid)
                    "
                    bindLabel="name"
                    placeholder="Select states"
                    bindValue="id"
                    formControlName="1248"
                    (blur)="handleNAchange('cmd_090', 1248)"

                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaForm.controls['1248'].touched &&
                      gaForm.controls['1248'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaForm.controls['1248'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  [class.is-invalid]="
                    (gaForm.controls['1249'].invalid)
                  "
                  class="form-control "
                  id="1249"
                  name="1249"
                  formControlName="1249"
                  type="text"
                  data-id="MLP 2 zip"
                  (blur)="handleNAchange('cmd_090', 1249)"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1249'].touched &&
                    gaForm.controls['1249'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1249'].errors"
                  >
                    required
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Effective Date (mm/yy):
                </label>
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group "
                    [class.is-invalid]="
                      (gaForm.controls['1252'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="1252"
                    name="1252"
                    formControlName="1252"
                    type="text"
                    data-id="MLP Eff date 2"
                    ngbDatepicker
                    #ga69="ngbDatepicker"
                    (blur)="clearInputIfInvalid('1252')"
                    (blur)="handleNAchange('cmd_090', 1252)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga69.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1252'].touched &&
                    gaForm.controls['1252'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1252'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('1252').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('1252').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('1252').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('1252').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Retroactive Date, if applicable (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group "
                    [class.is-invalid]="
                      (gaForm.controls['1251'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="1251"
                    name="1251"
                    formControlName="1251"
                    type="text"
                    data-id="MLP retro date 2"
                    ngbDatepicker
                    #ga70="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('1251')"
                    (blur)="handleNAchange('cmd_090', 1251)"


                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga70.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1251'].touched &&
                    gaForm.controls['1251'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1251'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('1251').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('1251').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('1251').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('1251').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Expiration Date (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    [class.is-invalid]="
                      (gaForm.controls['1253'].invalid)
                    "
                    placeholder="MM/DD/YYYY"
                    id="1253"
                    name="1253"
                    formControlName="1253"
                    type="text"
                    data-id="MLP Exp date 2"
                    ngbDatepicker
                    #ga71="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('1253')"
                    (blur)="handleNAchange('cmd_090', 1253)"


                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga71.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['1253'].touched &&
                    gaForm.controls['1253'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['1253'].errors"
                  >
                    required
                  </small>
                </div>
                <div>
                  <small class="text-danger" *ngIf="gaForm.get('1253').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="gaForm.get('1253').errors?.invalidMonth">
                   Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaForm.get('1253').errors?.invalidDateyear">
                    year is out of range(1900-2099)</small>
                   <small class="text-danger" *ngIf="gaForm.get('1253').errors?.invalidDateRange">
                   date is out of range
                    </small>
                  </div>
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Current Insurance Carrier / Provider of Professional
                  Liability Coverage:
                </label>
                <input
                  class="form-control"
                  id="cmd_093"
                  name="cmd_093"
                  formControlName="cmd_093"
                  type="text"
                  data-id="MLP 3 carrier"
                />
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Policy Number</label>
                <input
                  class="form-control"
                  id="cmd_094"
                  name="cmd_094"
                  formControlName="cmd_094"
                  type="text"
                  data-id="MLP 3 Pol"
                />
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_095"
                    name="cmd_095"
                    formControlName="cmd_095"
                    value="0"
                  />Claims-Made</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_095"
                    name="cmd_095"
                    formControlName="cmd_095"
                    value="1"
                  />Occurrence</label
                >
              </div>
            </td>
          </tr>
        </table>

        <table border="1" style="width: 88.3rem;">
          <tr>
            <td colspan="12" width="60%">
              <table border="1">
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Name of Local Contact (e.g. Insurance Agent or Broker):
                        <input
                          class="form-control"
                          id="cmd_096"
                          name="cmd_096"
                          formControlName="cmd_096"
                          type="text"
                          data-id="MLP 3 contact"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="18">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Contact Telephone Number:
                        <input
                          class="form-control"
                          id="cmd_097"
                          name="cmd_097"
                          formControlName="cmd_097"
                          type="text"
                          data-id="MLP 3 phone"
                        />
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Per claim limit of liability:
                        <ng-select
                          [items]="preClaim"
                          bindLabel="name"
                          placeholder="Select PreClaim"
                          bindValue="id"
                          formControlName="cmd_098"
                        >
                        </ng-select>
                      </label>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Aggregate amount:
                        <ng-select
                          [items]="aggregates"
                          bindLabel="name"
                          placeholder="Select Aggregate"
                          bindValue="id"
                          formControlName="cmd_099"
                        >
                        </ng-select>
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="6" width="40%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Mailing Address:</label>
                <input
                  style="width: 100%;margin-bottom: 2px;"
                  class="form-control "
                  id="cmd_0100"
                  name="cmd_0100"
                  formControlName="cmd_0100"
                  type="text"
                  data-id="MLP 3 add"
                />
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="cmd_0101"
                  name="cmd_0101"
                  formControlName="cmd_0101"
                  type="text"
                  data-id="MLP 3 city"
                />
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                <div class="mb-3 selectDrop dropDrownSet suffix">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select states"
                    bindValue="id"
                    formControlName="cmd_0102"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                <input
                  style="width: 100%"
                  class="form-control"
                  id="cmd_0103"
                  name="cmd_0103"
                  formControlName="cmd_0103"
                  type="text"
                  data-id="MLP 3 zip"
                />
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Effective Date (mm/yy):
                </label>
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_0104"
                    name="cmd_0104"
                    formControlName="cmd_0104"
                    type="text"
                    data-id="MLP 3 eff"
                    ngbDatepicker
                    #ga72="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_0104')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga72.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_0104').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0104').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0104').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0104').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0104').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Retroactive Date, if applicable (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_0105"
                    name="cmd_0105"
                    formControlName="cmd_0105"
                    type="text"
                    data-id="MLP 3 retro"
                    ngbDatepicker
                    #ga73="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_0105')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga73.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_0105').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0105').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0105').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0105').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0105').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
            <td colspan="6" width="33.33%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Expiration Date (mm/yy):</label
                >
                <div class="input-group">
                  <input
                    class="form-control  datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_0106"
                    name="cmd_0106"
                    formControlName="cmd_0106"
                    type="text"
                    data-id="MLP 3 exp"
                    ngbDatepicker
                    #ga74="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_0106')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga74.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_0106').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0106').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0106').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0106').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0106').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Professional Insurance History: Please answer each of the
                  following questions in full. If the answer to any question is
                  “YES”, or requires further information, please give a full
                  explanation of the specific details on an Explanation Form and
                  attach to the Application.
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">1. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Has your professional liability insurance coverage ever been
                  terminated or not renewed by action of the insurance
                  company?</label
                >
                <br />
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0107'].touched &&
                        gaForm.controls['cmd_0107'].errors) ||
                      (gaForm.controls['cmd_0107'].errors && isSubmit)
                    "
                    id="cmd_0107"
                    name="cmd_0107"
                    formControlName="cmd_0107"
                    value="1"

                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0107'].touched &&
                    gaForm.controls['cmd_0107'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0107'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0107'].touched &&
                        gaForm.controls['cmd_0107'].errors) ||
                      (gaForm.controls['cmd_0107'].errors && isSubmit)
                    "
                    id="cmd_0107"
                    name="cmd_0107"
                    formControlName="cmd_0107"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0107'].touched &&
                    gaForm.controls['cmd_0107'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0107'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0107'].touched &&
                      gaForm.controls['cmd_0107'].errors) ||
                    (gaForm.controls['cmd_0107'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>

                <label class="normalParagraphStyle"
                  >If yes, please provide date, name of company(s), and basis
                  for termination or non-renewal.</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">2. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been denied coverage?</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_0108"
                    name="cmd_0108"
                    formControlName="cmd_0108"
                    value="1"
                  />Yes</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    id="cmd_0108"
                    name="cmd_0108"
                    formControlName="cmd_0108"
                    value="0"

                  />No</label
                >
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0108'].touched &&
                      gaForm.controls['cmd_0108'].errors) ||
                    (gaForm.controls['cmd_0108'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>

                <label class="normalParagraphStyle"
                  >If yes, please provide details.</label
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">3. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Has your present professional liability insurance carrier
                  excluded any specific procedures from your insurance
                  coverage?</label
                >
                <br />
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0109'].touched &&
                        gaForm.controls['cmd_0109'].errors) ||
                      (gaForm.controls['cmd_0109'].errors && isSubmit)
                    "
                    id="cmd_0109"
                    formControlName="cmd_0109"
                    name="cmd_0109"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0109'].touched &&
                    gaForm.controls['cmd_0109'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0109'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0109'].touched &&
                        gaForm.controls['cmd_0109'].errors) ||
                      (gaForm.controls['cmd_0109'].errors && isSubmit)
                    "
                    id="cmd_0109"
                    formControlName="cmd_0109"
                    name="cmd_0109"
                    value="0"

                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0109'].touched &&
                    gaForm.controls['cmd_0109'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0109'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0109'].touched &&
                      gaForm.controls['cmd_0109'].errors) ||
                    (gaForm.controls['cmd_0109'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>

                <label class="normalParagraphStyle"
                  >If yes, please identify procedures and provide
                  details.</label
                >
              </div>
            </td>
          </tr>
        </table>

        <table border="1">
          <tr>
            <td colspan="18">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Professional Claims History: (If the answer to any of these
                  questions is “Yes,” please complete a separate Professional
                  Liability Claims Information Form for each. A Professional
                  Liability Claims Information Form has been provided as
                  Schedule B to this Application. Please make additional copies
                  as necessary.)
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">1. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have there ever been any professional liability (i.e.
                  malpractice) claims, suits, judgments, settlements or
                  arbitration proceedings involving you?
                </label>

                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0110'].touched &&
                        gaForm.controls['cmd_0110'].errors) ||
                      (gaForm.controls['cmd_0110'].errors && isSubmit)
                    "
                    id="cmd_0110"
                    formControlName="cmd_0110"
                    name="cmd_0110"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0110'].touched &&
                    gaForm.controls['cmd_0110'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0110'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0110'].touched &&
                        gaForm.controls['cmd_0110'].errors) ||
                      (gaForm.controls['cmd_0110'].errors && isSubmit)
                    "
                    id="cmd_0110"
                    formControlName="cmd_0110"
                    name="cmd_0110"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0110'].touched &&
                    gaForm.controls['cmd_0110'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0110'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0110'].touched &&
                      gaForm.controls['cmd_0110'].invalid) ||
                    (gaForm.controls['cmd_0110'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">2. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Are any professional liability (i.e. malpractice) claims,
                  suits, judgments, settlements or arbitration proceedings
                  involving you currently pending?
                </label>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0111'].touched &&
                        gaForm.controls['cmd_0111'].errors) ||
                      (gaForm.controls['cmd_0111'].errors && isSubmit)
                    "
                    id="cmd_0111"
                    name="cmd_0111"
                    formControlName="cmd_0111"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0111'].touched &&
                    gaForm.controls['cmd_0111'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0111'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0111'].touched &&
                        gaForm.controls['cmd_0111'].errors) ||
                      (gaForm.controls['cmd_0111'].errors && isSubmit)
                    "
                    id="cmd_0111"
                    name="cmd_0111"
                    formControlName="cmd_0111"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0111'].touched &&
                    gaForm.controls['cmd_0111'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0111'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0111'].touched &&
                      gaForm.controls['cmd_0111'].errors) ||
                    (gaForm.controls['cmd_0111'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">3. </label>
              </div>
            </td>
            <td colspan="6" width="90%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Are you aware of any formal demand for payment or similar
                  claim submitted to your insurer that did not result in a
                  lawsuit or other proceeding alleging professional
                  liability?</label
                >
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0112'].touched &&
                        gaForm.controls['cmd_0112'].errors) ||
                      (gaForm.controls['cmd_0112'].errors && isSubmit)
                    "
                    id="cmd_0112"
                    name="cmd_0112"
                    formControlName="cmd_0112"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0112'].touched &&
                    gaForm.controls['cmd_0112'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0112'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0112'].touched &&
                        gaForm.controls['cmd_0112'].errors) ||
                      (gaForm.controls['cmd_0112'].errors && isSubmit)
                    "
                    id="cmd_0112"
                    name="cmd_0112"
                    formControlName="cmd_0112"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0112'].touched &&
                    gaForm.controls['cmd_0112'].errors
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0112'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0112'].touched &&
                      gaForm.controls['cmd_0112'].errors) ||
                    (gaForm.controls['cmd_0112'].errors && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
        </table>

        <div class="centered inputBorderBox">
          <h4 class="boardCertification">X. HEALTH STATUS</h4>
        </div>
        <table border="1" width="100%">
          <tr>
            <td colspan="18" width="100%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Please answer each of the following questions in full.
                </label>
              </div>
            </td>
          </tr>
        </table>

        <table border="1" width="100%">
          <tr>
            <td colspan="2" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">1. </label>
              </div>
            </td>
            <td colspan="12" width="70%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Do you currently have any physical or mental condition(s)
                  that may affect your ability to practice or exercise the
                  clinical privileges or responsibilities typically associated
                  with the specialty and position for which you are submitting
                  this Application?<br />
                  <b
                    >If the answer to this question is “YES,” please give full
                    explanation of the specific details on an Explanation Form
                    and attach to the Application.
                  </b>
                  <br />(Note: Physical or mental condition(s) include, but are
                  not limited to, current alcohol or drug dependency, current
                  participation in aftercare programs for alcohol or drug
                  dependency, medical limitation of activity, workload, etc.,
                  and prescribed medications that may affect your clinical
                  judgment or motor skills.)</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0113'].touched &&
                        gaForm.controls['cmd_0113'].invalid) ||
                      (gaForm.controls['cmd_0113'].invalid && isSubmit)
                    "
                    id="cmd_0113"
                    name="cmd_0113"
                    formControlName="cmd_0113"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0113'].touched &&
                    gaForm.controls['cmd_0113'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0113'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0113'].touched &&
                        gaForm.controls['cmd_0113'].invalid) ||
                      (gaForm.controls['cmd_0113'].invalid && isSubmit)
                    "
                    id="cmd_0113"
                    name="cmd_0113"
                    formControlName="cmd_0113"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0113'].touched &&
                    gaForm.controls['cmd_0113'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0113'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0113'].touched &&
                      gaForm.controls['cmd_0113'].invalid) ||
                    (gaForm.controls['cmd_0113'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="10%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">2. </label>
              </div>
            </td>
            <td colspan="12" width="70%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Are you able to perform all the essential functions of the
                  position for which you are applying, safely and according to
                  accepted standards of performance, with or without reasonable
                  accommodation? If reasonable accommodation is required, please
                  specify such on an attached Explanation Form.</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0114'].touched &&
                        gaForm.controls['cmd_0114'].invalid) ||
                      (gaForm.controls['cmd_0114'].invalid && isSubmit)
                    "
                    id="cmd_0114"
                    name="cmd_0114"
                    formControlName="cmd_0114"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0114'].touched &&
                    gaForm.controls['cmd_0114'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0114'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0114'].touched &&
                        gaForm.controls['cmd_0114'].invalid) ||
                      (gaForm.controls['cmd_0114'].invalid && isSubmit)
                    "
                    id="cmd_0114"
                    name="cmd_0114"
                    formControlName="cmd_0114"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0114'].touched &&
                    gaForm.controls['cmd_0114'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0114'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0114'].touched &&
                      gaForm.controls['cmd_0114'].invalid) ||
                    (gaForm.controls['cmd_0114'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
        </table>

        <div class="centered inputBorderBox">
          <h4 class="boardCertification">XI. ATTESTATION QUESTIONS</h4>
          <label class="normalParagraphStyle">
            This section to be completed by the Practitioner. Modification to
            the wording or format of these Attestation Questions will invalidate
            the Application.
          </label>
        </div>
        <table border="1" width="100%">
          <tr>
            <td colspan="18" width="100%">
              <label class="boldParagraphStyle"
                >Please answer the following questions “yes” or “no”. If your
                answer to any of the following questions is “yes”, please
                provide details and reasons, as specified in each question, on
                an Explanation Form and attach to the Application.</label
              >
              <br />
              <label class="boldParagraphStyle"
                >For the purpose of the following questions, the term “adverse
                action” means a voluntary or involuntary termination, loss of,
                reduction, withdrawal, limitation, restriction, suspension,
                revocation, denial, or non-renewal of membership, clinical
                privileges, academic affiliation or appointment, or employment.
                “Adverse action” also means, with respect to professional
                licensure registration or certification, any previously
                successful or currently pending challenges to such licensure,
                registration or certification including any voluntary or
                involuntary restriction, suspension, revocation, denial,
                surrender, non-renewal, public or private reprimand, probation,
                consent order, reduction, withdrawal, limitation,
                relinquishment, or failure to proceed with an application for
                such licensure, registration or certification</label
              >
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">A.</label>
              </div>
            </td>
            <td colspan="16">
              <table border="1" width="100%">
                <tr>
                  <td colspan="16" width="100%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >To your knowledge, have you ever been the subject of an
                        investigation or adverse action (or is an investigation
                        or adverse action currently pending) by:
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12" width="79%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >1. a hospital or other healthcare facility (e.g.
                        surgical center, nursing home, renal dialysis facility,
                        etc.)?</label
                      >
                    </div>
                  </td>
                  <td colspan="4" width="21%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0115'].touched &&
                              gaForm.controls['cmd_0115'].invalid) ||
                            (gaForm.controls['cmd_0115'].invalid && isSubmit)
                          "
                          id="cmd_0115"
                          name="cmd_0115"
                          formControlName="cmd_0115"
                          value="1"
                        />Yes</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0115'].touched &&
                          gaForm.controls['cmd_0115'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0115'].errors"
                        >
                          required
                        </small>
                      </div>
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0115'].touched &&
                              gaForm.controls['cmd_0115'].invalid) ||
                            (gaForm.controls['cmd_0115'].invalid && isSubmit)
                          "
                          id="cmd_0115"
                          name="cmd_0115"
                          formControlName="cmd_0115"
                          value="0"
                        />No</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0115'].touched &&
                          gaForm.controls['cmd_0115'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0115'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaForm.controls['cmd_0115'].touched &&
                            gaForm.controls['cmd_0115'].invalid) ||
                          (gaForm.controls['cmd_0115'].invalid && isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12" width="79%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >2. an education facility or program (medical school,
                        residency, internship, etc.)?
                      </label>
                    </div>
                  </td>
                  <td colspan="4" width="21%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0116'].touched &&
                              gaForm.controls['cmd_0116'].invalid) ||
                            (gaForm.controls['cmd_0116'].invalid && isSubmit)
                          "
                          id="cmd_0116"
                          name="cmd_0116"
                          formControlName="cmd_0116"
                          value="1"
                        />Yes</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0116'].touched &&
                          gaForm.controls['cmd_0116'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0116'].errors"
                        >
                          required
                        </small>
                      </div>
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0116'].touched &&
                              gaForm.controls['cmd_0116'].invalid) ||
                            (gaForm.controls['cmd_0116'].invalid && isSubmit)
                          "
                          id="cmd_0116"
                          name="cmd_0116"
                          formControlName="cmd_0116"
                          value="0"
                        />No</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0116'].touched &&
                          gaForm.controls['cmd_0116'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0116'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaForm.controls['cmd_0116'].touched &&
                            gaForm.controls['cmd_0116'].invalid) ||
                          (gaForm.controls['cmd_0116'].invalid && isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12" width="79%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >3. a professional organization or society?
                      </label>
                    </div>
                  </td>
                  <td colspan="4" width="21%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0117'].touched &&
                              gaForm.controls['cmd_0117'].invalid) ||
                            (gaForm.controls['cmd_0117'].invalid && isSubmit)
                          "
                          id="cmd_0117"
                          formControlName="cmd_0117"
                          name="cmd_0117"
                          value="1"
                        />Yes</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0117'].touched &&
                          gaForm.controls['cmd_0117'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0117'].errors"
                        >
                          required
                        </small>
                      </div>
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0117'].touched &&
                              gaForm.controls['cmd_0117'].invalid) ||
                            (gaForm.controls['cmd_0117'].invalid && isSubmit)
                          "
                          id="cmd_0117"
                          formControlName="cmd_0117"
                          name="cmd_0117"
                          value="0"
                        />No</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0117'].touched &&
                          gaForm.controls['cmd_0117'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0117'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaForm.controls['cmd_0117'].touched &&
                            gaForm.controls['cmd_0117'].invalid) ||
                          (gaForm.controls['cmd_0117'].invalid && isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12" width="79%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >4. a professional licensing body (in any jurisdiction
                        for any profession)?
                      </label>
                    </div>
                  </td>
                  <td colspan="4" width="21%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0118'].touched &&
                              gaForm.controls['cmd_0118'].invalid) ||
                            (gaForm.controls['cmd_0118'].invalid && isSubmit)
                          "
                          id="cmd_0118"
                          formControlName="cmd_0118"
                          name="cmd_0118"
                          value="1"
                        />Yes</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0118'].touched &&
                          gaForm.controls['cmd_0118'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0118'].errors"
                        >
                          required
                        </small>
                      </div>
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0118'].touched &&
                              gaForm.controls['cmd_0118'].invalid) ||
                            (gaForm.controls['cmd_0118'].invalid && isSubmit)
                          "
                          id="cmd_0118"
                          formControlName="cmd_0118"
                          name="cmd_0118"
                          value="0"
                        />No</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0118'].touched &&
                          gaForm.controls['cmd_0118'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0118'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaForm.controls['cmd_0118'].touched &&
                            gaForm.controls['cmd_0118'].invalid) ||
                          (gaForm.controls['cmd_0118'].invalid && isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="12" width="79%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >5. a state or federal agency (DEA, etc.) regarding your
                        prescription of controlled substances?
                      </label>
                    </div>
                  </td>
                  <td colspan="4" width="21%">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0119'].touched &&
                              gaForm.controls['cmd_0119'].invalid) ||
                            (gaForm.controls['cmd_0119'].invalid && isSubmit)
                          "
                          id="cmd_0119"
                          name="cmd_0119"
                          formControlName="cmd_0119"
                          value="1"
                        />Yes</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0119'].touched &&
                          gaForm.controls['cmd_0119'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0119'].errors"
                        >
                          required
                        </small>
                      </div>
                      <label class="checkbox-inline radioStyle">
                        <input
                          type="radio"
                          [class.is-invalid]="
                            (gaForm.controls['cmd_0119'].touched &&
                              gaForm.controls['cmd_0119'].invalid) ||
                            (gaForm.controls['cmd_0119'].invalid && isSubmit)
                          "
                          id="cmd_0119"
                          name="cmd_0119"
                          formControlName="cmd_0119"
                          value="0"
                        />No</label
                      >
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaForm.controls['cmd_0119'].touched &&
                          gaForm.controls['cmd_0119'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaForm.controls['cmd_0119'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaForm.controls['cmd_0119'].touched &&
                            gaForm.controls['cmd_0119'].invalid) ||
                          (gaForm.controls['cmd_0119'].invalid && isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">B.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >To your knowledge, have you ever been the subject of any
                  report(s) to a state or federal data bank or state licensing
                  or disciplining entity?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0120'].touched &&
                        gaForm.controls['cmd_0120'].invalid) ||
                      (gaForm.controls['cmd_0120'].invalid && isSubmit)
                    "
                    id="cmd_0120"
                    name="cmd_0120"
                    formControlName="cmd_0120"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0120'].touched &&
                    gaForm.controls['cmd_0120'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0120'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0120'].touched &&
                        gaForm.controls['cmd_0120'].invalid) ||
                      (gaForm.controls['cmd_0120'].invalid && isSubmit)
                    "
                    id="cmd_0120"
                    name="cmd_0120"
                    formControlName="cmd_0120"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0120'].touched &&
                    gaForm.controls['cmd_0120'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0120'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0120'].touched &&
                      gaForm.controls['cmd_0120'].invalid) ||
                    (gaForm.controls['cmd_0120'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">C.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Has your application for clinical privileges or medical staff
                  membership or change in staff category at any hospital or
                  healthcare facility ever been denied in whole or in part or is
                  any such action pending?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0121'].touched &&
                        gaForm.controls['cmd_0121'].invalid) ||
                      (gaForm.controls['cmd_0121'].invalid && isSubmit)
                    "
                    id="cmd_0121"
                    name="cmd_0121"
                    formControlName="cmd_0121"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0121'].touched &&
                    gaForm.controls['cmd_0121'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0121'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0121'].touched &&
                        gaForm.controls['cmd_0121'].invalid) ||
                      (gaForm.controls['cmd_0121'].invalid && isSubmit)
                    "
                    id="cmd_0121"
                    name="cmd_0121"
                    formControlName="cmd_0121"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0121'].touched &&
                    gaForm.controls['cmd_0121'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0121'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0121'].touched &&
                      gaForm.controls['cmd_0121'].invalid) ||
                    (gaForm.controls['cmd_0121'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">D.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever resigned from a hospital or other health care
                  facility medical staff to avoid disciplinary action,
                  investigation or while under investigation or is such an
                  investigation pending?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0122'].touched &&
                        gaForm.controls['cmd_0122'].invalid) ||
                      (gaForm.controls['cmd_0122'].invalid && isSubmit)
                    "
                    id="cmd_0122"
                    name="cmd_0122"
                    formControlName="cmd_0122"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0122'].touched &&
                    gaForm.controls['cmd_0122'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0122'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0122'].touched &&
                        gaForm.controls['cmd_0122'].invalid) ||
                      (gaForm.controls['cmd_0122'].invalid && isSubmit)
                    "
                    id="cmd_0122"
                    name="cmd_0122"
                    formControlName="cmd_0122"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0122'].touched &&
                    gaForm.controls['cmd_0122'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0122'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0122'].touched &&
                      gaForm.controls['cmd_0122'].invalid) ||
                    (gaForm.controls['cmd_0122'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">E.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been suspended, fined, disciplined, sanctioned
                  or otherwise restricted or excluded from participating in any
                  federal or state health insurance program (for example,
                  Medicare or Medicaid)?.</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0123'].touched &&
                        gaForm.controls['cmd_0123'].invalid) ||
                      (gaForm.controls['cmd_0123'].invalid && isSubmit)
                    "
                    id="cmd_0123"
                    name="cmd_0123"
                    formControlName="cmd_0123"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0123'].touched &&
                    gaForm.controls['cmd_0123'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0123'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0123'].touched &&
                        gaForm.controls['cmd_0123'].invalid) ||
                      (gaForm.controls['cmd_0123'].invalid && isSubmit)
                    "
                    id="cmd_0123"
                    name="cmd_0123"
                    formControlName="cmd_0123"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0123'].touched &&
                    gaForm.controls['cmd_0123'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0123'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0123'].touched &&
                      gaForm.controls['cmd_0123'].invalid) ||
                    (gaForm.controls['cmd_0123'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">F.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been suspended, fined, disciplined, sanctioned
                  or otherwise restricted or excluded from participating in any
                  private health insurance program?
                </label>
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0124'].touched &&
                        gaForm.controls['cmd_0124'].invalid) ||
                      (gaForm.controls['cmd_0124'].invalid && isSubmit)
                    "
                    id="cmd_0124"
                    name="cmd_0124"
                    formControlName="cmd_0124"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0124'].touched &&
                    gaForm.controls['cmd_0124'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0124'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0124'].touched &&
                        gaForm.controls['cmd_0124'].invalid) ||
                      (gaForm.controls['cmd_0124'].invalid && isSubmit)
                    "
                    id="cmd_0124"
                    name="cmd_0124"
                    formControlName="cmd_0124"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0124'].touched &&
                    gaForm.controls['cmd_0124'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0124'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0124'].touched &&
                      gaForm.controls['cmd_0124'].invalid) ||
                    (gaForm.controls['cmd_0124'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">G.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Has any professional review organization under contract with
                  Medicare or Medicaid ever made an adverse quality
                  determination concerning your treatment rendered to any
                  patient?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0125'].touched &&
                        gaForm.controls['cmd_0125'].invalid) ||
                      (gaForm.controls['cmd_0125'].invalid && isSubmit)
                    "
                    id="cmd_0125"
                    name="cmd_0125"
                    formControlName="cmd_0125"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0125'].touched &&
                    gaForm.controls['cmd_0125'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0125'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0125'].touched &&
                        gaForm.controls['cmd_0125'].invalid) ||
                      (gaForm.controls['cmd_0125'].invalid && isSubmit)
                    "
                    id="cmd_0125"
                    name="cmd_0125"
                    formControlName="cmd_0125"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0125'].touched &&
                    gaForm.controls['cmd_0125'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0125'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0125'].touched &&
                      gaForm.controls['cmd_0125'].invalid) ||
                    (gaForm.controls['cmd_0125'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">H.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been convicted of or entered a plea for any
                  criminal offense (excluding parking tickets)?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0126'].touched &&
                        gaForm.controls['cmd_0126'].invalid) ||
                      (gaForm.controls['cmd_0126'].invalid && isSubmit)
                    "
                    id="cmd_0126"
                    formControlName="cmd_0126"
                    name="cmd_0126"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0126'].touched &&
                    gaForm.controls['cmd_0126'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0126'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0126'].touched &&
                        gaForm.controls['cmd_0126'].invalid) ||
                      (gaForm.controls['cmd_0126'].invalid && isSubmit)
                    "
                    id="cmd_0126"
                    formControlName="cmd_0126"
                    name="cmd_0126"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0126'].touched &&
                    gaForm.controls['cmd_0126'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0126'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0126'].touched &&
                      gaForm.controls['cmd_0126'].invalid) ||
                    (gaForm.controls['cmd_0126'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">I.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Are any criminal charges currently pending against
                  you?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0127'].touched &&
                        gaForm.controls['cmd_0127'].invalid) ||
                      (gaForm.controls['cmd_0127'].invalid && isSubmit)
                    "
                    id="cmd_0127"
                    formControlName="cmd_0127"
                    name="cmd_0127"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0127'].touched &&
                    gaForm.controls['cmd_0127'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0127'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0127'].touched &&
                        gaForm.controls['cmd_0127'].invalid) ||
                      (gaForm.controls['cmd_0127'].invalid && isSubmit)
                    "
                    id="cmd_0127"
                    formControlName="cmd_0127"
                    name="cmd_0127"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0127'].touched &&
                    gaForm.controls['cmd_0127'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0127'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0127'].touched &&
                      gaForm.controls['cmd_0127'].invalid) ||
                    (gaForm.controls['cmd_0127'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">J.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been arrested for or charged with a crime
                  involving children?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0128'].touched &&
                        gaForm.controls['cmd_0128'].invalid) ||
                      (gaForm.controls['cmd_0128'].invalid && isSubmit)
                    "
                    id="cmd_0128"
                    formControlName="cmd_0128"
                    name="cmd_0128"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0128'].touched &&
                    gaForm.controls['cmd_0128'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0128'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0128'].touched &&
                        gaForm.controls['cmd_0128'].invalid) ||
                      (gaForm.controls['cmd_0128'].invalid && isSubmit)
                    "
                    id="cmd_0128"
                    formControlName="cmd_0128"
                    name="cmd_0128"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0128'].touched &&
                    gaForm.controls['cmd_0128'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0128'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0128'].touched &&
                      gaForm.controls['cmd_0128'].invalid) ||
                    (gaForm.controls['cmd_0128'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">K.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been arrested for or charged with a sexual
                  offense?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0129'].touched &&
                        gaForm.controls['cmd_0129'].invalid) ||
                      (gaForm.controls['cmd_0129'].invalid && isSubmit)
                    "
                    id="cmd_0129"
                    name="cmd_0129"
                    formControlName="cmd_0129"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0129'].touched &&
                    gaForm.controls['cmd_0129'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0129'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0129'].touched &&
                        gaForm.controls['cmd_0129'].invalid) ||
                      (gaForm.controls['cmd_0129'].invalid && isSubmit)
                    "
                    id="cmd_0129"
                    name="cmd_0129"
                    formControlName="cmd_0129"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0129'].touched &&
                    gaForm.controls['cmd_0129'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0129'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0129'].touched &&
                      gaForm.controls['cmd_0129'].invalid) ||
                    (gaForm.controls['cmd_0129'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">L.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Have you ever been arrested for or charged with a crime
                  involving moral turpitude?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0130'].touched &&
                        gaForm.controls['cmd_0130'].invalid) ||
                      (gaForm.controls['cmd_0130'].invalid && isSubmit)
                    "
                    id="cmd_0130"
                    formControlName="cmd_0130"
                    name="cmd_0130"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0130'].touched &&
                    gaForm.controls['cmd_0130'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0130'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0130'].touched &&
                        gaForm.controls['cmd_0130'].invalid) ||
                      (gaForm.controls['cmd_0130'].invalid && isSubmit)
                    "
                    id="cmd_0130"
                    formControlName="cmd_0130"
                    name="cmd_0130"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0130'].touched &&
                    gaForm.controls['cmd_0130'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0130'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0130'].touched &&
                      gaForm.controls['cmd_0130'].invalid) ||
                    (gaForm.controls['cmd_0130'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="5%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">M.</label>
              </div>
            </td>
            <td colspan="12" width="75%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle"
                  >Are you currently using illegal drugs or legal drugs in an
                  illegal manner?</label
                >
              </div>
            </td>
            <td colspan="4" width="20%">
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0131'].touched &&
                        gaForm.controls['cmd_0131'].invalid) ||
                      (gaForm.controls['cmd_0131'].invalid && isSubmit)
                    "
                    id="cmd_0131"
                    formControlName="cmd_0131"
                    name="cmd_0131"
                    value="1"
                  />Yes</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0131'].touched &&
                    gaForm.controls['cmd_0131'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0131'].errors"
                  >
                    required
                  </small>
                </div>
                <label class="checkbox-inline radioStyle">
                  <input
                    type="radio"
                    [class.is-invalid]="
                      (gaForm.controls['cmd_0131'].touched &&
                        gaForm.controls['cmd_0131'].invalid) ||
                      (gaForm.controls['cmd_0131'].invalid && isSubmit)
                    "
                    id="cmd_0131"
                    formControlName="cmd_0131"
                    name="cmd_0131"
                    value="0"
                  />No</label
                >
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaForm.controls['cmd_0131'].touched &&
                    gaForm.controls['cmd_0131'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaForm.controls['cmd_0131'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaForm.controls['cmd_0131'].touched &&
                      gaForm.controls['cmd_0131'].invalid) ||
                    (gaForm.controls['cmd_0131'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </div>
            </td>
          </tr>
        </table>

        <div class="centered inputBorderBox">
          <h4 class="boardCertification">XII. ATTESTATION AND SIGNATURE</h4>
          <label class="normalParagraphStyle">
            By signing this Application, I certify, agree, understand and
            acknowledge the following:
          </label>
          <ul class="list-styled">
            <li>
              The information in this entire Application, including all subparts
              and attachments, is complete, current, correct, and not misleading
            </li>
            <li>
              Any misstatements or omissions (whether intentional or
              unintentional) on this Application may constitute cause for denial
              of my Application or summary dismissal or termination of my
              clinical privileges, membership or practitioner participation
              agreement
            </li>
            <li>
              A photocopy of this Application, including this attestation, the
              authorization and release of information form and any or all
              attachments has the same force and effect as the original.
            </li>
            <li>
              I have reviewed the information in this Application on the most
              recent date indicated below and it continues to be true and
              complete.
            </li>
            <li>
              While this Application is being processed, I agree to update the
              information originally provided in this Application should there
              be any change in the information
            </li>
            <li>
              No action will be taken on this Application until it is complete
              and all outstanding questions with respect to the Application have
              been resolved.
            </li>
            <li>
              This attestation statement and Application must be signed no more
              than 180 days prior to the credentialing decision date.
            </li>
          </ul>
        </div>
        <table border="1" width="100%">
          <tr>
            <td>
              <label class="normalParagraphStyle">Signature:</label>
            </td>
            <td>
              <!-- <img
                *ngIf="!showOne"
                class="img"
                src="./assets/images/e_sign.png"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <img
                *ngIf="showOne"
                class="img eSignImageShow"
                [src]="esignOne"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <input
                type="hidden"
                name="cmd_0991"
                id="cmd_0991"
                formControlName="cmd_0991"
                value="{{ finalImgPath }}"
              /> -->
              <img
                *ngIf="!showOne"
                class="img"
                src="./assets/images/e_sign.png"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <img
                *ngIf="showOne"
                class="img eSignImageShow"
                [src]="esignOne"
                alt="Credential my Doc"
                (click)="esignOpenOne()"
              />
              <input
                type="hidden"
                name="cmd_0991"
                id="cmd_0991"
                formControlName="cmd_0991"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Printed Name:</label>
                <input
                  class="form-control"
                  id="cmd_0132"
                  name="cmd_0132"
                  formControlName="cmd_0132"
                  type="text"
                  data-id="PrintedName"
                />
              </div>
            </td>
            <td>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Date:</label>
                <div class="input-group">
                  <input
                    class="form-controldatepicker"
                    id="cmd_0200"
                    formControlName="cmd_0200"
                    name="cmd_0200"
                    type="text"
                    ngbDatepicker
                    #ga105="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_0200')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga105.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_0200').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0200').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0200').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0200').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0200').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div class="mb-3">
          <div class="text-center">
            <h2>
              GEORGIA UNIFORM HEALTHCARE PRACTITIONER <br />
              CREDENTIALING APPLICATIONFORM
            </h2>
          </div>
          <br />
          <br />
          <div class="centered inputBorderBox">
            <h3 class="inputBoxStylesFour">EXPLANATION FORM</h3>
          </div>
          <table border="1">
            <tr>
              <td colspan="18">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label
                    style="font-weight: bold;font-size: 14px; padding: 20px"
                    >Please make as many copies of this page as needed to fully
                    respond to each question. For each response/explanation,
                    please provide your name and Social Security Number,
                    together with the corresponding page and section number from
                    the Application.</label
                  >
                </div>
              </td>
            </tr>
          </table>

          <br />
          <br />
          <table border="1" width="100%">
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">NAME:</label>
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0133"
                    name="cmd_0133"
                    formControlName="cmd_0133"
                    type="text"
                    data-id="PrintedName"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">SSN#:</label>
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0198"
                    name="cmd_0198"
                    formControlName="cmd_0198"
                    type="text"
                    data-id="ssn"
                  />
                </div>
              </td>
            </tr>
          </table>

          <br />
          <br />
          <table border="1" width="100%">
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Section #:</label>
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Page #:</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0134"
                    formControlName="cmd_0134"
                    name="cmd_0134"
                    type="text"
                    data-id="Explain 1"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0135"
                    formControlName="cmd_0135"
                    name="cmd_0135"
                    type="text"
                    data-id="Page 1"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0136"
                    formControlName="cmd_0136"
                    name="cmd_0136"
                    type="text"
                    data-id="Explain 2"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0137"
                    formControlName="cmd_0137"
                    name="cmd_0137"
                    type="text"
                    data-id="Page 2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0138"
                    formControlName="cmd_0138"
                    name="cmd_0138"
                    type="text"
                    data-id="Explain 3"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0139"
                    formControlName="cmd_0139"
                    name="cmd_0139"
                    type="text"
                    data-id="Page 3"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0140"
                    formControlName="cmd_0140"
                    name="cmd_0140"
                    type="text"
                    data-id="Explain 4"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0141"
                    formControlName="cmd_0141"
                    name="cmd_0141"
                    type="text"
                    data-id="Page 4"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0142"
                    name="cmd_0142"
                    formControlName="cmd_0142"
                    type="text"
                    data-id="Explain 5"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0143"
                    name="cmd_0143"
                    formControlName="cmd_0143"
                    type="text"
                    data-id="Page 5"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0144"
                    name="cmd_0144"
                    formControlName="cmd_0144"
                    type="text"
                    data-id="Explain 6"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0145"
                    name="cmd_0145"
                    formControlName="cmd_0145"
                    type="text"
                    data-id="Page 6"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0146"
                    name="cmd_0146"
                    formControlName="cmd_0146"
                    type="text"
                    data-id="Explain 7"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0147"
                    name="cmd_0147"
                    formControlName="cmd_0147"
                    type="text"
                    data-id="Page 7"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0148"
                    name="cmd_0148"
                    formControlName="cmd_0148"
                    type="text"
                    data-id="Explain 8"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0149"
                    name="cmd_0149"
                    formControlName="cmd_0149"
                    type="text"
                    data-id="Page 8"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0150"
                    name="cmd_0150"
                    formControlName="cmd_0150"
                    type="text"
                    data-id="Explain 8"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0151"
                    name="cmd_0151"
                    formControlName="cmd_0151"
                    type="text"
                    data-id="Page 8"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0152"
                    name="cmd_0152"
                    formControlName="cmd_0152"
                    type="text"
                    data-id="Explain 9"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0153"
                    name="cmd_0153"
                    formControlName="cmd_0153"
                    type="text"
                    data-id="Page 9"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="16">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0154"
                    name="cmd_0154"
                    formControlName="cmd_0154"
                    type="text"
                    data-id="Explain 10"
                  />
                </div>
              </td>
              <td colspan="2">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 70%"
                    class="form-control"
                    id="cmd_0155"
                    name="cmd_0155"
                    formControlName="cmd_0155"
                    type="text"
                    data-id="Page 10"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="mb-3">
          <div class="text-center">
            <h2>Schedule A</h2>
            <br />
            <h4>
              GEORGIA UNIFORM HEALTHCARE PRACTITIONER CREDENTIALING
              APPLICATIONFORM
            </h4>
            <h3>
              <u>AUTHORIZATION AND RELEASE OF INFORMATION FORM</u>
            </h3>
            <br />
            <h4>
              <u>Modified Releases Will Not Be Accepted </u>
            </h4>
          </div>
          <br />
          <br />
          <p class="boldParagraphStyle">
            By submitting this Application, including all subparts and
            attachments, I acknowledge, understand, consent and agree to the
            following:
          </p>
          <ul class="list-styled" id="content">
            <li>
              As an applicant for medical staff membership at the designated
              hospital(s) and/or participation status with the health care
              related organization(s) [e.g. hospital,medical staff, medical
              group, independent practice association (IPA), healthplan, health
              maintenance organization (HMO), preferred provider
              organization(PPO), physician hospital organization (PHO), managed
              care organization, network,medical society, professional
              association, medical school faculty position, or other healthcare
              delivery entity or system (hereinafter referred to as a
              “Healthcare Entity”) indicated on this Application, I have the
              burden of producing adequateinformation for proper evaluation of
              this Application.
            </li>
            <li>
              I also understand that I have the continuing responsibility to
              resolve any questions, concerns or doubts regarding any and all
              information in this Application. If I fail to produce this
              information, then I understand that the Healthcare Entity will not
              be required to evaluate or act upon this Application. I also agree
              to provide updated information as may be required or requested by
              the Healthcare Entity or its authorized representatives or
              designated agents.
            </li>
            <li>
              The Healthcare Entity and its authorized representatives or
              designated agents will investigate the information in this
              Application. I consent and agree to such investigation and to the
              disciplinary reporting and information exchange activities of the
              Healthcare Entity as a part of the verification and credentialing
              process.
            </li>
            <li>
              I specifically authorize the Healthcare Entity and its authorized
              representatives and designated agents to obtain and act upon
              information regarding my competence, qualifications, education,
              training, professional and clinical ability, character, conduct,
              ethics, judgment, mental and physical health status, emotional
              stability, utilization practices, professional licensure or
              certification, and any other matter related to my qualifications
              or matters addressed in this Application (my “Qualifications”).
            </li>
            <li>
              I authorize all individuals, institutions, schools, programs,
              entities, facilities, hospitals, societies, associations,
              companies, agencies, licensing authorities, boards, plans,
              organizations, Healthcare Entities or others with which I have
              been associated as well as all professional liability insurers
              with which I have had or currently have professional liability
              insurance, who may have information bearing on my Qualifications
              to consult with the Healthcare Entity and its authorized
              representatives and designated agents and to report, release,
              exchange and share information and documents with the Healthcare
              Entity, for the purpose of evaluating this Application and my
              Qualifications.
            </li>
            <LI
              >I consent to and authorize the inspection of records and
              documents (including medical records and peer review information)
              that may be material to an evaluation of this Application and my
              Qualifications and my ability to carry out the clinical
              privileges/services/participation I have requested. I authorize
              each and every individual and organization with custody of such
              records and documents to permit such inspection and copying as may
              be necessary for the evaluation of this Application. I also agree
              to appear for interviews, if required or requested by the
              Healthcare Entity, in regard to this Application.</LI
            >
            <li>
              I further consent to and authorize the release by the Healthcare
              Entity to other Healthcare Entities and interested persons on
              request of information the Healthcare Entity may have concerning
              me (including but not limited to peer review information which is
              provided to another Healthcare Entity for peer review purposes),
              as long as in each instance such release of information is done in
              good faith and without malice. I hereby release from all liability
              the Healthcare Entity and its authorized representatives or
              designated agents from any claim for damages of whatever nature
              for any release of information made in good faith by the
              Healthcare Entity or its representatives or agents.
            </li>
            <li>
              I release from any liability, to the fullest extent permitted by
              law, all persons and entities (individuals and organizations) for
              their acts performed in a reasonable manner in conjunction with
              investigating and evaluating my Application and Qualifications,
              and I waive all legal claims of whatever nature against the
              Healthcare Entity and its representatives and designated agents
              acting in good faith and without malice in connection with the
              investigation of this Application and my Qualifications.
            </li>
            <li>
              For hospital or medical staff membership/clinical privileges, I
              acknowledge that I have been informed of, and hereby agree to
              abide by, the medical staff bylaws, rules, regulations and
              policies. I agree to conduct my practice in accordance with
              applicable laws and ethical principles of my profession. I also
              agree to provide for continuous care for my patients.
            </li>

            <li>
              Any investigations, actions or recommendations of any committee or
              the governing body of the Healthcare Entity with respect to the
              evaluation of this Application and any periodic reappraisals or
              evaluations will be undertaken as a medical review and/or peer
              review committee and in fulfillment of the Healthcare Entity’s
              obligations under Georgia law to conduct a review of professional
              practices in the facility, and are therefore entitled to any
              protections provided by law.
            </li>

            <li>
              I have read and understand this Authorization and Release of
              Information Form. A photocopy of this Authorization and Release of
              Information Form shall be as effective as the original and shall
              constitute my written authorization and request to communicate any
              relevant information and to release any and all supportive
              documentation regarding this Application. This Authorization and
              Release shall apply in connection with the evaluation and
              processing of this Application as well as in connection with any
              periodic reappraisals and evaluations undertaken. I agree to
              execute such additional releases as may be required from time to
              time in connection with such periodic reappraisals and
              evaluations.
            </li>
          </ul>
          <br />
          <table border="1" width="100%">
            <tr>
              <td>
                <label class="boldParagraphStyle">Signature:</label>
              </td>
              <td>
                <!-- <img
                  *ngIf="!showTwo"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <img
                  *ngIf="showTwo"
                  class="img eSignImageShow"
                  [src]="esignTwo"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <input
                  type="hidden"
                  name="cmd_0992"
                  id="cmd_0992"
                  formControlName="cmd_0992"
                  value="{{ finalImgPathTwo }}"
                /> -->
                <img
                  *ngIf="!showTwo"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <img
                  *ngIf="showTwo"
                  class="img eSignImageShow"
                  [src]="esignTwo"
                  alt="Credential my Doc"
                  (click)="esignOpenTwo()"
                />
                <input
                  type="hidden"
                  name="cmd_0992"
                  id="cmd_0992"
                  formControlName="cmd_0992"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Printed Name:</label>
                  <input
                    class="form-control onboard "
                    id="cmd_0156"
                    name="cmd_0156"
                    formControlName="cmd_0156"
                    type="text"
                    data-id="PrintedName"
                  />
                </div>
              </td>
              <td>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Date:</label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_0195"
                      name="cmd_0195"
                      formControlName="cmd_0195"
                      type="text"
                      ngbDatepicker
                      #ga75="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_0195')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga75.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('cmd_0195').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('cmd_0195').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('cmd_0195').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_0195').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_0195').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <p class="boldParagraphStyle">
            I grant permission for the release of the credentials information
            contained in this Application to the following Healthcare
            Entity(ies):
          </p>
          <div class="mb-3 no-margin inputPadding">
            <input
              style="width: 40%"
              class="form-control onboard "
              id="cmd_0157"
              name="cmd_0157"
              formControlName="cmd_0157"
              type="text"
              data-id="Entity 1"
            />
            <input
              style="width: 40%"
              class="form-control onboard "
              id="cmd_0158"
              name="cmd_0158"
              formControlName="cmd_0158"
              type="text"
              data-id="Entity 2"
            />
            <input
              style="width: 40%"
              class="form-control onboard "
              id="cmd_0159"
              name="cmd_0159"
              formControlName="cmd_0159"
              type="text"
              data-id="Entity 3"
            />
          </div>
        </div>
        <div class="mb-3">
          <div class="text-center">
            <h2>Schedule B</h2>
            <br />
            <h3>
              GEORGIA UNIFORM HEALTHCARE PRACTITIONER <br />
              CREDENTIALING APPLICATION FORM
            </h3>
            <h3>
              <u>AUTHORIZATION AND RELEASE OF INFORMATION FORM</u>
            </h3>
          </div>
          <br />
          <br />
          <p class="boldParagraphStyle">
            The following information is necessary to complete the credentialing
            verification process and will be kept confidential. Please print or
            type answers to the following for any malpractice claims reported to
            your malpractice insurance carrier, opened, closed, settled or paid.
            For initial credentialing, please complete a separate form for each
            claim; for recredentialing, complete forms only for new/changed
            status claims since your last recredentialing. One case per sheet
            (please photocopy if additional sheets are needed).
          </p>
          <table border="1" width="100%">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">PROVIDER’S NAME:</label>
                  <br />
                  <i>(Required even if N/A)</i>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_160"
                    formControlName="cmd_160"
                    name="cmd_160"
                    type="text"
                    data-id="PrintedName"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline">
                    <input
                      type="checkbox"
                      id="cmd_0161"
                      name="cmd_0161"
                      formControlName="cmd_0161"
                      value="1"
                    />Does Not Apply</label
                  >
                  <br />
                  <i style="font-size: 12px"
                    >Note: Signature Required even if checked.</i
                  >
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="6" width="30%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Name of Patient Involved</label
                  >
                </div>
              </td>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Age</label>
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Month and Year of Occurrence
                  </label>
                  <br />
                  <i class="normalParagraphStyle"
                    >(Event precipitating claim)</i
                  >
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Month and Year of Lawsuit</label
                  >
                </div>
              </td>
              <td colspan="6" width="30%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Insurance Carrier at Time</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="30%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 45%"
                    class="form-control onboard "
                    id="1205"
                    name="1205"
                    formControlName="1205"
                    type="text"
                    data-id="Patientfirstnm"
                  />
                  <input
                    style="width: 45%"
                    class="form-control onboard "
                    id="1206"
                    name="1206"
                    formControlName="1206"
                    type="text"
                    data-id="Patientlastnm"
                  />
                </div>
              </td>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1207"
                    name="1207"
                    formControlName="1207"
                    type="text"
                    data-id="patient age"
                  />
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1208"
                    name="1208"
                    formControlName="1208"
                    type="text"
                    data-id="date of occurance"
                  />
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1209"
                    name="1209"
                    formControlName="1209"
                    type="text"
                    data-id="date of lawsuite"
                  />
                </div>
              </td>
              <td colspan="6" width="30%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1210"
                    name="1210"
                    formControlName="1210"
                    type="text"
                    data-id="Insur carrier at time of claim"
                  />
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >What is/was your status?</label
                  >
                </div>
              </td>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >List other defendants:</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0162"
                      name="cmd_0162"
                      formControlName="cmd_0162"
                      value="1"
                    />Primary Defendant</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0163"
                      name="cmd_0163"
                      formControlName="cmd_0163"
                      value="1"
                    />Co-Defendant</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0164"
                      name="cmd_0164"
                      formControlName="cmd_0164"
                      value="1"
                    />Other, please explain:</label
                  >
                </div>
              </td>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1213"
                    name="1213"
                    formControlName="1213"
                    type="text"
                    data-id="List other defendants"
                  />
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >What was the patient’s outcome?</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1214"
                    name="1214"
                    formControlName="1214"
                    type="text"
                    data-id="Patient Outcome"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >How were you alleged to have caused harm or injury to this
                    patient?</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1216"
                    name="1216"
                    formControlName="1216"
                    type="text"
                    data-id="Cause of harm"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please provide specifics in reference to the adverse
                    event:</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1217"
                    name="1217"
                    formControlName="1217"
                    type="text"
                    data-id="Specifics on the claim"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >What is/was your role in this event?</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1218"
                    name="1218"
                    formControlName="1218"
                    type="text"
                    data-id="Role in event"
                  />
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="18" width="100%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding centered"
                >
                  <label class="boldParagraphStyle">CURRENT STATUS</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0165"
                      name="cmd_0165"
                      formControlName="cmd_0165"
                      value="1"
                    />Still pending</label
                  >
                  <label class="normalParagraphStyle">(as of) Date:</label>
                  <div class="input-group">
                    <input
                      style="width: 30%"
                      class="form-control onboard datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1219"
                      name="1219"
                      formControlName="1219"
                      type="text"
                      data-id="pending date"
                      ngbDatepicker
                      #ga76="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1219')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga76.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1219').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1219').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1219').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1219').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1219').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
              <td colspan="9" width="50%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Who is handling the defense of the case?</label
                  >
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <input
                    style="width: 100%"
                    class="form-control onboard "
                    id="1275"
                    name="1275"
                    formControlName="1275"
                    type="text"
                    data-id="defense lawyer"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0166"
                      name="cmd_0166"
                      formControlName="cmd_0166"
                      value="1"
                    />Trial date set - awaiting trial</label
                  >
                </div>
              </td>
              <td colspan="9" width="50%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle">Trial Date:</label>
                </div>
                <div class="mb-3  col-md-6 input-group">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control onboard datepicker"
                      placement="bottom"
                      placeholder="MM/DD/YYYY"
                      id="1220"
                      name="1220"
                      formControlName="1220"
                      type="text"
                      data-id="trail date"
                      ngbDatepicker
                      #ga77="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1220')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga77.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1220').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1220').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1220').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1220').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1220').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0167"
                      name="cmd_0167"
                      formControlName="cmd_0167"
                      value="1"
                    />Dismissed</label
                  >
                </div>
              </td>
              <td colspan="9" width="50%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle">Date of Dismissal:</label>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control onboard datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1222"
                      name="1222"
                      formControlName="1222"
                      type="text"
                      data-id="date of dismissal"
                      ngbDatepicker
                      #ga78="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1222')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga78.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1222').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1222').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1222').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1222').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1222').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0168"
                      name="cmd_0168"
                      formControlName="cmd_0168"
                      value="1"
                    />Defense Verdict</label
                  >
                </div>
              </td>
              <td colspan="9" width="50%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Date of Defense Verdict:</label
                  >
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control onboard datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1223"
                      name="1223"
                      formControlName="1223"
                      type="text"
                      data-id="defense verdict"
                      ngbDatepicker
                      #ga79="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1223')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga79.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1223').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1223').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1223').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1223').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1223').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="6" width="25%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0168"
                      name="cmd_0168"
                      formControlName="cmd_0168"
                      value="1"
                    />Settled out of court</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <label class="normalParagraphStyle">Date:</label>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <div class="input-group">
                    <input
                      style="width: 60%"
                      class="form-control onboard datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1226"
                      name="1226"
                      formControlName="1226"
                      type="text"
                      data-id="settled date"
                      ngbDatepicker
                      #ga80="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1226')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga80.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1226').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1226').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1226').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1226').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1226').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Total Amount of Settlement:</label
                  >
                  <input
                    style="width: 30%"
                    class="form-control onboard "
                    id="1228"
                    name="1228"
                    formControlName="1228"
                    type="text"
                    data-id="amount settled"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Defense Verdict:</label
                  >
                  <input
                    style="width: 50%"
                    class="form-control onboard "
                    id="1229"
                    name="1229"
                    formControlName="1229"
                    type="text"
                    data-id="settle amount paid by prov"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_0169"
                      name="cmd_0169"
                      formControlName="cmd_0169"
                      value="1"
                    />Judgment</label
                  >
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 col-md-6 inputPadding">
                  <label class="normalParagraphStyle">Date:</label>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      placement="buttom"
                      style="width: 50%"
                      class="form-control"
                      placeholder="MM/DD/YYYY"
                      id="1227"
                      name="1227"
                      formControlName="1227"
                      type="text"
                      data-id="Jedgement date"
                      ngbDatepicker
                      #ga81="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1227')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga81.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('1227').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('1227').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('1227').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('1227').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('1227').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Total Amount of Judgment:</label
                  >
                  <input
                    style="width: 50%"
                    class="form-control onboard "
                    id="1231"
                    name="1231"
                    formControlName="1231"
                    type="text"
                    data-id="Amount of Judgement"
                  />
                </div>
              </td>
              <td colspan="6" width="25%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Defense Verdict:</label
                  >
                  <input
                    style="width: 30%"
                    class="form-control onboard "
                    id="1232"
                    name="1232"
                    formControlName="1232"
                    type="text"
                    data-id="judge amount paid by prov"
                  />
                </div>
              </td>
            </tr>
          </table>

          <p>
            This Professional Liability Claims Information Form is required on
            all claims/lawsuits that are reported by your malpractice insurance
            carrier and/or the National Practitioner Data Bank. Clinical details
            are required for all suits, regardless of status or settlement
            amount.
          </p>
          <br />
          <p>
            I certify that the information contained in this form is correct and
            complete (even if N/A) to the best of my knowledge.
          </p>
          <table border="1" width="100%">
            <tr>
              <td width="15%">
                <label class="boldParagraphStyle">Signature:</label>
              </td>
              <td width="35%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <!-- <img
                    *ngIf="!showThree"
                    class="img"
                    src="./assets/images/e_sign.png"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <img
                    *ngIf="showThree"
                    class="img eSignImageShow"
                    [src]="esignThree"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <input
                    type="hidden"
                    name="cmd_0993"
                    id="cmd_0993"
                    formControlName="cmd_0993"
                    value="{{ finalImgPathThree }}"
                  /> -->
                  <img
                    *ngIf="!showThree"
                    class="img"
                    src="./assets/images/e_sign.png"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <img
                    *ngIf="showThree"
                    class="img eSignImageShow"
                    [src]="esignThree"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <input
                    type="hidden"
                    name="cmd_0993"
                    id="cmd_0993"
                    formControlName="cmd_0993"
                  />
                </div>
              </td>
              <td width="15%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Date:</label>
                </div>
              </td>
              <td width="35%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="cmd_0196"
                      name="cmd_0196"
                      formControlName="cmd_0196"
                      type="text"
                      data-id="PrintedName"
                      ngbDatepicker
                      #ga82="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_0196')"

                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga82.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="gaForm.get('cmd_0196').invalid ">
                      <small class="text-danger" *ngIf="gaForm.get('cmd_0196').errors.invalidDateFormat">
                       Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="gaForm.get('cmd_0196').errors.invalidMonth">
                      Month is out of range
                      </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_0196').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
                     <small class="text-danger" *ngIf="gaForm.get('cmd_0196').errors.invalidDateRange">
                     date is out of range
                     </small>
                     </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="mb-3">
          <div class="text-center">
            <h2>Schedule C</h2>
            <br />
            <h4>
              GEORGIA UNIFORM HEALTHCARE PRACTITIONER CREDENTIALING
              APPLICATIONFORM
            </h4>
            <h3>
              <u>REGULATION ACKNOWLEDGEMENT</u>
            </h3>
            <h4>
              <u>NOTICE TO PHYSICIANS</u>
            </h4>
          </div>
          <br />
          <br />
          <p>
            Medicare and Tri-Care payment to hospitals is based in part on each
            patient’s principal and secondary diagnosis and the major procedures
            performed on the patient, as attested to by the patient’s attending
            physician by virtue of his or her signature in the medical record.
          </p>
          <p>
            Anyone who misrepresents, falsifies, or conceals essential
            information required for payment of Federal funds may be subject to
            fine, imprisonment, or civil penalty under applicable Federal laws.
          </p>
          <div class="centered inputBorderBox">
            <h4 class="inputBoxStylesFive">SPONSORSHIP INFORMATION</h4>
          </div>
          <table border="1" width="100%">
            <tr>
              <td width="100%" colspan="2">
                <div class="mb-3 no-margin col-md-6 vcenter">
                  Please name your primary sponsoring physician:
                </div>
                <div class="mb-3 no-margin col-md-2 inputPadding">
                  <input
                    class="form-control onboard "
                    id="397"
                    name="397"
                    formControlName="397"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div class="mb-3 no-margin col-md-2 inputPadding">
                  <input
                    class="form-control onboard "
                    id="399"
                    name="399"
                    formControlName="399"
                    type="text"
                    placeholder="Last name"
                  />
                </div>
                <div class="mb-3 no-margin col-md-2 inputPadding">
                  <input
                    class="form-control onboard "
                    id="398"
                    name="398"
                    formControlName="398"
                    type="text"
                    placeholder="Middle Name"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="mb-3 no-margin col-md-4 vcenter">
                  Address:
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control onboard "
                    id="408"
                    name="408"
                    formControlName="408"
                    type="text"
                    placeholder="Address 1"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control onboard "
                    id="409"
                    name="409"
                    formControlName="409"
                    type="text"
                    placeholder="Address 2"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-3 no-margin col-md-3 vcenter">
                  Phone Number:
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control onboard "
                    id="414"
                    name="414"
                    formControlName="414"
                    type="text"
                  />
                </div>
              </td>
              <td>
                <div class="mb-3 no-margin col-md-3 inputPadding">
                  Fax Number
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    class="form-control onboard "
                    id="415"
                    name="415"
                    formControlName="415"
                    type="text"
                  />
                </div>
              </td>
            </tr>
          </table>

          <br /><br />
          <p>By my signature below, I acknowledge receipt of this notice.</p>
        </div>
        <table border="1" width="100%">
          <tr>
            <td>
              <label class="normalParagraphStyle">Signature:</label>
            </td>
            <td>
              <div class="col-md-5 vcenter">
                <!-- <img
                  *ngIf="!showFour"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <img
                  *ngIf="showFour"
                  class="img eSignImageShow"
                  [src]="esignFour"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <input
                  type="hidden"
                  name="cmd_0994"
                  id="cmd_0994"
                  formControlName="cmd_0994"
                  value="{{ finalImgPathFour }}"
                /> -->
                <img
                  *ngIf="!showFour"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <img
                  *ngIf="showFour"
                  class="img eSignImageShow"
                  [src]="esignFour"
                  alt="Credential my Doc"
                  (click)="esignOpenFour()"
                />
                <input
                  type="hidden"
                  name="cmd_100072"
                  id="cmd_100072"
                  formControlName="cmd_100072"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Printed Name:</label>
                <input
                  class="form-control onboard "
                  id="cmd_0170"
                  name="cmd_0170"
                  formControlName="cmd_0170"
                  type="text"
                  data-id="PrintedName"
                />
              </div>
            </td>
            <td>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="normalParagraphStyle">Date:</label>
                <div class="input-group">
                  <input
                    class="form-control onboard datepicker input-group"
                    placeholder="MM/DD/YYYY"
                    id="cmd_0199"
                    name="cmd_0199"
                    formControlName="cmd_0199"
                    type="text"
                    ngbDatepicker
                    #ga83="ngbDatepicker"
                    (blur)="clearInputIfInvalid('cmd_0199')"

                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga83.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div *ngIf="gaForm.get('cmd_0199').invalid ">
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0199').errors.invalidDateFormat">
                     Only Date Format is allowed(MM/DD/YYYY)</small>
                    <small class="text-danger" *ngIf="gaForm.get('cmd_0199').errors.invalidMonth">
                    Month is out of range
                    </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0199').errors.invalidDateyear">
                     year is out of range(1900-2099) </small>
                   <small class="text-danger" *ngIf="gaForm.get('cmd_0199').errors.invalidDateRange">
                   date is out of range
                   </small>
                   </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
  <div class="modal-footer mt-4">
    <button
      *ngIf="formData.receivedForm"
      type="button"
      class="btn customOrange float-end"
      (click)="saveForm('approve')"
    >
      Approve
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('submit')"
    >
      Submit
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('save')"
    >
      Save
    </button>
    <button
      *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-end"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
