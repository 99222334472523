import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-intermountian-form',
    templateUrl: './intermountian-form.component.html',
    styleUrls: ['./intermountian-form.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class IntermountianFormComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Input() emailCustId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  public interMountian: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  ImgOne: any;
  imgOneSet: any;
  imgOneSetting: any;
  ImgTwo: any;
  imgTwoSet: any;
  imgTwoSetting: any;

  finalImg1: any;
  finalImg2: any;
  finalImg3: any;

  finalImgPath1: any;
  finalImgPath2: any;
  finalImgPath3: any;

  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;

  esignOne: any;
  esignTwo: any;
  esignThree: any;
  public customerId: any;
  finalImg: any;
  states: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  race: Array<object> = [];
  specialty: Array<object> = [];
  degree: Array<object> = [];
  isSubmit: boolean = false;
  public preClaim: any = [];
  public aggregates: any = [];
  gender: Array<object> = [];
  staffStatus: Array<object> = [];
  finalImgPath: any = [];
  locDropdown: Array<any> = [];
  boardCertification: Array<object> = [];
  specialtySecondary: Array<object> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private storageService: StorageService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private onboardService: OnboardingformsServiceService,
    private _sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getGenderData();
    this.getStatesData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getRaceData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.getLocationData();
    this.getBoardCertificationData();
    this.getSpecialtySecondaryData();
    this.afterSaveDataDisplay();
    
  }
  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_333468') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_333455') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_333466') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.interMountian.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    console.log(filePath);
    if (fieldId == 'cmd_333468') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_333455') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_333466') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 33,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        // this.spinner.hide();
      },
      error => {
        // this.spinner.hide();
      }
    );
  }

  buildForm() {
    this.interMountian = this.fb.group({
      9: ['', [Validators.required]],
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],
      278: ['', [Validators.required]],
      32: ['', [Validators.required]],
      810: ['', [Validators.required]],
      289: ['', [Validators.required]],
      291: ['', [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      277: ['', [Validators.required]],
      1535: ['', [Validators.required]],
      1536: ['', [Validators.required]],
      2207: ['', [Validators.required]],
      18: ['', [Validators.required]],
      20: ['', [Validators.required]],
      21: ['', [Validators.required]],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      708: ['', [Validators.required]],
      759: ['', [Validators.required]],
      34: ['', [Validators.required]],
      35: ['', [Validators.required]],
      36: ['', [Validators.required]],
      38: ['', [Validators.required]],
      39: ['', [Validators.required]],
      293: ['', [Validators.required]],
      294: [null, [this.dateFormatValidator]],
      295: [null, [this.dateFormatValidator]],
      296: ['', [Validators.required]],
      284: [''],
      cmd_333360: [''],
      cmd_333361: [''],
      cmd_333362: [''],
      cmd_333363: [''],
      cmd_333378: [''],
      554: [''],
      1169: [''],
      557: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      300: [''],
      307: [null, [this.dateFormatValidator]],
      308: [null, [this.dateFormatValidator]],
      1274: [''],
      962: [''],
      cmd_333374: [''],
      cmd_333375: [''],
      cmd_333376: [''],
      cmd_333377: [''],
      cmd_333379: [''],
      561: [''],
      562: [''],
      564: [null, [this.dateFormatValidator]],
      567: [null, [this.dateFormatValidator]],
      333: [''],
      334: [''],
      335: [''],
      1634: [''],
      1635: [''],
      1636: [''],
      812: [''],
      325: [''],
      309: [''],
      310: [null, [this.dateFormatValidator]],
      311: [null, [this.dateFormatValidator]],
      337: [''],
      339: [null, [this.dateFormatValidator]],
      503: ['', [Validators.required]],
      510: [null, [Validators.required,this.dateFormatValidator]],
      1238: [null, [Validators.required,this.dateFormatValidator]],
      504: ['', [Validators.required]],
      505: ['', [Validators.required]],
      506: ['', [Validators.required]],
      508: ['', [Validators.required]],
      509: ['', [Validators.required]],
      cmd_333380: [''],
      512: ['', [Validators.required]],
      511: [null, [Validators.required,this.dateFormatValidator]],
      479: [''],
      481: [null, [this.dateFormatValidator]],
      1300: [null, [this.dateFormatValidator]],
      483: [''],
      484: [''],
      485: [''],
      487: [''],
      488: [''],
      cmd_333381: [''],
      480: [''],
      482: [null, [this.dateFormatValidator]],
      490: [''],
      497: [null, [this.dateFormatValidator]],
      498: [null, [this.dateFormatValidator]],
      491: [''],
      492: [''],
      493: [''],
      495: [''],
      496: [''],
      cmd_333382: [''],
      500: [''],
      499: [null, [this.dateFormatValidator]],
      517: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      518: [''],
      519: [''],
      520: [''],
      522: [''],
      523: [''],
      cmd_333383: [''],
      529: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      531: [''],
      1498: [''],
      533: [''],
      535: [''],
      536: [''],
      cmd_333384: [''],
      542: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      544: [''],
      545: [''],
      546: [''],
      548: [''],
      549: [''],
      cmd_333385: [''],
      445: [''],
      453: [''],
      448: [''],
      450: [''],
      1326: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      456: [''],
      464: [''],
      459: [''],
      1343: [''],
      461: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      467: [''],
      475: [''],
      470: [''],
      472: [''],
      1339: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      1935: [''],
      1944: [''],
      1938: [''],
      1940: [''],
      1949: [''],
      1349: [''],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      1954: [''],
      1963: [''],
      1957: [''],
      1959: [''],
      1968: [''],
      1966: [null, [this.dateFormatValidator]],
      1967: [null, [this.dateFormatValidator]],
      cmd_33473: [''],
      cmd_33479: [''],
      cmd_33476: [''],
      cmd_33477: [''],
      cmd_33481: [''],
      cmd_33482: [null, [this.dateFormatValidator]],
      cmd_33483: [null, [this.dateFormatValidator]],
      cmd_33484: [''],
      cmd_33490: [''],
      cmd_33487: [''],
      cmd_33488: [''],
      cmd_33492: [''],
      cmd_33493: [null, [this.dateFormatValidator]],
      cmd_33494: [null, [this.dateFormatValidator]],
      cmd_33495: [''],
      cmd_33501: [''],
      cmd_33498: [''],
      cmd_33499: [''],
      cmd_33503: [''],
      cmd_33504: [null, [this.dateFormatValidator]],
      cmd_33505: [null, [this.dateFormatValidator]],
      3395: [null, [this.dateFormatValidator]],
      3396: [null, [this.dateFormatValidator]],
      1138: [''],
      3397: [null, [this.dateFormatValidator]],
      3398: [null, [this.dateFormatValidator]],
      1831: [''],
      3399: [null, [this.dateFormatValidator]],
      3400: [null, [this.dateFormatValidator]],
      1832: [''],
      cmd_333457: [''],
      cmd_333458: [''],
      cmd_333459: [''],
      cmd_333460: [''],
      cmd_333461: [''],
      cmd_333462: [''],
      cmd_333463: [''],
      cmd_333468: ['', Validators.required],
      cmd_333452: [''],
      cmd_333453: [null, [this.dateFormatValidator]],
      cmd_333454: [''],
      cmd_333455: [''],
      cmd_333456: [null, [this.dateFormatValidator]],
      cmd_333465: [''],
      cmd_333466: [''],
      cmd_333467: [null, [this.dateFormatValidator]]
    });
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLocationData() {
    let params: {};
    if (this.emailCustId) {
      params = {
        customerId: this.emailCustId
      };
    } else {
      params = {
        customerId: this.customerId
      };
    }
    // console.log(this.location);
    //this.spinner.show();
    this.onboardService.getLocationData(params).subscribe(
      data => {
        if (data['success']) {
          this.locDropdown = data.data.map(i => ({
            ...i,
            id: i.location_id.toString(),
            label:
              (i.tin ? i.tin : '') +
              ' ' +
              (i.practice_name ? i.practice_name : '') +
              ' ' +
              (i.practice__add1 ? i.practice__add1 : '') +
              ' ' +
              (i.practice__add2 ? i.practice__add2 : '')
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
    if (type == 'submit') {
      console.log(this.interMountian);
      if (this.interMountian.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.interMountian.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.interMountian.value,
        formId: 33
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  handleFirstNamechange(firstName) {
    this.interMountian.patchValue({
      cmd_333465: firstName + ' ' + this.interMountian.controls[9].value,
      cmd_333457: firstName + ' ' + this.interMountian.controls[9].value,
      cmd_333454: firstName + ' ' + this.interMountian.controls[9].value,
      cmd_333452: firstName + ' ' + this.interMountian.controls[9].value
    });
  }

  handleLastNamechange(lastName) {
    this.interMountian.patchValue({
      cmd_333465: this.interMountian.controls[8].value + ' ' + lastName,
      cmd_333457: this.interMountian.controls[8].value + ' ' + lastName,
      cmd_333454: this.interMountian.controls[8].value + ' ' + lastName,
      cmd_333452: this.interMountian.controls[8].value + ' ' + lastName
    });
  }

  getNpiuser(value) {
    this.interMountian.patchValue({
      cmd_333458: value
    });
  }
  getNpiPass(value) {
    this.interMountian.patchValue({
      cmd_333459: value
    });
  }

  getCaqhUser(value) {
    this.interMountian.patchValue({
      cmd_333460: value
    });
  }
  getCaqhPass(value) {
    this.interMountian.patchValue({
      cmd_333461: value
    });
  }

  public esignOpenOne(type: any) {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();

      if (type == 1) {
        this.finalImgPath1 = data.finalFilePath;
        this.finalImg1 = data.base64Img;
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg1
        );
        this.showOne = true;

        let temp = {
          cmd_333468: this.finalImgPath1
        };
        this.interMountian.patchValue(temp);
      }
      if (type == 2) {
        this.finalImgPath2 = data.finalFilePath;
        this.finalImg2 = data.base64Img;
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg2
        );
        this.showTwo = true;
        let temp = {
          cmd_333455: this.finalImgPath2
        };
        this.interMountian.patchValue(temp);
      } else if (type == 3) {
        this.finalImgPath3 = data.finalFilePath;
        this.finalImg3 = data.base64Img;
        this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg3
        );
        this.showThree = true;
        let temp = {
          cmd_333466: this.finalImgPath3
        };
        this.interMountian.patchValue(temp);
      }
    });
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.interMountian.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    // if(handleYesNoValue ){
    //   if(handleYesNoValue == ("cmd_100043")){
    //     this.handleYesNoChanges(handleYesNoValue);
    //   }
    // }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.interMountian.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
