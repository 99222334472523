<div id="SagNeuSty">
   <div class="cotainer-fluid mx-1">
      <div class="header" >
         <div class="logoImg">
            <!-- <img class="logoImg" src="../../../../assets/images/sage.png" alt="Credential my Doc" /> -->
            <img class="logoImg" src="../../../../assets/images/sage_2217png.png" alt="Credential my Doc" />
         </div>
         <div class="">
            <div class="text-center pt-4">
               <h2><b> Sage Neuroscience Center, Credentialing Application </b></h2>
            </div>
         </div>
      </div>
      <div class="section">
         <form [formGroup]="sageneu">
            <div class="centered texasFirstTable">
               <h3 class="texasHThree"><b>Section I-Individual Information</b></h3>
            </div>
            <table border="1" width="100%">
               <tr>
                  <td colspan="18">
                     <div class="row">
                        <div class="col-md-4">
                           TYPE OF PROFESSIONAL
                           <div class="mb-3 selectDrop dropDrownSet">
                              <ng-select [items]="credentials" bindLabel="name" placeholder="Credentials" bindValue="id"
                                 formControlName="822">
                              </ng-select>

                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="row">
                        <div class="col-md-3">
                           <label class="formLabel">LAST NAME</label>
                           <input class="form-control" id="9" [class.is-invalid]="
                            (sageneu.controls['9'].touched &&
                            sageneu.controls['9'].invalid) ||
                            (sageneu.controls['9'].invalid && isSubmit)
                            " name="9" formControlName="9" placeholder="Last name" (change)="onChangeLastName($event)"
                              type="text" data-id="lname" />
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['9'].touched &&
                               sageneu.controls['9'].invalid
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['9'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="col-md-3 ">
                           <label class="formLabel">FIRST</label>
                           <input class="form-control" id="8" [class.is-invalid]="
                            (sageneu.controls['8'].touched &&
                            sageneu.controls['8'].invalid) ||
                            (sageneu.controls['8'].invalid && isSubmit)
                            " name="8" (change)="onChangeFirstName($event)" formControlName="8" type="text"
                              placeholder="First Name" data-id="fname" />
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['8'].touched &&
                               sageneu.controls['8'].invalid
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['8'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="col-md-3 ">
                           <label class="formLabel">MIDDLE</label>
                           <input class="form-control" id="809" name="809" formControlName="809" type="text"
                              data-id="mname" placeholder="Middle Name" />
                        </div>
                        <div class="col-md-3">
                           (JR., SR., ETC.)
                           <div class="mb-3 selectDrop dropDrownSet">
                              <ng-select [items]="suffix" bindLabel="name" placeholder="Suffix" bindValue="id"
                                 formControlName="29">
                              </ng-select>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="row">
                        <div class=" col-6">
                           <label class="formLabel">MAIDEN NAME </label>
                           <input class="form-control" id="278" name="278" formControlName="278" type="text"
                              data-id="priorname" placeholder="Prior Name" />
                        </div>
                        <div class="col-6">
                           <label class="formLabel">YEARS ASSOCIATED (YYYY-YYYY) </label>
                           <div class="input-group">
                              <input class="form-control onboard datepicker" placeholder="Prior Name Effective Date"
                                 ngbDatepicker #d1="ngbDatepicker" id="280" name="280" formControlName="280" type="text"
                                 data-id="priornameeffdate_date" (blur)="clearInputIfInvalid('280')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('280').invalid ">
                              <small class="text-danger" *ngIf="sageneu.get('280').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY)
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('280').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('280').errors?.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('280').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                           <div class="input-group">
                              <input class="form-control onboard datepicker" placeholder="Prior Name Term Date"
                                 ngbDatepicker #d2="ngbDatepicker" id="281" name="281" formControlName="281" type="text"
                                 data-id="priornametermdate_date" (blur)="clearInputIfInvalid('281')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('281').invalid ">
                              <small class="text-danger" *ngIf="sageneu.get('281').errors.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY) </small>
                              <small class="text-danger" *ngIf="sageneu.get('281').errors.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('281').errors.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('281').errors.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="row">
                        <div class="col-6">
                           <label class="formLabel">OTHER NAME </label>
                           <input class="form-control" id="2337" name="2337" formControlName="2337" type="text"
                              data-id="othername" />
                        </div>
                        <div class="mb-3 col-6">
                           <label class="formLabel">YEARS ASSOCIATED(YYYY-YYYY)</label>
                           <div class="input-group">
                              <input class="form-control onboard datepicker" ngbDatepicker #d3="ngbDatepicker"
                                 id="cmd_01" name="cmd_01" formControlName="cmd_01" type="text"
                                 data-id="othernameeffdate_date" (blur)="clearInputIfInvalid('cmd_01')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('cmd_01').invalid ">
                              <small class="text-danger" *ngIf="sageneu.get('cmd_01').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY) </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_01').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_01').errors?.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_01').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                           <div class="input-group">
                              <input class="form-control onboard datepicker" ngbDatepicker #d4="ngbDatepicker"
                                 id="cmd_02" name="cmd_02" formControlName="cmd_02" type="text"
                                 data-id="othernametermdate_date" (blur)="clearInputIfInvalid('cmd_02')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('cmd_02').invalid">
                              <small class="text-danger" *ngIf="sageneu.get('cmd_02').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY) </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_02').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_02').errors?.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('cmd_02').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">HOME MAILING ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" [class.is-invalid]="
                           (sageneu.controls['16'].touched &&
                           sageneu.controls['16'].errors) ||
                           (sageneu.controls['16'].errors && isSubmit)
                           " id="16" name="16" formControlName="16" type="text" data-id="homeaddress1"
                                 placeholder="Home Address 1" />
                              <div class="formLabel col-md-2" *ngIf="
                           sageneu.controls['16'].touched &&
                           sageneu.controls['16'].errors
                           ">
                                 <small class="text-danger" *ngIf="sageneu.controls['16'].errors?.required">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="17" name="17" formControlName="17" type="text"
                                 data-id="homeaddress2" placeholder="Home Address 2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="row">
                        <div class="col-4">
                           <label class="formLabel">CITY</label>
                           <input class="form-control" [class.is-invalid]="
                               (sageneu.controls['18'].touched &&
                               sageneu.controls['18'].invalid) ||
                               (sageneu.controls['18'].invalid && isSubmit)
                               " id="18" name="18" formControlName="18" type="text" data-id="homecity"
                              placeholder="Home City" />
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['18'].touched &&
                               sageneu.controls['18'].invalid
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['18'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="mb-3 col-4 ">
                           <label class="formLabel ">STATE/COUNTRY</label>
                           <div class="mb-3  dropDrownSet">
                              <ng-select [items]="states" [class.is-invalid]="
                               (sageneu.controls['20'].touched &&
                               sageneu.controls['20'].invalid) ||
                               (sageneu.controls['20'].invalid && isSubmit)
                               " bindLabel="name" placeholder="State or Country" bindValue="id" formControlName="20">
                              </ng-select>
                              <div class="formLabel col-md-2" *ngIf="
                                  sageneu.controls['20'].touched &&
                                  sageneu.controls['20'].invalid
                                  ">
                                 <small class="text-danger" *ngIf="sageneu.controls['20'].errors?.required">
                                    required
                                 </small>
                              </div>
                           </div>
                        </div>
                        <div class="mb-3 col-4 ">
                           <label class="formLabel">POSTAL CODE</label>
                           <input class="form-control " [class.is-invalid]="
                               (sageneu.controls['21'].touched &&
                               sageneu.controls['21'].invalid) ||
                               (sageneu.controls['21'].invalid && isSubmit)
                               " id="21" name="21" formControlName="21" type="text" data-id="homezip"
                              placeholder="Home Zip" />
                           <div class="text-danger"
                              *ngIf="(sageneu.controls['21'].invalid&&sageneu.controls['21'].touched) && (sageneu.controls['21'].value==''|| sageneu.controls['21'].value==null) ">
                              <small>
                                 required
                              </small>
                           </div>
                           <div class="formLabel col-md-12" *ngIf="
                               sageneu.controls['21'].invalid
                               ">

                              <small class="text-danger" *ngIf="
                                  sageneu.controls['21'].errors?.invalidZipCode
                                  ">
                                 Zip code must be atleast 5 digits and less than 10 digits
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class=" no-margin  sideLabelAlignment">
                        <label class="formLabel">HOME PHONE NUMBER </label>
                        <input class="form-control" (keypress)="numberOnly($event)" [class.is-invalid]="
                         (sageneu.controls['708'].touched &&
                         sageneu.controls['708'].invalid) ||
                         (sageneu.controls['708'].invalid && isSubmit)
                         " id="708" name="708" formControlName="708" type="text" data-id="homephone"
                           placeholder="Home Phone" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['708'].touched &&
                            sageneu.controls['708'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['708'].errors?.required">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">SOCIAL SECURITY NUMBER </label>
                        <input class="form-control" [class.is-invalid]="
                         (sageneu.controls['32'].touched &&
                         sageneu.controls['32'].invalid) ||
                         (sageneu.controls['32'].invalid && isSubmit)
                         " id="32" name="32" formControlName="32" type="text" data-id="ssn" placeholder="SSN"
                           (ngModelChange)="getSsn($event)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['32'].touched &&
                            sageneu.controls['32'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['32'].errors?.required">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">GENDER</label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="gender" bindLabel="name" placeholder="Gender" bindValue="id"
                              formControlName="30">
                           </ng-select>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">CORRESPONDENCE ADDRESS </label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="cmd_837" name="cmd_837" formControlName="cmd_837"
                                 type="text" data-id="prac1_MailingAddress1" />
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="cmd_838" name="cmd_838" formControlName="cmd_838"
                                 type="text" data-id="prac1_MailingAddress2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="row">
                        <div class="col-4">
                           <label class="formLabel">CITY</label>
                           <input class="form-control" id="cmd_839" name="cmd_839" formControlName="cmd_839" type="text"
                              data-id="prac1_MailingCity" />
                        </div>
                        <div class="col-4 ">
                           <label class="formLabel ">STATE/COUNTRY</label>
                           <div class="mb-3 dropDrownSet">
                              <div>
                                 <ng-select [items]="states" bindLabel="name" bindValue="id" formControlName="cmd_841">
                                 </ng-select>
                              </div>
                           </div>
                        </div>
                        <div class="col-4">
                           <label class="formLabel">POSTAL CODE</label>
                           <input class="form-control " id="cmd_842" name="cmd_842" formControlName="cmd_842"
                              type="text" data-id="prac1_MailingZIP" />
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_842'].errors?.pattern"> Zip code must
                              be atleast 5 digits and less than 10 digits.</small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER</label>
                        <input class="form-control" id="cmd_870" name="cmd_870" (keypress)="numberOnly($event)"
                           formControlName="cmd_870" type="text" data-id="prac1_mailingphone" />
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">FAX NUMBER </label>
                        <input class="form-control" id="cmd_871" name="cmd_871" formControlName="cmd_871" type="text"
                           data-id="prac1_mailingfax" />
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">E-MAIL </label>
                        <input class="form-control" [class.is-invalid]="
                            (sageneu.controls['759'].touched &&
                            sageneu.controls['759'].invalid) ||
                            (sageneu.controls['759'].invalid && isSubmit)
                            " id="759" name="759" formControlName="759" type="text" data-id="hemail"
                           placeholder="Email" />
                        <div class="formLabel col-md-12" *ngIf="
                            sageneu.controls['759'].touched &&
                            sageneu.controls['759'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['759'].errors?.required">
                              required
                           </small>
                           <small class="text-danger" *ngIf="sageneu.controls['759'].errors.pattern">Please enter a
                              valid email.</small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="7">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">DATE OF BIRTH (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard" [class.is-invalid]="
                            (sageneu.controls['31'].touched &&
                            sageneu.controls['31'].invalid) ||
                            (sageneu.controls['31'].invalid && isSubmit)
                            " placeholder="DOB" ngbDatepicker #d5="ngbDatepicker" id="31" name="31"
                              formControlName="31" type="text" data-id="dob_date"
                              (blur)="clearInputIfInvalidMandi('31')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="(sageneu.controls['31'].invalid&&sageneu.controls['31'].touched) && (sageneu.controls['31'].value==''|| sageneu.controls['31'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <small class="text-danger" *ngIf="sageneu.get('31').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('31').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('31').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('31').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <!-- <td colspan="7">
                      <div class="col-md-8">
                          <div class="row">
                              <label for="31" class="col-sm-4 pt-2 formLabel">DATE OF BIRTH (MM/DD/YYYY)<span
                                      class="mandatory">*</span></label>
                              <div class="mb-3 col-md-8 ">
                                  <div class="input-group">
                                      <input class="form-control" formControlName="31" [class.is-invalid]="(sageneu.controls['31'].touched && sageneu.controls['31'].invalid) || (sageneu.controls['31'].invalid && isSubmit)" name="dp" placeholder="MM/DD/YYYY" ngbDatepicker #d5="ngbDatepicker" placement="bottom-left">
                                      <div class="input-group-append">
                                          <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i class="fas fa-calendar-alt"></i></button>
                                      </div>
                                  </div>
                                  <div class="formLabel col-md-4"
                                      *ngIf="sageneu.controls['31'].touched && sageneu.controls['31'].invalid">
                                      <small class="text-danger" *ngIf="sageneu.controls['31'].errors?.required">The
                                          DOB is required and cannot be empty
                                      </small>
                                  </div>
                              </div>
                          </div>
                      </div>
                      </td> -->
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PLACE OF BIRTH </label>
                     </div>
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <input class="form-control" id="810" name="810" formControlName="810" type="text"
                           data-id="bcity" placeholder="City of Birth" />

                     </div>
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                              formControlName="289">
                           </ng-select>

                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">CITIZENSHIP </label>
                        <input class="form-control" id="292" name="292" formControlName="292" type="text"
                           data-id="citizenship" placeholder="Citizenship" />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">IF NOT AMERICAN CITIZEN, VISA NUMBER & STATUS
                        </label>
                        <input class="form-control" id="1584" name="1584" formControlName="1584" type="text"
                           data-id="Visa #" placeholder="Visa Number" />
                        <input class="form-control" id="1282" name="1282" formControlName="1282" type="text"
                           data-id="Visa Status" placeholder="Visa Status" />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ARE YOU ELIGIBLE TO WORK IN THE UNITED STATES?
                        </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_04" name="cmd_04" formControlName="cmd_04"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_04" name="cmd_04" formControlName="cmd_04" value="0" />No</label>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                        <label class="formLabel">CAQH ID</label>
                        <input class="form-control" id="333" name="333" [class.is-invalid]="
                            (sageneu.controls['333'].touched &&
                            sageneu.controls['333'].invalid) ||
                            (sageneu.controls['333'].invalid && isSubmit)
                            " formControlName="333" type="text" data-id="caqh" placeholder="CAQH ID Number" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['333'].touched &&
                            sageneu.controls['333'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['333'].errors">
                              required
                           </small>
                        </div>

                     </div>
                     <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                        <label class="formLabel">USERNAME</label>
                        <input class="form-control" id="334" name="334" [class.is-invalid]="
                            (sageneu.controls['334'].touched &&
                            sageneu.controls['334'].invalid) ||
                            (sageneu.controls['334'].invalid && isSubmit)
                            " formControlName="334" type="text" data-id="caqh_username" placeholder="CAQH Username" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['334'].touched &&
                            sageneu.controls['334'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['334'].errors">
                              required
                           </small>
                        </div>
                     </div>
                     <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                        <label class="formLabel">PASSWORD</label>
                        <input class="form-control" id="335" name="335" formControlName="335" type="text"
                           [class.is-invalid]="
                            (sageneu.controls['335'].touched &&
                            sageneu.controls['335'].invalid) ||
                            (sageneu.controls['335'].invalid && isSubmit)
                            " data-id="caqh_pswd" placeholder="CAQH Password" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['335'].touched &&
                            sageneu.controls['335'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['335'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">U.S.MILITARY SERVICE/PUBLIC HEALTH
                        </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_05" name="cmd_05" formControlName="cmd_05"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_05" name="cmd_05" formControlName="cmd_05" value="0" />No</label>

                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">DATES OF SERVICE (MM/DD/YYYY) TO (MM/DD/YYYY)
                        </label>
                     </div>
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Active Duty Effective Date"
                              ngbDatepicker #d6="ngbDatepicker" id="1144" name="1144" formControlName="1144" type="text"
                              data-id="Mil Active from" (blur)="clearInputIfInvalid('1144')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>

                        <div *ngIf="sageneu.get('1144').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1144').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1144').errors.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1144').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1144').errors.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Active Duty Discharge Date"
                              ngbDatepicker #d7="ngbDatepicker" id="1145" name="1145" formControlName="1145" type="text"
                              data-id="Mil Active to" (blur)="clearInputIfInvalid('1145')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>

                        <div *ngIf="sageneu.get('1145').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1145').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1145').errors.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1145').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1145').errors.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LAST LOCATION </label>
                        <input class="form-control" id="1139" placeholder="Location of Last Duty Station" name="1139"
                           formControlName="1139" type="text" data-id="Military Locaiton" />

                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">BRANCH OF SERVICE </label>
                        <input placeholder="Branch" id="1141" name="1141" formControlName="1141" type="text"
                           data-id="Mil branch" class="form-control" />

                     </div>
                  </td>
                  <td colspan="9">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ARE YOU CURRENTLY ON ACTIVE OR RESERVE MILITARY DUTY?
                        </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_06" name="cmd_06" formControlName="cmd_06"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_06" name="cmd_06" formControlName="cmd_06" value="0" />No</label>
                     </div>
                  </td>
               </tr>
            </table>
            <table border="1" width="100%">
               <tr>
                  <td colspan="14">
                     <div class="centered texasFirstTable">
                        <h3 class="texasHThree">Education</h3>
                     </div>
                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input class="texasFloat" type="checkbox" name="cmd_0722" id="cmd_0722" formControlName="cmd_0722"
                        (ngModelChange)="handleNAchange('cmd_0722')" value="1" />
                     <!-- <input class="texasFloat" type="checkbox"  name="cmd_0722" id="cmd_0722" formControlName="cmd_0722" value="1" (change)="doesNotApply($event)"> -->
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PROFESSIONAL DEGREE (MEDICAL, DENTAL, CHIROPRACTIC, ETC.)
                        </label>
                     </div>
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                        <label class="formLabel">Issuing Institution: </label>
                     </div>
                     <div class="mb-3 no-margin col-md-6 sideLabelAlignment">
                        <input class="form-control" [class.is-invalid]="sageneu.controls['490'].invalid" id="490"
                           name="490" formControlName="490" type="text" data-id="meds"
                           (blur)="handleNAchange('cmd_0722', 490)" placeholder="Med School" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['490'].touched &&
                            sageneu.controls['490'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['490'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ADDRESS </label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" [class.is-invalid]="sageneu.controls['491'].invalid" id="491"
                                 name="491" formControlName="491" type="text" data-id="medsadd1"
                                 placeholder="Med School Address1" (blur)="handleNAchange('cmd_0722', 491)" />
                              <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['491'].touched &&
                            sageneu.controls['491'].errors
                            ">
                                 <small class="text-danger" *ngIf="sageneu.controls['491'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="492" name="492" formControlName="492" type="text"
                                 data-id="medsadd2" placeholder="Med School Address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input class="form-control" [class.is-invalid]="sageneu.controls['493'].invalid" id="493"
                                 name="493" formControlName="493" type="text" data-id="medsci"
                                 placeholder=" Med School City" (blur)="handleNAchange('cmd_0722', 493)" />
                              <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['493'].touched &&
                               sageneu.controls['493'].errors
                               ">
                                 <small class="text-danger" *ngIf="sageneu.controls['493'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div>
                                       <ng-select [items]="states" [class.is-invalid]="sageneu.controls['495'].invalid"
                                          bindLabel="name" placeholder="State or Country" bindValue="id"
                                          formControlName="495" (blur)="handleNAchange('cmd_0722', 495)">
                                       </ng-select>
                                    </div>
                                    <div class="formLabel col-md-2" *ngIf="
                                     sageneu.controls['495'].touched &&
                                     sageneu.controls['495'].errors
                                     ">
                                       <small class="text-danger" *ngIf="sageneu.controls['495'].errors">
                                          required
                                       </small>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input class="form-control" [class.is-invalid]="sageneu.controls['496'].invalid"
                                    id="496" name="496" formControlName="496" type="text" data-id="medsz"
                                    placeholder="Med School Zip" (blur)="handleNAchange('cmd_0722', 496)" />
                                 <div class="formLabel col-md-12" *ngIf="
                                  sageneu.controls['496'].invalid
                                  ">
                                    <small class="text-danger" *ngIf="sageneu.controls['496'].errors">
                                       required
                                    </small>
                                    <small class="text-danger" *ngIf="
                                     sageneu.controls['496'].errors?.invalidZipCode
                                     ">
                                       Zip code must be atleast 5 digits and less than 10 digits
                                    </small>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="mb-3  no-margin col-md-12 ">
                        <label class="formLabel">DEGREE </label>
                        <div class="mb-3 selectDrop dropDrownSet">
                           <ng-select [items]="degree" [class.is-invalid]="sageneu.controls['500'].invalid"
                              bindLabel="name" placeholder="Med School Degree" bindValue="id" formControlName="500"
                              (blur)="handleNAchange('cmd_0722', 500)">
                           </ng-select>
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['500'].touched &&
                               sageneu.controls['500'].errors
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['500'].errors">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="row">
                        <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 ">
                           <label class="formLabel">ATTENDANCE DATES(MM/YYYY TO MM/YYYY)</label>
                           <br />
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['497'].invalid" placeholder="Med School Start"
                              ngbDatepicker #d8="ngbDatepicker" id="497" name="497" formControlName="497" type="text"
                              data-id="medsstart_date" (blur)="handleNAchange('cmd_0722', 497)"
                              (blur)="clearInputIfInvalid('497','cmd_0722')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['497'].invalid && (sageneu.controls['497'].value==''|| sageneu.controls['497'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('497').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('497').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('497').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('497').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('497').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['498'].invalid" placeholder="Med School End"
                              ngbDatepicker #d9="ngbDatepicker" id="498" name="498" formControlName="498" type="text"
                              data-id="medsend_date" (blur)="handleNAchange('cmd_0722', 498)"
                              (blur)="clearInputIfInvalid('498','cmd_0722')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['498'].invalid && (sageneu.controls['498'].value==''|| sageneu.controls['498'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('498').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('498').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('498').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('498').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('498').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <input type="checkbox" id="cmd_0812" name="cmd_0812" formControlName="cmd_0812" value="1" />
                     <label><i>Please check this box and complete and submit Attachment A if you received other
                           professional degrees. </i></label>
                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline">POST-GRADUATE EDUCATION </label>
                     </div>
                     <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="0" />Internship
                     <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="1" />Residency
                     <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="2" />Fellowship
                     <input type="radio" id="cmd_08" name="cmd_08" formControlName="cmd_08" value="3" />Teaching
                     Appointment
                     <div class="text-danger" *ngIf="sageneu.controls['cmd_08'].invalid">
                        <small>
                           required
                        </small>
                     </div>

                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">SPECIALTY</label>
                     </div>
                     <div class="mb-3  dropDrownSet">
                        <ng-select [items]="specialty" [class.is-invalid]="sageneu.controls['527'].invalid"
                           bindLabel="name" placeholder="Specialities" bindValue="id" formControlName="527"
                           (blur)="handleNAchange('cmd_072235', 527)">
                        </ng-select>
                     </div>
                     <div class="formLabel col-md-2" *ngIf="
                      sageneu.controls['527'].touched &&
                      sageneu.controls['527'].errors
                      ">
                        <small class="text-danger" *ngIf="sageneu.controls['527'].errors">
                           required
                        </small>
                     </div>

                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input class="texasFloat" type="checkbox" name="cmd_072235" id="cmd_072235"
                        formControlName="cmd_072235" (ngModelChange)="handleNAchange('cmd_072235')" value="1" />
                     <!-- <input class="texasFloat" type="checkbox"  name="cmd_0722" id="cmd_0722" formControlName="cmd_0722" value="1" (change)="doesNotApply($event)"> -->
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">INSTITUTION</label>
                        <input class="form-control" id="517" name="517"
                           [class.is-invalid]="sageneu.controls['517'].invalid" formControlName="517" type="text"
                           data-id="interns" placeholder="Intern School " (blur)="handleNAchange('cmd_072235', 517)" />
                     </div>
                     <div class="formLabel col-md-2" *ngIf="
                      sageneu.controls['517'].touched &&
                      sageneu.controls['517'].errors
                      ">
                        <small class="text-danger" *ngIf="sageneu.controls['517'].errors">
                           required
                        </small>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="518" name="518"
                                 [class.is-invalid]="sageneu.controls['518'].invalid" placeholder="Address"
                                 formControlName="518" type="text" data-id="internadd1" placeholder="Intern Address1 "
                                 (blur)="handleNAchange('cmd_072235', 518)" />
                              <div class="formLabel col-md-2" *ngIf="
                      sageneu.controls['518'].touched &&
                      sageneu.controls['518'].errors
                      ">
                                 <small class="text-danger" *ngIf="sageneu.controls['518'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="519" name="519" placeholder="Address"
                                 formControlName="519" type="text" data-id="internadd2"
                                 placeholder="Intern Address2 " />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input class="form-control" [class.is-invalid]="sageneu.controls['520'].invalid" id="520"
                                 name="520" formControlName="520" type="text" data-id="internci"
                                 placeholder="Intern City " (blur)="handleNAchange('cmd_072235', 520)" />
                              <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['520'].touched &&
                               sageneu.controls['520'].errors
                               ">
                                 <small class="text-danger" *ngIf="sageneu.controls['520'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div>
                                       <ng-select [items]="states" bindLabel="name"
                                          [class.is-invalid]="sageneu.controls['522'].invalid"
                                          placeholder="State or Country" bindValue="id" formControlName="522"
                                          (blur)="handleNAchange('cmd_072235', 522)">
                                       </ng-select>
                                       <div class="formLabel col-md-2" *ngIf="
                                     sageneu.controls['522'].touched &&
                                     sageneu.controls['522'].errors
                                     ">
                                          <small class="text-danger" *ngIf="sageneu.controls['522'].errors">
                                             required
                                          </small>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input class="form-control" id="523"
                                    [class.is-invalid]="sageneu.controls['523'].invalid" name="523"
                                    formControlName="523" type="text" data-id="internz" placeholder="Intern Zip  "
                                    (blur)="handleNAchange('cmd_072235', 523)" />
                                 <div class="formLabel col-md-12" *ngIf="
                                  sageneu.controls['523'].invalid
                                  ">
                                    <small class="text-danger" *ngIf="sageneu.controls['523'].errors">
                                       required
                                    </small>
                                    <small class="text-danger" *ngIf="
                                     sageneu.controls['523'].errors?.invalidZipCode
                                     ">
                                       Zip code must be atleast 5 digits and less than 10 digits
                                    </small>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"></div>
                     <input type="checkbox" id="cmd_09" name="cmd_09" formControlName="cmd_09" value="1" />Program
                     successfully completed
                  </td>
                  <td colspan="12">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ATTENDANCE DATES (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Intern Start Date " ngbDatepicker
                              #d10="ngbDatepicker" id="524" name="524" formControlName="524" type="text"
                              data-id="internstart_date" (blur)="clearInputIfInvalid('524')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                           
                        </div>
                        <div *ngIf="sageneu.get('524').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('524').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('524').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('524').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('524').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Intern End Date " ngbDatepicker
                              #d11="ngbDatepicker" id="525" name="525" formControlName="525" type="text"
                              data-id="internend_date" (blur)="clearInputIfInvalid('525')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                           
                        </div>
                        <div *ngIf="sageneu.get('525').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('525').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('525').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('525').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('525').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PROGRAM DIRECTOR </label>
                        <input class="form-control" id="528" name="528" formControlName="528" type="text"
                           data-id="interndir" placeholder="Intern Director " />
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">CURRENT PROGRAM DIRECTOR (IF KNOWN)
                        </label>
                        <input class="form-control" id="818" name="818" formControlName="818" type="text"
                           data-id="interndir2" placeholder="Intern Attention " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">POST-GRADUATE EDUCATION
                        </label>
                     </div>
                     <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="0" />Internship
                     <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="1" />Residency
                     <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="2" />Fellowship
                     <input type="radio" id="cmd_010" name="cmd_010" formControlName="cmd_010" value="3" />Teaching
                     Appointment
                  </td>
                  <td colspan="12">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">SPECIALTY</label>
                     </div>
                     <div class="mb-3  dropDrownSet">
                        <ng-select [items]="specialty" bindLabel="name"  bindValue="id"
                           formControlName="530">
                        </ng-select>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">INSTITUTION</label>
                        <input class="form-control" id="529" name="529" formControlName="529" type="text" data-id="res"
                           placeholder="Residency School " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="531" name="531" formControlName="531" type="text"
                                 data-id="resadd1" placeholder="Residency Address1 " />
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="1498" name="1498" formControlName="1498" type="text"
                                 data-id="resadd2" placeholder="Residency Address2 " />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input class="form-control" id="533" name="533" formControlName="533" type="text"
                                 data-id="resci" placeholder="Residency City " />
                           </div>
                           <div class="col-4">
                              <div class="mb-3 no-margin  sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="State or Country"
                                          bindValue="id" formControlName="535">
                                       </ng-select>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input class="form-control" id="536" name="536" formControlName="536" type="text"
                                    data-id="resz" placeholder="Residency Zip " />
                                 <small class="text-danger" *ngIf="sageneu.controls['536'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
            </table>
            <hr class="tablePersonal" />
            <table border="1" width="100%">
               <td colspan="14">
                  <div class="centered texasFirstTable">
                     <h3 class="texasHThree">Education -continued</h3>
                  </div>
               </td>
               <tr>
                  <td colspan="9">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle"><b>POST-GRADUATE EDUCATION</b>
                        </label>
                     </div>
                     <input type="checkbox" id="cmd_011" name="cmd_011" formControlName="cmd_011" value="1" />Program
                     successfully completed
                  </td>
                  <td colspan="9">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ATTENDANCE DATES (MM/YYYY TO MM/YYYY)
                        </label>
                     </div>
                     <div class="row">
                        <div class="col-6">
                           <div class="input-group">
                              <input class="form-control onboard datepicker" placeholder="Residency Start "
                                 ngbDatepicker #d12="ngbDatepicker" id="537" name="537" formControlName="537"
                                 type="text" data-id="resstart_date" (blur)="clearInputIfInvalid('537')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()"
                                    type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                             
                           </div>
                           <div *ngIf="sageneu.get('537').invalid">
                              <small class="text-danger" *ngIf="sageneu.get('537').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY) </small>
                              <small class="text-danger" *ngIf="sageneu.get('537').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('537').errors?.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('537').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="input-group ">
                              <input class="form-control onboard datepicker" placeholder="Residency End " ngbDatepicker
                                 #d13="ngbDatepicker" id="538" name="538" formControlName="538" type="text"
                                 data-id="resend_date" (blur)="clearInputIfInvalid('538')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()"
                                    type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                              
                           </div>
                           <div *ngIf="sageneu.get('538').invalid ">
                              <small class="text-danger" *ngIf="sageneu.get('538').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY) </small>
                              <small class="text-danger" *ngIf="sageneu.get('538').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('538').errors?.invalidDateyear">
                                 year is out of range(1900-2099) </small>
                              <small class="text-danger" *ngIf="sageneu.get('538').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                        </div>
                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">PROGRAM DIRECTOR </label>
                        <input class="form-control" id="540" name="540" formControlName="540" type="text"
                           data-id="resdir1" placeholder="Residency Director " />
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">CURRENT PROGRAM DIRECTOR (IF KNOWN)</label>
                        <input class="form-control" id="541" name="541" formControlName="541" type="text"
                           data-id="resdir2" placeholder="Residency Attention  " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <input type="checkbox" id="cmd_0813" name="cmd_0813" formControlName="cmd_0813" value="1" />
                     <label><i>Please check this box and complete and submit Attachment B if you received additional
                           postgraduate training. </i></label>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>OTHER GRADUATE-LEVEL EDUCATION</b></label>
                     </div>
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 sideLabelAlignment">
                        <label class="formLabel">Issuing Institution: </label>
                     </div>
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <input class="form-control" id="503" name="503" formControlName="503" type="text"
                           data-id="UG_school1" placeholder="UG School " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="504" name="504" formControlName="504" type="text"
                                 data-id="UG School Address1" placeholder="UG School Address1 " />
                           </div>
                           <div class="col-6">

                              <input class="form-control" id="505" name="505" formControlName="505" type="text"
                                 data-id="UG School Address2" placeholder="UG School Address2 " />
                           </div>
                        </div>

                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input class="form-control" id="506" name="506" formControlName="506"
                                 type="text" data-id="UG School City" placeholder="UG School City " />
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet ">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="State or Country"
                                          bindValue="id" formControlName="508">
                                       </ng-select>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input class="form-control" id="509" name="509" formControlName="509" type="text"
                                    data-id="UG School Zip" placeholder="UG School Zip " />
                                 <small class="text-danger" *ngIf="sageneu.controls['509'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">DEGREE</label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="degree" bindLabel="name" placeholder="UG Degree" bindValue="id"
                              formControlName="512">
                           </ng-select>
                        </div>
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ATTENDANCE DATES (MM/YYYY TO MM/YYYY)
                        </label>
                     </div>
                     <div class="input-group ">
                        <input class="form-control onboard datepicker" ngbDatepicker #d14="ngbDatepicker" id="510"
                           name="510" formControlName="510" type="text" data-id="Training Pro 1 from"
                           (blur)="clearInputIfInvalid('510')" />
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button">
                              <i class="fas fa-calendar-alt"></i>
                           </button>
                        </div>
                        
                     </div>
                     <div *ngIf="sageneu.get('510').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('510').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('510').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('510').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('510').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                     <div class="input-group">
                        <input class="form-control onboard datepicker" ngbDatepicker #d15="ngbDatepicker" id="1238"
                           name="1238" formControlName="1238" type="text" data-id="Training Pro 1 to"
                           (blur)="clearInputIfInvalid('1238')" />
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button">
                              <i class="fas fa-calendar-alt"></i>
                           </button>
                        </div>
                       
                     </div>
                     <div *ngIf="sageneu.get('1238').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('1238').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('1238').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('1238').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('1238').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle"><b>Licenses and Certificates</b></label>
                        - Please include all license(s) and certifications in all States
                        where you are currently or have previously been licensed.
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE TYPE </label>
                        <input class="form-control" [class.is-invalid]="
                            (sageneu.controls['296'].touched &&
                            sageneu.controls['296'].errors) ||
                            (sageneu.controls['296'].errors && isSubmit)
                            " id="296" name="296" formControlName="296" type="text" data-id="licensetype"
                           placeholder="License Type " />
                     </div>
                     <div class="formLabel col-md-2" *ngIf="
                      sageneu.controls['296'].touched &&
                      sageneu.controls['296'].errors
                      ">
                        <small class="text-danger" *ngIf="sageneu.controls['296'].errors?.required">
                           required
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE NUMBER </label>
                        <input [class.is-invalid]="
                         (sageneu.controls['293'].touched &&
                         sageneu.controls['293'].errors) ||
                         (sageneu.controls['293'].errors && isSubmit)
                         " class="form-control" id="293" name="293" formControlName="293" type="text" data-id="license"
                           placeholder="License Number  " />
                        <div class="formLabel col-md-2" *ngIf="
                         sageneu.controls['293'].touched &&
                         sageneu.controls['293'].errors
                         ">
                           <small class="text-danger" *ngIf="sageneu.controls['293'].errors?.required">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12" style="padding: 2px;">
                        <label class="formLabel">STATE OF REGISTRATION </label>
                        <div class=" dropDrownSet">
                           <div >
                              <ng-select [items]="states" [class.is-invalid]="
                            (sageneu.controls['299'].touched &&
                            sageneu.controls['299'].errors) ||
                            (sageneu.controls['299'].errors && isSubmit)
                            " bindLabel="name" placeholder="State or Country" bindValue="id" formControlName="299">
                              </ng-select>
                           </div>
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['299'].touched &&
                            sageneu.controls['299'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['299'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input [class.is-invalid]="
                            (sageneu.controls['294'].touched &&
                            sageneu.controls['294'].errors) ||
                            (sageneu.controls['294'].errors && isSubmit)
                            " class="form-control onboard datepicker" placeholder="License Issue Date " ngbDatepicker
                              #d16="ngbDatepicker" id="294" name="294" formControlName="294" type="text"
                              data-id="licenseissuedate" (blur)="clearInputIfInvalidMandi('294')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="(sageneu.controls['294'].invalid&&sageneu.controls['294'].touched) && (sageneu.controls['294'].value==''|| sageneu.controls['294'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <small class="text-danger" *ngIf="sageneu.get('294').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('294').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('294').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('294').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input [class.is-invalid]="
                            (sageneu.controls['295'].touched &&
                            sageneu.controls['295'].errors) ||
                            (sageneu.controls['295'].errors && isSubmit)
                            " class="form-control onboard datepicker" placeholder="License Expiration Date "
                              ngbDatepicker #d17="ngbDatepicker" id="295" name="295" formControlName="295" type="text"
                              data-id="licenseexp_date" (blur)="clearInputIfInvalidMandi('295')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="(sageneu.controls['295'].invalid&&sageneu.controls['295'].touched) && (sageneu.controls['295'].value==''|| sageneu.controls['295'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <small class="text-danger" *ngIf="sageneu.get('295').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('295').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('295').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('295').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        DO YOU CURRENTLY PRACTICE IN THIS STATE?
                     </div>
                     <div class="mb-3 no-margin col-md-10 sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_013" name="cmd_013" formControlName="cmd_013"
                              value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_013" name="cmd_013" formControlName="cmd_013"
                              value="0" />No</label>
                     </div>
                     <!-- <div class="mb-3 no-margin col-md-8 sideLabelAlignment">
                         <input
                            type="checkbox"
                            name="cmd_07259"
                            id="cmd_07259"
                            formControlName="cmd_07259"
                            (ngModelChange)="handleNAchange('cmd_07259')"
                            value="1"
                            class="texasFloat"
                            />
                         I have not obtained Licensure yet
                      </div> -->
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE TYPE </label>
                        <input class="form-control" id="1274" name="1274" formControlName="1274" type="text"
                           data-id="Alt License type" placeholder="License 2 Type " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE NUMBER </label>
                        <input class="form-control" id="300" name="300" formControlName="300" type="text"
                           data-id="alt license" placeholder="License 2 Number " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">STATE OF REGISTRATION </label>
                        <div class=" dropDrownSet">
                           <div >
                              <ng-select [items]="altLicenseState" bindLabel="name" placeholder="Certification State "
                                 bindValue="id" formControlName="907">
                              </ng-select>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="License 2 Issue Date "
                              ngbDatepicker #d18="ngbDatepicker" id="307" name="307" formControlName="307" type="text"
                              data-id="alt licesne issue" (blur)="clearInputIfInvalid('307')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>

                        </div>
                        <div *ngIf="sageneu.get('307').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('307').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('307').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('307').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('307').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Alt License Exp " ngbDatepicker
                              #d19="ngbDatepicker" id="308" name="308" formControlName="308" type="text"
                              data-id="alt licesne exp" (blur)="clearInputIfInvalid('308')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('308').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('308').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('308').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('308').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('308').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        DO YOU CURRENTLY PRACTICE IN THIS STATE?
                     </div>
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_014" name="cmd_014" formControlName="cmd_014"
                              value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_014" name="cmd_014" formControlName="cmd_014"
                              value="0" />No</label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE TYPE </label>
                        <input class="form-control" id="1432" name="1432" formControlName="1432" type="text"
                           data-id="add_license_type" placeholder="Additional License Type " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">LICENSE NUMBER </label>
                        <input class="form-control" id="999" name="999" formControlName="999" type="text"
                           data-id="add_license" placeholder="Additional License Number  " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">STATE OF REGISTRATION </label>
                        <div class=" dropDrownSet">
                           <div >
                              <ng-select [items]="additionalLicenseState" bindLabel="name"
                                 placeholder="Additional License State " bindValue="id" formControlName="1010">
                              </ng-select>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Additional License Issue Date "
                              ngbDatepicker #d20="ngbDatepicker" id="1003" name="1003" formControlName="1003"
                              type="text" data-id="add license issue" (blur)="clearInputIfInvalid('1003')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('1003').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1003').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1003').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1003').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1003').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              placeholder="Additional License Expiration Date " ngbDatepicker #d21="ngbDatepicker"
                              id="1004" name="1004" formControlName="1004" type="text" data-id="add license exp"
                              (blur)="clearInputIfInvalid('1004')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>

                        </div>
                        <div *ngIf="sageneu.get('1004').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('1004').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1004').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1004').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('1004').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        DO YOU CURRENTLY PRACTICE IN THIS STATE?
                     </div>
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_015" name="cmd_015" formControlName="cmd_015"
                              value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_015" name="cmd_015" formControlName="cmd_015"
                              value="0" />No</label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"></div>
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="radio-inline radioStyle"></label>
                        <input type="radio" id="cmd_016" name="cmd_016" formControlName="cmd_016" value="1" />DEA
                        Number:
                        <input class="form-control" id="309" [class.is-invalid]="
                            (sageneu.controls['309'].touched &&
                            sageneu.controls['309'].errors) ||
                            (sageneu.controls['309'].errors && isSubmit)
                            " name="309" formControlName="309" type="text" data-id="dea" placeholder="DEA Number " />
                     </div>
                     <div class="formLabel col-md-2" *ngIf="
                      sageneu.controls['309'].touched &&
                      sageneu.controls['309'].errors
                      ">
                        <small class="text-danger" *ngIf="sageneu.controls['309'].errors?.required">
                           required
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" [class.is-invalid]="
                            (sageneu.controls['310'].touched &&
                            sageneu.controls['310'].errors) ||
                            (sageneu.controls['310'].errors && isSubmit)
                            " placeholder="DEA Issue Date " ngbDatepicker #d22="ngbDatepicker" id="310" name="310"
                              formControlName="310" type="text" data-id="deaissue"
                              (blur)="clearInputIfInvalidMandi('310')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>

                        </div>
                        <div class="mandatory"
                           *ngIf="(sageneu.controls['310'].invalid&&sageneu.controls['310'].touched) && (sageneu.controls['310'].value==''|| sageneu.controls['310'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <small class="text-danger" *ngIf="sageneu.get('310').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('310').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('310').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('310').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" [class.is-invalid]="
                            (sageneu.controls['311'].touched &&
                            sageneu.controls['311'].errors) ||
                            (sageneu.controls['311'].errors && isSubmit)
                            " ngbDatepicker #d23="ngbDatepicker" placeholder="DEA Exp Date " id="311" name="311"
                              formControlName="311" type="text" data-id="dea_exp_date"
                              (blur)="clearInputIfInvalidMandi('311')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>

                        </div>
                        <div class="mandatory"
                           *ngIf="(sageneu.controls['311'].invalid&&sageneu.controls['311'].touched) && (sageneu.controls['311'].value==''|| sageneu.controls['311'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <small class="text-danger" *ngIf="sageneu.get('311').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('311').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('311').errors?.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('311').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment"></div>
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="radio-inline radioStyle"></label>
                        <input type="radio" id="cmd_017" name="cmd_017" formControlName="cmd_017" value="1" />
                        CDS Number:
                        <input class="form-control" id="337" name="337" formControlName="337" type="text"
                           data-id="driverslic" placeholder="State Controlled Substance Number (CDS) " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="CDS Issue Date " ngbDatepicker
                              #d24="ngbDatepicker" id="338" name="338" formControlName="338" type="text"
                              data-id="drivers issue" (blur)="clearInputIfInvalid('338')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                           
                        </div>
                        <div *ngIf="sageneu.get('338').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('338').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('338').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('338').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('338').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="CDS Exp Date " ngbDatepicker
                              #d25="ngbDatepicker" id="339" name="339" formControlName="339" type="text"
                              data-id="drivers exp" (blur)="clearInputIfInvalid('339')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('339').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('339').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('339').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('339').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('339').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">OTHER CDS (PLEASE SPECIFY) </label>
                        <input class="form-control" id="cmd_019" name="cmd_019" formControlName="cmd_019" type="text"
                           data-id="CDs other" />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">NUMBER</label>
                        <input class="form-control" id="340" name="340" formControlName="340" type="text"
                           data-id="CDs number" placeholder="Alt CDS Number " />
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">STATE OF REGISTRATION </label>
                        <div class="  dropDrownSet">
                           <div >
                              <ng-select [items]="AltCdsStates" bindLabel="name" placeholder="Alt CDS State "
                                 bindValue="id" formControlName="997">
                              </ng-select>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ORIGINAL DATE OF ISSUE (MM/DD/YYYY)
                        </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Alt CDS Issue Date "
                              ngbDatepicker #d26="ngbDatepicker" id="341" name="341" formControlName="341" type="text"
                              data-id="alt CDs issue date" (blur)="clearInputIfInvalid('341')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('341').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('341').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('341').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('341').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('341').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Alt CDS Exp Date " ngbDatepicker
                              #d27="ngbDatepicker" id="342" name="342" formControlName="342" type="text"
                              data-id="alt CDs Exp date" (blur)="clearInputIfInvalid('342')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('342').invalid">
                        <small class="text-danger" *ngIf="sageneu.get('342').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('342').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('342').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('342').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        DO YOU CURRENTLY PRACTICE IN THIS STATE?
                     </div>
                     <label class="formLabel">
                        <input type="radio" id="cmd_020" name="cmd_020" formControlName="cmd_020"
                           value="1" />Yes</label>
                     <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_020" name="cmd_020" formControlName="cmd_020" value="0" />No</label>

                  </td>
               </tr>
               <tr>
                  <td colspan="2">
                     <div class="  col-md-12 sideLabelAlignment">
                        <label class="formLabel">UPIN</label>
                        <input class="form-control" id="819" name="819" formControlName="819" type="text" data-id="upin"
                           placeholder="UPIN" />
                     </div>
                  </td>
                  <td colspan="16">
                     <div class="row">
                        <div class="col-3">
                           <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                              <label class="formLabel">NATIONAL PROVIDER IDENTIFIER
                              </label>
                              <input class="form-control" id="1634" [class.is-invalid]="
                            (sageneu.controls['1634'].touched &&
                            sageneu.controls['1634'].errors) ||
                            (sageneu.controls['1634'].errors && isSubmit)
                            " name="1634" formControlName="1634" type="text" data-id="npi" placeholder="NPI" />
                           </div>
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['1634'].touched &&
                            sageneu.controls['1634'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['1634'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="col-3">
                           <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                              <label class="formLabel">NPI USERNAME
                              </label>
                              <input class="form-control" id="1635" name="1635" [class.is-invalid]="
                                 (sageneu.controls['1635'].touched &&
                                 sageneu.controls['1635'].errors) ||
                                 (sageneu.controls['1635'].errors && isSubmit)
                                 " formControlName="1635" type="text" data-id="npi-username"
                                 placeholder="NPI Username (NPPES) " />
                           </div>
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['1635'].touched &&
                            sageneu.controls['1635'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['1635'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="col-md-3">
                           <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                              <label class="formLabel">NPI PASSWORD
                              </label>
                              <input class="form-control" id="1636" [class.is-invalid]="
                                 (sageneu.controls['1636'].touched &&
                                 sageneu.controls['1636'].errors) ||
                                 (sageneu.controls['1636'].errors && isSubmit)
                                 " name="1636" formControlName="1636" type="text" data-id="npi_password"
                                 placeholder="NPI Password (NPPES) " />
                           </div>
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['1636'].touched &&
                            sageneu.controls['1636'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['1636'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                        <div class="col-md-3">
                           <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                              <label class="formLabel">TAXONOMY
                              </label>
                              <input class="form-control" id="4571" [class.is-invalid]="
                                 (sageneu.controls['4571'].touched &&
                                 sageneu.controls['4571'].errors) ||
                                 (sageneu.controls['4571'].errors && isSubmit)
                                 " name="4571" formControlName="4571" type="text" data-id="npi_taxomony"
                                 placeholder="Taxonomy" />
                           </div>
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['4571'].touched &&
                            sageneu.controls['4571'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['4571'].errors?.required">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>

                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        ARE YOU A PARTICIPATING MEDICARE PROVIDER?
                     </div>
                     <label class="formLabel">
                        <input type="radio" id="cmd_021" name="cmd_021" formControlName="cmd_021"
                           value="1" />Yes</label>
                     <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_021" name="cmd_021" formControlName="cmd_021" value="0" />No</label>
                     <label class="formLabel">Medicare Provider Number:</label>
                     <input class="form-control" id="325" name="325" formControlName="325" type="text"
                        data-id="medicare" placeholder="Medicare Number (PTAN) " />

                  </td>
                  <td colspan="9">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        ARE YOU A PARTICIPATING MEDICAID PROVIDER?
                     </div>
                     <label class="formLabel">
                        <input type="radio" id="cmd_022" name="cmd_022" formControlName="cmd_022"
                           value="1" />Yes</label>
                     <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_022" name="cmd_022" formControlName="cmd_022" value="0" />No</label>
                     <label class="formLabel">Medicaid Provider Number:</label>
                     <input class="form-control" id="812" name="812" formControlName="812" type="text"
                        data-id="medicaid" placeholder="Medicaid Number" />

                  </td>
               </tr>
               <tr>
                  <td colspan="12">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label>EDUCATIONAL COUNCIL FOR FOREIGN MEDICAL GRADUATES (ECFMG)
                        </label>
                        <div class="d-flex flex-row align-items-center flex-wrap">
                           <label class="radio-inline radioStyle">
                              <input type="radio" id="cmd_023" name="cmd_023" formControlName="cmd_023" value="0" />
                              N/A</label>

                           <label class="radio-inline radioStyle">
                              <input type="radio" id="cmd_023" name="cmd_023" formControlName="cmd_023"
                                 value="1" />Yes</label>
                           <label class="radio-inline radioStyle">
                              <input type="radio" id="cmd_023" name="cmd_023" formControlName="cmd_023"
                                 value="2" />No</label>
                           <label class="formLabel"> ECFMG Number:</label>
                           <input class="text form-control " id="514" name="514" formControlName="514" type="text"
                              data-id="ecfmg" placeholder="ECFMG Number " />
                        </div>





                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ECFMG ISSUE DATE (MM/DD/YYYY) </label>
                     </div>
                     <div class="input-group">
                        <input style="width:38%;" class="form-control onboard datepicker"
                           placeholder="ECFMG Issue Date " ngbDatepicker #d28="ngbDatepicker" id="515" name="515"
                           formControlName="515" type="text" data-id="ecfmgissue_date"
                           (blur)="clearInputIfInvalid('515')" />
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button">
                              <i class="fas fa-calendar-alt"></i>
                           </button>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('515').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('515').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('515').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('515').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('515').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
               </tr>
            </table>
            <table border="1" width="100%">
               <tr>
                  <td colspan="14">
                     <div class="centered texasFirstTable">
                        <h3 class="texasHThree">Professional/Specialty Information</h3>
                     </div>
                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input class="texasFloat" type="checkbox" name="cmd_0726" id="cmd_0726"
                        (ngModelChange)="handleNAchange('cmd_0726')" formControlName="cmd_0726" value="1" />
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="boldParagraph">PRIMARY SPECIALTY </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="specialty" [class.is-invalid]="sageneu.controls['284'].invalid"
                              bindLabel="name" placeholder="Specialities" bindValue="id" formControlName="284"
                              (blur)="handleNAchange('cmd_0726', 284)">
                           </ng-select>
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['284'].touched &&
                               sageneu.controls['284'].errors
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['284'].errors">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="12">
                     <label class="boldParagraph">BOARD CERTIFIED?</label>
                     <br />
                     <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_024" name="cmd_024" formControlName="cmd_024"
                           (blur)="handleNAchange('cmd_0726')" (ngModelChange)="handleYesNoChanges('cmd_024')"
                           [class.is-invalid]="(sageneu.controls['cmd_024'].touched &&
                      sageneu.controls['cmd_024'].invalid) ||
                      (sageneu.controls['cmd_024'].invalid && isSubmit)" value="1" />Yes</label>
                     <label class="radio-inline radioStyle">
                        <input type="radio" id="cmd_024" name="cmd_024" formControlName="cmd_024"
                           (blur)="handleNAchange('cmd_0726')" (ngModelChange)="handleYesNoChanges('cmd_024')"
                           [class.is-invalid]="(sageneu.controls['cmd_024'].touched &&
                      sageneu.controls['cmd_024'].invalid) ||
                      (sageneu.controls['cmd_024'].invalid && isSubmit)" value="0" />No</label>
                     <div class="mandatory" *ngIf="sageneu.controls['cmd_024'].invalid">
                        <small>
                           required
                        </small>
                     </div>
                     <span
                        *ngIf="(sageneu.controls['cmd_024'].touched && sageneu.controls['cmd_024'].errors) || (sageneu.controls['cmd_024'].errors && isSubmit)">
                        <i class="fas fa-times ms-2 text-danger"></i>
                     </span>
                     <br />
                     <div>
                        <label class="formLabel">Name of Certifying Board:</label>
                        <ng-select class="col-md-6" [items]="boardCertification" (blur)="handleYesNoChanges('cmd_024')"
                           [class.is-invalid]="sageneu.controls['554'].invalid"
                           placeholder="Primary Board Certification " bindValue="id" bindLabel="name"
                           formControlName="554">
                        </ng-select>
                        <div class="mandatory" *ngIf="sageneu.controls['554'].invalid">
                           <small>
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">INITIAL CERTIFICATION DATE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:80%;" [class.is-invalid]="sageneu.controls['557'].invalid"
                              class="form-control onboard datepicker" placeholder="Primary Board Original Issue Date "
                              ngbDatepicker #d29="ngbDatepicker" id="557" name="557" formControlName="557" type="text"
                              data-id="primarybcert" (blur)="handleNAchange('cmd_0726', 557)"
                              (blur)="clearInputIfInvalid('557','cmd_0726')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['557'].invalid && (sageneu.controls['557'].value==''|| sageneu.controls['557'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('557').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('557').errors.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="sageneu.get('557').errors.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('557').errors.invalidDateyear">
                           year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="sageneu.get('557').errors.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">RECERTIFICATION DATE(S), IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:48%;" class="form-control onboard datepicker"
                              placeholder="Primary Board First Recert Date "
                              [class.is-invalid]="sageneu.controls['558'].invalid" ngbDatepicker #d30="ngbDatepicker"
                              id="558" name="558" formControlName="558" type="text" data-id="primarybrcert2"
                              (blur)="handleNAchange('cmd_0726', 558)" (blur)="clearInputIfInvalid('558','cmd_0726')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['558'].invalid && (sageneu.controls['558'].value==''|| sageneu.controls['558'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>

                        <div *ngIf="sageneu.get('558').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('558').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('558').errors.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('558').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('558').errors.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>

                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE, IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:51%;" class="form-control onboard datepicker"
                              placeholder="Primary Board Expiration Date " ngbDatepicker #d31="ngbDatepicker"
                              [class.is-invalid]="sageneu.controls['560'].invalid" id="560" name="560"
                              formControlName="560" type="text" data-id="primarybexp_date"
                              (blur)="handleNAchange('cmd_0726', 560)" (blur)="clearInputIfInvalid('560','cmd_0726')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['560'].invalid && (sageneu.controls['560'].value==''|| sageneu.controls['560'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>

                        <div *ngIf="sageneu.get('560').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('560').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('560').errors.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('560').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('560').errors.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>

                  </td>
               </tr>
               <!-- <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <div class="col-md-8">
                           <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                              APPLY.</label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_025" name="cmd_025" formControlName="cmd_025" value="1" />
                              I have taken exam, results pending for Board
                              <input style="width: 50%" class="form-control" id="cmd_029" name="cmd_029"
                                 formControlName="cmd_029" type="text" data-id="test1" /></label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_026" name="cmd_026" formControlName="cmd_026" value="1" />
                              I have taken Part I and am eligible for Part II of the Exam.
                              <input style="width: 50%" class="form-control" id="cmd_030" name="cmd_030"
                                 formControlName="cmd_030" type="text" data-id="test2" /></label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_027" name="cmd_027" formControlName="cmd_027" value="1" />I
                              am intending to sit for the Boards on (date)
                              <input style="width: 50%" class="form-control" id="cmd_031" name="cmd_031"
                                 formControlName="cmd_031" type="text" data-id="test3" /></label>
                        </div>
                        <br />
                        <div class="row extraBoxPaddingLeft">
                           <div class="col-md-12">
                              <label class="boldParagraph" class="checkbox-inline radioStyle">
                                 <input type="checkbox" id="cmd_028" name="cmd_028" formControlName="cmd_028"
                                    value="1" />
                                 I am not planning to take Boards</label>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr> -->
               <tr>
                  <td colspan="18">

                     <div class="col-md-8">
                        <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                           APPLY.</label>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>

                              <input type="checkbox" id="cmd_025" name="cmd_025" formControlName="cmd_025" value="1" />
                              I have taken exam, results pending for Board</label>
                        </div>


                        <div class="col-md-6">
                           <input type="text" style="width:50%" id="cmd_029" class="form-control"
                              formControlName="cmd_029">


                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>
                              <input type="checkbox" id="cmd_026" name="cmd_026" formControlName="cmd_026" value="1" />
                              I have taken Part I and am eligible for Part II of the Exam.</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_030" name="cmd_030"
                              formControlName="cmd_030" type="text" data-id="test2" />
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>

                              <input type="checkbox" id="cmd_027" name="cmd_027" formControlName="cmd_027" value="1" />I
                              am intending to sit for the Boards on (date)</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_031" name="cmd_031"
                              formControlName="cmd_031" type="text" data-id="test3" />
                        </div>
                     </div>
                     <div class="row">

                        <div class="col-md-12">
                           <label>
                              <input type="checkbox" id="cmd_028" name="cmd_028" formControlName="cmd_028" value="1" />
                              I am not planning to take Boards</label>

                        </div>
                     </div>

                  </td>
               </tr>
               <!-- <tr>
                   <td colspan="18">
                      <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                         <div class="row extraBoxPaddingLeft">
                            <div class="col-md-12">
                               <label class="formLabel"
                                  >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                               SPECIALTY?
                               </label>
                            </div>
                         </div>
                         <br />
                         <label class="formLabel"><b>HMO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_032"
                            name="cmd_032"
                            formControlName="cmd_032"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_032"
                            name="cmd_032"
                            formControlName="cmd_032"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>PPO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_033"
                            name="cmd_033"
                            formControlName="cmd_033"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_033"
                            name="cmd_033"
                            formControlName="cmd_033"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>POS:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_034"
                            name="cmd_034"
                            formControlName="cmd_034"
                            value="1"
                            />Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_034"
                            name="cmd_034"
                            formControlName="cmd_034"
                            value="0"
                            />
                         No
                         </label>
                      </div>
                   </td>
                </tr> -->
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">SECONDARY SPECIALTY </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="specialtySecondary" bindLabel="name" placeholder="Secondary Specialty"
                              bindValue="id" formControlName="962">
                           </ng-select>
                        </div>
                     </div>
                  </td>
                  <td colspan="12">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="boldParagraph">BOARD CERTIFIED?</label>
                        <br />
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_035" name="cmd_035" formControlName="cmd_035"
                              value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_035" name="cmd_035" formControlName="cmd_035"
                              value="0" />No</label>
                        <br />

                        <label class="formLabel">Name of Certifying Board:</label>
                        <div class="mb-3  dropDrownSet ">
                           <div class="col-6">
                              <ng-select [items]="boardCertification" bindLabel="name"
                                 placeholder="2nd Board Certifications " bindValue="id" formControlName="561">
                              </ng-select>
                           </div>
                        </div>

                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">INITIAL CERTIFICATION DATE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:53%;" class="form-control onboard datepicker"
                              placeholder="Secondary Board Original Issue Date " ngbDatepicker #d32="ngbDatepicker"
                              id="564" name="564" formControlName="564" type="text" data-id="secbcert"
                              (blur)="clearInputIfInvalid('564')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('564').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('564').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('564').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('564').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('564').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">RECERTIFICATION DATE(S), IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:47%;" class="form-control onboard datepicker"
                              placeholder="Secondary Board First Recert Date " ngbDatepicker #d33="ngbDatepicker"
                              id="565" name="565" formControlName="565" type="text" data-id="secbrcert2"
                              (blur)="clearInputIfInvalid('565')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('565').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('565').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('565').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('565').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('565').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE, IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:50%;" class="form-control onboard datepicker"
                              placeholder="Secondary Board Expiration Date " ngbDatepicker #d34="ngbDatepicker" id="567"
                              name="567" formControlName="567" type="text" data-id="secbexp_date"
                              (blur)="clearInputIfInvalid('567')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d34.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('567').invalid">
                        <small class="text-danger" *ngIf="sageneu.get('567').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('567').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('567').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('567').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
               </tr>
            </table>
            <hr class="tablePersonal" />
            <table border="1" width="100%">
               <td colspan="18">
                  <div class="centered texasFirstTable">
                     <h3 class="texasHThree">
                        <b>Professional/Specialty Information</b> -continued
                     </h3>
                  </div>
               </td>
               <tr>
                  <td colspan="18">

                     <div class="col-md-8">
                        <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                           APPLY.</label>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>

                              <input type="checkbox" id="cmd_036" name="cmd_036" formControlName="cmd_036" value="1" />
                              I have taken exam, results pending for Board</label>
                        </div>


                        <div class="col-md-6
                          ">
                           <input type="text" style="width: 50%" id="cmd_040" class="form-control"
                              formControlName="cmd_040">


                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>
                              <input type="checkbox" id="cmd_037" name="cmd_037" formControlName="cmd_037" value="1" />
                              I have taken Part I and am eligible for Part II of the Exam.</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_041" name="cmd_041"
                              formControlName="cmd_041" type="text" data-id="test5" />
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>

                              <input type="checkbox" id="cmd_038" name="cmd_038" formControlName="cmd_038" value="1" />I
                              am intending to sit for the Boards on (date)</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_042" name="cmd_042"
                              formControlName="cmd_042" type="text" data-id="test6" />
                        </div>
                     </div>
                     <div class="row">

                        <div class="col-md-12">
                           <label>
                              <input type="checkbox" id="cmd_039" name="cmd_039" formControlName="cmd_039" value="1" />
                              I am not planning to take Boards</label>

                        </div>
                     </div>

                  </td>
               </tr>
               <!-- <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <div class="col-md-8">
                           <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                              APPLY.</label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_036" name="cmd_036" formControlName="cmd_036" value="1" />
                              I have taken exam, results pending for Board
                              <input style="width: 50%" class="form-control" id="cmd_040" name="cmd_040"
                                 formControlName="cmd_040" type="text" data-id="test4" /></label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_037" name="cmd_037" formControlName="cmd_037" value="1" />
                              I have taken Part I and am eligible for Part II of the Exam.
                              <input style="width: 50%" class="form-control" id="cmd_041" name="cmd_041"
                                 formControlName="cmd_041" type="text" data-id="test5" /></label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_038" name="cmd_038" formControlName="cmd_038" value="1" />I
                              am intending to sit for the Boards on (date)
                              <input style="width: 50%" class="form-control" id="cmd_042" name="cmd_042"
                                 formControlName="cmd_042" type="text" data-id="test6" /></label>
                        </div>
                        <br />
                        <div class="col-md-12">
                           <label class="boldParagraph" class="checkbox-inline radioStyle">
                              <input type="checkbox" id="cmd_039" name="cmd_039" formControlName="cmd_039" value="1" />
                              I am not planning to take Boards</label>
                        </div>
                     </div>
                  </td>
               </tr> -->
               <tr>
                  <td colspan="18" class="emptyRow">

                     <!-- <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                         <label class="formLabel"
                            >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                         SPECIALTY?
                         </label>
                         <br />
                         <label class="formLabel"><b>HMO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_043"
                            name="cmd_043"
                            formControlName="cmd_043"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_043"
                            name="cmd_043"
                            formControlName="cmd_043"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>PPO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_044"
                            name="cmd_044"
                            formControlName="cmd_044"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_044"
                            name="cmd_044"
                            formControlName="cmd_044"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>POS:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_045"
                            name="cmd_045"
                            formControlName="cmd_045"
                            value="1"
                            />Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_045"
                            name="cmd_045"
                            formControlName="cmd_045"
                            value="0"
                            />
                         No
                         </label>
                      </div> -->
                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ADDITIONAL SPECIALTY </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="specialtyTer" bindLabel="name" placeholder="Tertiary Specialty"
                              bindValue="id" formControlName="965">
                           </ng-select>
                        </div>
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="boldParagraph">BOARD CERTIFIED?</label>
                        <br />
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_046" name="cmd_046" formControlName="cmd_046"
                              value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_046" name="cmd_046" formControlName="cmd_046"
                              value="0" />No</label>
                        <br />
                        <div>
                           <label class="formLabel">Name of Certifying Board:</label>
                           <div class="mb-3  dropDrownSet">
                              <div>
                                 <ng-select [items]="boardCertification" bindLabel="name"
                                    placeholder="3rd Board Certifications" bindValue="id" formControlName="946">
                                 </ng-select>
                              </div>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">INITIAL CERTIFICATION DATE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:69%;" class="form-control onboard datepicker"
                              placeholder="Third Board Original Issue Date " ngbDatepicker #d35="ngbDatepicker" id="953"
                              name="953" formControlName="953" type="text" data-id="thrid board original issue date"
                              (blur)="clearInputIfInvalid('953')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('953').invalid">
                        <small class="text-danger" *ngIf="sageneu.get('953').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('953').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('953').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('953').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">RECERTIFICATION DATE(S), IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:43%;" class="form-control onboard datepicker"
                              placeholder="Third Board First Recert Date " ngbDatepicker #d36="ngbDatepicker" id="950"
                              name="950" formControlName="950" type="text" data-id="Tertiary Board recert 2"
                              (blur)="clearInputIfInvalid('950')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('950').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('950').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('950').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('950').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('950').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE, IF APPLICABLE (MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width:45%;" class="form-control onboard datepicker"
                              placeholder="Third Board Expiration Date " ngbDatepicker #d37="ngbDatepicker" id="954"
                              name="954" formControlName="954" type="text" data-id="Tertiary Board exp"
                              (blur)="clearInputIfInvalid('954')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d37.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('954').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('954').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('954').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('954').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('954').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">

                     <div class="col-md-8">
                        <label class="formLabel">IF NOT BOARD CERTIFIED, INDICATE ANY OF THE FOLLOWING THAT
                           APPLY.</label>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>
                              <input type="checkbox" id="cmd_047" name="cmd_047" formControlName="cmd_047" value="1" />
                              I have taken exam, results pending for Board</label>
                        </div>


                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_051" name="cmd_051"
                              formControlName="cmd_051" type="text" data-id="test7" />


                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>
                              <input type="checkbox" id="cmd_048" name="cmd_048" formControlName="cmd_048" value="1" />
                              I have taken Part I and am eligible for Part II of the Exam.</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_052" name="cmd_052"
                              formControlName="cmd_052" type="text" data-id="test8" />
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <label>

                              <input type="checkbox" id="cmd_049" name="cmd_049" formControlName="cmd_049" value="1" />I
                              am intending to sit for the Boards on (date)</label>
                        </div>
                        <div class="col-md-6">
                           <input style="width: 50%" class="form-control" id="cmd_053" name="cmd_053"
                              formControlName="cmd_053" type="text" data-id="test9" />
                        </div>
                     </div>
                     <div class="row">

                        <div class="col-md-12">
                           <label>
                              <input type="checkbox" id="cmd_050" name="cmd_050" formControlName="cmd_050" value="1" />
                              I am not planning to take Boards</label>

                        </div>
                     </div>

                  </td>
               </tr>
               <!-- <tr>
                   <td colspan="18">
                      <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                         <label class="formLabel"
                            >DO YOU WISH TO BE LISTED IN THE DIRECTORY UNDER THIS
                         SPECIALTY?
                         </label>
                         <br />
                         <label class="formLabel"><b>HMO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_054"
                            name="cmd_054"
                            formControlName="cmd_054"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_054"
                            name="cmd_054"
                            formControlName="cmd_054"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>PPO:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_055"
                            name="cmd_055"
                            formControlName="cmd_055"
                            value="1"
                            />
                         Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_055"
                            name="cmd_055"
                            formControlName="cmd_055"
                            value="0"
                            />
                         No
                         </label>
                         <br />
                         <label class="formLabel"><b>POS:</b></label>
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_056"
                            name="cmd_056"
                            formControlName="cmd_056"
                            value="1"
                            />Yes</label
                            >
                         <label class="boldParagraph" class="checkbox-inline radioStyle">
                         <input
                            type="radio"
                            id="cmd_056"
                            name="cmd_056"
                            formControlName="cmd_056"
                            value="0"
                            />
                         No
                         </label>
                      </div>
                   </td>
                </tr> -->


               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">PLEASE LIST OTHER AREAS OF PROFESSIONAL PRACTICE
                           INTEREST OR
                           FOCUS (HIV/AIDS, ETC.)
                        </label>
                        <input class="form-control" id="3826" name="3826" formControlName="3826" type="text"
                           data-id="other interest"
                           placeholder="Area of Interest, Special Experience, Skills and Training " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="14">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>Work History</b></label>- Please provide a chronological work
                        history. You may submit a
                        Curriculum Vitae as a supplement. Please explain all gaps in
                        employment that lasted more than six months.
                     </div>
                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input type="checkbox" name="cmd_0727" id="cmd_0727" formControlName="cmd_0727" value="1"
                        (ngModelChange)="handleNAchange('cmd_0727')" class="texasFloat" />
                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">
                           CURRENT PRACTICE/EMPLOYER NAME
                           <input class="form-control" id="445" name="445" formControlName="445"
                              [class.is-invalid]="sageneu.controls['445'].invalid" type="text" data-id="wkhist1"
                              placeholder="Work History 1 Location " (blur)="handleNAchange('cmd_0727', 445)" />
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['445'].touched &&
                               sageneu.controls['445'].errors
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['445'].errors">
                                 required
                              </small>
                           </div>
                        </label>
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">START DATE/END DATE (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group col-md-6">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['454'].invalid"
                              placeholder="Work History 1 Start Date " ngbDatepicker #d38="ngbDatepicker" id="454"
                              name="454" formControlName="454" type="text" data-id="wk1histstartdt"
                              (blur)="handleNAchange('cmd_0727', 454)" (blur)="clearInputIfInvalid('454','cmd_0727')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d38.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['454'].invalid && (sageneu.controls['454'].value==''|| sageneu.controls['454'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('454').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('454').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('454').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('454').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('454').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group col-md-6">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['455'].invalid"
                              placeholder="Work History 1 End Date " ngbDatepicker #d39="ngbDatepicker" id="455"
                              name="455" formControlName="455" type="text" data-id="wk1histenddt"
                              (blur)="handleNAchange('cmd_0727', 455)" (blur)="clearInputIfInvalid('455','cmd_0727')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d39.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['455'].invalid && (sageneu.controls['455'].value==''|| sageneu.controls['455'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('455').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('455').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('455').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('455').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('455').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" [class.is-invalid]="sageneu.controls['446'].invalid"
                                 class="form-control" placeholder="Address 1" id="446" name="446" formControlName="446"
                                 type="text" data-id="wkhist1add" placeholder="Work History 1 Address1 "
                                 (blur)="handleNAchange('cmd_0727', 446)" />
                              <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['446'].touched &&
                            sageneu.controls['446'].errors
                            ">
                                 <small class="text-danger" *ngIf="sageneu.controls['446'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control" placeholder="Address 2" id="447"
                                 name="447" formControlName="447" type="text" data-id="wkhist1add2"
                                 placeholder="Work History 1 Address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" placeholder="City"
                                 [class.is-invalid]="sageneu.controls['448'].invalid" class="form-control" id="448"
                                 name="448" formControlName="448" type="text" data-id="Work hist 1 city"
                                 placeholder="Work History 1 City " (blur)="handleNAchange('cmd_0727', 448)" />
                              <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['448'].touched &&
                               sageneu.controls['448'].errors
                               ">
                                 <small class="text-danger" *ngIf="sageneu.controls['448'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" [class.is-invalid]="sageneu.controls['450'].invalid"
                                          bindLabel="name" placeholder="States" bindValue="id" formControlName="450"
                                          (blur)="handleNAchange('cmd_0727', 450)">
                                       </ng-select>
                                    </div>
                                    <div class="formLabel col-md-2" *ngIf="
                                     sageneu.controls['450'].touched &&
                                     sageneu.controls['450'].errors
                                     ">
                                       <small class="text-danger" *ngIf="sageneu.controls['450'].errors">
                                          required
                                       </small>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" [class.is-invalid]="sageneu.controls['451'].invalid"
                                    class="form-control" placeholder="Zip" id="451" name="451" formControlName="451"
                                    type="text" data-id="Work hist 1 zip" placeholder="Work History 1 Zip "
                                    (blur)="handleNAchange('cmd_0727', 451)" />
                                 <div class="formLabel col-md-12" *ngIf="
                                  sageneu.controls['451'].invalid
                                  ">
                                    <small class="text-danger" *ngIf="sageneu.controls['451'].errors">
                                       required
                                    </small>
                                    <small class="text-danger" *ngIf="
                                     sageneu.controls['451'].errors?.invalidZipCode
                                     ">
                                       Zip code must be atleast 5 digits and less than 10 digits
                                    </small>
                                 </div>
                              </div>
                           </div>
                        </div>


                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                           <input class="form-control" id="456" name="456" formControlName="456" type="text"
                              data-id="wkhist2" placeholder="Work History 2 Location " />
                        </label>
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">START DATE/END DATE (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group col-md-6">
                           <input style="width:25%;" class="form-control onboard datepicker"
                              placeholder="Work History 2 Start Date " ngbDatepicker #d40="ngbDatepicker" id="465"
                              name="465" formControlName="465" type="text" data-id="wkhist2startdt"
                              (blur)="clearInputIfInvalid('465')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('465').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('465').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('465').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('465').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('465').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group col-md-6">
                           <input class="form-control onboard datepicker" placeholder="Work History 2 End Date "
                              ngbDatepicker #d41="ngbDatepicker" id="466" name="466" formControlName="466" type="text"
                              data-id="wkhist2enddt" (blur)="clearInputIfInvalid('466')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('466').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('466').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('466').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('466').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('466').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control" placeholder="Address 1" id="457"
                                 name="457" formControlName="457" type="text" data-id="wkhist2add"
                                 placeholder="Work History 2 Address1  " />
                           </div>
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control" placeholder="Address 2" id="458"
                                 name="458" formControlName="458" type="text" data-id="wkhist2address2"
                                 placeholder="Work History 2 Address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" placeholder="City" class="form-control" id="459" name="459"
                                 formControlName="459" type="text" data-id="Work hist 2 city"
                                 placeholder="Work History 2 City " />
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                                          formControlName="461">
                                       </ng-select>
                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3 no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" class="form-control" placeholder="Zip" id="462" name="462"
                                    formControlName="462" type="text" data-id="Work hist 2 zip"
                                    placeholder="Work History 2 Zip " />
                                 <small class="text-danger" *ngIf="sageneu.controls['462'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                        <input class="form-control" id="1338" name="1338" formControlName="1338" type="text"
                           data-id="wkhist2reasonforleaving" placeholder="Work History 2 Reason for Leaving " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                           <input class="form-control" id="467" name="467" formControlName="467" type="text"
                              data-id="wkhist3" placeholder="Work History 3 Location " />
                        </label>
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">START DATE/END DATE (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group col-6">
                           <input style="width:25%;" class="form-control onboard datepicker"
                              placeholder="Work History 3 Start Date " ngbDatepicker #d42="ngbDatepicker" id="476"
                              name="476" formControlName="476" type="text" data-id="wkhist3startdt"
                              (blur)="clearInputIfInvalid('476')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('476').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('476').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('476').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('476').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('476').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group col-6">
                           <input class="form-control onboard datepicker" placeholder="Work History 3 End Date "
                              ngbDatepicker #d43="ngbDatepicker" id="477" name="477" formControlName="477" type="text"
                              data-id="wkhist3enddt" (blur)="clearInputIfInvalid('477')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('477').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('477').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('477').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('477').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('477').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control"
                                 placeholder="Work History 3 Address1 " id="468" name="468" formControlName="468"
                                 type="text" data-id="wkhist3add" />
                           </div>
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control"
                                 placeholder="Work History 3 Address2 " id="469" name="469" formControlName="469"
                                 type="text" data-id="wkhist3address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" placeholder="City" class="form-control" id="470" name="470"
                                 formControlName="470" type="text" data-id="Work hist 3 city"
                                 placeholder="Work History 3 City " />
                           </div>
                           <div class="col-4">
                              <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                                          formControlName="472">
                                       </ng-select>
                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" class="form-control" placeholder="Zip" id="473" name="473"
                                    formControlName="473" type="text" data-id="Work hist 3 zip"
                                    placeholder="Work History 3 Zip " />
                                 <small class="text-danger" *ngIf="sageneu.controls['473'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                        <input class="form-control" id="1342" name="1342" formControlName="1342" type="text"
                           data-id="wkhist3reasonforleaving" placeholder="Work History 3 Reason for Leaving " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>PREVIOUS PRACTICE/EMPLOYER NAME</b>
                           <input class="form-control" id="1935" name="1935" formControlName="1935" type="text"
                              data-id="wkhist4" placeholder="Work History 4 Location " />
                        </label>
                     </div>
                  </td>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">START DATE/END DATE (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group col-md-6">
                           <input style="width:25%;" class="form-control onboard datepicker"
                              placeholder="Work History 4 Start Date " ngbDatepicker #d44="ngbDatepicker" id="1947"
                              name="1947" formControlName="1947" type="text" data-id="wkhist4startdt"
                              (blur)="clearInputIfInvalid('1947')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d44.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('1947').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1947').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1947').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1947').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1947').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group col-md-6">
                           <input class="form-control onboard datepicker" placeholder="Work History 4 End Date "
                              ngbDatepicker #d45="ngbDatepicker" id="1948" name="1948" formControlName="1948"
                              type="text" data-id="wkhist4enddt" (blur)="clearInputIfInvalid('1948')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('1948').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1948').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1948').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1948').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1948').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control"
                                 placeholder="Work History 4 Address1 " id="1936" name="1936" formControlName="1936"
                                 type="text" data-id="wkhist4add" />
                           </div>
                           <div class="col-6">
                              <input style="margin-bottom: 2px;" class="form-control"
                                 placeholder="Work History 4 Address2" id="1937" name="1937" formControlName="1937"
                                 type="text" data-id="wkhist4address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" placeholder="City" class="form-control" id="1938" name="1938"
                                 formControlName="1938" type="text" data-id="Work hist 4 city"
                                 placeholder="Work History 4 City " />
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                                          formControlName="1940">
                                       </ng-select>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" class="form-control" placeholder="Zip" id="1941" name="1941"
                                    formControlName="1941" type="text" data-id="Work hist 4 zip"
                                    placeholder="Work History 4 Zip " />
                                 <small class="text-danger" *ngIf="sageneu.controls['1941'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                        <input class="form-control" id="1952" name="1952" formControlName="1952" type="text"
                           data-id="wkhist4reasonforleaving" placeholder="Work History 4 Reason for Leaving " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="60%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">
                           PLEASE PROVIDE AN EXPLANATION FOR ANY GAPS GREATER THAN SIX
                           MONTHS (MM/YYYY TO MM/YYYY) IN WORK HISTORY.
                        </label>
                     </div>
                     <div class="mb-3">
                        <div class="row">
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel">Gap Dates: </label>
                              <div class="input-group">
                                 <input style="width: 40%" class="form-control onboard datepicker"
                                    placeholder="Gap Start Date " ngbDatepicker #d46="ngbDatepicker" id="3395"
                                    name="3395" formControlName="3395" type="text" data-id="Gap from 1"
                                    (blur)="clearInputIfInvalid('3395')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3395').invalid ">
                                 <small class="text-danger" *ngIf="sageneu.get('3395').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3395').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3395').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3395').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>

                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <div class="input-group">
                                 <input style="width: 53%" class="form-control onboard datepicker"
                                    placeholder="Gap End Date " ngbDatepicker #d47="ngbDatepicker" id="3396" name="3396"
                                    formControlName="3396" type="text" data-id="Gap to 1"
                                    (blur)="clearInputIfInvalid('3396')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3396').invalid">
                                 <small class="text-danger" *ngIf="sageneu.get('3396').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3396').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3396').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3396').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>
                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel">Explanation: </label>
                              <input style="width: 70%" class="form-control" id="1138" name="1138"
                                 formControlName="1138" type="text" data-id="Gap 1"
                                 placeholder="Explanation for Gap in Work History " />
                           </div>
                        </div>
                     </div>
                     <div class="mb-3">
                        <div class="row">

                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel">Gap Dates:</label>
                              <div class="input-group">
                                 <input style="width: 40%" class="form-control onboard datepicker"
                                    placeholder="Gap Start Date " ngbDatepicker #d48="ngbDatepicker" id="3397"
                                    name="3397" formControlName="3397" type="text" data-id="Gap from 2"
                                    (blur)="clearInputIfInvalid('3397')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3397').invalid">
                                 <small class="text-danger" *ngIf="sageneu.get('3397').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3397').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3397').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3397').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>

                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <div class="input-group">
                                 <input style="width: 53%" class="form-control onboard datepicker"
                                    placeholder="Gap End Date " ngbDatepicker #d49="ngbDatepicker" id="3398" name="3398"
                                    formControlName="3398" type="text" data-id="Gap to 2"
                                    (blur)="clearInputIfInvalid('3398')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3398').invalid ">
                                 <small class="text-danger" *ngIf="sageneu.get('3398').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3398').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3398').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3398').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>
                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel"> Explanation: </label>
                              <input style="width: 70%" class="form-control" id="1831" name="1831"
                                 formControlName="1831" type="text" data-id="Gap 2"
                                 placeholder="Explanation for Gap in Work History2 " />
                           </div>

                        </div>
                     </div>

                  </td>
               </tr>
            </table>
            <hr class="tablePersonal" />
            <table border="1" width="100%">

               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>Work History</b>-<i>continued</i></label>
                     </div>
                  </td>

               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3">
                        <div class="row">
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel">Gap Dates:</label>
                              <div class="input-group">
                                 <input style="width: 40%" class="form-control onboard datepicker"
                                    placeholder="Gap Start Date " ngbDatepicker #d50="ngbDatepicker" id="3399"
                                    name="3399" formControlName="3399" type="text" data-id="Gap from 3.0"
                                    (blur)="clearInputIfInvalid('3399')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3399').invalid">
                                 <small class="text-danger" *ngIf="sageneu.get('3399').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3399').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3399').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3399').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>

                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <div class="input-group">
                                 <input style="width: 53%" class="form-control onboard datepicker"
                                    placeholder="Gap End Date " ngbDatepicker #d51="ngbDatepicker" id="3400" name="3400"
                                    formControlName="3400" type="text" data-id="Gap to 3.0"
                                    (blur)="clearInputIfInvalid('3400')" />
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()"
                                       type="button">
                                       <i class="fas fa-calendar-alt"></i>
                                    </button>
                                 </div>
                              </div>
                              <div *ngIf="sageneu.get('3400').invalid ">
                                 <small class="text-danger" *ngIf="sageneu.get('3400').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3400').errors?.invalidMonth">
                                    Month is out of range
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3400').errors?.invalidDateyear">
                                    year is out of range(1900-2099)
                                 </small>
                                 <small class="text-danger" *ngIf="sageneu.get('3400').errors?.invalidDateRange">
                                    date is out of range
                                 </small>
                              </div>
                           </div>
                           <div class="mb-3 col-4 d-flex flex-row align-items-center flex-wrap no-margin">
                              <label class="formLabel"> Explanation: </label>
                              <input style="width: 70%" class="form-control" id="1832" name="1832"
                                 formControlName="1832" type="text" data-id="Gap 3.0"
                                 placeholder="Explanation for Gap in Work History3 " />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="mb-3  d-flex flex-row align-items-center flex-wrap  no-margin col-4">
                           <label class="formLabel">Gap Dates:</label>
                           <div class="input-group">
                              <input style="width: 40%" class="form-control onboard datepicker"
                                 placeholder="Gap Start Date " ngbDatepicker #d52="ngbDatepicker" id="3401" name="3401"
                                 formControlName="3401" type="text" data-id="Gap from 3.1"
                                 (blur)="clearInputIfInvalid('3401')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()"
                                    type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('3401').invalid ">
                              <small class="text-danger" *ngIf="sageneu.get('3401').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY)
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3401').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3401').errors?.invalidDateyear">
                                 year is out of range(1900-2099)
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3401').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>

                        </div>
                        <div class="mb-3 d-flex flex-row align-items-center flex-wrap  no-margin col-4">
                           <div class="input-group">
                              <input style="width: 53%" class="form-control onboard datepicker"
                                 placeholder="Gap End Date " ngbDatepicker #d53="ngbDatepicker" id="3402" name="3402"
                                 formControlName="3402" type="text" data-id="Gap to 3.1"
                                 (blur)="clearInputIfInvalid('3402')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()"
                                    type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div *ngIf="sageneu.get('3402').invalid">
                              <small class="text-danger" *ngIf="sageneu.get('3402').errors?.invalidDateFormat">
                                 Only Date Format is allowed(MM/DD/YYYY)
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3402').errors?.invalidMonth">
                                 Month is out of range
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3402').errors?.invalidDateyear">
                                 year is out of range(1900-2099)
                              </small>
                              <small class="text-danger" *ngIf="sageneu.get('3402').errors?.invalidDateRange">
                                 date is out of range
                              </small>
                           </div>
                        </div>
                        <div class="mb-3 d-flex flex-row align-items-center flex-wrap   no-margin col-4">
                           <label class="formLabel"> Explanation: </label>
                           <input style="width: 70%" class="form-control" id="1953" name="1953" formControlName="1953"
                              type="text" data-id="Gap 3.1" placeholder="Explanation for Gap in Work History4 " />
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <input type="checkbox" id="cmd_0814" name="cmd_0814" formControlName="cmd_0814" value="1" />
                     <label><b><i>Please check this box and complete and submit Attachment C if you have additional work
                              history </i></b></label>
                  </td>
               </tr>
               <tr>
                  <td colspan="14">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>Hospital Affiliations</b></label>-Please include all hospitals where
                        you currently have or have
                        previously had privileges.
                     </div>
                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input type="checkbox" name="cmd_0723" id="cmd_0723" formControlName="cmd_0723" value="1"
                        (ngModelChange)="handleNAchange('cmd_0723')" class="texasFloat" />
                  </td>
               </tr>
               <tr>
                  <td colspan="9">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">DO YOU HAVE HOSPITAL PRIVILEGES?
                        </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_059" name="cmd_059" formControlName="cmd_059"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_059" name="cmd_059" formControlName="cmd_059"
                              value="0" />No</label>
                     </div>
                  </td>
                  <td colspan="9">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">IF YOU DO NOT HAVE ADMITTING PRIVILEGES, WHAT ADMITTING
                           ARRANGEMENTS DO YOU HAVE?
                        </label>
                        <input class="form-control" id="cmd_060" name="cmd_060" formControlName="cmd_060" type="text"
                           data-id="hospaff1arrangements" />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="14">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PRIMARY HOSPITAL WHERE YOU HAVE ADMITTING PRIVILEGES</label>
                        <input class="form-control" [class.is-invalid]="sageneu.controls['418'].invalid" id="418"
                           name="418" formControlName="418" type="text" data-id="hospaff1nm"
                           placeholder="Hospital Affiliation 1 Name" (blur)="handleNAchange('cmd_0723', 418)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['418'].touched &&
                            sageneu.controls['418'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['418'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">START DATE (MM/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['426'].invalid"
                              placeholder="Hospital Affiliation 1 Initial Appointment " ngbDatepicker
                              #d54="ngbDatepicker" id="426" name="426" formControlName="426" type="text"
                              data-id="hospaff1app" (blur)="handleNAchange('cmd_0723', 426)"
                              (blur)="clearInputIfInvalid('426','cmd_0723')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d54.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['426'].invalid && (sageneu.controls['426'].value==''|| sageneu.controls['426'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('426').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('426').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('426').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('426').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('426').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" [class.is-invalid]="sageneu.controls['419'].invalid" id="419"
                                 name="419" formControlName="419" type="text" data-id="hospaff1add1"
                                 placeholder="Hospital Affiliation 1 Address1 "
                                 (blur)="handleNAchange('cmd_0723', 419)" />
                              <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['419'].touched &&
                               sageneu.controls['419'].errors
                               ">
                                 <small class="text-danger" *ngIf="sageneu.controls['419'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="420" name="420" formControlName="420" type="text"
                                 data-id="hospaff1add2" placeholder="Hospital Affiliation 1 Address 2 " />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" [class.is-invalid]="sageneu.controls['421'].invalid"
                                 class="form-control" placeholder="Hospital Affiliation 1 City " id="421" name="421"
                                 formControlName="421" type="text" data-id="hospaff1city"
                                 (blur)="handleNAchange('cmd_0723', 421)" />
                              <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['421'].touched &&
                            sageneu.controls['421'].errors
                            ">
                                 <small class="text-danger" *ngIf="sageneu.controls['421'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3 no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" [class.is-invalid]="sageneu.controls['423'].invalid"
                                          bindLabel="name" placeholder="States" bindValue="id" formControlName="423"
                                          (blur)="handleNAchange('cmd_0723', 423)">
                                       </ng-select>
                                    </div>
                                    <div class="formLabel col-md-2" *ngIf="
                                        sageneu.controls['423'].touched &&
                                        sageneu.controls['423'].errors
                                        ">
                                       <small class="text-danger" *ngIf="sageneu.controls['423'].errors">
                                          required
                                       </small>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" [class.is-invalid]="sageneu.controls['424'].invalid"
                                    class="form-control" placeholder="Hospital Affiliation 1 Zip " id="424" name="424"
                                    formControlName="424" type="text" data-id="hospaff1zip"
                                    (blur)="handleNAchange('cmd_0723', 424)" />
                                 <div class="formLabel col-md-12" *ngIf="
                                     sageneu.controls['424'].invalid
                                     ">
                                    <small class="text-danger" *ngIf="sageneu.controls['424'].errors">
                                       required
                                    </small>
                                    <small class="text-danger" *ngIf="
                                        sageneu.controls['424'].errors?.invalidZipCode
                                        ">
                                       Zip code must be atleast 5 digits and less than 10 digits
                                    </small>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER </label>
                        <input class="form-control" (keypress)="numberOnly($event)"
                           [class.is-invalid]="sageneu.controls['1305'].invalid" id="1305" name="1305"
                           formControlName="1305" type="text" data-id="hospaff1phone"
                           placeholder="Hospital Affiliation 1 Phone" (blur)="handleNAchange('cmd_0723', 1305)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['1305'].touched &&
                            sageneu.controls['1305'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['1305'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">FAX</label>
                        <input class="form-control" [class.is-invalid]="sageneu.controls['2136'].invalid" id="2136"
                           name="2136" formControlName="2136" type="text" data-id="hospaff1fax"
                           placeholder="Hospital Affiliation 1 Fax " (blur)="handleNAchange('cmd_0723', 2136)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['2136'].touched &&
                            sageneu.controls['2136'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['2136'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="8">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">E-MAIL </label>
                        <input class="form-control" [class.is-invalid]="sageneu.controls['2142'].invalid" id="2142"
                           name="2142" formControlName="2142" type="text" data-id="hospaff1email"
                           placeholder="Hospital Affiliation 1 Contact Email "
                           (blur)="handleNAchange('cmd_0723', 2142)" />
                        <!-- <small class="text-danger" *ngIf="sageneu.controls['2142'].errors">
                              required
                           </small>
                           <small class="text-danger" *ngIf="
                               sageneu.controls['2142'].errors?.invalidZipCode
                               ">
                              Please enter a valid email.
                           </small> -->
                        <div class="mandatory"
                           *ngIf="sageneu.controls['2142'].invalid && (sageneu.controls['2142'].value==''|| sageneu.controls['2142'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>

                        <div class="formLabel col-md-11"
                           *ngIf="sageneu.controls['2142'].touched && sageneu.controls['2142'].errors">

                           <small class="text-danger" *ngIf="sageneu.controls['2142'].invalid">Please enter a valid
                              email.</small>
                        </div>



                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="7">
                     <div class=" no-margin  sideLabelAlignment">
                        <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_061" name="cmd_061" formControlName="cmd_061" value="1"
                              (blur)="handleNAchange('cmd_0723')" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_061" name="cmd_061" formControlName="cmd_061" value="0"
                              (blur)="handleNAchange('cmd_0723')" />No</label>
                        <div class="text-danger" *ngIf="sageneu.controls['cmd_061'].invalid">
                           <small>
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="5">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                        </label>
                        <div class="mb-3  dropDrownSet">
                           <div class="col-md-12">
                              <ng-select [items]="staffStatus" [class.is-invalid]="sageneu.controls['894'].invalid"
                                 bindLabel="name" placeholder="Staff Status" bindValue="id" formControlName="894"
                                 (blur)="handleNAchange('cmd_0723', 894)">
                              </ng-select>
                              <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['894'].touched &&
                               sageneu.controls['894'].errors
                               ">
                                 <small class="text-danger" *ngIf="sageneu.controls['894'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_062" name="cmd_062" formControlName="cmd_062" value="1"
                              (blur)="handleNAchange('cmd_0723')" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_062" name="cmd_062" formControlName="cmd_062" value="0"
                              (blur)="handleNAchange('cmd_0723')" />No</label>
                        <div class="text-danger" *ngIf="sageneu.controls['cmd_062'].invalid">
                           <small>
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN
                           THE
                           PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                        </label>
                        <input class="form-control" id="cmd_063" name="cmd_063" formControlName="cmd_063" type="text"
                           data-id="hospaff1adimissons" />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="15">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">OTHER HOSPITAL WHERE YOU HAVE PRIVILEGES
                        </label>
                        <input class="form-control" id="427" name="427" formControlName="427" type="text"
                           data-id="hospaff2nm" placeholder="Hospital Affiliation 2 Name " />
                     </div>
                  </td>
                  <td colspan="3">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">START DATE (MM/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              placeholder="Hospital Affiliation 2 Initial Appointment " ngbDatepicker
                              #d55="ngbDatepicker" id="435" name="435" formControlName="435" type="text"
                              data-id="hospaff2app" (blur)="clearInputIfInvalid('435')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('435').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('435').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('435').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('435').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('435').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="428" name="428" formControlName="428" type="text"
                                 data-id="hospaff2add.0" placeholder="Hospital Affiliation 2 Address1 " />
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="429" name="429" formControlName="429" type="text"
                                 data-id="hospaff2add.1" placeholder="Hospital Affiliation 2 Address2 " />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" class="form-control" id="430" name="430" formControlName="430"
                                 type="text" placeholder="Hospital Affiliation 2 City " data-id="hospaff2city" />
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <div >
                                       <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                                          formControlName="432">
                                       </ng-select>
                                    </div>

                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" class="form-control" id="433" name="433"
                                    formControlName="433" type="text" placeholder="Hospital Affiliation 2 Zip "
                                    data-id="hospaff2zip" />
                                 <small class="text-danger" *ngIf="sageneu.controls['433'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER </label>
                        <input class="form-control" (keypress)="numberOnly($event)" id="1307" name="1307"
                           formControlName="1307" type="text" placeholder="Hospital Affiliation 2 Phone  "
                           data-id="hospaff2phone" />
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">FAX</label>
                        <input class="form-control" id="2137" name="2137" formControlName="2137" type="text"
                           data-id="hospaff2fax" placeholder="Hospital Affiliation 2 Fax " />
                     </div>
                  </td>
                  <td colspan="8">
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <label class="formLabel">E-MAIL</label>
                        <input class="form-control" id="2143" name="2143" formControlName="2143" type="text"
                           data-id="hospaff2email" placeholder="Hospital Affiliation 2 Contact Email " />
                        <small class="text-danger" *ngIf="sageneu.controls['2143'].errors?.pattern">Please enter a valid
                           email.</small>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_064" name="cmd_064" formControlName="cmd_064"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_064" name="cmd_064" formControlName="cmd_064"
                              value="0" />No</label>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                        </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="staffStatus" bindLabel="name" placeholder="Staff Status" bindValue="id"
                              formControlName="895">
                           </ng-select>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">ARE PRIVILEGES TEMPORARY? </label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_065" name="cmd_065" formControlName="cmd_065"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_065" name="cmd_065" formControlName="cmd_065"
                              value="0" />No</label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">OF THE TOTAL NUMBER OF ADMISSIONS TO ALL HOSPITALS IN
                           THE
                           PAST YEAR, WHAT PERCENTAGE IS TO PRIMARY HOSPITAL?
                        </label>
                        <input class="form-control" id="cmd_066" name="cmd_066" formControlName="cmd_066" type="text"
                           data-id="hospaff2admissons" />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <input type="checkbox" id="cmd_0815" name="cmd_0815" formControlName="cmd_0815" value="1" />
                     <label><i><b>Please check this box and complete and submit Attachment D if you have additional <u>current</u> 
                           hospital affiliations.</b> </i></label>
                  </td>
               </tr>
               <tr>
                  <td colspan="10">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">PREVIOUS HOSPITAL WHERE YOU HAVE HAD PRIVILEGES</label>
                        <input class="form-control" id="1183" name="1183" formControlName="1183" type="text"
                           data-id="Pre aff 1" placeholder="Previous Hospital Affiliation Name " />
                     </div>
                  </td>
                  <td colspan="8">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">AFFILIATION DATES (MM/YYYY TO MM/YYYY)
                        </label>
                        <div class="input-group">
                           <input style="width: 30%;" class="form-control onboard datepicker"
                              placeholder="Previous Hospital Affiliation Initial Appointment " ngbDatepicker
                              #d56="ngbDatepicker" id="1191" name="1191" formControlName="1191" type="text"
                              data-id="Pre aff 1 from" (blur)="clearInputIfInvalid('1191')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('1191').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1191').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1191').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1191').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1191').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              placeholder="Previous Hospital Affiliation End Date " ngbDatepicker #d57="ngbDatepicker"
                              id="2381" name="2381" formControlName="2381" type="text" data-id="Pre aff 1 to"
                              (blur)="clearInputIfInvalid('2381')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div *ngIf="sageneu.get('2381').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('2381').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('2381').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('2381').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('2381').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">ADDRESS</label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="1184" name="1184" formControlName="1184" type="text"
                                 data-id="Pre aff 1 add1" placeholder="Previous Hospital Affiliation Address1 " />
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="1185" name="1185" formControlName="1185" type="text"
                                 data-id="Pre aff 1 add2" placeholder="Previous Hospital Affiliation Address2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" class="form-control" id="1186" name="1186"
                                 formControlName="1186" type="text" placeholder="Previous Hospital Affiliation City "
                                 data-id="Pre aff 1 city" />
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">STATE/COUNTRY</label>
                                 <div class="mb-3  dropDrownSet">
                                    <ng-select [items]="states" bindLabel="name" placeholder="States " bindValue="id"
                                       formControlName="1188">
                                    </ng-select>

                                 </div>
                              </div>
                           </div>
                           <div class="col-4">
                              <div class="mb-3  no-margin  sideLabelAlignment">
                                 <label class="formLabel">POSTAL CODE</label>
                                 <input style="width: 100%" class="form-control" id="1189" name="1189"
                                    formControlName="1189" type="text" placeholder="Previous Hospital Affiliation Zip "
                                    data-id="Pre aff 1 zip" />
                                 <small class="text-danger" *ngIf="sageneu.controls['1189'].errors?.pattern"> Zip code
                                    must be
                                    atleast 5 digits and less than 10 digits.</small>
                              </div>
                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="6">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">FULL UNRESTRICTED PRIVILEGES? </label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_068" name="cmd_068" formControlName="cmd_068"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_068" name="cmd_068" formControlName="cmd_068"
                              value="0" />No</label>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">TYPES OF PRIVILEGES (PROVISIONAL, LIMITED, CONDITIONAL, ETC.)
                        </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="staffStatus" bindLabel="name" placeholder="Staff Status" bindValue="id"
                              formControlName="1192">
                           </ng-select>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class=" no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">WERE PRIVILEGES TEMPORARY? </label>
                        <br />
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_069" name="cmd_069" formControlName="cmd_069"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_069" name="cmd_069" formControlName="cmd_069"
                              value="0" />No</label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel">REASON FOR DISCONTINUANCE </label>
                        <input class="form-control" id="1303" name="1303" formControlName="1303" type="text"
                           data-id="Pre aff 1 leaving"
                           placeholder="Previous Hospital Affiliation Reason for Leaving " />
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <input type="checkbox" id="cmd_0816" name="cmd_0816" formControlName="cmd_0816" value="1" />
                     <label><b><i>Please check this box and complete and submit Attachment E if you have additional
                       <u>previous</u> hospital affiliations. </i></b></label>
                  </td>
               </tr>
            </table>
            <br>
            <br>
            <div>
               <div class="row ms-3">
                  <label>Population(s) Treated: </label>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0817" name="cmd_0817" formControlName="cmd_0817"
                        value="1" /><label>Couples/Marriage Counseling</label>
                  </div>
                  <div class="col-md-6">
                     <input type="checkbox" id="cmd_0818" name="cmd_0818" formControlName="cmd_0818"
                        value="2" /><label>Family Medicine</label>
                  </div>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0819" name="cmd_0819" formControlName="cmd_0819"
                        value="3" /><label>Family Therapy</label>
                  </div>
                  <div class="col-md-6">
                     <input type="checkbox" id="cmd_0820" name="cmd_0820" formControlName="cmd_0820"
                        value="4" /><label>Internal Medicine </label>
                  </div>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0821" name="cmd_0821" formControlName="cmd_0821"
                        value="5" /><label>Group Therapy </label>
                  </div>
                  <div class="col-md-6">
                     <input type="checkbox" id="cmd_0822" name="cmd_0822" formControlName="cmd_0822"
                        value="6" /><label>Psychiatric Medication Management
                     </label>
                  </div>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0823" name="cmd_0823" formControlName="cmd_0823"
                        value="7" /><label>Individual Therapy </label>
                  </div>
                  <div class="col-md-6">
                     <input type="checkbox" id="cmd_0824" name="cmd_0824" formControlName="cmd_0824"
                        value="others" /><label>Other</label>
                     <input id="cmd_0825" name="cmd_0825" formControlName="cmd_0825" />

                  </div>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0826" name="cmd_0826" formControlName="cmd_0826"
                        value="8" /><label>Addiction Medicine</label>
                  </div>

               </div>
            </div>

            <hr class="tablePersonal" />
            <br>
            <br>
            <div>
               <div class="row ms-3">
                  <label>Age Group(s) Treated: </label>
               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0827" name="cmd_0827" formControlName="cmd_0827"
                        value="1" /><label>Child (5 – 12) </label>
                  </div>

               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0828" name="cmd_0828" formControlName="cmd_0828"
                        value="2" /><label>Adolescent (13 – 17)</label>
                  </div>

               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0829" name="cmd_0829" formControlName="cmd_0829"
                        value="3" /><label>Adult (18 – 64)</label>
                  </div>

               </div>
               <div class="row ms-5">
                  <div class="col-md-4">
                     <input type="checkbox" id="cmd_0830" name="cmd_0830" formControlName="cmd_0830"
                        value="4" /><label>Geriatric (65+) </label>
                  </div>

               </div>

            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <table border="1" width="100%">


               <tr>
                  <td colspan="14">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 sideLabelAlignment">
                        <label class="formLabel"><b>Professional Liability Insurance Coverage</b></label>
                     </div>
                  </td>
                  <td colspan="4">
                     <label class="texasFloat">Does Not Apply</label>
                     <input type="checkbox" name="cmd_0725" id="cmd_0725" formControlName="cmd_0725"
                        (ngModelChange)="handleNAchange('cmd_0725')" value="1" class="texasFloat" />
                  </td>
               </tr>
               <tr>
                  <td colspan="6" width="21%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        SELF-INSURED?<label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_071" name="cmd_071" formControlName="cmd_071"
                              value="1" />Yes</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_071" name="cmd_071" formControlName="cmd_071"
                              value="0" />No</label>
                     </div>
                  </td>
                  <td colspan="12" width="79%">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">NAME OF CURRENT MALPRACTICE INSURANCE CARRIER OR SELF-INSURED
                           ENTITY
                        </label>
                        <input class="form-control" [class.is-invalid]="sageneu.controls['345'].invalid" id="345"
                           name="345" formControlName="345" type="text" data-id="mlp1carrier"
                           placeholder="MLP Carrier Name" (blur)="handleNAchange('cmd_0725', 345)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['345'].touched &&
                            sageneu.controls['345'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['345'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="79%">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ADDRESS </label>
                        <div class="row">
                           <div class="col-md-6">
                              <input class="form-control" [class.is-invalid]="sageneu.controls['901'].invalid" id="901"
                                 name="901" formControlName="901" type="text" data-id="MLP Mailing Add1"
                                 placeholder="MLP Address 1 " (blur)="handleNAchange('cmd_0725', 901)" />
                              <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['901'].touched &&
                            sageneu.controls['901'].errors
                            ">
                                 <small class="text-danger" *ngIf="sageneu.controls['901'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <input class="form-control" id="902" name="902" formControlName="902" type="text"
                                 data-id="MLP Mailing Add2" placeholder="MLP Address 2" />
                           </div>
                        </div>


                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="mb-3  no-margin  sideLabelAlignment">

                        <div class="row">
                           <div class="col-4">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" [class.is-invalid]="sageneu.controls['903'].invalid"
                                 class="form-control" id="903" name="903" formControlName="903" type="text"
                                 data-id="MLP City" placeholder="MLP City " (blur)="handleNAchange('cmd_0725', 903)" />
                              <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['903'].touched &&
                            sageneu.controls['903'].errors
                            ">
                                 <small class="text-danger" *ngIf="sageneu.controls['903'].errors">
                                    required
                                 </small>
                              </div>
                           </div>
                           <div class="col-4">

                              <label class="formLabel">STATE/COUNTRY</label>
                              <div class="mb-3  dropDrownSet">
                                 <ng-select [items]="states" [class.is-invalid]="sageneu.controls['905'].invalid"
                                    bindLabel="name" placeholder="States" bindValue="id" formControlName="905"
                                    (blur)="handleNAchange('cmd_0725', 905)">
                                 </ng-select>
                                 <div class="formLabel col-md-2" *ngIf="
                                        sageneu.controls['905'].touched &&
                                        sageneu.controls['905'].errors
                                        ">
                                    <small class="text-danger" *ngIf="sageneu.controls['905'].errors">
                                       required
                                    </small>
                                 </div>
                              </div>

                           </div>
                           <div class="col-4">

                              <label class="formLabel">POSTAL CODE</label>
                              <input style="width: 100%" [class.is-invalid]="sageneu.controls['906'].invalid"
                                 class="form-control" id="906" name="906" formControlName="906" type="text"
                                 data-id="MLP Zip" placeholder="MLP Zip " (blur)="handleNAchange('cmd_0725', 906)" />
                              <div class="formLabel col-md-12" *ngIf="
                                     sageneu.controls['906'].invalid
                                     ">
                                 <small class="text-danger" *ngIf="sageneu.controls['906'].errors">
                                    required
                                 </small>
                                 <small class="text-danger" *ngIf="
                                        sageneu.controls['906'].errors?.invalidZipCode
                                        ">
                                    Zip code must be atleast 5 digits and less than 10 digits
                                 </small>
                              </div>

                           </div>
                        </div>

                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER </label>
                        <input class="form-control" (keypress)="numberOnly($event)"
                           [class.is-invalid]="sageneu.controls['1266'].invalid" id="1266" name="1266"
                           formControlName="1266" type="text" data-id="MLP Phone" placeholder="MLP Phone "
                           (blur)="handleNAchange('cmd_0725', 1266)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['1266'].touched &&
                            sageneu.controls['1266'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['1266'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">POLICY NUMBER</label>
                        <input class="form-control" [class.is-invalid]="sageneu.controls['346'].invalid" id="346"
                           name="346" formControlName="346" type="text" data-id="mlp1pol"
                           placeholder="MLP Policy Number  " (blur)="handleNAchange('cmd_0725', 346)" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['346'].touched &&
                            sageneu.controls['346'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['346'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="5">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="MLP Original Issue Date  "
                              ngbDatepicker #d58="ngbDatepicker" id="348" name="348" formControlName="348" type="text"
                              data-id="mlp1dateeff_date" [class.is-invalid]="sageneu.controls['348'].invalid"
                              (blur)="handleNAchange('cmd_0725', 348)" (blur)="clearInputIfInvalid('348','cmd_0725')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar " (click)="d58.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['348'].invalid && (sageneu.controls['348'].value==''|| sageneu.controls['348'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('348').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('348').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('348').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('348').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('348').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>

                  </td>
                  <td colspan="5">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker"
                              [class.is-invalid]="sageneu.controls['349'].invalid" placeholder="MLP Expiration Date "
                              ngbDatepicker #d59="ngbDatepicker" id="349" name="349" formControlName="349" type="text"
                              data-id="mlp1dateexp_date" (blur)="handleNAchange('cmd_0725', 349)"
                              (blur)="clearInputIfInvalid('349','cmd_0725')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>
                        <div class="mandatory"
                           *ngIf="sageneu.controls['349'].invalid && (sageneu.controls['349'].value==''|| sageneu.controls['349'].value==null) ">
                           <small>
                              required
                           </small>
                        </div>
                        <div *ngIf="sageneu.get('349').invalid">
                           <small class="text-danger" *ngIf="sageneu.get('349').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('349').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('349').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('349').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF COVERAGE PER OCCURRENCE
                        </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="preClaim" [class.is-invalid]="sageneu.controls['350'].invalid"
                              bindLabel="name" placeholder="Per Claims" bindValue="id" formControlName="350"
                              (blur)="handleNAchange('cmd_0725', 350)">
                           </ng-select>
                           <div class="formLabel " *ngIf="
                               sageneu.controls['350'].touched &&
                               sageneu.controls['350'].errors
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['350'].errors">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="aggregates" [class.is-invalid]="sageneu.controls['351'].invalid"
                              bindLabel="name" placeholder="Aggregates" bindValue="id" formControlName="351"
                              (blur)="handleNAchange('cmd_0725', 351)">
                           </ng-select>
                           <div class="formLabel col-md-2" *ngIf="
                               sageneu.controls['351'].touched &&
                               sageneu.controls['351'].errors
                               ">
                              <small class="text-danger" *ngIf="sageneu.controls['351'].errors">
                                 required
                              </small>
                           </div>
                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">
                           TYPE OF COVERAGE
                           <br />
                           <input type="radio" id="cmd_072" name="cmd_072" formControlName="cmd_072"
                              value="1" />Individual</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_072" name="cmd_072" formControlName="cmd_072"
                              value="0" />Shared</label>
                     </div>
                     <div class="text-danger" *ngIf="sageneu.controls['cmd_072'].invalid">
                        <small>
                           required
                        </small>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3 no-margin  sideLabelAlignment">
                        <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
                        <input class="form-control" id="cmd_073"
                           [class.is-invalid]="sageneu.controls['cmd_073'].invalid" name="cmd_073"
                           formControlName="cmd_073" type="text" data-id="MLP length of time"
                           (blur)="handleNAchange('cmd_0725', 'cmd_073')" />
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_073'].touched &&
                            sageneu.controls['cmd_073'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_073'].errors">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>

               <tr>
                  <td colspan="18" width="79%">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">NAME OF PREVIOUS MALPRACTICE INSURANCE CARRIER IF WITH
                           CURRENT CARRIER LESS THAN 5 YEARS
                        </label>
                        <input class="form-control" id="1242" name="1242" formControlName="1242" type="text"
                           data-id="prevmlp1carrier" placeholder="Previous MLP Carriers " />

                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="79%">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">ADDRESS </label>
                        <div class="row">
                           <div class="col-6">
                              <input class="form-control" id="1244" name="1244" formControlName="1244" type="text"
                                 data-id="prevMLP Mailing Add1" placeholder="Previous MLP Address1 " />
                           </div>
                           <div class="col-6">
                              <input class="form-control" id="1245" name="1245" formControlName="1245" type="text"
                                 data-id="prevMLP Mailing Add2" placeholder="Previous MLP Address2" />
                           </div>

                        </div>



                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18">
                     <div class="row">


                        <div class="col-4">
                           <div class="mb-3  no-margin  sideLabelAlignment">
                              <label class="formLabel">CITY</label>
                              <input style="width: 100%" class="form-control" id="1246" name="1246"
                                 formControlName="1246" type="text" data-id="prevMLP City"
                                 placeholder="Previous MLP City " />

                           </div>
                        </div>
                        <div class="col-4">
                           <div class="mb-3  no-margin  sideLabelAlignment">
                              <label class="formLabel">STATE/COUNTRY</label>
                              <div class="mb-3  dropDrownSet">

                                 <ng-select [items]="states" bindLabel="name" placeholder="States" bindValue="id"
                                    formControlName="1248">
                                 </ng-select>


                              </div>
                           </div>
                        </div>
                        <div class="col-4">
                           <div class="mb-3  no-margin sideLabelAlignment">
                              <label class="formLabel">POSTAL CODE</label>
                              <input style="width: 100%" class="form-control" id="1249" name="1249"
                                 formControlName="1249" type="text" data-id="prevMLP Zip"
                                 placeholder="Previous MLP Zip " />
                              <small class="text-danger" *ngIf="sageneu.controls['1249'].errors?.pattern"> Zip code
                                 must be
                                 atleast 5 digits and less than 10 digits.</small>

                           </div>

                        </div>
                     </div>



                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">PHONE NUMBER </label>
                        <input class="form-control" (keypress)="numberOnly($event)" id="1254" name="1254"
                           formControlName="1254" type="text" data-id="prevMLP Phone"
                           placeholder="Previous MLP Phone " />
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">POLICY NUMBER</label>
                        <input class="form-control" id="1256" name="1256" formControlName="1256" type="text"
                           data-id="prevmlp1pol" placeholder="Previous MLP Policy Number " />

                     </div>
                  </td>
                  <td colspan="5">
                     <div class="mb-3  no-margin sideLabelAlignment">
                        <label class="formLabel">EFFECTIVE DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Previous MLP Effective Date "
                              ngbDatepicker #d60="ngbDatepicker" id="1252" name="1252" formControlName="1252"
                              type="text" data-id="prevmlp1dateeff_date" (blur)="clearInputIfInvalid('1252')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>

                        <div *ngIf="sageneu.get('1252').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1252').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1252').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1252').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1252').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>

                  </td>
                  <td colspan="5">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">EXPIRATION DATE (MM/DD/YYYY) </label>
                        <div class="input-group">
                           <input class="form-control onboard datepicker" placeholder="Previous MLP Expiration Date "
                              ngbDatepicker #d61="ngbDatepicker" id="1253" name="1253" formControlName="1253"
                              type="text" data-id="prevmlp1dateexp_date" (blur)="clearInputIfInvalid('1253')" />
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d61.toggle()" type="button">
                                 <i class="fas fa-calendar-alt"></i>
                              </button>
                           </div>
                        </div>

                        <div *ngIf="sageneu.get('1253').invalid ">
                           <small class="text-danger" *ngIf="sageneu.get('1253').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1253').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1253').errors?.invalidDateyear">
                              year is out of range(1900-2099)
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('1253').errors?.invalidDateRange">
                              date is out of range
                           </small>
                        </div>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF COVERAGE PER OCCURRENCE
                        </label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="preClaim" bindLabel="name" placeholder="Per Claims" bindValue="id"
                              formControlName="1264">
                           </ng-select>

                        </div>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">AMOUNT OF COVERAGE AGGREGATE</label>
                        <div class="mb-3  dropDrownSet">
                           <ng-select [items]="aggregates" bindLabel="name" placeholder="Aggregates" bindValue="id"
                              formControlName="1265">
                           </ng-select>

                        </div>
                     </div>
                  </td>
                  <td colspan="6">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="checkbox-inline radioStyle">
                           TYPE OF COVERAGE
                           <br />
                           <input type="radio" id="cmd_074" name="cmd_074" formControlName="cmd_074"
                              value="1" />Individual</label>
                        <label class="checkbox-inline radioStyle">
                           <input type="radio" id="cmd_074" name="cmd_074" formControlName="cmd_074"
                              value="0" />Shared</label>
                     </div>
                  </td>
                  <td colspan="4">
                     <div class="mb-3  no-margin  sideLabelAlignment">
                        <label class="formLabel">LENGTH OF TIME WITH CARRIER</label>
                        <input class="form-control" id="cmd_075" name="cmd_075" formControlName="cmd_075" type="text"
                           data-id="prevMLP length of time" />
                     </div>
                  </td>
               </tr>
            </table>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>

            <hr class="tablePersonal" />
            <table border="1" width="100%">
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <b>Section II-Disclosure Questions</b>
                        <label>- Please provide an explanation for any question answered
                           yes-except 16-on page 10.
                        </label>
                     </div>
                     <label class="formLabel"> <b> Licensure</b></label>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">1</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Has your license to practice, in your profession, ever been
                           denied, suspended, revoked, restricted,voluntarily surrendered
                           while under investigation, or have you ever been subject to a
                           consent order,probation or any conditions or limitations by
                           any state licensing board?</label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" [class.is-invalid]="
                         (sageneu.controls['cmd_0232'].touched &&
                         sageneu.controls['cmd_0232'].errors) ||
                         (sageneu.controls['cmd_0232'].invalid && isSubmit)
                         " id="cmd_0232" name="cmd_0232" formControlName="cmd_0232" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" [class.is-invalid]="
                         (sageneu.controls['cmd_0232'].touched &&
                         sageneu.controls['cmd_0232'].errors) ||
                         (sageneu.controls['cmd_0232'].invalid && isSubmit)
                         " id="cmd_0232" name="cmd_0232" formControlName="cmd_0232" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0232'].touched &&
                            sageneu.controls['cmd_0232'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0232'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0232'].touched &&
                            sageneu.controls['cmd_0232'].errors) ||
                            (sageneu.controls['cmd_0232'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">2</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever received a reprimand or been fined by any state
                           licensing board?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0233" [class.is-invalid]="
                         (sageneu.controls['cmd_0233'].touched &&
                         sageneu.controls['cmd_0233'].errors) ||
                         (sageneu.controls['cmd_0233'].errors && isSubmit)
                         " name="cmd_0233" formControlName="cmd_0233" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0233" [class.is-invalid]="
                         (sageneu.controls['cmd_0233'].touched &&
                         sageneu.controls['cmd_0233'].errors) ||
                         (sageneu.controls['cmd_0233'].errors && isSubmit)
                         " name="cmd_0233" formControlName="cmd_0233" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0233'].touched &&
                            sageneu.controls['cmd_0233'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0233'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0233'].touched &&
                            sageneu.controls['cmd_0233'].errors) ||
                            (sageneu.controls['cmd_0233'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Hospital Privileges and Other Affiliations</b>
                        </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">3</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have your clinical privileges or Medical Staff membership at
                           any hospital or healthcare institution ever been denied,
                           suspended, revoked, restricted, denied renewal or subject to
                           probationary or to other disciplinary conditions (for reasons
                           other than non-completion of medical records when quality of
                           care was not adversely affected) or have proceedings toward
                           any of those ends been instituted or recommended by any
                           hospital or healthcare institution, medical staff or
                           committee, or governing board?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0234" [class.is-invalid]="
                         (sageneu.controls['cmd_0234'].touched &&
                         sageneu.controls['cmd_0234'].errors) ||
                         (sageneu.controls['cmd_0234'].errors && isSubmit)
                         " name="cmd_0234" formControlName="cmd_0234" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0234" [class.is-invalid]="
                         (sageneu.controls['cmd_0234'].touched &&
                         sageneu.controls['cmd_0234'].errors) ||
                         (sageneu.controls['cmd_0234'].errors && isSubmit)
                         " name="cmd_0234" formControlName="cmd_0234" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0234'].touched &&
                            sageneu.controls['cmd_0234'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0234'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0234'].touched &&
                            sageneu.controls['cmd_0234'].errors) ||
                            (sageneu.controls['cmd_0234'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">4</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you voluntarily surrendered, limited your privileges or
                           not reapplied for privileges while under investigation?</label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0235" [class.is-invalid]="
                         (sageneu.controls['cmd_0235'].touched &&
                         sageneu.controls['cmd_0235'].errors) ||
                         (sageneu.controls['cmd_0235'].errors && isSubmit)
                         " name="cmd_0235" formControlName="cmd_0235" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0235" [class.is-invalid]="
                            (sageneu.controls['cmd_0235'].touched &&
                            sageneu.controls['cmd_0235'].errors) ||
                            (sageneu.controls['cmd_0235'].errors && isSubmit)
                            " name="cmd_0235" formControlName="cmd_0235" value="0" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0235'].touched &&
                            sageneu.controls['cmd_0235'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0235'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0235'].touched &&
                            sageneu.controls['cmd_0235'].errors) ||
                            (sageneu.controls['cmd_0235'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">5</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever been terminated for cause or not renewed for
                           cause from participation, or been subject to any disciplinary
                           action, by any managed care organizations (including HMOs,
                           PPOs, or provider organizations such as IPAs, PHOs)?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0236" [class.is-invalid]="
                         (sageneu.controls['cmd_0236'].touched &&
                         sageneu.controls['cmd_0236'].errors) ||
                         (sageneu.controls['cmd_0236'].errors && isSubmit)
                         " name="cmd_0236" formControlName="cmd_0236" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0236" [class.is-invalid]="
                         (sageneu.controls['cmd_0236'].touched &&
                         sageneu.controls['cmd_0236'].errors) ||
                         (sageneu.controls['cmd_0236'].errors && isSubmit)
                         " name="cmd_0236" formControlName="cmd_0236" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0236'].touched &&
                            sageneu.controls['cmd_0236'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0236'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0236'].touched &&
                            sageneu.controls['cmd_0236'].errors) ||
                            (sageneu.controls['cmd_0236'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Education, Training and Board Certification</b>
                        </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">6</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Were you ever placed on probation, disciplined, formally
                           reprimanded, suspended or asked to resign during an
                           internship, residency, fellowship, preceptorship or other
                           clinical education program? If you are currently in a
                           training program, have you been placed on probation,
                           disciplined, formally reprimanded, suspended or asked to
                           resign?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0237" [class.is-invalid]="
                            (sageneu.controls['cmd_0237'].touched &&
                            sageneu.controls['cmd_0237'].errors) ||
                            (sageneu.controls['cmd_0237'].errors && isSubmit)
                            " name="cmd_0237" formControlName="cmd_0237" value="1" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0237" [class.is-invalid]="
                         (sageneu.controls['cmd_0237'].touched &&
                         sageneu.controls['cmd_0237'].errors) ||
                         (sageneu.controls['cmd_0237'].errors && isSubmit)
                         " name="cmd_0237" formControlName="cmd_0237" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0237'].touched &&
                            sageneu.controls['cmd_0237'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0237'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0237'].touched &&
                            sageneu.controls['cmd_0237'].errors) ||
                            (sageneu.controls['cmd_0237'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">7</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">
                           Have you ever, while under investigation, voluntarily
                           withdrawn or prematurely terminated your status as a student
                           or employee in any internship, residency, fellowship,
                           preceptorship, or other clinical education program?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0238" [class.is-invalid]="
                         (sageneu.controls['cmd_0238'].touched &&
                         sageneu.controls['cmd_0238'].errors) ||
                         (sageneu.controls['cmd_0238'].errors && isSubmit)
                         " name="cmd_0238" formControlName="cmd_0238" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0238" [class.is-invalid]="
                         (sageneu.controls['cmd_0238'].touched &&
                         sageneu.controls['cmd_0238'].errors) ||
                         (sageneu.controls['cmd_0238'].errors && isSubmit)
                         " name="cmd_0238" formControlName="cmd_0238" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0238'].touched &&
                            sageneu.controls['cmd_0238'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0238'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0238'].touched &&
                            sageneu.controls['cmd_0238'].errors) ||
                            (sageneu.controls['cmd_0238'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">8</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have any of your board certifications or eligibility ever
                           been revoked?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0239" [class.is-invalid]="
                         (sageneu.controls['cmd_0239'].touched &&
                         sageneu.controls['cmd_0239'].errors) ||
                         (sageneu.controls['cmd_0239'].errors && isSubmit)
                         " name="cmd_0239" formControlName="cmd_0239" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0239" [class.is-invalid]="
                         (sageneu.controls['cmd_0239'].touched &&
                         sageneu.controls['cmd_0239'].errors) ||
                         (sageneu.controls['cmd_0239'].errors && isSubmit)
                         " name="cmd_0239" formControlName="cmd_0239" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0239'].touched &&
                            sageneu.controls['cmd_0239'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0239'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0239'].touched &&
                            sageneu.controls['cmd_0239'].errors) ||
                            (sageneu.controls['cmd_0239'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">9</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever chosen not to re-certify or voluntarily
                           surrendered your board certification(s) while under
                           investigation?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0240" [class.is-invalid]="
                         (sageneu.controls['cmd_0240'].touched &&
                         sageneu.controls['cmd_0240'].errors) ||
                         (sageneu.controls['cmd_0240'].errors && isSubmit)
                         " name="cmd_0240" formControlName="cmd_0240" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0240" [class.is-invalid]="
                         (sageneu.controls['cmd_0240'].touched &&
                         sageneu.controls['cmd_0240'].errors) ||
                         (sageneu.controls['cmd_0240'].errors && isSubmit)
                         " name="cmd_0240" formControlName="cmd_0240" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0240'].touched &&
                            sageneu.controls['cmd_0240'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0240'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0240'].touched &&
                            sageneu.controls['cmd_0240'].errors) ||
                            (sageneu.controls['cmd_0240'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>DEA or CDS </b></label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">10</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have your Federal DEA and/or Controlled Substances
                           Certificate(s) or authorization(s) ever been denied,
                           suspended, revoked, restricted, denied renewal, or voluntarily
                           relinquished?</label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0241" [class.is-invalid]="
                         (sageneu.controls['cmd_0241'].touched &&
                         sageneu.controls['cmd_0241'].errors) ||
                         (sageneu.controls['cmd_0241'].errors && isSubmit)
                         " name="cmd_0241" formControlName="cmd_0241" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0241" [class.is-invalid]="
                         (sageneu.controls['cmd_0241'].touched &&
                         sageneu.controls['cmd_0241'].errors) ||
                         (sageneu.controls['cmd_0241'].errors && isSubmit)
                         " name="cmd_0241" formControlName="cmd_0241" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0241'].touched &&
                            sageneu.controls['cmd_0241'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0241'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0241'].touched &&
                            sageneu.controls['cmd_0241'].errors) ||
                            (sageneu.controls['cmd_0241'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Medicare, Medicaid or other Governmental Program
                              Participation</b>
                        </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">11</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever been disciplined, excluded from, debarred,
                           suspended, reprimanded, sanctioned, censured, disqualified or
                           otherwise restricted in regard to participation in the
                           Medicare or Medicaid program, or in regard to other federal or
                           state governmental health care plans or programs?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0242" [class.is-invalid]="
                         (sageneu.controls['cmd_0242'].touched &&
                         sageneu.controls['cmd_0242'].errors) ||
                         (sageneu.controls['cmd_0242'].errors && isSubmit)
                         " name="cmd_0242" formControlName="cmd_0242" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0242" [class.is-invalid]="
                         (sageneu.controls['cmd_0242'].touched &&
                         sageneu.controls['cmd_0242'].errors) ||
                         (sageneu.controls['cmd_0242'].errors && isSubmit)
                         " name="cmd_0242" formControlName="cmd_0242" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0242'].touched &&
                            sageneu.controls['cmd_0242'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0242'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0242'].touched &&
                            sageneu.controls['cmd_0242'].errors) ||
                            (sageneu.controls['cmd_0242'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Other Sanctions or Investigations</b>
                        </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">12</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Are you currently or have you ever been the subject of an
                           investigation by any hospital, licensing authority, DEA or CDS
                           authorizing entities, education or training program, Medicare
                           or Medicaid program, or any other private, federal or state
                           health program?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0243" [class.is-invalid]="
                         (sageneu.controls['cmd_0243'].touched &&
                         sageneu.controls['cmd_0243'].errors) ||
                         (sageneu.controls['cmd_0243'].errors && isSubmit)
                         " name="cmd_0243" formControlName="cmd_0243" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0243" [class.is-invalid]="
                         (sageneu.controls['cmd_0243'].touched &&
                         sageneu.controls['cmd_0243'].errors) ||
                         (sageneu.controls['cmd_0243'].errors && isSubmit)
                         " name="cmd_0243" formControlName="cmd_0243" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0243'].touched &&
                            sageneu.controls['cmd_0243'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0243'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0243'].touched &&
                            sageneu.controls['cmd_0243'].errors) ||
                            (sageneu.controls['cmd_0243'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
            </table>
            <br />
            <br />
            <br />
            <br />
            <hr class="tablePersonal" />
            <table border="1" width="100%">
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <b>Section II-Disclosure Questions</b>
                        <label>- <i>continued</i>
                        </label>
                     </div>

                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Other Sanctions or Investigations</b>
                        </label>
                     </div>
                  </td>
               </tr>

               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">13</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">To your knowledge, has information pertaining to you ever been reported
                           to the National Practitioner
                           Data Bank or Healthcare Integrity and Protection Data Bank?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0244" [class.is-invalid]="
                         (sageneu.controls['cmd_0244'].touched &&
                         sageneu.controls['cmd_0244'].errors) ||
                         (sageneu.controls['cmd_0244'].errors && isSubmit)
                         " name="cmd_0244" formControlName="cmd_0244" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0244" [class.is-invalid]="
                         (sageneu.controls['cmd_0244'].touched &&
                         sageneu.controls['cmd_0244'].errors) ||
                         (sageneu.controls['cmd_0244'].errors && isSubmit)
                         " name="cmd_0244" formControlName="cmd_0244" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0244'].touched &&
                            sageneu.controls['cmd_0244'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0244'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0244'].touched &&
                            sageneu.controls['cmd_0244'].errors) ||
                            (sageneu.controls['cmd_0244'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">14</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever received sanctions from or been the subject of
                           investigation by any regulator y
                           agencies (e.g., CLIA, OSHA, etc.)?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0245" [class.is-invalid]="
                         (sageneu.controls['cmd_0245'].touched &&
                         sageneu.controls['cmd_0245'].errors) ||
                         (sageneu.controls['cmd_0245'].errors && isSubmit)
                         " name="cmd_0245" formControlName="cmd_0245" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0245" [class.is-invalid]="
                         (sageneu.controls['cmd_0245'].touched &&
                         sageneu.controls['cmd_0245'].errors) ||
                         (sageneu.controls['cmd_0245'].errors && isSubmit)
                         " name="cmd_0245" formControlName="cmd_0245" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0245'].touched &&
                            sageneu.controls['cmd_0245'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0245'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0245'].touched &&
                            sageneu.controls['cmd_0245'].errors) ||
                            (sageneu.controls['cmd_0245'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">15</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever been investigated, sanctioned, reprimanded or cautioned
                           by a military hospita l,
                           facility, or agency, or voluntarily terminated or resigned while under investigation by a
                           hospital o r
                           healthcare facility of any military agency?

                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0246" [class.is-invalid]="
                         (sageneu.controls['cmd_0246'].touched &&
                         sageneu.controls['cmd_0246'].errors) ||
                         (sageneu.controls['cmd_0246'].errors && isSubmit)
                         " name="cmd_0246" formControlName="cmd_0246" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0246" [class.is-invalid]="
                         (sageneu.controls['cmd_0246'].touched &&
                         sageneu.controls['cmd_0246'].errors) ||
                         (sageneu.controls['cmd_0246'].errors && isSubmit)
                         " name="cmd_0246" formControlName="cmd_0246" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0246'].touched &&
                            sageneu.controls['cmd_0246'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0246'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0246'].touched &&
                            sageneu.controls['cmd_0246'].errors) ||
                            (sageneu.controls['cmd_0246'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Malpractice Claims History</b> </label>
                     </div>


                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">16</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you had any malpractice actions within the past 5 years (pending,
                           settled, arbitrated ,
                           mediated or litigated?
                        </label>
                     </div>

                     <input type="checkbox" id="cmd_08171" name="cmd_08171" formControlName="cmd_08171" value="1" />
                     <label><i>If yes, please check this box and complete and submit Attachment G. </i></label>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0247" [class.is-invalid]="
                         (sageneu.controls['cmd_0247'].touched &&
                         sageneu.controls['cmd_0247'].errors) ||
                         (sageneu.controls['cmd_0247'].errors && isSubmit)
                         " name="cmd_0247" formControlName="cmd_0247" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0247" [class.is-invalid]="
                         (sageneu.controls['cmd_0247'].touched &&
                         sageneu.controls['cmd_0247'].errors) ||
                         (sageneu.controls['cmd_0247'].errors && isSubmit)
                         " name="cmd_0247" formControlName="cmd_0247" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0247'].touched &&
                            sageneu.controls['cmd_0247'].errors
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0247'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0247'].touched &&
                            sageneu.controls['cmd_0247'].errors) ||
                            (sageneu.controls['cmd_0247'].errors && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Criminal</b> </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">17</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever been convicted of, pled guilty to, or pled nolo
                           contendere to any felony that is
                           reasonably related to your qualifications, competence, functions, or duties as a medical
                           professional
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0248" [class.is-invalid]="
                         (sageneu.controls['cmd_0248'].touched &&
                         sageneu.controls['cmd_0248'].invalid) ||
                         (sageneu.controls['cmd_0248'].invalid && isSubmit)
                         " name="cmd_0248" formControlName="cmd_0248" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0248" [class.is-invalid]="
                         (sageneu.controls['cmd_0248'].touched &&
                         sageneu.controls['cmd_0248'].invalid) ||
                         (sageneu.controls['cmd_0248'].invalid && isSubmit)
                         " name="cmd_0248" formControlName="cmd_0248" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0248'].touched &&
                            sageneu.controls['cmd_0248'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0248'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0248'].touched &&
                            sageneu.controls['cmd_0248'].invalid) ||
                            (sageneu.controls['cmd_0248'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">18</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you ever been convicted of, pled guilty to, or pled nolo
                           contendere to any felony including a n
                           act of violence, child abuse or a sexual offense?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0249" [class.is-invalid]="
                         (sageneu.controls['cmd_0249'].touched &&
                         sageneu.controls['cmd_0249'].invalid) ||
                         (sageneu.controls['cmd_0249'].invalid && isSubmit)
                         " name="cmd_0249" formControlName="cmd_0249" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0249" [class.is-invalid]="
                         (sageneu.controls['cmd_0249'].touched &&
                         sageneu.controls['cmd_0249'].invalid) ||
                         (sageneu.controls['cmd_0249'].invalid && isSubmit)
                         " name="cmd_0249" formControlName="cmd_0249" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0249'].touched &&
                            sageneu.controls['cmd_0249'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0249'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0249'].touched &&
                            sageneu.controls['cmd_0249'].invalid) ||
                            (sageneu.controls['cmd_0249'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">19</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Have you been court-martialed for actions related to your duties as a
                           medical professional?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0250" [class.is-invalid]="
                         (sageneu.controls['cmd_0250'].touched &&
                         sageneu.controls['cmd_0250'].invalid) ||
                         (sageneu.controls['cmd_0250'].invalid && isSubmit)
                         " name="cmd_0250" formControlName="cmd_0250" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0250" [class.is-invalid]="
                         (sageneu.controls['cmd_0250'].touched &&
                         sageneu.controls['cmd_0250'].invalid) ||
                         (sageneu.controls['cmd_0250'].invalid && isSubmit)
                         " name="cmd_0250" formControlName="cmd_0250" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0250'].touched &&
                            sageneu.controls['cmd_0250'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0250'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0250'].touched &&
                            sageneu.controls['cmd_0250'].invalid) ||
                            (sageneu.controls['cmd_0250'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Ability to Perform Job </b></label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">20</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Are you currently engaged in the illegal use of drugs?
                           ("Currently" means sufficiently recent to justify a reasonable
                           belief that the use of drug may have an ongoing impact on
                           one's ability to pr actice medicine. It is not limited to the
                           day of, or within a matter of days or weeks before the d ate
                           of application, rather that it has occurred recently enough to
                           indicate the individual is actively engaged in such conduct.
                           "Illegal use of drugs" refers to drugs whose possession or
                           distribution is unlawful under the Controlled Substances Act,
                           21 U.S.C. § 812.22. It "does not include the use of a drug
                           taken under supervision by a licensed health care
                           professional, or other uses authorized by the Con trolled
                           Substances Act or other provision of Federal law." The term
                           does include, however, the unlawful use of prescription
                           controlled substances.)
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0251" [class.is-invalid]="
                         (sageneu.controls['cmd_0251'].touched &&
                         sageneu.controls['cmd_0251'].invalid) ||
                         (sageneu.controls['cmd_0251'].invalid && isSubmit)
                         " name="cmd_0251" formControlName="cmd_0251" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0251" [class.is-invalid]="
                         (sageneu.controls['cmd_0251'].touched &&
                         sageneu.controls['cmd_0251'].invalid) ||
                         (sageneu.controls['cmd_0251'].invalid && isSubmit)
                         " name="cmd_0251" formControlName="cmd_0251" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0251'].touched &&
                            sageneu.controls['cmd_0251'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0251'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0251'].touched &&
                            sageneu.controls['cmd_0251'].invalid) ||
                            (sageneu.controls['cmd_0251'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">21</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Do you use any chemical substances that would in any way
                           impair or limit your ability to practice medicine and perform
                           the functions of your job with reasonable skill and safety?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0252" [class.is-invalid]="
                         (sageneu.controls['cmd_0252'].touched &&
                         sageneu.controls['cmd_0252'].invalid) ||
                         (sageneu.controls['cmd_0252'].invalid && isSubmit)
                         " name="cmd_0252" formControlName="cmd_0252" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0252" [class.is-invalid]="
                         (sageneu.controls['cmd_0252'].touched &&
                         sageneu.controls['cmd_0252'].invalid) ||
                         (sageneu.controls['cmd_0252'].invalid && isSubmit)
                         " name="cmd_0252" formControlName="cmd_0252" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0252'].touched &&
                            sageneu.controls['cmd_0252'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0252'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0252'].touched &&
                            sageneu.controls['cmd_0252'].invalid) ||
                            (sageneu.controls['cmd_0252'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="18" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel"><b>Ability to Perform Job </b> </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">22</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Do you have any reason to believe that you would pose a risk
                           to the safety or well-being of your patients?</label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0253" [class.is-invalid]="
                         (sageneu.controls['cmd_0253'].touched &&
                         sageneu.controls['cmd_0253'].invalid) ||
                         (sageneu.controls['cmd_0253'].invalid && isSubmit)
                         " name="cmd_0253" formControlName="cmd_0253" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0253" [class.is-invalid]="
                         (sageneu.controls['cmd_0253'].touched &&
                         sageneu.controls['cmd_0253'].invalid) ||
                         (sageneu.controls['cmd_0253'].invalid && isSubmit)
                         " name="cmd_0253" formControlName="cmd_0253" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0253'].touched &&
                            sageneu.controls['cmd_0253'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0253'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0253'].touched &&
                            sageneu.controls['cmd_0253'].invalid) ||
                            (sageneu.controls['cmd_0253'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="2" width="5%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">23</label>
                     </div>
                  </td>
                  <td colspan="12" width="75%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">Are you unable to perform the essential functions of a
                           practitioner in your area of practice, with or without
                           reasonable accommodation?
                        </label>
                     </div>
                  </td>
                  <td colspan="4" width="20%">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0254" [class.is-invalid]="
                         (sageneu.controls['cmd_0254'].touched &&
                         sageneu.controls['cmd_0254'].invalid) ||
                         (sageneu.controls['cmd_0254'].invalid && isSubmit)
                         " name="cmd_0254" formControlName="cmd_0254" value="1"
                              (change)="validationsDataAdd($event)" />Yes</label>
                        <label class="radio-inline radioStyle">
                           <input type="radio" id="cmd_0254" [class.is-invalid]="
                         (sageneu.controls['cmd_0254'].touched &&
                         sageneu.controls['cmd_0254'].invalid) ||
                         (sageneu.controls['cmd_0254'].invalid && isSubmit)
                         " name="cmd_0254" formControlName="cmd_0254" value="0"
                              (change)="validationsDataAdd($event)" />No</label>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0254'].touched &&
                            sageneu.controls['cmd_0254'].invalid
                            ">
                           <small class="text-danger" *ngIf="sageneu.controls['cmd_0254'].errors?.required">
                              required
                           </small>
                        </div>
                        <span *ngIf="
                            (sageneu.controls['cmd_0254'].touched &&
                            sageneu.controls['cmd_0254'].invalid) ||
                            (sageneu.controls['cmd_0254'].invalid && isSubmit)
                            ">
                           <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                     </div>
                  </td>
               </tr>
            </table>
            <label>Please use the space below to explain yes answers to any question
               except #16.
            </label>
            <hr class="tablePersonal" />
            <tr>
               <td colspan="12" width="85%">
                  <h3 class="hThreeTableStyle">
                     <b>Section II - Disclosure Questions-continued </b>
                  </h3>
               </td>
            </tr>
            <table border="1" width="100%">
               <tr>
                  <td colspan="18">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="boldParagraph">Please use the space below to explain yes answers to any
                           question except 16.
                        </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="boldParagraph">QUESTION NUMBER</label>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="boldParagraph">PLEASE EXPLAIN </label>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 0 && sageneu.controls['cmd_0255'].invalid" class="form-control"
                           id="cmd_0255" formControlName="cmd_0255" name="cmd_0255"></textarea>
                        <div class="formLabel col-sm-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 0 && sageneu.controls['cmd_0255'].invalid">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0256" formControlName="cmd_0256"
                           name="cmd_0256" data-id="Text5.2.0.0"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 1 && sageneu.controls['cmd_0257'].invalid
                            " class="form-control" id="cmd_0257" formControlName="cmd_0257" name="cmd_0257"
                           data-id="Text5.2.1.1"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 1 && sageneu.controls['cmd_0257'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0258" formControlName="cmd_0258"
                           name="cmd_0258" data-id="Text5.2.0.1"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 2 && sageneu.controls['cmd_0259'].invalid
                            " class="form-control" id="cmd_0259" formControlName="cmd_0259" name="cmd_0259"
                           data-id="Text5.2.1.2"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 2 && sageneu.controls['cmd_0259'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0260" formControlName="cmd_0260"
                           name="cmd_0260" data-id="Text5.2.0.2"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 3 && sageneu.controls['cmd_0261'].invalid
                            " class="form-control" id="cmd_0261" formControlName="cmd_0261" name="cmd_0261"
                           data-id="Text5.2.1.3"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 3 && sageneu.controls['cmd_0261'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0262" formControlName="cmd_0262"
                           name="cmd_0262" data-id="Text5.2.0.3"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 4 && sageneu.controls['cmd_0263'].invalid
                            " class="form-control" id="cmd_0263" formControlName="cmd_0263" name="cmd_0263"
                           data-id="Text5.2.1.4"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 4 && sageneu.controls['cmd_0263'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0264" formControlName="cmd_0264"
                           name="cmd_0264" data-id="Text5.2.0.4"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 5 && sageneu.controls['cmd_0265'].invalid
                            " class="form-control" id="cmd_0265" formControlName="cmd_0265" name="cmd_0265"
                           data-id="Text5.2.1.5"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 5 && sageneu.controls['cmd_0265'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0266" formControlName="cmd_0266"
                           name="cmd_0266" data-id="Text5.2.0.5"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 6 && sageneu.controls['cmd_0267'].invalid
                            " class="form-control" id="cmd_0267" formControlName="cmd_0267" name="cmd_0267"
                           data-id="Text5.2.1.6"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 6 && sageneu.controls['cmd_0267'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0268" formControlName="cmd_0268"
                           name="cmd_0268" data-id="Text5.2.0.6"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 7 && sageneu.controls['cmd_0269'].invalid
                            " class="form-control" id="cmd_0269" formControlName="cmd_0269" name="cmd_0269"
                           data-id="Text5.2.1.7"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 7 && sageneu.controls['cmd_0269'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0270" formControlName="cmd_0270"
                           name="cmd_0270" data-id="Text5.2.0.7"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 8 && sageneu.controls['cmd_0271'].invalid
                            " class="form-control" id="cmd_0271" formControlName="cmd_0271" name="cmd_0271"
                           data-id="Text5.2.1.8.0"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 8 && sageneu.controls['cmd_0271'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0272" formControlName="cmd_0272"
                           name="cmd_0272" data-id="Text5.2.0.8.0"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 9 && sageneu.controls['cmd_0273'].invalid
                            " class="form-control" id="cmd_0273" formControlName="cmd_0273" name="cmd_0273"
                           data-id="Text5.2.1.9.0"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 9 && sageneu.controls['cmd_0273'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0274" formControlName="cmd_0274"
                           name="cmd_0274" data-id="Text5.2.0.9.0"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 10 && sageneu.controls['cmd_0275'].invalid
                            " class="form-control" id="cmd_0275" formControlName="cmd_0275" name="cmd_0275"
                           data-id="Text5.2.1.8.1"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 10 &&
                               sageneu.controls['cmd_0275'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0276" formControlName="cmd_0276"
                           name="cmd_0276" data-id="Text5.2.0.8.1"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 11 && sageneu.controls['cmd_0277'].invalid
                            " class="form-control" id="cmd_0277" formControlName="cmd_0277" name="cmd_0277"
                           data-id="Text5.2.1.9.1"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 11 &&
                               sageneu.controls['cmd_0277'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0278" formControlName="cmd_0278"
                           name="cmd_0278" data-id="Text5.2.0.9.1"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 12 && sageneu.controls['cmd_0279'].invalid
                            " class="form-control" id="cmd_0279" formControlName="cmd_0279" name="cmd_0279"
                           data-id="Text5.2.1.8.2"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 12 &&
                               sageneu.controls['cmd_0279'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0280" formControlName="cmd_0280"
                           name="cmd_0280" data-id="Text5.2.0.8.2"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 13 && sageneu.controls['cmd_0281'].invalid
                            " class="form-control" id="cmd_0281" formControlName="cmd_0281" name="cmd_0281"
                           data-id="Text5.2.1.9.2"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 13 &&
                               sageneu.controls['cmd_0281'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0282" formControlName="cmd_0282"
                           name="cmd_0282" data-id="Text5.2.0.9.2"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 14 && sageneu.controls['cmd_0283'].invalid
                            " class="form-control" id="cmd_0283" formControlName="cmd_0283" name="cmd_0283"
                           data-id="Text5.2.1.8.3"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 14 &&
                               sageneu.controls['cmd_0283'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0284" formControlName="cmd_0284"
                           name="cmd_0284" data-id="Text5.2.0.8.3"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 15 && sageneu.controls['cmd_0285'].invalid
                            " class="form-control" id="cmd_0285" formControlName="cmd_0285" name="cmd_0285"
                           data-id="Explain 10"></textarea>
                        <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0285'].touched &&
                            sageneu.controls['cmd_0285'].invalid
                            ">
                           <small class="text-danger" *ngIf="
                               questionCounter > 15 &&
                               sageneu.controls['cmd_0285'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0286" formControlName="cmd_0286"
                           name="cmd_0286" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 16 && sageneu.controls['cmd_0287'].invalid
                            " class="form-control" id="cmd_0287" formControlName="cmd_0287" name="cmd_0287"
                           data-id="Text5.2.1.9.3"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 16 &&
                               sageneu.controls['cmd_0287'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0288" formControlName="cmd_0288"
                           name="cmd_0288" data-id="Text5.2.0.9.3"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 17 && sageneu.controls['cmd_0289'].invalid
                            " class="form-control" id="cmd_0289" formControlName="cmd_0289" name="cmd_0289"
                           data-id="Text5.2.1.8.4"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 17 &&
                               sageneu.controls['cmd_0289'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0290" formControlName="cmd_0290"
                           name="cmd_0290" data-id="Text5.2.0.8.4"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 18 && sageneu.controls['cmd_0291'].invalid
                            " class="form-control" id="cmd_0291" formControlName="cmd_0291" name="cmd_0291"
                           data-id="Text5.2.1.9.4"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 18 &&
                               sageneu.controls['cmd_0291'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0292" formControlName="cmd_0292"
                           name="cmd_0292" data-id="Text5.2.0.9.4"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 19 && sageneu.controls['cmd_0293'].invalid
                            " class="form-control" id="cmd_0293" formControlName="cmd_0293" name="cmd_0293"
                           data-id="Text5.2.0.9.4"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 19 &&
                               sageneu.controls['cmd_0293'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0294" formControlName="cmd_0294"
                           name="cmd_0294" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 20 && sageneu.controls['cmd_0295'].invalid
                            " class="form-control" id="cmd_0295" formControlName="cmd_0295" name="cmd_0295"
                           data-id="Explain 10"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 20 &&
                               sageneu.controls['cmd_0295'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0296" formControlName="cmd_0296"
                           name="cmd_0296" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 21 && sageneu.controls['cmd_0297'].invalid
                            " class="form-control" id="cmd_0297" formControlName="cmd_0297" name="cmd_0297"
                           data-id="Explain 10"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 21 &&
                               sageneu.controls['cmd_0297'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0298" formControlName="cmd_0298"
                           name="cmd_0298" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" [class.is-invalid]="
                            questionCounter > 22 && sageneu.controls['cmd_0299'].invalid
                            " class="form-control" id="cmd_0299" formControlName="cmd_0299" name="cmd_0299"
                           data-id="Explain 10"></textarea>
                        <div class="formLabel col-md-2">
                           <small class="text-danger" *ngIf="
                               questionCounter > 22 &&
                               sageneu.controls['cmd_0299'].invalid
                               ">
                              required
                           </small>
                        </div>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0300" formControlName="cmd_0300"
                           name="cmd_0300" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0301" formControlName="cmd_0301"
                           name="cmd_0301" data-id="Explain 10"></textarea>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0302" formControlName="cmd_0302"
                           name="cmd_0302" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0303" formControlName="cmd_0303"
                           name="cmd_0303" data-id="Explain 10"></textarea>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0304" formControlName="cmd_0304"
                           name="cmd_0304" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
               <tr>
                  <td colspan="16">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0305" formControlName="cmd_0305"
                           name="cmd_0305" data-id="Explain 10"></textarea>
                     </div>
                  </td>
                  <td colspan="2">
                     <div class="d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <textarea style="width: 70%" class="form-control" id="cmd_0306" formControlName="cmd_0306"
                           name="cmd_0306" data-id="Page 10"></textarea>
                     </div>
                  </td>
               </tr>
            </table>
            <hr class="tablePersonal" />
            <h4 class="hThreeTableStyle">
               <b>Section III – Standard Authorization, Attestation and Release </b>
               (Not for Use for Employment Purposes)
            </h4>
            <br />I understand and agree that, as part of the credentialing application process for participation and⁄or
            clinical privileges
            (hereinafter, referred to as “Participation”) at or with
            <table border="1" width="100%">
               <tr>
                  <td colspan="18">
                     <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                        <label class="formLabel">
                           (PLEASE INDICATE MANAGED CARE COMPANY(S) OR HOSPITAL(S) TO WHICH YOU ARE APPLYING)
                           (HEREINAFTER, INDIVIDUALLY REFERRED TO AS THE “ENTITY”)
                        </label>
                        <input style="width: 70%" class="form-control" id="cmd_0307" name="cmd_0307"
                           formControlName="cmd_0307" type="text" data-id="Text5.1.4" />
                     </div>
                  </td>
               </tr>
            </table>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>and any of the Entity’s affiliated entities, I am required to provide sufficient and
                        accurate information for a proper evaluation
                        of my current licensure, relevant training and⁄or experience, clinical competence, health
                        status, character, ethics, and any
                        other criteria used by the Entity for determining initial and ongoing eligibility for
                        Participation. Each Entity and its
                        representatives, employees, and agent(s) acknowledge that the information obtained relating to
                        the application process will
                        be held confidential to the extent permitted by law.
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>I acknowledge that each Entity has its own criteria for acceptance, and I may be accepted or
                        rejected by each
                        independently. I further acknowledge and understand that my cooperation in obtaining information
                        and my consent to the
                        release of information do not guarantee that any Entity will grant me clinical privileges or
                        contract with me as a provider of
                        services. I understand that my application for Participation with the Entity is not an
                        application for employment with the Entity
                        and that acceptance of my application by the Entity will not result in my employment by the
                        Entity.
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label class="d-inline"><b>For Hospital Credentialing.</b> I consent to appear for an interview
                        with the credentials committee, medical staff executive
                        committee, or other representatives of the medical staff, hospital administration or the
                        governing board, if required or
                        requested. As a medical staff member, I pledge to provide continuous care for my patients. I
                        have been informed of existing
                        hospital bylaws, rules and regulations, and policies regarding the application process, and I
                        agree that as a medical staff
                        member, I will be bound by them.
                     </label>

                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label class="d-inline"><b>Authorization of Investigation Concerning Application for
                           Participation.</b>I authorize the following individuals including, without
                        limitation, the Entity, its representatives, employees, and/or designated agent(s); the Entity’s
                        affiliated entities and their
                        representatives, employees, and/or designated agents; and the Entity’s designated professional
                        credentials verification
                        organization (collectively referred to as “Agents”), to investigate information, which includes
                        both oral and written statements,
                        records, and documents, concerning my application for Participation. I agree to allow the Entity
                        and/or its Agent(s) to inspect
                        all records and documents relating to such an investigation.
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label class="d-inline"><b>Authorization of Third-Party Sources to Release Information
                           Concerning Application for Participation.</b> I authorize any third party,
                        including, but not limited to, individuals, agencies, medical groups responsible for credentials
                        verification, corporations,
                        companies, employers, former employers, hospitals, health plans, health maintenance
                        organizations, managed care
                        organizations, law enforcement or licensing agencies, insurance companies, educational and other
                        institutions, military
                        services, medical credentialing and accreditation agencies, professional medical societies, the
                        Federation of State Medical
                        Boards, the National Practitioner Data Bank, and the Health Care Integrity and Protection Data
                        Bank, to release to the Entity
                        and/or its Agent(s), information, including otherwise privileged or confidential information,
                        concerning my professional
                        qualifications, credentials, clinical competence, quality assurance and utilization data,
                        character, mental condition, physical
                        condition, alcohol or chemical dependency diagnosis and treatment, ethics, behavior, or any
                        other matter reasonably having
                        a bearing on my qualifications for Participation in, or with, the Entity. I authorize my current
                        and past professional liability
                        carrier(s) to release my history of claims that have been made and/or are currently pending
                        against me. I specifically waive
                        written notice from any entities and individuals who provide information based upon this
                        Authorization, Attestation and
                        Release. </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label class="d-inline">
                        <b>Authorization of Release and Exchange of Disciplinary
                           Information.</b> n. I hereby further authorize any third party at which I currently
                        have Participation or had Participation and/or each third party’s agents to release
                        “Disciplinary Information,” as defined
                        below, to the Entity and/or its Agent(s). I hereby further authorize the Agent(s) to release
                        Disciplinary Information about any
                        disciplinary action taken against me to its participating Entities at which I have
                        Participation, and as may be otherwise
                        required by law. As used herein, “Disciplinary Information” means information concerning: (I)
                        any action taken by such health
                        care organizations, their administrators, or their medical or other committees to revoke, deny,
                        suspend, restrict, or condition my
                        Participation or impose a corrective action plan; (ii) any other disciplinary action involving
                        me, including, but not limited to,
                        discipline in the employment context; or (iii) my resignation prior to the conclusion of any
                        disciplinary proceedings or prior to
                        the commencement of formal charges, but after I have knowledge that such formal charges were
                        being (or are being)
                        contemplated and/or were (or are) in preparation.
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label class="d-inline">
                        <b> Release from Liability.</b>I release from all liability and hold harmless any Entity, its
                        Agent(s), and any other third party for their acts
                        performed in good faith and without malice unless such acts are due to the gross negligence or
                        willful misconduct of the
                        Entity, its Agent(s), or other third party in connection with the gathering, release and
                        exchange of, and reliance upon,
                        information used in accordance with this Authorization, Attestation and Release. I further agree
                        not to sue any Entity, any
                        Agent(s), or any other third
                     </label>
                  </div>
               </td>
            </tr>
            <hr class="tablePersonal" />
            <h3 class="texasHThree">
               <b>Section III – Standard Authorization, Attestation and Release</b> –
               continued
            </h3>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>party for their acts, defamation or any other claims based on statements made in good faith
                        and without malice or
                        misconduct of such Entity, Agent(s) or third party in connection with the credentialing process.
                        This release shall be in addition
                        to, and in no way shall limit, any other applicable immunities provided by law for peer review
                        and credentialing activities.

                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>In this Authorization, Attestation and Release, all references to the Entity, its Agent(s),
                        and⁄or other third party include their
                        respective employees, directors, officers, advisors, counsel, and agents. The Entity or any of
                        its affiliates or agents retains the
                        right to allow access to the application information for purposes of a credentialing audit to
                        customers and⁄or their auditors to
                        the extent required in connection with an audit of the credentialing processes and provided that
                        the customer and⁄or their
                        auditor executes an appropriate confidentiality agreement. I understand and agree that this
                        Authorization, Attestation and
                        Release is irrevocable for any period during which I am an applicant for Participation at an
                        Entity, a member of an Entity’s
                        medical or health care staff, or a participating provider of an Entity. I agree to execute
                        another form of consent if law or
                        regulation limits the application of this irrevocable authorization. I understand that my
                        failure to promptly provide another
                        consent may be grounds for termination or discipline by the Entity in accordance with the
                        applicable bylaws, rules, and
                        regulations, and requirements of the Entity, or grounds for my termination of Participation at
                        or with the Entity. I agree that
                        information obtained in accordance with the provisions of this Authorization, Attestation and
                        Release is not and will not be a
                        violation of my privacy.
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="80%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>I certify that all information provided by me in my application is true, correct, and
                        complete to the best of my knowledge and
                        belief, and that I will notify the Entity and⁄or its Agent(s) within 10 days of any material
                        changes to the information I have
                        provided in my application or authorized to be released pursuant to the credentialing process. I
                        understand that corrections to
                        the application are permitted at any time prior to a determination of Participation by the
                        Entity, and must be submitted on-line
                        or in writing, and must be dated and signed by me (may be a written or an electronic signature).
                        I understand and agree that
                        any material misstatement or omission in the application may constitute grounds for withdrawal
                        of the application from
                        consideration; denial or revocation of Participation; and⁄or immediate suspension or termination
                        of Participation. This action
                        may be disclosed to the Entity and⁄or its Agent(s).
                     </label>
                  </div>
               </td>
            </tr>
            <tr>
               <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin sideLabelAlignment">
                     <label>I further acknowledge that I have read and understand the foregoing Authorization,
                        Attestation and Release. I understand
                        and agree that a facsimile or photocopy of this Authorization, Attestation and Release shall be
                        as effective as the original.
                     </label>
                  </div>
                  <!-- <div class="vcenter float-end">
                     <label class="boldForImage">SIGNATURE</label>
                     <div class="col-md-3 signaturePadding">
                        <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                           (click)="esignOpenOne()" />
                        <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                           (click)="esignOpenOne()" />
                        <input type="hidden" name="cmd_11122" id="cmd_11122" formControlName="cmd_11122"
                           [class.is-invalid]="(sageneu.controls['cmd_11122'].touched && sageneu.controls['cmd_11122'].invalid) || (sageneu.controls['cmd_11122'].invalid && isSubmit)"
                           value="{{ finalImgPath }}" />
                        <div class="invalid-feedback"
                           *ngIf="(sageneu.controls['cmd_11122'].touched && sageneu.controls['cmd_11122'].invalid) || (sageneu.controls['cmd_11122'].invalid && isSubmit)">
                           Signature is required and cannot be empty
                        </div>
                     </div>
                     <input style="width: 50%" class="form-control" id="cmd_0897" name="cmd_0897"
                        formControlName="cmd_0897" type="text" data-id="full_name" />
                     <label class="boldForLabel">NAME</label>
                     <input style="width: 50%" class="form-control" id="cmd_0779" name="cmd_0779"
                        formControlName="cmd_0779" type="text" data-id="NPI" />
                     <label class="boldForLabel">Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE)
                     </label>
                     <div class="mb-3 col-md-8 input-group">
                        <input style="width: 50%" class="form-control onboard  datepicker" ngbDatepicker
                           #d63="ngbDatepicker" id="cmd_0310" name="cmd_0310" formControlName="cmd_0310" type="text"
                           data-id="Text5.1.2" (blur)="clearInputIfInvalid('cmd_0310')" />
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button">
                              <i class="fas fa-calendar-alt"></i>
                           </button>
                        </div>
                     </div>
                     <div *ngIf="sageneu.get('cmd_0310').invalid ">
                        <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateFormat">
                           Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidMonth">
                           Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateyear">
                           year is out of range(1900-2099)
                        </small>
                        <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateRange">
                           date is out of range
                        </small>
                     </div>
                     <label class="boldForLabel">DATE (MM⁄DD⁄ YYYY) </label>
                  </div> -->
                  <div class="secondSignature">
                     <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                           <div class="col-md-12 signaturePadding">
                              <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                 (click)="esignOpenOne()" />
                              <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                 (click)="esignOpenOne()" />
                              <input type="hidden" name="cmd_11122" id="cmd_11122" formControlName="cmd_11122"
                                 [class.is-invalid]="(sageneu.controls['cmd_11122'].touched && sageneu.controls['cmd_11122'].invalid) || (sageneu.controls['cmd_11122'].invalid && isSubmit)"
                                 value="{{ finalImgPath }}" />
                              <div class="invalid-feedback"
                                 *ngIf="(sageneu.controls['cmd_11122'].touched && sageneu.controls['cmd_11122'].invalid) || (sageneu.controls['cmd_11122'].invalid && isSubmit)">
                                 Signature is required and cannot be empty
                              </div>

                           </div>
                           <label class="boldForImage">SIGNATURE</label>
                           <input style="width: 100%" [class.is-invalid]="
                           (sageneu.controls['cmd_0897'].touched &&
                           sageneu.controls['cmd_0897'].invalid) ||
                           (sageneu.controls['cmd_0897'].invalid && isSubmit)
                           " class="form-control" placeholder="Full Name based on what was entered previously"
                              id="cmd_0897" name="cmd_0897" formControlName="cmd_0897" type="text"
                              data-id="full_name" />
                           <div class="formLabel col-md-2" *ngIf="
                        sageneu.controls['cmd_0897'].touched &&
                        sageneu.controls['cmd_0897'].errors
                        ">
                              <small class="text-danger" *ngIf="sageneu.controls['cmd_0897'].errors?.required">
                                 required
                              </small>
                           </div>
                           <label class="boldForLabel">NAME</label>
                           <input style="width: 100%" [class.is-invalid]="
                           (sageneu.controls['cmd_0779'].touched &&
                           sageneu.controls['cmd_0779'].invalid) ||
                           (sageneu.controls['cmd_0779'].invalid && isSubmit)
                           " class="form-control" placeholder="NPI based on what was entered previously " id="cmd_0779"
                              name="cmd_0779" formControlName="cmd_0779" type="text" data-id="NPI" />
                           <div class="formLabel col-md-2" *ngIf="
                            sageneu.controls['cmd_0779'].touched &&
                            sageneu.controls['cmd_0779'].errors
                            ">
                              <small class="text-danger" *ngIf="sageneu.controls['cmd_0779'].errors?.required">
                                 required
                              </small>
                           </div>
                           <label class="boldForLabel">Last 4 digits of SSN or NPI (PLEASE PRINT OR TYPE)
                           </label>
                           <div class="mb-3  input-group">
                              <input style="width: 50%" class="form-control onboard  datepicker" ngbDatepicker
                                 #d63="ngbDatepicker" id="cmd_0310" [class.is-invalid]="
                           (sageneu.controls['cmd_0310'].touched &&
                           sageneu.controls['cmd_0310'].invalid) ||
                           (sageneu.controls['cmd_0310'].invalid && isSubmit)
                           " name="cmd_0310" placeholder="Populate todays date" formControlName="cmd_0310" type="text"
                                 data-id="Text5.1.2" (blur)="clearInputIfInvalidMandi('cmd_0310')" />
                              <div class="input-group-append">
                                 <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()"
                                    type="button">
                                    <i class="fas fa-calendar-alt"></i>
                                 </button>
                              </div>
                           </div>
                           <div class="text-danger"
                              *ngIf="(sageneu.controls['cmd_0310'].invalid&&sageneu.controls['cmd_0310'].touched) && (sageneu.controls['cmd_0310'].value==''|| sageneu.controls['cmd_0310'].value==null) ">
                              <small>
                                 required
                              </small>
                           </div>
                           <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                           <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidMonth">
                              Month is out of range
                           </small>
                           <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateyear">
                              year is out of range(1900-2099) </small>
                           <small class="text-danger" *ngIf="sageneu.get('cmd_0310').errors?.invalidDateRange">
                              date is out of range
                           </small>
                           <div>
                              <label class="boldForLabel">DATE (MM⁄DD⁄ YYYY) </label>
                           </div>
                        </div>
                     </div>

                  </div>
               </td>
            </tr>
         </form>
      </div>
   </div>
   <div class="modal-footer mt-4">
      <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
         (click)="saveForm('approve')">
         Approve
      </button>
      <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
         (click)="saveForm('submit')">
         Submit
      </button>
      <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
         (click)="saveForm('save')">
         Save
      </button>
      <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">
         Close
      </button>
   </div>
</div>