<div class="affiliated_dermatology">
    <form [formGroup]="affiliatedDermatologyForm">
    <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
           <br>
      <div class="text-center">
              <h5 class="ps-2 m-0"><b><u>CREDENTIALING APPLICATION(NEW PRACTITIONER)</u></b></h5>
          </div>
        <br><br>
      <div class="row mb-3" >
          <label >Enclosed, please find your Credentialing Application. In addition, attached are documents which are required to be completed and signed by you. </label>
        </div><br><div class="row mb-3">
          <label ><b>Please return this <u>completed</u> application <u>and</u> all attached forms – along with the following required documentation:</b></label>
        </div><div class="row mb-3 ps-4">
          <ol>
              <li><input  formControlName="cmd_001417" type="checkbox" id="cmd_001417" name="cmd_001417" value="1"> Completed Application (all areas completed and “N/A” in areas “Not Applicable” to you).</li>
              <li><input  formControlName="cmd_001418" type="checkbox" id="cmd_001418" name="cmd_001418" value="1"> Copy of your <u>current</u> CV (with dates in MM/YYYY format).</li>
              <li><input  formControlName="cmd_001419" type="checkbox" id="cmd_001419" name="cmd_001419" value="1"> Copies of all your <u>current</u> Board Certifications (if applicable).</li>
              <li><input  formControlName="cmd_001420" type="checkbox" id="cmd_001420" name="cmd_001420" value="1"> Copies of any <u>current</u> Special Certifications (if applicable).</li>
              <li><input  formControlName="cmd_001421" type="checkbox" id="cmd_001421" name="cmd_001421" value="1"> A minimum of Three (3) Peer References (within your same specialty).</li>
              <li><input  formControlName="cmd_001422" type="checkbox" id="cmd_001422" name="cmd_001422" value="1"> Copy of your <u>current</u> DEA Certificate.</li>
              <li><input  formControlName="cmd_001423" type="checkbox" id="cmd_001423" name="cmd_001423" value="1"> All (past and present) Medical Licenses (information and/or copies).</li>
              <li><input  formControlName="cmd_001434" type="checkbox" id="cmd_001434" name="cmd_001434" value="1"> Documentation and complete explanation regarding any/all Licensure Actions.</li>
              <li><input  formControlName="cmd_001424" type="checkbox" id="cmd_001424" name="cmd_001424" value="1"> Malpractice Insurance certificates or Information (for minimum of the past 10 years).</li>
              <li><input  formControlName="cmd_001425" type="checkbox" id="cmd_001425" name="cmd_001425" value="1"> Copy of your <u>current</u> Malpractice Certificate.</li>
              <li><input  formControlName="cmd_001426" type="checkbox" id="cmd_001426" name="cmd_001426" value="1"> Documentation and complete explanation regarding any/all Malpractice Claims/Cases.</li>
              <li><input  formControlName="cmd_001427" type="checkbox" id="cmd_001427" name="cmd_001427" value="1"> <u>Current</u> TB documentation (must be from within the last year – from today’s date).</li>
              <li><input  formControlName="cmd_001428" type="checkbox" id="cmd_001428" name="cmd_001428" value="1"> <u>Current</u> Flu Shot documentation (if done within the last year – from today’s date).</li>
              <li><input  formControlName="cmd_001429" type="checkbox" id="cmd_001429" name="cmd_001429" value="1"> <u>Current</u> ACLS/BLS/PALS/etc certificates.</li>
              <li><input  formControlName="cmd_001430" type="checkbox" id="cmd_001430" name="cmd_001430" value="1"> Copies of <u>ALL</u> your Medical Education and Training Certificates (i.e. medical school, internship,residencies and fellowships). </li>
              <li><input  formControlName="cmd_001431" type="checkbox" id="cmd_001431" name="cmd_001431" value="1"> Copies/Information/Logs regarding all your CME activities completed within the past 2 years.</li>
              <li><input  formControlName="cmd_001432" type="checkbox" id="cmd_001432" name="cmd_001432" value="1"> Copy of a <u>current</u> Government issued photo ID.</li>
              <li><input  formControlName="cmd_001433" type="checkbox" id="cmd_001433" name="cmd_001433" value="1"> Documentation and complete explanation regarding any/all Facility/Privileges/Affiliation Actions.</li>
          </ol>
        </div><br><div class="inner" >
          <label >I, (print practitioner’s name) <input  formControlName="cmd_001435"  type="text" name="cmd_001435" id="cmd_001435" placeholder="Printedname">certify I have completed the entire application  and  forms.
               </label>
        </div><br><br><div class="row mb-3" >
                <div class="col-md-3">
                   <label >Practitioner’s Signature: </label>
                  </div>
                  <div class="col-md-3">
                   <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenOne()">
                   <img  *ngIf="showOne" class="img eSignImageShow" [src]="esignOne"  alt="Credential my Doc" (click)="esignOpenOne()">
                   <input  formControlName="cmd_001436" type="hidden" name="cmd_001436" id="cmd_001436">
                    </div>
                <div class="col-md-2" >
                   <label>Date</label>
                  </div>
                  <div class="col-md-4">
                   <div class="input-group">
                    <input  formControlName="cmd_001437" (blur)="clearInputIfInvalid('cmd_001437')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                        #d1="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                    </div>
                    <div  *ngIf="affiliatedDermatologyForm.get('cmd_001437').invalid ">
                      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001437').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001437').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001437').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001437').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                </div>
              </div><br><div class="row mb-3 ps-4">
          <label >Please return the completed, signed application packet with requested documents to: </label>
        </div><div class="text-center" >
          <label ><b>Credentialing Department</b></label>
        </div><div class="text-center" >
          <label><b>Email: <u class="f-col-underline">Credentialing@affderm.com</u>,CC <u class="f-col-underline">Mketterhagen@affderm.com</u></b></label>
        </div><br><div class="row mb-3 ps-4">
          <label >Should you have <u>any</u> questions throughout this process, please feel free to contact the Marianne or the credentialing department. </label>
        </div><br><br><hr class="pdf-line"><div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div><br><div class="row mb-3">
          <label ><b>Instructions: Please complete the <u>entire</u> application and return as soon as possible. <u class="f-italic">DO NOT leave</u> any spaces blank; <u>use N/A if something non-applicable to you</u>. If more space is needed, attach additional sheets and make reference to the question being answered. Thank you.</b></label>
        </div><br><div class="text-center" >
          <label ><b>PERSONAL IDENTIFICATION DATA</b></label>
        </div><br><div class="row mb-3">
          <div class="col-md-1">
             <label >Name:</label>
          </div>
          <div class="col-md-2" >
                  <input  formControlName="9" class="form-control  " id="9" name="9" type="text" placeholder="Last Name"
                  [class.is-invalid]="(affiliatedDermatologyForm.controls['9'].touched && affiliatedDermatologyForm.controls['9'].invalid)
|| (affiliatedDermatologyForm.controls['9'].errors && isSubmit )"
                  data-id="lname"(ngModelChange)="lastNameMap($event)">
<div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['9'].touched && affiliatedDermatologyForm.controls['9'].invalid)
               || (affiliatedDermatologyForm.controls['9'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['9'].errors">
                   required
               </small>
           </div>
                </div>
              <div class="col-md-2">
                                      <ng-select [items]="suffix" bindLabel="name" placeholder="Suffix" bindValue="id"
                        formControlName="29"
                        [class.is-invalid]="(affiliatedDermatologyForm.controls['29'].touched && affiliatedDermatologyForm.controls['29'].invalid)
                        || (affiliatedDermatologyForm.controls['29'].errors && isSubmit )">
                    </ng-select>
 <div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['29'].touched && affiliatedDermatologyForm.controls['29'].invalid)
               || (affiliatedDermatologyForm.controls['29'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['29'].errors">
                   required
               </small>
           </div>
              </div>
              <div class="col-md-2" >
                  <input  formControlName="8" class="form-control  " id="8" name="8" type="text" placeholder="First Name" data-id="fname" (ngModelChange)="firstNameMap($event)"
                  [class.is-invalid]="(affiliatedDermatologyForm.controls['8'].touched && affiliatedDermatologyForm.controls['8'].invalid)
                  || (affiliatedDermatologyForm.controls['8'].errors && isSubmit )">
                   <div class="formLabel "
                                 *ngIf="(affiliatedDermatologyForm.controls['8'].touched && affiliatedDermatologyForm.controls['8'].invalid)
                                 || (affiliatedDermatologyForm.controls['8'].errors && isSubmit )">
                                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['8'].errors">
                                     required
                                 </small>
                             </div>
                </div>
              <div class="col-md-2" >
                  <input  formControlName="809" class="form-control  " id="809" name="809" type="text" placeholder="Middle Name"(ngModelChange)="middleNameMap($event)"
                  [class.is-invalid]="(affiliatedDermatologyForm.controls['809'].touched && affiliatedDermatologyForm.controls['809'].invalid)
                  || (affiliatedDermatologyForm.controls['809'].errors && isSubmit )">
                   <div class="formLabel "
                                 *ngIf="(affiliatedDermatologyForm.controls['809'].touched && affiliatedDermatologyForm.controls['809'].invalid)
                                 || (affiliatedDermatologyForm.controls['809'].errors && isSubmit )">
                                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['809'].errors">
                                     required
                                 </small>
                             </div>
                </div>
              <div class="col-md-1">
             <label >Degree:</label>
          </div>
          <div class="col-md-2">
                        <ng-select [items]="credentials" bindLabel="name" placeholder="Select  Degree" bindValue="id"
                        formControlName="822"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['822'].touched && affiliatedDermatologyForm.controls['822'].invalid)
                    || (affiliatedDermatologyForm.controls['822'].errors && isSubmit )" (change)="onChangeDropDownName($event)">
                    </ng-select>
 <div class="formLabel"
               *ngIf="(affiliatedDermatologyForm.controls['822'].touched && affiliatedDermatologyForm.controls['822'].invalid)
               || (affiliatedDermatologyForm.controls['822'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['822'].errors">
                   required
               </small>
           </div>
            </div>
        </div><br><div class="row mb-3">
          <div class="col-md-2">
             <label >Specialty:</label>
          </div>
          <div class="col-md-4">
                                               <ng-select [items]="specialty" bindLabel="name" placeholder="Primary Specialty" bindValue="id"
                        formControlName="284"
                        [class.is-invalid]="(affiliatedDermatologyForm.controls['284'].touched && affiliatedDermatologyForm.controls['284'].invalid)
                        || (affiliatedDermatologyForm.controls['284'].errors && isSubmit )">
                    </ng-select>
 <div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['284'].touched && affiliatedDermatologyForm.controls['284'].invalid)
               || (affiliatedDermatologyForm.controls['284'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['284'].errors">
                   required
               </small>
           </div>
          </div>
          <div class="col-md-2">
             <label >Sub-Specialty:</label>
          </div>
          <div class="col-md-4">
                                      <ng-select [items]="specialtySecondary" bindLabel="name" placeholder="Secondary specialty" bindValue="id"
                        formControlName="962"
                        [class.is-invalid]="(affiliatedDermatologyForm.controls['962'].touched && affiliatedDermatologyForm.controls['962'].invalid)
                        || (affiliatedDermatologyForm.controls['962'].errors && isSubmit )">
                    </ng-select>
 <div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['962'].touched && affiliatedDermatologyForm.controls['962'].invalid)
               || (affiliatedDermatologyForm.controls['962'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['962'].errors">
                   required
               </small>
           </div>
              </div>
          </div><br><div class="row mb-3">
          <div class="col-md-1">
            <label >Date of Birth:</label>
          </div>
          <div class="col-md-2">
                  <div class="input-group">
                    <input  formControlName="31"  (blur)="clearInputIfInvalidMandi('31')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                        #d2="ngbDatepicker" container="body"
                        [class.is-invalid]="(affiliatedDermatologyForm.controls['31'].touched && affiliatedDermatologyForm.controls['31'].invalid)
                        || (affiliatedDermatologyForm.controls['31'].errors && isSubmit )">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['31'].invalid&&affiliatedDermatologyForm.controls['31'].touched) && (affiliatedDermatologyForm.controls['31'].value==''|| affiliatedDermatologyForm.controls['31'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('31').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('31').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('31').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('31').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
              <div class="col-md-1">
            <label >Place of Birth:</label>
          </div>
          <div class="col-md-2">
              <input  formControlName="810" class="form-control " id="810" name="810" placeholder="Birth City" type="text"
              [class.is-invalid]="(affiliatedDermatologyForm.controls['810'].touched && affiliatedDermatologyForm.controls['810'].invalid)
              || (affiliatedDermatologyForm.controls['810'].errors && isSubmit )">
              <div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['810'].touched && affiliatedDermatologyForm.controls['810'].invalid)
               || (affiliatedDermatologyForm.controls['810'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['810'].errors">
                   required
               </small>
           </div>
          </div>
          <div class="col-md-2">
                                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="289"
                        [class.is-invalid]="(affiliatedDermatologyForm.controls['289'].touched && affiliatedDermatologyForm.controls['289'].invalid)
                        || (affiliatedDermatologyForm.controls['289'].errors && isSubmit )">
                    </ng-select>
 <div class="formLabel "
               *ngIf="(affiliatedDermatologyForm.controls['289'].touched && affiliatedDermatologyForm.controls['289'].invalid)
               || (affiliatedDermatologyForm.controls['289'].errors && isSubmit )">
               <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['289'].errors">
                   required
               </small>
           </div>
          </div>
          <div class="col-md-1" >
              <input  formControlName="291" class="form-control " id="291" name="291" placeholder="Birth Country" type="text"
              [class.is-invalid]="(affiliatedDermatologyForm.controls['291'].touched && affiliatedDermatologyForm.controls['291'].invalid)
              || (affiliatedDermatologyForm.controls['291'].errors && isSubmit )">
               <div class="formLabel "
                             *ngIf="(affiliatedDermatologyForm.controls['291'].touched && affiliatedDermatologyForm.controls['291'].invalid)
                             || (affiliatedDermatologyForm.controls['291'].errors && isSubmit )">
                             <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['291'].errors">
                                 required
                             </small>
                         </div>
            </div>
          <div class="col-md-1">
            <label >Citizenship:</label>
          </div>
          <div class="col-md-2">
              <input  formControlName="292" class="form-control " id="292" name="292" placeholder="Citizenship" type="text"
              [class.is-invalid]="(affiliatedDermatologyForm.controls['292'].touched && affiliatedDermatologyForm.controls['292'].invalid)
              || (affiliatedDermatologyForm.controls['292'].errors && isSubmit )">
               <div class="formLabel "
                             *ngIf="(affiliatedDermatologyForm.controls['292'].touched && affiliatedDermatologyForm.controls['292'].invalid)
                             || (affiliatedDermatologyForm.controls['292'].errors && isSubmit )">
                             <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['292'].errors">
                                 required
                             </small>
                         </div>
            </div>
        </div><div class="row mb-3" >
          <div class="col-md-2">
            <label >Sex:</label>
          </div>
          <div class="col-md-2">
                  <label class="checkbox-inline me-2" >
                  <input  formControlName="30" type="radio" id="30" name="30" value="2">Male</label>
                <label class="checkbox-inline me-2">
                  <input  formControlName="30" type="radio" id="30" name="30" value="1">Female</label>

          </div>
          <div class="formLabel col-md-1"
                  *ngIf="(affiliatedDermatologyForm.controls['30'].touched &&
                  affiliatedDermatologyForm.controls['30'].errors) || (affiliatedDermatologyForm.controls['30'].errors && isSubmit)">
                 <span>
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
                </div>
          <div class="col-md-1 p-0">
            <label >Maiden Name:</label>
          </div>


            <div class="col-md-2">
              <input  formControlName="278" class="form-control" id="278" name="278" type="text" placeholder="prior"
    [class.is-invalid]="(affiliatedDermatologyForm.controls['278'].touched && affiliatedDermatologyForm.controls['278'].invalid)
    || (affiliatedDermatologyForm.controls['278'].errors && isSubmit )">
     <div class="formLabel "
                   *ngIf="(affiliatedDermatologyForm.controls['278'].touched && affiliatedDermatologyForm.controls['278'].invalid)
                   || (affiliatedDermatologyForm.controls['278'].errors && isSubmit )">
                   <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['278'].errors">
                       required
                   </small>
               </div>
            </div>
          <div class="col-md-1">
            <label >SS#:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="32" class="form-control  " id="32" name="32" type="text" placeholder="social security number" data-id="ssn"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['32'].touched && affiliatedDermatologyForm.controls['32'].invalid)
  || (affiliatedDermatologyForm.controls['32'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['32'].touched && affiliatedDermatologyForm.controls['32'].invalid)
                 || (affiliatedDermatologyForm.controls['32'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['32'].errors">
                     required
                 </small>
             </div>
          </div>
        </div><br><div class="row mb-3" >
          <div class="col-md-2">
            <label >E-mail Address: </label>
          </div>
          <div class="col-md-4">
              <input  formControlName="759" class="form-control " id="759" name="759" placeholder="provider email" type="text"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['759'].touched && affiliatedDermatologyForm.controls['759'].invalid)
  || (affiliatedDermatologyForm.controls['759'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['759'].touched && affiliatedDermatologyForm.controls['759'].invalid)
                 || (affiliatedDermatologyForm.controls['759'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['759'].errors">
                     required
                 </small>
             </div>
            </div>
          <div class="col-md-2">
            <label >Cell Phone #:</label>
          </div>
          <div class="col-md-4">
              <input  formControlName="709" class="form-control " id="709" name="709" placeholder="provider mobile phone" type="text"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['709'].touched && affiliatedDermatologyForm.controls['709'].invalid)
  || (affiliatedDermatologyForm.controls['709'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['709'].touched && affiliatedDermatologyForm.controls['709'].invalid)
                 || (affiliatedDermatologyForm.controls['709'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['709'].errors">
                     required
                 </small>
             </div>
            </div>
        </div><br><div class="row mb-3" >
          <div class="col-md-2">
            <label >Marital Status:</label>
          </div>
          <div class="col-md-4">
              <input  formControlName="1680" class="form-control " id="1680" name="1680" placeholder="marital status" type="text">
          </div>
          <div class="col-md-2">
            <label >Spouse’s Name:</label>
          </div>
          <div class="col-md-4">
              <input  formControlName="33" class="form-control " id="33" name="33" placeholder="spouse name" type="text">
          </div>
        </div><br><div class="row mb-3" >
          <div class="col-md-2">
            <label >NPI #:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="1634" class="form-control " id="1634" name="1634" type="text" placeholder="Provider NPI"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['1634'].touched && affiliatedDermatologyForm.controls['1634'].invalid)
  || (affiliatedDermatologyForm.controls['1634'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['1634'].touched && affiliatedDermatologyForm.controls['1634'].invalid)
                 || (affiliatedDermatologyForm.controls['1634'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['1634'].errors">
                     required
                 </small>
             </div>
          </div>
          <div class="col-md-2">
            <label >Individual Tax ID#:</label>
          </div>
          <div class="col-md-4">
              <input  formControlName="1490" class="form-control " id="1490" name="1490" placeholder="Personal TIN" type="text">
          </div>
        </div><br><div class="row mb-3">
            <div class="col-md-2">
              <label >CAQH #:</label>
            </div>
            <div class="col-md-2">
              <input  formControlName="333" class="form-control " id="333" name="333" type="text" placeholder="CAQH"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['333'].touched && affiliatedDermatologyForm.controls['333'].invalid)
  || (affiliatedDermatologyForm.controls['333'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['333'].touched && affiliatedDermatologyForm.controls['333'].invalid)
                 || (affiliatedDermatologyForm.controls['333'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['333'].errors">
                     required
                 </small>
             </div>
            </div>
            <div class="col-md-2">
              <label >CAQH User Name:</label>
            </div>
            <div class="col-md-2">
              <input  formControlName="334" class="form-control " id="334" name="334" type="text" placeholder="CAQH Username"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['334'].touched && affiliatedDermatologyForm.controls['334'].invalid)
  || (affiliatedDermatologyForm.controls['334'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['334'].touched && affiliatedDermatologyForm.controls['334'].invalid)
                 || (affiliatedDermatologyForm.controls['334'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['334'].errors">
                     required
                 </small>
             </div>
            </div>
            <div class="col-md-2">
              <label >CAQH Password:</label>
            </div>
            <div class="col-md-2">
              <input  formControlName="335" class="form-control " id="335" name="335" type="text" placeholder="CAQH Password"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['335'].touched && affiliatedDermatologyForm.controls['335'].invalid)
  || (affiliatedDermatologyForm.controls['335'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['335'].touched && affiliatedDermatologyForm.controls['335'].invalid)
                 || (affiliatedDermatologyForm.controls['335'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['335'].errors">
                     required
                 </small>
             </div>
            </div>
        </div><br><div class="row mb-3">
          <div>
            <label >Medicare NPPES/PECOS Log-In and Password (if you do not have/recall this info, please call 800-465-3203 and request it – it’s only given to practitioners):
           </label>
          </div>
          <div class="col-md-2">
            <input  formControlName="1635" class="form-control " type="text" name="1635" id="1635" placeholder="NPI Username"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['1635'].touched && affiliatedDermatologyForm.controls['1635'].invalid)
  || (affiliatedDermatologyForm.controls['1635'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['1635'].touched && affiliatedDermatologyForm.controls['1635'].invalid)
                 || (affiliatedDermatologyForm.controls['1635'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['1635'].errors">
                     required
                 </small>
             </div>
          </div>
          <div class="col-md-2">
             <input  formControlName="1636" class="form-control " type="text" name="1636" id="1636" placeholder="NPI Password"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['1636'].touched && affiliatedDermatologyForm.controls['1636'].invalid)
  || (affiliatedDermatologyForm.controls['1636'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['1636'].touched && affiliatedDermatologyForm.controls['1636'].invalid)
                 || (affiliatedDermatologyForm.controls['1636'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['1636'].errors">
                     required
                 </small>
             </div>
            </div>
        </div><br><div class="row mb-3">
          <div class="col-md-6">
            <label ><b>Office Address</b></label>
          </div>
          <div class="col-md-6">
            <label ><b>Residence Address</b></label>
          </div>
        </div><div class="row mb-3">
          <div class="col-md-6">
                     <ng-select class="auto-grow-1_5x" [items]="locationList" bindLabel="practice_name"
                     placeholder="Select Locations" (ngModelChange)="onChangeLocation1($event)" bindValue="location_id"
                      formControlName="cmd_100041">
                    </ng-select>
          </div>
          <div class="col-md-6">
            <input  formControlName="16" class="form-control " id="16" name="16" type="text" placeholder="Home address 1">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-3" >
            <input  formControlName="cmd_001690" class="form-control  append_add_21" id="cmd_001690" name="cmd_001690" type="text" placeholder="Address1" data-id="practice__add11">
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001695" class="form-control  append_add_22" id="cmd_001695" name="cmd_001695" type="text" placeholder="Address2" data-id="practice__add21">
          </div>
          <div class="col-md-6">
            <input  formControlName="17" class="form-control " id="17" name="17" type="text" placeholder="Home address 2">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-2" >
             <input  formControlName="cmd_001692" class="form-control  append_city_2" id="cmd_001692" name="cmd_001692" type="text" placeholder="City" data-id="practice__city1">
          </div>
           <div class="mb-3 d-flex flex-row align-items-center flex-wrap  col-md-2">
                                                              <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                                                              formControlName="cmd_001693">
                                                          </ng-select>
                              </div>
          <div class="col-md-2">
            <input  formControlName="cmd_001694" class="form-control  append_zip_2" id="cmd_001694" name="cmd_001694" type="text" placeholder="ZIP" data-id="practice__zip1">
          </div>
          <div class="col-md-2">
            <input  formControlName="18" class="form-control " id="18" name="18" type="text" placeholder="Home City">
          </div>
          <div class="col-md-2">
                            <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="20">
                    </ng-select>
          </div>
          <div class="col-md-2">
            <input  formControlName="21" class="form-control " id="21" name="21" type="text" placeholder="Home Zip">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-1">
              <label >Phone:</label>
            </div>
          <div class="col-md-5" >
            <input  formControlName="cmd_001691" class="form-control  append_phone_2" id="cmd_001691" name="cmd_001691" type="text" data-id="phone">
          </div>
          <div class="col-md-1">
              <label >Phone:</label>
            </div>
          <div class="col-md-5">
            <input  formControlName="708" class="form-control " id="708" name="708" type="text" placeholder="Home Phone">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-1">
              <label >Fax:</label>
            </div>
          <div class="col-md-5" >
            <input  formControlName="cmd_001696" class="form-control  append_fax_2" id="cmd_001696" name="cmd_001696" type="text" data-id="fax">
          </div>
          <div class="col-md-1">
              <label >Fax:</label>
            </div>
          <div class="col-md-5">
            <input  formControlName="710" class="form-control " id="710" name="710" type="text" placeholder="Home Fax">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-1">
              <label >Email:</label>
            </div>
          <div class="col-md-5" >
            <input  formControlName="1664" class="form-control " id="1664" name="1664" type="text" placeholder="work email address">
          </div>
          <div class="col-md-1">
              <label >Emergency Contact:</label>
            </div>
          <div class="col-md-5">
            <input  formControlName="2682" class="form-control " id="2682" name="2682" type="text" placeholder="Emergency Contact name">
          </div>
        </div><div class="row mb-3">
          <div class="col-md-1">
              <label >Pager (Digital or Voice):</label>
            </div>
          <div class="col-md-5" >
            <input  formControlName="1017" class="form-control " id="1017" name="1017" type="text" placeholder="pager number">
          </div>
          <div class="col-md-1">
              <label >Cell Phone:</label>
            </div>
          <div class="col-md-5">
            <input  formControlName="2631" class="form-control " id="2631" name="2631" type="text" placeholder="Emergency contact number">
          </div>
        </div><br><br><hr class="pdf-line"><div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div><br><div class="text-center" >
          <label ><b>LICENSURE (ACTIVE AND INACTIVE)</b></label>
        </div><br><div class="row mb-3" >
          <label ><b><u>Please enclose copies or documentation for each</u></b>. Failure to list and provide information on all out-of-state medical licenses (past and present) may be grounds for rejection of application and/or privileges. </label>
        </div><br><table border="1" width="130%">
      <tbody><tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      <tr>
      </tr>
      </tbody></table><table  class="table-header">
        <tbody><tr  class="table-row">
          <th class="table-data">State</th>
          <th class="table-data">Active</th>
          <th class="table-data">Inactive</th>
          <th class="table-data">License / Certificate No.</th>
          <th class="table-data">Date Issued</th>
          <th class="table-data">Date Expires</th>
        </tr>
        <tr>
          <td class="table-data pt-2">Current Medical Lic</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001438" type="radio" id="cmd_001438" name="cmd_001438" value="1"></label>
              </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001438" type="radio" id="cmd_001438" name="cmd_001438" value="0"></label>
            </div>
            <div class="formLabel"
            *ngIf="(affiliatedDermatologyForm.controls['cmd_001438'].touched &&
            affiliatedDermatologyForm.controls['cmd_001438'].errors) || (affiliatedDermatologyForm.controls['cmd_001438'].errors && isSubmit)">
           <span >
            <i class="fas fa-times ms-2 text-danger"></i>
          </span>
          </div>
          </td>
          <td class="table-data">
            <div >
              <input  formControlName="293" class="form-control  " type="text" name="293" id="293" placeholder="Medical License Number"
              [class.is-invalid]="(affiliatedDermatologyForm.controls['293'].touched && affiliatedDermatologyForm.controls['293'].invalid)
              || (affiliatedDermatologyForm.controls['293'].errors && isSubmit )">
              <div class="formLabel "
              *ngIf="(affiliatedDermatologyForm.controls['293'].touched && affiliatedDermatologyForm.controls['293'].invalid)
              || (affiliatedDermatologyForm.controls['293'].errors && isSubmit )">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['293'].errors">
                  required
              </small>
          </div>
            </div>
          </td>
          <td class="table-data">
            <div>
              <div class="input-group">
                <input  formControlName="294" (blur)="clearInputIfInvalidMandi('294')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d4="ngbDatepicker" container="body"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['294'].touched && affiliatedDermatologyForm.controls['294'].invalid)
                    || (affiliatedDermatologyForm.controls['294'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['294'].invalid&&affiliatedDermatologyForm.controls['294'].touched) && (affiliatedDermatologyForm.controls['294'].value==''|| affiliatedDermatologyForm.controls['294'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('294').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('294').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('294').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('294').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
          <td class="table-data">
            <div>
             <div class="input-group">
                <input  formControlName="295"  (blur)="clearInputIfInvalidMandi('295')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d13="ngbDatepicker" container="body"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['295'].touched && affiliatedDermatologyForm.controls['295'].invalid)
|| (affiliatedDermatologyForm.controls['295'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['295'].invalid&&affiliatedDermatologyForm.controls['295'].touched) && (affiliatedDermatologyForm.controls['295'].value==''|| affiliatedDermatologyForm.controls['295'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('295').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('295').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('295').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('295').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
        </tr>
         <tr>
          <td class="table-data pt-2">Other State Lic:</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001439" type="radio" id="cmd_001439" name="cmd_001439" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001439" type="radio" id="cmd_001439" name="cmd_001439" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="300" class="form-control  " type="text" name="300" id="300" placeholder="Alternate Medical License Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="307" (blur)="clearInputIfInvalid('307')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d5="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('307').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('307').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('307').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('307').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('307').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="308"  (blur)="clearInputIfInvalid('308')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d6="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('308').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('308').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('308').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('308').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('308').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">Other State Lic:</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001440" type="radio" id="cmd_001440" name="cmd_001440" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001440" type="radio" id="cmd_001440" name="cmd_001440" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001441" class="form-control  " type="text" name="cmd_001441" id="cmd_001441" placeholder="Additional Medical License Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001442" (blur)="clearInputIfInvalid('cmd_001442')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d7="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001442').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001442').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001442').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001442').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001442').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001443" (blur)="clearInputIfInvalid('cmd_001443')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d8="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001443').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001443').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001443').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001443').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001443').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">Other State Lic:</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001444" type="radio" id="cmd_001444" name="cmd_001444" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001444" type="radio" id="cmd_001444" name="cmd_001444" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001445" class="form-control  " type="text" name="cmd_001445" id="cmd_001445" placeholder="Additional Medical License Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001446"  (blur)="clearInputIfInvalid('cmd_001446')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d9="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001446').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001446').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001446').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001446').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001446').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001447" (blur)="clearInputIfInvalid('cmd_001447')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d10="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001447').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001447').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001447').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001447').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001447').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2"></td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001448" type="radio" id="cmd_001448" name="cmd_001448" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001448" type="radio" id="cmd_001448" name="cmd_001448" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001449" class="form-control  " type="text" name="cmd_001449" id="cmd_001449" placeholder="Additional Medical License Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001450"  (blur)="clearInputIfInvalid('cmd_001450')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d14="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001450').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001450').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001450').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001450').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001450').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001451"  (blur)="clearInputIfInvalid('cmd_001451')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d11="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001451').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001451').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001451').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001451').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001451').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">DEA </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001452" type="radio" id="cmd_001452" name="cmd_001452" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001452" type="radio" id="cmd_001452" name="cmd_001452" value="0"></label>
            </div>
            <div class="formLabel"
            *ngIf="(affiliatedDermatologyForm.controls['cmd_001452'].touched &&
            affiliatedDermatologyForm.controls['cmd_001452'].errors) || (affiliatedDermatologyForm.controls['cmd_001452'].errors && isSubmit)">
           <span >
            <i class="fas fa-times ms-2 text-danger"></i>
          </span>
          </div>
          </td>
          <td class="table-data">
            <div >
              <input  formControlName="309" class="form-control  " type="text" name="309" id="309" placeholder="DEA Number"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['309'].touched && affiliatedDermatologyForm.controls['309'].invalid)
  || (affiliatedDermatologyForm.controls['309'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['309'].touched && affiliatedDermatologyForm.controls['309'].invalid)
                 || (affiliatedDermatologyForm.controls['309'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['309'].errors">
                     required
                 </small>
             </div>
            </div>
          </td>
          <td class="table-data">
            <div>
            <div class="input-group">
                <input  formControlName="310" (blur)="clearInputIfInvalidMandi('310')"   class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d12="ngbDatepicker" container="body"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['310'].touched && affiliatedDermatologyForm.controls['310'].invalid)
                    || (affiliatedDermatologyForm.controls['310'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['310'].invalid&&affiliatedDermatologyForm.controls['310'].touched) && (affiliatedDermatologyForm.controls['310'].value==''|| affiliatedDermatologyForm.controls['310'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('310').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('310').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('310').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('310').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
          <td class="table-data">
            <div>
           <div class="input-group">
            <input  formControlName="311"  (blur)="clearInputIfInvalidMandi('311')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d15="ngbDatepicker" container="body"
                [class.is-invalid]="(affiliatedDermatologyForm.controls['311'].touched && affiliatedDermatologyForm.controls['311'].invalid)
                || (affiliatedDermatologyForm.controls['311'].errors && isSubmit )">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['311'].invalid&&affiliatedDermatologyForm.controls['311'].touched) && (affiliatedDermatologyForm.controls['311'].value==''|| affiliatedDermatologyForm.controls['311'].value==null) " >
          <small >
          required
          </small>

      </div>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('311').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)                </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('311').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('311').errors?.invalidDateyear">
          year is out of range(1900-2099)                  </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('311').errors?.invalidDateRange">
            date is out of range
            </small>
            </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">CSPMP- registration </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001453" type="radio" id="cmd_001453" name="cmd_001453" value="1"></label>
              </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001453" type="radio" id="cmd_001453" name="cmd_001453" value="0"></label>
            </div>
            <div class="formLabel"
            *ngIf="(affiliatedDermatologyForm.controls['cmd_001453'].touched &&
            affiliatedDermatologyForm.controls['cmd_001453'].errors) || (affiliatedDermatologyForm.controls['cmd_001453'].errors && isSubmit)">
           <span >
            <i class="fas fa-times ms-2 text-danger"></i>
          </span>
          </div>
          </td>
          <td class="table-data">
            <div>
              <input  formControlName="337" class="form-control  " type="text" name="337" id="337" placeholder="CDS Number"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['337'].touched && affiliatedDermatologyForm.controls['337'].invalid)
  || (affiliatedDermatologyForm.controls['337'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['337'].touched && affiliatedDermatologyForm.controls['337'].invalid)
                 || (affiliatedDermatologyForm.controls['337'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['337'].errors">
                     required
                 </small>
             </div>
            </div>
          </td>
          <td class="table-data">
            <div >
              <div class="input-group">
                <input  formControlName="338"  (blur)="clearInputIfInvalidMandi('338')"   class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d16="ngbDatepicker" container="body"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['338'].touched && affiliatedDermatologyForm.controls['338'].invalid)
  || (affiliatedDermatologyForm.controls['338'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['338'].invalid&&affiliatedDermatologyForm.controls['338'].touched) && (affiliatedDermatologyForm.controls['338'].value==''|| affiliatedDermatologyForm.controls['338'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('338').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('338').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('338').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('338').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
          <td class="table-data">
            <div >
            <div class="input-group">
                <input  formControlName="339"  (blur)="clearInputIfInvalidMandi('339')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d17="ngbDatepicker" container="body"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['339'].touched && affiliatedDermatologyForm.controls['339'].invalid)
                    || (affiliatedDermatologyForm.controls['339'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['339'].invalid&&affiliatedDermatologyForm.controls['339'].touched) && (affiliatedDermatologyForm.controls['339'].value==''|| affiliatedDermatologyForm.controls['339'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('339').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('339').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('339').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('339').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">ACLS</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001706" type="radio" id="cmd_001706" name="cmd_001706" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001706" type="radio" id="cmd_001706" name="cmd_001706" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001697" class="form-control  " type="text" name="cmd_001697" id="cmd_001697" placeholder="Medical Certification Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001698"  (blur)="clearInputIfInvalid('cmd_001698')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d18="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001698').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001698').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001698').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001698').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001698').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
           <div class="input-group">
            <input  formControlName="cmd_001699" (blur)="clearInputIfInvalid('cmd_001699')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d19="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001699').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001699').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001699').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001699').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001699').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </td>
        </tr>
        <tr>
          <td class="table-data pt-2">BLS</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001646" type="radio" id="cmd_001646" name="cmd_001646" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001646" type="radio" id="cmd_001646" name="cmd_001646" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001700" class="form-control  " type="text" name="cmd_001700" id="cmd_001700" placeholder="Medical Certification Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001701"  (blur)="clearInputIfInvalid('cmd_001701')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d20="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001701').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001701').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001701').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001701').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001701').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
           <div class="input-group">
            <input  formControlName="cmd_001702" (blur)="clearInputIfInvalid('cmd_001702')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d21="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001702').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001702').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001702').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001702').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001702').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </td>
        </tr>
        <tr>
          <td class="table-data pt-2">PALS</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001647" type="radio" id="cmd_001647" name="cmd_001647" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001647" type="radio" id="cmd_001647" name="cmd_001647" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001703" class="form-control  " type="text" name="cmd_001703" id="cmd_001703" placeholder="Medical Certification Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001704" (blur)="clearInputIfInvalid('cmd_001704')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d22="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001704').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001704').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001704').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001704').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001704').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
         <div class="input-group">
            <input  formControlName="cmd_001705"  (blur)="clearInputIfInvalid('cmd_001705')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d23="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001705').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001705').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001705').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001705').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001705').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </td>
        </tr>
        <tr>
          <td class="table-data">ECFMG (if applicable)</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001645" type="radio" id="cmd_001645" name="cmd_001645" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001645" type="radio" id="cmd_001645" name="cmd_001645" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="514" class="form-control  " type="text" name="514" id="514" placeholder="ECFMG Number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="515"  (blur)="clearInputIfInvalid('515')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                    #d3="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('515').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('515').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('515').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('515').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('515').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
          </td>
        </tr>
        </tbody></table>
        <br>
        <div class="text-center" >
          <label ><b>HEALTH PLANS (ACTIVE AND INACTIVE)</b></label>
        </div>
      <br>
        <div class="row mb-3" >
          <label ><b><u>Please list ALL Health Plans you are or were enrolled with (please enclose copies of plan documentation/letters for each - if availabel).</u></b> Failure to list and provide information on all out-of-state Health Plans (past and present) may be grounds for rejection of application and/or privileges.  </label>
        </div>
        <br>
        <table  class="table-header">
        <tbody><tr  class="table-row">
          <th class="table-data">Health Plan</th>
          <th class="table-data">Active</th>
          <th class="table-data">Inactive</th>
          <th class="table-data">State and Plan ID No</th>
          <th class="table-data">Start Date</th>
          <th class="table-data">End Date</th>
        </tr>
        <tr>
          <td class="table-data pt-2">Medicare/PTAN</td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001648" type="radio" id="cmd_001648" name="cmd_001648" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001648" type="radio" id="cmd_001648" name="cmd_001648" value="0"></label>
                <div class="formLabel"
                *ngIf="(affiliatedDermatologyForm.controls['cmd_001648'].touched &&
 affiliatedDermatologyForm.controls['cmd_001648'].errors) || (affiliatedDermatologyForm.controls['cmd_001648'].errors && isSubmit)">
               <span>
                <i class="fas fa-times ms-2 text-danger"></i>
              </span>
              </div>
              </div>
          </td>
          <td class="table-data ps-2">
            <div class="mb-3">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="1375"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['1375'].touched && affiliatedDermatologyForm.controls['1375'].invalid)
  || (affiliatedDermatologyForm.controls['1375'].errors && isSubmit )">
                    </ng-select>
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['1375'].touched && affiliatedDermatologyForm.controls['1375'].invalid)
                 || (affiliatedDermatologyForm.controls['1375'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['1375'].errors">
                     required
                 </small>
             </div>
            </div>
            <div >
              <input  formControlName="325" class="form-control " type="text" name="325" id="325" placeholder="Medicare number"
  [class.is-invalid]="(affiliatedDermatologyForm.controls['325'].touched && affiliatedDermatologyForm.controls['325'].invalid)
  || (affiliatedDermatologyForm.controls['325'].errors && isSubmit )">
   <div class="formLabel "
                 *ngIf="(affiliatedDermatologyForm.controls['325'].touched && affiliatedDermatologyForm.controls['325'].invalid)
                 || (affiliatedDermatologyForm.controls['325'].errors && isSubmit )">
                 <small class="text-danger" *ngIf="affiliatedDermatologyForm.controls['325'].errors">
                     required
                 </small>
             </div>
            </div>
          </td>
          <td class="table-data">
            <div>
            <div class="input-group">
                <input  formControlName="1924" (blur)="clearInputIfInvalidMandi('1924')"   class="form-control" placeholder="Medicare License" ngbDatepicker
                    #d24="ngbDatepicker" container="body"
                    [class.is-invalid]="(affiliatedDermatologyForm.controls['1924'].touched && affiliatedDermatologyForm.controls['1924'].invalid)
                    || (affiliatedDermatologyForm.controls['1924'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['1924'].invalid&&affiliatedDermatologyForm.controls['1924'].touched) && (affiliatedDermatologyForm.controls['1924'].value==''|| affiliatedDermatologyForm.controls['1924'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1924').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1924').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1924').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1924').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
          <td class="table-data">
            <div >
            <div class="input-group">
                <input  formControlName="1925"  (blur)="clearInputIfInvalidMandi('1925')"   class="form-control" placeholder="Medicare License" ngbDatepicker
                    #d31="ngbDatepicker" container="body"  [class.is-invalid]="(affiliatedDermatologyForm.controls['1925'].touched && affiliatedDermatologyForm.controls['1925'].invalid)
                    || (affiliatedDermatologyForm.controls['1925'].errors && isSubmit )">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(affiliatedDermatologyForm.controls['1925'].invalid&&affiliatedDermatologyForm.controls['1925'].touched) && (affiliatedDermatologyForm.controls['1925'].value==''|| affiliatedDermatologyForm.controls['1925'].value==null) " >
              <small >
              required
              </small>

          </div>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1925').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)                </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1925').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1925').errors?.invalidDateyear">
              year is out of range(1900-2099)                  </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1925').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
          </td>
        </tr>
         <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001649" class="form-control " type="text" name="cmd_001649" id="cmd_001649">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001650" type="radio" id="cmd_001650" name="cmd_001650" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001650" type="radio" id="cmd_001650" name="cmd_001650" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001651" class="form-control  " type="text" name="cmd_001651" id="cmd_001651" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001652" (blur)="clearInputIfInvalid('cmd_001652')"  class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d25="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001652').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001652').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001652').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001652').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001652').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001653" (blur)="clearInputIfInvalid('cmd_001653')"  class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d26="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001653').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001653').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001653').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001653').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001653').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001654" class="form-control " type="text" name="cmd_001654" id="cmd_001654">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001655" type="radio" id="cmd_001655" name="cmd_001655" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001655" type="radio" id="cmd_001655" name="cmd_001655" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001656" class="form-control  " type="text" name="cmd_001656" id="cmd_001656" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001657" (blur)="clearInputIfInvalid('cmd_001657')"  class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d27="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001657').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001657').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001657').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001657').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001657').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001658"  (blur)="clearInputIfInvalid('cmd_001658')" class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d28="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001658').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001658').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001658').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001658').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001658').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          
        </tr>
         <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001659" class="form-control " type="text" name="cmd_001659" id="cmd_001659">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001660" type="radio" id="cmd_001660" name="cmd_001660" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001660" type="radio" id="cmd_001660" name="cmd_001660" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001661" class="form-control  " type="text" name="cmd_001661" id="cmd_001661" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001662"  (blur)="clearInputIfInvalid('cmd_001662')" class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d29="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001662').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001662').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001662').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001662').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001662').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001663" (blur)="clearInputIfInvalid('cmd_001663')"  class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d30="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001663').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001663').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001663').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001663').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001663').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001664" class="form-control " type="text" name="cmd_001664" id="cmd_001664">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001665" type="radio" id="cmd_001665" name="cmd_001665" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001665" type="radio" id="cmd_001665" name="cmd_001665" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001666" class="form-control  " type="text" name="cmd_001666" id="cmd_001666" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001667"  (blur)="clearInputIfInvalid('cmd_001667')" class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d32="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001667').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001667').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001667').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001667').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001667').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001668"  (blur)="clearInputIfInvalid('cmd_001668')" class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d33="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001668').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001668').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001668').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001668').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001668').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001669" class="form-control " type="text" name="cmd_001669" id="cmd_001669">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001670" type="radio" id="cmd_001670" name="cmd_001670" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001670" type="radio" id="cmd_001670" name="cmd_001670" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001671" class="form-control  " type="text" name="cmd_001671" id="cmd_001671" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001672" (blur)="clearInputIfInvalid('cmd_001672')"  class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d34="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d34.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001672').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001672').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001672').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001672').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001672').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001673" (blur)="clearInputIfInvalid('cmd_001673')"  class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d35="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001673').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001673').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001673').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001673').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001673').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001674" class="form-control " type="text" name="cmd_001674" id="cmd_001674">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001675" type="radio" id="cmd_001675" name="cmd_001675" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001675" type="radio" id="cmd_001675" name="cmd_001675" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001676" class="form-control  " type="text" name="cmd_001676" id="cmd_001676" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001677" (blur)="clearInputIfInvalid('cmd_001677')" class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d36="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001677').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001677').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001677').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001677').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001677').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001678" (blur)="clearInputIfInvalid('cmd_001678')" class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d37="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d37.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001678').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001678').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001678').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001678').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001678').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001679" class="form-control " type="text" name="cmd_001679" id="cmd_001679">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001680" type="radio" id="cmd_001680" name="cmd_001680" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001680" type="radio" id="cmd_001680" name="cmd_001680" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001681" class="form-control  " type="text" name="cmd_001681" id="cmd_001681" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001682"  (blur)="clearInputIfInvalid('cmd_001682')" class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d38="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d38.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001682').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001682').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001682').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001682').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001682').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001683" (blur)="clearInputIfInvalid('cmd_001683')"  class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d39="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d39.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001683').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001683').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001683').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001683').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001683').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        <tr>
          <td class="table-data pt-2">
            <input  formControlName="cmd_001684" class="form-control " type="text" name="cmd_001684" id="cmd_001684">
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
             <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001685" type="radio" id="cmd_001685" name="cmd_001685" value="1"></label>
            </div>
          </td>
          <td class="table-data">
            <div class="col-md-1 text-center pt-2" >
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001685" type="radio" id="cmd_001685" name="cmd_001685" value="0"></label>
            </div>
          </td>
          <td class="table-data">
              <input  formControlName="cmd_001686" class="form-control  " type="text" name="cmd_001686" id="cmd_001686" placeholder="State and provider number">
          </td>
          <td class="table-data">
            <div class="input-group">
                <input  formControlName="cmd_001687"  (blur)="clearInputIfInvalid('cmd_001687')" class="form-control" placeholder="Effective Date" ngbDatepicker
                    #d40="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001687').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001687').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001687').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001687').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001687').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
          <td class="table-data">
             <div class="input-group">
                <input  formControlName="cmd_001688" (blur)="clearInputIfInvalid('cmd_001688')"  class="form-control" placeholder="Expiration Date" ngbDatepicker
                    #d41="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="affiliatedDermatologyForm.get('cmd_001688').invalid ">
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001688').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001688').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001688').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001688').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </td>
        </tr>
        </tbody></table>
        <br><br>
      <hr class="pdf-line">
      <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
      <br>
        <div class="text-center" >
          <label ><b>PROFESSIONAL DATA</b></label>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-4">
             <label >Billing Office Name and address:</label>
          </div>
          <div class="col-md-8">
            <input  formControlName="cmd_001454" class="form-control  " id="cmd_001454" name="cmd_001454" type="text" placeholder="Name">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8">
            <input  formControlName="cmd_001455" class="form-control  " id="cmd_001455" name="cmd_001455" type="text" placeholder="Address">
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001456" class="form-control  " id="cmd_001456" name="cmd_001456" type="text" placeholder="Suite">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <input  formControlName="cmd_001457" class="form-control  " id="cmd_001457" name="cmd_001457" type="text" placeholder="City">
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001458" class="form-control  " id="cmd_001458" name="cmd_001458" type="text" placeholder="State">
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001459" class="form-control  " id="cmd_001459" name="cmd_001459" type="text" placeholder="Zip">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
             <label >Group Tax ID #:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001460" class="form-control  " id="cmd_001460" name="cmd_001460" type="text">
          </div>
          <div class="col-md-3">
             <label >Individual Tax ID # (IRS 1099):</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001461" class="form-control  " id="cmd_001461" name="cmd_001461" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
             <label >Office Manager’s Name:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001462" class="form-control  " id="cmd_001462" name="cmd_001462" type="text">
          </div>
          <div class="col-md-3">
             <label >Private / Direct Line:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001463" class="form-control  " id="cmd_001463" name="cmd_001463" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
             <label >Scheduling Contact Person:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001464" class="form-control  " id="cmd_001464" name="cmd_001464" type="text">
          </div>
          <div class="col-md-3">
             <label >Private / Direct Line:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001465" class="form-control  " id="cmd_001465" name="cmd_001465" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
             <label >Credentialing Contact Person:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001466" class="form-control  " id="cmd_001466" name="cmd_001466" type="text">
          </div>
          <div class="col-md-3">
             <label >Private / Direct Line:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001467" class="form-control  " id="cmd_001467" name="cmd_001467" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-5">
             <label >Mailing Address (if different than main office):</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001468" class="form-control  " id="cmd_001468" name="cmd_001468" type="text" placeholder="City">
          </div>
          <div class="col-md-2">
            <input  formControlName="cmd_001469" class="form-control  " id="cmd_001469" name="cmd_001469" type="text" placeholder="State">
          </div>
          <div class="col-md-2">
            <input  formControlName="cmd_001470" class="form-control  " id="cmd_001470" name="cmd_001470" type="text" placeholder="Zip">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <label >Solo Practice</label>
              <label class="checkbox-inline me-2" >
               <input  formControlName="cmd_001471" type="radio" id="cmd_001471" name="cmd_001471" value="1"></label>
          </div>
          <div class="col-md-3">
            <label >Group Practice</label>
              <label class="checkbox-inline me-2" >
               <input  formControlName="cmd_001471" type="radio" id="cmd_001471" name="cmd_001471" value="0"></label>
          </div>
          <div class="col-md-3">
            <label >Group Name</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001472" class="form-control  " id="cmd_001472" name="cmd_001472" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label >Name of Group Members: </label>
          </div>
          <div class="col-md-8">
            <input  formControlName="cmd_001473" class="form-control  " id="cmd_001473" name="cmd_001473" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <label >Name of Coverage Practitioners (1 minimum):</label>
          </div>
          <div class="col-md-8">
            <input  formControlName="cmd_001474" class="form-control  " id="cmd_001474" name="cmd_001474" type="text">
          </div>
        </div>
      <br>
        <div class="text-center" >
          <label ><b>BOARD CERTIFICATION</b></label>
        </div>
      <br>
        <div class="text-center" >
          <label ><u>Please enclose copy of Board Certificates or current “Letter of Eligibility.”</u></label>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-3">
            <label >1. Name of Specialty Board:</label>
          </div>
          <div class="col-md-3">
                      <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select Primary Board Certification" bindValue="id"
                        formControlName="554">
                    </ng-select>
          </div>
          <div class="col-md-2">
            <label >Certificate #:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1169" class="form-control  " id="1169" name="1169" type="text" placeholder="Primary Board Certification Number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Date Certified:</label>
          </div>
          <div class="col-md-2">
           <div class="input-group">
            <input  formControlName="557" (blur)="clearInputIfInvalid('557')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d42="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('557').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('557').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('557').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('557').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('557').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-2">
            <label >Date Recertified:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="558" (blur)="clearInputIfInvalid('558')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d43="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('558').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('558').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('558').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('558').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('558').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-2">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="560" (blur)="clearInputIfInvalid('560')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d44="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d44.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('560').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('560').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('560').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('560').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('560').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-3">
            <label >1. Name of Specialty Board:</label>
          </div>
          <div class="col-md-3">
                      <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select Secondary Board Certification" bindValue="id"
                        formControlName="561">
                    </ng-select>
          </div>
          <div class="col-md-3">
            <label >Certificate #:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="562" class="form-control  " id="562" name="562" type="text" placeholder="Secondary Board Certification Number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Date Certified:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="564" (blur)="clearInputIfInvalid('564')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d45="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('564').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('564').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('564').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('564').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('564').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-2">
            <label >Date Recertified:</label>
          </div>
          <div class="col-md-2">
        <div class="input-group">
            <input  formControlName="565" (blur)="clearInputIfInvalid('565')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d46="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('565').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('565').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('565').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('565').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('565').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-2">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="567" (blur)="clearInputIfInvalid('567')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d47="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('567').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('567').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('567').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('567').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('567').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-4">
            <label >If not certified, indicate current status: </label>
          </div>
          <div class="col-md-8">
            <input  formControlName="cmd_001475" class="form-control " id="cmd_001475" name="cmd_001475" type="text">
          </div>
        </div>
      <br>
          <div class="mb-3 d-flex flex-row align-items-center flex-wrap  col-md-12" >
                  <label >Have you ever been examined by a specialty board but failed to pass the examination?</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001477" type="radio" id="cmd_001477" name="cmd_001477" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001477" type="radio" id="cmd_001477" name="cmd_001477" value="0">No</label>
              </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-4">
            <label >Other Specialty Board(s) and dates:</label>
          </div>
          <div class="col-md-8">
            <input  formControlName="cmd_001476" class="form-control " id="cmd_001476" name="cmd_001476" type="text">
          </div>
        </div>
      <br><br>
      <hr class="pdf-line">
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br>
        <div class="text-center" >
          <label ><b>EDUCATIONAL DATA</b></label>
        </div>
      <br>
        <div class="text-center" >
          <label><b><u class="f-italic">Pre-Medical Education</u></b></label>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >College/University:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="503" class="form-control " id="503" name="503" type="text" placeholder="Under Grad School Name">
          </div>
          <div class="col-md-2">
            <label >Attended From:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="510" (blur)="clearInputIfInvalid('510')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d48="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('510').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('510').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('510').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('510').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('510').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="1238" (blur)="clearInputIfInvalid('1238')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d49="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1238').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1238').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1238').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1238').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1238').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2">
            <input  formControlName="504" class="form-control " id="504" name="504" type="text" placeholder="UG address 1">
          </div>
          <div class="col-md-2">
            <input  formControlName="505" class="form-control " id="505" name="505" type="text" placeholder="UG address 2">
          </div>
          <div class="col-md-1">
            <input  formControlName="506" class="form-control " id="506" name="506" type="text" placeholder="UG City">
          </div>
          <div class="col-md-2">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="508">
                    </ng-select>
          </div>
          <div class="col-md-2">
            <input  formControlName="509" class="form-control " id="509" name="509" type="text" placeholder="UG Zip">
          </div>
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-1">
            <input  formControlName="2345" class="form-control " id="2345" name="2345" type="text" placeholder="UG Phone">
          </div>
        </div>
          <div class="row mb-3">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap  col-md-2">
                      <label >Graduated:</label>
                  </div>
                  <div class="col-md-4">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001478" type="radio" id="cmd_001478" name="cmd_001478" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001478" type="radio" id="cmd_001478" name="cmd_001478" value="0">No</label>
                  </div>
                  <div class="col-md-1">
                    <label >Degree:</label>
                  </div>
                  <div class="col-md-2">
                     <ng-select [items]="degree" bindLabel="name" placeholder="Select  Degree" bindValue="id"
                        formControlName="512">
                    </ng-select>
                  </div>
           </div>
        <br>
        <div class="text-center" >
          <h6><b><u class="f-italic">Medical School</u></b></h6>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Institution:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="490" class="form-control " id="490" name="490" type="text" placeholder="Medical School Name">
          </div>
          <div class="col-md-2">
            <label >Attended From:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
            <input  formControlName="497" (blur)="clearInputIfInvalid('497')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d50="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('497').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('497').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('497').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('497').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('497').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="498"  (blur)="clearInputIfInvalid('498')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d51="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('498').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('498').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('498').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('498').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('498').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2">
            <input  formControlName="491" class="form-control " id="491" name="491" type="text" placeholder="address 1">
          </div>
          <div class="col-md-2">
            <input  formControlName="492" class="form-control " id="492" name="492" type="text" placeholder="address 2">
          </div>
          <div class="col-md-1">
            <input  formControlName="493" class="form-control " id="493" name="493" type="text" placeholder="City">
          </div>
          <div class="col-md-2">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="495">
                    </ng-select>
          </div>
          <div class="col-md-2">
            <input  formControlName="496" class="form-control " id="496" name="496" type="text" placeholder="Zip">
          </div>
        </div>
          <div class="row mb-3">
            <div class="col-md-1">
              <label >Phone:</label>
            </div>
            <div class="col-md-2">
              <input  formControlName="2351" class="form-control " id="2351" name="2351" type="text" placeholder="Phone">
            </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap  col-md-2">
                      <label >Graduated:</label>
                  </div>
                  <div class="col-md-4">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001479" type="radio" id="cmd_001479" name="cmd_001479" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001479" type="radio" id="cmd_001479" name="cmd_001479" value="0">No</label>
                  </div>
                  <div class="col-md-1">
                    <label >Degree:</label>
                  </div>
                  <div class="col-md-2">
                                                                          <ng-select [items]="degree" bindLabel="name" placeholder="Select  Degree" bindValue="id"
                                                                          formControlName="500">
                                                                      </ng-select>
                  </div>
           </div>
        <br>
        <div class="text-center" >
          <h5 ><b><u class="f-italic">Internship </u></b></h5>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Institution:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="517" class="form-control " id="517" name="517" type="text" placeholder="Internship  School Name">
          </div>
          <div class="col-md-2">
            <label >Attended From:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
            <input  formControlName="524" (blur)="clearInputIfInvalid('524')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d52="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('524').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('524').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('524').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('524').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('524').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="525" (blur)="clearInputIfInvalid('525')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d53="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('525').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('525').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('525').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('525').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('525').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="518" class="form-control " id="518" name="518" type="text" placeholder="address 1">
          </div>
          <div class="col-md-2">
            <input  formControlName="519" class="form-control " id="519" name="519" type="text" placeholder="address 2">
          </div>
          <div class="col-md-1">
            <input  formControlName="520" class="form-control " id="520" name="520" type="text" placeholder="City">
          </div>
          <div class="col-md-2">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="522">
                    </ng-select>
          </div>

        </div>
          <div class="row mb-3">
            <div class="col-md-2">
              <input  formControlName="523" class="form-control " id="523" name="523" type="text" placeholder="Zip">
            </div>
                  <div class="col-md-1">
                      <label >Completed:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001480" type="radio" id="cmd_001480" name="cmd_001480" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001480" type="radio" id="cmd_001480" name="cmd_001480" value="0">No</label>
                  </div>
                  <div class="col-md-2">
                      <label >Certificate Granted:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001481" type="radio" id="cmd_001481" name="cmd_001481" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001481" type="radio" id="cmd_001481" name="cmd_001481" value="0">No</label>
                  </div>
                  <div class="col-md-1">
                    <label >Specialty:</label>
                  </div>
                  <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select  Specialty" bindValue="id"
                     formControlName="527">
                 </ng-select>
                  </div>
           </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Program Director’s Name</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="528" class="form-control " id="528" name="528" type="text" placeholder="Internship Director">
          </div>
          <div class="col-md-1">
            <label >Email</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001482" class="form-control " id="cmd_001482" name="cmd_001482" type="text" placeholder="Email">
          </div>
           <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1318" class="form-control " id="1318" name="1318" type="text" placeholder="Phone">
          </div>
        </div>
        <br>
        <div class="text-center" >
          <h5 ><b><u class="f-italic">Residency</u></b></h5>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Institution:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="529" class="form-control " id="529" name="529" type="text" placeholder="Residency  School Name">
          </div>
          <div class="col-md-2">
            <label >Attended From:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
            <input  formControlName="537"  (blur)="clearInputIfInvalid('537')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d54="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d54.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('537').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('537').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('537').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('537').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('537').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="538" (blur)="clearInputIfInvalid('538')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d55="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('538').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('538').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('538').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('538').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('538').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="531" class="form-control " id="531" name="531" type="text" placeholder="Res address 1">
          </div>
          <div class="col-md-2">
            <input  formControlName="1498" class="form-control " id="1498" name="1498" type="text" placeholder="Res address 2">
          </div>
          <div class="col-md-1">
            <input  formControlName="533" class="form-control " id="533" name="533" type="text" placeholder="Res City">
          </div>
          <div class="col-md-2">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="535">
                    </ng-select>
          </div>

        </div>
          <div class="row mb-3">
            <div class="col-md-2">
              <input  formControlName="536" class="form-control " id="536" name="536" type="text" placeholder="Zip">
            </div>
                  <div class="col-md-1">
                      <label >Completed:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001483" type="radio" id="cmd_001483" name="cmd_001483" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001483" type="radio" id="cmd_001483" name="cmd_001483" value="0">No</label>
                  </div>
                  <div class="col-md-2">
                      <label >Certificate Granted:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001484" type="radio" id="cmd_001484" name="cmd_001484" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001484" type="radio" id="cmd_001484" name="cmd_001484" value="0">No</label>
                  </div>
                  <div class="col-md-1">
                    <label >Specialty:</label>
                  </div>
                  <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select  Specialty" bindValue="id"
                     formControlName="530">
                 </ng-select>
                  </div>
           </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Program Director’s Name</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="540" class="form-control " id="540" name="540" type="text" placeholder="Residency Director">
          </div>
          <div class="col-md-1">
            <label >Email</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001485" class="form-control " id="cmd_001485" name="cmd_001485" type="text" placeholder="Email">
          </div>
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1316" class="form-control " id="1316" name="1316" type="text" placeholder="Phone">
          </div>
        </div>
        <br>
        <div class="text-center" >
          <h5><b><u class="f-italic">Fellowship  </u></b></h5>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Institution:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="542" class="form-control " id="542" name="542" type="text" placeholder="Fellowship School Name">
          </div>
          <div class="col-md-2">
            <label >Attended From:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
          <input  formControlName="550"  (blur)="clearInputIfInvalid('550')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
              #d56="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="affiliatedDermatologyForm.get('550').invalid ">
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('550').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('550').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('550').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('550').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
        </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="551"  (blur)="clearInputIfInvalid('551')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d57="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('551').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('551').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('551').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('551').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('551').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="544" class="form-control " id="544" name="544" type="text" placeholder="address 1">
          </div>
          <div class="col-md-2">
            <input  formControlName="545" class="form-control " id="545" name="545" type="text" placeholder="address 2">
          </div>
          <div class="col-md-2">
            <input  formControlName="546" class="form-control " id="546" name="546" type="text" placeholder="City">
          </div>
          <div class="col-md-2">
                        <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="548">
                    </ng-select>
          </div>

        </div>
          <div class="row mb-3">
            <div class="col-md-2">
              <input  formControlName="549" class="form-control " id="549" name="549" type="text" placeholder="Zip">
            </div>
                  <div class="col-md-1">
                      <label >Completed:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001486" type="radio" id="cmd_001486" name="cmd_001486" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001486" type="radio" id="cmd_001486" name="cmd_001486" value="0">No</label>
                  </div>
                  <div class="col-md-2">
                      <label >Certificate Granted:</label>
                  </div>
                  <div class="col-md-3">
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001487" type="radio" id="cmd_001487" name="cmd_001487" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001487" type="radio" id="cmd_001487" name="cmd_001487" value="0">No</label>
                  </div>
                  <div class="col-md-1">
                    <label >Specialty:</label>
                  </div>
                  <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                     formControlName="543">
                 </ng-select>
                  </div>
           </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Program Director’s Name</label>
          </div>
          <div class="col-md-2">
            <input  formControlName="553" class="form-control " id="553" name="553" type="text" placeholder="Fellowship Director">
          </div>
          <div class="col-md-1">
            <label >Email</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001488" class="form-control " id="cmd_001488" name="cmd_001488" type="text" placeholder="Email">
          </div>
          <div class="col-md-1">
            <label >Phone</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1322" class="form-control " id="1322" name="1322" type="text" placeholder="Phone">
          </div>
        </div>
       <br>
        <div class="mb-3 d-flex flex-row align-items-center flex-wrap  col-md-12">
           <label >Does this history represent approved programs, of sufficient duration, to fulfil that portion of the requirements to take boards in your specialty? </label>
              <label class="checkbox-inline me-2" >
                <input  formControlName="cmd_001489" type="radio" id="cmd_001489" name="cmd_001489" value="1">Yes</label>
                  <label class="checkbox-inline me-2" >
                    <input  formControlName="cmd_001489" type="radio" id="cmd_001489" name="cmd_001489" value="0">No</label>
        </div>
      <br>
        <div class="text-center" >
          <label ><b>CONTINUING MEDICAL EDUCATION (CME)</b></label>
        </div>
      <br>
        <div class="text-center" >
          <label ><b><u>If you have been out of post graduate training for greater than 2 years, please submit documentation (copies of certificates and/or a detailed list) of CME hours accumulated within the past 2 years</u></b></label>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label >Total CME hours accumulated during the two past years:</label>
          </div>
          <div class="col-md-6">
            <input  formControlName="cmd_001490" class="form-control " id="cmd_001490" name="cmd_001490" type="text">
          </div>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-6">
            <label >How many (or what percentage) of the above stated CME hours are in your specialty? </label>
          </div>
          <div class="col-md-6">
            <input  formControlName="cmd_001491" class="form-control " id="cmd_001491" name="cmd_001491" type="text">
          </div>
        </div>
      <br><br>
      <hr class="pdf-line">
      <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
      <br>
        <div class="text-center" >
          <label ><b>HEALTH STATUS</b></label>
        </div>
        <div class="row mb-3" >
          <label >If you answer “Yes” to any of these questions, please give a <u>full explanation</u> on a separate sheet and attach it to this application.</label>
        </div>
        <div class="row mb-3 " >
          <div class="col-md-9">

          </div>
          <div class="col-3">
            <label class="header"><b>YES</b></label>
            <label class="header"><b>NO</b></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Do you have a physical or psychological condition that could affect you ability to exercise your clinical privileges or would require an accommodation in order for you to exercise the privileges requested safely and competently?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001492" type="radio" id="cmd_001492" name="cmd_001492" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001492" type="radio" id="cmd_001492" name="cmd_001492" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Are you taking medication, or under any therapy, that is reasonably likely to compromise your professional or medical duties, affect your clinical judgment or motor skills?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001493" type="radio" id="cmd_001493" name="cmd_001493" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001493" type="radio" id="cmd_001493" name="cmd_001493" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Are you presently, or have you ever been, under treatment for dependence of alcohol or drugs?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001494" type="radio" id="cmd_001494" name="cmd_001494" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001494" type="radio" id="cmd_001494" name="cmd_001494" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Are you currently under any limitations, in terms of activity or workload?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001495" type="radio" id="cmd_001495" name="cmd_001495" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001495" type="radio" id="cmd_001495" name="cmd_001495" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Are you currently under the care of a physician or psychologist?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001496" type="radio" id="cmd_001496" name="cmd_001496" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001496" type="radio" id="cmd_001496" name="cmd_001496" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">
            <label >Have you had or developed any chronic or recurring illness, major physical disability or mental health problems that would affect your ability to practice in the area in which privileges are sought?</label>
          </div>
          <div class="col-md-3">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001497" type="radio" id="cmd_001497" name="cmd_001497" value="1"></label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001497" type="radio" id="cmd_001497" name="cmd_001497" value="0"></label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-5">
            <label >Present Health Status:</label>
          </div>
          <div class="col-md-5">
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001498" type="radio" id="cmd_001498" name="cmd_001498" value="2">Good</label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001498" type="radio" id="cmd_001498" name="cmd_001498" value="1">Fair</label>
            <label class="checkbox-inline me-2" >
              <input  formControlName="cmd_001498" type="radio" id="cmd_001498" name="cmd_001498" value="0">Poor</label>
          </div>
        </div>
        <div class="row mb-3" >
          <div class="col-md-5">
            <label >(If fair or poor, please explain on a separate sheet.)</label>
          </div>
        </div>
      <br>
        <div class="text-center" >
          <label ><b>TB DOCUMENTATION</b></label>
        </div>
      <br>
        <div class="row mb-3" >
          <label >The Department of Health Services (DHS) and AFFDERM requires each provider to provide evidence of freedom from infectious pulmonary tuberculosis <b><u>annually.</u></b>The evidence of freedom of infectious pulmonary tuberculosis can be established by: </label>
        </div>
        <div class="row mb-3 ps-4">
          <label >a) A report of negative Mantoux skin test (done within 1 year from today’s date).</label>
        </div>
        <div class="row mb-3 ps-4">
          <label >b) A report of negative chest x-ray (done within 2 years from today’s date); <u>or</u></label>
        </div>
        <div class="row mb-3 ps-4">
          <label >c) If the medical staff/allied health staff member has had a positive Mantoux skin test, another physician’s written statement that he/she is free from infectious pulmonary tuberculosis and completion of the enclosed Annual Tuberculosis Questionnaire (done within 1 year from today’s date).</label>
        </div>
      <br>
        <div class="row mb-3" >
          <label >If a provider signs this attestation and cannot provide evidence, DHS has indicated that it will report the physician to AMB/BOMEX or the appropriate licensing board. </label>
        </div>
      <br>
        <div class="row mb-3" >
          <div class="col-md-7">
          <label >I attest that I was evaluated for infectious pulmonary tuberculosis on  </label>
        </div>
          <div class="col-md-4">
          <div class="input-group">
            <input  formControlName="826" (blur)="clearInputIfInvalid('826')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d58="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d58.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('826').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('826').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('826').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('826').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('826').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br>
        <div class="row mb-3" >
          <label >I have attached the following evidence to demonstrate that I am free from infectious pulmonary tuberculosis:</label>
        </div>
        <div class="row mb-3 ps-4">
          <div class="col-md-12">
            <label class="checkbox-inline me-2" >
             <input  formControlName="cmd_001499" type="checkbox" id="cmd_001499" name="cmd_001499" value="1">A report of a negative Mantoux skin test (done within 1 year from today’s date).</label>
          </div>
        </div>
        <div class="row mb-3 ps-4">
          <div class="col-md-12">
            <label class="checkbox-inline me-2" >
             <input  formControlName="cmd_001500" type="checkbox" id="cmd_001500" name="cmd_001500" value="1"> A report of a negative chest x-ray (done within 2 years from today’s date); <u>or</u></label>
          </div>
        </div>
        <div class="row mb-3 ps-4">
          <div class="col-md-12">
            <label class="checkbox-inline me-2" >
             <input  formControlName="cmd_001501" type="checkbox" id="cmd_001501" name="cmd_001501" value="1">  Although I had a positive Mantoux skin test, I have another physician’s statement that I am free from infectious pulmonary tuberculosis and have enclosed a completed Annual Tuberculosis Questionnaire (done within 1 year from today’s date).</label>
          </div>
        </div>
        <div class="text-center" >
         <label ><b>** MUST INCLUDE COPY OF TB TEST, CHEST X-RAY OR PHYSICIAN’S STATEMENT WITH APPLICATION (INCLUDING ANNUAL TB QUESTIONNAIRE IF APPLICABLE).**</b></label>
        </div>
      <br>
      <hr class="pdf-line">
      <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
      <br>
        <div class="text-center" >
          <label ><b>CREDENTIAL ACTION HISTORY</b></label>
        </div>
      <br>
        <div class="row mb-3" >
          <label >If you answer “Yes” to any of these questions, please give a <u>full explanation</u> on a separate sheet and attach it to this application.</label>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">

          </div>
          <div class="col-3">
            <label class="header"><b>YES</b></label>
            <label class="header"><b>NO</b></label>
          </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >1. Has any of your license(s) or registrations to practice medicine, in any state, ever (voluntarily or involuntarily) been denied, limited,suspended, reprimanded, revoked or relinquished?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001502" type="radio" id="cmd_001502" name="cmd_001502" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001502" type="radio" id="cmd_001502" name="cmd_001502" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >2. Did you ever withdraw any of your affiliation or registration applications before a potential adverse decision was made by board or institution?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001503" type="radio" id="cmd_001503" name="cmd_001503" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001503" type="radio" id="cmd_001503" name="cmd_001503" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >3. Have you ever had any pending formal investigations, corrective actions or disciplines by any institution for any reason, including patient complaints?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001504" type="radio" id="cmd_001504" name="cmd_001504" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001504" type="radio" id="cmd_001504" name="cmd_001504" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >4. Are/were there any pending professional malpractice claims or actions, medical conduct proceedings or licensing board actions in any jurisdictions?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001505" type="radio" id="cmd_001505" name="cmd_001505" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001505" type="radio" id="cmd_001505" name="cmd_001505" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >5. Are/were there any police or agency investigation, sustained charges or convictions for sexual harassment, sexual abuse, child abuse, elder abuse, findings pertinent to violations of patient’s rights, or other human rights violations?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001506" type="radio" id="cmd_001506" name="cmd_001506" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001506" type="radio" id="cmd_001506" name="cmd_001506" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >6. Have any disciplinary actions ever been initiated (or are in pending) against you by <u>any</u> state?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001507" type="radio" id="cmd_001507" name="cmd_001507" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001507" type="radio" id="cmd_001507" name="cmd_001507" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >7. Has your DEA (or state dispensing license) ever (voluntarily or involuntarily) been denied, limited, suspended, reprimanded, or revoked?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001508" type="radio" id="cmd_001508" name="cmd_001508" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001508" type="radio" id="cmd_001508" name="cmd_001508" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >8. Have you ever resigned (voluntarily or involuntarily) from <u>any</u> hospital or facility medical staff?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001509" type="radio" id="cmd_001509" name="cmd_001509" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001509" type="radio" id="cmd_001509" name="cmd_001509" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >9. Has your medical staff membership or privileges ever been denied, limited,suspended, revoked, reduced, or subject to probationary conditions? </label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001510" type="radio" id="cmd_001510" name="cmd_001510" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001510" type="radio" id="cmd_001510" name="cmd_001510" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >10. Has your medical staff category or privileges ever been reduced (or actions been initiated or are pending) limited or disciplinary actions recommended at <u>any</u> facility?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001511" type="radio" id="cmd_001511" name="cmd_001511" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001511" type="radio" id="cmd_001511" name="cmd_001511" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >11. Has your request for any specific clinical privileges ever been denied or granted with limitations?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001512" type="radio" id="cmd_001512" name="cmd_001512" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001512" type="radio" id="cmd_001512" name="cmd_001512" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >12. Has your Specialty Board status ever been suspended, diminished, revoked, or not renewed?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001513" type="radio" id="cmd_001513" name="cmd_001513" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001513" type="radio" id="cmd_001513" name="cmd_001513" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >13. Have you ever been convicted of a felony or misdemeanor (other than minor traffic violations)?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001514" type="radio" id="cmd_001514" name="cmd_001514" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001514" type="radio" id="cmd_001514" name="cmd_001514" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >14. Have you ever been suspended, sanctioned or otherwise restricted from participating in any private, federal or state health insurance program (i.e.,Medicare, Medicaid, HMO or PPO)?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001515" type="radio" id="cmd_001515" name="cmd_001515" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001515" type="radio" id="cmd_001515" name="cmd_001515" value="0"></label>
              </div>
        </div>
      <br><br>
      <hr class="pdf-line">
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br>
        <div class="text-center" >
          <label ><b>HOSPITAL/FACILITY AFFILIATION HISTORY</b></label>
        </div>
      <br>
        <div  class="ms-4">
          <label >Please list,<b><u> In Chronological Order (most recent first),</u></b> all hospitals, surgery centers or healthcare facilities where you have (or had) privileges <u>since completion of your postgraduate education.</u> If more space is needed, attach additional sheets.<b><u class="f-italic"> DO NOT USE “SEE CV” or your application may be denied</u></b></label>
        </div>
      <br>
      <table  class="table-header">
        <tbody><tr  class="table-row">
          <th class="table-data p-2">HEALTHCARE FACILITY</th>
          <th class="table-data p-2" colspan="3">ADDRESS</th>
          <th class="table-data p-2" colspan="3">DATES<br>(FROM-TO)</th>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="418" class="form-control  " id="418" name="418" type="text" placeholder="Primary Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="1305" class="form-control  " id="1305" name="1305" type="text" placeholder="Primary Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="2136" class="form-control  " id="2136" name="2136" type="text" placeholder="Primary Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="421" class="form-control  " id="421" name="421" type="text" placeholder="Primary Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="419" class="form-control  " id="419" name="419" type="text" placeholder="Primary Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF 1 STATE" bindValue="id"
                        formControlName="423">
                    </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="420" class="form-control  " id="420" name="420" type="text" placeholder="Primary Hos Aff add2">
          </div>
          <div >
            <input  formControlName="424" class="form-control  " id="424" name="424" type="text" placeholder="Primary Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="426" (blur)="clearInputIfInvalid('426')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d59="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('426').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('426').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('426').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('426').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('426').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div class="pt-2">
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="1370"  (blur)="clearInputIfInvalid('1370')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d60="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1370').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1370').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1370').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1370').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1370').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div class="pt-2">
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="427" class="form-control  " id="427" name="427" type="text" placeholder="Secondary Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="1307" class="form-control  " id="1307" name="1307" type="text" placeholder="Secondary Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="2137" class="form-control  " id="2137" name="2137" type="text" placeholder="Secondary Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="430" class="form-control  " id="430" name="430" type="text" placeholder="Secondary Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="428" class="form-control  " id="428" name="428" type="text" placeholder="Secondary Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF 2 STATE" bindValue="id"
                        formControlName="432">
                    </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="429" class="form-control  " id="429" name="429" type="text" placeholder="Secondary Hos Aff add2">
          </div>
          <div >
            <input  formControlName="433" class="form-control  " id="433" name="433" type="text" placeholder="Secondary Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="435" (blur)="clearInputIfInvalid('435')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d61="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d61.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('435').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('435').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('435').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('435').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('435').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="1371" (blur)="clearInputIfInvalid('1371')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d62="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d62.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1371').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1371').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>s
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1371').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1371').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1371').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="436" class="form-control  " id="436" name="436" type="text" placeholder="Third Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="1309" class="form-control  " id="1309" name="1309" type="text" placeholder="Third Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="2138" class="form-control  " id="2138" name="2138" type="text" placeholder="Third Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="439" class="form-control  " id="439" name="439" type="text" placeholder="Third Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="437" class="form-control  " id="437" name="437" type="text" placeholder="Third Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF 3 STATE" bindValue="id"
                        formControlName="441">
                    </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="438" class="form-control  " id="438" name="438" type="text" placeholder="Third Hos Aff add2">
          </div>
          <div >
            <input  formControlName="442" class="form-control  " id="442" name="442" type="text" placeholder="Third Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="444"  (blur)="clearInputIfInvalid('444')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d63="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('444').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('444').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('444').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('444').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('444').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div>
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="1372"  (blur)="clearInputIfInvalid('1372')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d64="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d64.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1372').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1372').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1372').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1372').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1372').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="cmd_001516" class="form-control  " id="cmd_001516" name="cmd_001516" type="text" placeholder="Additional Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="cmd_001517" class="form-control  " id="cmd_001517" name="cmd_001517" type="text" placeholder="Additional Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="cmd_001518" class="form-control  " id="cmd_001518" name="cmd_001518" type="text" placeholder="Additional Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="cmd_001519" class="form-control  " id="cmd_001519" name="cmd_001519" type="text" placeholder="Additional Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001520" class="form-control  " id="cmd_001520" name="cmd_001520" type="text" placeholder="Additional Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF STATE" bindValue="id"
                        formControlName="cmd_001521">
                    </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001522" class="form-control  " id="cmd_001522" name="cmd_001522" type="text" placeholder="Additional Hos Aff add2">
          </div>
          <div >
            <input  formControlName="cmd_001523" class="form-control  " id="cmd_001523" name="cmd_001523" type="text" placeholder="Additional Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="cmd_001524"  (blur)="clearInputIfInvalid('cmd_001524')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d65="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d65.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001524').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001524').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001524').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001524').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001524').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="cmd_001525" (blur)="clearInputIfInvalid('cmd_001525')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d66="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d66.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001525').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001525').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001525').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001525').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001525').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
         <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="cmd_001526" class="form-control  " id="cmd_001526" name="cmd_001526" type="text" placeholder="Additional Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="cmd_001527" class="form-control  " id="cmd_001527" name="cmd_001527" type="text" placeholder="Additional Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="cmd_001528" class="form-control  " id="cmd_001528" name="cmd_001528" type="text" placeholder="Additional Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="cmd_001529" class="form-control  " id="cmd_001529" name="cmd_001529" type="text" placeholder="Additional Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001530" class="form-control  " id="cmd_001530" name="cmd_001530" type="text" placeholder="Additional Hos Aff add1">
          </div>
          <div>
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF  STATE" bindValue="id"
                  formControlName="cmd_001531">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001532" class="form-control  " id="cmd_001532" name="cmd_001532" type="text" placeholder="Additional Hos Aff add2">
          </div>
          <div >
            <input  formControlName="cmd_001533" class="form-control  " id="cmd_001533" name="cmd_001533" type="text" placeholder="Additional Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="cmd_001534"  (blur)="clearInputIfInvalid('cmd_001534')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d67="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d67.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001534').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001534').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001534').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001534').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001534').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="cmd_001535" (blur)="clearInputIfInvalid('cmd_001535')"   class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d68="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d68.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001535').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001535').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001535').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001535').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001535').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="cmd_001536" class="form-control  " id="cmd_001536" name="cmd_001536" type="text" placeholder="Additional Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="cmd_001537" class="form-control  " id="cmd_001537" name="cmd_001537" type="text" placeholder="Additional Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="cmd_001538" class="form-control  " id="cmd_001538" name="cmd_001538" type="text" placeholder="Additional Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="cmd_001539" class="form-control  " id="cmd_001539" name="cmd_001539" type="text" placeholder="Additional Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001540" class="form-control  " id="cmd_001540" name="cmd_001540" type="text" placeholder="Additional Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF  STATE" bindValue="id"
                        formControlName="cmd_001541">
                    </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001542" class="form-control  " id="cmd_001542" name="cmd_001542" type="text" placeholder="Additional Hos Aff add2">
          </div>
          <div >
            <input  formControlName="cmd_001543" class="form-control  " id="cmd_001543" name="cmd_001543" type="text" placeholder="Additional Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
         <div class="input-group">
            <input  formControlName="cmd_001544" (blur)="clearInputIfInvalid('cmd_001544')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
            #d69="ngbDatepicker" container="body">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d69.toggle()" type="button"><i
                    class="fas fa-calendar-alt"></i>
            </button>
        </div>
    </div>
    <div  *ngIf="affiliatedDermatologyForm.get('cmd_001544').invalid ">
      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001544').errors?.invalidDateFormat">
        Only Date Format is allowed(MM/DD/YYYY)
      </small>
      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001544').errors?.invalidMonth">
      Month is out of range
      </small>
      <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001544').errors?.invalidDateyear">
        year is out of range(1900-2099)                      </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001544').errors?.invalidDateRange">
          date is out of range
          </small>
      </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div>
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="cmd_001545"  (blur)="clearInputIfInvalid('cmd_001545')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d70="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d70.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001545').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001545').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001545').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001545').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001545').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
      </tr>
      <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="cmd_001546" class="form-control  " id="cmd_001546" name="cmd_001546" type="text" placeholder="Additional Hosp Aff Name">
            <label >Phone:</label>
            <input  formControlName="cmd_001547" class="form-control  " id="cmd_001547" name="cmd_001547" type="text" placeholder="Additional Hosp Aff phone">
            <label >Fax:</label>
            <input  formControlName="cmd_001548" class="form-control  " id="cmd_001548" name="cmd_001548" type="text" placeholder="Additional Hosp Aff fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="cmd_001549" class="form-control  " id="cmd_001549" name="cmd_001549" type="text" placeholder="Additional Hos Aff City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001550" class="form-control  " id="cmd_001550" name="cmd_001550" type="text" placeholder="Additional Hos Aff add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select HOSP AFF  STATE" bindValue="id"
                  formControlName="cmd_001551">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001552" class="form-control  " id="cmd_001552" name="cmd_001552" type="text" placeholder="Additional Hos Aff add2">
          </div>
          <div >
            <input  formControlName="cmd_001553" class="form-control  " id="cmd_001553" name="cmd_001553" type="text" placeholder="Additional Hos Aff Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="cmd_001554" (blur)="clearInputIfInvalid('cmd_001554')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d71="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d71.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001554').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001554').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001554').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001554').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001554').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div>
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="cmd_001555" (blur)="clearInputIfInvalid('cmd_001555')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d72="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d72.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001555').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001555').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001555').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001555').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001555').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
      </tr>
      </tbody></table>
      <br><br>
      <hr class="pdf-line">
      <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
      <br>
        <div class="text-center" >
          <label ><b>EMPLOYMENT/ PRACTICE HISTORY</b></label>
        </div>
      <br>
        <div  class="ms-4">
          <label >Please list,<b><u> In Chronological Order (most recent first),</u></b> all companies, practices or healthcare facilities where you are/were employed <u>since completion of your postgraduate education.</u> This is different from hospital affiliations (unless you were employed by the hospital – if so, please list/explain) If more space is needed, attach additional sheets.<b><u class="f-italic"> DO NOT USE “SEE CV” or your application may be denied</u></b></label>
        </div>
      <br><table  class="table-header">
        <tbody><tr  class="table-row">
          <th class="table-data p-2">EMPLOYER</th>
          <th class="table-data p-2" colspan="3">ADDRESS</th>
          <th class="table-data p-2" colspan="3">DATES<br>(FROM-TO)</th>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="445" class="form-control  " id="445" name="445" type="text" placeholder="Current Work Loc">
            <label >Phone:</label>
            <input  formControlName="453" class="form-control  " id="453" name="453" type="text" placeholder="Current Work Loc Phone">
            <label >Fax:</label>
            <input  formControlName="888" class="form-control  " id="888" name="888" type="text" placeholder="Current Work Loc fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="448" class="form-control  " id="448" name="448" type="text" placeholder="Current Work Loc City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="446" class="form-control  " id="446" name="446" type="text" placeholder="Current Work Loc add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select current wok loc STATE" bindValue="id"
                  formControlName="450">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="447" class="form-control  " id="447" name="447" type="text" placeholder="Current Work Loc add2">
          </div>
          <div >
            <input  formControlName="451" class="form-control  " id="451" name="451" type="text" placeholder="Current Work Loc Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="454"  (blur)="clearInputIfInvalid('454')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d73="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d73.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('454').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('454').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('454').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('454').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('454').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div class="pt-2">
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
         <div class="input-group">
            <input  formControlName="455" (blur)="clearInputIfInvalid('455')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d74="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d74.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('455').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('455').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('455').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('455').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('455').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div class="pt-2">
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="456" class="form-control  " id="456" name="456" tSecond Work Locype="text" placeholder="Second Work Loc">
            <label >Phone:</label>
            <input  formControlName="464" class="form-control  " id="464" name="464" type="text" placeholder="Second Work Loc Phone">
            <label >Fax:</label>
            <input  formControlName="890" class="form-control  " id="890" name="890" type="text" placeholder="Second Work Loc fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="459" class="form-control  " id="459" name="459" type="text" placeholder="Second Work Loc City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="457" class="form-control  " id="457" name="457" type="text" placeholder="Second Work Loc add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Second wok loc STATE" bindValue="id"
                  formControlName="461">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="458" class="form-control  " id="458" name="458" type="text" placeholder="Second Work Loc add2">
          </div>
          <div >
            <input  formControlName="462" class="form-control  " id="462" name="462" type="text" placeholder="Second Work Loc Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="465"  (blur)="clearInputIfInvalid('465')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d75="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d75.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('465').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('465').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('465').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('465').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('465').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div>
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="466" (blur)="clearInputIfInvalid('466')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d76="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d76.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('466').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('466').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('466').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('466').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('466').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="467" class="form-control  " id="467" name="467" type="text" placeholder="third Work Loc">
            <label >Phone:</label>
            <input  formControlName="475" class="form-control  " id="475" name="475" type="text" placeholder="third Work Loc Phone">
            <label >Fax:</label>
            <input  formControlName="892" class="form-control  " id="892" name="892" type="text" placeholder="third Work Loc fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="470" class="form-control  " id="470" name="470" type="text" placeholder="third Work Loc City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="468" class="form-control  " id="468" name="468" type="text" placeholder="third Work Loc add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select third wok loc STATE" bindValue="id"
                  formControlName="472">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="469" class="form-control  " id="469" name="469" type="text" placeholder="third Work Loc add2">
          </div>
          <div >
            <input  formControlName="473" class="form-control  " id="473" name="473" type="text" placeholder="third Work Loc Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="476" (blur)="clearInputIfInvalid('476')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d77="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d77.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('476').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('476').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('476').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('476').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('476').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div>
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="477" (blur)="clearInputIfInvalid('477')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d78="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d78.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('477').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('477').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('477').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('477').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('477').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="1935" class="form-control  " id="1935" name="1935" type="text" placeholder="fourth Work Loc">
            <label >Phone:</label>
            <input  formControlName="1944" class="form-control  " id="1944" name="1944" type="text" placeholder="fourth Work Loc Phone">
            <label >Fax:</label>
            <input  formControlName="1945" class="form-control  " id="1945" name="1945" type="text" placeholder="fourth Work Loc fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="1938" class="form-control  " id="1938" name="1938" type="text" placeholder="fourth Work Loc City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="1936" class="form-control  " id="1936" name="1936" type="text" placeholder="fourth Work Loc add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select fourth wok loc STATE" bindValue="id"
                  formControlName="1940">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="1937" class="form-control  " id="1937" name="1937" type="text" placeholder="fourth Work Loc add2">
          </div>
          <div >
            <input  formControlName="1941" class="form-control  " id="1941" name="1941" type="text" placeholder="fourth Work Loc Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
          <div class="input-group">
            <input  formControlName="1947"  (blur)="clearInputIfInvalid('1947')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d79="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d79.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1947').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1947').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1947').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1947').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1947').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
           <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
         <div class="input-group">
            <input  formControlName="1948" (blur)="clearInputIfInvalid('1948')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d80="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d80.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1948').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1948').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1948').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1948').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1948').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="cmd_001556" class="form-control  " id="cmd_001556" name="cmd_001556" type="text" placeholder="fifth Work Loc">
            <label >Phone:</label>
            <input  formControlName="cmd_001557" class="form-control  " id="cmd_001557" name="cmd_001557" type="text" placeholder="fifth Work Loc Phone">
            <label >Fax:</label>
            <input  formControlName="cmd_001558" class="form-control  " id="cmd_001558" name="cmd_001558" type="text" placeholder="fifth Work Loc fax">
        </td>
        <td>
          <label class="lable-font"></label>
         <label>Street</label>
          <div class="pt-2">
            <input  formControlName="cmd_001559" class="form-control  " id="cmd_001559" name="cmd_001559" type="text" placeholder="fifth Work Loc City">
          </div>
          <div class="pt-2">
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001560" class="form-control  " id="cmd_001560" name="cmd_001560" type="text" placeholder="fifth Work Loc add1">
          </div>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select fifth wok loc STATE" bindValue="id"
                  formControlName="cmd_001561">
              </ng-select>
          </div>
          <div class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div  class="pt-2">
            <input  formControlName="cmd_001562" class="form-control  " id="cmd_001562" name="cmd_001562" type="text" placeholder="fifth Work Loc add2">
          </div>
          <div >
            <input  formControlName="cmd_001563" class="form-control  " id="cmd_001563" name="cmd_001563" type="text" placeholder="fifth Work Loc Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data-header">
           <div class="input-group">
            <input  formControlName="cmd_001564" (blur)="clearInputIfInvalid('cmd_001564')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d81="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d81.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001564').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001564').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001564').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001564').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001564').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        <td class="table-data-header">
          <label class="f3">−</label>
          <div >
            <label class="label-header">To</label>
          </div>
        </td>
        <td class="table-data-header">
        <div class="input-group">
            <input  formControlName="cmd_001565"  (blur)="clearInputIfInvalid('cmd_001565')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d82="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d82.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001565').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001565').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001565').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001565').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001565').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
          <div >
            <label class="label-header">Mo / Yr </label>
          </div>
        </td>
        </tr>
      </tbody></table>
      <br>
        <div class="text-center" >
          <label ><b>LIST ANY TIME GAPS GREATER THAN THREE (3) CONSECUTIVE MONTHS</b></label>
        </div>
      <br>
        <div class="row mb-3" >
          <label >List any time gaps <u>since your completion of post graduate education.</u> If more space is needed, please attach an additional sheet. </label>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >From:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="3395" (blur)="clearInputIfInvalid('3395')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d83="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d83.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3395').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3395').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3395').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3395').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3395').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
                </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
            <input  formControlName="3396" (blur)="clearInputIfInvalid('3396')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d84="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d84.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3396').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3396').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3396').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3396').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3396').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
                </div>
          <div class="col-md-1">
            <label >Reason:</label>
          </div>
          <div class="col-md-5">
                  <input  formControlName="1138" class="form-control " id="1138" name="1138" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >From:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="3397"  (blur)="clearInputIfInvalid('3397')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d85="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d85.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3397').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3397').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3397').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3397').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3397').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
                </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
                  <div class="input-group">
                    <input  formControlName="3398"  (blur)="clearInputIfInvalid('3398')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                        #d86="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d86.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="affiliatedDermatologyForm.get('3398').invalid ">
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3398').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3398').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3398').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3398').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                </div>
          <div class="col-md-1">
            <label >Reason:</label>
          </div>
          <div class="col-md-5">
                  <input  formControlName="1831" class="form-control " id="1831" name="1831" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >From:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="3399"  (blur)="clearInputIfInvalid('3399')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d87="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d87.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3399').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3399').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3399').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3399').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3399').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>

                </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
         <div class="input-group">
          <input  formControlName="3400"  (blur)="clearInputIfInvalid('3400')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
              #d88="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d88.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="affiliatedDermatologyForm.get('3400').invalid ">
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3400').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3400').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3400').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3400').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
                </div>
          <div class="col-md-1">
            <label >Reason:</label>
          </div>
          <div class="col-md-5">
                  <input  formControlName="1832" class="form-control " id="1832" name="1832" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >From:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="3401" (blur)="clearInputIfInvalid('3401')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d89="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d89.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3401').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3401').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3401').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3401').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3401').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
                </div>
          <div class="col-md-1">
            <label >To:</label>
          </div>
          <div class="col-md-2">
          <div class="input-group">
            <input  formControlName="3402"  (blur)="clearInputIfInvalid('3402')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d90="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d90.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('3402').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3402').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3402').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3402').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('3402').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
                </div>
          <div class="col-md-1">
            <label >Reason:</label>
          </div>
          <div class="col-md-5">
                  <input  formControlName="1953" class="form-control " id="1953" name="1953" type="text">
          </div>
        </div>
       <br><br>
      <hr class="pdf-line">
      <div class="text-center">
        <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
      </div>
      <br>
        <div class="text-center" >
          <label ><b>PROFESSIONAL REFERENCES</b></label>
        </div>
      <br>
        <div class="row mb-3" >
          <label >Please submit the names of at <u>minimum</u> three (3) professional references practitioners who have had direct clinical observation with you (include specialty and complete address, e-mail address and cell, phone and fax numbers) and whom can attest to your <u>current</u> clinical competency, ethical character, health status and ability to work cooperatively with others. They may not be affiliated with you by family and/or financial relationship and/or association. Out of state (or new to state) applicants will need at least one (1) Arizona professional reference.</label>
        </div>
      <br>
      <table  class="table-header">
        <tbody><tr  class="table-row">
          <th class="table-data p-2">NAME &amp; SPECIALTY </th>
          <th class="table-data p-2" colspan="3">ADDRESS</th>
          <th class="table-data p-2">NUMBERS</th>
        </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="359" class="form-control  " id="359" name="359" type="text" placeholder="First Reference Name">
            <label >Specialty:</label>
            <div>
                        <ng-select [items]="specialty" bindLabel="name" placeholder="Select first Ref Specialty" bindValue="id"
                        formControlName="368">
                    </ng-select>
            </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div class="pt-2">
            <input  formControlName="360" class="form-control  " id="360" name="360" type="text" placeholder="First Reference add1 ">
          </div>
          <div class="pt-2">
            <input  formControlName="362" class="form-control  " id="362" name="362" type="text" placeholder="First Reference City">
          </div>
          <div >
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div >
          <label>Street</label>
          </div>
          <label  class="pt-2"></label>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select First Ref STATE" bindValue="id"
                  formControlName="364">
              </ng-select>
          </div>
          <div  class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div  class="pt-2">
            <input  formControlName="361" class="form-control  " id="361" name="361" type="text" placeholder="First Reference add 2">
          </div>
          <div  class="pt-2">
            <input  formControlName="365" class="form-control  " id="365" name="365" type="text" placeholder="First Reference Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data">
            <label >Phone:</label>
            <input  formControlName="369" class="form-control  " id="369" name="369" type="text" placeholder="First Reference phone">
            <label >E-mail:</label>
            <input  formControlName="371" class="form-control  " id="371" name="371" type="text" placeholder="First Reference email">
            <label >Fax:</label>
            <input  formControlName="370" class="form-control  " id="370" name="370" type="text" placeholder="First Reference fax">
        </td>
       </tr>
       <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="372" class="form-control  " id="372" name="372" type="text" placeholder="Second Reference Name">
            <label >Specialty:</label>
            <div>
                      <ng-select [items]="specialty" bindLabel="name" placeholder="Select Second Ref Specialty" bindValue="id"
                      formControlName="380">
                  </ng-select>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div class="pt-2">
            <input  formControlName="373" class="form-control  " id="373" name="373" type="text" placeholder="Second Reference add1 ">
          </div>
          <div class="pt-2">
            <input  formControlName="375" class="form-control  " id="375" name="375" type="text" placeholder="Second Reference City">
          </div>
          <div >
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div >
          <label>Street</label>
          </div>
          <label  class="pt-2"></label>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Second Ref STATE" bindValue="id"
                  formControlName="377">
              </ng-select>
          </div>
          <div  class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div  class="pt-2">
            <input  formControlName="374" class="form-control  " id="374" name="374" type="text" placeholder="Second Reference add 2">
          </div>
          <div  class="pt-2">
            <input  formControlName="378" class="form-control  " id="378" name="378" type="text" placeholder="Second Reference Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data">
            <label >Phone:</label>
            <input  formControlName="381" class="form-control  " id="381" name="381" type="text" placeholder="Second Reference phone">
            <label >E-mail:</label>
            <input  formControlName="383" class="form-control  " id="383" name="383" type="text" placeholder="Second Reference email">
            <label >Fax:</label>
            <input  formControlName="382" class="form-control  " id="382" name="382" type="text" placeholder="Second Reference fax">
        </td>
       </tr>
        <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="384" class="form-control  " id="384" name="384" type="text" placeholder="Third Reference Name">
            <label >Specialty:</label>
            <div>
                      <ng-select [items]="specialty" bindLabel="name" placeholder="Select Third Ref Specialty" bindValue="id"
                      formControlName="393">
                  </ng-select>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div class="pt-2">
            <input  formControlName="385" class="form-control  " id="385" name="385" type="text" placeholder="Third Reference add1 ">
          </div>
          <div class="pt-2">
            <input  formControlName="387" class="form-control  " id="387" name="387" type="text" placeholder="Third Reference City">
          </div>
          <div >
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div >
          <label>Street</label>
          </div>
          <label  class="pt-2"></label>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Third Ref STATE" bindValue="id"
                  formControlName="389">
              </ng-select>
          </div>
          <div  class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div  class="pt-2">
            <input  formControlName="386" class="form-control  " id="386" name="386" type="text" placeholder="Third Reference add 2">
          </div>
          <div  class="pt-2">
            <input  formControlName="390" class="form-control  " id="390" name="390" type="text" placeholder="Third Reference Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data">
            <label >Phone:</label>
            <input  formControlName="394" class="form-control  " id="394" name="394" type="text" placeholder="Third Reference phone">
            <label >E-mail:</label>
            <input  formControlName="396" class="form-control  " id="396" name="396" type="text" placeholder="Third Reference email">
            <label >Fax:</label>
            <input  formControlName="395" class="form-control  " id="395" name="395" type="text" placeholder="Third Reference fax">
        </td>
       </tr>
       <tr>
        <td class="table-data">
            <label >Name:</label>
            <input  formControlName="1681" class="form-control  " id="1681" name="1681" type="text" placeholder="Fourth Reference Name">
            <label >Specialty:</label>
            <div>
                      <ng-select [items]="specialty" bindLabel="name" placeholder="Select Fourth Ref Specialty" bindValue="id"
                      formControlName="1691">
                  </ng-select>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div class="pt-2">
            <input  formControlName="1682" class="form-control  " id="1682" name="1682" type="text" placeholder="Fourth Reference add1 ">
          </div>
          <div class="pt-2">
            <input  formControlName="1684" class="form-control  " id="1684" name="1684" type="text" placeholder="Fourth Reference City">
          </div>
          <div >
            <label class="label-header">City</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <div >
          <label>Street</label>
          </div>
          <label  class="pt-2"></label>
          <div >
                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Fourth Ref STATE" bindValue="id"
                  formControlName="1686">
              </ng-select>
          </div>
          <div  class="pt-2">
            <label class="label-header">State</label>
          </div>
        </td>
        <td>
          <label class="lable-font"></label>
          <label></label>
          <div  class="pt-2">
            <input  formControlName="1683" class="form-control  " id="1683" name="1683" type="text" placeholder="Fourth Reference add 2">
          </div>
          <div  class="pt-2">
            <input  formControlName="1687" class="form-control  " id="1687" name="1687" type="text" placeholder="Fourth Reference Zip">
          </div>
          <div class="pt-2">
            <label class="label-header">Zip</label>
          </div>
        </td>
        <td class="table-data">
            <label >Phone:</label>
            <input  formControlName="1692" class="form-control  " id="1692" name="1692" type="text" placeholder="Fourth Reference phone">
            <label >E-mail:</label>
            <input  formControlName="1694" class="form-control  " id="1694" name="1694" type="text" placeholder="Fourth Reference email">
            <label >Fax:</label>
            <input  formControlName="1693" class="form-control  " id="1693" name="1693" type="text" placeholder="Fourth Reference fax">
        </td>
       </tr>
       </tbody></table>
       <br><br>
      <hr class="pdf-line">
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br>
        <div class="text-center" >
          <label ><b>PROFESSIONAL LIABILITY DATA </b></label>
        </div>
      <br>
        <div class="text-center" >
          <label >(Supply information for the last 15 years. Use a separate sheet if more space is needed.) </label>
        </div>
      <br>
        <div class="row mb-3" >
          <div class="col-md-2">
            <label ><b>Present Carrier:</b></label>
          </div>
          <div class="col-md-4">
            <input  formControlName="345" class="form-control " id="345" name="345" type="text" placeholder="Current Malpractice Carrier">
          </div>
          <div class="col-md-2">
            <label >Policy No.:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="346" class="form-control " id="346" name="346" type="text" placeholder="Current Malpractice Carrier policy number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2" >
            <input  formControlName="901" class="form-control " id="901" name="901" type="text" placeholder="MLP add 1">
          </div>
          <div class="col-md-2" >
            <input  formControlName="902" class="form-control " id="902" name="902" type="text" placeholder="MLP add 2">
          </div>
          <div class="col-md-1" >
            <input  formControlName="903" class="form-control " id="903" name="903" type="text" placeholder="MLP City">
          </div>
          <div class="col-md-2">
                                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select MLP STATE" bindValue="id"
                                  formControlName="905">
                              </ng-select>
          </div>
          <div class="col-md-1">
            <input  formControlName="906" class="form-control " id="906" name="906" type="text" placeholder="MLP Zip">
          </div>
          <div class="col-md-1">
            <label >Contact/Agent:</label>
          </div>
          <div class="col-md-2" >
            <input  formControlName="1240" class="form-control " id="1240" name="1240" type="text" placeholder="MLP agent">
          </div>
         </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1266" class="form-control " id="1266" name="1266" type="text" placeholder="Current MLP Carrier Phone">
          </div>
          <div class="col-md-1">
            <label >Fax:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1273" class="form-control " id="1273" name="1273" type="text" placeholder="Current MLP Carrier Fax">
          </div>
          <div class="col-md-1">
            <label >Email:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1639" class="form-control " id="1639" name="1639" type="text" placeholder="Current Mlp Carrier Email">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Exclusions:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001579" class="form-control " id="cmd_001579" name="cmd_001579" type="text">
          </div>
          <div class="col-md-3">
            <label >Tail/Prior Act Coverage?</label>
          </div>
        <div class="col-md-3">
          <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001578" type="radio" id="cmd_001578" name="cmd_001578" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001578" type="radio" id="cmd_001578" name="cmd_001578" value="0">No</label>
        </div>
       </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Coverage Limits Amounts:</label>
          </div>
          <div class="col-md-2" >
    <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
    formControlName="350">
</ng-select>
          </div>
          <div class="col-md-2" >
 <ng-select [items]="aggregates" bindLabel="name" placeholder="Select aggregate" bindValue="id"
 formControlName="351">
</ng-select>
          </div>
          <div class="col-md-3">
            <label >Number of Claims:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001580" class="form-control " id="cmd_001580" name="cmd_001580" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Effective Date:</label>
          </div>
          <div class="col-md-3">
           <div class="input-group">
            <input  formControlName="348" (blur)="clearInputIfInvalid('348')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d91="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d91.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('348').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('348').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('348').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('348').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('348').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="349"  (blur)="clearInputIfInvalid('349')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d92="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d92.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('349').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('349').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('349').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('349').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('349').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Retro Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="945" (blur)="clearInputIfInvalid('945')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d93="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d93.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('945').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('945').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('945').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('945').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('945').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br>
        <div class="row mb-3" >
          <div class="col-md-2">
            <label ><b>Carrier/Company:</b></label>
          </div>
          <div class="col-md-4">
            <input  formControlName="1242" class="form-control " id="1242" name="1242" type="text" placeholder="Previous Malpractice Carrier">
          </div>
          <div class="col-md-2">
            <label >Policy No.:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="1256" class="form-control " id="1256" name="1256" type="text" placeholder="Previous Malpractice Carrier policy number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2" >
            <input  formControlName="1244" class="form-control " id="1244" name="1244" type="text" placeholder="Pre add 1">
          </div>
          <div class="col-md-2" >
            <input  formControlName="1245" class="form-control " id="1245" name="1245" type="text" placeholder="Pre add 2">
          </div>
          <div class="col-md-1" >
            <input  formControlName="1246" class="form-control " id="1246" name="1246" type="text" placeholder="Pre City">
          </div>
          <div class="col-md-2">
                                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Pre MLP STATE" bindValue="id"
                                  formControlName="1248">
                              </ng-select>
          </div>
          <div class="col-md-1">
            <input  formControlName="1249" class="form-control " id="1249" name="1249" type="text" placeholder="Pre Zip">
          </div>
          <div class="col-md-1">
            <label >Contact/Agent:</label>
          </div>
          <div class="col-md-1" >
            <input  formControlName="1243" class="form-control " id="1243" name="1243" type="text" placeholder="Pre Agent">
          </div>
         </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1254" class="form-control " id="1254" name="1254" type="text" placeholder="Previous MLP Carrier Phone">
          </div>
          <div class="col-md-1">
            <label >Fax:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1255" class="form-control " id="1255" name="1255" type="text" placeholder="Previous MLP Carrier Fax">
          </div>
          <div class="col-md-1">
            <label >Email:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="1857" class="form-control " id="1857" name="1857" type="text" placeholder="Previous Mlp Carrier Email">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Exclusions:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001581" class="form-control " id="cmd_001581" name="cmd_001581" type="text">
          </div>
          <div class="col-md-3">
            <label >Tail/Prior Act Coverage?</label>
          </div>
        <div class="col-md-3">
          <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001582" type="radio" id="cmd_001582" name="cmd_001582" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001582" type="radio" id="cmd_001582" name="cmd_001582" value="0">No</label>
        </div>
       </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Coverage Limits Amounts:</label>
          </div>
          <div class="col-md-2" >
                                                   <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
                                                   formControlName="1264">
                                               </ng-select>
          </div>
          <div class="col-md-2" >
              <ng-select [items]="aggregates" bindLabel="name" placeholder="Select aggregate" bindValue="id"
              formControlName="1265">
          </ng-select>
          </div>
          <div class="col-md-3">
            <label >Number of Claims:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001583" class="form-control " id="cmd_001583" name="cmd_001583" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Effective Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="1252"  (blur)="clearInputIfInvalid('1252')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d94="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d94.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1252').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1252').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1252').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1252').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1252').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="1253" (blur)="clearInputIfInvalid('1253')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d95="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d95.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1253').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1253').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1253').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1253').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1253').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Retro Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="1251" (blur)="clearInputIfInvalid('1251')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d96="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d96.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1251').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1251').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1251').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1251').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1251').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br>
        <div class="row mb-3" >
          <div class="col-md-2">
            <label ><b>Carrier/Company:</b></label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001584" class="form-control " id="cmd_001584" name="cmd_001584" type="text" placeholder="Previous Malpractice Carrier">
          </div>
          <div class="col-md-2">
            <label >Policy No.:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001585" class="form-control " id="cmd_001585" name="cmd_001585" type="text" placeholder="Previous Malpractice Carrier policy number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2" >
            <input  formControlName="cmd_001586" class="form-control " id="cmd_001586" name="cmd_001586" type="text" placeholder="Pre add 1">
          </div>
          <div class="col-md-2" >
            <input  formControlName="cmd_001587" class="form-control " id="cmd_001587" name="cmd_001587" type="text" placeholder="Pre add 2">
          </div>
          <div class="col-md-1" >
            <input  formControlName="cmd_001588" class="form-control " id="cmd_001588" name="cmd_001588" type="text" placeholder="Pre City">
          </div>
          <div class="col-md-2">
                                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Pre MLP STATE" bindValue="id"
                                  formControlName="cmd_001589">
                              </ng-select>
          </div>
          <div class="col-md-1">
            <input  formControlName="cmd_001590" class="form-control " id="cmd_001590" name="cmd_001590" type="text" placeholder="Pre Zip">
          </div>
          <div class="col-md-1">
            <label >Contact/Agent:</label>
          </div>
          <div class="col-md-1" >
            <input  formControlName="cmd_001591" class="form-control " id="cmd_001591" name="cmd_001591" type="text" placeholder="Pre Agent">
          </div>
         </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001592" class="form-control " id="cmd_001592" name="cmd_001592" type="text" placeholder="Previous MLP Carrier Phone">
          </div>
          <div class="col-md-1">
            <label >Fax:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001593" class="form-control " id="cmd_001593" name="cmd_001593" type="text" placeholder="Previous MLP Carrier Fax">
          </div>
          <div class="col-md-1">
            <label >Email:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001594" class="form-control " id="cmd_001594" name="cmd_001594" type="text" placeholder="Previous Mlp Carrier Email">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Exclusions:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001595" class="form-control " id="cmd_001595" name="cmd_001595" type="text">
          </div>
          <div class="col-md-3">
            <label >Tail/Prior Act Coverage?</label>
          </div>
         <div class="col-md-3">
          <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001596" type="radio" id="cmd_001596" name="cmd_001596" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001596" type="radio" id="cmd_001596" name="cmd_001596" value="0">No</label>
        </div>
       </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Coverage Limits Amounts:</label>
          </div>
          <div class="col-md-2" >
  <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
                      formControlName="cmd_001597">
                          </ng-select>
          </div>
          <div class="col-md-2" >
                            <ng-select [items]="aggregates" bindLabel="name" placeholder="Select aggregates" bindValue="id"
                            formControlName="cmd_001598">
                        </ng-select>
          </div>
          <div class="col-md-3">
            <label >Number of Claims:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001599" class="form-control " id="cmd_001599" name="cmd_001599" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Effective Date:</label>
          </div>
          <div class="col-md-3">
           <div class="input-group">
            <input  formControlName="cmd_001600" (blur)="clearInputIfInvalid('cmd_001600')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d97="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d97.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001600').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001600').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001600').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001600').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001600').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-3">
         <div class="input-group">
          <input  formControlName="cmd_001601"  (blur)="clearInputIfInvalid('cmd_001601')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
              #d98="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d98.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="affiliatedDermatologyForm.get('cmd_001601').invalid ">
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001601').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001601').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001601').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001601').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
        </div>
          <div class="col-md-1">
            <label >Retro Date:</label>
          </div>
          <div class="col-md-3">
         <div class="input-group">
          <input  formControlName="cmd_001602" (blur)="clearInputIfInvalid('cmd_001602')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
              #d99="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d99.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="affiliatedDermatologyForm.get('cmd_001602').invalid ">
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001602').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001602').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001602').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001602').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
        </div>
        </div>
      <br>
        <div class="row mb-3" >
          <div class="col-md-2">
            <label ><b>Carrier/Company:</b></label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001603" class="form-control " id="cmd_001603" name="cmd_001603" type="text" placeholder="Previous Malpractice Carrier">
          </div>
          <div class="col-md-2">
            <label >Policy No.:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001604" class="form-control " id="cmd_001604" name="cmd_001604" type="text" placeholder="Previous Malpractice Carrier policy number">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Address:</label>
          </div>
          <div class="col-md-2" >
            <input  formControlName="cmd_001605" class="form-control " id="cmd_001605" name="cmd_001605" type="text" placeholder="Pre add 1">
          </div>
          <div class="col-md-2" >
            <input  formControlName="cmd_001606" class="form-control " id="cmd_001606" name="cmd_001606" type="text" placeholder="Pre add 2">
          </div>
          <div class="col-md-1" >
            <input  formControlName="cmd_001607" class="form-control " id="cmd_001607" name="cmd_001607" type="text" placeholder="Pre City">
          </div>
          <div class="col-md-2">
                                  <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Pre MLP STATE" bindValue="id"
                                  formControlName="cmd_001608">
                              </ng-select>
          </div>
          <div class="col-md-1">
            <input  formControlName="cmd_001609" class="form-control " id="cmd_001609" name="cmd_001609" type="text" placeholder="Pre Zip">
          </div>
          <div class="col-md-1">
            <label >Contact/Agent:</label>
          </div>
          <div class="col-md-1" >
            <input  formControlName="cmd_001610" class="form-control " id="cmd_001610" name="cmd_001610" type="text" placeholder="Pre Agent">
          </div>
         </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Phone:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001611" class="form-control " id="cmd_001611" name="cmd_001611" type="text" placeholder="Previous MLP Carrier Phone">
          </div>
          <div class="col-md-1">
            <label >Fax:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001612" class="form-control " id="cmd_001612" name="cmd_001612" type="text" placeholder="Previous MLP Carrier Fax">
          </div>
          <div class="col-md-1">
            <label >Email:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001613" class="form-control " id="cmd_001613" name="cmd_001613" type="text" placeholder="Previous Mlp Carrier Email">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Exclusions:</label>
          </div>
          <div class="col-md-4">
            <input  formControlName="cmd_001614" class="form-control " id="cmd_001614" name="cmd_001614" type="text">
          </div>
          <div class="col-md-3">
            <label >Tail/Prior Act Coverage?</label>
          </div>
         <div class="col-md-3">
          <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001615" type="radio" id="cmd_001615" name="cmd_001615" value="1">Yes</label>
                      <label class="checkbox-inline me-2" >
                          <input  formControlName="cmd_001615" type="radio" id="cmd_001615" name="cmd_001615" value="0">No</label>
        </div>
       </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Coverage Limits Amounts:</label>
          </div>
          <div class="col-md-2" >
                                                   <ng-select [items]="preClaim" bindLabel="name" placeholder="Select per claim" bindValue="id"
                                                   formControlName="cmd_001616">
                                               </ng-select>
          </div>
          <div class="col-md-2" >
            <ng-select [items]="aggregates" bindLabel="name" placeholder="Select  aggregates" bindValue="id"
            formControlName="cmd_001617">
        </ng-select>
          </div>
          <div class="col-md-3">
            <label >Number of Claims:</label>
          </div>
          <div class="col-md-3">
            <input  formControlName="cmd_001618" class="form-control " id="cmd_001618" name="cmd_001618" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Effective Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="cmd_001619" (blur)="clearInputIfInvalid('cmd_001619')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d100="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d100.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001619').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001619').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001619').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001619').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001619').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-1">
            <label >Expiration Date:</label>
          </div>
          <div class="col-md-3">
         <div class="input-group">
          <input  formControlName="cmd_001620"  (blur)="clearInputIfInvalid('cmd_001620')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
              #d101="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d101.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="affiliatedDermatologyForm.get('cmd_001620').invalid ">
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001620').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001620').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001620').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001620').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>
        </div>
          <div class="col-md-1">
            <label >Retro Date:</label>
          </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="cmd_001621" (blur)="clearInputIfInvalid('cmd_001621')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d102="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001621').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001621').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001621').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001621').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001621').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
        </div>
      <br><br>
      <hr class="pdf-line">
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br>
        <div class="text-center" >
          <label ><b>PROFESSIONAL LIABILITY DATA (CONT.)</b></label>
        </div>
      <br>
        <div  class="ms-4">
          <label >If you answer “Yes” to any of these questions, please give a full explanation on a separate sheet (and supply case/claim documentation) and attach it to this application. </label>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">

          </div>
          <div class="col-3">
            <label class="header"><b>YES</b></label>
            <label class="header"><b>NO</b></label>
          </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >1.  Have you ever been denied liability insurance, in whole or in part, or has your policy ever been cancelled, involuntary restricted, denied renewal for <u>any</u> reason.</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001622" type="radio" id="cmd_001622" name="cmd_001622" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001622" type="radio" id="cmd_001622" name="cmd_001622" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >2.  Does your malpractice coverage exclude you from performing <u>any</u> specific procedure(s)or practicing portions of your specialty for which you are requesting privileges?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001623" type="radio" id="cmd_001623" name="cmd_001623" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001623" type="radio" id="cmd_001623" name="cmd_001623" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >3.  Have you ever practiced without professional liability insurance?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001624" type="radio" id="cmd_001624" name="cmd_001624" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001624" type="radio" id="cmd_001624" name="cmd_001624" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >4.  Have <u>any</u> professional liability claims, suits or complaints ever been filed against you?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001625" type="radio" id="cmd_001625" name="cmd_001625" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001625" type="radio" id="cmd_001625" name="cmd_001625" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >5. Are <u>any</u> of these liability claims, suits or complaints presently pending?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001626" type="radio" id="cmd_001626" name="cmd_001626" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001626" type="radio" id="cmd_001626" name="cmd_001626" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >6. Have <u>any</u> judgments or settlements been made against you in professional liability cases?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001627" type="radio" id="cmd_001627" name="cmd_001627" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001627" type="radio" id="cmd_001627" name="cmd_001627" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >7. Have <u>any</u> judgments or settlements been made against you in professional liability cases?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001628" type="radio" id="cmd_001628" name="cmd_001628" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001628" type="radio" id="cmd_001628" name="cmd_001628" value="0"></label>
              </div>
        </div>
      <br>
        <div class="text-center" >
          <label ><b>PROFESSIONAL ASSOCIATIONS</b></label>
        </div>
      <br>
        <div class="ms-4">
          <label >If you answer “Yes” to <u>any</u> of these questions, please give a full explanation on a separate sheet and attach it to this application. </label>
        </div>
        <div class="row mb-3" >
          <div class="col-md-9">

          </div>
          <div class="col-3">
            <label class="header"><b>YES</b></label>
            <label class="header"><b>NO</b></label>
          </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >1.  Have you ever been denied membership or renewal to any professional association?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001629" type="radio" id="cmd_001629" name="cmd_001629" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001629" type="radio" id="cmd_001629" name="cmd_001629" value="0"></label>
              </div>
        </div>
        <div class="row mb-3 ps-4">
              <div class="col-md-9">
                <label >2. Have you ever been subject to disciplinary proceedings in any professional organization?</label>
              </div>
              <div class="col-md-3">
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001630" type="radio" id="cmd_001630" name="cmd_001630" value="1"></label>
                <label class="checkbox-inline me-2" >
                  <input  formControlName="cmd_001630" type="radio" id="cmd_001630" name="cmd_001630" value="0"></label>
              </div>
        </div>
      <br>
        <div class="text-center" >
          <label >Name of all professional organizations you are <u>currently</u> associated with (with affiliation dates): </label>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001631" class="form-control  " id="cmd_001631" name="cmd_001631" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001632" class="form-control  " id="cmd_001632" name="cmd_001632" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001633" class="form-control  " id="cmd_001633" name="cmd_001633" type="text">
          </div>
        </div>
      <br>
        <div class="text-center" >
          <label >Name of all professional organizations you were associated with in the past (with affiliation dates):  </label>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001634" class="form-control  " id="cmd_001634" name="cmd_001634" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001635" class="form-control  " id="cmd_001635" name="cmd_001635" type="text">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <input  formControlName="cmd_001636" class="form-control  " id="cmd_001636" name="cmd_001636" type="text">
          </div>
        </div>
      <br>
        <div class="text-center" >
          <label ><b>MILITARY SERVICE INFORMATION</b></label>
        </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-1">
            <label >Branch:</label>
          </div>
          <div class="col-md-2">
            <input  formControlName="1141" class="form-control " id="1141" name="1141" type="text" placeholder="Military Branch">
          </div>
          <div class="col-md-2">
            <label >Dates of Service:</label>
          </div>
          <div class="col-md-3">
           <div class="input-group">
            <input  formControlName="1144" (blur)="clearInputIfInvalid('1144')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d103="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1144').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1144').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1144').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1144').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1144').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
          <div class="col-md-3">
          <div class="input-group">
            <input  formControlName="1145" (blur)="clearInputIfInvalid('1145')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d104="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d104.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('1145').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1145').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1145').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1145').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('1145').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
        </div>
       </div>
        <div class="row mb-3">
          <div class="col-md-2">
            <label >Discharge Status:</label>
          </div>
          <div class="col-md-10">
            <input  formControlName="4152" class="form-control " id="4152" name="4152" type="text" placeholder="Military Branch Status">
          </div>
         </div>
       <br><br>
      <hr class="pdf-line">
       <br><br><br><br><br><br>
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br><br><br><br><br><br><br><br>
        <div class="text-center" >
          <h5 ><b>AFFIRMATION OF INFORMATION</b></h5>
        </div>
      <div class="inner">
          <label >The undersigned hereby affirms as follows: that he/she is the applicant named herein; that he/she has read the foregoing application and knows the contents thereof; that the same is complete, true and accurate to his/her own knowledge and belief.</label>
      </div>
      <div class="inner">
        <label >
      By submitting my application, I agree to adhere to the acceptable conduct, as outlined by any/all Alliance Healthcare Interventional Partners’ policies and procedures and standards, and to abide by all requirements of behavior and civility therein. I fully understand and agree that I have the overall and absolute burden to produce, verify and clarify any/all information and questions related to
      any statement and information related to my application, history and documentation as required and requested during and after my credentialing process.</label>
      </div>
       <br><br><br><br>
              <div class="row mb-3" >
                <div class="col-md-3">
                   <label >Signature:</label>
                  </div>
                  <div class="col-md-3">
                      <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenTwo()">
                      <img  *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo"  alt="Credential my Doc" (click)="esignOpenTwo()">
                      <input  formControlName="cmd_001639" type="hidden" name="cmd_001639" id="cmd_001639">
                  </div>
                <div class="col-md-2">
                   <label >Date</label>
                  </div>
                  <div class="col-md-4">
                  <div class="input-group">
                    <input  formControlName="cmd_001640" (blur)="clearInputIfInvalid('cmd_001640')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                        #d105="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d105.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="affiliatedDermatologyForm.get('cmd_001640').invalid ">
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001640').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)
                  </small>
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001640').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001640').errors?.invalidDateyear">
                    year is out of range(1900-2099)                      </small>
                    <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001640').errors?.invalidDateRange">
                      date is out of range
                      </small>
                  </div>
                </div>
              </div>
      <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><hr class="pdf-line">
        <div class="text-center">
          <img src="../../../../assets/images/affiliated_dermatology.png" width="210" height="110" alt="Credential my Doc">
        </div>
      <br>
        <div class="text-center" >
          <label ><b>AUTHORIZATION FOR RELEASE OF INFORMATION </b></label>
        </div>
      <br>
      <div class="inner">
        <label >
      I acknowledge that I have received/had an opportunity to read the policies of Affiliated Dermatology (AFFDERM). I have been advised that the AFFDERM policies are availabel for my review in the office of the AFFDERM Administrator, and that I am familiar with the principles and standards of the appropriate accreditation organizations and the applicable sections of the Arizona codes pertaining to rendering healthcare services, and the principles, standards and ethics of the National, State and local professional associations that apply to and govern my specialty and/or profession. I agree to be bound by the terms of the aforementioned if I am granted affiliation with AFFDERM, and I further agree to abide by such policies as may be from time-to-time enacted. I further agree to be bound by the terms of policies, even if I am not granted affiliation, in all matters relating to the consideration of my application to AFFDERM. Further, I agree to maintain an ethical practice, to provide for continuous care of my patients, to refrain from fee splitting or other inducements relating to patient referral, to refrain from delegating the responsibility for diagnosis of care of patients to a practitioner who is not qualified to undertake this responsibility and who is not adequately supervised, to seek consultation whenever necessary and to refrain from providing "ghost" surgical or medical services. </label>
      </div>
      <div class="inner">
        <label >
      I have not requested affiliation for any practice for which I am not qualified. Furthermore, I realize that certification by a board does not necessarily qualify me to perform certain patient care. However, I believe that I am qualified to perform all patient care for which I have requested affiliation. I
      shall not attend patients unless able to do so with skill and safety and shall not exceed my professional competence unless an emergency exists and no
      better resources are availabel. </label>
      </div>
      <div class="inner">
        <label >
      I understand and agree that I, as a AFFDERM applicant and beyond, have the burden of producing adequate information for proper evaluation of my professional competence, character, ethics and any other qualifications and for resolving any doubts about such qualifications. I fully understand that
      any significant misstatements in, or omissions from, this application constitute cause for denial of affiliation or cause for summary dismissal from AFFDERM. I hereby agree that if an adverse ruling is made with respect to my affiliation now or in the future, I will exhaust the administrative remedies
      afforded by AFFDERM before resorting to legal or other actions. All information submitted by me in this application and its enclosures is true to the
      best of my knowledge and belief.</label>
      </div>
      <div class="inner">
        <label >
      I hereby further release AFFDERM, and its employees and contacted individuals, from any claim and/or outcome as a result of any/all disclosures and/or investigations related to any/all my information. I further authorize and consent to the release, exchange and disclosure of information by AFFDERM, and its employees and contracted individuals, to any/all hospitals, medical associations, government agencies, insurance plans and any other persons or entity regarding any information AFFDERM may have concerning me, and I hereby release AFFDERM and its staff from any/all liability for so doing.</label>
      </div>
      <div class="inner">
        <label >
      By applying for affiliation with AFFDERM, I hereby signify my willingness to appear for interviews in regard to my application and information. I authorize AFFDERM, and its employees and representatives, to consult and request any/all information with administrators and members of any/all medical staffs of any hospital, agencies, education/training institutions, and any other health care facilities or institutions with which I have been associated and with any others, including past and present malpractice insurance carriers, who may have information bearing on my professional competence, character and ethical qualifications. I further authorize any/all contacted individuals and parties to disclose the requested information regarding my background (including any/all of my past/present actions and claims history). </label>
      </div>
      <div class="inner">
        <label >
      I hereby further consent to the inspection by AFFDERM, its employees and its representatives, of all records, and documents, including hospital medical records, which may be material to an evaluation of my professional qualifications and competence to carry out the requested patient care, as well as,my moral and ethical qualifications for affiliation.</label>
      </div>
      <div class="inner">
        <label >
      I hereby signify my willingness to document, upon request, the past and current status of my mental and physical health including submission to laboratory testing and mental and physical examination by laboratories and physicians designated by the requesting body, with waiver of admissibility of results. </label>
      </div>
      <div class="inner">
        <label >
      I hereby release from liability all representatives of AFFDERM, and its employees, for their acts performed in connection with researching and evaluating my application and my credentials and qualifications, and I hereby release from any liability any and all individuals and organizations who
      provide information to AFFDERM, or its employees, concerning my professional competence, ethics, character and any other information and qualification for processing, credentialing and affiliation determination, and I hereby consent to the release of such information by my signature below. </label>
      </div>
      <div class="inner">
        <label >
      I hereby affirm as follows: that I am the applicant named herein; that I have read the foregoing Authorization and know the contents thereof. I accept the stipulations and obligations and authorize the releases therein contained. </label>
      </div>
      <br>
        <div class="row mb-3">
          <div class="col-md-3">
             <label >Print Name and Degree:</label>
          </div>
          <div class="col-md-3" >
              <input  formControlName="cmd_001642" class="form-control  " id="cmd_001642" name="cmd_001642" type="text">
          </div>
        <div class="col-md-2">
                        <ng-select [items]="credentials" bindLabel="name" placeholder="Select Degree" bindValue="id"
                        formControlName="cmd_001643">
                    </ng-select>
            </div>
            <div class="col-md-1">
             <label >Date:</label>
          </div>
          <div class="col-md-2" >
          <div class="input-group">
            <input  formControlName="cmd_001644" (blur)="clearInputIfInvalid('cmd_001644')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                #d106="ngbDatepicker" container="body">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d106.toggle()" type="button"><i
                        class="fas fa-calendar-alt"></i>
                </button>
            </div>
        </div>
        <div  *ngIf="affiliatedDermatologyForm.get('cmd_001644').invalid ">
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001644').errors?.invalidDateFormat">
            Only Date Format is allowed(MM/DD/YYYY)
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001644').errors?.invalidMonth">
          Month is out of range
          </small>
          <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001644').errors?.invalidDateyear">
            year is out of range(1900-2099)                      </small>
            <small class="text-danger" *ngIf="affiliatedDermatologyForm.get('cmd_001644').errors?.invalidDateRange">
              date is out of range
              </small>
          </div>
            </div>
        </div>
      <br><br>
              <div class="row mb-3" >
                <div class="col-md-3">
                   <label >Signature of Applicant:</label>
                  </div>
                  <div class="col-md-4">
                  <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc" (click)="esignOpenThree()">
                  <img  *ngIf="showThree" class="img eSignImageShow" [src]="esignThree"  alt="Credential my Doc" (click)="esignOpenThree()">
                  <input  formControlName="cmd_001641" type="hidden" name="cmd_001641" id="cmd_001641">
                    </div>
              </div>
                        </form>
                            </div>
                            <div class="modal-footer mt-4">
                              <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
                              (click)="saveForm('approve')">Approve</button>
                              <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-end"
                              (click)="saveForm('submit')">Submit</button>
                              <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
                              (click)="saveForm('save')">Save</button>
                              <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
                            </div>


