import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { EsignComponent } from '../esign/esign.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
// import { Console } from 'console';

@Component({
    selector: 'ctg-cali-phy',
    templateUrl: './cali-phy.component.html',
    styleUrls: ['./cali-phy.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class CaliPhyComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public caliPhy: FormGroup;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  cName:any;
  eName:any;
  ImgOne: any;
  imgOneSet: any;
  imgOneSetting: any;
  esignOne: any;
  esignTwo:any;
  esignThree:any
  finalImgPath: any = [];
  finalImgPathTwo: any = [];
  finalImgPathThree: any = [];
  public customerId: any;
  finalImg: any;
  finalImgTwo: any ;
  finalImgThree: any ;
  firstName: any;
  lastName: any;
  finalName: any;
  states: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  race: Array<object> = [];
  specialty: Array<object> = [];
  degree: Array<object> = [];
  county: Array<object> = [];
  isSubmit: boolean = false;
  public preClaim: any = [];
  public aggregates: any = [];
  gender: Array<object> = [];
  staffStatus: Array<object> = [];
  public preAffiliation: boolean = false;
  public proLiabilities: boolean = false;
  public ynchange: any = false;
  public dyit: any;
  public iyplt: any;
  public dypie: any;
  public dyuap: any;
  public doyraso: any;
  public dyeaahs: any;

  constructor(
    private fb: FormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildForm();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getGenderData();
    this.getCountyData();
    this.getStatesData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getStaffStatusData();
    this.getRaceData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_943') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_355') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_3551') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.caliPhy.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    console.log(filePath);
    if (fieldId == 'cmd_943') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_355') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
         'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
   }
   if (fieldId == 'cmd_3551') {
    this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
       'data:image/jpg;base64,' + filePath
    );
    this.showThree = true;
 }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 31,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  buildForm() {
    this.caliPhy = this.fb.group({
      9: ['', [Validators.required]],
      8: ['', [Validators.required]],
      809: [''],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      708: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      759: ['', [Validators.required]],
      31: ['', [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      292: ['', [Validators.required]],
      32: ['', [Validators.required]],
      30: [null, [Validators.required]],
      741: [null, [Validators.required]],
      277: [null, [Validators.required]],
      1535: [null, [Validators.required]],
      1536: [null, [Validators.required]],
      2207: [null, [Validators.required]],
      333: ['', [Validators.required]],
      334: ['', [Validators.required]],
      335: ['', [Validators.required]],
      293: ['', [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295: [null, [Validators.required,this.dateFormatValidator]],
      309: ['', [Validators.required]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      1634: ['', [Validators.required]],
      514: ['', [Validators.required]],
      515: [null, [Validators.required,this.dateFormatValidator]],
      516: [null, [Validators.required,this.dateFormatValidator]],
      325: ['', [Validators.required]],
      812: ['', [Validators.required]],
      359: ['', [Validators.required]],
      360: ['', [Validators.required]],
      361: ['', [Validators.required]],
      362: ['', [Validators.required]],
      365: ['', [Validators.required]],
      364: [null, [Validators.required]],
      368: [null, [Validators.required]],
      369: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      371: ['', [Validators.required]],
      372: ['', [Validators.required]],
      373: ['', [Validators.required]],
      374: ['', [Validators.required]],
      375: ['', [Validators.required]],
      18 : [''],
      19 : [null],
      20 : [null],
      21 : [''],
      378: ['', [Validators.required]],
      377: [null, [Validators.required]],
      380: [null, [Validators.required]],
      381: ['', [Validators.required]],
      383: ['', [Validators.required]],
      384: ['', [Validators.required]],
      385: ['', [Validators.required]],
      386: ['', [Validators.required]],
      387: ['', [Validators.required]],
      390: ['', [Validators.required]],
      389: [null, [Validators.required]],
      393: [null, [Validators.required]],
      394: ['', [Validators.required]],
      396: ['', [Validators.required]],
      503: ['', [Validators.required]],
      504: ['', [Validators.required]],
      505: ['', [Validators.required]],
      506: ['', [Validators.required]],
      509: ['', [Validators.required]],
      512: [null, [Validators.required]],
      510: [null,[Validators.required,this.dateFormatValidator]],
      1238: [null, [Validators.required,this.dateFormatValidator]],
      479: ['',[Validators.required]],
      483: ['',[Validators.required]],
      484: ['',[Validators.required]],
      485: ['',[Validators.required]],
      488: ['',[Validators.required]],
      480: [null,[Validators.required]],
      481: [null,[Validators.required,this.dateFormatValidator]],
      1300: [null,[Validators.required,this.dateFormatValidator]],
      490: [''],
      491: [''],
      492: [''],
      493: [''],
      496: [''],
      500: [null],
      497: [null,[this.dateFormatValidator] ],
      498: [null,[this.dateFormatValidator] ],
      517: ['', [Validators.required]],
      528: ['', [Validators.required]],
      524: [null, [Validators.required,this.dateFormatValidator]],
      525: [null, [Validators.required,this.dateFormatValidator]],
      518: ['', [Validators.required]],
      519: ['', [Validators.required]],
      520: ['', [Validators.required]],
      523: ['', [Validators.required]],
      522: [null, [Validators.required]],
      527: [null, [Validators.required]],
      2539: ['', [Validators.required]],
      cmd_936: ['', [Validators.required]],
      529: ['', [Validators.required]],
      540: ['', [Validators.required]],
      537: [null, [Validators.required,this.dateFormatValidator]],
      538: [null, [Validators.required,this.dateFormatValidator]],
      531: ['', [Validators.required]],
      1498: ['', [Validators.required]],
      533: ['', [Validators.required]],
      536: ['', [Validators.required]],
      535: [null, [Validators.required]],
      530: [null, [Validators.required]],
      2540: ['', [Validators.required]],
      cmd_937: ['', [Validators.required]],
      542: ['', [Validators.required]],
      553: ['', [Validators.required]],
      550: [null, [Validators.required,this.dateFormatValidator]],
      551: [null, [Validators.required,this.dateFormatValidator]],
      544: ['', [Validators.required]],
      545: ['', [Validators.required]],
      546: ['', [Validators.required]],
      549: ['', [Validators.required]],
      548: [null, [Validators.required]],
      543: [null, [Validators.required]],
      2542: ['', [Validators.required]],
      cmd_938: ['', [Validators.required]],
      445: ['', [Validators.required]],
      454: [null, [Validators.required,this.dateFormatValidator]],
      455: [null, [Validators.required,this.dateFormatValidator]],
      446: ['', [Validators.required]],
      447: ['', [Validators.required]],
      448: ['', [Validators.required]],
      451: ['', [Validators.required]],
      450: [null, [Validators.required]],
      452: ['', [Validators.required]],
      453: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      889: ['', [Validators.required]],
      1138: ['', [Validators.required]],
      456: [''],
      465: [null,[this.dateFormatValidator] ],
      466: [null,[this.dateFormatValidator] ],
      457: [''],
      458: [''],
      459: [''],
      462: [''],
      461: [null],
      463: [''],
      464: ['', [Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]],
      891: [''],
      1831: [''],
      cmd_402: ['', [Validators.required]],
      cmd_403: ['', [Validators.required]],
      cmd_901: ['', [Validators.required]],
      cmd_902: ['', [Validators.required]],
      cmd_903: ['', [Validators.required]],
      cmd_939: [''],
      cmd_904: ['', [Validators.required]],
      cmd_940: [''],
      cmd_905: [''],
      cmd_941: [''],
      cmd_906: [''],
      cmd_942: [''],
      cmd_907: ['', [Validators.required]],
      cmd_501: ['', [Validators.required]],
      cmd_502: ['', [Validators.required]],
      cmd_503: ['', [Validators.required]],
      cmd_504: [''],
      cmd_505: [''],
      cmd_506: [''],
      cmd_507: [''],
      cmd_508: [''],
      cmd_509: [''],
      cmd_510: [''],
      cmd_511: [''],
      cmd_512: [''],
      cmd_908: [''],
      cmd_513: [''],
      cmd_514: [''],
      cmd_515: [''],
      cmd_516: [''],
      cmd_517: ['', [Validators.required]],
      cmd_518: ['', [Validators.required]],
      cmd_519: ['', [Validators.required]],
      cmd_520: ['', [Validators.required]],
      cmd_521: ['', [Validators.required]],
      cmd_522: ['', [Validators.required]],
      cmd_523: ['', [Validators.required]],
      cmd_524: ['', [Validators.required]],
      cmd_525: [''],
      cmd_526: [''],
      cmd_527: [''],
      cmd_528: [''],
      cmd_529: [''],
      cmd_530: [''],
      cmd_531: [''],
      cmd_532: [''],
      cmd_533: [''],
      cmd_534: [''],
      cmd_535: [''],
      cmd_536: [''],
      cmd_537: [''],
      cmd_538: [''],
      cmd_539: [''],
      418: ['', [Validators.required]],
      419: ['', [Validators.required]],
      420: ['', [Validators.required]],
      421: ['', [Validators.required]],
      423: [null, [Validators.required]],
      424: ['', [Validators.required]],
      425: ['', [Validators.required]],
      894: [null, [Validators.required]],
      426: [null, [Validators.required,this.dateFormatValidator]],
      427: [''],
      428: [''],
      429: [''],
      430: [''],
      432: [null],
      433: [''],
      434: [null],
      895: [null],
      435: [null,[this.dateFormatValidator] ],
      436: [''],
      437: [''],
      cmd_960: ['', [Validators.required]],
      438: [''],
      439: [''],
      441: [null],
      442: [''],
      443: [''],
      896: [null],
      444: [null,[this.dateFormatValidator] ],
      cmd_1020: [''],
      cmd_1000: [''],
      cmd_1001: [''],
      cmd_1002: [''],
      cmd_1003: [''],
      cmd_1004: [null],
      cmd_1005: [''],
      cmd_1006: [null,[this.dateFormatValidator]],
      cmd_1007: [null,[this.dateFormatValidator]],
      cmd_1008: [''],
      cmd_1009: [''],
      cmd_1010: [''],
      cmd_1011: [''],
      cmd_1012: [''],
      cmd_1013: [null],
      cmd_1014: [''],
      cmd_1015: [null,[this.dateFormatValidator]],
      cmd_1016: [null,[this.dateFormatValidator]],
      cmd_1017: [''],
      cmd_1018: [''],
      345: ['', [Validators.required]],
      346: ['', [Validators.required]],
      348: [null, [Validators.required,this.dateFormatValidator]],
      901: ['', [Validators.required]],
      902: ['', [Validators.required]],
      903: ['', [Validators.required]],
      906: ['', [Validators.required]],
      905: [null, [Validators.required]],
      350: [null, [Validators.required]],
      351: [null, [Validators.required]],
      349: [null, [Validators.required,this.dateFormatValidator]],
      cmd_1021: [''],
      cmd_601: [''],
      cmd_602: [''],
      cmd_603: [''],
      cmd_604: [''],
      cmd_605: [null],
      cmd_606: [''],
      cmd_607: [''],
      cmd_608: [null,[this.dateFormatValidator]],
      cmd_609: [null,[this.dateFormatValidator]],
      cmd_610: [''],
      cmd_611: [''],
      cmd_612: [''],
      cmd_613: [''],
      cmd_614: [null],
      cmd_615: [''],
      cmd_616: [''],
      cmd_617: [null,[this.dateFormatValidator]],
      cmd_618: [null,[this.dateFormatValidator]],
      cmd_540: [''],
      cmd_541: ['', [Validators.required]],
      cmd_542: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      cmd_543: ['', [Validators.required]],
      2953: ['', [Validators.required]],
      2960: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      2963: ['', [Validators.required]],
      2976: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      2979: ['', [Validators.required]],
      2986: [
        '',
        [Validators.required, Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]
      ],
      cmd_NA_01:[null],
      cmd_909: ['', [Validators.required]],
      cmd_910: ['', [Validators.required]],
      cmd_911: ['', [Validators.required]],
      cmd_420: [''],
      cmd_421: [''],
      cmd_422: [''],
      cmd_912: ['', [Validators.required]],
      cmd_913: ['', [Validators.required]],
      cmd_914: ['', [Validators.required]],
      cmd_915: ['', [Validators.required]],
      cmd_916: ['', [Validators.required]],
      cmd_917: ['', [Validators.required]],
      cmd_918: ['', [Validators.required]],
      cmd_919: ['', [Validators.required]],
      cmd_920: ['', [Validators.required]],
      cmd_921: ['', [Validators.required]],
      cmd_922: ['', [Validators.required]],
      cmd_923: ['', [Validators.required]],
      cmd_924: ['', [Validators.required]],
      cmd_925: ['', [Validators.required]],
      cmd_926: ['', [Validators.required]],
      cmd_927: ['', [Validators.required]],
      cmd_928: ['', [Validators.required]],
      cmd_35: [''],
      cmd_929: ['', [Validators.required]],
      cmd_930: ['', [Validators.required]],
      cmd_931: ['', [Validators.required]],
      cmd_932: ['', [Validators.required]],
      cmd_933: ['', [Validators.required]],
      1141: [''],
      1145: [null,this.dateFormatValidator],
      cmd_934: [''],
      cmd_935: [null, [Validators.required,this.dateFormatValidator]],
      cmd_943: ['', [Validators.required]],
      cmd_321: [''],
      cmd_43554: ['', [Validators.required]],
      cmd_10354:[null,[Validators.required,this.dateFormatValidator]],
      cmd_355:['',Validators.required],
      cmd_435541: ['', [Validators.required]],
      cmd_103541:[null,[Validators.required,this.dateFormatValidator]],
      cmd_3551:['',Validators.required],
      cmd_35512:[""], 
      cmd_35513:[""],
      cmd_35514:[""],
      cmd_35515:[""],
      
    });
  }

  handleYesNoChanges(cName,eName?){
    if (cName == "cmd_928") {
    let x=['cmd_35']
    x.forEach(element => {
    if((this.caliPhy.controls[element].value=='' || this.caliPhy.controls[element].value==null) && (this.caliPhy.controls[cName].value==1 || this.caliPhy.controls[cName].value==null)){
    this.setErrorControl(element);
    }
    else{
    this.removeErrorControl(element);
    }
    });
    }

    if (cName == "cmd_933") {
      let x=['1141','1145']
      x.forEach(element => {
      if((this.caliPhy.controls[element].value=='' || this.caliPhy.controls[element].value==null) && (this.caliPhy.controls[cName].value==1 || this.caliPhy.controls[cName].value==null)){
      this.setErrorControl(element);
      }
      else{
      this.removeErrorControl(element);
      }
      });
      }

      if (cName == "cmd_903") {
        let x=['cmd_939']
        x.forEach(element => {
        if((this.caliPhy.controls[element].value=='' || this.caliPhy.controls[element].value==null) && (this.caliPhy.controls[cName].value==1 || this.caliPhy.controls[cName].value==null)){
        this.setErrorControl(element);
        }
        else{
        this.removeErrorControl(element);
        }
        });
        }

        if (cName == "cmd_904") {
          let x=['cmd_940']
          x.forEach(element => {
          if((this.caliPhy.controls[element].value=='' || this.caliPhy.controls[element].value==null) && (this.caliPhy.controls[cName].value==1 || this.caliPhy.controls[cName].value==null)){
          this.setErrorControl(element);
          }
          else{
          this.removeErrorControl(element);
          }
          });
          }
          

          // if (cName == "cmd_100043") {
          //   let x=['1141','1145']
      
            
        
          //   x.forEach(element => {
          //     const ctrl = this.caliPhy.controls[element];
          //     if((this.caliPhy.controls[element].value=='' || this.caliPhy.controls[element].value==null) && (this.caliPhy.controls[cName].value==1 || this.caliPhy.controls[cName].value==null)){
          //       this.setErrorControl(element);
          //     }
      
          //     else{
          //       this.removeErrorControl(element);
          //     }
          //   });
          // }
      
    

  }



  handleNAchange(cName,eName?){
    // console.log(cName);
    // console.log(this.onPointForm.controls[cName].value);
    let result:boolean=false;
    if (cName == "cmd_NA_01") {
    if((this.caliPhy.controls['809'].value=='' || this.caliPhy.controls['809'].value==null) && !this.caliPhy.controls[cName].value){
    this.setErrorControl('809');
    result = true
    }
    else{
    this.removeErrorControl('809');
    }
    }
    }

    setErrorControl(name){
      this.caliPhy.controls[name].setErrors({ errors: true });
      }
      removeErrorControl(name){
      this.caliPhy.controls[name].setErrors(null)
      }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  saveForm(type) {
    this.isSubmit = true;
  this.handleYesNoChanges(this.cName);
  this.handleYesNoChanges('cmd_933');
  this.handleNAchange('cmd_NA_01');

    if (type == 'submit') {
      if (this.caliPhy.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.caliPhy.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.caliPhy.value,
        formId: 31
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_943: this.finalImgPath
      };
      this.caliPhy.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  public esignOpenTwo()
  {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPathTwo = data.finalFilePath;
           let temp = {
           cmd_355: this.finalImgPathTwo
           };
        this.caliPhy.patchValue(temp);
        this.finalImgPathTwo = data.finalFilePath;
        this.finalImgTwo = data.base64Img;
        this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImgTwo
           );
        this.showTwo = true;
    });
   }
   public esignOpenThree()
   {
     const modalRef = this.modalService.open(EsignComponent, this.modalOption);
     modalRef.result.then(result => { });
     modalRef.componentInstance.base64Img.subscribe(data => {
         modalRef.close();
         this.finalImgPathThree = data.finalFilePath;
            let temp = {
            cmd_3551: this.finalImgPathThree
            };
         this.caliPhy.patchValue(temp);
         this.finalImgPathThree = data.finalFilePath;
         this.finalImgThree = data.base64Img;
         this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
           'data:image/jpg;base64,' + this.finalImgThree
            );
         this.showThree = true;
     });
    }

  previousAffiliations(event) {
    this.preAffiliation = event.target.checked;
    if (this.preAffiliation) {
      this.caliPhy.controls['cmd_1000'].setErrors(null);
      this.caliPhy.controls['cmd_1001'].setErrors(null);
      this.caliPhy.controls['cmd_1002'].setErrors(null);
      this.caliPhy.controls['cmd_1003'].setErrors(null);
      this.caliPhy.controls['cmd_1004'].setErrors(null);
      this.caliPhy.controls['cmd_1005'].setErrors(null);
      this.caliPhy.controls['cmd_1006'].setErrors(null);
      this.caliPhy.controls['cmd_1007'].setErrors(null);
      this.caliPhy.controls['cmd_1008'].setErrors(null);
      this.caliPhy.controls['cmd_1009'].setErrors(null);
      this.caliPhy.controls['cmd_1010'].setErrors(null);
      this.caliPhy.controls['cmd_1011'].setErrors(null);
      this.caliPhy.controls['cmd_1012'].setErrors(null);
      this.caliPhy.controls['cmd_1013'].setErrors(null);
      this.caliPhy.controls['cmd_1014'].setErrors(null);
      this.caliPhy.controls['cmd_1015'].setErrors(null);
      this.caliPhy.controls['cmd_1016'].setErrors(null);
      this.caliPhy.controls['cmd_1017'].setErrors(null);
      this.caliPhy.controls['cmd_1018'].setErrors(null);
    }
  }

  proLiability(event) {
    this.proLiabilities = event.target.checked;
    if (this.proLiabilities) {
      this.caliPhy.controls['cmd_601'].setErrors(null);
      this.caliPhy.controls['cmd_602'].setErrors(null);
      this.caliPhy.controls['cmd_603'].setErrors(null);
      this.caliPhy.controls['cmd_604'].setErrors(null);
      this.caliPhy.controls['cmd_605'].setErrors(null);
      this.caliPhy.controls['cmd_606'].setErrors(null);
      this.caliPhy.controls['cmd_607'].setErrors(null);
      this.caliPhy.controls['cmd_608'].setErrors(null);
      this.caliPhy.controls['cmd_609'].setErrors(null);
      this.caliPhy.controls['cmd_610'].setErrors(null);
      this.caliPhy.controls['cmd_611'].setErrors(null);
      this.caliPhy.controls['cmd_612'].setErrors(null);
      this.caliPhy.controls['cmd_613'].setErrors(null);
      this.caliPhy.controls['cmd_614'].setErrors(null);
      this.caliPhy.controls['cmd_615'].setErrors(null);
      this.caliPhy.controls['cmd_616'].setErrors(null);
      this.caliPhy.controls['cmd_617'].setErrors(null);
      this.caliPhy.controls['cmd_618'].setErrors(null);
    }
  }

  yesnochange(event) {
    this.ynchange = event.target.value;
    if (this.ynchange == 0) {
      this.caliPhy.controls['1141'].setErrors(null);
      this.caliPhy.controls['1145'].setErrors(null);
    }
  }

  dyits(event) {
    this.dyit = event.target.value;
    if (this.dyit == 0) {
      this.caliPhy.controls['cmd_939'].setErrors(null);
    }
  }

  iypltc(event) {
    this.iyplt = event.target.value;
    if (this.iyplt == 0) {
      this.caliPhy.controls['cmd_940'].setErrors(null);
    }
  }

  dypied(event) {
    this.dypie = event.target.value;
    if (this.dypie == 0) {
      this.caliPhy.controls['cmd_941'].setErrors(null);
    }
  }

  dyuapm(event) {
    this.dyuap = event.target.value;
    if (this.dyuap == 0) {
      this.caliPhy.controls['cmd_942'].setErrors(null);
    }
  }

  doyras(event) {
    this.doyraso = event.target.value;
    if (this.doyraso == 0) {
      this.caliPhy.controls['cmd_35'].setErrors(null);
    }
  }

  dyeaah(event) {
    this.dyeaahs = event.target.value;
    if (this.dyeaahs == 0) {
      this.caliPhy.controls['cmd_501'].setErrors(null);
      this.caliPhy.controls['cmd_502'].setErrors(null);
      this.caliPhy.controls['cmd_503'].setErrors(null);
    }
  }

  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_934: this.finalName,
      cmd_43554:this.finalName,
      cmd_435541:this.finalName
    };
    this.caliPhy.patchValue(temp);
  }

  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_934: this.finalName,
      cmd_43554:this.finalName,
      cmd_435541:this.finalName
    };
    this.caliPhy.patchValue(temp);
  }
  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
    data => {
    if (data['success']) {
    this.county = data.data.map(i => ({
    ...i,
    id: i.id.toString()
    }));
    } else {
    this.notiService.showError(data.error, '', 3000);
    }
    //this.spinner.hide();
    },
    error => {
    //this.spinner.hide();
    }
    );
    }
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.caliPhy.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
      if(handleYesNoValue ){
        if(handleYesNoValue == ("cmd_933")){
          this.handleYesNoChanges(handleYesNoValue);
        }
      }
  
     
      
    }
    dateFormatValidator(control: FormControl): ValidationErrors | null {
      const dateString = control.value;
      const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    
      if (dateString && !dateString.match(dateFormat)) {
        return { 'invalidDateFormat': true };
      }
    
  
  
      if (dateString) {
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0]);
        const day = parseInt(dateParts[1]);
        const year = parseInt(dateParts[2]);
    
        // Check if any of the date components are zero or month is not between 1 and 12
        
    
        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
        
        if (month < 1 || month > 12 || day === 0 || year === 0) {
          return { 'invalidMonth': true };
        }
        if (month === 1 || month > 2) {
          if (day > ListofDays[month - 1]) {
            return { 'invalidDateRange': true };
          }
        } else if (month === 2) {
          const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
          if (!leapYear && day >= 29) {
            return { 'invalidDateRange': true };
          }
           else if (leapYear && day > 29) {
            return { 'invalidDateRange': true };
          }
        }
        if (year < 1900 || year > 2099) {
          return { 'invalidDateyear': true };
        }
        
       
      }
    
      return null;
    }
    clearInputIfInvalidMandi(controlName: string) {
      const control = this.caliPhy.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
  
      }}
}
