<div id="gaunifiedStyle">
  <div class="cotainer-fluid mx-1">
    <div class="header">
      <div class="text-center">
        <h2>
          GEORGIA UNIFORM <u>ALLIED</u> HEALTHCARE <br />PROFESSIONAL
          CREDENTIALING <br />
          APPLICATION FORM
        </h2>
        <br />
        <hr class="borderStyle" />
      </div>
      <p class="p">
        Please contact the Hospital, Health Plan or other Healthcare
        Organization, hereinafter "Healthcare Entity(ies)", to which you are
        applying for instructions on how to proceed. <br />The Healthcare Entity
        may not have adopted this form for use and/or may require a
        pre-application prior to submitting this form.
      </p>
      <div class="paddingParagraph">
        <div class="centered borderBox">
          <p class="p">
            This Application has been designed and organized into two main
            parts: Part One and Part Two.
          </p>
          <p class="p">
            Part One is standardized for Healthcare Entity(ies), and contains
            identical questions that Healthcare Entities need to ask as a part
            of their credentialing processes. Part One is available on the
            Georgia Uniform Healthcare Practitioner Credentialing Application
            Form (UHPCAF) web site at
            <a href="http://www.georgiacredentialing.org/"
              >www.georgiacredentialing.org.</a
            >
          </p>
          <p class="p">
            Part Two for health plans is standardized and contains additional
            identical questions that health plans need to ask as part of their
            credentialing processes and, is also available at
            <a href="http://www.georgiacredentialing.org/"
              >www.georgiacredentialing.org.</a
            >
          </p>
          <p class="p">
            Part Two for hospitals contains additional, customized or more
            specific questions as part of their credentialing and privileging
            processes.
          </p>
        </div>
        <div class="text-center marginStyle">
          <h4>PREPARED AND ENDORSED BYMEMBERS OF:</h4>
          <p class="text-center size">
            GHA/AN ASSOCIATION OF HOSPITALS AND HEALTH SYSTEMS<br />
            GEORGIA IN-HOUSE COUNSEL ASSOCIATION <br />
            GEORGIA ASSOCIATION MEDICAL STAFF SERVICES <br />
            GEORGIA ASSOCIATION OF HEALTH PLANS<br />
          </p>
        </div>
        <hr class="borderBlackStyle" />
      </div>
    </div>
    <div class="section">
      <form [formGroup]="gaUnifiedForm">
        <div class="">
          <div class="text-center">
            <h2>
              GEORGIA UNIFORM <u>ALLIED</u> HEALTHCARE PROFESSIONAL<br />
              CREDENTIALING APPLICATION FORM
            </h2>
            <br />
            <p class="p">
              <u
                >Prior to completing this Application, please read and observe
                the following:</u
              >
            </p>
          </div>
          <div class="generalInstructionPaddingStyle">
            <div class="centered generalInstructionBoxStyle">
              <h3 class="generalInstructionhStyle">GENERAL INSTRUCTIONS</h3>
              <div class="generalInstructionPaddingSetStyle">
                <ul class="list-styled">
                  <li>Please type or print legibly your responses</li>
                  <li>
                    Please note that modification to the wording or format of
                    this Application will invalidate it.
                  </li>
                  <li>
                    All information requested must be FULLY and TRUTHFULLY
                    provided.
                  </li>
                  <li>
                    Any changes to your responses must be lined through and
                    initialed. Use of any form of correctional fluid or tape is
                    not acceptable.
                  </li>
                  <li>
                    If an entire section does not apply to you, then please
                    check the box provided at the top of the section. If a
                    particular question does not apply to you, then write “N/A”
                    in the answer blank. If there are multiple, repetitive
                    answer blanks in a particular section (as, for example, in
                    the section entitled “Residencies and Fellowships”), it is
                    not necessary to mark “N/A” in each unneeded answer blank.
                  </li>
                  <li>
                    Unless specifically permitted by a particular question,
                    please understand that a reference to “See CV” for an answer
                    is not appropriate.
                  </li>
                  <li>
                    <b
                      >If more space than is provided on this Application is
                      needed in order to answer a question completely, use the
                      attached Explanation Form as necessary. Make as many
                      copies of the Explanation Form as needed to fully answer
                      each question. Include the section and page number of the
                      question being answered as well as your name and Social
                      Security Number on each Explanation Form. Attach all
                      Explanation Forms to this Application.</b
                    >
                  </li>
                  <li>
                    After <b>Part One</b> of the Application has been completed
                    in its entirety but <u>before</u> you sign and date it or
                    fill in the information on page <b>ii</b>,<u
                      >make a copy of the Application to retain in your files
                      and/or computer for future use.</u
                    ><br />In so doing, at the time of a submission to another
                    Healthcare Entity, all you will need to do is to check to
                    ensure that all the information remains complete, current
                    and accurate before completing page <b>ii</b> and signing
                    and forwarding the Application as needed.
                  </li>
                  <li>
                    Any gaps of time greater than thirty (30) days from
                    completion of medical school to the present date must be
                    accounted for before your Application will be considered
                    complete.
                  </li>
                  <li>Please sign and date the Application.</li>
                  <li>
                    Please sign and date Schedule A, Schedule B(as appropriate).
                  </li>
                  <li>
                    Identify the Healthcare Entity to which you are submitting
                    this Application and for what practice area(s) you are
                    applying in the spaces provided on page <b>ii</b>.
                  </li>
                  <li>
                    Mail the Application, Schedules, any Explanation Form(s)
                    prepared in order to answer any question(s) completely, as
                    well as a copy of all applicable enclosures listed on page<b
                      >ii</b
                    >
                    to the Healthcare Entity.
                  </li>
                </ul>
                <p class="p">
                  A current copy of the following documents must be submitted
                  with your Application:
                </p>
                <ul class="list-styled">
                  <li>One recent passport size photograph of yourself</li>
                  <li>State Professional License(s)</li>
                  <li>
                    Federal Narcotics License (DEA Registration) if applicable
                  </li>
                  <li>
                    Curriculum Vitae with complete professional history in
                    chronological order (month & year)
                  </li>
                  <li>
                    Diplomas and/or certificates of completion from professional
                    school
                  </li>
                  <li>
                    Specialty/Subspecialty Certification or letter from
                    certifying body stating your status (if applicable)
                  </li>
                  <li>
                    Declaration Page (Face Sheet) of Professional Liability
                    Policy or Certificate of Insurance
                  </li>
                  <li>
                    Permanent Resident Card or Visa Status (if applicable)
                  </li>
                  <li>
                    Military Discharge Record (Form DD-214) (if applicable)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="centered" class="inputBorderBox">
            <div class="mb-3">
              <label
                >Name of Healthcare Entity to which you are submitting this
                Application:</label
              >
              <input
                class="form-control"
                type="text"
                id="cmd_0171"
                name="cmd_0171"
                formControlName="cmd_0171"
                data-id="healthcare entity for submission"
              />
            </div>
            <hr class="hrStyle" />
            <div class="mb-3">
              <label
                >For what type of relationship (i.e., staff membership, network
                participation, etc.):</label
              >
              <input
                class="form-control"
                type="text"
                id="cmd_172"
                name="cmd_172"
                formControlName="cmd_172"
                data-id="For what type of relationship"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="">
          <div class="text-center">
            <h3>
              GEORGIA UNIFORM <u>ALLIED</u> HEALTHCARE <u>PROFESSIONAL</u
              ><br />CREDENTIALING APPLICATION FORM
            </h3>
            <h3 class="hTwoAlign">***************PART ONE***************</h3>
          </div>
          <div>
            <p class="p">
              <u
                >If more space than is provided on this Application is needed in
                order to answer a question completely, please use the attached
                Explanation Form as necessary.</u
              >
            </p>
          </div>
          <div class="centered" class="identifyBox">
            <h3 class="hThreeAlign">
              I. IDENTIFYING INFORMATION
              <span class="normalParagraphStyle"
                ><i>Please provide the practitioner’s full legal name.</i></span
              >
            </h3>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >Last Name (include suffix; Jr., Sr., III):</label
                    >
                    <input
                      class="form-control"
                      id="9"
                      name="9"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['9'].touched &&
                          gaUnifiedForm.controls['9'].invalid) ||
                        (gaUnifiedForm.controls['9'].invalid && isSubmit)
                      "
                      formControlName="9"
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['9'].touched &&
                        gaUnifiedForm.controls['9'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['9'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="mb-3 selectDrop suffix dropDrownSet">
                    <ng-select
                      [items]="suffix"
                      bindLabel="name"
                      placeholder="Select Suffix"
                      bindValue="id"
                      formControlName="29"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">First</label>
                    <input
                      class="form-control"
                      id="8"
                      name="8"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['8'].touched &&
                          gaUnifiedForm.controls['8'].invalid) ||
                        (gaUnifiedForm.controls['8'].invalid && isSubmit)
                      "
                      formControlName="8"
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['8'].touched &&
                        gaUnifiedForm.controls['8'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['8'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">Middle</label>
                    <input
                      class="form-control"
                      id="809"
                      name="809"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['809'].touched &&
                          gaUnifiedForm.controls['809'].invalid) ||
                        (gaUnifiedForm.controls['809'].invalid && isSubmit)
                      "
                      formControlName="809"
                      type="text"
                      data-id="lname"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['809'].touched &&
                        gaUnifiedForm.controls['809'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['809'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Title (PhD, CRNA, PA, etc.):</label
                  >

                  <div class="mb-3 selectDropTwo degree inputPadding">
                    <ng-select
                      [items]="credential"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['822'].touched &&
                          gaUnifiedForm.controls['822'].invalid) ||
                        (gaUnifiedForm.controls['822'].invalid && isSubmit)
                      "
                      placeholder="Select Credentials"
                      bindValue="id"
                      formControlName="822"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['822'].touched &&
                        gaUnifiedForm.controls['822'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['822'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Is there any other name under which you have been known or
                    have used (e.g. maiden name)?</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_180"
                      name="cmd_180"
                      formControlName="cmd_180"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline">
                    <input
                      type="radio"
                      id="cmd_180"
                      name="cmd_180"
                      formControlName="cmd_180"
                      value="0"
                    />No</label
                  >
                </div>
                <br />
                <div class="mb-3">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding"
                  >
                    <label class="normalParagraphStyle"
                      >Name(s) and Date(s) Used:</label
                    >
                  </div>
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-3 inputPadding"
                  >
                    <input
                      class="form-control"
                      id="278"
                      name="278"
                      placeholder="Prior Name"
                      formControlName="278"
                      type="text"
                      data-id="priorname"
                    />
                  </div>
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <div class="input-group">
                      <input
                        class="form-control  datepicker"
                        placeholder="MM/DD/YYYY"
                        id="280"
                        formControlName="280"
                        name="280"
                        type="text"
                        data-id="priornameeffdate_date"
                        ngbDatepicker
                        #ga01="ngbDatepicker"
                        (blur)="clearInputIfInvalid('280')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga01.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('280').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('280').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('280').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('280').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('280').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                    -
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group "
                        placeholder="MM/DD/YYYY"
                        id="281"
                        formControlName="281"
                        name="281"
                        type="text"
                        data-id="priornametermdate_date"
                        ngbDatepicker
                        #ga02="ngbDatepicker"
                        (blur)="clearInputIfInvalid('281')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga02.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('281').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('281').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('281').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('281').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('281').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding row">
                  <label class="padding-style">Home Street Address:</label>
                  <input
                    class="form-control"
                    id="16"
                    name="16"
                    placeholder="Address1"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['16'].touched &&
                        gaUnifiedForm.controls['16'].invalid) ||
                      (gaUnifiedForm.controls['16'].invalid && isSubmit)
                    "
                    formControlName="16"
                    type="text"
                    data-id="homeaddress"
                  />
                  <div
                    class="formLabel"
                    *ngIf="
                      gaUnifiedForm.controls['16'].touched &&
                      gaUnifiedForm.controls['16'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['16'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control padding-space"
                    id="17"
                    name="17"
                    placeholder="Address2"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['17'].touched &&
                        gaUnifiedForm.controls['17'].invalid) ||
                      (gaUnifiedForm.controls['17'].invalid && isSubmit)
                    "
                    formControlName="17"
                    type="text"
                    data-id="homeaddress"
                  />
                  <div
                    class="formLabel"
                    *ngIf="
                      gaUnifiedForm.controls['17'].touched &&
                      gaUnifiedForm.controls['17'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['17'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    class="form-control"
                    id="18"
                    name="18"
                    placeholder="Home City"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['18'].touched &&
                        gaUnifiedForm.controls['18'].invalid) ||
                      (gaUnifiedForm.controls['18'].invalid && isSubmit)
                    "
                    formControlName="18"
                    type="text"
                    data-id="homecity"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['18'].touched &&
                      gaUnifiedForm.controls['18'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['18'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap selectDrop dropDrownSet degree"
                >
                  <label class="normalParagraphStyle">State:</label>
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['20'].touched &&
                        gaUnifiedForm.controls['20'].invalid) ||
                      (gaUnifiedForm.controls['20'].invalid && isSubmit)
                    "
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="20"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['20'].touched &&
                      gaUnifiedForm.controls['20'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['20'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    class="form-control"
                    id="21"
                    name="21"
                    placeholder="Home Zip"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['21'].touched &&
                        gaUnifiedForm.controls['21'].invalid) ||
                      (gaUnifiedForm.controls['21'].invalid && isSubmit)
                    "
                    formControlName="21"
                    type="text"
                    data-id="lname"
                    minlength="5"
                    maxlength="10"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['21'].touched &&
                      gaUnifiedForm.controls['21'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['21'].errors.required"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaUnifiedForm.controls['21'].errors.required &&
                        gaUnifiedForm.controls['21'].value.length < 5
                      "
                    >
                      Should not be less then 5 digits
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="
                        !gaUnifiedForm.controls['21'].errors.required &&
                        gaUnifiedForm.controls['21'].value.length > 10
                      "
                    >
                      Should not be more then 10 digits
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 no-margin d-flex flex-row align-items-center flex-wrap inputPadding">
                  <label class="normalParagraphStyle"
                    >Home Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    id="708"
                    name="708"
                    placeholder="Home Phone"
                    formControlName="708"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['708'].touched &&
                        gaUnifiedForm.controls['708'].invalid) ||
                      (gaUnifiedForm.controls['708'].invalid && isSubmit)
                    "
                    type="text"
                    data-id="homephone"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['708'].touched &&
                      gaUnifiedForm.controls['708'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['708'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin d-flex flex-row align-items-center flex-wrap inputPadding">
                  <label class="normalParagraphStyle">E-Mail Address:</label>
                  <input
                    class="form-control"
                    id="759"
                    name="759"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['759'].touched &&
                        gaUnifiedForm.controls['759'].invalid) ||
                      (gaUnifiedForm.controls['759'].invalid && isSubmit)
                    "
                    formControlName="759"
                    type="text"
                    data-id="hemail"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['759'].touched &&
                      gaUnifiedForm.controls['759'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['759'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Citizenship (if not USA, provide type and status of visa
                    and enclose a copy):</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-7 inputPadding">
                  <input
                    class="form-control"
                    id="292"
                    name="292"
                    formControlName="292"
                    type="text"
                    data-id="citizenship"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div
                  class="mb-3 no-margin d-flex flex-row align-items-center flex-wrap  inputPadding  input-group"
                >
                  <label class="normalParagraphStyle">Date of Birth:</label>
                  <input
                    class="form-control  datepicker input-group "
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['31'].touched &&
                        gaUnifiedForm.controls['31'].invalid) ||
                      (gaUnifiedForm.controls['31'].invalid && isSubmit)
                    "
                    placeholder="MM/DD/YYYY"
                    formControlName="31"
                    id="31"
                    name="31"
                    type="text"
                    data-id="dob_date"
                    ngbDatepicker
                    #ga03="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('31')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga03.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['31'].invalid&&gaUnifiedForm.controls['31'].touched) && (gaUnifiedForm.controls['31'].value==''|| gaUnifiedForm.controls['31'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('31').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('31').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('31').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('31').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin d-flex flex-row align-items-center flex-wrap inputPadding">
                  <label class="normalParagraphStyle">Place of Birth:</label>
                  <input
                    class="form-control"
                    id="810"
                    placeholder="City of Birth"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['810'].touched &&
                        gaUnifiedForm.controls['810'].invalid) ||
                      (gaUnifiedForm.controls['810'].invalid && isSubmit)
                    "
                    name="810"
                    formControlName="810"
                    type="text"
                    data-id="bcity"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['810'].touched &&
                      gaUnifiedForm.controls['810'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['810'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div
                  class="mb-3 selectDrop d-flex flex-row align-items-center flex-wrap dropDrownSet inputPadding "
                >
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['289'].touched &&
                        gaUnifiedForm.controls['289'].invalid) ||
                      (gaUnifiedForm.controls['289'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="289"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['289'].touched &&
                      gaUnifiedForm.controls['289'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['289'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Gender:</label>
                  <label class="radio-inline" class="radioStyle">
                    <input
                      type="radio"
                      id="30"
                      name="30"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['30'].touched &&
                          gaUnifiedForm.controls['30'].invalid) ||
                        (gaUnifiedForm.controls['30'].invalid && isSubmit)
                      "
                      formControlName="30"
                      value="1"
                    />Male</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['30'].touched &&
                      gaUnifiedForm.controls['30'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['30'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="radio-inline" class="radioStyle">
                    <input
                      type="radio"
                      id="30"
                      name="30"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['30'].touched &&
                          gaUnifiedForm.controls['30'].invalid) ||
                        (gaUnifiedForm.controls['30'].invalid && isSubmit)
                      "
                      formControlName="30"
                      value="0"
                    />Female</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['30'].touched &&
                      gaUnifiedForm.controls['30'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['30'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['30'].touched &&
                        gaUnifiedForm.controls['30'].invalid) ||
                      (gaUnifiedForm.controls['30'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Social Security Number:</label
                  >
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    id="32"
                    name="32"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['32'].touched &&
                        gaUnifiedForm.controls['32'].invalid) ||
                      (gaUnifiedForm.controls['32'].invalid && isSubmit)
                    "
                    formControlName="32"
                    type="text"
                    data-id="ssn"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['32'].touched &&
                      gaUnifiedForm.controls['32'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['32'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-8 inputPadding">
                  <label class="normalParagraphStyle">UPIN:</label>
                  <input
                    class="form-control "
                    id="819"
                    name="819"
                    formControlName="819"
                    type="text"
                    data-id="upin"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >National Provider Identifier (NPI) (Type 1 Only):</label
                  >
                </div>
                <div class="mb-3 inputPadding">
                  <input
                    class="form-control"
                    id="1634"
                    name="1634"
                    formControlName="1634"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1634'].touched &&
                        gaUnifiedForm.controls['1634'].errors) ||
                      (gaUnifiedForm.controls['1634'].errors && isSubmit)
                    "
                    type="text"
                    data-id="npi"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1634'].touched &&
                      gaUnifiedForm.controls['1634'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1634'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Medicare Provider Number:</label
                  >
                  <input
                    class="form-control"
                    id="325"
                    name="325"
                    formControlName="325"
                    type="text"
                    data-id="medicare"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Georgia Medicaid Provider Number(s):</label
                  >
                  <input
                    class="form-control"
                    id="812"
                    name="812"
                    placeholder="Medicaid Number"
                    formControlName="812"
                    type="text"
                    data-id="medicaid"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Other State Medicaid Provider Number:</label
                  >
                  <input
                    class="form-control"
                    id="814"
                    name="814"
                    placeholder="Medicare Number (PTAN)"
                    formControlName="814"
                    type="text"
                    data-id="altmedicaid"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Georgia License Number:</label
                  >
                  <input
                    class="form-control"
                    id="293"
                    name="293"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['293'].touched &&
                        gaUnifiedForm.controls['293'].invalid) ||
                      (gaUnifiedForm.controls['293'].invalid && isSubmit)
                    "
                    formControlName="293"
                    type="text"
                    data-id="license"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['293'].touched &&
                      gaUnifiedForm.controls['293'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['293'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date mm/yy:</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control col-md-10 colinputWidth"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['295'].touched &&
                          gaUnifiedForm.controls['295'].invalid) ||
                        (gaUnifiedForm.controls['295'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="295"
                      name="295"
                      formControlName="295"
                      type="text"
                      data-id="licenseexp_date"
                      ngbDatepicker
                      #ga84="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('295')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga84.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['295'].invalid&&gaUnifiedForm.controls['295'].touched) && (gaUnifiedForm.controls['295'].value==''|| gaUnifiedForm.controls['295'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('295').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('295').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('295').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('295').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Drug Enforcement Administration Registration #:</label
                  >
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['309'].touched &&
                        gaUnifiedForm.controls['309'].errors) ||
                      (gaUnifiedForm.controls['309'].errors && isSubmit)
                    "
                    id="309"
                    name="309"
                    formControlName="309"
                    type="text"
                    data-id="dea"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['309'].touched &&
                      gaUnifiedForm.controls['309'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['309'].errors"
                    >
                      required
                    </small>
                  </div>
                  <br />
                </div>
              </td>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date mm/yy:</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control col-md-10 colinputWidth"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['311'].touched &&
                          gaUnifiedForm.controls['311'].invalid) ||
                        (gaUnifiedForm.controls['311'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="311"
                      name="311"
                      formControlName="311"
                      type="text"
                      data-id="licenseexp_date"
                      ngbDatepicker
                      #ga85="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('311')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga85.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['311'].invalid&&gaUnifiedForm.controls['311'].touched) && (gaUnifiedForm.controls['311'].value==''|| gaUnifiedForm.controls['311'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('311').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('311').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('311').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('311').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Controlled Substance Registration Number</label
                  >
                  <input
                    style="width:70%;"
                    class="form-control onboard "
                    id="337"
                    name="337"
                    type="text"
                    data-id="CDs number"
                  /><br />
                </div>
              </td>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (if applicable):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control col-md-10 colinputWidth"
                      placeholder="MM/DD/YYYY"
                      id="339"
                      name="339"
                      formControlName="339"
                      type="text"
                      data-id="licenseexp_date"
                      ngbDatepicker
                      #ga86="ngbDatepicker"
                      (blur)="clearInputIfInvalid('339')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga86.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('339').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('339').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('339').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('339').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('339').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Marital Status (optional):</label
                  ><br />
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="0"
                    />Single</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="1"
                    />Married</label
                  ><br />
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="2"
                    />Divorced</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_04"
                      name="cmd_04"
                      formControlName="cmd_04"
                      value="3"
                    />Widow</label
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Spouse (if applicable) (optional):</label
                  >
                  <input
                    class="form-control"
                    id="33"
                    name="33"
                    formControlName="33"
                    type="text"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Medical Specialty for Which Applying</label
                  >
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle">Primary: </label>
                    <div class="mb-3 selectDrop col-md-2 suffix">
                      <ng-select
                        [items]="specialty"
                        bindLabel="name"
                        placeholder="Primary Specialty"
                        bindValue="id"
                        formControlName="284"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle">Secondary: </label>
                    <div class="mb-3 selectDrop col-md-2 suffix">
                      <ng-select
                        [items]="specialtySecondary"
                        bindLabel="name"
                        placeholder="Secondary Specialty"
                        bindValue="id"
                        formControlName="962"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered" class="inputBorderBox">
            <h4 class="boardCertification">II. PRACTICE INFORMATION</h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      ><b>A .NAME OF PRIMARY CLINICAL PRACTICE:</b></label
                    >
                    <ng-select
                      bindLabel="label"
                      class="auto-grow-2x"
                      appendTo="body"
                      placeholder="Select Location"
                      bindValue="id"
                      formControlName="cmd_100040"
                    >
                      <ng-option *ngFor="let c of locDropdown" [value]="c.id">{{
                        c.label
                      }}</ng-option>
                    </ng-select>
                  </div>
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >Type of Practice Setting:
                      <input
                        class="form-control"
                        type="radio"
                        name="cmd_05"
                        id="cmd_05"
                        formControlName="cmd_05"
                        value="0"
                      />Solo
                      <input
                        class="form-control"
                        type="radio"
                        name="cmd_05"
                        id="cmd_05"
                        formControlName="cmd_05"
                        value="1"
                      />Group/Single</label
                    >
                  </div>
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >Specialty:
                      <input
                        class="form-control"
                        type="radio"
                        name="cmd_0172"
                        id="cmd_0172"
                        formControlName="cmd_0172"
                        value="0"
                      />Group/Multi-Specialty
                      <input
                        class="form-control"
                        type="radio"
                        name="cmd_0172"
                        id="cmd_0172"
                        formControlName="cmd_0172"
                        value="1"
                      />Hospital Based
                      <input
                        class="form-control"
                        type="radio"
                        name="cmd_0172"
                        id="cmd_0172"
                        formControlName="cmd_0172"
                        value="2"
                      />Other</label
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Primary Clinical Practice Street Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01523"
                    name="cmd_01523"
                    formControlName="cmd_01523"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <div class="input-group">
                    <label class="normalParagraphStyle"
                      >Start Date at Location (mm/yy):</label
                    >
                    <input
                      class="form-control inputWidthOne datepicker"
                      placeholder="MM/DD/YYYY"
                      id="cmd_01534"
                      formControlName="cmd_01534"
                      name="cmd_01534"
                      type="text"
                      data-id="priornameeffdate_date"
                      ngbDatepicker
                      #ga01001="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_01534')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga01001.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_01534').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01534').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01534').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01534').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01534').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    class="form-control"
                    id="cmd_01524"
                    name="cmd_01524"
                    formControlName="cmd_01524"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">County: </label>
                    <ng-select
                      [items]="county"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_01525'].touched &&
                          gaUnifiedForm.controls['cmd_01525'].invalid) ||
                        (gaUnifiedForm.controls['cmd_01525'].invalid &&
                          isSubmit)
                      "
                      placeholder="Select County"
                      bindValue="id"
                      formControlName="cmd_01525"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['cmd_01525'].touched &&
                        gaUnifiedForm.controls['cmd_01525'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['cmd_01525'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">State: </label>
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_015256'].touched &&
                          gaUnifiedForm.controls['cmd_015256'].invalid) ||
                        (gaUnifiedForm.controls['cmd_015256'].invalid &&
                          isSubmit)
                      "
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="cmd_015256"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['cmd_015256'].touched &&
                        gaUnifiedForm.controls['cmd_015256'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['cmd_015256'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    class="form-control"
                    id="cmd_01527"
                    name="cmd_01527"
                    formControlName="cmd_01527"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Primary Office Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01528"
                    name="cmd_01528"
                    formControlName="cmd_01528"
                    placeholder="Telephone"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Primary Office Fax Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01529"
                    name="cmd_01529"
                    formControlName="cmd_01529"
                    placeholder="Fax Number"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="7">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">
                    Patient Appointment Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01535"
                    name="cmd_01535"
                    formControlName="cmd_01535"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Mailing Address (if different from above):</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01530"
                    name="cmd_01530"
                    formControlName="cmd_01530"
                    placeholder="Address"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01531"
                    name="cmd_01531"
                    formControlName="cmd_01531"
                    type="text"
                    data-id="lname"
                  />
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="mb-3 col-md-4 inputPadding">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['cmd_01532'].touched &&
                            gaUnifiedForm.controls['cmd_01532'].invalid) ||
                          (gaUnifiedForm.controls['cmd_01532'].invalid &&
                            isSubmit)
                        "
                        placeholder="Select State"
                        bindValue="id"
                        formControlName="cmd_01532"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    id="cmd_01533"
                    name="cmd_01533"
                    formControlName="cmd_01533"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Office Manager /Administrative Contact:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01536"
                    name="cmd_01536"
                    formControlName="cmd_01536"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01537"
                    name="cmd_01537"
                    formControlName="cmd_01537"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Office Manager’s Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_1538"
                    name="cmd_1538"
                    formControlName="cmd_1538"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="6">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">
                    Office Manager’s Fax Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_1539"
                    name="cmd_1539"
                    formControlName="cmd_1539"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Answering Service Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01540"
                    name="cmd_01540"
                    formControlName="cmd_01540"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Pager/Beeper Number :</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01541"
                    name="cmd_01541"
                    formControlName="cmd_01541"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="7">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Office E-Mail Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01542"
                    name="cmd_01542"
                    formControlName="cmd_01542"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Credentialing Contact and Address (if different from
                    above):</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01543"
                    placeholder="lastname"
                    name="cmd_01543"
                    formControlName="cmd_01543"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01544"
                    placeholder="firstname"
                    name="cmd_01544"
                    formControlName="cmd_01544"
                    type="text"
                    data-id="lname"
                  />
                  <div class="mb-3 selectDrop suffix dropDrownSet">
                    <ng-select
                      [items]="suffix"
                      bindLabel="name"
                      placeholder="Select Suffix"
                      bindValue="id"
                      formControlName="cmd_01545"
                    >
                    </ng-select>
                  </div>
                  <input
                    class="form-control"
                    id="cmd_01546"
                    name="cmd_01546"
                    formControlName="cmd_01546"
                    placeholder="Address"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01547"
                    name="cmd_01547"
                    formControlName="cmd_01547"
                    placeholder="City"
                    type="text"
                    data-id="lname"
                  />
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="mb-3 col-md-4 inputPadding">
                      <ng-select
                        [items]="states"
                        bindLabel="name"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['cmd_01548'].touched &&
                            gaUnifiedForm.controls['cmd_01548'].invalid) ||
                          (gaUnifiedForm.controls['cmd_01548'].invalid &&
                            isSubmit)
                        "
                        placeholder="Select State"
                        bindValue="id"
                        formControlName="cmd_01548"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <input
                    class="form-control"
                    id="cmd_01549"
                    name="cmd_01549"
                    formControlName="cmd_01549"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="15">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Credentialing Contact’s Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01550"
                    name="cmd_01550"
                    formControlName="cmd_01550"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="10">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Credentialing Contact’s Fax Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01551"
                    name="cmd_01551"
                    formControlName="cmd_01551"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="15">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Federal Tax ID Number for this Practice Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01552"
                    name="cmd_01552"
                    formControlName="cmd_01552"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="10">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name Affiliated with Tax ID Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01553"
                    name="cmd_01553"
                    formControlName="cmd_01553"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div class="centered" class="inputBorderBox">
            <h4 class="boardCertification">
              II. PRACTICE INFORMATION - continued
            </h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >NAME OF SECONDARY CLINICAL PRACTICE:</label
                    >
                    <ng-select
                      bindLabel="label"
                      placeholder="Select Location"
                      bindValue="id"
                      formControlName="cmd_100041"
                    >
                      <ng-option *ngFor="let c of locDropdown" [value]="c.id">{{
                        c.label
                      }}</ng-option>
                    </ng-select>
                  </div>
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >Type of Practice Setting:
                      <input
                        class="form-control"
                        type="checkbox"
                        name="cmd_07"
                        id="cmd_07"
                        formControlName="cmd_07"
                        value="0"
                      />Solo
                      <input
                        class="form-control"
                        type="checkbox"
                        name="cmd_07"
                        id="cmd_07"
                        formControlName="cmd_07"
                        value="1"
                      />Group/Single</label
                    >
                  </div>
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle"
                      >Specialty:
                      <input
                        class="form-control"
                        type="checkbox"
                        name="cmd_08"
                        id="cmd_08"
                        formControlName="cmd_08"
                        value="0"
                      />Group/Multi-Specialty
                      <input
                        class="form-control"
                        type="checkbox"
                        name="cmd_08"
                        id="cmd_08"
                        formControlName="cmd_08"
                        value="1"
                      />Hospital Based
                      <input
                        class="form-control"
                        type="checkbox"
                        name="cmd_08"
                        id="cmd_08"
                        formControlName="cmd_08"
                        value="2"
                      />Other</label
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Secondary Clinical Practice Street Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01555"
                    name="cmd_01555"
                    formControlName="cmd_01555"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="12">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                >
                  <div class="input-group">
                    <label class="normalParagraphStyle"
                      >Start Date at Location (mm/yy):</label
                    >
                    <input
                      class="form-control inputWidthOne datepicker"
                      placeholder="MM/DD/YYYY"
                      id="cmd_01556"
                      formControlName="cmd_01556"
                      name="cmd_01556"
                      type="text"
                      data-id="priornameeffdate_date"
                      ngbDatepicker
                      #ga01000="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_01556')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga01000.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_01556').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01556').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01556').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01556').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01556').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    class="form-control"
                    id="cmd_01557"
                    name="cmd_01557"
                    formControlName="cmd_01557"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">County: </label>
                    <ng-select
                      [items]="county"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_01558'].touched &&
                          gaUnifiedForm.controls['cmd_01558'].invalid) ||
                        (gaUnifiedForm.controls['cmd_01558'].invalid &&
                          isSubmit)
                      "
                      placeholder="Select County"
                      bindValue="id"
                      formControlName="cmd_01558"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['cmd_01558'].touched &&
                        gaUnifiedForm.controls['cmd_01558'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['cmd_01558'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="8">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 col-md-4 inputPadding">
                    <label class="normalParagraphStyle">State: </label>
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_01559'].touched &&
                          gaUnifiedForm.controls['cmd_01559'].invalid) ||
                        (gaUnifiedForm.controls['cmd_01559'].invalid &&
                          isSubmit)
                      "
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="cmd_01559"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['cmd_01559'].touched &&
                        gaUnifiedForm.controls['cmd_01559'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['cmd_01559'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    class="form-control"
                    id="cmd_01560"
                    name="cmd_01560"
                    formControlName="cmd_01560"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Answering Service Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01561"
                    name="cmd_01561"
                    formControlName="cmd_01561"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Pager/Beeper Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01562"
                    name="cmd_01562"
                    formControlName="cmd_01562"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="7">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">
                    Office E-Mail Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01563"
                    name="cmd_01563"
                    formControlName="cmd_01563"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Federal Tax ID Number for this Practice Address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01564"
                    name="cmd_01564"
                    formControlName="cmd_01564"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="12">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name Affiliated with Tax ID Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01565"
                    name="cmd_01565"
                    formControlName="cmd_01565"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    ><b
                      >B. OTHER OFFICES:<u>
                        Please list any other current office locations with the
                        above information on Explanation Form(s).</u
                      ></b
                    ></label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    ><b>C. BILLING ADDRESS:</b> If different than primary
                    clinical site address, please provide complete billing
                    address:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01569"
                    name="cmd_01569"
                    formControlName="cmd_01569"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Office Manager/Administrative Contact:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01566"
                    name="cmd_01566"
                    formControlName="cmd_01566"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="9">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Office Phone Number:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_01562"
                    name="cmd_01562"
                    formControlName="cmd_01562"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
              <td colspan="7">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Office Fax Number:</label>
                  <input
                    class="form-control"
                    id="cmd_01563"
                    name="cmd_01563"
                    formControlName="cmd_01563"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    ><b>D. INTENTION:</b> If you are not currently in practice,
                    please describe your intentions regarding beginning and/or
                    reinstating your practice.</label
                  >
                  <input
                    class="form-control"
                    id="cmd_010"
                    name="cmd_010"
                    formControlName="cmd_010"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    ><b>E. CORRESPONDENCE:</b> To what address would you like
                    all correspondence forwarded?</label
                  >
                  <label class="normalParagraphStyle">
                    <input
                      class="form-control"
                      type="radio"
                      name="cmd_011"
                      id="cmd_011"
                      formControlName="cmd_011"
                      value="0"
                    />Primary Office:
                    <input
                      class="form-control"
                      type="radio"
                      name="cmd_011"
                      id="cmd_011"
                      formControlName="cmd_011"
                      value="1"
                    />Secondary Office
                    <input
                      class="form-control"
                      type="radio"
                      name="cmd_011"
                      id="cmd_011"
                      formControlName="cmd_011"
                      value="2"
                    />Billing Office
                    <input
                      class="form-control"
                      type="radio"
                      name="cmd_011"
                      id="cmd_011"
                      formControlName="cmd_011"
                      value="3"
                    />Home
                    <input
                      class="form-control"
                      type="radio"
                      name="cmd_011"
                      id="cmd_011"
                      formControlName="cmd_011"
                      value="4"
                    />Other (Please specify)
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    ><b>F. LANGUAGES:</b></label
                  ><br />
                  <label class="normalParagraphStyle">
                    1. Please list any language other than English (including
                    sign language) in which you are fluent:
                  </label>
                  <div class="mb-3 selectDrop  col-md-2 languageDropBox">
                    <ng-select
                      [items]="languages"
                      bindLabel="name"
                      placeholder="Primary Language"
                      bindValue="id"
                      formControlName="277"
                    >
                    </ng-select>
                  </div>
                  <div
                    class="mb-3 selectDrop copadding col-md-2 languageDropBox"
                  >
                    <ng-select
                      [items]="secondaryLanguages"
                      bindLabel="name"
                      placeholder="Secondary Language"
                      bindValue="id"
                      formControlName="1535"
                    >
                    </ng-select>
                  </div>
                  <div
                    class="mb-3 selectDrop cofourpadding col-md-2 languageDropBox"
                  >
                    <ng-select
                      [items]="tertiaryLanguages"
                      bindLabel="name"
                      placeholder="Select TertiaryLanguages"
                      bindValue="id"
                      formControlName="1536"
                    >
                    </ng-select>
                  </div>
                  <br />
                  <label class="normalParagraphStyle">
                    2. Please list any language other than English (including
                    sign language) in which a member of your staff is fluent and
                    identify staff member:
                  </label>
                  <input
                    class="form-control"
                    id="cmd_01515"
                    name="cmd_01515"
                    formControlName="cmd_01515"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01516"
                    name="cmd_01516"
                    formControlName="cmd_01516"
                    type="text"
                    data-id="lname"
                  />
                  <input
                    class="form-control"
                    id="cmd_01517"
                    name="cmd_01517"
                    formControlName="cmd_01517"
                    type="text"
                    data-id="lname"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div class="centered" class="inputBorderBox">
            <h4 class="boardCertification">III.CERTIFICATION</h4>
            <label class="floatInputs">Does Not Apply</label>
            <input
              class="floatInputs"
              type="checkbox"
              name="cmd_0193"
              id="cmd_0193"
              (ngModelChange)="handleNAchange('cmd_0193')" 
              formControlName="cmd_0193"
              value="1"
            />
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you certified by any board in your profession?</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0190"
                      name="cmd_0190"
                      FormControlName="cmd_0190"
                      value="1"
                    />Yes</label
                  >
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0190"
                      name="cmd_0190"
                      FormControlName="cmd_0190"
                      value="0"
                    />No</label
                  >
                  <span class="spanPadding">
                    <i>List all current and past board certifications.</i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Issuing Board</label
                  >
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Specialty </label>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Certified (mm/yy):</label
                  >
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Recertified (mm/yy):</label
                  >
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date Recertified (mm/yy):</label
                  >
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (if any) (mm/yy):</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="boardCertification"
                    [class.is-invalid]="gaUnifiedForm.controls['554'].invalid"
                    bindLabel="name"
                    placeholder="Primary Board Certification"
                    bindValue="id"
                    formControlName="554"
                    (blur)="handleNAchange('cmd_0193', 554)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['554'].invalid">
                    <small>
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    [class.is-invalid]="gaUnifiedForm.controls['556'].invalid"
                    bindLabel="name"
                    placeholder="Primary Specialty"
                    bindValue="id"
                    formControlName="556"
                    (blur)="handleNAchange('cmd_0193', 556)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['556'].invalid">
                    <small>
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      [class.is-invalid]="gaUnifiedForm.controls['557'].invalid"
                      placeholder="MM/DD/YYYY"
                      id="557"
                      name="557"
                      formControlName="557"
                      type="text"
                      data-id="primarybcert"
                      ngbDatepicker
                      #ga87="ngbDatepicker"
                      (blur)="handleNAchange('cmd_0193', 557)"
                       (blur)="clearInputIfInvalid('557','cmd_0193')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga87.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                  *ngIf="gaUnifiedForm.controls['557'].invalid && (gaUnifiedForm.controls['557'].value==''|| gaUnifiedForm.controls['557'].value==null) ">
                  <small>
                    required
                  </small>

                </div>

                <div *ngIf="gaUnifiedForm.get('557').invalid">
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('557').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('557').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('557').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('557').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="558"
                      name="558"
                      [class.is-invalid]="gaUnifiedForm.controls['558'].invalid"
                      formControlName="558"
                      type="text"
                      data-id="primarybrcert1"
                      ngbDatepicker
                      #ga93="ngbDatepicker"
                      (blur)="handleNAchange('cmd_0193', 558)"
                       (blur)="clearInputIfInvalid('558','cmd_0193')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga93.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                    *ngIf="gaUnifiedForm.controls['558'].invalid && (gaUnifiedForm.controls['558'].value==''|| gaUnifiedForm.controls['558'].value==null) ">
                    <small>
                      required
                    </small>

                  </div>

                  <div *ngIf="gaUnifiedForm.get('558').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('558').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('558').errors.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('558').errors.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('558').errors.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="559"
                      name="559"
                      [class.is-invalid]="gaUnifiedForm.controls['559'].invalid"
                      formControlName="559"
                      type="text"
                      data-id="primarybrcert2"
                      ngbDatepicker
                      #ga92="ngbDatepicker"
                      (blur)="handleNAchange('cmd_0193', 559)"
                       (blur)="clearInputIfInvalid('559','cmd_0193')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga92.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                    *ngIf="gaUnifiedForm.controls['559'].invalid && (gaUnifiedForm.controls['559'].value==''|| gaUnifiedForm.controls['559'].value==null) ">
                    <small>
                      required
                    </small>

                  </div>

                  <div *ngIf="gaUnifiedForm.get('559').invalid">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('559').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('559').errors.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('559').errors.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('559').errors.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      [class.is-invalid]="gaUnifiedForm.controls['560'].invalid"
                      placeholder="MM/DD/YYYY"
                      id="560"
                      name="560"
                      formControlName="560"
                      type="text"
                      data-id="primarybexp_date"
                      ngbDatepicker
                      #ga91="ngbDatepicker"
                      (blur)="handleNAchange('cmd_0193', 560)"
                      (blur)="clearInputIfInvalid('560','cmd_0193')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga91.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                  *ngIf="gaUnifiedForm.controls['560'].invalid && (gaUnifiedForm.controls['560'].value==''|| gaUnifiedForm.controls['560'].value==null) ">
                  <small>
                    required
                  </small>

                </div>

                <div *ngIf="gaUnifiedForm.get('560').invalid">
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('560').errors.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY) </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('560').errors.invalidMonth">
                    Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('560').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('560').errors.invalidDateRange">
                    date is out of range
                  </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="boardCertification"
                    bindLabel="name"
                    placeholder="Second Board Certification"
                    bindValue="id"
                    formControlName="561"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Secondary Specialty"
                    bindValue="id"
                    formControlName="563"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="564"
                      name="564"
                      formControlName="564"
                      type="text"
                      data-id="secbcert"
                      ngbDatepicker
                      #ga88="ngbDatepicker"
                      (blur)="clearInputIfInvalid('564')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga88.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('564').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('564').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('564').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('564').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('564').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="565"
                      name="565"
                      formControlName="565"
                      type="text"
                      data-id="secbrcert1"
                      ngbDatepicker
                      #ga89="ngbDatepicker"
                      (blur)="clearInputIfInvalid('565')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga89.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('565').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('565').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('565').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('565').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('565').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="566"
                      name="566"
                      formControlName="566"
                      type="text"
                      data-id="secbrcert2"
                      ngbDatepicker
                      #ga90="ngbDatepicker"
                      (blur)="clearInputIfInvalid('566')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga90.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('566').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('566').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('566').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('566').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('566').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="567"
                      name="567"
                      formControlName="567"
                      type="text"
                      data-id="secbexp_date"
                      ngbDatepicker
                      #ga94="ngbDatepicker"
                      (blur)="clearInputIfInvalid('567')"
                      
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga94.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('567').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('567').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('567').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('567').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('567').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3" width="30%">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="boardCertification"
                    bindLabel="name"
                    placeholder="Board Certification"
                    bindValue="id"
                    formControlName="946"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 selectDrop boardCertiSet inputPadding">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Tertiary Specialty"
                    bindValue="id"
                    formControlName="965"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="953"
                      name="953"
                      formControlName="953"
                      type="text"
                      data-id="Tertiary Board cert"
                      ngbDatepicker
                      #ga95="ngbDatepicker"
                      (blur)="clearInputIfInvalid('953')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga95.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('953').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('953').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('953').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('953').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('953').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="950"
                      name="950"
                      formControlName="950"
                      type="text"
                      data-id="Tertiary Board recert 1"
                      ngbDatepicker
                      #ga96="ngbDatepicker"
                      (blur)="clearInputIfInvalid('950')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga96.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('950').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('950').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('950').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('950').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('950').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="952"
                      name="952"
                      formControlName="952"
                      type="text"
                      data-id="Tertiary Board recert 2"
                      ngbDatepicker
                      #ga97="ngbDatepicker"
                      (blur)="clearInputIfInvalid('952')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga97.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('952').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('952').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('952').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('952').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('952').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="3">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      class="form-control inputoneWidth"
                      placeholder="MM/DD/YYYY"
                      id="954"
                      name="954"
                      formControlName="954"
                      type="text"
                      data-id="Tertiary Board exp"
                      ngbDatepicker
                      #ga98="ngbDatepicker"
                      (blur)="clearInputIfInvalid('954')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga98.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('954').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('954').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('954').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('954').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('954').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Please answer the following questions. Attach Explanation
                    Form(s), if necessary.</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">A.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been examined by any specialty board, but
                    failed to pass? If yes, please provide name of board(s) and
                    date(s):</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_012"
                      formControlName="cmd_012"
                      name="cmd_012"
                      value="1"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_012'].touched &&
                          gaUnifiedForm.controls['cmd_012'].invalid) ||
                        (gaUnifiedForm.controls['cmd_012'].invalid && isSubmit)
                      "
                    />
                    Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_012'].touched &&
                      gaUnifiedForm.controls['cmd_012'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_012'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="radio-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_012"
                      formControlName="cmd_012"
                      name="cmd_012"
                      value="0"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_012'].touched &&
                          gaUnifiedForm.controls['cmd_012'].invalid) ||
                        (gaUnifiedForm.controls['cmd_012'].invalid && isSubmit)
                      "
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_012'].touched &&
                      gaUnifiedForm.controls['cmd_012'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_012'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_012'].touched &&
                        gaUnifiedForm.controls['cmd_012'].invalid) ||
                      (gaUnifiedForm.controls['cmd_012'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">B.</label>
                </div>
              </td>
              <td colspan="16">
                <table border="1">
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >1. If you are not currently certified, have you
                          applied for the certification examination?</label
                        >
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_013"
                            formControlName="cmd_013"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_013'].touched &&
                                gaUnifiedForm.controls['cmd_013'].invalid) ||
                              (gaUnifiedForm.controls['cmd_013'].invalid &&
                                isSubmit)
                            "
                            name="cmd_013"
                            value="1"
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_013'].touched &&
                            gaUnifiedForm.controls['cmd_013'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_013'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_013"
                            formControlName="cmd_013"
                            name="cmd_013"
                            value="0"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_013'].touched &&
                                gaUnifiedForm.controls['cmd_013'].invalid) ||
                              (gaUnifiedForm.controls['cmd_013'].invalid &&
                                isSubmit)
                            "
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_013'].touched &&
                            gaUnifiedForm.controls['cmd_013'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_013'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_013'].touched &&
                              gaUnifiedForm.controls['cmd_013'].invalid) ||
                            (gaUnifiedForm.controls['cmd_013'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >2. If you have not applied for the certification
                          examination, do you intend to apply for the
                          certification examination? If yes, when? Date:</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group "
                            id="cmd_014"
                            name="cmd_014"
                            formControlName="cmd_014"
                            placeholder="MM/DD/YYYY"
                            type="text"
                            data-id="date of cert applied"
                            ngbDatepicker
                            #ga04="ngbDatepicker"
                            (blur)="clearInputIfInvalid('cmd_014')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga04.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="gaUnifiedForm.get('cmd_014').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_014').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_014').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_014').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_014').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_015"
                            formControlName="cmd_015"
                            name="cmd_015"
                            value="1"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_015'].touched &&
                                gaUnifiedForm.controls['cmd_015'].invalid) ||
                              (gaUnifiedForm.controls['cmd_015'].invalid &&
                                isSubmit)
                            "
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_015'].touched &&
                            gaUnifiedForm.controls['cmd_015'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_015'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_015"
                            formControlName="cmd_015"
                            name="cmd_015"
                            value="0"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_015'].touched &&
                                gaUnifiedForm.controls['cmd_015'].invalid) ||
                              (gaUnifiedForm.controls['cmd_015'].invalid &&
                                isSubmit)
                            "
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_015'].touched &&
                            gaUnifiedForm.controls['cmd_015'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_015'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_015'].touched &&
                              gaUnifiedForm.controls['cmd_015'].invalid) ||
                            (gaUnifiedForm.controls['cmd_015'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >3. If you have applied for the certification
                          examination, have you been accepted to take the
                          certification examination?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_016"
                            formControlName="cmd_016"
                            name="cmd_016"
                            value="1"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_016'].touched &&
                                gaUnifiedForm.controls['cmd_016'].invalid) ||
                              (gaUnifiedForm.controls['cmd_016'].invalid &&
                                isSubmit)
                            "
                          />Yes</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_016'].touched &&
                            gaUnifiedForm.controls['cmd_016'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_016'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            id="cmd_016"
                            formControlName="cmd_016"
                            name="cmd_016"
                            value="0"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_016'].touched &&
                                gaUnifiedForm.controls['cmd_016'].invalid) ||
                              (gaUnifiedForm.controls['cmd_016'].invalid &&
                                isSubmit)
                            "
                          />No</label
                        >
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_016'].touched &&
                            gaUnifiedForm.controls['cmd_016'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_016'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_016'].touched &&
                              gaUnifiedForm.controls['cmd_016'].invalid) ||
                            (gaUnifiedForm.controls['cmd_016'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">
                          4. If you have been accepted, when do you intend to
                          take the certification examination?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">Date:</label>
                        <div class="input-group">
                          <input
                            style="width: 60%"
                            class="form-control  datepicker"
                            placeholder="MM/DD/YYYY"
                            id="cmd_01526"
                            name="cmd_01526"
                            formControlName="cmd_01526"
                            type="text"
                            data-id="date of cert testing"
                            ngbDatepicker
                            #ga05="ngbDatepicker"
                            (blur)="clearInputIfInvalid('cmd_01526')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga05.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="gaUnifiedForm.get('cmd_01526').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01526').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01526').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01526').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01526').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="16" width="100%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">
                          5. If you do not intend to apply for the certification
                          examination, please attach reason on Explanation
                          Form(s)
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">C.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >If you are not currently board certified, please provide
                    the expiration date of admissibility If yes, when?
                    Date:</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control inputWidthOne"
                      placeholder="MM/DD/YYYY"
                      id="cmd_017"
                      name="cmd_017"
                      formControlName="cmd_017"
                      type="text"
                      data-id="date of admissibility"
                      ngbDatepicker
                      #ga100="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_017')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga100.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_017').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_017').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_017').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_017').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_017').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_01500"
                      formControlName="cmd_01500"
                      name="cmd_01500"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_01500"
                      formControlName="cmd_01500"
                      name="cmd_01500"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">D.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever had board certification revoked, limited,
                    suspended, involuntarily relinquished, subject to stipulated
                    or probationary conditions, received a letter of reprimand
                    from a specialty board, or is any such action currently
                    pending or under review? If yes, please attach Explanation
                    Form(s).</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_018"
                      formControlName="cmd_018"
                      name="cmd_018"
                      value="1"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_018'].touched &&
                          gaUnifiedForm.controls['cmd_018'].invalid) ||
                        (gaUnifiedForm.controls['cmd_018'].invalid && isSubmit)
                      "
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_018'].touched &&
                      gaUnifiedForm.controls['cmd_018'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_018'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_018"
                      formControlName="cmd_018"
                      name="cmd_018"
                      value="0"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_018'].touched &&
                          gaUnifiedForm.controls['cmd_018'].invalid) ||
                        (gaUnifiedForm.controls['cmd_018'].invalid && isSubmit)
                      "
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_018'].touched &&
                      gaUnifiedForm.controls['cmd_018'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_018'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_018'].touched &&
                        gaUnifiedForm.controls['cmd_018'].invalid) ||
                      (gaUnifiedForm.controls['cmd_018'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">E.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever voluntarily relinquished a board
                    certification, including any voluntary non-renewal of a time
                    limited board certification? If yes, please attach
                    Explanation Form(s).</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_019"
                      formControlName="cmd_019"
                      name="cmd_019"
                      value="1"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_019'].touched &&
                          gaUnifiedForm.controls['cmd_019'].invalid) ||
                        (gaUnifiedForm.controls['cmd_019'].invalid && isSubmit)
                      "
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_019'].touched &&
                      gaUnifiedForm.controls['cmd_019'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_019'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_019"
                      formControlName="cmd_019"
                      name="cmd_019"
                      value="0"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_019'].touched &&
                          gaUnifiedForm.controls['cmd_019'].invalid) ||
                        (gaUnifiedForm.controls['cmd_019'].invalid && isSubmit)
                      "
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_019'].touched &&
                      gaUnifiedForm.controls['cmd_019'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_019'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_019'].touched &&
                        gaUnifiedForm.controls['cmd_019'].invalid) ||
                      (gaUnifiedForm.controls['cmd_019'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              IV. EDUCATION, TRAINING AND PROFESSIONAL EXPERIENCE
            </h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >A. UNDERGRADUATE or TECHNICAL SCHOOL</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Complete School Name:</label
                  >
                  <input
                    style="width: 40%"
                    formControlName="503"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['503'].touched &&
                        gaUnifiedForm.controls['503'].invalid) ||
                      (gaUnifiedForm.controls['503'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="503"
                    name="503"
                    type="text"
                    data-id="ugs"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['503'].touched &&
                      gaUnifiedForm.controls['503'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['503'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 selectDropTwo inputPadding">
                  <ng-select
                    [items]="degree"
                    formControlName="512"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['512'].touched &&
                        gaUnifiedForm.controls['512'].invalid) ||
                      (gaUnifiedForm.controls['512'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select Degree"
                    bindValue="id"
                    formControlName="512"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['512'].touched &&
                      gaUnifiedForm.controls['512'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['512'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Graduation Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 40%"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['511'].touched &&
                          gaUnifiedForm.controls['511'].invalid) ||
                        (gaUnifiedForm.controls['511'].invalid && isSubmit)
                      "
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="511"
                      name="511"
                      formControlName="511"
                      type="text"
                      data-id="ugcomp"
                      ngbDatepicker
                      #ga06="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('511')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga06.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>

                    <div class="mandatory" *ngIf="(gaUnifiedForm.controls['511'].invalid&&gaUnifiedForm.controls['511'].touched) && (gaUnifiedForm.controls['511'].value==''|| gaUnifiedForm.controls['511'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('511').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('511').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('511').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('511').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    formControlName="506"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['506'].touched &&
                        gaUnifiedForm.controls['506'].invalid) ||
                      (gaUnifiedForm.controls['506'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="506"
                    name="506"
                    type="text"
                    data-id="ugsci"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['506'].touched &&
                      gaUnifiedForm.controls['506'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['506'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet suffix inputPadding"
                >
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['508'].touched &&
                        gaUnifiedForm.controls['508'].invalid) ||
                      (gaUnifiedForm.controls['508'].invalid && isSubmit)
                    "
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="508"
                  >
                  </ng-select>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['508'].touched &&
                      gaUnifiedForm.controls['508'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['508'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Course of Study or Major:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['513'].touched &&
                        gaUnifiedForm.controls['513'].invalid) ||
                      (gaUnifiedForm.controls['513'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="513"
                    name="513"
                    formControlName="513"
                    type="text"
                    data-id="ugspr"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['513'].touched &&
                      gaUnifiedForm.controls['513'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['513'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="20">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >B. POST GRADUATE DEGREES</label
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_020"
                      name="cmd_020"
                      (ngModelChange)="handleNAchange('cmd_020')"                      
                      formControlName="cmd_020"
                      value="1"
                    />Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Complete School Name:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="gaUnifiedForm.controls['479'].invalid"
                    class="form-control"
                    id="479"
                    name="479"
                    formControlName="479"
                    type="text"
                    data-id="grs"
                    (blur)="handleNAchange('cmd_020', 479)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['479'].invalid">
                    <small>
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Degree(s) Received:</label
                  >
                  <div class="mb-3 selectDropTwo suffix inputPadding">
                    <ng-select
                      [items]="degree"
                      [class.is-invalid]="gaUnifiedForm.controls['480'].invalid"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="480"
                      (blur)="handleNAchange('cmd_020', 480)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['480'].invalid">
                      <small>
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Graduation Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      style="width: 40%"
                      [class.is-invalid]="gaUnifiedForm.controls['482'].invalid"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="482"
                      name="482"
                      formControlName="482"
                      type="text"
                      data-id="grscomp"
                      ngbDatepicker
                      #ga07="ngbDatepicker"
                      (blur)="handleNAchange('cmd_020', 482)" 
                      (blur)="clearInputIfInvalid('482','cmd_020')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga07.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                    *ngIf="gaUnifiedForm.controls['482'].invalid && (gaUnifiedForm.controls['482'].value==''|| gaUnifiedForm.controls['482'].value==null) ">
                    <small>
                      required
                    </small>

                  </div>

                  <div *ngIf="gaUnifiedForm.get('482').invalid">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('482').errors.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('482').errors.invalidMonth">
                      Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('482').errors.invalidDateyear">
                      year is out of range(1900-2099) </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('482').errors.invalidDateRange">
                      date is out of range
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="gaUnifiedForm.controls['485'].invalid"
                    class="form-control"
                    id="485"
                    name="485"
                    formControlName="485"
                    type="text"
                    data-id="grsci"
                    (blur)="handleNAchange('cmd_020', 485)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['485'].invalid">
                    <small>
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                  <div
                    class="mb-3 selectDrop dropDrownSet suffix inputPadding "
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="gaUnifiedForm.controls['487'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="487"
                      (blur)="handleNAchange('cmd_020', 487)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['487'].invalid">
                      <small>
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Course of Study or Major:</label
                  >
                  <input
                    style="width: 40%"
                    [class.is-invalid]="gaUnifiedForm.controls['489'].invalid"
                    class="form-control"
                    id="489"
                    name="489"
                    formControlName="489"
                    type="text"
                    data-id="grspr"
                    (blur)="handleNAchange('cmd_020', 489)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['489'].invalid">
                    <small>
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >C. PROFESSIONAL TRAINING</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Medical / Professional School Name and Street
                    Address:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-3 inputPadding">
                  <input
                    class="form-control"
                    placeholder="Intern School"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['517'].touched &&
                        gaUnifiedForm.controls['517'].errors) ||
                      (gaUnifiedForm.controls['517'].errors && isSubmit)
                    "
                    id="517"
                    name="517"
                    formControlName="517"
                    type="text"
                    placeholder="Intern School"
                    data-id="meds"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['517'].touched &&
                      gaUnifiedForm.controls['517'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['517'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-3 inputPadding">
                  <input
                    class="form-control"
                    placeholder="Intern Address1"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['518'].touched &&
                        gaUnifiedForm.controls['518'].errors) ||
                      (gaUnifiedForm.controls['518'].errors && isSubmit)
                    "
                    id="518"
                    name="518"
                    formControlName="518"
                    type="text"
                    placeholder="Intern Address1"
                    data-id="Intern Address1"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['518'].touched &&
                      gaUnifiedForm.controls['518'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['518'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-3 inputPadding">
                  <input
                    class="form-control"
                    placeholder="Intern Address2"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['519'].touched &&
                        gaUnifiedForm.controls['519'].errors) ||
                      (gaUnifiedForm.controls['519'].errors && isSubmit)
                    "
                    id="519"
                    name="519"
                    formControlName="519"
                    type="text"
                    placeholder="Intern Address2"
                    data-id="Intern Address1"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['519'].touched &&
                      gaUnifiedForm.controls['519'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['519'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['520'].touched &&
                        gaUnifiedForm.controls['520'].errors) ||
                      (gaUnifiedForm.controls['520'].errors && isSubmit)
                    "
                    class="form-control"
                    id="520"
                    name="520"
                    formControlName="520"
                    placeholder="Intern City"
                    type="text"
                    data-id="medsci"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['520'].touched &&
                      gaUnifiedForm.controls['520'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['520'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="522"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['523'].touched &&
                        gaUnifiedForm.controls['523'].errors) ||
                      (gaUnifiedForm.controls['523'].errors && isSubmit)
                    "
                    class="form-control"
                    id="523"
                    name="523"
                    formControlName="523"
                    placeholder="Intern Zip"
                    type="text"
                    data-id="medsz"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['523'].touched &&
                      gaUnifiedForm.controls['523'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['523'].errors.required"
                    >
                      required
                    </small>
                    <!-- <small class="text-danger" *ngIf=" !gaUnifiedForm.controls['cmd_01503'].errors.required && gaUnifiedForm.controls['cmd_01503'].value.length<5">
                      Should not be less then 5 digits</small>
                    <small class="text-danger" *ngIf=" !gaUnifiedForm.controls['cmd_01503'].errors.required && gaUnifiedForm.controls['cmd_01503'].value.length>10">
                      Should not be more then 10 digits
                    </small> -->
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control datepicker"
                    
                      placeholder="MM/DD/YYYY"
                      id="524"
                      name="524"
                      formControlName="524"
                      type="text"
                      data-id="medsstart_date"
                      ngbDatepicker
                      #ga08="ngbDatepicker"
                      (blur)="clearInputIfInvalid('524')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga08.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('524').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('524').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('524').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('524').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('524').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                    
                      placeholder="MM/DD/YYYY"
                      id="525"
                      name="525"
                      formControlName="525"
                      type="text"
                      data-id="medsend_date"
                      ngbDatepicker
                      #ga09="ngbDatepicker"
                      (blur)="clearInputIfInvalid('525')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga09.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('525').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('525').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('525').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('525').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('525').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                     
                      placeholder="MM/DD/YYYY"
                      id="526"
                      name="526"
                      formControlName="526"
                      data-id="medscomp"
                      type="medscomp"
                      ngbDatepicker
                      #ga10="ngbDatepicker"
                      (blur)="clearInputIfInvalid('526')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga10.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('526').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('526').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('526').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('526').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('526').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Degree(s) Received:</label
                  >
                  <div class="mb-3 selectDropTwo inputPadding suffix">
                    <input
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['2539'].touched &&
                          gaUnifiedForm.controls['2539'].errors) ||
                        (gaUnifiedForm.controls['2539'].errors && isSubmit)
                      "
                      class="form-control"
                      id="2539"
                      name="2539"
                      formControlName="2539"
                      placeholder="Internship Department"
                      type="text"
                      data-id="medsz"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['2539'].touched &&
                        gaUnifiedForm.controls['2539'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['2539'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_021"
                      name="cmd_021"
                      formControlName="cmd_021"
                      value="0"
                    />No</label
                  >
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_021'].touched &&
                        gaUnifiedForm.controls['cmd_021'].invalid) ||
                      (gaUnifiedForm.controls['cmd_021'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <label class="normalParagraphStyle"
                    >Medical / Professional School Name and Street
                    Address:</label
                  >
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_01501'].touched &&
                        gaUnifiedForm.controls['cmd_01501'].errors) ||
                      (gaUnifiedForm.controls['cmd_01501'].errors && isSubmit)
                    "
                    id="cmd_01501"
                    name="cmd_01501"
                    formControlName="cmd_01501"
                    type="text"
                    data-id="interns"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_01501'].touched &&
                      gaUnifiedForm.controls['cmd_01501'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_01501'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City:</label>
                  <input
                    style="width: 40%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_01502'].touched &&
                        gaUnifiedForm.controls['cmd_01502'].errors) ||
                      (gaUnifiedForm.controls['cmd_01502'].errors && isSubmit)
                    "
                    class="form-control"
                    id="cmd_01502"
                    name="cmd_01502"
                    formControlName="cmd_01502"
                    type="text"
                    data-id="internci"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_01502'].touched &&
                      gaUnifiedForm.controls['cmd_01502'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_01502'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country:</label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <input
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_01504'].touched &&
                          gaUnifiedForm.controls['cmd_01504'].errors) ||
                        (gaUnifiedForm.controls['cmd_01504'].errors && isSubmit)
                      "
                      class="form-control"
                      id="cmd_01504"
                      name="cmd_01504"
                      formControlName="cmd_01504"
                      type="text"
                      data-id="internci"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['cmd_01504'].touched &&
                        gaUnifiedForm.controls['cmd_01504'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['cmd_01504'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip:</label>
                  <input
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_01503'].touched &&
                        gaUnifiedForm.controls['cmd_01503'].errors) ||
                      (gaUnifiedForm.controls['cmd_01503'].errors && isSubmit)
                    "
                    class="form-control"
                    id="cmd_01503"
                    name="cmd_01503"
                    formControlName="cmd_01503"
                    type="text"
                    data-id="internz"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_01503'].touched &&
                      gaUnifiedForm.controls['cmd_01503'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_01503'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_01505'].touched &&
                          gaUnifiedForm.controls['cmd_01505'].errors) ||
                        (gaUnifiedForm.controls['cmd_01505'].errors && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="cmd_01505"
                      name="cmd_01505"
                      formControlName="cmd_01505"
                      type="text"
                      data-id="internstart_date"
                      ngbDatepicker
                      #ga104="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_01505')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga104.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div  *ngIf="gaUnifiedForm.get('cmd_01505').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01505').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01505').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01505').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01505').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_01505'].touched &&
                      gaUnifiedForm.controls['cmd_01505'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_01505'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="8">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy):</label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="cmd_01506"
                      name="cmd_01506"
                      formControlName="cmd_01506"
                      type="text"
                      data-id="internend_date"
                      ngbDatepicker
                      #ga106="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_01506')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga106.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_01506').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01506').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01506').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01506').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01506').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Date of Completion (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="cmd_01507"
                      name="cmd_01507"
                      formControlName="cmd_01507"
                      data-id="interncompdate_date"
                      type="text"
                      ngbDatepicker
                      #ga13="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_01507')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga13.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_01507').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01507').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01507').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01507').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01507').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Degree(s) Received:</label
                  >
                </div>
                <div class="mb-3 selectDropTwo inputPadding suffix">
                  <input
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_01508'].touched &&
                        gaUnifiedForm.controls['cmd_01508'].errors) ||
                      (gaUnifiedForm.controls['cmd_01508'].errors && isSubmit)
                    "
                    class="form-control"
                    id="cmd_01508"
                    name="cmd_01508"
                    formControlName="cmd_01508"
                    type="text"
                    data-id="internz"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_01508'].touched &&
                      gaUnifiedForm.controls['cmd_01508'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_01508'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_01509"
                      name="cmd_01509"
                      formControlName="cmd_01509"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_01509"
                      name="cmd_01509"
                      formControlName="cmd_01509"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >(If you did not complete the program, please attach
                    Explanation Form(s)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="20">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">D.FACULTY POSITIONS</label>
                  <i
                    >List all academic, faculty, research, assistantships or
                    teaching positions you have held and the dates of those
                    appointments.</i
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_043"
                      name="cmd_043"
                      (ngModelChange)="handleNAchange('cmd_043')"
                      formControlName="cmd_043"
                      value="1"
                    />Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Program Specialty & Institution:</label
                  >
                  <input
                    style="width:70%;"
                    class="form-control"
                    placeholder="Primary Institution Name"
                    [class.is-invalid]="gaUnifiedForm.controls['1146'].invalid"
                    id="1146"
                    name="1146"
                    formControlName="1146"
                    type="text"
                    data-id="interns"
                    (blur)="handleNAchange('cmd_043', 1146)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1146'].invalid">
                    <small>
                        required
                    </small>
                </div>
                  <div
                    class="mb-3 selectDrop inputPadding suffix col-md-6"
                  >
                    <ng-select
                      [items]="specialty"
                      [class.is-invalid]="gaUnifiedForm.controls['1147'].invalid"
                      bindLabel="name"
                      placeholder="Select Specialty"
                      bindValue="id"
                      formControlName="1147"
                      (blur)="handleNAchange('cmd_043', 1147)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['1147'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </div>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Academic Rank or Title:</label
                  >
                  <input
                    style="width:70%;"
                    [class.is-invalid]="gaUnifiedForm.controls['1154'].invalid"
                    class="form-control"
                    id="1154"
                    name="1154"
                    formControlName="1154"
                    type="text"
                    data-id="faculty Pos 1 title"
                    (blur)="handleNAchange('cmd_043', 1154)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1154'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name & Address:</label
                  >
                  <input
                    placeholder="Primary Institution Address1"
                    [class.is-invalid]="gaUnifiedForm.controls['1148'].invalid"
                    class="form-control"
                    id="1148"
                    name="1148"
                    formControlName="1148"
                    placeholder="Primary Institution Address1"
                    type="text"
                    data-id="faculty Pos 1 Inst name"
                    (blur)="handleNAchange('cmd_043', 1148)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1148'].invalid">
                    <small>
                        required
                    </small>
                </div>
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['1149'].invalid"
                    class="form-control"
                    id="1149"
                    name="1149"
                    formControlName="1149"
                    placeholder="Primary Institution Address2"
                    type="text"
                    data-id="faculty Pos 1 Inst name"
                    (blur)="handleNAchange('cmd_043', 1149)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1149'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City: </label>
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['1150'].invalid"
                    class="form-control"
                    id="1150"
                    name="1150"
                    formControlName="1150"
                    type="text"
                    data-id="faculty Pos 1 city"
                    (blur)="handleNAchange('cmd_043', 1150)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1150'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country: </label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                  >
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="gaUnifiedForm.controls['1152'].invalid"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1152"
                      (blur)="handleNAchange('cmd_043', 1152)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['1152'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip: </label>
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['1153'].invalid"
                    class="form-control"
                    id="1153"
                    name="1153"
                    formControlName="1153"
                    type="text"
                    data-id="faculty Pos 1 zip"
                    (blur)="handleNAchange('cmd_043', 1153)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1153'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:40%;"
                      [class.is-invalid]="gaUnifiedForm.controls['1155'].invalid"
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1155"
                      name="1155"
                      formControlName="1155"
                      type="text"
                      data-id="faculty Pos 1 from"
                      ngbDatepicker
                      #ga24="ngbDatepicker"
                      (blur)="handleNAchange('cmd_043', 1155)"
                      (blur)="clearInputIfInvalid('1155','cmd_043')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga24.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['1155'].invalid && (gaUnifiedForm.controls['1155'].value==''|| gaUnifiedForm.controls['1155'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('1155').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('1155').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('1155').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1155').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1155').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:70%;"
                      [class.is-invalid]="gaUnifiedForm.controls['1156'].invalid"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1156"
                      name="1156"
                      formControlName="1156"
                      type="text"
                      data-id="faculty Pos 1  to"
                      ngbDatepicker
                      #ga25="ngbDatepicker"
                      (blur)="handleNAchange('cmd_043', 1156)"
                      (blur)="clearInputIfInvalid('1156','cmd_043')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga25.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['1156'].invalid && (gaUnifiedForm.controls['1156'].value==''|| gaUnifiedForm.controls['1156'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('1156').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('1156').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('1156').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1156').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1156').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Program Specialty & Institution:
                  </label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="1157"
                    name="1157"
                    placeholder="Secondary Institution Name"
                    formControlName="1157"
                    type="text"
                    data-id="faculty Pos 1 city"
                  />
                </div>
                <div class="mb-3 selectDrop inputPadding suffix col-md-6">
                  <ng-select
                    [items]="specialty"
                    bindLabel="name"
                    placeholder="Select Specialty"
                    bindValue="id"
                    formControlName="1158"
                  >
                  </ng-select>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Academic Rank or Title:
                  </label>
                  <input
                    style="width:70%;"
                    class="form-control"
                    id="1165"
                    name="1165"
                    formControlName="1165"
                    type="text"
                    data-id="faculty Pos 2 title"
                  />
                </div>
              </td>
              <td colspan="6" width="50%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Institution Name & Address</label
                  >
                  <input
                    class="form-control"
                    placeholder="Secondary Institution Add1"
                    id="1159"
                    name="1159"
                    formControlName="1159"
                    type="text"
                    data-id="faculty Pos 2 inst name"
                  />

                  <input
                    style="width:40%;"
                    class="form-control"
                    placeholder="Name"
                    id="1160"
                    placeholder="Secondary Institution Add2"
                    name="1160"
                    formControlName="1160"
                    type="text"
                    data-id="faculty Pos 2 inst name"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">City: </label>
                  <input
                    class="form-control"
                    id="1161"
                    name="1161"
                    formControlName="1161"
                    type="text"
                    data-id="faculty Pos 2 city"
                  />
                </div>
              </td>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">State/Country: </label>
                  <div
                    class="mb-3 selectDrop dropDrownSet inputPadding suffix "
                  >
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1163"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Zip: </label>
                  <input
                    class="form-control"
                    id="1164"
                    name="1164"
                    formControlName="1164"
                    type="text"
                    data-id="faculty Pos 2 zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">From (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:40%;"
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1166"
                      name="1166"
                      formControlName="1166"
                      type="text"
                      data-id="faculty Pos 2 from"
                      ngbDatepicker
                      #ga26="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1166')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga26.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('1166').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1166').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1166').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1166').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1166').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">To (mm/yy): </label>
                  <div class="input-group">
                    <input
                      style="width:70%;"
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1167"
                      name="1167"
                      formControlName="1167"
                      type="text"
                      data-id="faculty Pos 2 to"
                      ngbDatepicker
                      #ga27="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1167')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga27.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('1167').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1167').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1167').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1167').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1167').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="20">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >E. MILITARY/PUBLIC HEALTH SERVICE</label
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_044"
                      name="cmd_044"
                      (ngModelChange)="handleNAchange('cmd_044')"
                      formControlName="cmd_044"
                      value="1"
                    />Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Location of Last Duty Station:</label
                  >
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['1139'].invalid" 
                    id="1139"
                    name="1139"
                    formControlName="1139"
                    type="text"
                    data-id="Military Locaiton"
                    (blur)="handleNAchange('cmd_044', 1139)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1139'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <div class="input-group d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Rank at Discharge:</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['1140'].invalid" 
                    id="1140"
                    name="1140"
                    formControlName="1140"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_044', 1140)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1140'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="6">
                <div class="input-group no-margin inputPadding">
                  <label class="normalParagraphStyle">Branch: </label>
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['1141'].invalid" 
                    id="1141"
                    name="1141"
                    formControlName="1141"
                    type="text"
                    data-id="Mil branch"
                    (blur)="handleNAchange('cmd_044', 1141)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1141'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="6">
                <div class="input-group no-margin inputPadding ">
                  <label class="normalParagraphStyle"
                    >Active Duty Dates: <br />From (mm/yy)</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      [class.is-invalid]="gaUnifiedForm.controls['1144'].invalid" 
                      placeholder="MM/DD/YYYY"
                      id="1144"
                      name="1144"
                      formControlName="1144"
                      type="text"
                      data-id="Mil Active from"
                      ngbDatepicker
                      #ga28="ngbDatepicker"
                      (blur)="handleNAchange('cmd_044', 1144)"
                       (blur)="clearInputIfInvalid('1144','cmd_044')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga28.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                  *ngIf="gaUnifiedForm.controls['1144'].invalid && (gaUnifiedForm.controls['1144'].value==''|| gaUnifiedForm.controls['1144'].value==null) ">
                 <small>
                  required
               </small>

             </div>

        <div *ngIf="gaUnifiedForm.get('1144').invalid">
            <small class="text-danger" *ngIf="gaUnifiedForm.get('1144').errors.invalidDateFormat">
               Only Date Format is allowed(MM/DD/YYYY) </small>
             <small class="text-danger" *ngIf="gaUnifiedForm.get('1144').errors.invalidMonth">
                 Month is out of range
               </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('1144').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('1144').errors.invalidDateRange">
                    date is out of range
            </small>
         </div>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 input-group no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Active Duty Dates: <br />
                    To (mm/yy)
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker"
                      [class.is-invalid]="gaUnifiedForm.controls['1145'].invalid" 
                      placeholder="MM/DD/YYYY"
                      id="1145"
                      name="1145"
                      formControlName="1145"
                      type="text"
                      data-id="Mil Active to"
                      ngbDatepicker
                      #ga29="ngbDatepicker"
                      (blur)="handleNAchange('cmd_044', 1145)"
                       (blur)="clearInputIfInvalid('1145','cmd_044')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga29.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['1145'].invalid && (gaUnifiedForm.controls['1145'].value==''|| gaUnifiedForm.controls['1145'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('1145').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('1145').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('1145').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1145').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('1145').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Honorable Discharge:</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="gaUnifiedForm.controls['cmd_045'].invalid" 
                      id="cmd_045"
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="1"
                      (blur)="handleNAchange('cmd_044', 'cmd_045')"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_045'].touched &&
                      gaUnifiedForm.controls['cmd_045'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_045'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_045"
                      [class.is-invalid]="gaUnifiedForm.controls['cmd_045'].invalid"
                      name="cmd_045"
                      formControlName="cmd_045"
                      value="0"
                      (blur)="handleNAchange('cmd_044', 'cmd_045')"
                    />No</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_045'].touched &&
                      gaUnifiedForm.controls['cmd_045'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_045'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_045'].touched &&
                        gaUnifiedForm.controls['cmd_045'].errors) ||
                      (gaUnifiedForm.controls['cmd_045'].errors && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                  <label class="normalParagraphStyle"
                    >If no, attach Explanation Form(s).</label
                  >
                </div>
              </td>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you currently in the Reserves or National Guard?</label
                  ><br />
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_046"
                      name="cmd_046"
                      formControlName="cmd_046"
                      value="0"
                    />No</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been court-martialed?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_047"
                      name="cmd_047"
                      formControlName="cmd_047"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_047"
                      name="cmd_047"
                      formControlName="cmd_047"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle"
                    >If no, attach Explanation Form(s).</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Attach a copy of DD-214 Form.
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="20">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >F. SPONSORSHIP INFORMATION</label
                  >
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="checkbox"
                      id="cmd_04411"
                      name="cmd_04411"
                      (ngModelChange)="handleNAchange('cmd_04411')" 
                      formControlName="cmd_04411"
                      value="1"
                    />Does Not Apply</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please name your primary sponsoring physician:</label
                  >
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['397'].invalid" 
                    id="397"
                    name="397"
                    formControlName="397"
                    placeholder="Delegated Phy First Name"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 397)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['397'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['399'].invalid" 
                    id="399"
                    name="399"
                    formControlName="399"
                    placeholder="Delegated Phy Last name"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 399)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['399'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet suffix inputPadding "
                >
                  <ng-select
                    [items]="degree"
                    [class.is-invalid]="gaUnifiedForm.controls['1239'].invalid" 
                    bindLabel="name"
                    placeholder="Select Degree"
                    bindValue="id"
                    formControlName="1239"
                    (blur)="handleNAchange('cmd_04411', 1239)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1239'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Address:</label>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['408'].invalid" 
                    id="408"
                    name="408"
                    formControlName="408"
                    placeholder="Delegated Phy Add 1"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 408)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['408'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['409'].invalid" 
                    id="409"
                    name="409"
                    formControlName="409"
                    placeholder="Delegated Phy Add 2"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 409)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['409'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['410'].invalid" 
                    id="410"
                    name="410"
                    formControlName="410"
                    placeholder="Delegated City"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 410)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['410'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div
                  class="mb-3 selectDrop dropDrownSet suffix inputPadding "
                >
                  <ng-select
                    [items]="states"
                    [class.is-invalid]="gaUnifiedForm.controls['412'].invalid" 
                    bindLabel="name"
                    placeholder="Select State"
                    bindValue="id"
                    formControlName="412"
                    (blur)="handleNAchange('cmd_04411', 412)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['412'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 no-margin col-md-6 inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['413'].invalid" 
                    id="413"
                    name="413"
                    formControlName="413"
                    placeholder="Delegated Zip"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 413)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['413'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Phone Number:</label>
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['414'].invalid" 
                    id="414"
                    name="414"
                    formControlName="414"
                    placeholder="Delegated Physician Phone "
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 414)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['414'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">Fax Number</label>
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['415'].invalid" 
                    id="415"
                    name="415"
                    formControlName="415"
                    placeholder="Delegated Physician Fax"
                    type="text"
                    data-id="Mil discharge date"
                    (blur)="handleNAchange('cmd_04411', 415)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['415'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
          </table>

          <!-- <table border="1"> -->
          <!-- <tr>
              <td colspan="12">
                <h4 class="boardCertification">V. OTHER STATE HEALTH CARE LICENSES, REGISTRATIONS & CERTIFICATES</h4>
                <br />
                <i>Please include all ever held. If more room is needed please list on an attached Explanation Form.</i>
              </td>
              <td colspan="6" width="15%">
                <span class="floatInputs">
                  <label class="checkbox-inline radioStyle">
                    <input type="checkbox" id="cmd_048" name="cmd_048" formControlName="cmd_048" value="1" (change)="otherStateHealth($event)"/>
                    Does Not Apply</label>
                </span>
              </td>
            </tr> -->
          <div class="centered" class="inputBorderBox">
            <h4 class="boardCertification">
              V. OTHER STATE HEALTH CARE LICENSES, REGISTRATIONS & CERTIFICATES
            </h4>
            <br />
            <i
              >Please include all ever held. If more room is needed please list
              on an attached Explanation Form.</i
            >
            <label class="floatInputs">Does Not Apply</label>
            <input
              class="floatInputs"
              type="checkbox"
              name="cmd_048"
              id="cmd_048"
              (ngModelChange)="handleNAchange('cmd_048')" 
              formControlName="cmd_048"
              value="1"
            />
          </div>
          <!-- </table> -->
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Type and Status:
                    <input
                    [class.is-invalid]="gaUnifiedForm.controls['1274'].invalid" 
                      class="form-control"
                      id="1274"
                      name="1274"
                      formControlName="1274"
                      type="text"
                      placeholder="License 2 Type"
                      data-id="License 2 Type"
                      (blur)="handleNAchange('cmd_048', 1274)"
                    />
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['1274'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </label>
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Number:</label>
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['300'].invalid" 
                    class="form-control"
                    id="300"
                    name="300"
                    formControlName="300"
                    placeholder="License 2 Type"
                    type="text"
                    data-id="License 2 Type"
                    (blur)="handleNAchange('cmd_048', 300)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['300'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >State/Country:
                    <div
                      class="mb-3 selectDrop dropDrownSet inputPadding suffix"
                    >
                      <ng-select
                        [items]="altLicenseState"
                        [class.is-invalid]="gaUnifiedForm.controls['907'].invalid" 
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="907"
                        (blur)="handleNAchange('cmd_048', 907)"
                      >
                      </ng-select>
                      <div class="mandatory" *ngIf="gaUnifiedForm.controls['907'].invalid">
                        <small>
                            required
                        </small>
                    </div>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):
                    <div class="input-group">
                      <input
                      [class.is-invalid]="gaUnifiedForm.controls['308'].invalid" 
                        class="form-control  datepicker input-group "
                        placeholder="MM/DD/YYYY"
                        id="308"
                        name="308"
                        formControlName="308"
                        type="text"
                        data-id="alt_license_exp_date"
                        ngbDatepicker
                        #ga30="ngbDatepicker"
                        (blur)="handleNAchange('cmd_048', 308)"
                         (blur)="clearInputIfInvalid('308','cmd_048')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga30.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['308'].invalid && (gaUnifiedForm.controls['308'].value==''|| gaUnifiedForm.controls['308'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('308').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('308').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('308').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('308').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('308').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Obtained:
                    <div class="input-group">
                      <input
                      [class.is-invalid]="gaUnifiedForm.controls['307'].invalid" 
                        class="form-control  datepicker input-group "
                        placeholder="MM/DD/YYYY"
                        id="307"
                        name="307"
                        formControlName="307"
                        type="text"
                        data-id="alt_license_exp_date"
                        ngbDatepicker
                        #ga111="ngbDatepicker"
                        (blur)="handleNAchange('cmd_048', 307)"
                        (blur)="clearInputIfInvalid('307','cmd_048')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga111.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="mandatory"
                    *ngIf="gaUnifiedForm.controls['307'].invalid && (gaUnifiedForm.controls['307'].value==''|| gaUnifiedForm.controls['307'].value==null) ">
                   <small>
                    required
                 </small>

               </div>

          <div *ngIf="gaUnifiedForm.get('307').invalid">
              <small class="text-danger" *ngIf="gaUnifiedForm.get('307').errors.invalidDateFormat">
                 Only Date Format is allowed(MM/DD/YYYY) </small>
               <small class="text-danger" *ngIf="gaUnifiedForm.get('307').errors.invalidMonth">
                   Month is out of range
                 </small>
             <small class="text-danger" *ngIf="gaUnifiedForm.get('307').errors.invalidDateyear">
                      year is out of range(1900-2099) </small>
             <small class="text-danger" *ngIf="gaUnifiedForm.get('307').errors.invalidDateRange">
                      date is out of range
              </small>
           </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Relinquished:
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="3690"
                        name="3690"
                        [class.is-invalid]="gaUnifiedForm.controls['3690'].invalid" 
                        formControlName="3690"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga110="ngbDatepicker"
                        (blur)="handleNAchange('cmd_048', 3690)"
                         (blur)="clearInputIfInvalid('3690','cmd_048')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga110.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['3690'].invalid && (gaUnifiedForm.controls['3690'].value==''|| gaUnifiedForm.controls['3690'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('3690').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('3690').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('3690').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3690').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3690').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Reason:</label>
                  <input
                    class="form-control"
                    id="cmd_049"
                    name="cmd_049"
                    formControlName="cmd_049"
                    [class.is-invalid]="gaUnifiedForm.controls['cmd_049'].invalid" 
                    type="text"
                    data-id="alt licesne reason"
                    (blur)="handleNAchange('cmd_048', 'cmd_049')"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['cmd_049'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Type and Status:</label>
                  <input
                    class="form-control"
                    id="1432"
                    name="1432"
                    formControlName="1432"
                    type="text"
                    placeholder="Additional License Type"
                    data-id="sc_license_type"
                  />
                </div>
              </td>
              <td colspan="5">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Number:
                    <input
                      class="form-control"
                      id="999"
                      name="999"
                      formControlName="999"
                      type="text"
                      placeholder="Additional License Number"
                      data-id="sc_license"
                    />
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >State/Country:
                    <div class="mb-3 selectDrop dropDrownSet ">
                      <ng-select
                        [items]="additionalLicenseState"
                        bindLabel="name"
                        placeholder="Select States"
                        bindValue="id"
                        formControlName="1010"
                      >
                      </ng-select>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1004"
                        name="1004"
                        formControlName="1004"
                        type="text"
                        data-id="sc_license_epx_date"
                        ngbDatepicker
                        #ga31="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1004')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga31.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1004').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1004').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1004').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1004').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1004').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Obtained:
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1003"
                        name="1003"
                        formControlName="1003"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga32="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1003')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga32.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1003').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1003').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1003').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1003').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1003').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Year Relinquished:
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="cmd_01519"
                        name="cmd_01519"
                        formControlName="cmd_01519"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga33="ngbDatepicker"
                        (blur)="clearInputIfInvalid('cmd_01519')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga33.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('cmd_01519').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01519').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01519').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01519').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_01519').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </label>
                </div>
              </td>
              <td colspan="6">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Reason:</label>
                  <input
                    class="form-control"
                    id="cmd_051"
                    name="cmd_051"
                    formControlName="cmd_051"
                    type="text"
                    data-id="sc license reason"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              VI. CURRENT HOSPITAL AND OTHER FACILITY AFFILIATIONS
            </h4>
          </div>
          <table border="1" class="tableWidth">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">
                    <b
                      >Please list in reverse chronological order with the
                      current affiliation(s) first: </b
                    ><i
                      >(A) current hospital affiliations, (B) hospital
                      applications in process, (C) previous hospital
                      affiliations and (D) other current facility affiliations
                      (which includes surgery centers, dialysis centers, nursing
                      homes and other health care related facilities).</i
                    ><b
                      ><i>
                        Do not list residencies, internships or fellowships.
                        Please list all employment in Section VII.</i
                      ></b
                    >
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >A. CURRENT HOSPITAL AFFILIATIONS</label
                  >
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_052"
                        name="cmd_052"
                        (ngModelChange)="handleNAchange('cmd_052')" 
                        formControlName="cmd_052"
                        value="1"
                      />Does Not Apply</label
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Primary Facility Name:</label
                  >
                  <input
                    class="form-control"
                    [class.is-invalid]="gaUnifiedForm.controls['418'].invalid" 
                    id="418"
                    name="418"
                    formControlName="418"
                    placeholder="Hospital Affiliation 1 Name"
                    type="text"
                    data-id="hospaff1nm"
                    (blur)="handleNAchange('cmd_052', 418)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['418'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap selectDrop dropDrownSet inputPadding "
                >
                  <label class="normalParagraphStyle" style="padding-top:-10px"
                    >Department/Status (e.g. active, courtesy, provisional,
                    etc.):</label
                  >
                  <ng-select
                    [items]="staffStatus"
                    [class.is-invalid]="gaUnifiedForm.controls['894'].invalid" 
                    bindLabel="name"
                    placeholder="Hosp1 HoStaff status"
                    bindValue="id"
                    formControlName="894"
                    (blur)="handleNAchange('cmd_052', 894)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['894'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Appointment Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      [class.is-invalid]="gaUnifiedForm.controls['426'].invalid" 
                      placeholder="MM/DD/YYYY"
                      id="426"
                      name="426"
                      formControlName="426"
                      type="text"
                      data-id="hospaff1app"
                      ngbDatepicker
                      #ga34="ngbDatepicker"
                      (blur)="handleNAchange('cmd_052', 426)"
                      (blur)="clearInputIfInvalid('426','cmd_052')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga34.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['426'].invalid && (gaUnifiedForm.controls['426'].value==''|| gaUnifiedForm.controls['426'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('426').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('426').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('426').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('426').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('426').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
              <td colspan="12" width="60%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['419'].invalid" 
                    placeholder="Hosp Aff 1 Add1"
                    class="form-control"
                    id="419"
                    name="419"
                    formControlName="419"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_052', 419)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['419'].invalid">
                    <small>
                        required
                    </small>
                </div>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['420'].invalid" 
                    placeholder="Hosp Aff 1 Add2"
                    class="form-control"
                    id="420"
                    name="420"
                    formControlName="420"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_052', 420)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['420'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['421'].invalid" 
                    class="form-control"
                    placeholder="Hosp Aff 1 City"
                    id="421"
                    name="421"
                    formControlName="421"
                    type="text"
                    data-id="hospaff1city"
                    (blur)="handleNAchange('cmd_052', 421)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['421'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin">
                  <div class="mb-3 selectDrop dropDrownSet inputPadding">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="gaUnifiedForm.controls['423'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="423"
                      (blur)="handleNAchange('cmd_052', 423)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['423'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin  inputPadding">
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['424'].invalid" 
                    class="form-control"
                    placeholder="Hosp Aff 1 Zip"
                    id="424"
                    name="424"
                    formControlName="424"
                    type="text"
                    data-id="hospaff1zip"
                    (blur)="handleNAchange('cmd_052', 424)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['424'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Facility Name:
                    <input
                      class="form-control "
                      id="427"
                      name="427"
                      placeholder="Hosp Aff 2 Name"
                      formControlName="427"
                      [class.is-invalid]="gaUnifiedForm.controls['427'].invalid" 
                      type="text"
                      data-id="hospaff2nm"
                      (blur)="handleNAchange('cmd_052', 427)"
                    />
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['427'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </label>
                </div>
                <div class="mb-3 selectDrop dropDrownSet  inputPadding ">
                  <label class="normalParagraphStyle" style="padding-top:-10px"
                    >Department/Status (e.g. active, courtesy, provisional,
                    etc.):</label
                  >
                  <ng-select
                    [items]="staffStatus"
                    [class.is-invalid]="gaUnifiedForm.controls['895'].invalid" 
                    bindLabel="name"
                    placeholder="Hosp2 Staff status"
                    bindValue="id"
                    formControlName="895"
                    (blur)="handleNAchange('cmd_052', 895)"
                  >
                  </ng-select>
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['895'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Appointment Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="435"
                      name="435"
                      formControlName="435"
                      type="text"
                      [class.is-invalid]="gaUnifiedForm.controls['435'].invalid" 
                      data-id="hospaff2app"
                      ngbDatepicker
                      #ga35="ngbDatepicker"
                      (blur)="handleNAchange('cmd_052', 435)"
                      (blur)="clearInputIfInvalid('435','cmd_052')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga35.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['435'].invalid && (gaUnifiedForm.controls['435'].value==''|| gaUnifiedForm.controls['435'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('435').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('435').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('435').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('435').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('435').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
              <td colspan="12" width="60%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['428'].invalid" 
                    placeholder="Hosp Aff 2 Add1"
                    class="form-control"
                    id="428"
                    name="428"
                    formControlName="428"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_052', 428)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['428'].invalid">
                    <small>
                        required
                    </small>
                </div>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['429'].invalid" 
                    placeholder="Hosp Aff 2 Add2"
                    class="form-control"
                    id="429"
                    name="429"
                    formControlName="429"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_052', 429)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['429'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    class="form-control"
                    id="430"
                    name="430"
                    [class.is-invalid]="gaUnifiedForm.controls['430'].invalid" 
                    formControlName="430"
                    type="text"
                    placeholder="City"
                    data-id="hospaff2city"
                    (blur)="handleNAchange('cmd_052', 430)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['430'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 selectDrop  dropDrownSet">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      [class.is-invalid]="gaUnifiedForm.controls['432'].invalid" 
                      bindValue="id"
                      formControlName="432"
                      (blur)="handleNAchange('cmd_052', 432)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['432'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    class="form-control"
                    id="433"
                    name="433"
                    [class.is-invalid]="gaUnifiedForm.controls['433'].invalid" 
                    formControlName="433"
                    type="text"
                    placeholder="Hosp Aff 2 Zip"
                    data-id="hospaff2zip"
                    (blur)="handleNAchange('cmd_052', 433)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['433'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14" width="80%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >B. OTHER FACILITY AFFILIATIONS</label
                  >
                  <i class="normalParagraphStyle"
                    >Please list all current affiliations with other
                    facilities</i
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Facility Name:
                    <input
                      class="form-control"
                      id="1183"
                      name="1183"
                      formControlName="1183"
                      placeholder="Previous Hospital Affiliation"
                      type="text"
                      data-id="Pending app 2"
                    />
                  </label>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >From(mm/yy):
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1191"
                        name="1191"
                        formControlName="1191"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga38="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1191')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga38.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1191').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1191').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1191').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1191').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1191').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </label>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Appointment Date (mm/yy):
                    <div class="input-group">
                      <input
                        class="form-control  datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="2381"
                        name="2381"
                        formControlName="2381"
                        type="text"
                        data-id="sc license exp"
                        ngbDatepicker
                        #ga39="ngbDatepicker"
                        (blur)="clearInputIfInvalid('2381')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga39.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('2381').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('2381').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('2381').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('2381').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('2381').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </label>
                </div>
              </td>
              <td colspan="6" width="60%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 1"
                    class="form-control"
                    id="1184"
                    name="1184"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1184'].touched &&
                        gaUnifiedForm.controls['1184'].errors) ||
                      (gaUnifiedForm.controls['1184'].errors && isSubmit)
                    "
                    formControlName="1184"
                    type="text"
                    data-id="hospaff1add"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1184'].touched &&
                      gaUnifiedForm.controls['1184'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1184'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 2"
                    class="form-control"
                    id="1185"
                    name="1185"
                    formControlName="1185"
                    type="text"
                    data-id="hospaff1add"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    class="form-control"
                    id="1186"
                    name="1186"
                    formControlName="1186"
                    type="text"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1186'].touched &&
                        gaUnifiedForm.controls['1186'].errors) ||
                      (gaUnifiedForm.controls['1186'].errors && isSubmit)
                    "
                    placeholder="City"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1186'].touched &&
                      gaUnifiedForm.controls['1186'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1186'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="1188"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    class="form-control"
                    placeholder="Zip"
                    id="1189"
                    name="1189"
                    formControlName="1189"
                    type="text"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1189'].touched &&
                        gaUnifiedForm.controls['1189'].errors) ||
                      (gaUnifiedForm.controls['1189'].errors && isSubmit)
                    "
                    data-id="Pending app 2 zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1189'].touched &&
                      gaUnifiedForm.controls['1189'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1189'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Reason for Leaving:</label
                  >
                  <input
                    class="form-control inputBoxStylesOne"
                    id="1303"
                    name="1303"
                    formControlName="1303"
                    placeholder="Reason for Leaving:"
                    type="text"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1303'].touched &&
                        gaUnifiedForm.controls['1303'].errors) ||
                      (gaUnifiedForm.controls['1303'].errors && isSubmit)
                    "
                    data-id="Pending app 3 add"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1303'].touched &&
                      gaUnifiedForm.controls['1303'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1303'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              VII. PROFESSIONAL PRACTICE / WORK HISTORY
            </h4>
            <br />
            <label class="floatInputs">Does Not Apply</label>
            <input
              type="checkbox"
              name="cmd_0201"
              id="cmd_0201"
              formControlName="cmd_0201"
              (ngModelChange)="handleNAchange('cmd_0201')" 
              style="float: right;"
              value="1"
            />
            <i
              >A curriculum vitae is not sufficient for a complete answer to
              these questions.</i
            >
          </div>
          <table border="1">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please list in reverse chronological order all work and
                    professional and practice history activities not detailed
                    under Section II, IV or VI. Include any previous office
                    addresses and any military experience.
                    <u>Explain below any gaps greater than thirty (30) days.</u>
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" class="tableWidth">
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Current Practice / Employer:
                    <input
                      class="form-control"
                      [class.is-invalid]="gaUnifiedForm.controls['445'].invalid" 
                      id="445"
                      name="445"
                      formControlName="445"
                      type="text"
                      placeholder="work Hist 1 Location"
                      data-id="wkhist1"
                      (blur)="handleNAchange('cmd_0201', 445)"
                    />
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['445'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <tr>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">Contact Name:</label>
                      <input
                        class="form-control"
                        [class.is-invalid]="gaUnifiedForm.controls['452'].invalid" 
                        id="452"
                        name="452"
                        formControlName="452"
                        type="text"
                        placeholder="Work History 1 Contact"
                        data-id="wk1histcontact"
                        (blur)="handleNAchange('cmd_0201', 452)"
                      />
                      <div class="mandatory" *ngIf="gaUnifiedForm.controls['452'].invalid">
                        <small>
                            required
                        </small>
                    </div>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:</label
                      >
                      <input
                      [class.is-invalid]="gaUnifiedForm.controls['453'].invalid" 
                        class="form-control"
                        id="453"
                        name="453"
                        formControlName="453"
                        type="text"
                        data-id="wk1histtelephone"
                        placeholder="Work History 1 Telephone "
                        (blur)="handleNAchange('cmd_0201', 453)"
                      />
                      <div class="mandatory" *ngIf="gaUnifiedForm.controls['453'].invalid">
                        <small>
                            required
                        </small>
                    </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">From (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          [class.is-invalid]="gaUnifiedForm.controls['454'].invalid" 
                          placeholder="MM/DD/YYYY"
                          id="454"
                          name="454"
                          formControlName="454"
                          type="text"
                          data-id="wk1histstartdt"
                          ngbDatepicker
                          #ga49="ngbDatepicker"
                          (blur)="handleNAchange('cmd_0201', 454)"
                          (blur)="clearInputIfInvalid('454','cmd_0201')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga49.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['454'].invalid && (gaUnifiedForm.controls['454'].value==''|| gaUnifiedForm.controls['454'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('454').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('454').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('454').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('454').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('454').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">To (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control onboard datepicker"
                          [class.is-invalid]="gaUnifiedForm.controls['455'].invalid" 
                          placeholder="MM/DD/YYYY"
                          ngbDatepicker
                          #ga50="ngbDatepicker"
                          id="455"
                          name="455"
                          formControlName="455"
                          type="text"
                          data-id="wk1histenddt"
                          (blur)="handleNAchange('cmd_0201', 455)"
                          (blur)="clearInputIfInvalid('455','cmd_0201')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga50.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div class="mandatory"
                      *ngIf="gaUnifiedForm.controls['455'].invalid && (gaUnifiedForm.controls['455'].value==''|| gaUnifiedForm.controls['455'].value==null) ">
                     <small>
                      required
                   </small>
 
                 </div>
 
            <div *ngIf="gaUnifiedForm.get('455').invalid">
                <small class="text-danger" *ngIf="gaUnifiedForm.get('455').errors.invalidDateFormat">
                   Only Date Format is allowed(MM/DD/YYYY) </small>
                 <small class="text-danger" *ngIf="gaUnifiedForm.get('455').errors.invalidMonth">
                     Month is out of range
                   </small>
               <small class="text-danger" *ngIf="gaUnifiedForm.get('455').errors.invalidDateyear">
                        year is out of range(1900-2099) </small>
               <small class="text-danger" *ngIf="gaUnifiedForm.get('455').errors.invalidDateRange">
                        date is out of range
                </small>
             </div>
                    </div>
                  </td>
                </tr>
              </td>

              <td colspan="6" width="40%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['446'].invalid" 
                    placeholder="Address 1"
                    class="form-control"
                    id="446"
                    name="446"
                    formControlName="446"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_0201', 446)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['446'].invalid">
                    <small>
                        required
                    </small>
                </div>
                  <input
                    class="inputBoxStylesOne"
                    [class.is-invalid]="gaUnifiedForm.controls['447'].invalid" 
                    placeholder="Address 1"
                    class="form-control"
                    id="447"
                    name="447"
                    formControlName="447"
                    type="text"
                    data-id="hospaff1add"
                    (blur)="handleNAchange('cmd_0201', 447)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['447'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding"
                >
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['448'].invalid" 
                    placeholder="City"
                    class="form-control"
                    id="448"
                    name="448"
                    formControlName="448"
                    type="text"
                    data-id="Work hist 1 city"
                    (blur)="handleNAchange('cmd_0201', 448)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['448'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-4 inputPadding suffix"
                >
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="gaUnifiedForm.controls['450'].invalid" 
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="450"
                      (blur)="handleNAchange('cmd_0201', 450)"
                    >
                    </ng-select>
                    <div class="mandatory" *ngIf="gaUnifiedForm.controls['450'].invalid">
                      <small>
                          required
                      </small>
                  </div>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                  [class.is-invalid]="gaUnifiedForm.controls['451'].invalid" 
                    class="form-control"
                    placeholder="Zip"
                    id="451"
                    name="451"
                    formControlName="451"
                    type="text"
                    data-id="Work hist 1 zip"
                    (blur)="handleNAchange('cmd_0201', 451)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['451'].invalid">
                    <small>
                        required
                    </small>
                </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="25">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Previous Practice / Employer:
                    <input
                      class="form-control"
                      id="456"
                      name="456"
                      formControlName="456"
                      placeholder="Work hist 2 Location"
                      type="text"
                      data-id="wkhist2"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">Contact Name:</label>
                      <input
                        class="form-control"
                        id="463"
                        name="463"
                        formControlName="463"
                        type="text"
                        data-id="wkhist2contact"
                        placeholder="Work hist 2 Contact"
                      />
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:
                        <input
                          style="width: 50%"
                          class="form-control"
                          id="464"
                          name="464"
                          formControlName="464"
                          placeholder="Work hist 2 Telephone"
                          type="text"
                          data-id="wkhist2telephone"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">From (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          placeholder="MM/DD/YYYY"
                          id="465"
                          name="465"
                          formControlName="465"
                          type="text"
                          data-id="wkhist2startdt"
                          ngbDatepicker
                          #ga51="ngbDatepicker"
                          (blur)="clearInputIfInvalid('465')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga51.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="gaUnifiedForm.get('465').invalid ">
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('465').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('465').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('465').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('465').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">To (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          placeholder="MM/DD/YYYY"
                          id="466"
                          name="466"
                          formControlName="466"
                          type="text"
                          data-id="wkhist2enddt"
                          ngbDatepicker
                          #ga52="ngbDatepicker"
                          (blur)="clearInputIfInvalid('466')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga52.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="gaUnifiedForm.get('466').invalid ">
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('466').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('466').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('466').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('466').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    </div>
                  </td>
                </tr>
              </td>

              <td colspan="6" width="40%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 1"
                    class="form-control"
                    id="457"
                    name="457"
                    formControlName="457"
                    type="text"
                    data-id="hospaff1add"
                  />
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 2"
                    class="form-control"
                    id="458"
                    name="458"
                    formControlName="458"
                    type="text"
                    data-id="hospaff1add"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    placeholder="City"
                    class="form-control"
                    placeholder="City"
                    id="459"
                    name="459"
                    formControlName="459"
                    type="text"
                    data-id="Work hist 1 city"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <ng-select
                    [items]="states"
                    bindLabel="name"
                    placeholder="Select States"
                    bindValue="id"
                    formControlName="461"
                  >
                  </ng-select>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['462'].touched &&
                        gaUnifiedForm.controls['462'].invalid) ||
                      (gaUnifiedForm.controls['462'].invalid && isSubmit)
                    "
                    class="form-control"
                    placeholder="Zip"
                    id="462"
                    name="462"
                    formControlName="462"
                    type="text"
                    data-id="Zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['462'].touched &&
                      gaUnifiedForm.controls['462'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['462'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Previous Practice / Employer:</label
                  >
                  <input
                    class="form-control"
                    id="467"
                    name="467"
                    placeholder="Work hist 3 Location"
                    formControlName="467"
                    type="text"
                    data-id="wkhist3"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">Contact Name:</label>
                      <input
                        class="form-control"
                        id="474"
                        name="474"
                        formControlName="474"
                        type="text"
                        placeholder="Work hist 3 Contact"
                        data-id="wkhist3contact"
                      />
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle"
                        >Telephone Number:</label
                      >
                      <input
                        style="width: 50%"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['475'].touched &&
                            gaUnifiedForm.controls['475'].invalid) ||
                          (gaUnifiedForm.controls['475'].invalid && isSubmit)
                        "
                        class="form-control"
                        id="475"
                        name="475"
                        formControlName="475"
                        placeholder="Work hist 3 Telephone"
                        type="text"
                        data-id="wkhist3telephone"
                      />
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaUnifiedForm.controls['475'].touched &&
                          gaUnifiedForm.controls['475'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaUnifiedForm.controls['475'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">From (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group"
                          placeholder="MM/DD/YYYY"
                          id="476"
                          name="476"
                          formControlName="476"
                          type="text"
                          data-id="wkhist3startdt"
                          ngbDatepicker
                          #ga53="ngbDatepicker"
                          (blur)="clearInputIfInvalid('476')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga53.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="gaUnifiedForm.get('476').invalid ">
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('476').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('476').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('476').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('476').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    </div>
                  </td>
                  <td colspan="9">
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <label class="normalParagraphStyle">To (mm/yy):</label>
                      <div class="input-group">
                        <input
                          class="form-control  datepicker input-group "
                          placeholder="MM/DD/YYYY"
                          id="477"
                          name="477"
                          formControlName="477"
                          type="text"
                          data-id="wkhist3enddt"
                          ngbDatepicker
                          #ga101="ngbDatepicker"
                          (blur)="clearInputIfInvalid('477')"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            (click)="ga101.toggle()"
                            type="button"
                          >
                            <i class="fas fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div  *ngIf="gaUnifiedForm.get('477').invalid ">
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('477').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('477').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('477').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('477').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                    </div>
                  </td>
                </tr>
              </td>

              <td colspan="6" width="40%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-12 inputPadding"
                >
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 1"
                    class="form-control"
                    id="468"
                    name="468"
                    formControlName="457"
                    type="text"
                    data-id="hospaff1add"
                  />
                  <input
                    class="inputBoxStylesOne"
                    placeholder="Address 2"
                    class="form-control"
                    id="469"
                    name="469"
                    formControlName="458"
                    type="text"
                    data-id="hospaff1add"
                  />
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    placeholder="City"
                    class="form-control"
                    id="470"
                    name="470"
                    formControlName="470"
                    type="text"
                    data-id="Work hist 1 city"
                  />
                </div>
                <div class="mb-3 no-margin inputPadding suffix">
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="472"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin inputPadding">
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['473'].touched &&
                        gaUnifiedForm.controls['473'].invalid) ||
                      (gaUnifiedForm.controls['473'].invalid && isSubmit)
                    "
                    placeholder="Zip"
                    id="473"
                    name="473"
                    formControlName="473"
                    type="text"
                    data-id="Work hist 1 zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['473'].touched &&
                      gaUnifiedForm.controls['473'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['473'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="16" width="70%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >If your training, practice, military or work experience has
                    been interrupted for more than thirty (30) days by, for
                    example, illness, injury or family medical leave, then
                    please explain below any such gap since completing medical
                    school.
                  </label>
                </div>
              </td>
              <td colspan="4" width="30%">
                <label class="checkbox-inline radioStyle">
                  <input
                    type="checkbox"
                    id="cmd_081"
                    name="cmd_081"
                    formControlName="cmd_081"
                    (ngModelChange)="handleNAchange('cmd_081')" 
                    value="1"
                  />
                  Does Not Apply</label
                >
              </td>
            </tr>
            <tr>
              <td colspan="6" width="60%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Explanation of Interruption:</label
                  >
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">From (mm/yy):</label>
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle">To (mm/yy):</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="60%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="gaUnifiedForm.controls['1138'].invalid" 
                    class="form-control"
                    id="1138"
                    name="1138"
                    formControlName="1138"
                    placeholder="Explanation for Gap in Work History1"
                    type="text"
                    data-id="Gap 1"
                    (blur)="handleNAchange('cmd_081', 1138)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1138'].invalid">
                    <small>
                        required
                    </small>
                </div>  
                </div>
              </td>
              <td colspan="6" width="20%">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding input-group"
                >
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker"
                      placeholder="MM/DD/YYYY"
                      id="3395"
                      name="3395"
                      formControlName="3395"
                      [class.is-invalid]="gaUnifiedForm.controls['3395'].invalid" 
                      type="text"
                      data-id="Gap from 1"
                      ngbDatepicker
                      #ga54="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3395)"
                      (blur)="clearInputIfInvalid('3395','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga54.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                  *ngIf="gaUnifiedForm.controls['3395'].invalid && (gaUnifiedForm.controls['3395'].value==''|| gaUnifiedForm.controls['3395'].value==null) ">
                 <small>
                  required
               </small>

             </div>

        <div *ngIf="gaUnifiedForm.get('3395').invalid">
            <small class="text-danger" *ngIf="gaUnifiedForm.get('3395').errors.invalidDateFormat">
               Only Date Format is allowed(MM/DD/YYYY) </small>
             <small class="text-danger" *ngIf="gaUnifiedForm.get('3395').errors.invalidMonth">
                 Month is out of range
               </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('3395').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('3395').errors.invalidDateRange">
                    date is out of range
            </small>
         </div>
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="3396"
                      name="3396"
                      [class.is-invalid]="gaUnifiedForm.controls['3396'].invalid" 
                      formControlName="3396"
                      type="text"
                      data-id="Gap to 1"
                      ngbDatepicker
                      #ga55="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3396)"
                      (blur)="clearInputIfInvalid('3396','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga55.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                  *ngIf="gaUnifiedForm.controls['3396'].invalid && (gaUnifiedForm.controls['3396'].value==''|| gaUnifiedForm.controls['3396'].value==null) ">
                 <small>
                  required
               </small>

             </div>

        <div *ngIf="gaUnifiedForm.get('3396').invalid">
            <small class="text-danger" *ngIf="gaUnifiedForm.get('3396').errors.invalidDateFormat">
               Only Date Format is allowed(MM/DD/YYYY) </small>
             <small class="text-danger" *ngIf="gaUnifiedForm.get('3396').errors.invalidMonth">
                 Month is out of range
               </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('3396').errors.invalidDateyear">
                    year is out of range(1900-2099) </small>
           <small class="text-danger" *ngIf="gaUnifiedForm.get('3396').errors.invalidDateRange">
                    date is out of range
            </small>
         </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="60%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="gaUnifiedForm.controls['1831'].invalid" 
                    class="form-control"
                    id="1831"
                    name="1831"
                    placeholder="Explanation for Gap in Work History2"
                    formControlName="1831"
                    type="text"
                    data-id="Gap 2"
                    (blur)="handleNAchange('cmd_081', 1831)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1831'].invalid">
                    <small>
                        required
                    </small>
                </div>  
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker  input-group "
                      placeholder="MM/DD/YYYY"
                      id="3397"
                      name="3397"
                      [class.is-invalid]="gaUnifiedForm.controls['3397'].invalid" 
                      formControlName="3397"
                      type="text"
                      data-id="Gap from 2"
                      ngbDatepicker
                      #ga56="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3397)"
                      (blur)="clearInputIfInvalid('3397','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga56.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['3397'].invalid && (gaUnifiedForm.controls['3397'].value==''|| gaUnifiedForm.controls['3397'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('3397').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('3397').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('3397').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3397').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3397').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="3398"
                      name="3398"
                      [class.is-invalid]="gaUnifiedForm.controls['3398'].invalid" 
                      formControlName="3398"
                      type="text"
                      data-id="Gap to 2"
                      ngbDatepicker
                      #ga57="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3398)"
                      (blur)="clearInputIfInvalid('3398','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga57.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['3398'].invalid && (gaUnifiedForm.controls['3398'].value==''|| gaUnifiedForm.controls['3398'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('3398').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('3398').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('3398').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3398').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3398').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="60%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="gaUnifiedForm.controls['1832'].invalid" 
                    class="form-control"
                    id="1832"
                    placeholder="Explanation for Gap in Work History3"
                    name="1832"
                    formControlName="1832"
                    type="text"
                    data-id="Gap 3"
                    (blur)="handleNAchange('cmd_081', 1832)"
                  />
                  <div class="mandatory" *ngIf="gaUnifiedForm.controls['1832'].invalid">
                    <small>
                        required
                    </small>
                </div>                      
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="3399"
                      name="3399"
                      [class.is-invalid]="gaUnifiedForm.controls['3399'].invalid" 
                      formControlName="3399"
                      type="text"
                      data-id="Gap from 3"
                      ngbDatepicker
                      #ga58="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3399)"
                      (blur)="clearInputIfInvalid('3399','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga58.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['3399'].invalid && (gaUnifiedForm.controls['3399'].value==''|| gaUnifiedForm.controls['3399'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('3399').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('3399').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('3399').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3399').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3399').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
              <td colspan="6" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="input-group">
                    <input
                      style="width: 50%"
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="3400"
                      name="3400"
                      [class.is-invalid]="gaUnifiedForm.controls['3400'].invalid" 
                      formControlName="3400"
                      type="text"
                      data-id="Gap to 3"
                      ngbDatepicker
                      #ga59="ngbDatepicker"
                      (blur)="handleNAchange('cmd_081', 3400)"
                      (blur)="clearInputIfInvalid('3400','cmd_081')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga59.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory"
                     *ngIf="gaUnifiedForm.controls['3400'].invalid && (gaUnifiedForm.controls['3400'].value==''|| gaUnifiedForm.controls['3400'].value==null) ">
                    <small>
                     required
                  </small>

                </div>

           <div *ngIf="gaUnifiedForm.get('3400').invalid">
               <small class="text-danger" *ngIf="gaUnifiedForm.get('3400').errors.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY) </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('3400').errors.invalidMonth">
                    Month is out of range
                  </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3400').errors.invalidDateyear">
                       year is out of range(1900-2099) </small>
              <small class="text-danger" *ngIf="gaUnifiedForm.get('3400').errors.invalidDateRange">
                       date is out of range
               </small>
            </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">VIII. PROFESSIONAL REFERENCES</h4>
          </div>
          <table border="1">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please list three (3) references, from licensed
                    professional peers who through recent observations have
                    personal knowledge of and are directly familiar with your
                    professional competence, conduct and work. Do not include
                    relatives. At least one reference must be a practitioner in
                    your same professional discipline. (Please refer to Part Two
                    of this Application for any additional specific reference
                    requirements.)</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Name of Reference:
                          <input
                            class="form-control"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['359'].touched &&
                                gaUnifiedForm.controls['359'].invalid) ||
                              (gaUnifiedForm.controls['359'].invalid &&
                                isSubmit)
                            "
                            id="359"
                            name="359"
                            formControlName="359"
                            type="text"
                            placeholder="Ref 1 Name"
                            data-id="reference1name"
                          />
                          <div
                            class="formLabel"
                            *ngIf="
                              gaUnifiedForm.controls['359'].touched &&
                              gaUnifiedForm.controls['359'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaUnifiedForm.controls['359'].errors"
                            >
                              required
                            </small>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin">
                        <label class="normalParagraphStyle">Specialty:</label>
                        <div
                          class="mb-3 selectDrop inputPadding suffix col-md-3"
                        >
                          <ng-select
                            [items]="specialty"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['368'].touched &&
                                gaUnifiedForm.controls['368'].invalid) ||
                              (gaUnifiedForm.controls['368'].invalid &&
                                isSubmit)
                            "
                            bindLabel="name"
                            placeholder="Select Specialty"
                            bindValue="id"
                            formControlName="368"
                          >
                          </ng-select>
                          <div
                            class="formLabel col-md-11"
                            *ngIf="
                              gaUnifiedForm.controls['368'].touched &&
                              gaUnifiedForm.controls['368'].invalid
                            "
                          >
                            <small
                              class="text-danger"
                              *ngIf="gaUnifiedForm.controls['368'].errors"
                            >
                              required
                            </small>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Dates of Association:</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control  datepicker  input-group "
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['366'].touched &&
                                gaUnifiedForm.controls['366'].invalid) ||
                              (gaUnifiedForm.controls['366'].invalid &&
                                isSubmit)
                            "
                            placeholder="MM/DD/YYYY"
                            id="366"
                            name="366"
                            formControlName="366"
                            type="text"
                            data-id="reference1date1"
                            ngbDatepicker
                            #ga60="ngbDatepicker"
                            (blur)="clearInputIfInvalidMandi('366')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga60.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        -
                        <div class="mandatory" *ngIf="(gaUnifiedForm.controls['366'].invalid&&gaUnifiedForm.controls['366'].touched) && (gaUnifiedForm.controls['366'].value==''|| gaUnifiedForm.controls['366'].value==null) " >
                          <small >
                          required
                          </small>
        
                      </div>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('366').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('366').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('366').errors?.invalidDateyear">
                          year is out of range(1900-2099)                  </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('366').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['367'].touched &&
                                gaUnifiedForm.controls['367'].invalid) ||
                              (gaUnifiedForm.controls['367'].invalid &&
                                isSubmit)
                            "
                            placeholder="MM/DD/YYYY"
                            id="367"
                            name="367"
                            formControlName="367"
                            type="text"
                            data-id="reference1date2"
                            ngbDatepicker
                            #ga62="ngbDatepicker"
                            (blur)="clearInputIfInvalidMandi('367')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga62.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div class="mandatory" *ngIf="(gaUnifiedForm.controls['367'].invalid&&gaUnifiedForm.controls['367'].touched) && (gaUnifiedForm.controls['367'].value==''|| gaUnifiedForm.controls['367'].value==null) " >
                          <small >
                          required
                          </small>
        
                      </div>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('367').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)                </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('367').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('367').errors?.invalidDateyear">
                          year is out of range(1900-2099)                  </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('367').errors?.invalidDateRange">
                            date is out of range
                            </small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle"
                          >Telephone Number:</label
                        >
                        <input
                          class="form-control"
                          [class.is-invalid]="
                            (gaUnifiedForm.controls['369'].touched &&
                              gaUnifiedForm.controls['369'].invalid) ||
                            (gaUnifiedForm.controls['369'].invalid && isSubmit)
                          "
                          id="369"
                          name="369"
                          formControlName="369"
                          placeholder="Ref 1 Telephone"
                          type="text"
                          data-id="reference1telephone"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['369'].touched &&
                            gaUnifiedForm.controls['369'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['369'].errors"
                          >
                            required
                          </small>
                        </div>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle">Fax Number:</label>
                        <input
                          class="form-control"
                          id="370"
                          name="370"
                          [class.is-invalid]="
                            (gaUnifiedForm.controls['370'].touched &&
                              gaUnifiedForm.controls['370'].errors) ||
                            (gaUnifiedForm.controls['370'].errors && isSubmit)
                          "
                          formControlName="370"
                          placeholder="Ref 1 Fax"
                          type="text"
                          data-id="reference1fax"
                        />
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['370'].touched &&
                            gaUnifiedForm.controls['370'].errors
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['370'].errors"
                          >
                            required
                          </small>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Email:</label>
                  <input
                    style="width: 90%;"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['371'].touched &&
                        gaUnifiedForm.controls['371'].invalid) ||
                      (gaUnifiedForm.controls['371'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="371"
                    name="371"
                    placeholder="Ref 1 Email"
                    formControlName="371"
                    type="text"
                    data-id="reference1address"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['371'].touched &&
                      gaUnifiedForm.controls['371'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['371'].errors"
                    >
                      required
                    </small>
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['371'].errors.pattern"
                    >
                      Please enter a valid email.
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['360'].touched &&
                        gaUnifiedForm.controls['360'].invalid) ||
                      (gaUnifiedForm.controls['360'].invalid && isSubmit)
                    "
                    id="360"
                    name="360"
                    formControlName="360"
                    placeholder="Ref 1 Add1"
                    type="text"
                    data-id="reference1address"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['360'].touched &&
                      gaUnifiedForm.controls['360'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['360'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"></label>
                  <input
                    class="form-control inputBoxStylesOne"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['361'].touched &&
                        gaUnifiedForm.controls['361'].invalid) ||
                      (gaUnifiedForm.controls['361'].invalid && isSubmit)
                    "
                    id="361"
                    name="361"
                    formControlName="361"
                    placeholder="Ref 1 Add1"
                    type="text"
                    data-id="reference1address"
                    placeholder="Ref 1 Add2"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['361'].touched &&
                      gaUnifiedForm.controls['361'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['361'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['362'].touched &&
                        gaUnifiedForm.controls['362'].invalid) ||
                      (gaUnifiedForm.controls['362'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="362"
                    name="362"
                    formControlName="362"
                    placeholder="Ref 1 city"
                    type="text"
                    data-id="reference1city"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['362'].touched &&
                      gaUnifiedForm.controls['362'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['362'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['364'].touched &&
                          gaUnifiedForm.controls['364'].invalid) ||
                        (gaUnifiedForm.controls['364'].invalid && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="364"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['364'].touched &&
                        gaUnifiedForm.controls['364'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['364'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['365'].touched &&
                        gaUnifiedForm.controls['365'].invalid) ||
                      (gaUnifiedForm.controls['365'].invalid && isSubmit)
                    "
                    class="form-control"
                    id="365"
                    name="365"
                    formControlName="365"
                    placeholder="Ref 1 Zip"
                    type="text"
                    data-id="reference1zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['365'].touched &&
                      gaUnifiedForm.controls['365'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['365'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Name of Reference:</label
                        >
                        <input
                          class="form-control"
                          id="372"
                          name="372"
                          formControlName="372"
                          placeholder="Ref 2 Name"
                          type="text"
                          data-id="reference2name"
                        />
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Specialty:
                          <div class="mb-3 selectDrop suffix">
                            <ng-select
                              [items]="specialty"
                              bindLabel="name"
                              placeholder="Select Specialty"
                              bindValue="id"
                              formControlName="380"
                            >
                            </ng-select>
                          </div>
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Dates of Association:</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group "
                            placeholder="MM/DD/YYYY"
                            id="817"
                            name="817"
                            formControlName="817"
                            type="text"
                            data-id="reference2date1"
                            ngbDatepicker
                            #ga63="ngbDatepicker"
                            (blur)="clearInputIfInvalid('817')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga63.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="gaUnifiedForm.get('817').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('817').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('817').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('817').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('817').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                        -
                        <div class="input-group">
                          <input
                            class="form-control  datepicker"
                            placeholder="MM/DD/YYYY"
                            id="379"
                            name="379"
                            formControlName="379"
                            type="text"
                            data-id="reference2date2"
                            ngbDatepicker
                            #ga64="ngbDatepicker"
                            (blur)="clearInputIfInvalid('379')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga64.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>

                        <div  *ngIf="gaUnifiedForm.get('379').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('379').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('379').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('379').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('379').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle"
                          >Telephone Number:
                          <input
                            class="form-control"
                            id="381"
                            name="381"
                            placeholder="Ref 2 Telephone"
                            formControlName="381"
                            type="text"
                            data-id="reference2telephone"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle"
                          >Fax Number:
                          <input
                            class="form-control"
                            id="382"
                            name="382"
                            formControlName="382"
                            placeholder="Ref 2 Fax"
                            type="text"
                            data-id="reference2fax"
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Email:</label>
                  <input
                    style="width: 90%;"
                    class="form-control"
                    id="383"
                    name="383"
                    formControlName="383"
                    placeholder="Ref 2 Email"
                    type="text"
                    data-id="reference1address"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesOne"
                    id="373"
                    name="373"
                    formControlName="373"
                    placeholder="Ref 2 Add1"
                    type="text"
                    data-id="reference1address"
                  />
                  <input
                    class="form-control inputBoxStylesOne"
                    id="374"
                    name="374"
                    formControlName="374"
                    placeholder="Ref 2 Add2"
                    type="text"
                    data-id="reference1address"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="375"
                    name="375"
                    formControlName="375"
                    placeholder="Ref 2 City"
                    type="text"
                    data-id="reference1city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="377"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="378"
                    name="378"
                    formControlName="378"
                    placeholder="Ref 2 Zip"
                    type="text"
                    data-id="reference1zip"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" width="60%">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Name of Reference:</label
                        >
                        <input
                          class="form-control"
                          id="384"
                          name="384"
                          formControlName="384"
                          placeholder="Ref 3 Name"
                          type="text"
                          data-id="reference3name"
                        />
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle">Specialty:</label>
                        <div class="mb-3 selectDrop suffix">
                          <ng-select
                            [items]="specialty"
                            bindLabel="name"
                            placeholder="Select Specialty"
                            bindValue="id"
                            formControlName="393"
                          >
                          </ng-select>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="18">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >Dates of Association:</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            placeholder="MM/DD/YYYY"
                            id="391"
                            name="391"
                            formControlName="391"
                            type="text"
                            data-id="reference3date1"
                            ngbDatepicker
                            #ga102="ngbDatepicker"
                            (blur)="clearInputIfInvalid('391')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga102.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div  *ngIf="gaUnifiedForm.get('391').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('391').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('391').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('391').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('391').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                        -
                        <div class="input-group">
                          <input
                            class="form-control  datepicker input-group"
                            placeholder="MM/DD/YYYY"
                            id="392"
                            name="392"
                            formControlName="392"
                            type="text"
                            data-id="reference3date2"
                            ngbDatepicker
                            #ga65="ngbDatepicker"
                            (blur)="clearInputIfInvalid('392')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              (click)="ga65.toggle()"
                              type="button"
                            >
                              <i class="fas fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>

                        <div  *ngIf="gaUnifiedForm.get('392').invalid ">
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('392').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('392').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="gaUnifiedForm.get('392').errors?.invalidDateyear">
                            year is out of range(1900-2099)                      </small>
                            <small class="text-danger" *ngIf="gaUnifiedForm.get('392').errors?.invalidDateRange">
                              date is out of range
                              </small>
                          </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle"
                          >Telephone Number:
                          <input
                            class="form-control"
                            id="394"
                            name="394"
                            formControlName="394"
                            placeholder="Ref 3 Telephone"
                            type="text"
                            data-id="reference3telephone"
                          />
                        </label>
                      </div>
                    </td>
                    <td colspan="9">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputBoxStylesTwo"
                      >
                        <label class="normalParagraphStyle">Fax Number:</label>
                        <input
                          class="form-control"
                          id="395"
                          name="395"
                          formControlName="395"
                          placeholder="Ref 3 Fax"
                          type="text"
                          data-id="reference3fax"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td colspan="6" width="40%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Email:</label>
                  <input
                    id="396"
                    name="396"
                    formControlName="396"
                    placeholder="Ref3 Email"
                    type="text"
                    data-id="reference1address"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Complete Address:</label>
                  <input
                    class="form-control inputBoxStylesThree"
                    id="385"
                    name="385"
                    formControlName="385"
                    placeholder="Ref 3 Add1"
                    type="text"
                    data-id="reference1address"
                  />
                  <input
                    class="form-control inputBoxStylesThree"
                    id="386"
                    name="386"
                    formControlName="386"
                    placeholder="Ref 3 Add2"
                    type="text"
                    data-id="reference1address"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="387"
                    name="387"
                    formControlName="387"
                    placeholder="Ref 3 city"
                    type="text"
                    data-id="reference1city"
                  />
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="389"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="390"
                    name="390"
                    formControlName="390"
                    placeholder="Ref 3 Zip"
                    type="text"
                    data-id="reference1zip"
                  />
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">
              IX. PROFESSIONAL LIABILITY INSURANCE
            </h4>
          </div>
          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Current Insurance Carrier / Provider of Professional
                    Liability Coverage:</label
                  >
                  <input
                    class="form-control "
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['345'].touched &&
                        gaUnifiedForm.controls['345'].errors) ||
                      (gaUnifiedForm.controls['345'].errors && isSubmit)
                    "
                    id="345"
                    name="345"
                    formControlName="345"
                    type="text"
                    data-id="mlp1carrier"
                    placeholder="MLP Carrier Name"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['345'].touched &&
                      gaUnifiedForm.controls['345'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['345'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Policy Number</label>
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['346'].touched &&
                        gaUnifiedForm.controls['346'].errors) ||
                      (gaUnifiedForm.controls['346'].errors && isSubmit)
                    "
                    id="346"
                    name="346"
                    formControlName="346"
                    placeholder="MLP Policy Number"
                    type="text"
                    data-id="mlp1pol"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['346'].touched &&
                      gaUnifiedForm.controls['346'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['346'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Type of Coverage (check one):</label
                  >
                  <input
                    class="form-control "
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['347'].touched &&
                        gaUnifiedForm.controls['347'].errors) ||
                      (gaUnifiedForm.controls['347'].errors && isSubmit)
                    "
                    id="347"
                    name="347"
                    formControlName="347"
                    placeholder="MLP Type of Coverage"
                    type="text"
                    data-id="mlp1pol"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['347'].touched &&
                      gaUnifiedForm.controls['347'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['347'].errors"
                    >
                      required
                    </small>
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_088"
                        formControlName="cmd_088"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['cmd_088'].touched &&
                            gaUnifiedForm.controls['cmd_088'].errors) ||
                          (gaUnifiedForm.controls['cmd_088'].errors && isSubmit)
                        "
                        name="cmd_088"
                        value="0"
                      />
                      Claims-Made
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaUnifiedForm.controls['cmd_088'].touched &&
                          gaUnifiedForm.controls['cmd_088'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaUnifiedForm.controls['cmd_088'].errors"
                        >
                          required
                        </small>
                      </div>
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_088"
                        formControlName="cmd_088"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['cmd_088'].touched &&
                            gaUnifiedForm.controls['cmd_088'].errors) ||
                          (gaUnifiedForm.controls['cmd_088'].errors && isSubmit)
                        "
                        name="cmd_088"
                        value="1"
                      />
                      Occurrence
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaUnifiedForm.controls['cmd_088'].touched &&
                          gaUnifiedForm.controls['cmd_088'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaUnifiedForm.controls['cmd_088'].errors"
                        >
                          required
                        </small>
                      </div>
                      <span
                        *ngIf="
                          (gaUnifiedForm.controls['cmd_088'].touched &&
                            gaUnifiedForm.controls['cmd_088'].invalid) ||
                          (gaUnifiedForm.controls['cmd_088'].invalid &&
                            isSubmit)
                        "
                      >
                        <i class="fas fa-times ms-2 text-danger"></i>
                      </span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Local Contact (e.g. Insurance Agent or
                    Broker):</label
                  >
                  <input
                    class="form-control"
                    id="1240"
                    name="1240"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1240'].touched &&
                        gaUnifiedForm.controls['1240'].errors) ||
                      (gaUnifiedForm.controls['1240'].errors && isSubmit)
                    "
                    formControlName="1240"
                    placeholder="MLP Agents Name "
                    type="text"
                    data-id="MLP agent"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1240'].touched &&
                      gaUnifiedForm.controls['1240'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1240'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Contact Telephone Number:</label
                  >
                  <input
                    class="form-control"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1266'].touched &&
                        gaUnifiedForm.controls['1266'].errors) ||
                      (gaUnifiedForm.controls['1266'].errors && isSubmit)
                    "
                    id="1266"
                    name="1266"
                    formControlName="1266"
                    placeholder="MLP Phone"
                    type="text"
                    data-id="MLP Phone"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1266'].touched &&
                      gaUnifiedForm.controls['1266'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1266'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="25%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Per claim limit of liability:
                    <div class="mb-3 selectDrop dropDrownSet suffix">
                      <ng-select
                        [items]="preClaim"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['350'].touched &&
                            gaUnifiedForm.controls['350'].errors) ||
                          (gaUnifiedForm.controls['350'].errors && isSubmit)
                        "
                        bindLabel="name"
                        placeholder="Select PreClaim"
                        bindValue="id"
                        formControlName="350"
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          gaUnifiedForm.controls['350'].touched &&
                          gaUnifiedForm.controls['350'].errors
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="gaUnifiedForm.controls['350'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="6" width="15%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Aggregate amount:</label>
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="aggregates"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['351'].touched &&
                          gaUnifiedForm.controls['351'].errors) ||
                        (gaUnifiedForm.controls['351'].errors && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select Aggregate"
                      bindValue="id"
                      formControlName="351"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['351'].touched &&
                        gaUnifiedForm.controls['351'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['351'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="6" width="60%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Mailing Address:</label>
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['901'].touched &&
                        gaUnifiedForm.controls['901'].errors) ||
                      (gaUnifiedForm.controls['901'].errors && isSubmit)
                    "
                    class="form-control"
                    id="901"
                    placeholder="MLP Add1"
                    formControlName="901"
                    name="901"
                    type="text"
                    data-id="MLP Mailing Add"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['901'].touched &&
                      gaUnifiedForm.controls['901'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['901'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Mailing Address:</label>
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['902'].touched &&
                        gaUnifiedForm.controls['902'].errors) ||
                      (gaUnifiedForm.controls['902'].errors && isSubmit)
                    "
                    class="form-control"
                    id="902"
                    placeholder="MLP Add2"
                    formControlName="902"
                    name="902"
                    type="text"
                    data-id="MLP Mailing Add"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['902'].touched &&
                      gaUnifiedForm.controls['902'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['902'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['903'].touched &&
                        gaUnifiedForm.controls['903'].errors) ||
                      (gaUnifiedForm.controls['903'].errors && isSubmit)
                    "
                    class="form-control"
                    id="903"
                    name="903"
                    formControlName="903"
                    placeholder="MLP City"
                    type="text"
                    data-id="MLP City"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['903'].touched &&
                      gaUnifiedForm.controls['903'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['903'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['905'].touched &&
                          gaUnifiedForm.controls['905'].errors) ||
                        (gaUnifiedForm.controls['905'].errors && isSubmit)
                      "
                      bindLabel="name"
                      placeholder="Select States"
                      bindValue="id"
                      formControlName="905"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        gaUnifiedForm.controls['905'].touched &&
                        gaUnifiedForm.controls['905'].errors
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="gaUnifiedForm.controls['905'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
                <div class="mb-3 no-margin col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['906'].touched &&
                        gaUnifiedForm.controls['906'].errors) ||
                      (gaUnifiedForm.controls['906'].errors && isSubmit)
                    "
                    class="form-control"
                    id="906"
                    name="906"
                    formControlName="906"
                    type="text"
                    placeholder="MLP Zip"
                    data-id="MLP Zip"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['906'].touched &&
                      gaUnifiedForm.controls['906'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['906'].errors"
                    >
                      required
                    </small>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Effective Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['348'].touched &&
                          gaUnifiedForm.controls['348'].invalid) ||
                        (gaUnifiedForm.controls['348'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="348"
                      name="348"
                      formControlName="348"
                      type="text"
                      data-id="mlp1dateeff_date"
                      ngbDatepicker
                      #ga66="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('348')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga66.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['348'].invalid&&gaUnifiedForm.controls['348'].touched) && (gaUnifiedForm.controls['348'].value==''|| gaUnifiedForm.controls['348'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('348').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('348').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('348').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('348').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['349'].touched &&
                          gaUnifiedForm.controls['349'].invalid) ||
                        (gaUnifiedForm.controls['349'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="349"
                      name="349"
                      formControlName="349"
                      type="text"
                      data-id="mlp1dateexp_date"
                      ngbDatepicker
                      #ga67="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('349')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga67.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['349'].invalid&&gaUnifiedForm.controls['349'].touched) && (gaUnifiedForm.controls['349'].value==''|| gaUnifiedForm.controls['349'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('349').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('349').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('349').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('349').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Retroactive Date, if applicable (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['945'].touched &&
                          gaUnifiedForm.controls['945'].invalid) ||
                        (gaUnifiedForm.controls['945'].invalid && isSubmit)
                      "
                      placeholder="MM/DD/YYYY"
                      id="945"
                      name="945"
                      formControlName="945"
                      type="text"
                      data-id="MLP Retro date"
                      ngbDatepicker
                      #ga68="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('945')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga68.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="mandatory" *ngIf="(gaUnifiedForm.controls['945'].invalid&&gaUnifiedForm.controls['945'].touched) && (gaUnifiedForm.controls['945'].value==''|| gaUnifiedForm.controls['945'].value==null) " >
                    <small >
                    required
                    </small>
  
                </div>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('945').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)                </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('945').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('945').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('945').errors?.invalidDateRange">
                      date is out of range
                      </small>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >If you have changed your coverage within the last ten
                    years, did you purchase tail and/or nose (prior
                    occurrence/acts) coverage?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_089"
                      name="cmd_089"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_089'].touched &&
                          gaUnifiedForm.controls['cmd_089'].errors) ||
                        (gaUnifiedForm.controls['cmd_089'].errors && isSubmit)
                      "
                      formControlName="cmd_089"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_089'].touched &&
                      gaUnifiedForm.controls['cmd_089'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_089'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_089"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_089'].touched &&
                          gaUnifiedForm.controls['cmd_089'].invalid) ||
                        (gaUnifiedForm.controls['cmd_089'].invalid && isSubmit)
                      "
                      name="cmd_089"
                      formControlName="cmd_089"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_089'].touched &&
                      gaUnifiedForm.controls['cmd_089'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_089'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_089'].touched &&
                        gaUnifiedForm.controls['cmd_089'].invalid) ||
                      (gaUnifiedForm.controls['cmd_089'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
                <label class="normalParagraphStyle"
                  >If yes, please provide details/supporting data. If no, please
                  explain why not on an Explanation Form of the Application </label
                ><br />
                <label class="boldParagraphStyle">
                  NOTE: IF YOU ARE COVERED BY A MEDICAL PROFESSIONAL LIABILITY
                  INSURANCE PROGRAM THAT IS A CLAIMS MADE POLICY, YOU ARE
                  REQUIRED TO SHOW EVIDENCE OF PURCHASE OF CURRENT REPORTING
                  ENDORSEMENT COVERAGE (TAIL COVERAGE) OR PRIOR OCCURRENCE/ACTS
                  COVERAGE TO COVER PREVIOUS YEARS OF PRACTICE.
                </label>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <div class="boardCertification">
              Please list all previous professional liability carriers within
              the past ten (10) years (including any carriers during medical
              training if within the ten year period).
            </div>
          </div>

          <!-- <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please list all previous professional liability carriers
                    within the past ten (10) years (including any carriers
                    during medical training if within the ten year period).
                  </label>
                </div> -->

          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Current Insurance Carrier / Provider of Professional
                    Liability Coverage:
                  </label>
                  <input
                    class="form-control"
                    id="1242"
                    name="1242"
                    formControlName="1242"
                    type="text"
                    placeholder="Pre MLP Carrier"
                    data-id="MLP Carrier 2"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Policy Number</label>
                  <input
                    class="form-control"
                    id="1256"
                    name="1256"
                    formControlName="1256"
                    type="text"
                    data-id="MLP 2 pol"
                    placeholder="Pre MLP Policy Number"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Type of Coverage (check one):</label
                    >
                    <input
                      class="form-control"
                      id="1257"
                      name="1257"
                      formControlName="1257"
                      type="text"
                      data-id="MLP 2 pol"
                      placeholder="MLP Type of Coverage"
                    />
                  </div>
                </div>
                <div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_092"
                      formControlName="cmd_092"
                      name="cmd_092"
                      value="0"
                    />
                    Claims-Made
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_092"
                      formControlName="cmd_092"
                      name="cmd_092"
                      value="1"
                    />
                    Occurrence
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Local Contact (e.g. Insurance Agent or Broker):
                    <input
                      class="form-control"
                      id="1243"
                      name="1243"
                      formControlName="1243"
                      placeholder="Pre MLP Agent"
                      type="text"
                      data-id="MLP contact 2"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Contact Telephone Number:
                    <input
                      class="form-control"
                      id="1254"
                      name="1254"
                      formControlName="1254"
                      type="text"
                      placeholder="Pre MLP Telephone"
                      data-id="MLP Phone 2"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Per claim limit of liability:
                    <div class="mb-3 selectDrop dropDrownSet suffix">
                      <ng-select
                        [items]="preClaim"
                        bindLabel="name"
                        placeholder="Select PreClaim"
                        bindValue="id"
                        formControlName="1264"
                      >
                      </ng-select>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="4">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Aggregate amount:
                    <div class="mb-3 selectDrop dropDrownSet suffix">
                      <ng-select
                        [items]="aggregates"
                        bindLabel="name"
                        placeholder="Select Aggregate"
                        bindValue="id"
                        formControlName="1265"
                      >
                      </ng-select>
                    </div>
                  </label>
                </div>
              </td>
              <td colspan="10">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Mailing Address:</label>
                  <input
                    class="form-control inputBoxStylesThree"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['1244'].touched &&
                        gaUnifiedForm.controls['1244'].invalid) ||
                      (gaUnifiedForm.controls['1244'].invalid && isSubmit)
                    "
                    id="1244"
                    name="1244"
                    formControlName="1244"
                    placeholder="Pre MLP Add1"
                    type="text"
                    data-id="MLP 2 address"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['1244'].touched &&
                      gaUnifiedForm.controls['1244'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['1244'].errors"
                    >
                      required
                    </small>
                  </div>
                  <input
                    class="form-control inputBoxStylesThree"
                    id="1245"
                    name="1245"
                    formControlName="1245"
                    placeholder="Pre MLP Add2"
                    type="text"
                    data-id="MLP 2 address"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control "
                    id="1246"
                    name="1246"
                    placeholder="Pre MLP city"
                    formControlName="1246"
                    type="text"
                    data-id="mlp 2 city"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select states"
                      bindValue="id"
                      formControlName="1248"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="1249"
                    name="1249"
                    formControlName="1249"
                    type="text"
                    placeholder="Pre MLP Zip"
                    data-id="MLP 2 zip"
                  />
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Effective Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1252"
                      name="1252"
                      formControlName="1252"
                      type="text"
                      data-id="MLP Eff date 2"
                      ngbDatepicker
                      #ga69="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1252')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga69.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('1252').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1252').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1252').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1252').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1252').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Retroactive Date, if applicable (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group "
                      placeholder="MM/DD/YYYY"
                      id="1251"
                      name="1251"
                      formControlName="1251"
                      type="text"
                      data-id="MLP retro date 2"
                      ngbDatepicker
                      #ga70="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1251')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga70.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div  *ngIf="gaUnifiedForm.get('1251').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1251').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1251').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1251').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1251').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="1253"
                      name="1253"
                      formControlName="1253"
                      type="text"
                      data-id="MLP Exp date 2"
                      ngbDatepicker
                      #ga71="ngbDatepicker"
                      (blur)="clearInputIfInvalid('1253')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga71.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('1253').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1253').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1253').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('1253').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1253').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Current Insurance Carrier / Provider of Professional
                    Liability Coverage:</label
                  >
                  <input
                    class="form-control"
                    id="cmd_093"
                    name="cmd_093"
                    formControlName="cmd_093"
                    placeholder="Pre MLP Carrier2"
                    type="text"
                    data-id="MLP 3 carrier"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Policy Number</label>
                  <input
                    class="form-control"
                    id="cmd_094"
                    name="cmd_094"
                    placeholder="Pre MLP Policy number2"
                    formControlName="cmd_094"
                    type="text"
                    data-id="MLP 3 Pol"
                  />
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Type of Coverage (check one):</label
                    >
                    <input
                      class="form-control"
                      id="cmd_01511"
                      name="cmd_01511"
                      formControlName="cmd_01511"
                      type="text"
                      data-id="MLP 2 pol"
                      placeholder="MLP Type of Coverage"
                    />
                  </div>
                </div>
                <div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_095"
                      name="cmd_095"
                      formControlName="cmd_095"
                      value="0"
                    />
                    Claims-Made
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_095"
                      name="cmd_095"
                      formControlName="cmd_095"
                      value="1"
                    />
                    Occurrence
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Name of Local Contact (e.g. Insurance Agent or Broker):
                    <input
                      class="form-control"
                      id="cmd_096"
                      name="cmd_096"
                      formControlName="cmd_096"
                      placeholder="Pre MLP Agent2"
                      type="text"
                      data-id="MLP 3 contact"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Contact Telephone Number:
                    <input
                      class="form-control"
                      id="cmd_097"
                      name="cmd_097"
                      placeholder="Pre MLP Telephone2"
                      formControlName="cmd_097"
                      type="text"
                      data-id="MLP 3 phone"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Per claim limit of liability:
                    <ng-select
                      [items]="preClaim"
                      bindLabel="name"
                      placeholder="Select PreClaim"
                      bindValue="id"
                      formControlName="cmd_098"
                    >
                    </ng-select>
                  </label>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Aggregate amount:
                    <ng-select
                      [items]="aggregates"
                      bindLabel="name"
                      placeholder="Select Aggregate"
                      bindValue="id"
                      formControlName="cmd_099"
                    >
                    </ng-select>
                  </label>
                </div>
              </td>
              <td colspan="9">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Mailing Address:</label>
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    class="form-control"
                    id="cmd_0100"
                    name="cmd_0100"
                    formControlName="cmd_0100"
                    placeholder="Pre mlp add1 2"
                    type="text"
                    data-id="MLP 3 add"
                  />
                  <input
                    style="width: 100%;margin-bottom: 2px;"
                    class="form-control"
                    id="cmd_01512"
                    name="cmd_01512"
                    formControlName="cmd_01512"
                    placeholder="Pre mlp add2 2"
                    type="text"
                    data-id="MLP 3 add"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_0101"
                    name="cmd_0101"
                    placeholder="Pre MLP City 2"
                    formControlName="cmd_0101"
                    type="text"
                    data-id="MLP 3 city"
                  />
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <div class="mb-3 selectDrop dropDrownSet suffix">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select states"
                      bindValue="id"
                      formControlName="cmd_0102"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-4 inputPadding">
                  <input
                    style="width: 100%"
                    class="form-control"
                    id="cmd_0103"
                    name="cmd_0103"
                    placeholder="Pre MLP Zip 2"
                    formControlName="cmd_0103"
                    type="text"
                    data-id="MLP 3 zip"
                  />
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Effective Date (mm/yy):
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_0104"
                      name="cmd_0104"
                      formControlName="cmd_0104"
                      type="text"
                      data-id="MLP 3 eff"
                      ngbDatepicker
                      #ga72="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_0104')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga72.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_0104').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0104').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0104').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0104').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0104').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Retroactive Date, if applicable (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_0105"
                      name="cmd_0105"
                      formControlName="cmd_0105"
                      type="text"
                      data-id="MLP 3 retro"
                      ngbDatepicker
                      #ga73="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_0105')"
                      
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga73.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_0105').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0105').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0105').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0105').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0105').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
              <td colspan="6" width="33.33%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Expiration Date (mm/yy):</label
                  >
                  <div class="input-group">
                    <input
                      class="form-control  datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_0106"
                      name="cmd_0106"
                      formControlName="cmd_0106"
                      type="text"
                      data-id="MLP 3 exp"
                      ngbDatepicker
                      #ga74="ngbDatepicker"
                      (blur)="clearInputIfInvalid('cmd_0106')"
                      
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga74.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div  *ngIf="gaUnifiedForm.get('cmd_0106').invalid ">
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0106').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0106').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0106').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0106').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Professional Insurance History: Please answer each of the
                    following questions in full.
                    <i>If the answer to any question is “YES”</i>, or requires
                    further information, please give a full explanation of the
                    specific details on an Explanation Form and attach to the
                    Application.
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">1. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Has your professional liability insurance coverage ever
                    been terminated or not renewed by action of the insurance
                    company? </label
                  ><br />
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0107"
                      name="cmd_0107"
                      formControlName="cmd_0107"
                      value="1"
                    />
                    Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0107"
                      name="cmd_0107"
                      formControlName="cmd_0107"
                      value="0"
                    />
                    No
                  </label>
                  <label class="normalParagraphStyle"
                    >If yes, please provide date, name of company(s), and basis
                    for termination or non-renewal.
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">2. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been denied coverage?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0108"
                      name="cmd_0108"
                      formControlName="cmd_0108"
                      value="1"
                    />
                    Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0108"
                      name="cmd_0108"
                      formControlName="cmd_0108"
                      value="0"
                    />
                    No
                  </label>
                  <label class="normalParagraphStyle"
                    >If yes, please provide details.</label
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">3. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Has your present professional liability insurance carrier
                    excluded any specific procedures from your insurance
                    coverage? </label
                  ><br />
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0109"
                      formControlName="cmd_0109"
                      name="cmd_0109"
                      value="1"
                    />
                    Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0109"
                      formControlName="cmd_0109"
                      name="cmd_0109"
                      value="0"
                    />
                    No
                  </label>
                  <label class="normalParagraphStyle"
                    >If yes, please identify procedures and provide details.
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <table border="1">
            <tr>
              <td colspan="18">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Professional Claims History:
                    <i
                      >(If the answer to any of these questions is “Yes,” please
                      complete a separate Professional Liability Claims
                      Information Form for each. A Professional Liability Claims
                      Information Form has been provided as Schedule B to this
                      Application. Please make additional copies as
                      necessary.)</i
                    >
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">1. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have there ever been any professional liability (i.e.
                    malpractice) claims, suits, judgments, settlements or
                    arbitration proceedings involving you?
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0110"
                      formControlName="cmd_0110"
                      name="cmd_0110"
                      value="1"
                    />Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0110"
                      formControlName="cmd_0110"
                      name="cmd_0110"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">2. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are any professional liability (i.e. malpractice) claims,
                    suits, judgments, settlements or arbitration proceedings
                    involving you <i>currently pending?</i>
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0111"
                      name="cmd_0111"
                      formControlName="cmd_0111"
                      value="1"
                    />
                    Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0111"
                      name="cmd_0111"
                      formControlName="cmd_0111"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">3. </label>
                </div>
              </td>
              <td colspan="6" width="90%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you aware of any formal demand for payment or similar
                    claim submitted to your insurer that did not result in a
                    lawsuit or other proceeding alleging professional
                    liability?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0112"
                      name="cmd_0112"
                      formControlName="cmd_0112"
                      value="1"
                    />
                    Yes
                  </label>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_0112"
                      name="cmd_0112"
                      formControlName="cmd_0112"
                      value="0"
                    />
                    No
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">X. HEALTH STATUS</h4>
          </div>
          <table border="1" width="100%">
            <tr>
              <td colspan="18" width="100%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="boldParagraphStyle"
                    >Please answer each of the following questions in full.
                  </label>
                </div>
              </td>
            </tr>
          </table>

          <table border="1" width="100%">
            <tr>
              <td colspan="2" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">1. </label>
                </div>
              </td>
              <td colspan="12" width="70%">
                <div class="inputPadding">
                  <label class="normalParagraphStyle"
                    >Do you currently have any physical or mental condition(s)
                    that may affect your ability to practice or exercise the
                    clinical privileges or responsibilities typically associated
                    with the specialty and position for which you are submitting
                    this Application?<b
                      ><i
                        >If the answer to this question is “YES,” please give
                        full explanation of the specific details on an
                        Explanation Form and attach to the Application.</i
                      > </b
                    ><br /><br /><br />(Note: Physical or mental condition(s)
                    include, but are not limited to, current alcohol or drug
                    dependency, current participation in aftercare programs for
                    alcohol or drug dependency, medical limitation of activity,
                    workload, etc., and prescribed medications that may affect
                    your clinical judgment or motor skills.)
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0113'].touched &&
                          gaUnifiedForm.controls['cmd_0113'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0113'].invalid && isSubmit)
                      "
                      id="cmd_0113"
                      name="cmd_0113"
                      formControlName="cmd_0113"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0113'].touched &&
                      gaUnifiedForm.controls['cmd_0113'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0113'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0113'].touched &&
                          gaUnifiedForm.controls['cmd_0113'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0113'].invalid && isSubmit)
                      "
                      id="cmd_0113"
                      name="cmd_0113"
                      formControlName="cmd_0113"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0113'].touched &&
                      gaUnifiedForm.controls['cmd_0113'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0113'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0113'].touched &&
                        gaUnifiedForm.controls['cmd_0113'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0113'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="10%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">2. </label>
                </div>
              </td>
              <td colspan="12" width="70%">
                <div class="inputPadding">
                  <label class="normalParagraphStyle"
                    ><b>Are you able to perform</b> all the essential functions
                    of the position for which you are applying, safely and
                    according to accepted standards of performance, with or
                    without reasonable accommodation? If reasonable
                    accommodation is required, please specify such on
                    <b><u>an attached Explanation Form.</u></b></label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0114'].touched &&
                          gaUnifiedForm.controls['cmd_0114'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0114'].invalid && isSubmit)
                      "
                      id="cmd_0114"
                      name="cmd_0114"
                      formControlName="cmd_0114"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0114'].touched &&
                      gaUnifiedForm.controls['cmd_0114'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0114'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0114'].touched &&
                          gaUnifiedForm.controls['cmd_0114'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0114'].invalid && isSubmit)
                      "
                      id="cmd_0114"
                      name="cmd_0114"
                      formControlName="cmd_0114"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0114'].touched &&
                      gaUnifiedForm.controls['cmd_0114'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0114'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0114'].touched &&
                        gaUnifiedForm.controls['cmd_0114'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0114'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">XI. ATTESTATION QUESTIONS</h4>
            <label class="normalParagraphStyle"
              ><b
                ><i
                  >This section to be completed by the Practitioner.
                  Modification to the wording or format of these Attestation
                  Questions will invalidate the Application.</i
                ></b
              >
            </label>
          </div>
          <table border="1" width="100%">
            <tr>
              <td colspan="18" width="100%">
                <label class="boldParagraphStyle"
                  >Please answer the following questions “yes” or “no”. If your
                  answer to any of the following questions is “yes”, please
                  provide details and reasons, as specified in each question, on
                  an Explanation Form and attach to the Application. </label
                ><br />
                <label class="boldParagraphStyle"
                  >For the purpose of the following questions, the term “adverse
                  action” means a voluntary or involuntary termination, loss of,
                  reduction, withdrawal, limitation, restriction, suspension,
                  revocation, denial, or non-renewal of membership, clinical
                  privileges, academic affiliation or appointment, or
                  employment. “Adverse action” also means, with respect to
                  professional licensure registration or certification, any
                  previously successful or currently pending challenges to such
                  licensure, registration or certification including any
                  voluntary or involuntary restriction, suspension, revocation,
                  denial, surrender, non-renewal, public or private reprimand,
                  probation, consent order, reduction, withdrawal, limitation,
                  relinquishment, or failure to proceed with an application for
                  such licensure, registration or certification
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">A.</label>
                </div>
              </td>
              <td colspan="16">
                <table border="1" width="100%">
                  <tr>
                    <td colspan="16" width="100%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >To your knowledge, have you ever been the subject of
                          an investigation or <b>adverse action </b>(or is an
                          investigation or <b>adverse action</b>adverse
                          currently pending) by:
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >1. a hospital or other healthcare facility (e.g.
                          surgical center, nursing home, renal dialysis
                          facility, etc.)?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0115'].touched &&
                                gaUnifiedForm.controls['cmd_0115'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0115'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0115"
                            name="cmd_0115"
                            formControlName="cmd_0115"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0115'].touched &&
                            gaUnifiedForm.controls['cmd_0115'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0115'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0115'].touched &&
                                gaUnifiedForm.controls['cmd_0115'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0115'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0115"
                            name="cmd_0115"
                            formControlName="cmd_0115"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0115'].touched &&
                            gaUnifiedForm.controls['cmd_0115'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0115'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0115'].touched &&
                              gaUnifiedForm.controls['cmd_0115'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0115'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >2. an education facility or program (medical school,
                          residency, internship, etc.)?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0116'].touched &&
                                gaUnifiedForm.controls['cmd_0116'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0116'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0116"
                            name="cmd_0116"
                            formControlName="cmd_0116"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0116'].touched &&
                            gaUnifiedForm.controls['cmd_0116'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0116'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0116'].touched &&
                                gaUnifiedForm.controls['cmd_0116'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0116'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0116"
                            name="cmd_0116"
                            formControlName="cmd_0116"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0116'].touched &&
                            gaUnifiedForm.controls['cmd_0116'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0116'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0116'].touched &&
                              gaUnifiedForm.controls['cmd_0116'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0116'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >3. a professional organization or society?
                        </label>
                      </div>
                    </td>
                    <!-- <td colspan="4" width="21%">
                      <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                        <label class="checkbox-inline radioStyle">
                          <input type="radio" [class.is-invalid]="(gaUnifiedForm.controls['cmd_0117'].touched && gaUnifiedForm.controls['cmd_0117'].invalid) || (gaUnifiedForm.controls['cmd_0117'].invalid && isSubmit)"
                            id="cmd_0117" formControlName="cmd_0117" name="cmd_0117" value="1"/>
                            Yes
                        </label>
                        <div class="formLabel col-md-11" *ngIf="gaUnifiedForm.controls['cmd_0117'].touched && gaUnifiedForm.controls['cmd_0117'].invalid">
                          <small class="text-danger" *ngIf="gaUnifiedForm.controls['cmd_0117'].errors">
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input type="radio" [class.is-invalid]="(gaUnifiedForm.controls['cmd_0117'].touched && gaUnifiedForm.controls['cmd_0117'].invalid) || (gaUnifiedForm.controls['cmd_0117'].invalid && isSubmit)"
                            id="cmd_0117" formControlName="cmd_0117" name="cmd_0117" value="0"/>
                            No
                        </label>
                        <div class="formLabel col-md-11" ngIf="gaUnifiedForm.controls['cmd_0117'].touched && gaUnifiedForm.controls['cmd_0117'].invalid">
                          <small class="text-danger" *ngIf="gaUnifiedForm.controls['cmd_0117'].errors">
                            required
                          </small>
                        </div>
                        <span *ngIf="(gaUnifiedForm.controls['cmd_0117'].touched && gaUnifiedForm.controls['cmd_0117'].invalid) || (gaUnifiedForm.controls['cmd_0117'].invalid && isSubmit)">
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td> -->
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0117'].touched &&
                                gaUnifiedForm.controls['cmd_0117'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0117'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0117"
                            formControlName="cmd_0117"
                            name="cmd_0117"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0117'].touched &&
                            gaUnifiedForm.controls['cmd_0117'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0117'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0117'].touched &&
                                gaUnifiedForm.controls['cmd_0117'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0117'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0117"
                            formControlName="cmd_0117"
                            name="cmd_0117"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0117'].touched &&
                            gaUnifiedForm.controls['cmd_0117'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0117'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0117'].touched &&
                              gaUnifiedForm.controls['cmd_0117'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0117'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >4. a professional licensing body (in any jurisdiction
                          for any profession)?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0118'].touched &&
                                gaUnifiedForm.controls['cmd_0118'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0118'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0118"
                            formControlName="cmd_0118"
                            name="cmd_0118"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0118'].touched &&
                            gaUnifiedForm.controls['cmd_0118'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0118'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0118'].touched &&
                                gaUnifiedForm.controls['cmd_0118'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0118'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0118"
                            formControlName="cmd_0118"
                            name="cmd_0118"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0118'].touched &&
                            gaUnifiedForm.controls['cmd_0118'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0118'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0118'].touched &&
                              gaUnifiedForm.controls['cmd_0118'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0118'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >5. a private, federal, or state agency regarding your
                          participation in a third party payment program
                          (Medicare,Medicaid, HMO, PPO, PHO, PSHCC, network,
                          system, managed care organization, etc.)?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0513'].touched &&
                                gaUnifiedForm.controls['cmd_0513'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0513'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0513"
                            formControlName="cmd_0513"
                            name="cmd_0513"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0513'].touched &&
                            gaUnifiedForm.controls['cmd_0513'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0513'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0513'].touched &&
                                gaUnifiedForm.controls['cmd_0513'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0513'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0513"
                            formControlName="cmd_0513"
                            name="cmd_0513"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0513'].touched &&
                            gaUnifiedForm.controls['cmd_0513'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0513'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0513'].touched &&
                              gaUnifiedForm.controls['cmd_0513'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0513'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" width="79%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="normalParagraphStyle"
                          >6. a state or federal agency (DEA, etc.) regarding
                          your prescription of controlled substances?
                        </label>
                      </div>
                    </td>
                    <td colspan="4" width="21%">
                      <div
                        class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding"
                      >
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0119'].touched &&
                                gaUnifiedForm.controls['cmd_0119'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0119'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0119"
                            name="cmd_0119"
                            formControlName="cmd_0119"
                            value="1"
                          />
                          Yes
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0119'].touched &&
                            gaUnifiedForm.controls['cmd_0119'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0119'].errors"
                          >
                            required
                          </small>
                        </div>
                        <label class="checkbox-inline radioStyle">
                          <input
                            type="radio"
                            [class.is-invalid]="
                              (gaUnifiedForm.controls['cmd_0119'].touched &&
                                gaUnifiedForm.controls['cmd_0119'].invalid) ||
                              (gaUnifiedForm.controls['cmd_0119'].invalid &&
                                isSubmit)
                            "
                            id="cmd_0119"
                            name="cmd_0119"
                            formControlName="cmd_0119"
                            value="0"
                          />
                          No
                        </label>
                        <div
                          class="formLabel col-md-11"
                          *ngIf="
                            gaUnifiedForm.controls['cmd_0119'].touched &&
                            gaUnifiedForm.controls['cmd_0119'].invalid
                          "
                        >
                          <small
                            class="text-danger"
                            *ngIf="gaUnifiedForm.controls['cmd_0119'].errors"
                          >
                            required
                          </small>
                        </div>
                        <span
                          *ngIf="
                            (gaUnifiedForm.controls['cmd_0119'].touched &&
                              gaUnifiedForm.controls['cmd_0119'].invalid) ||
                            (gaUnifiedForm.controls['cmd_0119'].invalid &&
                              isSubmit)
                          "
                        >
                          <i class="fas fa-times ms-2 text-danger"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">B.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >To your knowledge, have you ever been the subject of any
                    report(s) to a state or federal data bank or state licensing
                    or disciplining entity?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0120'].touched &&
                          gaUnifiedForm.controls['cmd_0120'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0120'].invalid && isSubmit)
                      "
                      id="cmd_0120"
                      name="cmd_0120"
                      formControlName="cmd_0120"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0120'].touched &&
                      gaUnifiedForm.controls['cmd_0120'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0120'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0120'].touched &&
                          gaUnifiedForm.controls['cmd_0120'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0120'].invalid && isSubmit)
                      "
                      id="cmd_0120"
                      name="cmd_0120"
                      formControlName="cmd_0120"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0120'].touched &&
                      gaUnifiedForm.controls['cmd_0120'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0120'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0120'].touched &&
                        gaUnifiedForm.controls['cmd_0120'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0120'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">C.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Has your application for clinical privileges or medical
                    staff membership or change in staff category at any hospital
                    or healthcare facility ever been denied in whole or in part
                    or is any such action pending?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0121'].touched &&
                          gaUnifiedForm.controls['cmd_0121'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0121'].invalid && isSubmit)
                      "
                      id="cmd_0121"
                      name="cmd_0121"
                      formControlName="cmd_0121"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0121'].touched &&
                      gaUnifiedForm.controls['cmd_0121'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0121'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0121'].touched &&
                          gaUnifiedForm.controls['cmd_0121'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0121'].invalid && isSubmit)
                      "
                      id="cmd_0121"
                      name="cmd_0121"
                      formControlName="cmd_0121"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0121'].touched &&
                      gaUnifiedForm.controls['cmd_0121'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0121'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0121'].touched &&
                        gaUnifiedForm.controls['cmd_0121'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0121'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">D.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever resigned from a hospital or other health care
                    facility medical staff to avoid disciplinary action,
                    investigation or while under investigation or is such an
                    investigation pending?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0122'].touched &&
                          gaUnifiedForm.controls['cmd_0122'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0122'].invalid && isSubmit)
                      "
                      id="cmd_0122"
                      name="cmd_0122"
                      formControlName="cmd_0122"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0122'].touched &&
                      gaUnifiedForm.controls['cmd_0122'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0122'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0122'].touched &&
                          gaUnifiedForm.controls['cmd_0122'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0122'].invalid && isSubmit)
                      "
                      id="cmd_0122"
                      name="cmd_0122"
                      formControlName="cmd_0122"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0122'].touched &&
                      gaUnifiedForm.controls['cmd_0122'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0122'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0122'].touched &&
                        gaUnifiedForm.controls['cmd_0122'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0122'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">E.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been suspended, fined, disciplined,
                    sanctioned or otherwise restricted or excluded from
                    participating in any federal or state health insurance
                    program (for example, Medicare or Medicaid)?.
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0123'].touched &&
                          gaUnifiedForm.controls['cmd_0123'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0123'].invalid && isSubmit)
                      "
                      id="cmd_0123"
                      name="cmd_0123"
                      formControlName="cmd_0123"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0123'].touched &&
                      gaUnifiedForm.controls['cmd_0123'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0123'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0123'].touched &&
                          gaUnifiedForm.controls['cmd_0123'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0123'].invalid && isSubmit)
                      "
                      id="cmd_0123"
                      name="cmd_0123"
                      formControlName="cmd_0123"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0123'].touched &&
                      gaUnifiedForm.controls['cmd_0123'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0123'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0123'].touched &&
                        gaUnifiedForm.controls['cmd_0123'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0123'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">F.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been suspended, fined, disciplined,
                    sanctioned or otherwise restricted or excluded from
                    participating in any private health insurance program?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0124'].touched &&
                          gaUnifiedForm.controls['cmd_0124'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0124'].invalid && isSubmit)
                      "
                      id="cmd_0124"
                      name="cmd_0124"
                      formControlName="cmd_0124"
                      value="1"
                    />Yes</label
                  >
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0124'].touched &&
                      gaUnifiedForm.controls['cmd_0124'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0124'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0124'].touched &&
                          gaUnifiedForm.controls['cmd_0124'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0124'].invalid && isSubmit)
                      "
                      id="cmd_0124"
                      name="cmd_0124"
                      formControlName="cmd_0124"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0124'].touched &&
                      gaUnifiedForm.controls['cmd_0124'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0124'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0124'].touched &&
                        gaUnifiedForm.controls['cmd_0124'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0124'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">G.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Has any professional review organization under contract
                    with Medicare or Medicaid ever made an adverse quality
                    determination concerning your treatment rendered to any
                    patient?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0125'].touched &&
                          gaUnifiedForm.controls['cmd_0125'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0125'].invalid && isSubmit)
                      "
                      id="cmd_0125"
                      name="cmd_0125"
                      formControlName="cmd_0125"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0125'].touched &&
                      gaUnifiedForm.controls['cmd_0125'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0125'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0125'].touched &&
                          gaUnifiedForm.controls['cmd_0125'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0125'].invalid && isSubmit)
                      "
                      id="cmd_0125"
                      name="cmd_0125"
                      formControlName="cmd_0125"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0125'].touched &&
                      gaUnifiedForm.controls['cmd_0125'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0125'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0125'].touched &&
                        gaUnifiedForm.controls['cmd_0125'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0125'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">H.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been convicted of or entered a plea for any
                    criminal offense (excluding parking tickets)?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0126'].touched &&
                          gaUnifiedForm.controls['cmd_0126'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0126'].invalid && isSubmit)
                      "
                      id="cmd_0126"
                      formControlName="cmd_0126"
                      name="cmd_0126"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0126'].touched &&
                      gaUnifiedForm.controls['cmd_0126'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0126'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0126'].touched &&
                          gaUnifiedForm.controls['cmd_0126'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0126'].invalid && isSubmit)
                      "
                      id="cmd_0126"
                      formControlName="cmd_0126"
                      name="cmd_0126"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0126'].touched &&
                      gaUnifiedForm.controls['cmd_0126'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0126'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0126'].touched &&
                        gaUnifiedForm.controls['cmd_0126'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0126'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">I.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are any criminal charges currently pending against
                    you?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0127'].touched &&
                          gaUnifiedForm.controls['cmd_0127'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0127'].invalid && isSubmit)
                      "
                      id="cmd_0127"
                      formControlName="cmd_0127"
                      name="cmd_0127"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0127'].touched &&
                      gaUnifiedForm.controls['cmd_0127'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0127'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0127'].touched &&
                          gaUnifiedForm.controls['cmd_0127'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0127'].invalid && isSubmit)
                      "
                      id="cmd_0127"
                      formControlName="cmd_0127"
                      name="cmd_0127"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0127'].touched &&
                      gaUnifiedForm.controls['cmd_0127'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0127'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0127'].touched &&
                        gaUnifiedForm.controls['cmd_0127'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0127'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">J.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been arrested for or charged with a crime
                    involving children?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0128'].touched &&
                          gaUnifiedForm.controls['cmd_0128'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0128'].invalid && isSubmit)
                      "
                      id="cmd_0128"
                      formControlName="cmd_0128"
                      name="cmd_0128"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0128'].touched &&
                      gaUnifiedForm.controls['cmd_0128'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0128'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0128'].touched &&
                          gaUnifiedForm.controls['cmd_0128'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0128'].invalid && isSubmit)
                      "
                      id="cmd_0128"
                      formControlName="cmd_0128"
                      name="cmd_0128"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0128'].touched &&
                      gaUnifiedForm.controls['cmd_0128'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0128'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0128'].touched &&
                        gaUnifiedForm.controls['cmd_0128'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0128'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">K.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been arrested for or charged with a sexual
                    offense?
                  </label>
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0129'].touched &&
                          gaUnifiedForm.controls['cmd_0129'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0129'].invalid && isSubmit)
                      "
                      id="cmd_0129"
                      name="cmd_0129"
                      formControlName="cmd_0129"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0129'].touched &&
                      gaUnifiedForm.controls['cmd_0129'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0129'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0129'].touched &&
                          gaUnifiedForm.controls['cmd_0129'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0129'].invalid && isSubmit)
                      "
                      id="cmd_0129"
                      name="cmd_0129"
                      formControlName="cmd_0129"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0129'].touched &&
                      gaUnifiedForm.controls['cmd_0129'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0129'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0129'].touched &&
                        gaUnifiedForm.controls['cmd_0129'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0129'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">L.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Have you ever been arrested for or charged with a crime
                    involving moral turpitude?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0130'].touched &&
                          gaUnifiedForm.controls['cmd_0130'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0130'].invalid && isSubmit)
                      "
                      id="cmd_0130"
                      formControlName="cmd_0130"
                      name="cmd_0130"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0130'].touched &&
                      gaUnifiedForm.controls['cmd_0130'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0130'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0130'].touched &&
                          gaUnifiedForm.controls['cmd_0130'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0130'].invalid && isSubmit)
                      "
                      id="cmd_0130"
                      formControlName="cmd_0130"
                      name="cmd_0130"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0130'].touched &&
                      gaUnifiedForm.controls['cmd_0130'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0130'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0130'].touched &&
                        gaUnifiedForm.controls['cmd_0130'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0130'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" width="5%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">M.</label>
                </div>
              </td>
              <td colspan="12" width="75%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle"
                    >Are you currently using illegal drugs or legal drugs in an
                    illegal manner?</label
                  >
                </div>
              </td>
              <td colspan="4" width="20%">
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0131'].touched &&
                          gaUnifiedForm.controls['cmd_0131'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0131'].invalid && isSubmit)
                      "
                      id="cmd_0131"
                      formControlName="cmd_0131"
                      name="cmd_0131"
                      value="1"
                    />
                    Yes
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0131'].touched &&
                      gaUnifiedForm.controls['cmd_0131'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0131'].errors"
                    >
                      required
                    </small>
                  </div>
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0131'].touched &&
                          gaUnifiedForm.controls['cmd_0131'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0131'].invalid && isSubmit)
                      "
                      id="cmd_0131"
                      formControlName="cmd_0131"
                      name="cmd_0131"
                      value="0"
                    />
                    No
                  </label>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0131'].touched &&
                      gaUnifiedForm.controls['cmd_0131'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0131'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0131'].touched &&
                        gaUnifiedForm.controls['cmd_0131'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0131'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>

          <div class="centered inputBorderBox">
            <h4 class="boardCertification">XII. ATTESTATION AND SIGNATURE</h4>
            <label class="normalParagraphStyle">
              <i>
                By signing this Application, I certify, agree, understand and
                acknowledge the following:</i
              >
            </label>
            <ol class="list-styled">
              <li>
                The information in this entire Application, including all
                subparts and attachments, is complete, current, correct, and not
                misleading
              </li>
              <li>
                Any misstatements or omissions (whether intentional or
                unintentional) on this Application may constitute cause for
                denial of my Application or summary dismissal or termination of
                my clinical privileges, membership or practitioner participation
                agreement
              </li>
              <li>
                A photocopy of this Application, including this attestation, the
                authorization and release of information form and any or all
                attachments has the same force and effect as the original.
              </li>
              <li>
                I have reviewed the information in this Application on the most
                recent date indicated below and it continues to be true and
                complete.
              </li>
              <li>
                While this Application is being processed, I agree to update the
                information originally provided in this Application should there
                be any change in the information
              </li>
              <li>
                No action will be taken on this Application until it is complete
                and all outstanding questions with respect to the Application
                have been resolved.
              </li>
              <li>
                This attestation statement and Application must be signed no
                more than 180 days prior to the credentialing decision date.
              </li>
            </ol>
          </div>
          <table border="1" width="100%">
            <tr>
              <td>
                <label class="normalParagraphStyle"
                  >Applicant's Signature:</label
                >
              </td>
              <td>
                <img
                  *ngIf="!showOne"
                  class="img"
                  src="./assets/images/e_sign.png"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <img
                  *ngIf="showOne"
                  class="img eSignImageShow"
                  [src]="esignOne"
                  alt="Credential my Doc"
                  (click)="esignOpenOne()"
                />
                <input
                  type="hidden"
                  name="cmd_09911"
                  id="cmd_09911"
                  [class.is-invalid]="
                    (gaUnifiedForm.controls['cmd_09911'].touched &&
                      gaUnifiedForm.controls['cmd_09911'].invalid) ||
                    (gaUnifiedForm.controls['cmd_09911'].invalid && isSubmit)
                  "
                  formControlName="cmd_09911"
                />
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    gaUnifiedForm.controls['cmd_09911'].touched &&
                    gaUnifiedForm.controls['cmd_09911'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="gaUnifiedForm.controls['cmd_09911'].errors"
                  >
                    required
                  </small>
                </div>
                <span
                  *ngIf="
                    (gaUnifiedForm.controls['cmd_09911'].touched &&
                      gaUnifiedForm.controls['cmd_09911'].invalid) ||
                    (gaUnifiedForm.controls['cmd_09911'].invalid && isSubmit)
                  "
                >
                  <i class="fas fa-times ms-2 text-danger"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle">Printed Name:</label>
                    <input
                      class="form-control"
                      id="cmd_0132"
                      name="cmd_0132"
                      formControlName="cmd_0132"
                      type="text"
                      data-id="PrintedName"
                    />
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <ng-select
                      [items]="credential"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="cmd_01520"
                    >
                    </ng-select>
                  </div>
                </div>
              </td>
              <td>
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <label class="normalParagraphStyle">Date:</label>
                  <div class="input-group">
                    <input
                      class="form-control onboard datepicker input-group"
                      placeholder="MM/DD/YYYY"
                      id="cmd_0200"
                      name="cmd_0200"
                      [class.is-invalid]="
                        (gaUnifiedForm.controls['cmd_0200'].touched &&
                          gaUnifiedForm.controls['cmd_0200'].invalid) ||
                        (gaUnifiedForm.controls['cmd_0200'].invalid && isSubmit)
                      "
                      formControlName="cmd_0200"
                      type="text"
                      ngbDatepicker
                      #ga105="ngbDatepicker"
                      (blur)="clearInputIfInvalidMandi('cmd_0200')"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary calendar"
                        (click)="ga105.toggle()"
                        type="button"
                      >
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div class="mandatory" *ngIf="(gaUnifiedForm.controls['cmd_0200'].invalid&&gaUnifiedForm.controls['cmd_0200'].touched) && (gaUnifiedForm.controls['cmd_0200'].value==''|| gaUnifiedForm.controls['cmd_0200'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0200').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0200').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0200').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0200').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="mb-3">
            <div class="text-center">
              <h2>
                GEORGIA UNIFORM <u>ALLIED</u> HEALTHCARE PROFESSIONAL <br />
                CREDENTIALING APPLICATIONFORM
              </h2>
            </div>
            <br /><br />
            <div class="centered inputBorderBox">
              <h3 class="inputBoxStylesFour">EXPLANATION FORM</h3>
            </div>
            <table border="1">
              <tr>
                <td colspan="18">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label
                      style="font-weight: bold;font-size: 14px; padding: 20px"
                      >Please make as many copies of this page as needed to
                      fully respond to each question. For each
                      response/explanation, please provide your name and Social
                      Security Number, together with the corresponding page and
                      section number from the Application.
                    </label>
                  </div>
                </td>
              </tr>
            </table>

            <br /><br />
            <table border="1" width="100%">
              <tr>
                <td colspan="9">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">NAME:</label>
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0133"
                      name="cmd_0133"
                      formControlName="cmd_0133"
                      type="text"
                      data-id="PrintedName"
                    />
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <ng-select
                        [items]="credential"
                        bindLabel="name"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="cmd_01571"
                      >
                      </ng-select>
                    </div>
                  </div>
                </td>
                <td colspan="9">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">SSN#:</label>
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_01522"
                      name="cmd_01522"
                      formControlName="cmd_01522"
                      type="text"
                      data-id="ssn"
                    />
                  </div>
                </td>
              </tr>
            </table>

            <br /><br />
            <table border="1" width="100%">
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">Section #:</label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">Page #:</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0134"
                      formControlName="cmd_0134"
                      name="cmd_0134"
                      type="text"
                      data-id="Explain 1"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0135"
                      formControlName="cmd_0135"
                      name="cmd_0135"
                      type="text"
                      data-id="Page 1"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0136"
                      formControlName="cmd_0136"
                      name="cmd_0136"
                      type="text"
                      data-id="Explain 2"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0137"
                      formControlName="cmd_0137"
                      name="cmd_0137"
                      type="text"
                      data-id="Page 2"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0138"
                      formControlName="cmd_0138"
                      name="cmd_0138"
                      type="text"
                      data-id="Explain 3"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0139"
                      formControlName="cmd_0139"
                      name="cmd_0139"
                      type="text"
                      data-id="Page 3"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0140"
                      formControlName="cmd_0140"
                      name="cmd_0140"
                      type="text"
                      data-id="Explain 4"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0141"
                      formControlName="cmd_0141"
                      name="cmd_0141"
                      type="text"
                      data-id="Page 4"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0142"
                      name="cmd_0142"
                      formControlName="cmd_0142"
                      type="text"
                      data-id="Explain 5"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0143"
                      name="cmd_0143"
                      formControlName="cmd_0143"
                      type="text"
                      data-id="Page 5"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0144"
                      name="cmd_0144"
                      formControlName="cmd_0144"
                      type="text"
                      data-id="Explain 6"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0145"
                      name="cmd_0145"
                      formControlName="cmd_0145"
                      type="text"
                      data-id="Page 6"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0146"
                      name="cmd_0146"
                      formControlName="cmd_0146"
                      type="text"
                      data-id="Explain 7"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0147"
                      name="cmd_0147"
                      formControlName="cmd_0147"
                      type="text"
                      data-id="Page 7"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0148"
                      name="cmd_0148"
                      formControlName="cmd_0148"
                      type="text"
                      data-id="Explain 8"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0149"
                      name="cmd_0149"
                      formControlName="cmd_0149"
                      type="text"
                      data-id="Page 8"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0150"
                      name="cmd_0150"
                      formControlName="cmd_0150"
                      type="text"
                      data-id="Explain 8"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0151"
                      name="cmd_0151"
                      formControlName="cmd_0151"
                      type="text"
                      data-id="Page 8"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="16">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0152"
                      name="cmd_0152"
                      formControlName="cmd_0152"
                      type="text"
                      data-id="Explain 9"
                    />
                  </div>
                </td>
                <td colspan="2">
                  <div class="d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 70%"
                      class="form-control"
                      id="cmd_0153"
                      name="cmd_0153"
                      formControlName="cmd_0153"
                      type="text"
                      data-id="Page 9"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="mb-3">
            <div class="text-center">
              <h2>Schedule A</h2>
              <br />
              <h4>
                GEORGIA UNIFORM <u>ALLIED</u> HEALTHCARE PROFESSIONAL
                CREDENTIALING APPLICATIONFORM
              </h4>
              <h3><u>AUTHORIZATION AND RELEASE OF INFORMATION FORM</u></h3>
              <br />
              <h4><u>Modified Releases Will Not Be Accepted </u></h4>
            </div>
            <br /><br />
            <p class="boldParagraphStyle">
              By submitting this Application, including all subparts and
              attachments, I acknowledge, understand, consent and agree to the
              following:
            </p>
            <ol class="list-styled" id="content">
              <li>
                As an applicant for medical staff membership at the designated
                hospital(s) and/or participation status with the health care
                related organization(s) [<i
                  >e.g. hospital,medical staff, medical group, independent
                  practice association (IPA), healthplan, health maintenance
                  organization (HMO), preferred provider organization(PPO),
                  physician hospital organization (PHO), managed care
                  organization, network,medical society, professional
                  association, medical school faculty position, or other
                  healthcare delivery entity or system (hereinafter referred to
                  as a “Healthcare Entity”)</i
                >
                indicated on this Application, I have the burden of producing
                adequateinformation for proper evaluation of this Application.
              </li>
              <li>
                I also understand that I have the continuing responsibility to
                resolve any questions, concerns or doubts regarding any and all
                information in this Application. If I fail to produce this
                information, then I understand that the Healthcare Entity will
                not be required to evaluate or act upon this Application. I also
                agree to provide updated information as may be required or
                requested by the Healthcare Entity or its authorized
                representatives or designated agents.
              </li>
              <li>
                The Healthcare Entity and its authorized representatives or
                designated agents will investigate the information in this
                Application. I consent and agree to such investigation and to
                the disciplinary reporting and information exchange activities
                of the Healthcare Entity as a part of the verification and
                credentialing process.
              </li>
              <li>
                I specifically authorize the Healthcare Entity and its
                authorized representatives and designated agents to obtain and
                act upon information regarding my competence, qualifications,
                education, training, professional and clinical ability,
                character, conduct, ethics, judgment, mental and physical health
                status, emotional stability, utilization practices, professional
                licensure or certification, and any other matter related to my
                qualifications or matters addressed in this Application (my
                “Qualifications”).
              </li>
              <li>
                I authorize all individuals, institutions, schools, programs,
                entities, facilities, hospitals, societies, associations,
                companies, agencies, licensing authorities, boards, plans,
                organizations, Healthcare Entities or others with which I have
                been associated as well as all professional liability insurers
                with which I have had or currently have professional liability
                insurance, who may have information bearing on my Qualifications
                to consult with the Healthcare Entity and its authorized
                representatives and designated agents and to report, release,
                exchange and share information and documents with the Healthcare
                Entity, for the purpose of evaluating this Application and my
                Qualifications.
              </li>
              <li>
                I consent to and authorize the inspection of records and
                documents (including medical records and peer review
                information) that may be material to an evaluation of this
                Application and my Qualifications and my ability to carry out
                the clinical privileges/services/participation I have requested.
                I authorize each and every individual and organization with
                custody of such records and documents to permit such inspection
                and copying as may be necessary for the evaluation of this
                Application. I also agree to appear for interviews, if required
                or requested by the Healthcare Entity, in regard to this
                Application.
              </li>
              <li>
                I further consent to and authorize the release by the Healthcare
                Entity to other Healthcare Entities and interested persons on
                request of information the Healthcare Entity may have concerning
                me (including but not limited to peer review information which
                is provided to another Healthcare Entity for peer review
                purposes), as long as in each instance such release of
                information is done in good faith and without malice. I hereby
                release from all liability the Healthcare Entity and its
                authorized representatives or designated agents from any claim
                for damages of whatever nature for any release of information
                made in good faith by the Healthcare Entity or its
                representatives or agents.
              </li>
              <li>
                I release from any liability, to the fullest extent permitted by
                law, all persons and entities (individuals and organizations)
                for their acts performed in a reasonable manner in conjunction
                with investigating and evaluating my Application and
                Qualifications, and I waive all legal claims of whatever nature
                against the Healthcare Entity and its representatives and
                designated agents acting in good faith and without malice in
                connection with the investigation of this Application and my
                Qualifications.
              </li>
              <li>
                For hospital or medical staff membership/clinical privileges, I
                acknowledge that I have been informed of, and hereby agree to
                abide by, the medical staff bylaws, rules, regulations and
                policies. I agree to conduct my practice in accordance with
                applicable laws and ethical principles of my profession. I also
                agree to provide for continuous care for my patients.
              </li>
              <li>
                Any investigations, actions or recommendations of any committee
                or the governing body of the Healthcare Entity with respect to
                the evaluation of this Application and any periodic reappraisals
                or evaluations will be undertaken as a medical review and/or
                peer review committee and in fulfillment of the Healthcare
                Entity’s obligations under Georgia law to conduct a review of
                professional practices in the facility, and are therefore
                entitled to any protections provided by law.
              </li>
              <li>
                I have read and understand this Authorization and Release of
                Information Form. A photocopy of this Authorization and Release
                of Information Form shall be as effective as the original and
                shall constitute my written authorization and request to
                communicate any relevant information and to release any and all
                supportive documentation regarding this Application. This
                Authorization and Release shall apply in connection with the
                evaluation and processing of this Application as well as in
                connection with any periodic reappraisals and evaluations
                undertaken. I agree to execute such additional releases as may
                be required from time to time in connection with such periodic
                reappraisals and evaluations.
              </li>
            </ol>
            <br />
            <table border="1" width="100%">
              <tr>
                <td>
                  <label class="boldParagraphStyle">Signature:</label>
                </td>
                <td>
                  <img
                    *ngIf="!showTwo"
                    class="img"
                    src="./assets/images/e_sign.png"
                    alt="Credential my Doc"
                    (click)="esignOpenTwo()"
                  />
                  <img
                    *ngIf="showTwo"
                    class="img eSignImageShow"
                    [src]="esignTwo"
                    alt="Credential my Doc"
                    (click)="esignOpenTwo()"
                  />
                  <input
                    type="hidden"
                    name="cmd_0992"
                    id="cmd_0992"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_0992'].touched &&
                        gaUnifiedForm.controls['cmd_0992'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0992'].invalid && isSubmit)
                    "
                    formControlName="cmd_0992"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_0992'].touched &&
                      gaUnifiedForm.controls['cmd_0992'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_0992'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_0992'].touched &&
                        gaUnifiedForm.controls['cmd_0992'].invalid) ||
                      (gaUnifiedForm.controls['cmd_0992'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle">Printed Name:</label>
                    <input
                      class="form-control onboard "
                      id="cmd_0156"
                      name="cmd_0156"
                      formControlName="cmd_0156"
                      type="text"
                      data-id="PrintedName"
                    />
                    <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                      <ng-select
                        [items]="degree"
                        bindLabel="name"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="cmd_01521"
                      >
                      </ng-select>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle">Date:</label>
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="cmd_0195"
                        name="cmd_0195"
                        [class.is-invalid]="
                          (gaUnifiedForm.controls['cmd_0195'].touched &&
                            gaUnifiedForm.controls['cmd_0195'].invalid) ||
                          (gaUnifiedForm.controls['cmd_0195'].invalid &&
                            isSubmit)
                        "
                        formControlName="cmd_0195"
                        type="text"
                        ngbDatepicker
                        #ga75="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('cmd_0195')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga75.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                      <div class="mandatory" *ngIf="(gaUnifiedForm.controls['cmd_0195'].invalid&&gaUnifiedForm.controls['cmd_0195'].touched) && (gaUnifiedForm.controls['cmd_0195'].value==''|| gaUnifiedForm.controls['cmd_0195'].value==null) " >
                        <small >
                        required
                        </small>
      
                    </div>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0195').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)                </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0195').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0195').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0195').errors?.invalidDateRange">
                          date is out of range
                          </small>
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <p class="boldParagraphStyle">
              I grant permission for the release of the credentials information
              contained in this Application to the following Healthcare
              Entity(ies):
            </p>
            <div class="mb-3 no-margin inputPadding">
              <input
                style="width: 40%"
                class="form-control onboard "
                id="cmd_0157"
                name="cmd_0157"
                formControlName="cmd_0157"
                type="text"
                data-id="Entity 1"
              />
              <input
                style="width: 40%"
                class="form-control onboard "
                id="cmd_0158"
                name="cmd_0158"
                formControlName="cmd_0158"
                type="text"
                data-id="Entity 2"
              />
              <input
                style="width: 40%"
                class="form-control onboard "
                id="cmd_0159"
                name="cmd_0159"
                formControlName="cmd_0159"
                type="text"
                data-id="Entity 3"
              />
            </div>
          </div>
          <div class="mb-3">
            <div class="text-center">
              <h2>Schedule B</h2>
              <br />
              <h3>
                GEORGIA UNIFORM <u>ALLIED</u>HEALTHCARE PROFESSIONAL <br />
                CREDENTIALING APPLICATION FORM
              </h3>
              <h3>
                <u>AUTHORIZATION AND RELEASE OF INFORMATION FORM</u>
              </h3>
            </div>
            <br /><br />
            <p class="boldParagraphStyle">
              The following information is necessary to complete the
              credentialing verification process and will be kept confidential.
              Please print or type answers to the following for any malpractice
              claims reported to your malpractice insurance carrier, opened,
              closed, settled or paid. For initial credentialing, please
              complete a separate form for each claim; for recredentialing,
              complete forms only for new/changed status claims since your last
              recredentialing. One case per sheet (please photocopy if
              additional sheets are needed).
            </p>
            <table border="1" width="100%">
              <tr>
                <td colspan="6" width="33.33%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">PROVIDER’S NAME:</label>
                    <br /><i>(Required even if N/A)</i>
                  </div>
                </td>
                <td colspan="6" width="33.33%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control"
                      id="cmd_160"
                      formControlName="cmd_160"
                      name="cmd_160"
                      type="text"
                      data-id="PrintedName"
                    />
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Select Degree
                      <ng-select
                        [items]="degree"
                        bindLabel="name"
                        placeholder="Select Degree"
                        bindValue="id"
                        formControlName="cmd_01570"
                      >
                      </ng-select>
                    </label>
                  </div>
                </td>
                <td colspan="6" width="33.33%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline">
                      <input
                        type="checkbox"
                        id="cmd_0161"
                        name="cmd_0161"
                        formControlName="cmd_0161"
                        value="1"
                      />Does Not Apply</label
                    ><br />
                    <i style="font-size: 12px"
                      >Note: Signature Required even if checked.</i
                    >
                  </div>
                </td>
              </tr>
            </table>

            <table border="1" width="100%">
              <tr>
                <td colspan="6" width="30%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >Name of Patient Involved</label
                    >
                  </div>
                </td>
                <td colspan="6" width="10%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle">Age</label>
                  </div>
                </td>
                <td colspan="6" width="20%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >Month and Year of Occurrence
                    </label>
                    <br /><i class="normalParagraphStyle"
                      >(Event precipitating claim)</i
                    >
                  </div>
                </td>
                <td colspan="6" width="20%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >Month and Year of Lawsuit</label
                    >
                  </div>
                </td>
                <td colspan="6" width="30%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >Insurance Carrier at Time</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="6" width="30%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 45%"
                      class="form-control onboard "
                      id="1205"
                      name="1205"
                      formControlName="1205"
                      placeholder="Patient First Name"
                      type="text"
                      data-id="Patientfirstnm"
                    />
                    <input
                      style="width: 45%"
                      class="form-control onboard "
                      id="1206"
                      name="1206"
                      formControlName="1206"
                      placeholder="Patient Last Name"
                      type="text"
                      data-id="Patientlastnm"
                    />
                  </div>
                </td>
                <td colspan="6" width="10%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1207"
                      placeholder="Patient Age"
                      name="1207"
                      formControlName="1207"
                      type="text"
                      data-id="patient age"
                    />
                  </div>
                </td>
                <td colspan="6" width="20%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1208"
                        name="1208"
                        formControlName="1208"
                        type="text"
                        ngbDatepicker
                        #ga705="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1208')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga705.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1208').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1208').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1208').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1208').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1208').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
                <td colspan="6" width="20%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1209"
                        name="1209"
                        formControlName="1209"
                        type="text"
                        ngbDatepicker
                        #ga715="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1209')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga715.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1209').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1209').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1209').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1209').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1209').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
                <td colspan="6" width="30%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        id="1210"
                        name="1210"
                        formControlName="1210"
                        type="text"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <table border="1" width="100%">
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >What is/was your status?</label
                    >
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >List other defendants:</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_0162"
                        name="cmd_0162"
                        formControlName="cmd_0162"
                        value="0"
                      />
                      Primary Defendant
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_0163"
                        name="cmd_0163"
                        formControlName="cmd_0163"
                        value="1"
                      />
                      Co-Defendant
                    </label>
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_0164"
                        name="cmd_0164"
                        formControlName="cmd_0164"
                        value="2"
                      />
                      Other, please explain:
                    </label>
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1213"
                      name="1213"
                      formControlName="1213"
                      type="text"
                      data-id="List other defendants"
                    />
                  </div>
                </td>
              </tr>
            </table>

            <table border="1" width="100%">
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >What was the patient’s outcome?</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1214"
                      name="1214"
                      formControlName="1214"
                      type="text"
                      data-id="Patient Outcome"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >How were you alleged to have caused harm or injury to
                      this patient?</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1216"
                      name="1216"
                      formControlName="1216"
                      type="text"
                      data-id="Cause of harm"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >Please provide specifics in reference to the adverse
                      event:</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1217"
                      name="1217"
                      formControlName="1217"
                      type="text"
                      data-id="Specifics on the claim"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="boldParagraphStyle"
                      >What is/was your role in this event?</label
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="18" width="100%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1218"
                      name="1218"
                      formControlName="1218"
                      type="text"
                      data-id="Role in event"
                    />
                  </div>
                </td>
              </tr>
            </table>

            <table border="1" width="100%">
              <tr>
                <td colspan="18" width="100%">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding centered"
                  >
                    <label class="boldParagraphStyle">CURRENT STATUS</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0165"
                        name="cmd_0165"
                        formControlName="cmd_0165"
                        value="1"
                      />
                      Still pending
                    </label>
                    <label class="normalParagraphStyle">(as of) Date:</label>
                    <div class="input-group">
                      <input
                        style="width: 30%"
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1219"
                        name="1219"
                        formControlName="1219"
                        type="text"
                        data-id="pending date"
                        ngbDatepicker
                        #ga76="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1219')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga76.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1219').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1219').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1219').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1219').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1219').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle"
                      >Who is handling the defense of the case?
                    </label>
                  </div>
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <input
                      style="width: 100%"
                      class="form-control onboard "
                      id="1275"
                      name="1275"
                      formControlName="1275"
                      type="text"
                      data-id="defense lawyer"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0166"
                        name="cmd_0166"
                        formControlName="cmd_0166"
                        value="1"
                      />
                      Trial date set - awaiting trial
                    </label>
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle">Trial Date:</label>
                  </div>
                  <div class="mb-3  col-md-6 input-group">
                    <div class="input-group">
                      <input
                        style="width: 50%"
                        class="form-control onboard datepicker"
                        placement="bottom"
                        placeholder="MM/DD/YYYY"
                        id="1220"
                        name="1220"
                        formControlName="1220"
                        type="text"
                        data-id="trail date"
                        ngbDatepicker
                        #ga77="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1220')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga77.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1220').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1220').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1220').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1220').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1220').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0167"
                        name="cmd_0167"
                        formControlName="cmd_0167"
                        value="1"
                      />
                      Dismissed
                    </label>
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle"
                      >Date of Dismissal:</label
                    >
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6">
                    <div class="input-group">
                      <input
                        style="width: 50%"
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1222"
                        name="1222"
                        formControlName="1222"
                        type="text"
                        data-id="date of dismissal"
                        ngbDatepicker
                        #ga78="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1222')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga78.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1222').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1222').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1222').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1222').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1222').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="9" width="50%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0168"
                        name="cmd_0168"
                        formControlName="cmd_0168"
                        value="1"
                      />
                      Defense Verdict
                    </label>
                  </div>
                </td>
                <td colspan="9" width="50%">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle"
                      >Date of Defense Verdict:</label
                    >
                  </div>
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <div class="input-group">
                      <input
                        style="width: 50%"
                        class="form-control onboard datepicker input-group "
                        placeholder="MM/DD/YYYY"
                        id="1223"
                        name="1223"
                        formControlName="1223"
                        type="text"
                        data-id="defense verdict"
                        ngbDatepicker
                        #ga79="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1223')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga79.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1223').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1223').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1223').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1223').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1223').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
              </tr>
            </table>

            <table border="1" width="100%">
              <tr>
                <td colspan="6" width="25%">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0168"
                        name="cmd_0168"
                        formControlName="cmd_0168"
                        value="1"
                      />
                      Settled out of court
                    </label>
                  </div>
                </td>
                <td colspan="6">
                  <div
                    class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin col-md-6 inputPadding"
                  >
                    <label class="normalParagraphStyle">Date:</label>
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1226"
                        name="1226"
                        formControlName="1226"
                        type="text"
                        data-id="settled date"
                        ngbDatepicker
                        #ga80="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1226')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga80.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1226').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1226').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1226').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1226').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1226').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
                <td colspan="6">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap inputPadding">
                    <label class="normalParagraphStyle"
                      >Total Amount of Settlement:<b> $</b></label
                    >
                    <input
                      class="form-control onboard col-md-5"
                      id="1228"
                      name="1228"
                      formControlName="1228"
                      type="text"
                      data-id="amount settled"
                    />
                  </div>
                </td>
                <td colspan="6">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Date of Defense Verdict:<b> $</b></label
                    >
                    <input
                      class="form-control onboard "
                      id="1229"
                      name="1229"
                      formControlName="1229"
                      type="text"
                      data-id="settle amount paid by prov"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="checkbox"
                        id="cmd_0169"
                        name="cmd_0169"
                        formControlName="cmd_0169"
                        value="1"
                      />
                      Judgment
                    </label>
                  </div>
                </td>
                <td colspan="6">
                  <div class="mb-3 col-md-6 inputPadding">
                    <label class="normalParagraphStyle">Date:</label>
                  </div>
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <div class="input-group">
                      <input
                        placement="buttom"
                        style="width: 50%"
                        class="form-control"
                        placeholder="MM/DD/YYYY"
                        id="1227"
                        name="1227"
                        formControlName="1227"
                        type="text"
                        data-id="Jedgement date"
                        ngbDatepicker
                        #ga81="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1227')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="ga81.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="gaUnifiedForm.get('1227').invalid ">
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1227').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1227').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="gaUnifiedForm.get('1227').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="gaUnifiedForm.get('1227').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </td>
                <td colspan="6">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Total Amount of Judgment:<b> $</b></label
                    >
                    <input
                      class="form-control onboard "
                      id="1231"
                      name="1231"
                      formControlName="1231"
                      type="text"
                      data-id="Amount of Judgement"
                    />
                  </div>
                </td>
                <td colspan="6">
                  <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                    <label class="normalParagraphStyle"
                      >Date of Defense Verdict:<b> $</b></label
                    >
                    <input
                      class="form-control onboard "
                      id="1232"
                      name="1232"
                      formControlName="1232"
                      type="text"
                      data-id="judge amount paid by prov"
                    />
                  </div>
                </td>
              </tr>
            </table>

            <p>
              This Professional Liability Claims Information Form is required on
              all claims/lawsuits that are reported by your malpractice
              insurance carrier and/or the National Practitioner Data Bank.
              Clinical details are required for all suits, regardless of status
              or settlement amount.
            </p>
            <br />
            <p>
              I certify that the information contained in this form is correct
              and complete (even if N/A) to the best of my knowledge.
            </p>
            <tr>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <label class="boldParagraphStyle"
                  >Signature of Applicant:</label
                >
                <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                  <img
                    *ngIf="!showThree"
                    class="img"
                    src="./assets/images/e_sign.png"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <img
                    *ngIf="showThree"
                    class="img eSignImageShow"
                    [src]="esignThree"
                    alt="Credential my Doc"
                    (click)="esignOpenThree()"
                  />
                  <input
                    type="hidden"
                    name="cmd_09931"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_09931'].touched &&
                        gaUnifiedForm.controls['cmd_09931'].errors) ||
                      (gaUnifiedForm.controls['cmd_09931'].errors && isSubmit)
                    "
                    id="cmd_09931"
                    formControlName="cmd_09931"
                  />
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      gaUnifiedForm.controls['cmd_09931'].touched &&
                      gaUnifiedForm.controls['cmd_09931'].errors
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="gaUnifiedForm.controls['cmd_09931'].errors"
                    >
                      required
                    </small>
                  </div>
                  <span
                    *ngIf="
                      (gaUnifiedForm.controls['cmd_09931'].touched &&
                        gaUnifiedForm.controls['cmd_09931'].invalid) ||
                      (gaUnifiedForm.controls['cmd_09931'].invalid && isSubmit)
                    "
                  >
                    <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                </div>
              </div>
            </tr>
            <tr>
              <div class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding">
                <div
                  class="mb-3 d-flex flex-row align-items-center flex-wrap no-margin inputPadding input-group spacing"
                >
                  <input
                    placement="buttom"
                    style="width: 50%"
                    class="form-control"
                    placeholder="MM/DD/YYYY"
                    id="cmd_0196"
                    name="cmd_0196"
                    [class.is-invalid]="
                      (gaUnifiedForm.controls['cmd_0196'].touched &&
                        gaUnifiedForm.controls['cmd_0196'].errors) ||
                      (gaUnifiedForm.controls['cmd_0196'].errors && isSubmit)
                    "
                    formControlName="cmd_0196"
                    type="text"
                    data-id="Jedgement date"
                    ngbDatepicker
                    #ga801="ngbDatepicker"
                    (blur)="clearInputIfInvalidMandi('cmd_0196')"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="ga801.toggle()"
                      type="button"
                    >
                      <i class="fas fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="mandatory" *ngIf="(gaUnifiedForm.controls['cmd_0196'].invalid&&gaUnifiedForm.controls['cmd_0196'].touched) && (gaUnifiedForm.controls['cmd_0196'].value==''|| gaUnifiedForm.controls['cmd_0196'].value==null) " >
                  <small >
                  required
                  </small>

              </div>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0196').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)                </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0196').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0196').errors?.invalidDateyear">
                  year is out of range(1900-2099)                  </small>
                  <small class="text-danger" *ngIf="gaUnifiedForm.get('cmd_0196').errors?.invalidDateRange">
                    date is out of range
                    </small>
              </div>
            </tr>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer mt-4">
    <button
      *ngIf="formData.receivedForm"
      type="button"
      class="btn customOrange float-end"
      (click)="saveForm('approve')"
    >
      Approve
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('submit')"
    >
      Submit
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('save')"
    >
      Save
    </button>
    <button
      *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-end"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
