import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule } from '@angular/forms';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgClass } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaskInputDirectiveForMonth } from '../MaskInputDirectiveForMonth.directive';

@Component({
    selector: 'ctg-month-mode',
    templateUrl: './month-mode.component.html',
    styleUrls: ['./month-mode.component.scss'],
    standalone: true,
    imports: [FormsModule, MaskInputDirectiveForMonth, NgbPopoverModule, NgSelectModule, NgFor, NgClass]
})
export class MonthModeComponent implements OnInit {

  @ViewChild('pop') pop: NgbPopover;
  @Input('initialVal') initialVal;
  @Output() returnValue: EventEmitter<any> = new EventEmitter();
  constructor(private fb: UntypedFormBuilder) { }
  public form: UntypedFormGroup
  ngOnInit(): void {
    // //// console.log(this.initialVal);
    this.selectedYearMonth = this.initialVal ? this.initialVal : '';
    this.selectedMonth = this.returnMonth(this.initialVal);
    this.selectedYear = this.returnYear(this.initialVal)
    this.loadYears()
  }

  ngOnChanges() {
    this.selectedYearMonth = this.initialVal ? this.initialVal : '';
    this.selectedMonth = this.returnMonth(this.initialVal);
    this.selectedYear = this.returnYear(this.initialVal)
  }
  public selectedYearMonth: string = '01/2020'
  public selectedMonth: string = "01";
  public months: Array<any> = [
    {
      name: 'Jan',
      value: '01'
    },
    {
      name: 'Feb',
      value: '02'
    },
    {
      name: 'Mar',
      value: '03'
    },
    {
      name: 'Apr',
      value: '04'
    },
    {
      name: 'May',
      value: '05'
    },
    {
      name: 'Jun',
      value: '06'
    },
    {
      name: 'Jul',
      value: '07'
    },
    {
      name: 'Aug',
      value: '08'
    },
    {
      name: 'Sept',
      value: '09'
    },
    {
      name: 'Oct',
      value: '10'
    },
    {
      name: 'Nov',
      value: '11'
    },
    {
      name: 'Dec',
      value: '12'
    },

  ]

  public selectedYear = '2020'
  public years: Array<any> = [];

  loadYears() {
    for (let index = 1900; index < 2100; index++) {
      let t = {
        name: index.toString(),
        id: index.toString()
      }
      this.years.push(t)
    }
  }

  handlePopover(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  returnMonth(t) {
   
    if (t == '' || t == undefined || t == null) {
      return ''
    }
    else {
      let m = t.substring(0, t.indexOf('/'))
      return m
    }
  }
  returnYear(t) {
  
    if (t == '' || t == undefined || t == null) {
      return this.selectedYear
    }
    else {
      let m = t.substring(t.indexOf('/') + 1, t.length)
      return m
    }
  }

  onBlur() {
    this.selectedYearMonth = this.handleFormat(this.selectedYearMonth)
    this.returnValue.emit(this.selectedYearMonth)
 }

 handleFormat(value: string){

  if (value == '' || value == undefined || value == null || (/^(0[1-9]|1[0-2])\/?([0-9]{4})$/.test(value)==false )) {
    return value = '';
  }
  else {
    if (value.length > 3) {
          let month = value.substring(0, value.indexOf('/'))
              month = month.length < 2 ? '0' + month : month
          let year = value.substring(value.lastIndexOf('/') + 1, value.length);
              return month + '/' + year
    }
  }
 }

  handleDateChange(e){
 
    if(e.length==2){
      this.selectedMonth = e;
      this.selectedYear = '';
      this.handleYear()
    }
    else if(e.length>2){
      this.selectedMonth = this.returnMonth(e);
      this.selectedYear = this.returnYear(e)
    }
   
    if(e.length==7){
      this.handleYear()
    }
  
    if(e.length==0){
      this.returnValue.emit("")
    }
    
  }


  handleYear() {
    if (this.selectedMonth !== '') {
      this.selectedYearMonth = this.selectedMonth + '/' + this.selectedYear;
    }
    else {
      this.selectedMonth = '01'
      this.selectedYearMonth = this.selectedMonth + '/' + this.selectedYear;
    }
    this.returnValue.emit(this.selectedYearMonth)
  }
  handleMonthSelect(item) {
    this.selectedMonth = item.value;
    this.selectedYearMonth = item.value + '/' + this.selectedYear;
    this.returnValue.emit(this.selectedYearMonth)
    // this.pop.close()
  }
}
