<div id="cancercare">
    <form [formGroup]="CancerCareNorthwestForms1">
            <table border="1" width="100%" class="center"> 
                 <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>19. PEER REFERENCES</h6></label>
                    </th>     
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_19')" formControlName="cmd_NA_19"
                            id="cmd_NA_19">
                    </th>                           
                </tr>
                <tr>
                    <td colspan="12">
                        <label class="formLabel">List at least <b>three</b>  professional references, from your specialty area, not including relatives, who have worked with you in the
                            past two years. References must be from individuals who, through recent observation, are directly familiar with your work and
                            can attest to your clinical competence in your specialty area. <b>Please provide approximate From and To dates you have
                            known the identified peer reference.</b> If you have been out of residency or fellowship for a period of less than three years,
                            one reference must be from the Program Director. Allied Health Providers must provide at least one reference from their
                            same discipline.</label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference 1" id="359" 
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['359'].invalid" formControlName="359" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['359'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 1 Title" id="2722" formControlName="2722" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['2722'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['2722'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                           
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Specialty"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['368'].invalid" bindValue="id" formControlName="368"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['368'].invalid">
                                <small >
                                required
                                </small>
                            </div>         
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 1 E-mail Address" id="371" formControlName="371"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['371'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['371'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 1 Address 1" id="360" formControlName="360" 
                                (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['360'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['360'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 1 Address 2" id="361" formControlName="361" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 1 City" id="362" formControlName="362" 
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['362'].invalid" class="form-control">
                            <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['362'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter State" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['364'].invalid"  formControlName="364"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['364'].invalid">
                                <small >
                                required
                                </small>
                            </div>                
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 1 Zip" id="365" formControlName="365" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['365'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['365'].invalid">
                                <small >
                                required
                                </small>
                            </div>  
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            
                        <input type="text" placeholder="Enter Reference 1 Telephone Number" id="369" formControlName="369" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['369'].invalid" class="form-control">
                        <small
                        class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['369'].errors?.pattern">
                        Only number are allowed</small>
                        <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['369'].invalid">
                                <small >
                                required
                                </small>
                            </div>  
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 1 Fax Number" id="370" formControlName="370"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['370'].invalid" class="form-control">
                            <small
                        class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['370'].errors?.pattern">
                        Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['370'].invalid">
                                <small >
                                required
                                </small>
                            </div>                
                        </div>                                    
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number</label>
                        </div>
                            
                        <div class="col-md-12">
                           
                         
                       <input type="text" placeholder="Enter Reference 1Cell Phone Number" id="cmd_252" formControlName="cmd_252"  (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_252'].invalid"  class="form-control">
                       <small
                       class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_252'].errors?.pattern">
                       Only number are allowed</small>
                       <div class="mandatory"
                       *ngIf="CancerCareNorthwestForms1.controls['cmd_252'].invalid">
                       <small >
                       required
                       </small>
                   </div> 
                    </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d366="ngbDatepicker" id="366" (blur)="clearInputIfInvalid('366','cmd_NA_19')"
                                    (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['366'].invalid" class="form-control" formControlName="366">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d366.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                   
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['366'].invalid && (CancerCareNorthwestForms1.controls['366'].value==''|| CancerCareNorthwestForms1.controls['366'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('366').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('366').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('366').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('366').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('366').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d367="ngbDatepicker" id="367" class="form-control" (blur)="clearInputIfInvalid('367','cmd_NA_19')"
                                    (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['367'].invalid" formControlName="367">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d367.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                      
                                </div> 
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['367'].invalid && (CancerCareNorthwestForms1.controls['367'].value==''|| CancerCareNorthwestForms1.controls['367'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('367').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('367').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('367').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('367').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('367').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                                
                            </div> 
                                                            
                    </td>
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference 2" id="372" formControlName="372"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['372'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['372'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 Title" id="2723" formControlName="2723" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['2723'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['2723'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Specialty" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['380'].invalid" formControlName="380"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['380'].invalid">
                                <small >
                                required
                                </small>
                            </div>      
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 E-mail Address" id="383" formControlName="383" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['383'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['383'].invalid">
                                <small >
                                required
                                </small>
                            </div>  
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 2 Address 1" id="373" formControlName="373" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['373'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['373'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>  
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 2 Address 2" id="374" formControlName="374" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 City" id="375" formControlName="375" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['375'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['375'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>  

                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter  State" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['377'].invalid" bindValue="id" formControlName="377"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['377'].invalid">
                                <small >
                                required
                                </small>
                            </div>             
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 Zip" id="378" formControlName="378" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['378'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['378'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Ente Reference 2 Telephone Number" id="381" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['381'].invalid" formControlName="381" class="form-control">
                        
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['381'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['381'].invalid">
                                <small >
                                required
                                </small>
                            </div>   
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 Fax Number" id="382" formControlName="382" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['382'].invalid" class="form-control">							
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['382'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['382'].invalid">
                                <small >
                                required
                                </small>
                            </div>   
                        </div>                                    
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 2 Cell Phone Number" id="cmd_253" formControlName="cmd_253"  (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_253'].invalid" class="form-control">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_253'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                       *ngIf="CancerCareNorthwestForms1.controls['cmd_253'].invalid">
                       <small >
                       required
                       </small>
                   </div> 
                        </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d817="ngbDatepicker" id="817" class="form-control" (blur)="clearInputIfInvalid('817','cmd_NA_19')" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['817'].invalid" formControlName="817">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d817.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                       
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['817'].invalid && (CancerCareNorthwestForms1.controls['817'].value==''|| CancerCareNorthwestForms1.controls['817'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('817').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('817').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('817').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('817').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('817').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d379="ngbDatepicker" id="379" class="form-control" (blur)="clearInputIfInvalid('379','cmd_NA_19')" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['379'].invalid" formControlName="379">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d379.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['379'].invalid && (CancerCareNorthwestForms1.controls['379'].value==''|| CancerCareNorthwestForms1.controls['379'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('379').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('379').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('379').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('379').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('379').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                             
                            </div> 
                                                            
                    </td>
                </tr>   
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference 3" id="384" formControlName="384" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['384'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['384'].invalid">
                                <small >
                                required
                                </small>
                            </div>   
                        </div>                                    
                    </td>
                
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 Title" id="2725" formControlName="2725" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['2725'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['2725'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Specialty" bindValue="id" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['393'].invalid" formControlName="393"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['393'].invalid">
                                <small >
                                required
                                </small>
                            </div>          
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 E-mail Address" id="396" formControlName="396" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['396'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['396'].invalid">
                                <small >
                                required
                                </small>
                            </div>   
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 3 Address 1" id="385" formControlName="385" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['385'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['385'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>   
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Reference 3 Address 2" id="386" formControlName="386" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 City" id="387" formControlName="387"  (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['387'].invalid"class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['387'].invalid">
                                <small >
                                required
                                </small>
                            </div>   
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter State" bindValue="id" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['389'].invalid" formControlName="389"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['389'].invalid">
                                <small >
                                required
                                </small>
                            </div>               
                        </div>                                    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 Zip" id="390" formControlName="390" class="form-control" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['390'].invalid">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['390'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 Telephone Number" id="394" formControlName="394" class="form-control" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['394'].invalid">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['394'].errors?.pattern">
                            Only number are allowed</small> 
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['394'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 Fax Number" id="395" formControlName="395"  (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['395'].invalid" class="form-control">							
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['395'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['395'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>                                    
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reference 3 Cell Phone Number" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_254'].invalid"  id="cmd_254" formControlName="cmd_254" class="form-control">
                            
                            <small
                        class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_254'].errors?.pattern">
                        Only number are allowed</small><div class="mandatory"
                       *ngIf="CancerCareNorthwestForms1.controls['cmd_254'].invalid">
                       <small >
                       required
                       </small>
                   </div> 
                        </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d391="ngbDatepicker" id="391" class="form-control" (blur)="clearInputIfInvalid('391','cmd_NA_19')" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['391'].invalid" formControlName="391">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d391.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                           
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['391'].invalid && (CancerCareNorthwestForms1.controls['391'].value==''|| CancerCareNorthwestForms1.controls['391'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('391').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('391').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('391').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('391').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('391').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d392="ngbDatepicker" id="392" class="form-control" formControlName="392" (blur)="clearInputIfInvalid('392','cmd_NA_19')" (blur)="handleNAchange('cmd_NA_19')" [class.is-invalid]="CancerCareNorthwestForms1.controls['392'].invalid">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d392.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                            
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['392'].invalid && (CancerCareNorthwestForms1.controls['392'].value==''|| CancerCareNorthwestForms1.controls['392'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('392').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('392').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('392').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('392').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('392').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                                
                            </div> 
                                                            
                    </td>
                </tr>   
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>20. PROFESSIONAL AFFILIATIONS <i>(Do not abbreviate)</i></h6></label>
                    </th>   
                                             
                </tr> 
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">Please List Membership In All Professional Societies
                                Complete Name of Society: </label>
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Date Joined</label>
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Current Member</label>
                        </div>  
                    </td>
                </tr>

                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <input type="text" id="1695" placeholder="Enter Name of Professional Society 1"  class="form-control" formControlName="1695" > 
                            
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="1696" placeholder="Professional Society Start Date 1"  class="form-control" formControlName="1696" (blur)="clearInputIfInvalid('1696')"   class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa75="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa75.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms1.get('1696').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1696').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1696').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1696').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1696').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                        
                                <input type="Radio" value="1" name="cmd_253"  formControlName="cmd_253"  id="cmd_253">
                                <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="Radio" value="0" name="cmd_253" formControlName="cmd_253"  id="cmd_253">
                                <label for="No">No</label>
                                
                            
                        </div>  
                    </td>
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <input type="text" id="1701" placeholder="Enter Name of Professional Society 2" class="form-control"  formControlName="1701" >
                                
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="1702" class="form-control" placeholder="Professional Society Start Date 2" formControlName="1702" (blur)="clearInputIfInvalid('1702')"  class="form-control datepicker"  data-id="priornameeffdate_date" ngbDatepicker #wpa075="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa075.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                        
                            </div>     
                            <div *ngIf="CancerCareNorthwestForms1.get('1702').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1702').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1702').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1702').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1702').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>        
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                        
                            <input type="Radio" value="1" name="cmd_255" formControlName="cmd_255" id="cmd_255" >
                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="Radio" value="0" name="cmd_255" formControlName="cmd_255" id="cmd_255" >
                            <label for="No">No</label>

                            
                        </div>  
                    </td>
                </tr>
            </table>    
            <table width="100%" class="center" border="2">    
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>21. PROFESSIONAL LIABILITY</h6></label>
                    </th> 
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_21')" formControlName="cmd_NA_21"
                            id="cmd_NA_21">
                    </th>                               
                </tr> 
            
            
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>A. Current Insurance Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="345" formControlName="345" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['345'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['345'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="346" formControlName="346" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['346'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['346'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="901" formControlName="901" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['901'].invalid" class="form-control">                      
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['901'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="902" formControlName="902" class="form-control">                        
                            </div>
                                
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="903" formControlName="903" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['903'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['903'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="905" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['905'].invalid"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['905'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip" id="906" formControlName="906"  (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['906'].invalid"class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['906'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1266" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1266'].invalid" formControlName="1266" class="form-control">
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['1266'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="1273" formControlName="1273"  class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('1273').invalid && CancerCareNorthwestForms1.get('1273').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1273').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>  
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-Mail" id="1639" formControlName="1639" class="form-control" >
                                <div  *ngIf="CancerCareNorthwestForms1.get('1639').invalid && CancerCareNorthwestForms1.get('1639').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1639').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="350" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['350'].invalid"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['350'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates" bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="351" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['351'].invalid"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['351'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Issue Date " id="348" (blur)="clearInputIfInvalid('348','cmd_NA_21')" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['348'].invalid" formControlName="348" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa101="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa101.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                          
                                </div>  
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['348'].invalid && (CancerCareNorthwestForms1.controls['348'].value==''|| CancerCareNorthwestForms1.controls['348'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('348').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('348').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('348').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('348').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('348').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>          
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Expiration Date " id="349" formControlName="349" class="form-control datepicker" (blur)="clearInputIfInvalid('349','cmd_NA_21')" (blur)="handleNAchange('cmd_NA_21')" [class.is-invalid]="CancerCareNorthwestForms1.controls['349'].invalid" data-id="priornameeffdate_date" ngbDatepicker #wpa1001="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="wpa1001.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['349'].invalid && (CancerCareNorthwestForms1.controls['349'].value==''|| CancerCareNorthwestForms1.controls['349'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('349').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('349').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('349').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('349').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('349').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                               
                            </div>
                                    
                    </td> 
                </tr>    
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>B. PREVIOUS PROFESSIONAL LIABILITY CARRIERS WITHIN THE LAST TEN YEARS</h6><b> (Do not abbreviate)
                                    (Attach Additional Sheet if Necessary)</b></label>
                    </th> 
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_22')" formControlName="cmd_NA_22"
                            id="cmd_NA_22">
                    </th>                               
                </tr> 
                 <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="1242" formControlName="1242" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1242'].invalid" class="form-control" >
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['1242'].invalid">
                                <small >
                                required
                                </small>
                            </div>          
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="1256" formControlName="1256" class="form-control" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1256'].invalid" >
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['1256'].invalid">
                                <small >
                                required
                                </small>
                            </div>          
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="1244" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1244'].invalid"  formControlName="1244" class="form-control" >
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1244'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>  
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="1245" formControlName="1245" class="form-control" >
                                    
                                        
                            </div>
                                 
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="1246" formControlName="1246" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1246'].invalid"  class="form-control" >
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1246'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>          
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="1248" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1248'].invalid" > </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1248'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>         
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="1249" formControlName="1249" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1249'].invalid"  class="form-control" 
                                >
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1249'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>          
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1254" formControlName="1254" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1254'].invalid"  class="form-control">                                                                    
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1254'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>  
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="1255"class="form-control"   formControlName="1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('1255').invalid && CancerCareNorthwestForms1.get('1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>  
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="1857" formControlName="1857"   class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('1857').invalid && CancerCareNorthwestForms1.get('1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"  bindLabel="name" placeholder="Enter claim amount" bindValue="id" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1264'].invalid"  formControlName="1264"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1264'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>         
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1265'].invalid"  formControlName="1265"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1265'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>             
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="1252" (blur)="clearInputIfInvalid('1252','cmd_NA_22')" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1252'].invalid"  ngbDatepicker #wpa76="ngbDatepicker" formControlName="1252" class="form-control datepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa76.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>       
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms1.controls['1252'].invalid && (CancerCareNorthwestForms1.controls['1252'].value==''|| CancerCareNorthwestForms1.controls['1252'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms1.get('1252').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1252').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1252').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1252').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1252').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>     
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="1253" formControlName="1253"(blur)="clearInputIfInvalid('1253','cmd_NA_22')" (blur)="handleNAchange('cmd_NA_22')" [class.is-invalid]="CancerCareNorthwestForms1.controls['1253'].invalid"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa77="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa77.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                       
                                    
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms1.controls['1253'].invalid && (CancerCareNorthwestForms1.controls['1253'].value==''|| CancerCareNorthwestForms1.controls['1253'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms1.get('1253').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1253').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1253').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1253').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1253').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_1_1242" formControlName="add_1_1242" class="form-control">			
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_1_1256" formControlName="add_1_1256" class="form-control">	
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_1_1244" formControlName="add_1_1244" class="form-control">
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_1_1245" formControlName="add_1_1245" class="form-control">		
                            </div>
                                 
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_1_1246" formControlName="add_1_1246" class="form-control">		
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_1_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_1_1249" formControlName="add_1_1249" class="form-control">	
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_1_1254" formControlName="add_1_1254" class="form-control">    
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_1_1254').invalid && CancerCareNorthwestForms1.get('add_1_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>  
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_1_1255" formControlName="add_1_1255" class="form-control">    
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_1_1255').invalid && CancerCareNorthwestForms1.get('add_1_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>  
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_1_1857" formControlName="add_1_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_1_1857').invalid && CancerCareNorthwestForms1.get('add_1_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"  bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_1_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_1_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_1_1252" formControlName="add_1_1252" (blur)="clearInputIfInvalid('add_1_1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa076="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa076.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>   
                                <div *ngIf="CancerCareNorthwestForms1.get('add_1_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_1_1253" formControlName="add_1_1253" (blur)="clearInputIfInvalid('add_1_1253')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa177="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa177.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            <div *ngIf="CancerCareNorthwestForms1.get('add_1_1253').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1253').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1253').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1253').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_1_1253').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_2_1242" formControlName="add_2_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_2_1256" formControlName="add_2_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_2_1244" formControlName="add_2_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_2_1245" formControlName="add_2_1245" class="form-control" >
                                
                                        
                            </div>
                               
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_2_1246" formControlName="add_2_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_2_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_2_1249" formControlName="add_2_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_2_1254" formControlName="add_2_1254" class="form-control" >
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_2_1254').invalid && CancerCareNorthwestForms1.get('add_2_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>      
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_2_1255"class="form-control"  formControlName="add_2_1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_2_1255').invalid && CancerCareNorthwestForms1.get('add_2_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>          
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_2_1857" formControlName="add_2_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_2_1857').invalid && CancerCareNorthwestForms1.get('add_2_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_2_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_2_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_2_1252" formControlName="add_2_1252" (blur)="clearInputIfInvalid('add_2_1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa276="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa276.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>  
                                <div *ngIf="CancerCareNorthwestForms1.get('add_2_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>          
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                    <input type="text" placeholder="Enter Expiration Date" id="add_2_1253" formControlName="add_2_1253" (blur)="clearInputIfInvalid('add_2_1253')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa277="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa277.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_2_1253').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1253').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_2_1253').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                                
                    </td> 
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_3_1242" formControlName="add_3_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_3_1256" formControlName="add_3_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_3_1244" formControlName="add_3_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_3_1245" formControlName="add_3_1245" class="form-control" >
                                
                                        
                            </div>
                                 
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_3_1246" formControlName="add_3_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_3_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_3_1249" formControlName="add_3_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_3_1254" formControlName="add_3_1254" class="form-control" >
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_3_1254').invalid && CancerCareNorthwestForms1.get('add_3_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>      
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_3_1255"class="form-control"  formControlName="add_3_1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_3_1255').invalid && CancerCareNorthwestForms1.get('add_3_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>      
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_3_1857" formControlName="add_3_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_3_1857').invalid && CancerCareNorthwestForms1.get('add_3_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_3_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_3_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_3_1252" (blur)="clearInputIfInvalid('add_3_1252')"  formControlName="add_3_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa0676="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa0676.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div> 
                                <div *ngIf="CancerCareNorthwestForms1.get('add_3_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>     
                            </div>
                                    
                    </td> 
                   <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date(mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Expiration Date" ngbDatepicker #d0031="ngbDatepicker" id="add_3_1253" (blur)="clearInputIfInvalid('add_3_1253')"  class="form-control" formControlName="add_3_1253">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d0031.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_3_1253').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1253').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_3_1253').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                                    
                    </td> 
                </tr> 
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_4_1242" formControlName="add_4_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_4_1256" formControlName="add_4_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                       
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_4_1244" formControlName="add_4_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_4_1245" formControlName="add_4_1245" class="form-control" >
                                
                                        
                            </div>
                               
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_4_1246" formControlName="add_4_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_4_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_4_1249" formControlName="add_4_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_4_1254" formControlName="add_4_1254" class="form-control" >
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_4_1254').invalid && CancerCareNorthwestForms1.get('add_4_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>       
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_4_1255" class="form-control"  formControlName="add_4_1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_4_1255').invalid && CancerCareNorthwestForms1.get('add_4_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>      
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_4_1857" formControlName="add_4_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_4_1857').invalid && CancerCareNorthwestForms1.get('add_4_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_4_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_4_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_4_1252" formControlName="add_4_1252" (blur)="clearInputIfInvalid('add_4_1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa476="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa476.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_4_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_4_1253" formControlName="add_4_1253" (blur)="clearInputIfInvalid('add_4_1253')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa477="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa477.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            <div *ngIf="CancerCareNorthwestForms1.get('add_4_1253').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1253').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1253').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1253').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_4_1253').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_5_1242" formControlName="add_5_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_5_1256" formControlName="add_5_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_5_1244" formControlName="add_5_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_5_1245" formControlName="add_5_1245" class="form-control" >
                                
                                        
                            </div>
                                
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_5_1246" formControlName="add_5_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_5_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_5_1249" formControlName="add_5_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_5_1254" formControlName="add_5_1254" class="form-control" >
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_5_1254').invalid && CancerCareNorthwestForms1.get('add_5_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>      
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_5_1255"class="form-control"  formControlName="add_5_1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_5_1255').invalid && CancerCareNorthwestForms1.get('add_5_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>    
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_5_1857" formControlName="add_5_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_5_1857').invalid && CancerCareNorthwestForms1.get('add_5_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div>   
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_5_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_5_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_5_1252" formControlName="add_5_1252" (blur)="clearInputIfInvalid('add_5_1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa576="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa576.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_5_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>           
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_5_1253" formControlName="add_5_1253" (blur)="clearInputIfInvalid('add_5_1253')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa577="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa577.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            <div *ngIf="CancerCareNorthwestForms1.get('add_5_1253').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1253').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1253').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1253').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_5_1253').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div> 
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_6_1242" formControlName="add_6_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_6_1256" formControlName="add_6_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                      
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_6_1244" formControlName="add_6_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="add_6_1245" formControlName="add_6_1245" class="form-control" >
                                
                                        
                            </div>
                            
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_6_1246" formControlName="add_6_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_6_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_6_1249" formControlName="add_6_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_6_1254" formControlName="add_6_1254" class="form-control" >
                                        
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_6_1254').invalid && CancerCareNorthwestForms1.get('add_6_1254').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1254').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>
                            </div>
                                    
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_6_1255"class="form-control"  formControlName="add_6_1255" > 
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_6_1255').invalid && CancerCareNorthwestForms1.get('add_6_1255').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1255').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter E-mail" id="add_6_1857" formControlName="add_6_1857" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms1.get('add_6_1857').invalid && CancerCareNorthwestForms1.get('add_6_1857').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1857').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div>   
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_6_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter Aggregate amount" bindValue="id" formControlName="add_6_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_6_1252" formControlName="add_6_1252" (blur)="clearInputIfInvalid('add_6_1252')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa676="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa676.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_6_1252').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1252').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1252').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1252').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1252').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                                
                            </div>
                                    
                    </td> 
                   <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date(mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Expiration Date" ngbDatepicker #d031="ngbDatepicker" id="add_6_1253" (blur)="clearInputIfInvalid('add_6_1253')"  class="form-control" formControlName="add_6_1253">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d031.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div *ngIf="CancerCareNorthwestForms1.get('add_6_1253').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1253').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1253').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1253').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('add_6_1253').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div> 
                            </div>
                                    
                    </td> 
                </tr>
                
            </table>
            <table width="100%" class="center">  
                 <tr>
                    <td colspan="12">
                        
                            <label class="sideheading"  >
                                <h6>WASHINGTON PRACTITIONER ATTESTATION QUESTIONS -<i> To be completed by the practitioner</i></h6> </label>
                    </td>
                </tr> 
            </table>
            <table border="2" width="100%" class="center" >          
                <tr>
                    <td colspan="12">
                        <label class="formLabel">Please answer all of the following questions. If your answer to any of the following questions is 'Yes", provide details as specified
                            on a separate sheet.<i> If you attach additional sheets, sign and date each sheet.</i></label>
                    </td>   
                </tr>
                
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>A.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>PROFESSIONAL SANCTIONS</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been, or are you now in the process of being denied, revoked, terminated, suspended, restricted, reduced,
                                limited, sanctioned, placed on probation, monitored, or not renewed for any of the following? Or have you voluntarily or
                                involuntarily relinquished, withdrawn, or failed to proceed with an application for any of the following in order to avoid an
                                adverse action or to preclude an investigation or while under investigation relating to professional competence or conduct?
                            </label>
                        </div>        

                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">a.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">License to practice any profession in any jurisdiction</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_329"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_329'].touched && CancerCareNorthwestForms1.controls['cmd_329'].invalid) || (CancerCareNorthwestForms1.controls['cmd_329'].invalid && isSubmit)"
                                    id="cmd_329" name="cmd_329" value="1">
                    
                                <label>Yes</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_329'].touched && CancerCareNorthwestForms1.controls['cmd_329'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_329'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_329"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_329'].touched && CancerCareNorthwestForms1.controls['cmd_329'].invalid) || (CancerCareNorthwestForms1.controls['cmd_329'].invalid && isSubmit)"
                                    id="cmd_329" name="cmd_329" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_329'].touched && CancerCareNorthwestForms1.controls['cmd_329'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_329'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_329'].touched && CancerCareNorthwestForms1.controls['cmd_329'].invalid) || (CancerCareNorthwestForms1.controls['cmd_329'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>

                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">b.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Other professional registration or certification in any jurisdiction</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_330"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_330'].touched && CancerCareNorthwestForms1.controls['cmd_330'].invalid) || (CancerCareNorthwestForms1.controls['cmd_330'].invalid && isSubmit)"
                                    id="cmd_330" name="cmd_330" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_330'].touched && CancerCareNorthwestForms1.controls['cmd_330'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_330'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_330"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_330'].touched && CancerCareNorthwestForms1.controls['cmd_330'].invalid) || (CancerCareNorthwestForms1.controls['cmd_330'].invalid && isSubmit)"
                                    id="cmd_330" name="cmd_330" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_330'].touched && CancerCareNorthwestForms1.controls['cmd_330'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_330'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_330'].touched && CancerCareNorthwestForms1.controls['cmd_330'].invalid) || (CancerCareNorthwestForms1.controls['cmd_330'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">c.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty or subspecialty board certification</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            
                            <input type="radio" formControlName="cmd_331"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_331'].touched && CancerCareNorthwestForms1.controls['cmd_331'].invalid) || (CancerCareNorthwestForms1.controls['cmd_331'].invalid && isSubmit)"
                            id="cmd_331" name="cmd_331" value="1">
            
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_331'].touched && CancerCareNorthwestForms1.controls['cmd_331'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_331'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_331"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_331'].touched && CancerCareNorthwestForms1.controls['cmd_331'].invalid) || (CancerCareNorthwestForms1.controls['cmd_331'].invalid && isSubmit)"
                                    id="cmd_331" name="cmd_331" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_331'].touched && CancerCareNorthwestForms1.controls['cmd_331'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_331'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_331'].touched && CancerCareNorthwestForms1.controls['cmd_331'].invalid) || (CancerCareNorthwestForms1.controls['cmd_331'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                                                                        
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">d.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Membership on any hospital medical staff</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_332"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_332'].touched && CancerCareNorthwestForms1.controls['cmd_332'].invalid) || (CancerCareNorthwestForms1.controls['cmd_332'].invalid && isSubmit)"
                                    id="cmd_332" name="cmd_332" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_332'].touched && CancerCareNorthwestForms1.controls['cmd_332'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_332'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_332"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_332'].touched && CancerCareNorthwestForms1.controls['cmd_332'].invalid) || (CancerCareNorthwestForms1.controls['cmd_332'].invalid && isSubmit)"
                                    id="cmd_332" name="cmd_332" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_332'].touched && CancerCareNorthwestForms1.controls['cmd_332'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_332'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_332'].touched && CancerCareNorthwestForms1.controls['cmd_332'].invalid) || (CancerCareNorthwestForms1.controls['cmd_332'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">e.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Clinical privileges at any facility, including hospitals, ambulatory surgical centers, skilled nursing
                                facilities, etc.</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_333"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_333'].touched && CancerCareNorthwestForms1.controls['cmd_333'].invalid) || (CancerCareNorthwestForms1.controls['cmd_333'].invalid && isSubmit)"
                            id="cmd_333" name="cmd_333" value="1">
            
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="CancerCareNorthwestForms1.controls['cmd_333'].touched && CancerCareNorthwestForms1.controls['cmd_333'].invalid">
                
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_333'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_333"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_333'].touched && CancerCareNorthwestForms1.controls['cmd_333'].invalid) || (CancerCareNorthwestForms1.controls['cmd_333'].invalid && isSubmit)"
                                    id="cmd_333" name="cmd_333" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_333'].touched && CancerCareNorthwestForms1.controls['cmd_333'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_333'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_333'].touched && CancerCareNorthwestForms1.controls['cmd_333'].invalid) || (CancerCareNorthwestForms1.controls['cmd_333'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">f.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Medicare, Medicaid, FDA, NIH (Office of Human Research Protection), governmental, national
                                or international regulatory agency or any public program</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_334"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_334'].touched && CancerCareNorthwestForms1.controls['cmd_334'].invalid) || (CancerCareNorthwestForms1.controls['cmd_334'].invalid && isSubmit)"
                            id="cmd_334" name="cmd_334" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_334'].touched && CancerCareNorthwestForms1.controls['cmd_334'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_334'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_334"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_334'].touched && CancerCareNorthwestForms1.controls['cmd_334'].invalid) || (CancerCareNorthwestForms1.controls['cmd_334'].invalid && isSubmit)"
                                    id="cmd_334" name="cmd_334" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_334'].touched && CancerCareNorthwestForms1.controls['cmd_334'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_334'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_334'].touched && CancerCareNorthwestForms1.controls['cmd_334'].invalid) || (CancerCareNorthwestForms1.controls['cmd_334'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">g.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Professional society membership or fellowship</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_335"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_335'].touched && CancerCareNorthwestForms1.controls['cmd_335'].invalid) || (CancerCareNorthwestForms1.controls['cmd_335'].invalid && isSubmit)"
                                    id="cmd_335" name="cmd_335" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_335'].touched && CancerCareNorthwestForms1.controls['cmd_335'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_335'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_335"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_335'].touched && CancerCareNorthwestForms1.controls['cmd_335'].invalid) || (CancerCareNorthwestForms1.controls['cmd_335'].invalid && isSubmit)"
                                    id="cmd_335" name="cmd_335" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_335'].touched && CancerCareNorthwestForms1.controls['cmd_335'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_335'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_335'].touched && CancerCareNorthwestForms1.controls['cmd_335'].invalid) || (CancerCareNorthwestForms1.controls['cmd_335'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">h.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Participation/membership in an HMO, PPO, IPA, PHO, Health Plan or other entity</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_336"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_336'].touched && CancerCareNorthwestForms1.controls['cmd_336'].invalid) || (CancerCareNorthwestForms1.controls['cmd_336'].invalid && isSubmit)"
                                    id="cmd_336" name="cmd_336" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_336'].touched && CancerCareNorthwestForms1.controls['cmd_336'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_336'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_336"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_336'].touched && CancerCareNorthwestForms1.controls['cmd_336'].invalid) || (CancerCareNorthwestForms1.controls['cmd_336'].invalid && isSubmit)"
                                    id="cmd_336" name="cmd_336" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_336'].touched && CancerCareNorthwestForms1.controls['cmd_336'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_336'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_336'].touched && CancerCareNorthwestForms1.controls['cmd_336'].invalid) || (CancerCareNorthwestForms1.controls['cmd_336'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">i.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Academic Appointment</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_337"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_337'].touched && CancerCareNorthwestForms1.controls['cmd_337'].invalid) || (CancerCareNorthwestForms1.controls['cmd_337'].invalid && isSubmit)"
                            id="cmd_337" name="cmd_337" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_337'].touched && CancerCareNorthwestForms1.controls['cmd_337'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_337'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_337"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_337'].touched && CancerCareNorthwestForms1.controls['cmd_337'].invalid) || (CancerCareNorthwestForms1.controls['cmd_337'].invalid && isSubmit)"
                                    id="cmd_337" name="cmd_337" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_337'].touched && CancerCareNorthwestForms1.controls['cmd_337'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_337'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_337'].touched && CancerCareNorthwestForms1.controls['cmd_337'].invalid) || (CancerCareNorthwestForms1.controls['cmd_337'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">j.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Authority to prescribe controlled substances (DEA or other authority)</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_338"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_338'].touched && CancerCareNorthwestForms1.controls['cmd_338'].invalid) || (CancerCareNorthwestForms1.controls['cmd_338'].invalid && isSubmit)"
                            id="cmd_338" name="cmd_338" value="1">
            
                                        <label>Yes&nbsp;&nbsp;</label>
                            
                                        <div class="formLabel col-md-11"
                                            *ngIf="CancerCareNorthwestForms1.controls['cmd_338'].touched && CancerCareNorthwestForms1.controls['cmd_338'].invalid">
                            
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_338'].errors?.required">
                            
                                                required
                            
                                            </small>
                            
                                        </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_338"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_338'].touched && CancerCareNorthwestForms1.controls['cmd_338'].invalid) || (CancerCareNorthwestForms1.controls['cmd_338'].invalid && isSubmit)"
                                    id="cmd_338" name="cmd_338" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_338'].touched && CancerCareNorthwestForms1.controls['cmd_338'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_338'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_338'].touched && CancerCareNorthwestForms1.controls['cmd_338'].invalid) || (CancerCareNorthwestForms1.controls['cmd_338'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been subject to review, challenges, and/or disciplinary action, formal or informal, by
                                an ethics committee, licensing board, medical disciplinary board, professional association or
                                education/training institution?   </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_339"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_339'].touched && CancerCareNorthwestForms1.controls['cmd_339'].invalid) || (CancerCareNorthwestForms1.controls['cmd_339'].invalid && isSubmit)"
                            id="cmd_339" name="cmd_339" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_339'].touched && CancerCareNorthwestForms1.controls['cmd_339'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_339'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_339"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_339'].touched && CancerCareNorthwestForms1.controls['cmd_339'].invalid) || (CancerCareNorthwestForms1.controls['cmd_339'].invalid && isSubmit)"
                            id="cmd_339" name="cmd_339" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_339'].touched && CancerCareNorthwestForms1.controls['cmd_339'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_339'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_339'].touched && CancerCareNorthwestForms1.controls['cmd_339'].invalid) || (CancerCareNorthwestForms1.controls['cmd_339'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you been found by a state professional disciplinary board to have committed unprofessional
                                conduct as defined in applicable state provisions?  </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_340"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_340'].touched && CancerCareNorthwestForms1.controls['cmd_340'].invalid) || (CancerCareNorthwestForms1.controls['cmd_340'].invalid && isSubmit)"
                                    id="cmd_340" name="cmd_340" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_340'].touched && CancerCareNorthwestForms1.controls['cmd_340'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_340'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_340"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_340'].touched && CancerCareNorthwestForms1.controls['cmd_340'].invalid) || (CancerCareNorthwestForms1.controls['cmd_340'].invalid && isSubmit)"
                                    id="cmd_340" name="cmd_340" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_340'].touched && CancerCareNorthwestForms1.controls['cmd_340'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_340'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_340'].touched && CancerCareNorthwestForms1.controls['cmd_340'].invalid) || (CancerCareNorthwestForms1.controls['cmd_340'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>4.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been the subject of any reports to a state, federal, national data bank, or state
                                licensing or disciplinary entity? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_341"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_341'].touched && CancerCareNorthwestForms1.controls['cmd_341'].invalid) || (CancerCareNorthwestForms1.controls['cmd_341'].invalid && isSubmit)"
                            id="cmd_341" name="cmd_341" value="1">
            
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_341'].touched && CancerCareNorthwestForms1.controls['cmd_341'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_341'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_341"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_341'].touched && CancerCareNorthwestForms1.controls['cmd_341'].invalid) || (CancerCareNorthwestForms1.controls['cmd_341'].invalid && isSubmit)"
                            id="cmd_341" name="cmd_341" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_341'].touched && CancerCareNorthwestForms1.controls['cmd_341'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_341'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_341'].touched && CancerCareNorthwestForms1.controls['cmd_341'].invalid) || (CancerCareNorthwestForms1.controls['cmd_341'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr> 
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>B.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>CRIMINAL HISTORY</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been charged with a criminal violation (felony or misdemeanor) resulting in either a
                                plea bargain, conviction on the original or lesser charge, or payment of a fine, suspended sentence,
                                community service or other obligation? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_342"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_342'].touched && CancerCareNorthwestForms1.controls['cmd_342'].invalid) || (CancerCareNorthwestForms1.controls['cmd_342'].invalid && isSubmit)"
                                    id="cmd_342" name="cmd_342" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_342'].touched && CancerCareNorthwestForms1.controls['cmd_342'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_342'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_342"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_342'].touched && CancerCareNorthwestForms1.controls['cmd_342'].invalid) || (CancerCareNorthwestForms1.controls['cmd_342'].invalid && isSubmit)"
                            id="cmd_342" name="cmd_342" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_342'].touched && CancerCareNorthwestForms1.controls['cmd_342'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_342'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_342'].touched && CancerCareNorthwestForms1.controls['cmd_342'].invalid) || (CancerCareNorthwestForms1.controls['cmd_342'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">a.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Do you have notice of any such anticipated charges?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_343"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_343'].touched && CancerCareNorthwestForms1.controls['cmd_343'].invalid) || (CancerCareNorthwestForms1.controls['cmd_343'].invalid && isSubmit)"
                                    id="cmd_343" name="cmd_343" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_343'].touched && CancerCareNorthwestForms1.controls['cmd_343'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_343'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_343"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_343'].touched && CancerCareNorthwestForms1.controls['cmd_343'].invalid) || (CancerCareNorthwestForms1.controls['cmd_343'].invalid && isSubmit)"
                                    id="cmd_343" name="cmd_343" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_343'].touched && CancerCareNorthwestForms1.controls['cmd_343'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_343'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_343'].touched && CancerCareNorthwestForms1.controls['cmd_343'].invalid) || (CancerCareNorthwestForms1.controls['cmd_343'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>

                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">b.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Are you currently under governmental investigation?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_344"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_344'].touched && CancerCareNorthwestForms1.controls['cmd_344'].invalid) || (CancerCareNorthwestForms1.controls['cmd_344'].invalid && isSubmit)"
                                    id="cmd_344" name="cmd_344" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_344'].touched && CancerCareNorthwestForms1.controls['cmd_344'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_344'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_344"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_344'].touched && CancerCareNorthwestForms1.controls['cmd_344'].invalid) || (CancerCareNorthwestForms1.controls['cmd_344'].invalid && isSubmit)"
                                    id="cmd_344" name="cmd_344" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_344'].touched && CancerCareNorthwestForms1.controls['cmd_344'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_344'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_344'].touched && CancerCareNorthwestForms1.controls['cmd_344'].invalid) || (CancerCareNorthwestForms1.controls['cmd_344'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>C.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>AFFIRMATION OF ABILITIES</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Do you presently use any drugs illegally? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_345"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_345'].touched && CancerCareNorthwestForms1.controls['cmd_345'].invalid) || (CancerCareNorthwestForms1.controls['cmd_345'].invalid && isSubmit)"
                                    id="cmd_345" name="cmd_345" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_345'].touched && CancerCareNorthwestForms1.controls['cmd_345'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_345'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_345"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_345'].touched && CancerCareNorthwestForms1.controls['cmd_345'].invalid) || (CancerCareNorthwestForms1.controls['cmd_345'].invalid && isSubmit)"
                            id="cmd_345" name="cmd_345" value="0">
            
                                    <label>No</label>
                        
                                    <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms1.controls['cmd_345'].touched && CancerCareNorthwestForms1.controls['cmd_345'].invalid">
                        
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_345'].errors?.required">
                        
                                            required
                        
                                        </small>
                        
                                    </div>
                        
                                    <span
                                        *ngIf="(CancerCareNorthwestForms1.controls['cmd_345'].touched && CancerCareNorthwestForms1.controls['cmd_345'].invalid) || (CancerCareNorthwestForms1.controls['cmd_345'].invalid && isSubmit)">
                        
                                        <i class="fas fa-times ms-2 text-danger"></i>
                        
                                    </span>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Do you have any physical, mental health, or substance use condition that currently impairs, or could
                                impair, your ability to practice your profession in a competent, ethical, and professional manner?<b> If
                                the answer to this question is yes, please complete Section 23 below.</b> </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_346"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_346'].touched && CancerCareNorthwestForms1.controls['cmd_346'].invalid) || (CancerCareNorthwestForms1.controls['cmd_346'].invalid && isSubmit)"
                                    id="cmd_346" name="cmd_346" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_346'].touched && CancerCareNorthwestForms1.controls['cmd_346'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_346'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_346"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_346'].touched && CancerCareNorthwestForms1.controls['cmd_346'].invalid) || (CancerCareNorthwestForms1.controls['cmd_346'].invalid && isSubmit)"
                                    id="cmd_346" name="cmd_346" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_346'].touched && CancerCareNorthwestForms1.controls['cmd_346'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_346'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_346'].touched && CancerCareNorthwestForms1.controls['cmd_346'].invalid) || (CancerCareNorthwestForms1.controls['cmd_346'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                    
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are you unable to perform any of the services/clinical privileges required by the applicable
                                participating practitioner agreement/hospital agreement, with or without reasonable accommodation,
                                according to accepted standards of professional performance?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_347"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_347'].touched && CancerCareNorthwestForms1.controls['cmd_347'].invalid) || (CancerCareNorthwestForms1.controls['cmd_347'].invalid && isSubmit)"
                            id="cmd_347" name="cmd_347" value="1">
                            
                                        <label>Yes&nbsp;&nbsp;</label>
                            
                                        <div class="formLabel col-md-11"
                                            *ngIf="CancerCareNorthwestForms1.controls['cmd_347'].touched && CancerCareNorthwestForms1.controls['cmd_347'].invalid">
                            
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_347'].errors?.required">
                            
                                                required
                            
                                            </small>
                            
                                        </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_347"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_347'].touched && CancerCareNorthwestForms1.controls['cmd_347'].invalid) || (CancerCareNorthwestForms1.controls['cmd_347'].invalid && isSubmit)"
                                    id="cmd_347" name="cmd_347" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_347'].touched && CancerCareNorthwestForms1.controls['cmd_347'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_347'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_347'].touched && CancerCareNorthwestForms1.controls['cmd_347'].invalid) || (CancerCareNorthwestForms1.controls['cmd_347'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                
                
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>D.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>LITIGATION AND MALPRACTICE COVERAGE HISTORY (If you answer "Yes" to any of the questions in this
                                section,</b> <label class="formLabel">
                                    please document in Section 22. PROFESSIONAL LIABILITY ACTION DETAIL of this application.)</label></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have allegations or claims of professional negligence been made against you at any time, whether or
                                not you were individually named in the claim or lawsuit? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_348"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_348'].touched && CancerCareNorthwestForms1.controls['cmd_348'].invalid) || (CancerCareNorthwestForms1.controls['cmd_348'].invalid && isSubmit)"
                                    id="cmd_348" name="cmd_348" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_348'].touched && CancerCareNorthwestForms1.controls['cmd_348'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_348'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_348"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_348'].touched && CancerCareNorthwestForms1.controls['cmd_348'].invalid) || (CancerCareNorthwestForms1.controls['cmd_348'].invalid && isSubmit)"
                                    id="cmd_348" name="cmd_348" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_348'].touched && CancerCareNorthwestForms1.controls['cmd_348'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_348'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_348'].touched && CancerCareNorthwestForms1.controls['cmd_348'].invalid) || (CancerCareNorthwestForms1.controls['cmd_348'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you or your insurance carrier(s) ever paid any money on your behalf to settle/resolve a
                                professional malpractice claim (not necessarily a lawsuit) and/or to satisfy a judgement (courtordered damage award) in a professional lawsuit? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_349"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_349'].touched && CancerCareNorthwestForms1.controls['cmd_349'].invalid) || (CancerCareNorthwestForms1.controls['cmd_349'].invalid && isSubmit)"
                                    id="cmd_349" name="cmd_349" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_349'].touched && CancerCareNorthwestForms1.controls['cmd_349'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_349'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_349"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_349'].touched && CancerCareNorthwestForms1.controls['cmd_349'].invalid) || (CancerCareNorthwestForms1.controls['cmd_349'].invalid && isSubmit)"
                                    id="cmd_349" name="cmd_349" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_349'].touched && CancerCareNorthwestForms1.controls['cmd_349'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_349'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_349'].touched && CancerCareNorthwestForms1.controls['cmd_349'].invalid) || (CancerCareNorthwestForms1.controls['cmd_349'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are there any such claims being asserted against you now?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_350"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_350'].touched && CancerCareNorthwestForms1.controls['cmd_350'].invalid) || (CancerCareNorthwestForms1.controls['cmd_350'].invalid && isSubmit)"
                                    id="cmd_350" name="cmd_350" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_350'].touched && CancerCareNorthwestForms1.controls['cmd_350'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_350'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_350"
                            [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_350'].touched && CancerCareNorthwestForms1.controls['cmd_350'].invalid) || (CancerCareNorthwestForms1.controls['cmd_350'].invalid && isSubmit)"
                            id="cmd_350" name="cmd_350" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_350'].touched && CancerCareNorthwestForms1.controls['cmd_350'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_350'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_350'].touched && CancerCareNorthwestForms1.controls['cmd_350'].invalid) || (CancerCareNorthwestForms1.controls['cmd_350'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>4.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been denied professional liability coverage or has your coverage ever been
                                terminated, not renewed, restricted, or modified (e.g., reduced limits, restricted coverage,
                                surcharged)?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_351"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_351'].touched && CancerCareNorthwestForms1.controls['cmd_351'].invalid) || (CancerCareNorthwestForms1.controls['cmd_351'].invalid && isSubmit)"
                                    id="cmd_351" name="cmd_351" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_351'].touched && CancerCareNorthwestForms1.controls['cmd_351'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_351'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_351"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_351'].touched && CancerCareNorthwestForms1.controls['cmd_351'].invalid) || (CancerCareNorthwestForms1.controls['cmd_351'].invalid && isSubmit)"
                                    id="cmd_351" name="cmd_351" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_351'].touched && CancerCareNorthwestForms1.controls['cmd_351'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_351'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_351'].touched && CancerCareNorthwestForms1.controls['cmd_351'].invalid) || (CancerCareNorthwestForms1.controls['cmd_351'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>5.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are any of the privileges that you are requesting <u>not</u> covered by your current malpractice coverage? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_352"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_352'].touched && CancerCareNorthwestForms1.controls['cmd_352'].invalid) || (CancerCareNorthwestForms1.controls['cmd_352'].invalid && isSubmit)"
                                    id="cmd_352" name="cmd_352" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_352'].touched && CancerCareNorthwestForms1.controls['cmd_352'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_352'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_352"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_352'].touched && CancerCareNorthwestForms1.controls['cmd_352'].invalid) || (CancerCareNorthwestForms1.controls['cmd_352'].invalid && isSubmit)"
                                    id="cmd_352" name="cmd_352" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms1.controls['cmd_352'].touched && CancerCareNorthwestForms1.controls['cmd_352'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.controls['cmd_352'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_352'].touched && CancerCareNorthwestForms1.controls['cmd_352'].invalid) || (CancerCareNorthwestForms1.controls['cmd_352'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-12">
                            <label class="formLabel">I warrant that all the statements made on this form and on any attached information sheets are complete, accurate, and current. I
                                understand that any material misstatements in, or omissions from, this statement constitute cause for denial of membership or cause for
                                summary dismissal from the entity to which this statement has been submitted.</label>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Applicant's Signature: </label>
                        </div> 
                        <div class="col-md-12">
                            <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <input type="hidden" name="cmd_0019" id="cmd_0019" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_0019'].touched && CancerCareNorthwestForms1.controls['cmd_0019'].invalid) || (CancerCareNorthwestForms1.controls['cmd_0019'].invalid && isSubmit)" formControlName="cmd_0019" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_0019'].touched && CancerCareNorthwestForms1.controls['cmd_0019'].invalid) || (CancerCareNorthwestForms1.controls['cmd_0019'].invalid && isSubmit)">
                                        Signature is required 
                                </div>
                        </div> 
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Date </label>
                        </div> 
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Date" id="cmd_0020" (blur)="clearInputIfInvalidMandi('cmd_0020')"  class="form-control" formControlName="cmd_0020"
                                [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_0020'].touched && CancerCareNorthwestForms1.controls['cmd_0020'].invalid ) || (CancerCareNorthwestForms1.controls['cmd_0020'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>    
                        <div class="text-danger"
                            *ngIf="(CancerCareNorthwestForms1.controls['cmd_0020'].invalid&&CancerCareNorthwestForms1.controls['cmd_0020'].touched) && (CancerCareNorthwestForms1.controls['cmd_0020'].value==''|| CancerCareNorthwestForms1.controls['cmd_0020'].value==null) ">
                            <small>
                                Date is required and cannot be empty
                            </small>
                        
                        </div>
                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0020').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY) </small>
                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0020').errors?.invalidMonth">
                            Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0020').errors?.invalidDateyear">
                            year is out of range(1900-2099) </small>
                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0020').errors?.invalidDateRange">
                            date is out of range
                        </small>
                       
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Type or Print name here </label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" placeholder="Typed or printed name" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_33553'].touched &&
                                        
                                        CancerCareNorthwestForms1.controls['cmd_33553'].invalid) ||
                                        
                                        (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                            formControlName="cmd_33553" class="form-control">
                    
                                    
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms1.controls['cmd_33553'].touched && CancerCareNorthwestForms1.controls['cmd_33553'].invalid) || (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)">
                    
                                        Name is required and cannot be empty
                    
                                    </div>
                            </div> 
                        </div>    
                    </td>

                </tr>
            </table><br> 
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>22. PROFESSIONAL LIABILITY ACTION DETAIL – CONFIDENTIAL </h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_1')" formControlName="cmd_NA_1"
                            id="cmd_NA_1">
                    </th>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Practitioner Name:(print or type) </label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder=" PROVIDER PRINTED NAME AS ENTERED ABOVE" id="cmd_356"
                                        formControlName="cmd_356" class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_356'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['cmd_356'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                            </div>
                        </div>        
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Please list any past or current professional liability claim(s) or lawsuit(s), in which allegations of professional
                                    negligence were made against you, whether or not you were individually named in the claim or lawsuit. <u>Please do
                                    not include patient names or other HIPAA protected PHI.</u> Photocopy this page as needed and submit a separate
                                    page for EACH claim/event. A legible signed practitioner narrative that addresses all of the following details is an
                                    acceptable alternative. </label>
                            </div> 
                            
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Date and clinical details of the incident, with preceding events:</label>
                            </div> 
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="formLabel">Date: </label>
                                </div> 
                                <div class="col-md-6">
                                    <div class="input-group">
                                            <input type="text" (blur)="clearInputIfInvalid('1208','cmd_NA_1')" (blur)="handleNAchange('cmd_NA_1')" placeholder=" Date of occurance"
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh97="ngbDatepicker"
                                            formControlName="1208" id="1208"
                                            [class.is-invalid]="CancerCareNorthwestForms1.controls['1208'].invalid">
                            
                                        <div class="input-group-append">
                            
                                            <button class="btn btn-outline-secondary calendar" (click)="idh97.toggle()" type="button">
                            
                                                <i class="fas fa-calendar-alt"></i>
                            
                                            </button>
                            
                                        </div>
                                      
                                        </div>
                                        <div class="text-danger"
                                        *ngIf="CancerCareNorthwestForms1.controls['1208'].invalid && (CancerCareNorthwestForms1.controls['1208'].value==''|| CancerCareNorthwestForms1.controls['1208'].value==null) ">
                                        <small>
                                          required
                                        </small>
                                      
                                      </div>
                                      <div *ngIf="CancerCareNorthwestForms1.get('1208').invalid">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1208').errors.invalidDateFormat">
                                          Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1208').errors.invalidMonth">
                                          Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1208').errors.invalidDateyear">
                                          year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1208').errors.invalidDateRange">
                                          date is out of range
                                        </small>
                                      </div>
                                    </div>
                                        
                                
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="formLabel">Details: </label>
                                </div> 
                                <div class="col-md-6">
                                    <textarea id="1217" name="idharea" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1217"
                                    cols="70"
                                    [class.is-invalid]="CancerCareNorthwestForms1.controls['1217'].invalid"></textarea>
                    
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1217'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                    
                            </div>
                            </div>   
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Your role and specific responsibility in the incident:</label>
                            </div> 
                            <div class="col-md-12">
                                <textarea id="1218" name="idharea" [class.is-invalid]="CancerCareNorthwestForms1.controls['1218'].invalid" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1218"
                                    cols="70"
                                    ></textarea>
                    
                    
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1218'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Subsequent events, including patient’s clinical outcome:</label>
                            </div> 
                            <div class="col-md-12">
                                <textarea id="1214" name="idharea" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1214"
                                    cols="70"
                                    [class.is-invalid]="CancerCareNorthwestForms1.controls['1214'].invalid"></textarea>
                    
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1214'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                    </td>
                </tr>     
                
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel">Date suit or claim was filed:</label>
                            </div> 
                            <div class="col-md-6">
                                <div class="input-group">
                                
                                    <input type="text" (blur)="clearInputIfInvalid('1209','cmd_NA_1')" (blur)="handleNAchange('cmd_NA_1')" placeholder="Date of Lawsuit" id="1209"
                                        formControlName="1209" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh98="ngbDatepicker"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1209'].invalid">
                    
                                    <div class="input-group-append">
                    
                                        <button class="btn btn-outline-secondary calendar" (click)="idh98.toggle()" type="button">
                    
                                            <i class="fas fa-calendar-alt"></i>
                    
                                        </button>
                    
                                    </div>
                                    </div>

                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms1.controls['1209'].invalid && (CancerCareNorthwestForms1.controls['1209'].value==''|| CancerCareNorthwestForms1.controls['1209'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms1.get('1209').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1209').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1209').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1209').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1209').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                                    
                            </div>
                        </div>    
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Name and Address of Insurance Carrier that handled the claim:</label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')"
                                        placeholder="Insurance Carrier at Time of Occurrence" id="1210" formControlName="1210"
                                        class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1210'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['1210'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier Address 1"
                                        id="2012" formControlName="2012" class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['2012'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['2012'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                            </div>
                            <div class="col-md-2">
                                <input type="text"  placeholder="Insurance Carrier Address 2"
                                        id="1844" formControlName="1844" class="form-control"
                                        >                                               
                            </div>
                            <div class="col-md-3">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier City" id="1845"
                                formControlName="1845" class="form-control"
                                [class.is-invalid]="CancerCareNorthwestForms1.controls['1845'].invalid">
            
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms1.controls['1845'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                            </div>
                            <div class="col-md-2">
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_1')"
                                [class.is-invalid]="CancerCareNorthwestForms1.controls['1847'].invalid"bindLabel="name" placeholder="Insurance Carrier State" bindValue="id" formControlName="1847">
                    
                                    </ng-select>
                    
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms1.controls['1847'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier Zip" id="1848"
                                        formControlName="1848" class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1848'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['1848'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Your status in the legal action (primary defendant, co-defendant, other):</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Other Defendants Involved " id="1213"
                                        formControlName="1213" class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1213'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['1213'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Current status of suit or other action:</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder=" Claim Status" id="1858"
                                        formControlName="1858" class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1858'].invalid">
                    
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms1.controls['1858'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                        </div>    
                    </td>
                </tr> 
                <tr> 
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Date of settlement, judgment, or dismissal:</label>
                            </div> 
                            <div class="col-md-12">
                                <div class="input-group">
                                
                                    <input type="text" (blur)="clearInputIfInvalid('1226','cmd_NA_1')" (blur)="handleNAchange('cmd_NA_1')" placeholder="Date of Lawsuit" id="1226"
                                        formControlName="1226" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh981234="ngbDatepicker"
                                        [class.is-invalid]="CancerCareNorthwestForms1.controls['1226'].invalid">
                    
                                    <div class="input-group-append">
                    
                                        <button class="btn btn-outline-secondary calendar" (click)="idh981234.toggle()" type="button">
                    
                                            <i class="fas fa-calendar-alt"></i>
                    
                                        </button>
                    
                                    </div>
                                    </div>

                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms1.controls['1226'].invalid && (CancerCareNorthwestForms1.controls['1226'].value==''|| CancerCareNorthwestForms1.controls['1226'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms1.get('1226').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1226').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1226').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1226').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('1226').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                                     
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">If case was settled out-of-court, or with a judgment, settlement amount attributed to you? $</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Providers Settlement Portion" id="1228"
                                                formControlName="1228" class="form-control"
                                                [class.is-invalid]="CancerCareNorthwestForms1.controls['1228'].invalid">
                                                <div class="mandatory"
                                                *ngIf="CancerCareNorthwestForms1.controls['1845'].invalid">
                                                <small >
                                                required
                                                </small>
                                            </div>
                            
                            </div>
                        </div>    
                    </td>
                </tr>  
            </table>    
            <br>
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading">
                                <h6>23. Physician/Practitioner Health Program Disclosure</h6></label>
                                <label class="formLabel">Please complete below details if you answered yes to Question C.2 above</label>    
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_015')" formControlName="cmd_NA_015"
                            id="cmd_NA_015">
                    </th>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-6">
                            <label class="formLabel">Name of Monitoring Program:</label> 
                        </div>
                        <div class="col-md-6">
                            <input type="text" (blur)="handleNAchange('cmd_NA_015')"  id="cmd_503" formControlName="cmd_503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_503'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms1.controls['cmd_503'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-6">
                            <label class="formLabel">Address of Monitoring Program</label> 
                        </div>
                        <div class="col-md-6">
                            <input type="text" (blur)="handleNAchange('cmd_NA_015')"  id="cmd_0503" formControlName="cmd_0503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_0503'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms1.controls['cmd_0503'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Point of Contact Name:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_015')"  id="cmd_00503" formControlName="cmd_00503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_00503'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms1.controls['cmd_00503'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_015')"  id="cmd_000503" formControlName="cmd_000503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_000503'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms1.controls['cmd_000503'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Verification E-mail Address:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_015')"  id="cmd_0000503" formControlName="cmd_0000503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms1.controls['cmd_0000503'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms1.controls['cmd_0000503'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                </tr>
            </table>   <br> 
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>24. ATTESTATION</h6></label>
                    </th>

                </tr>
                <tr>
                    <td colspan="12" >
                        
                            <label class="formLabel"   >I certify the information in this entire application is complete, accurate, and current. I acknowledge that any misstatements in
                                or omissions from this application constitute cause for denial of membership or cause for summary dismissal from the entity to
                                which this statement has been made. A copy, or electronic PDF with signature authentication, of this application has the same
                                force and effect as the original. I have reviewed this information as of the most recent date listed below. </label>
                    </td>

                </tr>
            </table>   <br>    
            <table class="center" style="margin-left: auto; 
            margin-right: auto;">

                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-5">   
                                <label class="formLabel">
                                    Print Name
                                    Here:</label>
                            </div>
                            <div class="col-md-6">   
                                <label class="formLabel">
                                    <input type="text" placeholder="Typed or printed name" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_33553'].touched &&
                                            
                                    CancerCareNorthwestForms1.controls['cmd_33553'].invalid) ||
                                    
                                    (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                        formControlName="cmd_33553" class="form-control">
                
                                
                
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_33553'].touched && CancerCareNorthwestForms1.controls['cmd_33553'].invalid) || (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)">
                
                                    Name is required and cannot be empty
                
                                </div></label>
                            </div>
                        </div>            
                    </td>

                </tr>
                <tr>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Signature: </label>
                            </div> 
                            <div class="col-md-9">
                                <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                (click)="esignOpenTwo()" />
                                    <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                                (click)="esignOpenTwo()" />
                                    <input type="hidden" name="cmd_00019" id="cmd_00019" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_00019'].touched && CancerCareNorthwestForms1.controls['cmd_00019'].invalid) || (CancerCareNorthwestForms1.controls['cmd_00019'].invalid && isSubmit)" formControlName="cmd_00019" value="{{ finalImgPath }}" />
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms1.controls['cmd_00019'].touched && CancerCareNorthwestForms1.controls['cmd_00019'].invalid) || (CancerCareNorthwestForms1.controls['cmd_00019'].invalid && isSubmit)">
                                            Signature is required 
                                    </div>
                            </div> 
                        </div>    
                    </td>
                </tr>
                <tr>    
                    <td colspan="6">
                        <div class="row">
                            <div class="col-md-2">
                                <label class="formLabel">Date: </label>
                            </div> 
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Date" id="cmd_020" (blur)="clearInputIfInvalidMandi('cmd_020')"  class="form-control" formControlName="cmd_020"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_020'].touched && CancerCareNorthwestForms1.controls['cmd_020'].invalid ) || (CancerCareNorthwestForms1.controls['cmd_020'].invalid && isSubmit)" ngbDatepicker #d020="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d020.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>    
                                <div class="text-danger"
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_020'].invalid&&CancerCareNorthwestForms1.controls['cmd_020'].touched) && (CancerCareNorthwestForms1.controls['cmd_020'].value==''|| CancerCareNorthwestForms1.controls['cmd_020'].value==null) ">
                                    <small>
                                        Date is required and cannot be empty
                                    </small>
                                
                                </div>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_020').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_020').errors?.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_020').errors?.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_020').errors?.invalidDateRange">
                                    date is out of range
                                </small>
                        
                               
                            </div> 
                        </div>    
                    </td>
                </tr><br>
                <tr style="outline: 2px solid black">
                    <td colspan="12" style="padding-left:110px;">
                        <b>Review dates and initials: </b>
                    </td>
                </tr><br>
                <tr>
                    <td colspan="12">
                        <textarea id="cmd_0354"  row="160"  formControlName ="cmd_0354" cols="50"></textarea>
                    </td>
                </tr>
            </table> 
            <table border="1" width="100%">
                <tr>
                    <td colspan="12">
                        <div class="row">
                            
                                <div class="col-md-3">
                                    <label class="formLabel">Healthcare Organization: </label>
                                </div> 
                                <div class="col-md-9">
                                    <input type="text" placeholder="Enter Organization" id="cmd_0355" formControlName ="cmd_0355"  class="form-control">
                                </div> 
                            
                        </div>
                    </td>
                </tr>
                <tr>    
                    <td colspan="12">
                        <div class="row">
                           
                                <div class="col-md-3">
                                    <label class="formLabel">And/or Designated Agent: </label>
                                </div> 
                                <div class="col-md-9">
                                    <input type="text" placeholder="Enter Designated Agent" id="cmd_0356" formControlName ="cmd_0356"  class="form-control">
                                </div> 
                            
                        </div>
                    </td>
                </tr>
            </table>
            <table >   
                <tr>
                    <td>
                        <div class="text-center">
                            <h6>WASHINGTON PRACTITIONER APPLICATION AUTHORIZATION AND RELEASE OF INFORMATION FORM </h6>
                            <i style="font-size:17px;">Modified Releases Will Not Be Accepted</i>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="col-md-12">
                            <label class="formLabel">By submitting this authorization and release of information form in conjunction with the Washington Practitioner Application (WPA)
                                and/or the Washington Practitioner Attestation or Credentials Update (CU) form, I understand and agree as follows:</label>
                        </div>  
                    </td>
                </tr>
                <tr>
                    
                    <td>
                        <ol >
                            <li>
                                 I understand and acknowledge that, as an applicant for medical staff membership and/or participating status with the Healthcare
                                    Organization(s)* indicated on the WPA for initial credentialing or recredentialing, I have the burden of producing adequate information
                                    for proper evaluation of my competence, character, ethics, mental and physical health status, and or other qualifications in
                                    a timely manner. I understand that the application will not be processed until the application is deemed complete by the healthcare
                                    organization
                               
                            </li>
                            <li>    
                        
                                I further understand and acknowledge that the Healthcare Organization(s) or designated agent will investigate the information in
                                    this application. By submitting this application, I agree to such investigation and to information exchange activities of the Healthcare
                                    Organization(s) as part of the verification and credentialing process.
                               
                            </li>   
                            <li>   
                                I authorize all individuals, institutions and entities or organizations with which I am currently or have been associated and all
                                professional liability insurers with which I have had or currently have professional liability insurance, who may have information
                                bearing on my professional qualifications, ethical standing, competence, and mental and physical health status to release the
                                aforementioned information to the designated Healthcare Organization(s), their staffs and agents.
                            </li>
                            <li>  
                                I consent to the inspection of records and documents that may be material to an evaluation of qualifications and my ability to carry
                                out the clinical privileges or provide services I request. I authorize each and every individual and organization in custody of such
                                records and documents to permit such inspection and copying. I am willing to make myself available for interviews if required or
                                requested
                            </li>   
                            <li> 
                                I release from any liability, to the fullest extent permitted by law, all persons for their acts performed in a reasonable manner in
                                conjunction with providing information, investigating and evaluating my application and qualifications, and I waive all legal claims
                                against any representative of the Healthcare Organization(s) or their respective agent(s) who act in good faith and without malice in
                                connection with the investigation of this application.
                            </li>
                            <li>
                                I acknowledge that I have been informed of, and hereby agree to abide by, the bylaws, rules, regulations, contractual agreements,
                                and policies of the Healthcare Organization.
                            </li>
                            <li>    
                                I acknowledge that I am responsible for notifying the Healthcare Organization of any changes/challenges to licensure, DEA,
                                malpractice claims, criminal convictions, hospital privileges or other disciplinary actions.
                            </li>
                            <li>    
                                I attest to the accuracy, currency and completeness of the information provided. I understand and agree that any misstatements in
                                or omissions from the CU, WPA, Washington Practitioner Attestation and attachments hereto may constitute cause for denial of the
                                application or summary dismissal or termination of membership/clinical privileges/participation agreement.
                            </li>
                            <li>    
                                I agree to exhaust all available procedures and remedies as outlined in the bylaws, rules, regulations, and policies, and/or contractual
                                agreements of the Healthcare Organization(s) where I have membership and/or clinical privileges/participation status before initiating
                                judicial action.
                            </li>  
                            <li>  
                                 I understand that completion and submission of the Authorization and Release does not automatically grant me membership or
                                clinical privileges/participating status with the Healthcare Organization(s)* indicated on the WPA/CU or Attestation.
                            </li>    
                            <li>   
                                I hereby further authorize and consent to the release of information and/or reporting by the Healthcare Organization(s) to medical
                                associations, licensing boards, the National Practitioner Data Bank, the Healthcare Integrity and Protection Data Bank, and other
                                similar organizations regarding any pertinent information which the Healthcare Organization(s) may have concerning me as long as
                                such release of information and/or reporting is done in good faith and without malice, and I hereby release from liability Healthcare
                                Organization(s) and its staff and representatives for so doing. 
                            </li>    
                            <li>
                                I further acknowledge that I have read and understand the foregoing Authorization and Release. A photocopy or electronic PDF with
                                signature authentication of this Authorization and Release shall be as effective as the original and authorization constitutes my written
                                authorization and request to communicate any relevant information and to release any and all supportive documentation regarding
                                this application/attestation.
                            </li>    
                        </ol>
                    </td>
                </tr>   
            </table>      
            <table class="center" style="margin-left: auto; 
            margin-right: auto;">

                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-5">   
                                <label class="formLabel">
                                    Print Name
                                    Here:</label>
                            </div>
                            <div class="col-md-6">   
                                <label class="formLabel">
                                    <input type="text" placeholder="Typed or printed name" style="width:200px" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_33553'].touched &&
                                            
                                    CancerCareNorthwestForms1.controls['cmd_33553'].invalid) ||
                                    
                                    (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                        formControlName="cmd_33553" class="form-control">
                
                                
                
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_33553'].touched && CancerCareNorthwestForms1.controls['cmd_33553'].invalid) || (CancerCareNorthwestForms1.controls['cmd_33553'].invalid && isSubmit)">
                
                                    Name is required and cannot be empty
                
                                </div></label>
                            </div>
                        </div>            
                    </td>

                </tr>
                <tr>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Signature: </label>
                            </div> 
                            <div class="col-md-9">
                                <img *ngIf="!showThree" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                (click)="esignOpenThree()" />
                                    <img *ngIf="showThree" class="img eSignImageShow" [src]="esignThree" alt="Credential my Doc"
                                (click)="esignOpenThree()" />
                                    <input type="hidden" name="cmd_000190" id="cmd_000190" [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_000190'].touched && CancerCareNorthwestForms1.controls['cmd_000190'].invalid) || (CancerCareNorthwestForms1.controls['cmd_000190'].invalid && isSubmit)" formControlName="cmd_000190" value="{{ finalImgPath }}" />
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms1.controls['cmd_000190'].touched && CancerCareNorthwestForms1.controls['cmd_000190'].invalid) || (CancerCareNorthwestForms1.controls['cmd_000190'].invalid && isSubmit)">
                                            Signature is required 
                                    </div>
                            </div> 
                        </div>    
                    </td>
                </tr>
                <tr>    
                    <td colspan="6">
                        <div class="row">
                            <div class="col-md-2">
                                <label class="formLabel">Date: </label>
                            </div> 
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Date" id="cmd_0200" (blur)="clearInputIfInvalidMandi('cmd_0200')" class="form-control" formControlName="cmd_0200"
                                    [class.is-invalid]="(CancerCareNorthwestForms1.controls['cmd_0200'].touched && CancerCareNorthwestForms1.controls['cmd_0200'].invalid ) || (CancerCareNorthwestForms1.controls['cmd_0200'].invalid && isSubmit)" ngbDatepicker #d0200="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d0200.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>   
                                <div class="text-danger"
                                    *ngIf="(CancerCareNorthwestForms1.controls['cmd_0200'].invalid&&CancerCareNorthwestForms1.controls['cmd_0200'].touched) && (CancerCareNorthwestForms1.controls['cmd_0200'].value==''|| CancerCareNorthwestForms1.controls['cmd_0200'].value==null) ">
                                    <small>
                                        Date is required and cannot be empty
                                    </small>
                                
                                </div>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0200').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0200').errors?.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0200').errors?.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms1.get('cmd_0200').errors?.invalidDateRange">
                                    date is out of range
                                </small>
                        
                                
                            </div> 
                        </div>    
                    </td>
                </tr>
               
            </table>
            <table>
                <tr>
                    <td>
                        <b><i>*Healthcare Organization (e.g. hospital, medical staff, medical group, independent practice association, professional review organization health
                            plan, health maintenance organization, preferred provider organization, physician hospital organization, medical society, credentials
                            verification organization, professional association, medical school faculty position or other health delivery entity or system).</i></b>
                    </td>
                </tr>
            </table>            
        
        
        
            
            
        
    </form>


    
</div>    

    