import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbDropdownModule, NgbAccordionModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../../shared/DateFormatter/customDate';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../../shared/services/notifications/toaster-noti.service';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { environment } from 'apps/cmd-frontend/src/environments/environment'
import { Error_Message } from '../../../shared/services/common/common.service';
import {MedicalStaffService} from "./../../services/medical-staff/medical-staff.service"
import { MedicalStafModelComponent } from './medical-staf-model/medical-staf-model.component';
import { MaskInputDirective } from '../../SharedComponents/MaskInputDirective.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'medical-staff-verification1',
    templateUrl: './medical-staf-verification.component.html',
    styleUrls: ['./medical-staf-verification.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [NgbDropdownModule, NgbAccordionModule, FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule, MaskInputDirective]
})
export class MedicalStafVerificationComponent implements OnInit {
  public userId: any;
  public name:string;
  public customerId: any;
  public showProviderForm:boolean = true;
  public active = "1";
  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  public facilityDropdown: Array<object> = [];
  public modalResponse: object = {
    type: "close"
  };
  public searchUserForm: UntypedFormGroup;
  public verifyUserForm: UntypedFormGroup;
  public searchData : any;



  constructor( private fb:UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private medicalStaffService1: MedicalStaffService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private storageService: StorageService






  ) {
  }

  

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  ngOnInit(): void {
    this.userId = 53;
    this.customerId = 53;
    this.loadDropdownapi();
    this.buildForm();
  }


    buildForm() {
      this.searchUserForm = this.fb.group({
        firstInitial: ['',Validators.required],
        lastname:['',Validators.required],
        facility:['',Validators.required],
        phoneNo:['',[ Validators.required,
          Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}'),
          Validators.minLength(12), Validators.maxLength(12)]]

      }),
      this.verifyUserForm = this.fb.group({
        pfirstInitial: ['',Validators.required],
        plastname:['',Validators.required],
        pfacility:[null,Validators.required],
        dob:[null],
        ssn:[''],

        //phoneNo:['',[Validators.required,Validators.pattern("^\d[0-9]{10}$")]]

      })


      }

  loadDropdownapi(){
    let params = {
      //  customerId: this.customerId,
      //  userId: this.userId
     }
    this.spinner.show()
    this.medicalStaffService1.getFacilityDropdown1(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.facilityDropdown = data.status;
        }
        else {
          this.notiService.showError(data['error'], '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error);
        this.spinner.hide()
      }
    )


  }

  openModal() {
    //// console.log(this.verifyUserForm.value.ssn)
    if(this.verifyUserForm.value.ssn != "" || this.verifyUserForm.value.dob !=null){
      let params = {
        customerId: this.verifyUserForm.value.pfacility,
        userId: this.verifyUserForm.value.pfacility,
        providerFirstName:this.verifyUserForm.value.pfirstInitial,
        providerLastName:this.verifyUserForm.value.plastname,
        providerFacility:this.verifyUserForm.value.pfacility.toString(),
        providerSsn:this.verifyUserForm.value.ssn,
        providerDOb:this.verifyUserForm.value.dob,
        firstName:this.searchUserForm.value.firstInitial,
        lastName:this.searchUserForm.value.lastname,
        facility:this.searchUserForm.value.facility,
        phone:this.searchUserForm.value.phoneNo
      }
      this.spinner.show()
      this.medicalStaffService1.searchProvider(params).subscribe(
        data => {
          // console.log(data);
          if (data['success']) {
            this.searchData = data.status;
            this.modalOption.size = 'lg'
            const modalRef = this.modalService.open(MedicalStafModelComponent, this.modalOption);
            modalRef.componentInstance.searchData = this.searchData[0];
            modalRef.componentInstance.firstName=this.searchUserForm.value.firstInitial;
            modalRef.componentInstance.lastName=this.searchUserForm.value.lastname;
            modalRef.componentInstance.phone=this.searchUserForm.value.phoneNo;
            modalRef.componentInstance.downlaodPdf.subscribe(
              element => {
                this.downloadPDF();
              }
            )

          }
          else {
            this.notiService.showError(data['error'], '', 3000)
          }

          this.spinner.hide();
        },
        error => {
          // console.log(error);
          this.spinner.hide()
        }
      )
    }
    else{
      this.notiService.showError("Please enter SSN or DOB data", '', 3000)
    }
}

submitSearcherData(){
console.log(this.searchUserForm.valid)
  this.showProviderForm = false;
  this.active = "2";
  this.name = this.searchUserForm.controls['firstInitial'].value + ' '+this.searchUserForm.controls['lastname'].value
  // console.log(this.name);

}

downloadPDF() {
  let params = {
    customerId: this.verifyUserForm.value.pfacility,
    userId: this.verifyUserForm.value.pfacility,
    providerFirstName:this.verifyUserForm.value.pfirstInitial,
    providerLastName:this.verifyUserForm.value.plastname,
    providerFacility:this.verifyUserForm.value.pfacility.toString(),
    providerSsn:this.verifyUserForm.value.ssn,
    providerDOb:this.verifyUserForm.value.dob,
    firstName:this.searchUserForm.value.firstInitial,
    lastName:this.searchUserForm.value.lastname,
    facility:this.searchUserForm.value.facility,
    phone:this.searchUserForm.value.phoneNo
}
  this.spinner.show()
  this.medicalStaffService1.DownloadData(params).subscribe(
    data => {
      if (data['success']) {
        this.spinner.hide()
        let fileURL = `${environment.apiDownloadUrl}${data['status']}`
        var _window = window.open(fileURL, '_blank');
        let fileName = data['status'].split('/')[1];
        _window.document.close();
        _window.document.execCommand('Save', true, fileName || fileURL)
      }
      else {
        this.notiService.showError(data['error'], '', 3000)
      }
      this.spinner.hide()
    },
    error => {
      // console.log(error);
      this.notiService.showError(Error_Message, '', 3000)
      this.spinner.hide()
    }
  )
}



handleNumber(event,name) {
  if (event.code == 'Delete' || event.code == 'Backspace') {
  
  }
  else {
  let z = event.target.value;
  z = z.replace(/[^\d-]/g, '');
  if (z.length == 3) {
  z = z + '-'
  }
  else if (z.length == 7) {
  z = z + '-'
  }
  this.searchUserForm.controls[name].setValue(z)
  }
  }

}
