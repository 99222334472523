<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
<h4 class="modal-title">Change Password</h4>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">

    <div>
         <form
              [formGroup]="changePasswordForm"
              (ngSubmit)="onChangePassword()"
            >
              <div class="mb-3">
                <input
                  type="password"
                  formControlName="currentpassword"
                  class="form-control"
                  placeholder="Current Password"
autocomplete="off"
                  [ngClass]="{ 'is-invalid': submitted && f.currentpassword.errors }"
                />
                <div
                  *ngIf="submitted && f.currentpassword.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.currentpassword.errors.required">Current Password is required</div>

                </div>
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  formControlName="credential"
                  class="form-control"
                  autocomplete="off"
                  [ngClass]="{ 'is-invalid': submitted && f.credential.errors }"
                />
                <div
                  *ngIf="submitted && f.credential.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.credential.errors.required">
                    New Password is required
                  </div>
                  
                </div>
                <small class="text-danger" *ngIf="changePasswordForm.controls['credential'].hasError('minlength') && changePasswordForm.controls['credential'].touched">
                  Password should be atleast 10 characters. </small>
                <small class="text-danger" *ngIf="changePasswordForm.controls['credential'].hasError('pattern') && !changePasswordForm.controls['credential'].hasError('minlength')">
                Password must contain a least one symbol,upper and lower case letters,
                and numeric value. </small>
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  formControlName="confirmPassword"
                  class="form-control"
                  autocomplete="off"
                  [ngClass]="{
                    'is-invalid': submitted && f.confirmPassword.errors
                  }"
                />
                <div
                  *ngIf="submitted && f.confirmPassword.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.confirmPassword.errors.required">
                    Confirm Password is required
                  </div>
                  <div *ngIf="f.confirmPassword.errors.passwordMismatch">
                    Password and Confirm Password must be match.
                  </div>
                </div>
              </div>
              <div class="mb-3 custom">
                <button type="submit" class="btn  customOrange">
                 Confirm
                </button>
              </div>
            </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark customOrange" (click)="activeModal.close()">Close</button>
</div>

