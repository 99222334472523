import { Directive, ElementRef, Renderer2, OnInit, Input, HostListener } from '@angular/core';
@Directive({
    selector: '[appMaskInput]',
    standalone: true
})
export class MaskInputDirective implements OnInit {
@Input() appMaskValue: string;
constructor(
      private elRef: ElementRef,
      private renderer: Renderer2
    ) { }
ngOnInit(): void {
  }
@HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // console.log(event);
    if (this.appMaskValue && (this.appMaskValue.length === 2 || this.appMaskValue.length === 5) && event.key !== 'Backspace') {
      this.renderer.setProperty(this.elRef.nativeElement, 'value', this.appMaskValue + '/');
    }
  }
}
