<header>
    <nav class="navbar navbar-expand navbar-light bg-white fixed-top">
      <div class="d-flex justify-content-start px-2">
        <button class="btn btn-cutom sidebarToggle"  (click)="clickEvent()" id="sidebarToggle">
          <i class="fas fa-bars"></i>
        </button>
      </div>
     <a class="navbar-brand px-2 me-0" >
       <img src="./assets/images/logo.png"  width="200" class="d-inline-block align-top img-fluid img-logo" alt="">
     </a>
  
     <ul class="navbar-nav me-auto"></ul>
     <div *ngIf="pswdMsg == true">
      <span  style="font-size: 16px; background-color: #dc3545; color: white;"> {{password_message}} 
        <a style="font-weight: 600; text-decoration: underline; cursor: pointer;" (click)="updatePassword()">Click here</a> to update 
        <i class="fa fa-times" style="cursor: pointer" aria-hidden="true" (click)="closePassMsg()"></i>
      </span>
    </div>

     <ul class="navbar-nav justify-content-end ">
       <li class="nav-item dropdown no-arrow">
        <a class="nav-link"
        #d1="ngbPopover"
          placement="bottom"
          [ngbPopover]="popContent"
          [autoClose]="'outside'"
          [popoverTitle]="popTitle">
          <i class="fas fa-bell userIcon fa-2x m-2" (click)="onOpen()"></i>
          <span class="badge bg-primary custom-badge">{{reportCount}}</span>
        </a>
        <ng-template #popTitle>

             <div class="row">
                <div class="col-10 pt-2">
                   Reports Notification
                  </div>
                   <div class="col-2">
                     <button type="button" class="btn btn-default" (click)="d1.close()">
            <span aria-hidden="true">&times;</span>
          </button>
                  </div>
                  </div>

        </ng-template>
        <ng-template #popContent>
<div class="noti-body">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let reportinfo of reportsList; index as i;">
              <div class="row">
                <div class="col-10">
               <span data-toggle="tooltip" title='Report Generated at {{reportinfo.created_on}}' aria-hidden="true"> {{reportinfo.report_name}}</span>
               <div><small>{{reportinfo.created_on}}</small></div>
                  <div *ngIf="reportinfo.report_status== 'empty' && reportinfo.message &&  reportinfo.message.length<25"  class="text-danger"><small>{{reportinfo.message}}</small></div>
                   <div *ngIf="reportinfo.report_status== 'empty' && reportinfo.message &&  reportinfo.message.length>25" class="text-danger">
                     <small data-toggle="tooltip" title='Report Generated at {{reportinfo.message}}'>{{reportinfo.message|slice:50}}...</small>
                    </div>

                </div>
               <div class="col-2">
                   <i *ngIf="reportinfo.report_status== 'processing'" class="fas fa-spinner fa-pulse text-success download-icon"></i>
                 <i  *ngIf="reportinfo.report_status== 'completed'" class="fas fa-download text-success download-icon" (click)="ondownload(reportinfo)"></i>
                  <i *ngIf="reportinfo.report_status== 'failed'"   data-toggle="tooltip" title='Report Generation Failed'  class="fas fa-times text-danger download-icon"></i>
              </div>
              </div>

             </li>
          </ul>
          </div>

        </ng-template>
      </li>

        <li class="nav-item">
            <a class="nav-link joinData">
                <span class="userProfile">
                    Welcome {{ currentUserPermission&&currentUserPermission.user_name}}
                </span>
              </a>
              <a class="nav-link joinData" routerLinkActive="active" [queryParams]="{type: 1}" [routerLink]="['/expirations']">
              <span class="expirations">Expired Licenses</span>
              </a>
        </li>
       <li class="nav-item dropdown no-arrow">
           <a class="nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user-circle fa-2x m-2 userIcon"></i>
            <!-- <span class="px-2">
                  Admin ICON
            </span> -->
            </a>
           <div class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
             <a class="dropdown-item" (click)="onChangePassword()">Change Password</a>
             <!-- <a class="dropdown-item">Logout</a> -->
             <div class="dropdown-divider"></div>
             <a class="dropdown-item" routerLink="/signin" (click)="logOut()">Logout</a>
           </div>
         </li>
         <li *ngIf="isShowHotList" class="nav-item" [routerLink]="'/workflow/hotlist/1'">
            <a class="px-2 nav-link" placement="left" ngbTooltip="Hot List"  [openDelay]="300">
              <i class="fas fa-fire-alt fa-2x m-2 fireIcon"></i>
            </a>
         </li>
     </ul>

   </nav>


 </header>
