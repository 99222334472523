import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { SigninService } from '../../../containers/auth-routing/signin/signin.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import{CommonService}from '../../services/common/common.service'
import { Router } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'ctg-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: true,
    imports: [NgxSpinnerModule, FormsModule, ReactiveFormsModule, NgClass, NgIf]
})
export class ChangePasswordComponent implements OnInit {

  public changePasswordForm:UntypedFormGroup;
  public  submitted:boolean;
  public userName: string;
  private passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#^(){}+_>< "',-./:;=[\]`|~])[A-Za-z\d$@$!%*?&#^(){}+_>< "',-./:;=[\]`|~].{9,}$/;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private notiService: ToasterNotiService,
  ) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userEmail');
    this.buildForm();
  }



  buildForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      credential: ['', [Validators.required,Validators.minLength(10), Validators.pattern(this.passwordPattern)]],
      confirmPassword: ["", Validators.required]
    }, {
      validator: this.matchPassword('credential', 'confirmPassword')
    });
  }

  matchPassword(password: string, confirmPassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }


  get f() { return this.changePasswordForm.controls; }

  onChangePassword() {

    this.submitted = true;

    if (this.changePasswordForm.invalid)
      return

    let dataParams = {
      old_password: this.changePasswordForm.value.currentpassword,
      confirm_pwd: this.changePasswordForm.value.confirmPassword,
      new_password: this.changePasswordForm.value.credential, //add encription to password
      email: this.userName
    }

    this.commonService.updatePassword(dataParams).subscribe(
      data => {
        // console.log(data)
        if (data['success']) {
          this.notiService.showSuccess("Password Updated Successfully", '', 4000)
          this.logout();
         this. activeModal.close();
        }
        else {
          // console.log(data['error']);
          this.notiService.showError(data['error'], "", 4000);

        }
      },
      error => {
        // console.log(error);
        this.spinner.hide();
      })

  }

  logout(){
    this.commonService.logoutUser();
    localStorage.clear();
    this.router.navigate(['']);
  }

}
