import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalOptions, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { ErieFormSection1Component } from './erie-form-section1/erie-form-section1.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-erie-form',
    templateUrl: './erie-form.component.html',
    styleUrls: ['./erie-form.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgbDatepickerModule, NgSelectModule, ErieFormSection1Component]
})
export class ErieFormComponent implements OnInit {

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();


  public userId: any;
  public customerId: any;
  public erieForm: FormGroup;
  public credentialDropDownList: any = [];

  public stateDropDownDate: any = []
  public languageDropdownData: any = [];
  public specialtySecondary: any = [];
  public specialty: any = [];
  public country: any = [];
  public staffStatus: any = [];
  public degree: any = [];
  public raceData: any = [];
  public genderDropdownData: any = [];
  public boardCertification: any;
  public locationList: any = [];

  public isUsCitizen: boolean;
  public islicenseUnlimited: boolean;
  public islicenseUnlimited1: boolean;
  public islicenseUnlimited2: boolean;
  public islicenseUnlimited3: boolean;
  public islicenseUnlimited4: boolean;
  public isboardCertifiedSpecialty: boolean;
  public ischangeAction1: boolean;
  public isStraightchange: boolean;
  public ischangeAction2: boolean;
  public ischangeAction3: boolean;

  public ischangeAction4: boolean
  public ischangeAction5: boolean
  public ischangeAction6: boolean
  public ischangeAction7: boolean

  public ischangeAction8: boolean
  public ischangeAction9: boolean
  public ischangeAction10: boolean

  public ischangeAction11: boolean
  public ischangeAction12: boolean

  public ischangeAction13: boolean
  public ischangeAction14: boolean
  public ischangeAction15: boolean
  public ischangeAction16: boolean
  public ischangeAction17: boolean
  public ischangeAction18: boolean

  public ischangeAction19: boolean
  public ischangeAction20: boolean
  public ischangeAction21: boolean


  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  show4: boolean = false;
  show5: boolean = false;
  show6: boolean = false;
  show7: boolean = false;
  show8: boolean = false;
  show9: boolean = false;
  show10: boolean = false;
  show11: boolean = false;
  show12: boolean = false;
  show13: boolean = false;
  show14: boolean = false;
  show15: boolean = false;
  show16: boolean = false;
  show17: boolean = false;
  show18: boolean = false;
  show19: boolean = false;
  show20: boolean = false;
  show21: boolean = false;
  show22: boolean = false;

  esign1: any;
  esign2: any;
  esign3: any;
  esign4: any;
  esign5: any;
  esign6: any;
  esign7: any;
  esign8: any;
  esign9: any;
  esign10: any;
  esign11: any;
  esign12: any;
  esign13: any;
  esign14: any;
  esign15: any;
  esign16: any;
  esign17: any;
  esign18: any;
  esign19: any;
  esign20: any;
  esign21: any;
  esign22: any;


  finalImg1: any;
  finalImg2: any;
  finalImg3: any;
  finalImg4: any;
  finalImg5: any;
  finalImg6: any;
  finalImg7: any;
  finalImg8: any;
  finalImg9: any;
  finalImg10: any;
  finalImg11: any;
  finalImg12: any;
  finalImg13: any;
  finalImg14: any;
  finalImg15: any;
  finalImg16: any;
  finalImg17: any;
  finalImg18: any;
  finalImg19: any;
  finalImg20: any;
  finalImg21: any;
  finalImg22: any;

  finalImgPath1: any;
  finalImgPath2: any;
  finalImgPath3: any;
  finalImgPath4: any;
  finalImgPath5: any;
  finalImgPath6: any;
  finalImgPath7: any;
  finalImgPath8: any;
  finalImgPath9: any;
  finalImgPath10: any;
  finalImgPath11: any;
  finalImgPath12: any;
  finalImgPath13: any;
  finalImgPath14: any;
  finalImgPath15: any;
  finalImgPath16: any;
  finalImgPath17: any;
  finalImgPath18: any;
  finalImgPath19: any;
  finalImgPath20: any;
  finalImgPath21: any;
  finalImgPath22: any;

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };

  AltCdsStates: Array<object> = [];
  CdsStates: Array<object> = [];
  AddCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  isSubmit: boolean;
  enableForm: boolean;
  public aggregates: any = [];
  public preClaim: any = [];

  constructor(
    private fb: FormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildErieForm();
    this.getLocationList();
    this.getDegreeData();
    this.getCountyData();
    this.getStatesData();
    this.getSpecialtyData();
    this.getAltCdsStatesData();
    this.getSpecialtySecondaryData();
    this.getRaceData();
    this.getLanguagesData();
    this.getGenderData();
    this.getStaffStatusData();
    this.getBoardCertificationData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getCdsStatesData();
    this.getAdditionalCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 28,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }


  buildErieForm() {
    this.erieForm = this.fb.group({
      cmd_33330: [""],
      cmd_33331: [""],
      cmd_10201: [""],
      cmd_33332: [""],
      cmd_33333: [""],
      cmd_33334: [""],
      cmd_33335: [""],
      cmd_33336: [""],
      cmd_33337: [""],
      cmd_9000350: ["", Validators.required],
      cmd_33338: [""],
      cmd_33339: [null, [Validators.required,this.dateFormatValidator]],
      9: ["", Validators.required],
      8: ["", Validators.required],
      809: ["", Validators.required],
      480: [null, Validators.required],
      278: ["", Validators.required],
      2337: ["", Validators.required],
      31: [null, [Validators.required,this.dateFormatValidator]],
      810: ["", Validators.required],
      289: [null, Validators.required],
      291: ["", Validators.required],
      30: [null, Validators.required],
      cmd_33340: [""],
      cmd_33341: [""],
      cmd_33342: [""],
      cmd_33343: [null, Validators.required],
      cmd_33344: ["", Validators.required],
      cmd_33345: [""],
      1584: ["", Validators.required],
      32: ["", Validators.required],
      2682: ["", Validators.required],
      2631: ["", Validators.required],
      16: ["", Validators.required],
      17: ["", Validators.required],
      18: ["", Validators.required],
      20: [null, Validators.required],
      21: ["", Validators.required],
      708: ["", Validators.required],
      710: ["", Validators.required],
      759: ["", Validators.required],
      cmd_3334400: [""],
      293: ["", Validators.required],
      cmd_33346: ["", Validators.required],
      cmd_33347: [""],
      907: [null],
      300: [""],
      308: [null, [this.dateFormatValidator]],
      cmd_33348: [""],
      cmd_33349: [""],
      1010: [null],
      999: [""],
      1004: [null, [this.dateFormatValidator]],
      cmd_33350: [""],
      cmd_33351: [""],
      cmd_998: [null],
      cmd_999: [""],
      cmd_1004: [null, [this.dateFormatValidator]],
      cmd_33352: [""],
      cmd_33353: [""],
      cmd_33354: [""],
      309: ["", Validators.required],
      311: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33355: [""],
      cmd_33356: [""],
      cmd_33357: [""],
      996: [null],
      337: ["", Validators.required],
      339: [null, [this.dateFormatValidator]],
      997: [null],
      340: [""],
      342: [null, [this.dateFormatValidator]],
      2260: [null],
      2254: [""],
      2256: [null, [this.dateFormatValidator]],
      cmd_3335811: [""],
      819: [""],
      1634: ["", Validators.required],
      812: ["", Validators.required],
      4298: [null],
      3756: [""],
      3758: [null, [this.dateFormatValidator]],
      cmd_1007: [""],
      284: [null, Validators.required],
      cmd_33359: ["", Validators.required],
      554: [null],
      557: [null, [Validators.required,this.dateFormatValidator]],
      559: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33361: ["", Validators.required],
      1377: [null, [Validators.required,this.dateFormatValidator]],
      560: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33358: [null, [Validators.required,this.dateFormatValidator]],
      962: [null, Validators.required],
      cmd_33362: ["", Validators.required],
      561: [null],
      564: [null, [Validators.required,this.dateFormatValidator]],
      565: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33363: ["", Validators.required],
      1378: [null, [Validators.required,this.dateFormatValidator]],
      567: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33364: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34106: [null],
      cmd_33365: [""],
      cmd_34107: [null],
      cmd_34108: [null, [this.dateFormatValidator]],
      cmd_34109: [null, [this.dateFormatValidator]],
      cmd_33366: [""],
      cmd_34110: [null, [this.dateFormatValidator]],
      cmd_34111: [null, [this.dateFormatValidator]],

      cmd_33367: [null, [this.dateFormatValidator]],
      cmd_341112: [null],
      cmd_33368: [""],
      cmd_341113: [null],
      cmd_341114: [null, [this.dateFormatValidator]],
      cmd_341115: [null, [this.dateFormatValidator]],
      cmd_33369: [""],
      cmd_341116: [null, [this.dateFormatValidator]],
      cmd_34117: [null, [this.dateFormatValidator]],
      cmd_33370: [null, [this.dateFormatValidator]],
      cmd_33371: [""],
      901: ["", Validators.required],
      902: ["", Validators.required],
      903: ["", Validators.required],
      905: [null, Validators.required],
      906: ["", Validators.required],
      346: ["", Validators.required],
      348: [null, [Validators.required,this.dateFormatValidator]],
      349: [null, [Validators.required,this.dateFormatValidator]],
      350: [null, Validators.required],
      351: [null, Validators.required],
      945: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33372: [""],
      cmd_33373: [""],
      cmd_33374: ["", Validators.required],
      1242: ["", Validators.required],
      1244: ["", Validators.required],
      1245: ["", Validators.required],
      1246: ["", Validators.required],
      1248: [null, Validators.required],
      1249: ["", Validators.required],
      1256: ["", Validators.required],
      1252: [null, [Validators.required,this.dateFormatValidator]],
      1253: [null, [Validators.required,this.dateFormatValidator]],
      1264: [null, Validators.required],
      1265: [null, Validators.required],
      1251: [null, [Validators.required,this.dateFormatValidator]],
      cmd_1000: [""],
      cmd_33375: [""],
      cmd_33376: ["", Validators.required],
      cmd_33377: [""],
      cmd_33378: [""],
      cmd_33379: [""],
      cmd_33380: [""],
      cmd_33381: [null],
      cmd_33382: [""],
      cmd_33383: [""],
      cmd_33384: [null, [this.dateFormatValidator]],
      cmd_33385: [null, [this.dateFormatValidator]],
      cmd_33386: [null],
      cmd_33387: [null],
      cmd_33388: [null, [this.dateFormatValidator]],
      cmd_33389: [""],
      cmd_33390: [""],
      cmd_33391: [""],
      cmd_33392: [""],
      cmd_33393: [""],
      cmd_33394: [""],
      cmd_33395: [""],
      cmd_33396: [null],
      cmd_33397: [""],
      cmd_33398: [""],
      cmd_33399: [null, [this.dateFormatValidator]],
      cmd_33400: [null, [this.dateFormatValidator]],
      cmd_33401: [null],
      cmd_33402: [null],
      cmd_33403: [null, [this.dateFormatValidator]],
      cmd_1001: [""],
      cmd_33404: [""],
      cmd_33405: [""],
      490: ["", Validators.required],
      491: ["", Validators.required],
      492: ["", Validators.required],
      493: ["", Validators.required],
      495: [null, Validators.required],
      496: ["", Validators.required],
      2351: ["", Validators.required],
      2352: ["", Validators.required],
      500: [null, Validators.required],
      499: [null, [Validators.required,this.dateFormatValidator]],
      497: [null, [Validators.required,this.dateFormatValidator]],
      498: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33406: ["", Validators.required],
      515: [null, [Validators.required,this.dateFormatValidator]],
      514: ["", Validators.required],
      cmd_33407: ["", Validators.required],
      cmd_33408: [""],
      cmd_33409: [""],
      //cmd_33410: ["", Validators.required],
      517: ["", Validators.required],
      528: ["", Validators.required],
      518: ["", Validators.required],
      519: ["", Validators.required],
      520: ["", Validators.required],
      522: [null, Validators.required],
      523: ["", Validators.required],
      1318: ["", Validators.required],
      1319: ["", Validators.required],
      524: [null, [Validators.required,this.dateFormatValidator]],
      525: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33411: [""],
      cmd_33412: ["", Validators.required],
      527: [null],
      cmd_33413: ["", Validators.required],
      cmd_33414: [""],
      cmd_33415: ["", Validators.required],
      cmd_33416: [""],
      cmd_33417: [""],
      529: ["", Validators.required],
      540: ["", Validators.required],
      531: ["", Validators.required],
      1498: ["", Validators.required],
      533: ["", Validators.required],
      535: [null, Validators.required],
      536: ["", Validators.required],
      1316: ["", Validators.required],
      1317: ["", Validators.required],
      537: [null, [Validators.required,this.dateFormatValidator]],
      538: [null, [Validators.required,this.dateFormatValidator]],
      530: [null, Validators.required],
      cmd_33418: ["", Validators.required],
      cmd_33419: [""],
      cmd_33420: ["", Validators.required],
      cmd_33421: [""],
      1170: ["", Validators.required],
      1181: ["", Validators.required],
      1172: [""],
      1173: [""],
      1174: [""],
      1176: [null],
      1177: [""],
      1320: [""],
      1321: [""],
      1178: [null, [this.dateFormatValidator]],
      1179: [null, [this.dateFormatValidator]],
      1171: [null],
      cmd_33422: [""],
      cmd_33423: [""],
      cmd_33424: [""],
      cmd_33425: [""],
      cmd_33426: [""],
      542: ["", Validators.required],
      553: ["", Validators.required],
      545: ["", Validators.required],
      544: ["", Validators.required],
      546: ["", Validators.required],
      548: [null, Validators.required],
      549: ["", Validators.required],
      1322: ["", Validators.required],
      1323: ["", Validators.required],
      550: [null, [Validators.required,this.dateFormatValidator]],
      551: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33427: ["", Validators.required],
      cmd_33428: [""],
      cmd_33429: ["", Validators.required],
      cmd_33430: [""],
      966: [""],
      983: [""],
      968: [""],
      969: [""],
      970: [""],
      972: [null],
      1301: [""],
      1324: [""],
      1325: [""],
      974: [null, [this.dateFormatValidator]],
      975: [null, [this.dateFormatValidator]],
      967: [null],
      cmd_33431: [""],
      cmd_33432: [""],
      cmd_33433: [""],
      cmd_33434: [""],
      cmd_33435: [""],
      1146: [""],
      1382: [""],
      1148: [""],
      1149: [""],
      1150: [""],
      1152: [null],
      1153: [""],
      4142: [""],
      1155: [null, [this.dateFormatValidator]],
      1156: [null, [this.dateFormatValidator]],
      1154: [""],
      cmd_33436: [""],
      cmd_33437: [""],
      1157: [""],
      1383: [""],
      1159: [""],
      1160: [""],
      1161: [""],
      1163: [null],
      1164: [""],
      4143: [""],
      1166: [null, [this.dateFormatValidator]],
      1167: [null, [this.dateFormatValidator]],
      1165: [""],
      cmd_33438: [""],
      cmd_33439: [""],
      cmd_33440: [""],
      418: [""],
      419: [""],
      420: [""],
      421: [""],
      423: [null],
      424: [""],
      894: [null],
      426: [null, [this.dateFormatValidator]],
      425: [""],
      2136: [""],
      1305: [""],
      cmd_33441: [""],
      427: [""],
      428: [""],
      429: [""],
      430: [""],
      432: [null],
      433: [""],
      895: [null],
      435: [null, [this.dateFormatValidator]],
      2008: [null, [this.dateFormatValidator]],
      434: [""],
      2137: [""],
      1307: [""],
      cmd_33442: [""],

      436: [""],
      437: [""],
      438: [""],
      439: [""],
      441: [null],
      896: [null],
      444: [null, [this.dateFormatValidator]],
      2011: [null, [this.dateFormatValidator]],
      443: [""],
      2138: [""],
      1309: [""],
      cmd_33443: [""],
      cmd_33444: [""],
      1183: [""],
      1184: [""],
      1185: [""],
      1186: [""],
      1188: [null],
      1189: [""],
      1192: [null],
      1191: [null, [this.dateFormatValidator]],
      2381: [null, [this.dateFormatValidator]],
      1190: [""],
      2140: [""],
      1313: [""],
      cmd_33445: [""],
      cmd_33446: [""],
      cmd_33447: [""],
      cmd_33448: [""],
      cmd_33449: [""],
      cmd_33450: [null],
      cmd_33451: [""],
      cmd_33452: [null],
      cmd_33453: [null, [this.dateFormatValidator]],
      cmd_33454: [null, [this.dateFormatValidator]],
      cmd_33455: [""],
      cmd_33456: [""],
      cmd_33457: [""],
      cmd_33458: [""],
      cmd_33459: [""],
      cmd_33460: [""],
      cmd_33461: [""],
      cmd_33462: [""],
      cmd_33463: [null],
      cmd_33464: [""],
      cmd_33466: [null, [this.dateFormatValidator]],
      cmd_33468: [""],
      cmd_33469: [""],
      cmd_33470: [""],
      cmd_33471: [""],
      cmd_33472: [""],
      2025: [""],
      2032: [""],
      2033: [""],
      2034: [""],
      2036: [null],
      2040: [""],
      2041: [""],
      2045: [null],
      2042: [null, [this.dateFormatValidator]],
      2044: [null, [this.dateFormatValidator]],
      2046: [""],
      2047: [""],
      2048: [""],
      2049: [""],
      2052: [""],
      2055: [""],
      2056: [""],
      2060: [null],
      2057: [null, [this.dateFormatValidator]],
      2059: [null, [this.dateFormatValidator]],
      2061: [""],
      2062: [""],
      2063: [""],
      2064: [""],
      2067: [""],
      2066: [null],
      2070: [""],
      2071: [""],
      2075: [null],
      2072: [null, [this.dateFormatValidator]],
      2074: [null, [this.dateFormatValidator]],
      cmd_33518: [""],
      445: [""],
      446: [""],
      447: [""],
      448: [""],
      450: [null],
      451: [""],
      453: [""],
      888: [""],
      1326: [""],
      454: [null, [this.dateFormatValidator]],
      456: [null],
      457: [""],
      458: [""],
      459: [""],
      461: [null],
      462: [""],
      464: [""],
      890: [""],
      1343: [""],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      467: [""],
      468: [""],
      469: [""],
      470: [""],
      472: [""],
      473: [""],
      475: [""],
      892: [""],
      1339: [""],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      1935: [""],
      1936: [""],
      1937: [""],
      1938: [""],
      1940: [null],
      1941: [""],
      1944: [""],
      1945: [""],
      1949: [""],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      1954: [""],
      1955: [""],
      1956: [""],
      1957: [""],
      1960: [""],
      1963: [""],
      1964: [""],
      1968: [""],
      1966: [null, [this.dateFormatValidator]],
      1967: [null, [this.dateFormatValidator]],
      cmd_33473: [""],
      cmd_33474: [""],
      cmd_33475: [""],
      cmd_33476: [""],
      cmd_33477: [null],
      cmd_33478: [""],
      cmd_33479: [""],
      cmd_33481: [""],
      cmd_33482: [null, [this.dateFormatValidator]],
      cmd_33483: [null, [this.dateFormatValidator]],
      cmd_33484: [""],
      cmd_33485: [""],
      cmd_33486: [""],
      cmd_33487: [""],
      cmd_33488: [null],
      cmd_33489: [""],
      cmd_33490: [""],
      cmd_33491: [""],
      cmd_33492: [""],
      cmd_33493: [null, [this.dateFormatValidator]],
      cmd_33494: [null, [this.dateFormatValidator]],
      cmd_33495: [""],
      cmd_33496: [""],
      cmd_33497: [""],
      cmd_33498: [""],
      cmd_33500: [""],
      cmd_33501: [""],
      cmd_33502: [""],
      cmd_33503: [""],
      cmd_33504: [null, [this.dateFormatValidator]],
      cmd_33505: [null, [this.dateFormatValidator]],
      cmd_33506: [""],
      cmd_33507: [""],
      cmd_33508: [""],
      cmd_33509: [""],
      cmd_33510: [null],
      cmd_33511: [""],
      cmd_33512: [""],
      cmd_33513: [""],
      cmd_33514: [""],
      cmd_33515: [null, [this.dateFormatValidator]],
      cmd_33516: [null, [this.dateFormatValidator]],
      cmd_33517: [""],
      359: ["", Validators.required],
      2722: ["", Validators.required],
      368: [null, Validators.required],
      360: ["", Validators.required],
      361: ["", Validators.required],
      362: ["", Validators.required],
      364: [null, Validators.required],
      365: ["", Validators.required],
      369: ["", Validators.required],
      370: ["", Validators.required],
      2721: ["", Validators.required],
      366: [null, [Validators.required,this.dateFormatValidator]],
      372: ["", Validators.required],
      2723: ["", Validators.required],
      380: [null, Validators.required],
      373: ["", Validators.required],
      374: ["", Validators.required],
      375: ["", Validators.required],
      377: [null, Validators.required],
      378: ["", Validators.required],
      381: ["", Validators.required],
      382: ["", Validators.required],
      2724: ["", Validators.required],
      817: [null, [Validators.required,this.dateFormatValidator]],
      384: ["", Validators.required],
      2725: ["", Validators.required],
      393: [null, Validators.required],
      385: ["", Validators.required],
      386: ["", Validators.required],
      387: ["", Validators.required],
      389: [null, Validators.required],
      390: ["", Validators.required],
      394: ["", Validators.required],
      395: ["", Validators.required],
      2726: ["", Validators.required],
      391: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33519: ["", Validators.required],
      cmd_33520: ["", Validators.required],
      cmd_33521: ["", Validators.required],
      cmd_33522: ["", Validators.required],
      cmd_33523: ["", Validators.required],
      cmd_33524: ["", Validators.required],
      cmd_33525: ["", Validators.required],
      cmd_33526: ["", Validators.required],
      cmd_33527: ["", Validators.required],
      cmd_33528: ["", Validators.required],
      cmd_33529: ["", Validators.required],
      cmd_33530: ["", Validators.required],
      cmd_33531: ["", Validators.required],
      cmd_33532: ["", Validators.required],
      cmd_33533: ["", Validators.required],
      cmd_33534: ["", Validators.required],
      cmd_33535: ["", Validators.required],
      cmd_33536: ["", Validators.required],
      cmd_33537: ["", Validators.required],
      cmd_33538: ["", Validators.required],
      cmd_33539: ["", Validators.required],
      cmd_33540: ["", Validators.required],
      cmd_33541: ["", Validators.required],
      cmd_33542: ["", Validators.required],
      cmd_33543: ["", Validators.required],
      cmd_33544: ["", Validators.required],
      cmd_33545: [""],
      cmd_100040: [null, Validators.required],
      cmd_33548: ["", Validators.required],
      cmd_33549: ["", Validators.required],
      cmd_33550: ["", Validators.required],
      cmd_33551: [null, Validators.required],
      cmd_33552: ["", Validators.required],
      cmd_33553: ["", Validators.required],
      cmd_33554: ["", Validators.required],
      cmd_33555: ["", Validators.required],
      cmd_33556: ["", Validators.required],
      cmd_33557: ["", Validators.required],
      cmd_33558: ["", Validators.required],
      cmd_33559: ["", Validators.required],
      cmd_33560: ["", Validators.required],
      cmd_33561: ["", Validators.required],
      cmd_33562: [""],
      cmd_33563: ["", Validators.required],
      cmd_33564: ["", Validators.required],
      cmd_33565: [""],
      cmd_33566: ["", Validators.required],
      cmd_33567: ["", Validators.required],
      cmd_33568: [""],
      cmd_33569: [""],
      cmd_33570: [""],
      cmd_33571: [""],
      cmd_33572: [""],
      cmd_33573: [""],
      cmd_33574: [""],
      cmd_33575: [""],
      cmd_33576: [""],
      cmd_33577: [""],
      cmd_33578: [""],
      cmd_33579: [""],
      cmd_33580: [""],
      cmd_33581: [""],
      cmd_33582: [""],
      cmd_33583: [""],
      cmd_33584: [""],
      cmd_33585: [""],
      cmd_33586: [""],
      cmd_33587: [""],
      cmd_33588: [""],
      cmd_33589: [""],
      cmd_33590: [""],
      cmd_33591: [""],
      cmd_33592: [""],
      cmd_33593: [""],
      cmd_33594: [""],
      cmd_33595: [""],
      cmd_33596: [""],
      cmd_33597: [""],
      cmd_33598: [""],
      cmd_33599: [""],
      cmd_33600: [""],
      cmd_33601: [""],
      cmd_33602: [""],
      cmd_33603: [""],
      cmd_33604: [""],
      cmd_33605: [""],
      cmd_33606: [""],
      cmd_33607: [""],
      cmd_33608: [""],
      cmd_33609: [""],
      cmd_33610: [""],
      cmd_33611: [""],
      cmd_33612: [""],
      cmd_33613: [""],
      cmd_33614: [""],
      cmd_33615: [""],
      cmd_33616: [""],
      cmd_33617: [""],
      cmd_33618: [""],
      cmd_33619: [""],
      cmd_33620: [""],
      cmd_33621: [""],
      cmd_33622: [""],
      cmd_33623: [""],
      cmd_33624: [""],
      cmd_33625: [""],
      cmd_33626: [""],
      cmd_33627: [""],
      cmd_33628: [""],
      cmd_33629: [""],
      cmd_33630: [""],
      cmd_33631: [""],
      cmd_33632: [""],
      cmd_33633: [""],
      cmd_33634: [""],
      cmd_33635: [""],
      cmd_33636: [""],
      cmd_33637: [""],
      cmd_33638: [""],
      cmd_33639: [""],
      cmd_33640: [""],
      cmd_33641: [""],
      cmd_33642: [""],
      cmd_33643: [""],
      cmd_33644: [""],
      cmd_33645: [""],
      cmd_33646: [""],
      cmd_33647: [""],
      cmd_33648: [""],
      cmd_33649: [""],
      cmd_33650: [""],
      cmd_33651: [""],
      cmd_33652: [""],
      cmd_33653: [""],
      cmd_33654: [""],
      cmd_33655: [""],
      cmd_1002: [""],
      cmd_33656: [""],
      cmd_33657: [""],
      cmd_33658: [""],
      cmd_33659: [""],
      cmd_33660: [""],
      cmd_33661: [""],
      cmd_33662: [""],
      cmd_33663: [""],
      cmd_33664: [""],
      cmd_33665: [""],
      cmd_33666: [""],
      cmd_33667: [""],
      cmd_33668: [""],
      cmd_33669: [""],
      cmd_33670: [""],
      cmd_33671: [""],
      cmd_33672: [""],
      cmd_33673: [""],
      cmd_33674: [""],
      cmd_33675: [""],
      cmd_33676: [""],
      cmd_33677: [""],
      cmd_33678: [""],
      cmd_33679: [""],
      cmd_33680: [""],
      cmd_33681: [""],
      cmd_33682: [""],
      cmd_33683: [""],
      cmd_33684: [""],
      cmd_33685: [""],
      cmd_33686: [""],
      cmd_33687: [""],
      cmd_33688: [""],
      cmd_33689: [""],
      cmd_33690: [""],
      cmd_33691: [""],
      cmd_100041: [""],
      cmd_33693: [""],
      cmd_33694: [""],
      cmd_33695: [""],
      cmd_33696: [""],
      cmd_33697: [null],
      cmd_33698: [""],
      cmd_33699: [""],
      cmd_33700: [""],
      cmd_33701: [""],
      cmd_33702: [""],
      cmd_33703: [""],
      cmd_33704: [""],
      cmd_33705: [""],
      cmd_33706: [""],
      cmd_33707: [""],
      cmd_33708: [""],
      cmd_33709: [""],
      cmd_33710: [""],
      cmd_33711: [""],
      cmd_33712: [""],
      cmd_33713: [""],
      cmd_33714: [""],
      cmd_33715: [""],
      cmd_33716: [""],
      cmd_33717: [""],
      cmd_33718: [""],
      cmd_33719: [""],
      cmd_33720: [""],
      cmd_1003: [""],
      cmd_1010: [""],

      cmd_1008: [""],
      cmd_1009: [""],
      cmd_1011: [""],
      cmd_33721: [""],
      cmd_33722: [""],
      cmd_1005: [""],
      cmd_33723: [""],
      cmd_33724: [""],
      cmd_33725: [""],
      cmd_33726: [""],
      cmd_33727: [""],
      cmd_33728: [""],
      cmd_33729: [""],
      cmd_33730: [""],
      cmd_33731: [""],
      cmd_33732: [""],
      cmd_33733: [""],
      cmd_33734: [""],
      cmd_33735: [""],
      cmd_33736: [""],
      cmd_33737: [""],
      cmd_33738: [""],
      cmd_33739: [""],
      cmd_33740: [""],
      cmd_33741: [""],
      cmd_33742: [""],
      cmd_33743: [""],
      cmd_33744: [""],
      cmd_33745: [""],
      cmd_33746: [""],
      cmd_33747: [""],
      cmd_33748: [""],
      cmd_33749: [""],
      cmd_33750: [""],
      cmd_33751: [""],
      cmd_33752: [false],
      cmd_33753: [false],
      cmd_33754: [false],
      cmd_33755: [false],
      cmd_33756: [""],
      cmd_33757: [""],
      cmd_33758: [""],
      cmd_33759: [""],
      cmd_33760: [""],
      cmd_33761: [""],
      cmd_33762: [""],
      cmd_33763: [""],
      cmd_33764: [""],
      cmd_33765: [""],
      cmd_33766: [""],
      cmd_33768: [""],
      cmd_33769: [""],
      cmd_33770: [""],
      cmd_33771: [""],
      cmd_33772: [""],
      cmd_33774: [null],
      cmd_33775: [""],
      cmd_33776: [""],
      cmd_33777: [""],
      cmd_33778: [""],
      cmd_33779: [""],
      cmd_33781: [""],
      cmd_33782: [""],
      cmd_33783: [""],
      cmd_33784: [""],
      cmd_33785: [null],
      cmd_33786: [""],
      cmd_33787: [""],
      cmd_33788: [""],
      cmd_33789: [""],
      cmd_33790: [""],
      cmd_33791: [""],
      cmd_33792: [""],
      cmd_33793: [""],
      cmd_33794: [""],
      cmd_33795: [""],
      cmd_33796: [null],
      cmd_33797: [""],
      cmd_33798: [""],
      cmd_33799: [""],
      cmd_33800: [""],
      cmd_33801: [""],
      cmd_33802: [""],
      cmd_33803: [""],
      cmd_33804: [""],
      cmd_33805: [""],
      cmd_33806: [""],
      cmd_33808: [""],
      cmd_33809: [""],
      cmd_33810: [""],
      cmd_33811: [""],
      cmd_33812: [""],
      cmd_33813: [""],
      cmd_33814: [""],
      cmd_33815: [""],
      cmd_33816: [""],
      cmd_33817: [""],
      cmd_33818: [""],
      cmd_33819: [""],
      cmd_33820: [""],
      cmd_33821: [""],
      cmd_33822: [""],
      cmd_33823: [""],
      cmd_33824: [""],
      cmd_33825: [""],
      cmd_33826: [""],
      cmd_33827: [""],
      cmd_33828: [""],
      cmd_33829: [""],
      cmd_33830: [""],
      cmd_33831: [""],
      cmd_33832: [""],
      cmd_33833: [""],
      cmd_33834: [""],
      cmd_33835: [""],
      cmd_33836: [""],
      cmd_33837: [""],
      cmd_33838: [""],
      cmd_33839: [""],
      cmd_33840: [""],
      cmd_33841: [""],
      cmd_33842: [""],
      cmd_33843: [""],
      cmd_33844: [""],
      cmd_33845: [""],
      cmd_33846: [null],
      cmd_33847: [""],
      cmd_33848: [""],
      cmd_9000351: [""],
      cmd_34105: [null, [this.dateFormatValidator]],
      cmd_33849: [""],
      cmd_33850: [""],
      cmd_33851: [""],
      1205: [""],
      1853: [""],
      1218: [""],
      cmd_1218: [""],
      1216: [""],
      cmd_33857: [""],
      1208: [null, [this.dateFormatValidator]],
      1209: [null, [this.dateFormatValidator]],
      1224: [null, [this.dateFormatValidator]],
      cmd_33861: [""],
      cmd_33862: [""],
      cmd_33863: [""],
      cmd_33864: [""],
      cmd_33865: [""],
      cmd_33866: [""],
      cmd_33867: [""],
      1229: [""],
      1210: [""],
      1849: [""],
      1859: [""],
      2012: [""],
      1844: [""],
      1845: [""],
      cmd_33875: [null],
      1848: [""],
      cmd_9000352: [""],
      cmd_1012: [null, [this.dateFormatValidator]],
      cmd_33877: [""],
      cmd_33878: [""],
      cmd_33879: [""],
      cmd_33880: [""],
      cmd_33881: [""],
      cmd_33882: [""],
      cmd_33883: [""],
      cmd_33884: [""],
      cmd_33885: [""],
      cmd_33886: [""],
      cmd_33887: [""],
      cmd_33888: [""],
      cmd_33889: [""],
      cmd_33890: [null],
      cmd_33891: [""],
      cmd_33892: [null , [this.dateFormatValidator]],
      cmd_33893: [null, [this.dateFormatValidator]],
      cmd_33894: [""],
      cmd_33895: [""],
      cmd_9000353: [""],
      cmd_1013: [null, [this.dateFormatValidator]],
      cmd_33896: [""],
      cmd_33897: [""],
      cmd_33898: [""],
      cmd_33899: [null, [this.dateFormatValidator]],
      cmd_33902: [""],
      cmd_33903: [""],
      cmd_33904: [""],
      cmd_33905: [""],
      cmd_33906: [""],
      cmd_33907: [""],
      cmd_9000354: [""],
      cmd_1014: [null, [this.dateFormatValidator]],
      cmd_33908: [""],
      cmd_33909: [""],
      cmd_33910: [""],
      cmd_33911: [""],
      cmd_33912: [""],
      cmd_33913: [""],
      cmd_33914: [""],
      cmd_33915: [""],
      cmd_33916: [""],
      cmd_33917: [""],
      cmd_33918: [null],
      cmd_33919: [""],
      cmd_33920: [""],
      cmd_33921: [""],
      cmd_33922: [""],
      cmd_33923: [null],
      cmd_33924: [""],
      cmd_9000355: [""],
      cmd_1015: [null, [this.dateFormatValidator]],
      cmd_33925: [""],
      cmd_33926: [""],
      cmd_33927: [""],
      cmd_33928: [""],
      cmd_33929: [""],
      cmd_33930: [""],
      cmd_33931: [""],
      cmd_33932: [""],
      cmd_33933: [""],
      cmd_33934: [""],
      cmd_33935: [""],
      cmd_33936: [""],
      cmd_33937: [""],
      cmd_33938: [""],
      cmd_33940: [""],
      cmd_33941: [""],
      cmd_33942: [""],
      cmd_33943: [""],
      cmd_33944: [null],
      cmd_9000356: [""],
      cmd_10200: [null, [this.dateFormatValidator]],
      cmd_33947: [""],
      cmd_33948: [""],
      cmd_33949: [""],
      cmd_33950: [""],
      cmd_33951: [null, [this.dateFormatValidator]],
      cmd_33952: [""],
      cmd_33953: [""],
      cmd_33954: [null],
      cmd_33955: [""],
      cmd_33956: [null, [this.dateFormatValidator]],
      cmd_33957: [null, [this.dateFormatValidator]],
      cmd_33958: [""],
      cmd_33959: [""],
      cmd_33960: [""],
      cmd_33961: [null],
      cmd_33962: [""],
      cmd_33964: [null, [this.dateFormatValidator]],
      cmd_33965: [null, [this.dateFormatValidator]],
      cmd_33966: [""],
      cmd_33967: [""],
      cmd_33968: [""],
      cmd_33969: [null],
      cmd_33970: [""],
      cmd_33971: [""],
      cmd_33972: [""],
      cmd_33973: [""],
      cmd_33974: [null],
      cmd_33975: [""],
      cmd_9000357: [""],
      cmd_34112: [null, [this.dateFormatValidator]],
      cmd_9000358: [""],
      cmd_34113: [null, [this.dateFormatValidator]],
      cmd_33977: [""],
      cmd_33978: [""],
      cmd_33979: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33980: [null, [this.dateFormatValidator]],
      cmd_33981: [""],
      cmd_9000359: ["", Validators.required],
      cmd_33982: [""],
      cmd_33983: [""],
      cmd_33984: [null, [this.dateFormatValidator]],
      cmd_9000360: ["", Validators.required],
      cmd_34114: [null, [Validators.required,this.dateFormatValidator]],
      cmd_33985: [""],
      cmd_33986: [""],
      cmd_33987: [""],
      cmd_340: [null],
      741: [null],
      cmd_33989: [""],
      cmd_33990: [""],
      cmd_33991: [""],
      cmd_33992: [null],
      cmd_33993: [""],
      cmd_33994: [""],
      cmd_33995: [""],
      cmd_33996: [""],
      cmd_33997: [null],
      cmd_33998: [""],
      cmd_33999: [null, [this.dateFormatValidator]],
      cmd_34000: [null, [this.dateFormatValidator]],
      cmd_34001: [""],
      cmd_34002: [""],
      cmd_34003: [""],
      cmd_34004: [null],
      cmd_34005: [""],
      cmd_34006: [null, [this.dateFormatValidator]],
      cmd_34007: [null, [this.dateFormatValidator]],
      cmd_34008: [""],
      cmd_34009: [""],
      cmd_34010: [""],
      cmd_34011: [null],
      cmd_34012: [""],
      cmd_34013: [null, [this.dateFormatValidator]],
      cmd_34014: [null, [this.dateFormatValidator]],
      cmd_34015: [""],
      cmd_34016: [""],
      cmd_34017: [""],
      cmd_34018: [null],
      cmd_34019: [""],
      cmd_34020: [null, [this.dateFormatValidator]],
      cmd_34021: [null, [this.dateFormatValidator]],
      cmd_34022: [""],
      cmd_34023: [""],
      cmd_34024: [""],
      cmd_34025: [null],
      cmd_34026: [""],
      cmd_34027: [null, [this.dateFormatValidator]],
      cmd_34028: [null, [this.dateFormatValidator]],
      cmd_34029: [""],
      cmd_34030: [""],
      cmd_34031: [""],
      cmd_34032: [""],
      cmd_34033: [""],
      cmd_34034: [""],
      cmd_34035: [""],
      cmd_34036: [""],
      cmd_9000361: [""],
      cmd_34037: [""],
      cmd_34038: [""],
      cmd_34039: [""],
      cmd_34040: [""],
      cmd_34041: [""],
      cmd_34042: [null, [this.dateFormatValidator]],
      cmd_9000363: [""],
      cmd_34043: [null, [this.dateFormatValidator]],
      cmd_9000364: [""],
      cmd_34044: [null, [this.dateFormatValidator]],
      cmd_9000365: ["", Validators.required],
      cmd_34045: [""],
      cmd_3404611: [null, [this.dateFormatValidator]],
      cmd_34046: [""],
      cmd_34047: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34048: [""],
      cmd_34049: [""],
      cmd_9000367: [""],
      cmd_9000368: ["", Validators.required],
      cmd_34050: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34051: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34052: [""],
      cmd_34053: [""],
      cmd_10202: [""],
      cmd_9000369: [""],
      cmd_34054: [null, [this.dateFormatValidator]],
      cmd_9000370: ["", Validators.required],
      cmd_34055: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34056: [""],
      cmd_10203: [""],
      cmd_34057: [""],
      cmd_34058: [""],
      cmd_9000371: ["", Validators.required],
      cmd_34060: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34061: [""],
      cmd_34062: [""],
      cmd_34063: [""],
      cmd_34064: [""],
      cmd_34065: [null, [this.dateFormatValidator]],
      cmd_34066: [""],
      cmd_34067: [null],
      cmd_34068: ["", Validators.required],
      cmd_34069: [null],
      cmd_34070: [null],
      cmd_34071: [""],
      cmd_34072: [""],
      cmd_34073: [""],
      cmd_34074: [null],
      cmd_34075: [""],
      cmd_34076: ["", Validators.required],
      cmd_34077: ["", Validators.required],
      cmd_34078: ["", Validators.required],
      cmd_34079: [null, Validators.required],
      cmd_34080: ["", Validators.required],
      cmd_34081: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34082: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34083: ["", Validators.required],
      cmd_34084: ["", Validators.required],
      cmd_34085: ["", Validators.required],
      cmd_34086: [null, Validators.required],
      cmd_34087: ["", Validators.required],
      cmd_34088: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34089: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34090: ["", Validators.required],
      cmd_34091: ["", Validators.required],
      cmd_34092: ["", Validators.required],
      cmd_34093: [null, Validators.required],
      cmd_34094: ["", Validators.required],
      cmd_34095: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34096: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34097: ["", Validators.required],
      cmd_34098: ["", Validators.required],
      cmd_34099: ["", Validators.required],
      cmd_34100: [null, Validators.required],
      cmd_34101: ["", Validators.required],
      cmd_34102: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34103: [null, [Validators.required,this.dateFormatValidator]],
      cmd_34104: [""],
      cmd_1017: [""],
      cmd_31: [""],
      345: ["", Validators.required],
      543: [null, Validators.required],
      cmd_1662: [""],
      2037: [""],
      2051: [null],
      1959: [null],
      cmd_33480: [""],
      cmd_33499: [null],
      cmd_33547: ["", Validators.required],
      cmd_33465: [null],
      cmd_33467: [null, [this.dateFormatValidator]],
      cmd_33780: [""],
      cmd_33807: [""],
      cmd_33939: [null, [this.dateFormatValidator]],
      cmd_33945: [""],
      cmd_33946: [""],
      cmd_33767: [""],
      cmd_33976: [""],
      cmd_33988: [null, [this.dateFormatValidator]],
      cmd_34115: [null, [this.dateFormatValidator]],
      cmd_33900: [null, [this.dateFormatValidator]],
      cmd_33901: [null, [this.dateFormatValidator]],
      cmd_9000362: ["", Validators.required],
      cmd_9000366: ["", Validators.required],
      //need id
      //  cmd_33635:[""]




    });
  }

  public getStatesData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.stateDropDownDate = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getSpecialtyData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getSpecialtySecondaryData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getLanguagesData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languageDropdownData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getRaceData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.raceData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getDegreeData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getCountyData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getStaffStatusData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public getGenderData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.genderDropdownData = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }


  public getBoardCertificationData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }


  public esignOpen(type: any) {

    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then((result) => {
    })
    modalRef.componentInstance.base64Img.subscribe((data) => {
      modalRef.close()
      if (type == 1) {
        this.finalImgPath1 = data.finalFilePath
        this.finalImg1 = data.base64Img
        this.esign1 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg1);
        this.show1 = true;

        let temp = {
          cmd_9000350: this.finalImgPath1
        }
        this.erieForm.patchValue(temp);
      }
      if (type == 2) {
        this.finalImgPath2 = data.finalFilePath
        this.finalImg2 = data.base64Img
        this.esign2 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg2);
        this.show2 = true;
        let temp = {
          cmd_9000351: this.finalImgPath2
        }
        this.erieForm.patchValue(temp);
      } else if (type == 3) {
        this.finalImgPath3 = data.finalFilePath
        this.finalImg3 = data.base64Img
        this.esign3 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg3);
        this.show3 = true;
        let temp = {
          cmd_9000352: this.finalImgPath3
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 4) {
        this.finalImgPath4 = data.finalFilePath
        this.finalImg4 = data.base64Img
        this.esign4 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg4);
        this.show4 = true;
        let temp = {
          cmd_9000353: this.finalImgPath4
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 5) {
        this.finalImgPath5 = data.finalFilePath
        this.finalImg5 = data.base64Img
        this.esign5 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg5);
        this.show5 = true;
        let temp = {
          cmd_9000354: this.finalImgPath5
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 6) {
        this.finalImgPath6 = data.finalFilePath
        this.finalImg6 = data.base64Img
        this.esign6 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg6);
        this.show6 = true;
        let temp = {
          cmd_9000355: this.finalImgPath6
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 7) {
        this.finalImgPath7 = data.finalFilePath
        this.finalImg7 = data.base64Img
        this.esign7 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg7);
        this.show7 = true;
        let temp = {
          cmd_9000356: this.finalImgPath7
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 8) {
        this.finalImgPath8 = data.finalFilePath
        this.finalImg8 = data.base64Img
        this.esign8 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg8);
        this.show8 = true;
        let temp = {
          cmd_9000357: this.finalImgPath8
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 9) {
        this.finalImgPath9 = data.finalFilePath
        this.finalImg9 = data.base64Img
        this.esign9 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg9);
        this.show9 = true;
        let temp = {
          cmd_9000358: this.finalImgPath9
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 10) {
        this.finalImgPath10 = data.finalFilePath
        this.finalImg10 = data.base64Img
        this.esign10 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg10);
        this.show10 = true;
        let temp = {
          cmd_9000359: this.finalImgPath10
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 11) {
        this.finalImgPath11 = data.finalFilePath
        this.finalImg11 = data.base64Img
        this.esign11 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg11);
        this.show11 = true;
        let temp = {
          cmd_9000360: this.finalImgPath11
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 12) {
        this.finalImgPath12 = data.finalFilePath
        this.finalImg12 = data.base64Img
        this.esign12 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg12);
        this.show12 = true;
        let temp = {
          cmd_9000361: this.finalImgPath12
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 13) {
        this.finalImgPath13 = data.finalFilePath
        this.finalImg13 = data.base64Img
        this.esign13 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg13);
        this.show13 = true;
        let temp = {
          cmd_9000362: this.finalImgPath13
        }
        this.erieForm.patchValue(temp);
      } else if (type == 14) {
        this.finalImgPath14 = data.finalFilePath
        this.finalImg14 = data.base64Img
        this.esign14 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg14);
        this.show14 = true;
        let temp = {
          cmd_9000363: this.finalImgPath14
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 15) {
        this.finalImgPath15 = data.finalFilePath
        this.finalImg15 = data.base64Img
        this.esign15 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg15);
        this.show15 = true;
        let temp = {
          cmd_9000364: this.finalImgPath15
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 16) {
        this.finalImgPath16 = data.finalFilePath
        this.finalImg16 = data.base64Img
        this.esign16 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg16);
        this.show16 = true;
        let temp = {
          cmd_9000365: this.finalImgPath16
        }
        this.erieForm.patchValue(temp);
      }

      else if (type == 17) {
        this.finalImgPath17 = data.finalFilePath
        this.finalImg17 = data.base64Img
        this.esign17 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg17);
        this.show17 = true;
        let temp = {
          cmd_9000366: this.finalImgPath17
        }
        this.erieForm.patchValue(temp);
      } else if (type == 18) {
        this.finalImgPath18 = data.finalFilePath
        this.finalImg18 = data.base64Img
        this.esign18 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg18);
        this.show18 = true;
        let temp = {
          cmd_9000367: this.finalImgPath18
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 19) {
        this.finalImgPath19 = data.finalFilePath
        this.finalImg19 = data.base64Img
        this.esign19 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg19);
        this.show19 = true;
        let temp = {
          cmd_9000368: this.finalImgPath19
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 20) {
        this.finalImgPath20 = data.finalFilePath
        this.finalImg20 = data.base64Img
        this.esign20 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg20);
        this.show20 = true;
        let temp = {
          cmd_9000369: this.finalImgPath20
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 21) {
        this.finalImgPath21 = data.finalFilePath
        this.finalImg21 = data.base64Img
        this.esign21 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg21);
        this.show21 = true;
        let temp = {
          cmd_9000370: this.finalImgPath21
        }
        this.erieForm.patchValue(temp);
      }
      else if (type == 22) {
        this.finalImgPath22 = data.finalFilePath
        this.finalImg22 = data.base64Img
        this.esign22 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.finalImg22);
        this.show22 = true;
        let temp = {
          cmd_9000371: this.finalImgPath22
        }
        this.erieForm.patchValue(temp);
      }

    })
  }

  close() {
    this.onClose.emit('close modal');
  }



  handlegenderchange(value) {

    this.erieForm.patchValue({
      cmd_34069: value,
      cmd_340: value
    })

  }

  handleZipchange(value) {

    this.erieForm.patchValue({
      cmd_34075: value
    })
  }

  handleCitychange(value) {

    this.erieForm.patchValue({
      cmd_34073: value
    })
  }

  handleStatechange(value) {

    this.erieForm.patchValue({
      cmd_34074: value
    })
  }

  handleAddress1change(value) {

    this.erieForm.patchValue({
      cmd_34071: value
    })
  }

  handleAddress2change(value) {

    this.erieForm.patchValue({
      cmd_34072: value
    })
  }



  handleFirstNamechange(firstName) {
    this.erieForm.patchValue({
      cmd_33338: firstName + " " + this.erieForm.controls[9].value,
      cmd_33978: firstName + " " + this.erieForm.controls[9].value,
      cmd_33981: firstName + " " + this.erieForm.controls[9].value,
      cmd_33977: firstName + " " + this.erieForm.controls[9].value,
      cmd_33982: firstName + " " + this.erieForm.controls[9].value,

      cmd_34040: firstName + " " + this.erieForm.controls[9].value,
      cmd_34045: firstName + " " + this.erieForm.controls[9].value,
      cmd_34048: firstName + " " + this.erieForm.controls[9].value,
      cmd_34053: firstName + " " + this.erieForm.controls[9].value,
      cmd_10203: firstName + " " + this.erieForm.controls[9].value,
      cmd_34046: firstName + " " + this.erieForm.controls[9].value,
      cmd_33835: firstName,
      cmd_33850: firstName,
      cmd_33878: firstName,
      cmd_33897: firstName,
      cmd_33909: firstName,
      cmd_33926: firstName,
      cmd_33947: firstName,
      cmd_33985: firstName,
      cmd_34061: firstName,

    });

  }

  handleEmailChange(value) {
    this.erieForm.patchValue({
      cmd_1017: value
    });
  }

  handleHomePhonechange(value) {
    this.erieForm.patchValue({
      cmd_34104: value
    });
  }

  handleLastNamechange(lastName) {
    this.erieForm.patchValue({
      cmd_33338: this.erieForm.controls[8].value + " " + lastName,
      cmd_33978: this.erieForm.controls[8].value + " " + lastName,
      cmd_33981: this.erieForm.controls[8].value + " " + lastName,
      cmd_33977: this.erieForm.controls[8].value + " " + lastName,
      cmd_33982: this.erieForm.controls[8].value + " " + lastName,

      cmd_34040: this.erieForm.controls[8].value + " " + lastName,
      cmd_34045: this.erieForm.controls[8].value + " " + lastName,
      cmd_34048: this.erieForm.controls[8].value + " " + lastName,
      cmd_34053: this.erieForm.controls[8].value + " " + lastName,
      cmd_10203: this.erieForm.controls[8].value + " " + lastName,
      cmd_34046: this.erieForm.controls[8].value + " " + lastName,

      cmd_33834: lastName,
      cmd_33849: lastName,
      cmd_33877: lastName,
      cmd_33896: lastName,
      cmd_33908: lastName,
      cmd_33925: lastName,
      cmd_33949: lastName,
      cmd_33987: lastName,
      cmd_34062: lastName,
      cmd_34063: lastName,
    })

  }

  handleMiddlenamechange(middleName) {
    this.erieForm.patchValue({
      cmd_33836: middleName,
      cmd_33851: middleName,
      cmd_33879: middleName,
      cmd_33898: middleName,
      cmd_33910: middleName,
      cmd_33927: middleName,
      cmd_33986: middleName,
      cmd_34062: middleName,
      cmd_33948: middleName
    });
  }


  handleInvolvementData(value) {
    this.erieForm.patchValue({
      cmd_1218: value
    });
  }

  handlePriorNamechange(value) {

    this.erieForm.patchValue({
      cmd_34064: value,
    });
  }

  handleRacechange(value) {
    this.erieForm.patchValue({
      cmd_34070: value
    });

  }

  handleSsnchange(value) {
    this.erieForm.patchValue({
      cmd_33972: value,
      cmd_33983: value,
      cmd_34068: value,
    });

  }

  handleLicenseNumber(value) {
    this.erieForm.patchValue({
      cmd_34066: value
    });

  }

  handleLicenseState(value) {
    this.erieForm.patchValue({
      cmd_34067: value
    });

  }

  handleDOBChanges(value) {
    this.erieForm.patchValue({
      cmd_33980: value,
      cmd_33984: value,
      cmd_34065: value,
      cmd_33988: value,
      cmd_33971: value

    });

  }


  getLocationList() {

    let params: {}
    this.spinner.show();
    this.onboardService.geterielocations(params).subscribe(
      data => {
        if (data['success']) {

          this.locationList = data.data.map(i => ({
            ...i, location_id: i.location_id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  onChangeLocation1(locationId) {
    let params = {
      locationId: locationId
    }

    this.onboardService.getlocationdetails(params).subscribe(
      data => {
        if (data['success']) {

          let locationdata = data.data[0]
          this.erieForm.patchValue({
            cmd_33547: locationdata.practice_name,
            cmd_34037: locationdata.practice_name,
            cmd_33672: locationdata.practice_name,
            cmd_33548: locationdata.practice__add1 + " " + locationdata.practice__add2,
            cmd_33550: locationdata.county,
            cmd_33549: locationdata.practice__city,
            cmd_33551: +locationdata.practice__state,
            cmd_33552: locationdata.practice__zip,
            cmd_33553: locationdata.phone,
            cmd_33675: locationdata.practice_mailing_address_add1 + " " + locationdata.practice_mailing_address_add2,
            cmd_33556: locationdata.fax,
            cmd_33559: locationdata.answering_service_phone,

            cmd_33676: locationdata.pay_to_telephone,
            cmd_33674: locationdata.tin_id,
            cmd_34038: locationdata.tin_id,
            cmd_34039: locationdata.location_npi,
            cmd_33673: locationdata.typeofarrange,
            cmd_33554: locationdata.office_manager_first_name + " " + locationdata.office_manager_last_name,
            cmd_33574: locationdata.sunday,
            cmd_33568: locationdata.monday,
            cmd_33569: locationdata.tuesday,
            cmd_33570: locationdata.wednesday,
            cmd_33571: locationdata.thursday,
            cmd_33572: locationdata.friday,
            cmd_33573: locationdata.saturday
            // $('.append_bill_add').val(locationdata.pay_to__add1);
            // $('.append_bill_city2').val(locationdata.pay_to__city);
            // $('.append_bill_state2').val(locationdata.pay_to__state);
            // $('.append_bill_zip2').val(locationdata.pay_to__zip);
            // $('.append_bill_fax2').val(locationdata.pay_to_fax);
            // $('.append_bill_phone2').val(locationdata.pay_to_telephone);

          })
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }

      },
      error => {

      }
    )
  }


  onChangeLocation2(locationId) {

    let params = {
      locationId: locationId
    }
    this.spinner.show();
    this.onboardService.getlocationdetails(params).subscribe(
      data => {
        if (data['success']) {

          let locationdata2 = data.data[0]
          this.erieForm.patchValue({
            cmd_33693: locationdata2.practice_name,
            cmd_33814: locationdata2.practice_name,
            cmd_33694: locationdata2.practice__add1 + " " + locationdata2.practice__add2,
            cmd_33696: locationdata2.county,
            cmd_33695: locationdata2.practice__city,
            cmd_33697: +locationdata2.practice__state,
            cmd_33698: locationdata2.practice__zip,
            cmd_33699: locationdata2.phone,
            cmd_33702: locationdata2.fax,
            cmd_33705: locationdata2.answering_service_phone,
            cmd_33817: locationdata2.pay_to__add1,
            cmd_33818: locationdata2.pay_to_telephone,
            cmd_33816: locationdata2.tin_id,
            cmd_33815: locationdata2.typeofarrange,
            cmd_33720: locationdata2.sunday,
            cmd_33714: locationdata2.monday,
            cmd_33715: locationdata2.tuesday,
            cmd_33716: locationdata2.wednesday,
            cmd_33717: locationdata2.thursday,
            cmd_33718: locationdata2.friday,
            cmd_33719: locationdata2.saturday,
            cmd_33700: locationdata2.office_manager_first_name + " " + locationdata2.office_manager_last_name
            //       $('.append_prac_add12').val(locationdata2.practice_mailing_address_add1+" "+locationdata2.practice_mailing_address_add2);
            //       $('.append_bill_state21').val(locationdata2.pay_to__state);
            //       $('.append_bill_zip21').val(locationdata2.pay_to__zip);
            //       $('.append_bill_city21').val(locationdata2.pay_to__city);
            //       $('.append_bill_fax21').val(locationdata2.pay_to_fax);
            //       $('.append_npi_id_21').val(locationdata2.location_npi);
          });
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  saveForm(type) {

    this.isSubmit = true;
    console.log(this.erieForm);
    if (type == 'submit') {
      if (this.erieForm.invalid
        || (this.isUsCitizen && !this.erieForm.controls['cmd_33345'].value)
        || (this.islicenseUnlimited && !this.erieForm.controls['cmd_33347'].value)
        || (this.islicenseUnlimited1 && !this.erieForm.controls['cmd_33349'].value)
        || (this.islicenseUnlimited2 && !this.erieForm.controls['cmd_33351'].value)
        || (this.islicenseUnlimited3 && !this.erieForm.controls['cmd_33353'].value)
        || (this.islicenseUnlimited4 && !this.erieForm.controls['cmd_33356'].value)
        || (this.isboardCertifiedSpecialty && !this.erieForm.controls['554'].value)
        || (this.ischangeAction1 && !this.erieForm.controls['cmd_33408'].value)
        || (this.isStraightchange && !this.erieForm.controls['527'].value)
        || (this.ischangeAction2 && !this.erieForm.controls['cmd_33414'].value)
        || (this.ischangeAction3 && !this.erieForm.controls['cmd_33416'].value)
        || (this.ischangeAction3 && !this.erieForm.controls['cmd_33419'].value)
        || (this.ischangeAction5 && !this.erieForm.controls['cmd_33421'].value)
        || (this.ischangeAction6 && !this.erieForm.controls['cmd_33423'].value)
        || (this.ischangeAction7 && !this.erieForm.controls['cmd_33425'].value)
        || (this.ischangeAction8 && !this.erieForm.controls['cmd_33428'].value)
        || (this.ischangeAction9 && !this.erieForm.controls['cmd_33430'].value)
        || (this.ischangeAction10 && !this.erieForm.controls['cmd_33432'].value)
        || (this.ischangeAction11 && !this.erieForm.controls['cmd_33434'].value)
        || (this.ischangeAction12 && !this.erieForm.controls['cmd_33437'].value)
        || (this.ischangeAction13 && !this.erieForm.controls['cmd_33439'].value)
        || (this.ischangeAction14 && !this.erieForm.controls['cmd_33545'].value)
        || (this.ischangeAction15 && !this.erieForm.controls['cmd_33562'].value)
        || (this.ischangeAction16 && !this.erieForm.controls['cmd_33565'].value)
        || (this.ischangeAction17 && !this.erieForm.controls['cmd_33617'].value)
        || (this.ischangeAction18 && !this.erieForm.controls['cmd_33711'].value)
        || (this.ischangeAction20 && !this.erieForm.controls['561'].value)
        || (this.ischangeAction21 && !this.erieForm.controls['cmd_33708'].value)
      ) {

        this.notiService.showError('Please Fill the all mandatory fields to continue', '', 3000);

        if ((this.isUsCitizen && !this.erieForm.controls['cmd_33345'].value)) {
          this.erieForm.controls['cmd_33345'].setErrors({ errors: true });
        }

        if ((this.islicenseUnlimited && !this.erieForm.controls['cmd_33347'].value)) {
          this.erieForm.controls['cmd_33347'].setErrors({ errors: true });
        }

        if ((this.islicenseUnlimited1 && !this.erieForm.controls['cmd_33349'].value)) {
          this.erieForm.controls['cmd_33349'].setErrors({ errors: true });
        }

        if ((this.islicenseUnlimited2 && !this.erieForm.controls['cmd_33351'].value)) {
          this.erieForm.controls['cmd_33351'].setErrors({ errors: true });
        }
        if ((this.islicenseUnlimited3 && !this.erieForm.controls['cmd_33353'].value)) {
          this.erieForm.controls['cmd_33353'].setErrors({ errors: true });
        }

        if ((this.islicenseUnlimited4 && !this.erieForm.controls['cmd_33356'].value)) {
          this.erieForm.controls['cmd_33356'].setErrors({ errors: true });
        }

        if ((this.isboardCertifiedSpecialty && !this.erieForm.controls['554'].value)) {
          this.erieForm.controls['554'].setErrors({ errors: true });
        }

        if ((this.ischangeAction1 && !this.erieForm.controls['cmd_33408'].value)) {
          this.erieForm.controls['cmd_33408'].setErrors({ errors: true });
        }
        if ((this.isStraightchange && !this.erieForm.controls['527'].value)) {
          this.erieForm.controls['527'].setErrors({ errors: true });
        }

        if ((this.ischangeAction2 && !this.erieForm.controls['cmd_33414'].value)) {
          this.erieForm.controls['cmd_33414'].setErrors({ errors: true });
        }

        if ((this.ischangeAction3 && !this.erieForm.controls['cmd_33416'].value)) {
          this.erieForm.controls['cmd_33416'].setErrors({ errors: true });
        }


        if ((this.ischangeAction4 && !this.erieForm.controls['cmd_33419'].value)) {
          this.erieForm.controls['cmd_33419'].setErrors({ errors: true });
        }

        if ((this.ischangeAction5 && !this.erieForm.controls['cmd_33421'].value)) {
          this.erieForm.controls['cmd_33421'].setErrors({ errors: true });
        }

        if ((this.ischangeAction6 && !this.erieForm.controls['cmd_33423'].value)) {
          this.erieForm.controls['cmd_33423'].setErrors({ errors: true });
        }


        if ((this.ischangeAction7 && !this.erieForm.controls['cmd_33425'].value)) {
          this.erieForm.controls['cmd_33425'].setErrors({ errors: true });
        }

        if ((this.ischangeAction8 && !this.erieForm.controls['cmd_33428'].value)) {
          this.erieForm.controls['cmd_33428'].setErrors({ errors: true });
        }

        if ((this.ischangeAction9 && !this.erieForm.controls['cmd_33430'].value)) {
          this.erieForm.controls['cmd_33430'].setErrors({ errors: true });
        }


        if ((this.ischangeAction10 && !this.erieForm.controls['cmd_33432'].value)) {
          this.erieForm.controls['cmd_33432'].setErrors({ errors: true });
        }

        if ((this.ischangeAction11 && !this.erieForm.controls['cmd_33434'].value)) {
          this.erieForm.controls['cmd_33434'].setErrors({ errors: true });
        }

        if ((this.ischangeAction12 && !this.erieForm.controls['cmd_33437'].value)) {
          this.erieForm.controls['cmd_33419'].setErrors({ errors: true });
        }

        if ((this.ischangeAction13 && !this.erieForm.controls['cmd_33439'].value)) {
          this.erieForm.controls['cmd_33439'].setErrors({ errors: true });
        }

        if ((this.ischangeAction14 && !this.erieForm.controls['cmd_33545'].value)) {
          this.erieForm.controls['cmd_33545'].setErrors({ errors: true });
        }

        if ((this.ischangeAction15 && !this.erieForm.controls['cmd_33562'].value)) {
          this.erieForm.controls['cmd_33562'].setErrors({ errors: true });
        }

        if ((this.ischangeAction16 && !this.erieForm.controls['cmd_33565'].value)) {
          this.erieForm.controls['cmd_33565'].setErrors({ errors: true });
        }

        if ((this.ischangeAction17 && !this.erieForm.controls['cmd_33617'].value)) {
          this.erieForm.controls['cmd_33617'].setErrors({ errors: true });
        }


        if ((this.ischangeAction18 && !this.erieForm.controls['cmd_33711'].value)) {
          this.erieForm.controls['cmd_33711'].setErrors({ errors: true });
        }

        if ((this.ischangeAction20 && !this.erieForm.controls['561'].value)) {
          this.erieForm.controls['561'].setErrors({ errors: true });
        }

        if ((this.ischangeAction21 && !this.erieForm.controls['cmd_33708'].value)) {
          this.erieForm.controls['cmd_33708'].setErrors({ errors: true });
        }

        let invalid = [];
        let controls = this.erieForm.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        // console.log(invalid);
        return invalid;
        return;
      }

      let data = {
        formType: type,
        formValues: this.erieForm.value
      }
      this.onSubmit.emit(data);

    } else {
      let data = {
        formType: type,
        formValues: this.erieForm.value,
        formId: 28
      }
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }

  }

  onUsCitizen(event) {

    if (event == 1) {
      this.isUsCitizen = true
      if (!this.erieForm.controls['cmd_33345'].value) {
        this.erieForm.controls['cmd_33345'].setErrors({ errors: true });
      }
    } else {
      this.isUsCitizen = false;
      this.erieForm.controls['cmd_33345'].setErrors(null);
    }

  }


  onLicenseUnlimited(event) {

    if (event == 1) {
      this.islicenseUnlimited = true
      if (!this.erieForm.controls['cmd_33347'].value) {
        this.erieForm.controls['cmd_33347'].setErrors({ errors: true });
      }

    } else {
      this.islicenseUnlimited = false;
      this.erieForm.controls['cmd_33347'].setErrors(null);
    }

  }

  onLicenseUnlimited1(event) {

    if (event == 1) {
      this.islicenseUnlimited1 = true
      if (!this.erieForm.controls['cmd_33349'].value) {
        this.erieForm.controls['cmd_33349'].setErrors({ errors: true });
      }

    } else {
      this.islicenseUnlimited1 = false;
      this.erieForm.controls['cmd_33349'].setErrors(null);
    }

  }

  onLicenseUnlimited2(event) {

    if (event == 1) {
      this.islicenseUnlimited2 = true
      if (!this.erieForm.controls['cmd_33351'].value) {
        this.erieForm.controls['cmd_33351'].setErrors({ errors: true });
      }

    } else {
      this.islicenseUnlimited2 = false;
      this.erieForm.controls['cmd_33351'].setErrors(null);
    }

  }

  onLicenseUnlimited3(event) {

    if (event == 1) {
      this.islicenseUnlimited3 = true
      if (!this.erieForm.controls['cmd_33353'].value) {
        this.erieForm.controls['cmd_33353'].setErrors({ errors: true });
      }

    } else {
      this.islicenseUnlimited3 = false;
      this.erieForm.controls['cmd_33353'].setErrors(null);
    }

  }

  onLicenseUnlimited4(event) {

    if (event == 1) {
      this.islicenseUnlimited4 = true
      if (!this.erieForm.controls['cmd_33356'].value) {
        this.erieForm.controls['cmd_33356'].setErrors({ errors: true });
      }

    } else {
      this.islicenseUnlimited4 = false;
      this.erieForm.controls['cmd_33356'].setErrors(null);
    }

  }

  onboardCertifiedSpecialty(event) {

    if (event == 1) {
      this.isboardCertifiedSpecialty = true
      if (!this.erieForm.controls['554'].value) {
        this.erieForm.controls['554'].setErrors({ errors: true });
      }

    } else {
      this.isboardCertifiedSpecialty = false;
      this.erieForm.controls['554'].setErrors(null);
    }

  }

  onchangeaction1(event) {

    if (event == 1) {
      this.ischangeAction1 = true
      if (!this.erieForm.controls['cmd_33408'].value) {
        this.erieForm.controls['cmd_33408'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction1 = false;
      this.erieForm.controls['cmd_33408'].setErrors(null);
    }

  }

  onstraightchange(event) {

    if (event == 1) {
      this.isStraightchange = true
      if (!this.erieForm.controls['527'].value) {
        this.erieForm.controls['527'].setErrors({ errors: true });
      }

    } else {
      this.isStraightchange = false;
      this.erieForm.controls['527'].setErrors(null);
    }

  }


  onchangeaction2(event) {

    if (event == 1) {
      this.ischangeAction2 = true
      if (!this.erieForm.controls['cmd_33414'].value) {
        this.erieForm.controls['cmd_33414'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction2 = false;
      this.erieForm.controls['cmd_33414'].setErrors(null);
    }

  }

  onchangeaction3(event) {

    if (event == 1) {
      this.ischangeAction3 = true
      if (!this.erieForm.controls['cmd_33416'].value) {
        this.erieForm.controls['cmd_33416'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction3 = false;
      this.erieForm.controls['cmd_33416'].setErrors(null);
    }

  }

  onchangeaction4(event) {

    if (event == 1) {
      this.ischangeAction4 = true
      if (!this.erieForm.controls['cmd_33419'].value) {
        this.erieForm.controls['cmd_33419'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction4 = false;
      this.erieForm.controls['cmd_33419'].setErrors(null);
    }

  }


  onchangeaction5(event) {

    if (event == 1) {
      this.ischangeAction5 = true
      if (!this.erieForm.controls['cmd_33421'].value) {
        this.erieForm.controls['cmd_33421'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction5 = false;
      this.erieForm.controls['cmd_33421'].setErrors(null);
    }
  }

  onchangeaction6(event) {

    if (event == 1) {
      this.ischangeAction6 = true
      if (!this.erieForm.controls['cmd_33423'].value) {
        this.erieForm.controls['cmd_33423'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction6 = false;
      this.erieForm.controls['cmd_33423'].setErrors(null);
    }

  }

  onchangeaction7(event) {

    if (event == 1) {
      this.ischangeAction7 = true
      if (!this.erieForm.controls['cmd_33425'].value) {
        this.erieForm.controls['cmd_33425'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction7 = false;
      this.erieForm.controls['cmd_33425'].setErrors(null);
    }
  }

  onchangeaction8(event) {

    if (event == 1) {
      this.ischangeAction8 = true
      if (!this.erieForm.controls['cmd_33428'].value) {
        this.erieForm.controls['cmd_33428'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction8 = false;
      this.erieForm.controls['cmd_33428'].setErrors(null);
    }
  }

  onchangeaction9(event) {

    if (event == 1) {
      this.ischangeAction9 = true
      if (!this.erieForm.controls['cmd_33430'].value) {
        this.erieForm.controls['cmd_33430'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction9 = false;
      this.erieForm.controls['cmd_33430'].setErrors(null);
    }
  }

  onchangeaction10(event) {

    if (event == 1) {
      this.ischangeAction10 = true
      if (!this.erieForm.controls['cmd_33432'].value) {
        this.erieForm.controls['cmd_33432'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction10 = false;
      this.erieForm.controls['cmd_33432'].setErrors(null);
    }

  }

  onchangeaction11(event) {

    if (event == 1) {
      this.ischangeAction11 = true
      if (!this.erieForm.controls['cmd_33434'].value) {
        this.erieForm.controls['cmd_33434'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction11 = false;
      this.erieForm.controls['cmd_33434'].setErrors(null);
    }
  }

  onchangeaction12(event) {

    if (event == 1) {
      this.ischangeAction12 = true
      if (!this.erieForm.controls['cmd_33437'].value) {
        this.erieForm.controls['cmd_33437'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction12 = false;
      this.erieForm.controls['cmd_33437'].setErrors(null);
    }
  }

  onchangeaction13(event) {

    if (event == 1) {
      this.ischangeAction13 = true
      if (!this.erieForm.controls['cmd_33439'].value) {
        this.erieForm.controls['cmd_33439'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction13 = false;
      this.erieForm.controls['cmd_33439'].setErrors(null);
    }

  }

  onchangeaction14(event) {

    if (event == 1) {
      this.ischangeAction14 = true
      if (!this.erieForm.controls['cmd_33545'].value) {
        this.erieForm.controls['cmd_33545'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction14 = false;
      this.erieForm.controls['cmd_33545'].setErrors(null);
    }
  }

  onchangeaction15(event) {

    if (event == 1) {
      this.ischangeAction15 = true
      if (!this.erieForm.controls['cmd_33562'].value) {
        this.erieForm.controls['cmd_33562'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction15 = false;
      this.erieForm.controls['cmd_33562'].setErrors(null);
    }
  }

  onchangeaction16(event) {

    if (event == 1) {
      this.ischangeAction16 = true
      if (!this.erieForm.controls['cmd_33565'].value) {
        this.erieForm.controls['cmd_33565'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction16 = false;
      this.erieForm.controls['cmd_33565'].setErrors(null);
    }
  }

  onchangeaction17(event) {

    if (event == 1) {
      this.ischangeAction17 = true
      if (!this.erieForm.controls['cmd_33617'].value) {
        this.erieForm.controls['cmd_33617'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction17 = false;
      this.erieForm.controls['cmd_33617'].setErrors(null);
    }
  }

  onchangeaction18(event) {

    if (event == 1) {
      this.ischangeAction18 = true
      if (!this.erieForm.controls['cmd_33711'].value) {
        this.erieForm.controls['cmd_33711'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction18 = false;
      this.erieForm.controls['cmd_33711'].setErrors(null);
    }
  }

  onchangeaction19(event) {

    if (event == 1) {
      this.ischangeAction19 = true
      if (!this.erieForm.controls['cmd_34107'].value) {
        this.erieForm.controls['cmd_34107'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction19 = false;
      this.erieForm.controls['cmd_34107'].setErrors(null);
    }
  }

  onchangeaction20(event) {

    if (event == 1) {
      this.ischangeAction20 = true
      if (!this.erieForm.controls['561'].value) {
        this.erieForm.controls['561'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction20 = false;
      this.erieForm.controls['561'].setErrors(null);
    }
  }

  onchangeaction21(event) {

    if (event == 1) {
      this.ischangeAction21 = true
      if (!this.erieForm.controls['cmd_33708'].value) {
        this.erieForm.controls['cmd_33708'].setErrors({ errors: true });
      }

    } else {
      this.ischangeAction21 = false;
      this.erieForm.controls['cmd_33708'].setErrors(null);
    }
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_9000350') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000351') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000352') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000353') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000354') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000355') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000356') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000357') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000358') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000359') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000360') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000361') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000362') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000363') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000364') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000365') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000366') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000367') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000368') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000369') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000370') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_9000371') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }

      // if (array[i].field_name == 'cmd_2500036') {
      //   if (array[i].field_value == 1) {
      //     this.changeCmdOne = 1;
      //   } else {
      //     this.changeCmdOne = 0;
      //   }
      // }
    }
    this.erieForm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          let ImgOne = data.base64;
          this.setEsign(ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {

    if (fieldId == 'cmd_9000350') {
      this.esign1 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show1 = true;
    }
    else if (fieldId == 'cmd_9000351') {
      this.esign2 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show2 = true;
    } else if (fieldId == 'cmd_9000352') {
      this.esign3 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show3 = true;
    }
    else if (fieldId == 'cmd_9000353') {
      this.esign4 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show4 = true;
    }
    else if (fieldId == 'cmd_9000354') {
      this.esign5 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show5 = true;
    }
    else if (fieldId == 'cmd_9000355') {
      this.esign6 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show6 = true;
    }
    else if (fieldId == 'cmd_9000356') {
      this.esign7 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show7 = true;
    }
    else if (fieldId == 'cmd_9000357') {
      this.esign8 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show8 = true;
    }
    else if (fieldId == 'cmd_9000358') {
      this.esign9 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show9 = true;
    }
    else if (fieldId == 'cmd_9000359') {
      this.esign10 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show10 = true;
    }
    else if (fieldId == 'cmd_9000360') {
      this.esign11 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show11 = true;
    }
    else if (fieldId == 'cmd_9000361') {
      this.esign12 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show12 = true;
    }
    else if (fieldId == 'cmd_9000362') {
      this.esign13 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show13 = true;
    } else if (fieldId == 'cmd_9000363') {
      this.esign14 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show14 = true;
    }
    else if (fieldId == 'cmd_9000364') {
      this.esign15 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show15 = true;
    }
    else if (fieldId == 'cmd_9000365') {
      this.esign16 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show16 = true;
    }
    else if (fieldId == 'cmd_9000366') {
      this.esign17 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show17 = true;
    } else if (fieldId == 'cmd_9000367') {
      this.esign18 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show18 = true;
    }
    else if (fieldId == 'cmd_9000368') {
      this.esign19 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show19 = true;
    }
    else if (fieldId == 'cmd_9000369') {
      this.esign20 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show20 = true;
    }
    else if (fieldId == 'cmd_9000370') {
      this.esign21 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show21 = true;
    }
    else if (fieldId == 'cmd_9000371') {
      this.esign22 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + filePath);
      this.show22 = true;
    }
  }



  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.CdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalCdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.AddCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.erieForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    // if(handleYesNoValue ){
    //   if(handleYesNoValue == ("cmd_05" || "cmd_024" || "cmd_023")){
    //     this.handleYesNoChanges(handleYesNoValue);
    //   }
    //   else{
    //       this.handleNAchange(handleYesNoValue);
    //   }
      
      
    // }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.erieForm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    console.log(this.erieForm.get('31').errors?.invalidDateFormat);
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

}
