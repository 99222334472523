<div id="cancercare">
    <form [formGroup]="CancerCareNorthwestForms">
        <div class="text-center">
             <h2>Washington Practitioner Application </h2>
        </div> 
            <table width="100%" class="center">
                <tr >
                    <td colspan="12">
                        
                            <b class="sideheading">To use the Washington Practitioner Application (WPA), follow these instructions:</b>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div>
                            <ol >
                                <li class="dot"><b>Keep an <u>unsigned</u> and <u>undated</u> copy of the application on file for future requests.</b> When a request is received, send a copy of the completed application, making sure that all information is complete, current and accurate. </li>
                                <li class="dot">Please sign and date pages 11 and 13.</li>
                                <li class="dot">Please document any YES responses on the Attestation Question page.</li>
                                <li class="dot">Identify the health care related organization(s) to which this application is being submitted in the space provided below. </li>
                                <li class="dot">Attach copies of requested documents each time the application is submitted.</li>
                                <li class="dot">If changes must be made to the completed application, strike out the information and write in the modification, initial and date. </li>
                                <li class="dot">If a section does not apply to you, please check the provided box at the top of the section.</li>
                                <li class="dot">Expect addendums from the requesting organizations for information not included on the WPA.</li>
                            </ol>
                        </div>
                    </td>
                </tr>
            </table>     
            <table border="2" width="100%" class="center" > 
                <tr >
                    <td colspan="12">
                        <div >                
                                <label class="formLabel" class="formLabel">This application is submitted to:</label> 
                                <input type="text" id="cmd_373" class="form-control" formControlName="cmd_373" class="form-control">                    
                        </div>
                    </td>
                </tr>
            </table> <br> 
            <table border="1" width="100%" class="center" >    
                <tr>
                    <th colspan="12">
                        
                            <label class="sideheading"  >
                                <h6>1. INSTRUCTIONS</h6> </label>
                    </th>
                </tr>
                <tr>
                    <td colspan="12">

                            <label class="formLabel">This form should be&nbsp;<b>typed or legibly printed in black or blue ink.&nbsp;</b>If more space is needed than provided on original, attach additional sheets and reference the question being answered. <i><u>Please do not use
                                    abbreviations.</u></i><b>&nbsp;Current copies of the following documents must be submitted with this
                                    application:&nbsp;</b>(all are required for MDs, DOs; as applicable for other health practitioners).</label>
                            <div>
                                <ul>
                                    <li>DEA Certificate.</li>
                                </ul>
                                <ul>
                                    <li>Face Sheet of Professional Liability Policy or Certificate.</li>
                                </ul>
                                <ul>
                                    <li>Curriculum Vitae (Not an acceptable substitute for completing the application. Dates need to be listed in mm/yyyy Format). </li>
                                </ul>
                            </div>
                            <div>
                                <h6 style="text-align:center"><b>** All sections must be completed in their entirety. **</b></h6> </div>
                        
                    </td>
                </tr>
            </table><br>
            <table border="1" width="100%" class="center" >    
                <tr>
                    <th colspan="12">
                        
                            <label class="sideheading"  >
                                <h6>2. PRACTITIONER INFORMATION – Legal Name Required</h6> </label>
                    </th>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" >Last Name:(include suffix; Jr., Sr., III):</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Last Name" id="9" formControlName="9" (change)="onChangeLastName($event)" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['9'].touched && CancerCareNorthwestForms.controls['9'].invalid) || (CancerCareNorthwestForms.controls['9'].invalid && isSubmit)">
                                <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms.controls['9'].touched && CancerCareNorthwestForms.controls['9'].invalid">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['9'].errors?.required">
                                            required
                                        </small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <ng-select [items]="suffix" bindLabel="name"  [class.is-invalid]="(CancerCareNorthwestForms.controls['29'].touched && CancerCareNorthwestForms.controls['29'].invalid) || (CancerCareNorthwestForms.controls['29'].invalid && isSubmit)"placeholder="Suffix" bindValue="id" formControlName="29"> </ng-select>
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['29'].touched && CancerCareNorthwestForms.controls['29'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['29'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="8">First:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter First Name" id="8" formControlName="8" (change)="onChangeFirstName($event)" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['8'].touched && CancerCareNorthwestForms.controls['8'].invalid ) || (CancerCareNorthwestForms.controls['8'].invalid && isSubmit)">
                                    <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms.controls['8'].touched && CancerCareNorthwestForms.controls['8'].invalid">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['8'].errors?.required">
                                            required
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="8">Middle:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Middle Name" id="809" formControlName="809" (change)="onChangeMiddleName($event)" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['809'].touched && CancerCareNorthwestForms.controls['809'].invalid ) || (CancerCareNorthwestForms.controls['809'].invalid && isSubmit)">
                                    <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms.controls['809'].touched && CancerCareNorthwestForms.controls['809'].invalid">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['809'].errors?.required">
                                            required
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="8">Degree(s):</label>
                            </div>
                            <div class="col-md-12">
                                <ng-select [items]="credentials" bindLabel="name"  [class.is-invalid]="(CancerCareNorthwestForms.controls['822'].touched && CancerCareNorthwestForms.controls['822'].invalid) || (CancerCareNorthwestForms.controls['822'].invalid && isSubmit)"placeholder="Credentials" bindValue="id" formControlName="822"> </ng-select>
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['822'].touched && CancerCareNorthwestForms.controls['822'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['822'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>    
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="8">List any other name(s) under which you have been known by reference, licensing and or educational institutions, including the
                                    date of name change(s) if known (mm/dd/yyyy):</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Prior Name" [class.is-invalid]="(CancerCareNorthwestForms.controls['278'].touched && CancerCareNorthwestForms.controls['278'].invalid) || (CancerCareNorthwestForms.controls['278'].invalid && isSubmit)" formControlName="278" id="278" class="form-control">
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['278'].touched && CancerCareNorthwestForms.controls['278'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['278'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d0379="ngbDatepicker"  (blur)="clearInputIfInvalidMandi('cmd_0379')" placeholder="mm/dd/yyyy" id="cmd_0379" class="form-control"  [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_0379'].touched && CancerCareNorthwestForms.controls['cmd_0379'].invalid) || (CancerCareNorthwestForms.controls['cmd_0379'].invalid && isSubmit)" formControlName="cmd_0379">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d0379.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="(CancerCareNorthwestForms.controls['cmd_0379'].invalid&&CancerCareNorthwestForms.controls['cmd_0379'].touched) && (CancerCareNorthwestForms.controls['cmd_0379'].value==''|| CancerCareNorthwestForms.controls['cmd_0379'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_0379').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_0379').errors?.invalidMonth">
                                Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_0379').errors?.invalidDateyear">
                                year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_0379').errors?.invalidDateRange">
                                date is out of range
                            </small>
                            </div>  
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" >Home Mailing Address:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Home Address 1 " [class.is-invalid]="(CancerCareNorthwestForms.controls['16'].touched && CancerCareNorthwestForms.controls['16'].invalid) || (CancerCareNorthwestForms.controls['16'].invalid && isSubmit)" formControlName="16" id="16" class="form-control">
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['16'].touched && CancerCareNorthwestForms.controls['16'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['16'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Home Address 2 " formControlName="17" id="17" class="form-control">
                                
                            </div>
                        </div>    
                    </td>
                
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">City:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Home City "  [class.is-invalid]="(CancerCareNorthwestForms.controls['18'].touched && CancerCareNorthwestForms.controls['18'].invalid) || (CancerCareNorthwestForms.controls['18'].invalid && isSubmit)" formControlName="18" id="18" class="form-control">
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['18'].touched && CancerCareNorthwestForms.controls['18'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['18'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            
                        </div>    
                    </td>
                
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" >State:</label>
                            </div>
                            <div class="col-md-12 ">
                                <ng-select [items]="states" [class.is-invalid]="(CancerCareNorthwestForms.controls['20'].touched &&
                                    CancerCareNorthwestForms.controls['20'].invalid) ||
                                    (CancerCareNorthwestForms.controls['20'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="Home State" bindValue="id" formControlName="20"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['20'].touched && CancerCareNorthwestForms.controls['20'].invalid) || (CancerCareNorthwestForms.controls['20'].invalid && isSubmit)">
                                    required  </div>
                            </div>
                            
                        </div>    
                    </td>
                
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" >Zip Code:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Home Zip" [class.is-invalid]="(CancerCareNorthwestForms.controls['21'].touched && CancerCareNorthwestForms.controls['21'].invalid) || (CancerCareNorthwestForms.controls['21'].invalid && isSubmit)" formControlName="21" id="21" class="form-control">
                                <div class="formLabel col-md-2"
                                    *ngIf="CancerCareNorthwestForms.controls['21'].touched && CancerCareNorthwestForms.controls['21'].invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['21'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>                        
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Home Telephone Number:</label>
                            </div>

                            <div class="col-md-12">
                                <input type="text" placeholder="Home Phone " id="708" formControlName="708"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['708'].touched && CancerCareNorthwestForms.controls['708'].invalid ) || (CancerCareNorthwestForms.controls['708'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['708'].touched && CancerCareNorthwestForms.controls['708'].invalid) || (CancerCareNorthwestForms.controls['708'].invalid && isSubmit)">
                                    Cell Phone is required and cannot be empty 
                                </div>
                            </div>

                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Pager Number:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Pager"  id="1017" formControlName="1017"
                                    class="form-control">
                                 
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12 ">
                                <label class="formLabel" class="formLabel">Cell Number:</label>
                            </div>
                            <div class="col-md-12  ">
                                <input type="text" placeholder="Mobile Phone " id="709"
                                    formControlName="709" class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['709'].touched && CancerCareNorthwestForms.controls['709'].invalid ) || (CancerCareNorthwestForms.controls['709'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['709'].touched && CancerCareNorthwestForms.controls['709'].invalid) || (CancerCareNorthwestForms.controls['709'].invalid && isSubmit)">
                                    Cell Phone is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">E-Mail Address:</label>
                            </div>
                            <div class="col-md-12  ">
                                <input type="text" placeholder="E-Mail " id="759" formControlName="759"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['759'].touched && CancerCareNorthwestForms.controls['759'].invalid ) || (CancerCareNorthwestForms.controls['759'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['759'].touched && CancerCareNorthwestForms.controls['759'].invalid) || (CancerCareNorthwestForms.controls['759'].invalid && isSubmit)">
                                    E-Mail Address is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Birth Date(mm/dd/yy):</label>
                            </div>
                            <div class="col-md-8">
                                <div class="input-group">
                                <input type="text" placeholder="MM/DD/YYYY" id="31" formControlName="31" (blur)="clearInputIfInvalidMandi('31')"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh01="ngbDatepicker"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['31'].touched && CancerCareNorthwestForms.controls['31'].invalid ) || (CancerCareNorthwestForms.controls['31'].invalid && isSubmit)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh01.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                    *ngIf="(CancerCareNorthwestForms.controls['31'].invalid&&CancerCareNorthwestForms.controls['31'].touched) && (CancerCareNorthwestForms.controls['31'].value==''|| CancerCareNorthwestForms.controls['31'].value==null) ">
                                    <small>
                                        Birth Date is required and cannot be empty 
                                    </small>
                                
                                </div>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('31').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('31').errors?.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('31').errors?.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('31').errors?.invalidDateRange">
                                    date is out of range
                                </small>
                               
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12 ">
                                <label class="formLabel" class="formLabel" for="810">Birth Place:  </label>
                            </div>
                            <div class="col-md-3 ">
                                <label class="formLabel" class="formLabel" for="810">City:</label>
                            </div>
                            <div class="col-md-8 ">
                                <input type="text" placeholder="City of Birth " id="810" formControlName="810"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['810'].touched && CancerCareNorthwestForms.controls['810'].invalid ) || (CancerCareNorthwestForms.controls['810'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['810'].touched && CancerCareNorthwestForms.controls['810'].invalid) || (CancerCareNorthwestForms.controls['810'].invalid && isSubmit)">
                                    Birth Place is required and cannot be empty </div>
                            </div>
                            <div class="col-md-3 ">
                                <label class="formLabel" class="formLabel" for="289">State:</label>
                            </div>
                            <div class="col-md-8 ">
                                <ng-select [items]="states" [class.is-invalid]="(CancerCareNorthwestForms.controls['289'].touched &&
                                    CancerCareNorthwestForms.controls['289'].invalid) ||
                                    (CancerCareNorthwestForms.controls['289'].invalid && isSubmit)" bindLabel="name"
                                    placeholder="Birth State" bindValue="id" formControlName="289"> </ng-select>
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['289'].touched && CancerCareNorthwestForms.controls['289'].invalid) || (CancerCareNorthwestForms.controls['289'].invalid && isSubmit)">
                                    State is required and cannot be empty </div>
                            </div>
                            <div class="col-md-3 ">
                                <label class="formLabel" class="formLabel" for="291">Country:</label>
                            </div>
                            <div class="col-md-8 ">
                                <input type="text" placeholder="Country of Birth" id="291" formControlName="291"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['291'].touched && CancerCareNorthwestForms.controls['291'].invalid ) || (CancerCareNorthwestForms.controls['291'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['291'].touched && CancerCareNorthwestForms.controls['291'].invalid) || (CancerCareNorthwestForms.controls['291'].invalid && isSubmit)">
                                    Birth Place is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="292">Citizenship:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Citizenship" id="292" formControlName="292"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['292'].touched && CancerCareNorthwestForms.controls['292'].invalid ) || (CancerCareNorthwestForms.controls['292'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['292'].touched && CancerCareNorthwestForms.controls['292'].invalid) || (CancerCareNorthwestForms.controls['292'].invalid && isSubmit)">
                                    CitizenShip is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="292">Race/Ethnicity:</label>
                            </div>
                            <div class="col-md-12">
                                <ng-select [items]="race" bindLabel="name" placeholder="Select Race" bindValue="id"
                                                
                                                formControlName="741">
                                                </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                    
                        <div class="row">
                            <div class="col-md-12 ">
                                <label class="formLabel" class="formLabel" for="32">Social Security Number:</label>
                            </div>
                            <div class="col-md-12 ">
                                <input type="Text" placeholder="SSN" id="32"
                                    formControlName="32" class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['32'].touched && CancerCareNorthwestForms.controls['32'].invalid ) || (CancerCareNorthwestForms.controls['32'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['32'].touched && CancerCareNorthwestForms.controls['32'].invalid) || (CancerCareNorthwestForms.controls['32'].invalid && isSubmit)">
                                    SSN Number is required and cannot be empty </div>
                            </div>
                        </div>
                        
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="30">Gender:</label>
                            </div>
                            <div class="col-md-8">
                                <ng-select [items]="gender"  bindLabel="name"
                                    placeholder="Select Gender" bindValue="id" formControlName="30"> </ng-select>


                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" >Languages Fluently Spoken by Practitioner:</label>
                            </div>
                            <div class="col-md-6">
                                <ng-select [items]="languages"  bindLabel="name"
                                    placeholder="Primary Language" bindValue="id" formControlName="277">
                                </ng-select>

                            </div>
                            <div class="col-md-6">
                                <ng-select [items]="secondaryLanguages"  bindLabel="name"
                                    placeholder=" Secondary Language" bindValue="id" formControlName="1535">
                                </ng-select>
                                
                            </div>
                            <div class="col-md-6">
                                <ng-select [items]="tertiaryLanguages"  bindLabel="name"
                                    placeholder=" Teritary Language" bindValue="id" formControlName="1536">
                                </ng-select>
                                
                            </div>
                            <div class="col-md-6">
                                <ng-select [items]="quaternaryLanguages" bindLabel="name"
                                    placeholder=" Quaternary Language" bindValue="id" formControlName="2207">
                                </ng-select>
                                
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-5">
                                <label class="formLabel" class="formLabel">Have you ever voluntarily opted-out of Medicare? </label> 
                            </div>   
                            <div class="col-md-1">
                                <label class="formLabel" class="checkbox-inline">
                                    <input type="radio" id="cmd_01780" name="cmd_01780"  formControlName="cmd_01780" value="1">Yes</label>
                            </div>
                            <div class="col-md-1">       
                                    <label class="formLabel" class="checkbox-inline">
                                    <input type="radio" id="cmd_01780" name="cmd_01780"  formControlName="cmd_01780" value="0">No  </label>
                                    
                                    
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="1634">NPI: </label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="NPI " id="1634" formControlName="1634"
                                    class="form-control"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['1634'].touched && CancerCareNorthwestForms.controls['1634'].invalid ) || (CancerCareNorthwestForms.controls['1634'].invalid && isSubmit)">
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['1634'].touched && CancerCareNorthwestForms.controls['1634'].invalid) || (CancerCareNorthwestForms.controls['1634'].invalid && isSubmit)">
                                    NPI Number is required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel" for="325">Medicare Number(WA):</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Medicare Number (PTAN) " id="325"
                                    formControlName="325"  class="form-control">

                            </div>
                        </div> 
                    </td>
                    <td colspan="3">
                        <div class="row">
                            
                                <div class="col-md-12">
                                    <label class="formLabel" class="formLabel">Medicaid (DSHS) Number(s):</label>
                                </div>
                                <div class="col-md-12">
                                    <input type="text" placeholder=" Medicaid  Number " id="812"
                                        formControlName="812"  class="form-control">

                                </div>
                            
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="row">
                            
                                <div class="col-md-12">
                                    <label class="formLabel" class="formLabel">L & I Number(s):</label>
                                </div>
                                <div class="col-md-12">
                                    <input type="text" placeholder=" L & I Number(s)" id="cmd_01816"
                                        formControlName="cmd_01816"  class="form-control">

                                </div>
                            
                        </div>
                    </td>
                
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Specialty primarily practicing:</label>
                            </div>
                            <div class="col-md-12">
                                <ng-select [items]="specialty" [class.is-invalid]="(CancerCareNorthwestForms.controls['284'].touched &&
                                                        CancerCareNorthwestForms.controls['284'].invalid) ||
                                                        (CancerCareNorthwestForms.controls['284'].invalid && isSubmit)" bindLabel="name"
                                                        placeholder="Primary Specialty" bindValue="id" formControlName="284">
                                                    </ng-select>
                                <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['284'].touched && CancerCareNorthwestForms.controls['284'].invalid) || (CancerCareNorthwestForms.controls['284'].invalid && isSubmit)">
                                        required 
                                </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">                
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Sub specialties primarily practicing:</label>
                            </div>
                            <div class="col-md-12">
                                <ng-select [items]="specialtySecondary"  bindLabel="name"
                                                        placeholder="Secondary Specialty" bindValue="id" formControlName="962">
                                                    
                                </ng-select>
                                
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">                
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Other Professional Interests in Practice, Research, etc.:</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text"  id="cmd_11806"
                                        formControlName="cmd_11806"  class="form-control">
                                    
                            </div>
                        </div>    
                    </td>
                </tr>                       
            </table>  
            <br>
            <table border="1" width="100%" class="center" >    
            <tr>
                <th colspan="12">   
                    <div class="row"> 
                        <div class="col-md-3">                         
                            <h6>3. PRIMARY PRACTICE INFORMATION</h6>
                        </div>
                        <div class="col-md-3">      
                            <b>&nbsp;&nbsp;&nbsp;&nbsp; Practitioner Start Date (MM/YYYY):</b>
                        </div>     
                        <div class="col-md-2">
                            <div class="input-group">
                                <input
                                class="form-control onboard"
                                ngbDatepicker
                                #d551806="ngbDatepicker"
                                id="cmd_551806"
                                name="cmd_551806"
                                formControlName="cmd_551806"
                                (blur)="clearInputIfInvalid('cmd_551806')" 
                                type="text"
                                data-id="dob_date"
                                />
                                <div class="input-group-append">
                                    <button
                                    class="btn btn-outline-secondary calendar"
                                    (click)="d551806.toggle()"
                                    type="button"
                                    >
                                    <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                            </div>    
                            <div *ngIf="CancerCareNorthwestForms.get('cmd_551806').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_551806').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_551806').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_551806').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_551806').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                        <div class="col-md-3">      
                            <b>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; CHECK ALL THAT APPLY</b>
                        </div>
                    </div>     
                </th>
            </tr>
            <tr>
                <td colspan="12">
                    
                    <div class="col-md-12">
                        <label class="formLabel"><b>Practice Setting</b></label>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="padding-left:60px;">
                                <input type="Checkbox" value="Clinic/Group " formControlName="cmd_01" id="cmd_01">
                                <label class="formLabel" class="formLabel" for="cmd_02">Clinic/Group</label>&nbsp;&nbsp;
                                <input class="formLabel" type="Checkbox" value="SoloPractice " formControlName="cmd_02" id="cmd_02">
                                <label class="formLabel" class="formLabel" for="cmd_03 ">Solo Practice </label>&nbsp;&nbsp;
                                <input type="Checkbox" value="HomeBased" formControlName="cmd_03" id="cmd_03">
                                <label class="formLabel" class="formLabel" for="cmd_04 ">Home Based</label>&nbsp;&nbsp;
                                <input type="Checkbox" value="HospitalBased" formControlName="cmd_04" id="cmd_04">
                                <label class="formLabel" class="formLabel" for="cmd_05">Hospital Based</label>&nbsp;&nbsp;
                                <input type="Checkbox" value="PrimaryCareSite" formControlName="cmd_05" id="cmd_05">
                                <label class="formLabel" class="formLabel" for="cmd_06">Primary Care Site</label>&nbsp;&nbsp;
                                <input type="Checkbox" value="UrgentCare" formControlName="cmd_06" id="cmd_06">
                                <label class="formLabel" class="formLabel" for="cmd_07">Urgent Care</label>&nbsp;&nbsp;
                                <input type="Checkbox" value="Other" formControlName="cmd_07" id="cmd_07">&nbsp;&nbsp;
                                <label class="formLabel" class="formLabel" for="Other">Other</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Practitioner Profile</label>
                    </div>
                    <div class="col-md-12" style="padding-left: 40px;">                        
                        <input type="Checkbox" value="PCP" formControlName="cmd_08" id="cmd_08">
                        <label class="formLabel" for="cmd_09">PCP</label>&nbsp;&nbsp;
                        <input type="Checkbox" value="Specialist" formControlName="cmd_09" id="cmd_09">
                        <label class="formLabel" for="cmd_10 ">Specialist </label>&nbsp;&nbsp;
                        <input type="Checkbox" value="PCBOB" formControlName="cmd_10" id="cmd_10">
                        <label class="formLabel" for="PCBOB">Check if you are both PCP & OB</label>&nbsp;&nbsp;
                        <label class="formLabel" for="cmd_11">OB in your practice</label>&nbsp;&nbsp;
                        <input type="radio" value="yes" name="cmd_11" formControlName="cmd_11" id="cmd_11">
                        <label class="formLabel" for="cmd_11">Yes</label>&nbsp;&nbsp;
                        <input type="radio" value="No" name="cmd_11" formControlName="cmd_11" id="cmd_11">
                        <label class="formLabel" for="cmd_13">No</label>&nbsp;&nbsp;
                        <label class="formLabel" for="Deliveries">Deliveries</label>&nbsp;&nbsp;
                        <input type="radio" value="yes" name="cmd_13" formControlName="cmd_13" id="cmd_13">
                        <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                        <input type="radio" value="No" name="cmd_13" formControlName="cmd_13" id="cmd_13">
                        <label class="formLabel" for="No">No</label>&nbsp;&nbsp; 
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-12">
                        <label class="formLabel" for="Deliveries">Do you offer Telehealth?</label>&nbsp;&nbsp;
                        <input type="radio" value="yes" name="cmd_0013" formControlName="cmd_0013" id="cmd_0013">
                        <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                        <input type="radio" value="No" name="cmd_0013" formControlName="cmd_0013" id="cmd_0013">
                        <label class="formLabel" for="No">No</label>
                    </div>
                    <div class="col-md-12">
                        <label class="formLabel" for="Deliveries">Are you exclusively Telehealth?</label>&nbsp;&nbsp;
                        <input type="radio" value="yes" name="cmd_0014" formControlName="cmd_0014" id="cmd_0014">
                        <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                        <input type="radio" value="No" name="cmd_0014" formControlName="cmd_0014" id="cmd_0014">
                        <label class="formLabel" for="No">No</label>
                    </div>
                </td>
                <td colspan="8">
                    <div class="col-md-12">
                        <label class="formLabel" for="Deliveries">If Telehealth:</label>        
                    </div>
                    <div class="col-md-12">
                        <input type="Checkbox" value="PCP" formControlName="cmd_0015" id="cmd_0015">
                        <label class="formLabel" for="cmd_0015">Audio</label>&nbsp;&nbsp;
                        <input type="Checkbox" value="PCP" formControlName="cmd_0016" id="cmd_0016">
                        <label class="formLabel" for="cmd_0016">Visual</label>&nbsp;&nbsp;
                        <input type="Checkbox" value="PCP" formControlName="cmd_0017" id="cmd_0017">
                        <label class="formLabel" for="cmd_0017">Both</label>&nbsp;&nbsp;
                    </div>
                   
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Name of Practice / Affiliation or Clinic Name: </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="Name" id="cmd_15" formControlName="cmd_15"
                            class="form-control"
                            >
                        
                    </div>
                </td>
                <td colspan="8">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Department Name (if hospital based):</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder=" Department Name" id="cmd_16" formControlName="cmd_16"
                            class="form-control"
                            >
                        
                    </div>
                </td>             
            </tr>
            <tr>
                <td colspan="2">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Primary Office Street Address: </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="Address 1" id="cmd_41806" formControlName="cmd_41806"
                            class="form-control"
                            >
                        
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="Address 2" id="cmd_51806" formControlName="cmd_51806"
                            class="form-control"
                            >
                        
                    </div>
                </td>
                <td colspan="6">
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">City:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="City" id="cmd_61806" formControlName="cmd_61806"
                                class="form-control"
                                >                            
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">State:</label>
                        </div>
                        <div class="col-md-10">
                            <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                formControlName="cmd_71806"
                      >
                            </ng-select>                          
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="Zip" id="cmd_81806" formControlName="cmd_81806"
                                class="form-control"
                                >                            
                        </div>
                    </div>    
                </td>             
                <td colspan="4">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Org. NPI#:</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="NPI" id="cmd_91806" formControlName="cmd_91806"
                            class="form-control"
                            >                            
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Patient Appointment Telephone Number:</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="Telephone Number" id="cmd_101806" formControlName="cmd_101806"
                            class="form-control"
                            >  
                            <div  *ngIf="CancerCareNorthwestForms.get('cmd_101806').invalid && CancerCareNorthwestForms.get('cmd_101806').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_101806').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>
                                                      
                    </div>
                    
                </td>
                <td colspan="6">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Fax Number:</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" placeholder="Fax Number" id="cmd_111806" formControlName="cmd_111806"
                            class="form-control"
                            >     
                            <div  *ngIf="CancerCareNorthwestForms.get('cmd_111806').invalid && CancerCareNorthwestForms.get('cmd_111806').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_111806').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>                       
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Mailing Address: (if different from above)</label>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" placeholder="Address 1" id="cmd_121806" formControlName="cmd_121806"
                                class="form-control"
                                >                            
                        </div><div class="col-md-6">
                            <input type="text" placeholder="Address 2" id="cmd_131806" formControlName="cmd_131806"
                                class="form-control"
                                >                            
                        </div>
                    </div>    

                </td>
                <td colspan="8">
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">City:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="City" id="cmd_141806" formControlName="cmd_141806"
                                class="form-control"
                                >                            
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">State:</label>
                        </div>
                        <div class="col-md-10">
                            <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                formControlName="cmd_151806"
                      >
                            </ng-select>                          
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="Zip" id="cmd_161806" formControlName="cmd_161806"
                                class="form-control"
                                >                            
                        </div>
                    </div>    
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Billing Address: (if different from above)</label>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" placeholder="Address 1" id="cmd_171806" formControlName="cmd_171806"
                                class="form-control"
                                >                            
                        </div>
                        <div class="col-md-6">
                            <input type="text" placeholder="Address 2" id="cmd_181806" formControlName="cmd_181806"
                                class="form-control"
                                >                            
                        </div>
                    </div>    

                </td>
                <td colspan="8">
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">City:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="City" id="cmd_191806" formControlName="cmd_191806"
                                class="form-control"
                                >                            
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">State:</label>
                        </div>
                        <div class="col-md-10">
                            <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                formControlName="cmd_201806"
                      >
                            </ng-select>                          
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-1">
                            <label class="formLabel" class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-10">
                            <input type="text" placeholder="Zip" id="cmd_211806" formControlName="cmd_211806"
                                class="form-control"
                                >                            
                        </div>
                    </div>    
                </td>
            </tr>           
            <tr>
                    <td colspan="4">
                        
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Office Manager / Administrator Name: Administration Telephone Number: </label>
                            </div>
                        <div class="row">    
                            <div class="col-md-4">
                                <input type="text"  id="cmd_221806" formControlName="cmd_221806"
                                    class="form-control"
                                    >     
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_221806').invalid && CancerCareNorthwestForms.get('cmd_221806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_221806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                           
                            </div>
                            <div class="col-md-4">
                                <input type="text"  id="cmd_231806" formControlName="cmd_231806"
                                    class="form-control"
                                    >    
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_231806').invalid && CancerCareNorthwestForms.get('cmd_231806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_231806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>   
                                                         
                            </div>
                            <div class="col-md-4">
                                <input type="text"  id="cmd_241806" formControlName="cmd_241806"
                                    class="form-control"
                                    >     
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_241806').invalid && CancerCareNorthwestForms.get('cmd_241806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_241806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                          
                            </div>
                        </div>  
                    </td>
                
                <td colspan="4">
                
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Administration Telephone Number:</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" placeholder="Telephone Number" id="cmd_261806" formControlName="cmd_261806"
                                class="form-control"
                                >   
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_261806').invalid && CancerCareNorthwestForms.get('cmd_261806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_261806').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div> 
                                                   
                        </div>
                    
                </td>
                <td colspan="4">
                    
                        <div class="col-md-8">
                            <label class="formLabel" class="formLabel">Practice Website:</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" placeholder="Website" id="cmd_251806" formControlName="cmd_251806"
                                class="form-control"
                                >                            
                        </div>
                      
                </td>    
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-8">
                            <label class="formLabel" class="formLabel">E-mail Address: </label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" placeholder="E-mail" id="cmd_391806" formControlName="cmd_391806"
                                class="form-control"
                                >   
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_391806').invalid && CancerCareNorthwestForms.get('cmd_391806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_391806').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div>                            
                        </div>
                    </div>
                </td>
                <td colspan="8">
                    
                        <div class="row">
                            <div class="col-md-8">
                                <label class="formLabel" class="formLabel">Fax Number: </label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Fax Number" id="cmd_271806" formControlName="cmd_271806"
                                    class="form-control"
                                    >           
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_271806').invalid && CancerCareNorthwestForms.get('cmd_271806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_271806').errors.invalidPhoneFormat">
                                            Only Numbers are allowed
                                        </small>
                                      </div>                    
                            </div>
                        </div>
                   
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Credentialing Contact (if different from above):</label>
                        </div>
                    <div class="row">    
                        <div class="col-md-4">
                            <input type="text"  id="cmd_281806" formControlName="cmd_281806"
                                class="form-control"
                                >   
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_281806').invalid && CancerCareNorthwestForms.get('cmd_281806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_281806').errors.invalidPhoneFormat">
                                        Only Numbers are allowed
                                    </small>
                                  </div>                           
                        </div>
                        <div class="col-md-4">
                            <input type="text"  id="cmd_291806" formControlName="cmd_291806"
                                class="form-control"
                                >    
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_291806').invalid && CancerCareNorthwestForms.get('cmd_291806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_291806').errors.invalidPhoneFormat">
                                        Only Numbers are allowed
                                    </small>
                                  </div>                           
                        </div>
                        <div class="col-md-4">
                            <input type="text"  id="cmd_301806" formControlName="cmd_301806"
                                class="form-control"
                                >     
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_301806').invalid && CancerCareNorthwestForms.get('cmd_301806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_301806').errors.invalidPhoneFormat">
                                        Only Numbers are allowed
                                    </small>
                                  </div>                         
                        </div>
                    </div>  
                </td>
            
                <td colspan="8">
                
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Telephone Number:</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" placeholder="Telephone Number" id="cmd_311806" formControlName="cmd_311806"
                                class="form-control"
                                >  
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_311806').invalid && CancerCareNorthwestForms.get('cmd_311806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_311806').errors.invalidPhoneFormat">
                                        Only Numbers are allowed
                                    </small>
                                  </div>                                  
                        </div>
                    
                </td>
                
            </tr>
            <tr>
                <td colspan="12">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Credentialing Address: (if different from above)</label>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <input type="text" placeholder="Address 1" id="cmd_321806" formControlName="cmd_321806"
                                class="form-control"
                                >                            
                        </div>
                        <div class="col-md-2">
                            <input type="text" placeholder="Address 2" id="cmd_331806" formControlName="cmd_331806"
                                class="form-control"
                                >                            
                        </div>
                

                
                    
                        <div class="col-md-2">
                            <input type="text" placeholder="City" id="cmd_341806" formControlName="cmd_341806"
                                class="form-control"
                                >                            
                        </div>
                
                        <div class="col-md-3">
                            <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                formControlName="cmd_351806"
                    >
                            </ng-select>                          
                        </div>
                    
                        <div class="col-md-2">
                            <input type="text" placeholder="Zip" id="cmd_361806" formControlName="cmd_361806"
                                class="form-control"
                                >                            
                        </div>
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="formLabel" class="formLabel">E-mail Address: </label>
                        </div>
                        <div class="col-md-6">
                            <input type="text"  id="cmd_371806" formControlName="cmd_371806" placeholder="E-mail"
                                class="form-control"
                                >   
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_371806').invalid && CancerCareNorthwestForms.get('cmd_371806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_371806').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div>                            
                        </div>
                    </div>
                </td>
                <td colspan="8">
                    
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel" class="formLabel">Fax Number: </label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_381806" formControlName="cmd_381806" placeholder="Fax Number"
                                    class="form-control"
                                    >      
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_381806').invalid && CancerCareNorthwestForms.get('cmd_381806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_381806').errors.invalidPhoneFormat">
                                            Only Numbers are allowed
                                        </small>
                                      </div>                             
                            </div>
                        </div>
                    
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="formLabel" class="formLabel">Name Affiliated with Tax ID Number:</label>
                        </div>
                        <div class="col-md-6">
                            <input type="text"  id="cmd_411806" formControlName="cmd_411806"
                                class="form-control"
                                >                            
                        </div>
                    </div>
                </td>
                <td colspan="8">
                    
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel" class="formLabel">Federal Tax ID Number: </label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_401806" formControlName="cmd_401806"
                                    class="form-control"
                                    >                            
                            </div>                    
                        </div>                
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="formLabel" class="formLabel">Is the office wheelchair accessible?</label>
                            <input type="radio" value="1" name="cmd_37" formControlName="cmd_37" id="cmd_37">
                            <label class="formLabel" for="Yes">Yes</label>
                            <input type="radio" value="0" name="cmd_37" formControlName="cmd_37" id="cmd_37">
                            <label class="formLabel" for="No">No </label>
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="formLabel" class="formLabel">Are Gender Affirming treatment services offered?</label>
                            <input type="radio" value="1" name="cmd_421806" formControlName="cmd_421806" id="cmd_421806">
                            <label class="formLabel" for="Yes">Yes&nbsp;&nbsp;</label>
                            <input type="radio" value="0" name="cmd_421806" formControlName="cmd_421806" id="cmd_421806">
                            <label class="formLabel" for="No">No or&nbsp;&nbsp;</label>
                            <input type="radio" value="2" name="cmd_421806" formControlName="cmd_421806" id="cmd_421806">
                            <label class="formLabel" for="Unknown">Unknown</label>
                        </div>    
                    </div>
                </td>
                <td rowspan="2" colspan="8">
                    
                        <label class="formLabel" for="OfficeHours">Office Hours</label>
                    <div class="row">    
                            <div class="col-md-2">
                                <label class="formLabel" for="cmd_48">Monday:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" id="cmd_49" formControlName="cmd_49" class="form-control"> </div>
                    </div>
                    <div class="row">        
                                <div class="col-md-2">
                                <label class="formLabel" >Tuesday:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" id="cmd_50" formControlName="cmd_50" class="form-control"> </div>
                    </div> 
                    <div class="row">      
                                <div class="col-md-2">
                                    <label class="formLabel" >Wednesday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_51" formControlName="cmd_51" class="form-control"> </div>
                    </div>             
                    <div class="row">          
                                <div class="col-md-2">
                                    <label class="formLabel" >Thursday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_52" formControlName="cmd_52" class="form-control"> </div>
                    </div>             
                <div class="row">         
                                <div class="col-md-2">
                                    <label class="formLabel" >Friday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_53" formControlName="cmd_53" class="form-control"> </div>
                    </div>
                    <div class="row">             
                                <div class="col-md-2">
                                    <label class="formLabel" >Saturday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_54" formControlName="cmd_54" class="form-control"> </div>
                    </div>
                    <div class="row">             
                                <div class="col-md-2">
                                    <label class="formLabel" >Sunday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_55" formControlName="cmd_55" class="form-control"> </div>
                    </div>  
                    <div class="row">
                        <div class="col-md-8">
                            <label for="cmd_1007" class="formLabel" >Do you provide 24 hour coverage?</label>&nbsp;&nbsp;
                                <input type="radio" value="1" name="cmd_56" formControlName="cmd_56" (ngModelChange)="handleYesNoChanges('cmd_56')" id="cmd_56">
                                <label for="Yes">Yes</label>&nbsp;&nbsp;
                                <input type="radio" value="0" name="cmd_56" formControlName="cmd_56" (ngModelChange)="handleYesNoChanges('cmd_56')" id="cmd_56">
                                <label for="No">No</label>
                        </div>        
                    </div>   
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1007">If no, please explain how your patients obtain advice and care after hours:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_58"   row="15" formControlName="cmd_58"  (blur)="handleYesNoChanges('cmd_56')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_58'].invalid"  cols="28"></textarea>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_58'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                    </div>

                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-10">
                            <label for="cmd_1007" >Are you accepting new patients?</label>&nbsp;&nbsp;
                            <input type="radio" value="1" name="cmd_116" formControlName="cmd_116" id="cmd_116">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_116" formControlName="cmd_116" id="cmd_116">
                            <label for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <label for="cmd_1007" >Have you limited your practice in any way (e.g. 18 years or older?)</label>&nbsp;&nbsp;
                            <input type="radio" value="1" name="cmd_0117" (ngModelChange)="handleYesNoChanges('cmd_0117')" formControlName="cmd_0117" id="cmd_0117">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_0117" (ngModelChange)="handleYesNoChanges('cmd_0117')" formControlName="cmd_0117" id="cmd_0117">
                            <label for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1007">If yes, please explain:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_59"   row="10" formControlName="cmd_59" 
                            (blur)="handleYesNoChanges('cmd_0117')" 
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_59'].invalid"   
                                    
                        
                                 cols="25"></textarea>
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_59'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>      
                        
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <label  >Do you currently supervise ARNP’s or PA’s?</label>
                            <input type="radio" value="1" name="cmd_117" (ngModelChange)="handleYesNoChanges('cmd_117')" formControlName="cmd_117" id="cmd_116">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_117" (ngModelChange)="handleYesNoChanges('cmd_117')" formControlName="cmd_117" id="cmd_116">
                            <label for="No">No</label>

                            
                        </div>    
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1007">If yes, please provide the name and specialty below:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_059"   row="10" formControlName="cmd_059" (blur)="handleYesNoChanges('cmd_117')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_059'].invalid"  cols="25"></textarea>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_059'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel">Please list languages fluently spoken by office staff:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_60"   row="20" formControlName="cmd_60" cols="25"></textarea>
                        
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="8">
                    <label><b class="sideheading"><u class="sideheading">A. Hospital Inpatient Coverage Plan</u>(for those without admitting privileges)</b></label>
                </td>
                <td colspan="4">
                    <b class="sideheading">Does Not Apply</b>
                    <input type="checkbox" value="1" formControlName="cmd_NA_01" id="cmd_NA_01">
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-11">  
                        <label class="formLabel">Name of Admitting Physician/Practice/Clinic/Group:</label>
                    </div> 
                </td>
                <td colspan="8">
                    <div class="col-md-11">  
                        <label class="formLabel">Hospital Where privileged:</label>
                    </div> 
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-11">                
                            
                            <input type="text" id="cmd_431806" name="cmd_431806" class="form-control" formControlName="cmd_431806" class="form-control">                    
                    </div>
                </td>
            
                <td colspan="8">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_441806" name="cmd_441806" class="form-control" formControlName="cmd_441806" class="form-control">                    
                    </div>
                </td>
            </tr>    
            <tr >
                <td colspan="4">
                    <div class="col-md-11">                
                            
                            <input type="text" id="cmd_451806" name="cmd_451806" class="form-control" formControlName="cmd_451806" class="form-control">                    
                    </div>
                </td>
            
                <td colspan="8">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_461806" name="cmd_461806" class="form-control" formControlName="cmd_461806" class="form-control">                    
                    </div>
                </td>
            </tr>    
            <tr>
                <td colspan="8">
                    <label><b class="sideheading"><u class="sideheading">B. Office Covering Practitioners/Call Group</u></b></label>
                </td>
                <td colspan="4">
                    <b class="sideheading">Does Not Apply</b>
                    <input type="checkbox" value="1" formControlName="cmd_NA_02" id="cmd_NA_02">
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Provider Name, Degree</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Specialty</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Address</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Phone Number</label>
                    </div> 
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_471806" name="cmd_471806" class="form-control" formControlName="cmd_471806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_481806" name="cmd_481806" class="form-control" formControlName="cmd_481806" class="form-control">                    
                    </div>
                </td>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_491806" name="cmd_491806" class="form-control" formControlName="cmd_491806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_501806" name="cmd_501806" class="form-control" formControlName="cmd_501806" class="form-control">                    
                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_501806').invalid && CancerCareNorthwestForms.get('cmd_501806').touched">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_501806').errors.invalidPhoneFormat">
                                Only Numbers are allowed
                            </small>
                          </div>      
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_511806" name="cmd_511806" class="form-control" formControlName="cmd_511806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_521806" name="cmd_521806" class="form-control" formControlName="cmd_521806" class="form-control">                    
                    </div>
                </td>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_531806" name="cmd_531806" class="form-control" formControlName="cmd_531806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_541806" name="cmd_541806" class="form-control" formControlName="cmd_541806" class="form-control">                    
                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_541806').invalid && CancerCareNorthwestForms.get('cmd_541806').touched">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_541806').errors.invalidPhoneFormat">
                              Only Numbers are allowed
                            </small>
                          </div>  
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <b class="sideheadings">Attach a list of additional admitting physician/practice/clinic/group or covering practitioners if needed</b>
                </td>
            </tr>
                
            </table>
            <br>
            <table border="1" width="100%" class="center" >    
                <tr>
                    <th colspan="12">   
                        <div class="row"> 
                            <div class="col-md-4">                         
                                <h6>Practitioner Start Date at SECONDARY</h6>
                            </div>
                            <div class="col-md-3">      
                                <b> Practice location (MM/YYYY)</b>
                            </div>     
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input
                                    class="form-control onboard"
                                    ngbDatepicker
                                    #d561806="ngbDatepicker"
                                    id="cmd_561806"
                                    name="cmd_561806"
                                    formControlName="cmd_561806"
                                    (blur)="clearInputIfInvalid('cmd_561806')" 
                                    type="text"
                                    data-id="dob_date"
                                    />
                                    <div class="input-group-append">
                                        <button
                                        class="btn btn-outline-secondary calendar"
                                        (click)="d561806.toggle()"
                                        type="button"
                                        >
                                        <i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>    
                                <div *ngIf="CancerCareNorthwestForms.get('cmd_561806').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_561806').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_561806').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_561806').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_561806').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                            <div class="col-md-3">      
                                <b>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; CHECK ALL THAT APPLY</b>
                            </div>
                        </div>     
                    </th>
                </tr>
                <tr>
                    <td colspan="12">
                        
                        <div class="col-md-12">
                            <label class="formLabel"><b>Practice Setting</b></label>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="padding-left:60px;">
                                    <input type="Checkbox" value="Clinic/Group " formControlName="cmd_571806" id="cmd_571806">
                                    <label class="formLabel" class="formLabel" for="cmd_571806">Clinic/Group</label>&nbsp;&nbsp;
                                    <input class="formLabel" type="Checkbox" value="SoloPractice " formControlName="cmd_581806" id="cmd_581806">
                                    <label class="formLabel" class="formLabel" for="cmd_581806 ">Solo Practice </label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="HomeBased" formControlName="cmd_591806" id="cmd_591806">
                                    <label class="formLabel" class="formLabel" for="cmd_591806 ">Home Based</label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="HospitalBased" formControlName="cmd_601806" id="cmd_601806">
                                    <label class="formLabel" class="formLabel" for="cmd_601806">Hospital Based</label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="PrimaryCareSite" formControlName="cmd_611806" id="cmd_611806">
                                    <label class="formLabel" class="formLabel" for="cmd_611806">Primary Care Site</label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="UrgentCare" formControlName="cmd_621806" id="cmd_621806">
                                    <label class="formLabel" class="formLabel" for="cmd_621806">Urgent Care</label>&nbsp;&nbsp;
                                    <input type="Checkbox" value="Other" formControlName="cmd_631806" id="cmd_631806">&nbsp;&nbsp;
                                    <label class="formLabel" class="formLabel" for="Other">Other</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Practitioner Profile</label>
                        </div>
                        <div class="col-md-12" style="padding-left: 40px;">                        
                            <input type="Checkbox" value="PCP" formControlName="cmd_641806" id="cmd_641806">
                            <label class="formLabel" for="cmd_641806">PCP</label>&nbsp;&nbsp;
                            <input type="Checkbox" value="Specialist" formControlName="cmd_651806" id="cmd_651806">
                            <label class="formLabel" for="cmd_651806 ">Specialist </label>&nbsp;&nbsp;
                            <input type="Checkbox" value="PCBOB" formControlName="cmd_661806" id="cmd_661806">
                            <label class="formLabel" for="PCBOB">Check if you are both PCP & OB</label>&nbsp;&nbsp;
                            <label class="formLabel" for="cmd_11">OB in your practice</label>&nbsp;&nbsp;
                            <input type="radio" value="yes" name="cmd_671806" formControlName="cmd_671806" id="cmd_671806">
                            <label class="formLabel" for="cmd_671806">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="No" name="cmd_671806" formControlName="cmd_671806" id="cmd_671806">
                            <label class="formLabel" for="cmd_671806">No</label>&nbsp;&nbsp;
                            <label class="formLabel" for="Deliveries">Deliveries</label>&nbsp;&nbsp;
                            <input type="radio" value="yes" name="cmd_681806" formControlName="cmd_681806" id="cmd_681806">
                            <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="No" name="cmd_681806" formControlName="cmd_681806" id="cmd_681806">
                            <label class="formLabel" for="No">No</label>&nbsp;&nbsp; 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" for="Deliveries">Do you offer Telehealth?</label>&nbsp;&nbsp;
                            <input type="radio" value="yes" name="cmd_691806" formControlName="cmd_691806" id="cmd_691806">
                            <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="No" name="cmd_691806" formControlName="cmd_691806" id="cmd_691806">
                            <label class="formLabel" for="No">No</label>
                        </div>
                        <div class="col-md-12">
                            <label class="formLabel" for="Deliveries">Are you exclusively Telehealth?</label>&nbsp;&nbsp;
                            <input type="radio" value="yes" name="cmd_701806" formControlName="cmd_701806" id="cmd_701806">
                            <label class="formLabel" for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="No" name="cmd_701806" formControlName="cmd_701806" id="cmd_701806">
                            <label class="formLabel" for="No">No</label>
                        </div>
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel" for="Deliveries">If Telehealth:</label>        
                        </div>
                        <div class="col-md-12">
                            <input type="Checkbox" value="PCP" formControlName="cmd_711806" id="cmd_711806">
                            <label class="formLabel" for="cmd_711806">Audio</label>&nbsp;&nbsp;
                            <input type="Checkbox" value="PCP" formControlName="cmd_721806" id="cmd_721806">
                            <label class="formLabel" for="cmd_721806">Visual</label>&nbsp;&nbsp;
                            <input type="Checkbox" value="PCP" formControlName="cmd_731806" id="cmd_731806">
                            <label class="formLabel" for="cmd_731806">Both</label>&nbsp;&nbsp;
                        </div>
                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Name of Secondary Practice / Affiliation or Clinic Name: </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Name" id="cmd_741806" formControlName="cmd_741806"
                                class="form-control"
                                >
                            
                        </div>
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Department Name (if hospital based):</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder=" Department Name" id="cmd_751806" formControlName="cmd_751806"
                                class="form-control"
                                >
                            
                        </div>
                    </td>             
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Primary Office Street Address: </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Address 1" id="cmd_761806" formControlName="cmd_761806"
                                class="form-control"
                                >
                            
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Address 2" id="cmd_771806" formControlName="cmd_771806"
                                class="form-control"
                                >
                            
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">City:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="City" id="cmd_781806" formControlName="cmd_781806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">State:</label>
                            </div>
                            <div class="col-md-10">
                                <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                    formControlName="cmd_791806"
                        >
                                </ng-select>                          
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">Zip:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="Zip" id="cmd_801806" formControlName="cmd_801806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div>    
                    </td>             
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Org. NPI#:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="NPI" id="cmd_811806" formControlName="cmd_811806"
                                class="form-control"
                                >                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Patient Appointment Telephone Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Telephone Number" id="cmd_821806" formControlName="cmd_821806"
                                class="form-control"
                                >   
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_821806').invalid && CancerCareNorthwestForms.get('cmd_821806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_821806').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div> 
                                                        
                        </div>
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fax Number" id="cmd_831806" formControlName="cmd_831806"
                                class="form-control"
                                >    
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_831806').invalid && CancerCareNorthwestForms.get('cmd_831806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_831806').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>                         
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Mailing Address: (if different from above)</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Address 1" id="cmd_0891806" formControlName="cmd_0891806"
                                    class="form-control"
                                    >                            
                            </div><div class="col-md-6">
                                <input type="text" placeholder="Address 2" id="cmd_841806" formControlName="cmd_841806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div>    

                    </td>
                    <td colspan="8">
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">City:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="City" id="cmd_851806" formControlName="cmd_851806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">State:</label>
                            </div>
                            <div class="col-md-10">
                                <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                    formControlName="cmd_861806"
                        >
                                </ng-select>                          
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">Zip:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="Zip" id="cmd_0861806" formControlName="cmd_0861806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" class="formLabel">Billing Address: (if different from above)</label>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Address 1" id="cmd_871806" formControlName="cmd_871806"
                                    class="form-control"
                                    >                            
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Address 2" id="cmd_881806" formControlName="cmd_881806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div>    

                    </td>
                    <td colspan="8">
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">City:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="City" id="cmd_891806" formControlName="cmd_891806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">State:</label>
                            </div>
                            <div class="col-md-10">
                                <ng-select  [items]="states"bindLabel="name" placeholder="Select State" bindValue="id"
                                    formControlName="cmd_901806"
                        >
                                </ng-select>                          
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-md-1">
                                <label class="formLabel" class="formLabel">Zip:</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" placeholder="Zip" id="cmd_911806" formControlName="cmd_911806"
                                    class="form-control"
                                    >                            
                            </div>
                        </div>    
                    </td>
                </tr>           
                <tr>
                        <td colspan="4">
                            
                                <div class="col-md-12">
                                    <label class="formLabel" class="formLabel">Office Manager / Administrator Name: Administration Telephone Number: </label>
                                </div>
                            <div class="row">    
                                <div class="col-md-4">
                                    <input type="text"  id="cmd_921806" formControlName="cmd_921806"
                                        class="form-control"
                                        >     
                                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_921806').invalid && CancerCareNorthwestForms.get('cmd_921806').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_921806').errors.invalidPhoneFormat">
                                              Only Numbers are allowed
                                            </small>
                                          </div>                          
                                </div>
                                <div class="col-md-4">
                                    <!-- <input type="text"  id="cmd_931806" formControlName="cmd_931806"
                                        class="form-control"
                                        >    -->
                                        <input type="text"  id="cmd_931806" formControlName="cmd_931806"
                                        class="form-control"
                                        > 
                                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_931806').invalid && CancerCareNorthwestForms.get('cmd_931806').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_931806').errors.invalidPhoneFormat">
                                              Only Numbers are allowed
                                            </small>
                                          </div>      
                                                                
                                </div>
                                <div class="col-md-4">
                                    <input type="text"  id="cmd_941806" formControlName="cmd_941806"
                                        class="form-control"
                                        >       
                                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_941806').invalid && CancerCareNorthwestForms.get('cmd_941806').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_941806').errors.invalidPhoneFormat">
                                              Only Numbers are allowed
                                            </small>
                                          </div>                       
                                </div>
                            </div>  
                        </td>
                    
                    <td colspan="4">
                    
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Administration Telephone Number:</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Telephone Number" id="cmd_951806" formControlName="cmd_951806"
                                    class="form-control"
                                    > 
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_951806').invalid && CancerCareNorthwestForms.get('cmd_951806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_951806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>   
                                                               
                            </div>
                        
                    </td>
                    <td colspan="4">
                        
                            <div class="col-md-8">
                                <label class="formLabel" class="formLabel">Practice Website:</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Website" id="cmd_961806" formControlName="cmd_961806"
                                    class="form-control"
                                    >                            
                            </div>
                        
                    </td>    
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="formLabel" class="formLabel">E-mail Address: </label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="E-mail" id="cmd_971806" formControlName="cmd_971806"
                                    class="form-control"
                                    >   
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_971806').invalid && CancerCareNorthwestForms.get('cmd_971806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_971806').errors.invalidEmailFormat">
                                          Only Email format allowed
                                        </small>
                                      </div>   
                                                             
                            </div>
                        </div>
                    </td>
                    <td colspan="8">
                        
                            <div class="row">
                                <div class="col-md-8">
                                    <label class="formLabel" class="formLabel">Fax Number: </label>
                                </div>
                                <div class="col-md-9">
                                    <input type="text" placeholder="Fax Number" id="cmd_981806" formControlName="cmd_981806"
                                        class="form-control"
                                        >     
                                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_981806').invalid && CancerCareNorthwestForms.get('cmd_981806').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_981806').errors.invalidPhoneFormat">
                                              Only Numbers are allowed
                                            </small>
                                          </div>                          
                                </div>
                            </div>
                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Credentialing Contact (if different from above):</label>
                            </div>
                        <div class="row">    
                            <div class="col-md-4">
                                <input type="text"  id="cmd_991806" formControlName="cmd_991806"
                                    class="form-control"
                                    >       
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_991806').invalid && CancerCareNorthwestForms.get('cmd_991806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_991806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                      
                            </div>
                            <div class="col-md-4">
                                <input type="text"  id="cmd_1001806" formControlName="cmd_1001806"
                                    class="form-control"
                                    >       
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_1001806').invalid && CancerCareNorthwestForms.get('cmd_1001806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1001806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                        
                            </div>
                            <div class="col-md-4">
                                <input type="text"  id="cmd_1011806" formControlName="cmd_1011806"
                                    class="form-control"
                                    >  
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_1011806').invalid && CancerCareNorthwestForms.get('cmd_1011806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1011806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                                 
                            </div>
                        </div>  
                    </td>
                
                    <td colspan="8">
                    
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Telephone Number:</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Telephone Number" id="cmd_1021806" formControlName="cmd_1021806"
                                    class="form-control"
                                    >     
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_1021806').invalid && CancerCareNorthwestForms.get('cmd_1021806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1021806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                          
                            </div>
                        
                    </td>
                    
                </tr>
            <tr>
                <td colspan="12">
                    <div class="col-md-12">
                        <label class="formLabel" class="formLabel">Credentialing Address: (if different from above)</label>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <input type="text" placeholder="Address 1" id="cmd_1031806" formControlName="cmd_1031806"
                                class="form-control"
                                >                            
                        </div>
                        <div class="col-md-2">
                            <input type="text" placeholder="Address 2" id="cmd_1041806" formControlName="cmd_1041806"
                                class="form-control"
                                >                            
                        </div>
                

                
                    
                        <div class="col-md-2">
                            <input type="text" placeholder="City" id="cmd_1051806" formControlName="cmd_1051806"
                                class="form-control"
                                >                            
                        </div>
                
                        <div class="col-md-3">
                            <ng-select  [items]="states" bindLabel="name" placeholder="Select States" bindValue="id"
                                formControlName="cmd_1061806"
                    >
                            </ng-select>                          
                        </div>
                    
                        <div class="col-md-2">
                            <input type="text" placeholder="Zip" id="cmd_1071806" formControlName="cmd_1071806"
                                class="form-control"
                                >                            
                        </div>
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="formLabel" class="formLabel">E-mail Address: </label>
                        </div>
                        <div class="col-md-6">
                            <input type="text"  id="cmd_1081806" formControlName="cmd_1081806" placeholder="E-mail"
                                class="form-control"
                                >       
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_1081806').invalid && CancerCareNorthwestForms.get('cmd_1081806').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1081806').errors.invalidEmailFormat">
                                      Only Email format allowed
                                    </small>
                                  </div>                          
                        </div>
                    </div>
                </td>
                <td colspan="8">
                    
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel" class="formLabel">Fax Number: </label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_1091806" formControlName="cmd_1091806" placeholder="Fax Number"
                                    class="form-control"
                                    >     
                                    <div  *ngIf="CancerCareNorthwestForms.get('cmd_1091806').invalid && CancerCareNorthwestForms.get('cmd_1091806').touched">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1091806').errors.invalidPhoneFormat">
                                          Only Numbers are allowed
                                        </small>
                                      </div>                           
                            </div>
                        </div>
                    
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="formLabel" class="formLabel">Name Affiliated with Tax ID Number:</label>
                        </div>
                        <div class="col-md-6">
                            <input type="text"  id="cmd_1101806" formControlName="cmd_1101806"
                                class="form-control"
                                >                            
                        </div>
                    </div>
                </td>
                <td colspan="8">
                    
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel" class="formLabel">Federal Tax ID Number: </label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_01121806" formControlName="cmd_01121806"
                                    class="form-control"
                                    >                            
                            </div>                    
                        </div>                
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="formLabel" class="formLabel">Is the office wheelchair accessible?</label>
                            <input type="radio" value="1" name="cmd_1121806" formControlName="cmd_1121806" id="cmd_1121806">
                            <label class="formLabel" for="Yes">Yes</label>
                            <input type="radio" value="0" name="cmd_1121806" formControlName="cmd_1121806" id="cmd_1121806">
                            <label class="formLabel" for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="formLabel" class="formLabel">Are Gender Affirming treatment services offered?</label>
                            <input type="radio" value="1" name="cmd_1131806" formControlName="cmd_1131806" id="cmd_1131806">
                            <label class="formLabel" for="Yes">Yes</label>
                            <input type="radio" value="0" name="cmd_1131806" formControlName="cmd_1131806" id="cmd_1131806">
                            <label class="formLabel" for="No">No or</label>
                            <input type="radio" value="2" name="cmd_1131806" formControlName="cmd_1131806" id="cmd_1131806">
                            <label class="formLabel" for="No">Unknown</label>
                        </div>    
                    </div>
                </td>
                <td rowspan="2" colspan="8">
                    
                        <label class="formLabel" for="OfficeHours">Office Hours</label>
                    <div class="row">    
                            <div class="col-md-2">
                                <label class="formLabel" for="cmd_1141806">Monday:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" id="cmd_1141806" formControlName="cmd_1141806" class="form-control"> </div>
                    </div>
                    <div class="row">        
                                <div class="col-md-2">
                                <label class="formLabel" >Tuesday:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" id="cmd_1151806" formControlName="cmd_1151806" class="form-control"> </div>
                    </div> 
                    <div class="row">      
                                <div class="col-md-2">
                                    <label class="formLabel" >Wednesday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_1161806" formControlName="cmd_1161806" class="form-control"> </div>
                    </div>             
                    <div class="row">          
                                <div class="col-md-2">
                                    <label class="formLabel" >Thursday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_1171806" formControlName="cmd_1171806" class="form-control"> </div>
                    </div>             
                <div class="row">         
                                <div class="col-md-2">
                                    <label class="formLabel" >Friday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_1181806" formControlName="cmd_1181806" class="form-control"> </div>
                    </div>
                    <div class="row">             
                                <div class="col-md-2">
                                    <label class="formLabel" >Saturday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_1191806" formControlName="cmd_1191806" class="form-control"> </div>
                    </div>
                    <div class="row">             
                                <div class="col-md-2">
                                    <label class="formLabel" >Sunday:</label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" id="cmd_1201806" formControlName="cmd_1201806" class="form-control"> </div>
                    </div>  
                    <div class="row">
                        <div class="col-md-8">
                            <label for="cmd_1007" class="formLabel" >Do you provide 24 hour coverage?</label>&nbsp;&nbsp;
                                <input type="radio" value="1" name="cmd_1211806" (ngModelChange)="handleYesNoChanges('cmd_1211806')" formControlName="cmd_1211806" id="cmd_1211806">
                                <label for="Yes">Yes</label>&nbsp;&nbsp;
                                <input type="radio" value="0" name="cmd_1211806" (ngModelChange)="handleYesNoChanges('cmd_1211806')" formControlName="cmd_1211806" id="cmd_1211806">
                                <label for="No">No</label>
                        </div>        
                    </div>   
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1221806">If no, please explain how your patients obtain advice and care after hours:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_58"   row="15" formControlName="cmd_1221806" (blur)="handleYesNoChanges('cmd_1211806')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1221806'].invalid"  cols="28"></textarea>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_1221806'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                            
                    </div>

                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="row">
                        <div class="col-md-10">
                            <label for="cmd_1007" >Are you accepting new patients?</label>&nbsp;&nbsp;
                            <input type="radio" value="1" name="cmd_1231806" formControlName="cmd_1231806" id="cmd_1231806">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_1231806" formControlName="cmd_1231806" id="cmd_1231806">
                            <label for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <label for="cmd_1007" >Have you limited your practice in any way (e.g. 18 years or older?)</label>&nbsp;&nbsp;
                            <input type="radio" value="1" name="cmd_1241806" (ngModelChange)="handleYesNoChanges('cmd_1241806')" formControlName="cmd_1241806" id="cmd_1241806">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_1241806" (ngModelChange)="handleYesNoChanges('cmd_1241806')" formControlName="cmd_1241806" id="cmd_1241806">
                            <label for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1251806">If yes, please explain:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_1251806"   row="10" (blur)="handleYesNoChanges('cmd_1241806')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1251806'].invalid" formControlName="cmd_1251806" cols="25"></textarea>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_1251806'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <label  >Do you currently supervise ARNP’s or PA’s?</label>
                            <input type="radio" value="1" name="cmd_1261806" (ngModelChange)="handleYesNoChanges('cmd_1261806')" formControlName="cmd_1261806" id="cmd_1261806">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;
                            <input type="radio" value="0" name="cmd_1261806" (ngModelChange)="handleYesNoChanges('cmd_1261806')" formControlName="cmd_1261806" id="cmd_1261806">
                            <label for="No">No</label>
                        </div>    
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel" for="cmd_1271806">If yes, please provide the name and specialty below:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_1271806"   row="10" (blur)="handleYesNoChanges('cmd_1261806')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1271806'].invalid" formControlName="cmd_1271806" cols="25"></textarea>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_1271806'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                    </div>
                    <div class="row">   
                        <div class="col-md-11">  
                            <label class="formLabel">Please list languages fluently spoken by office staff:</label>
                        </div>  
                    </div> 
                    <div class="col-md-8">      
                            <textarea id="cmd_1281806"   row="20" formControlName="cmd_1281806" cols="25"></textarea>
                        
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="8">
                    <label><b class="sideheading"><u class="sideheading">A. Hospital Inpatient Coverage Plan</u>(for those without admitting privileges)</b></label>
                </td>
                <td colspan="4">
                    <b class="sideheading">Does Not Apply</b>
                    <input type="checkbox" value="1" formControlName="cmd_NA_03" id="cmd_NA_03">
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-11">  
                        <label class="formLabel">Name of Admitting Physician/Practice/Clinic/Group:</label>
                    </div> 
                </td>
                <td colspan="8">
                    <div class="col-md-11">  
                        <label class="formLabel">Hospital Where privileged:</label>
                    </div> 
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="col-md-11">                
                            
                            <input type="text" id="cmd_1291806" name="cmd_1291806" class="form-control" formControlName="cmd_1291806" class="form-control">                    
                    </div>
                </td>
            
                <td colspan="8">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1301806" name="cmd_1301806" class="form-control" formControlName="cmd_1301806" class="form-control">                    
                    </div>
                </td>
            </tr>    
            <tr >
                <td colspan="4">
                    <div class="col-md-11">                
                            
                            <input type="text" id="cmd_1311806" name="cmd_1311806" class="form-control" formControlName="cmd_1311806" class="form-control">                    
                    </div>
                </td>
            
                <td colspan="8">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1321806" name="cmd_1321806" class="form-control" formControlName="cmd_1321806" class="form-control">                    
                    </div>
                </td>
            </tr>    
            <tr>
                <td colspan="8">
                    <label><b class="sideheading"><u class="sideheading">B. Office Covering Practitioners/Call Group</u></b></label>
                </td>
                <td colspan="4">
                    <b class="sideheading">Does Not Apply</b>
                    <input type="checkbox" value="1" formControlName="cmd_NA_04" id="cmd_NA_04">
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Provider Name, Degree</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Specialty</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Address</label>
                    </div> 
                </td>
                <td colspan="3">
                    <div class="col-md-11">  
                        <label class="formLabel">Phone Number</label>
                    </div> 
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_1331806" name="cmd_1331806" class="form-control" formControlName="cmd_1331806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1341806" name="cmd_1341806" class="form-control" formControlName="cmd_1341806" class="form-control">                    
                    </div>
                </td>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_1351806" name="cmd_1351806" class="form-control" formControlName="cmd_1351806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1361806" name="cmd_1361806" class="form-control" formControlName="cmd_1361806" class="form-control">                    
                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_1361806').invalid && CancerCareNorthwestForms.get('cmd_1361806').touched">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1361806').errors.invalidPhoneFormat">
                              Only Numbers are allowed
                            </small>
                          </div>      
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_1371806" name="cmd_1371806" class="form-control" formControlName="cmd_1371806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1381806" name="cmd_1381806" class="form-control" formControlName="cmd_1381806" class="form-control">                    
                    </div>
                </td>
                <td colspan="3">
                    <div class="col-md-11">                                        
                            <input type="text" id="cmd_1391806" name="cmd_1391806" class="form-control" formControlName="cmd_1391806" class="form-control">                    
                    </div>
                </td>        
                <td colspan="3">
                    <div class="col-md-11">                                        
                        <input type="text" id="cmd_1401806" name="cmd_1401806" class="form-control" formControlName="cmd_1401806" class="form-control">                    
                        <div  *ngIf="CancerCareNorthwestForms.get('cmd_1401806').invalid && CancerCareNorthwestForms.get('cmd_1401806').touched">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1401806').errors.invalidPhoneFormat">
                              Only Numbers are allowed
                            </small>
                          </div> 
                    </div>
                </td>
            </tr> 
            <tr>
                <td colspan="12">
                    <b class="sideheadings">Attach a list of additional admitting physician/practice/clinic/group or covering practitioners if needed</b>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <b class="sideheadings">LIST OTHER OFFICE LOCATIONS WITH THE ABOVE INFORMATION ON A SEPARATE SHEET</b>
                </td>
            </tr>
            
            </table>    
            <br>
            <table border="1" width="100%" class="center"  >    
                <tr>
                    <th colspan="12">
                        <div class="col-md-12">        
                            <label class="sideheading"  >
                                <h6>4. PROFESSIONAL LICENSURE, REGISTRATIONS AND CERTIFICATIONS</h6> </label><br><b>(Attach Additional Sheet if Necessary)</b>
                        </div>        
                    </th>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Washington State Professional License/Registration/Cert
                                    Number:</label>
                            </div>

                            <div class="col-md-12">
                                <input type="text" placeholder="License Number" id="293" formControlName="293"
                                    class="form-control"
                                    >
                                
                            </div>

                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Issue Date:</label>
                            </div>

                            <div class="col-md-8">
                                <div class="input-group">
                                <input type="text" placeholder="MM/DD/YYYY" id="294" formControlName="294"    (blur)="clearInputIfInvalid('294')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #d294="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d294.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i> </button>
                                </div>

                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('294').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('294').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('294').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('294').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('294').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>

                            </div>

                        </div>
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel" class="formLabel">Expiration Date:</label>
                            </div>

                            <div class="col-md-12">
                                <div class="input-group">
                                <input type="text" placeholder="MM/DD/YYYY" id="295" formControlName="295"    (blur)="clearInputIfInvalid('295')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #d295="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d295.toggle()"
                                        type="button">
                                        <i class="fas fa-calendar-alt"></i> </button>
                                </div>

                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('295').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('295').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('295').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('295').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('295').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            

                        </div>
                        </div>
                    </td>
                </tr>    
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <b>Name of Sponsor if required by licensure, (e.g. Physician’s Assistant).</b>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="Text" placeholder="Enter Delegated Physician First Name " id="1745" formControlName="1745" class="form-control"> </div>
                                <div class="col-md-3">
                                    <input type="Text" placeholder="Enter Delegated Physician Last Name " id="1746" formControlName="1746" class="form-control"> </div>
                                <div class="col-md-3">
                                    <ng-select [items]="suffix" bindLabel="name" placeholder="Suffix" bindValue="id" formControlName="1748"> </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <ng-select [labelForId]="'1749'" [items]="credentials" bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="1749"> </ng-select>
                                    
                                </div>
                                
                            </div>    

                        
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <b>Pharmacists Collaborative Drug Therapy Agreement (CDTA) Number(s):</b>
                            </div>                    
                                <div class="col-md-12">
                                    <input type="Text" placeholder="Enter Pharmacists Collaborative Drug" id="cmd_153" formControlName="cmd_153" class="form-control">                                   
                                </div>                         
                    
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="row">   
                            <div class="col-md-8">
                                <label class="formLabel">Drug Enforcement Administration (DEA) Registration Number:</label>
                            </div>  
                            <div class="4">
                                <b>Does Not Apply:</b>
                                    <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_23')" formControlName="cmd_NA_23"
                                        id="cmd_NA_23">
                            </div> 
                        </div>                     
                            <div class="col-md-12">
                                <input type="text"  placeholder=" DEA Number" id="309" formControlName="309"
                                (blur)="handleNAchange('cmd_NA_23')" [class.is-invalid]="CancerCareNorthwestForms.controls['309'].invalid" class="form-control"
                                        >  
                                                    <div class="mandatory"
                                                        *ngIf="CancerCareNorthwestForms.controls['309'].invalid">
                                                        <small >
                                                        required
                                                        </small>
                                                    </div>                                                                                         
                            </div>
                    </td>   
                    <td colspan="6">       
                            <div class="col-md-12">
                                <label class="formLabel">Expiration Date:</label>
                            </div>                    
                            <div class="input-group">
                                <input
                                class="form-control onboard"
                                
                                placeholder="MM/DD/YYYY"
                                ngbDatepicker
                                #d311="ngbDatepicker"
                                id="311"
                                name="311"
                                (blur)="clearInputIfInvalid('311','cmd_NA_23')"
                                (blur)="handleNAchange('cmd_NA_23')" [class.is-invalid]="CancerCareNorthwestForms.controls['311'].invalid"
                                formControlName="311"
                                type="text"
                                data-id="dob_date"
                                />
                                <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary calendar"
                                    (click)="d311.toggle()"
                                    type="button"
                                >
                                    <i class="fas fa-calendar-alt"></i>
                                </button> 
                                </div>
                                 
                        
                                                    
                            </div>         
                            <div class="text-danger" *ngIf="CancerCareNorthwestForms.controls['311'].invalid && (CancerCareNorthwestForms.controls['311'].value==''|| CancerCareNorthwestForms.controls['311'].value==null) " >
                                <small >
                                required
                                </small>
                              
                              </div>
                              <div  *ngIf="CancerCareNorthwestForms.get('311').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('311').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)                  </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('311').errors.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('311').errors.invalidDateyear">
                                  year is out of range(1900-2099)                    </small>
                                  <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('311').errors.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>                                   
                    </td>
                </tr>  
                <tr>
                    <td colspan="6">
                        
                            <div class="col-md-12">
                                <label class="formLabel">ECFMG Number (applicable to foreign medical graduates):</label>
                            </div>                    
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_23')" [class.is-invalid]="CancerCareNorthwestForms.controls['514'].invalid"  placeholder="ECFMG Number" id="514" formControlName="514" class="form-control"
                                        >      <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['514'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>                                                                                 
                            </div>
                    </td>   
                    <td colspan="6">       
                            <div class="col-md-12">
                                <label class="formLabel">Date Issued:</label>
                            </div>                    
                            <div class="input-group">
                                <input
                                class="form-control onboard"
                                (blur)="clearInputIfInvalid('515','cmd_NA_23')" 
                                (blur)="handleNAchange('cmd_NA_23')" [class.is-invalid]="CancerCareNorthwestForms.controls['515'].invalid"
                                placeholder="MM/DD/YYYY"
                                ngbDatepicker
                                #d515="ngbDatepicker"
                                id="515"
                                name="515"
                                formControlName="515"
                                type="text"
                                data-id="dob_date"
                                />
                                <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary calendar"
                                    (click)="d515.toggle()"
                                    type="button"
                                >
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                                </div>
                            </div>   
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['515'].invalid && (CancerCareNorthwestForms.controls['515'].value==''|| CancerCareNorthwestForms.controls['515'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('515').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('515').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('515').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('515').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('515').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>                                         
                    </td>
                </tr>
            </table>
            <table border="1" width="100%" class="center"  >        
               <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>5. ALL OTHER PROFESSIONAL LICENSES, REGISTRATIONS AND CERTIFICATIONS</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_3')" formControlName="cmd_NA_3"
                            id="cmd_NA_3">
                    </th>
                </tr>
                <tr>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >State:</label>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Lic/Reg/Cert Number:</label>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Date Issued</label>
                        </div>
                    </td>   
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >Exp. Date </label>
                        </div>
                    </td> 
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Yr. Relinquish</label>
                        </div>
                    </td> 
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Reason </label>
                        </div>
                    </td> 
                </tr>
                <tr>
                    <td colspan="1">
                        
                            <div class="col-md-12">
                                <ng-select [items]="altLicenseState" 
                                 [class.is-invalid]="CancerCareNorthwestForms.controls['907'].invalid"(blur)="handleNAchange('cmd_NA_3',907)"  bindLabel="name" placeholder="Certification State" bindValue="id"
                                    formControlName="907"> </ng-select>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['907'].invalid">
                                    
                                                <small >
                                                    required
                                                </small>
                                    </div> 
            
                            </div>                                           
                    </td>
                    <td colspan="2">
                        
                            
                            <div class="col-md-12">
                                <input type="text" placeholder="License 2 Number"  
                               
                                id="300" formControlName="300"
                                            class="form-control">
                                            
                            </div>                        
                    
                    </td>
                    <td colspan="2">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="License2  Issue Date  " id="307"   
                                    
                                     formControlName="307"  (blur)="clearInputIfInvalid('307')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #d307="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d307.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                                    
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('307').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('307').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('307').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('307').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('307').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                        
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="License 2  Exp Date  " id="308" formControlName="308" (blur)="clearInputIfInvalid('308')" 
                                   
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #d308="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d308.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i> </button>
                                    </div>

                                    
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('308').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('308').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('308').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('308').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('308').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                        
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <input type="text" placeholder="Year Relinquish" id="cmd_154" formControlName="cmd_154"
                                class="form-control">
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <input type="text" placeholder="Reason" id="cmd_155" formControlName="cmd_155"
                                            class="form-control">
                        </div>
                    </td>
                </tr>           
                <tr>
                    <td colspan="1">
                        
                            <div class="col-md-12">
                                <ng-select [items]="additionalLicenseState" bindLabel="name"  
                                [class.is-invalid]="CancerCareNorthwestForms.controls['1010'].invalid"(blur)="handleNAchange('cmd_NA_3',1010)"
                                placeholder="Additional License State" bindValue="id"
                                            formControlName="1010"> </ng-select>
                                            <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1010'].invalid">
                                    
                                                <small >
                                                    required
                                                </small>
                                    </div> 
                                            
                            </div>                                           
                    </td>
                    <td colspan="2">                                            
                            <div class="col-md-12">
                                <input type="text" placeholder="Additional License Number " id="999" formControlName="999"
                                
                                            class="form-control">
                                            


                            </div>                        
                    
                    </td>
                    <td colspan="2">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Additional License Issue Date " id="1003" formControlName="1003" (blur)="clearInputIfInvalid('1003')" 
                                    
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #d1003="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1003.toggle()" type="button">
                                                <i class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                        

                                        
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('1003').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1003').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1003').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1003').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1003').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                        
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Additional License Expiration Date " id="1004" formControlName="1004" (blur)="clearInputIfInvalid('1004')" 
                                    
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #d1004="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1004.toggle()" type="button">
                                                <i class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                        
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('1004').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1004').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1004').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1004').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1004').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                        
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <input type="text" placeholder="Year Relinquish" id="cmd_156" formControlName="cmd_156"
                            class="form-control">
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <input type="text" placeholder="Reason" id="cmd_157" formControlName="cmd_157"
                                            class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        
                            <div class="col-md-12">
                                <ng-select [items]="additionalLicenseState" bindLabel="name"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['add_1_1010'].invalid"(blur)="handleNAchange('cmd_NA_3','add_1_1010')"
                                placeholder="Additional License State" bindValue="id"
                                            formControlName="add_1_1010"> </ng-select>
                                            
                            </div>                                           
                    </td>
                    <td colspan="2">                                            
                            <div class="col-md-12">
                                <input type="text" placeholder="Additional License Number " id="add_1_999" formControlName="add_1_999"
                                
                                            class="form-control">
                                             
                                            
                            </div>                        
                    
                    </td>
                    <td colspan="2">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Additional License Issue Date " id="add_1_1003" formControlName="add_1_1003" (blur)="clearInputIfInvalid('add_1_1003')" 
                                    
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #d01003="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d01003.toggle()" type="button">
                                                <i class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                           
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('add_1_1003').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1003').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1003').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1003').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1003').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>                        
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="row">
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Additional License Expiration Date " id="add_1_1004" formControlName="add_1_1004" (blur)="clearInputIfInvalid('add_1_1004')" 
                                            class="form-control datepicker" data-id="priornameeffdate_date"
                                            
                                             ngbDatepicker
                                            #d01004="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d01004.toggle()" type="button">
                                                <i class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                       
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('add_1_1004').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1004').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1004').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1004').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1004').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                            
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <input type="text" placeholder="Year Relinquish" id="cmd_0156" formControlName="cmd_0156"
                            class="form-control">
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <input type="text" placeholder="Reason" id="cmd_0157" formControlName="cmd_0157"
                                            class="form-control">
                        </div>
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>6. UNDERGRADUATE EDUCATION <i>(Do not abbreviate)</i></h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_05')" formControlName="cmd_NA_05"
                            id="cmd_NA_05">
                    </th>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >School/College/University/Vocational Education: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_05')" placeholder=" UG  School" id="503" formControlName="503" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['503'].invalid">
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['503'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Degree Received (be specific, e.g. BS
                                Biology) </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [labelForId]='512' [items]="degree"  (blur)="handleNAchange('cmd_NA_05')"
                             [class.is-invalid]="CancerCareNorthwestForms.controls['512'].invalid"
                                            bindLabel="name" placeholder="Select Degree" bindValue="id" formControlName="512">
                            </ng-select>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['512'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Graduation Date
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="UG School complete Date  "  (blur)="clearInputIfInvalid('511','cmd_NA_05')" (blur)="handleNAchange('cmd_NA_05')" id="511" formControlName="511"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh17="ngbDatepicker"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['511'].invalid">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh17.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                               
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['511'].invalid && (CancerCareNorthwestForms.controls['511'].value==''|| CancerCareNorthwestForms.controls['511'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('511').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('511').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('511').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('511').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('511').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text"  (blur)="handleNAchange('cmd_NA_05')" placeholder="UG School Address1 " id="504" formControlName="504"
                                                class="form-control"
                                                [class.is-invalid]="CancerCareNorthwestForms.controls['504'].invalid">
                                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['504'].invalid">
                                                    <small>
                                                        required
                                                    </small>
                                                    </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text"  placeholder="UG School Address2" id="505" formControlName="505"
                                    class="form-control"
                                    >
                               
                            </div>
                           
                    </td>  
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_05')" placeholder="UG School City" id="506" formControlName="506" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['506'].invalid">
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['506'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div>
                    </td>  
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_05')" [class.is-invalid]="CancerCareNorthwestForms.controls['508'].invalid"
                                            bindLabel="name" placeholder="UG School State" bindValue="id" formControlName="508">
                                        </ng-select>
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['508'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>  
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_05')" placeholder="UG School Zip" id="509" formControlName="509" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['509'].invalid">
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['509'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div>
                    </td>        
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >College or University Name: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Additional School Name " id="1288" formControlName="1288" class="form-control">
                        </div> 
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Degree Received (be specific, e.g. BS
                                Biology) </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="degree"   bindLabel="name" placeholder="Additional school  Degree" bindValue="id" formControlName="1298">
                                        </ng-select>
                                        
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Graduation Date
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Additional School Complete Date " id="1297" formControlName="1297" (blur)="clearInputIfInvalid('1297')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh18="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh18.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('1297').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1297').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1297').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1297').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1297').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address:</label> 
                        </div>
                       
                            <div class="col-md-12">
                                <input type="text" placeholder="Additional School Address1 " id="1289" formControlName="1289"
                                            class="form-control">
                            </div> 
                            <div class="col-md-12">
                                <input type="text" placeholder="Additional School Address2" id="1290" formControlName="1290"
                                            class="form-control">
                            </div> 
                          
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Additional School City " id="1291" formControlName="1291" class="form-control">
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"
                                            bindLabel="name" placeholder="Additional School State " bindValue="id" formControlName="1293">
                                        </ng-select>
                                    
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Additional School Zip" id="1294" formControlName="1294" class="form-control">
                        </div> 
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>7. MASTER DEGREE PROGRAM OR POST GRADUATE EDUCATION</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_06')" formControlName="cmd_NA_06"
                            id="cmd_NA_06">
                    </th>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text"  (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School" id="479" formControlName="479" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['479'].invalid">
            
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['479'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div> 
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text"  (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Address1" id="483" formControlName="483"
                                                        class="form-control"
                                                        [class.is-invalid]="CancerCareNorthwestForms.controls['483'].invalid">
                                    
                                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['483'].invalid">
                                                            <small>
                                                                required
                                                            </small>
                                                            </div>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Address2" id="484" formControlName="484"
                                                        class="form-control"
                                                        [class.is-invalid]="CancerCareNorthwestForms.controls['484'].invalid">
                                    
                                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['484'].invalid">
                                                            <small>
                                                                required
                                                            </small>
                                                            </div>
                            </div> 
                         
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School City" id="485" formControlName="485" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['485'].invalid">
                                    
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['485'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >State </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_06')" [class.is-invalid]="CancerCareNorthwestForms.controls['487'].invalid" bindLabel="name"
                                                        placeholder="Grad School State" bindValue="id" formControlName="487"> </ng-select>
                                    
                                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['487'].invalid">
                                                            <small>
                                                                required
                                                            </small>
                                                            </div>
                        </div> 
                    </td>
                    
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Zip" id="488" formControlName="488" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['488'].invalid">
                                    
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['488'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div> 
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Dates Attended (mm/yyyy - mm/yyyy): </label> 
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input type="text"  (blur)="clearInputIfInvalid('481','cmd_NA_06')" (blur)="handleNAchange('cmd_NA_06')"  placeholder="Grad School Start Date  " id="481" formControlName="481"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh200="ngbDatepicker"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['481'].invalid" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh200.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['481'].invalid && (CancerCareNorthwestForms.controls['481'].value==''|| CancerCareNorthwestForms.controls['481'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms.get('481').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('481').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('481').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('481').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('481').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div> 
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input type="text"  (blur)="clearInputIfInvalid('482','cmd_NA_06')" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Completed Date  " id="482" formControlName="482"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh23="ngbDatepicker"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['482'].invalid">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh23.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['482'].invalid && (CancerCareNorthwestForms.controls['482'].value==''|| CancerCareNorthwestForms.controls['482'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms.get('482').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('482').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('482').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('482').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('482').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>

                            </div>
                        </div>    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program or Course of Study: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Program " [class.is-invalid]="CancerCareNorthwestForms.controls['489'].invalid" id="489"
                                            formControlName="489" class="form-control">
                                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['489'].invalid">
                                                <small>
                                                    required
                                                </small>
                                                </div>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel" >Faculty Director:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_06')" placeholder="Grad School Attention  " id="4074" formControlName="4074"
                                                        class="form-control"
                                                        [class.is-invalid]="CancerCareNorthwestForms.controls['4074'].invalid">
                                    
                                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['4074'].invalid">
                                                            <small>
                                                                required
                                                            </small>
                                                            </div>
                        </div> 
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel" >Degree: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [labelForId]="'480'" [items]="degree"  (blur)="handleNAchange('cmd_NA_06')" [class.is-invalid]="CancerCareNorthwestForms.controls['480'].invalid" bindLabel="name"
                                            placeholder="Grad School Degree" bindValue="id" formControlName="480"> </ng-select>

                                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['480'].invalid">
                                                <small>
                                                    required
                                                </small>
                                                </div>
                        </div> 
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>8. MEDICAL/PROFESSIONAL EDUCATION <i>(Do not abbreviate)</i></h6> </label>
                    </th>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Medical/Professional School: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Medical/Professional School" id="490" formControlName="490" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['490'].touched && CancerCareNorthwestForms.controls['490'].invalid ) || (CancerCareNorthwestForms.controls['490'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['490'].touched && CancerCareNorthwestForms.controls['490'].invalid) || (CancerCareNorthwestForms.controls['490'].invalid && isSubmit)"> Required </div>
                                    
                            
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Start Date:
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Start Date" id="497" (blur)="clearInputIfInvalidMandi('497')" formControlName="497" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa19="ngbDatepicker" [class.is-invalid]="(CancerCareNorthwestForms.controls['497'].touched && CancerCareNorthwestForms.controls['497'].invalid ) || (CancerCareNorthwestForms.controls['497'].invalid && isSubmit)">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="wpa19.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                                               
                                            </div>   
                                            <div class="text-danger"
                                            *ngIf="(CancerCareNorthwestForms.controls['497'].invalid&&CancerCareNorthwestForms.controls['497'].touched) && (CancerCareNorthwestForms.controls['497'].value==''|| CancerCareNorthwestForms.controls['497'].value==null) ">
                                            <small>
                                                required
                                            </small>
                                        
                                        </div>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('497').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('497').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('497').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('497').errors?.invalidDateRange">
                                            date is out of range
                                        </small>                   
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Graduation Date
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Completed Date" id="499" formControlName="499"
                                    (blur)="clearInputIfInvalidMandi('499')" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #wpa20="ngbDatepicker"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['499'].touched && CancerCareNorthwestForms.controls['499'].invalid ) || (CancerCareNorthwestForms.controls['499'].invalid && isSubmit)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="wpa20.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                        
                        
                            </div>
                            <div class="text-danger"
                                *ngIf="(CancerCareNorthwestForms.controls['499'].invalid&&CancerCareNorthwestForms.controls['499'].touched) && (CancerCareNorthwestForms.controls['499'].value==''|| CancerCareNorthwestForms.controls['499'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('499').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('499').errors?.invalidMonth">
                                Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('499').errors?.invalidDateyear">
                                year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('499').errors?.invalidDateRange">
                                date is out of range
                            </small>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Degree Received </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="degree" [class.is-invalid]="(CancerCareNorthwestForms.controls['500'].touched &&
                                CancerCareNorthwestForms.controls['500'].invalid) ||
                                (CancerCareNorthwestForms.controls['500'].invalid && isSubmit)" bindLabel="name" placeholder="Enter Degree" bindValue="id" formControlName="500"> </ng-select>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['500'].touched && CancerCareNorthwestForms.controls['500'].invalid) || (CancerCareNorthwestForms.controls['500'].invalid && isSubmit)"> Degree is required and cannot be empty </div>
                                        
                        </div> 
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 1" id="491" formControlName="491" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['491'].touched && CancerCareNorthwestForms.controls['491'].invalid ) || (CancerCareNorthwestForms.controls['491'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['491'].touched && CancerCareNorthwestForms.controls['491'].invalid) || (CancerCareNorthwestForms.controls['491'].invalid && isSubmit)"> Required </div>
                                    
                            </div> 
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 2" id="492" formControlName="492" class="form-control" >
                                                                                    
                            </div>
                           
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="493" formControlName="493" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['493'].touched && CancerCareNorthwestForms.controls['493'].invalid ) || (CancerCareNorthwestForms.controls['493'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['493'].touched && CancerCareNorthwestForms.controls['493'].invalid) || (CancerCareNorthwestForms.controls['493'].invalid && isSubmit)"> Required </div>
                                        
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State:</label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" [class.is-invalid]="(CancerCareNorthwestForms.controls['495'].touched &&
                                CancerCareNorthwestForms.controls['495'].invalid) ||
                                (CancerCareNorthwestForms.controls['495'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="495"> </ng-select>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['495'].touched && CancerCareNorthwestForms.controls['495'].invalid) || (CancerCareNorthwestForms.controls['495'].invalid && isSubmit)"> State is required and cannot be empty </div>
                                        
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip Code " id="496" formControlName="496" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['496'].touched && CancerCareNorthwestForms.controls['496'].invalid ) || (CancerCareNorthwestForms.controls['496'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['496'].touched && CancerCareNorthwestForms.controls['496'].invalid) || (CancerCareNorthwestForms.controls['496'].invalid && isSubmit)"> Required </div>
                                        
                        </div> 
                    </td>
                </tr>   
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Medical/Professional School: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional School" id="add_1_1288" formControlName="add_1_1288" class="form-control"> 
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Start Date:
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Start Date" id="add_1_1295" formControlName="add_1_1295" (blur)="clearInputIfInvalid('add_1_1295')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa21="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="wpa21.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                            </div>     
                            <div *ngIf="CancerCareNorthwestForms.get('add_1_1295').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1295').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1295').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1295').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1295').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>            
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Graduation Date
                                (mm/yyyy) </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Complete Date " id="add_1_1297"  formControlName="add_1_1297" (blur)="clearInputIfInvalid('add_1_1297')" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa22="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="wpa22.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('add_1_1297').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1297').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1297').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1297').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1297').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Degree Received </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="degree"  bindLabel="name" placeholder="Enter Degree" bindValue="id" formControlName="add_1_1298"> </ng-select>
                                            
                        </div> 
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Additional School Address 1" id="add_1_1289" formControlName="add_1_1289" class="form-control">
                            </div> 
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Additional School Address 2" id="add_1_1290" formControlName="add_1_1290" class="form-control">
                            </div>
                        
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="add_1_1291" formControlName="add_1_1291" class="form-control">
                        </div> 
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State:</label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_1_1293"> </ng-select>
                                        
                        </div> 
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip code" id="add_1_1294" formControlName="add_1_1294" class="form-control">
                        </div> 
                    </td>
                </tr>  
                </table>
                <table border="1" width="100%" class="center"  >   
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>9. INTERNSHIP/PGYI(Attach Additional Sheet if Necessary)</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_07')" formControlName="cmd_NA_07"
                            id="cmd_NA_07">
                    </th>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['517'].invalid" placeholder="Intern School " id="517"
                            formControlName="517" class="form-control">
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['517'].invalid">
                                <small>
                                    required
                                </small>
                                </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1318'].invalid" placeholder="Intern Director Phone "
                                        id="1318" formControlName="1318" class="form-control"> <small class="text-danger"
                                        *ngIf="CancerCareNorthwestForms.controls['1318'].errors?.pattern">
                                        Only number are allowed</small>
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['1318'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1319'].invalid" placeholder="Intern Director Fax " id="1319"
                            formControlName="1319" class="form-control">
                            <small class="text-danger"
                                        *ngIf="CancerCareNorthwestForms.controls['1319'].errors?.pattern">
                                        Only number are allowed</small>
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['1319'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['528'].invalid" placeholder="Intern Director "
                                        id="528" formControlName="528" class="form-control">
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['528'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['518'].invalid" placeholder="Intern Address1"
                                        id="518" formControlName="518" class="form-control">
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['518'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" 
                                     placeholder="Intern Address2"
                                        id="519" formControlName="519" class="form-control">
                                    
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['520'].invalid" placeholder="Intern City " id="520"
                                        formControlName="520" class="form-control">
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['520'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_07')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['522'].invalid"
                                    bindLabel="name" placeholder="Intern State" bindValue="id" formControlName="522"> </ng-select>

                                    <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['522'].invalid">
                                        <small>
                                            required
                                        </small>
                                        </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['523'].invalid" placeholder="Intern Zip" id="523"
                                        formControlName="523" class="form-control">
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['523'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>

                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Type of Internship: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_07')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['2539'].invalid" placeholder="Internship Department "
                                        id="2539" formControlName="2539" class="form-control">
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['2539'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>

                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Specialty: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="specialty" (blur)="handleNAchange('cmd_NA_07')" [class.is-invalid]="CancerCareNorthwestForms.controls['527'].invalid"
                                        bindLabel="name" placeholder=" Intern Primary Specialty" bindValue="id" formControlName="527"> </ng-select>
                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['527'].invalid">
                                            <small>
                                                required
                                            </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >From (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" (blur)="clearInputIfInvalid('524','cmd_NA_07')"  (blur)="handleNAchange('cmd_NA_07')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['524'].invalid" placeholder="Intern Start Date "
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh24="ngbDatepicker"
                                    id="524" formControlName="524">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh24.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['524'].invalid && (CancerCareNorthwestForms.controls['524'].value==''|| CancerCareNorthwestForms.controls['524'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('524').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('524').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('524').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('524').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('524').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >To (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" (blur)="clearInputIfInvalid('526','cmd_NA_07')" (blur)="handleNAchange('cmd_NA_07')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['526'].invalid" placeholder="Intern Complete Date  "
                                    id="526" formControlName="526" class="form-control datepicker" data-id="priornameeffdate_date"
                                    ngbDatepicker #idh25="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh25.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['526'].invalid && (CancerCareNorthwestForms.controls['526'].value==''|| CancerCareNorthwestForms.controls['526'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('526').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('526').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('526').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('526').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('526').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>10. RESIDENCIES (Attach Additional Sheet if Necessary)</h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_08')" formControlName="cmd_NA_08"
                            id="cmd_NA_08">
                    </th>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['529'].invalid"
                                placeholder="Residency School " id="529" formControlName="529" class="form-control">
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['529'].invalid">
                                    <small>
                                        required
                                    </small>
                                    </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1316'].invalid"
                                    placeholder="Residency Phone" id="1316" formControlName="1316" class="form-control"> <small
                                    class="text-danger" *ngIf="CancerCareNorthwestForms.controls['1316'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1316'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1317'].invalid"
                            placeholder="Residency Fax" id="1317" formControlName="1317" class="form-control">
                            <small
                                    class="text-danger" *ngIf="CancerCareNorthwestForms.controls['1317'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1317'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['540'].invalid"
                                placeholder="Residency Director " id="540" formControlName="540" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['540'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['531'].invalid"
                                        placeholder="Residency Address1 " id="531" formControlName="531" class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['531'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" 
                                    
                                    placeholder="Residency Address2" id="1498" formControlName="1498" class="form-control">
                                
                            </div>
                        
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['533'].invalid"
                                placeholder="Residency  city" id="533" formControlName="533" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['533'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['535'].invalid"
                                    bindLabel="name" placeholder="Residency State" bindValue="id" formControlName="535"> </ng-select>
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['535'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['536'].invalid"
                                placeholder="Residency Zip" id="536" formControlName="536" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['536'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>

                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Type of Internship: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['2540'].invalid"
                                placeholder="Residency Department " id="2540" formControlName="2540" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['2540'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>

                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Specialty: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="specialty" (blur)="handleNAchange('cmd_NA_08')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['530'].invalid"
                                                    bindLabel="name" placeholder=" Residency Primary Specialty" bindValue="id" formControlName="530"> </ng-select>
                                                    <div class="mandatory"
                                                    *ngIf="CancerCareNorthwestForms.controls['530'].invalid">
                                                    <small >
                                                    required
                                                    </small>
                                                </div>
                                            
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >From (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <div class="input-group">
                                    <input type="text" (blur)="clearInputIfInvalid('537','cmd_NA_08')" (blur)="handleNAchange('cmd_NA_08')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['537'].invalid"
                                        placeholder="Residency Start  " id="537" formControlName="537" class="form-control datepicker"
                                        data-id="priornameeffdate_date" ngbDatepicker #idh26="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh26.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['537'].invalid && (CancerCareNorthwestForms.controls['537'].value==''|| CancerCareNorthwestForms.controls['537'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('537').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('537').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('537').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('537').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('537').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >To (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" (blur)="clearInputIfInvalid('538','cmd_NA_08')" (blur)="handleNAchange('cmd_NA_08')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['538'].invalid"
                                    placeholder="Residency End" id="538" formControlName="538" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh27="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh27.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['538'].invalid && (CancerCareNorthwestForms.controls['538'].value==''|| CancerCareNorthwestForms.controls['538'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('538').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('538').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('538').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('538').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('538').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label >Did you successfully complete the program?
                                </label>&nbsp;&nbsp;&nbsp;
                            </div>    
                                <div class="col-md-5">
                                    <input type="radio" formControlName="cmd_164" (blur)="handleNAchange('cmd_NA_08')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_164'].invalid"
                                        id="cmd_164" name="cmd_164" value="1">
                                    <label>Yes&nbsp;&nbsp;</label>
                                
                                    <input type="radio" formControlName="cmd_164" (blur)="handleNAchange('cmd_NA_08')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_164'].invalid"
                                        id="cmd_164" name="cmd_164" value="0">
                                    <label>No &nbsp;&nbsp; (If "No", please explain on separate sheet.)</label>
                                    <div class=" mandatory formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_164'].invalid">
                                    <small >
                                    required
                                    </small>
                                    </div>
                                    <span
                                        *ngIf="CancerCareNorthwestForms.controls['cmd_164'].invalid">
                                        <i class="fas fa-times ms-2 text-danger"></i>
                                    </span>
                                </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Additional Residency Schools " id="1170" formControlName="1170" class="form-control">
                                            
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text"  id="1320" placeholder="Additional Residency Director Phone" formControlName="1320" class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('1320').invalid && CancerCareNorthwestForms.get('1320').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1320').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>  
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Additional Residency Director Fax" id="1321" formControlName="1321" class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('1321').invalid && CancerCareNorthwestForms.get('1321').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1321').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>                  
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Program Director" id="1181" formControlName="1181" class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="1172" formControlName="1172" class="form-control"> 
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address" id="1173" formControlName="1173" class="form-control"> 
                            </div>
                        
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="1174" formControlName="1174" class="form-control"> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="1176"> </ng-select>
                                            

                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip Code" id="1177" formControlName="1177" class="form-control">
                        </div>
                    </td>

                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Type of Internship: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Type of Residency" id="2541" formControlName="2541" class="form-control">
                        </div>

                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Specialty: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="specialty" bindLabel="name" placeholder="Primary Specialty" bindValue="id" formControlName="1171"> </ng-select>
                                            
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >From (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Start Date" id="1178" formControlName="1178" (blur)="clearInputIfInvalid('1178')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa27="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa27.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                </div>
                                
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('1178').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1178').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1178').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1178').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1178').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >To (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Complete Date" id="1179" formControlName="1179" (blur)="clearInputIfInvalid('1179')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa28="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="wpa28.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div> 
                            <div *ngIf="CancerCareNorthwestForms.get('1179').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1179').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1179').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1179').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1179').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label >Did you successfully complete the program? </label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" id="cmd_165" name="cmd_165" formControlName="cmd_165" value="1">
                                <label>Yes</label>&nbsp;&nbsp;
                                <input type="radio" id="cmd_165" name="cmd_165" formControlName="cmd_165" value="0">
                                <label>No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(If "No", please explain on separate sheet.)</label>
                            </div>
                        </div>
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>11. FELLOWSHIPS (Attach Additional Sheet if Necessary)</h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_09')" formControlName="cmd_NA_09"
                            id="cmd_NA_09">
                    </th>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['542'].invalid"
                            placeholder="Fellowship School " id="542" formControlName="542" class="form-control">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['542'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                                </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship Phone" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1322'].invalid" id="1322" formControlName="1322"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['1322'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1322'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                                </div>      
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <!-- <div class="col-md-12">
                            <input type="text" placeholder="Fellowship Fax" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1323'].invalid"
                            class="form-control">
                            <small class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['1323'].errors?.pattern">
                                    Only number are allowed</small>

                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1323'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div> -->
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship Fax" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1323'].invalid" id="1323" formControlName="1323"
                                    class="form-control"> <small class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['1323'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1323'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                                </div>   
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship Director" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['553'].invalid" id="553" formControlName="553"
                            class="form-control">
                            <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['553'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_09')" placeholder="Fellowship Address 1"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['544'].invalid" id="544" formControlName="544"
                                        class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['544'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Fellowship Address 2"  id="545" formControlName="545"
                                        class="form-control">
                                   
                            </div>
                        
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship City" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['546'].invalid" id="546"
                                        formControlName="546" class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['546'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_09')"[class.is-invalid]="CancerCareNorthwestForms.controls['548'].invalid"
                                        bindLabel="name" placeholder="Fellowship  State" bindValue="id" formControlName="548"> </ng-select>
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['548'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship  Zip" (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['549'].invalid" id="549" formControlName="549"
                                        class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['549'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>

                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Course of Study:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Fellowship Department " (blur)="handleNAchange('cmd_NA_09')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['2542'].invalid" id="2542" formControlName="2542"
                                        class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['2542'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>

                    </td>
                    
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >From (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Fellowship  Start Date" id="550" (blur)="clearInputIfInvalid('550','cmd_NA_09')" (blur)="handleNAchange('cmd_NA_09')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['550'].invalid" formControlName="550"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh30="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh30.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['550'].invalid && (CancerCareNorthwestForms.controls['550'].value==''|| CancerCareNorthwestForms.controls['550'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('550').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('550').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('550').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('550').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('550').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                    
                
                        </div>    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >To (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Fellowship Complete Date " (blur)="clearInputIfInvalid('552','cmd_NA_09')"  (blur)="handleNAchange('cmd_NA_09')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['552'].invalid" id="552" formControlName="552"
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh31="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh31.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['552'].invalid && (CancerCareNorthwestForms.controls['552'].value==''|| CancerCareNorthwestForms.controls['552'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('552').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('552').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('552').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('552').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('552').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                    
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div class="row">
                                <div class="col-md-4">
                                    <label class="formLabel">Did you successfully complete the program?
                                    </label>&nbsp;&nbsp;&nbsp;
                                </div>    
                                <div class="col-md-6">
                                    <input type="radio" formControlName="cmd_167" (blur)="handleNAchange('cmd_NA_09')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_167'].invalid"
                                        id="cmd_167" name="cmd_167" value="1">
                                    <label class="formLabel">Yes&nbsp;&nbsp;</label>
                                    
                                    <input type="radio" formControlName="cmd_167" (blur)="handleNAchange('cmd_NA_09')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_167'].invalid"
                                        id="cmd_167" name="cmd_167" value="0">
                                    <label class="formLabel">No &nbsp;(If "No", please explain on separate sheet.)</label>
                                    <!-- <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms.controls['cmd_167'].touched && CancerCareNorthwestForms.controls['cmd_167'].invalid">
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_167'].errors?.required">
                                            required
                                        </small>
                                    </div> -->
                                    <div class="mandatory formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_167'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                                    <span
                                        *ngIf="CancerCareNorthwestForms.controls['cmd_167'].invalid">
                                        <i class="fas fa-times ms-2 text-danger"></i>
                                    </span>
                                </div>
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Institution" id="966" formControlName="966" class="form-control"> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Phone Number:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Phone Number" id="1324" formControlName="1324" class="form-control"> 
                            <div  *ngIf="CancerCareNorthwestForms.get('1324').invalid && CancerCareNorthwestForms.get('1324').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1324').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>   
                        </div>      
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Fax Number" id="1325" formControlName="1325" class="form-control"> 
                            <div  *ngIf="CancerCareNorthwestForms.get('1325').invalid && CancerCareNorthwestForms.get('1325').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1325').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Program Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Program Director" id="983" formControlName="983" class="form-control"> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Mailing Address: </label> 
                        </div>
                      
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Additional Fellowship Mailing Address 1" id="968" formControlName="968" class="form-control">
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Additional Fellowship Mailing Address 2" id="969" formControlName="969" class="form-control">
                            </div>
                   
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship city" id="970" formControlName="970" class="form-control">
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter Additional Fellowship state" bindValue="id" formControlName="972"> </ng-select>
                                
                        </div>
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Zip Code" id="1301" formControlName="1301" class="form-control">
                        </div>
                    </td>
                
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Course of Study:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Additional Fellowship Course of Study" id="2543" formControlName="2543" class="form-control">
                        </div>
                
                    </td>
                    
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >From (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Additional Fellowship Start Date" id="974" (blur)="clearInputIfInvalid('974')"  formControlName="974" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa31="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="wpa31.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('974').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('974').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('974').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('974').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('974').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                
                                    
                
                        </div>    
                    </td>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel" >To (mm/yyyy): </label> 
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Additional Fellowship Complete Date" id="975" formControlName="975"  (blur)="clearInputIfInvalid('975')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa0031="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="wpa0031.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div> </div>
                                <div *ngIf="CancerCareNorthwestForms.get('975').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('975').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('975').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('975').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('975').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                                    
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Did you successfully complete the program? </label>&nbsp;&nbsp;&nbsp;
                                        <input type="radio" id="cmd_168" name="cmd_168" formControlName="cmd_168" value="1">
                                        <label>Yes</label>&nbsp;&nbsp;
                                        <input type="radio" id="cmd_168" name="cmd_168" formControlName="cmd_168" value="0">
                                        <label>No&nbsp;(If "No", please explain on separate sheet.)</label>
                        
                            </div>   
                        </div>     
                    </td>
                </tr>
                </table>
                <table border="1" width="100%" class="center"  >  
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>12. PRECEPTORSHIP (Attach Additional Sheet if Necessary)</h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_10')" formControlName="cmd_NA_10"
                            id="cmd_NA_10">
                    </th>
                </tr> 
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1501806'].invalid"
                                placeholder=" Institution " id="cmd_1501806" formControlName="cmd_1501806" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_1501806'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" > Address: </label> 
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1511806'].invalid"
                                        placeholder=" Address1 " id="cmd_1511806" formControlName="cmd_1511806" class="form-control">
                                        <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1511806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1521806'].invalid"
                                    placeholder=" Address2" id="cmd_1521806" formControlName="cmd_1521806" class="form-control">
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1521806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                      
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1531806'].invalid"
                                placeholder="city" id="cmd_1531806" formControlName="cmd_1531806" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1531806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1541806'].invalid"
                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_1541806"> </ng-select>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1541806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1551806'].invalid"
                                placeholder=" Zip" id="cmd_1551806" formControlName="cmd_1551806" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1551806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
        
                </tr>
                <tr>    
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Telephone Number</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1561806'].invalid"
                                    placeholder=" Phone" id="cmd_1561806" formControlName="cmd_1561806" class="form-control"> <small
                                    class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_1561806'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1561806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1571806'].invalid"
                            placeholder=" Fax" id="cmd_1571806" formControlName="cmd_1571806" class="form-control">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_1571806'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['cmd_1571806'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Email Address </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1581806'].invalid"
                                placeholder="Email " id="cmd_1581806" formControlName="cmd_1581806" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1581806'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                </tr>
                
                <tr>
                    
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Dates Attended (mm/yyyy - mm/yyyy): </label> 
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                            
                                    <div class="input-group">
                                        <input type="text" (blur)="clearInputIfInvalid('cmd_1591806','cmd_NA_10')"  (blur)="handleNAchange('cmd_NA_10')"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1591806'].invalid"
                                            id="cmd_1591806" formControlName="cmd_1591806" class="form-control datepicker"
                                            data-id="priornameeffdate_date" ngbDatepicker #d1591806="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1591806.toggle()" type="button"> <i
                                                    class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1591806'].invalid && (CancerCareNorthwestForms.controls['cmd_1591806'].value==''|| CancerCareNorthwestForms.controls['cmd_1591806'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms.get('cmd_1591806').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1591806').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1591806').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1591806').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1591806').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                                
                            </div>    
                            <div class="col-md-6">
                                <div class="input-group">
                                    <input type="text" (blur)="clearInputIfInvalid('cmd_1601806','cmd_NA_10')" (blur)="handleNAchange('cmd_NA_10')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1601806'].invalid"
                                        id="cmd_1601806" formControlName="cmd_1601806" class="form-control datepicker"
                                        data-id="priornameeffdate_date" ngbDatepicker #d1601806="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d1601806.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_1601806'].invalid && (CancerCareNorthwestForms.controls['cmd_1601806'].value==''|| CancerCareNorthwestForms.controls['cmd_1601806'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('cmd_1601806').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1601806').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1601806').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1601806').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_1601806').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                            </div>
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Training:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1611806'].invalid"
                                placeholder="Training " id="cmd_1611806" formControlName="cmd_1611806" class="form-control">
                                <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_1611806'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>

                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Department Chairman: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_10')" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_1621806'].invalid"
                                                    bindLabel="name" placeholder=" Director" bindValue="id" formControlName="cmd_1621806"> 
                                                    <div class="mandatory"
                                                    *ngIf="CancerCareNorthwestForms.controls['cmd_1621806'].invalid">
                                                    <small >
                                                    required
                                                    </small>
                                                </div>   
                                            
                        </div>
                    </td>
                </tr>
            </table>
            <table width="100%" class="center" border="2">      
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>13. FACULTY/TEACHING APPOINTMENTS (Attach Additional Sheet if Necessary)</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_11')" formControlName="cmd_NA_11"
                            id="cmd_NA_11">
                    </th>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Institution: </label> 
                        </div>
                        <div class="col-md-12">
                        
                                        <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['1146'].invalid"
                                        placeholder="Primary Institution Name " id="1146" formControlName="1146"
                                        class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1146'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>         
                        </div>
                    </td>
                    
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" > Address: </label> 
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                
                                <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['1148'].invalid"
                                        placeholder="Primary Institution Address1 " id="1148" formControlName="1148" class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1148'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                            <div class="col-md-6">
                                
                                <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                                [class.is-invalid]="CancerCareNorthwestForms.controls['1149'].invalid"
                                        placeholder="Primary Institution Address2" id="1149" formControlName="1149" class="form-control">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1149'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >City: </label> 
                        </div>
                        <div class="col-md-12">
                            
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1150'].invalid"
                            placeholder="Primary Institution City " id="1150" formControlName="1150" class="form-control">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['1150'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >State: </label> 
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1152'].invalid"
                                    bindLabel="name" placeholder=" State" bindValue="id" formControlName="1152"> </ng-select>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1152'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    
        
                </tr>
                <tr>    
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Zip Code: </label> 
                        </div>
                        <!-- <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1153'].invalid"
                            class="form-control">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['1153'].invalid">
                            <small >
                            required
                            </small>
                        </div>   
                        </div> -->
                        <div class="col-md-12">
                        
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1153'].invalid"
                             id="1153" formControlName="1153"
                            class="form-control">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['1153'].invalid">
                            <small >
                            required
                            </small>
                        </div>         
            </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Telephone Number</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['4142'].invalid"
                            placeholder="Primary Institution Phone" id="4142" formControlName="4142"
                            class="form-control">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms.controls['4142'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['4142'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Fax Number: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_171'].invalid"
                            placeholder="Primary Institution Fax" id="cmd_171" formControlName="cmd_171"
                            class="form-control">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_171'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['cmd_171'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel" >Email Address </label> 
                        </div>
                        <div class="col-md-12">
                            
                            <input type="text" (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_0171'].invalid"
                            placeholder="Primary Institution Email" id="cmd_0171" formControlName="cmd_0171"
                            class="form-control">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['cmd_0171'].invalid">
                            <small >
                            required
                            </small>
                        </div>       
                        </div>
                    </td>
                </tr>
                
               <tr>
                    
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Dates Attended (mm/yyyy - mm/yyyy): </label> 
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                            
                                <div class="input-group">
                                    <input type="text" (blur)="clearInputIfInvalid('1155','cmd_NA_11')" (blur)="handleNAchange('cmd_NA_11')"
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['1155'].invalid"
                                        placeholder="Primary Position Start Date " id="1155" formControlName="1155"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh36="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="idh36.toggle()" type="button"> <i
                                                    class="fas fa-calendar-alt"></i> </button>
                                        </div>
                                </div>
                                <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['1155'].invalid && (CancerCareNorthwestForms.controls['1155'].value==''|| CancerCareNorthwestForms.controls['1155'].value==null) ">
                                <small>
                                  required
                                </small>
                              
                              </div>
                              <div *ngIf="CancerCareNorthwestForms.get('1155').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1155').errors.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1155').errors.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1155').errors.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1155').errors.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                                
                            </div>    
                            <div class="col-md-6">
                                
                                    <div class="input-group">
                                        <input type="text" (blur)="clearInputIfInvalid('1156','cmd_NA_11')" (blur)="handleNAchange('cmd_NA_11')"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['1156'].invalid"
                                            placeholder="Primary Position Term Date " id="1156" formControlName="1156"
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                            #idh37="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="idh37.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                    </div>
                                    <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['1156'].invalid && (CancerCareNorthwestForms.controls['1156'].value==''|| CancerCareNorthwestForms.controls['1156'].value==null) ">
                                    <small>
                                      required
                                    </small>
                                  
                                  </div>
                                  <div *ngIf="CancerCareNorthwestForms.get('1156').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1156').errors.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1156').errors.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1156').errors.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1156').errors.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Position:</label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Primary Position Rank or Title " id="1154" formControlName="1154" class="form-control"
                            (blur)="handleNAchange('cmd_NA_11')"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['1154'].invalid"
                            >
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['1154'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                                </div>

                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel" >Faculty Director: </label> 
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Primary Institution Chairman " id="1382" formControlName="1382"
                                        class="form-control"
                                        (blur)="handleNAchange('cmd_NA_11')" 
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['1382'].invalid"
                                        bindLabel="name" > 
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['1382'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div>           
                                            
                        </div>
                    </td>
                </tr>
            </table>
            <table border="2" width="100%" class="center" >     
                <tr>
                    <th colspan="7" border="2">
                        
                            <label class="sideheading"  >
                                <h6>14. BOARD CERTIFICATION</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_12')" formControlName="cmd_NA_12"
                            id="cmd_NA_12">
                    </th>
                    
                </tr> 
                <tr>
                    <td colspan="12">
                        <b>Are you board or otherwise professionally certified?</b>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <label class="checkbox-inline">
                            <input type="radio" id="cmd_008"
                            (blur)="handleNAchange('cmd_NA_12')"
                            (ngModelChange)="handleYesNoChanges('cmd_008')"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_008'].touched &&
                                            CancerCareNorthwestForms.controls['cmd_008'].invalid) ||
                                        (CancerCareNorthwestForms.controls['cmd_008'].invalid && isSubmit)"
                                        name="cmd_008"  formControlName="cmd_008" value="1">
                                        <b>Yes</b>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;If "Yes", please complete
                            below:
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_008'].invalid">
                                required  </div> </label>
                        
                    </td>
                    <td colspan="9">
                        <label class="checkbox-inline">
                            <input type="radio" id="cmd_008" (blur)="handleNAchange('cmd_NA_12')"
                            (ngModelChange)="handleYesNoChanges('cmd_008')"[class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_008'].touched &&
                                            CancerCareNorthwestForms.controls['cmd_008'].invalid) ||
                                        (CancerCareNorthwestForms.controls['cmd_008'].invalid && isSubmit)" name="cmd_008"  formControlName="cmd_008" value="0">
                                        
                                        <b>No</b>&nbsp;&nbsp;&nbsp;If "No", describe your intent for certification, if any, and dates of testing for
                            Certification on separate sheet.<div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_008'].invalid">
                                required  </div> </label>
                        
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >Issuing Board/Entity and State Issued </label> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel" >Specialty </label> 
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >Date Certified </label> 
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel" >Date Recertified </label> 
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel" >Expiration Date (if any)</label> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div class="col-md-12">
                            <ng-select
                            [items]="boardCertification"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['554'].invalid"                      
                            bindLabel="name"
                            placeholder="Select Primary Certificate"
                            bindValue="id"
                            formControlName="554"
                        >
                        </ng-select>
                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['554'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_175'].invalid"                      
                            bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_175">
                            </ng-select>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_175'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <ng-select [items]="specialty"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['556'].invalid"   bindLabel="name" placeholder="Primary Specialty" bindValue="id" formControlName="556">
                                </ng-select>
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['556'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>                    
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="557"
                                (blur)="clearInputIfInvalid('557','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['557'].invalid"  formControlName="557" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh38="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh38.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['557'].invalid && (CancerCareNorthwestForms.controls['557'].value==''|| CancerCareNorthwestForms.controls['557'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('557').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('557').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('557').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('557').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('557').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>         
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="558"
                                (blur)="clearInputIfInvalid('558','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['558'].invalid"  formControlName="558" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh39="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh39.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['558'].invalid && (CancerCareNorthwestForms.controls['558'].value==''|| CancerCareNorthwestForms.controls['558'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('558').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('558').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('558').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('558').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('558').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                            
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="560"
                                (blur)="clearInputIfInvalid('560','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                                [class.is-invalid]="CancerCareNorthwestForms.controls['560'].invalid"   formControlName="560" class="form-control datepicker"
                                data-id="priornameeffdate_date" ngbDatepicker #idh40="ngbDatepicker" 
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh40.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['560'].invalid && (CancerCareNorthwestForms.controls['560'].value==''|| CancerCareNorthwestForms.controls['560'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('560').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('560').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('560').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('560').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('560').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div class="col-md-12">
                            <ng-select
                            [items]="boardCertification"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['561'].invalid"                      
                            bindLabel="name"
                            placeholder="Select Secondary Certificate"
                            bindValue="id"
                            formControlName="561"
                        >
                        </ng-select>
                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['561'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_176'].invalid"                      
                            bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_176">
                            </ng-select>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_176'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <ng-select [items]="specialtySecondary"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['563'].invalid"   bindLabel="name" placeholder="Secondary Specialty" bindValue="id" formControlName="563">
                                </ng-select>
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['563'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>                    
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="564"
                                (blur)="clearInputIfInvalid('564','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['564'].invalid"  formControlName="564" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh038="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh038.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['564'].invalid && (CancerCareNorthwestForms.controls['564'].value==''|| CancerCareNorthwestForms.controls['564'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('564').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('564').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('564').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('564').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('564').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>       
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="565"
                                (blur)="clearInputIfInvalid('565','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['565'].invalid"  formControlName="565" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh039="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh039.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['565'].invalid && (CancerCareNorthwestForms.controls['565'].value==''|| CancerCareNorthwestForms.controls['565'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('565').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('565').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('565').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('565').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('565').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="567"
                                (blur)="clearInputIfInvalid('567','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                                [class.is-invalid]="CancerCareNorthwestForms.controls['567'].invalid"   formControlName="567" class="form-control datepicker"
                                data-id="priornameeffdate_date" ngbDatepicker #idh040="ngbDatepicker" 
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh040.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['567'].invalid && (CancerCareNorthwestForms.controls['567'].value==''|| CancerCareNorthwestForms.controls['567'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('567').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('567').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('567').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('567').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('567').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <div class="col-md-12">
                            <ng-select
                            [items]="boardCertification"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['946'].invalid"                      
                            bindLabel="name"
                            placeholder="Select Third Certificate"
                            bindValue="id"
                            formControlName="946"
                        >
                        </ng-select>
                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['946'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_177'].invalid"                      
                            bindLabel="name" placeholder=" State" bindValue="id" formControlName="cmd_177">
                            </ng-select>
                            <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_177'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <ng-select [items]="specialtyTer"
                            (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['948'].invalid"   bindLabel="name" 
                            placeholder="Teritary Specialty" bindValue="id" formControlName="948">
                                </ng-select>
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['948'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>                    
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="953"
                                (blur)="clearInputIfInvalid('953','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['953'].invalid"  formControlName="953" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh138="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh138.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['953'].invalid && (CancerCareNorthwestForms.controls['953'].value==''|| CancerCareNorthwestForms.controls['953'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('953').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('953').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('953').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('953').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('953').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>       
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="950"
                                (blur)="clearInputIfInvalid('950','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                            [class.is-invalid]="CancerCareNorthwestForms.controls['950'].invalid"  formControlName="950" class="form-control datepicker"
                                    data-id="priornameeffdate_date" ngbDatepicker #idh139="ngbDatepicker"
                                    >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh139.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['950'].invalid && (CancerCareNorthwestForms.controls['950'].value==''|| CancerCareNorthwestForms.controls['950'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('950').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('950').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('950').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('950').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('950').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                        </div>
                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="954"
                                (blur)="clearInputIfInvalid('954','cmd_008')"
                                (blur)="handleYesNoChanges('cmd_008')" 
                                [class.is-invalid]="CancerCareNorthwestForms.controls['954'].invalid"   formControlName="954" class="form-control datepicker"
                                data-id="priornameeffdate_date" ngbDatepicker #idh140="ngbDatepicker" 
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh140.toggle()"
                                        type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['954'].invalid && (CancerCareNorthwestForms.controls['954'].value==''|| CancerCareNorthwestForms.controls['954'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('954').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('954').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('954').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('954').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('954').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-5">
                                <label class="formLabel" >Have you applied for certification other than those indicated above? </label> 
                            </div>
                            <div class="col-md-6">    
                                <input type="radio" id="cmd_0081"
                                (blur)="handleNAchange('cmd_NA_12')"
                                (ngModelChange)="handleYesNoChanges('cmd_0081')"
                                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_0081'].touched &&
                                                CancerCareNorthwestForms.controls['cmd_0081'].invalid) ||
                                            (CancerCareNorthwestForms.controls['cmd_0081'].invalid && isSubmit)"
                                            name="cmd_0081"  formControlName="cmd_0081" value="1"><label class="formLabel" >Yes</label>
                                <input type="radio" id="cmd_0081"
                                            (blur)="handleNAchange('cmd_NA_12')"
                                            (ngModelChange)="handleYesNoChanges('cmd_0081')"
                                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_0081'].touched &&
                                                            CancerCareNorthwestForms.controls['cmd_0081'].invalid) ||
                                                        (CancerCareNorthwestForms.controls['cmd_0081'].invalid && isSubmit)"
                                                        name="cmd_0081"  formControlName="cmd_0081" value="0"><label class="formLabel" >No</label>
                                                    
                                                        <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_0081'].invalid">
                                        required 
                                    </div> 
                            </div>
                        </div>    
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">If so, list certification and date: </label> 
                            </div>
                            <div class="col-md-8">
                                
                                    <input type="text" id="cmd_0008"
                                    (blur)="handleYesNoChanges('cmd_0081')" 
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_0008'].invalid"   formControlName="cmd_0008" class="form-control"
                                    
                                    >
                                    
                        
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_0008'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>  
                            
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Certification number if applicable: </label> 
                            </div>
                            <div class="col-md-8">
                                
                                    <input type="text" id="cmd_00081"
                                    (blur)="handleNAchange('cmd_NA_12')" 
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_00081'].invalid"   formControlName="cmd_00081" class="form-control"
                                    
                                    >
                                    
                        
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_00081'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>  
                            
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">If you participate in a specialty which does not have board certification, please indicate specialty: </label> 
                            </div>
                            <div class="col-md-12">
                                
                                    <input type="text" id="cmd_00082"
                                    (blur)="handleNAchange('cmd_NA_12')" 
                                    [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_00082'].invalid"   formControlName="cmd_00082" class="form-control"
                                    
                                    >
                                    
                        
                                <div class="mandatory" *ngIf="CancerCareNorthwestForms.controls['cmd_00082'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>                        
                    </td>
                </tr>
            </table>
            <table border="2" width="100%" class="center" >     
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>15. OTHER CERTIFICATIONS ACLS, BLS, ATLS, PALS, NALS (e.g., Fluoroscopy, Radiography, etc.)
                                    (Attach Certificate if Applicable)</h6> </label>
                    </th>
                    
                    
                    
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-2">
                                <label class="formLabel">Type:</label> 
                            </div>
                            <div class="col-md-9">
                                <ng-select [items]="mediCertifications" bindLabel="name" placeholder="Enter Medical Certification" bindValue="id" formControlName="674" > </ng-select>
                                
                                
                            </div>
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Number:</label> 
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Medical Certification Number"  formControlName="3270" class="form-control">
                                        
                            </div>

                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel">Expiration Date:</label> 
                            </div>
                            <div  class="col-md-8">
                                <div class="input-group">
                                    <input type="text" placeholder="Medical Certification Expiration Date " 
                                        id="678" formControlName="678" (blur)="clearInputIfInvalid('678')" 
                                        
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh48="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh48.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('678').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('678').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('678').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('678').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('678').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-2">
                                <label class="formLabel">Type:</label> 
                            </div>
                            <div class="col-md-9">
                                <ng-select [items]="mediCertifications" bindLabel="name" placeholder="Enter Medical Certification" bindValue="id" formControlName="add_1_674" > </ng-select>
                                
                                
                            </div>
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Number:</label> 
                            </div>
                            <div class="col-md-9">
                                <input type="text" placeholder="Medical Certification Number"
                               
                                    formControlName="add_1_3270" class="form-control">
                                    
                            </div>

                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel">Expiration Date:</label> 
                            </div>
                            <div  class="col-md-8">
                                <div class="input-group">
                                    <input type="text" placeholder="Medical Certification Expiration Date " 
                                   
                                        id="add_1_678" formControlName="add_1_678" (blur)="clearInputIfInvalid('add_1_678')" 
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh0148="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="idh0148.toggle()" type="button"> <i
                                                class="fas fa-calendar-alt"></i> </button>
                                    </div>

                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('add_1_678').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_678').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_678').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_678').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_678').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                            </div>
                        </div>    
                    </td>
                </tr> 
            </table>  
            <table border="1" width="100%" class="center">    
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>16. HOSPITAL, MILITARY, & OTHER INSTITUTIONAL AFFILIATIONS</h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_13')" formControlName="cmd_NA_13"
                            id="cmd_NA_13">
                    </th>
                    
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-12">
                            <label class="formLabel">Please list in <b>reverse chronological order (with the current affiliation(s) first)</b> all institutions where you (A) Current Hospital
                                affiliation, (B) Previous Hospital Affiliations, (C) Current Military Affiliation, (D) Previous Military Affiliations (E) Applications in
                                process This includes hospitals, surgery centers, institutions, corporations, military assignments, or government agencies. If
                                more space is needed, attach additional sheet(s). List only affiliations here, list employment in section XVII, Work History.</label> 
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <b>A. CURRENT HOSPITAL AFFILIATIONS <i>(Do not abbreviate)</i> </b>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Primary Admitting Hospital:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 1 Name " id="418" formControlName="418"
                                        class="form-control" (blur)="handleNAchange('cmd_NA_13',418)"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['418'].invalid">
                                        <div class="mandatory"
                                            *ngIf="CancerCareNorthwestForms.controls['418'].invalid">
                                            <small >
                                            required
                                            </small>
                                        </div> 
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Hospital Affiliation 1 Department" (blur)="handleNAchange('cmd_NA_13',425)" id="425" formControlName="425" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['425'].invalid">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['425'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_13',419)" placeholder="Hospital Affiliation 1 Address 1" id="419" formControlName="419"
                                        class="form-control"
                                        [class.is-invalid]="CancerCareNorthwestForms.controls['419'].invalid">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['419'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                            </div>
                            <div class="col-md-12">
                                <input type="text"   placeholder="Hospital Affiliation 1 Address 2" id="420" formControlName="420"
                                        class="form-control"
                                        >
                                    
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_13',421)"  placeholder="Hospital Affiliation 1 City" id="421" formControlName="421" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['421'].invalid">
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['421'].invalid">
                            <small >
                            required
                            </small>
                        </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  (blur)="handleNAchange('cmd_NA_13',423)" [class.is-invalid]="CancerCareNorthwestForms.controls['423'].invalid"
                                    bindLabel="name" placeholder="Hospital Affiliation 1state" bindValue="id" formControlName="423"> </ng-select>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['423'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" (blur)="handleNAchange('cmd_NA_13',424)" placeholder="Hospital Affiliation 1 Zip" id="424" formControlName="424" class="form-control"
                            [class.is-invalid]="CancerCareNorthwestForms.controls['424'].invalid">
                                        <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['424'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Phone number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Phone number" id="1305" (blur)="handleNAchange('cmd_NA_13',1305)" formControlName ="1305" class="form-control"   [class.is-invalid]="CancerCareNorthwestForms.controls['1305'].invalid">
                             <small
                                    class="text-danger" *ngIf="CancerCareNorthwestForms.controls['1305'].errors?.pattern">
                                    Only number are allowed</small>
                                    <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1305'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                       </div>
                    </td>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Fax Number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Fax Number " id="2136" formControlName ="2136" class="form-control" >
                             <div  *ngIf="CancerCareNorthwestForms.get('2136').invalid && CancerCareNorthwestForms.get('2136').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2136').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                            </div>
                       </div>
                    </td>
                  </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Status (active, provisional, courtesy,
                                temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus"  
                                        bindLabel="name" formControlName="894" placeholder="Hospital Affiliation 1 Status" bindValue="id"> </ng-select>

                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Appointment Date (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text"  placeholder="Hospital Affiliation 1 Appointment Date" id="426" formControlName="426" (blur)="clearInputIfInvalid('426')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh52="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh52.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('426').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('426').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('426').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('426').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('426').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>

                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff/Credentialing E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Hospital Affiliation 1 E-mail Address" id="2142" formControlName="2142" class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('2142').invalid && CancerCareNorthwestForms.get('2142').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2142').errors.invalidEmailFormat">
                                  Only Email format allowed
                                </small>
                              </div> 
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-8">
                                <label for="9">Can you admit / follow clients of your primary, secondary, other practice locations?</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-md-4">    
                                <label  for="cmd_NA_14"><b>Does Not Apply</b></label>
                                <input type="checkbox" value="1" formControlName="cmd_NA_14" id="cmd_NA_14"> 
                    
                            </div>
                        </div>  
                        <div class="col-md-12">
                            <input type="radio" value="0"   formControlName="cmd_188" id="cmd_188" >
                            <label ><b>Primary practice admits only</b>
                                
                            </label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="1"  formControlName="cmd_188" >
                            <label><b>Secondary Practice admits only</b></label>&nbsp;&nbsp;&nbsp;&nbsp;
                                
                            <input type="radio" value="2" formControlName="cmd_188" >
                            <label ><b>Can admit to for all locations</b></label>

                                
                        </div>    
                    
                            
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Secondary Admitting Hospital: </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 2 Name" id="427"
                            formControlName="427" class="form-control">
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 2 Department" id="434" formControlName="434" class="form-control"
                                        >
                                
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Hospital Affiliation 2 Address 1" id="428" formControlName="428"
                                        class="form-control">
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Hospital Affiliation 2 Address 2" id="429" formControlName="429"
                                        class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 2 City" id="430" formControlName="430" class="form-control">
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Hospital Affiliation 2 State" bindValue="id" formControlName="432"> </ng-select>

                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 2 Zip" id="433" formControlName="433" class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Phone number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Phone number" id="1307" formControlName ="1307" class="form-control" >
                             <div  *ngIf="CancerCareNorthwestForms.get('1307').invalid && CancerCareNorthwestForms.get('1307').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1307').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                            </div>
                       </div>
                    </td>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Fax Number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Fax Number " id="2137" formControlName ="2137" class="form-control" >
                             <div  *ngIf="CancerCareNorthwestForms.get('2137').invalid && CancerCareNorthwestForms.get('2137').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2137').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                            </div>
                       </div>
                    </td>
                  </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Status (active, provisional, courtesy,
                                temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus" 
                                        bindLabel="name" formControlName="895" placeholder="Hospital Affiliation 2 status" bindValue="id"> </ng-select>
                            
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Appointment Date (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 2 Appointment Date" id="435" formControlName="435" (blur)="clearInputIfInvalid('435')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh53="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh53.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                </div>

                                <div *ngIf="CancerCareNorthwestForms.get('435').invalid ">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('435').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('435').errors?.invalidMonth">
                                      Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('435').errors?.invalidDateyear">
                                      year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('435').errors?.invalidDateRange">
                                      date is out of range
                                    </small>
                                  </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff/Credentialing E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Hospital Affiliation 2 E-mail Address" id="2143" formControlName="2143" class="form-control"
                                        >
                                        <div  *ngIf="CancerCareNorthwestForms.get('2143').invalid && CancerCareNorthwestForms.get('2143').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2143').errors.invalidEmailFormat">
                                              Only Email format allowed
                                            </small>
                                          </div>   
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="formLabel">Can you admit / follow clients of your primary, secondary, other practice locations?</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-md-4">    
                                <label  for="cmd_NA_16"><b>Does Not Apply</b></label>
                                <input type="checkbox" value="1" formControlName="cmd_NA_16" id="cmd_NA_16"> 
                    
                            </div>
                        </div>  
                        <div class="col-md-12">
                            <input type="radio" value="0" formControlName="cmd_0188" id="cmd_0188" >
                            <label class="formLabel">Primary practice admits only</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="1" formControlName="cmd_0188" >
                            <label class="formLabel">Secondary Practice admits only</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="2" formControlName="cmd_0188" >
                            <label class="formLabel">Can admit to for all locations</label>&nbsp;&nbsp; 
                        </div>    
                    
                            
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Other Institutions: </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 3 Name" id="436" formControlName="436"
                                        class="form-control">
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 3 Department" id="443" formControlName="443" class="form-control">
                                
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Hospital Affiliation 3 Address 1" id="437" formControlName="437"
                                        class="form-control">
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Hospital Affiliation 3 Address 2" id="438" formControlName="438"
                                        class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 3 City" id="439" formControlName="439" class="form-control">
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states" bindLabel="name" placeholder="Hospital Affiliation 3 State" bindValue="id" formControlName="441"> </ng-select>                            
                    </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Hospital Affiliation 3 Zip" id="442" formControlName="442" class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Phone number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Phone number" id="1309"  formControlName ="1309" class="form-control"   >
                             <div  *ngIf="CancerCareNorthwestForms.get('1309').invalid && CancerCareNorthwestForms.get('1309').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1309').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                                  
                            </div>
                       </div>
                    </td>
                    <td colspan="6">
                       <div class="row">
                          <div class="col-md-12"><label class="formLabel">Fax Number:</label></div>
                          <div class="col-md-12">
                             <input type="text" placeholder="Enter Fax Number " id="2138" formControlName ="2138" class="form-control" >
                             <div  *ngIf="CancerCareNorthwestForms.get('2138').invalid && CancerCareNorthwestForms.get('2138').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2138').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                            </div>
                       </div>
                    </td>
                  </tr>

                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Status (active, provisional, courtesy,
                                temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus" bindLabel="name" formControlName="896" placeholder=" status" bindValue="id"> </ng-select>  
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Appointment Date (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Hospital Affiliation 3 Appointment Date" id="444" formControlName="444" (blur)="clearInputIfInvalid('444')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh54="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh54.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('444').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('444').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('444').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('444').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('444').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff/Credentialing E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Hospital Affiliation 3 E-mail Address" id="2144" formControlName="2144" class="form-control"
                                        >
                                        <div  *ngIf="CancerCareNorthwestForms.get('2144').invalid && CancerCareNorthwestForms.get('2144').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2144').errors.invalidEmailFormat">
                                              Only Email format allowed
                                            </small>
                                          </div>      
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="formLabel">Can you admit / follow clients of your primary, secondary, other practice locations?</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-md-4">    
                                <label  for="cmd_NA_016"><b>Does Not Apply</b></label>
                                <input type="checkbox" value="1" formControlName="cmd_NA_016" id="cmd_NA_016"> 
                    
                            </div>
                        </div>  
                        <div class="col-md-12">
                            <input type="radio" value="0" formControlName="cmd_00188" id="cmd_00188" >
                            <label class="formLabel">Primary practice admits only</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="1" formControlName="cmd_00188" >
                            <label class="formLabel">Secondary Practice admits only</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="radio" value="2" formControlName="cmd_00188" >
                            <label class="formLabel">Can admit to for all locations</label>&nbsp;&nbsp; 
                        </div>    
                    
                            
                    </td>
                </tr>
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>B. PREVIOUS HOSPITAL AFFILIATIONS (Do not abbreviate)</h6> </label>
                    </th>   
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_5')" formControlName="cmd_NA_5"
                            id="cmd_NA_5">
                    </th>                             
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Admitting Hospital:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Previous Hospital Affiliation Name "
                            (blur)="handleNAchange('cmd_NA_5',1183)" [class.is-invalid]="CancerCareNorthwestForms.controls['1183'].invalid" formControlName="1183"
                                        id="1183" class="form-control">
                                        <div class="mandatory"
                                            *ngIf="CancerCareNorthwestForms.controls['1183'].invalid">
                                            <small >
                                            required
                                            </small>
                                        </div> 
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Department" id="1190"
                            (blur)="handleNAchange('cmd_NA_5',1190)" [class.is-invalid]="CancerCareNorthwestForms.controls['1190'].invalid" 
                            formControlName="1190" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1190'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                                
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 1" id="1184"
                                (blur)="handleNAchange('cmd_NA_5',1184)" [class.is-invalid]="CancerCareNorthwestForms.controls['1184'].invalid"  formControlName="1184"
                                class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1184'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 2" id="1185"
                                 formControlName="1185"
                                        class="form-control">
                                        
                            </div>
                        </div>    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="City" id="1186" (blur)="handleNAchange('cmd_NA_5',1186)" [class.is-invalid]="CancerCareNorthwestForms.controls['1186'].invalid" formControlName="1186" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1186'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id" formControlName="1188" (blur)="handleNAchange('cmd_NA_5',1188)" [class.is-invalid]="CancerCareNorthwestForms.controls['1188'].invalid"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1188'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                            
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Zip" id="1189" formControlName="1189"
                            (blur)="handleNAchange('cmd_NA_5',1189)" [class.is-invalid]="CancerCareNorthwestForms.controls['1189'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1189'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus" bindLabel="name" formControlName="1192" placeholder="status" 
                            
                            bindValue="id"> </ng-select>
                            
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  From Date" id="1191" formControlName="1191" (blur)="clearInputIfInvalid('1191')" 
                                 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh57="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh57.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                    
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('1191').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1191').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1191').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1191').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1191').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  To Date" id="2381" formControlName="2381" (blur)="clearInputIfInvalid('2381')" 
                                
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh58="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh58.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                                    
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('2381').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2381').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2381').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2381').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2381').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                </tr>
                <tr>    
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Reason for Leaving" id="1303"
                            formControlName="1303"
                                        class="form-control">
                                        
                                    
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Previous Hospital Affiliation E-mail Address" id="2146"
                         formControlName="2146" class="form-control"
                                        >
                                        <div  *ngIf="CancerCareNorthwestForms.get('2146').invalid && CancerCareNorthwestForms.get('2146').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('2146').errors.invalidEmailFormat">
                                              Only Email format allowed
                                            </small>
                                          </div>            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Admitting Hospital:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Previous Hospital Affiliation Name " id="add_1_1183"
                                        formControlName="add_1_1183" class="form-control">
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Department" id="add_1_1190" formControlName="add_1_1190"
                                        class="form-control">
                                
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 1" id="add_1_1184" formControlName="add_1_1184"
                                        class="form-control">
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 2" id="add_1_1185" formControlName="add_1_1185"
                                        class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  City" id="add_1_1186" formControlName="add_1_1186"
                                        class="form-control">
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="  State" bindValue="id" formControlName="add_1_1188"> </ng-select>
                                
                    </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Zip" id="add_1_1189" formControlName="add_1_1189" class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus" bindLabel="name" formControlName="add_1_1192" placeholder="  status" bindValue="id"> </ng-select>
                            
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  From Date" id="add_1_1191" formControlName="add_1_1191" (blur)="clearInputIfInvalid('add_1_1191')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh0057="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh0057.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('add_1_1191').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1191').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1191').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1191').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_1191').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  To Date" id="add_1_2381" formControlName="add_1_2381" (blur)="clearInputIfInvalid('add_1_2381')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh0058="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh0058.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('add_1_2381').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_2381').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_2381').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_2381').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_2381').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                            
                        </div>
                    </td>
                </tr>
                <tr>    
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Reason for Leaving" id="add_1_1303" formControlName="add_1_1303"
                                        class="form-control">
                                    
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Previous Hospital Affiliation E-mail Address" id="add_1_2146" formControlName="add_1_2146" class="form-control"
                                        >
                                        <div  *ngIf="CancerCareNorthwestForms.get('add_1_2146').invalid && CancerCareNorthwestForms.get('add_1_2146').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_1_2146').errors.invalidEmailFormat">
                                              Only Email format allowed
                                            </small>
                                          </div>       
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Admitting Hospital:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Previous Hospital Affiliation Name " formControlName="add_2_1183"
                                        id="add_2_1183" class="form-control">
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Department:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Department" id="add_2_1190" formControlName="add_2_1190" class="form-control">
                                
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 1" id="add_2_1184" formControlName="add_2_1184"
                                class="form-control">
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="  Mailing Address 2" id="add_2_1185" formControlName="add_2_1185"
                                        class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="City" id="add_2_1186" formControlName="add_2_1186" class="form-control">
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id" formControlName="add_2_1188"> </ng-select>
                                
                    </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Zip" id="add_2_1189" formControlName="add_2_1189" class="form-control">
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Previous Status (active, provisional, courtesy, temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="staffStatus" bindLabel="name" formControlName="add_2_1192" placeholder="  status" bindValue="id"> </ng-select>
                            
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  From Date" id="add_2_1191" formControlName="add_2_1191" (blur)="clearInputIfInvalid('add_2_1191')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh157="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh157.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('add_2_1191').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_1191').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_1191').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_1191').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_1191').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="  To Date" id="add_2_2381" formControlName="add_2_2381" (blur)="clearInputIfInvalid('add_2_2381')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh158="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="idh158.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                                </div>
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('add_2_2381').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_2381').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_2381').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_2381').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_2381').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>
                        </div>
                    </td>
                </tr>
                <tr>    
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="  Reason for Leaving" id="add_2_1303" formControlName="add_2_1303"
                                        class="form-control">
                                    
                        </div>
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Medical Staff E-mail Address:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"  placeholder="Previous Hospital Affiliation E-mail Address" id="add_2_2146" formControlName="add_2_2146" class="form-control"
                                        >
                                        <div  *ngIf="CancerCareNorthwestForms.get('add_2_2146').invalid && CancerCareNorthwestForms.get('add_2_2146').touched">
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('add_2_2146').errors.invalidEmailFormat">
                                              Only Email format allowed
                                            </small>
                                          </div>         
                        </div>
                    </td>
                </tr>
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>C. CURRENT MILITARY AFFILIATIONS (Do not abbreviate) Please include Military Reserves</h6> </label>
                    </th>     
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_6')" formControlName="cmd_NA_6"
                            id="cmd_NA_6">
                    </th>                           
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Primary Base:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Primary Base" id="1139"
                            (blur)="handleNAchange('cmd_NA_6',1139)" [class.is-invalid]="CancerCareNorthwestForms.controls['1139'].invalid" formControlName="1139" class="form-control"> 
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1139'].invalid">
                                <small >
                                required
                                </small>
                            </div> 

                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Division:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Branch" id="1141"
                            (blur)="handleNAchange('cmd_NA_6',1141)" [class.is-invalid]="CancerCareNorthwestForms.controls['1141'].invalid" formControlName="1141" class="form-control"> 
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1141'].invalid">
                                <small >
                                required
                                </small>
                            </div> 

                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 1" id="2112" formControlName="2112"
                                (blur)="handleNAchange('cmd_NA_6',2112)" [class.is-invalid]="CancerCareNorthwestForms.controls['2112'].invalid" class="form-control"> 
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['2112'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 2" id="2113" formControlName="2113" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="2114" formControlName="2114"
                            (blur)="handleNAchange('cmd_NA_6',2114)" [class.is-invalid]="CancerCareNorthwestForms.controls['2114'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['2114'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_6',2116)" [class.is-invalid]="CancerCareNorthwestForms.controls['2116'].invalid"
                            formControlName="2116"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['2116'].invalid">
                                <small >
                                required
                                </small>
                            </div>       
                    </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="2117" formControlName="2117"
                            (blur)="handleNAchange('cmd_NA_6',2117)" [class.is-invalid]="CancerCareNorthwestForms.controls['2117'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['2117'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Phone number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Phone Number" id="cmd_232"
                            (blur)="handleNAchange('cmd_NA_6','cmd_232')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_232'].invalid" formControlName="cmd_232" class="form-control">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_232'].errors?.pattern">
                            Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_232'].invalid">
                                <small >
                                required
                                </small>
                            </div>    
                        </div>
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="cmd_233" 
                            formControlName="cmd_233" class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('cmd_233').invalid && CancerCareNorthwestForms.get('cmd_233').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_233').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                        </div>
                    </td>
                </tr>
                <tr> 
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Status (active, provisional, courtesy, temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Rank at Discharge" id="4152" formControlName="4152"
                            (blur)="handleNAchange('cmd_NA_6',4152)" [class.is-invalid]="CancerCareNorthwestForms.controls['4152'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['4152'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                                    
                        </div>
                    </td>   
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Appointment Date (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Active Duty Effective Date" id="1144" formControlName="1144" class="form-control datepicker"  (blur)="clearInputIfInvalid('1144','cmd_NA_6')"
                                (blur)="handleNAchange('cmd_NA_6',1144)" [class.is-invalid]="CancerCareNorthwestForms.controls['1144'].invalid" data-id="priornameeffdate_date" ngbDatepicker #wpa57="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa57.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['1144'].invalid && (CancerCareNorthwestForms.controls['1144'].value==''|| CancerCareNorthwestForms.controls['1144'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('1144').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1144').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1144').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1144').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('1144').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>D. PREVIOUS MILITARY AFFILIATIONS (Do not abbreviate)</h6> </label>
                    </th>  
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_7')" formControlName="cmd_NA_7"
                            id="cmd_NA_7">
                    </th>                              
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Primary Base:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Primary Base" id="cmd_221"
                            (blur)="handleNAchange('cmd_NA_7','cmd_221')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_221'].invalid" formControlName="cmd_221" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_221'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Division:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Division" id="cmd_222" formControlName="cmd_222"
                            (blur)="handleNAchange('cmd_NA_7','cmd_222')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_222'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_222'].invalid">
                                <small >
                                required
                                </small>
                            </div>     
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 1" id="cmd_223" formControlName="cmd_223"
                                (blur)="handleNAchange('cmd_NA_7','cmd_223')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_223'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_223'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 2" id="cmd_224" formControlName="cmd_224" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="cmd_225" formControlName="cmd_225"
                            (blur)="handleNAchange('cmd_NA_7','cmd_225')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_225'].invalid" class="form-control"> 
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_225'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_7','cmd_226')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_226'].invalid" formControlName="cmd_226"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_226'].invalid">
                                <small >
                                required
                                </small>
                            </div>    
                    </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="cmd_227" formControlName="cmd_227"
                            (blur)="handleNAchange('cmd_NA_7','cmd_227')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_227'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_227'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Phone number:</label>
                        </div>
                        <div class="col-md-12">
                            
                        <input type="text" placeholder="Enter Phone Number" id="cmd_228" (blur)="handleNAchange('cmd_NA_7','cmd_228')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_228'].invalid"  formControlName="cmd_228" class="form-control">
                        <small
                        class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_228'].errors?.pattern">
                        Only number are allowed</small>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_228'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="cmd_229"
                            formControlName="cmd_229" class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('cmd_229').invalid && CancerCareNorthwestForms.get('cmd_229').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_229').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div> 
                        </div>
                    </td>
                </tr>
                <tr> 
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Status (active, provisional, courtesy, temporary, etc.):</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Rank at Discharge" id="cmd_230" formControlName="cmd_230"
                            (blur)="handleNAchange('cmd_NA_7','cmd_230')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_230'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_230'].invalid">
                                <small >
                                required
                                </small>
                            </div>       
                        </div>
                    </td>   
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Appointment Date (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Active Duty Effective Date" id="cmd_231"  (blur)="clearInputIfInvalid('cmd_231','cmd_NA_7')"
                                (blur)="handleNAchange('cmd_NA_7','cmd_231')" [class.is-invalid]="CancerCareNorthwestForms.controls['cmd_231'].invalid" formControlName="cmd_231" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa157="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa157.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                              
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_231'].invalid && (CancerCareNorthwestForms.controls['cmd_231'].value==''|| CancerCareNorthwestForms.controls['cmd_231'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('cmd_231').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_231').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_231').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_231').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_231').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>  
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>E. APPLICATIONS IN PROCESS <i>(Do not abbreviate)</i></h6> </label>
                    </th>    
                                               
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Hospital/Institution:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Hospital/Institution" id="cmd_234" formControlName="cmd_234" 
                            class="form-control">
                            
                        </div>
                    </td>
                
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Phone Number/Fax Number:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="cmd_235" formControlName="cmd_235"
                                class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_235').invalid && CancerCareNorthwestForms.get('cmd_235').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_235').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div> 
                            </div>
                        
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Date Application Submitted:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Date Application Submitted" id="cmd_236" formControlName="cmd_236" (blur)="clearInputIfInvalid('cmd_236')" 
                                 class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa100="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa100.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                            </div>     
                            <div *ngIf="CancerCareNorthwestForms.get('cmd_236').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_236').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_236').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_236').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_236').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>        
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 1" id="cmd_237" formControlName="cmd_237" 
                               class="form-control">
                                
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 2" id="cmd_238" formControlName="cmd_238" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="cmd_239" formControlName="cmd_239" class="form-control"> 
                            
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id"> </ng-select>
                               
                    </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="cmd_241" formControlName="cmd_241" class="form-control">
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Hospital/Institution:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Hospital/Institution" id="cmd_242" formControlName="cmd_242" class="form-control">
                        </div>
                    </td>
                
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Phone Number/Fax Number:</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="cmd_243" formControlName="cmd_243" class="form-control">
                                <div  *ngIf="CancerCareNorthwestForms.get('cmd_243').invalid && CancerCareNorthwestForms.get('cmd_243').touched">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_243').errors.invalidPhoneFormat">
                                      Only Numbers are allowed
                                    </small>
                                  </div>  
                            </div>
                        
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Date Application Submitted:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Date Application Submitted" id="cmd_244" formControlName="cmd_244" (blur)="clearInputIfInvalid('cmd_244')"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa0100="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa0100.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                            </div>     
                            <div *ngIf="CancerCareNorthwestForms.get('cmd_244').invalid ">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_244').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_244').errors?.invalidMonth">
                                  Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_244').errors?.invalidDateyear">
                                  year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('cmd_244').errors?.invalidDateRange">
                                  date is out of range
                                </small>
                              </div>           
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 1" id="cmd_245" formControlName="cmd_245" class="form-control">
                            
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Mailing Address 2" id="cmd_246" formControlName="cmd_246" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="cmd_247" formControlName="cmd_247" class="form-control"> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">State</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="State" bindValue="id" formControlName="cmd_248"> </ng-select>
                                
                    </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Zip</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="cmd_249" formControlName="cmd_249" class="form-control">
                        </div>
                    </td>
                </tr>
            </table>  
            <table border="1" width="100%" class="center">   
                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>17. WORK HISTORY <i>(Do not abbreviate)</i></h6> </label>
                    </th>  
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_9')" formControlName="cmd_NA_9"
                            id="cmd_NA_9">
                    </th>                              
                </tr>
                <tr>
                    <td colspan="12">
                        <label class="formLabel">Chronologically list all work history activities since completion of professional training (use extra sheets if necessary). This
                            information must be complete. Curriculum vitae is <u>not</u> sufficient. </label>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Practice / Employer:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Enter Name of Practice"  (blur)="handleNAchange('cmd_NA_9',445)" [class.is-invalid]="CancerCareNorthwestForms.controls['445'].invalid"id="445" formControlName="445" >
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['445'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Contact Name:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Enter Contact Name" id="452"  (blur)="handleNAchange('cmd_NA_9',452)" [class.is-invalid]="CancerCareNorthwestForms.controls['452'].invalid" formControlName="452">
                                            <div class="mandatory"
                                                *ngIf="CancerCareNorthwestForms.controls['452'].invalid">
                                                <small >
                                                required
                                                </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Enter Telephone Number " id="453" formControlName="453"  (blur)="handleNAchange('cmd_NA_9',453)" [class.is-invalid]="CancerCareNorthwestForms.controls['453'].invalid">
                            <small
                            class="text-danger" *ngIf="CancerCareNorthwestForms.controls['453'].errors?.pattern">
                            Only number are allowed</small>  
                            <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['453'].invalid">
                            <small >
                            required
                            </small>
                        </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reason for Leaving" id="1329" formControlName="1329"  (blur)="handleNAchange('cmd_NA_9',1329)" [class.is-invalid]="CancerCareNorthwestForms.controls['1329'].invalid" class="form-control">
                        
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1329'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Email Address</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Email Address" id="889" formControlName="889"
                             class="form-control">
                             <div  *ngIf="CancerCareNorthwestForms.get('889').invalid && CancerCareNorthwestForms.get('889').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('889').errors.invalidEmailFormat">
                                  Only Email format allowed
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="888" formControlName="888"
                            class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('888').invalid && CancerCareNorthwestForms.get('888').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('888').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>        
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="446" formControlName="446"
                                (blur)="handleNAchange('cmd_NA_9',446)" [class.is-invalid]="CancerCareNorthwestForms.controls['446'].invalid" class="form-control">
                                <div class="mandatory"
                                                *ngIf="CancerCareNorthwestForms.controls['446'].invalid">
                                                <small >
                                                required
                                                </small>
                                            </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="447" formControlName="447" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="448" formControlName="448"
                            (blur)="handleNAchange('cmd_NA_9',448)" [class.is-invalid]="CancerCareNorthwestForms.controls['448'].invalid" class="form-control">
                                            <div class="mandatory"
                                                *ngIf="CancerCareNorthwestForms.controls['448'].invalid">
                                                <small >
                                                required
                                                </small>
                                            </div>
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_9',450)" [class.is-invalid]="CancerCareNorthwestForms.controls['450'].invalid" formControlName="450"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['450'].invalid">
                                <small >
                                required
                                </small>
                            </div>               
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="451" formControlName="451" 
                            (blur)="handleNAchange('cmd_NA_9',451)" [class.is-invalid]="CancerCareNorthwestForms.controls['451'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['451'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter From Date" id="454" formControlName="454"  (blur)="clearInputIfInvalid('454','cmd_NA_9')"
                                 class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa62="ngbDatepicker"
                                 (blur)="handleNAchange('cmd_NA_9',454)" [class.is-invalid]="CancerCareNorthwestForms.controls['454'].invalid" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa62.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                            </div> 
                            <div class="text-danger"
                            *ngIf="CancerCareNorthwestForms.controls['454'].invalid && (CancerCareNorthwestForms.controls['454'].value==''|| CancerCareNorthwestForms.controls['454'].value==null) ">
                            <small>
                              required
                            </small>
                          
                          </div>
                          <div *ngIf="CancerCareNorthwestForms.get('454').invalid">
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('454').errors.invalidDateFormat">
                              Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('454').errors.invalidMonth">
                              Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('454').errors.invalidDateyear">
                              year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('454').errors.invalidDateRange">
                              date is out of range
                            </small>
                          </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter To Date" id="455" formControlName="455"  (blur)="clearInputIfInvalid('455','cmd_NA_9')"
                                (blur)="handleNAchange('cmd_NA_9',455)" [class.is-invalid]="CancerCareNorthwestForms.controls['455'].invalid" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa63="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa63.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                         
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['455'].invalid && (CancerCareNorthwestForms.controls['455'].value==''|| CancerCareNorthwestForms.controls['455'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('455').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('455').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('455').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('455').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('455').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>     
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Name of Malpractice Carrier During Employment:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_0451" formControlName="cmd_0451" class="form-control">
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Practice / Employer:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Practice" id="456" formControlName="456"
                            (blur)="handleNAchange('cmd_NA_9',456)" [class.is-invalid]="CancerCareNorthwestForms.controls['456'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['456'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Contact Name:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Contact Name" id="463" formControlName="463"
                            (blur)="handleNAchange('cmd_NA_9',463)" [class.is-invalid]="CancerCareNorthwestForms.controls['463'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['463'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Telephone Number" id="464"
                            (blur)="handleNAchange('cmd_NA_9',464)" [class.is-invalid]="CancerCareNorthwestForms.controls['464'].invalid" formControlName="464" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['464'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reason for Leaving" id="1338"
                            (blur)="handleNAchange('cmd_NA_9',1338)" [class.is-invalid]="CancerCareNorthwestForms.controls['1338'].invalid" formControlName="1338" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1338'].invalid">
                                <small >
                                required
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Email Address</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Email Address" id="891" formControlName="891"
                             class="form-control">
                             <div  *ngIf="CancerCareNorthwestForms.get('891').invalid && CancerCareNorthwestForms.get('891').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('891').errors.invalidEmailFormat">
                                  Only Email format allowed
                                </small>
                              </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="890" formControlName="890"
                            class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('890').invalid && CancerCareNorthwestForms.get('890').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('890').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>    
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="457" formControlName="457" 
                                (blur)="handleNAchange('cmd_NA_9',457)" [class.is-invalid]="CancerCareNorthwestForms.controls['457'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['457'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="458" formControlName="458" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="459" formControlName="459" class="form-control"
                            (blur)="handleNAchange('cmd_NA_9',459)" [class.is-invalid]="CancerCareNorthwestForms.controls['459'].invalid">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['459'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id"
                            (blur)="handleNAchange('cmd_NA_9',461)" [class.is-invalid]="CancerCareNorthwestForms.controls['461'].invalid" formControlName="461"> </ng-select>
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['461'].invalid">
                                <small >
                                required
                                </small>
                            </div>                 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="462" formControlName="462"
                            (blur)="handleNAchange('cmd_NA_9',462)" [class.is-invalid]="CancerCareNorthwestForms.controls['462'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['462'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter From Date" id="465" formControlName="465" class="form-control datepicker"  (blur)="clearInputIfInvalid('465','cmd_NA_9')"
                                (blur)="handleNAchange('cmd_NA_9',465)" [class.is-invalid]="CancerCareNorthwestForms.controls['465'].invalid" data-id="priornameeffdate_date" ngbDatepicker #wpa062="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa062.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                            </div>    
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['465'].invalid && (CancerCareNorthwestForms.controls['465'].value==''|| CancerCareNorthwestForms.controls['465'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('465').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('465').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('465').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('465').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('465').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter To Date" id="466"  (blur)="clearInputIfInvalid('466','cmd_NA_9')"
                                (blur)="handleNAchange('cmd_NA_9',466)" [class.is-invalid]="CancerCareNorthwestForms.controls['466'].invalid" formControlName="466" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa063="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa063.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['466'].invalid && (CancerCareNorthwestForms.controls['466'].value==''|| CancerCareNorthwestForms.controls['466'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('466').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('466').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('466').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('466').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('466').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>     
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Name of Malpractice Carrier During Employment:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_0452" formControlName="cmd_0452" class="form-control">
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Practice / Employer:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Practice" id="467" formControlName="467" 
                            (blur)="handleNAchange('cmd_NA_9',467)" [class.is-invalid]="CancerCareNorthwestForms.controls['467'].invalid"
                            class="form-control">
                                    <div class="mandatory"
                                        *ngIf="CancerCareNorthwestForms.controls['467'].invalid">
                                        <small >
                                        required
                                        </small>
                                    </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Contact Name:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Contact Name" id="474" formControlName="474"
                            (blur)="handleNAchange('cmd_NA_9',474)" [class.is-invalid]="CancerCareNorthwestForms.controls['474'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['474'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Telephone Number" id="475" formControlName="475"
                            (blur)="handleNAchange('cmd_NA_9',475)" [class.is-invalid]="CancerCareNorthwestForms.controls['475'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['475'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Reason for Leaving:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Reason for Leaving" id="1342" formControlName="1342"
                            (blur)="handleNAchange('cmd_NA_9',1342)" [class.is-invalid]="CancerCareNorthwestForms.controls['1342'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['1342'].invalid">
                                <small >
                                required
                                </small>
                            </div> 
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Email Address</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Email Address" id="893" formControlName="893" 
                             class="form-control">
                             <div  *ngIf="CancerCareNorthwestForms.get('893').invalid && CancerCareNorthwestForms.get('893').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('893').errors.invalidEmailFormat">
                                  Only Email format allowed
                                </small>
                              </div>
                        </div>
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="892" formControlName="892"
                            class="form-control">
                            <div  *ngIf="CancerCareNorthwestForms.get('892').invalid && CancerCareNorthwestForms.get('892').touched">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('892').errors.invalidPhoneFormat">
                                  Only Numbers are allowed
                                </small>
                              </div>    
                        </div>
                    </td>
                </tr>   
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address</label>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="468" formControlName="468"
                                (blur)="handleNAchange('cmd_NA_9',468)" [class.is-invalid]="CancerCareNorthwestForms.controls['468'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['468'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                            </div>
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="469" formControlName="469" class="form-control">
                            </div>
                        </div>    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="470" formControlName="470" (blur)="handleNAchange('cmd_NA_9',470)" [class.is-invalid]="CancerCareNorthwestForms.controls['470'].invalid" class="form-control">
                            <div class="mandatory"
                                *ngIf="CancerCareNorthwestForms.controls['470'].invalid">
                                <small >
                                required
                                </small>
                            </div>    
                        </div>
                    </td>
                    <td colspan="1">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" 
                            (blur)="handleNAchange('cmd_NA_9',472)" [class.is-invalid]="CancerCareNorthwestForms.controls['472'].invalid" formControlName="472"> </ng-select>
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['472'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>                 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Zip:</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="473" formControlName="473" 
                            (blur)="handleNAchange('cmd_NA_9',473)" [class.is-invalid]="CancerCareNorthwestForms.controls['473'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['473'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div> 
                        </div>
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">From (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter From Date" id="476" formControlName="476" (blur)="clearInputIfInvalid('476','cmd_NA_9')"
                                (blur)="handleNAchange('cmd_NA_9',476)" [class.is-invalid]="CancerCareNorthwestForms.controls['476'].invalid" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa67="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa67.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                      
                            </div>    
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['476'].invalid && (CancerCareNorthwestForms.controls['476'].value==''|| CancerCareNorthwestForms.controls['476'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('476').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('476').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('476').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('476').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('476').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (mm/yyyy):</label>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter To Date" id="477" formControlName="477"  (blur)="clearInputIfInvalid('477','cmd_NA_9')"
                                (blur)="handleNAchange('cmd_NA_9',477)" [class.is-invalid]="CancerCareNorthwestForms.controls['477'].invalid" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa68="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa68.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                            </div>
                            <div class="text-danger"
                                *ngIf="CancerCareNorthwestForms.controls['477'].invalid && (CancerCareNorthwestForms.controls['477'].value==''|| CancerCareNorthwestForms.controls['477'].value==null) ">
                                <small>
                                    required
                                </small>
                            
                            </div>
                            <div *ngIf="CancerCareNorthwestForms.get('477').invalid">
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('477').errors.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('477').errors.invalidMonth">
                                    Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('477').errors.invalidDateyear">
                                    year is out of range(1900-2099) </small>
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('477').errors.invalidDateRange">
                                    date is out of range
                                </small>
                            </div>
                        </div>
                    </td>
                </tr>     
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Name of Malpractice Carrier During Employment:</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text"  id="cmd_0453" formControlName="cmd_0453" class="form-control">
                            </div>
                        </div>    
                    </td>
                </tr>
            </table>  
            <table border="1" width="100%" class="center">   
                <tr> 
                    <th colspan="7" border="2">
                        
                            <label class="sideheading">
                                <h6>18. GAPS IN HISTORY. Please account for all gaps between dates of medical/professional school graduation to
                                    present not covered elsewhere within this application. Include dates, activity and names where applicable:</h6> </label>
                    </th>
                    <th colspan="5" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_18')" formControlName="cmd_NA_18"
                            id="cmd_NA_18">
                    </th>                                
                </tr>
                <tr>
                    <td colspan="4">
                        <input type="text" id="1138" placeholder="Explanation for Gap in Work History  1"
                        (blur)="handleNAchange('cmd_NA_18',1138)" [class.is-invalid]="CancerCareNorthwestForms.controls['1138'].invalid" formControlName="1138" class="form-control" >
                        <div class="mandatory"
                            *ngIf="CancerCareNorthwestForms.controls['1138'].invalid">
                            <small >
                            required
                            </small>
                        </div>  
                    </td>
                                
                    <td colspan="3">
                        
                            <div class="col-md-12">
                                <label class="formLabel">From(mm/yyyy):</label>
                            </div>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3395" placeholder="Gap  Start Date 1" formControlName="3395" (blur)="clearInputIfInvalid('3395','cmd_NA_18')"
                                    (blur)="handleNAchange('cmd_NA_18',3395)" [class.is-invalid]="CancerCareNorthwestForms.controls['3395'].invalid"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa69="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa69.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                  
                                </div>    
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3395'].invalid && (CancerCareNorthwestForms.controls['3395'].value==''|| CancerCareNorthwestForms.controls['3395'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3395').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3395').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3395').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3395').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3395').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        
                    </td>
                    <td colspan="5">
                        
                            <div class="col-md-12">
                                <label class="formLabel">To (mm/yyyy):</label>
                            </div>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3396" placeholder="Gap  End  Date 1" formControlName="3396" (blur)="clearInputIfInvalid('3396','cmd_NA_18')"
                                    (blur)="handleNAchange('cmd_NA_18',3396)" [class.is-invalid]="CancerCareNorthwestForms.controls['3396'].invalid"  class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa70="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa70.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                           
                                </div>    
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3396'].invalid && (CancerCareNorthwestForms.controls['3396'].value==''|| CancerCareNorthwestForms.controls['3396'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3396').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3396').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3396').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3396').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3396').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        
                        
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <input type="text" id="1831" placeholder="Explanation for Gap in Work History 2" formControlName="1831" 
                        (blur)="handleNAchange('cmd_NA_18',1831)" [class.is-invalid]="CancerCareNorthwestForms.controls['1831'].invalid" class="form-control">
                                <div class="mandatory"
                                    *ngIf="CancerCareNorthwestForms.controls['1831'].invalid">
                                    <small >
                                    required
                                    </small>
                                </div>
                    </td>
                    <td colspan="3">
                        
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3397" placeholder="Gap  Start Date 2" formControlName="3397" (blur)="clearInputIfInvalid('3397','cmd_NA_18')"
                                    (blur)="handleNAchange('cmd_NA_18',3397)" [class.is-invalid]="CancerCareNorthwestForms.controls['3397'].invalid" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa71="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa71.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                       
                                </div>  
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3397'].invalid && (CancerCareNorthwestForms.controls['3397'].value==''|| CancerCareNorthwestForms.controls['3397'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3397').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3397').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3397').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3397').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3397').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        
                    </td>
                    <td colspan="5">
                        
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3398" placeholder="Gap  End  Date 2" (blur)="clearInputIfInvalid('3398','cmd_NA_18')"
                                    (blur)="handleNAchange('cmd_NA_18',3398)" [class.is-invalid]="CancerCareNorthwestForms.controls['3398'].invalid" formControlName="3398" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa72="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa72.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                         
                                </div>    
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3398'].invalid && (CancerCareNorthwestForms.controls['3398'].value==''|| CancerCareNorthwestForms.controls['3398'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3398').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3398').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3398').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3398').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3398').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <input type="text" id="1832" placeholder="Explanation for Gap in Work History 3"
                        (blur)="handleNAchange('cmd_NA_18',1832)"  [class.is-invalid]="CancerCareNorthwestForms.controls['1832'].invalid" formControlName="1832" class="form-control">
                                            <div class="mandatory"
                                                *ngIf="CancerCareNorthwestForms.controls['1832'].invalid">
                                                <small >
                                                required
                                                </small>
                                            </div>
                    </td>
                    <td colspan="3">
                        
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3399" placeholder="Gap  Start Date 3" formControlName="3399" (blur)="clearInputIfInvalid('3399','cmd_NA_18')" (blur)="handleNAchange('cmd_NA_18',3399)" [class.is-invalid]="CancerCareNorthwestForms.controls['3399'].invalid"
                                     class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa73="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa73.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                         
                                </div>   
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3399'].invalid && (CancerCareNorthwestForms.controls['3399'].value==''|| CancerCareNorthwestForms.controls['3399'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3399').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3399').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3399').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3399').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3399').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                            </div>
                        
                    </td>
                    <td colspan="5">
                        
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" id="3400" placeholder="Gap  End  Date 3" formControlName="3400"  (blur)="clearInputIfInvalid('3400','cmd_NA_18')"
                                    (blur)="handleNAchange('cmd_NA_18',3400)" [class.is-invalid]="CancerCareNorthwestForms.controls['3400'].invalid" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa74="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa74.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                        
                                </div>   
                                <div class="text-danger"
                                    *ngIf="CancerCareNorthwestForms.controls['3400'].invalid && (CancerCareNorthwestForms.controls['3400'].value==''|| CancerCareNorthwestForms.controls['3400'].value==null) ">
                                    <small>
                                        required
                                    </small>
                                
                                </div>
                                <div *ngIf="CancerCareNorthwestForms.get('3400').invalid">
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3400').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3400').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3400').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.get('3400').errors.invalidDateRange">
                                        date is out of range
                                    </small>
</div> 
                            </div>                                    
                    </td>
                </tr>
            </table>   
                <!-- <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>19. PEER REFERENCES</h6></label>
                    </th>                                
                </tr>
                <tr>
                    <td colspan="12">
                        <label class="formLabel">List at least <b>three</b>  professional references, from your specialty area, not including relatives, who have worked with you in the
                            past two years. References must be from individuals who, through recent observation, are directly familiar with your work and
                            can attest to your clinical competence in your specialty area. <b>Please provide approximate From and To dates you have
                            known the identified peer reference.</b> If you have been out of residency or fellowship for a period of less than three years,
                            one reference must be from the Program Director. Allied Health Providers must provide at least one reference from their
                            same discipline.</label>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference" id="359" formControlName="359" class="form-control">
                        </div>                                    
                    </td>
                
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Title" id="2722" formControlName="2722" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Speciality" bindValue="id" formControlName="368"> </ng-select>
                                    
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter E-mail Address" id="371" formControlName="371" class="form-control">
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="360" formControlName="360" class="form-control">
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="361" formControlName="361" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="362" formControlName="362" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="364"> </ng-select>
                                            
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="365" formControlName="365" class="form-control">
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Telephone Number" id="369" formControlName="369" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="370" formControlName="370" class="form-control">
                                            
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number: (Optional)</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Cell Phone Number" id="cmd_252" formControlName="cmd_252" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d366="ngbDatepicker" id="366" class="form-control" formControlName="366">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d366.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d367="ngbDatepicker" id="367" class="form-control" formControlName="367">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d367.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div> 
                                                            
                    </td>
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference" id="372" formControlName="372" class="form-control">
                        </div>                                    
                    </td>
                
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Title" id="2723" formControlName="2723" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Speciality" bindValue="id" formControlName="380"> </ng-select>
                                    
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter E-mail Address" id="383" formControlName="383" class="form-control">
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="373" formControlName="373" class="form-control">
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="374" formControlName="374" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="375" formControlName="375" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="377"> </ng-select>
                                            
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="378" formControlName="378" class="form-control">
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Telephone Number" id="381" formControlName="381" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="382" formControlName="382" class="form-control">							
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number: (Optional)</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Cell Phone Number" id="cmd_253" formControlName="cmd_253" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d817="ngbDatepicker" id="817" class="form-control" formControlName="817">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d817.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d379="ngbDatepicker" id="379" class="form-control" formControlName="379">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d379.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div> 
                                                            
                    </td>
                </tr>   
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Name of Reference:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Reference" id="384" formControlName="384" class="form-control">
                        </div>                                    
                    </td>
                
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Title:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Title" id="2725" formControlName="2725" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="specialty"  bindLabel="name" placeholder="Enter Speciality" bindValue="id" formControlName="383"> </ng-select>
                                    
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">E-mail Address:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter E-mail Address" id="396" formControlName="396" class="form-control">
                        </div>                                    
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Mailing Address:</label>
                        </div>
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 1" id="385" formControlName="385" class="form-control">
                            </div>    
                            <div class="col-md-12">
                                <input type="text" placeholder="Mailing Address 2" id="386" formControlName="386" class="form-control">
                            </div> 
                                                            
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">City:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter City" id="387" formControlName="387" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">State:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="389"> </ng-select>
                                            
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Zip Code:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Zip" id="390" formControlName="390" class="form-control">
                        </div>                                    
                    </td>
                </tr> 
                <tr>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Telephone Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Telephone Number" id="394" formControlName="394" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">
                        <div class="col-md-12">
                            <label class="formLabel">Fax Number:</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Fax Number" id="395" formControlName="395" class="form-control">							
                        </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Cell Phone Number: (Optional)</label>
                        </div>
                            
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Cell Phone Number" id="cmd_254" formControlName="cmd_254" class="form-control">
                        </div>                                    
                    </td>
                    <td colspan="2">                    
                            <div class="col-md-12">
                                <label class="formLabel">From (MM/YY): </label>
                            </div>                                            
                            
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d391="ngbDatepicker" id="391" class="form-control" formControlName="391">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d391.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div>                                    
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">To (MM/YY): </label>
                        </div>    
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text"  ngbDatepicker #d392="ngbDatepicker" id="392" class="form-control" formControlName="393">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d392.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                                                
                            </div> 
                                                            
                    </td>
                </tr>   
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>20. PROFESSIONAL AFFILIATIONS <i>(Do not abbreviate)</i></h6></label>
                    </th>                                
                </tr> 
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <label class="formLabel">Please List Membership In All Professional Societies
                                Complete Name of Society: </label>
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">Date Joined</label>
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                            <label class="formLabel">Current Member</label>
                        </div>  
                    </td>
                </tr>

                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <input type="text" id="1695" placeholder="Enter Name of professional Society" class="form-control" formControlName="1695" > 
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="1696" placeholder="Enter Start Date"  class="form-control" formControlName="1696" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa75="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa75.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                            </div>                
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                        
                                <input type="Radio" value="1" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                                <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="Radio" value="0" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                                <label for="No">No</label>
                            
                        </div>  
                    </td>
                </tr>
                <tr>
                    <td colspan="5">
                        <div class="col-md-12">
                            <input type="text" id="1701" placeholder="Enter Name of professional Society" class="form-control" formControlName="1701" >
                        </div>  
                    </td>
                    <td colspan="3">
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" id="1702" class="form-control" placeholder="Enter Start Date" formControlName="1702" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa075="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa075.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                            </div>                
                        </div>   
                    </td>
                    <td colspan="4">
                        <div class="col-md-12">
                        
                            <input type="Radio" value="1" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="Radio" value="0" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            <label for="No">No</label>
                            
                        </div>  
                    </td>
                </tr>
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>21. PROFESSIONAL LIABILITY</h6></label>
                    </th>                                
                </tr> 
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="345" formControlName="345" class="form-control">
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="345" formControlName="345" class="form-control">
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="901" formControlName="901" class="form-control">                      
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="902" formControlName="902" class="form-control">                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="903" formControlName="903" class="form-control">
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="905"> </ng-select>
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1266" formControlName="1266" class="form-control">
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1266" formControlName="1266" class="form-control">
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="1273" formControlName="1273" class="form-control">
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1639" formControlName="1639" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="350"> </ng-select>
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="351"> </ng-select>
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Issue Date " id="348" formControlName="348" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa101="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa101.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date " id="349" formControlName="349" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa1001="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa1001.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                            </div>
                            </div>
                                    
                    </td> 
                </tr>
    
    
                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>B. PREVIOUS PROFESSIONAL LIABILITY CARRIERS WITHIN THE LAST TEN YEARS</h6><b> (Do not abbreviate)
                                    (Attach Additional Sheet if Necessary)</b></label>
                    </th>                                
                </tr> 
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="1242" formControlName="1242" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1242'].touched && CancerCareNorthwestForms.controls['1242'].invalid ) || (CancerCareNorthwestForms.controls['1242'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1242'].touched && CancerCareNorthwestForms.controls['1242'].invalid) || (CancerCareNorthwestForms.controls['1242'].invalid && isSubmit)"> Required </div>
                                        
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="1256" formControlName="1256" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1256'].touched && CancerCareNorthwestForms.controls['1256'].invalid ) || (CancerCareNorthwestForms.controls['1256'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1256'].touched && CancerCareNorthwestForms.controls['1256'].invalid) || (CancerCareNorthwestForms.controls['1256'].invalid && isSubmit)"> Required </div>
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="1244" formControlName="1244" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1244'].touched && CancerCareNorthwestForms.controls['1244'].invalid ) || (CancerCareNorthwestForms.controls['1244'].invalid && isSubmit)">
                                    <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1244'].touched && CancerCareNorthwestForms.controls['1244'].invalid) || (CancerCareNorthwestForms.controls['1244'].invalid && isSubmit)"> Required </div>
                                
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="1245" formControlName="1245" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1245'].touched && CancerCareNorthwestForms.controls['1245'].invalid ) || (CancerCareNorthwestForms.controls['1245'].invalid && isSubmit)">
                                <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1245'].touched && CancerCareNorthwestForms.controls['1245'].invalid) || (CancerCareNorthwestForms.controls['1245'].invalid && isSubmit)"> Required </div>
                                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="1246" formControlName="1246" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1246'].touched && CancerCareNorthwestForms.controls['1246'].invalid ) || (CancerCareNorthwestForms.controls['1246'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1246'].touched && CancerCareNorthwestForms.controls['1246'].invalid) || (CancerCareNorthwestForms.controls['1246'].invalid && isSubmit)"> Required </div>
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states" [class.is-invalid]="(CancerCareNorthwestForms.controls['1248'].touched &&
                                CancerCareNorthwestForms.controls['1248'].invalid) ||
                                (CancerCareNorthwestForms.controls['1248'].invalid && isSubmit)" bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="1248"> </ng-select>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1248'].touched && CancerCareNorthwestForms.controls['1248'].invalid) || (CancerCareNorthwestForms.controls['1248'].invalid && isSubmit)"> State is required and cannot be empty </div>
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="1249" formControlName="1249" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1249'].touched && CancerCareNorthwestForms.controls['1249'].invalid ) || (CancerCareNorthwestForms.controls['1249'].invalid && isSubmit)">
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1249'].touched && CancerCareNorthwestForms.controls['1249'].invalid) || (CancerCareNorthwestForms.controls['1249'].invalid && isSubmit)"> Required </div>
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1254" formControlName="1254" class="form-control" [class.is-invalid]="(CancerCareNorthwestForms.controls['1254'].touched && CancerCareNorthwestForms.controls['1254'].invalid ) || (CancerCareNorthwestForms.controls['1254'].invalid && isSubmit)"> <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['1254'].errors?.pattern">
                                    Only number are allowed</small> 
                                        <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1254'].touched && CancerCareNorthwestForms.controls['1254'].invalid) || (CancerCareNorthwestForms.controls['1254'].invalid && isSubmit)"> Required </div>
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="1255"class="form-control"  formControlName="1255" [class.is-invalid]="(CancerCareNorthwestForms.controls['1254'].touched && CancerCareNorthwestForms.controls['1254'].invalid ) || (CancerCareNorthwestForms.controls['1254'].invalid && isSubmit)"> 
                                        <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1254'].touched && CancerCareNorthwestForms.controls['1254'].invalid) || (CancerCareNorthwestForms.controls['1254'].invalid && isSubmit)"> Required </div>
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="1857" formControlName="1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim" [class.is-invalid]="(CancerCareNorthwestForms.controls['1264'].touched &&
                                CancerCareNorthwestForms.controls['1264'].invalid) ||
                                (CancerCareNorthwestForms.controls['1264'].invalid && isSubmit)" bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="1264"> </ng-select>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1264'].touched && CancerCareNorthwestForms.controls['1264'].invalid) || (CancerCareNorthwestForms.controls['1264'].invalid && isSubmit)"> Per Claim Amount is required and cannot be empty </div>
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates" [class.is-invalid]="(CancerCareNorthwestForms.controls['1265'].touched &&
                                CancerCareNorthwestForms.controls['1265'].invalid) ||
                                (CancerCareNorthwestForms.controls['1265'].invalid && isSubmit)" bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="1265"> </ng-select>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1265'].touched && CancerCareNorthwestForms.controls['1265'].invalid) || (CancerCareNorthwestForms.controls['1265'].invalid && isSubmit)"> Aggregate Amount is required and cannot be empty </div>
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="1252" formControlName="1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa76="ngbDatepicker" [class.is-invalid]="(CancerCareNorthwestForms.controls['1252'].touched && CancerCareNorthwestForms.controls['1252'].invalid ) || (CancerCareNorthwestForms.controls['1252'].invalid && isSubmit)">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa76.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1252'].touched && CancerCareNorthwestForms.controls['1252'].invalid) || (CancerCareNorthwestForms.controls['1252'].invalid && isSubmit)"> Required </div>
                            
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="1253" formControlName="1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa77="ngbDatepicker" [class.is-invalid]="(CancerCareNorthwestForms.controls['1253'].touched && CancerCareNorthwestForms.controls['1253'].invalid ) || (CancerCareNorthwestForms.controls['1253'].invalid && isSubmit)">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa77.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            <div class="invalid-feedback" *ngIf="(CancerCareNorthwestForms.controls['1253'].touched && CancerCareNorthwestForms.controls['1253'].invalid) || (CancerCareNorthwestForms.controls['1253'].invalid && isSubmit)"> Required </div>
                                    
                            </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_1_1242" formControlName="add_1_1242" class="form-control">			
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_1_1256" formControlName="add_1_1256" class="form-control">	
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_1_1244" formControlName="add_1_1244" class="form-control">
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_1_1245" formControlName="add_1_1245" class="form-control">		
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_1_1246" formControlName="add_1_1246" class="form-control">		
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_1_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_1_1249" formControlName="add_1_1249" class="form-control">	
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_1_1254" formControlName="add_1_1254" class="form-control">    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_1_1255" formControlName="add_1_1255" class="form-control">    
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_1_1857" formControlName="add_1_1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"  bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_1_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_1_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_1_1252" formControlName="add_1_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa076="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa076.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_1_1253" formControlName="add_1_1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa177="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa177.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_2_1242" formControlName="add_2_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_2_1256" formControlName="add_2_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_2_1244" formControlName="add_2_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_2_1245" formControlName="add_2_1245" class="form-control" >
                                
                                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_2_1246" formControlName="add_2_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_2_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_2_1249" formControlName="add_2_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_2_1254" formControlName="add_2_1254" class="form-control" >
                                        
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_2_1255"class="form-control"  formControlName="add_2_1255" > 
                                        
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_2_1857" formControlName="add_2_1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_2_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_2_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_2_1252" formControlName="add_2_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa276="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa276.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                    <input type="text" placeholder="Enter Expiration Date" id="add_2_1253" formControlName="add_2_1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa277="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa277.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                </div>
                            </div>                                
                    </td> 
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_4_1242" formControlName="add_4_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_4_1256" formControlName="add_4_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_4_1244" formControlName="add_4_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_4_1245" formControlName="add_4_1245" class="form-control" >
                                
                                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_4_1246" formControlName="add_4_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_4_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_4_1249" formControlName="add_4_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_4_1254" formControlName="add_4_1254" class="form-control" >
                                        
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_4_1255"class="form-control"  formControlName="add_4_1255" > 
                                        
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_4_1857" formControlName="add_4_1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_4_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_4_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_4_1252" formControlName="add_4_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa476="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa476.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_4_1253" formControlName="add_4_1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa477="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa477.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_5_1242" formControlName="add_5_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_5_1256" formControlName="add_5_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_5_1244" formControlName="add_5_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_5_1245" formControlName="add_5_1245" class="form-control" >
                                
                                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_5_1246" formControlName="add_5_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_5_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_5_1249" formControlName="add_5_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_5_1254" formControlName="add_5_1254" class="form-control" >
                                        
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_5_1255"class="form-control"  formControlName="add_5_1255" > 
                                        
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_5_1857" formControlName="add_5_1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_5_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_5_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_5_1252" formControlName="add_5_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa576="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa576.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>            
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date
                                (mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Expiration Date" id="add_5_1253" formControlName="add_5_1253" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa577="ngbDatepicker" >
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="wpa577.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                            </div>
                                            
                                    
                            </div>
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            
                                <b>Name of Carrier:</b>
                        </div>  
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Name of Carrier" id="add_6_1242" formControlName="add_6_1242" class="form-control" >
                                            
                                        
                        </div>      
                    </td>   
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Policy Number:</label>
                        </div> 
                        <div class="col-md-12">
                            <input type="text" placeholder="Enter Policy Number" id="add_6_1256" formControlName="add_6_1256" class="form-control" >
                                            
                                
                        </div>        
                    </td>                                  
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Mailing Address:</label>
                        </div> 
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_6_1244" formControlName="add_6_1244" class="form-control" >
                                    
                                
                            </div>
                            <div class="col-md-6">
                                <input type="text" placeholder="Enter Mailing Address" id="add_6_1245" formControlName="add_6_1245" class="form-control" >
                                
                                        
                            </div>
                        </div>          
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            City:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter City" id="add_6_1246" formControlName="add_6_1246" class="form-control" >
                                            
                                        
                            </div>
                                    
                    </td>     
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            State:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="states"  bindLabel="name" placeholder="Enter state" bindValue="id" formControlName="add_6_1248"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                            Zip Code:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Zip Code" id="add_6_1249" formControlName="add_6_1249" class="form-control" >
                                            
                                    
                            </div>
                                    
                    </td>     
                </tr> 
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Phone Number:</label>
                        
                            </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_6_1254" formControlName="add_6_1254" class="form-control" >
                                        
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Fax Number:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Fax Number" id="add_6_1255"class="form-control"  formControlName="add_6_1255" > 
                                        
                                
                            </div>
                                    
                    </td> 
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Claims History/Verification E-mail Address:</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <input type="text" placeholder="Enter Phone Number" id="add_6_1857" formControlName="add_6_1857" class="form-control">
                            </div>
                                    
                    </td> 
                </tr>
                <tr>
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Per claim amount: $ </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="preClaim"bindLabel="name" placeholder="Enter claim amount" bindValue="id" formControlName="add_6_1264"> </ng-select>
                                            
                                    
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Aggregate amount: $</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <ng-select [items]="aggregates"  bindLabel="name" placeholder="Enter aggregate amount" bindValue="id" formControlName="add_6_1265"> </ng-select>
                                            
                                        
                            </div>
                                    
                    </td> 
                    <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Date Began (mm/yyyy): </label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Enter Effective Date" id="add_6_1252" formControlName="add_6_1252" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #wpa676="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="wpa676.toggle()" type="button"> <i class="fas fa-calendar-alt"></i> </button>
                                    </div>
                                    
                            
                                </div>            
                            </div>
                                    
                    </td> 
                   <td colspan="3">
                        <div class="col-md-12">
                            <label class="formLabel">
                                Expiration Date(mm/yyyy):</label>
                        </div> 
                        
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" placeholder="Expiration Date" ngbDatepicker #d031="ngbDatepicker" id="add_6_1253" class="form-control" formControlName="add_6_1253">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d031.toggle()" type="button">
                                            <i class="fas fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                </div>
                            </div>
                                    
                    </td> 
                </tr>
                 <tr>
                    <td colspan="12">
                        
                            <label class="sideheading"  >
                                <h6>WASHINGTON PRACTITIONER ATTESTATION QUESTIONS -<i> To be completed by the practitioner</i></h6> </label>
                    </td>
                </tr>    
                <tr>
                    <td colspan="12">
                        <label class="formLabel">Please answer all of the following questions. If your answer to any of the following questions is 'Yes", provide details as specified
                            on a separate sheet.<i> If you attach additional sheets, sign and date each sheet.</i></label>
                    </td>   
                </tr>
                
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>A.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>PROFESSIONAL SANCTIONS</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been, or are you now in the process of being denied, revoked, terminated, suspended, restricted, reduced,
                                limited, sanctioned, placed on probation, monitored, or not renewed for any of the following? Or have you voluntarily or
                                involuntarily relinquished, withdrawn, or failed to proceed with an application for any of the following in order to avoid an
                                adverse action or to preclude an investigation or while under investigation relating to professional competence or conduct?
                            </label>
                        </div>        

                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">a.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">License to practice any profession in any jurisdiction</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_329"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_329'].touched && CancerCareNorthwestForms.controls['cmd_329'].invalid) || (CancerCareNorthwestForms.controls['cmd_329'].invalid && isSubmit)"
                                    id="cmd_329" name="cmd_329" value="1">
                    
                                <label>Yes</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_329'].touched && CancerCareNorthwestForms.controls['cmd_329'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_329'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_329"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_329'].touched && CancerCareNorthwestForms.controls['cmd_329'].invalid) || (CancerCareNorthwestForms.controls['cmd_329'].invalid && isSubmit)"
                                    id="cmd_329" name="cmd_329" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_329'].touched && CancerCareNorthwestForms.controls['cmd_329'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_329'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_329'].touched && CancerCareNorthwestForms.controls['cmd_329'].invalid) || (CancerCareNorthwestForms.controls['cmd_329'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>

                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">b.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Other professional registration or certification in any jurisdiction</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_330"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_330'].touched && CancerCareNorthwestForms.controls['cmd_330'].invalid) || (CancerCareNorthwestForms.controls['cmd_330'].invalid && isSubmit)"
                                    id="cmd_330" name="cmd_330" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_330'].touched && CancerCareNorthwestForms.controls['cmd_330'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_330'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_330"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_330'].touched && CancerCareNorthwestForms.controls['cmd_330'].invalid) || (CancerCareNorthwestForms.controls['cmd_330'].invalid && isSubmit)"
                                    id="cmd_330" name="cmd_330" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_330'].touched && CancerCareNorthwestForms.controls['cmd_330'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_330'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_330'].touched && CancerCareNorthwestForms.controls['cmd_330'].invalid) || (CancerCareNorthwestForms.controls['cmd_330'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">c.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Specialty or subspecialty board certification</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            
                            <input type="radio" formControlName="cmd_331"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_331'].touched && CancerCareNorthwestForms.controls['cmd_331'].invalid) || (CancerCareNorthwestForms.controls['cmd_331'].invalid && isSubmit)"
                            id="cmd_331" name="cmd_331" value="1">
            
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_331'].touched && CancerCareNorthwestForms.controls['cmd_331'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_331'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_331"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_331'].touched && CancerCareNorthwestForms.controls['cmd_331'].invalid) || (CancerCareNorthwestForms.controls['cmd_331'].invalid && isSubmit)"
                                    id="cmd_331" name="cmd_331" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_331'].touched && CancerCareNorthwestForms.controls['cmd_331'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_331'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_331'].touched && CancerCareNorthwestForms.controls['cmd_331'].invalid) || (CancerCareNorthwestForms.controls['cmd_331'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                                                                        
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">d.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Membership on any hospital medical staff</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_332"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_332'].touched && CancerCareNorthwestForms.controls['cmd_332'].invalid) || (CancerCareNorthwestForms.controls['cmd_332'].invalid && isSubmit)"
                                    id="cmd_332" name="cmd_332" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_332'].touched && CancerCareNorthwestForms.controls['cmd_332'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_332'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_332"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_332'].touched && CancerCareNorthwestForms.controls['cmd_332'].invalid) || (CancerCareNorthwestForms.controls['cmd_332'].invalid && isSubmit)"
                                    id="cmd_332" name="cmd_332" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_332'].touched && CancerCareNorthwestForms.controls['cmd_332'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_332'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_332'].touched && CancerCareNorthwestForms.controls['cmd_332'].invalid) || (CancerCareNorthwestForms.controls['cmd_332'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">e.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Clinical privileges at any facility, including hospitals, ambulatory surgical centers, skilled nursing
                                facilities, etc.</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_333"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_333'].touched && CancerCareNorthwestForms.controls['cmd_333'].invalid) || (CancerCareNorthwestForms.controls['cmd_333'].invalid && isSubmit)"
                            id="cmd_333" name="cmd_333" value="1">
            
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="CancerCareNorthwestForms.controls['cmd_333'].touched && CancerCareNorthwestForms.controls['cmd_333'].invalid">
                
                                <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_333'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_333"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_333'].touched && CancerCareNorthwestForms.controls['cmd_333'].invalid) || (CancerCareNorthwestForms.controls['cmd_333'].invalid && isSubmit)"
                                    id="cmd_333" name="cmd_333" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_333'].touched && CancerCareNorthwestForms.controls['cmd_333'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_333'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_333'].touched && CancerCareNorthwestForms.controls['cmd_333'].invalid) || (CancerCareNorthwestForms.controls['cmd_333'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">f.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Medicare, Medicaid, FDA, NIH (Office of Human Research Protection), governmental, national
                                or international regulatory agency or any public program</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_334"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_334'].touched && CancerCareNorthwestForms.controls['cmd_334'].invalid) || (CancerCareNorthwestForms.controls['cmd_334'].invalid && isSubmit)"
                            id="cmd_334" name="cmd_334" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_334'].touched && CancerCareNorthwestForms.controls['cmd_334'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_334'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_334"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_334'].touched && CancerCareNorthwestForms.controls['cmd_334'].invalid) || (CancerCareNorthwestForms.controls['cmd_334'].invalid && isSubmit)"
                                    id="cmd_334" name="cmd_334" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_334'].touched && CancerCareNorthwestForms.controls['cmd_334'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_334'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_334'].touched && CancerCareNorthwestForms.controls['cmd_334'].invalid) || (CancerCareNorthwestForms.controls['cmd_334'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">g.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Professional society membership or fellowship</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_335"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_335'].touched && CancerCareNorthwestForms.controls['cmd_335'].invalid) || (CancerCareNorthwestForms.controls['cmd_335'].invalid && isSubmit)"
                                    id="cmd_335" name="cmd_335" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_335'].touched && CancerCareNorthwestForms.controls['cmd_335'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_335'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_335"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_335'].touched && CancerCareNorthwestForms.controls['cmd_335'].invalid) || (CancerCareNorthwestForms.controls['cmd_335'].invalid && isSubmit)"
                                    id="cmd_335" name="cmd_335" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_335'].touched && CancerCareNorthwestForms.controls['cmd_335'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_335'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_335'].touched && CancerCareNorthwestForms.controls['cmd_335'].invalid) || (CancerCareNorthwestForms.controls['cmd_335'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">h.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Participation/membership in an HMO, PPO, IPA, PHO, Health Plan or other entity</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_336"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_336'].touched && CancerCareNorthwestForms.controls['cmd_336'].invalid) || (CancerCareNorthwestForms.controls['cmd_336'].invalid && isSubmit)"
                                    id="cmd_336" name="cmd_336" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_336'].touched && CancerCareNorthwestForms.controls['cmd_336'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_336'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_336"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_336'].touched && CancerCareNorthwestForms.controls['cmd_336'].invalid) || (CancerCareNorthwestForms.controls['cmd_336'].invalid && isSubmit)"
                                    id="cmd_336" name="cmd_336" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_336'].touched && CancerCareNorthwestForms.controls['cmd_336'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_336'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_336'].touched && CancerCareNorthwestForms.controls['cmd_336'].invalid) || (CancerCareNorthwestForms.controls['cmd_336'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">i.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Academic Appointment</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_337"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_337'].touched && CancerCareNorthwestForms.controls['cmd_337'].invalid) || (CancerCareNorthwestForms.controls['cmd_337'].invalid && isSubmit)"
                            id="cmd_337" name="cmd_337" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_337'].touched && CancerCareNorthwestForms.controls['cmd_337'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_337'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_337"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_337'].touched && CancerCareNorthwestForms.controls['cmd_337'].invalid) || (CancerCareNorthwestForms.controls['cmd_337'].invalid && isSubmit)"
                                    id="cmd_337" name="cmd_337" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_337'].touched && CancerCareNorthwestForms.controls['cmd_337'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_337'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_337'].touched && CancerCareNorthwestForms.controls['cmd_337'].invalid) || (CancerCareNorthwestForms.controls['cmd_337'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">j.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Authority to prescribe controlled substances (DEA or other authority)</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_338"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_338'].touched && CancerCareNorthwestForms.controls['cmd_338'].invalid) || (CancerCareNorthwestForms.controls['cmd_338'].invalid && isSubmit)"
                            id="cmd_338" name="cmd_338" value="1">
            
                                        <label>Yes&nbsp;&nbsp;</label>
                            
                                        <div class="formLabel col-md-11"
                                            *ngIf="CancerCareNorthwestForms.controls['cmd_338'].touched && CancerCareNorthwestForms.controls['cmd_338'].invalid">
                            
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_338'].errors?.required">
                            
                                                required
                            
                                            </small>
                            
                                        </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_338"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_338'].touched && CancerCareNorthwestForms.controls['cmd_338'].invalid) || (CancerCareNorthwestForms.controls['cmd_338'].invalid && isSubmit)"
                                    id="cmd_338" name="cmd_338" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_338'].touched && CancerCareNorthwestForms.controls['cmd_338'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_338'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_338'].touched && CancerCareNorthwestForms.controls['cmd_338'].invalid) || (CancerCareNorthwestForms.controls['cmd_338'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been subject to review, challenges, and/or disciplinary action, formal or informal, by
                                an ethics committee, licensing board, medical disciplinary board, professional association or
                                education/training institution?   </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_339"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_339'].touched && CancerCareNorthwestForms.controls['cmd_339'].invalid) || (CancerCareNorthwestForms.controls['cmd_339'].invalid && isSubmit)"
                            id="cmd_339" name="cmd_339" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_339'].touched && CancerCareNorthwestForms.controls['cmd_339'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_339'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_339"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_339'].touched && CancerCareNorthwestForms.controls['cmd_339'].invalid) || (CancerCareNorthwestForms.controls['cmd_339'].invalid && isSubmit)"
                            id="cmd_339" name="cmd_339" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_339'].touched && CancerCareNorthwestForms.controls['cmd_339'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_339'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_339'].touched && CancerCareNorthwestForms.controls['cmd_339'].invalid) || (CancerCareNorthwestForms.controls['cmd_339'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you been found by a state professional disciplinary board to have committed unprofessional
                                conduct as defined in applicable state provisions?  </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_340"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_340'].touched && CancerCareNorthwestForms.controls['cmd_340'].invalid) || (CancerCareNorthwestForms.controls['cmd_340'].invalid && isSubmit)"
                                    id="cmd_340" name="cmd_340" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_340'].touched && CancerCareNorthwestForms.controls['cmd_340'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_340'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_340"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_340'].touched && CancerCareNorthwestForms.controls['cmd_340'].invalid) || (CancerCareNorthwestForms.controls['cmd_340'].invalid && isSubmit)"
                                    id="cmd_340" name="cmd_340" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_340'].touched && CancerCareNorthwestForms.controls['cmd_340'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_340'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_340'].touched && CancerCareNorthwestForms.controls['cmd_340'].invalid) || (CancerCareNorthwestForms.controls['cmd_340'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>4.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been the subject of any reports to a state, federal, national data bank, or state
                                licensing or disciplinary entity? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_341"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_341'].touched && CancerCareNorthwestForms.controls['cmd_341'].invalid) || (CancerCareNorthwestForms.controls['cmd_341'].invalid && isSubmit)"
                            id="cmd_341" name="cmd_341" value="1">
            
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_341'].touched && CancerCareNorthwestForms.controls['cmd_341'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_341'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_341"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_341'].touched && CancerCareNorthwestForms.controls['cmd_341'].invalid) || (CancerCareNorthwestForms.controls['cmd_341'].invalid && isSubmit)"
                            id="cmd_341" name="cmd_341" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_341'].touched && CancerCareNorthwestForms.controls['cmd_341'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_341'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_341'].touched && CancerCareNorthwestForms.controls['cmd_341'].invalid) || (CancerCareNorthwestForms.controls['cmd_341'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr> 
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>B.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>CRIMINAL HISTORY</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been charged with a criminal violation (felony or misdemeanor) resulting in either a
                                plea bargain, conviction on the original or lesser charge, or payment of a fine, suspended sentence,
                                community service or other obligation? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_342"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_342'].touched && CancerCareNorthwestForms.controls['cmd_342'].invalid) || (CancerCareNorthwestForms.controls['cmd_342'].invalid && isSubmit)"
                                    id="cmd_342" name="cmd_342" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_342'].touched && CancerCareNorthwestForms.controls['cmd_342'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_342'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_342"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_342'].touched && CancerCareNorthwestForms.controls['cmd_342'].invalid) || (CancerCareNorthwestForms.controls['cmd_342'].invalid && isSubmit)"
                            id="cmd_342" name="cmd_342" value="0">
            
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_342'].touched && CancerCareNorthwestForms.controls['cmd_342'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_342'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_342'].touched && CancerCareNorthwestForms.controls['cmd_342'].invalid) || (CancerCareNorthwestForms.controls['cmd_342'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">a.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Do you have notice of any such anticipated charges?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_343"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_343'].touched && CancerCareNorthwestForms.controls['cmd_343'].invalid) || (CancerCareNorthwestForms.controls['cmd_343'].invalid && isSubmit)"
                                    id="cmd_343" name="cmd_343" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_343'].touched && CancerCareNorthwestForms.controls['cmd_343'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_343'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_343"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_343'].touched && CancerCareNorthwestForms.controls['cmd_343'].invalid) || (CancerCareNorthwestForms.controls['cmd_343'].invalid && isSubmit)"
                                    id="cmd_343" name="cmd_343" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_343'].touched && CancerCareNorthwestForms.controls['cmd_343'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_343'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_343'].touched && CancerCareNorthwestForms.controls['cmd_343'].invalid) || (CancerCareNorthwestForms.controls['cmd_343'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>

                <tr >
                    <td colspan="1" style="width:1px;">
                        
                            

                            
                    </td>
                    <td colspan="1" style="width:1px;">
                        <div class="col-md-12">
                            <label class="formLabel">b.</label>
                        </div>        

                    </td>
                    <td colspan="7">
                        <div class="col-md-12">
                            <label class="formLabel">Are you currently under governmental investigation?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_344"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_344'].touched && CancerCareNorthwestForms.controls['cmd_344'].invalid) || (CancerCareNorthwestForms.controls['cmd_344'].invalid && isSubmit)"
                                    id="cmd_344" name="cmd_344" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_344'].touched && CancerCareNorthwestForms.controls['cmd_344'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_344'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_344"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_344'].touched && CancerCareNorthwestForms.controls['cmd_344'].invalid) || (CancerCareNorthwestForms.controls['cmd_344'].invalid && isSubmit)"
                                    id="cmd_344" name="cmd_344" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_344'].touched && CancerCareNorthwestForms.controls['cmd_344'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_344'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_344'].touched && CancerCareNorthwestForms.controls['cmd_344'].invalid) || (CancerCareNorthwestForms.controls['cmd_344'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>C.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>AFFIRMATION OF ABILITIES</b></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Do you presently use any drugs illegally? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_345"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_345'].touched && CancerCareNorthwestForms.controls['cmd_345'].invalid) || (CancerCareNorthwestForms.controls['cmd_345'].invalid && isSubmit)"
                                    id="cmd_345" name="cmd_345" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_345'].touched && CancerCareNorthwestForms.controls['cmd_345'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_345'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_345"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_345'].touched && CancerCareNorthwestForms.controls['cmd_345'].invalid) || (CancerCareNorthwestForms.controls['cmd_345'].invalid && isSubmit)"
                            id="cmd_345" name="cmd_345" value="0">
            
                                    <label>No</label>
                        
                                    <div class="formLabel col-md-11"
                                        *ngIf="CancerCareNorthwestForms.controls['cmd_345'].touched && CancerCareNorthwestForms.controls['cmd_345'].invalid">
                        
                                        <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_345'].errors?.required">
                        
                                            required
                        
                                        </small>
                        
                                    </div>
                        
                                    <span
                                        *ngIf="(CancerCareNorthwestForms.controls['cmd_345'].touched && CancerCareNorthwestForms.controls['cmd_345'].invalid) || (CancerCareNorthwestForms.controls['cmd_345'].invalid && isSubmit)">
                        
                                        <i class="fas fa-times ms-2 text-danger"></i>
                        
                                    </span>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Do you have any physical, mental health, or substance use condition that currently impairs, or could
                                impair, your ability to practice your profession in a competent, ethical, and professional manner?<b> If
                                the answer to this question is yes, please complete Section 23 below.</b> </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_346"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_346'].touched && CancerCareNorthwestForms.controls['cmd_346'].invalid) || (CancerCareNorthwestForms.controls['cmd_346'].invalid && isSubmit)"
                                    id="cmd_346" name="cmd_346" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_346'].touched && CancerCareNorthwestForms.controls['cmd_346'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_346'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_346"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_346'].touched && CancerCareNorthwestForms.controls['cmd_346'].invalid) || (CancerCareNorthwestForms.controls['cmd_346'].invalid && isSubmit)"
                                    id="cmd_346" name="cmd_346" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_346'].touched && CancerCareNorthwestForms.controls['cmd_346'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_346'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_346'].touched && CancerCareNorthwestForms.controls['cmd_346'].invalid) || (CancerCareNorthwestForms.controls['cmd_346'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                    
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are you unable to perform any of the services/clinical privileges required by the applicable
                                participating practitioner agreement/hospital agreement, with or without reasonable accommodation,
                                according to accepted standards of professional performance?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_347"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_347'].touched && CancerCareNorthwestForms.controls['cmd_347'].invalid) || (CancerCareNorthwestForms.controls['cmd_347'].invalid && isSubmit)"
                            id="cmd_347" name="cmd_347" value="1">
                            
                                        <label>Yes&nbsp;&nbsp;</label>
                            
                                        <div class="formLabel col-md-11"
                                            *ngIf="CancerCareNorthwestForms.controls['cmd_347'].touched && CancerCareNorthwestForms.controls['cmd_347'].invalid">
                            
                                            <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_347'].errors?.required">
                            
                                                required
                            
                                            </small>
                            
                                        </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_347"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_347'].touched && CancerCareNorthwestForms.controls['cmd_347'].invalid) || (CancerCareNorthwestForms.controls['cmd_347'].invalid && isSubmit)"
                                    id="cmd_347" name="cmd_347" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_347'].touched && CancerCareNorthwestForms.controls['cmd_347'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_347'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_347'].touched && CancerCareNorthwestForms.controls['cmd_347'].invalid) || (CancerCareNorthwestForms.controls['cmd_347'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                
                
                <tr  style="outline: 1px solid black" >
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>D.</b></label>

                            
                    </td>
                    <td colspan="11">
                        <div class="col-md-12">
                            <b>LITIGATION AND MALPRACTICE COVERAGE HISTORY (If you answer "Yes" to any of the questions in this
                                section,</b> <label class="formLabel">
                                    please document in Section 22. PROFESSIONAL LIABILITY ACTION DETAIL of this application.)</label></div>        
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>1.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have allegations or claims of professional negligence been made against you at any time, whether or
                                not you were individually named in the claim or lawsuit? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_348"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_348'].touched && CancerCareNorthwestForms.controls['cmd_348'].invalid) || (CancerCareNorthwestForms.controls['cmd_348'].invalid && isSubmit)"
                                    id="cmd_348" name="cmd_348" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_348'].touched && CancerCareNorthwestForms.controls['cmd_348'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_348'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_348"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_348'].touched && CancerCareNorthwestForms.controls['cmd_348'].invalid) || (CancerCareNorthwestForms.controls['cmd_348'].invalid && isSubmit)"
                                    id="cmd_348" name="cmd_348" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_348'].touched && CancerCareNorthwestForms.controls['cmd_348'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_348'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_348'].touched && CancerCareNorthwestForms.controls['cmd_348'].invalid) || (CancerCareNorthwestForms.controls['cmd_348'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>2.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you or your insurance carrier(s) ever paid any money on your behalf to settle/resolve a
                                professional malpractice claim (not necessarily a lawsuit) and/or to satisfy a judgement (courtordered damage award) in a professional lawsuit? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_349"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_349'].touched && CancerCareNorthwestForms.controls['cmd_349'].invalid) || (CancerCareNorthwestForms.controls['cmd_349'].invalid && isSubmit)"
                                    id="cmd_349" name="cmd_349" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_349'].touched && CancerCareNorthwestForms.controls['cmd_349'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_349'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_349"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_349'].touched && CancerCareNorthwestForms.controls['cmd_349'].invalid) || (CancerCareNorthwestForms.controls['cmd_349'].invalid && isSubmit)"
                                    id="cmd_349" name="cmd_349" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_349'].touched && CancerCareNorthwestForms.controls['cmd_349'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_349'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_349'].touched && CancerCareNorthwestForms.controls['cmd_349'].invalid) || (CancerCareNorthwestForms.controls['cmd_349'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>3.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are there any such claims being asserted against you now?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_350"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_350'].touched && CancerCareNorthwestForms.controls['cmd_350'].invalid) || (CancerCareNorthwestForms.controls['cmd_350'].invalid && isSubmit)"
                                    id="cmd_350" name="cmd_350" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_350'].touched && CancerCareNorthwestForms.controls['cmd_350'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_350'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_350"
                            [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_350'].touched && CancerCareNorthwestForms.controls['cmd_350'].invalid) || (CancerCareNorthwestForms.controls['cmd_350'].invalid && isSubmit)"
                            id="cmd_350" name="cmd_350" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_350'].touched && CancerCareNorthwestForms.controls['cmd_350'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_350'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_350'].touched && CancerCareNorthwestForms.controls['cmd_350'].invalid) || (CancerCareNorthwestForms.controls['cmd_350'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>4.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Have you ever been denied professional liability coverage or has your coverage ever been
                                terminated, not renewed, restricted, or modified (e.g., reduced limits, restricted coverage,
                                surcharged)?</label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_351"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_351'].touched && CancerCareNorthwestForms.controls['cmd_351'].invalid) || (CancerCareNorthwestForms.controls['cmd_351'].invalid && isSubmit)"
                                    id="cmd_351" name="cmd_351" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_351'].touched && CancerCareNorthwestForms.controls['cmd_351'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_351'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_351"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_351'].touched && CancerCareNorthwestForms.controls['cmd_351'].invalid) || (CancerCareNorthwestForms.controls['cmd_351'].invalid && isSubmit)"
                                    id="cmd_351" name="cmd_351" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_351'].touched && CancerCareNorthwestForms.controls['cmd_351'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_351'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_351'].touched && CancerCareNorthwestForms.controls['cmd_351'].invalid) || (CancerCareNorthwestForms.controls['cmd_351'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="width:1px;">
                        
                            <label class="formLabel">
                                <b>5.</b></label>

                            
                    </td>
                    <td colspan="8">
                        <div class="col-md-12">
                            <label class="formLabel">Are any of the privileges that you are requesting <u>not</u> covered by your current malpractice coverage? </label>
                        </div>        

                    </td>
                    <td colspan="1" style="width:90px;">
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_352"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_352'].touched && CancerCareNorthwestForms.controls['cmd_352'].invalid) || (CancerCareNorthwestForms.controls['cmd_352'].invalid && isSubmit)"
                                    id="cmd_352" name="cmd_352" value="1">
                    
                                <label>Yes&nbsp;&nbsp;</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_352'].touched && CancerCareNorthwestForms.controls['cmd_352'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_352'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                            
                                
                        </div>
                    </td>
                    <td colspan="3" >
                        <div class="col-md-12">
                            <input type="radio" formControlName="cmd_352"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_352'].touched && CancerCareNorthwestForms.controls['cmd_352'].invalid) || (CancerCareNorthwestForms.controls['cmd_352'].invalid && isSubmit)"
                                    id="cmd_352" name="cmd_352" value="0">
                    
                                <label>No</label>
                    
                                <div class="formLabel col-md-11"
                                    *ngIf="CancerCareNorthwestForms.controls['cmd_352'].touched && CancerCareNorthwestForms.controls['cmd_352'].invalid">
                    
                                    <small class="text-danger" *ngIf="CancerCareNorthwestForms.controls['cmd_352'].errors?.required">
                    
                                        required
                    
                                    </small>
                    
                                </div>
                    
                                <span
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_352'].touched && CancerCareNorthwestForms.controls['cmd_352'].invalid) || (CancerCareNorthwestForms.controls['cmd_352'].invalid && isSubmit)">
                    
                                    <i class="fas fa-times ms-2 text-danger"></i>
                    
                                </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="col-md-12">
                            <label class="formLabel">I warrant that all the statements made on this form and on any attached information sheets are complete, accurate, and current. I
                                understand that any material misstatements in, or omissions from, this statement constitute cause for denial of membership or cause for
                                summary dismissal from the entity to which this statement has been submitted.</label>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Applicant's Signature: </label>
                        </div> 
                        <div class="col-md-12">
                            <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                            (click)="esignOpenOne()" />
                                <input type="hidden" name="cmd_0019" id="cmd_0019" [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_0019'].touched && CancerCareNorthwestForms.controls['cmd_0019'].invalid) || (CancerCareNorthwestForms.controls['cmd_0019'].invalid && isSubmit)" formControlName="cmd_0019" value="{{ finalImgPath }}" />
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_0019'].touched && CancerCareNorthwestForms.controls['cmd_0019'].invalid) || (CancerCareNorthwestForms.controls['cmd_0019'].invalid && isSubmit)">
                                        Signature is required 
                                </div>
                        </div> 
                    </td>
                    <td colspan="6">
                        <div class="col-md-12">
                            <label class="formLabel">Date </label>
                        </div> 
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text" placeholder="Enter Date" id="cmd_0020" class="form-control" formControlName="cmd_0020"
                                [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_0020'].touched && CancerCareNorthwestForms.controls['cmd_0020'].invalid ) || (CancerCareNorthwestForms.controls['cmd_0020'].invalid && isSubmit)" ngbDatepicker #d0020="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d0020.toggle()" type="button">
                                        <i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                        </div>    
                    
                        <div class="invalid-feedback"
                            *ngIf="(CancerCareNorthwestForms.controls['cmd_0020'].touched && CancerCareNorthwestForms.controls['cmd_0020'].invalid) || (CancerCareNorthwestForms.controls['cmd_0020'].invalid && isSubmit)">
                            Date is required and cannot be empty
                        </div>
                        </div> 
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Type or Print name here </label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" placeholder="Typed or printed name" [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_33553'].touched &&
                                        
                                        CancerCareNorthwestForms.controls['cmd_33553'].invalid) ||
                                        
                                        (CancerCareNorthwestForms.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                            formControlName="cmd_33553" class="form-control">
                    
                                    
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['cmd_33553'].touched && CancerCareNorthwestForms.controls['cmd_33553'].invalid) || (CancerCareNorthwestForms.controls['cmd_33553'].invalid && isSubmit)">
                    
                                        Name is required and cannot be empty
                    
                                    </div>
                            </div> 
                        </div>    
                    </td>

                </tr>
            </table><br> 
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>22. PROFESSIONAL LIABILITY ACTION DETAIL – CONFIDENTIAL </h6> </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_1')" formControlName="cmd_NA_1"
                            id="cmd_NA_1">
                    </th>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="formLabel">Practitioner Name:(print or type) </label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder=" PROVIDER PRINTED NAME AS ENTERED ABOVE" id="cmd_356"
                                        formControlName="cmd_356" class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_356'].touched && CancerCareNorthwestForms.controls['cmd_356'].invalid ) || (CancerCareNorthwestForms.controls['cmd_356'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['cmd_356'].touched && CancerCareNorthwestForms.controls['cmd_356'].invalid) || (CancerCareNorthwestForms.controls['cmd_356'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>        
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Please list any past or current professional liability claim(s) or lawsuit(s), in which allegations of professional
                                    negligence were made against you, whether or not you were individually named in the claim or lawsuit. <u>Please do
                                    not include patient names or other HIPAA protected PHI.</u> Photocopy this page as needed and submit a separate
                                    page for EACH claim/event. A legible signed practitioner narrative that addresses all of the following details is an
                                    acceptable alternative </label>
                            </div> 
                            
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Date and clinical details of the incident, with preceding events:</label>
                            </div> 
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="formLabel">Date: </label>
                                </div> 
                                <div class="col-md-6">
                                    <div class="input-group">
                                            <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder=" Date of occurance"
                                            class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh97="ngbDatepicker"
                                            formControlName="1208" id="1208"
                                            [class.is-invalid]="(CancerCareNorthwestForms.controls['1208'].touched && CancerCareNorthwestForms.controls['1208'].invalid ) || (CancerCareNorthwestForms.controls['1208'].invalid && isSubmit)">
                            
                                        <div class="input-group-append">
                            
                                            <button class="btn btn-outline-secondary calendar" (click)="idh97.toggle()" type="button">
                            
                                                <i class="fas fa-calendar-alt"></i>
                            
                                            </button>
                            
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(CancerCareNorthwestForms.controls['1208'].touched && CancerCareNorthwestForms.controls['1208'].invalid) || (CancerCareNorthwestForms.controls['1208'].invalid && isSubmit)">
                                            Required and cannot be empty </div>
                                        </div>
                                    </div>
                                        
                                
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="formLabel">Details: </label>
                                </div> 
                                <div class="col-md-6">
                                    <textarea id="1217" name="idharea" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1217"
                                    cols="70"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['1217'].touched && CancerCareNorthwestForms.controls['1217'].invalid ) || (CancerCareNorthwestForms.controls['1217'].invalid && isSubmit)"></textarea>
                    
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['1217'].touched && CancerCareNorthwestForms.controls['1217'].invalid) || (CancerCareNorthwestForms.controls['1217'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                    
                            </div>
                            </div>   
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Your role and specific responsibility in the incident:</label>
                            </div> 
                            <div class="col-md-12">
                                <textarea id="1218" name="idharea" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1218"
                                    cols="70"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['1218'].touched && CancerCareNorthwestForms.controls['1218'].invalid ) || (CancerCareNorthwestForms.controls['1218'].invalid && isSubmit)"></textarea>
                    
                    
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['1218'].touched && CancerCareNorthwestForms.controls['1218'].invalid) || (CancerCareNorthwestForms.controls['1218'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                            </div>
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        
                            <div class="col-md-12">
                                <label class="formLabel">Subsequent events, including patient’s clinical outcome:</label>
                            </div> 
                            <div class="col-md-12">
                                <textarea id="1214" name="idharea" (blur)="handleNAchange('cmd_NA_1')" row="30" formControlName="1214"
                                    cols="70"
                                    [class.is-invalid]="(CancerCareNorthwestForms.controls['1214'].touched && CancerCareNorthwestForms.controls['1214'].invalid ) || (CancerCareNorthwestForms.controls['1214'].invalid && isSubmit)"></textarea>
                    
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['1214'].touched && CancerCareNorthwestForms.controls['1214'].invalid) || (CancerCareNorthwestForms.controls['1214'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                            </div>
                    </td>
                </tr>     
                
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="formLabel">Date suit or claim was filed:</label>
                            </div> 
                            <div class="col-md-6">
                                <div class="input-group">
                                
                                    <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Date of Lawsuit" id="1209"
                                        formControlName="1209" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh98="ngbDatepicker"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1209'].touched && CancerCareNorthwestForms.controls['1209'].invalid ) || (CancerCareNorthwestForms.controls['1209'].invalid && isSubmit)">
                    
                                    <div class="input-group-append">
                    
                                        <button class="btn btn-outline-secondary calendar" (click)="idh98.toggle()" type="button">
                    
                                            <i class="fas fa-calendar-alt"></i>
                    
                                        </button>
                    
                                    </div>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1209'].touched && CancerCareNorthwestForms.controls['1209'].invalid) || (CancerCareNorthwestForms.controls['1209'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>    
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="formLabel">Name and Address of Insurance Carrier that handled the claim:</label>
                            </div> 
                            <div class="col-md-6">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')"
                                        placeholder="Insurance Carrier at Time of Occurrence" id="1210" formControlName="1210"
                                        class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1210'].touched && CancerCareNorthwestForms.controls['1210'].invalid ) || (CancerCareNorthwestForms.controls['1210'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1210'].touched && CancerCareNorthwestForms.controls['1210'].invalid) || (CancerCareNorthwestForms.controls['1210'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>    
                    </td>
                </tr>   
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-3">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier Address 1"
                                        id="2012" formControlName="2012" class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['2012'].touched && CancerCareNorthwestForms.controls['2012'].invalid ) || (CancerCareNorthwestForms.controls['2012'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['2012'].touched && CancerCareNorthwestForms.controls['2012'].invalid) || (CancerCareNorthwestForms.controls['2012'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                            <div class="col-md-2">
                                <input type="text"  placeholder="Insurance Carrier Address 2"
                                        id="1844" formControlName="1844" class="form-control"
                                        >                                               
                            </div>
                            <div class="col-md-3">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier City" id="1845"
                                formControlName="1845" class="form-control"
                                [class.is-invalid]="(CancerCareNorthwestForms.controls['1845'].touched && CancerCareNorthwestForms.controls['1845'].invalid ) || (CancerCareNorthwestForms.controls['1845'].invalid && isSubmit)">
            
                            <div class="invalid-feedback"
                                *ngIf="(CancerCareNorthwestForms.controls['1845'].touched && CancerCareNorthwestForms.controls['1845'].invalid) || (CancerCareNorthwestForms.controls['1845'].invalid && isSubmit)">
                                Required and cannot be empty </div>
                            </div>
                            <div class="col-md-2">
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_NA_1')"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1847'].touched &&
                                        
                                        CancerCareNorthwestForms.controls['1847'].invalid) ||
                                        
                                        (CancerCareNorthwestForms.controls['1847'].invalid && isSubmit)" bindLabel="name" placeholder="Insurance Carrier State" bindValue="id" formControlName="1847">
                    
                                    </ng-select>
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1847'].touched && CancerCareNorthwestForms.controls['1847'].invalid) || (CancerCareNorthwestForms.controls['1847'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                            <div class="col-md-2">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Insurance Carrier Zip" id="1848"
                                        formControlName="1848" class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1848'].touched && CancerCareNorthwestForms.controls['1848'].invalid ) || (CancerCareNorthwestForms.controls['1848'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1848'].touched && CancerCareNorthwestForms.controls['1848'].invalid) || (CancerCareNorthwestForms.controls['1848'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>
                    </td>
                </tr> 
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Your status in the legal action (primary defendant, co-defendant, other):</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Other Defendants Involved " id="1213"
                                        formControlName="1213" class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1213'].touched && CancerCareNorthwestForms.controls['1213'].invalid ) || (CancerCareNorthwestForms.controls['1213'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1213'].touched && CancerCareNorthwestForms.controls['1213'].invalid) || (CancerCareNorthwestForms.controls['1213'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Current status of suit or other action:</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder=" Claim Status" id="1858"
                                        formControlName="1858" class="form-control"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1858'].touched && CancerCareNorthwestForms.controls['1858'].invalid ) || (CancerCareNorthwestForms.controls['1858'].invalid && isSubmit)">
                    
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1858'].touched && CancerCareNorthwestForms.controls['1858'].invalid) || (CancerCareNorthwestForms.controls['1858'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>    
                    </td>
                </tr> 
                <tr> 
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">Date of settlement, judgment, or dismissal:</label>
                            </div> 
                            <div class="col-md-12">
                                <div class="input-group">
                        
                                    <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Settlement Date " id="1226"
                                        formControlName="1226" class="form-control datepicker"
                                        [class.is-invalid]="(CancerCareNorthwestForms.controls['1226'].touched && CancerCareNorthwestForms.controls['1226'].invalid ) || (CancerCareNorthwestForms.controls['1226'].invalid && isSubmit)"
                                        data-id="priornameeffdate_date" ngbDatepicker #idh99="ngbDatepicker">
                    
                                    <div class="input-group-append">
                    
                                        <button class="btn btn-outline-secondary calendar" (click)="idh99.toggle()" type="button">
                    
                                            <i class="fas fa-calendar-alt"></i>
                    
                                        </button>
                    
                                    </div>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="(CancerCareNorthwestForms.controls['1226'].touched && CancerCareNorthwestForms.controls['1226'].invalid) || (CancerCareNorthwestForms.controls['1226'].invalid && isSubmit)">
                                        Required and cannot be empty </div>
                            </div>
                        </div>    
                    </td>
                </tr>  
                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="formLabel">If case was settled out-of-court, or with a judgment, settlement amount attributed to you? $</label>
                            </div> 
                            <div class="col-md-12">
                                <input type="text" (blur)="handleNAchange('cmd_NA_1')" placeholder="Total Amount of Settlement " id="1228"
                                                formControlName="1228" class="form-control"
                                                [class.is-invalid]="(CancerCareNorthwestForms.controls['1228'].touched && CancerCareNorthwestForms.controls['1228'].invalid ) || (CancerCareNorthwestForms.controls['1228'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(CancerCareNorthwestForms.controls['1228'].touched && CancerCareNorthwestForms.controls['1228'].invalid) || (CancerCareNorthwestForms.controls['1228'].invalid && isSubmit)">
                                                Required and cannot be empty </div>
                            
                            </div>
                        </div>    
                    </td>
                </tr>  
            </table>    
            <br>
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="8" border="2">
                        
                            <label class="sideheading"  >
                                <h6>23. Physician/Practitioner Health Program Disclosure</h6></label><label >Please complete below details if you answered yes to Question C.2 above </label>
                    </th>
                    <th colspan="4" border="2">                                        
                        <b>Does Not Apply:</b>
                        <input type="checkbox" value="1" (ngModelChange)="handleNAchange('cmd_NA_2')" formControlName="cmd_NA_2"
                            id="cmd_NA_2">
                    </th>
                </tr>
            </table>   <br> 
            <table border="2" width="100%" class="center" > 

                <tr>
                    <th colspan="12" border="2">
                        
                            <label class="sideheading"  >
                                <h6>24. ATTESTATION</h6></label>
                    </th>

                </tr>
                <tr>
                    <td colspan="12" >
                        
                            <label class="formLabel"   >I certify the information in this entire application is complete, accurate, and current. I acknowledge that any misstatements in
                                or omissions from this application constitute cause for denial of membership or cause for summary dismissal from the entity to
                                which this statement has been made. A copy, or electronic PDF with signature authentication, of this application has the same
                                force and effect as the original. I have reviewed this information as of the most recent date listed below </label>
                    </td>

                </tr>
            </table>   <br>    
            <table class="center" style="margin-left: auto; 
            margin-right: auto;">

                <tr>
                    <td colspan="12">
                        <div class="row">
                            <div class="col-md-5">   
                                <label class="formLabel">
                                    Print Name
                                    Here:</label>
                            </div>
                            <div class="col-md-6">   
                                <label class="formLabel">
                                    <input type="text" placeholder="Typed or printed name" [class.is-invalid]="(CancerCareNorthwestForms.controls['cmd_33553'].touched &&
                                            
                                    CancerCareNorthwestForms.controls['cmd_33553'].invalid) ||
                                    
                                    (CancerCareNorthwestForms.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                        formControlName="cmd_33553" class="form-control">
                
                                
                
                                <div class="invalid-feedback"
                                    *ngIf="(CancerCareNorthwestForms.controls['cmd_33553'].touched && CancerCareNorthwestForms.controls['cmd_33553'].invalid) || (CancerCareNorthwestForms.controls['cmd_33553'].invalid && isSubmit)">
                
                                    Name is required and cannot be empty
                
                                </div></label>
                            </div>
                        </div>            
                    </td>

                </tr>
            </table>      
              -->
            <cancer-care-northwest-section1
                [CancerCareNorthwestForms1]="CancerCareNorthwestForms"
                [isSubmit]="isSubmit"
                (isHandleNAChanges)="handleNAchange($event)"
                (isclearInputIfInvalid)="clearInputIfInvalid($event.mandatory, $event.optional)"
                (isclearInputIfInvalidMandi)="clearInputIfInvalidMandi($event)"
                [showOne]="showOne" [finalImgPath]="finalImgPath" [esignOne]="esignOne"
                [showTwo]="showTwo"  [esignTwo]="esignTwo"
                [showThree]="showThree"  [esignThree]="esignThree">
                
            </cancer-care-northwest-section1>
           

            
        
        
        
            
            
        
    </form>


    <div class="modal-footer mt-4">
        <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
        (click)="saveForm('approve')">Approve</button>
        <button *ngIf="formData.submitForm" type="submit" class="btn btn customOrange float-end"
        (click)="saveForm('submit')">Submit</button>
        <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
        (click)="saveForm('save')">Save</button>
        <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
    </div>
</div>    

    