import { Component,OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../DateFormatter/customDate';
import { ChangeDetectorRef } from '@angular/core';
import { Wpa1Component } from './wpa1/wpa1.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'ctg-wpa',
    templateUrl: './wpa.component.html',
    styleUrls: ['./wpa.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule, Wpa1Component]
})
export class WpaComponent implements OnInit {

  public WpaForms: UntypedFormGroup;
  isSubmit: boolean = false;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private phonePattern = '^[0-9]*$';

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,
    private cdr: ChangeDetectorRef
  ) { }

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };


  ngOnInit(): void {
    this.buildForm();
    this.getCredentialsData();
    this.getStatesData();
    this.getDegreeData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.getGenderData();
    this.getSpecialtyData();
    this.getSpecialtySecondaryData();
    this.getStaffStatusData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getSuffixData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getBoardCertificationData();
    this.getMediCertifications();
    this.getTerSpecialtyData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    

    console.log(this.formData);
    console.log(this.fieldsData);
    console.log(this.formId);
  }

  bcRadioButtonNo: boolean =  false;
  bcRadioButtonYes: boolean =  false;
  flagForHandle: boolean =  false;


  finalImg: any;
  public suffix: any = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  boardCertification: Array<object> = [];
  mediCertifications: Array<object> = [];
  specialtyTer: Array<object> = [];







  credentials: Array<object> = [];
  states: Array<object> = [];
  degree: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  languages: Array<object> = [];
  gender: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  staffStatus: Array<object> = [];
  public preClaim: any = [];
  public aggregates: any = [];

  finalImgPath: any = [];
  finalImgPathTwo: any = [];
  finalImgPathThree: any = [];
  // finalImgPathFour: any = [];


  finalImgTwo: any = '';
  finalImgThree: any = '';
  // finalImgFour: any = '';

  esignOne: any;
  esignTwo: any;
  esignThree: any;
  // esignFour: any;

  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  // showFour: any = false;

  firstName: any;
  lastName: any;
  finalName: any;

  public ugeapp: boolean = false;
  public hmoapp: boolean = false;
  public bcapp: boolean = false;

  public entryfor14: boolean = false;


//board_certification_radio_button_function
// BCRadioButtonNo(event) {
//     if (event.target.value == 0) {
//       this.bcRadioButtonNo = true;

//       if(!this.entryfor14 ){
//       this.WpaForms.controls[554].setErrors(null);
//         this.WpaForms.controls["cmd_175"].setErrors(null);
//         this.WpaForms.controls[556].setErrors(null);
//         this.WpaForms.controls[557].setErrors(null);
//         this.WpaForms.controls[558].setErrors(null);
//         this.WpaForms.controls[560].setErrors(null);
//       }
//     } else {
//       this.bcRadioButtonNo = false;
//     }
//   }
BCRadioButtonNo(event) {

  this.bcRadioButtonNo = true;
  this.flagForHandle=true;
  this.cdr.detectChanges(); 
  if(this.WpaForms.controls["cmd_173"].value ==true){
   
    this.WpaForms.controls["cmd_01741"].setErrors(null);
    return 

  }
  
  if (event.target.value == 0) {
    
    this.WpaForms.controls[554].setErrors(null);
      this.WpaForms.controls["cmd_175"].setErrors(null);
      this.WpaForms.controls[556].setErrors(null);
      this.WpaForms.controls[557].setErrors(null);
      this.WpaForms.controls[558].setErrors(null);
      this.WpaForms.controls[560].setErrors(null);
      if (this.WpaForms.controls["cmd_01741"].value == "") {
        this.WpaForms.controls["cmd_01741"].setErrors({ errors: true });
      }
      // this.WpaForms.controls["cmd_01741"].setErrors({ errors: true });
  } else {
    this.bcRadioButtonNo = false;
  }
}



//does_not_apply_functions
// public underGraduate(event) {
//   // console.log(event.target.value, event.target.checked);
//   this.ugeapp = event;
//   if (this.ugeapp) {
//     this.WpaForms.controls[503].setErrors(null);
//     this.WpaForms.controls[512].setErrors(null);
//     this.WpaForms.controls[511].setErrors(null);
//     this.WpaForms.controls[504].setErrors(null);
//     this.WpaForms.controls[505].setErrors(null);
//     this.WpaForms.controls[506].setErrors(null);
//     this.WpaForms.controls[508].setErrors(null);
//     this.WpaForms.controls[509].setErrors(null);
//   }
// }

// BCRadioButtonYes(event) {
//   this.bcRadioButtonNo = false;

  
  
//   if(!this.entryfor14 ){
//       // alert(event.target.value);
//   this.bcRadioButtonYes = event.target.value;

//   if (this.bcRadioButtonYes && this.WpaForms.controls[554].value == null) {
//     this.WpaForms.controls[554].setErrors({ errors: true });
//   }
//   if (this.bcRadioButtonYes && this.WpaForms.controls["cmd_175"].value == null) {
//     this.WpaForms.controls["cmd_175"].setErrors({ errors: true });
//   }
//   if (this.bcRadioButtonYes && this.WpaForms.controls[556].value == null) {
//     this.WpaForms.controls[556].setErrors({ errors: true });
//   }
//   if (this.bcRadioButtonYes && this.WpaForms.controls[557].value == null) {
//     this.WpaForms.controls[557].setErrors({ errors: true });
//   }
//   if (this.bcRadioButtonYes && this.WpaForms.controls[558].value == null) {
//     this.WpaForms.controls[558].setErrors({ errors: true });
//   }
//   if (this.bcRadioButtonYes && this.WpaForms.controls[560].value == null) {
//     this.WpaForms.controls[560].setErrors({ errors: true });
//   }

// }


// }
BCRadioButtonYes(event) {
  // alert(event.target.value);
  this.bcRadioButtonNo = false;
  this.flagForHandle=false;
  if(this.WpaForms.controls["cmd_173"].value ==true){
    this.WpaForms.controls[554].setErrors(null);
    this.WpaForms.controls["cmd_175"].setErrors(null);
    this.WpaForms.controls[556].setErrors(null);
    this.WpaForms.controls[557].setErrors(null);
    this.WpaForms.controls[558].setErrors(null);
    this.WpaForms.controls[560].setErrors(null);
    return 

  }

  this.bcRadioButtonYes = event.target.value;
  this.WpaForms.controls["cmd_01741"].setErrors(null);
  if (this.bcRadioButtonYes && this.WpaForms.controls[554].value == null) {
    this.WpaForms.controls[554].setErrors({ errors: true });
  }
  if (this.bcRadioButtonYes && this.WpaForms.controls["cmd_175"].value == null) {
    this.WpaForms.controls["cmd_175"].setErrors({ errors: true });
  }
  if (this.bcRadioButtonYes && this.WpaForms.controls[556].value == null) {
    this.WpaForms.controls[556].setErrors({ errors: true });
  }
  if (this.bcRadioButtonYes && this.WpaForms.controls[557].value == null) {
    this.WpaForms.controls[557].setErrors({ errors: true });
  }
  if (this.bcRadioButtonYes && this.WpaForms.controls[558].value == null) {
    this.WpaForms.controls[558].setErrors({ errors: true });
  }
  if (this.bcRadioButtonYes && this.WpaForms.controls[560].value == null) {
    this.WpaForms.controls[560].setErrors({ errors: true });
  }
  



}


// public hmoia(event) {
//   // console.log(event.target.value, event.target.checked);
//   this.hmoapp = event;
//   if (this.hmoapp) {
//     this.WpaForms.controls[418].setErrors(null);
//     this.WpaForms.controls[425].setErrors(null);
//     this.WpaForms.controls[419].setErrors(null);
//     this.WpaForms.controls[420].setErrors(null);
//     this.WpaForms.controls[421].setErrors(null);
//     this.WpaForms.controls[423].setErrors(null);
//     this.WpaForms.controls[424].setErrors(null);
//     this.WpaForms.controls[1305].setErrors(null);
//     this.WpaForms.controls[2136].setErrors(null);
//     this.WpaForms.controls[894].setErrors(null);
//     this.WpaForms.controls[426].setErrors(null);
//   }
// }

public boardCertificate(event) {
  // console.log(event.target.value, event.target.checked);
  this.bcapp = event;
  if (this.bcapp) {
    this.WpaForms.controls[554].setErrors(null);
    this.WpaForms.controls["cmd_175"].setErrors(null);
    this.WpaForms.controls[556].setErrors(null);
    this.WpaForms.controls[557].setErrors(null);
    this.WpaForms.controls[558].setErrors(null);
    this.WpaForms.controls[560].setErrors(null);
  }
}


  //drop_down_functions

  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_33553: this.finalName,
      cmd_43554:this.finalName,
      cmd_03550:this.finalName,
      cmd_13551:this.finalName,
      cmd_356:this.finalName,

      // cmd_23552:this.finalName
    };
    this.WpaForms.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_33553: this.finalName,
      cmd_43554:this.finalName,
      cmd_03550:this.finalName,
      cmd_13551:this.finalName,
      cmd_356:this.finalName,
      // cmd_23552:this.finalName
    };
    this.WpaForms.patchValue(temp);
  }


  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getMediCertifications() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getMediCertifications(params).subscribe(
      data => {
        if (data['success']) {
          this.mediCertifications = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }


  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }


  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }





  public afterSaveDataDisplay() {
    let params = {
      type: 36,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_353'||array[i].field_name=='cmd_355'||
          array[i].field_name=='cmd_370') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.WpaForms.patchValue(fieldsCtrls);
    console.log(this.WpaForms)
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.setEsign(data.base64, fieldId);
        } else {
        }
      },
      error => {
      }
    );
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_353: this.finalImgPath
      };
      this.WpaForms.patchValue(temp);
      this.finalImg = data.base64Img;
      console.log(this.finalImg);
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      console.log(this.esignOne);
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_355: this.finalImgPathTwo
      };
      this.WpaForms.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  // public esignOpenFour() {
  //   const modalRef = this.modalService.open(EsignComponent, this.modalOption);
  //   modalRef.result.then(result => { });
  //   modalRef.componentInstance.base64Img.subscribe(data => {
  //     modalRef.close();
  //     this.finalImgPathFour = data.finalFilePath;
  //     let temp = {
  //       cmd_371: this.finalImgPathFour
  //     };
  //     this.WpaForms.patchValue(temp);
  //     this.finalImgPathFour = data.finalFilePath;
  //     this.finalImgFour = data.base64Img;
  //     this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
  //       'data:image/jpg;base64,' + this.finalImgFour
  //     );
  //     this.showFour = true;
  //   });
  // }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_370: this.finalImgPathThree
      };
      this.WpaForms.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }



  public setEsign(filePath, fieldId) {
    // console.log(filePath);
    if (fieldId == 'cmd_353') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_355') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_370') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
    // if (fieldId == 'cmd_371') {
    //   this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
    //     'data:image/jpg;base64,' + filePath
    //   );
    //   this.showFour = true;
    // }
  }



  close() {
    this.onClose.emit('close modal');
  }

  saveForm(type) {
    this.isSubmit = true;
    console.log(this.WpaForms);
    this.handleNAchange('cmd_160');
    this.handleNAchange('cmd_187');
    this.handleNAchange('cmd_173');
    if (type 
      == 'submit') {
     if (this.WpaForms.invalid) {
      // if (this.WpaForms.invalid||

      //   (this.bcRadioButtonNo &&
      //     this.WpaForms.controls['cmd_0174'].value == '')||
      //     (this.bcRadioButtonYes && (this.WpaForms.controls['554'].value||
      //     this.WpaForms.controls['556'].value||
      //     this.WpaForms.controls['cmd_175'].value||
      //     this.WpaForms.controls['557'].value||
      //     this.WpaForms.controls['558'].value||
      //     this.WpaForms.controls['560'].value))||
      //   (!this.ugeapp && (!this.WpaForms.controls['503'].value||
      //   !this.WpaForms.controls['512'].value||
      //   !this.WpaForms.controls['511'].value||
      //   !this.WpaForms.controls['504'].value||
      //   !this.WpaForms.controls['505'].value||
      //   !this.WpaForms.controls['506'].value||
      //   !this.WpaForms.controls['508'].value||
      //   !this.WpaForms.controls['509'].value))||

      //   (!this.hmoapp && (!this.WpaForms.controls['418'].value||
      //   !this.WpaForms.controls['425'].value||
      //   !this.WpaForms.controls['419'].value||
      //   !this.WpaForms.controls['420'].value||
      //   !this.WpaForms.controls['421'].value||
      //   !this.WpaForms.controls['423'].value||
      //   !this.WpaForms.controls['424'].value||
      //   !this.WpaForms.controls['1305'].value||
      //   !this.WpaForms.controls['2136'].value
      //   ))||
      //   (!this.bcapp && (!this.WpaForms.controls['554'].value||
      //   !this.WpaForms.controls['cmd_175'].value||
      //   !this.WpaForms.controls['556'].value||
      //   !this.WpaForms.controls['557'].value||
      //   !this.WpaForms.controls['558'].value||
      //   !this.WpaForms.controls['560'].value))){
      //     if (!this.ugeapp && !this.WpaForms.controls['512'].value) {
      //       this.WpaForms.controls['512'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['511'].value) {
      //       this.WpaForms.controls['511'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['504'].value) {
      //       this.WpaForms.controls['504'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['505'].value) {
      //       this.WpaForms.controls['505'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['506'].value) {
      //       this.WpaForms.controls['506'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['508'].value) {
      //       this.WpaForms.controls['508'].setErrors({ errors: true });
      //     }
      //     if (!this.ugeapp && !this.WpaForms.controls['509'].value) {
      //       this.WpaForms.controls['509'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['418'].value) {
      //       this.WpaForms.controls['418'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['425'].value) {
      //       this.WpaForms.controls['425'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['419'].value) {
      //       this.WpaForms.controls['419'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['420'].value) {
      //       this.WpaForms.controls['420'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['421'].value) {
      //       this.WpaForms.controls['421'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['423'].value) {
      //       this.WpaForms.controls['423'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['424'].value) {
      //       this.WpaForms.controls['424'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['1305'].value) {
      //       this.WpaForms.controls['1305'].setErrors({ errors: true });
      //     }
      //     if (!this.hmoapp && !this.WpaForms.controls['2136'].value) {
      //       this.WpaForms.controls['2136'].setErrors({ errors: true });
      //     }
        this.notiService.showError(
          'Please Fill all the mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.WpaForms.controls) {
      if (this.WpaForms.controls.hasOwnProperty(key)) {
        const element = this.WpaForms.controls[key];
        if(element.status=="INVALID"){
        console.log(key + " " + element.status)
        }
      }
    }
      } else {
        let data = {
          formType: type,
          formValues: this.WpaForms.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.WpaForms);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.WpaForms.value,
        formId: 36
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }



  buildForm() {
    this.WpaForms = this.fb.group({
      //PRACTITIONER INFORMATION
      8: ['', [Validators.required]],
      809: ['', [Validators.required]],

      29:[null],
      30: [null],
      9: ['', [Validators.required]],
      822: [null, [Validators.required]],
      31: ['', [Validators.required,this.dateFormatValidator]],
      32: ['', [Validators.required]],
      708:['',[Validators.pattern(this.phonePattern)]],
      1017:[''],
      278:[''],
      709: ['', [Validators.required,Validators.pattern(this.phonePattern)]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      18: ['', [Validators.required]],
      20: [null, [Validators.required]],
      21: ['' ,[Validators.required]],
      810: ['', [Validators.required]],
      291: ['', [Validators.required]],
      292: ['', [Validators.required]],
      289: [null, [Validators.required]],
      284: [null, [Validators.required]],
      962: [null, [Validators.required]],
      283: ['',[Validators.required,this.dateFormatValidator]],



      277: [null, [Validators.required]],
      1535: [null],
      1634: ['', [Validators.required]],
      812:[''],
      325:[''],
      "cmd_0812":[''],
      "3826":[''],
      // "cmd_30354":[''],


      //PROFESSIONAL LICENSURE-REGISTRATIONS
      293: [null, [Validators.required]],
      294: ['', [Validators.required,this.dateFormatValidator]],
      295: ['', [Validators.required,this.dateFormatValidator]],
      1745: [''],
      1746: [''],
      1748: [null],
      1749:[null],
      309: ['', [Validators.required]],
      311: ['', [Validators.required,this.dateFormatValidator]],
      514:[''],
      515:[null,[this.dateFormatValidator] ],

      //ALL OTHER PROFESSIONAL LICENSES

      907:[null],
      300:[''],
      307:[null,[this.dateFormatValidator]],
      308:[null,[this.dateFormatValidator]],
      1010:[null],
      999:[''],
      1003:[null,[this.dateFormatValidator]],
      1004:[null,[this.dateFormatValidator]],
      "add_1_1010":[null],
      "add_1_999":[''],
      "add_1_1003":[null,[this.dateFormatValidator]],
      "add_1_1004":[null,[this.dateFormatValidator]],
      "cmd_0155":[''],
      "cmd_0156":[''],






      //UNDERGRADUATE EDUCATION
      503:[''],
      512:[null],
      511:[null,[this.dateFormatValidator]],
      504:[''],
      505:[''],
      506:[''],
      508:[null],
      509:[''],

      1288:[''],
      1298:[null],
      1297:[null,[this.dateFormatValidator]],
      1289:[''],
      1290:[''],
      1291:[''],
      1293:[null],
      1294:[''],

      // MASTER DEGREE PROGRAM OR POST GRADUATE EDUCATION
      479:[''],
      483:[''],
      484:[''],
      485:[''],
      487:[null],
      488:[''],
      481:[null,[this.dateFormatValidator]],
      482:[null,[this.dateFormatValidator]],

      489:[''],
      4074:[''],
      480:[null],

      //MEDICAL/PROFESSIONAL EDUCATION
      490:['', [Validators.required]],
      497:['', [Validators.required,this.dateFormatValidator]],
      499:['', [Validators.required,this.dateFormatValidator]],
      500:[null, [Validators.required]],
      491:['', [Validators.required]],
      492:['', [Validators.required]],
      493:['', [Validators.required]],
      495:[null, [Validators.required]],
      496:['', [Validators.required]],

      "add_1_1288":[''],
      "add_1_1295":[null,[this.dateFormatValidator]],
      "add_1_1297":[null,[this.dateFormatValidator]],
      "add_1_1298":[null],
      "add_1_1289":[''],
      "add_1_1290":[''],
      "add_1_1291":[''],
      "add_1_1293":[null],
      "add_1_1294":[''],
      "cmd_01007":[''],

      //INTERNSHIP/PGYI
      517:[''],
      1318:['',[Validators.pattern(this.phonePattern)]],
      1319:[''],
      528:[''],
      518:[''],
      519:[''],
      520:[''],
      522:[null],
      523:[''],
      2539:[''],
      524:[null,[this.dateFormatValidator]],
      525:[null,[this.dateFormatValidator]],
      527:[null],


      //RESIDENCIES
      529:[''],
      1316:['',[Validators.pattern(this.phonePattern)]],
      1317:[''],
      540:[''],
      531:[''],
      1498:[''],
      533:[''],
      535:[null],
      536:[''],
      2540:[''],
      530:[null],
      537:[null,[this.dateFormatValidator]],
      538:[null,[this.dateFormatValidator]],
      1170:[''],
      1320:['',[Validators.pattern(this.phonePattern)]],
      1321:[''],
      1181:[''],
      1172:[''],
      1173:[''],
      1174:[''],
      1176:[null],
      1177:[''],
      2541:[''],
      1171:[null],
      1178:[null,[this.dateFormatValidator]],
      1179:[null,[this.dateFormatValidator]],

      //FELLOWSHIPS
      542:[''],
      549:[''],
      1322:['',[Validators.pattern(this.phonePattern)]],
      1323:[''],
      553:[''],
      544:[''],
      545:[''],
      546:[''],
      548:[null],
      2542:[''],
      550:[null,[this.dateFormatValidator]],
      551:[null,[this.dateFormatValidator]],
      966:[''],
      1324:['',[Validators.pattern(this.phonePattern)]],
      1325:[''],
      983:[''],
      968:[''],
      969:[''],
      970:[''],
      972:[null],
      1301:[''],
      2543:[''],
      974:[null,[this.dateFormatValidator]],
      975:[null,[this.dateFormatValidator]],

      //PRECEPTORSHIP
      2857:[''],
      2859:[''],
      2860:[''],
      2861:[''],
      2863:[null],
      2864:[''],
      2871:['',[Validators.pattern(this.phonePattern)]],
      2872:[''],
      4146:['',[Validators.pattern(this.emailPattern)]],
      2866:[null,[this.dateFormatValidator]],
      2868:[null,[this.dateFormatValidator]],
      2873:[''],
      2869:[''],
      //Department Chairman field is fending

      //FACULTY/TEACHING APPOINTMENTS
      1146:[''],

      1148:[''],
      1149:[''],
      1150:[''],
      1152:[null],
      1153:[''],
      4142:['',[Validators.pattern(this.phonePattern)]],
      1155:[null,[this.dateFormatValidator]],
      1156:[null,[this.dateFormatValidator]],
      1154:[''],
      1382:[''],

      //BOARD CERTIFICATION
      // 1169:[''],
      554:[null],
      561:[null],
      946:[null],
      556:[null],
      557:[null,[this.dateFormatValidator]],
      558:[null,[this.dateFormatValidator]],
      560:[null,[this.dateFormatValidator]],
      563:[null],
      564:[null,[this.dateFormatValidator]],
      "cmd_01741":[''],
      

      // need to check field in this position
      565:[null,[this.dateFormatValidator]],
      567:[null,[this.dateFormatValidator]],
      948:[null],
      953:[null,[this.dateFormatValidator]],
      950:[null,[this.dateFormatValidator]],
      954:[null,[this.dateFormatValidator]],

      // OTHER CERTIFICATIONS ACLS, BLS, ATLS, PALS, NALS
      674:[null],
      3270:[''],
      678:[null,[this.dateFormatValidator]],
      "add_1_674":[null],
      "add_1_3270":[''],
      "add_1_678":[null,[this.dateFormatValidator]],

     // HOSPITAL, MILITARY, & OTHER INSTITUTIONAL AFFILIATIONS
     418:[''],
     425:[''],
     419:[''],
     420:[''],
     421:[''],
     423:[null],
     424:[''],
     1305:['', [Validators.pattern(this.phonePattern)]],
     2136:[''],
     894:[null],
     426:[null,[this.dateFormatValidator]],

    427:[''],
    428:[''],
    429:[''],
    430:[''],
    432:[null],
    433:[''],
    1307:['',[Validators.pattern(this.phonePattern)]],
    2137:[''],
    895:[null],
    435:[null,[this.dateFormatValidator]],

     436:[''],
     434:[''],
     437:[''],
     438:[''],
     439:[''],
     441:[null],
     442:[''],
     443:[''],
     1309:['',[Validators.pattern(this.phonePattern)]],
     2138:[''],
     896:[null],
     444:[null,[this.dateFormatValidator]],

     //PREVIOUS HOSPITAL AFFILIATIONS
     1183:[''],
     1190:[''],
     1184:[''],
     1185:[''],
     1186:[''],
     1188:[null],
     1189:[''],
     1192:[null],
     1191:[null,[this.dateFormatValidator]],
     2381:[null,[this.dateFormatValidator]],
     1303:[''],

     "add_1_1183":[''],
     "add_1_1190":[''],
     "add_1_1184":[''],
     "add_1_1185":[''],
     "add_1_1186":[''],
     "add_1_1188":[null],
     "add_1_1189":[''],
     "add_1_1192":[null],
     "add_1_1191":[null,[this.dateFormatValidator]],
     "add_1_2381":[null,[this.dateFormatValidator]],
     "add_1_1303":[''],

     "add_2_1183":[''],
     "add_2_1190":[''],
     "add_2_1184":[''],
     "add_2_1185":[''],
     "add_2_1186":[''],
     "add_2_1188":[null],
     "add_2_1189":[''],
     "add_2_1192":[null],
     "add_2_1191":[null,[this.dateFormatValidator]],
     "add_2_2381":[null,[this.dateFormatValidator]],
     "add_2_1303":[''],

     //CURRENT MILITARY AFFILIATIONS
     1139:[''],
     1141:[''],
     1145:[null,[this.dateFormatValidator]],

     2112:[''],
     2113:[''],
     2114:[''],
     2116:[null],
     2117:[''],
     4152:[null],
     1144:[null,[this.dateFormatValidator]],

     //WORK HISTORY
     445:['', [Validators.required]],
     452:['', [Validators.required]],
     453:['', [Validators.required,Validators.pattern(this.phonePattern)]],
     1329:['', [Validators.required]],
     889:['', [Validators.required,Validators.pattern(this.emailPattern)]],
     888:['', [Validators.required]],
     446:['', [Validators.required]],
     447:['', [Validators.required]],
     448:['', [Validators.required]],
     450:[null, [Validators.required]],
     451:['', [Validators.required]],
     454:['', [Validators.required,this.dateFormatValidator]],
     455:[null, [Validators.required,this.dateFormatValidator]],

     456:['', [Validators.required]],
     463:['', [Validators.required]],
     464:['', [Validators.required,Validators.pattern(this.phonePattern)]],
     1338:['', [Validators.required]],
     891:['', [Validators.required,Validators.pattern(this.emailPattern)]],
     890:['', [Validators.required]],
     457:['', [Validators.required]],
     458:['', [Validators.required]],
     459:['', [Validators.required]],
     461:[null, [Validators.required]],
     462:['', [Validators.required]],
     465:['', [Validators.required,this.dateFormatValidator]],
     466:['', [Validators.required,this.dateFormatValidator]],

     467:[''],
     474:[''],
     475:['',[Validators.pattern(this.phonePattern)]],
     1342:[''],
     893:['',[Validators.pattern(this.emailPattern)]],
     892:[''],
     468:[''],
     469:[''],
     470:[''],
     472:[null],
     473:[''],
     476:[null,[this.dateFormatValidator]],
     477:[null,[this.dateFormatValidator]],

     //GAPS IN HISTORY
     1138:[''],
     3395:[null,[this.dateFormatValidator]],
     3396:[null,[this.dateFormatValidator]],
     1831:[''],
     3397:[null,[this.dateFormatValidator]],
     3398:[null,[this.dateFormatValidator]],
     1832:[''],
     3399:[null,[this.dateFormatValidator]],
     3400:[null,[this.dateFormatValidator]],

     // PEER REFERENCES
     359:['', [Validators.required]],
     2722:['', [Validators.required]],
     368:[null, [Validators.required]],
     380:[null, [Validators.required]],
     393:[null, [Validators.required]],


     371:['', [Validators.required, Validators.pattern(this.emailPattern)]],
     360:['', [Validators.required]],
     361:['', [Validators.required]],
     362:['', [Validators.required]],
     364:[null, [Validators.required]],
     365:['', [Validators.required]],
     369:['', [Validators.required,Validators.pattern(this.phonePattern)]],
     370:[''],

     372:['', [Validators.required]],
     2723:[null, [Validators.required]],
     383:['', [Validators.required, Validators.pattern(this.emailPattern)]],
     373:['', [Validators.required]],
     374:['', [Validators.required]],
     375:['', [Validators.required]],
     377:[null, [Validators.required]],
     378:['', [Validators.required]],
     381:['', [Validators.required,Validators.pattern(this.phonePattern)]],
     382:[''],

     384:['', [Validators.required]],
     2725:[null, [Validators.required]],
     396:['', [Validators.required, Validators.pattern(this.emailPattern)]],
     385:['', [Validators.required]],
     387:['', [Validators.required]],

     386:['', [Validators.required]],
     389:[null, [Validators.required]],
     390:['', [Validators.required]],
     394:['', [Validators.required,Validators.pattern(this.phonePattern)]],
     395:[''],

     //PROFESSIONAL AFFILIATIONS
     1695:[''],
     1696:[null,[this.dateFormatValidator]],
     1701:[''],
     1702:[null,[this.dateFormatValidator]],

     // PROFESSIONAL LIABILITY
     345:[''],
     346:[''],
     901:[''],
     902:[''],
     903:[''],
     905:[null],
     906:[''],
     1266:['',[Validators.pattern(this.phonePattern)]],
     1273:[''],
     350:[null],
     351:[null],
     348:[null,[this.dateFormatValidator]],
     349:[null,[this.dateFormatValidator]],

//PREVIOUS PROFESSIONAL LIABILITY
1242:['', [Validators.required]],
1256:['', [Validators.required]],
1244:['', [Validators.required]],
1245:['', [Validators.required]],

1246:['', [Validators.required]],
1248:[null, [Validators.required]],
1249:['', [Validators.required]],
1254:['', [Validators.required,Validators.pattern(this.phonePattern)]],
1255:[''],
1264:[null, [Validators.required]],
1265:[null, [Validators.required]],
1252:['', [Validators.required,this.dateFormatValidator]],
1253:['', [Validators.required,this.dateFormatValidator]],

"add_1_1242":[''],
"add_1_1256":[''],
"add_1_1244":[''],
"add_1_1245":[''],

"add_1_1246":[''],
"add_1_1248":[null],
"add_1_1249":[''],
"add_1_1254":['',[Validators.pattern(this.phonePattern)]],
"add_1_1255":[''],
"add_1_1264":[null],
"add_1_1265":[null],
"add_1_1252":[null,[this.dateFormatValidator]],
"add_1_1253":[null,[this.dateFormatValidator]],
"add_2_1242":[''],
"add_2_1256":[''],
"add_2_1244":[''],
"add_2_1245":[''],


"add_2_1246":[''],
"add_2_1248":[null],
"add_2_1249":[''],
"add_2_1254":['',[Validators.pattern(this.phonePattern)]],
"add_2_1255":[''],
"add_2_1264":[null],
"add_2_1265":[null],
"add_2_1252":[null,[this.dateFormatValidator]],
"add_2_1253":[null,[this.dateFormatValidator]],
"add_3_1242":[''],
"add_3_1256":[''],
"add_3_1244":[''],
"add_3_1245":[''],

"add_3_1246":[''],
"add_3_1248":[null],

"add_3_1249":[''],
"add_3_1254":['',[Validators.pattern(this.phonePattern)]],
"add_3_1255":[''],
"add_3_1264":[null],
"add_3_1265":[null],
"add_3_1252":[null,[this.dateFormatValidator]],
"add_3_1253":[null,[this.dateFormatValidator]],

"add_4_1242":[''],
"add_4_1256":[''],
"add_4_1244":[''],
"add_4_1245":[''],

"add_4_1246":[''],
"add_4_1248":[null],
"add_4_1249":[''],
"add_4_1254":['',[Validators.pattern(this.phonePattern)]],

"add_4_1255":[''],
"add_4_1264":[null],
"add_4_1265":[null],
"add_4_1252":[null,[this.dateFormatValidator]],
"add_4_1253":[null,[this.dateFormatValidator]],

"add_5_1242":[''],
"add_5_1256":[''],
"add_5_1244":[''],
"add_5_1245":[''],

"add_5_1246":[''],
"add_5_1248":[null],
"add_5_1249":[''],
"add_5_1254":['',[Validators.pattern(this.phonePattern)]],
"add_5_1255":[''],
"add_5_1264":[null],

"add_5_1265":[null],
"add_5_1252":[null,[this.dateFormatValidator]],
"add_5_1253":[null,[this.dateFormatValidator]],

"add_6_1242":[''],
"add_6_1256":[''],
"add_6_1244":[''],
"add_6_1245":[''],

"add_6_1246":[''],
"add_6_1248":[null],
"add_6_1249":[''],
"add_6_1254":['',[Validators.pattern(this.phonePattern)]],
"add_6_1255":[''],
"add_6_1264":[null],
"add_6_1265":[null],
"add_6_1252":[null,[this.dateFormatValidator]],
"add_6_1253":[null,[this.dateFormatValidator]],

//PROFESSIONAL LIABILITY ACTION DETAIL
2012:[''],
1845:[''],
1847:[null],
1848:[''],
1213:[''],
1858:[''],
1226:[null,[this.dateFormatValidator]],
1228:[''],

//saved fields
"cmd_01":[''],
"cmd_02":[''],
"cmd_03":[''],
"cmd_04":[''],
"cmd_05":[''],
"cmd_06":[''],
"cmd_07":[''],
"cmd_08":[''],
"cmd_09":[''],
"cmd_10":[''],
"cmd_11":[''],
// "cmd_12":[''],
"cmd_13":[''],
// "cmd_14":[''],
"cmd_15":[''],
"cmd_16":[''],
"cmd_17":['',[Validators.pattern(this.phonePattern)]],
"cmd_18":[''],
"cmd_19":[''],
"cmd_019":[''],
"cmd_20":[''],
// "cmd_020":[''],
"cmd_21":[null],
"cmd_22":[''],
"cmd_23":[''],
"cmd_24":[''],
"cmd_25":[''],

"cmd_26":[''],
"cmd_27":[''],
"cmd_28":['',[Validators.pattern(this.phonePattern)]],
"cmd_29":['',[Validators.pattern(this.emailPattern)]],
"cmd_30":[''],
"cmd_31":[''],
"cmd_32":['',[Validators.pattern(this.phonePattern)]],
"cmd_33":['',[Validators.pattern(this.emailPattern)]],
"cmd_34":[''],
"cmd_35":[''],
"cmd_36":[''],
"cmd_37":[''],
// "cmd_38":[''],
"cmd_39":[''],
// "cmd_40":[''],
"cmd_41":[''],
// "cmd_42":[''],
"cmd_43":[''],
"cmd_44":[''],
// "cmd_45":[''],
"cmd_46":[''],
"cmd_47":[''],
// "cmd_48":[''],
"cmd_49":[''],
"cmd_50":[''],

"cmd_51":[''],
"cmd_52":[''],
"cmd_53":[''],
"cmd_54":[''],
"cmd_55":[''],
"cmd_56":[''],
// "cmd_57":[''],
"cmd_58":[''],
"cmd_59":[''],
"cmd_60":[''],
"cmd_61":[''],
"cmd_061":[''],

"cmd_62":[''],
"cmd_63":[''],
"cmd_063":[''],

"cmd_64":[''],
"cmd_65":[''],
"cmd_065":[null],

"cmd_66":[null],
"cmd_67":[''],
"cmd_68":['',[Validators.pattern(this.phonePattern)]],
"cmd_69":[''],
"cmd_069":[null],

"cmd_70":[null],
"cmd_71":[''],
"cmd_72":['',[Validators.pattern(this.phonePattern)]],
"cmd_73":[''],
"cmd_073":[null],

"cmd_74":[null],
"cmd_75":[''],

"cmd_76":['',[Validators.pattern(this.phonePattern)]],
"cmd_77":[null,[this.dateFormatValidator] ],
"cmd_78":[''],
"cmd_79":[''],
"cmd_80":[''],
"cmd_81":[''],
"cmd_82":[''],
"cmd_83":[''],
"cmd_84":[''],
"cmd_85":[''],
"cmd_86":[''],
"cmd_87":[''],
"cmd_88":[''],
// "cmd_89":[''],
"cmd_90":[''],
// "cmd_91":[''],
"cmd_92":[''],
"cmd_93":[''],
"cmd_94":['',[Validators.pattern(this.phonePattern)]],
"cmd_95":[''],
"cmd_96":[''],
"cmd_096":[''],
"cmd_97":[''],
"cmd_98":[null],
"cmd_99":[''],
"cmd_100":[''],

"cmd_101":[''],
"cmd_0101":[''],
"cmd_00101":[''],
"cmd_000101":[null],
"cmd_0000101":[''],



"cmd_102":[''],

"cmd_103":[''],
"cmd_104":[''],
"cmd_0104":[''],

"cmd_105":['',[Validators.pattern(this.phonePattern)]],
"cmd_106":['',[Validators.pattern(this.emailPattern)]],
"cmd_107":[''],
"cmd_108":[''],
"cmd_0108":[''],

"cmd_109":['',[Validators.pattern(this.phonePattern)]],
"cmd_110":['',[Validators.pattern(this.emailPattern)]],
"cmd_111":[''],
"cmd_112":[''],
"cmd_113":[''],
"cmd_114":[''],
// "cmd_115":[''],
"cmd_116":[''],
// "cmd_117":[''],
"cmd_118":[''],
// "cmd_119":[''],
"cmd_120":[''],
"cmd_121":[''],
// "cmd_122":[''],
"cmd_123":[''],
"cmd_124":[''],
"cmd_125":[''],

"cmd_126":[''],
"cmd_127":[''],
"cmd_128":[''],
"cmd_129":[''],
"cmd_130":[''],
"cmd_131":[''],
"cmd_132":[''],
// "cmd_133":[''],
"cmd_134":[''],
"cmd_135":[''],
"cmd_136":[''],
"cmd_137":[''],
"cmd_138":[''],
"cmd_139":[''],
"cmd_140":[''],
"cmd_141":[''],
"cmd_0141":[null],

"cmd_142":[null],
"cmd_143":[''],
"cmd_144":['',[Validators.pattern(this.phonePattern)]],
"cmd_145":[''],
"cmd_0145":[null],

"cmd_146":[null],
"cmd_147":[''],
"cmd_148":['',[Validators.pattern(this.phonePattern)]],
"cmd_149":[''],
"cmd_0149":[null],

"cmd_150":[null],

"cmd_151":[''],
"cmd_152":['',[Validators.pattern(this.phonePattern)]],
"cmd_153":[''],
"cmd_154":[''],
"cmd_155":[''],
"cmd_156":[''],
"cmd_157":[''],
"cmd_158":[''],
"cmd_159":[''],
"cmd_160":[''],
"cmd_161":[''],
"cmd_162":[''],
"cmd_163":[''],
"cmd_164":[''],
"cmd_165":[''],
"cmd_166":[''],
"cmd_167":[''],
"cmd_168":[''],
"cmd_169":[''],
"cmd_170":[''],
"cmd_171":[''],
"cmd_172":['',[Validators.pattern(this.emailPattern)]],
"cmd_173":[''],
"cmd_174":[''],
"cmd_0174":[''],

"cmd_175":[null],

"cmd_176":[null],
"cmd_177":[null],
// "cmd_178":[''],
"cmd_179":[''],
"cmd_180":[null,[this.dateFormatValidator]],
"cmd_000355":[''],
"cmd_181":[''],
"cmd_182":[''],
// "cmd_183":[''],
// "cmd_184":[''],
// "cmd_185":[''],
// "cnd_186":[''],
"cmd_187":[''],
"cmd_188":[''],
"cmd_189":[''],
"cmd_190":[''],
"cmd_191":[''],
"cmd_192":[''],
"cmd_193":[''],
"cmd_194":[''],
"cmd_195":[''],
"cmd_196":[''],
"cmd_197":[''],
"cmd_198":[''],
"cmd_199":[''],
"cmd_200":[''],

"cmd_201":[''],
"cmd_202":[''],
"cmd_203":[''],
"cmd_204":[''],
"cmd_205":[''],
"cmd_206":[''],
"cmd_207":[''],
"cmd_208":[''],
"cmd_209":[''],
"cmd_210":[''],
"cmd_211":[''],
"cmd_212":[''],
"cmd_213":[''],
"cmd_214":[''],
"cmd_215":[''],
"cmd_216":[''],
"cmd_217":[''],
"cmd_218":[''],
"cmd_219":[''],
"cmd_220":[''],
"cmd_221":[''],
"cmd_222":[''],
"cmd_223":[''],
"cmd_224":[''],
"cmd_225":[''],

"cmd_226":[null],
"cmd_227":[''],
"cmd_228":['',[Validators.pattern(this.phonePattern)]],
"cmd_229":[''],
"cmd_230":[null],
"cmd_231":[null,[this.dateFormatValidator]],
"cmd_232":['',[Validators.pattern(this.phonePattern)]],
"cmd_0232":[null,[this.dateFormatValidator]],

"cmd_233":[''],
"cmd_234":[''],
"cmd_235":['',[Validators.pattern(this.phonePattern)]],
"cmd_236":[null,[this.dateFormatValidator]],
"cmd_237":[''],
"cmd_238":[''],
"cmd_239":[''],
"cmd_240":[null],
"cmd_241":[''],
"cmd_242":[''],
"cmd_243":['',[Validators.pattern(this.phonePattern)]],
"cmd_244":[null,[this.dateFormatValidator]],
"cmd_245":[''],
"cmd_246":[''],
"cmd_247":[''],
"cmd_248":[null],
"cmd_249":[''],

"cmd_259":[''],
"cmd_250":['',[Validators.pattern(this.phonePattern)]],

"cmd_251":['',[Validators.pattern(this.phonePattern)]],
"cmd_252":['',[Validators.pattern(this.phonePattern)]],
"cmd_253":[''],
// "cmd_254":[''],
"cmd_255":[''],
// "cmd_256":[''],

// "add_329":[''],
"cmd_330":[''],
"cmd_331":[''],
"cmd_332":[''],
"cmd_333":[''],
"cmd_334":[''],
"cmd_335":[''],
"cmd_336":[''],
"cmd_337":[''],
"cmd_338":[''],
"cmd_339":[''],
"cmd_340":[''],
"cmd_341":[''],
"cmd_342":[''],
"cmd_343":[''],
"cmd_344":[''],
"cmd_345":[''],
"cmd_346":[''],
"cmd_347":[''],
"cmd_348":[''],
"cmd_349":[''],
"cmd_350":[''],
"cmd_351":[''],
"cmd_352":[''],
"cmd_353":[''],
"cmd_354":[null,[this.dateFormatValidator]],
"cmd_0354":[''],
"cmd_10354":[null,[this.dateFormatValidator]],
"cmd_20354":[null,[this.dateFormatValidator]],

"cmd_355":[''],
"cmd_0355":[''],
"cmd_03550":[''],
"cmd_13551":[''],
// "cmd_23552":[''],
"cmd_33553":[''],
"cmd_43554":[''],
"cmd_356":[''],
 1208:[null,[this.dateFormatValidator]],
1209:[null,[this.dateFormatValidator]],
1217:[''],
1218:[''],
1214:[''],


"cmd_0356":[''],
// "cmd_10355":[''],
// "cmd_10356":[''],
"cmd_0102":[''],
"cmd_00102":[''],
"cmd_000102":[null],
"cmd_0000102":[''],


// "cmd_357":[''],
// "cmd_358":[''],
// "cmd_359":[''],
// "cmd_360":[''],
// "cmd_361":[''],

"cmd_370":[''],
"cmd_371":[''],
"cmd_372":[''],
"cmd_373":[''],
"cmd_374":[''],
"cmd_375":[''],
"cmd_376":[null],
"cmd_377":[''],
"cmd_378":[''],
"cmd_379":[''],
"cmd_380":[null],
"cmd_381":[''],
"cmd_382":[''],
"cmd_383":[''],
"cmd_384":[''],
"cmd_385":[''],
"cmd_329":[''],
1210:[''],
1844:[''],


         });
    console.log(this.WpaForms)
  }

  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.WpaForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
     
        this.handleNAchange(handleYesNoValue);
      }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.WpaForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

  handleNAchange(cName){
    let result:boolean=false;
    if(cName=='cmd_160'){
      let x=[503,512,511,504,505,506,508,509];
      x.forEach(element => {
        const ctrl = this.WpaForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.WpaForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_187'){
      let x=[418,425,419,420,421,423,424,1305,2136,894,426];
      x.forEach(element => {
        const ctrl = this.WpaForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.WpaForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
  if(cName=='cmd_173'){

    if(this.flagForHandle==true){
      let x=['cmd_01741'];
      x.forEach(element => {
        const ctrl = this.WpaForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.WpaForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    else{
      let x=[554,'cmd_175',556,557,558,560];
      x.forEach(element => {
        const ctrl = this.WpaForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.WpaForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
   
    
    }
  
  }
  setErrorControl(name){
    this.WpaForms.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.WpaForms.controls[name].setErrors(null)
  }

}
