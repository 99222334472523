<div id="intermedimages1">
    <form [formGroup]="intermedimage1">
        <table>
            <tbody>
                <!-- <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>XVIII. PROFESSIONAL AFFILIATIONS ( Do not abbreviate )</h6>
                                </label>
                            </td>
                            <tr class="12">
                                <td colspan="12">   
                                    <label>Please List Membership In All Professional Societies</label>
                                </td>

                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1695">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="1695" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="1695" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1696">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="1696" placeholder=" Start Date" class="form-control" formControlName="1696"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh75="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh75.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_253">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1701">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="1701" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="1701" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="1702" class="form-control" placeholder=" Start Date" formControlName="1702"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh76="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh76.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_255">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1707">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="1707" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="1707" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="346">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="1708" placeholder=" Start Date" class="form-control" formControlName="1708"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh77="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh77.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_253">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_256" formControlName="cmd_256" id="cmd_256">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_256" formControlName="cmd_256" id="cmd_256">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1701">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="cmd_1701" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="cmd_1701" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_1702" class="form-control" placeholder=" Start Date"
                                                formControlName="cmd_1702" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh78="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh78.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_257">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_257" formControlName="cmd_257" id="cmd_257">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_257" formControlName="cmd_257" id="cmd_257">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1695">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="cmd_1695" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="cmd_1695" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1696">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_1696" placeholder=" Start Date" class="form-control"
                                                formControlName="cmd_1696" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh79="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh79.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_258">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_258" formControlName="cmd_258" id="cmd_258">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_258" formControlName="cmd_258" id="cmd_258">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_01701">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" id="cmd_01701" placeholder=" Name of professional Society" class="form-control"
                                                formControlName="cmd_01701" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_01702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_01702" class="form-control" placeholder=" Start Date"
                                                formControlName="cmd_01702" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh80="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh80.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_259">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_259" formControlName="cmd_259" id="cmd_259">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_259" formControlName="cmd_259" id="cmd_259">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                      </div>
                   </td>
               </tr> -->
               <table class="table table-bordered" style="table-layout: fixed;">  
                <tr>
                    <td colspan="12">
                        <label >
                            <h6>XVIII. PROFESSIONAL AFFILIATIONS ( Do not abbreviate )</h6>
                        </label>
                    </td> 
                </tr>
                <tr>                                
                    <td colspan="12">   
                        <label>Please List Membership In All Professional Societies</label>
                    </td>
                </tr> 
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6">
                                <label for="1695">Complete Name of Society:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="1695" placeholder=" Name of  Society" class="form-control"
                                    formControlName="1695" style="width:100%">
                            </div>
                        </div>    
                    </td>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-5">
                                <label for="1696">Date Joined</label>
                            </div>
                            <div class="col-7 ">
                                <div class="input-group">
                                <input type="text" id="1696" placeholder=" Start Date" class="form-control" formControlName="1696" (blur)="clearInputIfInvalid('1696')" 
                                    class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh75="ngbDatepicker">
                
                                <div class="input-group-append">
                
                                    <button class="btn btn-outline-secondary calendar" (click)="idh75.toggle()" type="button"> <i
                                            class="fas fa-calendar-alt"></i> </button>
                
                                </div>
                                </div>
                                <div *ngIf="intermedimage1.get('1696').invalid ">
                                    <small class="text-danger" *ngIf="intermedimage1.get('1696').errors?.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1696').errors?.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1696').errors?.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1696').errors?.invalidDateRange">
                                        date is out of range
                                    </small>
                                  </div>
                            </div>
                
                        </div>
                
                    </td>
                    <td colspan="4">
                            
                        <div class="row">
                
                            <div class="col-6">
                
                                <label for="cmd_253">Current Member</label>
                
                            </div>
                
                            <div class="col-6">
                
                                <input type="Radio" value="1" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                
                                <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                
                                <input type="Radio" value="0" name="cmd_253" formControlName="cmd_253" id="cmd_253">
                
                                <label for="No">No</label>
                
                            </div>
                
                        </div>
                
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div class="row">
                            <div class="col-6">
                                 <label for="1701">Complete Name of Society:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="1701" placeholder=" Name of  Society" class="form-control"
                                                formControlName="1701" style="width:100%">
                            </div>
                        </div>
                    </td>
                    <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="1702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="1702" class="form-control" placeholder=" Start Date" formControlName="1702" (blur)="clearInputIfInvalid('1702')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh76="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh76.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('1702').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('1702').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1702').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1702').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1702').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_255">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_255" formControlName="cmd_255" id="cmd_255">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="1707">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="text" id="1707" placeholder=" Name of  Society" class="form-control"
                                                formControlName="1707" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="346">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="1708" placeholder=" Start Date" class="form-control" formControlName="1708" (blur)="clearInputIfInvalid('1708')" 
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh77="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh77.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('1708').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('1708').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1708').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1708').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1708').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_253">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_256" formControlName="cmd_256" id="cmd_256">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_256" formControlName="cmd_256" id="cmd_256">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_1701">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="text" id="cmd_1701" placeholder=" Name of  Society" class="form-control"
                                                formControlName="cmd_1701" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_1702" class="form-control" placeholder=" Start Date"
                                                formControlName="cmd_1702" class="form-control datepicker" data-id="priornameeffdate_date" (blur)="clearInputIfInvalid('cmd_1702')" 
                                                ngbDatepicker #idh78="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh78.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('cmd_1702').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1702').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1702').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1702').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1702').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_257">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_257" formControlName="cmd_257" id="cmd_257">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_257" formControlName="cmd_257" id="cmd_257">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_1695">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="text" id="cmd_1695" placeholder=" Name of  Society" class="form-control"
                                                formControlName="cmd_1695" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_1696">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_1696" placeholder=" Start Date" class="form-control"
                                                formControlName="cmd_1696" class="form-control datepicker" data-id="priornameeffdate_date" (blur)="clearInputIfInvalid('cmd_1696')"
                                                ngbDatepicker #idh79="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh79.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('cmd_1696').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1696').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1696').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1696').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_1696').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_258">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_258" formControlName="cmd_258" id="cmd_258">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_258" formControlName="cmd_258" id="cmd_258">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_01701">Complete Name of Society:</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="text" id="cmd_01701" placeholder=" Name of  Society" class="form-control"
                                                formControlName="cmd_01701" style="width:100%">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="cmd_01702">Date Joined</label>
                            
                                        </div>
                            
                                        <div class="col-7 ">
                                          <div class="input-group">
                            
                                            <input type="text" id="cmd_01702" class="form-control" placeholder=" Start Date"  (blur)="clearInputIfInvalid('cmd_01702')"
                                                formControlName="cmd_01702" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh80="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh80.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('cmd_01702').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_01702').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_01702').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_01702').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('cmd_01702').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="cmd_259">Current Member</label>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <input type="Radio" value="1" name="cmd_259" formControlName="cmd_259" id="cmd_259">
                            
                                            <label for="Yes">Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                                            <input type="Radio" value="0" name="cmd_259" formControlName="cmd_259" id="cmd_259">
                            
                                            <label for="No">No</label>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>  
        </table> 
               <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>XIX. PEER REFERENCES </h6>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                    <label for="cmd_0187"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_0187')" formControlName="cmd_0187"
                                        id="cmd_0187">
                                </span>
                            </td>
                            <tr class="col-12">
                            
                                <td colspan="12">
                            
                                    <label>List at least <b>three</b> professional references, from your specialty area, not including relatives,
                                        who have worked with you in the past two years. References must be from individuals who, through recent
                                        observation, are directly familiar with your work and can attest to your clinical competence in your
                                        specialty area. One reference must be from same discipline..</label>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="359">Name of Reference:<span *ngIf="intermedimage1.controls['359'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text"  (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Name " id="359" formControlName="359"
                                                class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['359'].invalid">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['359'].touched && intermedimage1.controls['359'].invalid) || (intermedimage1.controls['359'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-2">
                            
                                            <label for="2722">Title:<span *ngIf="intermedimage1.controls['2722'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Title" id="2722" formControlName="2722" class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['2722'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['2722'].touched && intermedimage1.controls['2722'].invalid) || (intermedimage1.controls['2722'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="368">Specialty:<span *ngIf="intermedimage1.controls['368'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <ng-select [items]="specialty" (blur)="handleNAchange('cmd_0187')" [class.is-invalid]="
                                                            
                                                            intermedimage1.controls['368'].invalid" bindLabel="name"
                                                placeholder="Reference 1 Primary Specialty" bindValue="id" formControlName="368"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['368'].touched && intermedimage1.controls['368'].invalid) || (intermedimage1.controls['368'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pe-0">
                            
                                            <label for="360">Mailing Address:<span *ngIf="intermedimage1.controls['360'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Address 1" id="360" formControlName="360" class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['360'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['360'].touched && intermedimage1.controls['360'].invalid) || (intermedimage1.controls['360'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-4 pe-0"></div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Address 2" id="361" formControlName="361" class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['361'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['361'].touched && intermedimage1.controls['361'].invalid) || (intermedimage1.controls['361'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-3 ">
                            
                                            <label for="362">City:<span *ngIf="intermedimage1.controls['362'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 City" id="362" formControlName="362" class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['362'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['362'].touched && intermedimage1.controls['362'].invalid) || (intermedimage1.controls['362'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3 ">
                            
                                            <label for="364">State:<span *ngIf="intermedimage1.controls['364'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ">
                            
                                            <ng-select [items]="states"  (blur)="handleNAchange('cmd_0187')" [class.is-invalid]="
                                                            
                                                            intermedimage1.controls['364'].invalid" bindLabel="name"
                                                placeholder="Reference 1 State" bindValue="id" formControlName="364"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['364'].touched && intermedimage1.controls['364'].invalid) || (intermedimage1.controls['364'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                            
                                        </div>
                                        <div class="col-3 ">
                            
                                            <label for="365">Zip Code:<span *ngIf="intermedimage1.controls['365'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Zip" id="365" formControlName="365" class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['365'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['365'].touched && intermedimage1.controls['365'].invalid) || (intermedimage1.controls['365'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="371">E-mail Address:<span *ngIf="intermedimage1.controls['371'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 E-mail" id="371" formControlName="371"
                                                class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['371'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['371'].touched && intermedimage1.controls['371'].invalid) || (intermedimage1.controls['371'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td> 
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="369">Telephone Number:<span *ngIf="intermedimage1.controls['369'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Phone" id="369" formControlName="369"
                                                class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['369'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['369'].touched && intermedimage1.controls['369'].invalid) || (intermedimage1.controls['369'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="370">Fax Number:<span *ngIf="intermedimage1.controls['370'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder="Reference 1 Fax" id="370" formControlName="370" class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['370'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['370'].touched && intermedimage1.controls['370'].invalid) || (intermedimage1.controls['370'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="cmd_252">Cell Phone Number:<span *ngIf="intermedimage1.controls['cmd_252'].invalid ||intermedimage1.controls['cmd_0187'].value==null||intermedimage1.controls['cmd_0187'].value=='' " class="mandatory">*</span> (Optional)</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_0187')" placeholder=" Cell Phone Number" id="cmd_252" formControlName="cmd_252"
                                                class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['cmd_252'].invalid">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['cmd_252'].touched && intermedimage1.controls['cmd_252'].invalid) || (intermedimage1.controls['cmd_252'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="372">Name of Reference:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Reference 2 Name" id="372" formControlName="372"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['372'].touched && intermedimage1.controls['372'].invalid ) || (intermedimage1.controls['372'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['372'].touched && intermedimage1.controls['372'].invalid) || (intermedimage1.controls['372'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="2723">Title:</label>
                            
                                        </div>
                            
                                        <div class="col-9">
                            
                                            <input type="text" placeholder="Reference 2  Title" id="2723" formControlName="2723" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['2723'].touched && intermedimage1.controls['2723'].invalid ) || (intermedimage1.controls['2723'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['2723'].touched && intermedimage1.controls['2723'].invalid) || (intermedimage1.controls['2723'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="380">Specialty:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <ng-select [items]="specialty" [class.is-invalid]="(intermedimage1.controls['380'].touched &&
                                                            
                                                            intermedimage1.controls['380'].invalid) ||
                                                            
                                                            (intermedimage1.controls['380'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="Reference 2 Primary Specialty" bindValue="id" formControlName="380"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['380'].touched && intermedimage1.controls['380'].invalid) || (intermedimage1.controls['380'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="373">Mailing Address:</label>
                            
                                        </div>                   
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 2  Address 1" id="373" formControlName="373" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['373'].touched && intermedimage1.controls['373'].invalid ) || (intermedimage1.controls['373'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['373'].touched && intermedimage1.controls['373'].invalid) || (intermedimage1.controls['373'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-4"></div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 2 Address 2" id="374" formControlName="374" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['374'].touched && intermedimage1.controls['374'].invalid ) || (intermedimage1.controls['374'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['374'].touched && intermedimage1.controls['374'].invalid) || (intermedimage1.controls['374'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="375">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 2  City" id="375" formControlName="375" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['375'].touched && intermedimage1.controls['375'].invalid ) || (intermedimage1.controls['375'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['375'].touched && intermedimage1.controls['375'].invalid) || (intermedimage1.controls['375'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="377">State:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage1.controls['377'].touched &&
                                                            
                                                            intermedimage1.controls['377'].invalid) ||
                                                            
                                                            (intermedimage1.controls['377'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="Reference 2  State" bindValue="id" formControlName="377"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['377'].touched && intermedimage1.controls['377'].invalid) || (intermedimage1.controls['377'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="378">Zip Code:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 2  Zip" id="378" formControlName="378" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['378'].touched && intermedimage1.controls['378'].invalid ) || (intermedimage1.controls['378'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['378'].touched && intermedimage1.controls['378'].invalid) || (intermedimage1.controls['378'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="383">E-mail Address:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Reference 2 E-mail" id="383" formControlName="383"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['383'].touched && intermedimage1.controls['383'].invalid ) || (intermedimage1.controls['383'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['383'].touched && intermedimage1.controls['383'].invalid) || (intermedimage1.controls['383'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="381">Telephone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 2 Phone " id="381" formControlName="381"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['381'].touched && intermedimage1.controls['381'].invalid ) || (intermedimage1.controls['381'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['381'].touched && intermedimage1.controls['381'].invalid) || (intermedimage1.controls['381'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="382">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 2 Fax " id="382" formControlName="382" class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="cmd_250">Cell Phone Number: (Optional)</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 2  Cell Phone " id="cmd_250" formControlName="cmd_250"
                                                class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage1.controls['cmd_250'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-5">
                            
                                            <label for="384">Name of Reference:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <input type="text" placeholder="Reference 3 Name" id="384" formControlName="384"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['384'].touched && intermedimage1.controls['384'].invalid ) || (intermedimage1.controls['384'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['384'].touched && intermedimage1.controls['384'].invalid) || (intermedimage1.controls['384'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-2">
                            
                                            <label for="2725">Title:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 3 Title" id="2725" formControlName="2725" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['2725'].touched && intermedimage1.controls['2725'].invalid ) || (intermedimage1.controls['2725'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['2725'].touched && intermedimage1.controls['2725'].invalid) || (intermedimage1.controls['2725'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="393">Specialty:</label>
                            
                                        </div>
                            
                                        <div class="col-7">
                            
                                            <ng-select [items]="specialty" [class.is-invalid]="(intermedimage1.controls['393'].touched &&
                                                            
                                                            intermedimage1.controls['393'].invalid) ||
                                                            
                                                            (intermedimage1.controls['393'].invalid && isSubmit)" bindLabel="name"
                                                placeholder=" Reference 3Primary Specialty" bindValue="id" formControlName="393"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['393'].touched && intermedimage1.controls['393'].invalid) || (intermedimage1.controls['393'].invalid && isSubmit)">
                                                primary Speciality is required and cannot be empty </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="385">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 3 Address 1" id="385" formControlName="385" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['385'].touched && intermedimage1.controls['385'].invalid ) || (intermedimage1.controls['385'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['385'].touched && intermedimage1.controls['385'].invalid) || (intermedimage1.controls['385'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-4"></div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 3  Address 2" id="386" formControlName="386" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['386'].touched && intermedimage1.controls['386'].invalid ) || (intermedimage1.controls['386'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['386'].touched && intermedimage1.controls['386'].invalid) || (intermedimage1.controls['386'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="387">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 3 City" id="387" formControlName="387" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['387'].touched && intermedimage1.controls['387'].invalid ) || (intermedimage1.controls['387'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['387'].touched && intermedimage1.controls['387'].invalid) || (intermedimage1.controls['387'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="389">State:</label>
                            
                                        </div>
                                        <div class="col-8">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage1.controls['389'].touched &&
                                                            
                                                            intermedimage1.controls['389'].invalid) ||
                                                            
                                                            (intermedimage1.controls['389'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="Reference 3 State" bindValue="id" formControlName="389"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['389'].touched && intermedimage1.controls['389'].invalid) || (intermedimage1.controls['389'].invalid && isSubmit)">
                                                State is required and cannot be empty </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="390">Zip Code:</label>
                            
                                        </div>
                                        <div class="col-8">
                            
                                            <input type="text" placeholder="Reference 3 Zip" id="390" formControlName="390" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['390'].touched && intermedimage1.controls['390'].invalid ) || (intermedimage1.controls['390'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['390'].touched && intermedimage1.controls['390'].invalid) || (intermedimage1.controls['390'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="396">E-mail Address:</label>
                            
                                        </div>
                            
                                        <div class="col-12">
                            
                                            <input type="text" placeholder="Reference 3 E-mail" id="396" formControlName="396"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['396'].touched && intermedimage1.controls['396'].invalid ) || (intermedimage1.controls['396'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['396'].touched && intermedimage1.controls['396'].invalid) || (intermedimage1.controls['396'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="394">Telephone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 3 Phone" id="394" formControlName="394"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['394'].touched && intermedimage1.controls['394'].invalid ) || (intermedimage1.controls['394'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['394'].touched && intermedimage1.controls['394'].invalid) || (intermedimage1.controls['394'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="395">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 3 Fax " id="395" formControlName="395" class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="2">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="cmd_251">Cell Phone Number: (Optional)</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="Reference 3 Cell Phone" id="cmd_251" formControlName="cmd_251"
                                                class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage1.controls['cmd_251'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                       </div>
                   </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>XX. PROFESSIONAL LIABILITY ( Do not abbreviate )  </h6>
                                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                                    <label for="cmd_000187"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                                    <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_000187')" formControlName="cmd_000187"
                                        id="cmd_000187">
                                </span>
                            </td>
                            <tr class="col-12">
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pe-0">
                            
                                            <label for="345"> Current Insurance Carrier:<span *ngIf="intermedimage1.controls['345'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" placeholder="MLP Carrier Name" (blur)="handleNAchange('cmd_000187')"
                                                [class.is-invalid]="intermedimage1.controls['345'].invalid"
                                                id="345" formControlName="345" class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['345'].touched && intermedimage1.controls['345'].invalid) || (intermedimage1.controls['345'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="346">Policy Number:<span *ngIf="intermedimage1.controls['346'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Policy Number" id="346" formControlName="346" class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['346'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['346'].touched && intermedimage1.controls['346'].invalid) || (intermedimage1.controls['346'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pe-0">
                            
                                            <label for="901">Mailing Address:<span *ngIf="intermedimage1.controls['901'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Address1" id="901" formControlName="901"
                                                class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['901'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['901'].touched && intermedimage1.controls['901'].invalid) || (intermedimage1.controls['901'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-4 pe-0"></div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Address2" id="902" formControlName="902"
                                                class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['902'].invalid">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['902'].touched && intermedimage1.controls['902'].invalid) || (intermedimage1.controls['902'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3">
                            
                                            <label for="903">City:<span *ngIf="intermedimage1.controls['903'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP City" id="903" formControlName="903" class="form-control"
                                                [class.is-invalid]="intermedimage1.controls['903'].invalid ">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['903'].touched && intermedimage1.controls['903'].invalid) || (intermedimage1.controls['903'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="905">State:<span *ngIf="intermedimage1.controls['905'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <ng-select [items]="states" (blur)="handleNAchange('cmd_000187')"
                                                [class.is-invalid]="intermedimage1.controls['905'].invalid "
                                                bindLabel="name" placeholder="MLP State" bindValue="id" formControlName="905"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['905'].touched && intermedimage1.controls['905'].invalid) || (intermedimage1.controls['905'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                                        <div class="col-3">
                            
                                            <label for="906">Zip Code:<span *ngIf="intermedimage1.controls['906'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Zip" id="906" formControlName="906" class="form-control"
                                                [class.is-invalid]=" intermedimage1.controls['906'].invalid">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['906'].touched && intermedimage1.controls['906'].invalid) || (intermedimage1.controls['906'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pe-0">
                            
                                            <label for="1266">Phone Number:<span *ngIf="intermedimage1.controls['1266'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Phone " id="1266"
                                                [class.is-invalid]="intermedimage1.controls['1266'].invalid "
                                                formControlName="1266" class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage1.controls['1266'].errors?.pattern">
                            
                                                Only number are allowed</small>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['1266'].touched && intermedimage1.controls['1266'].invalid) || (intermedimage1.controls['1266'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pe-0">
                            
                                            <label for="1273">Fax Number:<span *ngIf="intermedimage1.controls['1273'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" (blur)="handleNAchange('cmd_000187')" placeholder="MLP Fax"
                                                [class.is-invalid]="intermedimage1.controls['1273'].invalid "
                                                id="1273" formControlName="1273" class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['1273'].touched && intermedimage1.controls['1273'].invalid) || (intermedimage1.controls['1273'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pe-0">
                            
                                            <label for="945">Origination (retroactive) date:<span *ngIf="intermedimage1.controls['945'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ">
                                          <div class="input-group">
                            
                                            <input type="text"   (blur)="clearInputIfInvalidMandi('945')"  (blur)="handleNAchange('cmd_000187')" placeholder="MLP Retroactive Date "
                                                [class.is-invalid]="intermedimage1.controls['945'].invalid "
                                                id="945" formControlName="945" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh811="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh811.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="intermedimage1.controls['945'].invalid && (intermedimage1.controls['945'].value==''|| intermedimage1.controls['945'].value==null) ">
                                                <small>
                                                    Required
                                                </small>
                                            
                                            </div>
                                            <div *ngIf="intermedimage1.get('945').invalid">
                                                <small class="text-danger" *ngIf="intermedimage1.get('945').errors.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('945').errors.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('945').errors.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('945').errors.invalidDateRange">
                                                    date is out of range
                                                </small>
                                            </div>
                            
                                        </div>
                            
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="350">Per claim amount:<span *ngIf="intermedimage1.controls['350'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-8 ">
                            
                                            <ng-select [items]="preClaim" (blur)="handleNAchange('cmd_000187')" bindLabel="name"
                                                [class.is-invalid]="intermedimage1.controls['350'].invalid "
                                                placeholder="MLP Per Claim" bindValue="id" formControlName="350"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['350'].touched && intermedimage1.controls['350'].invalid) || (intermedimage1.controls['350'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="4">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="351">Aggregate amount:<span *ngIf="intermedimage1.controls['351'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-12 ">
                            
                                            <ng-select [items]="aggregates" (blur)="handleNAchange('cmd_000187')" [class.is-invalid]="
                                                        
                                                        intermedimage1.controls['351'].invalid" bindLabel="name"
                                                placeholder="MLP aggregate" bindValue="id" formControlName="351"> </ng-select>
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['351'].touched && intermedimage1.controls['351'].invalid) || (intermedimage1.controls['351'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="6">
                            
                                    <div class="row">
                            
                                        <div class="col-6">
                            
                                            <label for="348">Effective Date (mm/dd/yy):<span *ngIf="intermedimage1.controls['348'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="clearInputIfInvalidMandi('348')" (blur)="handleNAchange('cmd_000187')"  placeholder="MLP Issue Date  "
                                                [class.is-invalid]=" intermedimage1.controls['348'].invalid "
                                                id="348" formControlName="348" class="form-control datepicker" data-id="priornameeffdate_date"
                                                ngbDatepicker #idh81="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh81.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage1.controls['348'].invalid && (intermedimage1.controls['348'].value==''|| intermedimage1.controls['348'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage1.get('348').invalid">
                                            <small class="text-danger" *ngIf="intermedimage1.get('348').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('348').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('348').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('348').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                            
                                        <div class="col-6">
                            
                                            <label for="349">Expiration Date (mm/dd/yy):<span *ngIf="intermedimage1.controls['349'].invalid ||intermedimage1.controls['cmd_000187'].value==null||intermedimage1.controls['cmd_000187'].value=='' " class="mandatory">*</span></label>
                            
                                        </div>
                            
                                        <div class="col-6 ">
                                          <div class="input-group">
                            
                                            <input type="text" (blur)="clearInputIfInvalidMandi('349')" (blur)="handleNAchange('cmd_000187')"  placeholder="MLP Expiration Date " id="349"
                                                [class.is-invalid]="intermedimage1.controls['349'].invalid "
                                                formControlName="349" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh82="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh82.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="intermedimage1.controls['349'].invalid && (intermedimage1.controls['349'].value==''|| intermedimage1.controls['349'].value==null) ">
                                            <small>
                                                Required
                                            </small>
                                        
                                        </div>
                                        <div *ngIf="intermedimage1.get('349').invalid">
                                            <small class="text-danger" *ngIf="intermedimage1.get('349').errors.invalidDateFormat">
                                                Only Date Format is allowed(MM/DD/YYYY) </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('349').errors.invalidMonth">
                                                Month is out of range
                                            </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('349').errors.invalidDateyear">
                                                year is out of range(1900-2099) </small>
                                            <small class="text-danger" *ngIf="intermedimage1.get('349').errors.invalidDateRange">
                                                date is out of range
                                            </small>
                                        </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr>
                            
                                <td colspan="12">
                            
                                    <label for="9"><b>Please list ALL professional liability carriers within the past ten years
                                            &nbsp;&nbsp;</b></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            
                                        <label for="cmd_00059"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            
                                        <input type="checkbox" value="1" formControlName="cmd_00059" id="cmd_00059">
                            
                                    </span>
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pe-0">
                            
                                            <label for="1242"><b>Name of Carrier:</b></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" Name of Carrier" id="1242" formControlName="1242"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1242'].touched && intermedimage1.controls['1242'].invalid ) || (intermedimage1.controls['1242'].invalid && isSubmit)">
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="1256">Policy Number:</label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" placeholder=" Policy Number" id="1256" formControlName="1256"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1256'].touched && intermedimage1.controls['1256'].invalid ) || (intermedimage1.controls['1256'].invalid && isSubmit)">
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-4 ">
                            
                                            <label for="1244">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-8 ">
                            
                                            <input type="text" placeholder=" Mailing Address1" id="1244" formControlName="1244"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1244'].touched && intermedimage1.controls['1244'].invalid ) || (intermedimage1.controls['1244'].invalid && isSubmit)">
                            
                                        </div>
                                        <div class="col-4 "></div>
                            
                                        <div class="col-8 ">
                            
                                            <input type="text" placeholder=" Mailing Address2" id="1245" formControlName="1245"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1245'].touched && intermedimage1.controls['1245'].invalid ) || (intermedimage1.controls['1245'].invalid && isSubmit)">
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="1246">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" City" id="1246" formControlName="1246" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1246'].touched && intermedimage1.controls['1246'].invalid ) || (intermedimage1.controls['1246'].invalid && isSubmit)">
                            
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="1248">State:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage1.controls['1248'].touched &&
                                                        
                                                        intermedimage1.controls['1248'].invalid) ||
                                                        
                                                        (intermedimage1.controls['1248'].invalid && isSubmit)" bindLabel="name"
                                                placeholder=" State" bindValue="id" formControlName="1248"> </ng-select>
                            
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="1249">Zip Code:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" Zip " id="1249" formControlName="1249" class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1249'].touched && intermedimage1.controls['1249'].invalid ) || (intermedimage1.controls['1249'].invalid && isSubmit)">
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="1254">Phone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder=" Phone " id="1254" formControlName="1254"
                                                class="form-control"
                                                [class.is-invalid]="(intermedimage1.controls['1254'].touched && intermedimage1.controls['1254'].invalid ) || (intermedimage1.controls['1254'].invalid && isSubmit)">
                                            <small class="text-danger" *ngIf="intermedimage1.controls['1254'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 pe-0">
                            
                                            <label for="1255">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                            
                                            <input type="text" [class.is-invalid]="(intermedimage1.controls['1255'].touched &&
                                                        
                                                        intermedimage1.controls['1255'].invalid) ||
                                                        
                                                        (intermedimage1.controls['1255'].invalid && isSubmit)" placeholder=" Fax "
                                                id="1255" formControlName="1255" class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['1255'].touched && intermedimage1.controls['1255'].invalid) || (intermedimage1.controls['1255'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="1252">From (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder=" Effective Date" id="1252" formControlName="1252" (blur)="clearInputIfInvalid('1252')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh83="ngbDatepicker"
                                                [class.is-invalid]="(intermedimage1.controls['1252'].touched && intermedimage1.controls['1252'].invalid ) || (intermedimage1.controls['1252'].invalid && isSubmit)">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh83.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('1252').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('1252').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1252').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1252').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1252').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="1253">To (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder=" Expiration Date" id="1253" formControlName="1253" (blur)="clearInputIfInvalid('1253')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh84="ngbDatepicker"
                                                [class.is-invalid]="(intermedimage1.controls['1253'].touched && intermedimage1.controls['1253'].invalid ) || (intermedimage1.controls['1253'].invalid && isSubmit)">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh84.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('1253').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('1253').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1253').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1253').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('1253').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_1_1242"><b>Name of Carrier:</b></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" Name of Carrier" id="add_1_1242" formControlName="add_1_1242"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_1_1256">Policy Number:</label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" placeholder=" Policy Number" id="add_1_1256" formControlName="add_1_1256"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-4">
                            
                                            <label for="add_1_1244">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder=" Mailing Address1" id="add_1_1244" formControlName="add_1_1244"
                                                class="form-control">
                                        </div>
                                        <div class="col-4"></div>
                            
                            
                                        <div class="col-8">
                            
                                            <input type="text" placeholder=" Mailing Address2" id="add_1_1245" formControlName="add_1_1245"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="8">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_1_1246">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" City" id="add_1_1246" formControlName="add_1_1246"
                                                class="form-control">
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_1_1248">State:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage1.controls['add_1_1248'].touched &&
                                                        
                                                        intermedimage1.controls['add_1_1248'].invalid) ||
                                                        
                                                        (intermedimage1.controls['add_1_1248'].invalid && isSubmit)" bindLabel="name"
                                                placeholder=" State" bindValue="id" formControlName="add_1_1248"> </ng-select>
                            
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_1_1249">Zip Code:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder=" Zip Code" id="add_1_1249" formControlName="add_1_1249"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="add_1_1254">Phone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                            
                                            <input type="text" placeholder=" Phone " id="add_1_1254" formControlName="add_1_1254"
                                                class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage1.controls['add_1_1254'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="add_1_1255">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                            
                                            <input type="text" placeholder=" Fax " id="add_1_1255" formControlName="add_1_1255"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>                      
                               <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="add_1_1252">From (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder="  Effective Date" id="add_1_1252" formControlName="add_1_1252" (blur)="clearInputIfInvalid('add_1_1252')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh85="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh85.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('add_1_1252').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1252').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1252').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1252').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1252').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="add_1_1253">To (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                                        
                                            <input type="text" placeholder="  Expiration Date" id="add_1_1253" formControlName="add_1_1253"  (blur)="clearInputIfInvalid('add_1_1253')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh86="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh86.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('add_1_1253').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1253').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1253').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1253').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_1_1253').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_2_1242"><b>Name of Carrier:</b></label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder="  Name of Carrier" id="add_2_1242" formControlName="add_2_1242"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_2_1256">Policy Number:</label>
                            
                                        </div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" placeholder="  Policy Number" id="add_2_1256" formControlName="add_2_1256"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                            <tr class="col-12">
                            
                                <td colspan="7">
                            
                                    <div class="row">
                            
                                        <div class="col-4 pe-0">
                            
                                            <label for="add_2_1244">Mailing Address:</label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder="  Mailing Address1" id="add_2_1244" formControlName="add_2_1244"
                                                class="form-control">
                                        </div>
                                        <div class="col-4 ps-0"></div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder="  Mailing Address2" id="add_2_1245" formControlName="add_2_1245"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="5">
                            
                                    <div class="row">
                            
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_2_1246">City:</label>
                            
                                        </div>
                            
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder="  City" id="add_2_1246" formControlName="add_2_1246"
                                                class="form-control">
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_2_1248">State:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <ng-select [items]="states" [class.is-invalid]="(intermedimage1.controls['add_2_1248'].touched &&
                                                        
                                                        intermedimage1.controls['add_2_1248'].invalid) ||
                                                        
                                                        (intermedimage1.controls['add_2_1248'].invalid && isSubmit)" bindLabel="name"
                                                placeholder="  State" bindValue="id" formControlName="add_2_1248"> </ng-select>
                            
                                        </div>
                                        <div class="col-3 pe-0">
                            
                                            <label for="add_2_1249">Zip Code:</label>
                            
                                        </div>
                                        <div class="col-8 ps-0">
                            
                                            <input type="text" placeholder="  Zip  " id="add_2_1249" formControlName="add_2_1249"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
 
                            <tr class="col-12">
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="add_2_1254">Phone Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                            
                                            <input type="text" placeholder="  Phone  " id="add_2_1254" formControlName="add_2_1254"
                                                class="form-control"> <small class="text-danger"
                                                *ngIf="intermedimage1.controls['add_2_1254'].errors?.pattern">
                            
                                                Only number are allowed</small>
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12 ">
                            
                                            <label for="add_2_1255">Fax Number:</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                            
                                            <input type="text" placeholder="  Fax  " id="add_2_1255" formControlName="add_2_1255"
                                                class="form-control">
                                        </div>
                            
                                    </div>
                            
                                </td>
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="add_2_1252">From (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10 ">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder="  Effective Date" id="add_2_1252" formControlName="add_2_1252" (blur)="clearInputIfInvalid('add_2_1252')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh87="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh87.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('add_2_1252').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1252').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1252').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1252').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1252').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                                <td colspan="3">
                            
                                    <div class="row">
                            
                                        <div class="col-12">
                            
                                            <label for="add_2_1253">To (mm/dd/yy):</label>
                            
                                        </div>
                            
                                        <div class="col-10">
                                          <div class="input-group">
                            
                                            <input type="text" placeholder="  Expiration Date" id="add_2_1253" formControlName="add_2_1253" (blur)="clearInputIfInvalid('add_2_1253')"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh88="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh88.toggle()" type="button"> <i
                                                        class="fas fa-calendar-alt"></i> </button>
                            
                                            </div>
                                            </div>
                                            <div *ngIf="intermedimage1.get('add_2_1253').invalid ">
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1253').errors?.invalidDateFormat">
                                                    Only Date Format is allowed(MM/DD/YYYY)
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1253').errors?.invalidMonth">
                                                    Month is out of range
                                                </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1253').errors?.invalidDateyear">
                                                    year is out of range(1900-2099) </small>
                                                <small class="text-danger" *ngIf="intermedimage1.get('add_2_1253').errors?.invalidDateRange">
                                                    date is out of range
                                                </small>
                                              </div>
                                        </div>
                            
                                    </div>
                            
                                </td>                           
                          </tr>
                      </div>
                  </td>
              </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                    <td colspan="12">
                        <label>
                            <h6>XXI. PROFESSIONAL LIABILITY ACTION DETAIL-CONFIDENTIAL </h6>
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="doesnotapply">
                            <label for="cmd_099"><b>Does Not Apply:</b></label>&nbsp;&nbsp;
                            <input type="checkbox" value="Yes" (ngModelChange)="handleNAchange('cmd_099')" formControlName="cmd_099"
                                id="cmd_099">
                        </span>
                    </td>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-8 pe-0"> <label for="9">Practitioner Name:(print or type)<span *ngIf="intermedimage1.controls['cmd_356'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder=" PROVIDER PRINTED NAME AS ENTERED ABOVE" id="cmd_356"
                                    formControlName="cmd_356" class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['cmd_356'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['cmd_356'].touched && intermedimage1.controls['cmd_356'].invalid) || (intermedimage1.controls['cmd_356'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="col-12">
                
                            <label>
                
                                Please list any past or current professional liability claim(s) or lawsuit(s), in which allegations of
                                professional
                
                                negligence were made against you, whether or not you were individually named in the claim or
                                lawsuit.Please do
                
                                not include patient names or other HIPAA protected (PHI). Photocopy this page as needed and submit a
                                separate
                
                                page for EACH claim/event. A legible signed practitioner narrative that addresses all of the following
                                details is an acceptable alternative.
                
                            </label>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="9">Date and clinical details of the incident, with preceding
                                    events<span *ngIf="intermedimage1.controls['1208'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                        </div>
                
                        <div class="d-flex flex-row align-items-center flex-wrap ">
                            <div class="input-group">
                        
                            <label for="1208">Date<span *ngIf="intermedimage1.controls['1208'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label>
                
                            <input type="text"  (blur)="clearInputIfInvalidMandi('1208')" (blur)="handleNAchange('cmd_099')" placeholder=" Date of occurance"
                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker #idh97="ngbDatepicker"
                                formControlName="1208" id="1208"
                                [class.is-invalid]="intermedimage1.controls['1208'].invalid ">
                
                            <div class="input-group-append">
                
                                <button class="btn btn-outline-secondary calendar" (click)="idh97.toggle()" type="button">
                
                                    <i class="fas fa-calendar-alt"></i>
                
                                </button>
                
                            </div>
                            </div>
                        
                                <div class="text-danger"
                                    *ngIf="intermedimage1.controls['1208'].invalid && (intermedimage1.controls['1208'].value==''|| intermedimage1.controls['1208'].value==null) ">
                                    <small>
                                        Required and cannot be empty
                                    </small>
                                
                                </div>
                                <div *ngIf="intermedimage1.get('1208').invalid">
                                    <small class="text-danger" *ngIf="intermedimage1.get('1208').errors.invalidDateFormat">
                                        Only Date Format is allowed(MM/DD/YYYY) </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1208').errors.invalidMonth">
                                        Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1208').errors.invalidDateyear">
                                        year is out of range(1900-2099) </small>
                                    <small class="text-danger" *ngIf="intermedimage1.get('1208').errors.invalidDateRange">
                                        date is out of range
                                    </small>
                                </div>
                
                        </div>
                
                        <br>
                
                        <div class="d-flex flex-row align-items-center flex-wrap">
                
                            <label for="6">Details<span *ngIf="intermedimage1.controls['1217'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label>
                
                            <textarea id="1217" name="idharea" (blur)="handleNAchange('cmd_099')" row="30" formControlName="1217"
                                cols="70"
                                [class.is-invalid]="intermedimage1.controls['1217'].invalid"></textarea>
                
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage1.controls['1217'].touched && intermedimage1.controls['1217'].invalid) || (intermedimage1.controls['1217'].invalid && isSubmit)">
                                Required and cannot be empty </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="9">Your role and specific responsibility in the incident<span *ngIf="intermedimage1.controls['1218'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <textarea id="1218" name="idharea" (blur)="handleNAchange('cmd_099')" row="30" formControlName="1218"
                                cols="70"
                                [class.is-invalid]="intermedimage1.controls['1218'].invalid "></textarea>
                
                
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage1.controls['1218'].touched && intermedimage1.controls['1218'].invalid) || (intermedimage1.controls['1218'].invalid && isSubmit)">
                                Required and cannot be empty </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="9">Subsequent events, including patient’s clinical outcome<span *ngIf="intermedimage1.controls['1214'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <textarea id="1214" name="idharea" (blur)="handleNAchange('cmd_099')" row="30" formControlName="1214"
                                cols="70"
                                [class.is-invalid]=" intermedimage1.controls['1214'].invalid"></textarea>
                
                            <div class="invalid-feedback"
                                *ngIf="(intermedimage1.controls['1214'].touched && intermedimage1.controls['1214'].invalid) || (intermedimage1.controls['1214'].invalid && isSubmit)">
                                Required and cannot be empty </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="9">Date suit or claim was filed<span *ngIf="intermedimage1.controls['1209'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0 ">
                                <div class="input-group">
                            
                                <input type="text" (blur)="clearInputIfInvalidMandi('1209')" (blur)="handleNAchange('cmd_099')" placeholder="Date of Lawsuit" id="1209"
                                    formControlName="1209" class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                    #idh98="ngbDatepicker"
                                    [class.is-invalid]=" intermedimage1.controls['1209'].invalid ">
                
                                <div class="input-group-append">
                
                                    <button class="btn btn-outline-secondary calendar" (click)="idh98.toggle()" type="button">
                
                                        <i class="fas fa-calendar-alt"></i>
                
                                    </button>
                
                                </div>
                                </div>
                            

                                    <div class="text-danger"
                                        *ngIf="intermedimage1.controls['1209'].invalid && (intermedimage1.controls['1209'].value==''|| intermedimage1.controls['1209'].value==null) ">
                                        <small>
                                            Required and cannot be empty 
                                        </small>
                                    
                                    </div>
                                    <div *ngIf="intermedimage1.get('1209').invalid">
                                        <small class="text-danger" *ngIf="intermedimage1.get('1209').errors.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1209').errors.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1209').errors.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1209').errors.invalidDateRange">
                                            date is out of range
                                        </small>
                                    </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="1210">Name and Address of Insurance Carrier that handled the claim<span *ngIf="intermedimage1.controls['1210'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')"
                                    placeholder="Insurance Carrier at Time of Occurrence" id="1210" formControlName="1210"
                                    class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['1210'].invalid">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1210'].touched && intermedimage1.controls['1210'].invalid) || (intermedimage1.controls['1210'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="6">
                
                        <div class="row">
                
                           
                
                            <div class="col-12 d-flex ">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Insurance Carrier Address 1"
                                    id="2012" formControlName="2012" class="form-control"
                                    [class.is-invalid]="intermedimage1.controls['2012'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['2012'].touched && intermedimage1.controls['2012'].invalid) || (intermedimage1.controls['2012'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                            
                            
                            <div class="col-12 d-flex">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Insurance Carrier Address 2"
                                    id="1844" formControlName="1844" class="form-control"
                                    [class.is-invalid]="intermedimage1.controls['1844'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1844'].touched && intermedimage1.controls['1844'].invalid) || (intermedimage1.controls['1844'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                    <td colspan="6">
                
                        <div class="row">
                
                            <div class="col-3">
                
                                <label>City:<span *ngIf="intermedimage1.controls['1845'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label>
                
                            </div>
                
                            <div class="col-8 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Insurance Carrier City" id="1845"
                                    formControlName="1845" class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['1845'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1845'].touched && intermedimage1.controls['1845'].invalid) || (intermedimage1.controls['1845'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                            <div class="col-3">
                
                                <label>State:<span *ngIf="intermedimage1.controls['1847'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label>
                
                            </div>
                            <div class="col-8 ps-0">
                
                                <ng-select [items]="states" (blur)="handleNAchange('cmd_099')"
                                    [class.is-invalid]="
                                                                                                
                                                                                                intermedimage1.controls['1847'].invalid" bindLabel="name" placeholder="Insurance Carrier State"
                                    bindValue="id" formControlName="1847">
                
                                </ng-select>
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1847'].touched && intermedimage1.controls['1847'].invalid) || (intermedimage1.controls['1847'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                            <div class="col-3">
                
                                <label>Zip Code:<span *ngIf="intermedimage1.controls['1848'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label>
                
                            </div>
                            <div class="col-8 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Insurance Carrier Zip" id="1848"
                                    formControlName="1848" class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['1848'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1848'].touched && intermedimage1.controls['1848'].invalid) || (intermedimage1.controls['1848'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="1213">Your status in the legal action (primary defendant, co-defendant,
                                    other)<span *ngIf="intermedimage1.controls['1213'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Other Defendants Involved " id="1213"
                                    formControlName="1213" class="form-control"
                                    [class.is-invalid]="intermedimage1.controls['1213'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1213'].touched && intermedimage1.controls['1213'].invalid) || (intermedimage1.controls['1213'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="1858">Current status of suit or other action<span *ngIf="intermedimage1.controls['1858'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder=" Claim Status" id="1858"
                                    formControlName="1858" class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['1858'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1858'].touched && intermedimage1.controls['1858'].invalid) || (intermedimage1.controls['1858'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="1226">Date of settlement, judgment, or dismissal<span *ngIf="intermedimage1.controls['1226'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span>:</label></div>
                
                            <div class="col-4 ps-0 ">
                                <div class="input-group">
                
                                <input type="text" (blur)="clearInputIfInvalidMandi('1226')" (blur)="handleNAchange('cmd_099')" placeholder="Settlement Date " id="1226"
                                    formControlName="1226" class="form-control datepicker"
                                    [class.is-invalid]="intermedimage1.controls['1226'].invalid "
                                    data-id="priornameeffdate_date" ngbDatepicker #idh99="ngbDatepicker">
                
                                <div class="input-group-append">
                
                                    <button class="btn btn-outline-secondary calendar" (click)="idh99.toggle()" type="button">
                
                                        <i class="fas fa-calendar-alt"></i>
                
                                    </button>
                
                                </div>
                                </div>
                               

                                    <div class="text-danger"
                                        *ngIf="intermedimage1.controls['1226'].invalid && (intermedimage1.controls['1226'].value==''|| intermedimage1.controls['1226'].value==null) ">
                                        <small>
                                            Required and cannot be empty
                                        </small>
                                    
                                    </div>
                                    <div *ngIf="intermedimage1.get('1226').invalid">
                                        <small class="text-danger" *ngIf="intermedimage1.get('1226').errors.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1226').errors.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1226').errors.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('1226').errors.invalidDateRange">
                                            date is out of range
                                        </small>
</div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <tr>
                
                    <td colspan="12">
                
                        <div class="row">
                
                            <div class="col-6 pe-0"> <label for="1228">If case was settled out-of-court, or with a judgment, settlement
                                    amount attributed to you? $<span *ngIf="intermedimage1.controls['1228'].invalid ||intermedimage1.controls['cmd_099'].value==null||intermedimage1.controls['cmd_099'].value=='' " class="mandatory">*</span></label></div>
                
                            <div class="col-4 ps-0">
                
                                <input type="text" (blur)="handleNAchange('cmd_099')" placeholder="Total Amount of Settlement " id="1228"
                                    formControlName="1228" class="form-control"
                                    [class.is-invalid]=" intermedimage1.controls['1228'].invalid ">
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['1228'].touched && intermedimage1.controls['1228'].invalid) || (intermedimage1.controls['1228'].invalid && isSubmit)">
                                    Required and cannot be empty </div>
                
                            </div>
                
                        </div>
                
                    </td>
                
                </tr>
                <div>
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>IDAHO PRACTITIONER ATTESTATION QUESTIONS - To be completed by the practitioner</h6>
                    </label>
                
                    <label style="padding-left:10px">Please answer <b><u>all</u></b> of the following questions. If your answer to any
                        of the following questions is 'Yes", provide details as specified on a separate sheet.<i>If you attach
                            additional sheets, sign and
                
                            date each sheet.</i></label>
                
                </div>
                
                <div class="table table-bordered" style="table-layout:fixed;">
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>A. PROFESSIONAL SANCTIONS</h6>
                    </label>
                
                    <div class="col-12 row">
                
                        <label>1. Have you ever been, or are you now in the process of being denied, revoked, terminated, suspended,
                            restricted, reduced, limited, sanctioned, placed on probation, monitored, or not renewed for any of the
                            following? Or have you voluntarily or involuntarily relinquished, withdrawn, or failed to proceed with an
                            application for any of the following in order to avoid an adverse action or to preclude an investigation or
                            while under investigation relating to professional competence or conduct? </label>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>a. License to practice any profession in any jurisdiction</label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_329"
                                [class.is-invalid]="(intermedimage1.controls['cmd_329'].touched && intermedimage1.controls['cmd_329'].invalid) || (intermedimage1.controls['cmd_329'].invalid && isSubmit)"
                                id="cmd_329" name="cmd_329" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_329'].touched && intermedimage1.controls['cmd_329'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_329'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_329"
                                [class.is-invalid]="(intermedimage1.controls['cmd_329'].touched && intermedimage1.controls['cmd_329'].invalid) || (intermedimage1.controls['cmd_329'].invalid && isSubmit)"
                                id="cmd_329" name="cmd_329" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_329'].touched && intermedimage1.controls['cmd_329'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_329'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_329'].touched && intermedimage1.controls['cmd_329'].invalid) || (intermedimage1.controls['cmd_329'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">b. Other professional registration or certification in any jurisdiction </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_330"
                                [class.is-invalid]="(intermedimage1.controls['cmd_330'].touched && intermedimage1.controls['cmd_330'].invalid) || (intermedimage1.controls['cmd_330'].invalid && isSubmit)"
                                id="cmd_330" name="cmd_330" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_330'].touched && intermedimage1.controls['cmd_330'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_330'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_330"
                                [class.is-invalid]="(intermedimage1.controls['cmd_330'].touched && intermedimage1.controls['cmd_330'].invalid) || (intermedimage1.controls['cmd_330'].invalid && isSubmit)"
                                id="cmd_330" name="cmd_330" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_330'].touched && intermedimage1.controls['cmd_330'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_330'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_330'].touched && intermedimage1.controls['cmd_330'].invalid) || (intermedimage1.controls['cmd_330'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">c. Specialty or subspecialty board certification </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_331"
                                [class.is-invalid]="(intermedimage1.controls['cmd_331'].touched && intermedimage1.controls['cmd_331'].invalid) || (intermedimage1.controls['cmd_331'].invalid && isSubmit)"
                                id="cmd_331" name="cmd_331" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_331'].touched && intermedimage1.controls['cmd_331'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_331'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_331"
                                [class.is-invalid]="(intermedimage1.controls['cmd_331'].touched && intermedimage1.controls['cmd_331'].invalid) || (intermedimage1.controls['cmd_331'].invalid && isSubmit)"
                                id="cmd_331" name="cmd_331" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_331'].touched && intermedimage1.controls['cmd_331'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_331'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_331'].touched && intermedimage1.controls['cmd_331'].invalid) || (intermedimage1.controls['cmd_331'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label> d. Membership on any hospital medical staff </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_332"
                                [class.is-invalid]="(intermedimage1.controls['cmd_332'].touched && intermedimage1.controls['cmd_332'].invalid) || (intermedimage1.controls['cmd_332'].invalid && isSubmit)"
                                id="cmd_332" name="cmd_332" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_332'].touched && intermedimage1.controls['cmd_332'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_332'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_332"
                                [class.is-invalid]="(intermedimage1.controls['cmd_332'].touched && intermedimage1.controls['cmd_332'].invalid) || (intermedimage1.controls['cmd_332'].invalid && isSubmit)"
                                id="cmd_332" name="cmd_332" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_332'].touched && intermedimage1.controls['cmd_332'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_332'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_332'].touched && intermedimage1.controls['cmd_332'].invalid) || (intermedimage1.controls['cmd_332'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">e. Clinical privileges at any facility, including hospitals, ambulatory surgical centers,
                                skilled nursing facilities, etc. </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_333"
                                [class.is-invalid]="(intermedimage1.controls['cmd_333'].touched && intermedimage1.controls['cmd_333'].invalid) || (intermedimage1.controls['cmd_333'].invalid && isSubmit)"
                                id="cmd_333" name="cmd_333" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_333'].touched && intermedimage1.controls['cmd_333'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_333'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_333"
                                [class.is-invalid]="(intermedimage1.controls['cmd_333'].touched && intermedimage1.controls['cmd_333'].invalid) || (intermedimage1.controls['cmd_333'].invalid && isSubmit)"
                                id="cmd_333" name="cmd_333" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_333'].touched && intermedimage1.controls['cmd_333'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_333'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_333'].touched && intermedimage1.controls['cmd_333'].invalid) || (intermedimage1.controls['cmd_333'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">f. Medicare, Medicaid, FDA, governmental, national or international regulatory agency or any
                                public program </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_334"
                                [class.is-invalid]="(intermedimage1.controls['cmd_334'].touched && intermedimage1.controls['cmd_334'].invalid) || (intermedimage1.controls['cmd_334'].invalid && isSubmit)"
                                id="cmd_334" name="cmd_334" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_334'].touched && intermedimage1.controls['cmd_334'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_334'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_334"
                                [class.is-invalid]="(intermedimage1.controls['cmd_334'].touched && intermedimage1.controls['cmd_334'].invalid) || (intermedimage1.controls['cmd_334'].invalid && isSubmit)"
                                id="cmd_334" name="cmd_334" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_334'].touched && intermedimage1.controls['cmd_334'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_334'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_334'].touched && intermedimage1.controls['cmd_334'].invalid) || (intermedimage1.controls['cmd_334'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">g. Professional society membership or fellowship </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_335"
                                [class.is-invalid]="(intermedimage1.controls['cmd_335'].touched && intermedimage1.controls['cmd_335'].invalid) || (intermedimage1.controls['cmd_335'].invalid && isSubmit)"
                                id="cmd_335" name="cmd_335" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_335'].touched && intermedimage1.controls['cmd_335'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_335'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_335"
                                [class.is-invalid]="(intermedimage1.controls['cmd_335'].touched && intermedimage1.controls['cmd_335'].invalid) || (intermedimage1.controls['cmd_335'].invalid && isSubmit)"
                                id="cmd_335" name="cmd_335" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_335'].touched && intermedimage1.controls['cmd_335'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_335'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_335'].touched && intermedimage1.controls['cmd_335'].invalid) || (intermedimage1.controls['cmd_335'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9"> h. Participation/membership in an HMO, PPO, IPA, PHO or other entity </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_336"
                                [class.is-invalid]="(intermedimage1.controls['cmd_336'].touched && intermedimage1.controls['cmd_336'].invalid) || (intermedimage1.controls['cmd_336'].invalid && isSubmit)"
                                id="cmd_336" name="cmd_336" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_336'].touched && intermedimage1.controls['cmd_336'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_336'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_336"
                                [class.is-invalid]="(intermedimage1.controls['cmd_336'].touched && intermedimage1.controls['cmd_336'].invalid) || (intermedimage1.controls['cmd_336'].invalid && isSubmit)"
                                id="cmd_336" name="cmd_336" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_336'].touched && intermedimage1.controls['cmd_336'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_336'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_336'].touched && intermedimage1.controls['cmd_336'].invalid) || (intermedimage1.controls['cmd_336'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label for="9">i. Academic Appointment </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_337"
                                [class.is-invalid]="(intermedimage1.controls['cmd_337'].touched && intermedimage1.controls['cmd_337'].invalid) || (intermedimage1.controls['cmd_337'].invalid && isSubmit)"
                                id="cmd_337" name="cmd_337" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_337'].touched && intermedimage1.controls['cmd_337'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_337'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_337"
                                [class.is-invalid]="(intermedimage1.controls['cmd_337'].touched && intermedimage1.controls['cmd_337'].invalid) || (intermedimage1.controls['cmd_337'].invalid && isSubmit)"
                                id="cmd_337" name="cmd_337" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_337'].touched && intermedimage1.controls['cmd_337'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_337'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_337'].touched && intermedimage1.controls['cmd_337'].invalid) || (intermedimage1.controls['cmd_337'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>j. Authority to prescribe controlled substances (DEA or other authority) </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_338"
                                [class.is-invalid]="(intermedimage1.controls['cmd_338'].touched && intermedimage1.controls['cmd_338'].invalid) || (intermedimage1.controls['cmd_338'].invalid && isSubmit)"
                                id="cmd_338" name="cmd_338" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_338'].touched && intermedimage1.controls['cmd_338'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_338'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_338"
                                [class.is-invalid]="(intermedimage1.controls['cmd_338'].touched && intermedimage1.controls['cmd_338'].invalid) || (intermedimage1.controls['cmd_338'].invalid && isSubmit)"
                                id="cmd_338" name="cmd_338" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_338'].touched && intermedimage1.controls['cmd_338'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_338'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_338'].touched && intermedimage1.controls['cmd_338'].invalid) || (intermedimage1.controls['cmd_338'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>2. Have you ever been subject to review, challenges, and/or disciplinary action, formal or informal,
                                by an ethics committee, licensing board, medical disciplinary board, professional association or
                                education/training institution? </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_339"
                                [class.is-invalid]="(intermedimage1.controls['cmd_339'].touched && intermedimage1.controls['cmd_339'].invalid) || (intermedimage1.controls['cmd_339'].invalid && isSubmit)"
                                id="cmd_339" name="cmd_339" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_339'].touched && intermedimage1.controls['cmd_339'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_339'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_339"
                                [class.is-invalid]="(intermedimage1.controls['cmd_339'].touched && intermedimage1.controls['cmd_339'].invalid) || (intermedimage1.controls['cmd_339'].invalid && isSubmit)"
                                id="cmd_339" name="cmd_339" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_339'].touched && intermedimage1.controls['cmd_339'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_339'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_339'].touched && intermedimage1.controls['cmd_339'].invalid) || (intermedimage1.controls['cmd_339'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">3. Have you been found by a state professional disciplinary board to have committed
                            unprofessional conduct as defined in applicable state provisions?
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_340"
                                [class.is-invalid]="(intermedimage1.controls['cmd_340'].touched && intermedimage1.controls['cmd_340'].invalid) || (intermedimage1.controls['cmd_340'].invalid && isSubmit)"
                                id="cmd_340" name="cmd_340" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_340'].touched && intermedimage1.controls['cmd_340'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_340'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_340"
                                [class.is-invalid]="(intermedimage1.controls['cmd_340'].touched && intermedimage1.controls['cmd_340'].invalid) || (intermedimage1.controls['cmd_340'].invalid && isSubmit)"
                                id="cmd_340" name="cmd_340" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_340'].touched && intermedimage1.controls['cmd_340'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_340'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_340'].touched && intermedimage1.controls['cmd_340'].invalid) || (intermedimage1.controls['cmd_340'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>4. Have you ever been the subject of any reports to a state, federal, national data bank, or state
                                licensing or disciplinary entity? </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_341"
                                [class.is-invalid]="(intermedimage1.controls['cmd_341'].touched && intermedimage1.controls['cmd_341'].invalid) || (intermedimage1.controls['cmd_341'].invalid && isSubmit)"
                                id="cmd_341" name="cmd_341" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_341'].touched && intermedimage1.controls['cmd_341'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_341'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_341"
                                [class.is-invalid]="(intermedimage1.controls['cmd_341'].touched && intermedimage1.controls['cmd_341'].invalid) || (intermedimage1.controls['cmd_341'].invalid && isSubmit)"
                                id="cmd_341" name="cmd_341" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_341'].touched && intermedimage1.controls['cmd_341'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_341'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_341'].touched && intermedimage1.controls['cmd_341'].invalid) || (intermedimage1.controls['cmd_341'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                </div>
                
                <div class="table table-bordered" style="table-layout: fixed;">
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>B. CRIMINAL HISTORY</h6>
                    </label>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>1. Have you ever been charged with a criminal violation (felony or misdemeanor) resulting in either a
                                plea bargain, conviction on the original or lesser charge, or payment of a fine, suspended sentence,
                                community service or other obligation? </label>
                
                        </td>
                
                        <td class="col-2">
                
                
                            <input type="radio" formControlName="cmd_342"
                                [class.is-invalid]="(intermedimage1.controls['cmd_342'].touched && intermedimage1.controls['cmd_342'].invalid) || (intermedimage1.controls['cmd_342'].invalid && isSubmit)"
                                id="cmd_342" name="cmd_342" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_342'].touched && intermedimage1.controls['cmd_342'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_342'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_342"
                                [class.is-invalid]="(intermedimage1.controls['cmd_342'].touched && intermedimage1.controls['cmd_342'].invalid) || (intermedimage1.controls['cmd_342'].invalid && isSubmit)"
                                id="cmd_342" name="cmd_342" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_342'].touched && intermedimage1.controls['cmd_342'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_342'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_342'].touched && intermedimage1.controls['cmd_342'].invalid) || (intermedimage1.controls['cmd_342'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>a. Do you have notice of any such anticipated charges? </label>
                
                        </td>
                
                        <td class="col-2">
                
                
                            <input type="radio" formControlName="cmd_343"
                                [class.is-invalid]="(intermedimage1.controls['cmd_343'].touched && intermedimage1.controls['cmd_343'].invalid) || (intermedimage1.controls['cmd_343'].invalid && isSubmit)"
                                id="cmd_343" name="cmd_343" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_343'].touched && intermedimage1.controls['cmd_343'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_343'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_343"
                                [class.is-invalid]="(intermedimage1.controls['cmd_343'].touched && intermedimage1.controls['cmd_343'].invalid) || (intermedimage1.controls['cmd_343'].invalid && isSubmit)"
                                id="cmd_343" name="cmd_343" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_343'].touched && intermedimage1.controls['cmd_343'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_343'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_343'].touched && intermedimage1.controls['cmd_343'].invalid) || (intermedimage1.controls['cmd_343'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>b. Are you currently under governmental investigation? </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_344"
                                [class.is-invalid]="(intermedimage1.controls['cmd_344'].touched && intermedimage1.controls['cmd_344'].invalid) || (intermedimage1.controls['cmd_344'].invalid && isSubmit)"
                                id="cmd_344" name="cmd_344" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_344'].touched && intermedimage1.controls['cmd_344'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_344'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_344"
                                [class.is-invalid]="(intermedimage1.controls['cmd_344'].touched && intermedimage1.controls['cmd_344'].invalid) || (intermedimage1.controls['cmd_344'].invalid && isSubmit)"
                                id="cmd_344" name="cmd_344" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_344'].touched && intermedimage1.controls['cmd_344'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_344'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_344'].touched && intermedimage1.controls['cmd_344'].invalid) || (intermedimage1.controls['cmd_344'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                </div>
                
                <div class="table table-bordered" style="table-layout: fixed;">
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>C. AFFIRMATION OF ABILITIES</h6>
                    </label>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label> 1. Do you presently use any drugs illegally? </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_345"
                                [class.is-invalid]="(intermedimage1.controls['cmd_345'].touched && intermedimage1.controls['cmd_345'].invalid) || (intermedimage1.controls['cmd_345'].invalid && isSubmit)"
                                id="cmd_345" name="cmd_345" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_345'].touched && intermedimage1.controls['cmd_345'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_345'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_345"
                                [class.is-invalid]="(intermedimage1.controls['cmd_345'].touched && intermedimage1.controls['cmd_345'].invalid) || (intermedimage1.controls['cmd_345'].invalid && isSubmit)"
                                id="cmd_345" name="cmd_345" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_345'].touched && intermedimage1.controls['cmd_345'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_345'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_345'].touched && intermedimage1.controls['cmd_345'].invalid) || (intermedimage1.controls['cmd_345'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label> 2. Do you have, or have you had, any physical condition, mental health condition, or chemical
                                dependency condition (alcohol or other substance) that affects or could affect your current ability to
                                practice with or without reasonable accommodation? If reasonable accommodation is required, specify the
                                accommodations required.<u>If the answer to this question is yes,</u> please identify and describe any
                                rehabilitation program in which you are or were enrolled which assures your ability to adhere to
                                prevailing standards of professional performance. </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_346"
                                [class.is-invalid]="(intermedimage1.controls['cmd_346'].touched && intermedimage1.controls['cmd_346'].invalid) || (intermedimage1.controls['cmd_346'].invalid && isSubmit)"
                                id="cmd_346" name="cmd_346" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_346'].touched && intermedimage1.controls['cmd_346'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_346'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_346"
                                [class.is-invalid]="(intermedimage1.controls['cmd_346'].touched && intermedimage1.controls['cmd_346'].invalid) || (intermedimage1.controls['cmd_346'].invalid && isSubmit)"
                                id="cmd_346" name="cmd_346" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_346'].touched && intermedimage1.controls['cmd_346'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_346'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_346'].touched && intermedimage1.controls['cmd_346'].invalid) || (intermedimage1.controls['cmd_346'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label> 3. Are you unable to perform any of the services/clinical privileges required by the applicable
                                participating practitioner agreement/hospital agreement, with or without reasonable accommodation,
                                according to accepted standards of professional performance? </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_347"
                                [class.is-invalid]="(intermedimage1.controls['cmd_347'].touched && intermedimage1.controls['cmd_347'].invalid) || (intermedimage1.controls['cmd_347'].invalid && isSubmit)"
                                id="cmd_347" name="cmd_347" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_347'].touched && intermedimage1.controls['cmd_347'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_347'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_347"
                                [class.is-invalid]="(intermedimage1.controls['cmd_347'].touched && intermedimage1.controls['cmd_347'].invalid) || (intermedimage1.controls['cmd_347'].invalid && isSubmit)"
                                id="cmd_347" name="cmd_347" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_347'].touched && intermedimage1.controls['cmd_347'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_347'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_347'].touched && intermedimage1.controls['cmd_347'].invalid) || (intermedimage1.controls['cmd_347'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                </div>
                
                <div class="table table-bordered" style="table-layout: fixed;">
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>D. LITIGATION AND MALPRACTICE COVERAGE HISTORY (If you answer "Yes" to any of the questions in this
                
                            section,</h6>
                
                        <span>please document in Section XXI. PROFESSIONAL LIABILITY ACTION DETAIL of this
                
                            application.)</span>
                
                    </label>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>
                
                                1. Have allegations or claims of professional negligence been made against you at any time, whether
                
                                or
                
                                not you were individually named in the claim or lawsuit?
                
                            </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_348"
                                [class.is-invalid]="(intermedimage1.controls['cmd_348'].touched && intermedimage1.controls['cmd_348'].invalid) || (intermedimage1.controls['cmd_348'].invalid && isSubmit)"
                                id="cmd_348" name="cmd_348" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_348'].touched && intermedimage1.controls['cmd_348'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_348'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_348"
                                [class.is-invalid]="(intermedimage1.controls['cmd_348'].touched && intermedimage1.controls['cmd_348'].invalid) || (intermedimage1.controls['cmd_348'].invalid && isSubmit)"
                                id="cmd_348" name="cmd_348" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_348'].touched && intermedimage1.controls['cmd_348'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_348'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_348'].touched && intermedimage1.controls['cmd_348'].invalid) || (intermedimage1.controls['cmd_348'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>
                
                                2. Have you or your insurance carrier(s) ever paid any money on your behalf to settle/resolve a
                
                                professional malpractice claim (not necessarily a lawsuit) and/or to satisfy a judgement
                
                                (courtordered damage award) in a professional lawsuit?
                
                            </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_349"
                                [class.is-invalid]="(intermedimage1.controls['cmd_349'].touched && intermedimage1.controls['cmd_349'].invalid) || (intermedimage1.controls['cmd_349'].invalid && isSubmit)"
                                id="cmd_349" name="cmd_349" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_349'].touched && intermedimage1.controls['cmd_349'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_349'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_349"
                                [class.is-invalid]="(intermedimage1.controls['cmd_349'].touched && intermedimage1.controls['cmd_349'].invalid) || (intermedimage1.controls['cmd_349'].invalid && isSubmit)"
                                id="cmd_349" name="cmd_349" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_349'].touched && intermedimage1.controls['cmd_349'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_349'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_349'].touched && intermedimage1.controls['cmd_349'].invalid) || (intermedimage1.controls['cmd_349'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>
                
                                3. Are there any such claims being asserted against you now?
                
                            </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_350"
                                [class.is-invalid]="(intermedimage1.controls['cmd_350'].touched && intermedimage1.controls['cmd_350'].invalid) || (intermedimage1.controls['cmd_350'].invalid && isSubmit)"
                                id="cmd_350" name="cmd_350" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_350'].touched && intermedimage1.controls['cmd_350'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_350'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_350"
                                [class.is-invalid]="(intermedimage1.controls['cmd_350'].touched && intermedimage1.controls['cmd_350'].invalid) || (intermedimage1.controls['cmd_350'].invalid && isSubmit)"
                                id="cmd_350" name="cmd_350" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_350'].touched && intermedimage1.controls['cmd_350'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_350'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_350'].touched && intermedimage1.controls['cmd_350'].invalid) || (intermedimage1.controls['cmd_350'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>
                
                                4. Have you ever been denied professional liability coverage or has your coverage ever been
                
                                terminated, not renewed, restricted, or modified (e.g. reduced limits, restricted coverage,
                
                                surcharged)?
                
                            </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_351"
                                [class.is-invalid]="(intermedimage1.controls['cmd_351'].touched && intermedimage1.controls['cmd_351'].invalid) || (intermedimage1.controls['cmd_351'].invalid && isSubmit)"
                                id="cmd_351" name="cmd_351" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_351'].touched && intermedimage1.controls['cmd_351'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_351'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <input type="radio" formControlName="cmd_351"
                                [class.is-invalid]="(intermedimage1.controls['cmd_351'].touched && intermedimage1.controls['cmd_351'].invalid) || (intermedimage1.controls['cmd_351'].invalid && isSubmit)"
                                id="cmd_351" name="cmd_351" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_351'].touched && intermedimage1.controls['cmd_351'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_351'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_351'].touched && intermedimage1.controls['cmd_351'].invalid) || (intermedimage1.controls['cmd_351'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                    <div class="col-12 row">
                
                        <td class="col-10">
                
                            <label>
                
                                5. Are any of the privileges that you are requesting not covered by your current malpractice
                
                                coverage?
                
                            </label>
                
                        </td>
                
                        <td class="col-2">
                
                            <input type="radio" formControlName="cmd_352"
                                [class.is-invalid]="(intermedimage1.controls['cmd_352'].touched && intermedimage1.controls['cmd_352'].invalid) || (intermedimage1.controls['cmd_352'].invalid && isSubmit)"
                                id="cmd_352" name="cmd_352" value="1">
                
                            <label>Yes&nbsp;&nbsp;</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_352'].touched && intermedimage1.controls['cmd_352'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_352'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                
                            <input type="radio" formControlName="cmd_352"
                                [class.is-invalid]="(intermedimage1.controls['cmd_352'].touched && intermedimage1.controls['cmd_352'].invalid) || (intermedimage1.controls['cmd_352'].invalid && isSubmit)"
                                id="cmd_352" name="cmd_352" value="0">
                
                            <label>No</label>
                
                            <div class="formLabel col-md-11"
                                *ngIf="intermedimage1.controls['cmd_352'].touched && intermedimage1.controls['cmd_352'].invalid">
                
                                <small class="text-danger" *ngIf="intermedimage1.controls['cmd_352'].errors?.required">
                
                                    required
                
                                </small>
                
                            </div>
                
                            <span
                                *ngIf="(intermedimage1.controls['cmd_352'].touched && intermedimage1.controls['cmd_352'].invalid) || (intermedimage1.controls['cmd_352'].invalid && isSubmit)">
                
                                <i class="fas fa-times ms-2 text-danger"></i>
                
                            </span>
                
                        </td>
                
                    </div>
                
                </div>
                
                
                
                
                
                <div class="table table-bordered" style="table-layout: fixed;">
                
                    <label class="table table-bordered" style="border: 2px solid black;">
                
                        <h6>E. ATTESTATION </h6>
                    </label>
                
                    <tr>
                
                        <td colspan="12">
                
                            <div class="row">
                
                                <label for="9" style="padding-left:20px">
                
                                    I warrant that all the statements made on this form and on any attached information sheets are
                
                                    complete, accurate, and current. I
                
                                    understand that any material misstatements in, or omissions from, this statement constitute
                
                                    cause for denial of membership or cause
                
                                    for summary dismissal from the entity to which this statement has been submitted.
                
                                </label>
                
                            </div>
                
                        </td>
                
                    </tr>
                
                    <tr>
                        <td colspan="4">
                
                            <div class="row">
                
                                <div class="col-6 pe-0"> <label for="9">Type or Print name here:<span class="mandatory">*</span></label>
                                </div>
                
                                <div class="col-6 ps-0">
                
                                    <input type="text" placeholder="Typed or printed name" [class.is-invalid]="(intermedimage1.controls['cmd_33553'].touched &&
                                    
                                    intermedimage1.controls['cmd_33553'].invalid) ||
                                    
                                    (intermedimage1.controls['cmd_33553'].invalid && isSubmit)" id="cmd_33553"
                                        formControlName="cmd_33553" class="form-control">
                
                                </div>
                
                                <div class="invalid-feedback"
                                    *ngIf="(intermedimage1.controls['cmd_33553'].touched && intermedimage1.controls['cmd_33553'].invalid) || (intermedimage1.controls['cmd_33553'].invalid && isSubmit)">
                
                                    Name is required and cannot be empty
                
                                </div>
                
                            </div>
                
                        </td>
                        <td colspan="4">
                
                            <div class="row">
                
                                <div class="col-6 pe-0"> <label for="9">Applicant's Signature:<span class="mandatory">*</span></label>
                                </div>
                
                                <div class="col-6 ps-0">
                
                                    <img *ngIf="!showOne" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                        (click)="esignOpenOne()" />
                
                                    <img *ngIf="showOne" class="img eSignImageShow" [src]="esignOne" alt="Credential my Doc"
                                        (click)="esignOpenOne()" /><br><span>(Stamped signature is not acceptable)
                
                                    </span>
                
                                    <input type="hidden" placeholder=" Provider Signature" name="cmd_353" value="{{finalImgPath}}"
                                        id="cmd_353" class="form-control" formControlName="cmd_353"
                                        [class.is-invalid]="(intermedimage1.controls['cmd_353'].touched && intermedimage1.controls['cmd_353'].invalid ) || (intermedimage1.controls['cmd_353'].invalid && isSubmit)">
                
                                    <div class="invalid-feedback"
                                        *ngIf="(intermedimage1.controls['cmd_353'].touched && intermedimage1.controls['cmd_353'].invalid) || (intermedimage1.controls['cmd_353'].invalid && isSubmit)">
                
                                        Provider Signature is required and cannot be empty
                
                                    </div>
                
                                </div>
                
                            </div>
                
                        </td> 
                        
                
                        <td colspan="4">
                
                            <div class="row">
                
                                <div class="col-4 pe-0"> <label for="9">Date:<span class="mandatory">*</span></label></div>
                
                                <div class="col-8 ps-0 ">
                                    <div class="input-group">
                
                                    <input type="text" placeholder="  Date" (blur)="clearInputIfInvalidMandi('cmd_354')"  [class.is-invalid]="(intermedimage1.controls['cmd_354'].touched &&
                                    
                                    intermedimage1.controls['cmd_354'].invalid) ||
                                    
                                    (intermedimage1.controls['cmd_354'].invalid && isSubmit)" id="cmd_354" formControlName="cmd_354"
                                        class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                        #idh100="ngbDatepicker">
                
                                    <div class="input-group-append"> 
                
                                        <button class="btn btn-outline-secondary calendar" (click)="idh100.toggle()" type="button">
                
                                            <i class="fas fa-calendar-alt"></i>
                
                                        </button>
                                  </div>
                                  </div>
                                        <!-- <div class="text-danger"
                                            *ngIf="(intermedimage1.controls['cmd_354'].invalid&&intermedimage1.controls['cmd_354'].touched) && (intermedimage1.controls['cmd_354'].value==''|| intermedimage1.controls['cmd_354'].value==null) ">
                                            <small>
                                                Date is required and cannot be empty
                                            </small>
                                        </div>
                                        <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidMonth">
                                            Month is out of range
                                        </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                        <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateRange">
                                            date is out of range
                                        </small>
                                        
                                </div> -->
                                <div class="text-danger"
                                *ngIf="(intermedimage1.controls['cmd_354'].invalid&&intermedimage1.controls['cmd_354'].touched) && (intermedimage1.controls['cmd_354'].value==''|| intermedimage1.controls['cmd_354'].value==null) ">
                                <small>
                                    Date is required and cannot be empty
                                </small>
                            
                            </div>
                            <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateFormat">
                                Only Date Format is allowed(MM/DD/YYYY) </small>
                            <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidMonth">
                                Month is out of range
                            </small>
                            <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateyear">
                                year is out of range(1900-2099) </small>
                            <small class="text-danger" *ngIf="intermedimage1.get('cmd_354').errors?.invalidDateRange">
                                date is out of range
                            </small>
                        </div>
                            </div>
                
                        </td>
                
                                       
                    </tr>           
                </div>            
                </div>
                </td>
                </tr>
                <tr class="col-12">
                    <td colspan="12">
                        <div class="table table-bordered" style="table-layout: fixed;">
                            <td colspan="12">
                                <label>
                                    <h6>XXII. ATTESTATION</h6>  
                                </label>            
                            </td>
                            <tr>
                            
                                <td colspan="12">
                            
                                    <div class="d-flex flex-row align-items-center flex-wrap">
                            
                                        <label for="1">
                            
                                            I certify the information in this entire application is complete, accurate, and current. I acknowledge
                                            that any misstatements in or
                            
                                            omissions from this application constitute cause for denial of membership or cause for summary dismissal
                                            from the entity to
                            
                                            which this statement has been made. A photocopy of this application has the same force and effect as the
                                            original. I have
                            
                                            reviewed this information as of the most recent date listed below.
                            
                                        </label>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>                           
                            <tr>
                            
                                <td colspan="12">
                            
                                    <div class="row">
                            
                                        <div class="col-6 pe-0"> <label for="9">Print Name here:<span class="mandatory">*</span></label></div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <input type="text" placeholder=" Name" id="cmd_43554" [class.is-invalid]="(intermedimage1.controls['cmd_43554'].touched &&
                                                            
                                                            intermedimage1.controls['cmd_43554'].invalid) ||
                                                            
                                                            (intermedimage1.controls['cmd_43554'].invalid && isSubmit)" formControlName="cmd_43554"
                                                class="form-control">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['cmd_43554'].touched && intermedimage1.controls['cmd_43554'].invalid) || (intermedimage1.controls['cmd_43554'].invalid && isSubmit)">
                                                Required </div>
                            
                                        </div>
                            
                                        <br>
                            
                                        <div class="col-6 pe-0"> <label for="9"> Signature:<span class="mandatory">*</span></label></div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <img *ngIf="!showTwo" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                                                (click)="esignOpenTwo()" />
                            
                                            <img *ngIf="showTwo" class="img eSignImageShow" [src]="esignTwo" alt="Credential my Doc"
                                                (click)="esignOpenTwo()" /><br><span>(Stamped signature is not acceptable)
                            
                                            </span>
                            
                                            <input type="hidden" placeholder=" Provider Signature" name="cmd_355" value="{{finalImgPathTwo}}"
                                                id="cmd_355" class="form-control" formControlName="cmd_355"
                                                [class.is-invalid]="(intermedimage1.controls['cmd_355'].touched && intermedimage1.controls['cmd_355'].invalid ) || (intermedimage1.controls['cmd_355'].invalid && isSubmit)">
                            
                                            <div class="invalid-feedback"
                                                *ngIf="(intermedimage1.controls['cmd_355'].touched && intermedimage1.controls['cmd_355'].invalid) || (intermedimage1.controls['cmd_355'].invalid && isSubmit)">
                            
                                                Provider Signature is required and cannot be empty
                            
                                            </div>
                            
                                        </div>
                            
                            
                                        <div class="col-6 pe-0"> <label for="9">Date:<span class="mandatory">*</span></label></div>
                            
                                        <div class="col-4 ps-0 ">
                                            <div class="input-group">
                                            
                                            <input type="text" placeholder=" Date" id="cmd_10354" (blur)="clearInputIfInvalidMandi('cmd_10354')"  [class.is-invalid]="(intermedimage1.controls['cmd_10354'].touched &&
                                                            
                                                            intermedimage1.controls['cmd_10354'].invalid) ||
                                                            
                                                            (intermedimage1.controls['cmd_10354'].invalid && isSubmit)" formControlName="cmd_10354"
                                                class="form-control datepicker" data-id="priornameeffdate_date" ngbDatepicker
                                                #idh101="ngbDatepicker">
                            
                                            <div class="input-group-append">
                            
                                                <button class="btn btn-outline-secondary calendar" (click)="idh101.toggle()" type="button">
                            
                                                    <i class="fas fa-calendar-alt"></i>
                            
                                                </button>
                            
                                            </div>
                                            </div>
                                            <div class="text-danger"
                                            *ngIf="(intermedimage1.controls['cmd_10354'].invalid&&intermedimage1.controls['cmd_10354'].touched) && (intermedimage1.controls['cmd_10354'].value==''|| intermedimage1.controls['cmd_10354'].value==null) ">
                                            <small>
                                              Required
                                            </small>
                                          
                                          </div>
                                          <small class="text-danger" *ngIf="intermedimage1.get('cmd_10354').errors?.invalidDateFormat">
                                            Only Date Format is allowed(MM/DD/YYYY) </small>
                                          <small class="text-danger" *ngIf="intermedimage1.get('cmd_10354').errors?.invalidMonth">
                                            Month is out of range
                                          </small>
                                          <small class="text-danger" *ngIf="intermedimage1.get('cmd_10354').errors?.invalidDateyear">
                                            year is out of range(1900-2099) </small>
                                          <small class="text-danger" *ngIf="intermedimage1.get('cmd_10354').errors?.invalidDateRange">
                                            date is out of range
                                          </small>
                            
                                        </div>
                            
                                        <div class="col-6 pe-0"> <label for="9"><b>Review dates and initials:</b></label></div>
                            
                                        <div class="col-6 ps-0">
                            
                                            <textarea id="cmd_0354" name="idharea" row="90" formControlName="cmd_0354" cols="70"></textarea>
                            
                                        </div>
                            
                                    </div>
                            
                                </td>
                            
                            </tr>
                     </div>
                 </td>
             </tr>
          </tbody>
        </table>
    </form>
</div>   