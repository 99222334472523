<div id="erie-form" >
    <form [formGroup]="erieForm">

            <div class="row">
                <div class="col-12 text-end">
                    <img src="../../../../assets/images/erielogo.png" width="250" height="75" class="erie-logo"
                        alt="Credential my Doc">
                </div>
            </div>
            <div class="p-2">
                <p>Welcome to Erie Family Health Centers.</p>
            </div>
            <div class="p-2">
                <p>We are thrilled that you have decided to join our team!</p>
            </div>
            <br />
            <div class="p-2">
                <p>
                    Our aim is to ensure that your transition is achieved in a timely and efficient manner and in compliance
                    with the terms outlined in your contract. There are several steps in the onboarding process including
                    credentialing, privileging and enrollment <u>requiring your immediate attention to initiate this
                        process.</u></p>
            </div>
            <div class="p-2">
                <p>
                    You will find several forms to be carefully reviewed, completed, signed and submitted to create your
                    credentialing file. Enclosed are the required documents along with instructions to assist along with a
                    detailed list of the requirements below. Be sure to thoroughly review this information and contact the
                    credentialing department at <u class="f-col-underline"> credentialing@eriefamilyhealth.org</u>with any questions.</p>
            </div>
            <div class="p-2">
                <p>
                    Please submit the entire credentialing packet and supporting documentations as soon as possible to the
                    credentialing email inbox at<u class="f-col-underline"> credentialing@eriefamilyhealth.org</u> or fax, Attn: Credentialing Department
                    at (773) 904-7787.</p>
            </div>
            <div class="p-2">
                <p>
                    We look forward to working with you in having this process completed prior to your start date. If you
                    need further assistance or would like to schedule a time to meet and review the documents, please do not
                    hesitate to contact the credentialing department via email or by calling our main office to schedule an
                    appointment time with a credentialing representative.</p>
            </div>
            <div class="ps-2">
                <p>Feel free to print this e-mail and use as a checklist!</p>
            </div>
            <div class="col-md-12">
                <h5><u><b>Documents To Complete/Submit:</b></u></h5>
            </div>
            <div class="col-md-12">
                <ul class="ps-4">
                    <li> Erie Release and Attestation</li>
                    <li>Background Check Release forms</li>
                    <ul class="ps-4">
                        <li>IDPH Data Gathering Application </li>
                        <li>Please complete this form in its entirety. Any questions that may not relate to your scope of
                            professional practice please indicate with “N/A.” and Yes or No where appropriate. Please
                            include an email address for your peer references on page 18 which must be a person within the
                            same clinical discipline. Your application will be considered incomplete without this
                            information.</li>
                    </ul>
                    <li>CAQH Releases, CAQH ID, username and password</li>
                    <li>Professional Liability Insurance Application </li>
                    <li>Erie privileges request form </li>
                    <li>Health Attestation form </li>
                    <li>IMPACT Electronic Signature Agreement </li>
                    <li>IBCCP Contract</li>
                    <ul class="ps-4">
                        <li>State of IL Department of Healthcare & Family Services (CANTS) Background Check Form </li>
                        <li>One Beacon Insurance Application Form</li>
                    </ul>
                    <li>Erie Release and Attestation (Form Attached)</li>
                    <li class="text-blue">IDPH (Illinois Department of Public Health) Application (Form attached):</li>
                    <ul class="ps-4 text-blue">
                        <li> Please complete form in its entirety. Any questions that may not relate to your scope of
                            professional
                            practice please indicate with a “N/A.”</li>
                        <li>Any work/claim history gaps that exceed 30 consecutive days must be explained in a separate
                            addendum.</li>
                        <hr class="pdf-line">
                        <li>Chapter B: Business Information (pg. 22) : requests primary site information that you may not be
                            privy to, please leave section(s) blank after “office schedule”; specific site information will
                            be populated once it has been received by credentialing staff.</li>
                    </ul>
                    <li>Three (3) Peer References (Form Attached): </li>
                    <ul class="ps-4">
                        <li>To be completed by three (3) peers with <u>same credentials as you </u>(these are the same
                            references
                            you will list on the State of Illinois Credentialing IDPH application (pg. 18)).<u>Please
                                provide
                                email addresses and phone numbers for listed peers.</u> </li>
                    </ul>
                    <li>Health Attestation (Form Attached)</li>
                    <li>IMPACT Electronic Signature Agreement </li>
                </ul>
            </div>
            <div class="col-md-12 pt-4">
                <h5><b><u>Supporting Documents/Information to
                            Submit: </u></b></h5>
            </div>
            <div class="col-md-12 Pt-4">
                <h5><b>Erie Health Requirements:*</b></h5>
            </div>
            <div>
                <ul class="ps-4">
                    <li>Clear copy of valid IL Driver’s License/State ID or current passport</li>
                    <li>IL Professional License</li>
                    <li>IL Controlled Substance License</li>
                    <li>Current DEA Certificate with Illinois address</li>
                    <li>NPI Number, a copy of the original issue letter if available, username and password</li>
                    <li>Professional School Diploma(s)</li>
                    <li>Residency, Internship Fellowship Certificate (if applicable)</li>
                    <li>Current Board Certification if applicable</li>
                    <li>Past liability insurance carriers up to 10 years (copies of all past liability insurance
                        certificates) and loss history</li>
                    <li>Clear copies of health vaccines</li>
                    <li>Current Basic Life Support (BLS/CPR/PALS) cards/certificates</li>
                    <ul class="ps-4">
                        <li>Nexplanon Certificate(If applicable for privileging)</li>
                    </ul>
                </ul>
            </div>
            <div class="col-md-12 pt-4">
                <h6><b>
                        * If proof of required vaccines cannot be provided, re-administration of vaccine/titers is required
                        (See Health Attestation doc).</b>
                </h6>
            </div>
            <br />
            <hr class="pdf-line">
            <div class="row text-center pt-4">
                <div class="col-md-12">
                    <h4><b>STATE OF ILLINOIS</b></h4>
                </div>
                <div class="col-md-12 pt-4">
                    <h5><b>Health Care Professional Credentialing and Business Data Gathering Form </b></h5>
                </div>
            </div>
            <div class="row text-center pt-6">
                <div class="col-md-1"></div>
            <div class="col-md-10 pt-4">
                <h6><b> The Health Care Professional Credentials Data Collection Act [410 ILCS 517] requires that this
                        form be collected from health care professionals by hospitals, health care entities, and health
                        care plans which desire to credential such professional. Each hospital, health care entity, and
                        health care plan may also require completion of supplemental forms.</b>
                </h6>
            </div>
            </div>
            <table class="databox pt-4">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-12 pt-2 text-center">
                                <h6>INSTRUCTIONS</h6>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="row pt-6 ">
                <div class="col-1"></div>
                <div class="col-md-10  pt-4">
                    <h6><b> This form is for initial credentialing only. Other forms are required for recredentialing and
                            for updating information. YOU ONLY HAVE TO FILL OUT AND SUBMIT WHAT IS REQUESTED BY THE
                            CREDENTIALING ENTITY. PLEASE REFER TO THE INSTRUCTIONS PROVIDED TO YOU BY THE ORGANIZATION YOU
                            ARE APPLYING TO FOR THEIR REQUIREMENTS.</b>
                    </h6>
                    <div class="row pt-4 ">
                        <div class="col-md-12">
                            <p> This form has been segmented into two (2) different Chapters, each containing various sections:
                            </p>
                        </div>
                        <div class="col-md-12 pt-4 text-center">
                          <div><label> Chapter A: Practice and Professional Information
                        </label> </div>
                        <div>   <label>
                            Chapter B: Business Information
                        </label></div>
                        </div>
                        <div class="col-md-12 pt-4">
                            <h5><b> As previously noted, please consult the specific credentialing entity instructions for their
                                    individual Chapter or Section requirements for submission.</b>
                            </h5>
                        </div>
                        <div class="col-md-12 pt-4">
                            <p><b>
                                    GENERAL INSTRUCTIONS: </b>Wherever this application requests information but does not provide
                                sufficient space to provide a complete response (for example, you have more licenses, specialties,
                                work history, etc.) provide attachments which contain all of the information requested in the
                                relevant section OR duplicate the relevant section as many times as necessary and attach it to the
                                back of this application.
                            </p>
                        </div>
                        <div class="col-md-12 pt-4">
                            <p>
                                The data marked as “Confidential Information” shall be maintained in confidence to the extent
                                required by law. They may be used by the health care plan, entity or hospital and by their agents
                                for credentialing and internal business purposes. Other data contained in this form may be released.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <hr class="pdf-line">
            <table class="databox pt-4">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-12 pt-2 text-center"><h6>ATTACHMENTS</h6></div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="row pt-4">
            <div class="col-12">
            <h6>
                <b>Attach forms A-F as needed to support “yes” responses in Section J: Professional History and copies of
                    the following:</b>
            </h6>
            </div>
            </div>
            <table class="databox">
                <tr>
                    <td colspan="12">
                        <div class="d-flex">
                            <input formControlName="cmd_33330" type="checkbox" id="cmd_33330"  value="1">
                            <label for="cmd_33330"> Curriculum Vitae</label> <br />
                        </div>
                    </td>
                </tr>
                <tr class="table-backround">
                    <td colspan="12" class="tab">
                        <div class="d-flex ">
                            <h5> CONFIDENTIAL INFORMATION:</h5>
                    </div>
                         <div class="d-flex ">
                            <input formControlName="cmd_33331" type="checkbox"   value="1">
                            <label for="cmd_33331"> All Current Professional Licenses</label> <br />
                        </div>
                        <div class="d-flex ">
                            <input formControlName="cmd_10201" type="checkbox"  value="1">
                            <label for="cmd_10201"> Current Federal DEA License, If Applicable</label> <br />
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33332" type="checkbox" id="cmd_33332"  value="1">
                            <label for="cmd_33332"> Current State Controlled Substance License(s), If Applicable</label>
                            <br />
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33333" type="checkbox" id="cmd_33333"  value="1">
                            <label for="cmd_33333"> Current Professional Liability Insurance Face Sheet or Declaration of
                                Insurance with Effective Date, Expiration Date and Amount Displayed per Occurrence and In
                                Aggregate</label> <br />
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33334" type="checkbox" id="cmd_33334" value="1">
                            <label for="cmd_33334"> Current CLIA Certificate, If Applicable</label> <br />
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33335" type="checkbox" id="cmd_33335"  value="1">
                            <label for="cmd_33335"> Current W-9s, If Applicable</label>
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33336" type="checkbox" id="cmd_33336"  value="1">
                            <label for="cmd_33336"> ECFMG Certificate, If Applicable
                            </label>
                        </div>
                        <div class="d-flex">
                            <input formControlName="cmd_33337" type="checkbox" id="cmd_33337" value="1">
                            <label for="cmd_33337"> Professional School Diploma, Residency Certificates, Fellowship
                                Certificates, and Board Certifications, As Applicable
                            </label> <br />
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <table class="databox pt-4">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-12 pt-2 text-center">
                            <h6><b>AFFIRMATION OF INFORMATION</b></h6>
                        </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4 text-left">
                <h6><b>
                    I represent and warrant that all of the information provided and the responses given are correct and
                    complete to the best of my knowledge and belief. I understand that falsification or omission of
                    information may be grounds for rejection or termination, in addition to any penalties provided by
                    lawsuits. I further agree to promptly inform all entities to which this form was sent and not rejected
                    of any change required to be updated by the Health Care Professional Credentialing and Business Data
                    Gathering Update Form.</b></h6>
            </div>
            <div class="col-md-12 pt-4 text-left">
                <h6 >
                    I understand that this application does not entitle me to participation in any hospital, health care
                    entity, or health plan.</h6>
            </div>
            <br />
            <div class="row pt-4" >
                <div class="col-md-4 text-center">
                    <img *ngIf="!show1" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                      (click)="esignOpen(1)">
                    <img *ngIf="show1" class="img eSignImageShow" [src]="esign1" alt="Credential my Doc"
                        (click)="esignOpen(1)">
                    <input type="hidden"id="cmd_9000350" formControlName="cmd_9000350">

                    <div class="formLabel"
                    *ngIf="(erieForm.controls['cmd_9000350'].touched && erieForm.controls['cmd_9000350'].errors)
                     || (erieForm.controls['cmd_9000350'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['cmd_9000350'].errors">
                       required
                    </small>
                </div>
                </div>
                <div class="col-md-4">
                    <input formControlName="cmd_33338" class="form-control  append_office_manager_first_name_1"
                        id="cmd_33338" type="text" placeholder="PRINT NAME"
                       >
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_33339" (blur)="clearInputIfInvalidMandi('cmd_33339')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d152="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['cmd_33339'].touched && erieForm.controls['cmd_33339'].errors)
                            || (erieForm.controls['cmd_33339'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d152.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['cmd_33339'].invalid&&erieForm.controls['cmd_33339'].touched) && (erieForm.controls['cmd_33339'].value==''|| erieForm.controls['cmd_33339'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33339').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33339').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33339').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33339').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-4 text-center">
                    <p>Applicant’s Signature</p>
                </div>
                <div class="col-md-4">
                    <p> Type or Print Name </p>
                </div>
                <div class="col-md-3">
                    <p> Date</p>
                </div>
            </div>
            <div class="row pt-4">
            <div class="col-md-12 pt-4 text-center ">
                <h5><b> ** PLEASE BE ADVISED THAT EACH HOSPITAL, HEALTH CARE ENTITY, **</b>
                </h5>
                <h5><b>** AND HEALTH CARE PLAN MAY ALSO REQUIRE COMPLETION OF AN **
                    </b>
                </h5>
                <h5> <b>** ATTESTATION AND RELEASE OF INFORMATION FORM. ** </b>
                </h5>
            </div>
            </div>
            <br />
            <hr class="pdf-line">
            <table class="databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-center">
                            <h6><b>CHAPTER  A:</b></h6>
                            <br />
                            <h6 ><b>PRACTICE AND
                                    PROFESSIONAL INFORMATION</b></h6>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <table class="databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-center">
                            <label ><b>SECTION A.
                                    GENERAL INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row ">
                <div class="col-md-1">
                    <label >Name:</label>
                </div>
                <div class="col-md-3 mb-3">
                    <input formControlName="9" class="form-control"  type="text"
                    [class.is-invalid]="(erieForm.controls['9'].touched && erieForm.controls['9'].errors)
                    || (erieForm.controls['9'].errors && isSubmit)"
                    (ngModelChange)="handleLastNamechange($event)"
                        placeholder="Last Name">
                        <div class="formLabel"

                        *ngIf="(erieForm.controls['9'].touched && erieForm.controls['9'].errors)
                        || (erieForm.controls['9'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['9'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <input formControlName="8" class="form-control" id="8" type="text"
                    [class.is-invalid]="(erieForm.controls['8'].touched && erieForm.controls['8'].errors) || (erieForm.controls['8'].errors && isSubmit)"
                    placeholder="First Name"
                    (ngModelChange)="handleFirstNamechange($event)"
                  >
                        <div class="formLabel"

                        *ngIf="(erieForm.controls['8'].touched && erieForm.controls['8'].errors) || (erieForm.controls['8'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['8'].errors">
                            required
                        </small>
                    </div>

                </div>
                <div class="col-md-2 mb-3">
                    <input formControlName="809" class="form-control" id="809" type="text"
                    (ngModelChange)="handleMiddlenamechange($event)"
                    [class.is-invalid]="(erieForm.controls['809'].touched && erieForm.controls['809'].errors)
                     || (erieForm.controls['809'].errors && isSubmit)"
                        placeholder="MI"  >

                        <div class="formLabel"

                        *ngIf="(erieForm.controls['809'].touched && erieForm.controls['809'].errors)
                        || (erieForm.controls['809'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['809'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                         <ng-select [items]="degree" bindLabel="name" placeholder="Select degree"
                            bindValue="id" formControlName="480"  >
                        </ng-select>
                        <div class="formLabel"

                        *ngIf="(erieForm.controls['480'].touched && erieForm.controls['480'].errors)
                         || (erieForm.controls['480'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['480'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3">
                    <label>List other names by which you have been known:
                    </label>
                </div>
                <div class="col-md-12 mb-3">
                    <input formControlName="278" class="form-control" id="278"  type="text"
                    [class.is-invalid]="(erieForm.controls['278'].touched && erieForm.controls['278'].errors)
                    || (erieForm.controls['278'].errors && isSubmit)"
                    (ngModelChange)="handlePriorNamechange($event)"
                    placeholder="Prior Name" >

                        <div class="formLabel"

                        *ngIf="(erieForm.controls['278'].touched && erieForm.controls['278'].errors)
                         || (erieForm.controls['278'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['278'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label>If you have been known by other names, please explain
                        why your name /changed:
                    </label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-12">
                    <input formControlName="2337"  class="form-control  " id="2337"
                        type="text" placeholder="Nickname"
                        [class.is-invalid]="(erieForm.controls['2337'].touched && erieForm.controls['2337'].errors)
                        || (erieForm.controls['2337'].errors && isSubmit)"
                        >
                    <div class="formLabel"

                    *ngIf="(erieForm.controls['2337'].touched && erieForm.controls['2337'].errors)
                     || (erieForm.controls['2337'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['2337'].errors">
                        required
                    </small>
                </div>
                </div>
            </div>
            <br />
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Birth date</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="31"  (blur)="clearInputIfInvalidMandi('31')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker #d2="ngbDatepicker"
                        [class.is-invalid]="(erieForm.controls['31'].touched && erieForm.controls['31'].errors)
                        || (erieForm.controls['31'].errors && isSubmit)"
                            container="body"
                            (ngModelChange)="handleDOBChanges($event)"
                             >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['31'].invalid&&erieForm.controls['31'].touched) && (erieForm.controls['31'].value==''|| erieForm.controls['31'].value==null) " >
                        <small >
                        required
                        </small>
      
                    </div>
                    <small class="text-danger" *ngIf="erieForm.get('31').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('31').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('31').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('31').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      
                </div>
                <div class="col-md-1 p-0">
                    <label>Place of Birth </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="810" class="form-control" id="810"  type="text"
                    [class.is-invalid]="(erieForm.controls['810'].touched && erieForm.controls['810'].errors)
                    || (erieForm.controls['810'].errors && isSubmit)"

                        placeholder="city">
                        <div class="formLabel"
                        *ngIf="(erieForm.controls['810'].touched && erieForm.controls['810'].errors)
                         || (erieForm.controls['810'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['810'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Birth State" bindValue="id"
                     [class.is-invalid]="(erieForm.controls['289'].touched && erieForm.controls['289'].errors)
                     || (erieForm.controls['289'].errors && isSubmit)"
                     formControlName="289">
                    </ng-select>
                    <div class="formLabel"
                    *ngIf="(erieForm.controls['289'].touched && erieForm.controls['289'].errors)
                     || (erieForm.controls['289'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['289'].errors">
                        required
                    </small>
                </div>
              </div>
                <div class="col-md-3">
                     <!-- <ng-select [items]="country" bindLabel="name" placeholder="Select Country" bindValue="id"
                    required   formControlName="291">
                    </ng-select> -->
                    <input formControlName="291" class="form-control" placeholder="birth country"
                    [class.is-invalid]="(erieForm.controls['291'].touched && erieForm.controls['291'].errors)
                    || (erieForm.controls['291'].errors && isSubmit)"
                        container="body">
                    <div class="formLabel"

                    *ngIf="(erieForm.controls['291'].touched && erieForm.controls['291'].errors) || (erieForm.controls['291'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['291'].errors">
                        required
                    </small>
                </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Sex:</label>
                </div>
                <div class="col-md-3">
                 <ng-select
                 [items]="genderDropdownData" bindLabel="name" placeholder="Select gender" bindValue="id"
                 formControlName="30"
                 (ngModelChange)="handlegenderchange($event)"
                 >
                    </ng-select>
                    <div class="formLabel"
                    *ngIf="(erieForm.controls['30'].touched && erieForm.controls['30'].errors)
                     || (erieForm.controls['30'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['30'].errors">
                        Choose one
                    </small>
                </div>
            </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3 pt-2">
                    <label>Language Fluency of Applicant:</label>
                </div>
                <div class="col-md-2 pt-2">
                    <input formControlName="cmd_33340" class="me-2" type="checkbox"  value="1">
                    <label for="cmd_33340">English</label>
                    <div class="formLabel"
                             *ngIf="(erieForm.controls['cmd_33340'].touched && erieForm.controls['cmd_33340'].errors) ||
                            (erieForm.controls['cmd_33340'].errors && isSubmit)">
                               <small class="text-danger" *ngIf="erieForm.controls['cmd_33340'].errors">
                                                required
                                            </small>
                                        </div>

                </div>
                <div class="col-md-2 pt-2">
                    <input formControlName="cmd_33341" class="me-2" type="checkbox"  value="1">
                    <label for="cmd_33341">Spanish</label>
                    <div class="formLabel"
                             *ngIf="(erieForm.controls['cmd_33341'].touched && erieForm.controls['cmd_33341'].errors) ||
                            (erieForm.controls['cmd_33341'].errors && isSubmit)">
                               <small class="text-danger" *ngIf="erieForm.controls['cmd_33341'].errors">
                                                required
                                            </small>
                                        </div>
                </div>
                <div class="col-md-1 pt-2">
                    <input formControlName="cmd_33342" class="me-2" type="checkbox"  value="1">
                    <label for="cmd_33342">other</label>
                    <div class="formLabel"
                    *ngIf="(erieForm.controls['cmd_33342'].touched && erieForm.controls['cmd_33342'].errors) ||
                   (erieForm.controls['cmd_33342'].errors && isSubmit)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33342'].errors">
                                       required
                                   </small>
                               </div>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="languageDropdownData" bindLabel="name" placeholder="Select language" bindValue="id"
                        formControlName="cmd_33343"
                        [class.is-invalid]="(erieForm.controls['cmd_33343'].touched && erieForm.controls['cmd_33343'].errors)
                        || (erieForm.controls['cmd_33343'].errors && isSubmit)" >
                    </ng-select>
        <div class="formLabel"
                    *ngIf="(erieForm.controls['cmd_33343'].touched && erieForm.controls['cmd_33343'].errors)
                     || (erieForm.controls['cmd_33343'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['cmd_33343'].errors">
                        Language field can not be empty
                    </small>
                </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label class="me-2">U.S. Citizen?</label>
                    <label class="checkbox-inline me-2">
                        <input formControlName="cmd_33344" type="radio"
                            value="0" (ngModelChange)="onUsCitizen($event)" > Yes</label>
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33344" type="radio" (ngModelChange)="onUsCitizen($event)"   value="1"> No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33344'].touched && erieForm.controls['cmd_33344'].errors) ||
                            (erieForm.controls['cmd_33344'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33344'].touched && erieForm.controls['cmd_33344'].errors) ||
                            (erieForm.controls['cmd_33344'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                     </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-7">
                    <label class="me-2">If no, do you have a legal right to reside
                        permanently and work in the U.S.?</label>
                    <label class="checkbox-inline me-2">
                        <input formControlName="cmd_33345" class="me-2" type="radio"
                            value="0"> Yes</label>
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33345" class="me-2" type="radio"  value="1"> No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33345'].touched && erieForm.controls['cmd_33345'].errors) ||
                            (erieForm.controls['cmd_33345'].errors  && isUsCitizen )">
                            <span *ngIf="(erieForm.controls['cmd_33345'].touched && erieForm.controls['cmd_33345'].errors) ||
                            (erieForm.controls['cmd_33345'].errors && isUsCitizen)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <table class="databox table-backround" >
                <tr>
                    <td colspan="12">
                        <div class="text-center">
                            <h6><b>CONFIDENTIAL
                                    INFORMATION</b></h6>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-3">
                                <label>Resident Visa No: </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="1584" class="form-control  " id="1584"
                                [class.is-invalid]="(erieForm.controls['1584'].touched
                                 && erieForm.controls['1584'].errors)
                        || (erieForm.controls['1584'].errors && isSubmit)"
                                    type="text"  placeholder="Visa No">

         <div class="formLabel"
         *ngIf="(erieForm.controls['1584'].touched && erieForm.controls['1584'].errors)
          || (erieForm.controls['1584'].errors && isSubmit)">
         <small class="text-danger" *ngIf="erieForm.controls['1584'].errors">
            required
         </small>
        </div>

                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-3">
                                <label>Social Security Number: </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="32" class="form-control  " id="32"  type="text"
                                     placeholder="SSN Number "
                                     (ngModelChange)="handleSsnchange($event)"
                                     [class.is-invalid]="(erieForm.controls['32'].touched && erieForm.controls['32'].errors)
                                     || (erieForm.controls['32'].errors && isSubmit)"  >


                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['32'].touched && erieForm.controls['32'].errors)
                                      || (erieForm.controls['32'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['32'].errors">
                                        required
                                     </small>
                                 </div>

                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row">
                            <div class="col-md-3">
                                <label>Emergency Contact Person:</label>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="2682" class="form-control  " id="2682"
                                [class.is-invalid]="(erieForm.controls['2682'].touched && erieForm.controls['2682'].errors)
                                || (erieForm.controls['2682'].errors && isSubmit)"
                                    type="text"  placeholder="Emergency Contact Name"
                                    >
                                    <div class="formLabel"
                                    *ngIf="(erieForm.controls['2682'].touched && erieForm.controls['2682'].errors)
                                     || (erieForm.controls['2682'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['2682'].errors">
                                        required
                                    </small>
                                </div>

                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label>Telephone Number:</label>
                            </div>
                            <div class="col-md-3">
                                <input formControlName="2631" class="form-control  " id="2631"
                                [class.is-invalid]="(erieForm.controls['2631'].touched && erieForm.controls['2631'].errors)
                                || (erieForm.controls['2631'].errors && isSubmit)"
                                    type="text"  placeholder="phoneNumber"
                                    >

         <div class="formLabel"
         *ngIf="(erieForm.controls['2631'].touched && erieForm.controls['2631'].errors)
          || (erieForm.controls['2631'].errors && isSubmit)">
         <small class="text-danger" *ngIf="erieForm.controls['2631'].errors">
            phone number be empty
         </small>
        </div>

                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="16" class="form-control  " id="16"  type="text"
                    [class.is-invalid]="(erieForm.controls['16'].touched && erieForm.controls['16'].errors)
                    || (erieForm.controls['16'].errors && isSubmit)"
                    (ngModelChange)="handleAddress1change($event)"
                        placeholder="address1" >
                        <div class="formLabel"
                        *ngIf="(erieForm.controls['16'].touched && erieForm.controls['16'].errors)
                         || (erieForm.controls['16'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['16'].errors">
                            required
                        </small>
                    </div>

                </div>
                <div class="col-md-2">
                    <input formControlName="17" class="form-control  " id="17"  type="text"
                    [class.is-invalid]="(erieForm.controls['17'].touched && erieForm.controls['17'].errors)
                    || (erieForm.controls['17'].errors && isSubmit)"
                    (ngModelChange)="handleAddress2change($event)"
                        placeholder="address2" >
                        <div class="formLabel"
                        *ngIf="(erieForm.controls['17'].touched && erieForm.controls['17'].errors)
                         || (erieForm.controls['17'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['17'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                    <input formControlName="18" class="form-control  " id="18"  type="text"

                    [class.is-invalid]="(erieForm.controls['18'].touched && erieForm.controls['18'].errors)
                    || (erieForm.controls['18'].errors && isSubmit)"
                    (ngModelChange)="handleCitychange($event)"
                        placeholder="city" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['18'].touched && erieForm.controls['18'].errors)
                         || (erieForm.controls['18'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['18'].errors">
                            required
                        </small>
                    </div>


                </div>
                <div class="col-md-2">
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select Birth State" bindValue="id"
                     [class.is-invalid]="(erieForm.controls['20'].touched && erieForm.controls['20'].errors)
                     || (erieForm.controls['20'].errors && isSubmit)"
                     (ngModelChange)="handleStatechange($event)"
                     formControlName="20">

                    </ng-select>
                  <div class="formLabel"
                    *ngIf="(erieForm.controls['20'].touched && erieForm.controls['20'].errors)
                     || (erieForm.controls['20'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['20'].errors">
                        required
                    </small>
                </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="21" class="form-control" id="21" type="text"
                    [class.is-invalid]="(erieForm.controls['21'].touched && erieForm.controls['21'].errors)
                    || (erieForm.controls['21'].errors && isSubmit)"
                        placeholder="Zip"
                        (ngModelChange)="handleZipchange($event)">
                   <div class="formLabel"
                        *ngIf="(erieForm.controls['21'].touched && erieForm.controls['21'].errors)
                         || (erieForm.controls['21'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['21'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Daytime Phone:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="708" class="form-control" id="708"  type="text"
                    [class.is-invalid]="(erieForm.controls['708'].touched && erieForm.controls['708'].errors)
                    || (erieForm.controls['708'].errors && isSubmit)"
                        placeholder="Daytime Phone"
                        (ngModelChange)="handleHomePhonechange($event)" >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['708'].touched && erieForm.controls['708'].errors)
                         || (erieForm.controls['708'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['708'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="710" class="form-control" id="710" type="text"
                    [class.is-invalid]="(erieForm.controls['710'].touched && erieForm.controls['710'].errors)
                    || (erieForm.controls['710'].errors && isSubmit)"
                        placeholder="Fax No" >


                          <div class="formLabel"
                        *ngIf="(erieForm.controls['710'].touched && erieForm.controls['710'].errors)
                         || (erieForm.controls['710'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['710'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-2">
                    <label> E-Mail Address: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="759" class="form-control" id="759"  type="text"
                        placeholder="e-mail"
                        [class.is-invalid]="(erieForm.controls['759'].touched && erieForm.controls['759'].errors)
                        || (erieForm.controls['759'].errors && isSubmit)"
                        (ngModelChange)="handleEmailChange($event)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['759'].touched && erieForm.controls['759'].errors)
                         || (erieForm.controls['759'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['759'].errors">
                            email address is required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> Check here if you have appended additional
                        information for this section:
                    </label>
                </div>
                <div class="col-2">
                <input formControlName="cmd_3334400" type="checkbox"   value="1">
                </div>
            </div>
            <br />
            <hr class="pdf-line">
            <br />
            <table class="dataTable">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="pt-2 text-center">
                            <h6><b>SECTION B. PROFESSIONAL INFORMATION</b></h6>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3">
                <div class="col-md-3">
                    <label> Illinois Professional License Number: </label>
                </div>
                <div class="col-md-6">
                    <input formControlName="293" class="form-control " id="293"  type="text"
                    [class.is-invalid]="(erieForm.controls['293'].touched && erieForm.controls['293'].errors)
                    || (erieForm.controls['293'].errors && isSubmit)"
                      >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['293'].touched && erieForm.controls['293'].errors)
                         || (erieForm.controls['293'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['293'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label>License Unlimited? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline me-2">
                        <input formControlName="cmd_33346" type="radio"
                            value="0"  (ngModelChange)="onLicenseUnlimited($event)">Yes</label>
                </div>
                <div class="mb-3  col-md-1" >
                    <label class="checkbox-inline me-2">
                        <input formControlName="cmd_33346" type="radio"
                        (ngModelChange)="onLicenseUnlimited($event)"  value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33346'].touched && erieForm.controls['cmd_33346'].errors) ||
                            (erieForm.controls['cmd_33346'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33346'].touched && erieForm.controls['cmd_33346'].errors) ||
                            (erieForm.controls['cmd_33346'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label>If No, please explain:</label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33347" class="form-control" id="cmd_33347"
                        type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33347'].touched && erieForm.controls['cmd_33347'].errors)
                        || (erieForm.controls['cmd_33347'].errors && islicenseUnlimited)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33347'].touched && erieForm.controls['cmd_33347'].errors)
                         || (erieForm.controls['cmd_33347'].errors && islicenseUnlimited)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33347'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-8">
                    <label> <b> Current and Previous Professional License(s) in
                            Other States</b></label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="altLicenseState" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="907">
                    </ng-select>
                </div>
                <div class="col-md-1">
                    <label>License #:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="300" class="form-control  " id="300" type="text"
                        >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="308" class="form-control" (blur)="clearInputIfInvalid('308')" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d3="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                   
                    </div>
                    <div  *ngIf="erieForm.get('308').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('308').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('308').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('308').errors?.invalidDateyear">
                          year is out of range(1900-2099)                    </small>
                          <small class="text-danger" *ngIf="erieForm.get('308').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                  
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>License Unlimited? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33348" type="radio"
                            value="0"  (ngModelChange)="onLicenseUnlimited1($event)">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33348"  (ngModelChange)="onLicenseUnlimited1($event)" type="radio"  value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33348'].touched && erieForm.controls['cmd_33348'].errors) ||
                            (erieForm.controls['cmd_33348'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33348'].touched && erieForm.controls['cmd_33348'].errors) ||
                            (erieForm.controls['cmd_33348'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label>If No, please explain:</label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33349" class="form-control " id="cmd_33349"
                        type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33349'].touched && erieForm.controls['cmd_33349'].errors)
                        || (erieForm.controls['cmd_33349'].errors && islicenseUnlimited1)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33349'].touched && erieForm.controls['cmd_33349'].errors)
                         || (erieForm.controls['cmd_33349'].errors && islicenseUnlimited1)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33349'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="additionalLicenseState" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="1010">
                    </ng-select>
                </div>
                <div class="col-md-1">
                    <label>License #:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="999" class="form-control  " id="999"  type="text"
                      >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="1004" (blur)="clearInputIfInvalid('1004')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d4="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1004').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1004').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1004').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1004').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1004').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>License Unlimited? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33350" type="radio"
                            value="0"  (ngModelChange)="onLicenseUnlimited2($event)" >Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33350"  (ngModelChange)="onLicenseUnlimited2($event)" type="radio"  value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33350'].touched && erieForm.controls['cmd_33350'].errors) ||
                            (erieForm.controls['cmd_33350'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33350'].touched && erieForm.controls['cmd_33350'].errors) ||
                                         (erieForm.controls['cmd_33350'].errors && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label>If No, please explain:</label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33351"  class="form-control " id="cmd_33351"
                        type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33351'].touched && erieForm.controls['cmd_33351'].errors)
                        || (erieForm.controls['cmd_33351'].errors && islicenseUnlimited2)" >

                <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33351'].touched && erieForm.controls['cmd_33351'].errors)
                         || (erieForm.controls['cmd_33351'].errors && islicenseUnlimited2)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33351'].errors">
                            required
                        </small>
                    </div>
                </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="cmd_998">
                    </ng-select>
                </div>
                <div class="col-md-1">
                    <label>License #:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="cmd_999" class="form-control  " id="cmd_999"  type="text"
                      >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_1004" (blur)="clearInputIfInvalid('cmd_1004')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d5="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_1004').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_1004').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_1004').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_1004').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_1004').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>License Unlimited? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33352" type="radio"
                            value="0" (ngModelChange)="onLicenseUnlimited3($event)">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33352" (ngModelChange)="onLicenseUnlimited3($event)" type="radio" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33352'].touched && erieForm.controls['cmd_33352'].errors) ||
                            (erieForm.controls['cmd_33352'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33352'].touched && erieForm.controls['cmd_33352'].errors) ||
                            (erieForm.controls['cmd_33352'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label>If No, please explain:</label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33353" style="width: 100%" class="form-control " id="cmd_33353"
                        type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33353'].touched && erieForm.controls['cmd_33353'].errors)
                        || (erieForm.controls['cmd_33353'].errors && islicenseUnlimited3)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33353'].touched && erieForm.controls['cmd_33353'].errors)
                         || (erieForm.controls['cmd_33353'].errors && islicenseUnlimited3)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33353'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-7">
                    <label> <b>Check here if you have appended additional
                        information for this section:</b>
                    </label>
                </div>
                <input formControlName="cmd_33354" type="checkbox" id="cmd_33354" value="1">
            </div>
            <table class="databox">
                <tr>
                        <td colspan="12" class="table-backround">
                            <div class="row pt-2 mb-3" >
                            <div class="col-md-4" >
                                <label ><b>Current Federal DEA License Number:
                                    </b></label>
                            </div>
                            <div class="col-md-4">
                                <input formControlName="309" style="width: 100%; background-color: white;"
                                    class="form-control" id="309" type="text"
                                    [class.is-invalid]="(erieForm.controls['309'].touched && erieForm.controls['309'].errors)
                                    || (erieForm.controls['309'].errors && isSubmit)" >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['309'].touched && erieForm.controls['309'].errors)
                         || (erieForm.controls['309'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['309'].errors">
                            DEA number can not be empty
                        </small>
                    </div>

                            </div>
                            <div class="col-md-4">
                                <label> <b>CONFIDENTIAL INFORMATION </b>
                                </label>
                            </div>
                        </div>
                        </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>DEA License Number Expiration Date:</label>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <input formControlName="311"  (blur)="clearInputIfInvalidMandi('311')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d6="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['311'].touched && erieForm.controls['311'].errors)
                            || (erieForm.controls['311'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['311'].invalid&&erieForm.controls['311'].touched) && (erieForm.controls['311'].value==''|| erieForm.controls['311'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('311').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('311').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('311').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('311').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>

                    <div class="col-md-3">
                        <label>License Unlimited? </label>
                    </div>
                    <div class="mb-3 col-md-1" >
                        <label class="checkbox-inline">
                            <input formControlName="cmd_33355" type="radio"
                                value="0" (ngModelChange)="onLicenseUnlimited4($event)">Yes</label>
                    </div>
                    <div class="mb-3 col-md-1" >
                        <label class="checkbox-inline">
                            <input formControlName="cmd_33355" (ngModelChange)="onLicenseUnlimited4($event)" type="radio"   value="1">No</label>
                    </div>
                    <div class="formLabel col-md-1"
                    *ngIf="(erieForm.controls['cmd_33355'].touched && erieForm.controls['cmd_33355'].errors) ||
                   (erieForm.controls['cmd_33355'].errors && isSubmit)">
                   <span *ngIf="(erieForm.controls['cmd_33355'].touched && erieForm.controls['cmd_33355'].errors) ||
                   (erieForm.controls['cmd_33355'].errors && isSubmit)">
                      <i class="fas fa-times ms-2 text-danger"></i>
                  </span>
                               </div>

            </div>
            <div class="row" >
                <div class="col-md-8">
                    <label>If No, please explain:</label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33356" style="width: 100%" class="form-control" id="cmd_33356"
                        type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33356'].touched && erieForm.controls['cmd_33356'].errors)
                        || (erieForm.controls['cmd_33356'].errors && islicenseUnlimited4)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33356'].touched && erieForm.controls['cmd_33356'].errors)
                         || (erieForm.controls['cmd_33356'].errors && islicenseUnlimited4)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33356'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3 ps-5 pt-2" >
                <div class="col-md-5">
                    <label><b> Check here if you have appended additional
                        information for this section:</b>
                    </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="cmd_33357" type="checkbox" id="cmd_33357"  value="1">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-7">
                    <label> <b>Current and Previous State Controlled Substance
                            Number(s):</b>
                    </label>
                </div>
            </div>
            <div class="col-md-12 " style="text-align:center">
                <label class="table-backround"><b>  CONFIDENTIAL
                        INFORMATION</b></label>
            </div>
            <div class="row" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="CdsStates" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="996">
                    </ng-select>
                </div>
                <div class="col-md-2 mb-3 table-backround">
                    <label >CS
                        License #:</label>
                    </div>

                    <div class="col-md-3 mb-3 table-backround">
                        <input formControlName="337" class="form-control  " id="337"  type="text"
                        [class.is-invalid]="(erieForm.controls['337'].touched && erieForm.controls['337'].errors)
                        || (erieForm.controls['337'].errors && isSubmit)"
                          >
           <div class="formLabel"
                          *ngIf="(erieForm.controls['337'].touched && erieForm.controls['337'].errors)
                           || (erieForm.controls['337'].errors && isSubmit)">
                          <small class="text-danger" *ngIf="erieForm.controls['337'].errors">

                              CDS number can not be empty
                          </small>
                      </div>

                    </div>

                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="339" (blur)="clearInputIfInvalid('339')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d7="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d7.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('339').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('339').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('339').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('339').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('339').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="AltCdsStates" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="997">
                    </ng-select>
                </div>
                <div class="col-md-2  mb-3 table-backround">
                    <label >CS
                        License #:</label>
                </div>
                <div class="col-md-3  mb-3 table-backround">
                    <input formControlName="340" class="form-control  " id="340" type="text"
                      >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="342" (blur)="clearInputIfInvalid('342')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d8="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d8.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('342').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('342').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('342').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('342').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('342').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="AddCdsStates" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="2260">
                    </ng-select>
                </div>
                <div class="col-md-2  mb-3 table-backround">
                    <label>CS License #:</label>
                </div>
                <div class="col-md-3  mb-3 table-backround">
                    <input formControlName="2254" class="form-control " id="2254" name="2254" type="text"
                        >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="2256" (blur)="clearInputIfInvalid('2256')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d9="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d9.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('2256').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('2256').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('2256').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('2256').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('2256').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-12">
                    <label> <b>Please identify all limitation related to the
                            above Controlled Substances Number(s) and explain limitation.</b>
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_3335811" style="width: 100%" class="form-control " id="cmd_3335811"
                        name="cmd_3335811" type="text">
                </div>
            </div>
        <br>
            <hr class="pdf-line">

            <div class="row mb-3" >
                <div class="col-md-4">
                    <label><b>Medicare Unique Provider ID# (UPIN):</b> </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="819" class="form-control  " id="819" name="819" type="text"
                    >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label><b>National Provider Identification Number (NPI):</b>
                    </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="1634" class="form-control " id="1634" name="1634" type="text"
                    [class.is-invalid]="(erieForm.controls['1634'].touched && erieForm.controls['1634'].errors)
                    || (erieForm.controls['1634'].errors && isSubmit)" >

        <div class="formLabel"
                    *ngIf="(erieForm.controls['1634'].touched && erieForm.controls['1634'].errors)
                     || (erieForm.controls['1634'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['1634'].errors">
                        required
                    </small>
                </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label><b>Medicaid ID#:</b> </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="812" class="form-control  " id="812" name="812" type="text"
                    [class.is-invalid]="(erieForm.controls['812'].touched && erieForm.controls['812'].errors)
                    || (erieForm.controls['812'].errors && isSubmit)"   >
         <div class="formLabel"
                        *ngIf="(erieForm.controls['812'].touched && erieForm.controls['812'].errors)
                         || (erieForm.controls['812'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['812'].errors">
                            Medicaid number can not be empty
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label><b>X-Ray Certification:</b> </label>
                </div>
                <div class="col-md-1">
                    <label>State:</label>
                </div>
                <div class="col-md-2">
                     <!-- <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State" bindValue="id"
                        formControlName="4298">
                    </ng-select> -->
                     <input formControlName="4298" class="form-control  " id="4298" name="4298" type="text">
                </div>
                <div class="col-md-1">
                    <label >Certificate#:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="3756" class="form-control  " id="3756" name="3756" type="text"
                      >
                </div>
                <div class="col-md-1">
                    <label>Exp.Date:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="3758" (blur)="clearInputIfInvalid('3758')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d10="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d10.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('3758').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('3758').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('3758').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('3758').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('3758').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <div class="row mb-3 pt-4" >
                <div class="col-md-7">
                    <label> <b>Check here if you have appended additional
                        information for this section:</b>
                    </label>
                </div>
                <input formControlName="cmd_1007" type="checkbox" id="cmd_1007" name="cmd_1007" value="1">
            </div>
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label ><b>COMPLETE FOR EACH
                                    SPECIALTY</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label><b>Specialty I:</b> </label>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="SELECT  SPECIALTY" bindValue="id"
                        formControlName="284"
                        [class.is-invalid]="(erieForm.controls['284'].touched && erieForm.controls['284'].errors)
                        || (erieForm.controls['284'].errors && isSubmit)" >
                    </ng-select>
                    <div class="formLabel"
                        *ngIf="(erieForm.controls['284'].touched && erieForm.controls['284'].errors)
                         || (erieForm.controls['284'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['284'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label>Are you Board Certified in Specialty I? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33359" type="radio" name="cmd_33359"
                            value="1" (ngModelChange)="onboardCertifiedSpecialty($event)">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33359" type="radio" (ngModelChange)="onboardCertifiedSpecialty($event)" name="cmd_33359" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33359'].touched && erieForm.controls['cmd_33359'].errors) ||
               (erieForm.controls['cmd_33359'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33359'].touched && erieForm.controls['cmd_33359'].errors) ||
               (erieForm.controls['cmd_33359'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>


            </div>

                <div class="row mb-3" >
                    <div class="col-md-3">
                        <label>If Yes, name of Certifying Board:</label>
                    </div>
                    <div class="col-md-4">
                         <ng-select [items]="boardCertification" bindLabel="name" placeholder="SELECT PRIMARY BOARD CERTIFICATON"
                            bindValue="id" formControlName="554"
                            [class.is-invalid]="(erieForm.controls['554'].touched && erieForm.controls['554'].errors)
                            || (erieForm.controls['554'].errors && isboardCertifiedSpecialty )" >
                        </ng-select>

         <div class="formLabel"
                        *ngIf="(erieForm.controls['554'].touched && erieForm.controls['554'].errors)
                         || (erieForm.controls['554'].errors && isboardCertifiedSpecialty)">
                        <small class="text-danger" *ngIf="erieForm.controls['554'].errors">
                            required
                        </small>
                    </div>
                    </div>
                </div>

            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Date of Certification:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="557"  (blur)="clearInputIfInvalidMandi('557')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d11="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['557'].touched && erieForm.controls['557'].errors)
                            || (erieForm.controls['557'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d11.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['557'].invalid&&erieForm.controls['557'].touched) && (erieForm.controls['557'].value==''|| erieForm.controls['557'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('557').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('557').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('557').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('557').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="col-md-3">
                    <label>Date of Recertification (if applicable): </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="559"  (blur)="clearInputIfInvalidMandi('559')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d12="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['559'].touched && erieForm.controls['559'].errors)
                            || (erieForm.controls['559'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d12.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['559'].invalid&&erieForm.controls['559'].touched) && (erieForm.controls['559'].value==''|| erieForm.controls['559'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('559').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('559').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('559').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('559').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> If No, have you taken or are you scheduled to take
                        the specialty boards certification? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33361" type="radio" name="cmd_33361"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33361" type="radio" name="cmd_33361" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33361'].touched && erieForm.controls['cmd_33361'].errors) ||
               (erieForm.controls['cmd_33361'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33361'].touched && erieForm.controls['cmd_33361'].errors) ||
               (erieForm.controls['cmd_33361'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>If Certifying Boards taken, give date: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1377"  (blur)="clearInputIfInvalidMandi('1377')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d13="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['1377'].touched && erieForm.controls['1377'].errors)
                            || (erieForm.controls['1377'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d13.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['1377'].invalid&&erieForm.controls['1377'].touched) && (erieForm.controls['1377'].value==''|| erieForm.controls['1377'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('1377').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('1377').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('1377').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('1377').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
                <div class="col-md-4">
                    <label>Certification Expiration Date, if Any: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="560"  (blur)="clearInputIfInvalidMandi('560')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d14="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['560'].touched && erieForm.controls['560'].errors)
                            || (erieForm.controls['560'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d14.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['560'].invalid&&erieForm.controls['560'].touched) && (erieForm.controls['560'].value==''|| erieForm.controls['560'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('560').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('560').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('560').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('560').errors?.invalidDateRange">
                          date is out of range
                          </small>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label> If not taken, date scheduled to take Specialty
                        Boards: </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_33358" (blur)="clearInputIfInvalidMandi('cmd_33358')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d15="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['cmd_33358'].touched && erieForm.controls['cmd_33358'].errors)
                            || (erieForm.controls['cmd_33358'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d15.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['cmd_33358'].invalid&&erieForm.controls['cmd_33358'].touched) && (erieForm.controls['cmd_33358'].value==''|| erieForm.controls['cmd_33358'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33358').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33358').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33358').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33358').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
            </div>
            <br />
            <div class="row mb-3 pt-4" >
                <div class="col-md-3">
                    <label><b>Specialty/Subspecialty II: </b> </label>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="specialtySecondary" bindLabel="name" placeholder="Select SECONDARY SPECILATY" bindValue="id"
                        formControlName="962"   [class.is-invalid]="(erieForm.controls['962'].touched && erieForm.controls['962'].errors)
                        || (erieForm.controls['962'].errors && isSubmit)" >
                    </ng-select>


         <div class="formLabel"
                        *ngIf="(erieForm.controls['962'].touched && erieForm.controls['962'].errors)
                         || (erieForm.controls['962'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['962'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label>Are you Board Certified in Specialty II? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33362" (ngModelChange)="onchangeaction20($event)"  type="radio" name="cmd_33362"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33362" type="radio" (ngModelChange)="onchangeaction20($event)"   name="cmd_33362" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33362'].touched && erieForm.controls['cmd_33362'].errors) ||
               (erieForm.controls['cmd_33362'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33362'].touched && erieForm.controls['cmd_33362'].errors) ||
               (erieForm.controls['cmd_33362'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>
            </div>

                <div class="row mb-3" >
                    <div class="col-md-3">
                        <label>If Yes, name of Certifying Board:</label>
                    </div>
                    <div class="col-md-4">
                         <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select SECONDARY BOARD CERTIFICATON"
                            bindValue="id" formControlName="561"
                            [class.is-invalid]="(erieForm.controls['561'].touched && erieForm.controls['561'].errors)
                            || (erieForm.controls['561'].errors && ischangeAction20)">
                        </ng-select>


         <div class="formLabel"
                        *ngIf="(erieForm.controls['561'].touched && erieForm.controls['561'].errors)
                         || (erieForm.controls['561'].errors && ischangeAction20)">
                        <small class="text-danger" *ngIf="erieForm.controls['561'].errors">
                            required
                        </small>
                    </div>
                    </div>
                </div>

            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Date of Certification:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="564" (blur)="clearInputIfInvalidMandi('564')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d16="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['564'].touched && erieForm.controls['564'].errors)
                            || (erieForm.controls['564'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d16.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['564'].invalid&&erieForm.controls['564'].touched) && (erieForm.controls['564'].value==''|| erieForm.controls['564'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('564').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('564').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('564').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('564').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="col-md-3">
                    <label>Date of Recertification (if applicable): </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="565" (blur)="clearInputIfInvalidMandi('565')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d17="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['565'].touched && erieForm.controls['565'].errors)
                            || (erieForm.controls['565'].errors && isSubmit)"
                >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d17.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['565'].invalid&&erieForm.controls['565'].touched) && (erieForm.controls['565'].value==''|| erieForm.controls['565'].value==null) " >
                        <small >
                        required
                        </small>
                    </div>
                    <small class="text-danger" *ngIf="erieForm.get('565').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('565').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('565').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('565').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> If No, have you taken or are you scheduled to take
                        the specialty boards certification? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33363" type="radio"  name="cmd_33363"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33363" type="radio" name="cmd_33363" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33363'].touched && erieForm.controls['cmd_33363'].errors) ||
               (erieForm.controls['cmd_33363'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33363'].touched && erieForm.controls['cmd_33363'].errors) ||
               (erieForm.controls['cmd_33363'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>If Certifying Boards taken, give date: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1378" (blur)="clearInputIfInvalidMandi('1378')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d18="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['1378'].touched && erieForm.controls['1378'].errors)
                            || (erieForm.controls['1378'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d18.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['1378'].invalid&&erieForm.controls['1378'].touched) && (erieForm.controls['1378'].value==''|| erieForm.controls['1378'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('1378').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('1378').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('1378').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('1378').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="col-md-4">
                    <label>Certification Expiration Date, if Any: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="567" (blur)="clearInputIfInvalidMandi('567')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d19="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['567'].touched && erieForm.controls['567'].errors)
                    || (erieForm.controls['567'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d19.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>


                    <div class="text-danger" *ngIf="(erieForm.controls['567'].invalid&&erieForm.controls['567'].touched) && (erieForm.controls['567'].value==''|| erieForm.controls['567'].value==null) " >
                        <small >
                        required
                        </small>
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('567').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('567').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('567').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('567').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label> If not taken, date scheduled to take Specialty
                        Boards: </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_33364" (blur)="clearInputIfInvalidMandi('cmd_33364')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d20="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['cmd_33364'].touched && erieForm.controls['cmd_33364'].errors)
                            || (erieForm.controls['cmd_33364'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d20.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['cmd_33364'].invalid&&erieForm.controls['cmd_33364'].touched) && (erieForm.controls['cmd_33364'].value==''|| erieForm.controls['cmd_33364'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33364').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33364').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33364').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33364').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
            </div>

            <hr class="pdf-line">
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label><b>Specialty/Subspecialty III:</b> </label>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select SPECILATY" bindValue="id"
                        formControlName="cmd_34106">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label>Are you Board Certified in Specialty III? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33365"  type="radio"  name="cmd_33365"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33365" type="radio"   name="cmd_33365" value="1">No</label>
                </div>
            </div>

                <div class="row mb-3" >
                    <div class="col-md-3">
                        <label>If Yes, name of Certifying Board:</label>
                    </div>
                    <div class="col-md-4">
                         <ng-select [items]="boardCertification" bindLabel="name" placeholder="Select THIRD BOARD CERTIFICATON"
                            bindValue="id" formControlName="cmd_34107"  >
                        </ng-select>

                    </div>
                    </div>


            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Date of Certification:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_34108" (blur)="clearInputIfInvalid('cmd_34108')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d21="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d21.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_34108').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34108').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34108').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34108').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_34108').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-3">
                    <label>Date of Recertification (if applicable): </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_34109" (blur)="clearInputIfInvalid('cmd_34109')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d22="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d22.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_34109').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34109').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34109').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34109').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_34109').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> If No, have you taken or are you scheduled to take
                        the specialty boards certification? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33366" type="radio"  name="cmd_33366"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33366" type="radio"  name="cmd_33366" value="1">No</label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>If Certifying Boards taken, give date: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="cmd_34110" (blur)="clearInputIfInvalid('cmd_34110')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d23="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d23.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_34110').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34110').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34110').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34110').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_34110').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-4">
                    <label>Certification Expiration Date, if Any: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="cmd_34111" (blur)="clearInputIfInvalid('cmd_34111')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d24="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_34111').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34111').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34111').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34111').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_34111').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label> If not taken, date scheduled to take Specialty
                        Boards: </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_33367" (blur)="clearInputIfInvalid('cmd_33367')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d25="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d25.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33367').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33367').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33367').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33367').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33367').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label><b>Specialty/Subspecialty IV:</b> </label>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select SPECILATY" bindValue="id"
                        formControlName="cmd_341112">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label>Are you Board Certified in Specialty IV? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33368" type="radio"  name="cmd_33368"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33368" type="radio"  name="cmd_33368" value="1">No</label>
                </div>
            </div>

                <div class="row mb-3" >
                    <div class="col-md-3">
                        <label>If Yes, name of Certifying Board:</label>
                    </div>
                    <div class="col-md-4">
                         <ng-select [items]="boardCertification" bindLabel="name" placeholder="SELECT SECONDARY BOARD CERTIFICATON"
                            bindValue="id" formControlName="cmd_341113">
                        </ng-select>
                    </div>
                </div>

            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Date of Certification:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_341114" (blur)="clearInputIfInvalid('cmd_341114')"   class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d26="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d26.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_341114').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341114').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341114').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341114').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_341114').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-3">
                    <label>Date of Recertification (if applicable): </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_341115" (blur)="clearInputIfInvalid('cmd_341115')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d27="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d27.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_341115').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341115').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341115').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341115').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_341115').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> If No, have you taken or are you scheduled to take
                        the specialty boards certification? </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33369" type="radio" name="cmd_33369"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33369" type="radio"  name="cmd_33369" value="1">No</label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>If Certifying Boards taken, give date: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="cmd_341116" (blur)="clearInputIfInvalid('cmd_341116')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d28="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d28.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_341116').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341116').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341116').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_341116').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_341116').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-4">
                    <label>Certification Expiration Date, if Any: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="cmd_34117" (blur)="clearInputIfInvalid('cmd_34117')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d29="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d29.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_34117').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34117').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34117').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_34117').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_34117').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-4">
                    <label> If not taken, date scheduled to take Specialty
                        Boards: </label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="cmd_33370" (blur)="clearInputIfInvalid('cmd_33370')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d30="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d30.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33370').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33370').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33370').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33370').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33370').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-6">
                    <label> Check here if you have appended additional
                        information for this section:
                    </label>
                </div>
                <input formControlName="cmd_33371" type="checkbox" id="cmd_33371" name="cmd_33371" value="1">
            </div>
            <hr class="pdf-line">
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label ><b>SECTION C.
                                    PROFESSIONAL LIABILITY INSURANCE</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="row mb-3 pt-4">
                <div class="col-md-12">
                    <h3 style="font-weight: normal;font-size: 14px;text-align:center;"><b> Please provide information on all
                            professional liability insurance carriers from whom you have received coverage in the past 10
                            years.</b>
                    </h3>
                </div>
            </div>
            <table class="databox" >
                <tr style="border:1px solid black">
                    <td colspan="12" class="table-backround" style="border: none;">
                        <div  class="text-center">
                            <label style="font-weight: normal;font-size: 14px;text-align:left;padding-bottom: 2px;"><b>CURRENT
                                    PROFESSIONAL LIABILITY INSURANCE</b></label>
                        </div>
                    </td>
                </tr>
                <tr style="border:1px solid black">
                    <td colspan="12" class="table-backround">
                        <div >
                            <label style="font-weight: normal;font-size: 14px;text-align:left ;padding-bottom: 2px;"><b>CONFIDENTIAL
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3">
                            <div class="col-md-2">
                                <label>Carrier </label>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="345" class="form-control  " id="345" name="345" type="text"
                                [class.is-invalid]="(erieForm.controls['345'].touched && erieForm.controls['345'].errors)
                                || (erieForm.controls['345'].errors && isSubmit)"  >
                                  <div class="formLabel"
                        *ngIf="(erieForm.controls['345'].touched && erieForm.controls['345'].errors)
                         || (erieForm.controls['345'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['345'].errors">
                            required
                        </small>
                    </div>


                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 " >
                            <div class="col-md-2">
                                <label>Address:</label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="901" class="form-control  " id="901" name="901" type="text"
                                    placeholder="address1"
                                    [class.is-invalid]="(erieForm.controls['901'].touched && erieForm.controls['901'].errors)
                                    || (erieForm.controls['901'].errors && isSubmit)"  >
         <div class="formLabel"
                        *ngIf="(erieForm.controls['901'].touched && erieForm.controls['901'].errors)
                         || (erieForm.controls['901'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['901'].errors">
                            required
                        </small>
                    </div>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="902" class="form-control  " id="902" name="902" type="text"
                                    placeholder="address2"
                                    [class.is-invalid]="(erieForm.controls['902'].touched && erieForm.controls['902'].errors)
                                    || (erieForm.controls['902'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['902'].touched && erieForm.controls['902'].errors)
                         || (erieForm.controls['902'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['902'].errors">
                           addresss 2cannot be empty
                        </small>
                    </div>


                            </div>
                            <div class="col-md-2">
                                <input formControlName="903" class="form-control  " id="903" name="903" type="text"
                                    placeholder="city"
                                    [class.is-invalid]="(erieForm.controls['903'].touched && erieForm.controls['903'].errors)
                                    || (erieForm.controls['903'].errors && isSubmit)"  >
                     <div class="formLabel"
                        *ngIf="(erieForm.controls['903'].touched && erieForm.controls['903'].errors)
                         || (erieForm.controls['903'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['903'].errors">
                            required
                        </small>
                    </div>


                            </div>
                            <div class="col-md-2" >
                                <div class="mb-3" >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                        bindValue="id" formControlName="905"
                                        [class.is-invalid]="(erieForm.controls['905'].touched && erieForm.controls['905'].errors)
                                        || (erieForm.controls['905'].errors && isSubmit)" >
                                    </ng-select>


         <div class="formLabel"
                        *ngIf="(erieForm.controls['905'].touched && erieForm.controls['905'].errors)
                         || (erieForm.controls['905'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['905'].errors">
                          required
                        </small>
                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="906" class="form-control" id="906" name="906" type="text"
                                    placeholder="zip"
                                    [class.is-invalid]="(erieForm.controls['906'].touched && erieForm.controls['906'].errors)
                        || (erieForm.controls['906'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['906'].touched && erieForm.controls['906'].errors)
                         || (erieForm.controls['906'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['906'].errors">
                            required
                        </small>
                    </div>
          </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 " >
                            <div class="col-md-2">
                                <label>Policy Number: </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="346" class="form-control  " id="346 " name="346" type="text"
                                     placeholder="Policy Number"
                                     [class.is-invalid]="(erieForm.controls['346'].touched && erieForm.controls['346'].errors)
                        || (erieForm.controls['346'].errors && isSubmit)" >
                    <div class="formLabel"
                        *ngIf="(erieForm.controls['346'].touched && erieForm.controls['346'].errors)
                         || (erieForm.controls['346'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['346'].errors">
                            required
                        </small>
                    </div>


                            </div>
                            <div class="col-md-2">
                                <label> Original Effective Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="348" (blur)="clearInputIfInvalidMandi('348')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                                        #d31="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['348'].touched && erieForm.controls['348'].errors)
                                        || (erieForm.controls['348'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d31.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="(erieForm.controls['348'].invalid&&erieForm.controls['348'].touched) && (erieForm.controls['348'].value==''|| erieForm.controls['348'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('348').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('348').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('348').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('348').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            </div>
                            <div class="col-md-2">
                                <label> Expiration Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="349" (blur)="clearInputIfInvalidMandi('349')" class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d32="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['349'].touched && erieForm.controls['349'].errors)
                                        || (erieForm.controls['349'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d32.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="(erieForm.controls['349'].invalid&&erieForm.controls['349'].touched) && (erieForm.controls['349'].value==''|| erieForm.controls['349'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('349').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('349').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('349').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('349').errors?.invalidDateRange">
                                      date is out of range
                                      </small>


                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 " >
                            <div class="col-md-2">
                                <label>Policy Limits: </label>
                            </div>
                            <div class="col-md-2">
                                <label> Per Occurrence: $</label>
                            </div>
                            <div class="col-md-3">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim"
                      bindValue="id" formControlName="350"
                        [class.is-invalid]="(erieForm.controls['350'].touched && erieForm.controls['350'].errors)
                                || (erieForm.controls['350'].errors && isSubmit)" >
                  </ng-select>
         <div class="formLabel"
                        *ngIf="(erieForm.controls['350'].touched && erieForm.controls['350'].errors)
                         || (erieForm.controls['350'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['350'].errors">
                            required
                        </small>
                    </div>


                            </div>
                            <div class="col-md-2">
                                <label> Aggregate: $ </label>
                            </div>
                            <div class="col-md-3">
                                 <ng-select [items]="aggregates" bindLabel="name"
                      placeholder="Aggregate " bindValue="id" formControlName="351"
                       [class.is-invalid]="(erieForm.controls['351'].touched && erieForm.controls['351'].errors)
                                || (erieForm.controls['351'].errors && isSubmit)">
                  </ng-select>
         <div class="formLabel"
                        *ngIf="(erieForm.controls['351'].touched && erieForm.controls['351'].errors)
                         || (erieForm.controls['351'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['351'].errors">
                            required
                        </small>
                    </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Retroactive Date: </label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input formControlName="945" (blur)="clearInputIfInvalidMandi('945')" class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d33="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['945'].touched && erieForm.controls['945'].errors)
                                        || (erieForm.controls['945'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d33.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="(erieForm.controls['945'].invalid&&erieForm.controls['945'].touched) && (erieForm.controls['945'].value==''|| erieForm.controls['945'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('945').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('945').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('945').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('945').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                      </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-4">
                                <label>What type of coverage do you have?
                                </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33372" type="checkbox" id="cmd_33372" name="cmd_33372"
                                    value="1">
                                <label>Claims Made </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33373" type="checkbox" id="cmd_33373" name="cmd_33373"
                                    value="1">
                                <label>Occurrence </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-7">
                                <label>Has any judgment or payment of claim or
                                    settlement amount exceeded the limits of this coverage?
                                </label>
                            </div>
                            <div class="mb-3 col-md-1" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33374" type="radio"  name="cmd_33374"
                                        value="0">Yes</label>
                            </div>
                            <div class="mb-3 col-md-1" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33374" type="radio"  name="cmd_33374"
                                        value="1">No</label>
                            </div>
                            <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33374'].touched && erieForm.controls['cmd_33374'].errors) ||
                            (erieForm.controls['cmd_33374'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33374'].touched && erieForm.controls['cmd_33374'].errors) ||
                            (erieForm.controls['cmd_33374'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

                        </div>
                    </td>
                </tr>
            </table>
<div class="pt-4">
    <table class="databox pt-4">
        <tr style=" border: 1px solid black;">
            <td colspan="12" class="table-backround">
                <div >
                    <label style="font-weight: normal;font-size: 14px;text-align:left;padding-bottom: 2px;"><b>PREVIOUS
                            PROFESSIONAL LIABILITY INSURANCE</b></label>
                </div>
            </td>
        </tr>
        <tr style=" border: 1px solid black;">
            <td colspan="12" class="table-backround">
                <div >
                    <label style="font-weight: normal;font-size: 14px;text-align:left ;padding-bottom: 2px;"><b>CONFIDENTIAL
                            INFORMATION</b></label>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3" >
                    <div class="col-md-2">
                        <label>Carrier </label>
                    </div>
                    <div class="col-md-3">
                        <input formControlName="1242" class="form-control  " id="1242" name="1242" type="text"
                        [class.is-invalid]="(erieForm.controls['1242'].touched && erieForm.controls['1242'].errors)
                           || (erieForm.controls['1242'].errors && isSubmit)" >


            <div class="formLabel"
                           *ngIf="(erieForm.controls['1242'].touched && erieForm.controls['1242'].errors)
                            || (erieForm.controls['1242'].errors && isSubmit)">
                           <small class="text-danger" *ngIf="erieForm.controls['1242'].errors">
                            required
                           </small>
                       </div>

                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround" style="border: none;">
                <div class="row mb-3 " >
                    <div class="col-md-2">
                        <label>Address:</label>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="1244" class="form-control  " id="1244" name="1244"
                            type="text" placeholder="address1"
                            [class.is-invalid]="(erieForm.controls['1244'].touched && erieForm.controls['1244'].errors)
                            || (erieForm.controls['1244'].errors && isSubmit)"   >


             <div class="formLabel"
                            *ngIf="(erieForm.controls['1244'].touched && erieForm.controls['1244'].errors)
                             || (erieForm.controls['1244'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1244'].errors">
                                required
                            </small>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <input formControlName="1245" class="form-control  " id="1245" name="1245"
                            type="text" placeholder="address2"  [class.is-invalid]="(erieForm.controls['1245'].touched && erieForm.controls['1245'].errors)
                            || (erieForm.controls['1245'].errors && isSubmit)"   >


             <div class="formLabel"
                            *ngIf="(erieForm.controls['1245'].touched && erieForm.controls['1245'].errors)
                             || (erieForm.controls['1245'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1245'].errors">
                                required
                            </small>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <input formControlName="1246" class="form-control  " id="1246" name="1246"
                            type="text" placeholder="city" [class.is-invalid]="(erieForm.controls['1246'].touched && erieForm.controls['1246'].errors)
                            || (erieForm.controls['1246'].errors && isSubmit)"   >
             <div class="formLabel"
                            *ngIf="(erieForm.controls['1246'].touched && erieForm.controls['1246'].errors)
                             || (erieForm.controls['1246'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1246'].errors">
                                required
                            </small>
                        </div>

                    </div>
                    <div class="col-md-2" style="padding-top:1px">

                             <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                bindValue="id" formControlName="1248"
                                [class.is-invalid]="(erieForm.controls['1248'].touched && erieForm.controls['1248'].errors)
                                || (erieForm.controls['1248'].errors && isSubmit)" >
                            </ng-select>

             <div class="formLabel"
                            *ngIf="(erieForm.controls['1248'].touched && erieForm.controls['1248'].errors)
                             || (erieForm.controls['1248'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1248'].errors">
                                required
                            </small>


                        </div>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="1249" class="form-control  " id="1249" name="1249"
                            type="text" placeholder="zip"
                            [class.is-invalid]="(erieForm.controls['1249'].touched && erieForm.controls['1249'].errors)
                            || (erieForm.controls['1249'].errors && isSubmit)"   >

            <div class="formLabel"
                           *ngIf="(erieForm.controls['1249'].touched && erieForm.controls['1249'].errors)
                            || (erieForm.controls['1249'].errors && isSubmit)">
                           <small class="text-danger" *ngIf="erieForm.controls['1249'].errors">
                               required
                           </small>
                       </div>

                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3 " >
                    <div class="col-md-2">
                        <label>Policy Number: </label>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="1256" class="form-control  " id="1256" name="1256"
                            type="text"  placeholder="Policy Number"
                            [class.is-invalid]="(erieForm.controls['1256'].touched && erieForm.controls['1256'].errors)
                            || (erieForm.controls['1256'].errors && isSubmit)" >

             <div class="formLabel"
                            *ngIf="(erieForm.controls['1256'].touched && erieForm.controls['1256'].errors)
                             || (erieForm.controls['1256'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1256'].errors">
                                required
                            </small>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <label> Original Effective Date: </label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input formControlName="1252" (blur)="clearInputIfInvalidMandi('1252')" class="form-control" placeholder="mm/dd/yyyy"
                                ngbDatepicker #d34="ngbDatepicker" container="body"
                                [class.is-invalid]="(erieForm.controls['1252'].touched && erieForm.controls['1252'].errors)
                                || (erieForm.controls['1252'].errors && isSubmit)">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d34.toggle()"
                                    type="button"><i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="(erieForm.controls['1252'].invalid&&erieForm.controls['1252'].touched) && (erieForm.controls['1252'].value==''|| erieForm.controls['1252'].value==null) " >
                            <small >
                            required
                            </small>
                          
                          </div>
                          <small class="text-danger" *ngIf="erieForm.get('1252').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="erieForm.get('1252').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="erieForm.get('1252').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="erieForm.get('1252').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                    <div class="col-md-2">
                        <label> Expiration Date: </label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input formControlName="1253" (blur)="clearInputIfInvalidMandi('1253')" class="form-control" placeholder="mm/dd/yyyy"
                                ngbDatepicker #d35="ngbDatepicker" container="body"
                                [class.is-invalid]="(erieForm.controls['1253'].touched && erieForm.controls['1253'].errors)
                                || (erieForm.controls['1253'].errors && isSubmit)" >
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d35.toggle()"
                                    type="button"><i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="(erieForm.controls['1253'].invalid&&erieForm.controls['1253'].touched) && (erieForm.controls['1253'].value==''|| erieForm.controls['1253'].value==null) " >
                            <small >
                            required
                            </small>
                          
                          </div>
                          <small class="text-danger" *ngIf="erieForm.get('1253').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="erieForm.get('1253').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="erieForm.get('1253').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="erieForm.get('1253').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3 " >
                    <div class="col-md-2">
                        <label>Policy Limits: </label>
                    </div>
                    <div class="col-md-2">
                        <label> Per Occurrence: $</label>
                    </div>
                    <div class="col-md-3">

                            <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim "
                      bindValue="id" formControlName="1264"
                      [class.is-invalid]="(erieForm.controls['1264'].touched && erieForm.controls['1264'].errors)
                            || (erieForm.controls['1264'].errors && isSubmit)">
                  </ng-select>

 <div class="formLabel"
                *ngIf="(erieForm.controls['1264'].touched && erieForm.controls['1264'].errors)
                 || (erieForm.controls['1264'].errors && isSubmit)">
                <small class="text-danger" *ngIf="erieForm.controls['1264'].errors">
                    required
                </small>
            </div>

                    </div>
                    <div class="col-md-2">
                        <label> Aggregate: $ </label>
                    </div>
                    <div class="col-md-3">
                        <ng-select [items]="aggregates" bindLabel="name"
                      placeholder="Aggregate " bindValue="id" formControlName="1265"
                        [class.is-invalid]="(erieForm.controls['1265'].touched && erieForm.controls['1265'].errors)
                            || (erieForm.controls['1265'].errors && isSubmit)">
                  </ng-select>

             <div class="formLabel"
                            *ngIf="(erieForm.controls['1265'].touched && erieForm.controls['1265'].errors)
                             || (erieForm.controls['1265'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['1265'].errors">
                                required
                            </small>
                        </div>

                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3" >
                    <div class="col-md-2">
                        <label>Retroactive Date: </label>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group">
                            <input formControlName="1251" (blur)="clearInputIfInvalidMandi('1251')" class="form-control" placeholder="mm/dd/yyyy"
                                ngbDatepicker #d36="ngbDatepicker" container="body"
                                [class.is-invalid]="(erieForm.controls['1251'].touched && erieForm.controls['1251'].errors)
                                || (erieForm.controls['1251'].errors && isSubmit)" >
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d36.toggle()"
                                    type="button"><i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>


                        <div class="text-danger" *ngIf="(erieForm.controls['1251'].invalid&&erieForm.controls['1251'].touched) && (erieForm.controls['1251'].value==''|| erieForm.controls['1251'].value==null) " >
                            <small >
                            required
                            </small>
                          
                          </div>
                          <small class="text-danger" *ngIf="erieForm.get('1251').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="erieForm.get('1251').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="erieForm.get('1251').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="erieForm.get('1251').errors?.invalidDateRange">
                              date is out of range
                              </small>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3" >
                    <div class="col-md-4">
                        <label>What type of coverage do you have?
                        </label>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="cmd_1000" type="checkbox" id="cmd_1000" name="cmd_1000" value="1">
                        <label>Claims Made </label>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="cmd_33375" type="checkbox" id="cmd_33375" name="cmd_33375"
                            value="1">
                        <label>Occurrence </label>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="12" class="table-backround">
                <div class="row mb-3" >
                    <div class="col-md-7">
                        <label>Has any judgment or payment of claim or
                            settlement amount exceeded the limits of this coverage?
                        </label>
                    </div>
                    <div class="mb-3 col-md-1" >
                        <label class="checkbox-inline">
                            <input formControlName="cmd_33376" type="radio"  name="cmd_33376"
                                value="0">Yes</label>
                    </div>
                    <div class="mb-3 col-md-1" >
                        <label class="checkbox-inline">
                            <input formControlName="cmd_33376" type="radio"  name="cmd_33376"
                                value="1">No</label>
                    </div>
                    <div class="formLabel col-md-1"
                     *ngIf="(erieForm.controls['cmd_33376'].touched && erieForm.controls['cmd_33376'].errors) ||
                    (erieForm.controls['cmd_33376'].errors && isSubmit)">
                    <span *ngIf="(erieForm.controls['cmd_33376'].touched && erieForm.controls['cmd_33376'].errors) ||
                    (erieForm.controls['cmd_33376'].errors && isSubmit)">
                       <i class="fas fa-times ms-2 text-danger"></i>
                   </span>
                                </div>


                </div>
            </td>
        </tr>
    </table>
</div>

            <hr class="pdf-line">
            <table class="databox">
                <tr style=" border: 1px solid black;">
                    <td colspan="12" class="table-backround">
                        <div >
                            <label style="font-weight: normal;font-size: 14px;text-align:left;padding-bottom: 2px;"><b>PREVIOUS
                                    PROFESSIONAL LIABILITY INSURANCE</b></label>
                        </div>
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td colspan="12" class="table-backround">
                        <div >
                            <label style="font-weight: normal;font-size: 14px;text-align:left ;padding-bottom: 2px;"><b>CONFIDENTIAL
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Carrier </label>
                            </div>
                            <div class="col-md-3">
                                <input formControlName="cmd_33377" class="form-control  " id="cmd_33377"
                                    name="cmd_33377" type="text">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Address:</label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33378" class="form-control  " id="cmd_33378"
                                    name="cmd_33378" type="text" placeholder="address1"
                                    >
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33379" class="form-control  " id="cmd_33379"
                                    name="cmd_33379" type="text" placeholder="address2"
                                    >
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33380" class="form-control  " id="cmd_33380"
                                    name="cmd_33380" type="text" placeholder="city"
                                    >
                            </div>
                            <div class="col-md-2" >
                                <div class="mb-3  " >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                        bindValue="id" formControlName="cmd_33381">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33382" class="form-control  " id="cmd_33382"
                                    name="cmd_33382" type="text" placeholder="zip"
                                   >
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Policy Number: </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33383" class="form-control  " id="cmd_33383"
                                    name="cmd_33383" type="text"  placeholder="Policy Number"
                                   >
                            </div>
                            <div class="col-md-2">
                                <label> Original Effective Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="cmd_33384" (blur)="clearInputIfInvalid('cmd_33384')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d37="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d37.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33384').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33384').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33384').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33384').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33384').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="col-md-2">
                                <label> Expiration Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="cmd_33385" (blur)="clearInputIfInvalid('cmd_33385')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d38="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d38.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33385').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33385').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33385').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33385').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33385').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Policy Limits: </label>
                            </div>
                            <div class="col-md-2">
                                <label> Per Occurrence: $</label>
                            </div>
                            <div class="col-md-3">

                                    <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim "
                      bindValue="id" formControlName="cmd_33386">
                  </ng-select>

                            </div>
                            <div class="col-md-2">
                                <label> Aggregate: $ </label>
                            </div>
                            <div class="col-md-3">

                                    <ng-select [items]="aggregates" bindLabel="name"
                      placeholder="Aggregate " bindValue="id" formControlName="cmd_33387">
                  </ng-select>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Retroactive Date: </label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input formControlName="cmd_33388" (blur)="clearInputIfInvalid('cmd_33388')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d39="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d39.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33388').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33388').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33388').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33388').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33388').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-4">
                                <label>What type of coverage do you have?
                                </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33389" type="checkbox" id="cmd_33389" name="cmd_33389"
                                    value="1">
                                <label>Claims Made </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33390" type="checkbox" id="cmd_33390" name="cmd_33390"
                                    value="1">
                                <label>Occurrence </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-7">
                                <label>Has any judgment or payment of claim or
                                    settlement amount exceeded the limits of this coverage?
                                </label>
                            </div>
                            <div class="mb-3 col-md-2" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33391" type="radio"  name="cmd_33391"
                                        value="0">Yes</label>
                            </div>
                            <div class="mb-3 col-md-1" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33391" type="radio" name="cmd_33391"
                                        value="1">No</label>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="pt-4">
            <table class="databox pt-4">
                <tr style=" border: 1px solid black;">
                    <td colspan="12" class="table-backround">
                        <div >
                            <label style="font-weight: normal;font-size: 14px;text-align:left;padding-bottom: 2px;"><b>PREVIOUS
                                    PROFESSIONAL LIABILITY INSURANCE</b></label>
                        </div>
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td colspan="12" class="table-backround">
                        <div >
                            <label style="font-weight: normal;font-size: 14px;text-align:left ;padding-bottom: 2px;"><b>CONFIDENTIAL
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Carrier </label>
                            </div>
                            <div class="col-md-3">
                                <input formControlName="cmd_33392" class="form-control  " id="cmd_33392"
                                    name="cmd_33392" type="text">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Address:</label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33393" class="form-control  " id="cmd_33393"
                                    name="cmd_33393" type="text" placeholder="address1"
                                    >
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33394" class="form-control  " id="cmd_33394"
                                    name="cmd_33394" type="text" placeholder="address2"
                                    >
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33395" class="form-control  " id="cmd_33395"
                                    name="cmd_33395" type="text" placeholder="city"
                                    >
                            </div>
                            <div class="col-md-2" style="padding-top:1px">
                                <div class="mb-3  " >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                        bindValue="id" formControlName="cmd_33396">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33397" class="form-control  " id="cmd_33397"
                                    name="cmd_33397" type="text" placeholder="zip"
                                   >
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Policy Number: </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33398" class="form-control  " id="cmd_33398"
                                    name="cmd_33398" type="text"  placeholder="Policy Number"
                                   >
                            </div>
                            <div class="col-md-2">
                                <label> Original Effective Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="cmd_33399" (blur)="clearInputIfInvalid('cmd_33399')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d40="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d40.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33399').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33399').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33399').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33399').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33399').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                            <div class="col-md-2">
                                <label> Expiration Date: </label>
                            </div>
                            <div class="col-md-2">
                                <div class="input-group">
                                    <input formControlName="cmd_33400" (blur)="clearInputIfInvalid('cmd_33400')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d41="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d41.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33400').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33400').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33400').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33400').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33400').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Policy Limits: </label>
                            </div>
                            <div class="col-md-2">
                                <label> Per Occurrence: $</label>
                            </div>
                            <div class="col-md-3">
                                <ng-select [items]="preClaim" bindLabel="name" placeholder="PerClaim"
                      bindValue="id" formControlName="cmd_33401">
                  </ng-select>

                            </div>
                            <div class="col-md-2">
                                <label> Aggregate: $ </label>
                            </div>
                            <div class="col-md-3">
                                 <ng-select [items]="aggregates" bindLabel="name"
                      placeholder="Aggregate" bindValue="id" formControlName="cmd_33402">
                  </ng-select>

                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-2">
                                <label>Retroactive Date: </label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <input formControlName="cmd_33403" (blur)="clearInputIfInvalid('cmd_33403')"  class="form-control" placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d42="ngbDatepicker" container="body">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d42.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div  *ngIf="erieForm.get('cmd_33403').invalid ">
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33403').errors?.invalidDateFormat">
                                      Only Date Format is allowed(MM/DD/YYYY)
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33403').errors?.invalidMonth">
                                    Month is out of range
                                    </small>
                                    <small class="text-danger" *ngIf="erieForm.get('cmd_33403').errors?.invalidDateyear">
                                      year is out of range(1900-2099)                      </small>
                                      <small class="text-danger" *ngIf="erieForm.get('cmd_33403').errors?.invalidDateRange">
                                        date is out of range
                                        </small>
                                    </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-4">
                                <label>What type of coverage do you have?
                                </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_1001" type="checkbox" id="cmd_1001" name="cmd_1001" value="1">
                                <label>Claims Made </label>
                            </div>
                            <div class="col-md-2">
                                <input formControlName="cmd_33404" type="checkbox" id="cmd_33404" name="cmd_33404"
                                    value="1">
                                <label>Occurrence </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3" >
                            <div class="col-md-7">
                                <label>Has any judgment or payment of claim or
                                    settlement amount exceeded the limits of this coverage?
                                </label>
                            </div>
                            <div class="mb-3 col-md-2" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33405" type="radio"  name="cmd_33405"
                                        value="0">Yes</label>
                            </div>
                            <div class="mb-3 col-md-1" >
                                <label class="checkbox-inline">
                                    <input formControlName="cmd_33405" type="radio" name="cmd_33405"
                                        value="1">No</label>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
            <br />
            <hr class="pdf-line">
            <br />
            <br />
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label ><b>SECTION D. EDUCATION
                                    AND TRAINING</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="col-md-12">
                <h5 class="text-center"><b> If any gaps in your training (greater
                        than 30 days), or if you have not completed any portion of your training, please explain on a
                        separate sheet of paper and attach to this application. </b>
                </h5>
            </div>
            <br />
            <br />
            <table class="databox"  >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-left ">
                            <label ><b>MEDICAL/PROFESSIONAL
                                    SCHOOL</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="490" class="form-control  " id="490" name="490" type="text"
                        placeholder=" Medical School Name"
                        [class.is-invalid]="(erieForm.controls['490'].touched && erieForm.controls['490'].errors)
                        || (erieForm.controls['490'].errors && isSubmit)" >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['490'].touched && erieForm.controls['490'].errors)
                         || (erieForm.controls['490'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['490'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="491" class="form-control  " id="491" name="491" type="text"
                        placeholder="address1"  [class.is-invalid]="(erieForm.controls['491'].touched && erieForm.controls['491'].errors)
                        || (erieForm.controls['491'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['491'].touched && erieForm.controls['491'].errors)
                         || (erieForm.controls['491'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['491'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="492" class="form-control  " id="492" name="492" type="text"
                        placeholder="address2"
                        [class.is-invalid]="(erieForm.controls['492'].touched && erieForm.controls['492'].errors)
                        || (erieForm.controls['492'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['492'].touched && erieForm.controls['492'].errors)
                         || (erieForm.controls['492'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['492'].errors">
                            required
                        </small>
                    </div>


                </div>
                <div class="col-md-2">
                    <input formControlName="493" class="form-control  " id="493" name="493" type="text"
                        placeholder="city"
                        [class.is-invalid]="(erieForm.controls['493'].touched && erieForm.controls['493'].errors)
                        || (erieForm.controls['493'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['493'].touched && erieForm.controls['493'].errors)
                         || (erieForm.controls['493'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['493'].errors">
                            required
                        </small>
                    </div>


                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="495"
                             [class.is-invalid]="(erieForm.controls['495'].touched && erieForm.controls['495'].errors)
                            || (erieForm.controls['495'].errors && isSubmit)" >
                        </ng-select>


         <div class="formLabel"
                        *ngIf="(erieForm.controls['495'].touched && erieForm.controls['495'].errors)
                         || (erieForm.controls['495'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['495'].errors">
                          required
                        </small>
                    </div>


                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="496" class="form-control  " id="496" name="496" type="text"
                        placeholder="zip"
                        [class.is-invalid]="(erieForm.controls['496'].touched && erieForm.controls['496'].errors)
                        || (erieForm.controls['496'].errors && isSubmit)" >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['496'].touched && erieForm.controls['496'].errors)
                         || (erieForm.controls['496'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['496'].errors">
                            required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="2351" class="form-control  " id="2351" name="2351" type="text"
                        placeholder="Phone"
                        [class.is-invalid]="(erieForm.controls['2351'].touched && erieForm.controls['2351'].errors)
                        || (erieForm.controls['2351'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['2351'].touched && erieForm.controls['2351'].errors)
                         || (erieForm.controls['2351'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['2351'].errors">
                            required
                        </small>
                    </div>


                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="2352" class="form-control  " id="2352" name="2352" type="text"
                        placeholder="Fax No"
                        [class.is-invalid]="(erieForm.controls['2352'].touched && erieForm.controls['2352'].errors)
                        || (erieForm.controls['2352'].errors && isSubmit)"  >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['2352'].touched && erieForm.controls['2352'].errors)
                         || (erieForm.controls['2352'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['2352'].errors">
                            required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Degree: </label>
                </div>
                <div class="col-md-3">
                     <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree" bindValue="id"
                        formControlName="500"
                        [class.is-invalid]="(erieForm.controls['500'].touched && erieForm.controls['500'].errors)
                        || (erieForm.controls['500'].errors && isSubmit)" >
                    </ng-select>


        <div class="formLabel"
                    *ngIf="(erieForm.controls['500'].touched && erieForm.controls['500'].errors)
                     || (erieForm.controls['500'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['500'].errors">
                        required
                    </small>
                </div>


                </div>
                <div class="col-md-2">
                    <label>Year Graduated:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="499" (blur)="clearInputIfInvalidMandi('499')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d1="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['499'].touched && erieForm.controls['499'].errors)
                            || (erieForm.controls['499'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['499'].invalid&&erieForm.controls['499'].touched) && (erieForm.controls['499'].value==''|| erieForm.controls['499'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('499').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('499').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('499').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('499').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="497" (blur)="clearInputIfInvalidMandi('497')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d43="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['497'].touched && erieForm.controls['497'].errors)
                            || (erieForm.controls['497'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d43.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['497'].invalid&&erieForm.controls['497'].touched) && (erieForm.controls['497'].value==''|| erieForm.controls['497'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('497').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('497').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('497').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('497').errors?.invalidDateRange">
                          date is out of range
                          </small>


                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="498" (blur)="clearInputIfInvalidMandi('498')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d44="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['498'].touched && erieForm.controls['498'].errors)
                            || (erieForm.controls['498'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d44.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>


                    <div class="text-danger" *ngIf="(erieForm.controls['498'].invalid&&erieForm.controls['498'].touched) && (erieForm.controls['498'].value==''|| erieForm.controls['498'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('498').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('498').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('498').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('498').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label> If you are a graduate of a foreign medical school,
                        are you certified by the Educational Commission for Foreign Medical Graduates (ECFMG)?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33406" type="radio"  name="cmd_33406"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33406" type="radio"  name="cmd_33406" o
                            value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33406'].touched && erieForm.controls['cmd_33406'].errors) ||
               (erieForm.controls['cmd_33406'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33406'].touched && erieForm.controls['cmd_33406'].errors) ||
               (erieForm.controls['cmd_33406'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                </div>
                <div class="col-md-2">
                    <label>Date Issued: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="515" (blur)="clearInputIfInvalidMandi('515')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d45="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['515'].touched && erieForm.controls['515'].errors)
                    || (erieForm.controls['515'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d45.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['515'].invalid&&erieForm.controls['515'].touched) && (erieForm.controls['515'].value==''|| erieForm.controls['515'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('515').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('515').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('515').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('515').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
                <div class="col-md-2">
                    <label>Serial Number for ECFMG:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="514" class="form-control  " id="514" name="514" type="text"
                        placeholder="ECFMG number"
                        [class.is-invalid]="(erieForm.controls['514'].touched && erieForm.controls['514'].errors)
                        || (erieForm.controls['514'].errors && isSubmit)"  >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['514'].touched && erieForm.controls['514'].errors)
                         || (erieForm.controls['514'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['514'].errors">
                       required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33407" (ngModelChange)="onchangeaction1($event)" type="radio"  name="cmd_33407"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33407" type="radio"  (ngModelChange)="onchangeaction1($event)"  name="cmd_33407" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33407'].touched && erieForm.controls['cmd_33407'].errors) ||
               (erieForm.controls['cmd_33407'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33407'].touched && erieForm.controls['cmd_33407'].errors) ||
               (erieForm.controls['cmd_33407'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>


            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12" style="padding-bottom: 20px">
                    <input formControlName="cmd_33408" style="width: 100%" class="form-control" id="cmd_33408"
                        name="cmd_33408" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33408'].touched && erieForm.controls['cmd_33408'].errors)
                        || (erieForm.controls['cmd_33408'].errors && ischangeAction1)">

                        <div class="formLabel text-danger col-md-1"
                        *ngIf="(erieForm.controls['cmd_33408'].touched && erieForm.controls['cmd_33408'].errors) ||
                       (erieForm.controls['cmd_33408'].errors && ischangeAction1)">
                         required
                                   </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>
                        If you attended more than one medical/professional school, please check here and attach an
                        explanation that duplicates the information requested above:
                    </label>
                </div>
                <div class="col-md-2" >
                    <input formControlName="cmd_33409" type="checkbox" id="cmd_33409" name="cmd_33409" value="1">
                </div>
            </div>
            <!-- <div class="row mb-3" >
                <div class="col-md-12" style="padding-bottom: 20px">
                    <input formControlName="cmd_33410" style="width: 100%" class="form-control  " id="cmd_33410"
                        name="cmd_33410" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33410'].touched && erieForm.controls['cmd_33410'].errors)
                        || (erieForm.controls['cmd_33410'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33410'].touched && erieForm.controls['cmd_33410'].errors)
                         || (erieForm.controls['cmd_33410'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33410'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div> -->
            <br />
            <br />
            <table class="databox" >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-left ">
                            <label
                                ><b>INTERNSHIP</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="517" class="form-control  " id="517" name="517" type="text"
                        placeholder=" Internship School Name"
                    [class.is-invalid]="(erieForm.controls['517'].touched && erieForm.controls['517'].errors)
                    || (erieForm.controls['517'].errors && isSubmit)" >


         <div class="formLabel"
                        *ngIf="(erieForm.controls['517'].touched && erieForm.controls['517'].errors)
                         || (erieForm.controls['517'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['517'].errors">
                       required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="528" class="form-control  " id="528" name="528" type="text"
                        placeholder="Department Chair or Program Director: "
                        [class.is-invalid]="(erieForm.controls['528'].touched && erieForm.controls['528'].errors)
                        || (erieForm.controls['528'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['528'].touched && erieForm.controls['528'].errors)
                         || (erieForm.controls['528'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['528'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>

                <div class="row mb-3 pt-2 p-2 " >
                    <div class="col-md-1">
                        <label>Mailing Address:</label>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="518" class="form-control  " id="518" name="518" type="text"
                            placeholder="address1"
                            [class.is-invalid]="(erieForm.controls['518'].touched && erieForm.controls['518'].errors)
                            || (erieForm.controls['518'].errors && isSubmit)" >

             <div class="formLabel"
                            *ngIf="(erieForm.controls['518'].touched && erieForm.controls['518'].errors)
                             || (erieForm.controls['518'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['518'].errors">
                                required
                            </small>
                        </div>


                    </div>
                    <div class="col-md-2">
                        <input formControlName="519" class="form-control" id="519" name="519" type="text"
                            placeholder="address2"
                            [class.is-invalid]="(erieForm.controls['519'].touched && erieForm.controls['519'].errors)
                            || (erieForm.controls['519'].errors && isSubmit)" >

             <div class="formLabel"
                            *ngIf="(erieForm.controls['519'].touched && erieForm.controls['519'].errors)
                             || (erieForm.controls['519'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['519'].errors">
                                required
                            </small>
                        </div>


                    </div>
                    <div class="col-md-2">
                        <input formControlName="520" class="form-control  " id="520" name="520" type="text"
                            placeholder="city"
                            [class.is-invalid]="(erieForm.controls['520'].touched && erieForm.controls['520'].errors)
                            || (erieForm.controls['520'].errors && isSubmit)" >

             <div class="formLabel"
                            *ngIf="(erieForm.controls['520'].touched && erieForm.controls['520'].errors)
                             || (erieForm.controls['520'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['520'].errors">
                                required
                            </small>
                        </div>


                    </div>
                    <div class="col-md-2" style="padding-top:1px">
                        <div class="mb-3  " >
                             <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                bindValue="id" formControlName="522"
                                [class.is-invalid]="(erieForm.controls['522'].touched && erieForm.controls['522'].errors)
                            || (erieForm.controls['522'].errors && isSubmit)" >

                            </ng-select>
             <div class="formLabel"
                            *ngIf="(erieForm.controls['522'].touched && erieForm.controls['522'].errors)
                             || (erieForm.controls['522'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['522'].errors">
                                required
                            </small>
                        </div>


                        </div>
                    </div>
                    <div class="col-md-2">
                        <input formControlName="523" class="form-control  " id="523" name="523" type="text"
                            placeholder="zip"
                            [class.is-invalid]="(erieForm.controls['523'].touched && erieForm.controls['523'].errors)
                            || (erieForm.controls['523'].errors && isSubmit)" >

             <div class="formLabel"
                            *ngIf="(erieForm.controls['523'].touched && erieForm.controls['523'].errors)
                             || (erieForm.controls['523'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['523'].errors">
                                required
                            </small>
                        </div>


                    </div>
                </div>

            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1318" class="form-control  " id="1318" name="1318" type="text"
                        placeholder="Phone"
                        [class.is-invalid]="(erieForm.controls['1318'].touched && erieForm.controls['1318'].errors)
                        || (erieForm.controls['1318'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1318'].touched && erieForm.controls['1318'].errors)
                         || (erieForm.controls['1318'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1318'].errors">
                            required
                        </small>
                    </div>


                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1319" class="form-control  " id="1319" name="1319" type="text"
                        placeholder="Fax No"
                        [class.is-invalid]="(erieForm.controls['1319'].touched && erieForm.controls['1319'].errors)
                        || (erieForm.controls['1319'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1319'].touched && erieForm.controls['1319'].errors)
                         || (erieForm.controls['1319'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1319'].errors">
                            required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="524" (blur)="clearInputIfInvalidMandi('524')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d46="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['524'].touched && erieForm.controls['524'].errors)
                            || (erieForm.controls['524'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d46.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['524'].invalid&&erieForm.controls['524'].touched) && (erieForm.controls['524'].value==''|| erieForm.controls['524'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('524').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('524').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('524').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('524').errors?.invalidDateRange">
                          date is out of range
                          </small>


                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="525" (blur)="clearInputIfInvalidMandi('525')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d47="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['525'].touched && erieForm.controls['525'].errors)
                    || (erieForm.controls['525'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d47.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['525'].invalid&&erieForm.controls['525'].touched) && (erieForm.controls['525'].value==''|| erieForm.controls['525'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('525').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('525').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('525').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('525').errors?.invalidDateRange">
                          date is out of range
                          </small>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Type of internship: </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="cmd_33411" type="checkbox" id="cmd_33411" name="cmd_33411" value="1">
                    <label>Rotating </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="cmd_33412" type="checkbox" id="cmd_33412" name="cmd_33412" value="1"

                    [class.is-invalid]="(erieForm.controls['cmd_33412'].touched && erieForm.controls['cmd_33412'].errors)
                    || (erieForm.controls['cmd_33412'].errors && isSubmit)"
                    (ngModelChange)="onstraightchange($event)"
                    >
                    <label>Straight </label>
                    <div class="formLabel"
                    *ngIf="(erieForm.controls['cmd_33412'].touched && erieForm.controls['cmd_33412'].errors)
                     || (erieForm.controls['cmd_33412'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['cmd_33412'].errors">
                        required
                    </small>
                </div>

                </div>
                <div class="col-md-3">
                    <label>If straight, please list specialty: </label>
                </div>
                <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select SPECIALTY" bindValue="id"
                        formControlName="527"
                        [class.is-invalid]="(erieForm.controls['527'].touched && erieForm.controls['527'].errors)
                        || (erieForm.controls['527'].errors && isStraightchange)" >
                    </ng-select>
        <div class="formLabel"
                    *ngIf="(erieForm.controls['527'].touched && erieForm.controls['527'].errors)
                     || (erieForm.controls['527'].errors && isStraightchange)">
                    <small class="text-danger" *ngIf="erieForm.controls['527'].errors">
                        required
                    </small>
                </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-5">
                    <label> Did you successfully complete this program?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33413" (ngModelChange)="onchangeaction2($event)" type="radio"  name="cmd_33413"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33413" type="radio"   (ngModelChange)="onchangeaction2($event)" name="cmd_33413" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33413'].touched && erieForm.controls['cmd_33413'].errors) ||
                            (erieForm.controls['cmd_33413'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33413'].touched && erieForm.controls['cmd_33413'].errors) ||
                            (erieForm.controls['cmd_33413'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If No,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33414" style="width: 100%" class="form-control " id="cmd_33414"
                        name="cmd_33414" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33414'].touched && erieForm.controls['cmd_33414'].errors)
                        || (erieForm.controls['cmd_33414'].errors && ischangeAction2)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33414'].touched && erieForm.controls['cmd_33414'].errors)
                         || (erieForm.controls['cmd_33414'].errors && ischangeAction2)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33414'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33415"  (ngModelChange)="onchangeaction3($event)" type="radio"  name="cmd_33415"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33415" type="radio"  (ngModelChange)="onchangeaction3($event)" name="cmd_33415" value="0">No</label>
                </div>

                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33415'].touched && erieForm.controls['cmd_33415'].errors) ||
               (erieForm.controls['cmd_33415'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33415'].touched && erieForm.controls['cmd_33415'].errors) ||
               (erieForm.controls['cmd_33415'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33416" style="width: 100%" class="form-control  " id="cmd_33416"
                        name="cmd_33416" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33416'].touched && erieForm.controls['cmd_33416'].errors)
                        || (erieForm.controls['cmd_33416'].errors && ischangeAction3)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33416'].touched && erieForm.controls['cmd_33416'].errors)
                         || (erieForm.controls['cmd_33416'].errors && ischangeAction3)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33416'].errors">
                            required
                        </small>
                    </div>


                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-10" >
                    <label>
                        If more than one internship, please check here and attach additional information that duplicates the
                        information requested above:
                    </label>
                </div>
                <div class="col-md-2" >
                    <input formControlName="cmd_33417" type="checkbox" id="cmd_33417" name="cmd_33417" value="1">
                </div>
            </div>
            <br />
            <hr class="pdf-line">
            <table class="databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-left">
                            <label ><b>FIRST
                                    RESIDENCY</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3">
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="529" class="form-control  " id="529" name="529" type="text"
                        placeholder="FIRST RESIDENCY"
                        [class.is-invalid]="(erieForm.controls['529'].touched && erieForm.controls['529'].errors)
                        || (erieForm.controls['529'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['529'].touched && erieForm.controls['529'].errors)
                         || (erieForm.controls['529'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['529'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="540" class="form-control  " id="540" name="540" type="text"
                        placeholder="Department Chair or Program Director: "
                        [class.is-invalid]="(erieForm.controls['540'].touched && erieForm.controls['540'].errors)
                        || (erieForm.controls['540'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['540'].touched && erieForm.controls['540'].errors)
                         || (erieForm.controls['540'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['540'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="531" class="form-control  " id="531" name="531" type="text"
                        placeholder="address1"
                        [class.is-invalid]="(erieForm.controls['531'].touched && erieForm.controls['531'].errors)
                        || (erieForm.controls['531'].errors && isSubmit)"  >
         <div class="formLabel"
                        *ngIf="(erieForm.controls['531'].touched && erieForm.controls['531'].errors)
                         || (erieForm.controls['531'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['531'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="1498" class="form-control  " id="1498" name="1498" type="text"
                        placeholder="address2"
                        [class.is-invalid]="(erieForm.controls['1498'].touched && erieForm.controls['1498'].errors)
                        || (erieForm.controls['1498'].errors && isSubmit)"
                         >

          <div class="formLabel"
                         *ngIf="(erieForm.controls['1498'].touched && erieForm.controls['1498'].errors)
                          || (erieForm.controls['1498'].errors && isSubmit)">
                         <small class="text-danger" *ngIf="erieForm.controls['1498'].errors">
                             required
                         </small>
                     </div>



                </div>
                <div class="col-md-2">
                    <input formControlName="533" class="form-control  " id="533" name="533" type="text"
                        placeholder="city"
                        [class.is-invalid]="(erieForm.controls['533'].touched && erieForm.controls['533'].errors)
                        || (erieForm.controls['533'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['533'].touched && erieForm.controls['533'].errors)
                         || (erieForm.controls['533'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['533'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="535"
                            [class.is-invalid]="(erieForm.controls['535'].touched && erieForm.controls['535'].errors)
                            || (erieForm.controls['535'].errors && isSubmit)">
                        </ng-select>
         <div class="formLabel"
                        *ngIf="(erieForm.controls['535'].touched && erieForm.controls['535'].errors)
                         || (erieForm.controls['535'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['535'].errors">
                            required
                        </small>
                    </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="536" class="form-control  " id="536" name="536" type="text"
                        placeholder="zip"
                        [class.is-invalid]="(erieForm.controls['536'].touched && erieForm.controls['536'].errors)
                        || (erieForm.controls['536'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['536'].touched && erieForm.controls['536'].errors)
                         || (erieForm.controls['536'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['536'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>

            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1316" class="form-control  " id="1316" name="1316" type="text"
                        placeholder="Daytime Phone"
                        [class.is-invalid]="(erieForm.controls['1316'].touched && erieForm.controls['1316'].errors)
                        || (erieForm.controls['1316'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1316'].touched && erieForm.controls['1316'].errors)
                         || (erieForm.controls['1316'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1316'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1317" class="form-control  " id="1317" name="1317" type="text"
                        placeholder="Fax No"
                        [class.is-invalid]="(erieForm.controls['1317'].touched && erieForm.controls['1317'].errors)
                        || (erieForm.controls['1317'].errors && isSubmit)" >
                        <div class="formLabel"
                        *ngIf="(erieForm.controls['1317'].touched && erieForm.controls['1317'].errors)
                         || (erieForm.controls['1317'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1317'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="537" (blur)="clearInputIfInvalidMandi('537')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d48="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['537'].touched && erieForm.controls['537'].errors)
                            || (erieForm.controls['537'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d48.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="(erieForm.controls['537'].invalid&&erieForm.controls['537'].touched) && (erieForm.controls['537'].value==''|| erieForm.controls['537'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('537').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('537').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('537').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('537').errors?.invalidDateRange">
                          date is out of range
                          </small>

                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="538" (blur)="clearInputIfInvalidMandi('538')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d49="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm.controls['538'].touched && erieForm.controls['538'].errors)
                            || (erieForm.controls['538'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d49.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['538'].invalid&&erieForm.controls['538'].touched) && (erieForm.controls['538'].value==''|| erieForm.controls['538'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('538').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('538').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('538').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('538').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Type of residency: </label>
                </div>
                <div class="col-md-4">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                        formControlName="530"
                        [class.is-invalid]="(erieForm.controls['530'].touched && erieForm.controls['530'].errors)
                        || (erieForm.controls['530'].errors && isSubmit)" >
                    </ng-select>


                        <div class="formLabel"
                        *ngIf="(erieForm.controls['530'].touched && erieForm.controls['530'].errors)
                         || (erieForm.controls['530'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['530'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-5">
                    <label> Did you successfully complete this program?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33418" (ngModelChange)="onchangeaction4($event)" type="radio"

                        name="cmd_33418"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33418" type="radio"  (ngModelChange)="onchangeaction4($event)"  name="cmd_33418" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33418'].touched && erieForm.controls['cmd_33418'].errors) ||
               (erieForm.controls['cmd_33418'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33418'].touched && erieForm.controls['cmd_33418'].errors) ||
               (erieForm.controls['cmd_33418'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If No,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33419" style="width: 100%" class="form-control " id="cmd_33419"
                        name="cmd_33419" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33419'].touched && erieForm.controls['cmd_33419'].errors)
                        || (erieForm.controls['cmd_33419'].errors && ischangeAction4)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33419'].touched && erieForm.controls['cmd_33419'].errors)
                         || (erieForm.controls['cmd_33419'].errors && ischangeAction4)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33419'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33420" (ngModelChange)="onchangeaction5($event)" type="radio" name="cmd_33420"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33420" type="radio" (ngModelChange)="onchangeaction5($event)"  name="cmd_33420" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33420'].touched && erieForm.controls['cmd_33420'].errors) ||
                            (erieForm.controls['cmd_33420'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33420'].touched && erieForm.controls['cmd_33420'].errors) ||
                            (erieForm.controls['cmd_33420'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33421" style="width: 100%" class="form-control " id="cmd_33421"
                        name="cmd_33421" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33421'].touched && erieForm.controls['cmd_33421'].errors)
                        || (erieForm.controls['cmd_33421'].errors && ischangeAction5)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33421'].touched && erieForm.controls['cmd_33421'].errors)
                         || (erieForm.controls['cmd_33421'].errors && ischangeAction5)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33421'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <table class="databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-left">
                            <label ><b>SECOND
                                    RESIDENCY</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3">
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="1170" class="form-control  " id="1170" name="1170" type="text"
                        placeholder=" additional residency School Name"
                        [class.is-invalid]="(erieForm.controls['1170'].touched && erieForm.controls['1170'].errors)
                        || (erieForm.controls['1170'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1170'].touched && erieForm.controls['1170'].errors)
                         || (erieForm.controls['1170'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1170'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1181" class="form-control  " id="1181" name="1181" type="text"
                        placeholder="Department Chair or Program Director: "
                        [class.is-invalid]="(erieForm.controls['1181'].touched && erieForm.controls['1181'].errors)
                        || (erieForm.controls['1181'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1181'].touched && erieForm.controls['1181'].errors)
                         || (erieForm.controls['1181'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1181'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="1172" class="form-control  " id="1172" name="1172" type="text"
                        placeholder="address1"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1173" class="form-control  " id="1173" name="1173" type="text"
                        placeholder="address2"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1174" class="form-control  " id="1174" name="1174" type="text"
                        placeholder="city"  >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="1176">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="1177" class="form-control  " id="1177" name="1177" type="text"
                        placeholder="zip">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1320" class="form-control  " id="1320" name="1320" type="text"
                        placeholder="Daytime Phone" >
                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1321" class="form-control  " id="1321" name="1321" type="text"
                        placeholder="Fax No" >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="1178" (blur)="clearInputIfInvalid('1178')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d50="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d50.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1178').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1178').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1178').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1178').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1178').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="1179" (blur)="clearInputIfInvalid('1179')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d51="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d51.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1179').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1179').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1179').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1179').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1179').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Type of residency: </label>
                </div>
                <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                        formControlName="1171">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-5">
                    <label> Did you successfully complete this program?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33422" (ngModelChange)="onchangeaction6($event)" type="radio"  name="cmd_33422"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33422" (ngModelChange)="onchangeaction6($event)"  type="radio"  name="cmd_33422" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33422'].touched && erieForm.controls['cmd_33422'].errors) ||
                            (erieForm.controls['cmd_33422'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33422'].touched && erieForm.controls['cmd_33422'].errors) ||
                            (erieForm.controls['cmd_33422'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If No,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33423" style="width: 100%" class="form-control  " id="cmd_33423"
                        name="cmd_33423" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33423'].touched && erieForm.controls['cmd_33423'].errors)
                        || (erieForm.controls['cmd_33423'].errors && ischangeAction6)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33423'].touched && erieForm.controls['cmd_33423'].errors)
                         || (erieForm.controls['cmd_33423'].errors && ischangeAction6)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33423'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33424" (ngModelChange)="onchangeaction7($event)"  type="radio"  name="cmd_33424"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33424" (ngModelChange)="onchangeaction7($event)"  type="radio"  name="cmd_33424" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33424'].touched && erieForm.controls['cmd_33424'].errors) ||
                            (erieForm.controls['cmd_33424'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33424'].touched && erieForm.controls['cmd_33424'].errors) ||
                            (erieForm.controls['cmd_33424'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33425" style="width: 100%" class="form-control " id="cmd_33425"
                        name="cmd_33425" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33425'].touched && erieForm.controls['cmd_33425'].errors)
                        || (erieForm.controls['cmd_33425'].errors && ischangeAction7)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33425'].touched && erieForm.controls['cmd_33425'].errors)
                         || (erieForm.controls['cmd_33425'].errors && ischangeAction7)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33425'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-10" >
                    <label>
                        If more than two residencies, please check here and attach additional information that duplicates
                        the information requested above:
                    </label>
                </div>
                <div class="col-md-2" >
                    <input formControlName="cmd_33426" type="checkbox" id="cmd_33426" name="cmd_33426" value="1">
                </div>
            </div>
            <br />
            <hr class="pdf-line">
            <table class="databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-left ">
                            <label ><b>FIRST
                                    FELLOWSHIP</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="542" class="form-control  " id="542" name="542" type="text"
                        placeholder="fellowship School Name"
                          [class.is-invalid]="(erieForm.controls['542'].touched && erieForm.controls['542'].errors)
                        || (erieForm.controls['542'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['542'].touched && erieForm.controls['542'].errors)
                         || (erieForm.controls['542'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['542'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="553" class="form-control  " id="553" name="553" type="text"
                        placeholder="fellowship School Director" [class.is-invalid]="(erieForm.controls['553'].touched && erieForm.controls['553'].errors)
                        || (erieForm.controls['553'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['553'].touched && erieForm.controls['553'].errors)
                         || (erieForm.controls['553'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['553'].errors">
                            required
                        </small>
                    </div>
            </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="544" class="form-control  " id="544" name="544" type="text"
                        placeholder="address1" [class.is-invalid]="(erieForm.controls['544'].touched && erieForm.controls['544'].errors)
                        || (erieForm.controls['544'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['544'].touched && erieForm.controls['544'].errors)
                         || (erieForm.controls['544'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['544'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="545" class="form-control  " id="545" name="545" type="text"
                        placeholder="address2"  [class.is-invalid]="(erieForm.controls['545'].touched && erieForm.controls['545'].errors)
                        || (erieForm.controls['545'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['545'].touched && erieForm.controls['545'].errors)
                         || (erieForm.controls['545'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['545'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="546" class="form-control  " id="546" name="546" type="text"
                        placeholder="city" [class.is-invalid]="(erieForm.controls['546'].touched && erieForm.controls['546'].errors)
                        || (erieForm.controls['546'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['546'].touched && erieForm.controls['546'].errors)
                         || (erieForm.controls['546'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['546'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select State"
                            bindValue="id" formControlName="548">


                        </ng-select>
                        <div class="formLabel"
                        *ngIf="(erieForm.controls['548'].touched && erieForm.controls['548'].errors)
                         || (erieForm.controls['548'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['548'].errors">
                            required
                        </small>
                    </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="549" class="form-control  " id="549" name="549" type="text"
                        placeholder="zip" [class.is-invalid]="(erieForm.controls['549'].touched && erieForm.controls['549'].errors)
                        || (erieForm.controls['549'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['549'].touched && erieForm.controls['549'].errors)
                         || (erieForm.controls['549'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['549'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1322" class="form-control  " id="1322" name="1322" type="text"
                        placeholder="Daytime Phone"  [class.is-invalid]="(erieForm.controls['1322'].touched && erieForm.controls['1322'].errors)
                        || (erieForm.controls['1322'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1322'].touched && erieForm.controls['1322'].errors)
                         || (erieForm.controls['1322'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1322'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1323" class="form-control  " id="1323" name="1323" type="text"
                        placeholder="Fax No"  [class.is-invalid]="(erieForm.controls['1323'].touched && erieForm.controls['1323'].errors)
                        || (erieForm.controls['1323'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['1323'].touched && erieForm.controls['1323'].errors)
                         || (erieForm.controls['1323'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['1323'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="550" (blur)="clearInputIfInvalidMandi('550')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d52="ngbDatepicker" container="body"    [class.is-invalid]="(erieForm.controls['550'].touched && erieForm.controls['550'].errors)
                            || (erieForm.controls['550'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d52.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['550'].invalid&&erieForm.controls['550'].touched) && (erieForm.controls['550'].value==''|| erieForm.controls['550'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('550').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('550').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('550').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('550').errors?.invalidDateRange">
                          date is out of range
                          </small>
              </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="551" (blur)="clearInputIfInvalidMandi('551')" class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d53="ngbDatepicker" container="body"
                              [class.is-invalid]="(erieForm.controls['551'].touched && erieForm.controls['551'].errors)
                            || (erieForm.controls['551'].errors && isSubmit)" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d53.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf="(erieForm.controls['551'].invalid&&erieForm.controls['551'].touched) && (erieForm.controls['551'].value==''|| erieForm.controls['551'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm.get('551').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm.get('551').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('551').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm.get('551').errors?.invalidDateRange">
                          date is out of range
                          </small>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Type of fellowship: </label>
                </div>
                <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                        formControlName="543">
                    </ng-select>
     <div class="formLabel"
                    *ngIf="(erieForm.controls['543'].touched && erieForm.controls['543'].errors)
                     || (erieForm.controls['543'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm.controls['543'].errors">
                        required
                    </small>
                </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-5">
                    <label> Did you successfully complete this program?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33427"(change)="onchangeaction8($event)"  type="radio"  name="cmd_33427"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33427" type="radio" (ngModelChange)="onchangeaction8($event)"  name="cmd_33427" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33427'].touched && erieForm.controls['cmd_33427'].errors) ||
               (erieForm.controls['cmd_33427'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33427'].touched && erieForm.controls['cmd_33427'].errors) ||
               (erieForm.controls['cmd_33427'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>


            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If No,explain
                    </label>
                </div>
                <div class="col-md-12" style="padding-bottom: 20px">
                    <input formControlName="cmd_33428" style="width: 100%" class="form-control  " id="cmd_33428"
                        name="cmd_33428" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33428'].touched && erieForm.controls['cmd_33428'].errors)
                        || (erieForm.controls['cmd_33428'].errors && ischangeAction8)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33428'].touched && erieForm.controls['cmd_33428'].errors)
                         || (erieForm.controls['cmd_33428'].errors && ischangeAction8)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33428'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33429" (ngModelChange)="onchangeaction9($event)"  type="radio" name="cmd_33429"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33429" type="radio"  (ngModelChange)="onchangeaction9($event)"  name="cmd_33429" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                *ngIf="(erieForm.controls['cmd_33429'].touched && erieForm.controls['cmd_33429'].errors) ||
               (erieForm.controls['cmd_33429'].errors && isSubmit)">
               <span *ngIf="(erieForm.controls['cmd_33429'].touched && erieForm.controls['cmd_33429'].errors) ||
               (erieForm.controls['cmd_33429'].errors && isSubmit)">
                  <i class="fas fa-times ms-2 text-danger"></i>
              </span>
                           </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12" style="padding-bottom: 20px">
                    <input formControlName="cmd_33430" style="width: 100%" class="form-control " id="cmd_33430"
                        name="cmd_33430" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33430'].touched && erieForm.controls['cmd_33430'].errors)
                        || (erieForm.controls['cmd_33430'].errors && ischangeAction9)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33430'].touched && erieForm.controls['cmd_33430'].errors)
                         || (erieForm.controls['cmd_33430'].errors && ischangeAction9)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33430'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <table class="databox">

                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-left ">
                            <label style="font-weight: normal;font-size: 14px;     padding-bottom: 2px;"><b>SECOND
                                    FELLOWSHIP</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="966" class="form-control  " id="966" name="966" type="text"
                        placeholder="additional fellowship School Name" >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="983" class="form-control  " id="983" name="983" type="text"
                        placeholder="additional Fellowship school Director: " >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="968" class="form-control  " id="968" name="968" type="text"
                        placeholder="address1"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="969" class="form-control  " id="969" name="969" type="text"
                        placeholder="address2"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="970" class="form-control  " id="970" name="970" type="text"
                        placeholder="city"  >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="972">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="1301" class="form-control  " id="1301" name="1301" type="text"
                        placeholder="zip">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Telephone Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1324" class="form-control  " id="1324" name="1324" type="text"
                        placeholder="Daytime Phone" >
                </div>
                <div class="col-md-2">
                    <label>Fax Number:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1325" class="form-control  " id="1325" name="1325" type="text"
                        placeholder="Fax No" >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="974" (blur)="clearInputIfInvalid('974')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d54="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d54.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('974').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('974').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('974').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('974').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('974').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <input formControlName="975" (blur)="clearInputIfInvalid('975')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d55="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d55.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('975').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('975').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('975').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('975').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('975').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label>Type of fellowship: </label>
                </div>
                <div class="col-md-2">
                     <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty" bindValue="id"
                        formControlName="967">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-5">
                    <label> Did you successfully complete this program?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33431" (ngModelChange)="onchangeaction10($event)"  type="radio"  name="cmd_33431"
                            value="0">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33431" type="radio" (ngModelChange)="onchangeaction10($event)"   name="cmd_33431" value="1">No</label>
                </div>

         <div class="formLabel col-md-1"
         *ngIf="(erieForm.controls['cmd_33431'].touched && erieForm.controls['cmd_33431'].errors) ||
        (erieForm.controls['cmd_33431'].errors && isSubmit)">
        <span *ngIf="(erieForm.controls['cmd_33431'].touched && erieForm.controls['cmd_33431'].errors) ||
        (erieForm.controls['cmd_33431'].errors && isSubmit)">
           <i class="fas fa-times ms-2 text-danger"></i>
       </span>
                    </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If No,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33432" style="width: 100%" class="form-control  " id="cmd_33432"
                        name="cmd_33432" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33432'].touched && erieForm.controls['cmd_33432'].errors)
                        || (erieForm.controls['cmd_33432'].errors && ischangeAction10)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33432'].touched && erieForm.controls['cmd_33432'].errors)
                         || (erieForm.controls['cmd_33432'].errors && ischangeAction10)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33432'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3" >

                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33433" (ngModelChange)="onchangeaction11($event)"  type="radio" name="cmd_33433"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33433" type="radio" (ngModelChange)="onchangeaction11($event)"   name="cmd_33433" value="0">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33433'].touched && erieForm.controls['cmd_33433'].errors) ||
                            (erieForm.controls['cmd_33433'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33433'].touched && erieForm.controls['cmd_33433'].errors) ||
                            (erieForm.controls['cmd_33433'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33434" style="width: 100%" class="form-control  " id="cmd_33434"
                        name="cmd_33434" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33434'].touched && erieForm.controls['cmd_33434'].errors)
                        || (erieForm.controls['cmd_33434'].errors && ischangeAction11)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33434'].touched && erieForm.controls['cmd_33434'].errors)
                         || (erieForm.controls['cmd_33434'].errors && ischangeAction11)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33434'].errors">
                            required
                        </small>
                    </div>

                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-10" >
                    <label>
                        If more than two fellowships, please check here and attach additional information that duplicates
                        the information requested above:
                    </label>
                </div>
                <div class="col-md-2" >
                    <input formControlName="cmd_33435" type="checkbox" id="cmd_33435" name="cmd_33435" value="1">
                </div>
            </div>
            <br />
            <br />
            <hr class="pdf-line">
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-left">
                            <label ><b>TEACHING
                                    EXPERIENCE/FACULTY APPOINTMENT (MOST RECENT)</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="1146" class="form-control  " id="1146" name="1146" type="text"
                        placeholder="Primary Institution Name" >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1382" class="form-control  " id="1382" name="1382" type="text"
                        placeholder=" Primary Institution Chairman " >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="1148" class="form-control  " id="1148" name="1148" type="text"
                        placeholder="address1"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1149" class="form-control  " id="1149" name="1149" type="text"
                        placeholder="address2"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1150" class="form-control  " id="1150" name="1150" type="text"
                        placeholder="city"  >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  " >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="1152">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="1153" class="form-control  " id="1153" name="1153" type="text"
                        placeholder="zip">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Institution Notes:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="4142" class="form-control  " id="4142" name="4142" type="text"
                        >
                </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1155" (blur)="clearInputIfInvalid('1155')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d56="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d56.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1155').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1155').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1155').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1155').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1155').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1156" (blur)="clearInputIfInvalid('1156')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d57="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d57.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1156').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1156').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1156').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1156').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1156').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-2">
                    <label>Rank/Position ,if applicable </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="1154" class="form-control  " id="1154" name="1154" type="text"
                        placeholder="Primary Position Rank or Title ">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33436" (ngModelChange)="onchangeaction12($event)"  type="radio"  name="cmd_33436"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33436" (ngModelChange)="onchangeaction12($event)"  type="radio"  name="cmd_33436" value="0">No</label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12" style="padding-bottom: 20px">
                    <input formControlName="cmd_33437" style="width: 100%" class="form-control " id="cmd_33437"
                        name="cmd_33437" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33437'].touched && erieForm.controls['cmd_33437'].errors)
                        || (erieForm.controls['cmd_33437'].errors && ischangeAction12)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33437'].touched && erieForm.controls['cmd_33437'].errors)
                         || (erieForm.controls['cmd_33437'].errors && ischangeAction12)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33437'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-left">
                            <label ><b>TEACHING
                                    EXPERIENCE/FACULTY APPOINTMENT (PREVIOUS)</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Institution Name: </label>
                </div>
                <div class="col-md-4">
                    <input formControlName="1157" class="form-control  " id="1157" name="1157" type="text"
                        placeholder="Secondary Institution Name" >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-3">
                    <label> Department Chair or Program Director: </label>
                </div>
                <div class="col-md-3">
                    <input formControlName="1383" class="form-control  " id="1383" name="1383" type="text"
                        placeholder="Secondary Institution Chairman " >
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-1">
                    <label>Mailing Address:</label>
                </div>
                <div class="col-md-2">
                    <input formControlName="1159" class="form-control  " id="1159" name="1159" type="text"
                        placeholder="address1"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1160" class="form-control  " id="1160" name="1160" type="text"
                        placeholder="address2"  >
                </div>
                <div class="col-md-2">
                    <input formControlName="1161" class="form-control  " id="1161" name="1161" type="text"
                        placeholder="city"  >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3" >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="1163">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <input formControlName="1164" class="form-control  " id="1164" name="1164" type="text"
                        placeholder="zip">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label> Secondary Institution Notes:</label>
                </div>
                <div class="col-md-3">
                    <input formControlName="4143" class="form-control  " id="4143" name="4143" type="text"
                        placeholder="Institution Notes" >
                </div>

            </div>
            <div class="row mb-3" >
                <div class="col-md-2">
                    <label>Dates attended: </label>
                </div>
                <div class="col-md-1">
                    <label>From:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1166" (blur)="clearInputIfInvalid('1166')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d58="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d58.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1166').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1166').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1166').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1166').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1166').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label>To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <input formControlName="1167" (blur)="clearInputIfInvalid('1167')"  class="form-control" placeholder="mm/dd/yyyy" ngbDatepicker
                            #d59="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d59.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1167').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1167').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1167').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1167').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1167').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-2">
                    <label> Rank/Position,if applicable </label>
                </div>
                <div class="col-md-2">
                    <input formControlName="1165" class="form-control  " id="1165" name="1165" type="text"
                        placeholder="Secondary Position Rank or Title">
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8">
                    <label> Were you the subject of any disciplinary action
                        during your attendance at this institution?
                    </label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33438" (ngModelChange)="onchangeaction13($event)"  type="radio"  name="cmd_33438"
                            value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1" >
                    <label class="checkbox-inline">
                        <input formControlName="cmd_33438" (ngModelChange)="onchangeaction13($event)"  type="radio"  name="cmd_33438" value="0">No</label>
                </div>
            </div>
            <div class="row mb-3" >
                <div class="col-md-8" >
                    <label>If Yes,explain
                    </label>
                </div>
                <div class="col-md-12">
                    <input formControlName="cmd_33439" style="width: 100%" class="form-control  " id="cmd_33439"
                        name="cmd_33439" type="text"
                        [class.is-invalid]="(erieForm.controls['cmd_33439'].touched && erieForm.controls['cmd_33439'].errors)
                        || (erieForm.controls['cmd_33439'].errors && ischangeAction13)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33439'].touched && erieForm.controls['cmd_33439'].errors)
                         || (erieForm.controls['cmd_33439'].errors && ischangeAction13)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33439'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <br />
            <div class="row mb-3" >
                <div class="col-md-10" >
                    <label>If more than two teaching experiences/faculty
                        appointments, please check here and attach additional information that duplicates the information
                        requested above:
                    </label>
                </div>
                <div class="col-md-2" >
                    <input formControlName="cmd_33440" type="checkbox" id="cmd_33440" name="cmd_33440" value="1">
                </div>
            </div>


            <br />
            <br />
            <br />
            <hr class="pdf-line">
            <table class="databox"
            >
            <tr>
                <td colspan="12" class="table-backround">
                    <div  class="text-center">
                        <label ><b>MEMBERSHIP STATUS –
                                USE FOR
                                SECTIONS E, F, AND G</b></label>
                    </div>
                </td>
            </tr>
            </table>
            <br />
            <div class="row"  >
            <div class="col-md-12 text-center" >
                <label><b>Please use the following key to
                        indicate membership status in Sections E (Hospital Membership – Current and Pending), F
                        (Hospital
                        Membership – Previous), and G (Ambulatory Surgery Center Practice) below. </b>
                </label>
            </div>
            </div>
            <table border="1" style="width:80%;margin-left:5rem">
            <tr border="none">
                <td> A. Active</td>
                <td>E. Suspended / Terminated/ Resigned</td>
                <td>I. Provisional</td>
            </tr>
            <tr>
                <td> B. Courtesy</td>
                <td>F. Active Provisional Staff</td>
                <td>J. Affiliate</td>
            </tr>
            <tr>
                <td>C. Consulting</td>
                <td>G. Senior Staff</td>
                <td>K. Pending</td>
            </tr>
            <tr>
                <td> D. Adjunct</td>
                <td>H. Associate</td>
                <td>L. Other (Specify)</td>
            </tr>
            </table>
            <br />
            <br />
            <table class="databox" border="1" >
            <tr>
                <td colspan="12" class="table-backround">
                    <div  class="text-center">
                        <label style="font-weight: normal;font-size: 14px; padding-bottom: 2px;"><b>SECTION E. HOSPITAL
                                MEMBERSHIP - CURRENT AND PENDING</b></label>
                    </div>
                </td>
            </tr>
            </table>
            <br />
            <div class="row"  >
            <div class="col-md-12" >
                <label style="font-weight: normal;text-align:justify;font-size: 14px"><b>Please list all hospitals at
                        which you
                        are a member of the Medical Staff and have clinical privileges or have applications for
                        privileges
                        pending.</b> (Include additional sheets if more than three hospitals.)
                </label>
            </div>
            </div>
            <br />
            <br />
            <hr class="black-line" >
            <label ><b> A. Primary Hospital </b> </label>
            <br />
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >Hospital Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="418"  class="form-control   " id="418"    name="418" type="text" >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="419"  class="form-control   " id="419" name="419"    type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="420"  class="form-control   " id="420" name="420"    type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="421"  class="form-control   " id="421" name="421"    type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="423">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="424"  class="form-control   " id="424" name="424"    type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row  ms-4  mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="894">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="426"  (blur)="clearInputIfInvalid('426')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d60="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d60.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('426').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('426').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('426').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('426').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('426').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <label  >Present</label>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="425"  class="form-control   " id="425"    name="425" type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2136"  class="form-control   " id="2136"    name="2136" type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1305"  class="form-control   " id="1305"    name="1305" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-10">
                  <input   formControlName="cmd_33441"  style="width: 100%" class="form-control   "    id="cmd_33441" name="cmd_33441" type="text"
                    >
            </div>
            </div>
            <br />
            <hr class="black-line">
            <label><b> B. Other Hospital </b></label>
            <br />
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >Hospital Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="427"  class="form-control   " id="427"    name="427" type="text" >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="428"  class="form-control   " id="428" name="428"    type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="429"  class="form-control   " id="429" name="429"    type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="430"  class="form-control   " id="430" name="430"    type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="432">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="433"  class="form-control   " id="433" name="433"    type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name"    placeholder="Category of appointment:" bindValue="id"
                    formControlName="895">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="435"  (blur)="clearInputIfInvalid('435')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d61="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d61.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('435').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('435').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('435').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('435').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('435').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2008" (blur)="clearInputIfInvalid('2008')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d62="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d62.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2008').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2008').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2008').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2008').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2008').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="434"  class="form-control   " id="434"    name="434" type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2137"  class="form-control   " id="2137" name="2137"    type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1307"  class="form-control   " id="1307" name="1307"    type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-12">
                  <input   formControlName="cmd_33442"  style="width: 100%" class="form-control   "    id="cmd_33442" name="cmd_33442" type="text"
                    >
            </div>
            </div>
            <br />

            <hr class="pdf-line">
            <hr class="black-line">
            <label ><b> C. Other Hospital </b> </label>
            <br />
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >Hospital Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="436"  class="form-control"  name="436"    type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>

            <div class="col-md-2">
                  <input   formControlName="437"  class="form-control   " id="437" name="437"    type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="438"  class="form-control   " id="438" name="438"    type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                    <input   formControlName="439"  class="form-control   " id="439" name="439"    type="text" placeholder="city"
                    >

                </div>
            </div>
            <div class="col-md-2">
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  "
                        bindValue="id" formControlName="441">
                    </ng-select>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment" bindValue="id"
                    formControlName="896">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="444"  (blur)="clearInputIfInvalid('444')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d63="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d63.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('444').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('444').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('444').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('444').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('444').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2011" (blur)="clearInputIfInvalid('2011')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d64="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d64.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2011').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2011').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2011').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2011').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2011').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="443"  class="form-control   " id="443" name="443"    type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2138"  class="form-control   " id="2138" name="2138"    type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1309"  class="form-control   " id="1309" name="1309"    type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-12">
                  <input   formControlName="cmd_33443"  style="width: 100%" class="form-control   "    id="cmd_33443" name="cmd_33443" type="text"
                    >
            </div>
            </div>
            <br />
            <hr class="black-line">
            <div class="row">
                <div class="col-md-8">
                    <label  > Check here if you have appended additional information
                        for this
                        section:
                    </label>
                    </div>
                    <div class="col-md-2" >
                      <input   formControlName="cmd_33444"  type="checkbox" id="cmd_33444"     name="cmd_33444" value="1">
                    </div>
            </div>

            <br />
            <br />
            <table border="1"class="databox">
            <tr>
                <td colspan="12" class="table-backround">
                    <div style="padding-top:10px; " class="text-center">
                        <label ><b>SECTION F. HOSPITAL
                                MEMBERSHIP – PREVIOUS</b></label>
                    </div>
                </td>
            </tr>
            </table>
            <br />
            <div class="row mb-3"  >
            <div class="col-md-10" >
                <label class="text-center"><b>Please list all hospitals where
                        you
                        previously held privileges other than during your Internship/Residency/Fellowship. Use the
                        Membership Status key listed prior to Section E.</b> (Include additional sheets if more than
                    three
                    hospitals.)
                </label>
            </div>
            </div>
            <br />
            <br />
            <hr class="black-line">
            <div class="row mb-3"  >
            <div class="col-md-2">
                <label  ><b>A.Hospital Name: </b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="1183"  class="form-control   " id="1183"    name="1183" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="1184"  class="form-control   " id="1184"    name="1184" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="1185"  class="form-control   " id="1185" name="1185"    type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="1186"  class="form-control   " id="1186" name="1186"    type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name"    placeholder="Select  State"
                        bindValue="id" formControlName="1188">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="1189"  class="form-control   " id="1189" name="1189" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="1192">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="1191" (blur)="clearInputIfInvalid('1191')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d65="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d65.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('1191').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('1191').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('1191').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('1191').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('1191').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2381"  (blur)="clearInputIfInvalid('2381')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d66="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d66.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2381').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2381').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2381').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2381').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2381').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1190"  class="form-control   " id="1190" name="1190"    type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2140"  class="form-control   " id="2140" name="2140"    type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1313"  class="form-control   " id="1313" name="1313"    type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-10">
                  <input   formControlName="cmd_33445"  style="width: 100%" class="form-control   "    id="cmd_33445" name="cmd_33445" type="text"
                    >
            </div>
            </div>
            <br />
            <hr class="black-line">
            <div class="row mb-3"  >
            <div class="col-md-2">
                <label  ><b>B.Hospital Name: </b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="cmd_33446"  class="form-control   "    name="cmd_33446" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33447"  class="form-control   "     name="cmd_33447" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33448"  class="form-control   " id="cmd_33448"    name="cmd_33448" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33449"  class="form-control   " id="cmd_33449"    name="cmd_33449" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="cmd_33450">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33451"  class="form-control   " id="cmd_33451"    name="cmd_33451" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="cmd_33452">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_33453"  (blur)="clearInputIfInvalid('cmd_33453')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d67="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d67.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('cmd_33453').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33453').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33453').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33453').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33453').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_33454"  (blur)="clearInputIfInvalid('cmd_33454')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d68="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d68.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('cmd_33454').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33454').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33454').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33454').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33454').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33455"  class="form-control   " id="cmd_33455"    name="cmd_33455" type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33456"  class="form-control   " id="cmd_33456"    name="cmd_33456" type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33457"  class="form-control   " id="cmd_33457"    name="cmd_33457" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-10">
                  <input   formControlName="cmd_33458"  style="width: 100%" class="form-control   "    id="cmd_33458" name="cmd_33458" type="text"
                    >
            </div>
            </div>
            <br />
            <hr class="black-line">
            <div class="row mb-3"  >
            <div class="col-md-2">
                <label  ><b>C.Hospital Name: </b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="cmd_33459"  class="form-control   " id="cmd_33459"    name="cmd_33459" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33460"  class="form-control   " id="cmd_33460"    name="cmd_33460" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33461"  class="form-control   " id="cmd_33461"    name="cmd_33461" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33462"  class="form-control   " id="cmd_33462"    name="cmd_33462" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                    <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="cmd_33463">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33464"  class="form-control   " id="cmd_33464" name="cmd_33464" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row  ms-4  mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="cmd_33465">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_33466" (blur)="clearInputIfInvalid('cmd_33466')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d69="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d69.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('cmd_33466').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33466').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33466').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33466').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33466').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_33467"  (blur)="clearInputIfInvalid('cmd_33467')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d70="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d70.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('cmd_33467').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33467').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33467').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('cmd_33467').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('cmd_33467').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <div class="row  ms-4  mb-3"  >
            <div class="col-md-3">
                <label  >Department/Division:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33468"  class="form-control   " id="cmd_33468" name="cmd_33468" type="text" >
            </div>
            <div class="col-md-3">
                <label  >Medical Staff Office FAX #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33469"  class="form-control   " id="cmd_33469" name="cmd_33469" type="text" placeholder="Fax No"
                    >
            </div>
            </div>
            <div class="row   ms-4  mb-3"  >
            <div class="col-md-3">
                <label  >Department Telephone #:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33470"  class="form-control   " id="cmd_33470" name="cmd_33470" type="text">
            </div>
            </div>
            <div class="row  ms-4  mb-3"  >
            <div class="col-md-8">
                <label  > Any Limitations in Your Area of Specialty at this
                    Hospital?
                </label>
            </div>
            <div class="col-md-10">
                <input   formControlName="cmd_33471"  style="width: 100%" class="form-control   " id="cmd_33471" name="cmd_33471" type="text"
                  >
              </div>
            </div>

            <br />
            <br />
            <hr class="black-line">
            <div class="row">
                <div class="col-md-8">
                    <label  > Check here if you have appended additional information
                        for this
                        section:
                    </label>
                    </div>
                    <div class="col-md-2" >
                      <input   formControlName="cmd_33472"  type="checkbox" id="cmd_33472" name="cmd_33472" value="1">
                    </div>
            </div>

            <br />
            <br />
            <table class="databox" border="1" >
            <tr>
                <td colspan="12" class="table-backround">
                    <div  class="text-center">
                        <label ><b>SECTION G. AMBULATORY
                                SURGERY
                                CENTER PRACTICE</b></label>
                    </div>
                </td>
            </tr>
            </table>
            <br />
            <div class="row mb-3"  >
            <div class="text-center col-md-10" >
                <label style="font-weight: normal;text-align:justify;font-size: 14px"><b>Please list all ambulatory
                        surgery
                        centers where you currently have or previously had privileges. Use the Membership Status key at
                        the
                        top of page 13. </b> ((Include additional sheets if more than three ambulatory surgery centers.)
                </label>
            </div>
            </div>
            <br />
            <hr class="black-line">
            <label><b> A. Primary Ambulatory Surgery Center</b></label>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >ASC Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="2025"  class="form-control   " id="2025" name="2025" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2032"  class="form-control   " id="2032" name="2032" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2033"  class="form-control   " id="2033" name="2033" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2034"  class="form-control   " id="2034" name="2034" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3"  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="2036">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2037"  class="form-control   " id="2037" name="2037" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2040"  class="form-control   " id="2040" name="2040" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2041"  class="form-control   " id="2041" name="2041" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="2045">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2042" (blur)="clearInputIfInvalid('2042')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d71="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d71.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2042').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2042').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2042').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2042').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2042').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2044"  (blur)="clearInputIfInvalid('2044')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d72="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d72.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2044').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2044').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2044').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2044').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2044').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <br />
            <hr class="black-line">
            <label><b> B. Other Ambulatory Surgery Center</b></label>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >ASC Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="2046"  class="form-control   " id="2046" name="2046" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2047"  class="form-control   " id="2047" name="2047" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2048"  class="form-control   " id="2048" name="2048" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2049"  class="form-control   " id="2049" name="2049" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="2051">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2052"  class="form-control   " id="2052" name="2052" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2055"  class="form-control   " id="2055" name="2055" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2056"  class="form-control   " id="2056" name="2056" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="2060">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2057" (blur)="clearInputIfInvalid('2057')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d73="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d73.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2057').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2057').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2057').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2057').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2057').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2059" (blur)="clearInputIfInvalid('2059')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d74="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d74.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2059').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2059').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2059').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2059').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2059').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <br />
            <hr class="black-line">
            <label><b> C. Other Ambulatory Surgery Center</b></label>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  >ASC Name:
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="2061"  class="form-control   " id="2061" name="2061" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2062"  class="form-control   " id="2062" name="2062" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2063"  class="form-control   " id="2063" name="2063" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="2064"  class="form-control   " id="2064" name="2064" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="2066">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="2067"  class="form-control   " id="2067" name="2067" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2070"  class="form-control   " id="2070" name="2070" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="2071"  class="form-control   " id="2071" name="2071" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Membership Status: </label>
            </div>
            <div class="col-md-3">
                 <ng-select [items]="staffStatus" bindLabel="name" placeholder="Category of appointment:" bindValue="id"
                    formControlName="2075">
                </ng-select>
            </div>
            <div class="col-md-1">
                <label  >Dates From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2072" (blur)="clearInputIfInvalid('2072')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d75="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d75.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2072').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2072').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2072').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2072').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2072').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="2074" (blur)="clearInputIfInvalid('2074')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d76="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d76.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('2074').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('2074').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2074').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('2074').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('2074').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <br />
            <hr class="black-line">
            <div class="row">
                <div class="col-md-8">
                    <label  > Check here if you have appended additional information
                        for this
                        section:
                    </label>
                    </div>
                    <div class="col-md-2" >
                      <input   formControlName="cmd_33518"  type="checkbox" id="cmd_33518" name="cmd_33518" value="1">
                    </div>
            </div>

            <br />
            <hr
            class="pdf-line">
            <table class="databox"
            >
            <tr>
                <td colspan="12" class="table-backround">
                    <div class="text-center">
                        <label ><b>SECTION H. WORK
                                HISTORY</b></label>
                    </div>
                </td>
            </tr>
            </table>
            <br />
            <div class="row mb-3"  >
            <div class="text-center col-md-12">
                <label ><b>List chronologically (most
                        recent
                        first) all work engagements (including employment, selfemployment, service as an independent
                        contractor, and military service). Do not duplicate internship, residency, and fellowship
                        information previously reported. If there is any gap of greater than 30 days in chronology,
                        explain
                        it on a separate page. </b>
                </label>
            </div>
            </div>
            <hr class="black-line">
            <div class="row mb-3"  >
            <div class="col-md-2">
                <label  ><b>Current work place:</b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="445"  class="form-control   " id="445" name="445" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="446"  class="form-control   " id="446" name="446" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="447"  class="form-control   " id="447" name="447" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="448"  class="form-control   " id="448" name="448" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="450">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="451"  class="form-control   " id="451" name="451" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="453"  class="form-control   " id="453" name="453" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="888"  class="form-control   " id="888" name="888" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Title or Professional Occupation:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1326"  class="form-control   " id="1326" name="1326" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  > Time in this employment: From: </label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="454" (blur)="clearInputIfInvalid('454')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d77="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d77.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('454').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('454').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('454').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('454').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('454').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >to Present</label>
            </div>
            </div>
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
            <div class="col-md-3">
                <label  ><b>Previous work place: </b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="456"  class="form-control   " id="456" name="456" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="457"  class="form-control   " id="457" name="457" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="458"  class="form-control   " id="458" name="458" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="459"  class="form-control   " id="459" name="459" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="461">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="462"  class="form-control   " id="462" name="462" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="464"  class="form-control   " id="464" name="464" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="890"  class="form-control   " id="890" name="890" type="text">
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Title or Professional Occupation:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1343"  class="form-control   " id="1343" name="1343" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-2">
                <label  > Time in this employment: From: </label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="465"  (blur)="clearInputIfInvalid('465')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d78="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d78.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('465').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('465').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('465').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('465').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('465').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="466"  (blur)="clearInputIfInvalid('466')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d79="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d79.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('466').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('466').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('466').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('466').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('466').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
            <div class="col-md-3">
                <label  ><b>Previous work place: </b>
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="467"  class="form-control   " id="467" name="467" type="text" >
            </div>
            </div>
            <div class="row ms-4 mb-3"  >
            <div class="col-md-1">
                <label  >Address:</label>
            </div>
            <div class="col-md-2">
                  <input   formControlName="468"  class="form-control   " id="468" name="468" type="text" placeholder="address1"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="469"  class="form-control   " id="469" name="469" type="text" placeholder="address2"
                     >
            </div>
            <div class="col-md-2">
                  <input   formControlName="470"  class="form-control   " id="470" name="470" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2" style="padding-top:1px">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select State"
                        bindValue="id" formControlName="472">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="473"  class="form-control   " id="473" name="473" type="text" placeholder="zip"
                   >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Telephone :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="475"  class="form-control   " id="475" name="475" type="text">
            </div>
            <div class="col-md-3">
                <label  > Fax Number :</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="892"  class="form-control   " id="892" name="892" type="text">
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-3">
                <label  > Title or Professional Occupation:</label>
            </div>
            <div class="col-md-3">
                  <input   formControlName="1339"  class="form-control   " id="1339" name="1339" type="text" >
            </div>
            </div>
            <div class="row  ms-4 mb-3"  >
            <div class="col-md-2">
                <label  > Time in this employment: From: </label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="476"  (blur)="clearInputIfInvalid('476')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d80="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d80.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('476').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('476').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('476').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('476').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('476').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="477"  (blur)="clearInputIfInvalid('477')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d81="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d81.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm.get('477').invalid ">
                    <small class="text-danger" *ngIf="erieForm.get('477').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('477').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm.get('477').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm.get('477').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            </div>
            <br />
            <hr class="black-line" >
            <br />
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="1935"  class="form-control   " id="1935" name="1935" type="text" >
                </div>
            </div>
            <div class="row  ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="1936"  class="form-control   " id="1936" name="1936" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="1937"  class="form-control   " id="1937" name="1937" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="1938"  class="form-control   " id="1938" name="1938" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="1940">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="1941"  class="form-control   " id="1941" name="1941" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1944"  class="form-control   " id="1944" name="1944" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1945"  class="form-control   " id="1945" name="1945" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1949"  class="form-control   " id="1949" name="1949" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="1947" (blur)="clearInputIfInvalid('1947')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d82="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d82.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1947').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1947').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1947').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1947').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1947').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="1948"  (blur)="clearInputIfInvalid('1948')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d83="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d83.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1948').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1948').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1948').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1948').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1948').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="1954"  class="form-control   " id="1954" name="1954" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="1955"  class="form-control   " id="1955" name="1955" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="1956"  class="form-control   " id="1956" name="1956" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="1957"  class="form-control   " id="1957" name="1957" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="1959">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="1960"  class="form-control   " id="1960" name="1960" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1963"  class="form-control   " id="1963" name="1963" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1964"  class="form-control   " id="1964" name="1964" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="1968"  class="form-control   " id="1968" name="1968" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="1966"  (blur)="clearInputIfInvalid('1966')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d84="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d84.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1966').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1966').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1966').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1966').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1966').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="1967"  (blur)="clearInputIfInvalid('1967')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d85="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d85.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('1967').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('1967').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1967').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('1967').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('1967').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <hr
                class="pdf-line">
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33473"  class="form-control   " id="cmd_33473" name="cmd_33473" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33474"  class="form-control   " id="cmd_33474" name="cmd_33474" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33475"  class="form-control   " id="cmd_33475" name="cmd_33475" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33476"  class="form-control   " id="cmd_33476" name="cmd_33476" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="cmd_33477">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33478"  class="form-control   " id="cmd_33478" name="cmd_33478" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33479"  class="form-control   " id="cmd_33479" name="cmd_33479" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33480"  class="form-control   " id="cmd_33480" name="cmd_33480" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33481"  class="form-control   " id="cmd_33481" name="cmd_33481" type="text"
                        >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33482" (blur)="clearInputIfInvalid('cmd_33482')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d86="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d86.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33482').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33482').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33482').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33482').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33482').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33483"  (blur)="clearInputIfInvalid('cmd_33483')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d87="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d87.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33483').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33483').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33483').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33483').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33483').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33484"  class="form-control   " id="cmd_33484" name="cmd_33484" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33485"  class="form-control   " id="cmd_33485" name="cmd_33485" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33486"  class="form-control   " id="cmd_33486" name="cmd_33486" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33487"  class="form-control   " id="cmd_33487" name="cmd_33487" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="cmd_33488">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33489"  class="form-control   " id="cmd_33489" name="cmd_33489" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33490"  class="form-control   " id="cmd_33490" name="cmd_33490" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33491"  class="form-control   " id="cmd_33491" name="cmd_33491" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33492"  class="form-control   " id="cmd_33492" name="cmd_33492" type="text"
                        >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33493"  (blur)="clearInputIfInvalid('cmd_33493')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d88="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d88.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33493').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33493').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33493').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33493').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33493').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33494" (blur)="clearInputIfInvalid('cmd_33494')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d89="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d89.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33494').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33494').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33494').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33494').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33494').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33495"  class="form-control   " id="cmd_33495" name="cmd_33495" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33496"  class="form-control   " id="cmd_33496" name="cmd_33496" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33497"  class="form-control   " id="cmd_33497" name="cmd_33497" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33498"  class="form-control  " id="cmd_33498" name="cmd_33498" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="cmd_33499">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33500"  class="form-control   " id="cmd_33500" name="cmd_33500" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33501"  class="form-control   " id="cmd_33501" name="cmd_33501" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33502"  class="form-control   " id="cmd_33502" name="cmd_33502" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33503"  class="form-control   " id="cmd_33503" name="cmd_33503" type="text"
                        >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33504"  (blur)="clearInputIfInvalid('cmd_33504')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d90="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d90.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33504').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33504').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33504').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33504').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33504').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33505"  (blur)="clearInputIfInvalid('cmd_33505')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d91="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d91.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33505').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33505').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33505').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33505').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33505').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <br />
            <div class="row mb-3"  >
                <div class="col-md-3">
                    <label  ><b>Previous work place: </b>
                    </label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33506"  class="form-control   " id="cmd_33506" name="cmd_33506" type="text" >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-1">
                    <label  >Address:</label>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33507"  class="form-control   " id="cmd_33507" name="cmd_33507" type="text" placeholder="address1"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33508"  class="form-control   " id="cmd_33508" name="cmd_33508" type="text" placeholder="address2"
                         >
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33509"  class="form-control   " id="cmd_33509" name="cmd_33509" type="text" placeholder="city"
                         >
                </div>
                <div class="col-md-2" style="padding-top:1px">
                    <div class="mb-3  "  >
                         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                            bindValue="id" formControlName="cmd_33510">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-2">
                      <input   formControlName="cmd_33511"  class="form-control   " id="cmd_33511" name="cmd_33511" type="text" placeholder="zip"
                       >
                </div>
            </div>
            <div class="row  ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Telephone :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33512"  class="form-control   " id="cmd_33512" name="cmd_33512" type="text">
                </div>
                <div class="col-md-3">
                    <label  > Fax Number :</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33513"  class="form-control   " id="cmd_33513" name="cmd_33513" type="text">
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-3">
                    <label  > Title or Professional Occupation:</label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33514"  class="form-control   " id="cmd_33514" name="cmd_33514" type="text"
                        >
                </div>
            </div>
            <div class="row ms-4 mb-3"  >
                <div class="col-md-2">
                    <label  > Time in this employment: From: </label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33515" (blur)="clearInputIfInvalid('cmd_33515')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d92="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d92.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33515').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33515').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33515').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33515').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33515').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-1">
                    <label  >To:</label>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                          <input   formControlName="cmd_33516" (blur)="clearInputIfInvalid('cmd_33516')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d93="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d93.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm.get('cmd_33516').invalid ">
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33516').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33516').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm.get('cmd_33516').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm.get('cmd_33516').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
            </div>
            <br />
            <hr class="black-line">
            <div class="row">
            <div class="col-md-10">
                <label  > Check here if you have appended additional information
                    for this
                    section:
                </label>
            </div>
            <div class="col-md-2" >
                  <input   formControlName="cmd_33517"  type="checkbox" id="cmd_33517" name="cmd_33517" value="1">
            </div>
        </div>
            <br />

            <hr
                class="pdf-line">
                <table class="databox"
               >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="row ">
                    <div class="col-md-12 text-center">
                         <label ><b> SECTION I.
                                    PROFESSIONAL
                                    REFERENCES</b></label>
                                </div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="text-center col-md-12">
                <h6 ><b> Please list the names of three individuals who have
                        personal
                        knowledge (within the past 12 months) of your current clinical abilities, ethical character and
                        interpersonal skills and who would be willing to provide this information upon request. Do not list
                        partners
                        or department chairpersons. Do not list relatives or people listed elsewhere in this credentialing
                        form.
                    </b>
                </h6>
            </div>
            <table class="databox" >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="text-left" >
                            <label ><b>CONFIDENTIAL
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-1">
                                <label  > <b>1.Name:</b></label>
                            </div>
                            <div class="col-md-4">
                                  <input   formControlName="359"  class="form-control   " id="359" name="359" type="text"
                                    [class.is-invalid]="(erieForm.controls['359'].touched && erieForm.controls['359'].errors)
                                    || (erieForm.controls['359'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['359'].touched && erieForm.controls['359'].errors)
                                     || (erieForm.controls['359'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['359'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <label  > <b>Title</b></label>
                            </div>
                            <div class="col-md-4">
                                  <input   formControlName="2722"  class="form-control   " id="2722" name="2722" type="text" placeholder="Title"

                                    [class.is-invalid]="(erieForm.controls['2722'].touched && erieForm.controls['2722'].errors)
                                    || (erieForm.controls['2722'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['2722'].touched && erieForm.controls['2722'].errors)
                                     || (erieForm.controls['2722'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['2722'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3  "  >
                            <div class="col-md-2">
                                <label  >Specialty: </label>
                            </div>
                            <div class="col-md-4">
                                 <ng-select [items]="specialty" bindLabel="name" placeholder="Select SPECIALTY"
                                    bindValue="id" formControlName="368"
                                    [class.is-invalid]="(erieForm.controls['368'].touched && erieForm.controls['368'].errors)
                                    || (erieForm.controls['368'].errors && isSubmit)" >
                                </ng-select>



                 <div class="formLabel"
                                *ngIf="(erieForm.controls['368'].touched && erieForm.controls['368'].errors)
                                 || (erieForm.controls['368'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['368'].errors">
                                    required
                                </small>
                            </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Mailing Address:</label>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="360"  class="form-control   " id="360" name="360" type="text" placeholder="address1"

                                     [class.is-invalid]="(erieForm.controls['360'].touched && erieForm.controls['360'].errors)
                                     || (erieForm.controls['360'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['360'].touched && erieForm.controls['360'].errors)
                                      || (erieForm.controls['360'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['360'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="361"  class="form-control   " id="361" name="361" type="text" placeholder="address2"

                                     [class.is-invalid]="(erieForm.controls['361'].touched && erieForm.controls['361'].errors)
                                     || (erieForm.controls['361'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['361'].touched && erieForm.controls['361'].errors)
                                      || (erieForm.controls['361'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['361'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="362"  class="form-control   " id="362" name="362" type="text" placeholder="city"

                                     [class.is-invalid]="(erieForm.controls['362'].touched && erieForm.controls['362'].errors)
                                     || (erieForm.controls['362'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['362'].touched && erieForm.controls['362'].errors)
                                      || (erieForm.controls['362'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['362'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2" style="padding-top:1px">
                                <div class="mb-3  "  >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                                        bindValue="id" formControlName="364"
                                        [class.is-invalid]="(erieForm.controls['364'].touched && erieForm.controls['364'].errors)
                                        || (erieForm.controls['364'].errors && isSubmit)">
                                    </ng-select>

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['364'].touched && erieForm.controls['364'].errors)
                                     || (erieForm.controls['364'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['364'].errors">
                                        required
                                    </small>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="365"  class="form-control   " id="365" name="365" type="text" placeholder="zip"

                                    [class.is-invalid]="(erieForm.controls['365'].touched && erieForm.controls['365'].errors)
                                    || (erieForm.controls['365'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['365'].touched && erieForm.controls['365'].errors)
                                     || (erieForm.controls['365'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['365'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Telephone </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="369"  class="form-control   " id="369" name="369" type="text"

                                     [class.is-invalid]="(erieForm.controls['369'].touched && erieForm.controls['369'].errors)
                                     || (erieForm.controls['369'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['369'].touched && erieForm.controls['369'].errors)
                                      || (erieForm.controls['369'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['369'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                <label  >Fax Number: </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="370"  class="form-control   " id="370" name="370" type="text"

                                     [class.is-invalid]="(erieForm.controls['370'].touched && erieForm.controls['370'].errors)
                                     || (erieForm.controls['370'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['370'].touched && erieForm.controls['370'].errors)
                                      || (erieForm.controls['370'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['370'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Relationship : </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="2721"  class="form-control   " id="2721" name="2721" type="text"

                                    [class.is-invalid]="(erieForm.controls['2721'].touched && erieForm.controls['2721'].errors)
                                    || (erieForm.controls['2721'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['2721'].touched && erieForm.controls['2721'].errors)
                                     || (erieForm.controls['2721'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['2721'].errors?.required">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label  > Years Known: </label>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                      <input   formControlName="366"  (blur)="clearInputIfInvalidMandi('366')" class="form-control"    placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d94="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['366'].touched && erieForm.controls['366'].errors)
                                || (erieForm.controls['366'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d94.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>


                                <div class="text-danger" *ngIf="(erieForm.controls['366'].invalid&&erieForm.controls['366'].touched) && (erieForm.controls['366'].value==''|| erieForm.controls['366'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('366').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('366').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('366').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('366').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-1">
                                <label  > <b>2.Name:</b></label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="372"  class="form-control   " id="372" name="372" type="text"

                                    [class.is-invalid]="(erieForm.controls['372'].touched && erieForm.controls['372'].errors)
                                    || (erieForm.controls['372'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['372'].touched && erieForm.controls['372'].errors)
                                     || (erieForm.controls['372'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['372'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label  >Title </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="2723"  class="form-control   " id="2723" name="2723" type="text"

                                     [class.is-invalid]="(erieForm.controls['2723'].touched && erieForm.controls['2723'].errors)
                                     || (erieForm.controls['2723'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['2723'].touched && erieForm.controls['2723'].errors)
                                      || (erieForm.controls['2723'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['2723'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Specialty: </label>
                            </div>
                            <div class="col-md-3">
                                 <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty"
                                    bindValue="id" formControlName="380"
                                    [class.is-invalid]="(erieForm.controls['380'].touched && erieForm.controls['380'].errors)
                                    || (erieForm.controls['380'].errors && isSubmit)" >
                                </ng-select>
                 <div class="formLabel"
                                *ngIf="(erieForm.controls['380'].touched && erieForm.controls['380'].errors)
                                 || (erieForm.controls['380'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['380'].errors">
                                    required
                                </small>
                            </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 ">
                            <div class="col-md-2">
                                <label  >Mailing Address:</label>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="373"  class="form-control   " id="373" name="373" type="text" placeholder="address1"
                                        [class.is-invalid]="(erieForm.controls['373'].touched && erieForm.controls['373'].errors)
                                     || (erieForm.controls['373'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['373'].touched && erieForm.controls['373'].errors)
                                      || (erieForm.controls['373'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['373'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="374"  class="form-control   " id="374" name="374" type="text" placeholder="address2"

                                     [class.is-invalid]="(erieForm.controls['374'].touched && erieForm.controls['374'].errors)
                                     || (erieForm.controls['374'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['374'].touched && erieForm.controls['374'].errors)
                                      || (erieForm.controls['374'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['374'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="375"  class="form-control   " id="375" name="375" type="text" placeholder="city"

                                     [class.is-invalid]="(erieForm.controls['375'].touched && erieForm.controls['375'].errors)
                                     || (erieForm.controls['375'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['375'].touched && erieForm.controls['375'].errors)
                                      || (erieForm.controls['375'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['375'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2" style="padding-top:1px">
                                <div class="mb-3  "  >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select State"
                                        bindValue="id" formControlName="377"
                                        [class.is-invalid]="(erieForm.controls['377'].touched && erieForm.controls['377'].errors)
                                        || (erieForm.controls['377'].errors && isSubmit)" >
                                    </ng-select>


                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['377'].touched && erieForm.controls['377'].errors)
                                     || (erieForm.controls['377'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['377'].errors">
                                        required
                                    </small>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="378"  class="form-control   " id="378" name="378" type="text" placeholder="zip"

                                    [class.is-invalid]="(erieForm.controls['378'].touched && erieForm.controls['378'].errors)
                                    || (erieForm.controls['378'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['378'].touched && erieForm.controls['378'].errors)
                                     || (erieForm.controls['378'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['378'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Telephone </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="381"  class="form-control   " id="381" name="381" type="text"

                                     [class.is-invalid]="(erieForm.controls['381'].touched && erieForm.controls['381'].errors)
                                     || (erieForm.controls['381'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['381'].touched && erieForm.controls['381'].errors)
                                      || (erieForm.controls['381'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['381'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                <label  >Fax Number: </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="382"  class="form-control   " id="382" name="382" type="text"

                                     [class.is-invalid]="(erieForm.controls['382'].touched && erieForm.controls['382'].errors)
                                     || (erieForm.controls['382'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['382'].touched && erieForm.controls['382'].errors)
                                      || (erieForm.controls['382'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['382'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-2">
                                <label  >Relationship : </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="2724"  class="form-control   " id="2724" name="2724" type="text"

                                    [class.is-invalid]="(erieForm.controls['2724'].touched && erieForm.controls['2724'].errors)
                                    || (erieForm.controls['2724'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['2724'].touched && erieForm.controls['2724'].errors)
                                     || (erieForm.controls['2724'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['2724'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label  > Years Known: </label>
                            </div>
                            <div class="col-md-3">
                               <div class="input-group">
                                      <input   formControlName="817" (blur)="clearInputIfInvalidMandi('817')"  class="form-control"    placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d95="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['817'].touched && erieForm.controls['817'].errors)
                                        || (erieForm.controls['817'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d95.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>


                                <div class="text-danger" *ngIf="(erieForm.controls['817'].invalid&&erieForm.controls['817'].touched) && (erieForm.controls['817'].value==''|| erieForm.controls['817'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('817').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('817').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('817').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('817').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-1">
                                <label  > <b>3.Name:</b></label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="384"  class="form-control   " id="384" name="384" type="text"

                                    [class.is-invalid]="(erieForm.controls['384'].touched && erieForm.controls['384'].errors)
                                    || (erieForm.controls['384'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['384'].touched && erieForm.controls['384'].errors)
                                     || (erieForm.controls['384'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['384'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label  >Title </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="2725"  class="form-control   " id="2725" name="2725" type="text"

                                     [class.is-invalid]="(erieForm.controls['2725'].touched && erieForm.controls['2725'].errors)
                                     || (erieForm.controls['2725'].errors && isSubmit)">

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['2725'].touched && erieForm.controls['2725'].errors)
                                      || (erieForm.controls['2725'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['2725'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-2">
                                <label  >Specialty: </label>
                            </div>
                            <div class="col-md-3">
                                 <ng-select [items]="specialty" bindLabel="name" placeholder="Select Specialty"
                                    bindValue="id" formControlName="393"
                                    [class.is-invalid]="(erieForm.controls['393'].touched && erieForm.controls['393'].errors)
                                    || (erieForm.controls['393'].errors && isSubmit)" >
                                </ng-select>


                 <div class="formLabel"
                                *ngIf="(erieForm.controls['393'].touched && erieForm.controls['393'].errors)
                                 || (erieForm.controls['393'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['393'].errors">
                                    required
                                </small>
                            </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-2">
                                <label  >Mailing Address:</label>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="385"  class="form-control   " id="385" name="385" type="text" placeholder="address1"

                                     [class.is-invalid]="(erieForm.controls['385'].touched && erieForm.controls['385'].errors)
                                     || (erieForm.controls['385'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['385'].touched && erieForm.controls['385'].errors)
                                      || (erieForm.controls['385'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['385'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="386"  class="form-control   " id="386" name="386" type="text" placeholder="address2"

                                     [class.is-invalid]="(erieForm.controls['386'].touched && erieForm.controls['386'].errors)
                                     || (erieForm.controls['386'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['386'].touched && erieForm.controls['386'].errors)
                                      || (erieForm.controls['386'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['386'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="387"  class="form-control   " id="387" name="387" type="text" placeholder="city"

                                     [class.is-invalid]="(erieForm.controls['387'].touched && erieForm.controls['387'].errors)
                                     || (erieForm.controls['387'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['387'].touched && erieForm.controls['387'].errors)
                                      || (erieForm.controls['387'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['387'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2" style="padding-top:1px">
                                <div class="mb-3  "  >
                                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select State"
                                        bindValue="id" formControlName="389"
                                        [class.is-invalid]="(erieForm.controls['389'].touched && erieForm.controls['389'].errors)
                                        || (erieForm.controls['389'].errors && isSubmit)" >
                                    </ng-select>


                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['389'].touched && erieForm.controls['389'].errors)
                                     || (erieForm.controls['389'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['389'].errors">
                                        required
                                    </small>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="390"  class="form-control   " id="390" name="390" type="text" placeholder="zip"

                                    [class.is-invalid]="(erieForm.controls['390'].touched && erieForm.controls['390'].errors)
                                    || (erieForm.controls['390'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['390'].touched && erieForm.controls['390'].errors)
                                     || (erieForm.controls['390'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['390'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3"  >
                            <div class="col-md-2">
                                <label  >Telephone </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="394"  class="form-control   " id="394" name="394" type="text"

                                     [class.is-invalid]="(erieForm.controls['394'].touched && erieForm.controls['394'].errors)
                                     || (erieForm.controls['394'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['394'].touched && erieForm.controls['394'].errors)
                                      || (erieForm.controls['394'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['394'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                            <div class="col-md-2">
                                <label  >Fax Number: </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="395"  class="form-control   " id="395" name="395" type="text"

                                     [class.is-invalid]="(erieForm.controls['395'].touched && erieForm.controls['395'].errors)
                                     || (erieForm.controls['395'].errors && isSubmit)" >

                      <div class="formLabel"
                                     *ngIf="(erieForm.controls['395'].touched && erieForm.controls['395'].errors)
                                      || (erieForm.controls['395'].errors && isSubmit)">
                                     <small class="text-danger" *ngIf="erieForm.controls['395'].errors">
                                         required
                                     </small>
                                 </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-backround">
                        <div class="row mb-3 "  >
                            <div class="col-md-2">
                                <label  >Relationship : </label>
                            </div>
                            <div class="col-md-3">
                                  <input   formControlName="2726"  class="form-control   " id="2726" name="2726" type="text"
                                    [class.is-invalid]="(erieForm.controls['2726'].touched && erieForm.controls['2726'].errors)
                                    || (erieForm.controls['2726'].errors && isSubmit)" >

                     <div class="formLabel"
                                    *ngIf="(erieForm.controls['2726'].touched && erieForm.controls['2726'].errors)
                                     || (erieForm.controls['2726'].errors && isSubmit)">
                                    <small class="text-danger" *ngIf="erieForm.controls['2726'].errors">
                                        required
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label  > Years Known: </label>
                            </div>
                            <div class="col-md-3">
                               <div class="input-group">
                                      <input   formControlName="391"  (blur)="clearInputIfInvalidMandi('391')" class="form-control"    placeholder="mm/dd/yyyy"
                                        ngbDatepicker #d96="ngbDatepicker" container="body"
                                        [class.is-invalid]="(erieForm.controls['391'].touched && erieForm.controls['391'].errors)
                                        || (erieForm.controls['391'].errors && isSubmit)" >
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d96.toggle()"
                                            type="button"><i class="fas fa-calendar-alt"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="(erieForm.controls['391'].invalid&&erieForm.controls['391'].touched) && (erieForm.controls['391'].value==''|| erieForm.controls['391'].value==null) " >
                                    <small >
                                    required
                                    </small>
                                  
                                  </div>
                                  <small class="text-danger" *ngIf="erieForm.get('391').errors?.invalidDateFormat">
                                    Only Date Format is allowed(MM/DD/YYYY)</small>
                                  <small class="text-danger" *ngIf="erieForm.get('391').errors?.invalidMonth">
                                  Month is out of range
                                  </small>
                                  <small class="text-danger" *ngIf="erieForm.get('391').errors?.invalidDateyear">
                                    year is out of range(1900-2099)                  </small>
                                    <small class="text-danger" *ngIf="erieForm.get('391').errors?.invalidDateRange">
                                      date is out of range
                                      </small>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <br />
            <hr
                class="pdf-line">
            <table class="databox"
               >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label
                                style="font-weight: normal;font-size: 14px;text-align:centre;    padding-bottom: 2px;"><b>
                                    SECTION J. PROFESSIONAL HISTORY: CONFIDENTIAL</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <table border="1"class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>ADVERSE
                                    OR
                                    OTHER ACTIONS</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="row mb-3 pt-4">
            <div class="col-md-12 text-left">
                <h6 ><b>
                        Submit with all applications. Please answer the following questions to the best of your knowledge
                        with a
                        “yes” or “no.” If you answer “yes” to any question(s) please complete Form A. Please make copies of
                        Form A
                        as needed and complete one form for each “yes” answer. </b>
                </h6>
            </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >1.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Has your license to practice in any jurisdiction
                        ever
                        been denied, restricted, limited,suspended, revoked, canceled and/or subject to probation either
                        voluntarily or involuntarily, or has your application for a license ever been withdrawn?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2 " >
                          <input   formControlName="cmd_33519"  type="radio"  name="cmd_33519" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33519"  type="radio"  name="cmd_33519" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33519'].touched && erieForm.controls['cmd_33519'].errors) ||
                            (erieForm.controls['cmd_33519'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33519'].touched && erieForm.controls['cmd_33519'].errors) ||
                            (erieForm.controls['cmd_33519'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >2.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have you ever been reprimanded and/or fined, been
                        the
                        subject of a complaint and/or have you been notified in writing that you have been investigated as
                        the
                        possible subject of a criminal, civil or disciplinary action by any state or federal agency which
                        licenses providers?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33520"  type="radio" name="cmd_33520" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33520"  type="radio"  name="cmd_33520" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33520'].touched && erieForm.controls['cmd_33520'].errors) ||
                            (erieForm.controls['cmd_33520'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33520'].touched && erieForm.controls['cmd_33520'].errors) ||
                            (erieForm.controls['cmd_33520'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >3.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have you lost any board certification(s), and/or
                        failed
                        to recertify?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33521"  type="radio"  name="cmd_33521" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33521"  type="radio"  name="cmd_33521" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33521'].touched && erieForm.controls['cmd_33521'].errors) ||
                            (erieForm.controls['cmd_33521'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33521'].touched && erieForm.controls['cmd_33521'].errors) ||
                            (erieForm.controls['cmd_33521'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >4.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have you been examined by a Certifying Board but
                        failed
                        to pass?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33522"  type="radio"  name="cmd_33522" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33522"  type="radio"  name="cmd_33522" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33522'].touched && erieForm.controls['cmd_33522'].errors) ||
                            (erieForm.controls['cmd_33522'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33522'].touched && erieForm.controls['cmd_33522'].errors) ||
                            (erieForm.controls['cmd_33522'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >5.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Has any information pertaining to you, including
                        malpractice judgments and/or disciplinary action, ever been reported to the National Practitioner
                        Data
                        Bank (NPDB) and/or any other practitioner data bank?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33523"  type="radio" name="cmd_33523" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33523"  type="radio"  name="cmd_33523" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33523'].touched && erieForm.controls['cmd_33523'].errors) ||
                            (erieForm.controls['cmd_33523'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33523'].touched && erieForm.controls['cmd_33523'].errors) ||
                            (erieForm.controls['cmd_33523'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >6.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >6. Has your federal DEA number and/or state
                        controlled
                        substances license been restricted, limited, relinquished, suspended or revoked, either voluntarily
                        or
                        involuntarily, and/or have you ever been notified in writing that you are being investigated as the
                        possible subject of a criminal or disciplinary action with respect to your DEA or controlled
                        substance
                        register?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33524"  type="radio"  name="cmd_33524" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33524"  type="radio" name="cmd_33524" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33524'].touched && erieForm.controls['cmd_33524'].errors) ||
                            (erieForm.controls['cmd_33524'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33524'].touched && erieForm.controls['cmd_33524'].errors) ||
                            (erieForm.controls['cmd_33524'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >7.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have you, or any of your hospital or ambulatory
                        surgery
                        center privileges and/or membership been denied, revoked, suspended, reduced, placed on probation,
                        proctored, placed under mandatory consultation or non-renewed?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33525"  type="radio"  name="cmd_33525" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33525"  type="radio"  name="cmd_33525" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33525'].touched && erieForm.controls['cmd_33525'].errors) ||
                            (erieForm.controls['cmd_33525'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33525'].touched && erieForm.controls['cmd_33525'].errors) ||
                            (erieForm.controls['cmd_33525'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>


            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >8.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > you voluntarily or involuntarily relinquished or
                        failed
                        to seek renewal of your hospital or ambulatory surgery center privileges for any reason?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33526"  type="radio" name="cmd_33526" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33526"  type="radio" name="cmd_33526" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33526'].touched && erieForm.controls['cmd_33526'].errors) ||
                            (erieForm.controls['cmd_33526'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33526'].touched && erieForm.controls['cmd_33526'].errors) ||
                            (erieForm.controls['cmd_33526'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >9.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have any disciplinary actions or proceedings been
                        instituted against you and/or are any disciplinary actions or proceedings now pending with respect
                        to
                        your hospital or aubulatory surgery center privileges and/or your license?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33527"  type="radio"  name="cmd_33527" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33527"  type="radio" name="cmd_33527" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33527'].touched && erieForm.controls['cmd_33527'].errors) ||
                            (erieForm.controls['cmd_33527'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33527'].touched && erieForm.controls['cmd_33527'].errors) ||
                            (erieForm.controls['cmd_33527'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >10.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have you ever been reprimanded, censured, excluded,
                        suspended and/or disqualified from participating, or voluntarily withdrawn to avoid an
                        investigation, in
                        Medicare, Medicaid, CHAMPUS and/or any other governmental health-related programs?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33528"  type="radio"  name="cmd_33528" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33528"  type="radio"  name="cmd_33528" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33528'].touched && erieForm.controls['cmd_33528'].errors) ||
                            (erieForm.controls['cmd_33528'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33528'].touched && erieForm.controls['cmd_33528'].errors) ||
                            (erieForm.controls['cmd_33528'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >11.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have Medicare, Medicaid, CHAMPUS, PRO authorities
                        and/or
                        any other third party payors brought charges against you for alleged inappropriate fees and/or
                        quality-of-care issues?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33529"  type="radio"  name="cmd_33529" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33529"  type="radio" name="cmd_33529" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33529'].touched && erieForm.controls['cmd_33529'].errors) ||
                            (erieForm.controls['cmd_33529'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33529'].touched && erieForm.controls['cmd_33529'].errors) ||
                            (erieForm.controls['cmd_33529'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <hr
                class="pdf-line">
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >12.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Have you been denied membership and/or been subject
                        to
                        probation, reprimand,sanction or disciplinary action, or have you ever been notified in writing that
                        you
                        are being investigated as the possible subject of a criminal or disciplinary action by any health
                        care
                        organization, e.g. hospital, HMO, PPO, IPA, professional group or society, licensing board,
                        certification board ,PSRO, or PRO?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33530"  type="radio"  name="cmd_33530" value="0">Yes</label>
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33530"  type="radio" name="cmd_33530" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33530'].touched && erieForm.controls['cmd_33530'].errors) ||
                            (erieForm.controls['cmd_33530'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33530'].touched && erieForm.controls['cmd_33530'].errors) ||
                            (erieForm.controls['cmd_33530'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >13.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have you withdrawn an application or any portion of
                        an
                        application for appointment or reappointment for clinical privileges or staff appointment or for a
                        license or membership in an IPA, PHO, professional group or society, health care entity or health
                        care
                        plan prior to a final decision to avoid a professional review or an adverse decision?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33531"  type="radio" name="cmd_33531" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33531"  type="radio"  name="cmd_33531" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33531'].touched && erieForm.controls['cmd_33531'].errors) ||
                            (erieForm.controls['cmd_33531'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33531'].touched && erieForm.controls['cmd_33531'].errors) ||
                            (erieForm.controls['cmd_33531'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <br />
            <br />
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>PROFESSIONAL
                                    LIABILITY ACTIONS</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4">
                <h6><b>
                        If you answer yes to any question(s) in this section please complete FORM B. Please make copies of
                        FORM B if
                        needed, and complete one for each yes answer. </b>
                </h6>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >1.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have any professional liability judgments ever been
                        entered against you?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33532"  type="radio"  name="cmd_33532" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33532"  type="radio" name="cmd_33532" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33532'].touched && erieForm.controls['cmd_33532'].errors) ||
                            (erieForm.controls['cmd_33532'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33532'].touched && erieForm.controls['cmd_33532'].errors) ||
                            (erieForm.controls['cmd_33532'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>

            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >2.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have any professional liability claim settlements
                        ever
                        been paid by you and/or paid on your behalf?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33533"  type="radio"  name="cmd_33533" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33533"  type="radio"  name="cmd_33533" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33533'].touched && erieForm.controls['cmd_33533'].errors) ||
                            (erieForm.controls['cmd_33533'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33533'].touched && erieForm.controls['cmd_33533'].errors) ||
                            (erieForm.controls['cmd_33533'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >3.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Are there any currently pending professional
                        liability
                        suits, actions and/or claims filed against you?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33534"  type="radio"  name="cmd_33534" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33534"  type="radio" name="cmd_33534" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33534'].touched && erieForm.controls['cmd_33534'].errors) ||
                            (erieForm.controls['cmd_33534'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33534'].touched && erieForm.controls['cmd_33534'].errors) ||
                            (erieForm.controls['cmd_33534'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  > 4.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Has any person or entity ever been sued for your
                        clinical actions?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33535"  type="radio"  name="cmd_33535" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33535"  type="radio" name="cmd_33535" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33535'].touched && erieForm.controls['cmd_33535'].errors) ||
                            (erieForm.controls['cmd_33535'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33535'].touched && erieForm.controls['cmd_33535'].errors) ||
                            (erieForm.controls['cmd_33535'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <br />
            <br />
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>LIABILITY
                                    INSURANCE</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4">
                <h6 ><b>
                        If you answer yes to this question please complete FORM C. </b>
                </h6>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-9">
                    <label  >Have you ever been denied or voluntarily relinquished
                        your professional liability insurance coverage, and/or have had your professional liability
                        insurance
                        coverage canceled, nonrenewed or limits reduced ?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33536"  type="radio" name="cmd_33536" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33536"  type="radio" name="cmd_33536" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33536'].touched && erieForm.controls['cmd_33536'].errors) ||
                            (erieForm.controls['cmd_33536'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33536'].touched && erieForm.controls['cmd_33536'].errors) ||
                            (erieForm.controls['cmd_33536'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>CRIMINAL
                                    ACTIONS</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4">
                <h6><b>
                        If you answer yes to any question(s) in this section please complete FORM D. Please make copies of
                        FORM D if
                        needed, and complete one for each yes answer. </b>
                </h6>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >1.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have you been charged with or convicted of a crime
                        (other
                        than a minor traffic
                        offense) in this or any other state or country and/or do you have any criminal charges pending other
                        than minor traffic offenses in this state or any other state or country?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33537"  type="radio"  name="cmd_33537" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33537"  type="radio"  name="cmd_33537" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33537'].touched && erieForm.controls['cmd_33537'].errors) ||
                            (erieForm.controls['cmd_33537'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33537'].touched && erieForm.controls['cmd_33537'].errors) ||
                            (erieForm.controls['cmd_33537'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >2.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Have you been the subject of a civil or criminal
                        complaint or administrative action or been notified in writing that you are being investigated as
                        the
                        possible subject at a civil, criminal or administrative action regarding sexual misconduct, child
                        abuse,
                        domestic violence or elder abuse?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-3" >
                          <input   formControlName="cmd_33538"  type="radio" name="cmd_33538" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33538"  type="radio"  name="cmd_33538" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33538'].touched && erieForm.controls['cmd_33538'].errors) ||
                            (erieForm.controls['cmd_33538'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33538'].touched && erieForm.controls['cmd_33538'].errors) ||
                            (erieForm.controls['cmd_33538'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <br />
            <br />
            <hr
                class="pdf-line">
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>MEDICAL
                                    CONDITION</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4">
                <h6 ><b>
                        If you answer yes to this question please complete FORM E. </b>
                </h6>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-8">
                    <label  >Do you have a medical condition, physical defect or
                        emotional impairment which in any way impairs and/or limits your ability to practice medicine with
                        reasonable skill and safety?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33539"  type="radio"  name="cmd_33539" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33539"  type="radio"  name="cmd_33539" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33539'].touched && erieForm.controls['cmd_33539'].errors) ||
                            (erieForm.controls['cmd_33539'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33539'].touched && erieForm.controls['cmd_33539'].errors) ||
                            (erieForm.controls['cmd_33539'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <br />
            <br />
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label ><b>CHEMICAL
                                    SUBSTANCES OR ALCOHOL ABUSE</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="col-md-12 pt-4">
                <h6><b>
                        If you answer yes to any question(s) in this section please complete FORM F. Please make copies of
                        FORM F if
                        needed, and complete one for each yes answer. </b>
                </h6>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >1.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Are you currently engaged in illegal use of any legal
                        or
                        illegal substances?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33540"  type="radio" name="cmd_33540" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33540"  type="radio"  name="cmd_33540" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33540'].touched && erieForm.controls['cmd_33540'].errors) ||
                            (erieForm.controls['cmd_33540'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33540'].touched && erieForm.controls['cmd_33540'].errors) ||
                            (erieForm.controls['cmd_33540'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >2.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  > Do you currently overuse and/or abuse alcohol or any
                        other controlled substances?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33541"  type="radio" name="cmd_33541" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33541"  type="radio"  name="cmd_33541" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33541'].touched && erieForm.controls['cmd_33541'].errors) ||
                            (erieForm.controls['cmd_33541'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33541'].touched && erieForm.controls['cmd_33541'].errors) ||
                            (erieForm.controls['cmd_33541'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  >3.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >If you use alcohol and/or chemical substances, does
                        your
                        use in any way impair and/or limit your ability to practice medicine with reasonable skill and
                        safety?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33542"  type="radio"  name="cmd_33542" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33542"  type="radio" name="cmd_33542" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33542'].touched && erieForm.controls['cmd_33542'].errors) ||
                            (erieForm.controls['cmd_33542'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33542'].touched && erieForm.controls['cmd_33542'].errors) ||
                            (erieForm.controls['cmd_33542'].errors && isSubmit)">
                               <i class="fas fa-times ms-2 text-danger"></i>
                           </span>
                                        </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-1">
                    <label  > 4.
                    </label>
                </div>
                <div class="col-md-8">
                    <label  >Are you currently participating in a supervised
                        rehabilitation program and/or professional assistance program which monitors you for alcohol and/or
                        substance abuse?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33543"  type="radio"  name="cmd_33543" value="0">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33543"  type="radio"  name="cmd_33543" value="1">No</label>
                </div>
                <div class="formLabel col-md-1"
                             *ngIf="(erieForm.controls['cmd_33543'].touched && erieForm.controls['cmd_33543'].errors) ||
                            (erieForm.controls['cmd_33543'].errors && isSubmit)">
                            <span *ngIf="(erieForm.controls['cmd_33543'].touched && erieForm.controls['cmd_33543'].errors) ||
                                         (erieForm.controls['cmd_33543'].errors && isSubmit)">
                                            <i class="fas fa-times ms-2 text-danger"></i>
                                        </span>
                                        </div>

            </div>
            <br />
            <br />
            <br />
            <table border="1" class="sub-databox">
                <tr>
                    <td colspan="12" class="table-backround">
                        <div >
                            <label
                                ><b>INVESTMENTS</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div class="row mb-3"  >
                <div class="col-md-9">
                    <label  >In the last five (5) years have you and/or a member
                        of
                        your family purchased or made an investment in (other than securities of a publicly traded company),
                        or
                        otherwise have a business interest in any clinical laboratory, diagnostic or testing center,
                        hospital,
                        surgicenter, and/or other business dealing with the provision of ancillary health services,
                        equipment or
                        supplies?
                    </label>
                </div>
                <div class="mb-3 col-md-2"  >
                    <label class="checkbox-inline me-2" >
                          <input   formControlName="cmd_33544"  (ngModelChange)="onchangeaction14($event)"  type="radio" name="cmd_33544" value="1">Yes</label>
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33544"  type="radio" (ngModelChange)="onchangeaction14($event)"  name="cmd_33544" value="0">No</label>
                </div>

                <div class="formLabel col-md-1"
         *ngIf="(erieForm.controls['cmd_33544'].touched && erieForm.controls['cmd_33544'].errors) ||
        (erieForm.controls['cmd_33544'].errors && isSubmit)">
        <span *ngIf="(erieForm.controls['cmd_33544'].touched && erieForm.controls['cmd_33544'].errors) ||
        (erieForm.controls['cmd_33544'].errors && isSubmit)">
           <i class="fas fa-times ms-2 text-danger"></i>
       </span>
                    </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-8">
                    <label  >If Yes, please provide explanation:
                    </label>
                </div>
                <div class="col-md-12">
                    <textarea rows="8" cols="80"  formControlName="cmd_33545" name="cmd_33545"
                    [class.is-invalid]="(erieForm.controls['cmd_33545'].touched && erieForm.controls['cmd_33545'].errors)
                    || (erieForm.controls['cmd_33545'].errors && ischangeAction14)" ></textarea>

        <div class="formLabel"
                    *ngIf="(erieForm.controls['cmd_33545'].touched && erieForm.controls['cmd_33545'].errors)
                     || (erieForm.controls['cmd_33545'].errors && ischangeAction14)">
                    <small class="text-danger" *ngIf="(erieForm.controls['cmd_33545'].touched && erieForm.controls['cmd_33545'].errors)
                    || (erieForm.controls['cmd_33545'].errors && ischangeAction14)">
                        required
                    </small>
                </div>
                </div>


            </div>

            <hr
                class="pdf-line">
            <br />
            <br />
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label style="font-weight: normal;font-size: 24px;padding-bottom: 2px;"><b>CHAPTER B:BUSINESS
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <table class="databox"
                >
                <tr>
                    <td colspan="12" class="table-backround">
                        <div  class="text-center">
                            <label ><b>SECTION K. PRIMARY
                                    SITE
                                    INFORMATION</b></label>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="row"  >
                <div class="col-md-12">
                    <label  ><b>Please provide the following information for the
                            primary
                            site at which you practice. </b>
                    </label>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-2">
                   <div class="primary-site">

                    <label><b>Primary Site</b></label>
                   </div>
                </div>
                <div class="col-md-10">
                    <div class="row mb-3 ">
                        <div class="col-md-12">
                            <div  >
                                 <ng-select [items]="locationList" bindLabel="practice_name" placeholder="Select locations"
                                    bindValue="location_id" formControlName="cmd_100040"
                                    (ngModelChange)="onChangeLocation1($event)"
                                    [class.is-invalid]="(erieForm.controls['cmd_100040'].touched && erieForm.controls['cmd_100040'].errors)
                                    || (erieForm.controls['cmd_100040'].errors && isSubmit)" >
                                </ng-select>
                            </div>
             <div class="formLabel"
                            *ngIf="(erieForm.controls['cmd_100040'].touched && erieForm.controls['cmd_100040'].errors)
                             || (erieForm.controls['cmd_100040'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['cmd_100040'].errors">
                                required
                            </small>
                        </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                            <label>Group/Business Name</label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                              <input   formControlName="cmd_33547"  class="form-control   append_practicename" id="cmd_33547" name="cmd_33547"
                                type="text"
                                  [class.is-invalid]="(erieForm.controls['cmd_33547'].touched && erieForm.controls['cmd_33547'].errors)
                                    || (erieForm.controls['cmd_33547'].errors && isSubmit)">
                                 <div class="formLabel"
                            *ngIf="(erieForm.controls['cmd_33547'].touched && erieForm.controls['cmd_33547'].errors)
                             || (erieForm.controls['cmd_33547'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['cmd_33547'].errors">
                                required
                            </small>
                        </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                            <label>Building Name</label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                              <input   formControlName="cmd_33548"  class="form-control   append_add_21" id="cmd_33548" name="cmd_33548" type="text"
                                [class.is-invalid]="(erieForm.controls['cmd_33548'].touched && erieForm.controls['cmd_33548'].errors)
                                    || (erieForm.controls['cmd_33548'].errors && isSubmit)">
                                 <div class="formLabel"
                            *ngIf="(erieForm.controls['cmd_33548'].touched && erieForm.controls['cmd_33548'].errors)
                             || (erieForm.controls['cmd_33548'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['cmd_33548'].errors">
                                required
                            </small>
                        </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                            <label>Office Address – Number and Street – Suite</label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-3">
                              <input   formControlName="cmd_33549"  class="form-control   append_city_2" id="cmd_33549" name="cmd_33549" type="text"
                                placeholder="city"
                                  [class.is-invalid]="(erieForm.controls['cmd_33549'].touched && erieForm.controls['cmd_33549'].errors)
                                    || (erieForm.controls['cmd_33549'].errors && isSubmit)">
                                 <div class="formLabel"
                            *ngIf="(erieForm.controls['cmd_33549'].touched && erieForm.controls['cmd_33549'].errors)
                             || (erieForm.controls['cmd_33549'].errors && isSubmit)">
                            <small class="text-danger" *ngIf="erieForm.controls['cmd_33549'].errors">
                                required
                            </small>
                        </div>
                        </div>
                        <div class="col-md-3">
                              <input   formControlName="cmd_33550"  class="form-control    append_county" id="cmd_33550" name="cmd_33550" type="text"
                                placeholder="County"
                                [class.is-invalid]="(erieForm.controls['cmd_33550'].touched && erieForm.controls['cmd_33550'].errors)
                                || (erieForm.controls['cmd_33550'].errors && isSubmit)" >

                 <div class="formLabel"
                                *ngIf="(erieForm.controls['cmd_33550'].touched && erieForm.controls['cmd_33550'].errors)
                                 || (erieForm.controls['cmd_33550'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['cmd_33550'].errors">
                                    required
                                </small>
                            </div>
                        </div>
                        <div class="col-md-3" style="padding-top:1px">
                            <div class="mb-3  "  >
                                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select State"
                                    bindValue="id" formControlName="cmd_33551"   [class.is-invalid]="(erieForm.controls['cmd_33551'].touched && erieForm.controls['cmd_33551'].errors)
                                || (erieForm.controls['cmd_33551'].errors && isSubmit)" >
                                </ng-select>



                 <div class="formLabel"
                                *ngIf="(erieForm.controls['cmd_33551'].touched && erieForm.controls['cmd_33551'].errors)
                                 || (erieForm.controls['cmd_33551'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['cmd_33551'].errors">
                                    required
                                </small>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                              <input   formControlName="cmd_33552"  class="form-control   append_zip_2" id="cmd_33552" name="cmd_33552" type="text"
                                placeholder="zip"
                                [class.is-invalid]="(erieForm.controls['cmd_33552'].touched && erieForm.controls['cmd_33552'].errors)
                                || (erieForm.controls['cmd_33552'].errors && isSubmit)" >

                 <div class="formLabel"
                                *ngIf="(erieForm.controls['cmd_33552'].touched && erieForm.controls['cmd_33552'].errors)
                                 || (erieForm.controls['cmd_33552'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['cmd_33552'].errors">
                                    required
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row "  >
                        <div class="col-md-3">
                            <label>City </label>
                        </div>
                        <div class="col-md-3">
                            <label>County</label>
                        </div>
                        <div class="col-md-3" style="padding-top:1px">
                            <label>State</label>
                        </div>
                        <div class="col-md-3">
                            <label>Zip</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33553"  class="form-control   append_phone_2" id="cmd_33553" name="cmd_33553" type="text"
                        placeholder="telephone"
                         [class.is-invalid]="(erieForm.controls['cmd_33553'].touched && erieForm.controls['cmd_33553'].errors)
                                || (erieForm.controls['cmd_33553'].errors && isSubmit)" >

                 <div class="formLabel"
                                *ngIf="(erieForm.controls['cmd_33553'].touched && erieForm.controls['cmd_33553'].errors)
                                 || (erieForm.controls['cmd_33553'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['cmd_33553'].errors">
                                    required
                                </small>
                            </div>
                </div>
                <div class="col-md-6">
                      <input   formControlName="cmd_33554"  class="form-control   append_office_manager_first_name_2" id="cmd_33554" name="cmd_33554"
                        type="text" placeholder="Name"
                         [class.is-invalid]="(erieForm.controls['cmd_33554'].touched && erieForm.controls['cmd_33554'].errors)
                                || (erieForm.controls['cmd_33554'].errors && isSubmit)" >

                 <div class="formLabel"
                                *ngIf="(erieForm.controls['cmd_33554'].touched && erieForm.controls['cmd_33554'].errors)
                                 || (erieForm.controls['cmd_33554'].errors && isSubmit)">
                                <small class="text-danger" *ngIf="erieForm.controls['cmd_33554'].errors">
                                    required
                                </small>
                            </div>
                </div>
            </div>
            <div class="row "  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                    <label>Main Telephone Number </label>
                </div>
                <div class="col-md-6">
                    <label>Office Administrator – Last First MI</label>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33555"  class="form-control   " id="cmd_33555" name="cmd_33555" type="text"
                        placeholder="Beeper Number"
                        [class.is-invalid]="(erieForm.controls['cmd_33555'].touched && erieForm.controls['cmd_33555'].errors)
                        || (erieForm.controls['cmd_33555'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33555'].touched && erieForm.controls['cmd_33555'].errors)
                         || (erieForm.controls['cmd_33555'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33555'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33556"  class="form-control   append_fax_2" id="cmd_33556" name="8cmd_33556" type="text"
                        placeholder="FAX Number"
                        [class.is-invalid]="(erieForm.controls['cmd_33556'].touched && erieForm.controls['cmd_33556'].errors)
                        || (erieForm.controls['cmd_33556'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33556'].touched && erieForm.controls['cmd_33556'].errors)
                         || (erieForm.controls['cmd_33556'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33556'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33557"  class="form-control   " id="cmd_33557" name="cmd_33557" type="text" placeholder="E-mail"
                        [class.is-invalid]="(erieForm.controls['cmd_33557'].touched && erieForm.controls['cmd_33557'].errors)
                        || (erieForm.controls['cmd_33557'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33557'].touched && erieForm.controls['cmd_33557'].errors)
                         || (erieForm.controls['cmd_33557'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33557'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                    <label>Beeper Number </label>
                </div>
                <div class="col-md-3">
                    <label> FAX Number </label>
                </div>
                <div class="col-md-3">
                    <label> E-mail</label>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33558"  class="form-control   " id="cmd_33558" name="cmd_33558" type="text"

                        [class.is-invalid]="(erieForm.controls['cmd_33558'].touched && erieForm.controls['cmd_33558'].errors)
                        || (erieForm.controls['cmd_33558'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33558'].touched && erieForm.controls['cmd_33558'].errors)
                         || (erieForm.controls['cmd_33558'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33558'].errors">
                            required
                        </small>
                    </div>
                </div>
                <div class="col-md-6">
                      <input   formControlName="cmd_33559"  class="form-control   answering_service_phone" id="cmd_33559" name="cmd_33559" type="text"
                        placeholder="AnsweringService"

                        [class.is-invalid]="(erieForm.controls['cmd_33559'].touched && erieForm.controls['cmd_33559'].errors)
                        || (erieForm.controls['cmd_33559'].errors && isSubmit)" >

         <div class="formLabel"
                        *ngIf="(erieForm.controls['cmd_33559'].touched && erieForm.controls['cmd_33559'].errors)
                         || (erieForm.controls['cmd_33559'].errors && isSubmit)">
                        <small class="text-danger" *ngIf="erieForm.controls['cmd_33559'].errors">
                            required
                        </small>
                    </div>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                    <label>Emergency Number </label>
                </div>
                <div class="col-md-6">
                    <label> Answering Service</label>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-4">
                    <label>Specialty practiced at this site: </label>
                </div>
                <div class="col-md-6">
                      <input   formControlName="cmd_33560"  class="form-control   " id="cmd_33560" name="cmd_33560" type="text"
                      [class.is-invalid]="(erieForm.controls['cmd_33560'].touched && erieForm.controls['cmd_33560'].errors)
                      || (erieForm.controls['cmd_33560'].errors && isSubmit)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33560'].touched && erieForm.controls['cmd_33560'].errors)
                       || (erieForm.controls['cmd_33560'].errors && isSubmit)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33560'].errors">
                          required
                      </small>
                  </div>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-7">
                    <label  >Is your practice restricted within your specialty
                        (e.g.,
                        by age or type of patient)? </label>
                </div>
                <div class="mb-3 col-md-1"  >
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33561" (ngModelChange)="onchangeaction15($event)"    type="radio" name="cmd_33561" value="1">Yes</label>
                </div>
                <div class="mb-3 col-md-1"  >
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33561"  (ngModelChange)="onchangeaction15($event)"  type="radio" name="cmd_33561" value="0">No</label>
                </div>

         <div class="formLabel col-md-1"
         *ngIf="(erieForm.controls['cmd_33561'].touched && erieForm.controls['cmd_33561'].errors) ||
        (erieForm.controls['cmd_33561'].errors && isSubmit)">
        <span *ngIf="(erieForm.controls['cmd_33561'].touched && erieForm.controls['cmd_33561'].errors) ||
        (erieForm.controls['cmd_33561'].errors && isSubmit)">
           <i class="fas fa-times ms-2 text-danger"></i>
       </span>
                    </div>

            </div>
            <div class="row mb-3"  >
                <div class="col-md-8">
                    <label  >If Yes, describe the restrictions:
                    </label>
                </div>
                <div class="col-md-12">
                      <input   formControlName="cmd_33562"  type="text" class="form-control" id="cmd_33562" name="cmd_33562"
                      [class.is-invalid]="(erieForm.controls['cmd_33562'].touched && erieForm.controls['cmd_33562'].errors)
                      || (erieForm.controls['cmd_33562'].errors && ischangeAction15)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33562'].touched && erieForm.controls['cmd_33562'].errors)
                       || (erieForm.controls['cmd_33562'].errors && ischangeAction15)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33562'].errors">
                          required
                      </small>
                  </div>
                    </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-10">
                    <label  >Briefly describe your practice at this location,
                        including any special practice focus or equipment:
                    </label>
                </div>
                <div class="col-md-12">
                      <input   formControlName="cmd_33563"  type="text" class="form-control" id="cmd_33563" name="cmd_33563"
                      [class.is-invalid]="(erieForm.controls['cmd_33563'].touched && erieForm.controls['cmd_33563'].errors)
                      || (erieForm.controls['cmd_33563'].errors && isSubmit)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33563'].touched && erieForm.controls['cmd_33563'].errors)
                       || (erieForm.controls['cmd_33563'].errors && isSubmit)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33563'].errors">
                          required
                      </small>
                  </div>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-8">
                    <label  >Are you currently accepting new patients at this
                        location?
                    </label>
                </div>
                <div class="mb-3 col-md-1"  >
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33564" (ngModelChange)="onchangeaction16($event)"   type="radio"  name="cmd_33564" value="1">Yes</label>
                </div>
                <div class="mb-3  col-md-1"  >
                    <label class="checkbox-inline" >
                          <input   formControlName="cmd_33564"  (ngModelChange)="onchangeaction16($event)"  type="radio"  name="cmd_33564" value="1">No</label>
                </div>

         <div class="formLabel col-md-1"
         *ngIf="(erieForm.controls['cmd_33564'].touched && erieForm.controls['cmd_33564'].errors) ||
        (erieForm.controls['cmd_33564'].errors && isSubmit)">
        <span *ngIf="(erieForm.controls['cmd_33564'].touched && erieForm.controls['cmd_33564'].errors) ||
        (erieForm.controls['cmd_33564'].errors && isSubmit)">
           <i class="fas fa-times ms-2 text-danger"></i>
       </span>
                    </div>


            </div>
            <div class="row mb-3"  >
                <div class="col-md-10">
                    <label  > If yes, describe any restrictions (e.g., appointment
                        type, patient type):
                    </label>
                </div>
                <div class="col-md-12">
                      <input   formControlName="cmd_33565"  type="text" class="form-control" id="cmd_33565" name="cmd_33565"
                      [class.is-invalid]="(erieForm.controls['cmd_33565'].touched && erieForm.controls['cmd_33565'].errors)
                      || (erieForm.controls['cmd_33565'].errors && ischangeAction16)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33565'].touched && erieForm.controls['cmd_33565'].errors)
                       || (erieForm.controls['cmd_33565'].errors && ischangeAction16)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33565'].errors">
                          required
                      </small>
                  </div>
                    </div>
            </div>
            <div class="row mb-3 "  >
                <div class="col-md-8">
                    <label  > Please provide the number of active patients
                        enrolled
                        with you at this site:
                    </label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33566"  type="text" class="form-control" id="cmd_33566" name="cmd_33566"
                      [class.is-invalid]="(erieForm.controls['cmd_33566'].touched && erieForm.controls['cmd_33566'].errors)
                      || (erieForm.controls['cmd_33566'].errors && isSubmit)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33566'].touched && erieForm.controls['cmd_33566'].errors)
                       || (erieForm.controls['cmd_33566'].errors && isSubmit)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33566'].errors">
                          required
                      </small>
                  </div>
                </div>
            </div>
            <div class="row mb-3"  >
                <div class="col-md-8">
                    <label  >Please provide the number of patient visits you have
                        at
                        this site per year:
                    </label>
                </div>
                <div class="col-md-3">
                      <input   formControlName="cmd_33567"  type="text" class="form-control " id="cmd_33567" name="cmd_33567"
                      [class.is-invalid]="(erieForm.controls['cmd_33567'].touched && erieForm.controls['cmd_33567'].errors)
                      || (erieForm.controls['cmd_33567'].errors && isSubmit)" >

        <div class="formLabel"
                      *ngIf="(erieForm.controls['cmd_33567'].touched && erieForm.controls['cmd_33567'].errors)
                       || (erieForm.controls['cmd_33567'].errors && isSubmit)">
                      <small class="text-danger" *ngIf="erieForm.controls['cmd_33567'].errors">
                          required
                      </small>
                  </div>
                </div>
            </div>
            <label class="pt-2"><b>
                    Indicate your office schedule at this location in the following table. Write your specific hours in the
                    appropriate spaces for each day:</b>
            </label>
            <table border="1" class="pt-4 " style="width:80%;margin-left:5rem">
                <th>
                </th>
                <th>
                    Monday
                </th>
                <th>
                    Tuesday
                </th>
                <th>
                    Wednesday
                </th>
                <th>
                    Thursday
                </th>
                <th>
                    Friday
                </th>
                <th>
                    Saturday
                </th>
                <th> Sunday </th>
                <tr>
                    <td><b>Hours</b></td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33568"  class="form-control" type="text" id="cmd_33568" name="mondaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33569"  class="form-control" type="text" id="cmd_33569" name="Tuesdaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33570"  class="form-control" type="text" id="cmd_33570" name="wednesdaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33571"  class="form-control" type="text" id="cmd_33571" name="Thursdaydaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33572"  class="form-control" type="text" id="cmd_33572" name="Firdaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33573"  class="form-control" type="text" id="cmd_33573" name="Saturdaytext">
                        </div>
                    </td>
                    <td> to
                        <div col-sm-1>
                              <input   formControlName="cmd_33574"  class="form-control" type="text" id="cmd_33574" name="sundaytext">
                        </div>
                    </td>
                </tr>
            </table>
        
                <ctg-erie-form-section1 
                [erieForm1]="erieForm"
                [isSubmit]="isSubmit"
                (involvementData)="handleInvolvementData($event)"
                (racechange)="handleRacechange($event)"
                (licenseNumber)="handleLicenseNumber($event)"
                (licenseState)="handleLicenseState($event)"
                (onChangeLocation)="onChangeLocation2($event)"
                (changeaction18)="onchangeaction18($event)"
                (changeaction17)="onchangeaction17($event)"
                (changeaction21)="onchangeaction18($event)"
                (esignopenevent)="esignOpen($event)"
                [ischangeAction21]="ischangeAction21"
                [ischangeAction18]="ischangeAction18"
                [ischangeAction17]="ischangeAction17"
  [show2]="show2"
  [show3]="show3"
  [show4]="show4"
  [show5]="show5"
  [show6]="show6"
  [show7]="show7"
  [show8]="show8"
  [show9]="show9"
  [show10]="show10"
  [show11]="show11"
  [show12]="show12"
  [show13]="show13"
  [show14]="show14"
  [show15]="show15"
  [show16]="show16"
  [show17]="show17"
  [show18]="show18"
  [show19]="show19"
  [show20]="show20"
  [show21]="show21"
  [show22]="show22"
  [esign2]="esign2"
  [esign3]="esign3"
  [esign4]="esign4"
  [esign5]="esign5"
  [esign6]="esign6"
  [esign7]="esign7"
  [esign8]="esign8"
  [esign9]="esign9"
  [esign10]="esign10"
  [esign11]="esign11"
  [esign12]="esign12"
  [esign13]="esign13"
  [esign14]="esign14"
  [esign15]="esign15"
  [esign16]="esign16"
  [esign17]="esign17"
  [esign18]="esign18"
  [esign19]="esign19"
  [esign20]="esign20"
  [esign21]="esign21"
  [esign22]="esign22"

      ></ctg-erie-form-section1> 

              </form>
        </div>

          <div class="modal-footer mt-4">
            <button *ngIf="formData.receivedForm" type="button" class="btn customOrange float-end"
            (click)="saveForm('approve')">Approve</button>
            <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
            (click)="saveForm('submit')">Submit</button>
            <button *ngIf="formData.submitForm" type="button" class="btn btn customOrange float-end"
            (click)="saveForm('save')">Save</button>
            <button *ngIf="formData.previewForm" type="button" class="btn customOrange float-end" (click)="close()">Close</button>
        </div>
