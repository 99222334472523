import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'aba-building-blocks',
    templateUrl: './aba-building-blocks.component.html',
    styleUrls: ['./aba-building-blocks.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
  export class AbaBuildingBlocksComponent implements OnInit {
    @Input() formData;
    @Input() fieldsData;
    @Input() formId;
    @Output() onSubmit = new EventEmitter();
    @Output() onClose = new EventEmitter();
    private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(
      private fb: FormBuilder,
      private onboardService: OnboardingformsServiceService,
      private spinner: NgxSpinnerService,
      private notiService: ToasterNotiService,
      private modalService: NgbModal,
      private _sanitizer: DomSanitizer,
      private storageService: StorageService
    ) {}
    public userId: any;
    public customerId: any;
    public AbaBuildBlocksForms: FormGroup;
    isSubmit: boolean = false;
    private phonePattern = '^[0-9]*$';
    modalOption: NgbModalOptions = {
      size: 'md',
      keyboard: false,
      backdrop: 'static'
    };

    ngOnInit(): void {
      this.userId = Number(this.storageService.getItem('userId'));
      this.customerId = Number(this.storageService.getItem('customerId'));
      this.buildababuildblocksForm();
      this.getStatesData();
      this.getGenderData();
      this.getDegreeData();
      this.getSpecialtyData();
      this.getAlternateLicenseStateData();
      this.getCredentialsData();
      this.getSuffixData();
      this.getAlternateLicenseStateData();
      this.getAdditionalLicenseStateData();
      this.getBoardCertificationData();
      this.getSpecialtySecondaryData();
      this.getTerSpecialtyData();
      this.getStaffStatusData();
      this.getPreClaimData();
      this.getAggregatesData();
      this.getAltCdsStatesData();
      this.fieldsData && this.bindFormValue(this.fieldsData);
      this.afterSaveDataDisplay();
    
    } 
    //drop down arrays
    states: Array<object> = [];
    gender: Array<object> = [];
    degree: Array<object> = [];
    specialty: Array<object> = [];
    altLicenseState: Array<object> = [];
    credentials: Array<object> = [];
    suffix: Array<object> = [];
    AltCdsStates: Array<object> = [];
    additionalLicenseState: Array<object> = [];
    boardCertification: Array<object> = [];
    specialtySecondary: Array<object> = [];
    specialtyTer: Array<object> = [];
    staffStatus: Array<object> = [];
    preClaim: Array<object> = [];
    aggregates: Array<object> = [];
    public doits: any;
    public usmil: any;
    finalImgPath: any = [];
    finalImg: any;
    esignOne: any;
    showOne: any = false;
    firstName: any;
    lastName: any;
    finalName: any;
    changeCmdOne: any;
    changeCmdTwo: any;
    changeCmdThree: any;
    questionCounter = 0;
    indexKey = 0;

    public getSuffixData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSuffixData(params).subscribe(
        data => {
          if (data['success']) {
            this.suffix = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getSpecialtySecondaryData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtySecondaryData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtySecondary = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getAggregatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAggregatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.aggregates = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    
    public getSpecialtyData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialty = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getDegreeData() {
      let params: {};
      this.onboardService.getDegreeData(params).subscribe(
        data => {
          if (data['success']) {
            this.degree = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
        },
        error => {
        }
      );
    }
  
    
    public getStatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.states = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    } 
    public getGenderData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getGenderData(params).subscribe(
        data => {
          if (data['success']) {
            this.gender = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public afterSaveDataDisplay() {
      let params = {
        type: 40,
        formId: this.formId
      };
      //this.spinner.show();
      this.onboardService.toGetSaveData(params).subscribe(
        data => {
          if (data['success']) {
            this.bindFormValue(data.data);
          } else {
            // this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          // this.notiService.showError(Error_Message, '', 3000)
        }
      );
    }

    public getAlternateLicenseStateData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAlternateLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.altLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public setEsign(filePath, fieldId) {
      // console.log(filePath);
      if (fieldId == 'cmd_11122') {
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + filePath
        );
        this.showOne = true;
      }
    }

    public previewEsign(filePath, fieldId) {
      //this.spinner.show();
      let params = {
        filePath: filePath
      };
      this.onboardService.getPreviewEsign(params).subscribe(
        (data: any) => {
          if (data['success']) {
            this.setEsign(data.base64, fieldId);
          } else {
            //this.notiService.showError(data.error, '', 3000)
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public bindFormValue(array) {
      let fieldsCtrls = {};
      for (let i = 0; i < array.length; i++) {
        fieldsCtrls[array[i].field_name] = array[i].field_value;
        if (array[i].field_name == 'cmd_11122') {
          if (array[i].field_value != '') {
            this.previewEsign(array[i].field_value, array[i].field_name);
          }
        }
   
      }
      
      this.AbaBuildBlocksForms.patchValue(fieldsCtrls);
    }

    buildababuildblocksForm() {
      this.AbaBuildBlocksForms = this.fb.group({
        
        822: [null],
        9: ['', [Validators.required]],
        8: ['', [Validators.required]],
        809: [''],
        29: [null],
        278: [''],
        280: [null, [this.dateFormatValidator]],
        281: [null, [this.dateFormatValidator]],
        2337: [''],
        cmd_01: [null, [this.dateFormatValidator]],
        cmd_02: [null, [this.dateFormatValidator]],
        16: ['', [Validators.required]],
        17: [''],
        18: ['', [Validators.required]],
        20: [null, [Validators.required]],
        21: [
          '',
          [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
        ],
        708: ['', [Validators.required]],
        32: ['', [Validators.required]],
        30: [null],
        cmd_837: [''],
        cmd_838: [''],
        cmd_839: [''],
        cmd_841: [null],
        cmd_842: [''],
        cmd_870: [''],
        cmd_871: [''],
        759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        31: [null, [Validators.required,this.dateFormatValidator]],
        810: ['', [Validators.required]],
        289: [null, [Validators.required]],
        292: [''],
        1584: [''],
        1282: [''],
        cmd_04: [''],
        333: ['', [Validators.required]],
        334: ['', [Validators.required]],
        335: ['', [Validators.required]],
        cmd_004: [''],
        cmd_005: [''],
        cmd_006: [''],
        cmd_05: ['', [Validators.required]],
        1144: [null, [this.dateFormatValidator]],
        1145: [null, [this.dateFormatValidator]],
        1139: [''],
        1141: [''],
        cmd_06: [''],
        //EDUCATION
        cmd_NA_02:[null],
        490: [''],
        491: [''],
        492: [''],
        493: [''],
        495: [null],
        496: ['', [Validators.minLength(5), Validators.maxLength(10)]],
        500: [null],
        497: [null, [this.dateFormatValidator]],
        498: [null, [this.dateFormatValidator]],
        293: [''],
        299: [null],
        294: [null, [this.dateFormatValidator]],
        295: [null, [this.dateFormatValidator]],
        cmd_013: [''],
        cmd_NA_03:[null],
        1274: [''],
        300: [''],
        907: [null],
        307: [null, [this.dateFormatValidator]],
        308: [null, [this.dateFormatValidator]],
        cmd_014: [''],
        1432: [''],
        999: [null],
        1010: [null],
        1003: [null, [this.dateFormatValidator]],
        1004: [null, [this.dateFormatValidator]],
        cmd_015: [''],
        1634: [''],
        cmd_022: [''],
        812: [''],
        //Professional/Specialty
        cmd_NA_04:[null],
        556:[null],
        cmd_024:['', [Validators.required]],
        554:[null],
        557:[null, [this.dateFormatValidator]],
        559: [null, [this.dateFormatValidator]],
        560: [null, [this.dateFormatValidator]],
        cmd_025: [''],
        cmd_026: [''],
        cmd_027: [''],
        cmd_029: [''],
        cmd_030: [''],
        cmd_031: [''],
        cmd_028: [''],
        cmd_032: [''],
        cmd_033: [''],
        cmd_034: [''],
        563: [null],
        cmd_035: [''],
        561: [null],
        564: [null, [this.dateFormatValidator]],
        565: [null, [this.dateFormatValidator]],
        567: [null, [this.dateFormatValidator]],
        cmd_036: [''],
        cmd_040: [''],
        cmd_037: [''],
        cmd_041: [''],
        cmd_038: [''],
        cmd_042: [''],
        cmd_039: [''],
        cmd_043: [''],
        cmd_044: [''],
        cmd_045: [''],
        948: [null],
        cmd_046: [''],
        946: [null],
        953: [null, [this.dateFormatValidator]],
        950: [null, [this.dateFormatValidator]],
        954: [null, [this.dateFormatValidator]],
        cmd_047: [''],
        cmd_051: [''],
        cmd_048: [''],
        cmd_049: [''],
        cmd_052: [''],
        cmd_050: [''],
        cmd_053: [''],
        cmd_054: [''],
        cmd_055: [''],
        cmd_056: [''],
        cmd_057: [''],
        cmd_NA_05:[null],
        445: [''],
        454: [null, [this.dateFormatValidator]],
        455: [null, [this.dateFormatValidator]],
        446: [''],
        447: [''],
        448: [''],
        450: [null],
        451: ['', [Validators.minLength(5), Validators.maxLength(10)]],
        456: [''],
        465: [null, [this.dateFormatValidator]],
        466: [null, [this.dateFormatValidator]],
        457: [''],
        458: [''],
        459: [''],
        461: [null],
        462: [''],
        1338: [''],
        467: [''],
        476: [null, [this.dateFormatValidator]],
        477: [null, [this.dateFormatValidator]],
        468: [''],
        469: [''],
        470: [''],
        472: [null],
        473: [''],
        1342: [''],
        1935: [''],
        1947: [null, [this.dateFormatValidator]],
        1948: [null, [this.dateFormatValidator]],
        1936: [''],
        1937: [''],
        1938: [''],
        1940: [null],
        1941: [''],
        1952: [''],
        3395: [null, [this.dateFormatValidator]],
        3396: [null, [this.dateFormatValidator]],
        1138: [''],
        3397: [null, [this.dateFormatValidator]],
        3398: [null, [this.dateFormatValidator]],
        1831: [''],
        1832: [''],
        3399: [null, [this.dateFormatValidator]],
        3400: [null, [this.dateFormatValidator]],
        3401: [null, [this.dateFormatValidator]],
        3402: [null, [this.dateFormatValidator]],
        1953: [''],
        cmd_NA_07:[null],
        359: [''],
        371: ['', [Validators.pattern(this.emailPattern)]],
        369: [''],
        370: [''],
        360: [''],
        361: [''],
        362: [''],
        364: [null],
        365: ['', [Validators.minLength(5), Validators.maxLength(10)]],
        372: [''],
        383: ['', [Validators.pattern(this.emailPattern)]],
        396: ['', [Validators.pattern(this.emailPattern)]],
        381: [''],
        382: [''],
        373: [''],
        374: [''],
        375: [''],
        377: [null],
        378: [''],
        384: [''],
        394: [''],
        395: [''],
        385: [''],
        386: [''],
        387: [''],
        389: [null],
        390: [''],
        cmd_NA_08:[null],
        cmd_071: [''],
        345: [''],
        901: [''],
        902: [''],
        903: [''],
        905: [null],
        906: ['', [Validators.minLength(5), Validators.maxLength(10)]],
        1266: [''],
        346: [''],
        348: [null, [this.dateFormatValidator]],
        349: [null, [this.dateFormatValidator]],
        350: [null],
        351: [null],
        1242: [''],
        cmd_072: [''],
        cmd_073: [''],
        cmd_NA_09: [null],
        1244: [''],
        1245: [''],
        1246: [''],
        1248: [null],
        1249: ['', [Validators.minLength(5), Validators.maxLength(10)]],
        1254: [''],
        1256: [''],
        1252: [null, [this.dateFormatValidator]],
        1253: [null, [this.dateFormatValidator]],
        1264: [null],
        1265: [null],
        cmd_074: [''],
        cmd_075: [''],
        cmd_088: [''],
        cmd_089: [''],
        cmd_090: [''],
        cmd_091: [''],
        cmd_092: [''],
        cmd_093: [''],
        cmd_094: [''],
        cmd_0728: [''],
        cmd_0729: [''],
        cmd_095: [''],
        cmd_0730: [''],
        cmd_0731: [''],
        cmd_0732: [''],
        cmd_0733: [null],
        cmd_0734: [''],
        cmd_0735: [''],
        cmd_0736: [''],
        cmd_0737: [''],
        cmd_0738: [''],
        cmd_0739: [''],
        cmd_0740: [''],
        cmd_0741: [''],
        cmd_0742: [''],
        cmd_0743: [null, [this.dateFormatValidator]],
        cmd_096: [''],
        cmd_0744: [''],
        cmd_0745: [''],
        cmd_0746: [''],
        cmd_0747: [''],
       
        cmd_098: [''],
        cmd_099: [''],
        cmd_0770: [''],
        cmd_0106: [''],
        cmd_0114: [''],
        cmd_0100: [''],
        cmd_0771: [''],
        cmd_0107: [''],
        cmd_0115: [''],
        cmd_0101: [''],
        cmd_0772: [''],
        cmd_0109: [''],
        cmd_0116: [''],
        cmd_0102: [''],
        cmd_0773: [''],
        cmd_0110: [''],
        cmd_0117: [''],
        cmd_0103: [''],
        cmd_0774: [''],
        cmd_0111: [''],
        cmd_0118: [''],
        cmd_0104: [''],
        cmd_0775: [''],
        cmd_0112: [''],
        cmd_0119: [''],
        cmd_0105: [''],
        cmd_0776: [''],
        cmd_0113: [''],
        cmd_0120: [''],
        cmd_0121: [''],
        cmd_0122: [''],
        cmd_0123: [''],
        cmd_0124: [''],
        cmd_0125: [''],
        cmd_0126: [''],
        cmd_0127: [''],
        cmd_0128: [''],
        cmd_0129: [''],
        cmd_0130: [''],
        cmd_0131: [''],
        cmd_0777: [''],
        cmd_0132: [''],      
        cmd_0232: ['', [Validators.required]],
        cmd_0233: ['', [Validators.required]],
        cmd_0234: ['', [Validators.required]],
        cmd_0235: ['', [Validators.required]],
        cmd_0236: ['', [Validators.required]],
        cmd_0237: ['', [Validators.required]],
        cmd_0238: ['', [Validators.required]],
        cmd_0239: ['', [Validators.required]],
        cmd_0240: ['', [Validators.required]],
        cmd_0241: ['', [Validators.required]],
        cmd_0242: ['', [Validators.required]],
        cmd_0243: ['', [Validators.required]],
        cmd_0244: ['', [Validators.required]],
        cmd_0245: ['', [Validators.required]],
        cmd_0246: ['', [Validators.required]],
        cmd_0247: ['', [Validators.required]],
        cmd_0248: ['', [Validators.required]],
        cmd_0249: ['', [Validators.required]],
        cmd_0250: ['', [Validators.required]],
        cmd_0251: ['', [Validators.required]],
        cmd_0252: ['', [Validators.required]],
        cmd_0253: ['', [Validators.required]],
        cmd_0254: ['', [Validators.required]],
        cmd_0255:[''],
        cmd_0256: [''],
        cmd_0257: ['', [Validators.required]],
        cmd_0259: ['', [Validators.required]],
        cmd_0260: [''],
        cmd_0261: ['', [Validators.required]],
        cmd_0262: [''],
        cmd_0263: ['', [Validators.required]],
        cmd_0264: [''],
        cmd_0265: ['', [Validators.required]],
        cmd_0266: [''],
        cmd_0267: ['', [Validators.required]],
        cmd_0268: [''],
        cmd_0269: ['', [Validators.required]],
        cmd_0270: [''],
        cmd_0271: ['', [Validators.required]],
        cmd_0272: [''],
        cmd_0273: ['', [Validators.required]],
        cmd_0274: [''],
        cmd_0275: ['', [Validators.required]],
        cmd_0276: [''],
        cmd_0277: ['', [Validators.required]],
        cmd_0278: [''],
        cmd_0279: ['', [Validators.required]],
        cmd_0390: [''],
        cmd_0281: ['', [Validators.required]],
        cmd_0282: [''],
        cmd_0283: ['', [Validators.required]],
        cmd_0284: [''],
        cmd_0285: ['', [Validators.required]],
        cmd_0286: [''],
        cmd_0258: [''],
        cmd_0280: [null],
        cmd_0287: ['', [Validators.required]],
        cmd_0288: [''],
        cmd_0289: ['', [Validators.required]],
        cmd_0290: [''],
        cmd_0291: ['', [Validators.required]],
        cmd_0292: [''],
        cmd_0293: ['', [Validators.required]],
        cmd_0294: [''],
        cmd_0295: ['', [Validators.required]],
        cmd_0296: [''],
        cmd_0297: ['', [Validators.required]],
        cmd_0298: [''],
        cmd_0299: ['', [Validators.required]],
        cmd_0300: [''],
        cmd_0302: [''],
        cmd_0303: [''],
        cmd_0304: [''],
        cmd_0305: [''],
        cmd_0306: [''],
        cmd_0301: [''],
        cmd_0307: [''],
        cmd_0897: [''],
        cmd_0779: [''],
        cmd_0310: [null, [Validators.required,this.dateFormatValidator]],
        cmd_11122:  ['', [Validators.required]],
        cmd_009: ['', [Validators.required]],
        cmd_0010: [''],
        cmd_0011: [''],
        cmd_0012: [''],
        cmd_0013: [''],
        cmd_0014: [''],
        cmd_0015: ['', [Validators.required]],
        cmd_0016: ['', [Validators.required]],
        cmd_0017: ['', [Validators.required]],
        cmd_0018: ['', [Validators.required]],
        cmd_0019: ['', [Validators.required]],
        cmd_0020: ['', [Validators.required]],
        cmd_0021: ['', [Validators.required]],
        cmd_0022: ['', [Validators.required]],
        cmd_0023: [''],
        cmd_0024: [''],
        cmd_0025: [''],
        cmd_0026: [''],
        cmd_0027: [''],
        cmd_0028: [''],
        cmd_0029: [''],
        cmd_0030: [''],
        cmd_0031: [''],
        cmd_0032: [''],
        cmd_0033: [''],
        cmd_0034: [''],
        cmd_NA_010: [null],
        cmd_NA_011: [null],
        cmd_NA_012: [null],
        cmd_NA_013: [null],
        cmd_NA_014: [null],
        cmd_NA_015: [null],
        cmd_NA_016: [null],
        cmd_NA_017: [null],
        cmd_NA_018: [null],
        cmd_NA_019: [null],
        cmd_NA_020: [null],
        cmd_NA_021: [null],
        cmd_NA_022: [null],
        cmd_NA_023: [null],
        cmd_NA_024: [null],
        cmd_NA_025: [null],
        cmd_NA_026: [null],
        cmd_NA_027: [null],
        cmd_NA_028: [null],
        cmd_NA_029: [null],
        cmd_NA_030: [null],
        cmd_NA_031: [null],
        cmd_NA_032: [null],
        cmd_NA_033: [null],
        cmd_0035: [''],

        
        

      

        
      });
      console.log(this.AbaBuildBlocksForms);

    } 

    setErrorControl(name){
      this.AbaBuildBlocksForms.controls[name].setErrors({ errors: true });
    }
    removeErrorControl(name){
      this.AbaBuildBlocksForms.controls[name].setErrors(null)
    }

    numberOnly(event): boolean {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

    public getPreClaimData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getPreClaimData(params).subscribe(
        data => {
          if (data['success']) {
            this.preClaim = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getStaffStatusData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getStaffStatusData(params).subscribe(
        data => {
          if (data['success']) {
            this.staffStatus = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getBoardCertificationData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getBoardCertificationData(params).subscribe(
        data => {
          if (data['success']) {
            this.boardCertification = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
    public getTerSpecialtyData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getTerSpecialtyData(params).subscribe(
        data => {
          if (data['success']) {
            this.specialtyTer = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  
    

    getSsn(value) {
      this.AbaBuildBlocksForms.patchValue({
        // cmd_0779: value
        cmd_0779: value.substring(value.length-4, value.length)
      });
    }
   
    
    onChangeCmdThree(event) {
      if (event.target.value == 1) {
        this.changeCmdThree = true;
      } else {
        this.changeCmdThree = false;
      }
    }

    public getCredentialsData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getCredentialsData(params).subscribe(
        data => {
          if (data['success']) {
            this.credentials = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    public getAdditionalLicenseStateData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAdditionalLicenseStateData(params).subscribe(
        data => {
          if (data['success']) {
            this.additionalLicenseState = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }
  

    public getAltCdsStatesData() {
      let params: {};
      //this.spinner.show();
      this.onboardService.getAltCdsStatesData(params).subscribe(
        data => {
          if (data['success']) {
            this.AltCdsStates = data.data.map(i => ({
              ...i,
              id: i.id.toString()
            }));
          } else {
            this.notiService.showError(data.error, '', 3000);
          }
          //this.spinner.hide();
        },
        error => {
          //this.spinner.hide();
        }
      );
    }

    onChangeFirstName(event) {
      this.firstName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
        cmd_0897: this.finalName
      };
      this.AbaBuildBlocksForms.patchValue(temp);
    }
    onChangeLastName(event) {
      this.lastName = event.target.value;
      this.finalName = this.firstName + ' ' + this.lastName;
      let temp = {
        cmd_0897: this.finalName
      };
      this.AbaBuildBlocksForms.patchValue(temp);
    }
    handleYesNoChanges(cName,eName?){
      if (cName == "cmd_024") {
        let x=['554']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && (this.AbaBuildBlocksForms.controls[cName].value==1 || this.AbaBuildBlocksForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if (cName == "cmd_05") {
        let x=['1141','1139','1144','1145']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && (this.AbaBuildBlocksForms.controls[cName].value==1 || this.AbaBuildBlocksForms.controls[cName].value==null)){
            this.setErrorControl(element);
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      
    }
    public esignOpenOne() {
      const modalRef = this.modalService.open(EsignComponent, this.modalOption);
      modalRef.result.then(result => { });
      modalRef.componentInstance.base64Img.subscribe(data => {
        modalRef.close();
        this.finalImgPath = data.finalFilePath;
        let temp = {
          cmd_11122: this.finalImgPath
        };
        this.AbaBuildBlocksForms.controls['cmd_11122'].setValue(this.finalImgPath);
        this.finalImg = data.base64Img;
        this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + this.finalImg
        );
        this.showOne = true;
      });
    }
    // doit(event) {
    //   this.doits = event.target.value;
    //   if (this.doits == 0) {
    //     this.AbaBuildBlocksForms.controls['cmd_0018'].setErrors(null);
    //   }
    //   else if (this.doits == 1) {
    //     this.AbaBuildBlocksForms.controls['cmd_0018'].setErrors({ errors: true });
    //   }
    // }
    handleNAchange(cName,eName?){
      let result:boolean=false;
      if(cName=='cmd_NA_02'){
        let x=[490,491,493,495,496,497,498,500]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }  
      
      if(cName=='cmd_NA_03'){
        let x=[293,299,294,295]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      
      if(cName=='cmd_NA_04'){
        let x=[556,557,'cmd_024']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_05'){
        let x=[445,454,446,447,448,450,451,461,472]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      
      if(cName=='cmd_NA_07'){
        let x=[359,371,369,360,361,362,364,365,372,383,381,384,396,394]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_08'){
        let x=[345,901,902,903,905,906,1266,346,348,349,350,351]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_09'){
        let x=[1242,1244,1245,1246,1248,1249,1256,1252,1253,1264,1265]
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_010'){
        let x=['cmd_0035']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_NA_011'){
        let x=['cmd_0232']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_012'){
        let x=['cmd_0233']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_013'){
        let x=['cmd_0234']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_014'){
        let x=['cmd_0235']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_015'){
        let x=['cmd_0236']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_016'){
        let x=['cmd_0237']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_017'){
        let x=['cmd_0238']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_018'){
        let x=['cmd_0239']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }
      if(cName=='cmd_NA_019'){
        let x=['cmd_0240']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      }  
      if(cName=='cmd_NA_020'){
        let x=['cmd_0241']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_021'){
        let x=['cmd_0242']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_022'){
        let x=['cmd_0243']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_023'){
        let x=['cmd_0244']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_024'){
        let x=['cmd_0245']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_025'){
        let x=['cmd_0246']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_026'){
        let x=['cmd_0247']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_027'){
        let x=['cmd_0248']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_028'){
        let x=['cmd_0249']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_029'){
        let x=['cmd_0250']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_030'){
        let x=['cmd_0251']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_031'){
        let x=['cmd_0252']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_032'){
        let x=['cmd_0253']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
      if(cName=='cmd_NA_033'){
        let x=['cmd_0254']
        x.forEach(element => {
          if((this.AbaBuildBlocksForms.controls[element].value=='' || this.AbaBuildBlocksForms.controls[element].value==null) && !this.AbaBuildBlocksForms.controls[cName].value){
            this.setErrorControl(element);
            result = true
          }
          else{
            this.removeErrorControl(element);
          }
        });
      } 
    }   

    
    usmilitary(event) {
      this.usmil = event.target.value;
      if (this.usmil == 0) {
        this.AbaBuildBlocksForms.controls['1144,1145,1139,1141'].setErrors(null);
      }
      else if (this.usmil == 1) {
        this.AbaBuildBlocksForms.controls['1144,1145,1139,1141'].setErrors({ errors: true });
      }
    }  

    public validationsDataAdd(event?) {
    let yesNoQuestionIds = {
      cmd_0232: 'cmd_0255',
      cmd_0233: 'cmd_0257',
      cmd_0234: 'cmd_0259',
      cmd_0235: 'cmd_0261',
      cmd_0236: 'cmd_0263',
      cmd_0237: 'cmd_0265',
      cmd_0238: 'cmd_0267',
      cmd_0239: 'cmd_0269',
      cmd_0240: 'cmd_0271',
      cmd_0241: 'cmd_0273',
      cmd_0242: 'cmd_0275',
      cmd_0243: 'cmd_0277',
      cmd_0244: 'cmd_0279',
      cmd_0245: 'cmd_0281',
      cmd_0246: 'cmd_0283',
      cmd_0247: 'cmd_0285',
      cmd_0248: 'cmd_0287',
      cmd_0249: 'cmd_0289',
      cmd_0250: 'cmd_0291',
      cmd_0251: 'cmd_0293',
      cmd_0252: 'cmd_0295',
      cmd_0253: 'cmd_0297',
      cmd_0254: 'cmd_0299'
    };
    // ID of respective explanation section
    let yesNoQuestionExplanation = [
      'cmd_0255',
      'cmd_0257',
      'cmd_0259',
      'cmd_0261',
      'cmd_0263',
      'cmd_0265',
      'cmd_0267',
      'cmd_0269',
      'cmd_0271',
      'cmd_0273',
      'cmd_0275',
      'cmd_0277',
      'cmd_0279',
      'cmd_0281',
      'cmd_0283',
      'cmd_0285',
      'cmd_0287',
      'cmd_0289',
      'cmd_0291',
      'cmd_0293',
      'cmd_0295',
      'cmd_0297',
      'cmd_0299'
    ];
    this.questionCounter = 0;
    for (const key in yesNoQuestionIds) {
      if (yesNoQuestionIds.hasOwnProperty(key)) {
        // console.log(yesNoQuestionIds[key]);
        const element = this.AbaBuildBlocksForms.controls[key].value;
        // console.log(element);
        if (element == 1) {
          this.questionCounter++;
        }
      }
    }
    // console.log(this.questionCounter);
    for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
      // console.log(yesNoQuestionExplanation[0]);
      if (this.indexKey < this.questionCounter) {
        // console.log(this.AbaBuildBlocksForms.controls[yesNoQuestionExplanation[this.indexKey]])
        if (this.AbaBuildBlocksForms.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.AbaBuildBlocksForms.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
          // console.log('setting error')
        this.AbaBuildBlocksForms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          {
            errors: true
          }
        );
        }
      } else {
        // console.log('removing error');
        // console.log('else error');
        this.AbaBuildBlocksForms.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
          null
        );
      }
    }
    // for (const key in this.AbaBuildBlocksForms.controls) {
    //   if (this.AbaBuildBlocksForms.controls.hasOwnProperty(key)) {
    //     const element = this.AbaBuildBlocksForms.controls[key];
    //     if(element.status=="INVALID"){
    //     console.log(key + " " + element.status)
    //     }
    //   }
    // }
  }
    
    

    saveForm(type) {
      this.isSubmit = true;
      this.handleNAchange('cmd_NA_02');
      this.handleNAchange('cmd_NA_03');
      this.handleNAchange('cmd_NA_04');
      this.handleYesNoChanges('cmd_024');
      this.handleYesNoChanges('cmd_05');
      this.handleNAchange('cmd_NA_05');
      this.handleNAchange('cmd_NA_07');
      this.handleNAchange('cmd_NA_08');
      this.handleNAchange('cmd_NA_09');
      this.handleYesNoChanges('cmd_0232');
      this.handleNAchange('cmd_NA_010');
      this.handleNAchange('cmd_NA_011');
      this.handleNAchange('cmd_NA_012');
      this.handleNAchange('cmd_NA_013');
      this.handleNAchange('cmd_NA_014');
      this.handleNAchange('cmd_NA_015');
      this.handleNAchange('cmd_NA_016');
      this.handleNAchange('cmd_NA_017');
      this.handleNAchange('cmd_NA_018');
      this.handleNAchange('cmd_NA_019');
      this.handleNAchange('cmd_NA_020');
      this.handleNAchange('cmd_NA_021');
      this.handleNAchange('cmd_NA_022');
      this.handleNAchange('cmd_NA_023');
      this.handleNAchange('cmd_NA_024');
      this.handleNAchange('cmd_NA_025');
      this.handleNAchange('cmd_NA_026');
      this.handleNAchange('cmd_NA_027');
      this.handleNAchange('cmd_NA_028');
      this.handleNAchange('cmd_NA_029');
      this.handleNAchange('cmd_NA_030');
      this.handleNAchange('cmd_NA_031');
      this.handleNAchange('cmd_NA_032');
      this.handleNAchange('cmd_NA_033');
      this.validationsDataAdd()
      console.log(this.AbaBuildBlocksForms);
      if (type == 'submit') {
        if (this.AbaBuildBlocksForms.invalid) {
          this.notiService.showError(
            'Please Fill the all mandatory fields to continue',
            '',
            3000
          );
          for (const key in this.AbaBuildBlocksForms.controls) {
            if (this.AbaBuildBlocksForms.controls.hasOwnProperty(key)) {
              const element = this.AbaBuildBlocksForms.controls[key];
              if(element.status=="INVALID"){
              console.log(key + " " + element.status)
              }
            }
          }
        } else {
          if (this.AbaBuildBlocksForms.controls['cmd_11122'].value == null || this.AbaBuildBlocksForms.controls['cmd_11122'].value == '') {
            this.notiService.showError('Please add your Signature')
            return
          }
          let data = {
            formType: type,
            formValues: this.AbaBuildBlocksForms.value
          };
          console.log("Form has been submitted successfully");
          this.onSubmit.emit(data);
          console.log(this.AbaBuildBlocksForms);
  
        }
      } else {
        let data = {
          formType: type,
          formValues: this.AbaBuildBlocksForms.value,
          formId: 40
        };
        this.onSubmit.emit(data);
        this.onClose.emit('close modal');
      }
    }
    close() {
      this.onClose.emit('close modal');
    }

    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.AbaBuildBlocksForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
      if(handleYesNoValue ){
        if(handleYesNoValue == ("cmd_05")){
          this.handleYesNoChanges(handleYesNoValue);
        }
        else{
            this.handleNAchange(handleYesNoValue);
        }
        
        
      }
      
      
    }
    clearInputIfInvalidMandi(controlName: string) {
      const control = this.AbaBuildBlocksForms.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
  
      }}
  
    dateFormatValidator(control: FormControl): ValidationErrors | null {
      const dateString = control.value;
      const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    
      if (dateString && !dateString.match(dateFormat)) {
        return { 'invalidDateFormat': true };
      }
    
  
  
      if (dateString) {
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0]);
        const day = parseInt(dateParts[1]);
        const year = parseInt(dateParts[2]);
    
        // Check if any of the date components are zero or month is not between 1 and 12
        
    
        const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
        
        if (month < 1 || month > 12 || day === 0 || year === 0) {
          return { 'invalidMonth': true };
        }
        if (month === 1 || month > 2) {
          if (day > ListofDays[month - 1]) {
            return { 'invalidDateRange': true };
          }
        } else if (month === 2) {
          const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
          if (!leapYear && day >= 29) {
            return { 'invalidDateRange': true };
          }
           else if (leapYear && day > 29) {
            return { 'invalidDateRange': true };
          }
        }
        if (year < 1900 || year > 2099) {
          return { 'invalidDateyear': true };
        }
        
       
      }
    
      return null;
    }
















      
      
     
     
         
  }