<div id="content-wrapper">
  <div class="topBox">
    <div></div>
    <h4> {{pendingViewEnable?'Pending Reports':'View Generated Forms'}} </h4>
  </div>
  <div class="p-3">
    <div class="searchBox">
      <form [formGroup]="searchForm">
        <div class="row">
          <div class="mb-3 col-3">
            <div class="mb-3">
              <div class="input-group">
                <input class="form-control" name="dp" placeholder="From Date" placement="bottom" formControlName="fromDate" 
                   ngbDatepicker #d1="ngbDatepicker" appMaskInput [appMaskValue]="this.searchForm.value.fromDate" >
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 col-3">
            <div class="mb-3">
              <div class="input-group">
                <input class="form-control" name="dp" placeholder="To Date" placement="bottom" formControlName="toDate"
                  ngbDatepicker #d2="ngbDatepicker" appMaskInput [appMaskValue]="this.searchForm.value.toDate">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                      class="fas fa-calendar-alt"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 col-3" *ngIf="!pendingViewEnable">
            <ng-select [items]="catageoryCol" bindLabel="label" formControlName="category"
              (ngModelChange)="oncategoryChange($event)" placeholder="Select category" bindValue="id">
            </ng-select>
          </div>
          <div class="mb-3  col-3" *ngIf="!pendingViewEnable">
            <ng-select [items]="typeCol" bindLabel="name" formControlName="selectTypeId" placeholder="Select Item"
              bindValue="id">
            </ng-select>
          </div>
          <div class="col-12 text-end">
            <button class="btn customOrange" type="button" (click)="onSearchClick()">Search</button>
            <button class="btn customOrange" type="button" (click)="onSearchCancel()">Clear</button>
            <button class="btn customOrange" type="button" (click)="onexcelDownloadClick()">
              <i class="fas fa-file-excel"></i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="tableData">
    <ngx-datatable #myTable class="material" [columnMode]="'force'" [headerHeight]="37" [footerHeight]="50"
      [rowHeight]="'auto'" [rows]="generateFormListCol" [externalPaging]="true" [limit]="limitvalue"
      [offset]="PageNumber" [count]="count" (page)="onPage($event)" [virtualization]="false">
      <ngx-datatable-column name="Date" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <span>{{row.created_on}}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Form Name" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <span>{{row.form_name}}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Entity" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <span *ngIf="row.provider_id"> Provider: {{row.provider_name}}</span>
            <span *ngIf="!row.provider_id && row.location"> Location: {{row.location}}</span>
            <span *ngIf="!row.provider_id && !row.location && row.tin"> Tin: {{row.tin}}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="User" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <span>{{row.created_by}}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="PDF" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div>
            <span class="text-danger" (click)="onPdfDownLoad(row)"><i class="far fa-file-pdf fa-lg"></i></span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{pendingViewEnable?'Upload':'Pending/Signed'}}" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template> 
          <div *ngIf="row.gen_portal_users!=1">
            <div *ngIf="row.is_sign_req==1 && pendingViewEnable">
              <span (click)="onEsignUploadfile(row)"> <img src="../../../../assets//images/dwn1.png" width="30"
                  height="30" /></span>
            </div>
            <div *ngIf="!pendingViewEnable">
              <span *ngIf="row.is_signed==1" (click)="onDownloadesignFile(row)"> <img
                  src="../../../../assets//images/up1.png" width="30" height="30" /></span>
            </div>
            <div *ngIf="!pendingViewEnable &&(row.is_sign_req==1 && row.is_signed==0) || row.is_portal_user==1">
              <!-- <span *ngIf="row.is_downloaded==1" (click)="showEmailmodal(row,false)"> <img
                  src="../../../../assets//images/pro_down.png" width="30" height="30" />
                <label>{{row.is_downloaded_timestamp}}</label></span> -->

              <span *ngIf="row.is_sign_req==1 && row.is_signed==0" (click)="showEmailmodal(row,false)"> <img
                  src="../../../../assets//images/pdfunsigned.png" width="30" height="30" /></span>

              <span *ngIf="row.is_sign_req==0" (click)="showEmailmodal(row,true)"> <img
                  src="../../../../assets//images/is_email.jpg" width="30" height="30" /></span>
            </div>
            <div *ngIf="pendingViewEnable && row.is_send">
              <span *ngIf="row.is_sign_req==0" > 
                <img  src="../../../../assets//images/e_sign.png" width="30" height="30" /></span>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="pendingViewEnable" name="E-sign" [width]="200">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>