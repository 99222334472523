<div id="interMountianStyle">
  <div class="cotainer-fluid mx-1">
    <div class="header">
      <h2 class="firstHeading">
        Provider Credentialing Application
      </h2>
    </div>

    <div class="section">
      <form [formGroup]="interMountian">
        <table class="table tabwidth" border="1">
          <thead class="tabhead">
            <tr>
              <th class="tabTh">Applicant Information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-4">
                    <label>LastName</label>
                    <input
                      class="form-control onboard"
                      formControlName="9"
                      [class.is-invalid]="
                        (interMountian.controls['9'].touched &&
                          interMountian.controls['9'].invalid) ||
                        (interMountian.controls['9'].invalid && isSubmit)
                      "
                      id="9"
                      name="9"
                      type="text"
                      placeholder="Last Name"
                      (ngModelChange)="handleLastNamechange($event)"
                      data-id="managing_contact_name"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['9'].touched &&
                        interMountian.controls['9'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['9'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>First</label>
                    <input
                      class="form-control onboard"
                      formControlName="8"
                      [class.is-invalid]="
                        (interMountian.controls['8'].touched &&
                          interMountian.controls['8'].invalid) ||
                        (interMountian.controls['8'].invalid && isSubmit)
                      "
                      id="8"
                      name="8"
                      type="text"
                      placeholder="First Name"
                      data-id="managing_contact_name"
                      (ngModelChange)="handleFirstNamechange($event)"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['8'].touched &&
                        interMountian.controls['8'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['8'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label>Middle</label>
                    <input
                      class="form-control onboard"
                      formControlName="809"
                      [class.is-invalid]="
                        (interMountian.controls['809'].touched &&
                          interMountian.controls['809'].invalid) ||
                        (interMountian.controls['809'].invalid && isSubmit)
                      "
                      id="809"
                      name="809"
                      type="text"
                      placeholder="Middle Name"
                      data-id="managing_contact_name"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['809'].touched &&
                        interMountian.controls['809'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['809'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label>Others names used:</label>
                  </div>

                  <div class="col-md-4">
                    <input
                      class="form-control onboard"
                      formControlName="278"
                      [class.is-invalid]="
                        (interMountian.controls['278'].touched &&
                          interMountian.controls['278'].invalid) ||
                        (interMountian.controls['278'].invalid && isSubmit)
                      "
                      id="278"
                      name="278"
                      type="text"
                      placeholder="Prior Name"
                      data-id="managing_contact_name"
                    />
                    <label>If none keep it as 'NA'</label>
                  </div>

                  <div class="col-md-3"></div>

                  <div class="col-md-1">
                    <label>SSN:</label>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="32"
                      [class.is-invalid]="
                        (interMountian.controls['32'].touched &&
                          interMountian.controls['32'].invalid) ||
                        (interMountian.controls['32'].invalid && isSubmit)
                      "
                      id="32"
                      name="32"
                      type="text"
                      placeholder="SSN"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['32'].touched &&
                        interMountian.controls['32'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['32'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label>Place of Birth:</label>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="810"
                      [class.is-invalid]="
                        (interMountian.controls['810'].touched &&
                          interMountian.controls['810'].invalid) ||
                        (interMountian.controls['810'].invalid && isSubmit)
                      "
                      id="810"
                      name="810"
                      type="text"
                      placeholder="city of birth"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['810'].touched &&
                        interMountian.controls['810'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['810'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (interMountian.controls['289'].touched &&
                          interMountian.controls['289'].invalid) ||
                        (interMountian.controls['289'].invalid && isSubmit)
                      "
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="289"
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['289'].touched &&
                        interMountian.controls['289'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['289'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="291"
                      [class.is-invalid]="
                        (interMountian.controls['291'].touched &&
                          interMountian.controls['291'].invalid) ||
                        (interMountian.controls['291'].invalid && isSubmit)
                      "
                      id="291"
                      name="291"
                      type="text"
                      placeholder="Country"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['291'].touched &&
                        interMountian.controls['291'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['291'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Date:</label>
                  </div>

                  <div class="col-md-3">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="31"
                        name="31"
                        formControlName="31"
                        [class.is-invalid]="
                          (interMountian.controls['31'].touched &&
                            interMountian.controls['31'].errors) ||
                          (interMountian.controls['31'].errors && isSubmit)
                        "
                        type="text"
                        ngbDatepicker
                        #in01="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('31')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in01.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="(interMountian.controls['31'].invalid&&interMountian.controls['31'].touched) && (interMountian.controls['31'].value==''|| interMountian.controls['31'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="interMountian.get('31').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="interMountian.get('31').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="interMountian.get('31').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="interMountian.get('31').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="col-md-5">
                  <label>Languages spoken fluently: </label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="languages"
                        bindLabel="name"
                        placeholder="Select langauge"
                        bindValue="id"
                        formControlName="277"
                        [class.is-invalid]="
                          (interMountian.controls['277'].touched &&
                            interMountian.controls['277'].invalid) ||
                          (interMountian.controls['277'].invalid && isSubmit)
                        "
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          interMountian.controls['277'].touched &&
                          interMountian.controls['277'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="interMountian.controls['277'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="secondaryLanguages"
                        bindLabel="name"
                        placeholder="Select langauge"
                        bindValue="id"
                        formControlName="1535"
                        [class.is-invalid]="
                          (interMountian.controls['1535'].touched &&
                            interMountian.controls['1535'].invalid) ||
                          (interMountian.controls['1535'].invalid && isSubmit)
                        "
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          interMountian.controls['1535'].touched &&
                          interMountian.controls['1535'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="interMountian.controls['1535'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="tertiaryLanguages"
                        bindLabel="name"
                        placeholder="Select langauge"
                        bindValue="id"
                        formControlName="1536"
                        [class.is-invalid]="
                          (interMountian.controls['1536'].touched &&
                            interMountian.controls['1536'].invalid) ||
                          (interMountian.controls['1536'].invalid && isSubmit)
                        "
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          interMountian.controls['1536'].touched &&
                          interMountian.controls['1536'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="interMountian.controls['1536'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3 selectDrop dropDrownSet">
                      <ng-select
                        [items]="quaternaryLanguages"
                        bindLabel="name"
                        placeholder="Select langauge"
                        bindValue="id"
                        formControlName="2207"
                        [class.is-invalid]="
                          (interMountian.controls['2207'].touched &&
                            interMountian.controls['2207'].invalid) ||
                          (interMountian.controls['2207'].invalid && isSubmit)
                        "
                      >
                      </ng-select>
                      <div
                        class="formLabel col-md-11"
                        *ngIf="
                          interMountian.controls['2207'].touched &&
                          interMountian.controls['2207'].invalid
                        "
                      >
                        <small
                          class="text-danger"
                          *ngIf="interMountian.controls['2207'].errors"
                        >
                          required
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>


            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Physical Address:</label
                    >
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="16"
                      [class.is-invalid]="
                        (interMountian.controls['16'].touched &&
                          interMountian.controls['16'].invalid) ||
                        (interMountian.controls['16'].invalid && isSubmit)
                      "
                      id="16"
                      name="16"
                      type="text"
                      placeholder="Home Address 1"
                      data-id="managing_contact_name"
                    />
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="17"
                      [class.is-invalid]="
                        (interMountian.controls['17'].touched &&
                          interMountian.controls['17'].invalid) ||
                        (interMountian.controls['17'].invalid && isSubmit)
                      "
                      id="17"
                      name="17"
                      type="text"
                      placeholder="Home Address 2"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['17'].touched &&
                        interMountian.controls['17'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['17'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="18"
                      [class.is-invalid]="
                        (interMountian.controls['18'].touched &&
                          interMountian.controls['18'].invalid) ||
                        (interMountian.controls['18'].invalid && isSubmit)
                      "
                      id="18"
                      name="18"
                      type="text"
                      placeholder="Home city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['18'].touched &&
                        interMountian.controls['18'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['18'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-3 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (interMountian.controls['20'].touched &&
                          interMountian.controls['20'].invalid) ||
                        (interMountian.controls['20'].invalid && isSubmit)
                      "
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="20"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >

                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['20'].touched &&
                        interMountian.controls['20'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['20'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="21"
                      [class.is-invalid]="
                        (interMountian.controls['21'].touched &&
                          interMountian.controls['21'].invalid) ||
                        (interMountian.controls['21'].invalid && isSubmit)
                      "
                      id="21"
                      name="21"
                      type="text"
                      placeholder="Home Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >

                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['21'].touched &&
                        interMountian.controls['21'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['21'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Mailing Address:</label
                    >
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="34"
                      [class.is-invalid]="
                        (interMountian.controls['34'].touched &&
                          interMountian.controls['34'].invalid) ||
                        (interMountian.controls['34'].invalid && isSubmit)
                      "
                      id="34"
                      name="34"
                      type="text"
                      placeholder="Previous Address 1"
                      data-id="managing_contact_name"
                    />
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="35"
                      [class.is-invalid]="
                        (interMountian.controls['35'].touched &&
                          interMountian.controls['35'].invalid) ||
                        (interMountian.controls['35'].invalid && isSubmit)
                      "
                      id="35"
                      name="35"
                      type="text"
                      placeholder="Previous Address 2"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['35'].touched &&
                        interMountian.controls['35'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['35'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="36"
                      [class.is-invalid]="
                        (interMountian.controls['36'].touched &&
                          interMountian.controls['36'].invalid) ||
                        (interMountian.controls['36'].invalid && isSubmit)
                      "
                      id="36"
                      name="36"
                      type="text"
                      placeholder="Previous Home city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['36'].touched &&
                        interMountian.controls['36'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['36'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-3 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      [class.is-invalid]="
                        (interMountian.controls['38'].touched &&
                          interMountian.controls['38'].invalid) ||
                        (interMountian.controls['38'].invalid && isSubmit)
                      "
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="38"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >

                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['38'].touched &&
                        interMountian.controls['38'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['38'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="39"
                      [class.is-invalid]="
                        (interMountian.controls['39'].touched &&
                          interMountian.controls['39'].invalid) ||
                        (interMountian.controls['39'].invalid && isSubmit)
                      "
                      id="39"
                      name="39"
                      type="text"
                      placeholder="Pervious Home Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >

                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['39'].touched &&
                        interMountian.controls['39'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['39'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Personal Phone:</label
                    >
                  </div>

                  <div class="col-md-4">
                    <input
                      class="form-control onboard"
                      formControlName="708"
                      [class.is-invalid]="
                        (interMountian.controls['708'].touched &&
                          interMountian.controls['708'].invalid) ||
                        (interMountian.controls['708'].invalid && isSubmit)
                      "
                      id="708"
                      name="708"
                      type="text"
                      placeholder="Phone"
                    />
                  </div>

                  <div class="col-md-3"></div>

                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Personal Email:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="759"
                      [class.is-invalid]="
                        (interMountian.controls['759'].touched &&
                          interMountian.controls['759'].invalid) ||
                        (interMountian.controls['759'].invalid && isSubmit)
                      "
                      id="759"
                      name="759"
                      type="text"
                      placeholder="Email"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['759'].touched &&
                        interMountian.controls['759'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['759'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="tabhead">
            <tr>
              <th class="tabTh">Professional Licenses and Certifications</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >AZ License #:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="293"
                      [class.is-invalid]="
                        (interMountian.controls['293'].touched &&
                          interMountian.controls['293'].invalid) ||
                        (interMountian.controls['293'].invalid && isSubmit)
                      "
                      id="293"
                      name="293"
                      type="text"
                      data-id="managing_contact_name"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['293'].touched &&
                        interMountian.controls['293'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['293'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Date Issued</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="294"
                        name="294"
                        formControlName="294"
                        type="text"
                        ngbDatepicker
                        #in02="ngbDatepicker"
                        (blur)="clearInputIfInvalid('294')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in02.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('294').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('294').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('294').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('294').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('294').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration/Renewal Date</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="295"
                        name="295"
                        formControlName="295"
                        type="text"
                        ngbDatepicker
                        #in03="ngbDatepicker"
                        (blur)="clearInputIfInvalid('295')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in03.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('295').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('295').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('295').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('295').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('295').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >License Type:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="296"
                      [class.is-invalid]="
                        (interMountian.controls['296'].touched &&
                          interMountian.controls['296'].invalid) ||
                        (interMountian.controls['296'].invalid && isSubmit)
                      "
                      id="296"
                      name="296"
                      type="text"
                      data-id="managing_contact_name"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['296'].touched &&
                        interMountian.controls['296'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['296'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Specialty:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <ng-select
                      [items]="specialty"
                      bindLabel="name"
                      placeholder="Select specialty"
                      bindValue="id"
                      formControlName="284"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-1"></div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Age Range Served:
                    </label>
                  </div>

                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333360"
                      name="cmd_333360"
                      value="1"
                    />(0-11)
                  </div>

                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333361"
                      name="cmd_333361"
                      value="1"
                    />(12-17)
                  </div>
                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333362"
                      name="cmd_333362"
                      value="1"
                    />(18-64)
                  </div>
                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333363"
                      name="cmd_333363"
                      value="1"
                    />(65+)
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="18">
                <div class="mb-3">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_333378"
                      name="cmd_333378"
                      formControlName="cmd_333378"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_333378"
                      name="cmd_333378"
                      formControlName="cmd_333378"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle">
                    If yes,continue below:
                  </label>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Board Name:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <ng-select
                      [items]="boardCertification"
                      bindLabel="name"
                      placeholder="Select Board Certification"
                      bindValue="id"
                      formControlName="554"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Certification #:
                    </label>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="1169"
                      id="1169"
                      name="1169"
                      type="1169"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <label style="font-weight: normal;font-size: 14px"
                      >Certification Issue Date:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="557"
                        name="557"
                        formControlName="557"
                        type="text"
                        ngbDatepicker
                        #in04="ngbDatepicker"
                        (blur)="clearInputIfInvalid('557')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in04.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('557').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('557').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('557').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('557').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('557').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-3">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration/Renewal Date:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="560"
                        name="560"
                        formControlName="560"
                        type="text"
                        ngbDatepicker
                        #in05="ngbDatepicker"
                        (blur)="clearInputIfInvalid('560')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in05.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('560').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('560').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('560').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('560').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('560').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="tabhead">
            <tr>
              <th class="tabTh">Additional Professional Licenses</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >AZ License #:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="300"
                      id="300"
                      name="300"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Date Issued</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="307"
                        name="307"
                        formControlName="307"
                        type="text"
                        ngbDatepicker
                        #in06="ngbDatepicker"
                        (blur)="clearInputIfInvalid('307')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in06.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('307').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('307').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('307').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('307').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('307').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration/Renewal Date
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="308"
                        name="308"
                        formControlName="308"
                        type="text"
                        ngbDatepicker
                        #in07="ngbDatepicker"
                        (blur)="clearInputIfInvalid('308')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in07.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('308').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('308').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('308').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('308').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('308').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >License Type:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="1274"
                      [class.is-invalid]="
                        (interMountian.controls['1274'].touched &&
                          interMountian.controls['1274'].invalid) ||
                        (interMountian.controls['1274'].invalid && isSubmit)
                      "
                      id="1274"
                      name="1274"
                      type="text"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Specialty:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <ng-select
                      [items]="specialty"
                      bindLabel="name"
                      placeholder="Select specialty"
                      bindValue="id"
                      formControlName="962"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-1"></div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Age Range Served:
                    </label>
                  </div>

                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333374"
                      name="cmd_333374"
                      value="1"
                    />(0-11)
                  </div>

                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333375"
                      name="cmd_333375"
                      value="1"
                    />(12-17)
                  </div>
                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333376"
                      name="cmd_333376"
                      value="1"
                    />(18-64)
                  </div>
                  <div class="col-sm-1">
                    <input
                      type="checkbox"
                      id="cmd_333377"
                      name="cmd_333377"
                      value="1"
                    />(65+)
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="18">
                <div class="mb-3">
                  <label class="normalParagraphStyle"
                    >Did you complete the program?</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_333379"
                      name="cmd_333379"
                      formControlName="cmd_333379"
                      value="1"
                    />Yes</label
                  >
                  <label class="checkbox-inline radioStyle">
                    <input
                      type="radio"
                      id="cmd_333379"
                      name="cmd_333379"
                      formControlName="cmd_333379"
                      value="0"
                    />No</label
                  >
                  <label class="normalParagraphStyle">
                    If yes,continue below:
                  </label>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Board Name:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <ng-select
                      [items]="boardCertification"
                      bindLabel="name"
                      placeholder="Select Board Certification"
                      bindValue="id"
                      formControlName="561"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Certification #:
                    </label>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="562"
                      id="562"
                      name="562"
                      type="text"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <label style="font-weight: normal;font-size: 14px"
                      >Certification Issue Date:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="564"
                        name="564"
                        formControlName="564"
                        type="text"
                        ngbDatepicker
                        #in08="ngbDatepicker"
                        (blur)="clearInputIfInvalid('564')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in08.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('564').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('564').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('564').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('564').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('564').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-3">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration/Renewal Date:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="567"
                        name="567"
                        formControlName="567"
                        type="text"
                        ngbDatepicker
                        #in09="ngbDatepicker"
                        (blur)="clearInputIfInvalid('567')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in09.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('567').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('567').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('567').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('567').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('567').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <hr class="pdf-line" />

          <thead class="tabhead">
            <tr>
              <th class="tabTh">Professional Data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-10 ">
                    <label
                      ><b>
                        Registration with CAQH & NPI is required for all
                        professionals.
                      </b>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10">
                    If you do not know your login for CAQH or NPI please follow
                    the applicable links below to recover that information:
                  </div>

                  <ul class="hyperlinkcolour">
                    <li>NPI – <a>https://nppes.cms.hhs.gov/#/</a></li>
                    <li>CAQH – <a>https://proview.caqh.org/Login</a></li>
                  </ul>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >CAQH#:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="333"
                      id="333"
                      name="333"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Username:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="334"
                      id="334"
                      name="334"
                      type="text"
                      (ngModelChange)="getCaqhUser($event)"
                    />
                  </div>
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Password:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="335"
                      id="335"
                      name="335"
                      type="text"
                      (ngModelChange)="getCaqhPass($event)"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >NPI#:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="1634"
                      id="1634"
                      name="1634"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Username:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="1635"
                      id="1635"
                      name="1635"
                      type="text"
                      (ngModelChange)="getNpiuser($event)"
                    />
                  </div>
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Password:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="1636"
                      id="1636"
                      name="1636"
                      type="text"
                      (ngModelChange)="getNpiPass($event)"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >AHCCCS ID#:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="812"
                      id="812"
                      name="812"
                      type="text"
                    />
                  </div>
                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >Medicare PTAN:
                    </label>
                  </div>

                  <div class="col-md-3">
                    <input
                      class="form-control onboard"
                      formControlName="325"
                      id="325"
                      name="325"
                      type="text"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-5">
                    <label style="font-weight: normal;font-size: 14px"
                      >If applicable:
                    </label>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >DEA #:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="309"
                      id="309"
                      name="309"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Date Issued</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="310"
                        name="310"
                        formControlName="310"
                        type="text"
                        ngbDatepicker
                        #in10="ngbDatepicker"
                        (blur)="clearInputIfInvalid('310')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in10.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('310').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('310').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('310').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('310').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('310').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration Date</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="311"
                        name="311"
                        formControlName="311"
                        type="text"
                        ngbDatepicker
                        #in11="ngbDatepicker"
                        (blur)="clearInputIfInvalid('311')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in11.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('311').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('311').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('311').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('311').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('311').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-3">
                    <label style="font-weight: normal;font-size: 14px"
                      >State Controlled Substance#:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="337"
                      id="337"
                      name="337"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >Expiration Date</label
                    >
                  </div>
                  <div class="col-md-3">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="339"
                        name="339"
                        formControlName="339"
                        type="text"
                        ngbDatepicker
                        #in12="ngbDatepicker"
                        (blur)="clearInputIfInvalid('339')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in12.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('339').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('339').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('339').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('339').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('339').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="tabhead">
            <tr>
              <th class="tabTh">Education & Training</th>
            </tr>
          </thead>
          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Undergraduate</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="503"
                      [class.is-invalid]="
                        (interMountian.controls['503'].touched &&
                          interMountian.controls['503'].invalid) ||
                        (interMountian.controls['503'].invalid && isSubmit)
                      "
                      id="503"
                      name="503"
                      type="text"
                    />
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['503'].touched &&
                        interMountian.controls['503'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['503'].errors?.required"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:
                    </label>
                  </div>
                  <div class="col-md-3">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="510"
                        name="510"
                        formControlName="510"
                        [class.is-invalid]="
                          (interMountian.controls['510'].touched &&
                            interMountian.controls['510'].errors) ||
                          (interMountian.controls['510'].errors && isSubmit)
                        "
                        type="text"
                        ngbDatepicker
                        #in13="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('510')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in13.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="(interMountian.controls['510'].invalid&&interMountian.controls['510'].touched) && (interMountian.controls['510'].value==''|| interMountian.controls['510'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="interMountian.get('510').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="interMountian.get('510').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="interMountian.get('510').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="interMountian.get('510').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    
                  </div>

                  <div class="col-md-1">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:
                    </label>
                  </div>
                  <div class="col-md-3">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1238"
                        name="1238"
                        formControlName="1238"
                        [class.is-invalid]="
                          (interMountian.controls['1238'].touched &&
                            interMountian.controls['1238'].errors) ||
                          (interMountian.controls['1238'].errors && isSubmit)
                        "
                        type="text"
                        ngbDatepicker
                        #in14="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('1238')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in14.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="(interMountian.controls['1238'].invalid&&interMountian.controls['1238'].touched) && (interMountian.controls['1238'].value==''|| interMountian.controls['1238'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="interMountian.get('1238').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="interMountian.get('1238').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="interMountian.get('1238').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="interMountian.get('1238').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="504"
                      [class.is-invalid]="
                        (interMountian.controls['504'].touched &&
                          interMountian.controls['504'].errors) ||
                        (interMountian.controls['504'].errors && isSubmit)
                      "
                      id="504"
                      name="504"
                      type="text"
                      placeholder="UG School Address 1"
                    />
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      interMountian.controls['504'].touched &&
                      interMountian.controls['504'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="interMountian.controls['504'].errors?.required"
                    >
                      required
                    </small>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="505"
                      [class.is-invalid]="
                        (interMountian.controls['505'].touched &&
                          interMountian.controls['505'].errors) ||
                        (interMountian.controls['505'].errors && isSubmit)
                      "
                      id="505"
                      name="505"
                      type="text"
                      placeholder="UG School Address 2"
                    />
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      interMountian.controls['505'].touched &&
                      interMountian.controls['505'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="interMountian.controls['505'].errors?.required"
                    >
                      required
                    </small>
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="506"
                      [class.is-invalid]="
                        (interMountian.controls['506'].touched &&
                          interMountian.controls['506'].errors) ||
                        (interMountian.controls['506'].errors && isSubmit)
                      "
                      id="506"
                      name="506"
                      type="text"
                      placeholder="UG School city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>
                  <div
                    class="formLabel col-md-11"
                    *ngIf="
                      interMountian.controls['506'].touched &&
                      interMountian.controls['506'].invalid
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="interMountian.controls['506'].errors?.required"
                    >
                      required
                    </small>
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="508"
                      [class.is-invalid]="
                        (interMountian.controls['508'].touched &&
                          interMountian.controls['508'].errors) ||
                        (interMountian.controls['508'].errors && isSubmit)
                      "
                    >
                    </ng-select>

                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['508'].touched &&
                        interMountian.controls['508'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['508'].errors"
                      >
                        required
                      </small>
                    </div>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="509"
                      [class.is-invalid]="
                        (interMountian.controls['509'].touched &&
                          interMountian.controls['509'].errors) ||
                        (interMountian.controls['509'].errors && isSubmit)
                      "
                      id="509"
                      name="509"
                      type="text"
                      placeholder="UG School Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
                <div
                  class="formLabel col-md-11"
                  *ngIf="
                    interMountian.controls['509'].touched &&
                    interMountian.controls['509'].invalid
                  "
                >
                  <small
                    class="text-danger"
                    *ngIf="interMountian.controls['509'].errors?.required"
                  >
                    required
                  </small>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Graduate?</label>
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333380"
                        name="cmd_333380"
                        formControlName="cmd_333380"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333380"
                        name="cmd_333380"
                        formControlName="cmd_333380"
                        value="0"
                      />No</label
                    >
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Degree Type </label>
                  </div>

                  <div
                    class="col-md-2 mb-3 selectDropTwo degree inputPadding"
                  >
                    <ng-select
                      [items]="degree"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="512"
                      [class.is-invalid]="
                        (interMountian.controls['512'].touched &&
                          interMountian.controls['512'].errors) ||
                        (interMountian.controls['512'].errors && isSubmit)
                      "
                    >
                    </ng-select>
                    <div
                      class="formLabel col-md-11"
                      *ngIf="
                        interMountian.controls['512'].touched &&
                        interMountian.controls['512'].invalid
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="interMountian.controls['512'].errors"
                      >
                        required
                      </small>
                    </div>
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle"
                      >Date of Graduation (mm/yyyy)
                    </label>
                  </div>

                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="511"
                        name="511"
                        formControlName="511"
                        [class.is-invalid]="
                          (interMountian.controls['511'].touched &&
                            interMountian.controls['511'].errors) ||
                          (interMountian.controls['511'].errors && isSubmit)
                        "
                        type="text"
                        ngbDatepicker
                        #in15="ngbDatepicker"
                        (blur)="clearInputIfInvalidMandi('511')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in15.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div class="text-danger" *ngIf="(interMountian.controls['511'].invalid&&interMountian.controls['511'].touched) && (interMountian.controls['511'].value==''|| interMountian.controls['511'].value==null) " >
                      <small >
                      required
                      </small>
    
                  </div>
                    <small class="text-danger" *ngIf="interMountian.get('511').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)                </small>
                    <small class="text-danger" *ngIf="interMountian.get('511').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="interMountian.get('511').errors?.invalidDateyear">
                      year is out of range(1900-2099)                  </small>
                      <small class="text-danger" *ngIf="interMountian.get('511').errors?.invalidDateRange">
                        date is out of range
                        </small>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Graduate</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="479"
                      id="479"
                      name="479"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="481"
                        name="481"
                        formControlName="481"
                        type="text"
                        ngbDatepicker
                        #in16="ngbDatepicker"
                        (blur)="clearInputIfInvalid('481')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in16.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('481').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('481').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('481').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('481').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('481').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="1300"
                        name="1300"
                        formControlName="1300"
                        type="text"
                        ngbDatepicker
                        #in17="ngbDatepicker"
                        (blur)="clearInputIfInvalid('1300')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in17.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('1300').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('1300').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('1300').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('1300').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('1300').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="483"
                      id="483"
                      name="483"
                      type="text"
                      placeholder="Graduate Address 1"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="484"
                      id="484"
                      name="484"
                      type="text"
                      placeholder="Graduate Address 2"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="485"
                      id="485"
                      name="485"
                      type="text"
                      placeholder="Graduate city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="487"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="488"
                      id="488"
                      name="488"
                      type="text"
                      placeholder="Graduate Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Graduate?</label>
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333381"
                        name="cmd_333381"
                        formControlName="cmd_333381"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333381"
                        name="cmd_333381"
                        formControlName="cmd_333381"
                        value="0"
                      />No</label
                    >
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Degree Type </label>
                  </div>

                  <div
                    class="col-md-2 mb-3 selectDropTwo degree inputPadding"
                  >
                    <ng-select
                      [items]="degree"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="480"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle"
                      >Date of Graduation (mm/yyyy)
                    </label>
                  </div>

                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="482"
                        name="482"
                        formControlName="482"
                        type="text"
                        ngbDatepicker
                        #in18="ngbDatepicker"
                        (blur)="clearInputIfInvalid('482')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in18.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('482').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('482').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('482').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('482').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('482').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Doctorate</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="490"
                      id="490"
                      name="490"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="497"
                        name="497"
                        formControlName="497"
                        type="text"
                        ngbDatepicker
                        #in19="ngbDatepicker"
                        (blur)="clearInputIfInvalid('497')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in19.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('497').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('497').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('497').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('497').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('497').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="498"
                        name="498"
                        formControlName="498"
                        type="text"
                        ngbDatepicker
                        #in20="ngbDatepicker"
                        (blur)="clearInputIfInvalid('498')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in20.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('498').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('498').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('498').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('498').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('498').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="491"
                      id="491"
                      name="491"
                      type="text"
                      placeholder="Medical School Address 1"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="492"
                      id="492"
                      name="492"
                      type="text"
                      placeholder="Medical School Address 2"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="493"
                      id="493"
                      name="493"
                      type="text"
                      placeholder="Medical School city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="495"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="496"
                      id="496"
                      name="496"
                      type="text"
                      placeholder="Medical School Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Graduate?</label>
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333382"
                        name="cmd_333382"
                        formControlName="cmd_333382"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333382"
                        name="cmd_333382"
                        formControlName="cmd_333382"
                        value="0"
                      />No</label
                    >
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">Degree Type </label>
                  </div>

                  <div
                    class="col-md-2 mb-3 selectDropTwo degree inputPadding"
                  >
                    <ng-select
                      [items]="degree"
                      bindLabel="name"
                      placeholder="Select Degree"
                      bindValue="id"
                      formControlName="500"
                    >
                    </ng-select>
                  </div>

                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle"
                      >Date of Graduation (mm/yyyy)
                    </label>
                  </div>

                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="499"
                        name="499"
                        formControlName="499"
                        type="text"
                        ngbDatepicker
                        #in21="ngbDatepicker"
                        (blur)="clearInputIfInvalid('499')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in21.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('499').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('499').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('499').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('499').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('499').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Additional Education/Training: Internship</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="517"
                      id="517"
                      name="517"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:</label
                    >
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="524"
                        name="524"
                        formControlName="524"
                        type="text"
                        ngbDatepicker
                        #in23="ngbDatepicker"
                        (blur)="clearInputIfInvalid('524')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in23.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('524').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('524').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('524').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('524').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('524').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="525"
                        name="525"
                        formControlName="525"
                        type="text"
                        ngbDatepicker
                        #in24="ngbDatepicker"
                        (blur)="clearInputIfInvalid('525')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in24.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('525').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('525').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('525').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('525').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('525').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="518"
                      id="518"
                      name="518"
                      type="text"
                      placeholder="Internship Address 1"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="519"
                      id="519"
                      name="519"
                      type="text"
                      placeholder="Internship Address 2"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="520"
                      id="520"
                      name="520"
                      type="text"
                      placeholder="Internship city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="522"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="523"
                      id="523"
                      name="523"
                      type="text"
                      placeholder="Medical School Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle">
                      Program Complete?
                    </label>
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333383"
                        name="cmd_333383"
                        formControlName="cmd_333383"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333383"
                        name="cmd_333383"
                        formControlName="cmd_333383"
                        value="0"
                      />No</label
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Additional Education/Training: Residency</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="529"
                      id="529"
                      name="529"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="537"
                        name="537"
                        formControlName="537"
                        type="text"
                        ngbDatepicker
                        #in49="ngbDatepicker"
                        (blur)="clearInputIfInvalid('537')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in49.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('537').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('537').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('537').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('537').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('537').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="538"
                        name="538"
                        formControlName="538"
                        type="text"
                        ngbDatepicker
                        #in51="ngbDatepicker"
                        (blur)="clearInputIfInvalid('538')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in51.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('538').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('538').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('538').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('538').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('538').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="531"
                      id="531"
                      name="531"
                      type="text"
                      placeholder="Residency Address 1"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="1498"
                      id="1498"
                      name="1498"
                      type="text"
                      placeholder="Residency Address 2"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="533"
                      id="533"
                      name="533"
                      type="text"
                      placeholder="Residency city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="535"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="536"
                      id="536"
                      name="536"
                      type="text"
                      placeholder="Residency Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle"
                      >Program Complete?</label
                    >
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333384"
                        name="cmd_333384"
                        formControlName="cmd_333384"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333384"
                        name="cmd_333384"
                        formControlName="cmd_333384"
                        value="0"
                      />No</label
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <thead class="subtabhead">
            <tr>
              <th class="tabTh">Additional Education/Training: Fellowship</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px">
                      Institution:</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="542"
                      id="542"
                      name="542"
                      type="text"
                    />
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >From:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="550"
                        name="550"
                        formControlName="550"
                        type="text"
                        ngbDatepicker
                        #in50="ngbDatepicker"
                        (blur)="clearInputIfInvalid('550')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in50.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('550').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('550').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('550').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('550').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('550').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>

                  <div class="col-md-2">
                    <label style="font-weight: normal;font-size: 14px"
                      >To:
                    </label>
                  </div>
                  <div class="col-md-2">
                    <div class="input-group">
                      <input
                        class="form-control onboard datepicker input-group"
                        placeholder="MM/DD/YYYY"
                        id="551"
                        name="551"
                        formControlName="551"
                        type="text"
                        ngbDatepicker
                        #in52="ngbDatepicker"
                        (blur)="clearInputIfInvalid('551')"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="in52.toggle()"
                          type="button"
                        >
                          <i class="fas fa-calendar-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div  *ngIf="interMountian.get('551').invalid ">
                      <small class="text-danger" *ngIf="interMountian.get('551').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('551').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="interMountian.get('551').errors?.invalidDateyear">
                        year is out of range(1900-2099)                      </small>
                        <small class="text-danger" *ngIf="interMountian.get('551').errors?.invalidDateRange">
                          date is out of range
                          </small>
                      </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-1">
                    <label class="normalParagraphStyle">Address </label>
                  </div>
                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="544"
                      id="544"
                      name="544"
                      type="text"
                      placeholder="Fellowship Address 1"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="545"
                      id="545"
                      name="545"
                      type="text"
                      placeholder="Fellowship Address 2"
                    />
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="546"
                      id="546"
                      name="546"
                      type="text"
                      placeholder="Fellowship city"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >City</label
                    >
                  </div>

                  <div class="col-md-2 mb-3 degree">
                    <ng-select
                      [items]="states"
                      bindLabel="name"
                      placeholder="Select State"
                      bindValue="id"
                      formControlName="548"
                    >
                    </ng-select>

                    <label style="font-weight: normal;font-size: 14px"
                      >state</label
                    >
                  </div>

                  <div class="col-md-2">
                    <input
                      class="form-control onboard"
                      formControlName="523"
                      id="523"
                      name="523"
                      type="text"
                      placeholder="Fellowship Zip"
                    />
                    <label style="font-weight: normal;font-size: 14px"
                      >Zip</label
                    >
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="row">
                  <div class="col-md-2 mb-3">
                    <label class="normalParagraphStyle"
                      >Program Complete?</label
                    >
                  </div>
                  <div class="col-md-2 mb-3">
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333385"
                        name="cmd_333385"
                        formControlName="cmd_333385"
                        value="1"
                      />Yes</label
                    >
                    <label class="checkbox-inline radioStyle">
                      <input
                        type="radio"
                        id="cmd_333385"
                        name="cmd_333385"
                        formControlName="cmd_333385"
                        value="0"
                      />No</label
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="pdf-line" />

        <table class="table tabwidth" border="1">
          <thead class="tabhead">
            <tr>
              <th class="tabTh">
                List in Chronological Order starting with Most Recent last 10
                Years’ of Experience
              </th>
            </tr>
          </thead>
        </table>

        <div class="row mb-3">
          <div class="col-md-12">
            <label
              ><b
                >Any gaps in employment history equal to or greater than six (6)
                months, requires a brief explanation in the space provided
                below.</b
              >
            </label>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="445"
              id="445"
              name="445"
              type="text"
              data-id="managing_contact_name"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="453"
              id="453"
              name="453"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="448"
              id="448"
              name="448"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="450"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1326"
              id="1326"
              name="1326"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="454"
                name="454"
                formControlName="454"
                type="text"
                ngbDatepicker
                #in25="ngbDatepicker"
                (blur)="clearInputIfInvalid('454')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in25.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('454').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('454').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('454').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('454').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('454').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="455"
                name="455"
                formControlName="455"
                type="text"
                ngbDatepicker
                #in26="ngbDatepicker"
                (blur)="clearInputIfInvalid('455')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in26.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('455').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('455').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('455').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('455').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('455').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="456"
              id="456"
              name="456"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="464"
              id="464"
              name="464"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="459"
              id="459"
              name="459"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="461"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1343"
              id="1343"
              name="1343"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="465"
                name="465"
                formControlName="465"
                type="text"
                ngbDatepicker
                #in27="ngbDatepicker"
                (blur)="clearInputIfInvalid('465')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in27.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('465').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('465').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('465').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('465').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('465').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="466"
                name="466"
                formControlName="466"
                type="text"
                ngbDatepicker
                #in28="ngbDatepicker"
                (blur)="clearInputIfInvalid('466')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in28.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('466').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('466').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('466').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('466').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('466').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="467"
              id="467"
              name="467"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="475"
              id="475"
              name="475"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="470"
              id="470"
              name="470"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="472"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1339"
              id="1339"
              name="1339"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="476"
                name="476"
                formControlName="476"
                type="text"
                ngbDatepicker
                #in29="ngbDatepicker"
                (blur)="clearInputIfInvalid('476')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in29.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('476').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('476').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('476').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('476').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('476').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="477"
                name="477"
                formControlName="477"
                type="text"
                ngbDatepicker
                #in30="ngbDatepicker"
                (blur)="clearInputIfInvalid('477')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in30.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('477').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('477').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('477').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('477').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('477').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1935"
              id="1935"
              name="1935"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1944"
              id="1944"
              name="1944"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1938"
              id="1938"
              name="1938"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="1940"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1349"
              id="1349"
              name="1349"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="1947"
                name="1947"
                formControlName="1947"
                type="text"
                ngbDatepicker
                #in31="ngbDatepicker"
                (blur)="clearInputIfInvalid('1947')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in31.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('1947').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('1947').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1947').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1947').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('1947').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="1948"
                name="1948"
                formControlName="1948"
                type="text"
                ngbDatepicker
                #in32="ngbDatepicker"
                (blur)="clearInputIfInvalid('1948')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in32.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('1948').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('1948').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1948').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1948').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('1948').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1954"
              id="1954"
              name="1954"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1963"
              id="1963"
              name="1963"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1957"
              id="1957"
              name="1957"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="1959"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="1968"
              id="1968"
              name="1968"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="1966"
                name="1966"
                formControlName="1966"
                type="text"
                ngbDatepicker
                #in33="ngbDatepicker"
                (blur)="clearInputIfInvalid('1966')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in33.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('1966').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('1966').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1966').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1966').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('1966').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="1967"
                name="1967"
                formControlName="1967"
                type="text"
                ngbDatepicker
                #in34="ngbDatepicker"
                (blur)="clearInputIfInvalid('1967')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in34.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('1967').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('1967').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1967').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('1967').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('1967').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33473"
              id="cmd_33473"
              name="cmd_33473"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33479"
              id="cmd_33479"
              name="cmd_33479"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33476"
              id="cmd_33476"
              name="cmd_33476"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="cmd_33477"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33481"
              id="cmd_33481"
              name="cmd_33481"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33482"
                name="cmd_33482"
                formControlName="cmd_33482"
                type="text"
                ngbDatepicker
                #in35="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33482')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in35.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33482').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33482').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33482').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33482').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33482').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33483"
                name="cmd_33483"
                formControlName="cmd_33483"
                type="text"
                ngbDatepicker
                #in36="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33483')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in36.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33483').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33483').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33483').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33483').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33483').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33484"
              id="cmd_33484"
              name="cmd_33484"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33490"
              id="cmd_33490"
              name="cmd_33490"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33487"
              id="cmd_33487"
              name="cmd_33487"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="cmd_33488"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33492"
              id="cmd_33492"
              name="cmd_33492"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33493"
                name="cmd_33493"
                formControlName="cmd_33493"
                type="text"
                ngbDatepicker
                #in37="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33493')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in37.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33493').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33493').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33493').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33493').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33493').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33494"
                name="cmd_33494"
                formControlName="cmd_33494"
                type="text"
                ngbDatepicker
                #in38="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33494')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in38.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33494').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33494').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33494').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33494').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33494').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
            
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label>Company:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33495"
              id="cmd_33495"
              name="cmd_33495"
              type="text"
            />
          </div>
          <div class="col-md-2">
            <label>Telephone :</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33501"
              id="cmd_33501"
              name="cmd_33501"
              type="text"
            />
          </div>
        </div>

        <div class="row ">
          <div class="col-md-2">
            <label>City</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33498"
              id="cmd_33498"
              name="cmd_33498"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>State</label>
          </div>

          <div class="col-md-2 mb-3 degree">
            <ng-select
              [items]="states"
              bindLabel="name"
              placeholder="Select State"
              bindValue="id"
              formControlName="cmd_33499"
            >
            </ng-select>
          </div>
        </div>

        <div class="row spacecell">
          <div class="col-md-2">
            <label> Job Title:</label>
          </div>

          <div class="col-md-2">
            <input
              class="form-control onboard"
              formControlName="cmd_33503"
              id="cmd_33503"
              name="cmd_33503"
              type="text"
            />
          </div>

          <div class="col-md-2">
            <label>Starting Mo/Yr</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33504"
                name="cmd_33504"
                formControlName="cmd_33504"
                type="text"
                ngbDatepicker
                #in39="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33504')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in39.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33504').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33504').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33504').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33504').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33504').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-2">
            <label>Ending Mo/Yr:</label>
          </div>
          <div class="col-md-2">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_33505"
                name="cmd_33505"
                formControlName="cmd_33505"
                type="text"
                ngbDatepicker
                #in40="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_33505')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in40.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_33505').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_33505').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33505').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_33505').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_33505').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label>Are you currently Active Duty Military? </label>
          </div>

          <div class="col-md-2">
            <input type="radio" id="cmd_333386" name="cmd_333386" value="1" />
            <label>Yes</label>
            <input type="radio" id="cmd_333386" name="cmd_333386" value="0" />
            <label>No</label>
          </div>

          <div class="col-md-4">
            <label>Are you currently in the Reserves or National Guard?</label>
          </div>

          <div class="col-md-2">
            <input type="radio" id="cmd_333387" name="cmd_333387" value="1" />
            <label>Yes</label>
            <input type="radio" id="cmd_333387" name="cmd_333387" value="0" />
            <label>No</label>
          </div>
        </div>

        <hr class="pdf-line" />

        <div class="row">
          <h2 class="headingstyleinm">PROVIDER CLINICAL FOCUS CHECKLIST</h2>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333388"
              name="cmd_333388"
              value="1"
            />Abuse (Physical, Sexual, etc.)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333389"
              name="cmd_333389"
              value="1"
            />Medicaid Opioid Treatment Program (OTP)-Physicians Only
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333390"
              name="cmd_333390"
              value="1"
            />Addiction Medicine
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333391"
              name="cmd_333391"
              value="1"
            />Mood Disorder
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333392"
              name="cmd_333392"
              value="1"
            />Anger Management
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333393"
              name="cmd_333393"
              value="1"
            />Multi-Systemic Therapy (MST)
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333394"
              name="cmd_333394"
              value="1"
            />Anxiety
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333395"
              name="cmd_333395"
              value="1"
            />Naltrexone Injectable MAT
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333396"
              name="cmd_333396"
              value="1"
            />Attention Deficit Disorders (ADHD)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333397"
              name="cmd_333397"
              value="1"
            />Native American Traditional Healing
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333398"
              name="cmd_333398"
              value="1"
            />Autism Spectrum Disorders
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333399"
              name="cmd_333399"
              value="1"
            />Obsessive Compulsive Disorder
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333400"
              name="cmd_333400"
              value="1"
            />Behavior Modification
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333401"
              name="cmd_333401"
              value="1"
            />Organic Disorders
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333402"
              name="cmd_333402"
              value="1"
            />Biofeedback
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333403"
              name="cmd_333403"
              value="1"
            />Medicaid OpioidPain Management
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333404"
              name="cmd_333404"
              value="1"
            />Bipolar Disorder
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333405"
              name="cmd_333405"
              value="1"
            />Positive Behavioral Interventions & Supports
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333406"
              name="cmd_333406"
              value="1"
            />Co-Occurring Disorders (Dual Diagnosis)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333407"
              name="cmd_333407"
              value="1"
            />Post-Partum Depression
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333408"
              name="cmd_333408"
              value="1"
            />Cognitive Behavioral Therapy
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333409"
              name="cmd_333409"
              value="1"
            />Post-Traumatic Stress Disorder (PTSD)
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333410"
              name="cmd_333410"
              value="1"
            />Depression
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333411"
              name="cmd_333411"
              value="1"
            />Program of Assertive Community Treatment (ACT)
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333412"
              name="cmd_333412"
              value="1"
            />Dialectical Behavioral Therapy
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333413"
              name="cmd_333413"
              value="1"
            />Psychological Testing
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333414"
              name="cmd_333414"
              value="1"
            />Dissociative Disorders
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333415"
              name="cmd_333415"
              value="1"
            />Psychotic/Schizophrenic Disorders
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333416"
              name="cmd_333416"
              value="1"
            />Domestic Violence
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333417"
              name="cmd_333417"
              value="1"
            />Relaxation Technics
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333418"
              name="cmd_333418"
              value="1"
            />Electroconvulsive Therapy (ECT)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333419"
              name="cmd_333419"
              value="1"
            />School-based Services
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333420"
              name="cmd_333420"
              value="1"
            />Enhanced Residential Services
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333421"
              name="cmd_333421"
              value="1"
            />Serious Mental Illness
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333422"
              name="cmd_333422"
              value="1"
            />Evaluation & Assessment (Mental Health)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333423"
              name="cmd_333423"
              value="1"
            />Sex Offender Treatment
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333424"
              name="cmd_333424"
              value="1"
            />Eye Movement Desensitization & Reprocessing (EMDR)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333425"
              name="cmd_333425"
              value="1"
            />Sexual Dysfunction
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333426"
              name="cmd_333426"
              value="1"
            />Feeding & Eating Disorders
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333427"
              name="cmd_333427"
              value="1"
            />Sleep-Wake Disorders
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333428"
              name="cmd_333428"
              value="1"
            />Greif & Bereavement
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333429"
              name="cmd_333429"
              value="1"
            />Somatoform Disorders
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333430"
              name="cmd_333430"
              value="1"
            />HIV/AIDS/ARC
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333431"
              name="cmd_333431"
              value="1"
            />SPRAVATO™ (Prescribers Only)
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333432"
              name="cmd_333432"
              value="1"
            />Hypnosis
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333433"
              name="cmd_333433"
              value="1"
            />Structured Outpatient Addiction Program (SOAP)
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333434"
              name="cmd_333434"
              value="1"
            />Infertility
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333435"
              name="cmd_333435"
              value="1"
            />Substance Use Disorders
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333436"
              name="cmd_333436"
              value="1"
            />Intellectual & Developmental Disabilities
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333437"
              name="cmd_333437"
              value="1"
            />Transgender
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333438"
              name="cmd_333438"
              value="1"
            />Intensive Outpatient Program (IOP)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333439"
              name="cmd_333439"
              value="1"
            />
            Trauma Therapy
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333440"
              name="cmd_333440"
              value="1"
            />LBGTQ
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333441"
              name="cmd_333441"
              value="1"
            />Traumatic Brain Injury
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333442"
              name="cmd_333442"
              value="1"
            />Medical Illness/Disease Management
          </div>
        </div>

        <hr class="pdf-line" />

        <table class="table gaptable center" border="1">
          <div class="col-md-12">
            <label>Briefly explain employment gap:</label>
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="Gap Start Date"
                id="3395"
                name="3395"
                formControlName="3395"
                type="text"
                ngbDatepicker
                #in41="ngbDatepicker"
                (blur)="clearInputIfInvalid('3395')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in41.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3395').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3395').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3395').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3395').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3395').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                id="3396"
                name="3396"
                type="text"
                formControlName="3396"
                placeholder="Gap End Date"
                type="text"
                ngbDatepicker
                #in42="ngbDatepicker"
                (blur)="clearInputIfInvalid('3396')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in42.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3396').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3396').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3396').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3396').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3396').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-12 spacecell">
            <input
              class="form-control onboard "
              id="1138"
              name="1138"
              formControlName="1138"
              type="text"
              placeholder="Explanation for GAP in work history"
              data-id="name"
            />
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                id="3397"
                name="3397"
                type="text"
                formControlName="3397"
                placeholder="Gap Start Date2"
                ngbDatepicker
                #in43="ngbDatepicker"
                (blur)="clearInputIfInvalid('3397')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in43.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3397').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3397').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3397').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3397').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3397').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                id="3398"
                name="3398"
                type="text"
                placeholder="Gap End Date2"
                formControlName="3398"
                ngbDatepicker
                #in44="ngbDatepicker"
                (blur)="clearInputIfInvalid('3398')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in44.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3398').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3398').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3398').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3398').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3398').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-12 spacecell">
            <input
              class="form-control onboard "
              id="1831"
              name="1831"
              type="text"
              formControlName="1831"
              placeholder="Explanation for GAP in work history2"
              data-id="name"
            />
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                id="3399"
                name="3399"
                formControlName="3399"
                type="text"
                placeholder="Gap Start Date3"
                ngbDatepicker
                #in46="ngbDatepicker"
                (blur)="clearInputIfInvalid('3399')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in46.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3399').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3399').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3399').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3399').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3399').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-5 spacecell">
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                id="3400"
                name="3400"
                type="text"
                formControlName="3400"
                placeholder="Gap End Date3"
                ngbDatepicker
                #in47="ngbDatepicker"
                (blur)="clearInputIfInvalid('3400')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in47.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('3400').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('3400').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3400').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('3400').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('3400').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>

          <div class="col-md-12 spacecell">
            <input
              class="form-control onboard "
              id="1832"
              name="1832"
              formControlName="1832"
              type="text"
              placeholder="Explanation for GAP in work history3"
              data-id="name"
            />
          </div>
        </table>

        <table class="table tabwidth" border="1">
          <thead class="tabhead">
            <tr>
              <th class="tabTh">Required Documents</th>
            </tr>
          </thead>
        </table>
        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333443"
              name="cmd_333443"
              value="1"
            />Professional License
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333444"
              name="cmd_333444"
              value="1"
            />DEA Certification (if applicable)
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333445"
              name="cmd_333445"
              value="1"
            />Board Certification (if applicable)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333446"
              name="cmd_333446"
              value="1"
            />Dangerous Substance Card (DCS) (if applicable)
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333447"
              name="cmd_333447"
              value="1"
            />Degree/Diploma (most recent)
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333448"
              name="cmd_333448"
              value="1"
            />Fingerprint Clearance Card
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333449"
              name="cmd_333449"
              value="1"
            />CV or Resume
          </div>

          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333450"
              name="cmd_333450"
              value="1"
            />COI/ACORD Statement (if Contracted)
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <input
              type="checkbox"
              id="cmd_333451"
              name="cmd_333451"
              value="1"
            />
            Standard Attestation, Authorization and Release (Signed)
          </div>
        </div>

        <div class="text-center">
          By signing below you are attesting to the accuracy of the information
          provided.
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>Provider Name</label>
            <input
              class="form-control onboard"
              formControlName="cmd_333452"
              id="cmd_333452"
              name="cmd_333452"
              type="text"
              placeholder="Provider Name"
            />
          </div>
          <div class="col-md-5">
            <label>Date</label>
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_333453"
                name="cmd_333453"
                formControlName="cmd_333453"
                type="text"
                ngbDatepicker
                #in53="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_333453')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in53.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_333453').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_333453').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333453').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333453').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_333453').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label>Signature:</label>
          </div>
          <div class="col-md-3">
            <img
              *ngIf="!showOne"
              class="img"
              src="./assets/images/e_sign.png"
              alt="Credential my Doc"
              (click)="esignOpenOne(1)"
            />
            <img
              *ngIf="showOne"
              class="img eSignImageShow"
              [src]="esignOne"
              alt="Credential my Doc"
              (click)="esignOpenOne(1)"
            />
          </div>
        </div>
        <div class="row">
          <input
            type="hidden"
            name="cmd_333468"
            formControlName="cmd_333468"
            id="cmd_333468"
          />
          <div
            class="formLabel"
            *ngIf="
              (interMountian.controls['cmd_333468'].touched &&
                interMountian.controls['cmd_333468'].errors) ||
              (interMountian.controls['cmd_333468'].errors && isSubmit)
            "
          >
            <small
              class="text-danger signpadd"
              *ngIf="interMountian.controls['cmd_333468'].errors"
            >
              Signature required
            </small>
          </div>
        </div>

        <div class="text-center">
          Please submit completed application and all required documents to
          <a href="iccredentialing@ichd.net.">iccredentialing@ichd.net.</a>
        </div>

        <hr class="pdf-line" />
        <h6 class="text-center">
          <b> Standard Authorization, Attestation and Release</b>
        </h6>
        <div class="mb-3  ">
          <p>
            I understand and agree that, as part of the credentialing
            application process for participation, membership and/or clinical
            privileges (hereinafter, referred to as "Participation") at or with
            each healthcare organization indicated on the "List of Authorized
            Organizations" that accompanies this Provider Application
            (hereinafter,each healthcare organization on the "List of Authorized
            Organizations" is individually referred to as the "Entity"), and any
            of the Entity's affiliated entities, I am required to provide
            sufficient and accurate information for a proper evaluation of my
            current licensure, relevant training and/or experience, clinical
            competence, health status, character, ethics, and any other criteria
            used by the Entity for determining initial and ongoing eligibility
            for Participation. Each Entity and its representatives, employees,
            and agent(s) acknowledge that the information obtained relating to
            the application process will be held confidential to the extent
            permitted by law.
          </p>
        </div>
        <div class="mb-3  ">
          <p>
            I acknowledge that each Entity has its own criteria for acceptance,
            and I may be accepted or rejected by each independently. I further
            acknowledge and understand that my cooperation in obtaining
            information and my consent to the release of information do not
            guarantee that any Entity will grant me clinical privileges or
            contract with me as a provider of services. I understand that my
            application for Participation with the Entity is not an application
            for employment with the Entity and that acceptance of my application
            by the Entity will not result in my employment by the Entity.
          </p>
        </div>

        <div class="mb-3  ">
          <p>
            <b
              >Authorization of Investigation Concerning Application for
              Participation.</b
            >b I authorize the following individuals including, without
            limitation, the Entity, its representatives, employees, and/or
            designated agent(s); the Entity's affiliated entities and their
            representatives, employees, and/or designated agents; and the
            Entity's designated professional credentials verification
            organization (collectively referred to as "Agents"), to investigate
            information, which includes both oral and written
            statements,records, and documents, concerning my application for
            Participation. I agree to allow the Entity and/or its Agent(s) to
            inspect and copy all records and documents relating to such an
            investigation.
          </p>
        </div>

        <div class="mb-3  ">
          <p>
            <b
              >Authorization of Third-Party Sources to Release Information
              Concerning Application for Participation.
            </b>
            I authorize any third party, including, but not limited
            to,individuals, agencies, medical groups responsible for credentials
            verification, corporations, companies, employers, former employers,
            hospitals, health plans, health maintenance organizations, managed
            care organizations, law enforcement or licensing agencies, insurance
            companies, educational and other institutions, military services,
            medical credentialing and accreditation agencies, professional
            medical societies, the Federation of State Medical Boards, the
            National Practitioner Data Bank, and the Health Care Integrity and
            Protection Data Bank, to release to the Entity and/or its Agent(s),
            information, including otherwise privileged or confidential
            information, concerning my professional qualifications, credentials,
            clinical competence, quality assurance and utilization data,
            character, mental condition, physical condition, alcohol or chemical
            dependency diagnosis and treatment, ethics, behavior, or any other
            matter reasonably having a bearing on my qualifications for
            Participation in, or with, the Entity. I authorize my current and
            past professional liability carrier(s) to release my history of
            claims that have been made and/or are currently pending against me.
            I specifically waive written notice from any entities and
            individuals who provide information based upon this Authorization,
            Attestation and Release.
          </p>
        </div>
        <div class="mb-3  ">
          <p>
            <b
              >Authorization of Release and Exchange of Disciplinary
              Information.</b
            >
            I hereby further authorize any third party at which I currently have
            Participation or had Participation and/or each third party's agents
            to release "Disciplinary Information," as defined below, to the
            Entity and/or its Agent(s). I hereby further authorize the Agent(s)
            to release Disciplinary Information about any disciplinary action
            taken against me to its participating Entities at which I have
            Participation, and as may be otherwise required by law. As used
            herein, "Disciplinary Information" means information concerning (i)
            any action taken by such health care organizations, their
            administrators, or their medical or other committees to revoke,
            deny, suspend, restrict, or condition my Participation or impose a
            corrective action plan; (ii) any other disciplinary action involving
            me, including, but not limited to, discipline in the employment
            context; or (iii) my resignation prior to the conclusion of any
            disciplinary proceedings or prior to the commencement of formal
            charges, but after I have knowledge that such formal charges were
            being (or are being) contemplated and/or were (or are) in
            preparation.
          </p>
        </div>
        <div class="mb-3  ">
          <p>
            <b>Release from Liability.</b> I release from all liability and hold
            harmless any Entity, its Agent(s), and any other third party for
            their acts performed in good faith and without malice unless such
            acts are due to the gross negligence or willful misconduct of the
            Entity, its Agent(s), or other third party in connection with the
            gathering, release and exchange of, and reliance upon, information
            used in accordance with this Authorization, Attestation and Release.
            I further agree not to sue any Entity, any Agent(s), or any other
            third party for their acts, defamation or any other claims based on
            statements made in good faith and without malice or misconduct of
            such Entity, Agent(s) or third party in connection with the
            credentialing process. This release shall be in addition to, and in
            no way shall limit, any other applicable immunities provided by law
            for peer review and credentialing activities. In this Authorization,
            Attestation and Release, all references to the Entity, its Agent(s),
            and/or other third party include their respective employees,
            directors, officers, advisors, counsel, and agents. The Entity or
            any of its affiliates or agents retains the right to allow access to
            the application information for purposes of a credentialing audit to
            customers and/or their auditors to the extent required in connection
            with an audit of the credentialing processes and provided that the
            customer and/or their auditor executes an appropriate
            confidentiality agreement. I understand and agree that this
            Authorization, Attestation and Release is irrevocable for any period
            during which I am an applicant for Participation at an Entity, a
            member of an Entity's medical or health care staff, or a
            participating provider of an Entity. I agree to execute another form
            of consent if law or regulation limits the application of this
            irrevocable authorization. I understand that my failure to promptly
            provide another consent may be grounds for termination or discipline
            by the Entity in accordance with the applicable bylaws, rules, and
            regulations, and requirements of the Entity, or grounds for my
            termination of Participation at or with the Entity. I agree that
            information obtained in accordance with the provisions of this
            Authorization, Attestation and Release is not and will not be a
            violation of my privacy.
          </p>
        </div>
        <div class="mb-3  ">
          <p>
            I certify that all information provided by me in my application is
            current, true, correct, accurate and complete to the best of my
            knowledge and belief, and is furnished in good faith. I will notify
            the Entity and/or its Agent(s) within 10 days of any material
            changes to the information (including any changes/challenges to
            licenses, DEA, insurance, malpractice claims, NPDB/HIPDB reports,
            discipline, criminal convictions, etc.) I have provided in my
            application or authorized to be released pursuant to the
            credentialing process. I understand that corrections to the
            application are permitted at any time prior to a determination of
            Participation by the Entity, and must be submitted online or in
            writing, and must be dated and signed by me (may be a written or an
            electronic signature). I acknowledge that the Entity will not
            process an application until they deem it to be a complete
            application and that I am responsible to provide a complete
            application and to produce adequate and timely information for
            resolving questions that arise in the application process. I
            understand and agree that any material misstatement or omission in
            the application may constitute grounds for withdrawal of the
            application from consideration; denial or revocation of
            Participation; and/or immediate suspension or termination of
            Participation. This action may be disclosed to the Entity and/or its
            Agent(s). I further acknowledge that I have read and understand the
            foregoing Authorization, Attestation and Release and that I have
            access to the bylaws of applicable medical staff organizations and
            agree to abide by these bylaws, rules and regulations. I understand
            and agree that a facsimile or photocopy of this Authorization,
            Attestation and Release shall be as effective as the original.
          </p>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>Provider Name</label>
            <input
              class="form-control onboard"
              formControlName="cmd_333454"
              id="cmd_333454"
              name="cmd_333454"
              type="text"
              placeholder="Provider Name"
            />
          </div>
          <div class="col-md-3">
            <label>Signature:</label>
          </div>
          <div class="col-md-3">
            <img
              *ngIf="!showTwo"
              class="img"
              src="./assets/images/e_sign.png"
              alt="Credential my Doc"
              (click)="esignOpenOne(2)"
            />
            <img
              *ngIf="showTwo"
              class="img eSignImageShow"
              [src]="esignTwo"
              alt="Credential my Doc"
              (click)="esignOpenOne(2)"
            />
          </div>
        </div>
        <div class="row">
          <input
            type="hidden"
            name="cmd_333455"
            formControlName="cmd_333455"
            id="cmd_333455"
          />
        </div>
        <div class="row">
          <div class="col-md-5">
            <label>Date</label>
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_333456"
                name="cmd_333456"
                formControlName="cmd_333456"
                type="text"
                ngbDatepicker
                #in45="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_333456')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in45.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_333456').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_333456').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333456').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333456').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_333456').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>

        <hr class="pdf-line" />

        <div class="text-center">
          <img
            src="../../../../assets/images/intermountain.png"
            alt="Credential my Doc"
            height=" 200px"
          />
        </div>

        <div class="row text-center">
          <h2 class="headintermountian">
            <u>Practitioner/Provider Consent Form</u>
          </h2>
        </div>

        <div class="row">
          <div class="col-sm-1 ">
            <label> I, </label>
          </div>

          <div class="col-md-4">
            <input
              class="form-control onboard "
              id="cmd_333457"
              name="cmd_333457"
              type="text"
              formControlName="cmd_333457"
            />
          </div>

          <div class="col-md-7">
            <label>
              give my consent to Intermountain Centers for Human Development and
              its Affiliates
            </label>
          </div>
        </div>

        <div class="mb-3">
          <p>
            (Intermountain Centers),to update and maintain my National Provider
            Identifier (NPI) record, my CAQH Proview profile and my AHCCCS
            Provider registration for as long as I am employed and/or contracted
            by Intermountain Centers. I understand that this consent will be in
            full effect from the date of signature through the date of
            separation, at which time it will become null and void. I further
            understand and agree to provide my login information to the afore
            mentioned systems.
          </p>
        </div>

        <div class="row">
          <div class="col-md-1 ">
            <label
              ><b>
                NPI
              </b>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell ">
            <label>username: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333458"
              name="cmd_333458"
              type="text"
              formControlName="cmd_333458"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label>password: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333459"
              name="cmd_333459"
              type="text"
              formControlName="cmd_333459"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label
              ><b>
                CAQH
              </b>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label>username: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333460"
              name="cmd_333460"
              type="text"
              formControlName="cmd_333460"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label>password: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333461"
              name="cmd_333461"
              type="text"
              formControlName="cmd_333461"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label
              ><b>
                AHCCCS
              </b>
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label>username: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333462"
              name="cmd_333462"
              type="text"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1 spacecell">
            <label>password: </label>
          </div>

          <div class="col-md-4 spacecell">
            <input
              class="form-control onboard "
              id="cmd_333463"
              name="cmd_333463"
              type="text"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-1">
            <input
              type="checkbox"
              id="cmd_333464"
              name="cmd_333464"
              value="1"
            />
          </div>

          <div class="col-md-11">
            <label>
              I decline to allow Intermountain Centers to update and maintain my
              NPI, CAQH and AHCCCS information. By declining, I understand and
              agree to maintain these accounts with current and accurate
              information throughout my employment/contract with Intermountain
              Centers.
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label>Provider Name</label>
            <input
              class="form-control onboard"
              formControlName="cmd_333465"
              id="cmd_333465"
              name="cmd_333465"
              type="text"
              placeholder="Provider Name"
            />
          </div>
        </div>
        <div class="row" style="padding-left:20px">
          <div class="col-md-3">
            <label>Signature:</label>
          </div>
          <div class="col-md-4">
            <img
              *ngIf="!showThree"
              class="img"
              src="./assets/images/e_sign.png"
              alt="Credential my Doc"
              (click)="esignOpenOne(3)"
            />
            <img
              *ngIf="showThree"
              class="img eSignImageShow"
              [src]="esignThree"
              alt="Credential my Doc"
              (click)="esignOpenOne(3)"
            />
            <input
              type="hidden"
              name="cmd_333466"
              formControlName="cmd_333466"
              id="cmd_333466"
            />
          </div>
          <div class="col-md-5">
            <label>Date</label>
            <div class="input-group">
              <input
                class="form-control onboard datepicker input-group"
                placeholder="MM/DD/YYYY"
                id="cmd_333467"
                name="cmd_333467"
                formControlName="cmd_333467"
                type="text"
                ngbDatepicker
                #in48="ngbDatepicker"
                (blur)="clearInputIfInvalid('cmd_333467')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="in48.toggle()"
                  type="button"
                >
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <div  *ngIf="interMountian.get('cmd_333467').invalid ">
              <small class="text-danger" *ngIf="interMountian.get('cmd_333467').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333467').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="interMountian.get('cmd_333467').errors?.invalidDateyear">
                year is out of range(1900-2099)                      </small>
                <small class="text-danger" *ngIf="interMountian.get('cmd_333467').errors?.invalidDateRange">
                  date is out of range
                  </small>
              </div>
          </div>
        </div>
        <div class="text-center">
          Please return completed form to
          <a href="iccredentialing@ichd.net">iccredentialing@ichd.net</a>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer mt-4">
    <button
      *ngIf="formData.receivedForm"
      type="button"
      class="btn customOrange float-end"
      (click)="saveForm('approve')"
    >
      Approve
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('submit')"
    >
      Submit
    </button>
    <button
      *ngIf="formData.submitForm"
      type="button"
      class="btn btn customOrange float-end"
      (click)="saveForm('save')"
    >
      Save
    </button>
    <button
      *ngIf="formData.previewForm"
      type="button"
      class="btn customOrange float-end"
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
