<header>
    <nav class="navbar navbar-expand navbar-light bg-white fixed-top">
      
     <a class="navbar-brand px-2 me-0" >
       <img src="./../../../../assets/images/logo.png"  width="200" class="d-inline-block align-top img-fluid img-logo" alt="">
     </a>
     </nav>
     </header>
<div id="content-wrapper">

    <div class="container-fluid">

        <div class="topBox">
            <h4>Medical Staff Verification</h4>
        </div>
        <div class="whiteBack">


            <ngb-accordion [closeOthers]="true" [activeIds]="active">
                <ngb-panel id="1">
                    <ng-template ngbPanelTitle>
                        <h6 class="container-fluid">
                            <div class="row">
                                <div class="col-sm-10">
                                    <h6> Searcher Data </h6>
                                </div>
                                <div class="col-sm-2 text-danger">{{name}}
                                </div>
                            </div>
                        </h6>

                    </ng-template>

                    <ng-template ngbPanelContent>
                        <form [formGroup]="searchUserForm">

                            <div class="mb-3 row">
                                <label for="firstIntial" class="col-sm-2 col-form-label">First Initial<span
                                        class="text-danger"> * </span> : </label>
                                <div class="mb-3 col-sm-7">
                                    <input [class.is-invalid]="searchUserForm.get('firstInitial').invalid && 
                                    searchUserForm.get('firstInitial').touched" type="text" maxlength="1"
                                        class="form-control" formControlName="firstInitial" id="firstIntial"  placeholder="Enter Your First Initial"  >
                                    <small [class.d-none]="searchUserForm.get('firstInitial').valid ||
                                    searchUserForm.get('firstInitial').untouched" class="text-danger">
                                        First Initial is required</small>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="lastname" class="col-sm-2 col-form-label">Last Name <span
                                    class="text-danger"> * </span>: </label>
                                <div class="mb-3 col-sm-7">
                                    <input type="text" [class.is-invalid]="searchUserForm.get('lastname').invalid && 
                                    searchUserForm.get('lastname').touched" class="form-control"
                                        formControlName="lastname" id="lastname" placeholder="Enter Your Last Name">
                                    <small [class.d-none]="searchUserForm.get('lastname').valid ||
                                    searchUserForm.get('lastname').untouched" class="text-danger">
                                        Lastname is required</small>

                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="facility" class="col-sm-2 col-form-label">Facility<span
                                    class="text-danger"> * </span> : </label>
                                <div class="mb-3 col-sm-7">
                                    <input type="text" [class.is-invalid]="searchUserForm.get('facility').invalid && 
                                    searchUserForm.get('facility').touched" class="form-control"
                                        formControlName="facility" id="facility" placeholder="Enter Facility">
                                    <small [class.d-none]="searchUserForm.get('facility').valid ||
                                    searchUserForm.get('facility').untouched" class="text-danger">
                                        Facility is required</small>

                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="phone" class="col-sm-2 col-form-label">Phone <span
                                    class="text-danger"> * </span>: </label>
                                <div class="mb-3 col-sm-7">
                                    <input type="text" required 
                                     formControlName="phoneNo" (keydown)="handleNumber($event,'phoneNo')" class="form-control" id="phone"
                                        minlength = "12" maxlength="12" id="phone" placeholder="Enter Your Phone Number">

                                    <div *ngIf="searchUserForm.get('phoneNo').invalid && 
                                    searchUserForm.get('phoneNo').touched">
                                        <small class="text-danger"
                                            *ngIf="searchUserForm.get('phoneNo').errors?.required">Phone Number is
                                            required.</small>
                                        <small class= "text-danger" *ngIf="searchUserForm.get('phoneNo').hasError('minlength')">
                                        Phone number must be 12 digits.</small>

                                        
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label  class="col-sm-2 col-form-label"> </label>
                                <div class="mb-3 col-sm-7">
                                    <button class="btn customOrange" type="submit"  [disabled]="!searchUserForm.valid"
                                        (click)="submitSearcherData()">Submit</button>
                                </div>
                            </div>

                        </form>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="2" [disabled]="showProviderForm">
                    <ng-template ngbPanelTitle>
                        <div class="d-flex justify-content-between pt-1">
                            <div class="d-flex">
                                <h6> Verify Provider </h6>
                            </div>
                            <!-- <div class="d-flex">{{searchUserForm.firstInitial.value}}{{searchUserForm.lastname.value}}
                          </div>  -->
                        </div>
                    </ng-template>

                    <ng-template ngbPanelContent>
                        <form [formGroup]="verifyUserForm">
                            <div class="mb-3 row">
                                <label for="firstIntial" class="col-sm-2 col-form-label">First Initial <span
                                    class="text-danger"> * </span>: </label>
                                <div class="mb-3 col-sm-7">
                                    <input type="text" [class.is-invalid]="verifyUserForm.get('pfirstInitial').invalid && 
                                    verifyUserForm.get('pfirstInitial').touched" maxlength="1" class="form-control"
                                        id="firstIntial" formControlName="pfirstInitial" placeholder="Enter Provider First Initial">
                                    <small [class.d-none]="verifyUserForm.get('pfirstInitial').valid ||
                                     verifyUserForm.get('pfirstInitial').untouched" class="text-danger">
                                        First Initial is required</small>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="lastname" class="col-sm-2 col-form-label">Last Name <span
                                    class="text-danger"> * </span>: </label>
                                <div class="mb-3 col-sm-7">
                                    <input type="text" [class.is-invalid]="verifyUserForm.get('plastname').invalid && 
                                    verifyUserForm.get('plastname').touched" class="form-control" id="lastname"
                                        formControlName="plastname" id= "lastname" placeholder="Enter Provider Last Name">
                                    <small [class.d-none]="verifyUserForm.get('plastname').valid ||
                                     verifyUserForm.get('plastname').untouched" class="text-danger">
                                        Lastname is required</small>

                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="pfacility" class="col-form-label col-sm-2"> Facility <span
                                    class="text-danger"> * </span>: </label>
                                <!-- <select id="facility" class="form-control col-sm-6">
                                            <option>Select Facility</option>
                                        </select> -->
                                <div class="col-sm-7">
                                    <ng-select bindLabel="customer" placeholder="Select Facility" bindValue="user_id"
                                        [items]="facilityDropdown" dropdownPosition="button"
                                        formControlName="pfacility">
                                    </ng-select>
                                    <small *ngIf="verifyUserForm.get('pfacility').value ==null && 
                                        verifyUserForm.get('pfacility').touched" class="text-danger">
                                        Facility is required.</small>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="ssn" class="col-sm-2 col-form-label">SSN or DOB <span
                                    class="text-danger"> * </span>: </label>
                                <div class="mb-3 col-sm-3">
                                    <input type="text" class="form-control" id="ssn" formControlName="ssn" placeholder="Last 4 Digits of SSN" maxlength="4">
                                </div>
                                <label for="dob" class="col-sm-1 col-form-label"> or </label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="dob" name="dp"
                                            formControlName="dob" placeholder="MM/DD/YYYY" ngbDatepicker appMaskInput [appMaskValue]="this.verifyUserForm.value.dob"
                                            #d1="ngbDatepicker"  placement="left" [minDate]="{year: 1900, month:1, day: 1}"
                                            >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                                type="button"><i class="fas fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="mb-3 row">
                                <label  class="col-sm-2 col-form-label"> </label>
                                <div class="mb-3 col-sm-7">
                                    <button class="btn customOrange" type="submit" [disabled]="!verifyUserForm.valid"
                                        (click)="openModal()">Search</button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>

            </ngb-accordion>
         

            </div>
        </div>
    </div>