import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { TexasSectionOneComponent } from '../../../shared/SharedForms/texas/shared/SharedForms/texas/texas-section-one/texas-section-one.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
import { TexasSectionOneComponent as TexasSectionOneComponent_1 } from './shared/SharedForms/texas/texas-section-one/texas-section-one.component';

@Component({
    selector: 'ctg-texas',
    templateUrl: './texas.component.html',
    styleUrls: ['./texas.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TexasSectionOneComponent_1, NgIf, NgbDatepickerModule, NgSelectModule]
})
export class TexasComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer
  ) {}

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public texas: UntypedFormGroup;
  questionCounter = 0;
  indexKey = 0;
  yesNoQuestionIds: any = [];
  yesNoQuestionExplanation: any = [];
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  greetings: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  degree: Array<object> = [];
  location: Array<object> = [];
  languages: Array<object> = [];
  credentials: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  boardCertification: Array<object> = [];
  specialtyTer: Array<object> = [];
  states: Array<object> = [];
  id: '';
  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  showOne: any = false;
  isSubmit: boolean = false;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  public eduapp: boolean = false;
  public workapp: boolean = false;
  public hospapp: boolean = false;
  public refapp: boolean = false;
  public proapp: boolean = false;
  public prospeapp: boolean = false;
  public country: any = [];
  changeCmdOne: any;
  changeCmdTwo: any;
  changeCmdThree: any;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  ngOnInit(): void {
    this.buildForm();
    this.getSuffixData();
    this.getCredentialsData();
    this.getStatesData();
    this.getDegreeData();
    this.getCountyData();
    this.getAltCdsStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getTerSpecialtyData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    this.getPreClaimData();
    this.getAggregatesData();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_0778') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_023') {
        if (array[i].field_value == 1) {
          this.changeCmdOne = 1;
        } else {
          this.changeCmdOne = 0;
        }
      }
      if (array[i].field_name == 'cmd_05') {
        if (array[i].field_value == 1) {
          this.changeCmdTwo = 1;
        } else {
          this.changeCmdTwo = 0;
        }
      }
      if (array[i].field_name == 'cmd_024') {
        if (array[i].field_value == 1) {
          this.changeCmdThree = 1;
        } else {
          this.changeCmdThree = 0;
        }
      }
    }
    this.texas.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_0778') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 15,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCountyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  buildForm() {
    this.texas = this.fb.group({
      cmd_0931: [null],
      cmd_0702: [null],
      822: [null, [Validators.required]],
      20: [null, [Validators.required]],
      841: [null],
      cmd_0817: [null],
      cmd_0827: [null],
      cmd_0347: [null],
      cmd_0806: [null],
      cmd_08: [''],
      cmd_0385: [null],
      cmd_0401: [null],
      cmd_0414: [null],
      cmd_0432: [null],
      cmd_0441: [null],
      cmd_0450: [null],
      cmd_0459: [null],
      cmd_0838: [null],
      cmd_0888: [''],
      cmd_0889: [''],
      cmd_0638: [''],
      cmd_0641: [''],
      cmd_0644: [''],
      cmd_0647: [''],
      cmd_0650: [''],
      cmd_0653: [''],
      cmd_0656: [''],
      cmd_0660: [''],
      cmd_0716: [''],
      cmd_0715: [''],
      cmd_0717: [''],
      cmd_0718: [''],

      cmd_0846: [null],
      cmd_0854: [null],
      991: [null],
      cmd_0862: [null],
      cmd_0870: [null],
      cmd_0497: [null],
      cmd_0509: [null],
      cmd_0521: [null],
      cmd_0533: [null],
      cmd_0545: [null],
      cmd_0535: [null],
      cmd_0878: [null],
      cmd_0890: [null],
      29: [null],
      289: [null, [Validators.required]],
      495: [null],
      496: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      500: [null, [Validators.required]],
      522: [null],
      535: [null],
      1293: [null],
      1010: [null],
      450: [null],
      461: [null],
      472: [null],
      1940: [null],
      423: [null],
      432: [null],
      1188: [null],
      364: [null],
      377: [null],
      389: [null],
      905: [null],
      1248: [null],
      cmd_0733: [null],
      cmd_0750: [null],
      cmd_0754: [null],
      cmd_0764: [null],
      cmd_0784: [null],
      cmd_0793: [null],
      cmd_0320: [null],
      cmd_0329: [null],
      cmd_0338: [null],
      cmd_0356: [null],
      cmd_0423: [null],
      cmd_0895: [null],
      cmd_0899: [null],
      cmd_0909: [null],
      cmd_0778: [''],
      9: ['', [Validators.required]],
      8: ['', [Validators.required]],
      809: [''],
      278: [''],
      280:[null, [this.dateFormatValidator]],
      281: [null, [this.dateFormatValidator]],
      cmd_01:  [null, [this.dateFormatValidator]],
      cmd_02:  [null, [this.dateFormatValidator]],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      18: ['', [Validators.required]],
      21: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      708: ['', [Validators.required]],
      32: ['', [Validators.required]],
      cmd_03: [''],
      837: [''],
      838: [''],
      839: [''],
      842: [''],
      870: [''],
      871: [''],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      31:[null, [Validators.required,this.dateFormatValidator]],
      810: ['', [Validators.required]],
      1584: [''],
      1282: [''],
      cmd_04: [''],
      cmd_05: [''],
      1144: [null],
      1145: [null],
      1139: ['', [Validators.required]],
      1141: ['', [Validators.required]],
      cmd_06: [''],
      cmd_0722: [''],
      490: [''],
      491: [''],
      cmd_09: [''],
      cmd_0365: [''],
      cmd_012: [''],
      492: [''],
      493: [''],
      497:[null, [Validators.required,this.dateFormatValidator]],
      498:[null, [Validators.required,this.dateFormatValidator]],
      cmd_07: [''],
      527: [null],
      517: [''],
      518: [''],
      519: [''],
      520: [''],
      523: [''],
      524: [null, [this.dateFormatValidator]],
      525:  [null, [this.dateFormatValidator]],
      528: [''],
      cmd_010: [''],
      530: [null],
      529: [''],
      531: [''],
      1498: [''],
      533: [''],
      536: [''],
      537:[null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      540: [''],
      1288: [''],
      1289: [''],
      1290: [''],
      1291: [''],
      1294: [''],
      1298: [null],
      1295:[null,  [this.dateFormatValidator]],
      1297:[null, [this.dateFormatValidator]],
      296: [''],
      293: ['', [Validators.required]],
      299: [null, [Validators.required]],
      294: [null, [Validators.required,this.dateFormatValidator]],
      295:[null, [Validators.required,this.dateFormatValidator]],
      cmd_013: [''],
      1274: [''],
      300: [''],
      907: [null],
      307:[null,  [this.dateFormatValidator]],
      308:[null,  [this.dateFormatValidator]],
      cmd_014: [''],
      1432: [''],
      999: [''],
      1003:[null,[this.dateFormatValidator]],
      1004: [null,[this.dateFormatValidator]],
      cmd_015: [''],
      309: [''],
      cmd_016: [''],
      cmd_017: [''],
      cmd_0100: [''],
      310:[null,[this.dateFormatValidator]],
      311: [null,[this.dateFormatValidator]],
      1193: [''],
      cmd_018:[null,[this.dateFormatValidator]],
      821: [null,[this.dateFormatValidator]],
      cmd_019: [''],
      337: [''],
      996: [''],
      338: [null,[this.dateFormatValidator]],
      339: [null,[this.dateFormatValidator]],
      cmd_020: [''],
      819: [''],
      1634: [''],
      cmd_021: [''],
      325: [''],
      cmd_022: [''],
      812: [''],
      cmd_023: ['', [Validators.required]],
      514: [''],
      515: [null,[this.dateFormatValidator]],
      cmd_0726: [''],
      556: [null],
      cmd_024: [''],
      554: [null],
      557: [null, [Validators.required,this.dateFormatValidator]],
      559:  [null,[this.dateFormatValidator]],
      560:  [null,[this.dateFormatValidator]],
      cmd_029: [''],
      cmd_030: [''],
      cmd_031: [''],
      cmd_028: [''],
      cmd_032: [''],
      cmd_033: [''],
      cmd_034: [''],
      563: [null],
      cmd_035: [''],
      561: [null],
      564: [null,[this.dateFormatValidator]],
      565:[null,[this.dateFormatValidator]],
      567: [null,[this.dateFormatValidator]],
      cmd_036: [''],
      cmd_040: [''],
      cmd_037: [''],
      cmd_041: [''],
      cmd_042: [''],
      cmd_038: [''],
      cmd_039: [''],
      cmd_043: [''],
      cmd_044: [''],
      cmd_045: [''],
      948: [null],
      cmd_046: [''],
      946: [null],
      953:[null,[this.dateFormatValidator]],
      950: [null,[this.dateFormatValidator]],
      954:[null,[this.dateFormatValidator]],
      cmd_047: [null],
      cmd_051: [''],
      cmd_048: [''],
      cmd_052: [''],
      cmd_049: [''],
      cmd_053: [''],
      cmd_050: [''],
      cmd_054: [''],
      cmd_055: [''],
      cmd_056: [''],
      cmd_057: [''],
      cmd_0727: [''],
      445: [''],
      454: [null, [Validators.required,this.dateFormatValidator]],
      455: [null, [Validators.required,this.dateFormatValidator]],
      446: [''],
      447: [''],
      448: [''],
      451: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      456: [''],
      465: [null,[this.dateFormatValidator]],
      466:[null,[this.dateFormatValidator]],
      457: [''],
      458: [''],
      459: [''],
      462: [''],
      1338: [''],
      467: [''],
      476:[null,[this.dateFormatValidator]],
      477: [null,[this.dateFormatValidator]],
      468: [''],
      469: [''],
      470: [''],
      473: [''],
      1342: [''],
      1935: [''],
      1947: [null,[this.dateFormatValidator]],
      1948: [null,[this.dateFormatValidator]],
      1936: [''],
      1937: [''],
      1938: [''],
      1941: [''],
      1952: [''],
      3395: [null,[this.dateFormatValidator]],
      3396:  [null,[this.dateFormatValidator]],
      3397: [null,[this.dateFormatValidator]],
      3398:[null,[this.dateFormatValidator]],
      3399: [null,[this.dateFormatValidator]],
      3400: [null,[this.dateFormatValidator]],
      1138: [''],
      1831: [''],
      1832: [''],
      1953: [''],
      3401:[null,[this.dateFormatValidator]],
      3402: [null,[this.dateFormatValidator]],
      cmd_058: [''],
      cmd_0723: [''],
      cmd_059: [''],
      cmd_060: [''],
      418: [''],
      426: [null, [Validators.required,this.dateFormatValidator]],
      419: [''],
      420: [''],
      421: [''],
      424: [''],
      1305: [''],
      2136: [''],
      2142: [''],
      cmd_061: [''],
      894: [''],
      cmd_062: [''],
      cmd_063: [''],
      427: [''],
      435: [null,[this.dateFormatValidator]],
      428: [''],
      430: [''],
      433: [''],
      1307: [''],
      2137: [''],
      2143: [''],
      cmd_064: [''],
      895: [''],
      cmd_065: [''],
      cmd_067: [''],
      cmd_066: [''],
      1183: [''],
      1191: [null,[this.dateFormatValidator]],
      2381:[null,[this.dateFormatValidator]],
      1184: [''],
      1185: [''],
      1186: [''],
      1302: [''],
      cmd_068: [''],
      1192: [''],
      cmd_069: [''],
      1303: [''],
      cmd_070: [''],
      cmd_0724: [''],
      359: [''],
      371: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      369: [''],
      370: [''],
      360: [''],
      361: [''],
      362: [''],
      365: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      372: [''],
      383: [''],
      381: ['', [Validators.required]],
      382: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      373: [''],
      374: [''],
      375: [''],
      378: [''],
      384: [''],
      396: [''],
      394: ['', [Validators.required]],
      395: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      385: [''],
      386: [''],
      387: [''],
      390: [''],
      cmd_0725: [''],
      cmd_071: [''],
      345: [''],
      901: [''],
      902: [''],
      903: [''],
      906: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      1266: [''],
      346: [''],
      945: [null, [Validators.required,this.dateFormatValidator]],
      349: [null, [Validators.required,this.dateFormatValidator]],
      350: [null],
      351: [null],
      cmd_072: [''],
      cmd_073: [''],
      1242: [''],
      1244: [''],
      1245: [''],
      1246: [''],
      1249: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      1254: [''],
      1256: [''],
      1252:  [null, [Validators.required,this.dateFormatValidator]],
      1253: [null, [Validators.required,this.dateFormatValidator]],
      1264: [null],
      1265: [null],
      cmd_074: [''],
      cmd_075: [''],
      cmd_076: [''],
      2953: [''],
      3403: [''],
      2963: [''],
      3404: [''],
      2979: [''],
      3405: [''],
      cmd_077: [''],
      cmd_078: [''],
      cmd_079: [''],
      cmd_080: [''],
      cmd_082: [''],
      cmd_083: [''],
      cmd_084: [''],
      cmd_085: [''],
      cmd_086: [''],
      cmd_087: [''],
      cmd_088: [''],
      cmd_089: [''],
      cmd_090: [''],
      cmd_091: [''],
      cmd_092: [''],
      cmd_0101: [''],
      cmd_0102: [''],
      cmd_0103: [''],
      cmd_0104: [''],
      1233: [''],
      cmd_0720: [''],
      cmd_0105: [''],
      cmd_093: [''],
      cmd_094: [''],
      cmd_0728: [''],
      cmd_0729: [''],
      cmd_095: [''],
      cmd_0730: [''],
      cmd_0731: [''],
      cmd_0732: [''],
      cmd_0734: [''],
      cmd_0735: [''],
      cmd_0736: [''],
      cmd_0737: [''],
      cmd_0738: [''],
      cmd_0739: [''],
      cmd_0740: [''],
      cmd_0741: [''],
      cmd_0742: [''],
      cmd_0743:[null,[this.dateFormatValidator]],
      cmd_096: [''],
      cmd_0744: [''],
      cmd_0745: [''],
      cmd_0746: [''],
      cmd_0747: [''],
      cmd_0748: [''],
      cmd_0749: [''],
      cmd_0751: [''],
      cmd_0752: [''],
      cmd_0753: [''],
      cmd_0755: [''],
      cmd_0756: [''],
      cmd_0757: [''],
      cmd_0758: [''],
      cmd_0759: [''],
      cmd_0760: [''],
      cmd_0761: [''],
      cmd_0762: [''],
      cmd_0763: [''],
      cmd_0765: [''],
      cmd_0766: [''],
      cmd_0767: [''],
      cmd_0768: [''],
      cmd_097: [''],
      cmd_0769: [''],
      cmd_098: [''],
      cmd_099: [''],
      cmd_0770: [''],
      cmd_0106: [''],
      cmd_0114: [''],
      cmd_011: [''],
      cmd_0771: [''],
      cmd_0107: [''],
      cmd_0115: [''],
      cmd_0772: [''],
      cmd_0109: [''],
      cmd_0116: [''],
      cmd_0773: [''],
      cmd_0110: [''],
      cmd_0117: [''],
      cmd_0774: [''],
      cmd_0111: [''],
      cmd_0118: [''],
      cmd_0775: [''],
      cmd_0112: [''],
      cmd_0119: [''],
      cmd_0776: [''],
      cmd_0113: [''],
      cmd_0120: [''],
      cmd_0121: [''],
      cmd_0122: [''],
      cmd_0123: [''],
      cmd_0124: [''],
      cmd_0125: [''],
      cmd_0126: [''],
      cmd_0127: [''],
      cmd_0128: [''],
      cmd_0129: [''],
      cmd_0130: [''],
      cmd_0131: [''],
      cmd_0777: [''],
      cmd_0132: [''],
      cmd_0133: [''],
      cmd_0134: [''],
      cmd_0135: [''],
      cmd_0136: [''],
      cmd_0137: [''],
      cmd_0138: [''],
      cmd_0139: [''],
      cmd_0140: [''],
      cmd_0141: [''],
      cmd_0142: [''],
      cmd_0143: [''],
      cmd_0144: [''],
      cmd_0145: [''],
      cmd_0146: [''],
      cmd_0147: [''],
      cmd_0148: [''],
      cmd_0149: [''],
      cmd_0150: [''],
      cmd_0151: [''],
      cmd_0152: [''],
      cmd_0153: [''],
      cmd_0154: [''],
      cmd_0155: [''],
      cmd_0156: [''],
      cmd_0157: [''],
      cmd_0158: [''],
      cmd_0159: [''],
      cmd_0160: [''],
      cmd_0161: [''],
      cmd_0162: [''],
      cmd_0163: [''],
      cmd_0164: [''],
      cmd_0165: [''],
      cmd_0166: [''],
      cmd_0167: [''],
      cmd_0168: [''],
      cmd_0169: [''],
      cmd_0170: [''],
      cmd_0171: [''],
      cmd_0172: [''],
      cmd_0173: [''],
      cmd_0174: [''],
      cmd_0175: [''],
      cmd_0176: [''],
      cmd_0177: [''],
      cmd_0178: [''],
      cmd_0179: [''],
      cmd_0180: [''],
      cmd_0181: [''],
      cmd_0183: [''],
      cmd_0184: [''],
      cmd_0186: [''],
      cmd_0187: [''],
      cmd_0189: [''],
      cmd_0190: [''],
      cmd_0192: [''],
      cmd_0193: [''],
      cmd_0195: [''],
      cmd_0196: [''],
      cmd_0182: [''],
      cmd_0185: [''],
      cmd_0188: [''],
      cmd_0191: [''],
      cmd_0194: [''],
      cmd_0197: [''],
      cmd_0200: [''],
      cmd_0204: [''],

      cmd_0198: [''],
      cmd_0199: [''],
      cmd_0201: [''],
      cmd_0202: [''],
      cmd_0203: [''],
      cmd_0205: [''],
      cmd_0206: [''],
      cmd_0207: [''],
      cmd_0208: [''],
      cmd_0209: [''],
      cmd_0210: [''],
      cmd_0211: [''],
      cmd_0212: [''],
      cmd_0213: [''],
      cmd_0214: [''],
      cmd_0215: [''],
      cmd_0216: [''],
      cmd_0217: [''],
      cmd_0218: [''],
      cmd_0219: [''],
      cmd_0220: [''],
      cmd_0222: [''],
      cmd_0223: [''],
      cmd_0224: [''],
      cmd_0225: [''],
      cmd_0226: [''],
      cmd_0227: [''],
      cmd_0228: [''],
      cmd_0229: [''],
      cmd_0230: [''],
      cmd_0231: [''],
      cmd_0232: ['', [Validators.required]],
      cmd_0233: ['', [Validators.required]],
      cmd_0234: ['', [Validators.required]],
      cmd_0235: ['', [Validators.required]],
      cmd_0236: ['', [Validators.required]],
      cmd_0237: ['', [Validators.required]],
      cmd_0238: ['', [Validators.required]],
      cmd_0239: ['', [Validators.required]],
      cmd_0240: ['', [Validators.required]],
      cmd_0241: ['', [Validators.required]],
      cmd_0242: ['', [Validators.required]],
      cmd_0243: ['', [Validators.required]],
      cmd_0244: ['', [Validators.required]],
      cmd_0245: ['', [Validators.required]],
      cmd_0246: ['', [Validators.required]],
      cmd_0247: ['', [Validators.required]],
      cmd_0248: ['', [Validators.required]],
      cmd_0249: ['', [Validators.required]],
      cmd_0250: ['', [Validators.required]],
      cmd_0251: ['', [Validators.required]],
      cmd_025: [''],
      cmd_026: [''],
      cmd_027: [''],
      cmd_0252: ['', [Validators.required]],
      cmd_0253: ['', [Validators.required]],
      cmd_0254: ['', [Validators.required]],
      cmd_0255: ['', [Validators.required]],
      cmd_0256: [''],
      cmd_0257: ['', [Validators.required]],
      cmd_0258: [''],
      cmd_0259: ['', [Validators.required]],
      cmd_0260: [''],
      cmd_0261: ['', [Validators.required]],
      cmd_0262: [''],
      cmd_0263: ['', [Validators.required]],
      cmd_0408: [''],
      cmd_0264: [''],
      cmd_0458: [''],
      cmd_0265: ['', [Validators.required]],
      cmd_0266: [''],
      cmd_0267: ['', [Validators.required]],
      cmd_0268: [''],
      cmd_0269: ['', [Validators.required]],
      cmd_0270: [''],
      cmd_0271: ['', [Validators.required]],
      cmd_0272: [''],
      cmd_0273: ['', [Validators.required]],
      cmd_0274: [''],
      cmd_0275: ['', [Validators.required]],
      cmd_0276: [''],
      cmd_0277: ['', [Validators.required]],
      cmd_0278: [''],
      cmd_0279: ['', [Validators.required]],
      cmd_0280: [''],
      cmd_0281: ['', [Validators.required]],
      cmd_0282: [''],
      cmd_0283: ['', [Validators.required]],
      cmd_0284: [''],
      cmd_0285: ['', [Validators.required]],
      cmd_0286: [''],
      cmd_0287: ['', [Validators.required]],
      cmd_0288: [''],
      cmd_0289: ['', [Validators.required]],
      cmd_0290: [''],
      cmd_0291: ['', [Validators.required]],
      cmd_0292: [''],
      cmd_0293: ['', [Validators.required]],
      cmd_0294: [''],
      cmd_0295: ['', [Validators.required]],
      cmd_0296: [''],
      cmd_0297: ['', [Validators.required]],
      cmd_0298: [''],
      cmd_0299: ['', [Validators.required]],
      cmd_0300: [''],
      cmd_0301: [''],
      cmd_0302: [''],
      cmd_0303: [''],
      cmd_0304: [''],
      cmd_0305: [''],
      cmd_0306: [''],
      cmd_0307: [''],
      cmd_0308: [''],
      cmd_0309: [''],
      cmd_0779: [''],
      cmd_0310: [null,[this.dateFormatValidator]],
      cmd_0311: [''],
      cmd_0312: [''],
      cmd_0313: [''],
      cmd_0314: [''],
      cmd_0315: [''],
      cmd_0316: [''],
      cmd_0317: [''],
      cmd_0318: [''],
      cmd_0780: [''],
      cmd_0781: [''],
      cmd_0782: [''],
      cmd_0783: [''],
      cmd_0785: [''],
      cmd_0786: [''],
      cmd_0787: [null,[this.dateFormatValidator]],
      cmd_0788: [null,[this.dateFormatValidator]],
      cmd_0789: [''],
      cmd_0790: [''],
      cmd_0791: [''],
      cmd_0792: [''],
      cmd_0794: [''],
      cmd_0795: [''],
      cmd_0796: [null,[this.dateFormatValidator]],
      cmd_0797: [null,[this.dateFormatValidator]],
      cmd_0798: [''],
      cmd_0799: [''],
      cmd_0800: [''],
      cmd_0319: [''],
      cmd_0321: [''],
      cmd_0322: [''],
      cmd_0323: [null,[this.dateFormatValidator]],
      cmd_0324: [null,[this.dateFormatValidator]],
      cmd_0325: [''],
      cmd_0326: [''],
      cmd_0327: [''],
      cmd_0328: [''],
      cmd_0330: [''],
      cmd_0331: [''],
      cmd_0332: [null,[this.dateFormatValidator]],
      cmd_0333: [null,[this.dateFormatValidator]],
      cmd_0334: [''],
      cmd_0335: [''],
      cmd_0336: [''],
      cmd_0337: [''],
      cmd_0339: [''],
      cmd_0340: [''],
      cmd_0341: [null,[this.dateFormatValidator]],
      cmd_0342: [null,[this.dateFormatValidator]],
      cmd_0343: [''],
      cmd_0344: [''],
      cmd_0345: [''],
      cmd_0346: [''],
      cmd_0348: [''],
      cmd_0349: [''],
      cmd_0350: [null,[this.dateFormatValidator]],
      cmd_0351:[null,[this.dateFormatValidator]],
      cmd_0352: [''],
      cmd_0353: [''],
      cmd_0354: [''],
      cmd_0355: [''],
      cmd_0357: [''],
      cmd_0358: [''],
      cmd_0359: [null,[this.dateFormatValidator]],
      cmd_0360: [null,[this.dateFormatValidator]],
      cmd_0361: [''],
      cmd_0362: [''],
      cmd_0363: [''],
      cmd_0364: [''],
      cmd_0801: [''],
      cmd_0802: [''],
      cmd_0803: [''],
      cmd_0804: [''],
      cmd_0805: [''],
      cmd_0807: [''],
      cmd_0808: [null,[this.dateFormatValidator]],
      cmd_0809: [null,[this.dateFormatValidator]],
      cmd_0810: [''],
      cmd_0811: [''],
      cmd_0366: [''],
      cmd_0367: [''],
      cmd_0368: [''],
      cmd_0369: [''],
      cmd_0812: [''],
      cmd_0813: [''],
      cmd_0814: [''],
      cmd_0815: [''],
      cmd_0816: [''],
      cmd_0818: [''],
      cmd_0819:[null,[this.dateFormatValidator]],
      cmd_0820: [null,[this.dateFormatValidator]],
      cmd_0821: [''],
      cmd_0822: [''],
      cmd_0830: [null,[this.dateFormatValidator]],
      cmd_0371: [''],
      cmd_0372: [''],
      cmd_0373: [''],
      cmd_0374: [''],
      cmd_0823: [''],
      cmd_0824: [''],
      cmd_0825: [''],
      cmd_0828: [''],
      cmd_0375: [''],
      cmd_0829: [null,[this.dateFormatValidator]],
      cmd_0831: [''],
      cmd_0832: [''],
      cmd_0376: [''],
      cmd_0377: [''],
      cmd_0378: [''],
      cmd_0379: [''],
      cmd_0380: [''],
      cmd_0381: [''],
      cmd_0382: [''],
      cmd_0383: [''],
      cmd_0384: [''],
      cmd_0386: [''],
      cmd_0387: [''],
      cmd_0388: [null,[this.dateFormatValidator]],
      cmd_0389: [null,[this.dateFormatValidator]],
      cmd_0390: [''],
      cmd_0391: [''],
      cmd_0392: [''],
      cmd_0393: [''],
      cmd_0394: [''],
      cmd_0395: [''],
      cmd_0396: [''],
      cmd_0397: [''],
      cmd_0398: [''],
      cmd_0399: [''],
      cmd_0400: [''],
      cmd_0402: [''],
      cmd_0403: [''],
      cmd_0404: [null,[this.dateFormatValidator]],
      cmd_0405: [null,[this.dateFormatValidator]],
      cmd_0406: [''],
      cmd_0407: [''],
      cmd_0409:[null,[this.dateFormatValidator]],
      cmd_0410: [null,[this.dateFormatValidator]],
      cmd_0411: [''],
      cmd_0412: [''],
      cmd_0413: [''],
      cmd_0415: [''],
      cmd_0416: [''],
      cmd_0417: [''],
      cmd_0418: [null,[this.dateFormatValidator]],
      cmd_0419: [null,[this.dateFormatValidator]],
      cmd_0420: [''],
      cmd_0421: [''],
      cmd_0422: [''],
      cmd_0424: [''],
      cmd_0425: [''],
      cmd_0426: [''],
      cmd_0427: [null,[this.dateFormatValidator]],
      cmd_0428: [null,[this.dateFormatValidator]],
      cmd_0429: [''],
      cmd_0430: [''],
      cmd_0431: [''],
      cmd_0433: [''],
      cmd_0434: [''],
      cmd_0435: [''],
      cmd_0436: [null,[this.dateFormatValidator]],
      cmd_0437: [null,[this.dateFormatValidator]],
      cmd_0438: [''],
      cmd_0439: [''],
      cmd_0440: [''],
      cmd_0442: [''],
      cmd_0443: [''],
      cmd_0444: [''],
      cmd_0445:[null,[this.dateFormatValidator]],
      cmd_0446: [null,[this.dateFormatValidator]],
      cmd_0447: [''],
      cmd_0448: [''],
      cmd_0449: [''],
      cmd_0451: [''],
      cmd_0452: [''],
      cmd_0453: [''],
      cmd_0454: [null,[this.dateFormatValidator]],
      cmd_0455: [null,[this.dateFormatValidator]],
      cmd_0456: [''],
      cmd_0457: [''],
      cmd_0460: [''],
      cmd_0461: [''],
      cmd_0833: [''],
      cmd_0834: [null,[this.dateFormatValidator]],
      cmd_0835: [''],
      cmd_0836: [''],
      cmd_0837: [''],
      cmd_0839: [null,[this.dateFormatValidator]],
      cmd_0462: [''],
      cmd_0463: [''],
      cmd_0464: [''],
      cmd_0465: [''],
      cmd_0840: [''],
      cmd_0466: [''],
      cmd_0467: [''],
      cmd_0841: [''],
      cmd_0842: [null,[this.dateFormatValidator]],
      cmd_0843: [''],
      cmd_0844: [''],
      cmd_0845: [''],
      cmd_0847: [''],
      cmd_0468: [''],
      cmd_0469: [''],
      cmd_0470: [''],
      cmd_0471: [''],
      cmd_04711: [''],
      cmd_04701: [''],
      cmd_04691: [''],
      cmd_0848: [''],
      2337: [''],
      292: [''],
      cmd_0472: [''],
      cmd_0473: [''],
      cmd_0849: [''],
      cmd_0850: [null,[this.dateFormatValidator]],
      cmd_0851: [''],
      cmd_0852: [''],
      cmd_0853: [''],
      cmd_0855: [''],
      cmd_0474: [''],
      cmd_0475: [''],
      cmd_0476: [''],
      cmd_0477: [''],
      cmd_0856: [''],
      cmd_0478: [''],
      986: [''],
      994: [null,[this.dateFormatValidator]],
      987: [''],
      988: [''],
      989: [''],
      992: [''],
      995: [''],
      cmd_0479: [''],
      cmd_0857: [''],
      cmd_0858: [null,[this.dateFormatValidator]],
      cmd_0859: [''],
      cmd_0860: [''],
      cmd_0861: [''],
      cmd_0863: [''],
      cmd_0480: [''],
      cmd_0481: [''],
      cmd_0482: [''],
      cmd_0483: [''],
      cmd_0864: [''],
      cmd_0484: [''],
      cmd_0485: [''],
      cmd_0865: [''],
      cmd_0866:  [null,[this.dateFormatValidator]],
      cmd_0867: [''],
      cmd_0868: [''],
      cmd_0869: [''],
      cmd_0871: [''],
      cmd_0486: [''],
      cmd_0487: [''],
      cmd_0488: [''],
      cmd_0872: [''],
      cmd_0719: [''],
      cmd_0511: [''],
      cmd_0489: [''],
      cmd_0490: [''],
      cmd_0491: [''],
      cmd_0492:  [null,[this.dateFormatValidator]],
      cmd_0493:  [null,[this.dateFormatValidator]],
      cmd_0494: [''],
      cmd_0495: [''],
      cmd_0496: [''],
      cmd_0498: [''],
      cmd_0499: [''],
      cmd_0500: [''],
      cmd_0501: [''],
      cmd_0502: [''],
      cmd_0503: [''],
      cmd_0504: [null,[this.dateFormatValidator]],
      cmd_0505:[null,[this.dateFormatValidator]],
      cmd_0506: [''],
      cmd_0507: [''],
      cmd_0508: [''],
      cmd_0510: [''],
      cmd_0512: [''],
      cmd_0513: [''],
      cmd_0514: [''],
      cmd_0515: [''],
      cmd_0516: [null,[this.dateFormatValidator]],
      cmd_0517: [null,[this.dateFormatValidator]],
      cmd_0518: [''],
      cmd_0519: [''],
      cmd_0520: [''],
      cmd_0522: [''],
      cmd_0523: [''],
      cmd_0524: [''],
      cmd_0525: [''],
      cmd_0526: [''],
      cmd_0527: [''],
      cmd_0528:[null,[this.dateFormatValidator]],
      cmd_0529: [null,[this.dateFormatValidator]],
      cmd_0530: [''],
      cmd_0531: [''],
      cmd_0532: [''],
      cmd_0534: [''],
      cmd_0536: [''],
      cmd_0537: [''],
      cmd_0538: [''],
      cmd_0540: [null,[this.dateFormatValidator]],
      cmd_0541: [null,[this.dateFormatValidator]],
      cmd_0542: [''],
      cmd_0543: [''],
      cmd_0544: [''],
      cmd_0546: [''],
      cmd_0547: [''],
      cmd_0548: [''],
      cmd_0549: [''],
      cmd_0550: [''],
      cmd_0551: [''],
      cmd_0552: [''],
      cmd_0553: [''],
      cmd_0554: [''],
      cmd_0555: [''],
      cmd_0873: [''],
      cmd_0874: [''],
      cmd_0556: [''],
      cmd_0875: [''],
      cmd_0876: [''],
      cmd_0877: [''],
      cmd_0879: [''],
      cmd_0880: [''],
      cmd_0881: [''],
      cmd_0882: [''],
      cmd_0883: [''],
      cmd_0884: [''],
      cmd_0885: [''],
      cmd_0557: [''],
      cmd_0886: [''],
      cmd_0558: [''],
      cmd_0887: [null,[this.dateFormatValidator]],
      cmd_0559: [''],
      cmd_0891: [''],
      cmd_0892: [''],
      cmd_0893: [''],
      cmd_0894: [''],
      cmd_0896: [''],
      cmd_0897: [''],
      cmd_0898: [''],
      cmd_0900: [''],
      cmd_0901: [''],
      cmd_0902: [''],
      cmd_0903: [''],
      cmd_0904: [''],
      cmd_0905: [''],
      cmd_0906: [''],
      cmd_0907: [''],
      cmd_0908: [''],
      cmd_0910: [''],
      cmd_0911: [''],
      cmd_0912: [''],
      cmd_0913: [''],
      cmd_0560: [''],
      cmd_0914: [''],
      cmd_0561: [''],
      cmd_0562: [''],
      cmd_0915: [''],
      cmd_0563: [''],
      cmd_0564: [''],
      cmd_0565: [''],
      cmd_0916: [''],
      cmd_0566: [''],
      cmd_0567: [''],
      cmd_0568: [''],
      cmd_0917: [''],
      cmd_0569: [''],
      cmd_0570: [''],
      cmd_0571: [''],
      cmd_0918: [''],
      cmd_0572: [''],
      cmd_0573: [''],
      cmd_0574: [''],
      cmd_0919: [''],
      cmd_0575: [''],
      cmd_0576: [''],
      cmd_0577: [''],
      cmd_0920: [''],
      cmd_0578: [''],
      cmd_0579: [''],
      cmd_0580: [''],
      cmd_0921: [''],
      cmd_0581: [''],
      cmd_0582: [''],
      cmd_0583: [''],
      cmd_0584: [''],
      cmd_0585: [''],
      cmd_0586: [''],
      cmd_0587: [''],
      cmd_0588: [''],
      cmd_0589: [''],
      cmd_0590: [''],
      cmd_0591: [''],
      cmd_0592: [''],
      30: [''],
      cmd_0594: [''],
      cmd_0595: [''],
      cmd_0596: [''],
      cmd_0597: [''],
      cmd_0598: [''],
      cmd_0599: [''],
      cmd_0600: [''],
      cmd_0601: [''],
      cmd_0602: [''],
      cmd_0603: [''],
      cmd_0604: [''],
      cmd_0605: [''],
      cmd_0606: [''],
      cmd_0607: [''],
      cmd_0608: [''],
      cmd_0609: [''],
      cmd_0610: [''],
      cmd_0611: [''],
      cmd_0612: [''],
      cmd_0613: [''],
      cmd_0614: [''],
      cmd_0615: [''],
      cmd_0922: [''],
      cmd_0616: [''],
      cmd_0923: [''],
      cmd_0617: [''],
      cmd_0618: [''],
      cmd_0619: [''],
      cmd_0620: [''],
      cmd_0621: [''],
      cmd_0622: [''],
      cmd_0623: [''],
      cmd_0624: [''],
      cmd_0625: [''],
      cmd_0626: [''],
      cmd_0627: [''],
      cmd_0628: [''],
      cmd_0629: [''],
      cmd_0630: [''],
      cmd_0631: [''],
      cmd_0632: [''],
      cmd_0633: [''],
      cmd_0634: [''],
      cmd_0635: [''],
      cmd_0636: [''],
      cmd_0637: [''],
      cmd_0639: [''],
      cmd_0640: [''],
      cmd_0642: [''],
      cmd_0643: [''],
      cmd_0645: [''],
      cmd_0646: [''],
      cmd_0648: [''],
      cmd_0649: [''],
      cmd_0651: [''],
      cmd_0654: [''],
      cmd_0655: [''],
      cmd_0657: [''],
      cmd_0658: [''],
      cmd_0659: [''],
      cmd_0661: [''],
      cmd_0664: [''],
      cmd_0665: [''],
      cmd_0666: [''],
      cmd_0667: [''],
      cmd_0668: [''],
      cmd_0669: [''],
      cmd_0670: [''],
      cmd_0671: [''],
      cmd_0672: [''],
      cmd_0673: [''],
      cmd_0674: [''],
      cmd_0675: [''],
      cmd_0676: [''],
      cmd_0677: [''],
      cmd_0678: [''],
      cmd_0679: [''],
      cmd_0680: [''],
      cmd_0681: [''],
      cmd_0682: [''],
      cmd_0683: [''],
      cmd_0684: [''],
      cmd_0685: [''],
      cmd_0686: [''],
      cmd_0687: [''],
      cmd_0688: [''],
      cmd_0689: [''],
      cmd_0690: [''],
      cmd_0691: [''],
      cmd_0692: [''],
      cmd_0693: [''],
      cmd_0928: [''],
      cmd_0929: [''],
      cmd_0930: [''],
      cmd_0927: [''],
      cmd_0932: [''],
      cmd_0933: [''],
      cmd_0934: [''],
      cmd_0935: [''],
      cmd_0936: [''],
      cmd_0926: [''],
      cmd_0924: [null,[this.dateFormatValidator]],
      cmd_0925: [null,[this.dateFormatValidator]],
      cmd_0937: [''],
      cmd_0938: [''],
      cmd_0939: [''],
      cmd_0940: [''],
      cmd_0941: [''],
      cmd_0694: [''],
      cmd_0695: [null,[this.dateFormatValidator]],
      cmd_0696: [null,[this.dateFormatValidator]],
      cmd_0697: [''],
      cmd_0698: [''],
      cmd_0699: [''],
      cmd_0700: [''],
      cmd_0701: [''],
      cmd_0703: [''],
      cmd_0704: [''],
      cmd_0705: [''],
      cmd_0706: [''],
      cmd_0707: [''],
      cmd_0708: [''],
      cmd_0709: [''],
      cmd_0710: [''],
      cmd_0711: [''],
      cmd_0712: [''],
      cmd_0714: [''],
      cmd_0713: [''],
      cmd_04681:[''],
      cmd_04721:[''],
      cmd_0593:[''],
    });
  }

  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
    }
  }

  onChangeCmdTwo(event) {
    if (event.target.value == 1) {
      this.changeCmdTwo = true;
    } else {
      this.changeCmdTwo = false;
    }
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  onChangeCmdThree(event) {
    if (event.target.value == 1) {
      this.changeCmdThree = true;
    } else {
      this.changeCmdThree = false;
    }
  }

  public education(event) {
    // console.log(event.target.value, event.target.checked);
    this.eduapp = event.target.checked;
    if (this.eduapp) {
      this.texas.controls[490].setErrors(null);
      this.texas.controls[491].setErrors(null);
      this.texas.controls[492].setErrors(null);
      this.texas.controls[493].setErrors(null);
      this.texas.controls[495].setErrors(null);
      this.texas.controls[496].setErrors(null);
      this.texas.controls[497].setErrors(null);
      this.texas.controls[498].setErrors(null);
    }
  }

  public proSpecApp(event) {
    // console.log(event.target.value, event.target.checked);
    this.prospeapp = event.target.checked;
    if (this.prospeapp) {
      this.texas.controls[556].setErrors(null);
      this.texas.controls[557].setErrors(null);
      this.texas.controls['cmd_024'].setErrors(null);
    }
  }

  public workHistApp(event) {
    // console.log(event.target.value, event.target.checked);
    this.workapp = event.target.checked;
    if (this.workapp) {
      this.texas.controls[445].setErrors(null);
      this.texas.controls[454].setErrors(null);
      this.texas.controls[455].setErrors(null);
      this.texas.controls[446].setErrors(null);
      this.texas.controls[447].setErrors(null);
      this.texas.controls[448].setErrors(null);
      this.texas.controls[450].setErrors(null);
      this.texas.controls[451].setErrors(null);
      this.texas.controls[461].setErrors(null);
      this.texas.controls[472].setErrors(null);
    }
  }

  public hospAffApp(event) {
    // console.log(event.target.value, event.target.checked);
    this.hospapp = event.target.checked;
    if (this.hospapp) {
      this.texas.controls[418].setErrors(null);
      this.texas.controls[426].setErrors(null);
      this.texas.controls[419].setErrors(null);
      this.texas.controls[420].setErrors(null);
      this.texas.controls[421].setErrors(null);
      this.texas.controls[423].setErrors(null);
      this.texas.controls[424].setErrors(null);
      this.texas.controls[1305].setErrors(null);
      this.texas.controls[432].setErrors(null);
      this.texas.controls[1188].setErrors(null);
    }
  }

  public referencesApp(event) {
    // console.log(event.target.value, event.target.checked);
    this.refapp = event.target.checked;
    if (this.refapp) {
      this.texas.controls[359].setErrors(null);
      this.texas.controls[371].setErrors(null);
      this.texas.controls[369].setErrors(null);
      this.texas.controls[360].setErrors(null);
      this.texas.controls[361].setErrors(null);
      this.texas.controls[362].setErrors(null);
      this.texas.controls[364].setErrors(null);
      this.texas.controls[365].setErrors(null);
    }
  }

  public proInsApp(event) {
    // console.log(event.target.value, event.target.checked);
    this.proapp = event.target.checked;
    if (this.proapp) {
      this.texas.controls[345].setErrors(null);
      this.texas.controls[901].setErrors(null);
      this.texas.controls[902].setErrors(null);
      this.texas.controls[903].setErrors(null);
      this.texas.controls[905].setErrors(null);
      this.texas.controls[906].setErrors(null);
      this.texas.controls[1266].setErrors(null);
      this.texas.controls[346].setErrors(null);
      this.texas.controls[945].setErrors(null);
      this.texas.controls[349].setErrors(null);
      this.texas.controls[350].setErrors(null);
      this.texas.controls[351].setErrors(null);
      this.texas.controls[1242].setErrors(null);
      this.texas.controls[1244].setErrors(null);
      this.texas.controls[1245].setErrors(null);
      this.texas.controls[1246].setErrors(null);
      this.texas.controls[1248].setErrors(null);
      this.texas.controls[1249].setErrors(null);
      this.texas.controls[1256].setErrors(null);
      this.texas.controls[1252].setErrors(null);
      this.texas.controls[1253].setErrors(null);
      this.texas.controls[1264].setErrors(null);
      this.texas.controls[1265].setErrors(null);
    }
  }

  saveForm(type) {
    this.isSubmit = true;
    this.validationsDataAdd()
     console.log(this.texas);
    if (type == 'submit') {
      if (
        this.texas.invalid ||
        (!this.eduapp &&
          (!this.texas.controls['490'].value ||
            !this.texas.controls['491'].value ||
            !this.texas.controls['492'].value ||
            !this.texas.controls['493'].value ||
            !this.texas.controls['495'].value ||
            !this.texas.controls['496'].value ||
            !this.texas.controls['497'].value ||
            !this.texas.controls['498'].value)) ||
        (!this.workapp &&
          (!this.texas.controls['445'].value ||
            !this.texas.controls['454'].value ||
            !this.texas.controls['455'].value ||
            !this.texas.controls['446'].value ||
            !this.texas.controls['447'].value ||
            !this.texas.controls['448'].value ||
            !this.texas.controls['450'].value ||
            !this.texas.controls['451'].value ||
            !this.texas.controls['461'].value ||
            !this.texas.controls['472'].value)) ||
        (!this.hospapp &&
          (!this.texas.controls['418'].value ||
            !this.texas.controls['426'].value ||
            !this.texas.controls['419'].value ||
            !this.texas.controls['420'].value ||
            !this.texas.controls['421'].value ||
            !this.texas.controls['423'].value ||
            !this.texas.controls['424'].value ||
            !this.texas.controls['1305'].value ||
            !this.texas.controls['432'].value ||
            !this.texas.controls['1188'].value)) ||
        (!this.refapp &&
          (!this.texas.controls['359'].value ||
            !this.texas.controls['371'].value ||
            !this.texas.controls['369'].value ||
            !this.texas.controls['360'].value ||
            !this.texas.controls['361'].value ||
            !this.texas.controls['362'].value ||
            !this.texas.controls['364'].value ||
            !this.texas.controls['365'].value)) ||
        (!this.proapp &&
          (!this.texas.controls['345'].value ||
            !this.texas.controls['901'].value ||
            !this.texas.controls['902'].value ||
            !this.texas.controls['903'].value ||
            !this.texas.controls['905'].value ||
            !this.texas.controls['906'].value ||
            !this.texas.controls['1266'].value ||
            !this.texas.controls['346'].value ||
            !this.texas.controls['945'].value ||
            !this.texas.controls['349'].value ||
            !this.texas.controls['350'].value ||
            !this.texas.controls['351'].value ||
            !this.texas.controls['1242'].value ||
            !this.texas.controls['1244'].value ||
            !this.texas.controls['1245'].value ||
            !this.texas.controls['1246'].value ||
            !this.texas.controls['1248'].value ||
            !this.texas.controls['1249'].value ||
            !this.texas.controls['1256'].value ||
            !this.texas.controls['1252'].value ||
            !this.texas.controls['1253'].value ||
            !this.texas.controls['1264'].value ||
            !this.texas.controls['1265'].value)) ||
        (!this.prospeapp &&
          (!this.texas.controls['556'].value ||
            !this.texas.controls['557'].value ||
            !this.texas.controls['cmd_024'].value)) ||
        (this.changeCmdTwo &&
          this.texas.controls['1144'].value == null &&
          this.texas.controls['1145'].value == null) ||
        (this.changeCmdOne && this.texas.controls['514'].value == '') ||
        (this.changeCmdThree && this.texas.controls['554'].value == '')
      ) {
        if (!this.eduapp && !this.texas.controls['490'].value) {
          this.texas.controls['490'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['491'].value) {
          this.texas.controls['491'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['492'].value) {
          this.texas.controls['492'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['493'].value) {
          this.texas.controls['493'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['495'].value) {
          this.texas.controls['495'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['496'].value) {
          this.texas.controls['496'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['497'].value) {
          this.texas.controls['497'].setErrors({ errors: true });
        }
        if (!this.eduapp && !this.texas.controls['498'].value) {
          this.texas.controls['498'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['445'].value) {
          this.texas.controls['445'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['454'].value) {
          this.texas.controls['454'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['455'].value) {
          this.texas.controls['455'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['446'].value) {
          this.texas.controls['446'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['447'].value) {
          this.texas.controls['447'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['448'].value) {
          this.texas.controls['448'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['450'].value) {
          this.texas.controls['450'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['451'].value) {
          this.texas.controls['451'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['461'].value) {
          this.texas.controls['461'].setErrors({ errors: true });
        }
        if (!this.workapp && !this.texas.controls['472'].value) {
          this.texas.controls['472'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['418'].value) {
          this.texas.controls['418'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['426'].value) {
          this.texas.controls['426'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['419'].value) {
          this.texas.controls['419'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['420'].value) {
          this.texas.controls['420'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['421'].value) {
          this.texas.controls['421'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['423'].value) {
          this.texas.controls['423'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['424'].value) {
          this.texas.controls['424'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['1305'].value) {
          this.texas.controls['1305'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['432'].value) {
          this.texas.controls['432'].setErrors({ errors: true });
        }
        if (!this.hospapp && !this.texas.controls['1188'].value) {
          this.texas.controls['1188'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['359'].value) {
          this.texas.controls['359'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['371'].value) {
          this.texas.controls['371'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['369'].value) {
          this.texas.controls['369'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['360'].value) {
          this.texas.controls['360'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['361'].value) {
          this.texas.controls['361'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['362'].value) {
          this.texas.controls['362'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['364'].value) {
          this.texas.controls['364'].setErrors({ errors: true });
        }
        if (!this.refapp && !this.texas.controls['365'].value) {
          this.texas.controls['365'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['345'].value) {
          this.texas.controls['345'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['901'].value) {
          this.texas.controls['901'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['902'].value) {
          this.texas.controls['902'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['903'].value) {
          this.texas.controls['903'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['905'].value) {
          this.texas.controls['905'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['906'].value) {
          this.texas.controls['906'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1266'].value) {
          this.texas.controls['1266'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['346'].value) {
          this.texas.controls['346'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['945'].value) {
          this.texas.controls['945'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['349'].value) {
          this.texas.controls['349'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['350'].value) {
          this.texas.controls['350'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['351'].value) {
          this.texas.controls['351'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1242'].value) {
          this.texas.controls['1242'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1244'].value) {
          this.texas.controls['1244'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1245'].value) {
          this.texas.controls['1245'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1246'].value) {
          this.texas.controls['1246'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1248'].value) {
          this.texas.controls['1248'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1249'].value) {
          this.texas.controls['1249'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1256'].value) {
          this.texas.controls['1256'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1252'].value) {
          this.texas.controls['1252'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1253'].value) {
          this.texas.controls['1253'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1264'].value) {
          this.texas.controls['1264'].setErrors({ errors: true });
        }
        if (!this.proapp && !this.texas.controls['1265'].value) {
          this.texas.controls['1265'].setErrors({ errors: true });
        }
        if (!this.prospeapp && !this.texas.controls['556'].value) {
          this.texas.controls['556'].setErrors({ errors: true });
        }
        if (!this.prospeapp && !this.texas.controls['557'].value) {
          this.texas.controls['557'].setErrors({ errors: true });
        }
        if (!this.prospeapp && !this.texas.controls['cmd_024'].value) {
          this.texas.controls['cmd_024'].setErrors({ errors: true });
        }

        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        this.gotoTop();
      } else {
        let data = {
          formType: type,
          formValues: this.texas.value
        };
        this.onSubmit.emit(data);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.texas.value,
        formId: 15
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  close() {
    this.onClose.emit('close modal');
  }

  public getCredentialsData() {
    let params: {};
    ////this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    ////this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSuffixData() {
    let params: {};
    ////this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      }
    );
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_0778: this.finalImgPath
      };
      this.texas.patchValue(temp);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.texas.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
    clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
      const control = this.texas.get(controlName);
      const dateString = control.value;
    
      // Use the dateFormatValidator to check if the date is valid
      const validationErrors = this.dateFormatValidator(new FormControl(dateString));
    
      if (validationErrors) {
        // If any of the validation conditions are met, clear the input field and set errors to null
        control.setValue('');
        control.setErrors(null);
      }
     
      
    }
    public validationsDataAdd(event?) {
      let yesNoQuestionIds = {
        cmd_0232: 'cmd_0255',
        cmd_0233: 'cmd_0257',
        cmd_0234: 'cmd_0259',
        cmd_0235: 'cmd_0261',
        cmd_0236: 'cmd_0263',
        cmd_0237: 'cmd_0265',
        cmd_0238: 'cmd_0267',
        cmd_0239: 'cmd_0269',
        cmd_0240: 'cmd_0271',
        cmd_0241: 'cmd_0273',
        cmd_0242: 'cmd_0275',
        cmd_0243: 'cmd_0277',
        cmd_0244: 'cmd_0279',
        cmd_0245: 'cmd_0281',
        cmd_0246: 'cmd_0283',
        cmd_0247: 'cmd_0285',
        cmd_0248: 'cmd_0287',
        cmd_0249: 'cmd_0289',
        cmd_0250: 'cmd_0291',
        cmd_0251: 'cmd_0293',
        cmd_0252: 'cmd_0295',
        cmd_0253: 'cmd_0297',
        cmd_0254: 'cmd_0299'
      };
      // ID of respective explanation section
      let yesNoQuestionExplanation = [
        'cmd_0255',
        'cmd_0257',
        'cmd_0259',
        'cmd_0261',
        'cmd_0263',
        'cmd_0265',
        'cmd_0267',
        'cmd_0269',
        'cmd_0271',
        'cmd_0273',
        'cmd_0275',
        'cmd_0277',
        'cmd_0279',
        'cmd_0281',
        'cmd_0283',
        'cmd_0285',
        'cmd_0287',
        'cmd_0289',
        'cmd_0291',
        'cmd_0293',
        'cmd_0295',
        'cmd_0297',
        'cmd_0299'
      ];
      this.questionCounter = 0;
      for (const key in yesNoQuestionIds) {
        if (yesNoQuestionIds.hasOwnProperty(key)) {
          // console.log(yesNoQuestionIds[key]);
          const element = this.texas.controls[key].value;
          // console.log(element);
          if (element == 1) {
            this.questionCounter++;
          }
        }
      }
      // console.log(this.questionCounter);
      for (this.indexKey = 0; this.indexKey < 23; this.indexKey++) {
        // console.log(yesNoQuestionExplanation[0]);
        if (this.indexKey < this.questionCounter) {
          // console.log(this.texas.controls[yesNoQuestionExplanation[this.indexKey]])
          if (this.texas.controls[yesNoQuestionExplanation[this.indexKey]].value == '' || this.texas.controls[yesNoQuestionExplanation[this.indexKey]].value == null) {
            // console.log('setting error')
          this.texas.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
            {
              errors: true
            }
          );
          }
        } else {
          // console.log('removing error');
          // console.log('else error');
          this.texas.controls[yesNoQuestionExplanation[this.indexKey]].setErrors(
            null
          );
        }
      }
      // for (const key in this.texas.controls) {
      //   if (this.texas.controls.hasOwnProperty(key)) {
      //     const element = this.texas.controls[key];
      //     if(element.status=="INVALID"){
      //     console.log(key + " " + element.status)
      //     }
      //   }
      // }
    }
}
