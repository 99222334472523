import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
// import { CommonService } from '../../../shared/services/common/common.service'
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomDateParserFormatter, CustomAdapter } from '../../DateFormatter/customDate';

import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { MonthModeComponent } from '../../SharedComponents/month-mode/month-mode.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';




@Component({
    selector: 'ctg-pcs-form',
    templateUrl: './pcs-form.component.html',
    styleUrls: ['./pcs-form.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule, MonthModeComponent]
})
export class PcsFormComponent implements OnInit {

  public PCSForms: UntypedFormGroup;
  isSubmit: boolean = false;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private phonePattern = '^[0-9]*$';

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private onboardService: OnboardingformsServiceService, private notiService: ToasterNotiService,

  ) { }

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };

  ngOnInit(): void {
    console.log(this.formData);
    this.buildForm();
    this.getDegreeData();
    this.getGenderData();
    this.getSpecialtyData();
    this.getRaceData();
    this.getStatesData();
    this.getCdsStatesData();
    this.getLanguagesData();
    this.getDegreeData();
    this.getStaffStatusData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getCredentialsData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
    console.log(this.formData);
    console.log(this.fieldsData);
    console.log(this.formId);
  }
  //drop_down_arrays
  degree: Array<object> = [];
  gender: Array<object> = [];
  specialty: Array<object> = [];
  race: Array<object> = [];
  states: Array<object> = [];
  CdsStates: Array<object> = [];
  staffStatus: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  public preClaim: any = [];
  public aggregates: any = [];
  languages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  credentials: Array<object> = [];


  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  changeCmdTwo: any;
  changeCmdOne: any;
  changeCmdThree: any;



  showOne: any = false;

  firstName: any;
  lastName: any;
  finalName: any;


  public primarySpecialityCol: any[] = [];


  close() {
    this.onClose.emit('close modal');
  }

  onChangeCmdTwo(event) {
    if (event.target.value == 1) {
      this.changeCmdTwo = true;
    } else {
      this.changeCmdTwo = false;
    }
  }

  onChangeCmdOne(event) {
    if (event.target.value == 1) {
      this.changeCmdOne = true;
    } else {
      this.changeCmdOne = false;
    }
  }

  onChangeCmdThree(event) {
    if (event.target.value == 1) {
      this.changeCmdThree = true;
    } else {
      this.changeCmdThree = false;
    }
  }


  public afterSaveDataDisplay() {
    let params = {
      type: 34,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    // console.log(filePath);
    if (fieldId == 'cmd_1143') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
  }
  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.setEsign(data.base64, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_1143') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.PCSForms.patchValue(fieldsCtrls);
    console.log(this.PCSForms)
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_1143: this.finalImgPath
      };
      this.PCSForms.patchValue(temp);
      this.finalImg = data.base64Img;
      console.log(this.finalImg);
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      console.log(this.esignOne);
      this.showOne = true;
    });
  }



  //drop_down_functions

  public getCredentialsData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }


  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getGenderData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getGenderData(params).subscribe(
      data => {
        if (data['success']) {
          this.gender = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }

  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_1142: this.finalName
    };
    this.PCSForms.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_1142: this.finalName
    };
    this.PCSForms.patchValue(temp);
  }


  public getRaceData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getRaceData(params).subscribe(
      data => {
        if (data['success']) {
          this.race = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.CdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }


  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  
  setErrorControl(name){
    this.PCSForms.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.PCSForms.controls[name].setErrors(null)
  }

  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_NA_01'){
      let x=[996,337,338,339]
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_NA_02'){
      let x=[517,518,519,520,522,523,528,524,525,2539,527,'cmd_1149']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_03'){
      let x=[529,540,537,538,2540,530,'cmd_1147']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    
    if(cName=='cmd_NA_04'){
      let x=[542,544,545,546,548,549,553,550,551,2542,543,'cmd_1146']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_05'){
      let x=[418,425,894,426]
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_06'){
      let x=['cmd_01005']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_07'){
      let x=['cmd_1014']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_08'){
      let x=['cmd_01014']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && this.PCSForms.controls[cName].value){
      
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_09'){
      let x=['cmd_1019']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
    if(cName=='cmd_NA_10'){
      let x=['cmd_1020']
      x.forEach(element => {
        if((this.PCSForms.controls[element].value=='' || this.PCSForms.controls[element].value==null) && !this.PCSForms.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }  
  }  

  saveForm(type) {
    this.isSubmit = true;
    this.handleNAchange('cmd_NA_01');
    this.handleNAchange('cmd_NA_02');
    this.handleNAchange('cmd_NA_03');
    this.handleNAchange('cmd_NA_04');
    this.handleNAchange('cmd_NA_05');
    this.handleNAchange('cmd_NA_06');
    this.handleNAchange('cmd_NA_07');
    this.handleNAchange('cmd_NA_08');
    this.handleNAchange('cmd_NA_09');
    this.handleNAchange('cmd_NA_10');
    

    console.log(this.PCSForms);
    if (type == 'submit') {
      if (this.PCSForms.invalid ||
        (this.changeCmdTwo &&
        this.PCSForms.controls['cmd_01017'].value == '')||
        (this.changeCmdOne &&
          this.PCSForms.controls['cmd_01018'].value == '')||
          (this.changeCmdThree &&
            this.PCSForms.controls['cmd_01068'].value == '')) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.PCSForms.controls) {
      if (this.PCSForms.controls.hasOwnProperty(key)) {
        const element = this.PCSForms.controls[key];
        if(element.status=="INVALID"){
        console.log(key + " " + element.status)
        }
      }
    }
      } else {
        let data = {
          formType: type,
          formValues: this.PCSForms.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.PCSForms);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.PCSForms.value,
        formId: 34
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }





  buildForm() {
    this.PCSForms = this.fb.group({
      8: ['', [Validators.required]],
      30: [null, [Validators.required]],
      9: ['', [Validators.required]],
      822: [null, [Validators.required]],
      31: ['', [Validators.required,this.dateFormatValidator]],
      32: [null, [Validators.required]],
      709: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      759: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      16: ['', [Validators.required]],
      17: ['', [Validators.required]],
      18: [, [Validators.required]],
      20: [null, [Validators.required]],

      1535: [null],
      1536: [null],
      2207: [null],



      21: ['', [Validators.required]],
      810: ['', [Validators.required]],
      289: [null, [Validators.required]],
      291: ['', [Validators.required]],
      292: [''],
      277: [null, [Validators.required]],
      333: ['', [Validators.required]],
      334: ['', [Validators.required]],
      335: ['', [Validators.required]],
      2108: ['', [Validators.required]],
      2109: ['', [Validators.required]],
      741: [null],


      //Npi
      1634: ['', [Validators.required]],
      1635: ['', [Validators.required]],
      1636: ['', [Validators.required]],

      //medical licence
      293: [null, [Validators.required]],
      299: [null, [Validators.required]],
      294: ['', [Validators.required,this.dateFormatValidator]],
      295: ['', [Validators.required,this.dateFormatValidator]],

      //DEA
      309: ['', [Validators.required]],
      310: ['', [Validators.required,this.dateFormatValidator]],

      //CDS
      996: [null],
      337: [''],
      338: [null,[this.dateFormatValidator]],
      339: [null,[this.dateFormatValidator]],


      //peer refernces 1
      359: ['', [Validators.required]],
      360: ['', [Validators.required]],
      361: ['', [Validators.required]],
      362: ['', [Validators.required]],
      365: ['', [Validators.required]],
      368: [null, [Validators.required]],
      364: [null, [Validators.required]],

      // 284: [null, [Validators.required]],
      369: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      371: ['', [Validators.pattern(this.emailPattern)]],


      //peer refernces 2
      372: ['', [Validators.required]],
      373: ['', [Validators.required]],
      374: ['', [Validators.required]],
      375: ['', [Validators.required]],
      377: [null, [Validators.required]],

      378: ['', [Validators.required]],
      380: [null, [Validators.required]],
      381: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],

      383: ['', [Validators.pattern(this.emailPattern)]],

      //peer refernces 3
      384: ['', [Validators.required]],
      385: ['', [Validators.required]],
      386: ['', [Validators.required]],
      387: ['', [Validators.required]],
      389: [null, [Validators.required]],
      390: ['', [Validators.required]],
      393: [null, [Validators.required]],
      394: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      396: ['', [Validators.pattern(this.emailPattern)]],

      //medical_education
      479: [''],
      480: [''],
      482: [''],
      483: [''],

      //Education-Undergraduate
      503: ['', [Validators.required]],
      504: ['', [Validators.required]],
      505: ['', [Validators.required]],
      506: ['', [Validators.required]],
      508: [null, [Validators.required]],
      509: ['', [Validators.required]],
      512: [null, [Validators.required]],
      511: [null, [Validators.required,this.dateFormatValidator]],

      //Education-Medical/Professional
      490: ['', [Validators.required]],
      491: ['', [Validators.required]],
      492: ['', [Validators.required]],
      493: ['', [Validators.required]],
      495: [null, [Validators.required]],
      496: ['', [Validators.required]],
      500: [null, [Validators.required]],
      499: [null, [Validators.required,this.dateFormatValidator]],

      //Education-Internship
      517: [''],
      518: [''],
      519: [''],
      520: [''],
      522: [null],
      523: [''],
      528: [''],
      524: [null,[this.dateFormatValidator]],
      525: [null,[this.dateFormatValidator]],
      2539: [''],
      527: [null],

      //Education-Residency
      529: [''],
      531: [''],
      1498: [''],
      533: [''],
      535: [null],
      536: [''],
      540: [''],
      537: [null,[this.dateFormatValidatorMonth]],
      538: [null,[this.dateFormatValidatorMonth]],
      2540: [''],
      530: [null],

      //Education-Fellowship
      542: [''],
      544: [''],
      545: [''],
      546: [''],
      548: [null],
      549: [''],
      553: [''],
      550: [null,[this.dateFormatValidator]],
      551: [null,[this.dateFormatValidator]],
      2542: [''],
      543: [null],

      //hospital-affliations
      418: [''],
      419: [''],
      420: [''],
      421: [''],//For city
       423: [null], //state
       424: [''], //Zip
      426: [null,[this.dateFormatValidator]], //appointment date


      894: [null],
      430: [''],
      432: [null],
      433: [''],
      441: [null],
      442: [''],




      427: [''],
      425: [''],
      428: [''],
      429: [''],
      434: [''],
      435: [null,[this.dateFormatValidator]],

      895: [null],

      436: [''],
      437: [''],
      438: [''],
      439: [''],
      443: [''],
      896: [null],
      444: [null,[this.dateFormatValidator]],


      //professional liability
      345: ['', [Validators.required]],
      346: ['', [Validators.required]],
      348: ['', [Validators.required,this.dateFormatValidator]],
      901: ['', [Validators.required]],
      902: ['', [Validators.required]],
      350: [null, [Validators.required]],
      903: ['', [Validators.required]],
      905: [null, [Validators.required]],
      906: ['', [Validators.required]],
      351: [null, [Validators.required]],
      1266: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],

      349: ['', [Validators.required,this.dateFormatValidator]],

      //saved_fileds_validation
      "cmd_1001": [''],
      // "cmd_1002": [''],
      "809": [''],
      "cmd_1004": [''],
      "cmd_1005": ['', [Validators.required]],
      "cmd_01005": [''],

      "cmd_1006": ['', [Validators.required]],
      "cmd_1007": [''],
      "cmd_1008": ['', [Validators.required]],
      "cmd_001008": [''],

      "cmd_01008": ['', [Validators.required]],

      "cmd_1009": ['', [Validators.required]],
      "cmd_1010": ['',[Validators.required,Validators.pattern('^[0-9]*$')]],
      "cmd_1038": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_1044": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_1057": ['', [Validators.pattern(this.phonePattern)]],







      "cmd_1072": [''],
      "cmd_1073": [''],
      "cmd_1074": [''],
      "cmd_1075": [''],
      "cmd_1076": [''],
      "cmd_1077": [''],
      // "cmd_1079":  [''],
      "cmd_1080": [''],
      "cmd_1081": [''],
      "cmd_01081": [''],

      "cmd_1082": [''],
      "cmd_1083": [''],
      "cmd_1084": [''],
      "cmd_1085": [''],
      cmd_1086: [null],
      "cmd_1087": [''],
      "cmd_1088": [''],
      "cmd_1089": [''],
      "cmd_01089": [''],

      "cmd_1090": [''],
      // "cmd_1091": [''],
      "cmd_1091": ['', [Validators.pattern(this.phonePattern)]],
      "cmd_1079": ['', [Validators.pattern(this.phonePattern)]],



      "cmd_1092": [''],
      "cmd_1094": [''],
      "cmd_1095": [''],
      "cmd_1096": [''],
      "cmd_1097": [''],
      "cmd_1098": [''],
      "cmd_1099": [''],
      "cmd_01099": [''],

      "cmd_1100": [''],
      "cmd_1101": [''],
      "cmd_1102": [''],
      "cmd_1103": [''],
      "cmd_1104": [''],
      "cmd_1105": [''],
      "cmd_1106": [''],
      "cmd_01106": [''],

      "cmd_1107": [''],
      "cmd_1108": [''],
      "cmd_1109": [''],
      "cmd_1110": [''],

      "cmd_1078": ['',[Validators.pattern(this.emailPattern)]],
      "cmd_1093": ['',[Validators.pattern(this.emailPattern)]],

      "cmd_1011": [''],
      "cmd_1012": [''],
      "cmd_1013": [null ,[this.dateFormatValidator]],
      "cmd_1014": [''],
      "cmd_01014": [''],

      "cmd_1015": [''],
      "cmd_1016": [null, [Validators.required]],
      "cmd_1017": [null, [Validators.required]],
      "cmd_1018": [null, [Validators.required]],
      cmd_1019: [''],
      cmd_1020: [''],

      "cmd_01017": [''],
      "cmd_01018": [''],



      "cmd_1021": [''],
      "cmd_1022": [''],
      "cmd_1023": [''],
      "cmd_1024": [''],
      "cmd_1025": [''],
      "cmd_1026": [''],
      "cmd_1027": [''],
      "cmd_1028": [''],
      "cmd_1029": [''],
      "cmd_1030": [''],
      "cmd_1031": [''],
      "cmd_1032": [''],
      "cmd_1033": [''],
      "cmd_1034": [''],
      "cmd_1035": [''],
      "cmd_1036": [''],
      "cmd_01036": [''],

      "cmd_1037": [''],
      "cmd_1039": [''],
      "cmd_1040": [''],
      "cmd_1041": [''],
      "cmd_1042": [''],
      "cmd_01042": [''],

      "cmd_1043": [''],
      "cmd_1045": [''],
      "cmd_1048": [''],
      "cmd_01048": [''],







      "cmd_1065": ['',[Validators.required, Validators.pattern('^[0-9]*$')]],

      // "cmd_1066": [''],

      "cmd_1067": [''],
      "cmd_pcs_1068":[''],
      "cmd_pcs_1069":[''],
      "cmd_pcs_1070":[''],
      "cmd_pcs_1071":[''],
      "cmd_pcs_1072":[''],
      "cmd_pcs_1073":[''],







      //Radio buttond ids

      "cmd_1049": [''],
      "cmd_1047": [''],
      "cmd_1046": [''],
      // "cmd_01046": [''],

      "cmd_1050": [''],
      "cmd_1051": [''],
      "cmd_1052": [''],
      "cmd_1053": [''],
      "cmd_1054": [''],
      // "cmd_01054": [''],
      "cmd_001054": [''],
      "cmd_01061":[''],
      "cmd_1055": [''],
      "cmd_01055": [''],
      "cmd_1056": [''],
      "cmd_1061": [''],
      "cmd_1062": [''],
      cmd_1063: [null,[this.dateFormatValidator]],
      "cmd_1064": [''],
      "cmd_1068": [null, [Validators.required]],
      "cmd_01068": [''],
      "cmd_1069": [''],
      cmd_1070: [null,[this.dateFormatValidator]],
      "cmd_1071": [''],
      "cmd_1111": [null, [Validators.required]],
      "cmd_1112": [null, [Validators.required]],
      "cmd_1113": [null, [Validators.required]],
      "cmd_1114": [null, [Validators.required]],
      "cmd_1115": [null, [Validators.required]],
      "cmd_1116": [null, [Validators.required]],
      "cmd_1117": [null, [Validators.required]],
      "cmd_1118": [null, [Validators.required]],
      "cmd_1119": [null, [Validators.required]],
      "cmd_1120": [null, [Validators.required]],
      "cmd_1121": [null, [Validators.required]],
      // "cmd_1122": [null, [Validators.required]],
      "cmd_1123": [null, [Validators.required]],
      "cmd_1124": [null, [Validators.required]],
      "cmd_1125": [null, [Validators.required]],

      "cmd_1126": [null, [Validators.required]],
      "cmd_1127": [null, [Validators.required]],

      "cmd_1128": [null, [Validators.required]],
      "cmd_1129": [null, [Validators.required]],
      "cmd_1130": [null, [Validators.required]],
      "cmd_1131": [null, [Validators.required]],
     
      
     
      
      
      
      
      "cmd_1139":[''],
      
      

      "cmd_1149": [null],
      "cmd_1147": [null],
      "cmd_1146": [null],

      "cmd_1142": [null, [Validators.required]],
      "cmd_1143": ['', [Validators.required]],
      "cmd_1144": [null, [Validators.required,this.dateFormatValidator]],
      "cmd_1150": [null, [Validators.required]],
      "cmd_1151": ['', [Validators.required]],
      "cmd_1152": [null],
      "cmd_1153": [''],
      "cmd_1154": [null],
      "cmd_1155": [''],
      "cmd_1156": [null],
      "cmd_1157": [''],
      "cmd_1158": [null],
      "cmd_1159": [''],
      "cmd_1160": [null],

      "cmd_1161": [''],
      "cmd_1162": [null],

      "cmd_1163": [''],

      "cmd_1164": [null],

      "cmd_1165": [''],

      "cmd_1166": [null],

      "cmd_1167": [''],

      "cmd_1168": [null],

      "cmd_1169": [''],
      "cmd_1170": [''],
      "cmd_1171": [''],
      "cmd_1172": [''],
      "cmd_1173": [''],
      "cmd_01170":[''],
      "cmd_1174": [''],
      "cmd_1175": [''],
      "cmd_1176": [''],

      "cmd_1177": [''],
      "cmd_1178": [''],
      "cmd_1179": [''],
      "cmd_1180": [''],
      "cmd_1181": [''],
      "cmd_1182": [''],
      "cmd_1183": [''],
      

      "cmd_1185": [''],
      "cmd_1186": [''],
      "cmd_1187": [''],
      "cmd_1188": [''],
      "cmd_1189": [''],
      "cmd_1190": [''],
      "cmd_1191": [''],
      "cmd_1192": [''],

      
      "cmd_1194": [''],
      "cmd_1195": [''],
      "cmd_1196": [''],
      "cmd_1197": [''],
      "cmd_1198": [''],
      "cmd_1199": [''],
      "cmd_1200": [''],
      "cmd_1201": [''],
      cmd_NA_02:[null],
      cmd_NA_01:[null],
      cmd_NA_03:[null],
      cmd_NA_04:[null],
      cmd_NA_05:[null],
      cmd_NA_06:[null],
      cmd_NA_07:[null],
      cmd_NA_08:[null],
      "cmd_1203": [''],
      cmd_NA_09:[null],
      cmd_NA_10:[null],
















    });
    console.log(this.PCSForms)
  }

  returnval(event){
console.log(event)
this.PCSForms.controls['537'].setValue(event)
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.PCSForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      
          this.handleNAchange(handleYesNoValue);
      
      
      
    }
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.PCSForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  dateFormatValidatorMonth(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const year = parseInt(dateParts[1]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
                
      
      if (month < 1 || month > 12 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  clearInputIfInvalidMonth(controlName: string,handleYesNoValue?:string) {
    const control = this.PCSForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidatorMonth(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

}
