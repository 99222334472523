<div [ngClass]="toggledStatus ? '' : 'toggled'" class="sidebar navbar-nav ">
  <div id="main-wrapper" [ngClass]="toggledStatus ? '' : 'mini-sidebar'">
    <aside class="left-sidebar" >
      <!-- Sidebar scroll-->
      <!-- Content -->
      <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
          <ul id="sidebarnav" >
            <li *ngFor="let item of menuItem" class="sidebar-item">
              <a *ngIf="!item.modules  && item.type_id.includes(typeId) && item.isAccess" class="sidebar-link waves-effect waves-dark sidebar-link"
                [routerLinkActive]="['active']" [routerLink]="item.url" aria-expanded="false">
                <i class="{{item.class}}" ></i>
                <span class="hide-menu" >{{item.module_name}}</span>
              </a>
              <a *ngIf="item.modules  && item.type_id.includes(typeId) && item.isAccess"
                class="sidebar-link has-arrow waves-effect waves-dark sidebar-link"
                data-bs-toggle="collapse" href="#{{item.module_id}}" aria-expanded="false">
                <i class="{{item.class}}"></i>
                <span class="hide-menu">{{item.module_name}}</span>
              </a>
              <ul *ngIf="item.modules && item.type_id.includes(typeId) && item.isAccess"
                id="{{item.module_id}}" aria-expanded="false" class="collapse first-level">
                <li *ngFor="let sub of item.modules" class="sidebar-item">
                  <a class="sidebar-link"
                    *ngIf="sub && (sub.module_name == 'Provider Roster Report'
                    || sub.module_name == 'Location Roster Report'
                    || sub.module_name == 'TIN Roster Report' || sub.module_name == 'Create A Task' || sub.module_name == 'Create A Note') && sub.type_id.includes(typeId) && sub.isAccess"
                    (click)="handleSubRoutes(sub.module_name)">
                    <i class="{{sub.class}}"></i>
                    <span class="hide-menu">{{sub.module_name}}</span>
                  </a>
                  <a class="sidebar-link"
                    [routerLinkActive]="['active']"
                    *ngIf="sub && !(sub.module_name == 'Provider Roster Report'
                    || sub.module_name == 'Location Roster Report'
                    || sub.module_name == 'TIN Roster Report' || sub.module_name == 'Create A Task' || sub.module_name == 'Create A Note') && sub.type_id.includes(typeId) && sub.isAccess"
                    [routerLink]="sub.url">
                    <i class="{{sub.class}}"></i>
                    <span class="hide-menu">{{sub.module_name}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <!-- End Sidebar navigation -->
      </div>
      <!-- End Sidebar scroll-->
    </aside>
  </div>
</div>
