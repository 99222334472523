import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-anne-dermatology',
    templateUrl: './anne-dermatology.component.html',
    styleUrls: ['./anne-dermatology.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class AnneDermatologyComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}

  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public annederm: UntypedFormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  states: Array<object> = [];
  staffStatus: Array<object> = [];
  degree: Array<object> = [];
  altLicenseState: Array<object> = [];
  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  languages: Array<object> = [];
  secondaryLanguages: Array<object> = [];
  tertiaryLanguages: Array<object> = [];
  quaternaryLanguages: Array<object> = [];
  finalImgPath: any = [];
  finalImg: any;
  finalImgPathTwo: any = [];
  finalImgTwo: any = '';
  finalImgPathThree: any = [];
  finalImgThree: any = '';
  finalImgPathFour: any = [];
  finalImgFour: any = '';
  esignOne: any;
  esignTwo: any;
  esignThree: any;
  esignFour: any;
  showOne: any = false;
  showTwo: any = false;
  showThree: any = false;
  showFour: any = false;
  public userId: any;
  public customerId: any;
  isSubmit: boolean = false;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  firstName: any;
  lastName: any;
  finalName: any;

  ngOnInit(): void {
    this.buildForm();
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.getStatesData();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getStaffStatusData();
    this.getSpecialtySecondaryData();
    this.getLanguagesData();
    this.getsecondaryLanguagesData();
    this.gettertiaryLanguagesData();
    this.getquaternaryLanguagesData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getAlternateLicenseStateData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_10006801') {
        // console.log(array[i].field_name);
        // console.log(array[i].field_value);
        if (array[i].field_value != '') {
          // console.log(array[i].field_value);
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_100068') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_100070') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_100072') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.annederm.patchValue(fieldsCtrls);
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_10006801') {
      // console.log(filePath);
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_100068') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
    if (fieldId == 'cmd_100070') {
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showThree = true;
    }
    if (fieldId == 'cmd_100072') {
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showFour = true;
    }
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 22,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }

  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  buildForm() {
    this.annederm = this.fb.group({
      cmd_10006801: [''],
      cmd_100068: [''],
      cmd_100070: [''],
      cmd_100072: [''],
      8: ['', [Validators.required]],
      9: ['', [Validators.required]],
      20: [null],
      289: [null],
      284: [null],
      962: [null],
      299: [null],
      907: [null],
      1010: [null],
      1197: [null],
      508: [null],
      487: [null],
      495: [null],
      522: [null],
      528: [''],
      424: [''],
      458: [''],
      cmd_100020: [''],
      1218: [''],
      cmd_100058: [''],
      527: [null],
      535: [null],
      530: [null],
      548: [null],
      543: [null],
      277: [null],
      1535: [null],
      1536: [null],
      364: [null],
      377: [null],
      389: [null],
      423: [null],
      432: [null],
      450: [null],
      461: [null],
      472: [null],
      1940: [null],
      1959: [null],
      cmd_01100164:[null],
      905: [null],
      1210: [''],
      cmd_100001: [''],
      709: ['', [Validators.required]],
      759: ['', [Validators.required]],
      cmd_100000010: [''],
      cmd_100000011: [''],
      cmd_100000012: [''],
      16: ['', [Validators.required]],
      17: [''],
      18: ['', [Validators.required]],
      31: [null, [Validators.required,this.dateFormatValidator]],
      32: ['', [Validators.required]],
      810: ['', [Validators.required]],
      1680: [''],
      278: [''],
      1634: ['', [Validators.required]],
      1635: [''],
      1636: [''],
      333: ['', [Validators.required]],
      334: [''],
      335: [''],
      812: [''],
      cmd_100002: [''],
      2111: [''],
      cmd_100003: [''],
      557: [null , [this.dateFormatValidator] ],
      1169: [''],
      cmd_100004: [''],
      560: [null, [this.dateFormatValidator] ],
      cmd_100005: [''],
      562: [''],
      564: [null, [this.dateFormatValidator] ],
      293: [''],
      294: [null, [this.dateFormatValidator] ],
      295: [null,[this.dateFormatValidator]],
      300: [''],
      307: [null, [this.dateFormatValidator]],
      308: [null, [this.dateFormatValidator]],
      999: [''],
      1003: [null, [this.dateFormatValidator]],
      1004: [null, [this.dateFormatValidator]],
      1196: [''],
      1198: [null, [this.dateFormatValidator]],
      1199: [null, [this.dateFormatValidator]],
      cmd_100006: [''],
      309: [''],
      311: [null, [this.dateFormatValidator]],
      337: [''],
      339: [null, [this.dateFormatValidator]],
      cmd_100007: [''],
      cmd_100008: [''],
      503: [''],
      504: [''],
      505: [''],
      506: [''],
      509: [''],
      2345: [''],
      510: [null, [this.dateFormatValidator]],
      1238: [null, [this.dateFormatValidator]],
      512: [null],
      479: [''],
      483: [''],
      488: [''],
      484: [''],
      485: [''],
      2348: [''],
      481: [null, [this.dateFormatValidator]],
      1300: [null, [this.dateFormatValidator]],
      480: [null],
      490: [''],
      491: [''],
      492: [''],
      493: [''],
      496: [''],
      2351: [''],
      497: [null, [this.dateFormatValidator]],
      498: [null, [this.dateFormatValidator]],
      500: [null],
      517: [''],
      518: [''],
      519: [''],
      520: [''],
      523: [''],
      1318: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      529: [''],
      531: [''],
      1498: [''],
      533: [''],
      536: [''],
      1316: [''],
      540: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      542: [''],
      544: [''],
      545: [''],
      546: [''],
      549: [''],
      1322: [''],
      553: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      359: ['', [Validators.required]],
      2722: ['', [Validators.required]],
      371: ['', [Validators.required]],
      360: [''],
      361: [''],
      362: [''],
      365: [''],
      369: [''],
      372: ['', [Validators.required]],
      2723: ['', [Validators.required]],
      383: ['', [Validators.required]],
      373: [''],
      374: [''],
      375: [''],
      378: [''],
      381: [''],
      384: ['', [Validators.required]],
      2725: ['', [Validators.required]],
      396: ['', [Validators.required]],
      385: [''],
      386: [''],
      387: [''],
      390: [''],
      394: [''],
      cmd_100009: ['', [Validators.required]],
      1141: [''],
      418: [''],
      419: [''],
      420: [''],
      421: [''],
      894: [null],
      427: [''],
      428: [''],
      429: [''],
      430: [''],
      433: [''],
      895: [null],
      445: [''],
      446: [''],
      447: [''],
      448: [''],
      453: [''],
      888: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      451: [''],
      456: [''],
      457: [''],
      459: [''],
      462: [''],
      464: [''],
      890: [''],
      465: [null, [this.dateFormatValidator]],
      466: [null, [this.dateFormatValidator]],
      3397: [null, [this.dateFormatValidator]],
      3398: [null, [this.dateFormatValidator]],
      cmd_100010: [''],
      // cmd_100011: [''],
      // cmd_100011_new: [''],
      467: [''],
      468: [''],
      469: [''],
      470: [''],
      473: [''],
      475: [''],
      892: [''],
      476: [null, [this.dateFormatValidator]],
      477: [null, [this.dateFormatValidator]],
      cmd_100013: [''],
      cmd_100014: [''],
      cmd_100015: [''],
      1935: [''],
      1936: [''],
      1937: [''],
      1938: [''],
      1941: [''],
      1944: [''],
      1945: [''],
      1947: [null, [this.dateFormatValidator]],
      1948: [null, [this.dateFormatValidator]],
      cmd_100016: [''],
      cmd_100017: [''],
      cmd_100018: [''],
      cmd_001100164:[''],
      1954: [''],
      1955: [''],
      1956: [''],
      1957: [''],
      1960: [''],
      1963: [''],
      1964: [''],
      1966: [null, [this.dateFormatValidator]],
      1967: [null, [this.dateFormatValidator]],
      cmd_100019: [''],
      cmd_100021: [''],
      cmd_1100161: [''],
      cmd_1100162: [''],
      cmd_1100163: [''],
      cmd_1100164: [''],
      cmd_1100165: [''],
      cmd_110016123: [''],
      cmd_1100167: [null, [this.dateFormatValidator]],
      cmd_1100168: [null, [this.dateFormatValidator]],
      cmd_1100169: [''],
      cmd_11001610: [''],
      cmd_11001611: [''],
      1138: [''],
      3395: [null, [this.dateFormatValidator]],
      3396: [null, [this.dateFormatValidator]],
      1831: [''],
      1832: [''],
      3399: [null, [this.dateFormatValidator]],
      3400: [null, [this.dateFormatValidator]],
      345: [''],
      901: [''],
      902: [''],
      903: [''],
      906: [''],
      350: [null],
      351: [null],
      346: [''],
      349: [null, [this.dateFormatValidator]],
      cmd_000022: [''],
      1242: [''],
      1264: [null],
      1265: [null],
      1256: [''],
      1253: [null, [this.dateFormatValidator]],
      cmd_100023: ['', [Validators.required]],
      cmd_100024: ['', [Validators.required]],
      cmd_100025: ['', [Validators.required]],
      cmd_100026: ['', [Validators.required]],
      cmd_100027: ['', [Validators.required]],
      cmd_100028: ['', [Validators.required]],
      cmd_100029: ['', [Validators.required]],
      cmd_100030: ['', [Validators.required]],
      cmd_100031: ['', [Validators.required]],
      cmd_100032: ['', [Validators.required]],
      cmd_100033: ['', [Validators.required]],
      cmd_100034: ['', [Validators.required]],
      cmd_100035: ['', [Validators.required]],
      cmd_100036: ['', [Validators.required]],
      cmd_100037: ['', [Validators.required]],
      cmd_100038: ['', [Validators.required]],
      cmd_100039: ['', [Validators.required]],
      cmd_100040: ['', [Validators.required]],
      cmd_100041: ['', [Validators.required]],
      cmd_100042: ['', [Validators.required]],
      cmd_100043: ['', [Validators.required]],
      cmd_100044: ['', [Validators.required]],
      cmd_100045: ['', [Validators.required]],
      cmd_100046: ['', [Validators.required]],
      cmd_100047: ['', [Validators.required]],
      cmd_100048: ['', [Validators.required]],
      1209: [null, [this.dateFormatValidator]],
      1208: [null, [this.dateFormatValidator]],
      1909: [''],
      cmd_100049: [''],
      cmd_100050: [''],
      1858: [''],
      cmd_100051: [''],
      1226: [null, [this.dateFormatValidator]],
      cmd_100052: [''],
      cmd_100053: [''],
      1220: [null, [this.dateFormatValidator]],
      1222: [null, [this.dateFormatValidator]],
      cmd_100055: [''],
      cmd_100054_new: [''],
      cmd_100057: [null, [this.dateFormatValidator]],
      cmd_100000102: [''],
      cmd_100000103: [''],
      cmd_100059123: [''],
      cmd_100060: [''],
      cmd_100061: [''],
      cmd_100062: [''],
      cmd_100063: [''],
      cmd_100064: [''],
      cmd_100065: [''],
      cmd_100066: [''],
      cmd_100067: [''],
      cmd_100069: [null, [this.dateFormatValidator]],

      cmd_100000104: [''],
      cmd_100071: [null, [this.dateFormatValidator]],
      cmd_100000105: [''],
      cmd_100073: [null, [this.dateFormatValidator]]
    });
  }

  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.languages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getsecondaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getsecondaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.secondaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public gettertiaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.gettertiaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.tertiaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getquaternaryLanguagesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getquaternaryLanguagesData(params).subscribe(
      data => {
        if (data['success']) {
          this.quaternaryLanguages = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.annederm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.annederm.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}
  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

  saveForm(type) {
    this.isSubmit = true;
    console.log(this.annederm);
    if (type == 'submit') {
      
      if (this.annederm.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
      } else {
        let data = {
          formType: type,
          formValues: this.annederm.value
        };
        this.onSubmit.emit(data);
        console.log(this.annederm);
      }
    } else {
      let data = {
        formType: type,
        formValues: this.annederm.value,
        formId: 22
      };
      this.onSubmit.emit(data);
      //this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      this.finalImgPath = data.finalFilePath;
      modalRef.close();
      let temp = {
        cmd_10006801: this.finalImgPath
      };
      this.annederm.patchValue(temp);
      this.finalImgPath = data.finalFilePath;
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }

  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathTwo = data.finalFilePath;
      let temp = {
        cmd_100068: this.finalImgPathTwo
      };
      this.annederm.patchValue(temp);
      this.finalImgPathTwo = data.finalFilePath;
      this.finalImgTwo = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgTwo
      );
      this.showTwo = true;
    });
  }

  public esignOpenThree() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathThree = data.finalFilePath;
      let temp = {
        cmd_100070: this.finalImgPathThree
      };
      this.annederm.patchValue(temp);
      this.finalImgPathThree = data.finalFilePath;
      this.finalImgThree = data.base64Img;
      this.esignThree = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgThree
      );
      this.showThree = true;
    });
  }

  public esignOpenFour() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => {});
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPathFour = data.finalFilePath;
      let temp = {
        cmd_100072: this.finalImgPathFour
      };
      this.annederm.patchValue(temp);
      this.finalImgPathFour = data.finalFilePath;
      this.finalImgFour = data.base64Img;
      this.esignFour = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImgFour
      );
      this.showFour = true;
    });
  }

  onChangeFirstName(event) {
    //cmd_11234
    this.firstName = event.target.value;
    let temp = {
      cmd_100000105: this.firstName
    };
    let temp1 = {
      cmd_100000104: this.firstName
    };

    let temp2 = {
      cmd_100000103: this.firstName
    };

    let temp3 = {
      cmd_100000102: this.firstName
    };

    this.annederm.patchValue(temp);
    this.annederm.patchValue(temp1);
    this.annederm.patchValue(temp2);
    this.annederm.patchValue(temp3);
  }

  onChangeLastName(event) {
    //cmd_11235
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;

    let temp = {
      cmd_100000105: this.finalName
    };
    let temp1 = {
      cmd_100000104: this.finalName
    };
    let temp2 = {
      cmd_100000103: this.finalName
    };

    let temp3 = {
      cmd_100000102: this.finalName
    };

    this.annederm.patchValue(temp);
    this.annederm.patchValue(temp1);
    this.annederm.patchValue(temp2);
    this.annederm.patchValue(temp3);
  }
}
