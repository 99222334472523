<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
    <h4 class="modal-title">{{popupType.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('cross-click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{popupType.message}}</p>
</div>
<div class="modal-footer">
    <div type="button" class="btn customOrange float-end" (click)="activeModal.close('no')">No</div>
    <div type="button" class="btn customOrange float-end" (click)="onSave('yes')">Yes</div>
</div>
