import { FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';


@Component({
    selector: 'ctg-comprashve_care',
    templateUrl: './comprashve_care.component.html',
    styleUrls: ['./comprashve_care.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})
export class ComprashveCareComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  firstName: any;
  finalName: any;
  lastName: any;
  middleName: any;
  constructor(
    private fb: UntypedFormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ) {}
  public userId: any;
  public customerId: any;
  public CSCcare: UntypedFormGroup;
  isSubmit: boolean = false;
  private phonePattern = '^[0-9]*$';

  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  
  

  ngOnInit(): void {
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.bulidcsccareForm();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.getSuffixData();
    this.getStatesData();
    this.getCountyData();
    this.getAltCdsStatesData();
    this.getDegreeData();
    this.getStaffStatusData();
    this.getBoardCertificationData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.getSpecialtyData();
    this.getSpecialtySecondaryData();
    this.getTerSpecialtyData();
    this.afterSaveDataDisplay();
  }

  public doits: any;
    finalImgPath: any = [];
    finalImg: any;
    esignOne: any;
    showOne: any = false;
    esignTwo: any;
    showTwo: any = false;
    suffix: Array<object> = [];
    states: Array<object> = [];
    public country: any = [];
    public stateDropDownDate: any = [];
    AltCdsStates: Array<object> = [];
    degree: Array<object> = [];
    staffStatus: Array<object> = [];
    boardCertification: Array<object> = [];
    public preClaim: any = [];
    public aggregates: any = [];
    specialty: Array<object> = [];
    specialtySecondary: Array<object> = [];
    specialtyTer: Array<object> = [];






  public afterSaveDataDisplay() {
    let params = {
      type: 43,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        // this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }
  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getTerSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getTerSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtyTer = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getStaffStatusData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStaffStatusData(params).subscribe(
      data => {
        if (data['success']) {
          this.staffStatus = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  bulidcsccareForm() {
    this.CSCcare = this.fb.group({

      8:['', [Validators.required]],
       809:['', [Validators.required]],
      //  9:[''],
       9:['',[Validators.required]],
       32:['',[Validators.required]],
       29:[null, [Validators.required]],
       cmd_001:[''],
       cmd_002:[''],
       cmd_003:[''],
       cmd_004:[''],
       cmd_005:[''],
       cmd_006:[''],
       cmd_007:[''],
       cmd_008:[''],
       cmd_009:[''],
       cmd_0010:[''],
       cmd_0011:[null, [this.dateFormatValidator]],
       cmd_0012:[null, [this.dateFormatValidator]],
       278:[''],
       280:[null, [this.dateFormatValidator]],
       281:[null, [this.dateFormatValidator]],
       289:[null,[Validators.required]],
       1664:['', [Validators.required, Validators.pattern(this.emailPattern)]],
       16:['',[Validators.required]],
       17:['',[Validators.required]],
       709: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
       18: ['', [Validators.required]],
       20: [null, [Validators.required]],
       810:['',[Validators.required]],
       21: ['',[Validators.required, Validators.minLength(5), Validators.maxLength(10)]
      ],
      31: [null, [Validators.required ,this.dateFormatValidator]],
      292: ['',[Validators.required]],
      1634: ['', Validators.required],
      812: ['', Validators.required],
      325: ['', Validators.required],
      2953:[''],
      2962:['', [Validators.pattern(this.emailPattern)]],
      2960:['', [Validators.pattern(this.phonePattern)]],
      294: [null,[Validators.required,this.dateFormatValidator]],
      295: [null,[Validators.required,this.dateFormatValidator]],
      337: ['',[Validators.required]],
      339: [null, [Validators.required,this.dateFormatValidator]],
      311: [null, [Validators.required,this.dateFormatValidator]],
      309: ['', [Validators.required]],
      300: [''],
      307: [null, [this.dateFormatValidator]],
      //Hospital
      418: [''],
      426: [null, [this.dateFormatValidator]],
      2007: [null, [this.dateFormatValidator]],
      419: [''],
      420: [''],
      421: [''],
      423: [null],
      424: [''],
      1304: [''],
      894: [null],
      445: [''],
      554: [null],
      1169: [''],
      557: [null, [this.dateFormatValidator]],
      558: [null, [this.dateFormatValidator]],
      359: [null, [Validators.required]],
      2722: [null, [Validators.required]],
      2721: [null, [Validators.required]],
      4242: [null, [Validators.required]],
      371: [null, [Validators.required]],
      372:[''],
      2723:[''],
      2724:[''],
      4243:[''],
      383:[''],
      384:[''],
      2725:[''],
      2726:[''],
      4244:[''],
      396:[''],
      345:[''],
      901:[''],
      902:[''],
      903:[''],
      905:[null],
      906:[null],
      346:[''],
      1240:[''],
     348:[null, [this.dateFormatValidator]],
     349:[null, [this.dateFormatValidator]],
     350: [null],
     351: [null],
     347: [''],
      895:[null],
      896:[null],
      561:[null],
      562:[''],
      564:[null, [this.dateFormatValidator]],
      565:[null, [this.dateFormatValidator]],
      1695:[''],
      1701:[''],
      1707:[''],
      1973:[''],
      469:[''],
      cmd_013:[''],
      cmd_0015:[''],
      cmd_0016:[''],
      cmd_0017:[null],
      cmd_0018:[null],
      cmd_0019:['',[Validators.required]],
      cmd_0020:[''],
      cmd_0021:[''],
      cmd_0022:[null],
      cmd_0023:[''],
      cmd_0024:[''],
      cmd_0025:[null],
      cmd_0026:[''],
      cmd_0027:[''],
      cmd_0028:[''],
      cmd_0029:[''],
      cmd_0030:[''],
      cmd_0031:[''],
      cmd_0032:[null],
      cmd_0033:[null, [this.dateFormatValidator]],
      cmd_0034:[''],
      cmd_0035:[null, [this.dateFormatValidator]],
      cmd_0036:[''],
      cmd_0037:[null, [this.dateFormatValidator]],
      cmd_0038:[''],
      cmd_0039:[null, [this.dateFormatValidator]],
      cmd_0040:[''],
      cmd_0041:[null, [this.dateFormatValidator]],
      cmd_0042:[''],
      cmd_0043:[null],
      cmd_0044:[''],
      cmd_0045:[''],
      cmd_0046:[''],
      cmd_0047:[null],
      cmd_0048:[''],
      cmd_0049:[null, [this.dateFormatValidator]],
      cmd_0050:[null,[Validators.required]],
      368:[null,[Validators.required]],
      cmd_0052:[null],
      380:[null],
      556:[null],
      cmd_0054:[null],
      393:[null],
      cmd_0056:[null],
      cmd_0057:[''],
      cmd_0058:[''],
      cmd_0059:[''],
      cmd_0060:[''],
      cmd_0061:[''],
      cmd_0062:[''],
      cmd_0063:[''],
      cmd_0064:[''],
      cmd_0065:[''],
      cmd_0066:[''],
      cmd_0067: ['', [Validators.required]],
      cmd_0068: ['', [Validators.required]],
      cmd_0069: ['', [Validators.required]],
      cmd_0070: ['', [Validators.required]],
      cmd_0071: ['', [Validators.required]],
      cmd_0072: ['', [Validators.required]],
      cmd_0073: ['', [Validators.required]],
      cmd_0074: ['', [Validators.required]],
      cmd_0075: ['', [Validators.required]],
      cmd_0076: ['', [Validators.required]],
      cmd_0077: ['', [Validators.required]],
      cmd_0078: ['', [Validators.required]],
      cmd_0079: ['', [Validators.required]],
      cmd_0080: ['', [Validators.required]],
      cmd_0081: ['', [Validators.required]],
      cmd_0082: ['', [Validators.required]],
      cmd_0083: ['', [Validators.required]],
      cmd_0084: ['', [Validators.required]],
      cmd_0085: ['', [Validators.required]],
      cmd_0086: ['', [Validators.required]],
      cmd_0087: ['', [Validators.required]],
      cmd_0088: ['', [Validators.required]],
      cmd_0089: ['', [Validators.required]],
      cmd_0090: ['', [Validators.required]],
      cmd_0091: ['', [Validators.required,this.dateFormatValidator]],
      cmd_0093:['',[Validators.required]],
      cmd_0094: ['', [Validators.required]],

      cmd_0095: ['', [Validators.required,this.dateFormatValidator]],
      cmd_0096:[''],
      cmd_0097:[''],
      cmd_0098:[''],
      cmd_0099:[''],
      cmd_00111:[''],
      cmd_00112:[''],
      cmd_00113:[null],
      cmd_00114:[''],
      cmd_00115:[''],
      cmd_00116:[''],
      cmd_00117:[''],
      cmd_00118:[null, [this.dateFormatValidator]],
      cmd_00119:[''],
      cmd_00120:[''],
      cmd_00121:[''],
      cmd_00122:[''],
      cmd_00123:[''],
      cmd_00124:[''],
      cmd_00125:[''],
      cmd_00126:[null, [this.dateFormatValidator]],
      cmd_00127:[null, [this.dateFormatValidator]],
      cmd_00128:[null, [this.dateFormatValidator]],
      cmd_00129:[null, [this.dateFormatValidator]],














      427:[''],
      435:[null, [this.dateFormatValidator]],
      2008:[null, [this.dateFormatValidator]],
      428:[''],
      429:[''],
      430:[''],
      432:[null],
      433:[''],
      1306:[''],
      436:[''],
      444:[null, [this.dateFormatValidator]],
      2011:[null, [this.dateFormatValidator]],
      437:[''],
      438:[''],
      439:[''],
      441:[null],
      442:[''],
      1308:[''],
      456:[''],
      465:[null, [this.dateFormatValidator]],
      466:[null, [this.dateFormatValidator]],
      457:[''],
      458:[''],
      459:[''],
      461:[null],
      462:[''],
      467:[''],
      476:[null, [this.dateFormatValidator]],
      477:[null, [this.dateFormatValidator]],
      468:[''],
      470:[''],
      472:[null],
      473:[''],
      1377:[null, [this.dateFormatValidator]],
      293: ['', Validators.required],
      503: ['', Validators.required],
      512: [null, Validators.required],
      504: ['', Validators.required],
      505: ['', Validators.required],
      511: ['', [Validators.required,this.dateFormatValidator]],
      506: ['', Validators.required],
      508: [null],
      509: ['', Validators.required],
      490: [''],
      500: [null],
      491: [''],
      492: [''],
      499: [null, [this.dateFormatValidator]],
      493: [''],
      495: [null],
      496: [''],
      //other Professional
      479: [''],
      480: [null],
      483: [''],
      484: [''],
      482: [null, [this.dateFormatValidator]],
      485: [''],
      487: [null],
      488: [''],
      //Intership
      517: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      518: [''],
      519: [''],
      528: [''],
      520: [''],
      522: [null],
      523: [''],
      2539: [''],
      //Residency
      529: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      531: [''],
      1498: [''],
      540: [''],
      533: [''],
      535: [null],
      536: [''],
      2540: [''],
      
      add_1_1170:[''],
      add_1_1178:[null, [this.dateFormatValidator]],
      add_1_1179:[null, [this.dateFormatValidator]],
      add_1_1172:[''],
      add_1_1173:[''],
      add_1_1181:[''],
      add_1_1174:[''],
      add_1_1176:[null],
      add_1_1177:[''],
      add_1_2541:[''],
      //List in Chronological
      542: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      544: [''],
      545: [''],
      553: [''],
      546: [''],
      548: [null],
      549: [''],
      2542: [''],
      454: [null, [this.dateFormatValidator]],
      455: [null, [this.dateFormatValidator]],
      446: [''],
      447: [''],
      448: [''],
      450: [null],
      451: [''],

      add_1_966:[''],
      add_1_974:[null, [this.dateFormatValidator]],
      add_1_975:[null, [this.dateFormatValidator]],
      add_1_968:[''],
      add_1_969:[''],
      add_1_983:[''],
      add_1_970:[''],
      add_1_972:[null],
      add_1_1301:[''],
      add_1_2543:[''],
      add_2_966:[''],
      add_2_974:[null, [this.dateFormatValidator]],
      add_2_975:[null, [this.dateFormatValidator]],
      add_2_968:[''],
      add_2_969:[''],
      add_2_983:[''],
      add_2_970:[''],
      add_2_972:[null],
      add_2_1301:[''],
      add_2_2543:[''],
      add_3_986:[''],
      add_3_994:[null, [this.dateFormatValidator]],
      add_3_2010:[null, [this.dateFormatValidator]],
      add_3_987:[''],
      add_3_988:[''],
      add_3_991:[null],
      add_3_989:[''],
      add_3_992:[''],
      add_3_1310:[''],
      add_3_995:[null],
      add_4_986:[''],
      add_4_994:[null, [this.dateFormatValidator]],
      add_4_2010:[null, [this.dateFormatValidator]],
      add_4_987:[''],
      add_4_988:[''],
      add_4_989:[''],
      add_4_991:[null],
      add_4_992:[''],
      add_4_1310:[''],
      add_4_995:[null],



    });
    console.log(this.CSCcare);

  }
 
  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_0090') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
      if (array[i].field_name == 'cmd_0094') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
 
    }
    
    this.CSCcare.patchValue(fieldsCtrls);
  }
  public setEsign(filePath, fieldId) {
    // console.log(filePath);
    if (fieldId == 'cmd_0090') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
    if (fieldId == 'cmd_0094') {
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showTwo = true;
    }
  }
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_0090: this.finalImgPath
      };
      this.CSCcare.patchValue(temp);

      this.CSCcare.controls['cmd_0090'].setValue(this.finalImgPath);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  public esignOpenTwo() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_0094: this.finalImgPath
      };
      this.CSCcare.patchValue(temp);

      this.CSCcare.controls['cmd_0094'].setValue(this.finalImgPath);
      this.finalImg = data.base64Img;
      this.esignTwo = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showTwo = true;
    });
  }
  
  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  onChangeFirstName(event) {
    this.firstName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0093: this.finalName
    };
    this.CSCcare.patchValue(temp);
  }
  onChangeLastName(event) {
    this.lastName = event.target.value;
    this.finalName = this.firstName + ' ' + this.lastName;
    let temp = {
      cmd_0093: this.finalName
    };
    this.CSCcare.patchValue(temp);
  }
  onChangeMiddleName(event) {
    
    this.middleName = event.target.value;
    this.finalName = this.firstName + ' ' + this.middleName+ ' ' + this.lastName;
    let temp = {
      cmd_0093: this.finalName
    };
    this.CSCcare.patchValue(temp);
  }
  public getCountyData() {
    let params: {}
    this.spinner.show();
    this.onboardService.getCountyData(params).subscribe(
      data => {
        if (data['success']) {
          this.country = data.data.map(i => ({
            ...i, id: i.id.toString()
          }))
        }
        else {
          this.notiService.showError(data.error, '', 3000)
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    )
  }

  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.setEsign(data.base64, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getStatesData(...args: []) {
    let params: {};
    this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.stateDropDownDate = data.data.map(i => ({
            ...i, id: i.id.toString()
          }));
        }
        else {
          this.notiService.showError(data.error, '', 3000);
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  public getSuffixData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSuffixData(params).subscribe(
      data => {
        if (data['success']) {
          this.suffix = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  setErrorControl(name){
    this.CSCcare.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.CSCcare.controls[name].setErrors(null)
  }
  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_0017'){
      let x=[479,480,483,484,482,485,487,488]
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
          result = true
        }
        else{
          this.removeErrorControl(element);
        }
      });
    } 
    if(cName=='cmd_0018'){
      let x=[517,524,525,518,519,528,520,522,523,2539,'cmd_0019','cmd_0021']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0022'){
      let x=[529,537,538,531,1498,540,533,535,536,2540,'cmd_0023','cmd_0024']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0025'){
      let x=[542,550,551,544,545,553,546,548,549,2542,'cmd_0026','cmd_0028','cmd_0030','cmd_0027']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0032'){
      let x=[418,426,2007,419,420,421,423,424,1304,'cmd_0033',894,'cmd_0034','cmd_00118']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0043'){
      let x=[445,'cmd_0044',454,455,446,447,448,450,451]
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0047'){
      let x=[554,1169,557,558]
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_0056'){
      let x=[345,901,902,903,905,906,346,1240,348,349,'cmd_0057',350,347,351]
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if(cName=='cmd_00113'){
      let x=[490,500,491,492,499,493,495,496]
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null)&& !this.CSCcare.controls[cName].value){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
  } 
  handleYesNoChanges(cName,eName?){
    if (cName == "cmd_0019") {
      let x=['cmd_00115']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);

        }
        else{
          this.removeErrorControl(element);
        }
      });
    
    }
    if (cName == "cmd_0023") {
      let x=['cmd_00116']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0026") {
      let x=['cmd_00117']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0028") {
      let x=['cmd_00119']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0030") {
      let x=['cmd_00120']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0024") {
      let x=['cmd_00121']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==0 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0021") {
      let x=['cmd_00122']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==1 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0027") {
      let x=['cmd_00123']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==1 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0029") {
      let x=['cmd_00124']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==1 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
    if (cName == "cmd_0031") {
      let x=['cmd_00125']
      x.forEach(element => {
        if((this.CSCcare.controls[element].value=='' || this.CSCcare.controls[element].value==null) && (this.CSCcare.controls[cName].value==1 || this.CSCcare.controls[cName].value==null)){
          this.setErrorControl(element);
        }
        else{
          this.removeErrorControl(element);
        }
      });
    }
  }
  

  saveForm(type) {
    this.isSubmit = true;
    this.handleNAchange('cmd_0017');
    this.handleNAchange('cmd_0018');
    this.handleNAchange('cmd_0022');
    this.handleNAchange('cmd_0025');
    this.handleNAchange('cmd_0032');
    this.handleNAchange('cmd_0043');
    this.handleNAchange('cmd_0047');
    this.handleNAchange('cmd_0056');
    this.handleNAchange('cmd_00113');
    this.handleYesNoChanges('cmd_00115');
    this.handleYesNoChanges('cmd_00116');
    this.handleYesNoChanges('cmd_00117');
    this.handleYesNoChanges('cmd_00119');
    this.handleYesNoChanges('cmd_00120');
    this.handleYesNoChanges('cmd_00121');
    this.handleYesNoChanges('cmd_00122');
    this.handleYesNoChanges('cmd_00123');
    this.handleYesNoChanges('cmd_00124');
    this.handleYesNoChanges('cmd_00125');













    console.log(this.CSCcare);
    if (type == 'submit') {
      if (this.CSCcare.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.CSCcare.controls) {
          if (this.CSCcare.controls.hasOwnProperty(key)) {
            const element = this.CSCcare.controls[key];
            if(element.status=="INVALID"){
            console.log(key + " " + element.status)
            }
          }
        }
      } else {
        if (this.CSCcare.controls['cmd_0090'].value == null || this.CSCcare.controls['cmd_0090'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        if (this.CSCcare.controls['cmd_0094'].value == null || this.CSCcare.controls['cmd_0094'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        let data = {
          formType: type,
          formValues: this.CSCcare.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.CSCcare);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.CSCcare.value,
        formId: 43
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }
  close() {
    this.onClose.emit('close modal');
  }
  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.CSCcare.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      this.handleNAchange(handleYesNoValue);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.CSCcare.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
  }
