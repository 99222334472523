import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from 'apps/cmd-frontend/src/app/shared/services/notifications/toaster-noti.service';
import { NgbCalendar, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'apps/cmd-frontend/src/app/shared/services/storage/storage.service';
import { UntypedFormBuilder } from '@angular/forms';
import {MedicalStaffService} from "../../../services/medical-staff/medical-staff.service"

@Component({
    selector: 'medical-staf-model1',
    templateUrl: './medical-staf-model.component.html',
    styleUrls: ['./medical-staf-model.component.scss'],
    standalone: true
})
export class MedicalStafModelComponent implements OnInit {
  
  
  public date;
  public modalResponse: object = {
    type: "close"
  };
  @Input() searchData;
  @Input() firstName;
  @Input() lastName;
  @Input() phone;

  @Output() downlaodPdf: EventEmitter<any> = new EventEmitter();









  constructor(
    public activeModal: NgbActiveModal,
    private medicalStaffService1: MedicalStaffService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private calendar: NgbCalendar,
    private storageService:StorageService

  ) {

   }

  ngOnInit(): void {
    this.getToday();
  }


  getToday() {
    this.date = this.calendar.getToday()
    this.date =  this.date ? this.date.month + '/' + this.date.day + '/' + this.date.year : '';
  }

  download(){
    this.downlaodPdf.emit();
  }
  
}
