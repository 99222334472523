import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EsignComponent } from '../esign/esign.component';
import { DomSanitizer } from '@angular/platform-browser';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { StorageService } from '../../services/storage/storage.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ctg-newman-medical-staff',
    templateUrl: './newman-medical-staff.component.html',
    styleUrls: ['./newman-medical-staff.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgbDatepickerModule, NgIf, NgSelectModule]
})
export class NewmanMedicalStaffComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private _sanitizer: DomSanitizer
  ) { }

  public activeCheck = 0;
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  public newMed: FormGroup;
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
  };
  suffix: Array<object> = [];
  credentials: Array<object> = [];
  states: Array<object> = [];
  degree: Array<object> = [];
  specialty: Array<object> = [];
  gender: Array<object> = [];
  AltCdsStates: Array<object> = [];
  altLicenseState: Array<object> = [];
  additionalLicenseState: Array<object> = [];
  boardCertification: Array<object> = [];
  specialtySecondary: Array<object> = [];
  specialtyTer: Array<object> = [];
  staffStatus: Array<object> = [];
  preClaim: Array<object> = [];
  aggregates: Array<object> = [];
  county: Array<object> = [];
  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  showOne: any = false;
  isSubmit: boolean = false;
  changeCmdOne: any;
  changeCmdTwo: any;
  changeCmdThree: any;
  doesNotApplyVar: any;
  public eduapp: boolean = false;
  public workapp: boolean = false;
  public hospapp: boolean = false;
  public refapp: boolean = false;
  public proapp: boolean = false;
  public premlpapp: boolean = false;
  public prospeapp: boolean = false;
  public licenseapp: boolean = false;
  public dataRadio: boolean = false;

  firstName: any;
  lastName: any;
  finalName: any;
  ImgOne: any;
  imgOneSet: any;
  imgTwoSet: any;
  imgOneSetting: any;
  yesNoQuestionIds: any = [];
  yesNoQuestionExplanation: any = [];
  questionCounter = 0;

  // questionCounter = 0;
  // answerCounter = 0;
  // questionNumbers = '';
  indexKey = 0;
  private phonePattern = '^[0-9]*$';
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  customerId = 0;
  ngOnInit(): void {
    this.customerId = Number(this.storageService.getItem('customerId'));
    console.log(this.customerId)
    this.buildForm();
    this.getSpecialtyData();
    this.getDegreeData();
    this.getStatesData();
    this.getAdditionalLicenseStateData();
    this.getAlternateLicenseStateData();
    this.getBoardCertificationData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
    this.afterSaveDataDisplay();
    
  }

  buildForm(){
    this.newMed = this.fb.group({
      cmd_0090: [null, [this.dateFormatValidator]],
      9: ['',[Validators.required]],
      8: ['',[Validators.required]],
      809: [''],
      31: [null, [this.dateFormatValidator]],
      810: [''],
      291: [''],
      289: [null],
      cmd_0001: [''],
      cmd_0002: [''],
      cmd_0003: [''],
      cmd_0004: [''],
      3734: ['',[Validators.pattern(this.phonePattern)]],
      16: [''],
      17: [''],
      18: [''],
      20: [null],
      21: [''],
      708: ['',[Validators.pattern(this.phonePattern)]],
      759: ['',[Validators.pattern(this.emailPattern)]],
      cmd_0005: [''],
      292: [''],
      32: ['',[Validators.required]],
      1680: [''],
      33: [''],
      4041: [''],
      cmd_0006: [''],
      503: [''],
      512: [null],
      513: [''],
      504: [''],
      505: [''],
      506: [''],
      508: [null],
      509: [''],
      1238:[null, [this.dateFormatValidator]],
      490: [''],
      500: [null],
      1376: [''],
      491: [''],
      492: [''],
      493: [''],
      495: [null],
      496: [''],
      498: [null, [this.dateFormatValidator]],
      517: [''],
      518: [''],
      519: [''],
      520: [''],
      522: [null],
      523: [''],
      524: [null, [this.dateFormatValidator]],
      525: [null, [this.dateFormatValidator]],
      527: [null],
      528: [''],
      529: [''],
      531: [''],
      1498: [''],
      533: [''],
      535: [null],
      536: [''],
      537: [null, [this.dateFormatValidator]],
      538: [null, [this.dateFormatValidator]],
      1170: [''],
      1172: [''],
      1173: [''],
      1174: [''],
      1176: [null],
      1177: [''],
      1178: [null, [this.dateFormatValidator]],
      1179: [null, [this.dateFormatValidator]],
      542: [''],
      544: [''],
      545: [''],
      546: [''],
      548: [null],
      549: [''],
      550: [null, [this.dateFormatValidator]],
      551: [null, [this.dateFormatValidator]],
      966: [''],
      968: [''],
      969: [''],
      970: [''],
      972: [null],
      1301: [''],
      974: [null, [this.dateFormatValidator]],
      975: [null, [this.dateFormatValidator]],
      cmd_0073: [''],
      cmd_0007: [''],
      cmd_0080: [''],
      cmd_0081: [''],
      cmd_0082: [''],
      418: [''],
      419: [''],
      420: [''],
      421: [''],
      423: [null],
      424: [''],
      426: [null, [this.dateFormatValidator]],
      1370: [null, [this.dateFormatValidator]],
      cmd_050: [''],
      427: [''],
      428: [''],
      429: [''],
      430: [''],
      432: [null],
      433: [''],
      435: [null, [this.dateFormatValidator]],
      1371: [null, [this.dateFormatValidator]],
      cmd_051: [''],
      436: [''],
      437: [''],
      438: [''],
      439: [''],
      441: [null],
      442: [''],
      444: [null, [this.dateFormatValidator]],
      1372: [null, [this.dateFormatValidator]],
      cmd_052: [''],
      986: [''],
      987: [''],
      988: [''],
      989: [''],
      991: [null],
      992: [''],
      994: [null, [this.dateFormatValidator]],
      1373: [null, [this.dateFormatValidator]],
      cmd_053: [''],
      554: [null],
      557: [null, [this.dateFormatValidator]],
      560: [null, [this.dateFormatValidator]],
      cmd_0061: [''],
      cmd_0062: [null, [this.dateFormatValidator]],
      cmd_0068: [''],
      cmd_0064: [''],
      cmd_0067: [null, [this.dateFormatValidator]],
      cmd_0063: [''],
      293: [''],
      300: [''],
      299: [null],
      907: [null],
      add_1_999: [''],
      add_3_999: [''],
      add_1_1010: [null],
      add_3_1010: [null],
      add_2_999: [''],
      add_4_999: [''],
      add_2_1010: [null],
      add_4_1010: [null],
      309: [''],
      311: [null, [this.dateFormatValidator]],
      1634: [''],
      514: [''],
      cmd_0065: [''],
      359: [''],
      360: [''],
      361: [''],
      362: [''],
      364: [null],
      365: [''],
      368: [null],
      371: ['', [Validators.pattern(this.emailPattern)]],
      4242: [''],
      369: ['', [Validators.pattern(this.phonePattern)]],
      370: [''],
      372: [''],
      373: [''],
      374: [''],
      375: [''],
      377: [null],
      378: [''],
      380: [null],
      383: ['', [Validators.pattern(this.emailPattern)]],
      4243: [''],
      381: ['', [Validators.pattern(this.phonePattern)]],
      382: [''],
      384: [''],
      385: [''],
      386: [''],
      387: [''],
      389: [null],
      390: [''],
      393: [null],
      396: ['', [Validators.pattern(this.emailPattern)]],
      4244: [''],
      394: ['', [Validators.pattern(this.phonePattern)]],
      395: [''],
      1681: [''],
      1682: [''],
      1683: [''],
      1684: [''],
      1686: [null],
      1687: [''],
      1691: [null],
      1694: ['', [Validators.pattern(this.emailPattern)]],
      4245: [''],
      1692: ['', [Validators.pattern(this.phonePattern)]],
      1693: [''],
      cmd_0070: [''],
      cmd_0071: [''],
      cmd_010: [''],
      cmd_011: [''],
      cmd_012: [''],
      cmd_013: [''],
      cmd_014: [''],
      cmd_015: [''],
      cmd_016: [''],
      cmd_017: [''],
      cmd_018: [''],
      cmd_019: [''],
      cmd_020: [''],
      cmd_021: [''],
      cmd_022: [''],
      cmd_023: [''],
      cmd_024: [''],
      cmd_025: [''],
      cmd_026: [''],
      cmd_027: [''],
      cmd_028: [''],
      cmd_0008: [''],
      // cmd_NA_02:[''],
      cmd_029: [''],
      cmd_030: [''],
      cmd_031: [''],
      cmd_032: [''],
      cmd_0091: ['', [Validators.required]],
      cmd_0092: [null, [Validators.required,this.dateFormatValidator]]
      });
  }

  
  public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_0019: this.finalImgPath
      };
      this.newMed.controls['cmd_0091'].setValue(this.finalImgPath);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  saveForm(type) {
    this.isSubmit = true;

    // this.handleNAchange('cmd_NA_02');
    // this.handleYesNoChanges('cmd_0073');
    // this.handleYesNoChanges('cmd_028');
    this.handleCheckboxChanges('cmd_0080');
    console.log(this.newMed);
  
    if (type == 'submit') {
      if (this.newMed.invalid) {
        this.notiService.showError(
          'Please Fill the all mandatory fields to continue',
          '',
          3000
        );
        for (const key in this.newMed.controls) {
          if (this.newMed.controls.hasOwnProperty(key)) {
            const element = this.newMed.controls[key];
            if(element.status=="INVALID"){
            console.log(key + " " + element.status)
            }
          }
        }
      } else {
        if (this.newMed.controls['cmd_0091'].value == null || this.newMed.controls['cmd_0091'].value == '') {
          this.notiService.showError('Please add your Signature')
          return
        }
        let data = {
          formType: type,
          formValues: this.newMed.value
        };
        console.log("Form has been submitted successfully");
        this.onSubmit.emit(data);
        console.log(this.newMed);

      }
    } else {
      let data = {
        formType: type,
        formValues: this.newMed.value,
        formId: 37
      };
      this.onSubmit.emit(data);
      this.onClose.emit('close modal');
    }
  }

  close() {
    this.onClose.emit('close modal');
  }

  public afterSaveDataDisplay() {
    let params = {
      type: 38,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }
  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_0091') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
    }
    this.newMed.patchValue(fieldsCtrls);
  }
  public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.ImgOne = data.base64;
          this.setEsign(this.ImgOne, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public setEsign(filePath, fieldId) {
    this.imgOneSetting = 1;
    // console.log(filePath);
    if (fieldId == 'cmd_0091') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
  }
  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getDegreeData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  setErrorControl(name){
    this.newMed.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.newMed.controls[name].setErrors(null)
  }
  handleYesNoChanges(cName,eName?){
    // if (cName == "cmd_0073") {
    //   let x=['cmd_0007']
    //   x.forEach(element => {
    //     if((this.newMed.controls[element].value=='' || this.newMed.controls[element].value==null) && (this.newMed.controls[cName].value==1 || this.newMed.controls[cName].value==null)){
    //       this.setErrorControl(element);
    //     }
    //     else{
    //       this.removeErrorControl(element);
    //     }
    //   });
    // }
    // if (cName == "cmd_028") {
    //   let x=['cmd_0008']
    //   x.forEach(element => {
    //     if((this.newMed.controls[element].value=='' || this.newMed.controls[element].value==null) && (this.newMed.controls[cName].value==1 || this.newMed.controls[cName].value==null) && this.newMed.controls['cmd_NA_02'].value!=1){
    //       this.setErrorControl(element);
    //     }
    //     else{
    //       this.removeErrorControl(element);
    //     }
    //   });
    // }
  }
  handleNAchange(cName,eName?){
    let result:boolean=false;
    // if(cName=='cmd_NA_02'){
    //   let x=['cmd_0008']
    //   x.forEach(element => {
    //     if((this.newMed.controls[element].value=='' || this.newMed.controls[element].value==null) && !this.newMed.controls[cName].value && this.newMed.controls['cmd_028'].value==1){
    //       this.setErrorControl(element);
    //       result = true
    //     }
    //     else{
    //       this.removeErrorControl(element);
    //     }
    //   });
    // }
  }
  handleCheckboxChanges(cName){
    if(cName == 'cmd_0080' || cName == 'cmd_0081' || cName == 'cmd_0082'){
      if(this.newMed.controls['cmd_0080'].value || this.newMed.controls['cmd_0081'].value || this.newMed.controls['cmd_0082'].value){
        this.activeCheck = 0;
      }else{
        this.activeCheck = 1;
      }
    }
  }
  handleTextBox(cName,eName){
    if((this.newMed.controls[cName].value == '' && this.newMed.controls[eName].value==0) || this.newMed.controls['cmd_NA_02'].value){
      this.removeErrorControl(cName);
    }
  }

  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.newMed.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.newMed.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }
}
