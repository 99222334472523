<div class="erie-form"  [formGroup]="erieForm1">
    <hr
class="pdf-line">

<label class="pt-4"><b>
    Please indicate standard patient waiting times to schedule an appointment at this site for:</b>
</label>
<table border="1"  class="pt-2" style="width:90%">
<tr>
    <td colspan="8" width="70%">
        <div class="mb-3  "  >
        </div>
    </td>
    <td colspan="2" width="15%">
        <div  >
            <label  ><b> New Patient</b> </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div   >
            <label  ><b> Existing Patient </b></label>
        </div>
    </td>
</tr>
<tr>
    <td colspan="8" width="70%">
        <div >
            <label  > Emergency Care
            </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div   >
              <input   formControlName="cmd_33575"  class="form-control" type="text" id="cmd_33575" name="careone">
        </div>
    </td>
    <td colspan="2" width="15%">
        <div   >
              <input   formControlName="cmd_33576"  class="form-control" type="text" id="cmd_33576" name="caretwo">
        </div>
    </td>
</tr>
<tr>
    <td colspan="8" width="70%">
        <div  >
            <label  >Urgent Care
            </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div  >
              <input   formControlName="cmd_33577"  class="form-control" type="text" id="cmd_33577" name="ucareone">
        </div>
    </td>
    <td colspan="2" width="15%">
        <div   >
              <input   formControlName="cmd_33578"  class="form-control" type="text" id="cmd_33578" name="ucaretwo">
        </div>
    </td>
</tr>
<tr>
    <td colspan="8" width="70%">
        <div   >
            <label  > Symptomatic Care (e.g., sore throat)
            </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div >
              <input   formControlName="cmd_33579"  class="form-control" type="text" id="cmd_33579" name="scareone">
        </div>
    </td>
    <td colspan="2" width="15%">
        <div >
              <input   formControlName="cmd_33580"  class="form-control" type="text" id="cmd_33580" name="scaretwo">
        </div>
    </td>
</tr>
<tr>
    <td colspan="8" width="70%">
        <div   >
            <label  > Routine Visits (e.g., blood pressure check)
            </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div  >
              <input   formControlName="cmd_33581"  class="form-control" type="text" id="cmd_33581" name="rcareone">
        </div>
    </td>
    <td colspan="2" width="15%">
        <div   >
              <input   formControlName="cmd_33582"  class="form-control" type="text" id="cmd_33582" name="rcaretwo">
        </div>
    </td>
</tr>
<tr>
    <td colspan="8" width="70%">
        <div  >
            <label  > Preventive Routine Care (e.g., school or
                annual
                physical)
            </label>
        </div>
    </td>
    <td colspan="2" width="15%">
        <div>
              <input   formControlName="cmd_33583"  class="form-control" type="text" id="cmd_33583" name="pcareone">
        </div>
    </td>
    <td colspan="2" width="15%">
        <div  >
              <input   formControlName="cmd_33584"  class="form-control" type="text" id="cmd_33584" name="pcaretwo">
        </div>
    </td>
</tr>
</table>
<label class="pt-4"><b>
    Please provide the following regarding your practice at this site:</b>
</label>
<table border="1" style="width:90%">
<tr>
    <td colspan="7" width="70%">
        <div  >
            <label  > Maximum Number of Appointments per Hour
            </label>
        </div>
    </td>
    <td colspan="3" width="15%">
        <div   >
              <input   formControlName="cmd_33585"  class="form-control" type="text" id="cmd_33585" name="pcareone">
        </div>
    </td>
</tr>
<tr>
    <td colspan="7" width="70%">
        <div  >
            <label  > Average Waiting Time in Office (from
                scheduled
                appointment time to actual examination) </label>
        </div>
    </td>
    <td colspan="3" width="15%">
        <div>
              <input   formControlName="cmd_33586"  class="form-control" type="text" id="cmd_33586" name="pcareone">
        </div>
    </td>
</tr>
<tr>
    <td colspan="4" rowspan="3" width="50%">
        <div>
            <label style="font-weight: normal;font-size: 20px"> Average Response Time for Returning Patient
                Calls:
            </label>
        </div>
        <div class="col-md-8"  >
              <input   formControlName="cmd_33587"  class="form-control" type="text" id="cmd_33587" name="aresponse">
        </div>
    </td>
    <td colspan="3" width="25%">
        <div  >
            <label  >Acute or Urgent Situation: </label>
        </div>
    </td>
    <td colspan="3" width="25%">
        <div>
              <input   formControlName="cmd_33588"  class="form-control" type="text" id="cmd_33588" name="pcareone">
        </div>
    </td>
</tr>
<tr>
    <td colspan="3" width="25%">
        <div  >
            <label  >Emergency Situation: </label>
        </div>
    </td>
    <td colspan="3" width="25%">
        <div   >
              <input   formControlName="cmd_33589"  class="form-control" type="text" id="cmd_33589" name="pcareone">
        </div>
    </td>
</tr>
<tr>
    <td colspan="3" width="25%">
        <div  >
            <label  >Routine Call: </label>
        </div>
    </td>
    <td colspan="3" width="25%">
        <div   >
              <input   formControlName="cmd_33590"  class="form-control" type="text" id="cmd_33590" name="pcareone">
        </div>
    </td>
</tr>
</table>
<br />
<br />
<label ><b>
Please check all procedures you perform at this site:
</b>
</label>
<table  class="pt-3" border="1">
<tr>
    <td>
          <input   formControlName="cmd_33591"  type="checkbox" id="cmd_33591" name="cmd_33591" value="1">
        Age-appropriate immunizations
    </td>
    <td>
          <input   formControlName="cmd_33592"  type="checkbox" id="cmd_33592" name="cmd_33592" value="1">
        EKG
    </td>
    <td>
          <input   formControlName="cmd_33593"  type="checkbox" id="cmd_33593" name="cmd_33593" value="1">
        Drawing blood
    </td>
</tr>
<tr>
    <td>
          <input   formControlName="cmd_33594"  type="checkbox" id="cmd_33594" name="cmd_33594" value="1">
        Tympanometry/audiometry screening
    </td>
    <td>
          <input   formControlName="cmd_33595"  type="checkbox" id="cmd_33595" name="cmd_33595" value="1">
        X-rays
    </td>
    <td>
          <input   formControlName="cmd_33596"  type="checkbox" id="cmd_33596" name="cmd_33596" value="1">
        Minor surgery
    </td>
</tr>
<tr>
    <td>
          <input   formControlName="cmd_33597"  type="checkbox" id="cmd_33597" name="cmd_33597" value="1">
        Pulmonary function studies
    </td>
    <td>
          <input   formControlName="cmd_33598"  type="checkbox" id="cmd_33598" name="cmd_33598" value="1">
        Flexible sigmoidoscopy
    </td>
    <td>
          <input   formControlName="cmd_33599"  type="checkbox" id="cmd_33599" name="cmd_33599" value="1">
        Laceration repair
    </td>
</tr>
<tr>
    <td>
          <input   formControlName="cmd_33600"  type="checkbox" id="cmd_33600" name="cmd_33600" value="1">
        Office gynecology (routine pelvic/PAP)
    </td>
    <td>
          <input   formControlName="cmd_33601"  type="checkbox" id="cmd_33601" name="cmd_33601" value="1">
        Asthma treatment
    </td>
    <td>
          <input   formControlName="cmd_33602"  type="checkbox" id="cmd_33602" name="cmd_33602" value="1">
        Allergy skin testing
    </td>
</tr>
<tr>
    <td>
          <input   formControlName="cmd_33603"  type="checkbox" id="cmd_33603" name="cmd_33603" value="1">
        Osteopathic /Chiropractic manipulation
    </td>
    <td>
          <input   formControlName="cmd_33604"  type="checkbox" id="cmd_33604" name="cmd_33604" value="1">
        IV hydration/treatment
    </td>
    <td>
          <input   formControlName="cmd_33605"  type="checkbox" id="cmd_33605" name="cmd_33605" value="1">
        Physical Therapy
    </td>
</tr>
</table>
<br />
<label>
<b>
    List any special skills or qualifications you or your office staff have that enhance your ability to
    practice
    medicine or treat certain patients or classes of patients. List separately any special language skills,
    such as
    fluency in a foreign language or proficiency in sign language.
</b>
</label>
<div class="row mb-3">
<div class="col-md-3">
    <label  > Special Skills of Practitioner:
    </label>
</div>
<div class="col-md-6" >
      <input   formControlName="cmd_33606"  class="form-control" type="text" id="cmd_33606" name="cmd_33606">
</div>
</div>
<div class="row mb-3">
<div class="col-md-3">
    <label  > Special Skills of Staff:
    </label>
</div>
<div class="col-md-6" >
      <input class="form-control"  formControlName="cmd_33607"  type="text" id="cmd_33607" name="cmd_33607">
</div>
</div>
<div class="row mb-3">
<div class="col-md-3">
    <label  > Languages Spoken by Practitioner:
    </label>
</div>
<div class="col-md-6" >
      <input class="form-control"  formControlName="cmd_33608"  type="text" id="cmd_33608" name="cmd_33608">
</div>
</div>
<div class="row mb-3">
<div class="col-md-3">
    <label  > Languages Written by Practitioner:
    </label>
</div>
<div class="col-md-6" >
      <input class="form-control"  formControlName="cmd_33609"  type="text" id="cmd_33609" name="cmd_33609">
</div>
</div>
<div class="row mb-3">
<div class="col-md-3">
    <label  >Languages Spoken by Staff:
    </label>
</div>
<div class="col-md-6" >
      <input  class="form-control" formControlName="cmd_33610"  type="text" id="cmd_33610" name="cmd_33610">
</div>
</div>
<div class="row mb-3">
<div class="col-md-3">
    <label  > Languages Written by Staff:
    </label>
</div>
<div class="col-md-6" >
      <input class="form-control"  formControlName="cmd_33611"  type="text" id="cmd_33611" name="cmd_33611">
</div>
</div>
<label>
<b>Is this practice site handicapped accessible </b>
(check all that apply)?
</label>
<div class="row mb-3">
<div class="col-md-2"></div>
<div class="col-md-2">
      <input   formControlName="cmd_33612"  type="checkbox" id="cmd_33612" name="cmd_33612" value="1">
    Building
</div>
<div class="col-md-2">
      <input   formControlName="cmd_33613"  type="checkbox" id="cmd_33613" name="cmd_33613" value="1">
    Parking
</div>
<div class="col-md-2">
      <input   formControlName="cmd_33614"  type="checkbox" id="cmd_33614" name="cmd_33614" value="1">
    Wheelchair
</div>
<div class="col-md-2">
      <input   formControlName="cmd_33615"  type="checkbox" id="cmd_33615" name="cmd_33615" value="1">
    Restroom
</div>
<div class="col-md-2"></div>
</div>
<div class="row mb-3"  >
<div class="col-md-8">
    <label  ><b>Does this site employ paraprofessionals for direct
            patient care?</b></label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33616" (ngModelChange)="onchangeaction17($event)"  type="radio"  name="cmd_33616" value="1">Yes</label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33616" (ngModelChange)="onchangeaction17($event)"   type="radio"  name="cmd_33616" value="0">No</label>
</div>

<div class="formLabel col-md-1"
*ngIf="(erieForm1.controls['cmd_33616'].touched && erieForm1.controls['cmd_33616'].errors) ||
(erieForm1.controls['cmd_33616'].errors && isSubmit)">
<span *ngIf="(erieForm.controls['cmd_33616'].touched && erieForm.controls['cmd_33616'].errors) ||
(erieForm.controls['cmd_33616'].errors && isSubmit)">
<i class="fas fa-times ms-2 text-danger"></i>
</span>
    </div>


</div>
<div class="row"  >
<div class="col-md-8">
    <label style="font-weight: normal;font-size: 14px;padding-left: 25px;"><b>If yes, </b>is supervision
        always
        provided on premises during paraprofessionals’ direct patient care?</label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33617"  type="radio"  name="cmd_33617" value="0">Yes</label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33617"  type="radio"  name="cmd_33617" value="1">No</label>
</div>
<div class="formLabel col-md-1"
             *ngIf="(erieForm1.controls['cmd_33617'].touched && erieForm1.controls['cmd_33617'].errors) ||
            (erieForm1.controls['cmd_33617'].errors && ischangeAction17)">
            <span *ngIf="(erieForm.controls['cmd_33617'].touched && erieForm.controls['cmd_33617'].errors) ||
            (erieForm.controls['cmd_33617'].errors && ischangeAction17)">
               <i class="fas fa-times ms-2 text-danger"></i>
           </span>
                        </div>

</div>
<div class="row"  >
<div class="col-md-8">
    <label style="font-weight: normal;font-size: 14px;padding-left:85px;"> Do the paraprofessional(s) bill
        under
        any of your Tax ID Numbers?
    </label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33618"  type="radio"  name="cmd_33618" value="0">Yes</label>
</div>
<div class="mb-3 col-md-1"  >
    <label class="checkbox-inline" >
          <input   formControlName="cmd_33618"  type="radio" name="cmd_33618" value="1">No</label>
</div>
</div>
<table class="databox"
>
<tr>
        <td colspan="12" class="table-backround">
            <div class="row"  >
            <div class="col-md-5" >
                <label style="font-weight: normal;font-size: 14px;text-align:centre;"><b>If yes, </b>list
                    Tax ID
                    Numbers used: </label>
            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-4">
                <label  > <b>CONFIDENTIAL INFORMATION </b>
                </label>
            </div>
        </div>
        </td>
</tr>
<tr>
        <td colspan="12" class="table-backround">
            <div class="row mb-3" style="padding-bottom:5px">
            <div class="col-md-5">
                  <input   formControlName="cmd_33619"  style="width: 100%; background-color: white;" class="form-control" id="cmd_33619"
                    name="cmd_33619" type="text">
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33620"  style="width: 100%; background-color: white;" class="form-control   "
                    id="cmd_33620" name="cmd_33620" type="text">
            </div>
            <div class="col-md-4">
                  <input   formControlName="cmd_33621"  style="width: 100%; background-color: white;" class="form-control   "
                    id="cmd_33621" name="cmd_33621" type="text">
            </div>
        </div>
        </td>
</tr>
</table>
<br />
<br />
<hr
class="pdf-line">
    <div class="row"  >
        <div class="col-md-2">
            <label> <b>Lab Service at this site?</b>
            </label>
        </div>
        <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
            <label class="checkbox-inline" >
                  <input   formControlName="cmd_33622"  type="radio"  name="cmd_33622" value="0">Yes</label>
        </div>
        <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
            <label class="checkbox-inline" >
                  <input   formControlName="cmd_33622"  type="radio" name="cmd_33622" value="1">No</label>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <label  >If yes, check whether:
            </label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33623"  type="checkbox" id="cmd_33623" name="cmd_33623" value="1">
            Primary
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33624"  type="checkbox" id="cmd_33624" name="cmd_33624" value="1">
            Secondary
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33625"  type="checkbox" id="cmd_33625" name="cmd_33625" value="1">
            Tertiary
        </div>
    </div>
    <div class="row"  >
        <div class="col-md-3">
            <label> CLIA Waiver:
            </label>
        </div>
        <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
            <label class="checkbox-inline" >
                  <input   formControlName="cmd_33626"  type="radio" name="cmd_33626" value="0">Yes</label>
        </div>
        <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
            <label class="checkbox-inline" >
                  <input   formControlName="cmd_33626"  type="radio"  name="cmd_33626" value="1">No</label>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-4">
            <label  ><b>If yes,</b>CLIA Expiration Date:
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33627"   class="form-control   " id="cmd_33627"
                name="cmd_33627" type="text">
        </div>
    </div>
    <label><b>
            Please provide the following information about physician(s)/practitioner(s) who provide coverage for
            patients
            enrolled at this site when you are not available. </b>
    </label>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Name
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33628"  type="text" id="cmd_33628" name="cmd_33628" class="form-control">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >speciallty
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33629"  class="form-control" type="text" id="cmd_33629" name="cmd_33629">
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33630"  class="form-control   " id="cmd_33630" name="cmd_33630" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33631"  class="form-control   " id="cmd_33631" name="cmd_33631" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33632"  class="form-control   " id="cmd_33632" name="cmd_33632" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                    bindValue="id" formControlName="cmd_33633">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Telephone
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33634"  class="form-control" type="text" id="cmd_33634" name="cmd_33634">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-3">
            <label  >Availability:
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33635"  type="checkbox" id="cmd_33635" name="cmd_33635" value="1">
            Days
        </div>

        <div class="col-md-3">
            <input   formControlName="cmd_33635"  type="checkbox" id="cmd_33635" name="cmd_33635" value="1">
            Nights
      </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33636"  type="checkbox" id="cmd_33636" name="cmd_33636" value="1">
            Weekends
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33637"  type="checkbox" id="cmd_33637" name="cmd_33637" value="1">
            Holidays
        </div>
    </div>
    <table  class="databox"
        >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-2">
                        <label >TAX ID#</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33638"  class="form-control" type="text" id="cmd_33638" name="cmd_33638"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <br />
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Name
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33639"  type="text" id="cmd_33639" name="cmd_33639" class="form-control">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >speciallty
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33640"  class="form-control" type="text" id="cmd_33640" name="cmd_33640">
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33641"  class="form-control   " id="cmd_33641" name="cmd_33641" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33642"  class="form-control   " id="cmd_33642" name="cmd_33642" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33643"  class="form-control   " id="cmd_33643" name="cmd_33643" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                    bindValue="id" formControlName="cmd_33644">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Telephone
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33645"  class="form-control" type="text" id="cmd_33645" name="cmd_33645">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-3">
            <label  >Availability: Nights
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33646"  type="checkbox" id="cmd_33646" name="cmd_33646" value="1">
            Days
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33647"  type="checkbox" id="cmd_33647" name="cmd_33648" value="1">
            Weekends
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33648"  type="checkbox" id="cmd_33648" name="cmd_33648" value="1">
            Holidays
        </div>
    </div>
    <table  class="databox"
        >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row  p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-2">
                        <label >TAX ID#</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33649"  class="form-control" type="text" id="cmd_33649" name="cmd_33649"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <br />
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Name
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33650"  type="text" id="cmd_33650" name="cmd_33650" class="form-control">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >speciallty
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_33651"  class="form-control" type="text" id="cmd_33651" name="cmd_33651">
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33652"  class="form-control   " id="cmd_33652" name="cmd_33652" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33653"  class="form-control   " id="cmd_33653" name="cmd_33653" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33654"  class="form-control   " id="cmd_33654" name="cmd_33654" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                    bindValue="id" formControlName="cmd_33655">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  >Telephone
            </label>
        </div>
        <div class="col-md-8" >
              <input   formControlName="cmd_1002"  class="form-control" type="text" id="cmd_1002" name="cmd_1002">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-3">
            <label  >Availability: Nights
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33656"  type="checkbox" id="cmd_33656" name="cmd_33656" value="1">
            Days
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33657"  type="checkbox" id="cmd_33657" name="cmd_33657" value="1">
            Weekends
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33658"  type="checkbox" id="cmd_33658" name="cmd_33658" value="1">
            Holidays
        </div>
    </div>
    <table  class="databox"  >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row  p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-2">
                        <label >TAX ID#</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33659"  class="form-control" type="text" id="cmd_33659" name="cmd_33659"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <br />
    <label><b>
            Please provide the following information about physician(s)/practitioner(s) who practice in this
            office:</b>
    </label>
    <div class="row"  >
        <div class="col-md-1">
            <label  >Name:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33660"  class="form-control " id="cmd_33660" name="cmd_33660" type="text" placeholder="Last Name"
               >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33661"  class="form-control" id="cmd_33661" name="cmd_33661" type="text" placeholder="First Name"
                >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33662"  class="form-control " id="cmd_33662" name="cmd_33662" type="text" placeholder="MI"
                >
        </div>
        <div class="col-md-2">
            <label  >Specialty:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33663"  class="form-control " id="cmd_33663" name="cmd_33663" type="text" placeholder="Specialty"
               >
        </div>
    </div>
    <br />
    <div class="row"  >
        <div class="col-md-1">
            <label  >Name:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33664"  class="form-control " id="cmd_33664" name="cmd_33664" type="text" placeholder="Last Name"
               >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33665"  class="form-control" id="cmd_33665" name="cmd_33665" type="text" placeholder="First Name"
                >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33666"  class="form-control " id="cmd_33666" name="cmd_33666" type="text" placeholder="MI"
                >
        </div>
        <div class="col-md-2">
            <label  >Specialty:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33667"  class="form-control " id="cmd_33667" name="cmd_33667" type="text" placeholder="Specialty"
               >
        </div>
    </div>
    <br />
    <div class="row"  >
        <div class="col-md-1">
            <label  >Name:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33668"  class="form-control " id="cmd_33668" name="cmd_33668" type="text" placeholder="Last Name"
               >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33669"  class="form-control" id="cmd_33669" name="cmd_33669" type="text" placeholder="First Name"
                >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33670"  class="form-control " id="cmd_33670" name="cmd_33670" type="text" placeholder="MI"
                >
        </div>
        <div class="col-md-2">
            <label  >Specialty:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33671"  class="form-control " id="cmd_33671" name="cmd_33671" type="text" placeholder="Specialty"
               >
        </div>
    </div>
    <br />
    <br />

    <hr
        class="pdf-line">
    <table  class="databox">
        <tr>
            <td colspan="12" class="table-backround">
                <div >
                    <label><b>SECTION  L. PRIMARY SITE TAX INFORMATION</b></label>
                </div>
            </td>
        </tr>
    </table>
    <br />
    <div class="row"  >
        <div class="text-center col-md-12">
            <label  ><b>Please provide the following information for your
                    Primary
                    Site. Include tax information for each business arrangement you use at this site. </b>(pleasee
                include
                additional sheets if more than four applicable business arrangements.)
            </label>
        </div>
    </div>
    <hr class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #1 </b> </label>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-7">
            <label  >Name of Business Arrangement On SS4 or W-9 Form:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33672"  class="form-control   append_practicename" id="cmd_33672" name="cmd_33672" type="text"
                >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-8">
            <label  >Type of Arrangement (e.g., solo or group practice,
                IPA,
                PHO):
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33673"  class="form-control   append_type_arrangement" id="cmd_33673" name="cmd_33673" type="text"
                >
        </div>
    </div>
    <br />
    <table  class="databox"  >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row  p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-3">
                        <label >Tax ID for this Arrangement:
                            #</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33674"  class="form-control append_tin_id_2" type="text" id="cmd_33674" name="cmd_33674"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Billing Address, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33675"  class="form-control   append_prac_add1" id="cmd_33675" name="cmd_33675" type="text"
                >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Telephone Number, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33676"  class="form-control   append_bill_phone2" id="cmd_33676" name="cmd_33676" type="text"
                >
        </div>
    </div>
    <br />
    <hr class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #2 </b> </label>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-7">
            <label  >Name of Business Arrangement On SS4 or W-9 Form:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33677"  class="form-control   " id="cmd_33677" name="cmd_33677" type="text" >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-8">
            <label  >Type of Arrangement (e.g., solo or group practice,
                IPA,
                PHO):
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33678"  class="form-control   " id="cmd_33678" name="cmd_33678" type="text" >
        </div>
    </div>
    <br />
    <table  class="databox"  >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row  p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-3">
                        <label >Tax ID for this Arrangement:
                            #</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33679"  class="form-control" type="text" id="cmd_33679" name="cmd_33679"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Billing Address, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33680"  class="form-control   " id="cmd_33680" name="cmd_33680" type="text" >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Telephone Number, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33681"  class="form-control   " id="cmd_33681" name="cmd_33681" type="text" >
        </div>
    </div>
    <br />
    <hr  class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #3 </b> </label>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-7">
            <label  >Name of Business Arrangement On SS4 or W-9 Form:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33682"  class="form-control   " id="cmd_33682" name="cmd_33682" type="text" >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-8">
            <label  >Type of Arrangement (e.g., solo or group practice,
                IPA,
                PHO):
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33683"  class="form-control   " id="cmd_33683" name="cmd_33683" type="text" >
        </div>
    </div>
    <br />
    <table  class="databox"  >
        <tr>
                <td colspan="12" class="table-backround">
                    <div class="row  p-2"  >
                    <div class="col-md-5" >
                        <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                    </div>
                    <div class="col-md-3">
                        <label >Tax ID for this Arrangement:
                            #</label>
                    </div>
                    <div class="col-md-4">
                          <input   formControlName="cmd_33684"  class="form-control" type="text" id="cmd_33684" name="cmd_33684"
                          >
                    </div>
                </div>
                </td>
        </tr>
    </table>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Billing Address, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33685"  class="form-control   " id="cmd_33685" name="cmd_33685" type="text" >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-6">
            <label  > Telephone Number, if Different from Primary Site:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33686"  class="form-control   " id="cmd_33686" name="cmd_33686" type="text" >
        </div>
    </div>
    <br />
    <hr class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #4 </b> </label>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-7">
            <label  >Name of Business Arrangement On SS4 or W-9 Form:
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33687"  class="form-control   " id="cmd_33687" name="cmd_33687" type="text" >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-8">
            <label  >Type of Arrangement (e.g., solo or group practice,
                IPA,
                PHO):
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33688"  class="form-control   " id="cmd_33688" name="cmd_33688" type="text" >
        </div>
    </div>
    <br />

    <table class="databox" >
    <tr>
            <td colspan="12" class="table-backround">
                <div class="row  p-2"  >
                <div class="col-md-5" >
                    <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                </div>
                <div class="col-md-3">
                    <label >Tax ID for this Arrangement:
                        #</label>
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_33689"  class="form-control" type="text" id="cmd_33689" name="cmd_33689"
                      >
                </div>
            </div>
            </td>
    </tr>
    </table>
    <br />
    <div class="row mb-3"  >
    <div class="col-md-6">
        <label  > Billing Address, if Different from Primary Site:
        </label>
    </div>
    <div class="col-md-4">
          <input   formControlName="cmd_33690"  class="form-control   " id="cmd_33690" name="cmd_33690" type="text" >
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-6">
        <label  > Telephone Number, if Different from Primary Site:
        </label>
    </div>
    <div class="col-md-4">
          <input   formControlName="cmd_33691"  class="form-control   " id="cmd_33691" name="cmd_33691" type="text" >
    </div>
    </div>
    <hr class="black-line">
    <br />
    <hr
    class="pdf-line">
    <table class="databox"
    >
    <tr>
        <td colspan="12" class="table-backround">
            <div  class="text-center">
                <label style="font-weight: normal;font-size: 14px;padding-bottom: 2px;"><b>SECTION M. ADDITIONAL
                        SITE
                        INFORMATION</b></label>
            </div>
        </td>
    </tr>
    </table>
    <br />

    <div class="row"  >
    <div class="col-md-12">
        <label  ><b>Please provide the following information for each
                additional site at which you practice.</b>
        </label>
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2"
        style="width: 120px;border: 6px solid black ; margin: 10px 20px; text-align: center;    height: 115px;">
        <label><b>Site#</b></label>
    </div>
    <div class="col-md-9">
        <div class="col-md-12">
            <div  >
                 <ng-select [items]="locationList" bindLabel="practice_name" placeholder="Select  Locations"
                    bindValue="location_id" formControlName="cmd_100041"
                    (ngModelChange)="onChangeLocation2($event)"
                    >
                </ng-select>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">
                <label>Group/Business Name</label>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">
                  <input   formControlName="cmd_33693"  class="form-control   append_practicename1" id="cmd_33693" name="cmd_33693"
                    type="text">
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">
                <label>Building Name</label>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">
                  <input   formControlName="cmd_33694"  class="form-control   append_add_211" id="cmd_33694" name="cmd_33694" type="text"
                   >
            </div>
        </div>
        <div class="row ">
            <div class="col-md-12">
                <label>Office Address – Number and Street – Suite</label>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                  <input   formControlName="cmd_33695"  class="form-control   append_city_22" id="cmd_33695" name="cmd_33695" type="text"
                    placeholder="city" >
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33696"  class="form-control   append_county2" id="cmd_33696" name="cmd_33696" type="text"
                    placeholder="Country">
            </div>
            <div class="col-md-3">
                <div class="mb-3  "  >
                     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                        bindValue="id" formControlName="cmd_33697">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_33698"  class="form-control   append_zip_22" id="cmd_33698" name="cmd_33698" type="text"
                    placeholder="zip">
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-3">
                <label>City </label>
            </div>
            <div class="col-md-3">
                <label>County</label>
            </div>
            <div class="col-md-3">
                <label>State</label>
            </div>
            <div class="col-md-3">
                <label>Zip</label>
            </div>
        </div>
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33699"  class="form-control   append_phone_22" id="cmd_33699" name="cmd_33699" type="text"
            placeholder="telephone" >
    </div>
    <div class="col-md-4">
          <input   formControlName="cmd_33700"  class="form-control   append_office_manager_first_name_21" id="cmd_33700" name="cmd_33700"
            type="text" placeholder="Name" >
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
        <label>Main Telephone Number </label>
    </div>
    <div class="col-md-4">
        <label>Office Administrator</label>
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33701"  class="form-control   " id="cmd_33701" name="cmd_33701" type="text"
            placeholder="Bepper Number" >
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33702"  class="form-control   append_fax_22" id="cmd_33702" name="cmd_33702" type="text"
            placeholder="FAX Number">
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33703"  class="form-control   " id="cmd_33703" name="cmd_33703" type="text" placeholder="E-mail"
            >
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
        <label>Beeper Number </label>
    </div>
    <div class="col-md-3">
        <label> FAX Number </label>
    </div>
    <div class="col-md-3">
        <label> E-mail</label>
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33704"  class="form-control   " id="cmd_33704" name="cmd_33704" type="text"
           >
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33705"  class="form-control   answering_service_phone2" id="cmd_33705" name="cmd_33705" type="text"
            placeholder="AnsweringService">
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-2">
    </div>
    <div class="col-md-3">
        <label>Emergency Number </label>
    </div>
    <div class="col-md-3">
        <label> Answering Service</label>
    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-4">
        <label>Specialty practiced at this site: </label>
    </div>
    <div class="col-md-6">
          <input   formControlName="cmd_33706"  class="form-control   " id="cmd_33706" name="cmd_33706" type="text">
    </div>
    </div>
    <div class="row"  >
    <div class="col-md-8">
        <label  >Is your practice restricted within your specialty
            (e.g.,
            by age or type of patient)? </label>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
        <label class="checkbox-inline" >
              <input   formControlName="cmd_33707"  (ngModelChange)="onchangeaction21($event)"  type="radio"  name="cmd_33707" value="0">Yes</label>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
        <label class="checkbox-inline" >
              <input   formControlName="cmd_33707" (ngModelChange)="onchangeaction21($event)"   type="radio"  name="cmd_33707" value="1">No</label>
    </div>

    </div>
    <div class="row"  >
    <div class="col-md-8">
        <label  >If Yes, describe the restrictions:
        </label>
    </div>
    <div class="col-md-12">
          <input   formControlName="cmd_33708"  type="text" class="form-control" id="cmd_33708" name="cmd_33708"
          [class.is-invalid]="(erieForm1.controls['cmd_33708'].touched && erieForm1.controls['cmd_33708'].errors)
          || (erieForm1.controls['cmd_33708'].errors && ischangeAction21)">
<div class="formLabel"
            *ngIf="(erieForm1.controls['cmd_33708'].touched && erieForm1.controls['cmd_33708'].errors)
             || (erieForm1.controls['cmd_33708'].errors && ischangeAction21)">
            <small class="text-danger" *ngIf="erieForm1.controls['cmd_33708'].errors">
                required
            </small>
        </div>
        </div>
    </div>
    <div class="row"  >
    <div class="col-md-10">
        <label  >Briefly describe your practice at this location,
            including any special practice focus or equipment:
        </label>
    </div>
    <div class="col-md-12">
          <input   formControlName="cmd_33709"  type="text" class="form-control" id="cmd_33709" name="cmd_33709">
    </div>
    </div>
    <div class="row"  >
    <div class="col-md-8">
        <label  >Are you currently accepting new patients at this
            location?
        </label>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
        <label class="checkbox-inline" >
              <input   formControlName="cmd_33710"  (ngModelChange)="onchangeaction18($event)"  type="radio"  name="cmd_33710" value="1">Yes</label>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1"  >
        <label class="checkbox-inline" >
              <input   formControlName="cmd_33710"  (ngModelChange)="onchangeaction18($event)"  type="radio"  name="cmd_33710" value="0">No</label>
    </div>


    </div>
    <div class="row  mb-3"  >
    <div class="col-md-10">
        <label  > If yes, describe any restrictions (e.g., appointment
            type, patient type):
        </label>
    </div>
    <div class="col-md-12">
          <input   formControlName="cmd_33711"  type="text" class="form-control" id="cmd_33711" name="cmd_33711"
          [class.is-invalid]="(erieForm1.controls['cmd_33711'].touched && erieForm1.controls['cmd_33711'].errors)
          || (erieForm1.controls['cmd_33711'].errors && ischangeAction18)">
<div class="formLabel"
            *ngIf="(erieForm1.controls['cmd_33711'].touched && erieForm1.controls['cmd_33711'].errors)
             || (erieForm1.controls['cmd_33711'].errors && ischangeAction18)">
            <small class="text-danger" *ngIf="erieForm1.controls['cmd_33711'].errors">
                required
            </small>
        </div>

    </div>
    </div>
    <div class="row mb-3"  >
    <div class="col-md-8">
        <label  > Please provide the number of active patients
            enrolled
            with you at this site:
        </label>
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33712"  type="text" class="form-control" id="cmd_33712" name="cmd_33712">
    </div>
    </div>
    <div class="row  mb-3"  >
    <div class="col-md-8">
        <label  >Please provide the number of patient visits you have
            at
            this site per year:
        </label>
    </div>
    <div class="col-md-3">
          <input   formControlName="cmd_33713"  type="text" class="form-control " id="cmd_33713" name="cmd_33713">
    </div>
    </div>
    <label><b>
        Indicate your office schedule at this location in the following table. Write your specific hours in the
        appropriate spaces for each day:</b>
    </label>
    <table border="1" style="width:80%;margin-left:5rem">
    <th>
    </th>
    <th>
        Monday
    </th>
    <th>
        Tuesday
    </th>
    <th>
        Wednesday
    </th>
    <th>
        Thursday
    </th>
    <th>
        Friday
    </th>
    <th>
        Saturday
    </th>
    <th> Sunday </th>
    <tr>
        <td><b>Hours</b></td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33714"  class="form-control" type="text" id="cmd_33714" name="mondaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33715"  class="form-control" type="text" id="cmd_33715" name="Tuesdaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33716"  class="form-control" type="text" id="cmd_33716" name="wednesdaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33717"  class="form-control" type="text" id="cmd_33717" name="Thursdaydaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33718"  class="form-control" type="text" id="cmd_33718" name="Firdaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33719"  class="form-control" type="text" id="cmd_33719" name="Saturdaytext">
            </div>
        </td>
        <td> to
            <div col-sm-1>
                  <input   formControlName="cmd_33720"  class="form-control" type="text" id="cmd_33720" name="sundaytext">
            </div>
        </td>
    </tr>
    </table>
    <hr
    class="pdf-line">

  <label><b>
          Please indicate standard patient waiting times to schedule an appointment at this site for:</b>
  </label>
  <table border="1" >
      <tr>
          <td colspan="8" >
              <div class="mb-3  " >
              </div>
          </td>
          <td colspan="2" >
              <div class="mb-3  " >
                  <label > New Patient </label>
              </div>
          </td>
          <td colspan="2" >
              <div class="mb-3  " >
                  <label > Existing Patient</label>
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="8" >
              <div class="mb-3  " >
                  <label > Emergency Care
                  </label>
              </div>
          </td>
          <td colspan="2" >
              <div class="mb-3  " >
                    <input  formControlName="cmd_1003"  class="form-control" id="cmd_1003" type="text" name="careone">
              </div>
          </td>
          <td colspan="2">
              <div class="mb-3  " >
                    <input   formControlName="cmd_1010"  class="form-control" id="cmd_1010" type="text" name="caretwo">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="8" >
              <div class="mb-3  " >
                  <label > Urgent Care
                  </label>
              </div>
          </td>
          <td colspan="2">
              <div class="mb-3  " >
                    <input   formControlName="cmd_1008"  class="form-control" id="cmd_1008" type="text" name="ucareone">
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_1009"  class="form-control" id="cmd_1009" type="text" name="ucaretwo">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="8" width="70%">
              <div class="mb-3  " >
                  <label > Symptomatic Care (e.g., sore throat)
                  </label>
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_1011"  class="form-control" id="cmd_1011" type="text" name="scareone">
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33721"  class="form-control" id="cmd_33721" type="text" name="scaretwo">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="8" width="70%">
              <div class="mb-3  " >
                  <label > Routine Visits (e.g., blood pressure check)
                  </label>
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33722"  class="form-control" id="cmd_33722" type="text" name="rcareone">
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_1005"  class="form-control" id="cmd_1005" type="text" name="rcaretwo">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="8" width="70%">
              <div class="mb-3  " >
                  <label > Preventive Routine Care (e.g., school or
                      annual
                      physical)
                  </label>
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33723"  class="form-control" id="cmd_33723" type="text" name="pcareone">
              </div>
          </td>
          <td colspan="2" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33724"  class="form-control" id="cmd_33724" type="text" name="pcaretwo">
              </div>
          </td>
      </tr>
  </table>
  <label><b>
          Please provide the following regarding your practice at this site:</b>
  </label>
  <table border="1" style="width:90%">
      <tr>
          <td colspan="7" width="70%">
              <div class="mb-3  " >
                  <label > Maximum Number of Appointments per Hour
                  </label>
              </div>
          </td>
          <td colspan="3" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33725"  class="form-control" id="cmd_33725" type="text" name="pcareone">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="7" width="70%">
              <div class="mb-3  " >
                  <label > Average Waiting Time in Office (from
                      scheduled
                      appointment time to actual examination) </label>
              </div>
          </td>
          <td colspan="3" width="15%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33726"  class="form-control" id="cmd_33726" type="text" name="pcareone">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="4" rowspan="3" width="50%">
              <div class="mb-3  " >
                  <label style="font-weight: normal;font-size: 20px"> Average Response Time for Returning Patient
                      Calls:
                  </label>
              </div>
              <div class="col-md-8" >
                    <input   formControlName="cmd_33727"  class="form-control" type="text" id="cmd_33727" name="aresponse">
              </div>
          </td>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                  <label >Acute or Urgent Situation: </label>
              </div>
          </td>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33728"  class="form-control" type="text" id="cmd_33728" name="pcareone">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                  <label >Emergency Situation: </label>
              </div>
          </td>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33729"  class="form-control" id="cmd_33729" type="text" name="pcareone">
              </div>
          </td>
      </tr>
      <tr>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                  <label >Routine Call: </label>
              </div>
          </td>
          <td colspan="3" width="25%">
              <div class="mb-3  " >
                    <input   formControlName="cmd_33730"  class="form-control" id="cmd_33730" type="text" name="pcareone">
              </div>
          </td>
      </tr>
  </table>
  <br />
  <br />
   <label><b>
          Please check all procedures you perform at this site:
      </b>
  </label>
  <table border="1" style="width:80%;margin-left:5rem">
      <tr>
          <td>
                <input   formControlName="cmd_33731"  type="checkbox" id="cmd_33731" name="cmd_33731" value="1">
              Age-appropriate immunizations
          </td>
          <td>
                <input   formControlName="cmd_33732"  type="checkbox" id="cmd_33732" name="cmd_33732" value="1">
              EKG
          </td>
          <td>
                <input   formControlName="cmd_33733"  type="checkbox" id="cmd_33733" name="cmd_33733" value="1">
              Drawing blood
          </td>
      </tr>
      <tr>
          <td>
                <input   formControlName="cmd_33734"  type="checkbox" id="cmd_33734" name="cmd_33734" value="1">
              Tympanometry/audiometry screening
          </td>
          <td>
                <input   formControlName="cmd_33735"  type="checkbox" id="cmd_33735" name="cmd_33735" value="1">
              X-rays
          </td>
          <td>
                <input   formControlName="cmd_33736"  type="checkbox" id="cmd_33736" name="cmd_33736" value="1">
              Minor surgery
          </td>
      </tr>
      <tr>
          <td>
                <input   formControlName="cmd_33737"  type="checkbox" id="cmd_33737" name="cmd_33737" value="1">
              Pulmonary function studies
          </td>
          <td>
                <input   formControlName="cmd_33738"  type="checkbox" id="cmd_33738" name="cmd_33738" value="1">
              Flexible sigmoidoscopy
          </td>
          <td>
                <input   formControlName="cmd_33739"  type="checkbox" id="cmd_33739" name="cmd_33739" value="1">
              Laceration repair
          </td>
      </tr>
      <tr>
          <td>
                <input   formControlName="cmd_33740"  type="checkbox" id="cmd_33740" name="cmd_33740" value="1">
              Office gynecology (routine pelvic/PAP)
          </td>
          <td>
                <input   formControlName="cmd_33741"  type="checkbox" id="cmd_33741" name="cmd_33741" value="1">
              Asthma treatment
          </td>
          <td>
                <input   formControlName="cmd_33742"  type="checkbox" id="cmd_33742" name="cmd_33742" value="1">
              Allergy skin testing
          </td>
      </tr>
      <tr>
          <td>
                <input   formControlName="cmd_33743"  type="checkbox" id="cmd_33743" name="cmd_33743" value="1">
              Osteopathic /Chiropractic manipulation
          </td>
          <td>
                <input   formControlName="cmd_33744"  type="checkbox" id="cmd_33744" name="cmd_33744" value="1">
              IV hydration/treatment
          </td>
          <td>
                <input   formControlName="cmd_33745"  type="checkbox" id="cmd_33745" name="cmd_33745" value="1">
              Physical Therapy
          </td>
      </tr>
  </table>
  <br />
  <label>
      <b>
          List any special skills or qualifications you or your office staff have that enhance your ability to
          practice
          medicine or treat certain patients or classes of patients. List separately any special language skills,
          such as
          fluency in a foreign language or proficiency in sign language.
      </b>
  </label>
  <div class="row mb-3">
      <div class="col-md-3">
          <label > Special Skills of Practitioner:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33746"  class="form-control" type="text" id="cmd_33746" name="cmd_33746">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label > Special Skills of Staff:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33747"  class="form-control" type="text" id="cmd_33747" name="cmd_33747">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-5">
          <label > Languages Spoken by Practitioner:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33748"  class="form-control" type="text" id="cmd_33748" name="cmd_33748">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-5">
          <label > Languages Written by Practitioner:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33749"  class="form-control" type="text" id="cmd_33749" name="cmd_33749">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-5">
          <label >Languages Spoken by Staff:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33750"  class="form-control" type="text" id="cmd_33750" name="cmd_33750">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label > Languages Written by Staff:
          </label>
      </div>
      <div class="col-md-6" >
            <input   formControlName="cmd_33751"  class="form-control" type="text" id="cmd_33751" name="cmd_33751">
      </div>
  </div>

  <label>
      <b>Is this practice site handicapped accessible </b>
      (check all that apply)?
  </label>
  <div class="row mb-3">
      <div class=" mb-3 d-flex flex-row align-items-center flex-wrap  col-md-3">
          <label class="checkbox-inline">
            <input  class="form-control" formControlName="cmd_33752"  type="checkbox" id="cmd_33752" name="cmd_33752" value="1">
          Building</label>
      </div>
      <div class=" mb-3 d-flex flex-row align-items-center flex-wrap col-md-3">
        <label class="checkbox-inline"> <input  class="form-control"  formControlName="cmd_33753"  type="checkbox" id="cmd_33753" name="cmd_33753" value="1">
          Parking</label>
      </div>
      <div class=" mb-3 d-flex flex-row align-items-center flex-wrap col-md-3">
        <label class="checkbox-inline">    <input   class="form-control" formControlName="cmd_33754"  type="checkbox" id="cmd_33754" name="cmd_33754" value="1">
          Wheelchair</label>
      </div>
      <div class=" mb-3 d-flex flex-row align-items-center flex-wrap col-md-3">
        <label class="checkbox-inline">   <input  class="form-control"  formControlName="cmd_33755"  type="checkbox" id="cmd_33755" name="cmd_33755" value="1">
          Restroom</label>
      </div>
  </div>
  <div class="row" >
      <div class="col-md-8">
          <label ><b>Does this site employ paraprofessionals for direct
                  patient care?</b></label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33756"  type="radio"  name="cmd_33756" value="0">Yes</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33756"  type="radio"  name="cmd_33756" value="1">No</label>
      </div>
  </div>
  <div class="row" >
      <div class="col-md-8">
          <label style="font-weight: normal;font-size: 14px;padding-left: 25px;"><b>If yes, </b>is supervision
              always
              provided on premises during paraprofessionals’ direct patient care?</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33757"  type="radio"  name="cmd_33757" value="0">Yes</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33757"  type="radio" name="cmd_33757" value="1">No</label>
      </div>
  </div>

  <div class="row" >
      <div class="col-md-8">
          <label> Do the paraprofessional(s) bill
              under
              any of your Tax ID Numbers?
          </label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33758"  type="radio"  name="cmd_33758" value="0">Yes</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33758"  type="radio" name="cmd_33758" value="1">No</label>
      </div>
  </div>
   <table class="databox"
      >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row" >
                  <div class="col-md-5" >
                      <label style="font-weight: normal;font-size: 14px;text-align:centre;"><b>If yes, </b>list
                          Tax ID
                          Numbers used: </label>
                  </div>
                  <div class="col-md-3">
                  </div>
                  <div class="col-md-4">
                      <label > <b>CONFIDENTIAL INFORMATION </b>
                      </label>
                  </div>
                </div>
              </td>
      </tr>
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row mb-3" style="padding-bottom:5px">
                  <div class="col-md-5">
                        <input   formControlName="cmd_33759"   class="form-control" id="cmd_33759"
                          name="cmd_33759" type="text">
                  </div>
                  <div class="col-md-3">
                        <input   formControlName="cmd_33760"   class="form-control   "
                          id="cmd_33760" name="cmd_33760" type="text">
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33761"   class="form-control   "
                          id="cmd_33761" name="cmd_33761" type="text">
                  </div>
                  </div>
              </td>
      </tr>
  </table>
  <br />
  <br />

  <hr
      class="pdf-line">
  <div class="row" >
      <div class="col-md-4">
          <label> <b>Lab Service at this site?</b>
          </label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33762"  type="radio"  name="cmd_33762" value="0">Yes</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33762"  type="radio"  name="cmd_33762" value="1">No</label>
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label >If yes, check whether:
          </label>
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33763"  type="checkbox" id="cmd_33763" name="cmd_33763" value="1">
          Primary
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33764"  type="checkbox" id="cmd_33764" name="cmd_33764" value="1">
          Secondary
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33765"  type="checkbox" id="cmd_33765" name="cmd_33765" value="1">
          Tertiary
      </div>
  </div>
  <div class="row" >
      <div class="col-md-3">
          <label> CLIA Waiver:
          </label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33766"  type="radio"  name="cmd_33766" value="0">Yes</label>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap col-md-1" >
          <label class="checkbox-inline" >
                <input   formControlName="cmd_33766"  type="radio"  name="cmd_33766" value="1">No</label>
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-4">
          <label ><b>If yes,</b>CLIA Expiration Date:
          </label>
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33767"   class="form-control   " id="cmd_33767"
              name="cmd_33767" type="text">
      </div>
  </div>
  <label><b>
          Please provide the following information about physician(s)/practitioner(s) who provide coverage for
          patients
          enrolled at this site when you are not available. </b>
  </label>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Name
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33768"  type="text" id="cmd_33768" name="cmd_33768" class="form-control">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >speciallty
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33769"  class="form-control" type="text" id="cmd_33769" name="cmd_33769">
      </div>
  </div>
  <div class="row mb-3" >
      <div class="col-md-1">
          <label >Address:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33770"  class="form-control   " id="cmd_33770" name="cmd_33770" type="text" placeholder="address1"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33771"  class="form-control   " id="cmd_33771" name="cmd_33771" type="text" placeholder="address2"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33772"  class="form-control   " id="cmd_33772" name="cmd_33772" type="text" placeholder="city"
               >
      </div>
      <div class="col-md-2">
          <div class="mb-3  " >
               <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                  bindValue="id" formControlName="cmd_33774">
              </ng-select>
          </div>
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Telephone
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33775"  class="form-control" type="text" id="cmd_33775" name="cmd_33775">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label >Availability: Nights
          </label>
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33776"  type="checkbox" id="cmd_33776" name="cmd_33776" value="1">
          Days
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33777"  type="checkbox" id="cmd_33777" name="cmd_33777" value="1">
          Weekends
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33778"  type="checkbox" id="cmd_33778" name="cmd_33778" value="1">
          Holidays
      </div>
  </div>
  <table class="databox"
      >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2" >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-2">
                      <label >TAX ID#</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33779"  class="form-control" type="text" id="cmd_33779" name="cmd_33779"
                        >
                  </div>
                </div>
              </td>
      </tr>
  </table>
  <br />
  <br />
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Name
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33780"  type="text" id="cmd_33780" name="cmd_33780" class="form-control">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >speciallty
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33781"  class="form-control" type="text" id="cmd_33781" name="cmd_33781">
      </div>
  </div>
  <div class="row mb-3" >
      <div class="col-md-1">
          <label >Address:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33782"  class="form-control   " id="cmd_33782" name="cmd_33782" type="text" placeholder="address1"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33783"  class="form-control   " id="cmd_33783" name="cmd_33783" type="text" placeholder="address2"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33784"  class="form-control   " id="cmd_33784" name="cmd_33784" type="text" placeholder="city"
               >
      </div>
      <div class="col-md-2">
          <div class="mb-3  " >
               <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                  bindValue="id" formControlName="cmd_33785">
              </ng-select>
          </div>
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Telephone
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33786"  class="form-control" type="text" id="cmd_33786" name="cmd_33786">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label >Availability: Nights
          </label>
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33787"  type="checkbox" id="cmd_33787" name="cmd_33787" value="1">
          Days
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33788"  type="checkbox" id="cmd_33788" name="cmd_33788" value="1">
          Weekends
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33789"  type="checkbox" id="cmd_33789" name="cmd_33789" value="1">
          Holidays
      </div>
  </div>

   <table  class="databox"
      >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2" >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-2">
                      <label >TAX ID#</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33790"  class="form-control" type="text" id="cmd_33790" name="cmd_33790"
                        >
                  </div>
                </div>
              </td>
      </tr>
  </table>
  <br />
  <br />
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Name
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33791"  type="text" id="cmd_33791" name="cmd_33791" class="form-control">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >speciallty
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33792"  class="form-control" type="text" id="cmd_33792" name="cmd_33792">
      </div>
  </div>
  <div class="row mb-3" >
      <div class="col-md-1">
          <label >Address:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33793"  class="form-control   " id="cmd_33793" name="cmd_33793" type="text" placeholder="address1"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33794"  class="form-control   " id="cmd_33794" name="cmd_33794" type="text" placeholder="address2"
               >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33795"  class="form-control   " id="cmd_33795" name="cmd_33795" type="text" placeholder="city"
               >
      </div>
      <div class="col-md-2">
          <div class="mb-3  " >
               <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                  bindValue="id" formControlName="cmd_33796">
              </ng-select>
          </div>
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-2">
          <label >Telephone
          </label>
      </div>
      <div class="col-md-8" >
            <input   formControlName="cmd_33797"  class="form-control" type="text" id="cmd_33797" name="cmd_33797">
      </div>
  </div>
  <div class="row mb-3">
      <div class="col-md-3">
          <label >Availability: Nights
          </label>
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33798"  type="checkbox" id="cmd_33798" name="cmd_33798" value="1">
          Days
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33799"  type="checkbox" id="cmd_33799" name="cmd_33799" value="1">
          Weekends
      </div>
      <div class="col-md-3">
            <input   formControlName="cmd_33800"  type="checkbox" id="cmd_33800" name="cmd_33800" value="1">
          Holidays
      </div>
  </div>
  <table  class="databox"  >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2" >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-2">
                      <label >TAX ID#</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33801"  class="form-control" type="text" id="cmd_33801" name="cmd_33801"
                        >
                  </div>
                </div>
              </td>
      </tr>
  </table>
  <br />
  <br />
  <label><b>
          Please provide the following information about physician(s)/practitioner(s) who practice in this
          office:</b>
  </label>
  <div class="row" >
      <div class="col-md-1">
          <label >Name:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33802"  class="form-control " id="cmd_33802" name="cmd_33802" type="text" placeholder="Last Name"
             >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33803"  class="form-control" id="cmd_33803" name="cmd_33803" type="text" placeholder="First Name"
              >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33804"  class="form-control " id="cmd_33804" name="cmd_33804" type="text" placeholder="MI"
              >
      </div>
      <div class="col-md-2">
          <label >Specialty:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33805"  class="form-control " id="cmd_33805" name="cmd_33805" type="text" placeholder="Specialty"
             >
      </div>
  </div>
  <br />
  <div class="row" >
      <div class="col-md-1">
          <label >Name:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33806"  class="form-control " id="cmd_33806" name="cmd_33806" type="text" placeholder="Last Name"
             >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33807"  class="form-control" id="cmd_33807" name="cmd_33807" type="text" placeholder="First Name"
              >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33808"  class="form-control " id="cmd_33808" name="cmd_33808" type="text" placeholder="MI"
              >
      </div>
      <div class="col-md-2">
          <label >Specialty:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33809"  class="form-control " id="cmd_33809" name="cmd_33809" type="text" placeholder="Specialty"
             >
      </div>
  </div>
  <br />
  <div class="row" >
      <div class="col-md-1">
          <label >Name:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33810"  class="form-control " id="cmd_33810" name="cmd_33810" type="text" placeholder="Last Name"
             >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33811"  class="form-control" id="cmd_33811" name="cmd_33811" type="text" placeholder="First Name"
              >
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33812"  class="form-control " id="cmd_33812" name="cmd_33812" type="text" placeholder="MI"
              >
      </div>
      <div class="col-md-2">
          <label >Specialty:</label>
      </div>
      <div class="col-md-2">
            <input   formControlName="cmd_33813"  class="form-control " id="cmd_33813" name="cmd_33813" type="text" placeholder="Specialty"
             >
      </div>
  </div>
  <br />
  <br />
  <hr
  class="pdf-line">
    <table class="databox" >
      <tr>
          <td colspan="12" class="table-backround">
              <div  class="text-center">
                  <label><b>SECTION N. ADDITIONAL
                          SITE
                          TAX INFORMATION</b></label>
              </div>
          </td>
      </tr>
    </table>
    <br />
    <div class="row"  >
      <div class=" text-center col-md-12">
          <label  ><b>Please provide the following information for each
                  additional site at which you practice. Include tax information for each business arrangement you
                  use at
                  this site. </b>(If there is more than one additional site, or more than five business
              arrangements at
              any one site, please copy and complete this page for each additional site and business arrangement.)
          </label>
      </div>
    </div>
    <hr class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #1</b> </label>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-7">
          <label  >Name of Business Arrangement On SS4 or W-9 Form:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33814"  class="form-control   append_practicename1" id="cmd_33814" name="cmd_33814" type="text"
              >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-8">
          <label  >Type of Arrangement (e.g., solo or group practice,
              IPA,
              PHO):
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33815"  class="form-control   append_type_arrangement2" id="cmd_33815" name="cmd_33815" type="text"
              >
      </div>
    </div>
    <br />
    <table  class="databox"  >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2"  >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-3">
                      <label >Tax ID for this Arrangement:
                          #</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33816"  class="form-control append_tin_id_21" type="text" id="cmd_33816" name="cmd_33816"
                        >
                  </div>
                </div>
              </td>
      </tr>
    </table>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Billing Address, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33817"  class="form-control   append_bill_add2" id="cmd_33817" name="cmd_33817" type="text"
              >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Telephone Number, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33818"  class="form-control   append_bill_phone21" id="cmd_33818" name="cmd_33818" type="text"
              >
      </div>
    </div>
    <br />
    <hr  class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #2 </b> </label>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-7">
          <label  >Name of Business Arrangement On SS4 or W-9 Form:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33819"  class="form-control   " id="cmd_33819" name="cmd_33819" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-8">
          <label  >Type of Arrangement (e.g., solo or group practice,
              IPA,
              PHO):
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33820"  class="form-control   " id="cmd_33820" name="cmd_33820" type="text" >
      </div>
    </div>
    <br />
    <table  class="databox"  >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2"  >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-3">
                      <label >Tax ID for this Arrangement:
                          #</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33821"  class="form-control" type="text" id="cmd_33821" name="cmd_33821"
                        >
                  </div>
                </div>
              </td>
      </tr>
    </table>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Billing Address, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33822"  class="form-control   " id="cmd_33822" name="cmd_33822" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Telephone Number, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33823"  class="form-control   " id="cmd_33823" name="cmd_33823" type="text" >
      </div>
    </div>
    <hr  class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #3 </b> </label>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-7">
          <label  >Name of Business Arrangement On SS4 or W-9 Form:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33824"  class="form-control   " id="cmd_33824" name="cmd_33824" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-8">
          <label  >Type of Arrangement (e.g., solo or group practice,
              IPA,
              PHO):
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33825"  class="form-control   " id="cmd_33825" name="cmd_33825" type="text" >
      </div>
    </div>
    <br />
    <table  class="databox"  >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2"  >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-3">
                      <label >Tax ID for this Arrangement:
                          #</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33826"  class="form-control" type="text" id="cmd_33826" name="cmd_33826"
                        >
                  </div>
                </div>
              </td>
      </tr>
    </table>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Billing Address, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33827"  class="form-control   " id="cmd_33827" name="cmd_33827" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Telephone Number, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33828"  class="form-control   " id="cmd_33828" name="cmd_33828" type="text" >
      </div>
    </div>
    <br />
    <hr  class="black-line">
    <label style="font-weight: normal;font-size: 14px; margin-left:2rem"><b> Business Arrangement #4 </b> </label>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-7">
          <label  >Name of Business Arrangement On SS4 or W-9 Form:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33829"  class="form-control   " id="cmd_33829" name="cmd_33829" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-8">
          <label  >Type of Arrangement (e.g., solo or group practice,
              IPA,
              PHO):
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33830"  class="form-control   " id="cmd_33830" name="cmd_33830" type="text" >
      </div>
    </div>
    <br />
    <table  class="databox"  >
      <tr>
              <td colspan="12" class="table-backround">
                <div class="row  p-2"  >
                  <div class="col-md-5" >
                      <label ><b>CONFIDENTIAL INFORMATION</b> </label>
                  </div>
                  <div class="col-md-3">
                      <label >Tax ID for this Arrangement:
                          #</label>
                  </div>
                  <div class="col-md-4">
                        <input   formControlName="cmd_33831"  class="form-control" type="text" id="cmd_33831" name="cmd_33831"
                        >
                  </div>
                </div>
              </td>
      </tr>
    </table>
    <br />
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Billing Address, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33832"  class="form-control   " id="cmd_33832" name="cmd_33832" type="text" >
      </div>
    </div>
    <div class="row mb-3"  >
      <div class="col-md-6">
          <label  > Telephone Number, if Different from Primary Site:
          </label>
      </div>
      <div class="col-md-4">
            <input   formControlName="cmd_33833"  class="form-control   " id="cmd_33833" name="cmd_33833" type="text" >
      </div>
    </div>
    <hr class="black-line">
    <table style=" border: 1px solid black;width:90%; margin-left: 5%;margin-top:20px;">
      <tr>
          <td colspan="12" class="table-backround">
              <div  class="text-center">
                  <label style="font-weight: normal;font-size: 14px;   padding-bottom: 2px;"><b>End Credentialing
                          and
                          Business Data Gathering Form.Attach Forms A-F As Required.</b></label>
              </div>
          </td>
      </tr>
    </table>
    <br />
    <hr
          class="pdf-line">
      <table style=" border: 1px solid black;width:90%; margin-left: 5%;margin-top:20px;">
          <tr>
              <td colspan="12" class="table-backround">
                  <div style="padding-top:10px; " class="text-center">
                      <label style="font-weight: normal;font-size: 14px;padding-bottom: 2px;"><b>FORM A – ADVERSE AND
                              OTHER
                              ACTIONS</b></label>
                  </div>
              </td>
          </tr>
      </table>
      <label><b>
              DUPLICATE this form as necessary to complete separate sheet for EACH occurrence that applies. Use
              reverse side
              of this form if additional space is needed.
          </b></label>
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33834"  class="form-control " id="cmd_33834" name="cmd_33834" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33835"  class="form-control" id="cmd_33835" name="cmd_33835" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33836"  class="form-control " id="cmd_33836" name="cmd_33836" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-8">
              <label  > Indicate the number of ONE of the questions in
                  Section J
                  to which you answered “yes”: Question Number:
              </label>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33837"  class="form-control " id="cmd_33837" name="cmd_33837" type="text">
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >A. Describe the circumstances surrounding this
                  occurrence. Please include the date of the occurrence.
              </label>
          </div>
          <div class="col-md-12" style="padding: 2px;padding-left:75px;">
              <textarea rows="8" formControlName="cmd_33838" cols="80" id="cmd_33838" name="cmd_33838"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >B. Provide an explanation of any actions taken.
                  Please
                  include the date the action was taken.
              </label>
          </div>
          <div class="col-md-12" style="padding: 2px;padding-left:75px;">
              <textarea rows="8" formControlName="cmd_33839" cols="80" id="cmd_33839" name="cmd_33839"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >C. Provide the current status of the issue.
              </label>
          </div>
          <div class="col-md-12" style="padding: 2px;padding-left:75px;">
              <textarea rows="8" cols="80" formControlName="cmd_33840" id="cmd_33840" name="cmd_33840"></textarea>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > D. If known: Contact:
              </label>
          </div>
          <div class="col-md-4">
                <input   formControlName="cmd_33841"  class="form-control   " id="cmd_33841" name="cmd_33841" type="text"
                   >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  > Department/Committee:
              </label>
          </div>
          <div class="col-md-4">
                <input   formControlName="cmd_33842"  class="form-control   " id="cmd_33842" name="cmd_33842" type="text"
                   >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  >Address
              </label>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33843"  class="form-control   " id="cmd_33843" name="cmd_33843" type="text" placeholder="address1"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33844"  class="form-control   " id="cmd_33844" name="cmd_33844" type="text" placeholder="address2"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33845"  class="form-control   " id="cmd_33845" name="cmd_33845" type="text" placeholder="city"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                      bindValue="id" formControlName="cmd_33846">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33847"  class="form-control   " id="cmd_33847" name="cmd_33847" type="text" placeholder="zip"
                   >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  >Telephone:
              </label>
          </div>
          <div class="col-md-4">
                <input   formControlName="cmd_33848"  class="form-control   " id="cmd_33848" name="cmd_33848" type="text"
                   >
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
                <img *ngIf="!show2" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                (click)="esignOpen(2)">
              <img *ngIf="show2" class="img eSignImageShow" [src]="esign2" alt="Credential my Doc"
                (click)="esignOpen(2)">
              <input type="hidden" name="cmd_9000351"  formControlName="cmd_9000351">
              </div>
          <div class="col-md-3">


          <div class="input-group">
            <input   formControlName="cmd_34105" (blur)="clearInputIfInvalid('cmd_34105')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
              #d97="ngbDatepicker" container="body">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d97.toggle()" type="button">
                  <i class="fas fa-calendar-alt"></i>
              </button>
          </div>
      </div>
      <div  *ngIf="erieForm1.get('cmd_34105').invalid ">
        <small class="text-danger" *ngIf="erieForm1.get('cmd_34105').errors?.invalidDateFormat">
          Only Date Format is allowed(MM/DD/YYYY)
        </small>
        <small class="text-danger" *ngIf="erieForm1.get('cmd_34105').errors?.invalidMonth">
        Month is out of range
        </small>
        <small class="text-danger" *ngIf="erieForm1.get('cmd_34105').errors?.invalidDateyear">
          year is out of range(1900-2099)                      </small>
          <small class="text-danger" *ngIf="erieForm1.get('cmd_34105').errors?.invalidDateRange">
            date is out of range
            </small>
        </div>

          </div>
      </div>
      <div class="row mb-3 text-center" >
          <div class="col-md-7" >
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  > Date</label>
          </div>
      </div>
      <br />


      <hr
          class="pdf-line">
      <table class="databox"
          >
          <tr>
              <td colspan="12" class="table-backround">
                  <div  class="text-center">
                      <label style="font-weight: normal;font-size: 14px;     padding-bottom: 2px;"><b>FORM B –
                              PROFESSIONAL
                              LIABILITY ACTIONS</b></label>
                  </div>
              </td>
          </tr>
      </table>
      <br />
      <label><b>
              DUPLICATE this form as necessary to complete a separate sheet for EACH action or allegation. Use reverse
              side of
              this form if additional space is needed.
          </b></label>
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33849"  class="form-control " id="cmd_33849" name="cmd_33849" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33850"  class="form-control" id="cmd_33850" name="cmd_33850" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33851"  class="form-control " id="cmd_33851" name="cmd_33852" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  >A. Plaintiff’s Name: </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="1205"  class="form-control " id="1205" name="1205" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6">
              <label  > If court case, Case Name & Case Number:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="1853"  class="form-control " id="1853" name="1853" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-5">
              <label  > B. Your Involvement in the Care (Attending,
                  Consulting,
                  Etc.):
              </label>
          </div>
          <div class="col-md-4">
                <input   formControlName="1218"  class="form-control "
                (ngModelChange)="handleInvolvementData($event)"
                id="1218" name="1218" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-12">
              <label  > C. Your Status in the Case (Sole Defendant,
                  Co-Defendant, Ownership Interest in Provider Practice Name in
              </label>
          </div>
          <div class="col-md-8">
                <input   formControlName="cmd_1218"  class="form-control " id="cmd_1218" name="cmd_1218" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6">
              <label  > D. Allegations, including Patient Outcome, if
                  Available:
              </label>
          </div>
          <div class="col-md-6">
                <input   formControlName="1216"  class="form-control " id="1216" name="1216" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-12">
                <input   formControlName="cmd_33857"  class="form-control " id="cmd_33857" name="cmd_33857" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > E. Date of Incident (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="1208"  (blur)="clearInputIfInvalid('1208')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d98="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d98.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('1208').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('1208').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1208').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1208').errors?.invalidDateyear">
                  year is out of range(1900-2099)                    </small>
                  <small class="text-danger" *ngIf="erieForm1.get('1208').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
          <div class="col-md-3">
              <label  > F. Date Filed (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="1209"  (blur)="clearInputIfInvalid('1209')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d99="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d99.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('1209').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('1209').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1209').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1209').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('1209').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > G. Date Case Closed (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
             <div class="input-group">
                    <input   formControlName="1224" (blur)="clearInputIfInvalid('1224')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d100="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d100.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('1224').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('1224').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1224').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('1224').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('1224').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-3">
              <label  > Resolution Case:
              </label>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33861"  type="checkbox" id="cmd_33861" name="cmd_33861" value="1">
              Dismissed
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33862"  type="checkbox" id="cmd_33862" name="cmd_33862" value="1">
              Judgment
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33863"  type="checkbox" id="cmd_33863" name="cmd_33863" value="1">
              Arbitration
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33864"  type="checkbox" id="cmd_33864" name="cmd_33864" value="1">
              Other
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-3">
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33865"  type="checkbox" id="cmd_33865" name="cmd_33865" value="1">
              Settlement out of Court
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33866"  type="checkbox" id="cmd_33866" name="cmd_33866" value="1">
              Pending
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33867"  type="checkbox" id="cmd_33867" name="cmd_33867" value="1">
              Mediation
          </div>
          <div class="col-md-2">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-4">
              <label  > H. Amount Paid on Your Behalf (if any): $
              </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="1229"  class="form-control " id="1229" name="1229" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6">
              <label  >I. Professional Liability Insurer Name (if one was
                  involved):
              </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="1210"  class="form-control " id="1210" name="1210" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > J. Insurer Telephone Number:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="1849"  class="form-control " id="1849" name="1849" type="text">
          </div>
          <div class="col-md-2">
              <label  > K. Policy Number:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="1859"  class="form-control " id="1859" name="1859" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-7">
              <label  > L. Insurer Address (Street, City, State, Zip Code):
              </label>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
                <input   formControlName="2012"  class="form-control   " id="2012" name="2012" type="text" placeholder="address1"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="1844"  class="form-control   " id="1844" name="1844" type="text" placeholder="address2"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="1845"  class="form-control   " id="1845" name="1845" type="text" placeholder="city"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                      bindValue="id" formControlName="cmd_33875">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2">
                <input   formControlName="1848"  class="form-control   " id="1848" name="1848" type="text" placeholder="zip"
                   >
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
                <img *ngIf="!show3" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                (click)="esignOpen(3)">
              <img *ngIf="show3" class="img eSignImageShow" [src]="esign3" alt="Credential my Doc"
                (click)="esignOpen(3)">
              <input type="hidden" name="cmd_9000352"  formControlName="cmd_9000352">
              </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="cmd_1012"  (blur)="clearInputIfInvalid('cmd_1012')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d101="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d101.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_1012').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1012').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1012').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1012').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_1012').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center">
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  > Date</label>
          </div>
      </div>
      <br />

      <hr
          class="pdf-line">
      <table class="databox"
          >
          <tr>
              <td colspan="12" class="table-backround">
                  <div  class="text-center">
                      <label style="font-weight: normal;font-size: 14px;padding-bottom: 2px;"><b> FORM C – LIABILITY
                              INSURANCE
                          </b></label>
                  </div>
              </td>
          </tr>
      </table>
      <br />
      <label><b>
              DUPLICATE this form as necessary to complete a separate sheet for EACH action or allegation. Use reverse
              side of
              this form if additional space is needed.
          </b></label>
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33877"  class="form-control " id="cmd_33877" name="cmd_33877" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33878"  class="form-control" id="cmd_33878" name="cmd_33878" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33879"  class="form-control " id="cmd_33879" name="cmd_33879" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-10">
              <label  > <b> A. History of Professional Liability Insurance
                      (Please
                      check One)
                  </b> </label>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-3">
              <label  > Resolution Case:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33880"  type="checkbox" id="cmd_33880" name="cmd_33880" value="1">
              Canceled Voluntarily
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33881"  type="checkbox" id="cmd_33881" name="cmd_33881" value="1">
              Non-Renewed
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-3">
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33882"  type="checkbox" id="cmd_33882" name="cmd_33882" value="1">
              Canceled Involuntarily
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33883"  type="checkbox" id="cmd_33883" name="cmd_33883" value="1">
              Application Denied
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  >
                  B. Carrier Name:
              </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="cmd_33884"  class="form-control " id="cmd_33884" name="cmd_33884" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > C. Carrier Telephone Number:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33885"  class="form-control " id="cmd_33885" name="cmd_33885" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  > D. Policy Number:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33886"  class="form-control " id="cmd_33886" name="cmd_33886" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-7">
              <label  >E. Carrier Address (Street, City, State, Zip Code):
              </label>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
                <input   formControlName="cmd_33887"  class="form-control   " id="cmd_33887" name="cmd_33887" type="text" placeholder="address1"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33888"  class="form-control   " id="cmd_33888" name="cmd_33888" type="text" placeholder="address2"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33889"  class="form-control   " id="cmd_33889" name="cmd_33889" type="text" placeholder="city"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                      bindValue="id" formControlName="cmd_33890">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33891"  class="form-control   " id="cmd_33891" name="cmd_33891" type="text" placeholder="zip"
                   >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > F. Dates of Coverage: From (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
             <div class="input-group">
                    <input   formControlName="cmd_33892" (blur)="clearInputIfInvalid('cmd_33892')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d102="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d102.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33892').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33892').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33892').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33892').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33892').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
          <div class="col-md-3">
              <label  >To (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
             <div class="input-group">
                    <input   formControlName="cmd_33893"  (blur)="clearInputIfInvalid('cmd_33893')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d103="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d103.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33893').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33893').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33893').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33893').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33893').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-4">
              <label  > G. Circumstances Involved:
              </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="cmd_33894"  class="form-control " id="cmd_33894" name="cmd_33894" type="text">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-12">
                <input   formControlName="cmd_33895"  class="form-control" id="cmd_33895" name="cmd_33895" type="text">
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
        <img *ngIf="!show4" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
        (click)="esignOpen(4)">
      <img *ngIf="show4" class="img eSignImageShow" [src]="esign4" alt="Credential my Doc"
        (click)="esignOpen(4)">
      <input type="hidden" name="cmd_9000352"  formControlName="cmd_9000353" >
              </div>
          <div class="col-md-3">
               <div class="input-group">
                    <input   formControlName="cmd_1013" (blur)="clearInputIfInvalid('cmd_1013')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d104="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d104.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_1013').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1013').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1013').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1013').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_1013').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7" >
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  > Date</label>
          </div>
      </div>
      <br />
      <br />

      <hr
          class="pdf-line">
      <table class="databox"
          >
          <tr>
              <td colspan="12" class="table-backround">
                  <div  class="text-center">
                      <label><b>FORM D – CRIMINAL
                              ACTIONS
                          </b></label>
                  </div>
              </td>
          </tr>
      </table>
      <br />
      <div class="col-md-12">
         <h6 class="text-center"><b> DUPLICATE this form as necessary to
                  complete
                  a separate sheet for EACH action or allegation. Use reverse side of this form if additional space is
                  needed.
              </b>
         </h6>
      </div>
      <br />
      <br />
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%;">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33896"  class="form-control " id="cmd_33896" name="cmd_33896" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33897"  class="form-control" id="cmd_33897" name="cmd_33897" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33898"  class="form-control " id="cmd_33898" name="cmd_33898" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > A. Date of Incident (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
             <div class="input-group">
                    <input   formControlName="cmd_33899"  (blur)="clearInputIfInvalid('cmd_33899')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d105="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d105.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33899').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33899').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33899').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33899').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33899').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > B. Date of Complaint or Conviction (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
             <div class="input-group">
                <input   formControlName="cmd_33900" (blur)="clearInputIfInvalid('cmd_33900')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                  #d154="ngbDatepicker" container="body">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d154.toggle()" type="button"><i
                          class="fas fa-calendar-alt"></i>
                  </button>
              </div>
          </div>
          <div  *ngIf="erieForm1.get('cmd_33900').invalid ">
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33900').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33900').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33900').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_33900').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
            </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  > C. Date of Resolution (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
              <div class="input-group">
                <input   formControlName="cmd_33901" (blur)="clearInputIfInvalid('cmd_33901')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                  #d153="ngbDatepicker" container="body">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d153.toggle()" type="button"><i
                          class="fas fa-calendar-alt"></i>
                  </button>
              </div>
          </div>
          <div  *ngIf="erieForm1.get('cmd_33901').invalid ">
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33901').errors?.invalidDateFormat">
              Only Date Format is allowed(MM/DD/YYYY)
            </small>
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33901').errors?.invalidMonth">
            Month is out of range
            </small>
            <small class="text-danger" *ngIf="erieForm1.get('cmd_33901').errors?.invalidDateyear">
              year is out of range(1900-2099)                      </small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_33901').errors?.invalidDateRange">
                date is out of range
                </small>
            </div>
            </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-5">
              <label  > D. Type of Resolution (Dismissed, Plea Bargain,
                  Misdemeanor, Felony):
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33902"  class="form-control " id="cmd_33902" name="cmd_33902" type="text">
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  E. Allegation(s):
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80" formControlName="cmd_33903" id="cmd_33903" name="cmd_33903"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  F. Details of Incident:
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33904" id="cmd_33904" name="cmd_33904"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  G. Actions Taken Against You:
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80" formControlName="cmd_33905"  id="cmd_33905" name="cmd_33905"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  H. Current Status of Situation:
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33906"  id="cmd_33906" name="cmd_33906"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-10">
              <label  >
                  I. Medical Practice Privileges Affected as a Result of This Situation:
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33907" id="cmd_33907" name="cmd_33907"></textarea>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
                <img *ngIf="!show5" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                (click)="esignOpen(5)">
              <img *ngIf="show5" class="img eSignImageShow" [src]="esign5" alt="Credential my Doc"
                (click)="esignOpen(5)">
              <input type="hidden" name="cmd_9000354"  formControlName="cmd_9000354">
              </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="cmd_1014" (blur)="clearInputIfInvalid('cmd_1014')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d106="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d106.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_1014').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1014').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1014').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1014').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_1014').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  > Date</label>
          </div>
      </div>
      <br />
      <br />
      <hr
          class="pdf-line">
      <table class="databox"
          >
          <tr>
              <td colspan="12" class="table-backround">
                  <div  class="text-center">
                      <label style="font-weight: normal;font-size: 14px;padding-bottom: 2px;"><b>FORM E – MEDICAL
                              CONDITION
                          </b></label>
                  </div>
              </td>
          </tr>
      </table>
      <br />
      <div class="col-md-10">
         <h6 class="text-center"><b> DUPLICATE this form as necessary to
                  complete
                  a separate sheet for EACH condition. Use reverse side of this form if additional space is needed.
              </b>
         </h6>
      </div>
      <br />
      <br />
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33908"  class="form-control " id="cmd_33908" name="cmd_33908" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33909"  class="form-control" id="cmd_33909" name="cmd_33909" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33910"  class="form-control " id="cmd_33910" name="cmd_33910" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  A. Describe this medical condition:
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8"  formControlName="cmd_33911"   cols="80" id="cmd_33911" name="cmd_33911"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-10">
              <label  >
                  B. To what extent does or could this condition affect your current ability to practice medicine in
                  your
                  specialty area or to perform a full range of clinical activities?
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33912"   id="cmd_33912" name="cmd_33912"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-12">
              <label  >
                  C. What is the current status of your condition?
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33913"  id="cmd_33913" name="cmd_33913"></textarea>
          </div>
      </div>
      <div class="row"  >
          <div class="col-md-10">
              <label  >
                  D. Provide the name and address of your personal physician/health care provider who can provide
                  information about your health condition.
              </label>
          </div>
          <div class="mb-3  " style="padding: 2px;padding-left:75px">
              <textarea rows="8" cols="80"  formControlName="cmd_33914"  id="cmd_33914" name="cmd_33914"></textarea>
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-9" >
              <label><b>Name</b></label>
          </div>
          <div class="col-md-3">
              <label  ><b>Telephone Number</b> </label>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
                <input   formControlName="cmd_33915"  class="form-control   " id="cmd_33915" name="cmd_33915" type="text" placeholder="Last Name"
                  >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33916"  class="form-control   " id="cmd_33916" name="cmd_33916" type="text"
                  placeholder="First Name"  >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33917"  class="form-control   " id="cmd_33917" name="cmd_33917" type="text" placeholder="MI"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="degree" bindLabel="name" placeholder="Select Degree"
                      bindValue="id" formControlName="cmd_33918">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2" style="margin-left: 70px">
                <input   formControlName="cmd_33919"  class="form-control   " id="cmd_33919" name="cmd_33919" type="text"
                  placeholder="phoneNumber" >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
                <input   formControlName="cmd_33920"  class="form-control   " id="cmd_33920" name="cmd_33920" type="text" placeholder="Last Name"
                  >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33921"  class="form-control   " id="cmd_33921" name="cmd_33921" type="text"
                  placeholder="First Name"  >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33922"  class="form-control   " id="cmd_33922" name="cmd_33922" type="text" placeholder="MI"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="degree" bindLabel="name" placeholder="Select degree"
                      bindValue="id" formControlName="cmd_33923">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2" style="margin-left: 70px">
                <input   formControlName="cmd_33924"  class="form-control   " id="cmd_33924" name="cmd_33924" type="text"
                  placeholder="phoneNumber" >
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
          <img *ngIf="!show6" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
          (click)="esignOpen(6)">
        <img *ngIf="show6" class="img eSignImageShow" [src]="esign6" alt="Credential my Doc"
          (click)="esignOpen(6)">
        <input type="hidden" name="cmd_9000355"  formControlName="cmd_9000355">
              </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="cmd_1015"  (blur)="clearInputIfInvalid('cmd_1015')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d107="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d107.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_1015').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1015').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1015').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_1015').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_1015').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3 text-center">
          <div class="col-md-7" >
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  >Date</label>
          </div>
      </div>
      <br />
      <br />
      <hr
          class="pdf-line">
      <table class="databox"
          >
          <tr>
              <td colspan="12" class="table-backround">
                  <div  class="text-center">
                      <label><b>FORM F – CHEMICAL
                              SUBSTANCES OR ALCOHOL ABUSE </b></label>
                  </div>
              </td>
          </tr>
      </table>
      <br />
      <div class="col-md-10">
         <h6 class="text-center"><b> DUPLICATE this form as necessary to
                  complete
                  a separate sheet for EACH chemical substance incident. Use reverse side of this form if additional
                  space is
                  needed.
              </b>
         </h6>
      </div>
      <br />
      <br />
      <div class="row mb-3" style="padding-left: 20px;padding-top: 2%">
          <div class="col-md-2">
              <label  >Applicant Name:</label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33925"  class="form-control " id="cmd_33925" name="cmd_33925" type="text" placeholder="Last Name"
                 >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33926"  class="form-control" id="cmd_33926" name="cmd_33926" type="text" placeholder="First Name"
                  >
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33927"  class="form-control " id="cmd_33927" name="cmd_33927" type="text" placeholder="MI"
                  >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-10">
              <label  >
                  Describe the substance you use:
              </label>
          </div>
          <div class="col-md-10">
                <input   formControlName="cmd_33928"  class="form-control " id="cmd_33928" name="cmd_33928" type="text">
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-10">
              <label  >
                  A. To what extent does, or could, your use of this substance affect your current ability to practice
                  medicine in your specialty area or to perform a full range of clinical activities?
              </label>
          </div>
          <div class="col-md-10 mb-3">
                <input   formControlName="cmd_33929"  class="form-control " id="cmd_33929" name="cmd_33929" type="text">
          </div>
          <div class="col-md-10">
                <input   formControlName="cmd_33930"  class="form-control " id="cmd_33930" name="cmd_33930" type="text">
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-6">
              <label  >
                  B. Monitored by State Board Mandate (Name and Address)
              </label>
          </div>
          <div class="col-md-6">
              <label  >
                  C. Monitored Voluntarily (Name and Address)
              </label>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6 mb-3">

                    <input   formControlName="cmd_33931"  class="form-control " id="cmd_33931" name="cmd_33931" type="text">

          </div>
          <div class="col-md-6">

                    <input   formControlName="cmd_33932"  class="form-control " id="cmd_33932" name="cmd_33932" type="text">

          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6">

                    <input   formControlName="cmd_33933"  class="form-control " id="cmd_33933" name="cmd_33933" type="text">

          </div>
          <div class="col-md-6">

                    <input   formControlName="cmd_33934"  class="form-control " id="cmd_33934" name="cmd_33934" type="text">

          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-6">

                    <input   formControlName="cmd_33935"  class="form-control " id="cmd_33935" name="cmd_33935" type="text">

          </div>
          <div class="col-md-6">

                    <input   formControlName="cmd_33936"  class="form-control " id="cmd_33936" name="cmd_33936" type="text">

          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-10">
              <label  >
                  D. Other information about the current status of your use of substances:
              </label>
          </div>
          <div class="col-md-10 mb-3">
                <input   formControlName="cmd_33937"  class="form-control " id="cmd_33937" name="cmd_33937" type="text">
          </div>
          <div class="col-md-10 mb-3">
                <input   formControlName="cmd_33938"  class="form-control " id="cmd_33938" name="cmd_33938" type="text">
          </div>
      </div>
      <br />
      <div class="row"  >
          <div class="col-md-3">
              <label  >
                  E. Abstinent since (mm/yy):
              </label>
          </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="cmd_33939"  (blur)="clearInputIfInvalid('cmd_33939')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d108="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d108.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33939').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33939').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33939').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33939').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33939').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <br />
      <div class="row"  >
          <div class="col-md-10">
              <label  >
                  F. Provide the name and address of your personal physician/health care provider who can provide
                  information about your treatment for alcohol or chemical substance use and can comment on what
                  impact
                  (if any) it has on your current/future professional practice.
              </label>
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  >
                  Name:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33940"  class="form-control " id="cmd_33940" name="cmd_33940" type="text">
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-1">
              <label  >Address:</label>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33941"  class="form-control   " id="cmd_33941" name="cmd_33941" type="text" placeholder="address1"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33942"  class="form-control   " id="cmd_33942" name="cmd_33942" type="text" placeholder="address2"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33943"  class="form-control   " id="cmd_33943" name="cmd_33943" type="text" placeholder="city"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                      bindValue="id" formControlName="cmd_33944">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33945"  class="form-control   " id="cmd_33945" name="cmd_33945" type="text" placeholder="zip"
                  >
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  >
                  Telephone:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33946"  class="form-control " id="cmd_33946" name="cmd_33946" type="text">
          </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-7 text-center" >
          <img *ngIf="!show7" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
          (click)="esignOpen(7)">
        <img *ngIf="show7" class="img eSignImageShow" [src]="esign7" alt="Credential my Doc"
          (click)="esignOpen(7)">
        <input type="hidden" name="cmd_9000356"  formControlName="cmd_9000356">
              </div>
          <div class="col-md-3">
              <div class="input-group">
                    <input   formControlName="cmd_10200" (blur)="clearInputIfInvalid('cmd_10200')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d109="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d109.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_10200').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_10200').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_10200').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_10200').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_10200').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <div class="row mb-3 text-center" >
          <div class="col-md-7" >
              <label>Applicant’s Signature</label>
          </div>
          <div class="col-md-4">
              <label  > Date</label>
          </div>
      </div>
      <br />
      <br />
      <hr
          class="pdf-line">

     <h6 class="text-center"><b>
              Consumer Authorization</b>
     </h6>
      <div class="mb-3  " style="padding: 30px">
          <p>
              <b>Authorization:</b>By signing below, you authorize: (a) General Information Services (“GIS”) to
              request information about you from any public or private information source; (b) anyone to provide
              information about you to GIS; (c) GIS to provide us ( Erie Family Health Center, Inc. ) one or more
              reports based on that information; and (d) us to share those reports with others for legitimate business
              purposes related to your employment. GIS may investigate your education, work history, professional
              licenses and credentials, references, address history, social security number validity, right to work,
              criminal record, lawsuits, driving record, credit history, and any other information with public or
              private information sources. You acknowledge that a fax, image, or copy of this authorization is as
              valid as the original. You make this authorization to be valid for as long as you are an applicant or
              employee with us.
          </p>
      </div>
      <div class="mb-3 d-flex flex-row align-items-center flex-wrap" style="padding: 30px">
          <label >
              <b>Personal Information:</b> Please print the information requested below to identify yourself for GIS.
          </label>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  >
                  Printed name:
              </label>
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33947"  class="form-control " id="cmd_33947" name="cmd_33947" type="text" placeholder="firstName">
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33948"  class="form-control " id="cmd_33948" name="cmd_33948" type="text" placeholder="Middle">
          </div>
          <div class="col-md-3">
                <input   formControlName="cmd_33949"  class="form-control " id="cmd_33949" name="cmd_33949" type="text" placeholder="lastName">
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-3">
              <label  >Other names used:
              </label>
          </div>
          <div class="col-md-5">
                <input   formControlName="cmd_33950"  class="form-control " id="cmd_33950" name="cmd_33950" type="text">
          </div>
      </div>
      <div class="mb-3  ">
          <label  style="font-weight: normal;font-size: 14px;margin-left: 15px;">Current and
              former addresses: </label>
      </div>
      <div class="row mb-3" style="padding-left: 20px;padding:30px;">
          <div class="col-md-2">
              <label  >from Mo/Yr:
              </label>
          </div>
          <div class="col-md-2">
             <div class="input-group">
                    <input   formControlName="cmd_33951"  (blur)="clearInputIfInvalid('cmd_33951')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d110="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d110.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33951').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33951').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33951').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33951').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33951').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
          <div class="col-md-1">
              <label  >to:
              </label>
          </div>
          <div class="col-md-2">
              <label  >Current
              </label>
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
          <div class="col-md-1">
              <label  >Address:</label>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33952"  class="form-control   " id="cmd_33952" name="cmd_33952" type="text" placeholder="address1"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_10202"  class="form-control   " id="cmd_10202" name="cmd_10202" type="text" placeholder="address2"
                   >
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33953"  class="form-control   "  name="cmd_33953" type="text" placeholder="city"
                   >
          </div>
          <div class="col-md-2">
              <div class="mb-3  "  >
                   <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                      bindValue="id" formControlName="cmd_33954">
                  </ng-select>
              </div>
          </div>
          <div class="col-md-2">
                <input   formControlName="cmd_33955"  class="form-control   " id="cmd_33955" name="cmd_33955" type="text" placeholder="zip"
                  >
          </div>
      </div>
      <div class="row mb-3"  >
          <div class="col-md-2">
              <label  > from Mo/Yr :
              </label>
          </div>
          <div class="col-md-2">
              <div class="input-group">
                    <input   formControlName="cmd_33956" (blur)="clearInputIfInvalid('cmd_33956')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d111="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d111.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33956').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33956').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33956').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33956').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33956').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
          <div class="col-md-1">
              <label  > to :
              </label>
          </div>
          <div class="col-md-2">
              <div class="input-group">
                    <input   formControlName="cmd_33957"  (blur)="clearInputIfInvalid('cmd_33957')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                      #d112="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d112.toggle()" type="button"><i
                              class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_33957').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33957').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33957').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33957').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33957').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
          </div>
      </div>
      <br />
      <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33958"  class="form-control   " id="cmd_33958" name="cmd_33958" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33959"  class="form-control   " id="cmd_33959" name="cmd_33959" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33960"  class="form-control   " id="cmd_33960" name="cmd_33960" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                    bindValue="id" formControlName="cmd_33961">
                </ng-select>
            </div>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33962"  class="form-control   " id="cmd_33962" name="cmd_33963" type="text" placeholder="zip"
                >
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-2">
            <label  > from Mo/Yr:
            </label>
        </div>
        <div class="col-md-2">
           <div class="input-group">
                  <input   formControlName="cmd_33964"  (blur)="clearInputIfInvalid('cmd_33964')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d113="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d113.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_33964').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33964').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33964').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33964').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33964').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
        <div class="col-md-1">
            <label  > to :
            </label>
        </div>
        <div class="col-md-2">
            <div class="input-group">
                  <input   formControlName="cmd_33965"  (blur)="clearInputIfInvalid('cmd_33965')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d114="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d114.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_33965').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33965').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33965').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33965').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33965').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
    </div>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33966"  class="form-control   " id="cmd_33966" name="cmd_33966" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33967"  class="form-control   " id="cmd_33967" name="cmd_33967" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33968"  class="form-control   " id="cmd_33968" name="cmd_33968" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
                 <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                    bindValue="id" formControlName="cmd_33969">
                </ng-select>
            </div>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33970"  class="form-control   " id="cmd_33970" name="cmd_33970" type="text" placeholder="zip"
                >
        </div>
    </div>
    <div class="mb-3  " style="padding: 30px">
        <label > Some
            government agencies and other information sources require the following information when checking for
            records. GIS will not use it for any other purposes.
        </label>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33971"  class="form-control   " id="cmd_33971" name="cmd_33971" type="text" placeholder="mm/dd/yyyy"
                 >
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33972"  class="form-control   " id="cmd_33972" name="cmd_33972" type="text" placeholder="SSN"
                 >
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
        </div>
        <div class="col-md-3">
            <label  >Date of birth </label>
        </div>
        <div class="col-md-3">
            <label  >Social security number</label>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33973"  class="form-control   " id="cmd_33973" name="cmd_33973" type="text"
              (ngModelChange)="handleLicenseNumber($event)" >
        </div>
        <div class="col-md-2">
            <div class="mb-3 "  >
     <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
    bindValue="id" formControlName="cmd_33974"
    (ngModelChange)="handleLicenseState($event)">
    </ng-select>
            </div>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33975"  class="form-control   " id="cmd_33975" name="cmd_33975" type="text"
                 >
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
        </div>
        <div class="col-md-3">
            <label  >Driver’s license number state Name as it appears on
                license</label>
        </div>
        <div class="col-md-2">
            <label  > state </label>
        </div>
        <div class="col-md-3">
            <label  > Name as it appears on license</label>
        </div>
    </div>
    <div class="mb-3  " style="padding: 30px">
        <label >
            <b>Report Copy:</b> If you are applying for a job or live in California, Minnesota, or Oklahoma, you may
            request a copy of the report by checking this box:
              <input   formControlName="cmd_33976"  type="checkbox" id="cmd_33976" name="cmd_33976" value="1">
        </label>
    </div>
    <div class="row mb-3">
        <div class="col-md-7 text-center" >
        <img *ngIf="!show8" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
        (click)="esignOpen(8)">
      <img *ngIf="show8" class="img eSignImageShow" [src]="esign8" alt="Credential my Doc"
        (click)="esignOpen(8)">
      <input type="hidden" name="cmd_9000357"  formControlName="cmd_9000357" >
            </div>
        <div class="col-md-3">
           <div class="input-group">
                  <input   formControlName="cmd_34112"  (blur)="clearInputIfInvalid('cmd_34112')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d115="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d115.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_34112').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34112').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34112').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34112').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34112').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
    </div>
    <div class="row mb-3 text-center">
        <div class="col-md-7" >
            <label>Applicant’s Signature</label>
        </div>
        <div class="col-md-4">
            <label  > Date</label>
        </div>
    </div>
    <br />

    <hr
        class="pdf-line">
   <h6 class="text-center"><b>
            Consumer Disclosure</b>
   </h6>
    <div class="mb-3  ">
        <p >
            We (Erie Family Health Center, Inc.) will obtain one or more consumer reports or investigative consumer
            reports (or both) about you for employment purposes. These purposes may include hiring, contract,
            assignment, promotion, re-assignment, and termination. The reports will include information about your
            character, general reputation, personal characteristics, and mode of living. </p>
    </div>
    <div class="mb-3  ">
        <p >
            We will obtain these reports through a consumer reporting agency. Our consumer reporting agency is
            General Information Services. GIS’s address is P.O. Box 353, Chapin, SC 29036. GIS’s telephone number is
            (866) 265-4917. GIS’s website is at www.geninfo.com, where you can find information about GIS’s
            international privacy practices. </p>
    </div>
    <div class="mb-3  ">
        <p >
            To prepare the reports, GIS may investigate your education, work history, professional licenses and
            credentials, references, address history, social security number validity, right to work, criminal
            record, lawsuits, driving record, credit history, and any other information with public or private
            information sources. </p>
    </div>
    <div class="mb-3  ">
        <p >
            You may obtain a copy of any report that GIS provides and GIS’s files about you (in person, by mail, or
            by phone) by providing identification to GIS. If you do, GIS will provide you help to understand the
            files, including trained personnel and an explanation of any codes. Another person may accompany you by
            providing identification. </p>
    </div>
    <div class="mb-3  " style="padding: 30px">
        <p >
            If GIS obtains any information by interview, you have the right to obtain a complete and accurate
            disclosure of the scope and nature of the investigation performed.
        </p>
    </div>
    <div class="mb-3  " style="padding: 30px">
        <p >
            Please sign below to acknowledge your receipt of this disclosure.
        </p>
    </div>
    <div class="row mb-3">
        <div class="col-md-7 text-center" >
    <img *ngIf="!show9" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
    (click)="esignOpen(9)">
    <img *ngIf="show9" class="img eSignImageShow" [src]="esign9" alt="Credential my Doc"
    (click)="esignOpen(9)">
    <input type="hidden" name="cmd_9000358"  formControlName="cmd_9000358" >
            </div>
        <div class="col-md-3">
            <div class="input-group">
                  <input   formControlName="cmd_34113"  (blur)="clearInputIfInvalid('cmd_34113')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d116="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d116.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_34113').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34113').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34113').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34113').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34113').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
    </div>
    <div class="row mb-3 text-center" >
        <div class="col-md-7" >
            <label>Applicant’s Signature</label>
        </div>
        <div class="col-md-4">
            <label  > Date</label>
        </div>
    </div>
    <br />
    <div class="row mb-3">
        <div class="col-md-3">
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_33977"  class="form-control   " id="cmd_33977" name="cmd_33977" type="text" >
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-3">
        </div>
        <div class="col-md-4">
            <label  > Printed Name</label>
        </div>
    </div>
    <hr
        class="pdf-line">
    <div class="text-center">
        <img src="../../../../assets/images/erielogo.png" width="350" height="210" alt="Credential my Doc">
    </div>
   <h6 class="text-center"><b>
            EMPLOYEE HEALTH ATTESTATION FORM </b>
   </h6>
    <br />
    <br />
    <div class="row mb-3">
        <div class="col-md-2">
            <label  > Practitioner Name</label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33978"  class="form-control   " id="cmd_33978" name="cmd_33978" type="text" >
        </div>
        <div class="col-md-2">
            <label  > Date </label>
        </div>
        <div class="col-md-3">
          <div class="input-group">
                  <input   formControlName="cmd_33979" (blur)="clearInputIfInvalidMandi('cmd_33979')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d117="ngbDatepicker" container="body"
                    [class.is-invalid]="(erieForm1.controls['cmd_33979'].touched && erieForm1.controls['cmd_33979'].errors)
                   || (erieForm1.controls['cmd_33979'].errors && isSubmit)">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d117.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>

            <div class="text-danger" *ngIf="(erieForm1.controls['cmd_33979'].invalid&&erieForm1.controls['cmd_33979'].touched) && (erieForm1.controls['cmd_33979'].value==''|| erieForm1.controls['cmd_33979'].value==null) " >
                <small >
                required
                </small>
              
              </div>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_33979').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)</small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_33979').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_33979').errors?.invalidDateyear">
                year is out of range(1900-2099)                  </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33979').errors?.invalidDateRange">
                  date is out of range
                  </small>

        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label  > Date of Birth </label>
        </div>
        <div class="col-md-3">
           <div class="input-group">
                  <input   formControlName="cmd_33980"   (blur)="clearInputIfInvalid('cmd_33980')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d118="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d118.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_33980').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33980').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33980').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33980').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33980').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-1">
            <label  > I, </label>
        </div>
        <div class="col-md-9">
              <input   formControlName="cmd_33981"  class="form-control   " id="cmd_33981" name="cmd_33981" type="text" >
        </div>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap ">
        <p>
            hereby attest that I am aware that the below mentioned records must be presented to Erie Family Health
            Center for credentialing andpre-employment checks. I am also aware that any vaccine/(s) that remain
            outstanding must be obtained on my first day of employment in accordance with Erie's employee health
            policy.I understand that any deviation from Employee Health requirements will be a direct violation of
            Employee Health and hiring policies and can be cause for immediate dismissal.
        </p>
    </div>
    <div class="mb-3 d-flex flex-row align-items-center flex-wrap pt-4 col-md-12">
        <h4 style="font-weight: normal;font-size: 14px;text-align:left;"><b>Required Immunizations: </b></h4>
    </div>
    <div>
        <ul style="font-weight: normal;font-size: 14px; margin: 0;padding: 0;padding-left: 20px;">
            <li>PPD or Quant gold (within the last 12 months)</li>
            <li>Proof of Hep. B vaccination or titer results</li>
            <li>Tdap is required (once in a lifetime)</li>
            <li>MMR is required</li>
            <li>Current seasonal flu vaccination</li>
        </ul>
    </div>
    <div class="row mb-3">
        <div class="col-md-5 text-center" style="margin-left: 5px;">
        <img *ngIf="!show10" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
        (click)="esignOpen(10)">
      <img *ngIf="show10" class="img eSignImageShow" [src]="esign10" alt="Credential my Doc"
        (click)="esignOpen(10)">
      <input type="hidden" name="cmd_9000359"  formControlName="cmd_9000359" >


  <div class="formLabel"
  *ngIf="(erieForm1.controls['cmd_9000359'].touched && erieForm1.controls['cmd_9000359'].errors)
   || (erieForm1.controls['cmd_9000359'].errors && isSubmit)">
  <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000359'].errors">
     required
  </small>
</div>
            </div>
        <div class="col-md-5 sidebox"
        >
            <p><b style="margin-left: -4px">PLEASE RETURN COMPLETED FORM AND COPIES OF HEALTH RECORDS TO:</b></p>
            <p>Erie Family Health Centers </p>
            <p><b>Attention: Credentialing Specialist </b></p>
            <p>1701 W. Superior Street </p>
            <p>Chicago, IL 60622 </p>
            <p>Email: credentialing@eriefamilyhealth.org </p>
            <p>Or </p>
            <p>Fax to (312) 432-4354 </p>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-5">
            <label  > Practitioner Signature </label>
        </div>
    </div>
    <hr
        class="pdf-line">
    <div class="text-center">
        <img src="../../../../assets/images/erielogo.png" width="350" height="210" alt="Credential my Doc">
    </div>
   <h6 class="text-center"><b>
            RELEASE & ATTESTATION </b>
   </h6>
    <br />
    <br />
    <div class="mb-3  ">
        <p  >
            In making medical application for membership to the medical staff, I hereby authorize Erie Family Health
            Centers and its credentialing verification organization, CHG Companies, Inc. (dba CompHealth
            Credentialing), its affiliates and successors, to obtain any information that may be relevant to an
            evaluation of my professional qualifications including information about disciplinary actions or other
            confidential or privileges information, and other credentials. </p>
    </div>
    <div class="mb-3  ">
        <p >
            I authorize the release of all information necessary from all medical schools, colleges, universities,
            transcript offices, medical institutions, or other organizations, hospital, employers, personal
            references, physicians, attorneys, medical malpractice carriers or organizations, business and
            professional associates, all government agencies and instrumentalities, the National Practitioner Data
            Bank, the Federation of State medical Boards, the American Medical Association, American Osteopathic
            Association, American Board of Medical Specialties, DEA, state licensing boards, specialty boards and
            any other pertinent source.</p>
    </div>
    <div class="mb-3  ">
        <p >
            I hereby indemnify and hold harmless Erie Family Health Centers and CHG Companies, Inc., their agents,
            officers and employees, as well as any third parties, including, but not limited to, the Federation of
            State Medical Boards and the others listed above, from any damages or liability, civil or otherwise,
            from any acts performed in good faith without malice and in connection with the collection and
            verification of such information.</p>
    </div>
    <div class="mb-3  ">
        <p >
            I understand that I have the burden of providing adequate information to Erie Family Health Centers and
            CHG Companies, Inc. its affiliates or successors, to demonstrate my qualifications. I understand that
            any misstatement in this form may constitute grounds for denial or summary dismissal as a participating
            provider. If any material changes occur affecting my professional status, it is my obligation to notify
            Erie Family Health Centers and CHG Companies, Inc., or the appropriate affiliate or successor as soon as
            possible.
        </p>
    </div>
    <div class="mb-3  ">
        <p >
            I attest that information that is contained in this application is correct and complete.
        </p>
    </div>
    <div class="mb-3  ">
        <label >
            A copy of this document shall operate as full proof of authority and release.
        </label>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-3">
            <label  >
                Name:
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33982"  class="form-control " id="cmd_33982" name="cmd_33982" type="text">
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-3">
            <label  >
                Social Security Number
            </label>
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33983"  class="form-control " id="cmd_33983" name="cmd_33983" type="text">
        </div>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-3">
            <label  >
                Date of Birth
            </label>
        </div>
        <div class="col-md-3">
           <div class="input-group">
                  <input   formControlName="cmd_33984"   (blur)="clearInputIfInvalid('cmd_33984')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d119="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d119.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_33984').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33984').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33984').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33984').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33984').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-7 text-center" >
    <img *ngIf="!show11" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
    (click)="esignOpen(11)">
    <img *ngIf="show11" class="img eSignImageShow" [src]="esign11" alt="Credential my Doc"
    (click)="esignOpen(11)">
    <input type="hidden" name="cmd_9000360"  formControlName="cmd_9000360">
    <div class="formLabel"
    *ngIf="(erieForm1.controls['cmd_9000360'].touched && erieForm1.controls['cmd_9000360'].errors)
     || (erieForm1.controls['cmd_9000360'].errors && isSubmit)">
    <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000360'].errors">
       required
    </small>
</div>
            </div>
        <div class="col-md-3">
          <div class="input-group">
                  <input   formControlName="cmd_34114" (blur)="clearInputIfInvalidMandi('cmd_34114')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d120="ngbDatepicker" container="body"
                    [class.is-invalid]="(erieForm1.controls['cmd_34114'].touched && erieForm1.controls['cmd_34114'].errors)
                    || (erieForm1.controls['cmd_34114'].errors && isSubmit)">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d120.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34114'].invalid&&erieForm1.controls['cmd_34114'].touched) && (erieForm1.controls['cmd_34114'].value==''|| erieForm1.controls['cmd_34114'].value==null) " >
                <small >
                required
                </small>
              
              </div>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_34114').errors?.invalidDateFormat">
                Only Date Format is allowed(MM/DD/YYYY)</small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_34114').errors?.invalidMonth">
              Month is out of range
              </small>
              <small class="text-danger" *ngIf="erieForm1.get('cmd_34114').errors?.invalidDateyear">
                year is out of range(1900-2099)                  </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_34114').errors?.invalidDateRange">
                  date is out of range
                  </small>
        </div>
    </div>
    <div class="row mb-3 text-center" >
        <div class="col-md-7 text-center" >
            <label>Applicant’s Signature</label>
        </div>
        <div class="col-md-4">
            <label  > Date</label>
        </div>
    </div>
    <br />
    <hr
        class="pdf-line">
   <h6 class="text-center">
        State of Illinois
   </h6>
   <h6 class="text-center"><b>
            AUTHORIZATION FOR BACKGROUND CHECK </b>
   </h6>
   <h6 class="text-center">
        Child Abuse and Neglect Tracking System (CANTS)
   </h6>
   <h6 class="text-center"><b>
            For Programs NOT Licensed by DCFS </b>
   </h6>
    <br />
    <br />
    <div class="mb-3  ">
        <label >
            <b>NOTE: Do not use this form if you are an applicant for licensure or an employee/volunteer of a
                licensed child care facility. Please contact your licensing representative. </b>
        </label>
    </div>
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >
                Name:
            </label>
        </div>
        <div class="col-md-5">
              <input   formControlName="cmd_33985"  class="form-control " id="cmd_33985" name="cmd_33985" type="text" placeholder="firstName">
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33986"  class="form-control " id="cmd_33986" name="cmd_33986" type="text" placeholder="Middle">
        </div>
        <div class="col-md-3">
              <input   formControlName="cmd_33987"  class="form-control " id="cmd_33987" name="cmd_33987" type="text" placeholder="lastName">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-2">
            <label>Date of Birth</label>
        </div>
        <div class="col-md-4">
           <div class="input-group">
                  <input   formControlName="cmd_33988"   (blur)="clearInputIfInvalid('cmd_33988')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d121="ngbDatepicker" container="body">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d121.toggle()" type="button"><i
                            class="fas fa-calendar-alt"></i>
                    </button>
                </div>
            </div>
            <div  *ngIf="erieForm1.get('cmd_33988').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33988').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33988').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_33988').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_33988').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
        </div>
        <div class="col-md-1">
            <label>Gender:</label>
        </div>

        <div class="col-md-3">
             <ng-select
            class="required" [items]="genderDropdownData" bindLabel="name" placeholder="Select gender" bindValue="id"
            required
            formControlName="cmd_340"
            >
               </ng-select>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-1">
            <label  >Race:</label>
        </div>
        <div class="col-md-6">
         <ng-select [items]="raceData" bindLabel="name" placeholder="Select Race"
        bindValue="id" formControlName="741"
        (ngModelChange)="handleRacechange($event)">
    </ng-select>
        </div>
    </div>
    <br />
    <div class="row mb-3"  >
        <div class="col-md-1">
            <label  >Address:</label>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33989"  class="form-control   " id="cmd_33989" name="cmd_33989" type="text" placeholder="address1"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33990"  class="form-control   " id="cmd_33990" name="cmd_33990" type="text" placeholder="address2"
                 >
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33991"  class="form-control   " id="cmd_33991" name="cmd_33991" type="text" placeholder="city"
                 >
        </div>
        <div class="col-md-2">
            <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_33992">
    </ng-select>
            </div>
        </div>
        <div class="col-md-2">
              <input   formControlName="cmd_33993"  class="form-control   " id="cmd_33993" name="cmd_33993" type="text" placeholder="zip"
                >
        </div>
    </div>
    <div class="mb-3  ">
        <label >
            If you currently reside in Illinois, please list all previous addresses for the past five years.
        </label>
    </div>
    <h6>OR</h6>
    <td colspan="18" width="80%">
        <div class="mb-3  ">
            <label >
                If you currently reside out-of-state, please provide ALL Illinois addresses in which you did reside
                while living in Illinois.
            </label>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-8">
                <label  >(Street/Apt#/City/County/State/Zip Code)
                </label>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-2">
                  <input   formControlName="cmd_33994"  class="form-control   " id="cmd_33994" name="cmd_33994" type="text"
                    placeholder="address1"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33995"  class="form-control   " id="cmd_33995" name="cmd_33995" type="text"
                    placeholder="address2"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33996"  class="form-control   " id="cmd_33996" name="cmd_33996" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2">
                <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_33997">
    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_33998"  class="form-control   " id="cmd_33998" name="cmd_33998" type="text" placeholder="zip"
                    >
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-1">
                <label  >From:</label>
            </div>
            <div class="col-md-2">
               <div class="input-group">
                      <input   formControlName="cmd_33999"  (blur)="clearInputIfInvalid('cmd_33999')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d122="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d122.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_33999').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_33999').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_33999').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_33999').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_33999').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34000"  (blur)="clearInputIfInvalid('cmd_34000')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d123="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d123.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34000').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34000').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34000').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34000').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34000').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-2">
                  <input   formControlName="cmd_34001"  class="form-control   " id="cmd_34001" name="cmd_34001" type="text"
                    placeholder="address1"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34002"  class="form-control   " id="cmd_34002" name="cmd_34002" type="text"
                    placeholder="address2"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34003"  class="form-control   " id="cmd_34003" name="cmd_34003" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2">
                <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_34004">
    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34005"  class="form-control   " id="cmd_34005" name="cmd_34005" type="text" placeholder="zip"
                    >
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-1">
                <label  >From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34006" (blur)="clearInputIfInvalid('cmd_34006')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d124="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d124.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34006').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34006').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34006').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34006').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34006').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34007"  (blur)="clearInputIfInvalid('cmd_34007')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d125="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d125.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34007').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34007').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34007').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34007').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34007').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-2">
                  <input   formControlName="cmd_34008"  class="form-control   " id="cmd_34008" name="cmd_34008" type="text"
                    placeholder="address1"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34009"  class="form-control   " id="cmd_34009" name="cmd_34009" type="text"
                    placeholder="address2"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34010"  class="form-control   " id="cmd_34010" name="cmd_34010" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2">
                <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_34011">
    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34012"  class="form-control   " id="cmd_34012" name="cmd_34012" type="text" placeholder="zip"
                    >
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-1">
                <label  >From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34013" (blur)="clearInputIfInvalid('cmd_34013')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d126="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d126.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34013').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34013').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34013').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34013').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34013').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34014" (blur)="clearInputIfInvalid('cmd_34014')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d127="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d127.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34014').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34014').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34014').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34014').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34014').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-2">
                  <input   formControlName="cmd_34015"  class="form-control   " id="cmd_34015" name="cmd_34015" type="text"
                    placeholder="address1"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34016"  class="form-control   " id="cmd_34016" name="cmd_34016" type="text"
                    placeholder="address2"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34017"  class="form-control   " id="cmd_34017" name="cmd_34017" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2">
                <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_34018">
    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34019"  class="form-control   " id="cmd_34019" name="9md_34019" type="text" placeholder="zip"
                    >
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-1">
                <label  >From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34020"  (blur)="clearInputIfInvalid('cmd_34020')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d128="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d128.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34020').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34020').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34020').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34020').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34020').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34021" (blur)="clearInputIfInvalid('cmd_34021')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d129="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d129.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34021').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34021').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34021').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34021').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34021').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-2">
                  <input   formControlName="cmd_34022"  class="form-control   " id="cmd_34022" name="cmd_34022" type="text"
                    placeholder="address1"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34023"  class="form-control   " id="cmd_34023" name="cmd_34023" type="text"
                    placeholder="address2"  >
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34024"  class="form-control   " id="cmd_34024" name="cmd_34024" type="text" placeholder="city"
                     >
            </div>
            <div class="col-md-2">
                <div class="mb-3  "  >
         <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
        bindValue="id" formControlName="cmd_34025">
    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                  <input   formControlName="cmd_34026"  class="form-control   " id="cmd_34026" name="cmd_34026" type="text" placeholder="zip"
                    >
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-1">
                <label  >From:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34027" (blur)="clearInputIfInvalid('cmd_34027')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d130="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d130.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34027').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34027').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34027').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34027').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34027').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
            <div class="col-md-1">
                <label  >To:</label>
            </div>
            <div class="col-md-2">
                <div class="input-group">
                      <input   formControlName="cmd_34028" (blur)="clearInputIfInvalid('cmd_34028')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d131="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d131.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34028').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34028').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34028').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34028').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34028').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-10">
                <label  >
                    List maiden name and/or all other names by which you have been known: (last, first, middle)
                </label>
            </div>
        </div>
        <div class="row mb-3"  >
            <div class="col-md-6">

                      <input   formControlName="cmd_34029"  class="form-control " id="cmd_34029" name="cmd_34029" type="text">

            </div>
            <div class="col-md-6">

                      <input   formControlName="cmd_34030"  class="form-control " id="cmd_34030" name="cmd_34030" type="text">

            </div>
        </div>
        <div class="row  mb-3"  >
            <div class="col-md-6">

                      <input   formControlName="cmd_34031"  class="form-control " id="cmd_34031" name="cmd_34031" type="text">

            </div>
            <div class="col-md-6">

                      <input   formControlName="cmd_34032"  class="form-control " id="cmd_34032" name="cmd_34032" type="text">

            </div>
        </div>
        <div class="row  mb-3"  >
            <div class="col-md-6">

                      <input   formControlName="cmd_34033"  class="form-control " id="cmd_34033" name="cmd_34033" type="text">

            </div>
            <div class="col-md-6">

                      <input   formControlName="cmd_34034"  class="form-control " id="cmd_34034" name="cmd_34034" type="text">

            </div>
        </div>
        <div class="row  mb-3"  >
            <div class="col-md-6">

                      <input   formControlName="cmd_34035"  class="form-control " id="cmd_34035" name="cmd_34035" type="text">

            </div>
            <div class="col-md-6">

                      <input   formControlName="cmd_34036"  class="form-control " id="cmd_34036" name="cmd_34036" type="text">

            </div>
        </div>
        <br />
        <div class="mb-3  ">
            <label >
                I hereby authorize the Illinois Department of Children and Family Services to conduct a search of
                the
                Child Abuse and Neglect Tracking system (CANTS) to determine whether I have been a perpetrator of an
                indicated incident of child abuse and/or neglect or involved in a pending investigation. I further
                consent to the release of this information to the agency listed below.
            </label>
        </div>
        <br />
        <br />
        <div class="row mb-3">
            <div class="col-md-5 text-center">
             <img *ngIf="!show12" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
             (click)="esignOpen(12)">
           <img *ngIf="show12" class="img eSignImageShow" [src]="esign12" alt="Credential my Doc"
             (click)="esignOpen(12)">
           <input type="hidden" name="cmd_9000361"  formControlName="cmd_9000361" >
                </div>
 <div class="col-md-6 sidebox">
                <p><b style="margin-left: -4px">Submit by mail OR fax OR email.</b></p>
                <p>Mail to: Department of Children and Family Services </p>
                <p>406 E. Monroe – Station # 30 </p>
                <p>Springfield, IL 62701 </p>
                <p>FAX to: 217-782-3991</p>
                <p>Scan/Email to: CFS689Background@illinois.gov </p>
            </div>
        </div>
         <div class="row mb-3">
             <div  class="col-md-3"> </div>
               <div class="col-md-3">
                <div class="input-group">
                      <input   formControlName="cmd_34115" (blur)="clearInputIfInvalid('cmd_34115')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d132="ngbDatepicker" container="body">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d132.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div  *ngIf="erieForm1.get('cmd_34115').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34115').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34115').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34115').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34115').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
            </div>
         </div>
        <div class="row mb-3">
            <div class="col-md-5">
                <label> Signed </label>
            </div>
            <div class="col-md-7">
                <label  >Date </label>
            </div>
        </div>
        <br />
        <br />
        <hr
            class="pdf-line">
            <img src="../../../../assets/images/impactlogo.png" width="750" height="210" alt="Credential my Doc">
           <h6 class="text-center"><b>
                    Electronic Signature Agreement</b>
           </h6>
            <table class="databox" >
                <tr>
                    <td colspan="12">
                        <div class="row"  >
                            <div class="col-md-5">
                                <label style="font-weight: normal;font-size: 18px;"><b>Employer or Employing Entity Name
                                    </b></label>
                            </div>
                            <div class="col-md-5">
                                <label style="font-weight: normal;font-size: 18px;"><b> Employer Identification Number
                                    </b></label>
                            </div>
                            <div class="col-md-2">
                                <label style="font-weight: normal;font-size: 18px;"><b> NPI </b></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row form=group"  >
                            <div class="col-md-5">
                                  <input   formControlName="cmd_34037"  class="form-control   " id="cmd_34037" name="cmd_34037" type="text"
                                    >
                            </div>
                            <div class="col-md-5">
                                  <input   formControlName="cmd_34038"  class="form-control   " id="cmd_34038" name="cmd_34038" type="text"
                                    >
                            </div>
                            <div class="col-md-2">
                                  <input   formControlName="cmd_34039"  class="form-control   " id="cmd_34039" name="cmd_34039" type="text"
                                    >
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <table class="databox" >
                <tr>
                    <td colspan="12">
                        <div class="row"  >
                            <div class="col-md-8 form=group">
                                <label style="font-weight: normal;font-size: 18px;"><b>Individual Name (Doctor, Dentist,
                                        Nurse,
                                        etc.) </b></label>
                            </div>
                            <div class="col-md-4">
                                <label style="font-weight: normal;font-size: 18px;"><b> NPI </b></label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <div class="row form=group"  >
                            <div class="col-md-8">
                                  <input   formControlName="cmd_34040"  class="form-control   " id="cmd_34040" name="cmd_34040" type="text"
                                    >
                            </div>
                            <div class="col-md-4">
                                  <input   formControlName="cmd_34041"  class="form-control  " id="cmd_34041" name="cmd_34041" type="text"
                                    >
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <div class="mb-3  ">
                <label >
                    The undersigned Individual and Employing Entity attest that they have entered into an agreement
                    effective on the date indicated below. Both parties agree an authorized representative of the
                    Employing
                    Entity has the authority to sign and submit the electronic Illinois Department of Healthcare and
                    Family
                    Services Medical Assistance Provider Enrollment Trading Partner Agreement and to maintain enrollment
                    information through the HFS IMPACT Provider Enrollment Subsystem.
                </label>
            </div>
        </td>
        <table class="databox" >
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-8">
                            <label style="font-weight: normal;font-size: 18px;"><b>Individual Signature </b></label>
                        </div>
                        <div class="col-md-4">
                            <label style="font-weight: normal;font-size: 18px;"><b> Date </b></label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-7" style="margin-left: 5px;">
             <img *ngIf="!show13" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
             (click)="esignOpen(13)">
           <img *ngIf="show13" class="img eSignImageShow" [src]="esign13" alt="Credential my Doc"
             (click)="esignOpen(13)">
           <input type="hidden" name="cmd_9000362"  formControlName="cmd_9000362">

           <div class="formLabel"
           *ngIf="(erieForm1.controls['cmd_9000362'].touched && erieForm1.controls['cmd_9000362'].errors)
            || (erieForm1.controls['cmd_9000362'].errors && isSubmit)">
           <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000362'].errors">
              required
           </small>
       </div>
        </div>
                        <div class="col-md-4" >
                            <div class="input-group">
                                  <input   formControlName="cmd_34042" (blur)="clearInputIfInvalid('cmd_34042')"  class="form-control"    placeholder="mm/dd/yyyy"
                                    ngbDatepicker #d133="ngbDatepicker" container="body">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d133.toggle()"
                                        type="button"><i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <div  *ngIf="erieForm1.get('cmd_34042').invalid ">
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34042').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34042').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34042').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34042').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <br />
        <table class="databox" >
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-8">
                            <label style="font-weight: normal;font-size: 18px;"><b>Employing Entity Signature
                                </b></label>
                        </div>
                        <div class="col-md-4">
                            <label style="font-weight: normal;font-size: 18px;"><b> Date </b></label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-7 " style="margin-left: 5px;">
               <img *ngIf="!show14" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
               (click)="esignOpen(14)">
             <img *ngIf="show14" class="img eSignImageShow" [src]="esign14" alt="Credential my Doc"
               (click)="esignOpen(14)">
             <input type="hidden" name="cmd_9000363"  formControlName="cmd_9000363" >
                            </div>
                        <div class="col-md-4" >
                            <div class="input-group">
                                  <input   formControlName="cmd_34043" (blur)="clearInputIfInvalid('cmd_34043')"   class="form-control"    placeholder="mm/dd/yyyy"
                                    ngbDatepicker #d134="ngbDatepicker" container="body">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d134.toggle()"
                                        type="button"><i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <div  *ngIf="erieForm1.get('cmd_34043').invalid ">
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34043').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34043').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34043').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34043').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <br />
        <table  class="databox" >
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-8">
                            <label style="font-weight: normal;font-size: 18px;"><b>Individual Single Sign-on User ID
                                </b></label>
                        </div>
                        <div class="col-md-4">
                            <label style="font-weight: normal;font-size: 18px;"><b> Date </b></label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="12">
                    <div class="row"  >
                        <div class="col-md-7" style="margin-left: 5px;">
                       <img *ngIf="!show15" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                       (click)="esignOpen(15)">
                     <img *ngIf="show15" class="img eSignImageShow" [src]="esign15" alt="Credential my Doc"
                       (click)="esignOpen(15)">
                     <input type="hidden" name="cmd_9000364"  formControlName="cmd_9000364">
                            </div>
                        <div class="col-md-4" >
                            <div class="input-group">
                                  <input   formControlName="cmd_34044"  (blur)="clearInputIfInvalid('cmd_34044')"  class="form-control"    placeholder="mm/dd/yyyy"
                                    ngbDatepicker #d135="ngbDatepicker" container="body">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d135.toggle()"
                                        type="button"><i class="fas fa-calendar-alt"></i>
                                    </button>
                                </div>
                            </div>
                            <div  *ngIf="erieForm1.get('cmd_34044').invalid ">
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34044').errors?.invalidDateFormat">
                                  Only Date Format is allowed(MM/DD/YYYY)
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34044').errors?.invalidMonth">
                                Month is out of range
                                </small>
                                <small class="text-danger" *ngIf="erieForm1.get('cmd_34044').errors?.invalidDateyear">
                                  year is out of range(1900-2099)                      </small>
                                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34044').errors?.invalidDateRange">
                                    date is out of range
                                    </small>
                                </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <br />
        <hr
            class="pdf-line">
        <table class="databox"
            >
            <tr>
                <td colspan="12" class="table-backround">
                    <div  class="text-center p-2">
                        <label><b>AFFIRMATION OF
                                INFORMATION</b></label>
                    </div>
                </td>
            </tr>
        </table>
        <br />
        <div class="mb-3  ">
            <p >
                I represent and warrant that all of the information provided and the responses given are correct and
                complete to the best of my knowledge and belief. I understand that falsification or omission of
                information may be grounds for rejection or termination, in addition to any penalties provided by law. I
                further agree to promptly inform all entities to which this form was sent and not rejected of any change
                required to be updated by the Health Care Professional Credentialing and Business Data Gathering Update
                Form. </p> </div>
        <div class="mb-3  ">
            <p >
                I understand that this application does not entitle me to participation in any hospital, health care
                entity, or health plan.</p> </div>
        <div class="row mb-3">
            <div class="col-md-5 text-center" >
      <img *ngIf="!show16" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
      (click)="esignOpen(16)">
      <img *ngIf="show16" class="img eSignImageShow" [src]="esign16" alt="Credential my Doc"
      (click)="esignOpen(16)">
      <input type="hidden" name="cmd_9000365"  formControlName="cmd_9000365" >
      <div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_9000365'].touched && erieForm1.controls['cmd_9000365'].errors)
       || (erieForm1.controls['cmd_9000365'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000365'].errors">
         required
      </small>
  </div>
    </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_34045"  class="form-control   " id="cmd_34045" name="cmd_34045" type="text" >
            </div>
            <div class="col-md-3">
                <div class="input-group">
                    <input   formControlName="cmd_3404611" (blur)="clearInputIfInvalid('cmd_3404611')"   class="form-control"    placeholder="mm/dd/yyyy"
                      ngbDatepicker #d136="ngbDatepicker" container="body">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d136.toggle()"
                          type="button"><i class="fas fa-calendar-alt"></i>
                      </button>
                  </div>
              </div>
              <div  *ngIf="erieForm1.get('cmd_3404611').invalid ">
                <small class="text-danger" *ngIf="erieForm1.get('cmd_3404611').errors?.invalidDateFormat">
                  Only Date Format is allowed(MM/DD/YYYY)
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_3404611').errors?.invalidMonth">
                Month is out of range
                </small>
                <small class="text-danger" *ngIf="erieForm1.get('cmd_3404611').errors?.invalidDateyear">
                  year is out of range(1900-2099)                      </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_3404611').errors?.invalidDateRange">
                    date is out of range
                    </small>
                </div>
            </div>
        </div>
        <div class="row mb-3 text-center">
            <div class="col-md-5" >
                <label>Applicant’s Signature</label>
            </div>
            <div class="col-md-3">
                <label  > Printed Name</label>
            </div>
            <div class="col-md-3">
                <label  > Date</label>
            </div>
        </div>
        <br />
        <div class="col-md-12">
           <h6 class="text-center"><b>** PLEASE BE ADVISED THAT EACH
                    HOSPITAL,
                    HEALTH CARE ENTITY, **</b>
           </h6>
           <h6 class="text-center"><b>** AND HEALTH CARE PLAN MAY ALSO
                    REQUIRE
                    COMPLETION OF AN **</b>
           </h6>
           <h6 class="text-center"> <b>** ATTESTATION AND RELEASE OF
                    INFORMATION
                    FORM. ** </b>
           </h6>
        </div>
        <hr
            class="pdf-line">
       <h6 class="text-center"><b>
                Standard Authorization, Attestation and Release</b>
       </h6>
        <div class="mb-3  ">
            <p >
                I understand and agree that, as part of the credentialing application process for participation,
                membership and/or clinical privileges (hereinafter, referred to as "Participation") at or with each
                healthcare organization indicated on the "List of Authorized Organizations" that accompanies this
                Provider Application (hereinafter,each healthcare organization on the "List of Authorized Organizations"
                is individually referred to as the "Entity"), and any of the Entity's affiliated entities, I am required
                to provide sufficient and accurate information for a proper evaluation of my current licensure, relevant
                training and/or experience, clinical competence, health status, character, ethics, and any other
                criteria used by the Entity for determining initial and ongoing eligibility for Participation. Each
                Entity and its representatives, employ -ees, and agent(s) acknowledge that the information obtained
                relating to the application process will be held confidential to the extent permitted by law. </p>
        </div>
        <div class="mb-3  ">
            <p >
                I acknowledge that each Entity has its own criteria for acceptance, and I may be accepted or rejected by
                each independently. I further acknowledge and understand that my cooperation in obtaining information
                and my consent to the release of information do not guarantee that any Entity will grant me clinical
                privileges or contract with me as a provider of services. I understand that my application for
                Participation with the Entity is not an application for employment with the Entity and that acceptance
                of my application by the Entity will not result in my employment by the Entity. </p>
        </div>
        <div class="mb-3  ">
            <p >
                <b>Authorization of Investigation Concerning Application for Participation. </b>I authorize the
                following individuals including, without limitation, the Entity, its representa- tives, employees,
                and/or designated agent(s); the Entity's affiliated entities and their representatives, employees,
                and/or designated agents; and the Entity's designat- ed professional credentials verification
                organization (collectively referred to as "Agents"), to investigate information, which includes both
                oral and written statements, records, and documents, concerning my application for Participation. I
                agree to allow the Entity and/or its Agent(s) to inspect and copy all records and documents relating to
                such an investigation. </p> </div>
        <div class="mb-3  ">
            <p >
                <b>Authorization of Third-Party Sources to Release Information Concerning Application for
                    Participation.</b> I authorize any third party, including, but not limited to, individuals,
                agencies, medical groups responsible for credentials verification, corporations, companies, employers,
                former employers, hospitals, health plans, health maintenance organizations, managed care organizations,
                law enforcement or licensing agencies, insurance companies, educational and other institutions, military
                services, medical credentialing and accreditation agencies, professional medical societies, the
                Federation of State Medical Boards, the National Practitioner Data Bank, and the Health Care Integrity
                and Protection Data Bank, to release to the Entity and/or its Agent(s), information, including otherwise
                privileged or confidential information, concerning my professional qualifications, credentials, clinical
                competence, quality assurance and utilization data, character, mental condition, physical condition,
                alcohol or chemical dependency diagnosis and treatment, ethics, behavior, or any other matter reasonably
                having a bearing on my qualifications for Participation in, or with, the Entity. I authorize my current
                and past professional liability carrier(s) to release my history of claims that have been made and/or
                are cur- rently pending against me. I specifically waive written notice from any entities and
                individuals who provide information based upon this Authorization, Attestation and Release. </p>
        </div>
        <div class="mb-3  ">
            <p >
                <b>Authorization of Third-Party Sources to Release Information Concerning Application for
                    Participation.</b> I authorize any third party, including, but not limited to, individuals,
                agencies, medical groups responsible for credentials verification, corporations, companies, employers,
                former employers, hospitals, health plans, health maintenance organizations, managed care organizations,
                law enforcement or licensing agencies, insurance companies, educational and other institutions, military
                services, medical credentialing and accreditation agencies, professional medical societies, the
                Federation of State Medical Boards, the National Practitioner Data Bank, and the Health Care Integrity
                and Protection Data Bank, to release to the Entity and/or its Agent(s), information, including otherwise
                privileged or confidential information, concerning my professional qualifications, credentials, clinical
                competence, quality assurance and utilization data, character, mental condition, physical condition,
                alcohol or chemical dependency diagnosis and treatment, ethics, behavior, or any other matter reasonably
                having a bearing on my qualifications for Participation in, or with, the Entity. I authorize my current
                and past professional liability carrier(s) to release my history of claims that have been made and/or
                are cur- rently pending against me. I specifically waive written notice from any entities and
                individuals who provide information based upon this Authorization, Attestation and Release.</p>
        </div>
        <div class="mb-3  ">
            <p >
                <b>Authorization of Release and Exchange of Disciplinary Information.</b> I hereby further authorize any
                third party at which I currently have Participation or had Participation and/or each third party's
                agents to release "Disciplinary Information," as defined below, to the Entity and/or its Agent(s). I
                hereby further authorize the Agent(s) to release Disciplinary Information about any disciplinary action
                taken against me to its participating Entities at which I have Participation, and as may be otherwise
                required by law. As used herein, "Disciplinary Information" means information concerning (i) any action
                taken by such health care organizations, their administrators, or their medical or other committees to
                revoke, deny, suspend, restrict, or condition my Participation or impose a corrective action plan; (ii)
                any other disciplinary action involving me, including, but not limited to, discipline in the employment
                context; or (iii) my resignation prior to the conclusion of any disciplinary pro - ceedings or prior to
                the commencement of formal charges, but after I have knowledge that such formal charges were being (or
                are being) contemplated and/or were (or are) in preparation.
            </p>
        </div>
        <div class="mb-3  ">
            <p >
                <b>Release from Liability. </b>I release from all liability and hold harmless any Entity, its Agent(s),
                and any other third party for their acts performed in good faith and with- out malice unless such acts
                are due to the gross negligence or willful misconduct of the Entity, its Agent(s), or other third party
                in connection with the gathering, release and exchange of, and reliance upon, information used in
                accordance with this Authorization, Attestation and Release. I further agree not to sue any Entity, any
                Agent(s), or any other third party for their acts, defamation or any other claims based on statements
                made in good faith and without malice or misconduct of such Entity, Agent(s) or third party in
                connection with the credentialing process. This release shall be in addition to, and in no way shall
                limit, any other applicable immuni-ties provided by law for peer review and credentialing activities. In
                this Authorization, Attestation and Release, all references to the Entity, its Agent(s), and/or other
                third party include their respective employees, directors, officers, advisors, counsel, and agents. The
                Entity or any of its affiliates or agents retains the right to allow access to the application
                information for purposes of a credentialing audit to customers and/or their auditors to the extent
                required in connection with an audit of the credentialing processes and provided that the customer
                and/or their auditor executes an appropriate confidentiality agreement. I understand and agree that this
                Authorization, Attestation and Release is irrevocable for any period during which I am an applicant for
                Participation at an Entity, a member of an Entity's medical or health care staff, or a participating
                provider of an Entity. I agree to execute another form of consent if law or regulation limits the
                application of this irrevocable authori- zation. I understand that my failure to promptly provide
                another consent may be grounds for termination or discipline by the Entity in accordance with the
                applicable bylaws, rules, and regulations, and requirements of the Entity, or grounds for my termination
                of Participation at or with the Entity. I agree that information obtained in accordance with the
                provisions of this Authorization, Attestation and Release is not and will not be a violation of my
                privacy. </p> </div>
        <div class="mb-3  ">
            <p >
                I certify that all information provided by me in my application is current, true, correct, accurate and
                complete to the best of my knowledge and belief, and is furnished in good faith. I will notify the
                Entity and/or its Agent(s) within 10 days of any material changes to the information (including any
                changes/challenges to licenses, DEA, insurance, malpractice claims, NPDB/HIPDB reports, discipline,
                criminal convictions, etc.) I have provided in my application or authorized to be released pursuant to
                the credentialing process. I understand that corrections to the application are permitted at any time
                prior to a determination of Participation by the Entity, and must be submitted online or in writing, and
                must be dated and signed by me (may be a written or an electronic signature). I acknowledge that the
                Entity will not process an application until they deem it to be a complete application and that I am
                responsible to provide a complete application and to produce adequate and timely informa-tion for
                resolving questions that arise in the application process. I understand and agree that any material
                misstatement or omission in the application may constitute grounds for withdrawal of the application
                from consideration; denial or revocation of Participation; and/or immediate suspension or termination of
                Participation. Thisaction may be disclosed to the Entity and/or its Agent(s). I further acknowledge that
                I have read and understand the foregoing Authorization, Attestation and Release and that I have access
                to the bylaws of applicable medical staff organizations and agree to abide by these bylaws, rules and
                regulations. I understand and agree that a facsimile or photocopy of this Authorization, Attestation and
                Release shall be as effective as the original. </p></div>
        <div class="row mb-3">
            <div class="col-md-5 text-center" >
             <img *ngIf="!show17" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
             (click)="esignOpen(17)">
           <img *ngIf="show17" class="img eSignImageShow" [src]="esign17" alt="Credential my Doc"
             (click)="esignOpen(17)">
           <input type="hidden" name="cmd_9000366"  formControlName="cmd_9000366">
           <div class="formLabel"
           *ngIf="(erieForm1.controls['cmd_9000366'].touched && erieForm1.controls['cmd_9000366'].errors)
            || (erieForm1.controls['cmd_9000366'].errors && isSubmit)">
           <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000366'].errors">
              required
           </small>
       </div>
        </div>
            <div class="col-md-3">
                  <input   formControlName="cmd_34046"  class="form-control   " id="cmd_34046" name="cmd_34046" type="text" >
            </div>
            <div class="col-md-3">
                <div class="input-group">
                      <input   formControlName="cmd_34047"  (blur)="clearInputIfInvalidMandi('cmd_34047')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d137="ngbDatepicker" container="body"
                        [class.is-invalid]="(erieForm1.controls['cmd_34047'].touched && erieForm1.controls['cmd_34047'].errors)
                        || (erieForm1.controls['cmd_34047'].errors && isSubmit)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d137.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34047'].invalid&&erieForm1.controls['cmd_34047'].touched) && (erieForm1.controls['cmd_34047'].value==''|| erieForm1.controls['cmd_34047'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34047').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34047').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34047').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34047').errors?.invalidDateRange">
                      date is out of range
                      </small>
            </div>
        </div>
        <div class="row mb-3 text-center" >
            <div class="col-md-5" >
                <label>Applicant’s Signature</label>
            </div>
            <div class="col-md-3">
                <label  > Printed Name</label>
            </div>
            <div class="col-md-3">
                <label  > Date</label>
            </div>
        </div>
        <br />
        <hr
            class="pdf-line">
       <h6 class="text-center"><b>
                ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
       </h6>
       <h6 class="text-center"><b>
                Lead Agency/Provider Contract for</b>
       </h6>
       <h6 class="text-center"><b>
                Erie Family Health Center </b>
       </h6>
       <div class="row mb-3">
        <div class="col-md-3">
            <label  > This update by and between </label>
        </div>
        <div class="col-md-5">
              <input   formControlName="cmd_34048"  class="form-control   " id="cmd_34048" name="cmd_34048" type="text" >
        </div>
        <div class="col-md-4">
            <label  > here in after referred to as </label>
        </div>
    </div>
        <div class="mb-3  ">
            <label >
                the (medical) Provider, and Erie Family Health Center, hereinafter referred to as the Lead Agency sets
                forth updates to the above-referenced department contract under which the Provider will administer
                provider services for the Illinois Breast and Cervical Cancer Program; and
            </label>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <label  >WHEREAS, the effective date of this amendment begins on
                </label>
            </div>
            <div class="col-md-4">
                  <input   formControlName="cmd_34049"  class="form-control   " id="cmd_34049" name="cmd_34049" type="text" >
            </div>
            <div class="col-md-2">
                <label  >and will </label>
            </div>
        </div>

        <div class="mb-3  ">
            <label >
                WHEREAS, the CPT codes rates have bee updated since last year) Please see attached updated CPT code
                rates);
            </label> </div>
        <div class="mb-3  ">
            <label >
                WHEREAS, all other forms and conditions of the original contract remain in place; and
            </label>
        </div>
        <div class="mb-3  ">
            <label >
                THEREFORE, the Provider’s designated signature below signifies that the Provider accepts this amendment
                as written, and that all other terms and conditions of the original IBCCP contract shall remain as
                stated therein.
            </label> </div>
            <div class="row mb-3">
                <div class="col-md-6 text-center">
                    <label  >FOR THE AGENCY:</label>
                </div>
                <div class="col-md-6 text-center">
                    <label  > FOR THE PROVIDER:</label>
                </div>
            </div>

        <div class="row mb-3">
            <div class="col-md-6 text-center" >
             <img *ngIf="!show18" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
             (click)="esignOpen(18)">
           <img *ngIf="show18" class="img eSignImageShow" [src]="esign18" alt="Credential my Doc"
             (click)="esignOpen(18)">
           <input type="hidden" name="cmd_9000367"  formControlName="cmd_9000367">
                </div>
            <div class="col-md-6 text-center" >
            <img *ngIf="!show19" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
            (click)="esignOpen(19)">
          <img *ngIf="show19" class="img eSignImageShow" [src]="esign19" alt="Credential my Doc"
            (click)="esignOpen(19)">
          <input type="hidden" name="cmd_9000368"  formControlName="cmd_9000368">
          <div class="formLabel"
                    *ngIf="(erieForm1.controls['cmd_9000368'].touched && erieForm1.controls['cmd_9000368'].errors)
                     || (erieForm1.controls['cmd_9000368'].errors && isSubmit)">
                    <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000368'].errors">
                       required
                    </small>
                </div>
        </div>
        </div>
        <div class="row mb-3 text-center">
            <div class="col-md-6 text-center" >
                <label>Erie Family Health Center</label>
            </div>
            <div class="col-md-6 text-center" >
                <label>Designated Signatory</label>
            </div>
        </div>
        <br />
        <div class="row mb-3 text-center" >
            <div class="col-md-2" >
            </div>
            <div class="col-md-3" >
                <div class="input-group">
                    <input   formControlName="cmd_34050" (blur)="clearInputIfInvalidMandi('cmd_34050')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                    #d138="ngbDatepicker" container="body"
                    [class.is-invalid]="(erieForm1.controls['cmd_34050'].touched && erieForm1.controls['cmd_34050'].errors)
                    || (erieForm1.controls['cmd_34050'].errors && isSubmit)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d138.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34050'].invalid&&erieForm1.controls['cmd_34050'].touched) && (erieForm1.controls['cmd_34050'].value==''|| erieForm1.controls['cmd_34050'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34050').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34050').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34050').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34050').errors?.invalidDateRange">
                      date is out of range
                      </small>
            </div>
            <div class="col-md-3" >
            </div>
            <div class="col-md-3" >
                <div class="input-group">
                      <input   formControlName="cmd_34051" (blur)="clearInputIfInvalidMandi('cmd_34051')"   class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                        #d139="ngbDatepicker" container="body"
                        [class.is-invalid]="(erieForm1.controls['cmd_34051'].touched && erieForm1.controls['cmd_34051'].errors)
                        || (erieForm1.controls['cmd_34051'].errors && isSubmit)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d139.toggle()" type="button"><i
                                class="fas fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34051'].invalid&&erieForm1.controls['cmd_34051'].touched) && (erieForm1.controls['cmd_34051'].value==''|| erieForm1.controls['cmd_34051'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34051').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34051').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34051').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34051').errors?.invalidDateRange">
                      date is out of range
                      </small>

            </div>
        </div>
        <div class="row mb-3 text-center" >
            <div class="col-md-2" >
            </div>
            <div class="col-md-3" >
                <label style="font-weight: normal;font-size: 14px; padding-left:76px;">Date</label>
            </div>
            <div class="col-md-3" >
            </div>
            <div class="col-md-3" >
                <label style="font-weight: normal;font-size: 14px; padding-left:76px;">Date</label>
            </div>
        </div>

        <hr
            class="pdf-line">
       <h6 class="text-center"><b>
                ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
       </h6>
       <h6 class="text-center"><b>
                Lead Agency/Provider Contract for</b>
       </h6>
       <h6 class="text-center"><b>
                Erie Family Health Center </b>
       </h6>
       <div class="row mb-3">
        <div class="col-md-4">
            <label  >This Agreement, effective as of
            </label>
        </div>
        <div class="col-md-4">
              <input   formControlName="cmd_34052"  class="form-control   " id="cmd_34052" name="cmd_34052" type="text" >
        </div>
        <div class="col-md-4">
            <label  >is made by and between </label>
        </div>
       </div>
       <div class="row">
        <div class="col-md-5">
            <input   formControlName="cmd_34053"  class="form-control   " id="cmd_34053" name="cmd_34053" type="text" >
      </div>
      <div class="col-md-7">
          <label  >here in after referred to as the (medical) Provider, and
          </label>
      </div>
       </div>

        <div class="mb-3  ">
            <label >
                Erie Family Health Center, hereinafter referred to as the Lead Agency, and
            </label> </div>
        <div class="mb-3  ">
            <label >
                WHEREAS, Erie Family Health Center has a Lead Agency subcontract with the Illinois Department of Public
                Health through the Illinois Breast and Cervical Cancer Project (IBCCP) to provide breast and cervical
                cancer screening services to women who are uninsured, regardless of income; AND
            </label>
        </div>
        <div class="mb-3  ">
            <label >
                WHEREAS, the Lead Agency, in accordance with the philosophy and guidelines established by the Illinois
                Department of Public Health (IDPH) and the Center for Disease Control (CDC), seeks to develop a
                comprehensive community support system to assist women in reducing breast and cervical cancer mortality
                by increasing the availability of cancer screening; AND
            </label></div>
        <div class="mb-3  ">
            <label >
                WHEREAS, the Lead Agency wishes to provide case management, tracking and follow-up for enrolled clients
                and the Provider wishes to provide breast and cervical cancer screening services; AND
            </label>
        </div>
        <div class="mb-3  ">
            <label >
                WHEREAS, the target group for cervical screening is women age 21 to 64 for Pap smears and women age 40
                and older for mammography screening;
            </label>
        </div>
        <div class="mb-3  ">
            <label >
                NOW THEREFORE, in consideration of these premises and of the mutual promises contained herein, the
                parties agree as follows:
            </label>
        </div>
        <b>A. GENERAL ASSURANCES</b>
        <td colspan="18" width="80%">
            <div class="mb-3  ">
                <label >
                    The Lead Agency and the Provider shall provide services to eligible clients in accordance with the
                    Illinois Breast and Cervical Cancer Project (IBCCP) as described in Attachment A, General Provider
                    Assurances. </label>
            </div>
            <b>B. PROVIDER SERVICES</b>
            <label>
                The Provider shall provide the breast and cervical cancer screening services, as applicable, for clients
                as follows:
            </label>
            <ol   type="1">
                <li>
                    Screening for breast cancer shall include a clinical breast examination, (CBE) and mammography if
                    age 40 or over
                    or, if not elevated risk, at age 50 or over as per the USPSTF after shared decision making
                    conversation between
                    the patient and healthcare provider.
                </li>
                <li>
                    The cervical cancer screening shall include a complete visual and bimanual pelvic examination and
                    Pap test.
                </li>
                <li>
                    A measurement of blood pressure shall be done during CBE visits.
                </li>
                <li>
                    Mammograms will be performed for screening and/or diagnostic purposes.
                </li>
                <li>
                    Appropriate and timely clinic services will be available to each woman enrolled in the IBCCP
                    program.
                </li>
            </ol>
            <b>C. REPORTING AND RECORDS MAINTENANCE</b>
            <ol   type="1">
                <li>
                    Only provide services to clients eligible for IBCCP based on income verification and services
                    provided,
                    determined by the front desk staff.
                </li>
                <li>
                    The Provider shall ensure that medical records are confidential and are not released without written
                    consent
                    from the client. The Lead Agency will maintain a signed Release of Information form for each client.
                </li>
                <li>
                    The Provider shall ensure that the required screening and follow-up data forms are returned to the
                    Lead Agency
                    per Illinois Department of Public Health (IDPH) and Centers for Disease Control (CDC) requirements
                    for this
                    program. Payment will not be made to the provider until all reports are obtained.
                </li>
                <li>
                    The Lead Agency will maintain complete records of all the services, receipts, and disbursements
                    relative to the
                    Illinois Breast and Cervical Cancer Project (IBCCP).
                </li>
                <hr
                    class="pdf-line">
               <h6 class="text-center"><b>
                        ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
               </h6>
               <h6 class="text-center"><b>
                        Lead Agency/Provider Contract for</b>
               </h6>
               <h6 class="text-center"><b>
                        Erie Family Health Center </b>
               </h6>
                <li>
                    The Provider shall assure that essential elements for the IBCCP grant will be reported to the Lead
                    Agency per
                    (IDPH) and (CDC) requirements for this project.
                </li>
                <li>
                    The Provider agrees that it will comply with the purpose and standards recommended by the Joint
                    Commission and
                    the American Medical Association or American Osteopathic Association as appropriate.
                </li>
            </ol>
            <b>D. LEAD AGENCY SERVICES</b>
            <ol   type="1">
                <li>
                    The Lead Agency will provide case management consistent with reporting and follow up guidelines as
                    defined by
                    this contract, including tracking, follow-up and education services for all clients.
                </li>
                <li>
                    The Lead Agency will determine eligibility for screening services and complete all enrollment
                    requirements prior
                    to referring the recipient for services with an appropriate voucher.
                </li>
                <li>
                    The Lead Agency will provide information, education and referral to Providers contracted with the
                    breast and
                    cervical cancer program.
                </li>
                <li>
                    The Lead Agency will bear the sole responsibility for reporting all program transactions to the IDPH
                    through the
                    Cornerstone computer system.
                </li>
            </ol>
            <b>E. Billing</b>
            <ol   type="1">
                <li>
                    The Provider shall bill for clinical services for only those clients who have been enrolled in the
                    IBCCP by the
                    Lead Agency and that present an appropriate voucher for said service. The Provider will bill the
                    Lead Agency
                    within 90 days of the service date. The bill from the Provider shall include either the current
                    IBCCP rates
                    (enhanced Medicare rates) as listed on the Provider Payment Form or list the Provider’s standard
                    charges with
                    CPT codes as applicable. The dates of services must be provided, along with the total amount claimed
                    for
                    reimbursement. The Lead Agency will reimburse the Provider within 45 days of receipt of the bill for
                    services.
                </li>
                <li>
                    The Illinois Breast and Cervical Cancer Project (IBCCP) provides payment of the deductible/co-
                    payment for
                    clients who are on Medicare or have an insurance plan that does not cover screening services, or has
                    a
                    deductible/co-payment. The maximum amount paid for any service is listed on the Provider Payment
                    Form in
                    Attachment B.
                </li>
                <li>
                    The Provider will be notified by the Lead Agency of authorized changes to the rates listed in
                    Attachment B and
                    the effective dates of such changes. </li>
                <li>
                    IBCCP is the payor of last resort. The maximum amount paid for any service is listed in Allowable
                    CPT Codes
                    (Attachment B) for the Illinois Breast & Cervical Cancer Program.
                    <ol   type="a">
                        <li>
                            Women enrolled in Medicare, who do not have Medicare Part B, may be eligible for screening
                            services.
                            This program may pay any remaining Medicaid spend-down amounts, not to exceed the cost
                            allowed for
                            actual screening services covered by this grant agreement.
                        </li>
                    </ol>
                </li>
            </ol>
            <b>F. STANDARD AGREEMENTS:</b>
            <ol   type="1">
                <li>
                    <b>TERM:</b> The term of this Agreement shall commence on date mentioned in effective date, and
                    continue until
                    one or both parties, terminate in writing, and give 30 day notice.
                </li>
                <li>
                    <b>TERMINATION:</b> Either party may terminate this agreement without cause by giving the other
                    party at least
                    thirty (30) days prior written notice. In addition, either party may terminate this agreement upon
                    breach by the
                    other party of any material provision of this agreement, provided such breach continues for fifteen
                    (15) days
                    after receipt by the breaching party of written notice of such breach from the non-breaching party.
                </li>
                <li>
                    <b>EFFECT OF TERMINATION:</b> As of the effective date of termination of this agreement, neither
                    party shall
                    have any rights or obligations hereunder except for rights and obligations accruing prior to such
                    effective date
                    of termination, or arising as a result of any breach of this agreement, including an obligation by
                    the Provider
                    to continue to treat, but only for a period of time not to exceed twelve (12) days, any client who,
                    at the time
                    of termination, is undergoing treatment by the Provider.
                </li>
            </ol>
            <b>G. GENERAL PROVISION:</b>
            <hr
                class="pdf-line">
           <h6 class="text-center"><b>
                    ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
           </h6>
           <h6 class="text-center"><b>
                    Lead Agency/Provider Contract for</b>
           </h6>
           <h6 class="text-center"><b>
                    Erie Family Health Center </b>
           </h6>

            <ol   type="1">
                <li>
                    <b>INDEPENDENT CONTRACTORS:</b> None of the provisions of this Agreement is intended to create nor
                    shall they be
                    deemed or construed by the parties to create any relationship between the parties hereto other than
                    that of
                    independent entities contracting with each other.
                </li>
                <li>
                    <b> LEGISLATIVE LIMITATIONS:</b> Notwithstanding any other provision of this agreement, if the
                    governmental
                    agencies (or their representatives) which administer Medicare, any other payer, or any other
                    federal, state or
                    local government or agency passes, issues or promulgates any law, rule, regulation, standard or
                    inter-precaution
                    at any time while this agreement is in effect which prohibits, restricts, limits or in any way
                    substantially
                    changes the methods or amount of reimbursement or payment for services rendered under this
                    Agreement, or which
                    otherwise significantly affects either party’s rights or obligations hereunder, either party may
                    give the other
                    notice of intent to amend this agreement to the satisfaction of both parties, to compensate for such
                    prohibition, restriction, limitation or change. If this agreement is not so amended in writing
                    within thirty
                    (30) days after said notice was given, this agreement shall terminate as of midnight on the
                    thirtieth (30th) day
                    after said notice was given, unless parties agree that they do not desire such termination.
                <li>
                    <b>ENTIRE AMMENDMENT MODIFICATION:</b> This agreement contains the entire understanding of the
                    parties with
                    respect to the subject matter hereof and supersedes all prior agreements, oral or written and all
                    other
                    communications between the parties relating to the subject matter. This agreement may not be amended
                    or modified
                    except by mutual written agreement.
                </li>
                <li>
                    <b>COMPLIANCE WITH LAW:</b> Each party agrees to comply with all applicable state and federal law
                    including, but
                    not limited to, the Illinois Mental Health and Developmental Disabilities Code (Ill. Rev. State.
                    Chi. 91 ½ par.
                    1-100, et seq.), as may be amended from time to time.
                </li>
                <li>
                    <b>GOVERNING LAW:</b> This Agreement shall be construed in accordance with the laws of the State of
                    Illinois.
                </li>

                <li>
                    <b>COUNTERPARTS:</b> This Agreement may be executed in one or more counterparts, all of which
                    together shall
                    constitute only one Agreement. </li>
                <li>
                    <b>PARTIAL INVALIDITY:</b> In the event that any provision of this Agreement is invalid or
                    unenforceable, such
                    invalid or unenforceable provision shall not invalidate or affect the other provisions of this
                    Agreement which
                    shall remain in effect and be construed as if such provision were not a part hereof, provided that
                    if the
                    invalidation or unenforceability of such provision shall, in the opinion of either party’s rights or
                    obligations
                    under this Agreement, then the Agreement may be terminated by such party upon thirty (30) days
                    written notice by
                    such party to the other party. </li>
                <li>
                    <b>NOTICE:</b>All notices hereunder by either party to the other shall be in writing, delivered
                    personally, by
                    certified or registered mail, return receipt requested, or by Federal Express or Express receipt
                    requested, or
                    by Federal Express or Express Mail, and shall be deemed to have been duly given when delivered
                    personally or
                    when deposited in the United States mails, postage prepaid, address as follows, or to such other
                    persons or
                    places as either party may from time to time designate by written notice to the other.
                </li>
                <b>If to the Provider:</b>
                <br />
                <b>If to the Lead Agency: </b> IBCCP Program Coordinator
                <br />
                <label style="margin-left:150px;"> Erie Family Health Center</label>
                <li>
                    <b>WAIVER:</b> A waiver by either party of a breach or failure to perform hereunder shall not
                    constitute a
                    waiver of any subsequent breach or failure.
                </li>
                <li>
                    <b>CAPTIONS:</b> The captions contained herein are used solely for convenience and shall not be
                    deemed to define
                    or limit the provisions of this Agreement.
                </li>
                <hr
                    class="pdf-line">
               <h6 class="text-center"><b>
                        ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
               </h6>
               <h6 class="text-center"><b>
                        Lead Agency/Provider Contract for</b>
               </h6>
               <h6 class="text-center"><b>
                        Erie Family Health Center </b>
               </h6>
                <li>
                    <b>ASSIGNMENT BINDING EFFECT:</b> The Lead Agency shall not assign or transfer, in whole or in part,
                    this
                    agreement or any of the Lead Agency’s rights, duties or obligations under this agreement without the
                    prior
                    written consent of the Provider except that Lead Agency may assign this contract to a subsidiary or
                    to an
                    affiliated entity under common control. This agreement shall insure to the benefit of and be binding
                    upon the
                    parties hereto and their respective heirs, representative, successors and permitted assigns.
                </li>
                <li>
                    <b>INSURANCE:</b> Provider shall maintain general and professional liability insurance coverage on
                    an occurrence
                    basis through the FTCA or another commercially reasonable professional liability insurance program
                    with minimum
                    limits of $1,000,000 per occurrence and $3,000,000 in the aggregate. Provider shall provide to Lead
                    Agency a
                    certificate of insurance evidencing that such coverage is in effect during the term of this
                    Agreement.
                </li>
                <li>
                    EXCLUSION FROM STATE AND FEDERAL HEALTH CARE PROGRAMS: Provider represents and warrants to Lead
                    Agency that
                    Provider and its owners and employees are not excluded from participation in any federal health care
                    programs,
                    as defined under 42. U.S.C.
                    §1230a-7b (f), or any form of state Medicaid program, and to Provider’s knowledge, there are no
                    pending or
                    threatened governmental investigations that may lead to such exclusion. Provider agrees to notify
                    Lead Agency of
                    the commencement of any such exclusion or investigation within seven (7) business days of Provider’s
                    first
                    learning of it. Lead Agency shall have the right to immediately terminate this Agreement upon
                    learning of any
                    such exclusion and shall be kept apprised by Provider of the status of any such investigation.
                </li>
            </ol>
            <div class="mb-3 row ">
                <p >
                    IN WITNESS WHEREOF, the parties hereto have executed this agreement as of the day and year first
                    written
                    above. </p>
            </div>
            <br />
            <div class="row"  >
                <div class="col-md-4 text-center">
                 <img *ngIf="!show20" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
                 (click)="esignOpen(20)">
               <img *ngIf="show20" class="img eSignImageShow" [src]="esign20" alt="Credential my Doc"
                 (click)="esignOpen(20)">
               <input type="hidden" name="cmd_9000369"  formControlName="cmd_9000369">
                    </div>
                <div class="col-md-2 pt-4">
                    <div class="input-group">
                          <input   formControlName="cmd_34054"  (blur)="clearInputIfInvalid('cmd_34054')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d140="ngbDatepicker" container="body">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d140.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div  *ngIf="erieForm1.get('cmd_34054').invalid ">
                        <small class="text-danger" *ngIf="erieForm1.get('cmd_34054').errors?.invalidDateFormat">
                          Only Date Format is allowed(MM/DD/YYYY)
                        </small>
                        <small class="text-danger" *ngIf="erieForm1.get('cmd_34054').errors?.invalidMonth">
                        Month is out of range
                        </small>
                        <small class="text-danger" *ngIf="erieForm1.get('cmd_34054').errors?.invalidDateyear">
                          year is out of range(1900-2099)                      </small>
                          <small class="text-danger" *ngIf="erieForm1.get('cmd_34054').errors?.invalidDateRange">
                            date is out of range
                            </small>
                        </div>
                </div>
                <div class="col-md-4 text-center">
         <img *ngIf="!show21" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
         (click)="esignOpen(21)">
       <img *ngIf="show21" class="img eSignImageShow" [src]="esign21" alt="Credential my Doc"
         (click)="esignOpen(21)">
       <input type="hidden" name="cmd_9000370"  formControlName="cmd_9000370">

    <div class="formLabel"
    *ngIf="(erieForm1.controls['cmd_9000370'].touched && erieForm1.controls['cmd_9000370'].errors)
     || (erieForm1.controls['cmd_9000370'].errors && isSubmit)">
    <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000370'].errors">
       required
    </small>
  </div>
    </div>
                <div class="col-md-2 pt-4">
                    <div class="input-group">
                          <input   formControlName="cmd_34055" (blur)="clearInputIfInvalidMandi('cmd_34055')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                            #d141="ngbDatepicker" container="body"
                            [class.is-invalid]="(erieForm1.controls['cmd_34055'].touched && erieForm1.controls['cmd_34055'].errors)
                            || (erieForm1.controls['cmd_34055'].errors && isSubmit)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d141.toggle()" type="button"><i
                                    class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                        <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34055'].invalid&&erieForm1.controls['cmd_34055'].touched) && (erieForm1.controls['cmd_34055'].value==''|| erieForm1.controls['cmd_34055'].value==null) " >
                            <small >
                            required
                            </small>
                          
                          </div>
                          <small class="text-danger" *ngIf="erieForm1.get('cmd_34055').errors?.invalidDateFormat">
                            Only Date Format is allowed(MM/DD/YYYY)</small>
                          <small class="text-danger" *ngIf="erieForm1.get('cmd_34055').errors?.invalidMonth">
                          Month is out of range
                          </small>
                          <small class="text-danger" *ngIf="erieForm1.get('cmd_34055').errors?.invalidDateyear">
                            year is out of range(1900-2099)                  </small>
                            <small class="text-danger" *ngIf="erieForm1.get('cmd_34055').errors?.invalidDateRange">
                              date is out of range
                              </small>

                    </div>
                </div>
            </div>
            <div class="row"  >
                <div class="col-md-3" style="margin-left: 8rem;margin-top: 2rem;">
                    <label>
                        Signature
                    </label>
                </div>
                <div class="col-md-2" style="margin-top: 2rem;">
                    <label>
                        Date
                    </label>
                </div>
                <div class="col-md-3" style="margin-top: 2rem;">
                    <label style="margin-left: 59px;">
                        Signature
                    </label>
                </div>
                <div class="col-md-2">
                    <label style="margin-top: 19px;margin-left: 80px;">
                        Date
                    </label>
                </div>
            </div>
            <div class="row"  >
                <div class="col-md-4">
                      <input   formControlName="cmd_34056"  class="form-control   " id="cmd_34056" name="cmd_34056" type="text" >
                      <label>
                        Print Name
                    </label>
                    </div>
                <div class="col-md-4">
                </div>
                <div class="col-md-4">

                      <input   formControlName="cmd_10203"  class="form-control   " id="cmd_10203" name="cmd_10203" type="text" >
                      <label>
                        Print Name
                    </label>
                    </div>
            </div>

            <div class="row"  >
                <div class="col-md-4">
                      <input   formControlName="cmd_34057"  class="form-control"  name="cmd_34057" type="text" >
                      <label>
                        Title
                    </label>
                    </div>
                <div class="col-md-4">
                </div>
                <div class="col-md-4">
                      <input   formControlName="cmd_34058"  class="form-control"  name="cmd_34058" type="text" >
                      <label>
                        Title
                    </label>
                    </div>
            </div>

             <hr
             class="pdf-line">
        <h6 class="text-center"><b>
                 ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
        </h6>
        <h6 class="text-center"><b>
                 Lead Agency/Provider Contract for</b>
        </h6>
        <h6 class="text-center"><b>
                 Erie Family Health Center </b>
        </h6>
        <h6 class="text-center pt-4">  <u >ATTACHMENT A: GENERAL PROVIDER ASSURANCES</u></h6>
         <ol class="pt-4"   type="1">
             <li>
                 All contracts and subcontracts must be on file with the Lead Agency and the Illinois Department of
                 Public Health
                 (IDPH) before reimbursement of the Provider will be approved. Reimbursement for services will be
                 provided upon
                 receipt, by the Lead Agency, of all required client data forms.
             </li>
             <li>
                 The Provider must agree that no “balance billing” will be allowed for Recipients and that the
                 maximum rates for
                 reimbursement will be the rates established annually under Medicare Part B. Balance billing means
                 that if a
                 screening service costs more than the Medicare rate, the Provider cannot charge the balance to
                 another source of
                 funding to make up the difference.
             </li>
             <li>
                 For those services reimbursed by the Lead Agency through the Illinois Breast and Cervical Cancer
                 Project, no
                 sliding fee, deductible, administrative fee nor co-pay will be charged to clients seen through the
                 project. This
                 project is considered to be the payer of the last resort.
             </li>
             <li>
                 No project funds will be spent for treatment services (though funds may be used for screening and
                 diagnostic
                 services) for women who have breast cancer or cervical cancer, but women with cancer in remission
                 may be
                 screened. Payment for treatment services must come from private sources; community based sources,
                 other
                 government programs or is provided through the provider’s charity program. </li>
             <li>
                 For cervical cytology and Pap testing, reimbursement will be allowed for a maximum of no more than
                 three (3)
                 specimens per client, per year, breast specimens are limited to three (3) per breast. </li>
             <li>
                 The Provider must have a current valid license to practice in Illinois, with no disciplinary actions
                 being taken
                 against the Provider at the time of contracting with this project.
             </li>
             <li>
                 The Provider assures the facility conducting breast and cervical cancer screening funded by the
                 IBCCP has Food
                 and Drug Administration (FDA) certification or Clinical Laboratories Improvement Act (CLIA)
                 certification.
             </li>
             <li>
                 The Provider assures the facility conducting Mammography is fully certified by the FDA under the
                 Mammography
                 Quality Standards Act of 1992 (MQSA). The Provider shall use the languages from the American College
                 of
                 Radiology (ACR) Breast Imaging Reporting and Data System (BIRADS) method for reporting the
                 interpretation of
                 mammography examinations. Reading and reporting of mammograms shall be done according to the
                 American College of
                 Radiology (ACR) Lexicon system. All cytology labs used must be approved according to the CLIA.
                 Documentation of
                 CLIA certification must be submitted to the Agency before services are provided.
             </li>
             <li>
                 The Provider must report Pap test results using the Bethesda System. Results other than those within
               normal
                 limits (WNL) require repeating the Pap test or further diagnostic work-up.
             </li>
             <li>
                 Cervical examinations, and Papanicolau (Pap) tests and breast examinations may be provided by MDs,
                 DOs, Nurse
                 Practitioners, Certified Nurse Midwives, Clinical Nurse Specialists and Physician Assistants.
             </li>
             <li>
                 The Provider of clinical services must observe Standard Precautions during physical exams, and
                 specimen
                 collection and preparation.
             </li>
             <hr
             class="pdf-line">
        <h6 class="text-center"><b>
                 ILLINOIS BREAST AND CERVICAL CANCER PROGRAM</b>
        </h6>
        <h6 class="text-center"><b>
                 Lead Agency/Provider Contract for</b>
        </h6>
        <h6 class="text-center"><b>
                 Erie Family Health Center </b>
        </h6>
         <li>
             The Provider agrees to maintain documentation of services in the Provider’s records in the format
             that is used
             for all other patients. All client information sheets must be returned to the Lead Agency before
             reimbursement
             can be sent.
         </li>
         <li>
             The Provider will make a reasonable effort to notify clients by phone or in writing within two(2)
             weeks of the
             results of all pap tests and mammograms regardless of outcome. </li>
         <li>
             The Provider shall refer women with abnormal screening results and/or other identified medical
             problems back to
             the Lead Agency. The Lead Agency will communicate with the Provider in order to obtain appropriate
             treatment and
             follow-up within the existing framework of the program if services are to be reimbursed through the
             program. For
             services not covered by the program, the Lead Agency will ask the provider to provide appropriate
             referral for
             treatment and follow-up services for the client.
         </li>
         <li>
             The Provider should have a commitment and historical ability to serve economically disadvantaged and
             indigent
             women, and ability to network with other community organizations in the area to recruit and refer
             for treatment
             economically disadvantaged women.
         </li>
         <li>
             The Provider must agree to prepare timely reports for submission to the Lead agency, including
             reports,
             follow-up and re-screening at appropriate levels.
         </li>
         <br />
         <label class="ms-2"> END</label>
     </ol>
             <hr
             class="pdf-line">
         <img src="../../../../assets/images/mbi.png" width="350" height="210" alt="Credential my Doc"
             style="margin-left: 25rem;height:fit-content;">
        <h6 class="text-center">
             DISCLOSURE AND AUTHORIZATION
        </h6>
        <h6 class="text-center">[IMPORTANT -- PLEASE READ CAREFULLY
             BEFORE SIGNING
             AUTHORIZATION]
        </h6>
         <h6 class="text-center"><u>DISCLOSURE REGARDING BACKGROUND
                 INVESTIGATION</u>
         </h6>
         <br />
         <br />
         <p >
             Erie Family Health may obtain information about you from a third party consumer reporting agency for
             employment
             purposes. Thus, you may be the subject of a “consumer report” which may include information about your
             character,
             general reputation, personal characteristics, and/or mode of living. These reports may contain
             information regarding
             your credit history, criminal history, social security verification, motor vehicle records (“driving
             records”),
             verification of your education or employment history, or other background checks. Credit history will
             only be
             requested where such information is substantially related to the duties and responsibilities of the
             position for
             which you are applying. You have the right, upon written request made within a reasonable time, to
             request whether a
             consumer report has been run about you and to request a copy of your report. These searches will be
             conducted by MBI
             Worldwide, 101 N. Park Ave., Suite 200, Herrin, IL, 62948; Toll-free 866-275-4624; www.mbiworldwide.com.
             The scope
             of this disclosure is all-encompassing, however, allowing the Company to obtain from any outside
             organization all
             manner of consumer reports throughout the course of your employment to the extent permitted by law.
         </p>
         <p>
             <b>DISCLOSURE REGARDING “INVESTIGATIVE CONSUMER REPORT” BACKGROUND INVESTIGATION</b>
             <br />
             Erie Family Health, to which you have applied for employment, may request an investigative consumer
             report about you
             from a third party consumer reporting agency, in connection with your employment or application for
             employment
             (including independent contractor or volunteer assignments, as applicable). An “investigative consumer
             report” is a
             background report that includes information from personal interviews (except in California, where that
             term includes
             background reports with or without information obtained from personal interviews). The most common form
             of an
             investigative consumer report in connection with your employment is a reference check through personal
             interviews
             with sources such as your former employers and associates, and other information sources. The
             investigative consumer
             report may contain information concerning your character, general reputation, personal characteristics,
             mode of
             living, or credit standing. You may request more information about the nature and scope of an
             investigative consumer
             report, if any, by contacting the Company.You have the right, upon written request made within a
             reasonable time, to
             request (1) whether an investigative consumer report has been obtained about you, (2) disclosure of the
             nature and
             scope of any investigative consumer report and (3) a copy of your report. These reports will be
             conducted by MBI
             Worldwide, 101 N. Park Ave., Suite 200, Herrin, IL 62948; Toll-free: 866-275-4624; www.mbiworldwide.com.
             The scope
             of this disclosure is all-encompassing, however, allowing the Company to obtain from any outside
             organization all
             manner of investigative consumer reports throughout the course of your employment to the extent
             permitted by law.
         </p>
         <p>
             <b><u>ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND CHECK</u></b>
             <br />
             I acknowledge receipt of the separate document entitled DISCLOSURE REGARDING BACKGROUND INVESTIGATION
             and A SUMMARY
             OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and certify that I have read and understand both of
             those
             documents. I hereby authorize the obtaining of “consumer reports” and/or “investigative consumer
             reports” by
             Employer at any time after receipt of this authorization and throughout my employment, if applicable. To
             this end, I
             hereby authorize, without reservation, any law enforcement agency, administrator, state or federal
             agency,
             institution, school or university (public or private), information service bureau, employer, or
             insurance company to
             furnish any and all background information requested by MBI Worldwide, 101 N. Park Ave., Suite 200,
             Herrin, IL
             62948; Toll-free 866-275-4624; www.mbiworldwide.com and/or Employer. I agree that a facsimile (“fax”),
             electronic or
             photographic copy of this Authorization shall be as valid as the original.
         </p>
         <table class="databox" >
             <tr>
                 <td colspan="12">
                     <label><u>New York applicants only: Upon request, you will be informed whether or not a consumer
                             report was
                             requested by the Company, and if such report was requested, informed of the name and
                             address of the
                             consumer reporting agency that furnished the report. You have the right to inspect and
                             receive a
                             copy of any investigative consumer report requested by the Company by contacting the
                             consumer
                             reporting agency identified above directly. By signing below, you acknowledge receipt of
                             Article
                             23-A of the New York Correction Law
                         </u></label>
             </tr>
             <tr>
                 <td colspan="12">
                     <label>
                         <b>New York City applicants only:</b> You acknowledge and authorize the Employer to provide
                         any notices
                         required by federal, state or local law to you at the address(es) and/or email address(es)
                         you provided
                         to the Company.</label>
                 </td>
             </tr>

             <tr>
                 <td colspan="12">
                     <label>
                         <u>Washington State applicants only: You also have the right to request from the consumer
                             reporting
                             agency a written summary of your rights and remedies under the Washington Fair Credit
                             Reporting
                             Act.</u>
                     </label>
                 </td>
             </tr>

             <tr>
                 <td colspan="12">
                     <label>
                         <u>Minnesota and Oklahoma applicants only:</u> Please check this box if you would like to receive
                         a copy of a
                         consumer report if one is obtained by the Company.</label>
                 </td>
             </tr>
         </table>
         <div class="row pt-4 mb-3">
             <div class="col-md-7 text-center" >
   <img *ngIf="!show22" class="img" src="./assets/images/e_sign.png" alt="Credential my Doc"
   (click)="esignOpen(22)">
   <img *ngIf="show22" class="img eSignImageShow" [src]="esign22" alt="Credential my Doc"
   (click)="esignOpen(22)">
   <input type="hidden" name="cmd_9000371"  formControlName="cmd_9000371" >

   <div class="formLabel"
   *ngIf="(erieForm1.controls['cmd_9000371'].touched && erieForm1.controls['cmd_9000371'].errors)
    || (erieForm1.controls['cmd_9000371'].errors && isSubmit)">
   <small class="text-danger" *ngIf="erieForm1.controls['cmd_9000371'].errors">
      required
   </small>
 </div>
                 </div>
             <div class="col-md-3">
                 <div class="input-group">
                       <input   formControlName="cmd_34060" (blur)="clearInputIfInvalidMandi('cmd_34060')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d142="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34060'].touched && erieForm1.controls['cmd_34060'].errors)
                         || (erieForm1.controls['cmd_34060'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d142.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                     <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34060'].invalid&&erieForm1.controls['cmd_34060'].touched) && (erieForm1.controls['cmd_34060'].value==''|| erieForm1.controls['cmd_34060'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34060').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34060').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34060').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm1.get('cmd_34060').errors?.invalidDateRange">
                          date is out of range
                          </small>

                 </div>
             </div>
         </div>
         <div class="row mb-3">
             <div class="col-md-7 text-center" >
                 <label style="font-weight: normal;font-size: 14px; padding-left:76px;">Applicant’s Signature</label>
             </div>
             <div class="col-md-4">
                 <label  > Date</label>
             </div>
         </div>
         <br />
         <br />
             <hr
             class="pdf-line">
         <img src="../../../../assets/images/mbi.png" width="350" height="210" alt="Credential my Doc"
             style="margin-left: 25rem;height:fit-content;">
        <h6 class="text-center">
             <u>BACKGROUND INFORMATION</u>
        </h6>
         <label><u>Please print/type the requested information. Lack of legible or missing information may delay
                 processing of
                 this request.</u></label>
         <div class="row mb-3"  >
             <div class="col-md-3">
                 <label  >
                     Applicant Name:
                 </label>
             </div>
             <div class="col-md-3">
                   <input   formControlName="cmd_34061"  class="form-control " id="cmd_34061" name="cmd_34061" type="text" placeholder="firstName">
             </div>
             <div class="col-md-3">
                   <input   formControlName="cmd_34062"  class="form-control " id="cmd_34062" name="cmd_34062" type="text" placeholder="Middle">
             </div>
             <div class="col-md-3">
                   <input   formControlName="cmd_34063"  class="form-control " id="cmd_34063" name="cmd_34063" type="text" placeholder="lastName">
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-5">
                 <label  >Other legal names known by (limit to 7years):
                 </label>
             </div>
             <div class="col-md-7">
                   <input   formControlName="cmd_34064"  class="form-control " id="cmd_34064" name="cmd_34064" type="text" placeholder="otherNames">
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-2">
                 <label>Date of Birth*</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34065"  (blur)="clearInputIfInvalid('cmd_34065')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d143="ngbDatepicker" container="body">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d143.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>
                 <div  *ngIf="erieForm1.get('cmd_34065').invalid ">
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34065').errors?.invalidDateFormat">
                      Only Date Format is allowed(MM/DD/YYYY)
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34065').errors?.invalidMonth">
                    Month is out of range
                    </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34065').errors?.invalidDateyear">
                      year is out of range(1900-2099)                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34065').errors?.invalidDateRange">
                        date is out of range
                        </small>
                    </div>
             </div>
             <div class="col-md-3">
                 <label>Driver’s License#</label>
             </div>
             <div class="col-md-3" style="margin-left: -50px;">
                   <input   formControlName="cmd_34066"  class="form-control " id="cmd_34066" name="cmd_34066" type="text" placeholder="license">
             </div>
             <div class="col-md-2">
                 <div class="mb-3  "  >
              <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
             bindValue="id" formControlName="cmd_34067">
         </ng-select>
                 </div>
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-1">
                 <label>SSN*</label>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34068"  class="form-control "
                    id="cmd_34068" name="cmd_34068" type="text" placeholder="SSN"
                    [class.is-invalid]="(erieForm1.controls['cmd_34068'].touched && erieForm1.controls['cmd_34068'].errors)
                    || (erieForm1.controls['cmd_34068'].errors && isSubmit)">
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34068'].touched && erieForm1.controls['cmd_34068'].errors)
       || (erieForm1.controls['cmd_34068'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34068'].errors">
        required
      </small>
  </div>
                </div>
             <div class="col-md-1">
                 <label>Gender:</label>

             </div>
             <div class="col-md-3">
                <ng-select
                class="required" [items]="genderDropdownData" bindLabel="name" placeholder="Select gender" bindValue="id"
                required
                formControlName="cmd_34069"
                >
                   </ng-select>
             </div>
             <div class="col-md-1">
                 <label  >Race:</label>
             </div>
             <div class="col-md-3">
                  <ng-select [items]="raceData" bindLabel="name" placeholder="Select Race"
                     bindValue="id" formControlName="cmd_34070">
                 </ng-select>
             </div>
         </div>
         <br />
         <div class="row mb-3"  >
             <div class="col-md-2">
                 <label  >Present Address:</label>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34071"  class="form-control   " id="cmd_34071" name="cmd_34071" type="text"
                     placeholder="address1"  >
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34072"  class="form-control   " id="cmd_34072" name="cmd_34072" type="text"
                     placeholder="address2"  >
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34073"  class="form-control   " id="cmd_34073" name="cmd_34073" type="text" placeholder="city"
                      >
             </div>
             <div class="col-md-2">
                 <div class="mb-3  "  >
                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                         bindValue="id" formControlName="cmd_34074">
                     </ng-select>
                 </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34075"  class="form-control   " id="cmd_34075" name="cmd_34075" type="text" placeholder="zip"
                     >
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-12">
                 <label  >Home Addresses for the Past 7 Years: (List
                     additional
                     addresses on separate page, if needed.)
                 </label>
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-2">
                   <input   formControlName="cmd_34076"  class="form-control   " id="cmd_34076" name="cmd_34076" type="text"
                   [class.is-invalid]="(erieForm1.controls['cmd_34076'].touched && erieForm1.controls['cmd_34076'].errors)
                   || (erieForm1.controls['cmd_34076'].errors && isSubmit)"
                     placeholder="address1"  >
             <div class="formLabel"
                     *ngIf="(erieForm1.controls['cmd_34076'].touched && erieForm1.controls['cmd_34076'].errors)
                      || (erieForm1.controls['cmd_34076'].errors && isSubmit)">
                     <small class="text-danger" *ngIf="erieForm1.controls['cmd_34076'].errors">
                        required
                     </small>
                 </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34077"  class="form-control" id="cmd_34077" name="cmd_34077" type="text"
                   [class.is-invalid]="(erieForm1.controls['cmd_34077'].touched && erieForm1.controls['cmd_34077'].errors)
                   || (erieForm1.controls['cmd_34077'].errors && isSubmit)"
                   placeholder="address2"  >
                   <div class="formLabel"
                     *ngIf="(erieForm1.controls['cmd_34077'].touched && erieForm1.controls['cmd_34077'].errors)
                      || (erieForm1.controls['cmd_34077'].errors && isSubmit)">
                     <small class="text-danger" *ngIf="erieForm1.controls['cmd_34077'].errors">
                        required
                     </small>
                 </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34078"  class="form-control   " id="cmd_34078" name="cmd_34078" type="text" placeholder="city"
                   [class.is-invalid]="(erieForm1.controls['cmd_34078'].touched && erieForm1.controls['cmd_34078'].errors)
                   || (erieForm1.controls['cmd_34078'].errors && isSubmit)">

                    <div class="formLabel"
                     *ngIf="(erieForm1.controls['cmd_34078'].touched && erieForm1.controls['cmd_34078'].errors)
                      || (erieForm1.controls['cmd_34078'].errors && isSubmit)">
                     <small class="text-danger" *ngIf="erieForm1.controls['cmd_34078'].errors">
                        required
                     </small>
                 </div>

             </div>
             <div class="col-md-2">

                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                         bindValue="id" formControlName="cmd_34079"
                         [class.is-invalid]="(erieForm1.controls['cmd_34079'].touched && erieForm1.controls['cmd_34079'].errors)
                         || (erieForm1.controls['cmd_34079'].errors && isSubmit)"
                         >

                     </ng-select>


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34079'].touched && erieForm1.controls['cmd_34079'].errors)
       || (erieForm1.controls['cmd_34079'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34079'].errors">
        required
      </small>
  </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34080"  class="form-control   " id="cmd_34080" name="9cmd_34080" type="text" placeholder="zip"
                   [class.is-invalid]="(erieForm1.controls['cmd_34080'].touched && erieForm1.controls['cmd_34080'].errors)
                   || (erieForm1.controls['cmd_34080'].errors && isSubmit)"   >


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34080'].touched && erieForm1.controls['cmd_34080'].errors)
       || (erieForm1.controls['cmd_34080'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34080'].errors">
        required
      </small>
  </div>
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-1">
                 <label  >From:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34081" (blur)="clearInputIfInvalidMandi('cmd_34081')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d144="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34081'].touched && erieForm1.controls['cmd_34081'].errors)
                         || (erieForm1.controls['cmd_34081'].errors && isSubmit)" >
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d144.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>
                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34081'].invalid&&erieForm1.controls['cmd_34081'].touched) && (erieForm1.controls['cmd_34081'].value==''|| erieForm1.controls['cmd_34081'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34081').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34081').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34081').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34081').errors?.invalidDateRange">
                      date is out of range
                      </small>
             </div>
             <div class="col-md-1">
                 <label  >To:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34082" (blur)="clearInputIfInvalidMandi('cmd_34082')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d145="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34082'].touched && erieForm1.controls['cmd_34082'].errors)
                         || (erieForm1.controls['cmd_34082'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d145.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                     <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34082'].invalid&&erieForm1.controls['cmd_34082'].touched) && (erieForm1.controls['cmd_34082'].value==''|| erieForm1.controls['cmd_34082'].value==null) " >
                        <small >
                        required
                        </small>
                      
                      </div>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34082').errors?.invalidDateFormat">
                        Only Date Format is allowed(MM/DD/YYYY)</small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34082').errors?.invalidMonth">
                      Month is out of range
                      </small>
                      <small class="text-danger" *ngIf="erieForm1.get('cmd_34082').errors?.invalidDateyear">
                        year is out of range(1900-2099)                  </small>
                        <small class="text-danger" *ngIf="erieForm1.get('cmd_34082').errors?.invalidDateRange">
                          date is out of range
                          </small>

                 </div>
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-2">
                   <input   formControlName="cmd_34083"  class="form-control" id="cmd_34083" name="cmd_34083" type="text"
                     placeholder="address1"
                     [class.is-invalid]="(erieForm1.controls['cmd_34083'].touched && erieForm1.controls['cmd_34083'].errors)
                     || (erieForm1.controls['cmd_34083'].errors && isSubmit)" >

<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34083'].touched && erieForm1.controls['cmd_34083'].errors)
       || (erieForm1.controls['cmd_34083'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34083'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34084"  class="form-control   " id="cmd_34084" name="cmd_34084" type="text"
                     placeholder="address2"
                     [class.is-invalid]="(erieForm1.controls['cmd_34084'].touched && erieForm1.controls['cmd_34084'].errors)
                     || (erieForm1.controls['cmd_34084'].errors && isSubmit)" >


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34084'].touched && erieForm1.controls['cmd_34084'].errors)
       || (erieForm1.controls['cmd_34084'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34084'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34085"  class="form-control   " id="cmd_34085" name="cmd_34085" type="text" placeholder="city"
                   [class.is-invalid]="(erieForm1.controls['cmd_34085'].touched && erieForm1.controls['cmd_34085'].errors)
    || (erieForm1.controls['cmd_34085'].errors && isSubmit)">
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34085'].touched && erieForm1.controls['cmd_34085'].errors)
       || (erieForm1.controls['cmd_34085'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34085'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                 <div class="mb-3  "  >
                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                         bindValue="id" formControlName="cmd_34086"
    [class.is-invalid]="(erieForm1.controls['cmd_34086'].touched && erieForm1.controls['cmd_34086'].errors)
    || (erieForm1.controls['cmd_34086'].errors && isSubmit)">
                     </ng-select>

<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34086'].touched && erieForm1.controls['cmd_34086'].errors)
       || (erieForm1.controls['cmd_34086'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34086'].errors">
        required
      </small>
  </div>
</div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34087"  class="form-control   " id="cmd_34087" name="cmd_34087" type="text" placeholder="zip"
                   [class.is-invalid]="(erieForm1.controls['cmd_34087'].touched && erieForm1.controls['cmd_34087'].errors)
                   || (erieForm1.controls['cmd_34087'].errors && isSubmit)" >
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34087'].touched && erieForm1.controls['cmd_34087'].errors)
       || (erieForm1.controls['cmd_34087'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34087'].errors">
        required
      </small>
  </div>

             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-1">
                 <label  >From:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34088" (blur)="clearInputIfInvalidMandi('cmd_34088')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d146="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34088'].touched && erieForm1.controls['cmd_34088'].errors)
                         || (erieForm1.controls['cmd_34088'].errors && isSubmit)">

                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d146.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
</div>
<div class="text-danger" *ngIf="(erieForm1.controls['cmd_34088'].invalid&&erieForm1.controls['cmd_34088'].touched) && (erieForm1.controls['cmd_34088'].value==''|| erieForm1.controls['cmd_34088'].value==null) " >
    <small >
    required
    </small>
  
  </div>
  <small class="text-danger" *ngIf="erieForm1.get('cmd_34088').errors?.invalidDateFormat">
    Only Date Format is allowed(MM/DD/YYYY)</small>
  <small class="text-danger" *ngIf="erieForm1.get('cmd_34088').errors?.invalidMonth">
  Month is out of range
  </small>
  <small class="text-danger" *ngIf="erieForm1.get('cmd_34088').errors?.invalidDateyear">
    year is out of range(1900-2099)                  </small>
    <small class="text-danger" *ngIf="erieForm1.get('cmd_34088').errors?.invalidDateRange">
      date is out of range
      </small>
             </div>
             <div class="col-md-1">
                 <label  >To:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34089"  (blur)="clearInputIfInvalidMandi('cmd_34089')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d147="ngbDatepicker" container="body"

    [class.is-invalid]="(erieForm1.controls['cmd_34089'].touched && erieForm1.controls['cmd_34089'].errors)
    || (erieForm1.controls['cmd_34089'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d147.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>

                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34089'].invalid&&erieForm1.controls['cmd_34089'].touched) && (erieForm1.controls['cmd_34089'].value==''|| erieForm1.controls['cmd_34089'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34089').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34089').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34089').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34089').errors?.invalidDateRange">
                      date is out of range
                      </small>

             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-2">
                   <input   formControlName="cmd_34090"  class="form-control   " id="cmd_34090" name="cmd_34090" type="text"
                     placeholder="address1"
                     [class.is-invalid]="(erieForm1.controls['cmd_34090'].touched && erieForm1.controls['cmd_34090'].errors)
                     || (erieForm1.controls['cmd_34090'].errors && isSubmit)" >


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34090'].touched && erieForm1.controls['cmd_34090'].errors)
       || (erieForm1.controls['cmd_34090'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34090'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34091"  class="form-control   " id="cmd_34091" name="cmd_34091" type="text"
                     placeholder="address2"
                     [class.is-invalid]="(erieForm1.controls['cmd_34091'].touched && erieForm1.controls['cmd_34091'].errors)
                     || (erieForm1.controls['cmd_34091'].errors && isSubmit)"  >
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34091'].touched && erieForm1.controls['cmd_34091'].errors)
       || (erieForm1.controls['cmd_34091'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34091'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34092"  class="form-control   " id="cmd_34092" name="cmd_34092" type="text" placeholder="city"
                   [class.is-invalid]="(erieForm1.controls['cmd_34092'].touched && erieForm1.controls['cmd_34092'].errors)
                   || (erieForm1.controls['cmd_34092'].errors && isSubmit)"    >
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34092'].touched && erieForm1.controls['cmd_34092'].errors)
       || (erieForm1.controls['cmd_34092'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34092'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                 <div class="mb-3  "  >
                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                         bindValue="id" formControlName="cmd_34093"
                         [class.is-invalid]="(erieForm1.controls['cmd_34093'].touched && erieForm1.controls['cmd_34093'].errors)
                         || (erieForm1.controls['cmd_34093'].errors && isSubmit)">
                     </ng-select>
                 </div>
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34093'].touched && erieForm1.controls['cmd_34093'].errors)
       || (erieForm1.controls['cmd_34093'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34093'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34094"  class="form-control   " id="cmd_34094" name="cmd_34094" type="text" placeholder="zip"
                   [class.is-invalid]="(erieForm1.controls['cmd_34094'].touched && erieForm1.controls['cmd_34094'].errors)
                   || (erieForm1.controls['cmd_34094'].errors && isSubmit)">
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34094'].touched && erieForm1.controls['cmd_34094'].errors)
       || (erieForm1.controls['cmd_34094'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34094'].errors">
        required
      </small>
  </div>

             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-1">
                 <label  >From:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34095"  (blur)="clearInputIfInvalidMandi('cmd_34095')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d148="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34095'].touched && erieForm1.controls['cmd_34095'].errors)
                         || (erieForm1.controls['cmd_34095'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d148.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>

                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34095'].invalid&&erieForm1.controls['cmd_34095'].touched) && (erieForm1.controls['cmd_34095'].value==''|| erieForm1.controls['cmd_34095'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34095').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34095').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34095').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34095').errors?.invalidDateRange">
                      date is out of range
                      </small>

             </div>
             <div class="col-md-1">
                 <label  >To:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34096" (blur)="clearInputIfInvalidMandi('cmd_34096')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d149="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34096'].touched && erieForm1.controls['cmd_34096'].errors)
                         || (erieForm1.controls['cmd_34096'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d149.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>

                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34096'].invalid&&erieForm1.controls['cmd_34096'].touched) && (erieForm1.controls['cmd_34096'].value==''|| erieForm1.controls['cmd_34096'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34096').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34096').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34096').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34096').errors?.invalidDateRange">
                      date is out of range
                      </small>

             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-2">
                   <input   formControlName="cmd_34097"  class="form-control   " id="cmd_34097" name="cmd_34097" type="text"
                     placeholder="address1"
                     [class.is-invalid]="(erieForm1.controls['cmd_34097'].touched && erieForm1.controls['cmd_34097'].errors)
                     || (erieForm1.controls['cmd_34097'].errors && isSubmit)" >
<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34097'].touched && erieForm1.controls['cmd_34097'].errors)
       || (erieForm1.controls['cmd_34097'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34097'].errors">
        required
      </small>
  </div>

             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34098"  class="form-control   " id="cmd_34098" name="cmd_34098" type="text"
                     placeholder="address2"
                      [class.is-invalid]="(erieForm1.controls['cmd_34098'].touched && erieForm1.controls['cmd_34098'].errors)
                     || (erieForm1.controls['cmd_34098'].errors && isSubmit)" >
                     <div class="formLabel"
                     *ngIf="(erieForm1.controls['cmd_34098'].touched && erieForm1.controls['cmd_34098'].errors)
                      || (erieForm1.controls['cmd_34098'].errors && isSubmit)">
                     <small class="text-danger" *ngIf="erieForm1.controls['cmd_34098'].errors">
                        required
                     </small>
                 </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34099"  class="form-control   " id="cmd_34099" name="9cmd_34099" type="text" placeholder="city"
    [class.is-invalid]="(erieForm1.controls['cmd_34099'].touched && erieForm1.controls['cmd_34099'].errors)
    || (erieForm1.controls['cmd_34099'].errors && isSubmit)" >

<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34099'].touched && erieForm1.controls['cmd_34099'].errors)
       || (erieForm1.controls['cmd_34099'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34099'].errors">
        required
      </small>
  </div>
             </div>
             <div class="col-md-2">
                 <div class="mb-3 "  >
                      <ng-select [items]="stateDropDownDate" bindLabel="name" placeholder="Select  State"
                         bindValue="id" formControlName="cmd_34100"
                         [class.is-invalid]="(erieForm1.controls['cmd_34100'].touched && erieForm1.controls['cmd_34100'].errors)
                         || (erieForm1.controls['cmd_34100'].errors && isSubmit)">
                     </ng-select>


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34100'].touched && erieForm1.controls['cmd_34100'].errors)
       || (erieForm1.controls['cmd_34100'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34100'].errors">
        required
      </small>
  </div>

                 </div>
             </div>
             <div class="col-md-2">
                   <input   formControlName="cmd_34101"  class="form-control   " id="cmd_34101" name="cmd_34101" type="text" placeholder="zip"
                   [class.is-invalid]="(erieForm1.controls['cmd_34101'].touched && erieForm1.controls['cmd_34101'].errors)
                   || (erieForm1.controls['cmd_34101'].errors && isSubmit)" >


<div class="formLabel"
      *ngIf="(erieForm1.controls['cmd_34101'].touched && erieForm1.controls['cmd_34101'].errors)
       || (erieForm1.controls['cmd_34101'].errors && isSubmit)">
      <small class="text-danger" *ngIf="erieForm1.controls['cmd_34101'].errors">
        required
      </small>
  </div>

             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-1">
                 <label  >From:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34102"  (blur)="clearInputIfInvalidMandi('cmd_34102')" class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d150="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34102'].touched && erieForm1.controls['cmd_34102'].errors)
                         || (erieForm1.controls['cmd_34102'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d150.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>


                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34102'].invalid&&erieForm1.controls['cmd_34102'].touched) && (erieForm1.controls['cmd_34102'].value==''|| erieForm1.controls['cmd_34102'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34102').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34102').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34102').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34102').errors?.invalidDateRange">
                      date is out of range
                      </small>

             </div>
             <div class="col-md-1">
                 <label  >To:</label>
             </div>
             <div class="col-md-2">
                 <div class="input-group">
                       <input   formControlName="cmd_34103" (blur)="clearInputIfInvalidMandi('cmd_34103')"  class="form-control"    placeholder="mm/dd/yyyy" ngbDatepicker
                         #d151="ngbDatepicker" container="body"
                         [class.is-invalid]="(erieForm1.controls['cmd_34103'].touched && erieForm1.controls['cmd_34103'].errors)
                         || (erieForm1.controls['cmd_34103'].errors && isSubmit)">
                     <div class="input-group-append">
                         <button class="btn btn-outline-secondary calendar" (click)="d151.toggle()" type="button"><i
                                 class="fas fa-calendar-alt"></i>
                         </button>
                     </div>
                 </div>


                 <div class="text-danger" *ngIf="(erieForm1.controls['cmd_34103'].invalid&&erieForm1.controls['cmd_34103'].touched) && (erieForm1.controls['cmd_34103'].value==''|| erieForm1.controls['cmd_34103'].value==null) " >
                    <small >
                    required
                    </small>
                  
                  </div>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34103').errors?.invalidDateFormat">
                    Only Date Format is allowed(MM/DD/YYYY)</small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34103').errors?.invalidMonth">
                  Month is out of range
                  </small>
                  <small class="text-danger" *ngIf="erieForm1.get('cmd_34103').errors?.invalidDateyear">
                    year is out of range(1900-2099)                  </small>
                    <small class="text-danger" *ngIf="erieForm1.get('cmd_34103').errors?.invalidDateRange">
                      date is out of range
                      </small>

             </div>
         </div>
         <br />
         <br />
         <div class="row mb-3"  >
             <div class="col-md-3">
                 <label  >Applicant Phone Number :</label>
             </div>
             <div class="col-md-4">
                   <input   formControlName="cmd_34104"  class="form-control" id="cmd_34104" type="text"
                     placeholder="phoneNumber" >
             </div>
         </div>
         <div class="row mb-3"  >
             <div class="col-md-3">
                 <label  >Applicant Email Address:</label>
             </div>
             <div class="col-md-4">
                   <input   formControlName="cmd_1017"  class="form-control " id="cmd_1017"  type="text" placeholder="email"
                     >
             </div>
         </div>
         <label>
             *This information will be used for background screening purposes only and will not be used as hiring
             criteria.
         </label>
</div>
